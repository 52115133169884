import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import { isEmpty } from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { Possibly } from '@helpers/types'

import SearchBoxInput from '@components/GenericInputs/SearchBoxInput'
import { VerticalListEntry } from '@components/NavigationLists/VerticalListGroup'
import styles from '@components/NavigationLists/VerticalListGroup.module.scss'

type VerticalListGroupProps = {
    selectedEntries: Array<VerticalListEntry['id']> | undefined
    selectableEntries: Array<{ organizationName: string; devices: Array<VerticalListEntry> }>
    onSelectionChange: (id: string) => void
} & Possibly<{
    search: string
    onSearch: (search: string) => void
    placeholder: string
}>

const DevicesMultiselectList: React.FC<VerticalListGroupProps> = ({
    selectedEntries,
    selectableEntries,
    onSelectionChange,
    search,
    onSearch,
    placeholder,
}) => {
    const { t } = useTranslation()

    return (
        <div className={classNames(styles.verticalListGroupContainer, styles.multiSelectListContainer)}>
            {onSearch && (
                <SearchBoxInput
                    className={styles.stickySearch}
                    placeholder={placeholder ?? ''}
                    value={search}
                    onChange={onSearch}
                />
            )}
            <div>
                {selectableEntries.map(({ organizationName, devices }, index) => {
                    return (
                        <React.Fragment key={index}>
                            {!isEmpty(devices) && (
                                <div key={organizationName} className={styles.nonSelectableItem}>
                                    {organizationName
                                        ? organizationName
                                        : t('button.noOrganization', 'No organization')}
                                </div>
                            )}
                            {devices.map(({ id, className, icon, displayText, indicators }) => (
                                <div
                                    key={id}
                                    className={classNames(
                                        {
                                            [styles.verticalListGroupItem]: true,
                                            [styles.active]: selectedEntries?.includes(id),
                                        },
                                        styles.multiSelectItem,
                                        className
                                    )}
                                    onClick={() => onSelectionChange(id)}
                                >
                                    <div className={styles.titleContainer}>
                                        {icon && <FontAwesomeIcon icon={icon} />}
                                        {displayText}
                                    </div>
                                    {indicators && <div className={styles.indicators}>{indicators}</div>}
                                </div>
                            ))}
                        </React.Fragment>
                    )
                })}
            </div>
        </div>
    )
}

export default DevicesMultiselectList
