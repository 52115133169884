import { useState, useEffect } from 'react'

/**
 * Returns if we are running in a pre-rendering context (react-snap, SSR, etc.).
 * For hydration to work, we must output the same nodes on the server and on the
 * client, which is why we always assume that we are pre-rendering on the first
 * render and only change the value after mounting.
 */
export const useIsPreRendering = () => {
    const [value, setValue] = useState(true)

    useEffect(() => {
        setValue(navigator.userAgent === 'ReactSnap')
    }, [])

    return value
}
