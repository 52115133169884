import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import { Interval } from 'luxon'
import React, { Children, ReactElement } from 'react'
import { Col, ColProps } from 'react-bootstrap'

import { LocalityNameModel } from '@api/models'

import { displayNumericValue, formatLargeNumber } from '@helpers/displayUtils'

import ExplanationTooltip from '@components/ExplanationTooltip'

import styles from './StatisticBox.module.scss'

type StatisticValue = {
    value?: number | string
    unit?: JSX.Element | string
}

export const StatisticBoxItem: React.FC<{
    className?: string
    icon?: IconDefinition
    iconClass?: string
    value?: number | string
    unit?: JSX.Element | string
    changeIndicator?: JSX.Element
    verticalPlacement?: boolean
}> = ({ icon, iconClass, value, unit, className, changeIndicator = null, verticalPlacement = false }) => (
    <div
        className={classNames({
            [styles.wrapperHorizontal]: !verticalPlacement,
            [styles.wrapperVertical]: verticalPlacement,
        })}
    >
        <div className={classNames(styles.data, styles.dataSizeLarge, className)}>
            {icon && <FontAwesomeIcon className={classNames(styles.icon, iconClass)} icon={icon} />}
            {typeof value === 'number' ? formatLargeNumber(displayNumericValue(value)) : value}
            {!(Number.isNaN(value) || value === undefined) && unit}
        </div>
        {changeIndicator}
    </div>
)

type Props = ColProps & {
    icon?: JSX.Element
    label: string
    labelIcon?: JSX.Element
    tooltip?: string
    interval: Interval
    localities: LocalityNameModel[]
    children: ReactElement<StatisticValue> | ReactElement<StatisticValue>[]
    verticalPlacement?: boolean
    boxClassName?: string
}

const StatisticBox: React.FC<Props> = ({
    icon,
    labelIcon,
    label,
    tooltip,
    interval,
    localities,
    children,
    verticalPlacement,
    boxClassName,
    ...rest
}) => {
    const data: StatisticValue[] = Children.toArray(children).map((it) => {
        const props: StatisticValue = (it as ReactElement<StatisticValue>).props

        return { value: props.value, unit: props.unit }
    })

    return (
        <Col {...rest}>
            <div className={classNames(styles.box, boxClassName)}>
                <span className={styles.label}>
                    {labelIcon}
                    {label}
                    {tooltip && (
                        <ExplanationTooltip
                            selectedInterval={interval}
                            selectedLocalities={localities}
                            statisticsValue={data}
                            statisticsValueDescription={tooltip}
                        />
                    )}
                </span>
                <div
                    className={classNames({
                        [styles.dataDisplay]: true,
                        [styles.verticalBoxItem]: verticalPlacement,
                    })}
                >
                    <>{children}</>
                </div>
            </div>
        </Col>
    )
}

export default StatisticBox
