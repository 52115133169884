import { faGlobeEurope } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { uniqueId } from 'lodash'
import React, { useMemo } from 'react'
import { Dropdown } from 'react-bootstrap'

import styles from './StaySafeDetails.module.scss'
import { LanguageSelection } from './staySafeData'

interface Props {
    onSelect: (locality: LanguageSelection) => void
    languages: Array<LanguageSelection>
}

const StaySafeDetailsLanguagePicker: React.FC<Props> = ({ onSelect, languages }) => {
    const id = useMemo(uniqueId, [])

    return (
        <Dropdown
            className={styles.staySafeScreenLanguageDropdown}
            onSelect={(eventKey) => {
                if (eventKey === null) {
                    return
                }

                const selected = languages.find((it) => it.ISO639 === eventKey)

                if (selected !== undefined) {
                    onSelect(selected)
                }
            }}
        >
            <Dropdown.Toggle id={id} variant="secondary">
                <FontAwesomeIcon icon={faGlobeEurope} />
            </Dropdown.Toggle>
            <Dropdown.Menu>
                {languages.map(({ ISO639: shortName, ISO: longName }) => (
                    <Dropdown.Item key={shortName} eventKey={String(shortName)}>
                        {longName}
                    </Dropdown.Item>
                ))}
            </Dropdown.Menu>
        </Dropdown>
    )
}

export default StaySafeDetailsLanguagePicker
