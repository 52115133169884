import { IconDefinition } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import { isEmpty } from 'lodash'
import React, { useEffect } from 'react'
import { ListGroup } from 'react-bootstrap'

import { Possibly } from '@helpers/types'

import useRefMap from '@hooks/useRefMap'

import SearchBoxInput from '@components/GenericInputs/SearchBoxInput'

import styles from './VerticalListGroup.module.scss'

export type VerticalListEntry = {
    id: string
    displayText: string
    icon?: IconDefinition
    indicators?: Array<JSX.Element>
    className?: string
    disabled?: boolean
}

type VerticalListGroupProps = {
    selectedEntry: VerticalListEntry['id'] | undefined
    selectableEntries: Array<VerticalListEntry>
    unfilteredSelectableEntries: Array<VerticalListEntry>
    onSelectionChange: (id: string) => void
    scrollToSelected?: boolean
} & Possibly<{
    search: string
    onSearch: (search: string) => void
    placeholder: string
}>

const VerticalListGroup: React.FC<VerticalListGroupProps> = ({
    selectedEntry,
    selectableEntries,
    unfilteredSelectableEntries,
    onSelectionChange,
    search,
    onSearch,
    placeholder,
    scrollToSelected,
}) => {
    const { getRef, setRef } = useRefMap()

    useEffect(() => {
        if (selectedEntry && scrollToSelected) {
            const selected = getRef(selectedEntry)
            selected?.scrollIntoView({ behavior: 'auto', block: 'center' })
        }
    }, [selectedEntry])

    return (
        <div className={styles.verticalListGroupContainer}>
            {onSearch && !isEmpty(unfilteredSelectableEntries) && (
                <SearchBoxInput
                    className={styles.stickySearch}
                    placeholder={placeholder ?? ''}
                    value={search}
                    onChange={onSearch}
                />
            )}
            <ListGroup
                activeKey={selectedEntry}
                onSelect={(eventKey) => (eventKey ? onSelectionChange(eventKey) : undefined)}
            >
                {selectableEntries.map(({ displayText, id, icon, indicators, className, disabled = false }) => (
                    <ListGroup.Item
                        key={id}
                        ref={(anchor: HTMLAnchorElement | null) => setRef(id, anchor)}
                        className={classNames(
                            {
                                [styles.verticalListGroupItem]: true,
                                [styles.active]: selectedEntry === id,
                            },
                            className
                        )}
                        disabled={id === selectedEntry || disabled}
                        eventKey={id}
                        action
                    >
                        <div className={styles.titleContainer}>
                            {icon && <FontAwesomeIcon icon={icon} />}
                            {displayText}
                        </div>
                        {indicators && <div className={styles.indicators}>{indicators}</div>}
                    </ListGroup.Item>
                ))}
            </ListGroup>
        </div>
    )
}

export default VerticalListGroup
