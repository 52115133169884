import { uniqueId } from 'lodash'
import React, { useMemo } from 'react'
import { Tab, TabProps, Tabs } from 'react-bootstrap'
import { SelectCallback } from 'react-bootstrap/esm/helpers'

import { Possibly } from '@helpers/types'

import { BreadcrumbsList } from '@components/Breadcrumbs'

import BarePageLayout from './BarePageLayout'
import { LayoutHeadingProps } from './LayoutHeading'

export type TabList = Array<TabProps & { renderOnDemand?: boolean }>

type TabsProps = {
    activeKey: string
    onSelect: SelectCallback
    tabs: TabList
}

type Props = TabsProps & LayoutHeadingProps & Possibly<{ breadcrumbs: BreadcrumbsList }> & { documentTitle?: string }

const TabbedPageLayout: React.FC<Props> = ({ breadcrumbs, activeKey, heading, onSelect, tabs, documentTitle }) => {
    const id = useMemo(uniqueId, [])

    return (
        <>
            <BarePageLayout
                breadcrumbs={breadcrumbs}
                documentTitle={documentTitle}
                heading={heading}
                headingType="page"
            >
                <Tabs activeKey={activeKey} id={`${id}-tabs`} onSelect={onSelect}>
                    {tabs.map(({ eventKey, title, children, renderOnDemand = false }, index) => (
                        <Tab
                            key={`tab-${index}`}
                            eventKey={eventKey}
                            mountOnEnter={renderOnDemand}
                            title={title}
                            unmountOnExit={renderOnDemand}
                        >
                            {children}
                        </Tab>
                    ))}
                </Tabs>
            </BarePageLayout>
        </>
    )
}

export default TabbedPageLayout
