import { faDownload } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { isEmpty } from 'lodash'
import { DateTime, Interval } from 'luxon'
import React from 'react'
import { Button } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import { ConversionStatisticsResponse, LocalityResponse, OutagesResponse } from '@api/models'

import { gdynusaReadableInterval } from '@helpers/datetimeUtils'
import { GoogleEventName } from '@helpers/gtag'
import { formatIntervalToFilename } from '@helpers/intervals'
import { useLocalizeDateTime } from '@helpers/timezoneConfig'
import { ExportData, exportToExcel } from '@helpers/xlsxExporter'

import {
    prepareConversionData,
    prepareHourlyBouncedData,
    prepareWeeklyBouncedData,
} from '@components/ConversionDashboard/conversionDetailsPreprocessor'
import { useNotify } from '@components/notifications/NotificationsContext'

interface ExportButtonProps {
    conversionStatistics: ConversionStatisticsResponse
    now: DateTime
    outages: OutagesResponse
    selectedLocality: LocalityResponse
    pickedInterval: Interval
    hourlyConversions: ConversionStatisticsResponse
}

export const ConversionDetailsExportButton: React.FC<ExportButtonProps> = ({
    conversionStatistics,
    now,
    outages,
    selectedLocality,
    pickedInterval,
    hourlyConversions,
}) => {
    const { t } = useTranslation()
    const localize = useLocalizeDateTime()
    const notify = useNotify()

    const handleDownload = () => {
        const conversionData = prepareConversionData(conversionStatistics, now, outages)

        const exportData: ExportData = {
            [t('heading.localityConversionsStatistics', '{{locality}} Conversions Statistics', {
                locality: selectedLocality.name,
            })]: conversionData.map((it) => ({
                [t('others.date', 'Date')]: localize(it.interval.start).toLocaleString({
                    ...DateTime.DATETIME_SHORT,
                    weekday: 'short',
                }),
                [t('conversions.passersby', 'Passersby')]: it.passersby ?? 0,
                [t('conversions.visitors', 'Visitors')]: it.visitors ?? 0,
                [t('conversions.bounced', 'Bounced')]: it.bounced ?? 0,
                [t('conversions.browsingVisitors', 'Browsing visitors')]: it.browsingVisitors,
                [t('conversions.sales', 'Sales')]: it.sales ?? 0,
                [t('conversions.passerbyVisitorRate', 'Passerby > visitor (%)')]: it.passerbyToVisitors,
                [t('heading.bounceRate', 'Bounce rate')]: it.bounceRate,
                [t('conversions.visitorSaleRate', 'Visitor > sale (%)')]: it.visitorsToSales,
            })),
            [t('heading.bouncedDuringWeek', 'Bounced visitors during a week')]: prepareWeeklyBouncedData(
                hourlyConversions,
                localize
            ).map((it) => ({
                Weekday: it.name,
                [t('conversions.bouncedVisitors', 'Bounced visitors ')]: it.bounced,
            })),
            [t('heading.bouncedDuringDay', 'Bounced visitors during a day')]: prepareHourlyBouncedData(
                hourlyConversions,
                localize
            ).map((it) => ({
                Time: it.name,
                [t('conversions.bouncedVisitors', 'Bounced visitors ')]: it.bounced,
            })),
        }

        if (!isEmpty(exportData)) {
            exportToExcel(
                exportData,
                `${selectedLocality.name}-conversion-details-${formatIntervalToFilename(
                    gdynusaReadableInterval(pickedInterval.mapEndpoints(localize))
                )}`,
                GoogleEventName.DOWNLOAD_CONVERSIONS_LOCALITY_EXCEL
            )
        } else {
            notify({
                title: t('notification.error', 'Error'),
                content: t('notification.nothingToExport', 'Nothing to export'),
                variant: 'danger',
            })
        }
    }

    return (
        <Button variant="secondary" onClick={handleDownload}>
            <FontAwesomeIcon icon={faDownload} />
            {t('button.downloadExcel', 'Download Excel')}
        </Button>
    )
}
