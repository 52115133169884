import React from 'react'
import { Form } from 'react-bootstrap'

type Props = Omit<
    React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
    'onChange' | 'value'
> & {
    value?: string
    onChange?: (value: string) => void
    isInvalid?: boolean
    autofocus?: boolean
    plaintext?: boolean
    className?: string
}

const TextInput = ({
    value,
    onChange,
    onBlur,
    onFocus,
    onClick,
    isInvalid,
    autofocus,
    placeholder,
    tabIndex,
    readOnly,
    plaintext,
    disabled,
    className,
}: Props) => {
    const handleChange = (value: string) => {
        if (onChange) {
            onChange(value)

            return
        }
    }

    return (
        <Form.Control
            autoFocus={autofocus}
            className={className}
            disabled={disabled}
            isInvalid={isInvalid}
            placeholder={placeholder}
            plaintext={plaintext}
            readOnly={readOnly}
            tabIndex={tabIndex}
            type="text"
            value={value ?? ''}
            onBlur={onBlur}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e.target.value)}
            onClick={onClick}
            onFocus={onFocus}
        />
    )
}

export default TextInput
