/* tslint:disable */
/* eslint-disable */
/**
 * Vividi Dashboard API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DeviceResponse,
    DeviceResponseFromJSON,
    DeviceResponseFromJSONTyped,
    DeviceResponseToJSON,
} from './DeviceResponse';

/**
 * 
 * @export
 * @interface DeviceListResponse
 */
export interface DeviceListResponse {
    /**
     * 
     * @type {Array<DeviceResponse>}
     * @memberof DeviceListResponse
     */
    devices: Array<DeviceResponse>;
    /**
     * Device connection status indexed by their ids.
     * @type {{ [key: string]: boolean; }}
     * @memberof DeviceListResponse
     */
    connections?: { [key: string]: boolean; };
}

export function DeviceListResponseFromJSON(json: any): DeviceListResponse {
    return DeviceListResponseFromJSONTyped(json, false);
}

export function DeviceListResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeviceListResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'devices': ((json['devices'] as Array<any>).map(DeviceResponseFromJSON)),
        'connections': !exists(json, 'connections') ? undefined : json['connections'],
    };
}

export function DeviceListResponseToJSON(value?: DeviceListResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'devices': ((value.devices as Array<any>).map(DeviceResponseToJSON)),
        'connections': value.connections,
    };
}

