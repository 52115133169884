import { groupBy, orderBy, sortBy } from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import {
    DeviceResponse,
    DeviceStatusInformation,
    LocalityConfigurationStateModel,
    OrganizationResponse,
    SceneResponse,
} from '@api/models'

import { generateEditOrganizationPath, OrganizationEditPageTabs } from '@helpers/VividiURLs'
import { orderDevices } from '@helpers/orderFunctions'

import Heading from '@elements/Heading/Heading'

import DeviceList from './DeviceList'
import styles from './DeviceList.module.scss'

interface Props {
    devices: DeviceResponse[]
    scenes: { [deviceId: string]: SceneResponse }
    localities: { [id: number]: LocalityConfigurationStateModel }
    organizations: { [id: number]: OrganizationResponse }
    connections?: { [id: number]: boolean }
    status?: { [deviceId: string]: DeviceStatusInformation }
    showLocality: boolean
}

const orgNameById = (organizations: { [id: number]: OrganizationResponse }) => (organizationId: string) =>
    organizations[parseInt(organizationId, 10)]?.name

const DeviceListByOrganization = ({
    devices,
    localities,
    organizations,
    connections,
    scenes,
    status,
    showLocality,
}: Props) => {
    const { t } = useTranslation()

    const determineOrgName = orgNameById(organizations)

    const devicesByOrganizations = groupBy(
        sortBy(devices, [(device) => device.id]),
        (device) => device.organizationId || ''
    )

    return (
        <>
            {orderBy(Object.entries(devicesByOrganizations), ([organizationId]) =>
                determineOrgName(organizationId)
            ).map(([organizationId, devices]) => {
                const organizationName = determineOrgName(organizationId)

                return (
                    <React.Fragment key={organizationId}>
                        {organizationName !== undefined ? (
                            <Link
                                key={organizationName}
                                to={generateEditOrganizationPath(
                                    Number(organizationId),
                                    OrganizationEditPageTabs.devices
                                )}
                            >
                                <Heading className={styles.link} text={`${organizationName} (${devices.length})`} />
                            </Link>
                        ) : (
                            <Heading
                                className="text-muted"
                                text={
                                    organizationId === ''
                                        ? t('button.noOrganization', 'No organization')
                                        : t('others.unknownOrganization', 'Unknown organization')
                                }
                            />
                        )}
                        <DeviceList
                            connections={connections}
                            devices={orderDevices(devices)}
                            localities={localities}
                            organizationId={Number(organizationId)}
                            scenes={scenes}
                            showLocality={showLocality}
                            status={status}
                        />
                    </React.Fragment>
                )
            })}
        </>
    )
}

export default DeviceListByOrganization
