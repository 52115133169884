/* tslint:disable */
/* eslint-disable */
/**
 * Vividi Dashboard API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateUserRequestBody
 */
export interface CreateUserRequestBody {
    /**
     * 
     * @type {string}
     * @memberof CreateUserRequestBody
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserRequestBody
     */
    phoneNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserRequestBody
     */
    language?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserRequestBody
     */
    password?: string;
    /**
     * 
     * @type {number}
     * @memberof CreateUserRequestBody
     */
    organizationId?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUserRequestBody
     */
    sendInvitation?: boolean;
}

export function CreateUserRequestBodyFromJSON(json: any): CreateUserRequestBody {
    return CreateUserRequestBodyFromJSONTyped(json, false);
}

export function CreateUserRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateUserRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'email': json['email'],
        'phoneNumber': !exists(json, 'phone_number') ? undefined : json['phone_number'],
        'language': !exists(json, 'language') ? undefined : json['language'],
        'password': !exists(json, 'password') ? undefined : json['password'],
        'organizationId': !exists(json, 'organization_id') ? undefined : json['organization_id'],
        'sendInvitation': !exists(json, 'send_invitation') ? undefined : json['send_invitation'],
    };
}

export function CreateUserRequestBodyToJSON(value?: CreateUserRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'email': value.email,
        'phone_number': value.phoneNumber,
        'language': value.language,
        'password': value.password,
        'organization_id': value.organizationId,
        'send_invitation': value.sendInvitation,
    };
}

