/* tslint:disable */
/* eslint-disable */
/**
 * Vividi Dashboard API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    FloorplanMapping,
    FloorplanMappingFromJSON,
    FloorplanMappingFromJSONTyped,
    FloorplanMappingToJSON,
} from './FloorplanMapping';

/**
 * 
 * @export
 * @interface LocalityFloorplanMappingList
 */
export interface LocalityFloorplanMappingList {
    /**
     * 
     * @type {Array<FloorplanMapping>}
     * @memberof LocalityFloorplanMappingList
     */
    mappings: Array<FloorplanMapping>;
}

export function LocalityFloorplanMappingListFromJSON(json: any): LocalityFloorplanMappingList {
    return LocalityFloorplanMappingListFromJSONTyped(json, false);
}

export function LocalityFloorplanMappingListFromJSONTyped(json: any, ignoreDiscriminator: boolean): LocalityFloorplanMappingList {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'mappings': ((json['mappings'] as Array<any>).map(FloorplanMappingFromJSON)),
    };
}

export function LocalityFloorplanMappingListToJSON(value?: LocalityFloorplanMappingList | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'mappings': ((value.mappings as Array<any>).map(FloorplanMappingToJSON)),
    };
}

