import { uniqueId } from 'lodash'
import { DateTime, Interval } from 'luxon'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Popover, Image as BootstrapImage, OverlayTrigger } from 'react-bootstrap'
import { useQuery } from 'react-query'
import AutoSizer from 'react-virtualized-auto-sizer'

import { FloorplanHeatmapData } from '@api'

import { statisticsApi } from '@services'

import { mergeQueryResults } from '@helpers/api'
import { gdynusaReadableInterval } from '@helpers/datetimeUtils'
import { displayPreciseDuration, formatLargeNumber } from '@helpers/displayUtils'
import { formatInterval, Precision } from '@helpers/intervals'
import { NumberFormat, Size } from '@helpers/types'

import ErrorView from '@components/ErrorView'
import LegacyLoadingWrapper from '@components/LegacyLoadingWrapper'
import LoadingSpinner from '@components/LoadingSpinner'
import TooltipContent from '@components/plots/TooltipContent'
import { parseDate } from '@components/plots/common'

interface Props {
    heatmap: FloorplanHeatmapData
    dataHeatmap: FloorplanHeatmapData
    tooltipTitle: string
    tooltipText: string
    sectorValueType: NumberFormat
}

interface OverlayedCanvasProps {
    now: DateTime
    src: string
    srcHeatmapData: string
    tooltipTitle: string
    tooltipText: string
    sectorValueType: NumberFormat
}

const rgbToHex = (r: number, g: number, b: number) => {
    return ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)
}

const formatTooltipData = (data: number, sectorValueType: NumberFormat) => {
    if (sectorValueType === 'time') return displayPreciseDuration(data)

    return formatLargeNumber(data)
}

const OverlayedHeatmapCanvas: React.FC<OverlayedCanvasProps> = ({
    src,
    srcHeatmapData,
    tooltipTitle,
    tooltipText,
    sectorValueType,
    now,
}) => {
    const imageRef = useRef<HTMLImageElement | null>(null)
    const dataCanvasRef = useRef<HTMLCanvasElement | null>(null)
    const dataCanvasContextRef = useRef<CanvasRenderingContext2D | null>(null)

    const [sectorValues, setSectorValue] = useState<number | null>(null)
    const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 })

    const [scaledImageSize, setScaledImageSize] = useState<Size>()

    const onImageRefReceived = useCallback((node: HTMLImageElement | null) => {
        imageRef.current = node

        if (node !== null) {
            node.onload = () => {
                const { width, height } = node.getBoundingClientRect()
                setScaledImageSize({ width, height })
            }
        }
    }, [])

    const onDataCanvasRefReceived = useCallback((node: HTMLCanvasElement | null) => {
        dataCanvasRef.current = node
        dataCanvasContextRef.current = node?.getContext('2d') ?? null
    }, [])

    useEffect(() => {
        if (scaledImageSize === undefined || dataCanvasRef.current === null || srcHeatmapData === '') {
            return
        }

        dataCanvasRef.current.width = scaledImageSize.width
        dataCanvasRef.current.height = scaledImageSize.height
        const canvasCtx = dataCanvasRef.current.getContext('2d')

        const resizedHeatmap = new Image(scaledImageSize.width, scaledImageSize.height)
        resizedHeatmap.src = srcHeatmapData

        resizedHeatmap.onload = () => {
            canvasCtx?.drawImage(resizedHeatmap, 0, 0, scaledImageSize.width, scaledImageSize.height)
        }
    }, [scaledImageSize, srcHeatmapData])

    const extractAndStoreSectorValue = useCallback((e: React.MouseEvent<Element, MouseEvent>) => {
        const targetRect = imageRef.current?.getBoundingClientRect()

        const mouse = { x: 0, y: 0 }

        if (targetRect) {
            mouse.x = e.clientX - targetRect.left
            mouse.y = e.clientY - targetRect.top
            setMousePosition({ x: mouse.x, y: mouse.y })
        }

        const imageData = dataCanvasContextRef.current?.getImageData(mouse.x, mouse.y, 1, 1).data

        if (imageData) {
            setSectorValue(parseInt(rgbToHex(imageData[0], imageData[1], imageData[2]), 16))
        }
    }, [])

    const id = useMemo(uniqueId, [])

    return (
        <>
            <canvas ref={onDataCanvasRefReceived} style={{ display: 'none' }} />
            <AutoSizer disableHeight={true} style={{ textAlign: 'center', width: 'auto' }}>
                {({ width }) => (
                    <OverlayTrigger
                        overlay={(overlayProps) => {
                            const updatedTransform = (() => {
                                if (overlayProps.style.transform === undefined) {
                                    return undefined
                                }

                                const match = overlayProps.style.transform.match(
                                    /translate\(([-.0-9]+)px, ([-.0-9]+)px\)/
                                )

                                if (match === null) {
                                    return undefined
                                }

                                const x = parseInt(match[1], 10)
                                const y = parseInt(match[2], 10)

                                return `translate(${x + mousePosition.x - 10}px, ${y + mousePosition.y + 10}px)`
                            })()

                            return (
                                (sectorValues !== null && (
                                    <Popover
                                        {...overlayProps}
                                        id={id}
                                        style={{
                                            ...overlayProps.style,
                                            transform: updatedTransform,
                                            maxWidth: `${tooltipTitle.length}em`,
                                            whiteSpace: 'nowrap',
                                        }}
                                    >
                                        <TooltipContent
                                            active={true}
                                            formatter={(value) => [
                                                formatTooltipData(value, sectorValueType),
                                                tooltipText,
                                            ]}
                                            label={tooltipTitle}
                                            now={now}
                                            payload={[
                                                {
                                                    color: 'black',
                                                    name: '',
                                                    value: sectorValues,
                                                    payload: { hasData: true },
                                                },
                                            ]}
                                        />
                                    </Popover>
                                )) || <></>
                            )
                        }}
                        placement="left-start"
                        transition={false}
                    >
                        <BootstrapImage
                            ref={onImageRefReceived}
                            src={src}
                            style={{
                                maxHeight: width * 0.75,
                                cursor: 'pointer',
                                maxWidth: width,
                            }}
                            onMouseMove={(e) => extractAndStoreSectorValue(e)}
                        />
                    </OverlayTrigger>
                )}
            </AutoSizer>
        </>
    )
}

const FloorplanDataHeatmapDisplayBox: React.FC<Props> = ({
    heatmap,
    dataHeatmap,
    tooltipText,
    tooltipTitle,
    sectorValueType,
}) => {
    const heatmapContentCall = useQuery(
        statisticsApi.getFloorplanHeatmapContent.query({
            heatmapId: heatmap.heatmapId,
        })
    )

    const heatmapDataContentCall = useQuery(
        statisticsApi.getFloorplanHeatmapContent.query({
            heatmapId: dataHeatmap.heatmapId,
        })
    )

    const tooltipInterval = formatInterval(
        gdynusaReadableInterval(Interval.fromDateTimes(parseDate(heatmap.startingFrom), parseDate(heatmap.endingAt))),
        Precision.DAY,
        DateTime.DATE_SHORT
    )

    const [now] = useState(DateTime.utc())

    const src = useMemo(
        () => (heatmapContentCall.data !== undefined ? window.URL.createObjectURL(heatmapContentCall.data) : ''),
        [heatmapContentCall.data]
    )

    const srcData = useMemo(
        () =>
            heatmapDataContentCall.data !== undefined ? window.URL.createObjectURL(heatmapDataContentCall.data) : '',
        [heatmapDataContentCall.data]
    )

    const requests = mergeQueryResults(heatmapContentCall, heatmapDataContentCall)

    return (
        <LegacyLoadingWrapper
            errorComponent={<ErrorView message="Heatmap fetching failed" />}
            placeholder={<LoadingSpinner bare />}
            request={requests}
        >
            {() => (
                <div>
                    <OverlayedHeatmapCanvas
                        key={`${dataHeatmap.heatmapId}-${heatmap.heatmapId}`}
                        now={now}
                        sectorValueType={sectorValueType}
                        src={src}
                        srcHeatmapData={srcData}
                        tooltipText={tooltipText}
                        tooltipTitle={`${tooltipTitle} ${tooltipInterval}`}
                    />
                </div>
            )}
        </LegacyLoadingWrapper>
    )
}

export default FloorplanDataHeatmapDisplayBox
