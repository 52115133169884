import { head, noop } from 'lodash'
import { Interval } from 'luxon'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { useHistory } from 'react-router-dom'
import { useParams } from 'react-router-dom'

import { FloorplanHeatmapListResponse, FloorplanHeatmapType, LocalityNameModel } from '@api'

import { localityApi, statisticsApi } from '@services'

import { generateHeatmapDetailPath } from '@helpers/VividiURLs'
import { mergeQueryResults } from '@helpers/api'

import { NormalizedDashboardParams, Params } from '@components/DashboardParamsNormalizer'
import ErrorView from '@components/ErrorView'
import FloorplanHeatmapDetailLayout from '@components/FloorplanHeatmapDetail/FloorplanHeatmapDetailLayout'
import FloorplanHeatmapDetailParamsNormalizer, {
    DashboardApiCalls,
} from '@components/FloorplanHeatmapDetail/FloorplanHeatmapDetailParamsNormalizer'
import FloorplanHeatmapDetailsContent from '@components/FloorplanHeatmapDetail/FloorplanHeatmapDetailsContent'
import Helmet from '@components/Helmet'
import LegacyLoadingWrapper from '@components/LegacyLoadingWrapper'
import LoadingSpinner from '@components/LoadingSpinner'
import { parseDate } from '@components/plots/common'

interface HeatmapParams extends Params {
    floorplanId?: string
}

const getSelectedHeatmapData = (
    heatmaps: FloorplanHeatmapListResponse,
    heatmapType: FloorplanHeatmapType,
    selectedInterval: Interval
) =>
    head(
        heatmaps.heatmaps.filter(
            (it) =>
                Interval.fromDateTimes(parseDate(it.startingFrom), parseDate(it.endingAt)).equals(selectedInterval) &&
                it.heatmapType === heatmapType
        )
    )

const FloorplanHeatmapDetailsPage: React.FC<NormalizedDashboardParams & DashboardApiCalls> = ({
    locality,
    interval,
    visibleOrganizationsCall,
}) => {
    const { t } = useTranslation()

    const history = useHistory()
    const params = useParams<HeatmapParams>()

    const floorplanId =
        params.floorplanId === undefined
            ? Number(Object.keys(locality.floorplans)[0])
            : Number(params.floorplanId) ?? Number(Object.keys(locality.floorplans)[0])

    const heatmapCall = useQuery(
        statisticsApi.getFloorplanHeatmapList.query({
            floorplanId,
        })
    )

    const localityFeaturesCall = useQuery(localityApi.getLocalityConfigurationStates.query())

    const handleLocalityChange = (locality: LocalityNameModel) => {
        return history.push(generateHeatmapDetailPath(locality.id, interval.start, null, null))
    }

    const handleFloorplanSelect = (floorplanId: number) => {
        return history.push(generateHeatmapDetailPath(locality.id, interval.start, null, Number(floorplanId)))
    }

    const handleIntervalChange = (interval: Interval) => {
        return history.push(generateHeatmapDetailPath(locality.id, interval.start, null, Number(floorplanId)))
    }

    const documentTitle = `${locality ? locality.name : ''} ${t('title.floorplanHeatmaps', 'Heatmaps')} - ${
        locality.floorplans[floorplanId]
    }`

    return (
        <>
            <Helmet>
                <title>{documentTitle}</title>
            </Helmet>
            <LegacyLoadingWrapper
                errorComponent={
                    <FloorplanHeatmapDetailLayout
                        availableIntervals={[]}
                        floorplans={[]}
                        localities={[locality]}
                        organizations={[]}
                        selectedInterval={interval}
                        selectedLocality={locality}
                        onFloorplanSelect={noop}
                        onLocalityChange={handleLocalityChange}
                        onNavigation={handleIntervalChange}
                    >
                        <ErrorView message={t('others.problemFetchingData', 'There was a problem fetching data.')} />
                    </FloorplanHeatmapDetailLayout>
                }
                placeholder={
                    <FloorplanHeatmapDetailLayout
                        availableIntervals={[]}
                        floorplans={[]}
                        localities={[locality]}
                        organizations={[]}
                        selectedInterval={interval}
                        selectedLocality={locality}
                        onFloorplanSelect={noop}
                        onLocalityChange={handleLocalityChange}
                        onNavigation={handleIntervalChange}
                    >
                        <LoadingSpinner />
                    </FloorplanHeatmapDetailLayout>
                }
                request={mergeQueryResults(heatmapCall, visibleOrganizationsCall, localityFeaturesCall)}
            >
                {([heatmaps, organizations, localityFeatures]) => {
                    return (
                        <FloorplanHeatmapDetailLayout
                            key={locality.id}
                            availableIntervals={heatmaps.heatmaps.map((it) => [it.startingFrom, it.endingAt])}
                            floorplans={Object.entries(locality.floorplans)}
                            localities={localityFeatures.localities.filter((l) => l.isStaySafeFloorplanConfigured)} // TODO: filter for localities with floorplans
                            organizations={organizations.organizations}
                            selectedFloorplan={floorplanId}
                            selectedInterval={interval}
                            selectedLocality={locality}
                            onFloorplanSelect={handleFloorplanSelect}
                            onLocalityChange={handleLocalityChange}
                            onNavigation={handleIntervalChange}
                        >
                            <FloorplanHeatmapDetailsContent
                                dwellTimeDataHeatmap={getSelectedHeatmapData(
                                    heatmaps,
                                    FloorplanHeatmapType.DwellTimeData,
                                    interval
                                )}
                                dwellTimeHeatmap={getSelectedHeatmapData(
                                    heatmaps,
                                    FloorplanHeatmapType.DwellTime,
                                    interval
                                )}
                                floorplan={floorplanId}
                                selectedLocality={locality}
                                trajectoryHeatmap={getSelectedHeatmapData(
                                    heatmaps,
                                    FloorplanHeatmapType.Trajectories,
                                    interval
                                )}
                                utilityGridDataHeatmap={getSelectedHeatmapData(
                                    heatmaps,
                                    FloorplanHeatmapType.UtilityGridData,
                                    interval
                                )}
                                utilityGridHeatmap={getSelectedHeatmapData(
                                    heatmaps,
                                    FloorplanHeatmapType.UtilityGrid,
                                    interval
                                )}
                            />
                        </FloorplanHeatmapDetailLayout>
                    )
                }}
            </LegacyLoadingWrapper>
        </>
    )
}

const FloorplanHeatmapDetailNormalizer: React.FC = () => {
    const { t } = useTranslation()

    return (
        <FloorplanHeatmapDetailParamsNormalizer
            defaultLocalityPathGenerator={generateHeatmapDetailPath}
            errorComponent={({ interval }) => (
                <FloorplanHeatmapDetailLayout
                    availableIntervals={[]}
                    floorplans={[]}
                    localities={[]}
                    organizations={[]}
                    selectedInterval={interval}
                    onFloorplanSelect={noop}
                    onLocalityChange={noop}
                    onNavigation={noop}
                >
                    <ErrorView
                        message={t('others.fetchingListFailed', 'Fetching the list of localities failed')}
                        title={t('others.errorWhileFetchingData', 'Error while fetching data')}
                    />
                </FloorplanHeatmapDetailLayout>
            )}
            loadingComponent={({ interval }) => (
                <FloorplanHeatmapDetailLayout
                    availableIntervals={[]}
                    floorplans={[]}
                    localities={[]}
                    organizations={[]}
                    selectedInterval={interval}
                    onFloorplanSelect={noop}
                    onLocalityChange={noop}
                    onNavigation={noop}
                >
                    <LoadingSpinner />
                </FloorplanHeatmapDetailLayout>
            )}
            successComponent={(params, apiCalls) => <FloorplanHeatmapDetailsPage {...params} {...apiCalls} />}
        />
    )
}

export default FloorplanHeatmapDetailNormalizer
