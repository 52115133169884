import { FloorplanHeatmapType } from '@api'

import { OrganizationEditPageTabs } from '@helpers/VividiURLs'

export enum GoogleEventCategory {
    ORGANIZATION = 'organization',
    LOCALITY = 'locality',
    SCENE = 'scene',
    DEVICE = 'device',
    NAVIGATION = 'navigation',
    STATISTICS = 'statistics',
    REPORT = 'reports',
    USER = 'user',
    OTHERS = 'others',
}

export enum GoogleEventName {
    // USER
    LOGIN = 'login',
    LOGOUT = 'logout',
    CHANGE_PASSWORD = 'change-password',
    CHANGE_LANGUAGE = 'change-language',

    // ORGANIZATION
    CLICK_MODULES_TAB = 'click-modules-tab',
    CLICK_LOCALITIES_TAB = 'click-localities-tab',
    CLICK_SCENES_TAB = 'click-scenes-tab',
    CLICK_DEVICES_TAB = 'click-devices-tab',
    CLICK_USERS_TAB = 'click-users-tab',
    CLICK_DATA_PUBLISHERS_TAB = 'click-data-publishers-tab',

    // REPORTS
    DOWNLOAD_FOOTFALL_LOCALITY_EXCEL = 'download-footfall-locality-excel',
    DOWNLOAD_FOOTFALL_ORGANIZATION_EXCEL = 'download-footfall-organization-excel',
    DOWNLOAD_CONVERSIONS_LOCALITY_EXCEL = 'download-conversions-locality-excel',
    DOWNLOAD_CONVERSIONS_ORGANIZATION_EXCEL = 'download-conversions-organization-excel',
    DOWNLOAD_REALTIME_LOCALITY_EXCEL = 'download-realtime-locality-excel',
    DOWNLOAD_QUEUES_LOCALITY_EXCEL = 'download-queues-locality-excel',
    DOWNLOAD_QUEUES_ORGANIZATION_EXCEL = 'download-queues-organization-excel',
    DOWNLOAD_OCCUPANCY_LOCALITY_EXCEL = 'download-occupancy-locality-excel',
    DOWNLOAD_OCCUPANCY_ORGANIZATION_EXCEL = 'download-occupancy-organization-excel',
    DOWNLOAD_EMOTIONS_LOCALITY_EXCEL = 'download-emotions-locality-excel',
    DOWNLOAD_EMOTIONS_ORGANIZATION_EXCEL = 'download-emotions-organization-excel',

    // NAVIGATION
    MENU_CLICK_LOGO = 'menu-click-logo',
    MENU_CLICK_HOME = 'menu-click-home',

    MENU_CLICK_FOOTFALL = 'menu-click-footfall',
    MENU_CLICK_REALTIME = 'menu-click-realtime',
    MENU_CLICK_CONVERSIONS = 'menu-click-conversions',
    MENU_CLICK_QUEUES = 'menu-click-queues',
    MENU_CLICK_HEATMAPS = 'menu-click-heatmaps',
    MENU_CLICK_OCCUPANCY = 'menu-click-occupancy',
    MENU_CLICK_EMOTIONS = 'menu-click-emotions',

    MENU_CLICK_REPORTS = 'menu-click-reports',
    MENU_CLICK_ORGANIZATIONS = 'menu-click-organizations',
    MENU_CLICK_DEVICES = 'menu-click-devices',

    // STATISTICS
    FILTER_LOCALITY_CHECKBOX = 'filter-locality-checkbox',
    FILTER_LOCALITY_SELECT_ONLY = 'filter-locality-select-only',
    SELECT_DATE_INTERVAL = 'select-date-interval',
    COMPARE_WITH_PREVIOUS_INTERVAL_BUTTON = 'compare-with-previous-interval-button',
    COMPARE_WITH_PREVIOUS_INTERVAL_CHECKBOX = 'compare-with-previous-interval-checkbox',
    FOOTFALL_FILTER_BOUNDARIES_BUTTON_CLICK = 'footfall-filter-boundaries-button-click',

    DOWNLOAD_SPACE_UTILIZATION_HEATMAP = 'download-space-utilization-heatmap-image',
    DOWNLOAD_SPACE_UTILIZATION_GRID = 'download-space-utilization-grid-image',
    DOWNLOAD_DWELL_TIME_HEATMAP = 'download-dwell-time-heatmap-image',

    // OTHERS
    CLICK_KNOWLEDGE_BASE = 'click-knowledge-base',
    CLICK_REQUEST_DEMO = 'click-request-demo',
    CLICK_PASSWORD_RECOVERY = 'click-password-recovery',
}

export const heatmapEvent: { [key: string]: GoogleEventName } = {
    [FloorplanHeatmapType.Trajectories]: GoogleEventName.DOWNLOAD_SPACE_UTILIZATION_HEATMAP,
    [FloorplanHeatmapType.UtilityGrid]: GoogleEventName.DOWNLOAD_SPACE_UTILIZATION_GRID,
    [FloorplanHeatmapType.DwellTime]: GoogleEventName.DOWNLOAD_DWELL_TIME_HEATMAP,
}

export const organizationTabEvent: { [key: string]: GoogleEventName } = {
    [OrganizationEditPageTabs.modules]: GoogleEventName.CLICK_MODULES_TAB,
    [OrganizationEditPageTabs.localities]: GoogleEventName.CLICK_LOCALITIES_TAB,
    [OrganizationEditPageTabs.scenes]: GoogleEventName.CLICK_SCENES_TAB,
    [OrganizationEditPageTabs.devices]: GoogleEventName.CLICK_DEVICES_TAB,
    [OrganizationEditPageTabs.users]: GoogleEventName.CLICK_USERS_TAB,
    [OrganizationEditPageTabs.dataPublishers]: GoogleEventName.CLICK_DATA_PUBLISHERS_TAB,
}

export const installGTag = () => {
    if (process.env.REACT_APP_GA_CODE === undefined) {
        return
    }

    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.async = true
    script.src = `https://www.googletagmanager.com/gtag/js?id=${process.env.REACT_APP_GA_CODE}`

    document.head.appendChild(script)
    const win = window as any

    win.gtag = function gtag() {
        // eslint-disable-next-line prefer-rest-params
        win.dataLayer.push(arguments)
    }

    win.dataLayer = win.dataLayer || []

    win.gtag('js', new Date())
    win.gtag('config', process.env.REACT_APP_GA_CODE, { debug_mode: false })
}

export const trackEvent = (
    eventCategory: GoogleEventCategory,
    eventActionName: GoogleEventName,
    eventLabel?: string,
    userEmail?: string
) => {
    if (process.env.REACT_APP_GA_CODE === undefined) {
        return
    }

    const win = window as any

    if (userEmail !== undefined) {
        win.gtag?.('event', eventActionName, {
            event_category: eventCategory,
            event_label: eventLabel,
            user_id: userEmail,
        })
    } else {
        win.gtag?.('event', eventActionName, { event_category: eventCategory, event_label: eventLabel })
    }
}
