import classNames from 'classnames'
import { MouseEventHandler, MouseEvent } from 'react'

import { Point } from '@api'

import { EditableDetectionZone, SceneObjectType } from '@helpers/describeScene'
import { edges } from '@helpers/points'

import styles from './elements.module.scss'

const determineClassName = (type: EditableDetectionZone['type']): string => {
    switch (type) {
        case SceneObjectType.pos:
            return styles.posZone
        case SceneObjectType.queue:
            return styles.queueZone
        case SceneObjectType.seat:
            return styles.seatZone
        case SceneObjectType.stopZone:
            return styles.stopZone
        case SceneObjectType.otherZone:
            return styles.otherZone
    }
}

type Props = {
    points: Array<Point>
    isEditable: boolean
    isHighlighted: boolean
    type: EditableDetectionZone['type']
    onClick?: () => void
    onPointDragStart?: (pointIndex: number) => void
    onZoneDragStart?: MouseEventHandler
    onPointInsert?: (event: MouseEvent, index: number) => void
    onPointDelete?: (index: number) => void
    onMouseOver?: MouseEventHandler
    onMouseOut?: MouseEventHandler
}

export const Zone = (props: Props) => (
    <g
        className={classNames(styles.zone, determineClassName(props.type), {
            [styles.editable]: props.isEditable,
            [styles.highlighted]: props.isHighlighted,
        })}
        onClick={props.onClick}
        onMouseOut={props.onMouseOut}
        onMouseOver={props.onMouseOver}
    >
        <polygon
            className={classNames({
                [styles.zoneArea]: true,
            })}
            points={props.points.map((p) => `${p.x},${p.y}`).join(' ')}
            onMouseDown={(e) => {
                e.preventDefault()
                e.stopPropagation()

                props.onZoneDragStart?.(e)
            }}
        />
        {edges(props.points).map(([a, b], edgeIndex) => (
            <line
                key={`${a.x}+${b.x}`}
                className={classNames({
                    [styles.zoneEdge]: true,
                })}
                x1={a.x}
                x2={b.x}
                y1={a.y}
                y2={b.y}
                onMouseDown={(e) => {
                    e.preventDefault()
                    e.stopPropagation()

                    props.onPointInsert?.(e, edgeIndex + 1)
                }}
            />
        ))}
        {props.isEditable &&
            props.points.map((point, pointIndex) => (
                <circle
                    key={`${point.x}+${point.y}`}
                    className={classNames({
                        [styles.node]: true,
                    })}
                    cx={point.x}
                    cy={point.y}
                    r={10}
                    onMouseDown={(e) => {
                        e.preventDefault()
                        e.stopPropagation()

                        if (e.button === 2) {
                            if (props.points.length > 3) {
                                props.onPointDelete?.(pointIndex)
                            }
                        } else {
                            props.onPointDragStart?.(pointIndex)
                        }
                    }}
                />
            ))}
    </g>
)
