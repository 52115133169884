import React from 'react'

import { Role } from '@api/models'

import useProfile from '@helpers/profile'

interface Props {
    whitelist: Array<Role>
    children: React.ReactNode | React.ReactNode[]
}

const RoleChecker: React.FC<Props> = (props) => {
    const profileCall = useProfile()

    if (!profileCall.data || !props.whitelist.includes(profileCall.data.role)) {
        return null
    }

    return <>{React.Children.toArray(props.children)}</>
}

export default RoleChecker
