import { Duration } from 'luxon'
import React from 'react'
import { useState } from 'react'
import { UseQueryResult } from 'react-query'

function useAutoRefetchWithCountDown<T>(
    intervalInSeconds: Duration,
    request: UseQueryResult<T>
): {
    countDown: number
    request: UseQueryResult<T>
    manualRefetch: () => void
} {
    const seconds = intervalInSeconds.as('seconds')

    if (seconds < 1) {
        throw new Error('Interval must be at least 1 second long')
    }

    const [countDown, setCountDown] = useState(seconds)

    React.useEffect(() => {
        const timeLeft = setTimeout(() => {
            switch (countDown) {
                case 0:
                    setCountDown(seconds)
                    break
                default:
                    setCountDown(countDown - 1)
            }
        }, 1000)

        return () => clearTimeout(timeLeft)
    }, [countDown])

    React.useEffect(() => {
        if (countDown === 0) {
            request.refetch()
        }
    }, [countDown])

    const manualRefetch = () => {
        request.refetch()
        setCountDown(seconds)
    }

    return {
        countDown,
        request,
        manualRefetch,
    }
}

export default useAutoRefetchWithCountDown
