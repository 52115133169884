import * as React from 'react'
import { SliderItem, GetHandleProps } from 'react-compound-slider'

import styles from './OpeningHoursPicker.module.scss'

interface HandleProps {
    domain: number[]
    handle: SliderItem
    getHandleProps: GetHandleProps
}

const Handle: React.FC<HandleProps> = ({ domain: [min, max], handle: { id, value, percent }, getHandleProps }) => (
    <div
        aria-valuemax={max}
        aria-valuemin={min}
        aria-valuenow={value}
        className={styles.handle}
        role="slider"
        style={{
            left: `${percent}%`,
        }}
        {...getHandleProps(id)}
    />
)

export default Handle
