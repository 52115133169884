import { faBookOpenCover } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { GoogleEventCategory, GoogleEventName, trackEvent } from '@helpers/gtag'
import useProfile from '@helpers/profile'

import TooltipWrapper from '@components/TooltipWrapper'

const KNOWLEDGE_BASE_PATH = 'https://vividi.io/2021/06/10/knowledge-base/'

const KnowledgeBaseLink: React.FC = () => {
    const { t } = useTranslation()
    const profile = useProfile()

    return (
        <a
            href={KNOWLEDGE_BASE_PATH}
            target="blank"
            onClick={() =>
                trackEvent(GoogleEventCategory.OTHERS, GoogleEventName.CLICK_KNOWLEDGE_BASE, '', profile.data?.email)
            }
        >
            <TooltipWrapper
                tooltipPosition="bottom"
                tooltipText={t('tooltip.openKnowledgeBase', 'Open knowledge base')}
            >
                <FontAwesomeIcon icon={faBookOpenCover} />
            </TooltipWrapper>
        </a>
    )
}

export default KnowledgeBaseLink
