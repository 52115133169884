import { faPlus, faVideo, faStreetView } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import { isEmpty, orderBy } from 'lodash'
import React, { useEffect, useMemo, useState } from 'react'
import { Button } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useMutation, UseQueryResult } from 'react-query'
import { Link, useHistory, useLocation, useParams } from 'react-router-dom'

import { DeviceResponse, DockerImageResponse, OrganizationResponse, ProfileResponse, Role, SceneResponse } from '@api'

import { deviceApi, organizationApi } from '@services'

import { generateOrganizationEditDeviceCreatePath, VIVIDI_APP } from '@helpers/VividiURLs'
import { useApiCallCleaner } from '@helpers/api'
import { isPhoneNumberWithoutCountryCodeValid } from '@helpers/validations'

import { useDetectMobile } from '@hooks/useDetectDevice'

import Box from '@elements/Box/Box'

import AsyncButton from '@components/AsyncButton'
import DeviceIcon from '@components/DeviceIcon'
import AccordionLayout from '@components/Layouts/AccordionLayout'
import SideNavigationListLayout from '@components/Layouts/SideNavigationLayout'
import VerticalListGroup, { VerticalListEntry } from '@components/NavigationLists/VerticalListGroup'
import TooltipWrapper from '@components/TooltipWrapper'
import { PhoneNumberErrors } from '@components/UserForm/UserEditForm'
import { useNotify } from '@components/notifications/NotificationsContext'

import AddDeviceForm from './AddDeviceForm'
import AssignDevicesForm from './AssignDevicesForm'
import DevicesEditForm from './DevicesEditForm'
import styles from './DevicesTab.module.scss'

const NEW_ITEM_ID = 'new'

const defaultPhoneNumberErrors: PhoneNumberErrors = {
    phoneNumber: {
        isInvalid: true,
        isTouched: false,
    },
}

export interface DeviceConfigurationData {
    ssid: string
    password: string
}

const defaultNetworkConfig = {
    ssid: '',
    password: '',
}

const DevicesTab: React.FC<{
    assignableDevices: Array<DeviceResponse>
    organizationDevices: Array<DeviceResponse>
    organizationScenes: Array<SceneResponse>
    selectedOrganization: OrganizationResponse
    profile: UseQueryResult<ProfileResponse>
    isNewDevice: boolean
    isTabActive: boolean
    determineEditDevicePath: (sceneId?: number) => string | undefined
    determineCreateDevicePath: () => string
    allDevicesWithNoScene: Array<DeviceResponse>
    visibleOrganizations: Array<OrganizationResponse>
}> = ({
    assignableDevices,
    organizationDevices,
    organizationScenes,
    selectedOrganization,
    profile,
    isNewDevice,
    isTabActive,
    determineEditDevicePath,
    allDevicesWithNoScene,
    visibleOrganizations,
}) => {
    const mobile = useDetectMobile()
    const { t } = useTranslation()
    const location = useLocation()
    const params = useParams<{ deviceId?: string }>()
    const history = useHistory()
    const clean = useApiCallCleaner()
    const notify = useNotify()
    const search = useMemo(() => new URLSearchParams(location.search).get('search') ?? '', [location.search])
    const filteredDevices = organizationDevices.filter(({ id }) => id.toString().includes(search))
    const isAdmin = profile.data?.role === Role.Administrator

    const {
        mutate: addDevice,
        status: addDeviceState,
        reset,
    } = useMutation(deviceApi.updateDevice, {
        onSuccess: (data) => {
            clean(organizationApi)
            clean(deviceApi)
            handleDeviceSelection(data)
            reset()
        },
        onError: () => {
            notify({
                title: t('notification.error', 'Error'),
                variant: 'Error',
                timeoutSeconds: 5,
                content: t('notification.addingDeviceFailed', 'Adding device failed'),
            })
        },
    })

    const { mutate: updateDevice } = useMutation(deviceApi.updateDevice, {
        onSuccess: () => {
            clean(organizationApi)
            clean(deviceApi)
        },
        onError: () => {
            notify({
                title: t('notification.error', 'Error'),
                variant: 'Error',
                timeoutSeconds: 5,
                content: t('notification.deviceUpdateFailed', 'Device update failed'),
            })
        },
    })

    const deviceId = params.deviceId !== undefined ? parseInt(params.deviceId, 10) : undefined
    const selectedDevice = organizationDevices.find(({ id }) => id === deviceId)

    const [selectedPickerDevice, setSelectedPickerDevice] = useState<DeviceResponse | undefined>(undefined)
    const [devicePhoneNumber, setDevicePhoneNumber] = useState<string | undefined>(undefined)
    const [errors, setErrors] = useState<PhoneNumberErrors>({
        phoneNumber: {
            isTouched: false,
            isInvalid: devicePhoneNumber !== undefined && !isPhoneNumberWithoutCountryCodeValid(devicePhoneNumber),
        },
    })

    const [note, setDeviceNote] = useState<string | undefined>(selectedDevice?.note)
    const [color, setDeviceColor] = useState<string | undefined>(selectedDevice?.color)
    const [organizationId, setDeviceOrganizationId] = useState(
        selectedDevice?.organizationId ?? selectedOrganization.id
    )
    const [config, setConfig] = useState<DeviceConfigurationData>(defaultNetworkConfig)
    const [image, setImage] = useState<string | undefined>(selectedDevice?.vividiImageName)
    const [isEdited, setEdited] = useState(false)

    const handleAddDevice = () => {
        if (selectedPickerDevice === undefined) {
            return
        }

        addDevice({
            deviceId: selectedPickerDevice.id,
            body: { organizationId: selectedOrganization.id },
        })
    }

    const handleSearch = (search: string) => {
        history.replace({ ...location, search: search ? `search=${search}` : undefined })
    }

    const handleNetworkConfigChange = (config: DeviceConfigurationData) => {
        setConfig(config)
    }

    const handleNoteUpdate = (note: string) => {
        setDeviceNote(note)
        setEdited(true)
    }

    const handleColorUpdate = () => {
        setDeviceColor((prev) => (prev === 'white' ? 'black' : 'white'))
        setEdited(true)
    }

    const handleOrganizationUpdate = (organization: OrganizationResponse) => {
        setDeviceOrganizationId(organization.id)
        setEdited(true)
    }

    const handleDeviceImageSelect = (image: DockerImageResponse) => {
        setImage(`${image.name}:${image.tag}`)
        setEdited(true)
    }

    const onCancel = () => {
        setDeviceNote(selectedDevice?.note ?? '')
        setDeviceColor(selectedDevice?.color)
        setDevicePhoneNumber(selectedDevice?.simPhoneNumber ?? '')
        setDeviceOrganizationId(selectedDevice?.organizationId ?? selectedOrganization.id)
        setConfig(defaultNetworkConfig)
        setImage(selectedDevice?.vividiImageName)
        setErrors(defaultPhoneNumberErrors)
        setEdited(false)
    }

    const handlePhoneNumberUpdate = (newPhoneNumber: string) => {
        setDevicePhoneNumber(!isEmpty(newPhoneNumber) ? newPhoneNumber : undefined)

        setErrors((prev) => ({
            ...prev,
            phoneNumber: {
                isTouched: true,
                isInvalid: !isEmpty(newPhoneNumber) && !isPhoneNumberWithoutCountryCodeValid(newPhoneNumber),
            },
        }))

        setEdited(true)
    }

    const handleSaveChanges = () => {
        if (selectedDevice === undefined) {
            return
        }

        updateDevice({
            deviceId: selectedDevice.id,
            body: {
                note,
                color,
                organizationId,
                simPhoneNumber: devicePhoneNumber,
                ...(image ? { vividiImageName: image } : undefined),
            },
        })
    }

    const handleDeviceSelection = (device?: DeviceResponse, forceReplace = false) => {
        const devicePath = determineEditDevicePath(device?.id)

        const url = search && devicePath ? `${devicePath}?search=${search}` : devicePath ?? VIVIDI_APP

        if (selectedDevice || forceReplace) {
            history.replace(url)
        } else {
            history.push(url)
        }
    }

    const addDeviceButton = (
        <>
            {!isNewDevice && isAdmin && (
                <Button
                    as={Link}
                    to={generateOrganizationEditDeviceCreatePath(selectedOrganization.id)}
                    variant="secondary"
                >
                    <FontAwesomeIcon icon={faPlus} />
                    {t('others.addDevices', 'Add devices')}
                </Button>
            )}
        </>
    )
    const saveButton = (
        <AsyncButton
            disabled={errors.phoneNumber.isInvalid && errors.phoneNumber.isTouched}
            loadingText={t('button.savingChanges', 'Saving changes...')}
            status="idle"
            text={t('button.saveChanges', 'Save changes')}
            type="submit"
            variant="primary"
            onClick={(e) => {
                e.preventDefault()
                handleSaveChanges()
            }}
        />
    )

    const cancelButton = (
        <Button variant="secondary" onClick={onCancel}>
            {t('button.cancel', 'Cancel')}
        </Button>
    )

    const deviceColorButton = (
        <>
            {isAdmin ? (
                <TooltipWrapper
                    tooltipPosition="top-start"
                    tooltipText={t('devices.deviceColor', 'Change device color')}
                >
                    <div className={styles.clickable} onClick={handleColorUpdate}>
                        <DeviceIcon color={color} />
                        {selectedDevice?.id}
                    </div>
                </TooltipWrapper>
            ) : (
                <div>
                    <DeviceIcon color={color} />
                    {selectedDevice?.id}
                </div>
            )}
        </>
    )

    const form = (
        <DevicesEditForm
            key={`form-${selectedDevice?.id}`}
            actionButtons={
                mobile && isEdited ? (
                    <>
                        {saveButton}
                        {cancelButton}
                    </>
                ) : undefined
            }
            deviceColorButton={deviceColorButton}
            errors={errors}
            isAdmin={isAdmin}
            networkConfig={config}
            note={note}
            organizationScenes={organizationScenes}
            phoneNumber={devicePhoneNumber}
            phoneNumberLabel={t('form.simNumber', 'SIM Phone number')}
            selectedDevice={selectedDevice}
            selectedOrganizationId={organizationId}
            vividiImage={image}
            onDeviceImageUpdate={handleDeviceImageSelect}
            onNetworkConfigUpdate={handleNetworkConfigChange}
            onNoteUpdate={handleNoteUpdate}
            onOrganizationUpdate={handleOrganizationUpdate}
            onPhoneNumberUpdate={handlePhoneNumberUpdate}
        />
    )

    const newDeviceFormTemporary = (
        <AddDeviceForm
            assignableDevices={assignableDevices}
            selectedPickerDevice={selectedPickerDevice}
            submissionStatus={addDeviceState}
            onAddDevice={handleAddDevice}
            onDeviceSelect={setSelectedPickerDevice}
        />
    )

    const unfilteredNavigationEntries: Array<VerticalListEntry> =
        organizationDevices.map(({ id, color }, index) => ({
            icon: faVideo,
            id: String(id),
            displayText: `#${id}`,
            className: classNames(
                styles.deviceIconContainer,
                { [styles.white]: color === 'white' },
                { [styles.black]: color === 'black' || !color },
                { [styles.mobile]: mobile }
            ),
            ...(organizationScenes.find(({ deviceId }) => deviceId === id)
                ? { indicators: [<FontAwesomeIcon key={index} icon={faStreetView} />] }
                : {}),
        })) ?? []

    const navigationEntries: Array<VerticalListEntry> =
        filteredDevices.map(({ id, color }, index) => ({
            icon: faVideo,
            id: String(id),
            displayText: `#${id}`,
            className: classNames(
                styles.deviceIconContainer,
                { [styles.white]: color === 'white' },
                { [styles.black]: color === 'black' || !color },
                { [styles.mobile]: mobile }
            ),
            ...(organizationScenes.find(({ deviceId }) => deviceId === id)
                ? { indicators: [<FontAwesomeIcon key={index} icon={faStreetView} />] }
                : {}),
        })) ?? []

    organizationScenes.find(({ deviceId: id }) => id === deviceId)

    const sortedNavigationEntries = isNewDevice
        ? [
              {
                  id: NEW_ITEM_ID,
                  displayText: t('others.newDevice', 'New device'),
              },
              ...orderBy(navigationEntries, 'displayText'),
          ]
        : orderBy(navigationEntries, 'displayText')

    const desktopNavigation = (
        <VerticalListGroup
            placeholder={t('form.deviceId', 'Device id')}
            search={search}
            selectableEntries={sortedNavigationEntries}
            selectedEntry={isNewDevice ? NEW_ITEM_ID : selectedDevice?.id.toString()}
            unfilteredSelectableEntries={unfilteredNavigationEntries}
            scrollToSelected
            onSearch={handleSearch}
            onSelectionChange={(id) =>
                handleDeviceSelection(filteredDevices.find((device) => device.id === Number(id)))
            }
        />
    )

    const newDeviceForm: JSX.Element = isNewDevice ? newDeviceFormTemporary : form

    useEffect(() => {
        if (isTabActive && !mobile && selectedDevice === undefined && !isEmpty(organizationDevices) && !isNewDevice) {
            handleDeviceSelection(
                organizationDevices.find(({ id }) => Number(sortedNavigationEntries[0].id) === id),
                true
            )
        }
    }, [selectedDevice, isNewDevice, mobile, isTabActive, organizationDevices, sortedNavigationEntries])

    useEffect(() => {
        setDeviceNote(selectedDevice?.note ?? '')
        setDeviceColor(selectedDevice?.color)
        setDevicePhoneNumber(selectedDevice?.simPhoneNumber ?? '')
        setImage(selectedDevice?.vividiImageName)
        setEdited(false)
    }, [selectedDevice])

    const placeholder = (
        <div className={styles.noDevicesInOrganizationContainer}>
            <span className={styles.noDevices}>
                {t('others.noDevicesInOrganization', 'No devices added to organization')}
            </span>
        </div>
    )

    const organizationWithDevices = isEmpty(organizationDevices)

    const dashOrganizationDevices = allDevicesWithNoScene.filter(
        ({ organizationId }) =>
            !organizationId ||
            visibleOrganizations
                .filter(({ name }) => name.startsWith('-') || name === '')
                .some(({ id }) => id === organizationId)
    )

    return (
        <Box paddingSize="lg">
            {mobile ? (
                isNewDevice ? (
                    newDeviceFormTemporary
                ) : (
                    <AccordionLayout
                        heading={t('table.devices', 'Devices')!}
                        headingButtons={addDeviceButton}
                        items={navigationEntries}
                        placeholder={placeholder}
                        selectedItem={selectedDevice?.id.toString()}
                        selectedItemContent={newDeviceForm}
                        onSelect={(id) =>
                            handleDeviceSelection(filteredDevices.find((device) => device.id === Number(id)))
                        }
                    />
                )
            ) : isNewDevice ? (
                <AssignDevicesForm
                    devices={dashOrganizationDevices}
                    organizationDevices={organizationDevices}
                    selectedOrganization={selectedOrganization}
                    visibleOrganizations={visibleOrganizations}
                />
            ) : (
                <SideNavigationListLayout
                    content={!organizationWithDevices ? form : <></>}
                    contentHeading={{
                        heading: organizationWithDevices ? placeholder : '',
                        headingButtons: isEdited ? (
                            <div className={styles.controlButtonRow}>
                                {saveButton}
                                {cancelButton}
                            </div>
                        ) : undefined,
                    }}
                    listHeading={{
                        heading: t('table.devices', 'Devices'),
                        headingButtons: addDeviceButton,
                    }}
                    navigation={desktopNavigation}
                />
            )}
        </Box>
    )
}

export default DevicesTab
