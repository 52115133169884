import { useState, useMemo, useEffect } from 'react'
import { UseQueryResult } from 'react-query'

import { getImageSize } from '@helpers/images'
import { Size } from '@helpers/types'

export type EditorDimensions = {
    width: number
    height?: number
    scale: number
}

export type EditorDataProps = {
    backgroundCall: UseQueryResult<Blob>
    disableContentScaling: boolean
    width: number
}

export type EditorData = {
    backgroundCall: UseQueryResult<Blob>
    imageUrl?: string
    imageDimensions?: Size
    editorDimensions: EditorDimensions
}

const useSvgEditorData = ({ backgroundCall, width, disableContentScaling }: EditorDataProps): EditorData => {
    const [imageDimensions, setImageDimensions] = useState<Size>()
    const [editorDimensions, setEditorDimensions] = useState<EditorDimensions>({
        width,
        scale: 1,
    })

    const imageUrl = useMemo(() => {
        if (backgroundCall.data === undefined) {
            return undefined
        }

        return URL.createObjectURL(backgroundCall.data)
    }, [backgroundCall.data])

    useEffect(() => {
        if (!imageUrl) {
            return
        }

        getImageSize(imageUrl).then((size) => {
            setImageDimensions(size)

            const scale = width / size.width

            if (disableContentScaling || size.width < width) {
                setEditorDimensions({
                    width: size.width,
                    height: size.height,
                    scale: 1,
                })
            } else {
                setEditorDimensions({
                    width,
                    height: size.height * scale,
                    scale,
                })
            }
        })
    }, [imageUrl, width, disableContentScaling, setEditorDimensions])

    return {
        backgroundCall,
        imageDimensions,
        editorDimensions,
        imageUrl,
    }
}

export default useSvgEditorData
