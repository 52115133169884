import { faBusinessTime } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Button } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { MutationStatus } from 'react-query'

import { LocalityModel, LocalityResponse } from '@api'

import ControlledModal from '@components/ControlledModal'

import LocalityOpeningHoursForm from './LocalityOpeningHoursForm'
import styles from './LocalityOpeningHoursModal.module.scss'

const LocalityOpeningHoursModal: React.FC<{
    isOpeningHoursModalOpen: boolean
    isDescribeSceneTabModalDisabled?: boolean
    locality: LocalityResponse
    onOpeningHoursModalOpen: () => void
    onOpeningHoursModalClose: () => void
    onOpeningHoursUpdate: (data: LocalityModel) => void
    openingHoursUpdateStatus: MutationStatus
}> = ({
    isOpeningHoursModalOpen,
    onOpeningHoursModalOpen,
    onOpeningHoursModalClose,
    onOpeningHoursUpdate,
    locality,
    openingHoursUpdateStatus,
}) => {
    const { t } = useTranslation()

    return (
        <ControlledModal
            className={styles.modal}
            control={
                <Button variant="secondary" onClick={onOpeningHoursModalOpen}>
                    <FontAwesomeIcon icon={faBusinessTime} />
                    {t('form.setOpeningHours', 'Set hours')}
                </Button>
            }
            modalContainerClassName={styles.modalContainer}
            show={isOpeningHoursModalOpen}
            centered
            onHide={onOpeningHoursModalClose}
        >
            <LocalityOpeningHoursForm
                locality={locality}
                submissionStatus={openingHoursUpdateStatus}
                onCloseModal={onOpeningHoursModalClose}
                onSubmit={onOpeningHoursUpdate}
            />
        </ControlledModal>
    )
}

export default LocalityOpeningHoursModal
