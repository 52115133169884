import produce from 'immer'
import { fromPairs } from 'lodash'
import React, { useState } from 'react'
import { Form, Row, Col } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { MutationStatus } from 'react-query'

import { OrganizationResponse, Role, UserResponse, UserModel, LocalityNameModel } from '@api/models'

import { ErrorEntry } from '@helpers/types'
import { isPhoneNumberValid } from '@helpers/validations'

import AsyncButton from '@components/AsyncButton'
import TextInput from '@components/GenericInputs/TextInput'
import MultiSelectBox from '@components/MultiSelectBox'

import OrganizationPicker from './OrganizationPicker'
import RolePicker from './RolePicker'

interface Props {
    onSubmit: (userData: {
        user: UserModel
        role: Role
        organizationId?: number
        localities: LocalityNameModel[]
    }) => void
    initialData: UserResponse
    submissionStatus: MutationStatus
    organizations: OrganizationResponse[]
    localities: LocalityNameModel[]
    selectedLocalities: LocalityNameModel[]
}

export interface PhoneNumberErrors {
    phoneNumber: ErrorEntry
}

const UserEditForm = (props: Props) => {
    const { t } = useTranslation()

    const [phoneNumber, setPhoneNumber] = useState(props.initialData.phoneNumber)
    const [role, setRole] = useState(props.initialData.role)
    const [selectedOrganization, setSelectedOrganization] = useState(
        props.organizations.find((organization) => organization.id === props.initialData.organizationId)
    )
    const [selectedLocalities, setSelectedLocalities] = useState<Array<LocalityNameModel>>(props.selectedLocalities)

    const [errors, setErrors] = useState<PhoneNumberErrors>({
        phoneNumber: {
            isTouched: false,
            isInvalid:
                props.initialData.phoneNumber !== undefined && !isPhoneNumberValid(props.initialData.phoneNumber),
        },
    })

    const handleOrganizationSelect = (organizationId?: number) => {
        const organization = props.organizations.find((organization) => organization.id === organizationId)
        setSelectedOrganization(organization)
        const localities = props.localities.filter((locality) => locality.organizationId === organizationId)
        setSelectedLocalities(localities)
    }

    const handleLocalitiesSelect = (localityIds: Array<string>) => {
        const selectedLocalities = props.localities.filter((locality) => localityIds.includes(locality.id.toString()))

        setSelectedLocalities(selectedLocalities)
    }

    const localityChoices = fromPairs(
        props.localities
            .filter((locality) => locality.organizationId === selectedOrganization?.id)
            .map((l) => [l.id.toString(), l.name])
    )

    const onSubmit: React.FormEventHandler = (event) => {
        event.preventDefault()

        if (Object.values(errors).some((entry) => entry.isInvalid)) {
            setErrors((prev) =>
                produce(prev, (draft) => {
                    Object.values(draft).forEach((it) => (it.isTouched = true))
                })
            )

            return
        }

        props.onSubmit({
            organizationId: selectedOrganization?.id,
            role,
            user: {
                email: props.initialData.email,
                phoneNumber,
            },
            localities: selectedLocalities,
        })
    }

    const isLocalityPickerDisabled = role === Role.OrganizationOwner || role === Role.OrganizationAdministrator

    return (
        <Form onSubmit={onSubmit}>
            <Form.Group as={Row}>
                <Form.Label column={true} sm={2}>
                    {t('form.email', 'E-mail')}
                </Form.Label>
                <Col sm={10}>
                    <TextInput plaintext={true} readOnly={true} value={props.initialData.email} />
                </Col>
            </Form.Group>
            <Form.Group as={Row}>
                <Form.Label column={true} sm={2}>
                    {t('form.phoneNumber', 'Phone number')}
                </Form.Label>
                <Col sm={10}>
                    <TextInput
                        isInvalid={errors.phoneNumber.isTouched && errors.phoneNumber.isInvalid}
                        value={phoneNumber ?? ''}
                        onChange={(newPhoneNumber) => {
                            setPhoneNumber(newPhoneNumber.length > 0 ? newPhoneNumber : undefined)
                            setErrors((prev) => ({
                                ...prev,
                                phoneNumber: {
                                    isTouched: true,
                                    isInvalid: newPhoneNumber.length > 0 && !isPhoneNumberValid(newPhoneNumber),
                                },
                            }))
                        }}
                    />
                </Col>
            </Form.Group>
            <Form.Group as={Row}>
                <Form.Label column={true} sm={2}>
                    {t('form.organization', 'Organization')}
                </Form.Label>
                <Col sm={10}>
                    <OrganizationPicker
                        organizationId={props.initialData.organizationId!}
                        organizations={props.organizations}
                        readOnly={true}
                        onChange={handleOrganizationSelect}
                    />
                </Col>
            </Form.Group>
            <Form.Group as={Row}>
                <Form.Label column={true} sm={2}>
                    {t('form.role', 'Role')}
                </Form.Label>
                <Col sm={10}>
                    <RolePicker role={role} onChange={setRole} />
                </Col>
            </Form.Group>
            <Form.Group as={Row}>
                <Form.Label column={true} sm={2}>
                    {t('table.localities', 'Localities')}
                </Form.Label>
                <Col sm={10}>
                    <MultiSelectBox
                        choices={localityChoices}
                        disabled={Object.values(localityChoices).length === 0 || isLocalityPickerDisabled}
                        selection={selectedLocalities.map((l) => l.id.toString())}
                        onSelect={handleLocalitiesSelect}
                    />
                </Col>
            </Form.Group>
            <Form.Group as={Row}>
                <Col sm={{ span: 10 }}>
                    <AsyncButton
                        loadingText={t('button.saving', 'Saving...')}
                        status={props.submissionStatus}
                        text={t('button.save', 'Save')}
                        type="submit"
                        variant="primary"
                    />
                </Col>
            </Form.Group>
        </Form>
    )
}

export default UserEditForm
