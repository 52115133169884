import { clamp } from 'lodash'
import { DateTime, Duration } from 'luxon'
import React, { useState, useEffect } from 'react'

import { CrowdSizeResponse, CrowdSizeResponseFromJSON, FloorplanCrowdSizeResponse } from '@api/models'
import { PersonPosition } from '@api/models/PersonPosition'

export interface OccupancyData {
    maximumCapacity?: number
    currentCrowdSize?: number
    freeCapacity?: number
    occupancyPercentage?: number
    isSafeToEnter: boolean
    waitTime?: Duration
    observedAt?: DateTime
    isOffline: boolean
    everReceivedData: boolean
    everHadError: boolean
    locations?: { [key: string]: Array<PersonPosition> }
}

interface Props {
    localityId: number
    connectionCheckInterval?: Duration
    dataRenderer: React.ComponentType<{ data: OccupancyData }>
    parseFunction?: (json: any) => CrowdSizeResponse | FloorplanCrowdSizeResponse
    endpointName?: 'crowd_size' | 'floorplan_crowd_size'
    subscriptionType: string
    updateInterval?: number
}

const LocalityOccupancyConsumer: React.FC<Props> = (props) => {
    const updateInterval = props.updateInterval ?? 1
    const parseFunction = props.parseFunction ?? CrowdSizeResponseFromJSON
    const endpointName = props.endpointName ?? 'crowd_size'

    const [data, setData] = useState<OccupancyData>({
        isSafeToEnter: false,
        isOffline: true,
        everReceivedData: false,
        everHadError: false,
    })
    const [connectionStatus, setConnectionStatus] = useState({
        reconnectionCounter: 0,
        lastUpdate: DateTime.utc(),
        lastConnection: DateTime.utc(),
    })

    // Subscribe to WebSocket
    useEffect(() => {
        setConnectionStatus((prev) => ({
            ...prev,
            lastConnection: DateTime.utc(),
        }))

        const ws = new WebSocket(
            `${process.env.REACT_APP_WS_URL}/api/statistics/by-locality/${props.localityId}/${endpointName}/ws?interval=${updateInterval}&subscription_type=${props.subscriptionType}`
        )

        ws.onmessage = (evt) => {
            const crowdSize = parseFunction(JSON.parse(evt.data))

            setData((prev) => {
                const now = DateTime.local()

                if (!prev.observedAt || now >= prev.observedAt) {
                    const update = {
                        ...prev,
                        everReceivedData: true,
                        isOffline: false,
                        maximumCapacity: crowdSize.maximumCapacity,
                        currentCrowdSize: crowdSize.currentCrowdSize,
                        freeCapacity: clamp(
                            crowdSize.maximumCapacity - crowdSize.currentCrowdSize,
                            0,
                            crowdSize.maximumCapacity
                        ),
                        occupancyPercentage:
                            crowdSize.maximumCapacity > 0
                                ? clamp(
                                      Math.round((100 * crowdSize.currentCrowdSize) / crowdSize.maximumCapacity),
                                      0,
                                      100
                                  )
                                : 100,
                        isSafeToEnter: crowdSize.currentCrowdSize < crowdSize.maximumCapacity,
                        observedAt: now,
                        waitTime: Duration.fromObject({
                            seconds: Math.max(0, crowdSize.estimatedWaitTime),
                        }),
                    }

                    if ('personPositions' in crowdSize) {
                        // fix locations type as it comes broken from the generated API
                        update.locations = (crowdSize as FloorplanCrowdSizeResponse).personPositions as unknown as {
                            [key: string]: Array<PersonPosition>
                        }
                    }

                    return update
                }

                return prev
            })
            setConnectionStatus((prev) => ({
                ...prev,
                lastUpdate: DateTime.utc(),
            }))
        }

        ws.onerror = () => {
            setData((prev) => ({
                ...prev,
                isOffline: true,
                everHadError: true,
                observedAt: DateTime.local(),
            }))
        }

        ws.onclose = () => {
            setData((prev) => ({
                ...prev,
                isOffline: true,
                observedAt: DateTime.local(),
            }))
        }

        return () => {
            if (typeof ws?.close === 'function') {
                ws.close()
            }
        }
    }, [props.localityId, connectionStatus.reconnectionCounter])

    // Reset WebSocket connection if no message arrived in a long time
    useEffect(() => {
        const interval = setInterval(() => {
            const sinceLastUpdate = DateTime.utc().diff(connectionStatus.lastUpdate).as('seconds')

            const sinceLastConnection = DateTime.utc().diff(connectionStatus.lastConnection).as('seconds')

            if (
                Math.min(sinceLastUpdate, sinceLastConnection) > (props.connectionCheckInterval ?? 3 * updateInterval)
            ) {
                setData((prev) => ({
                    ...prev,
                    isOffline: true,
                }))
                setConnectionStatus((prev) => ({
                    ...prev,
                    reconnectionCounter: prev.reconnectionCounter + 1,
                    lastUpdate: DateTime.utc(),
                }))
            }
        }, 1000)

        return () => clearInterval(interval)
    }, [connectionStatus.lastUpdate, connectionStatus.lastConnection, props.connectionCheckInterval])

    return <props.dataRenderer data={data} />
}

export default LocalityOccupancyConsumer
