/* tslint:disable */
/* eslint-disable */
/**
 * Vividi Dashboard API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Point,
    PointFromJSON,
    PointFromJSONTyped,
    PointToJSON,
} from './Point';

/**
 * 
 * @export
 * @interface EntityVisitRecord
 */
export interface EntityVisitRecord {
    /**
     * Unique ID of the entity across all devices
     * @type {number}
     * @memberof EntityVisitRecord
     */
    globalEntityId: number;
    /**
     * Entity ID as reported by device. No specific uniqueness guaranteed
     * @type {number}
     * @memberof EntityVisitRecord
     */
    entityId: number;
    /**
     * 
     * @type {string}
     * @memberof EntityVisitRecord
     */
    startedAt: string;
    /**
     * 
     * @type {string}
     * @memberof EntityVisitRecord
     */
    endedAt: string;
    /**
     * 
     * @type {string}
     * @memberof EntityVisitRecord
     */
    classification?: EntityVisitRecordClassificationEnum;
    /**
     * 
     * @type {Array<Point>}
     * @memberof EntityVisitRecord
     */
    trajectory: Array<Point>;
    /**
     * 
     * @type {number}
     * @memberof EntityVisitRecord
     */
    age?: number;
    /**
     * 
     * @type {number}
     * @memberof EntityVisitRecord
     */
    isMale?: number;
    /**
     * 
     * @type {number}
     * @memberof EntityVisitRecord
     */
    emotionScore?: number;
}

/**
* @export
* @enum {string}
*/
export enum EntityVisitRecordClassificationEnum {
    Incoming = 'incoming',
    Outgoing = 'outgoing'
}

export function EntityVisitRecordFromJSON(json: any): EntityVisitRecord {
    return EntityVisitRecordFromJSONTyped(json, false);
}

export function EntityVisitRecordFromJSONTyped(json: any, ignoreDiscriminator: boolean): EntityVisitRecord {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'globalEntityId': json['global_entity_id'],
        'entityId': json['entity_id'],
        'startedAt': json['started_at'],
        'endedAt': json['ended_at'],
        'classification': !exists(json, 'classification') ? undefined : json['classification'],
        'trajectory': ((json['trajectory'] as Array<any>).map(PointFromJSON)),
        'age': !exists(json, 'age') ? undefined : json['age'],
        'isMale': !exists(json, 'is_male') ? undefined : json['is_male'],
        'emotionScore': !exists(json, 'emotion_score') ? undefined : json['emotion_score'],
    };
}

export function EntityVisitRecordToJSON(value?: EntityVisitRecord | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'global_entity_id': value.globalEntityId,
        'entity_id': value.entityId,
        'started_at': value.startedAt,
        'ended_at': value.endedAt,
        'classification': value.classification,
        'trajectory': ((value.trajectory as Array<any>).map(PointToJSON)),
        'age': value.age,
        'is_male': value.isMale,
        'emotion_score': value.emotionScore,
    };
}

