import React from 'react'
import { QueryStatus } from 'react-query'

import LoadingSpinner from '@components/LoadingSpinner'

export type ContentProps = {
    loadingState?: QueryStatus
    children?: React.ReactNode | Array<React.ReactNode>
    bare?: boolean
}

export const Content: React.FC<ContentProps> = ({ loadingState, children, bare = true }: ContentProps) => {
    if (loadingState === 'loading') {
        return <LoadingSpinner bare={bare} />
    }

    return <>{children}</>
}
