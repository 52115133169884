import React from 'react'
import { Card } from 'react-bootstrap'

import { XOR } from '@helpers/types'

import Heading from '@elements/Heading/Heading'

import styles from './Card.module.scss'

type Props = {
    buttons?: React.ReactNode | React.ReactNode[] | JSX.Element
    hideButtons?: boolean
    text?: string
    subtitle?: string
} & XOR<{ children?: React.ReactNode | React.ReactNode[] }, { level?: 1 | 2 | 3 }>

const CardTitle: React.FC<Props> = ({ text, children, buttons, hideButtons = false, subtitle, level = 2 }) => (
    <Card.Title className={styles.title}>
        {buttons !== undefined && !hideButtons ? (
            <div className={styles.titleWithButtons}>
                {children === undefined ? <Heading level={level} text={text} /> : children}
                {buttons}
            </div>
        ) : children === undefined ? (
            <Heading level={level} text={text} />
        ) : (
            children
        )}
        {subtitle && <span className={styles.subtitle}>{subtitle}</span>}
    </Card.Title>
)

export default CardTitle
