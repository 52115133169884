/**
 * Vividi Dashboard API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime'
import {UseQueryOptions, QueryKey} from 'react-query'

import {
    ProfileResponse,
    ProfileResponseFromJSON,
    ProfileResponseToJSON,
} from '../models';


/**
 * 
 */
export class UserProfileApi extends runtime.BaseAPI {
    readonly name = 'UserProfileApi'

    /**
     * Get profile information for the current user.
     */
    getProfile = Object.assign(
        async (initOverrides?: RequestInit): Promise<ProfileResponse> => {
            const response = await this.getProfile.invokeRaw(initOverrides);
            return await response.value();
        },
        {
            invokeRaw: async (initOverrides?: RequestInit): Promise<runtime.ApiResponse<ProfileResponse>> => {
                const queryParameters: any = {};
            
                const headerParameters: runtime.HTTPHeaders = {};
            
                if (this.configuration && this.configuration.accessToken) {
                    const token = this.configuration.accessToken;
                    const tokenString = await token("token", []);
            
                    if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                    }
                }
                const response = await this.request({
                    path: `/api/profile`,
                    method: 'GET',
                    headers: headerParameters,
                    query: queryParameters,
                }, initOverrides);
            
                return new runtime.JSONApiResponse(response, (jsonValue) => ProfileResponseFromJSON(jsonValue));
            },
            mutationKey: [
                'UserProfileApi',
                'getProfile', 
            ],
            queryKey: (initOverrides?: RequestInit) => ([
                    ...this.getProfile.mutationKey, 
                {
                }
            ]),
            query: (initOverrides?: RequestInit): UseQueryOptions<ProfileResponse> & {queryKey: QueryKey} => ({
                queryFn: () => this.getProfile(initOverrides),
                queryKey: this.getProfile.queryKey(initOverrides)
            })
        }
    )

}
