/* tslint:disable */
/* eslint-disable */
/**
 * Vividi Dashboard API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    WeatherDescription,
    WeatherDescriptionFromJSON,
    WeatherDescriptionFromJSONTyped,
    WeatherDescriptionToJSON,
} from './WeatherDescription';

/**
 * 
 * @export
 * @interface IntervalWeather
 */
export interface IntervalWeather {
    /**
     * 
     * @type {string}
     * @memberof IntervalWeather
     */
    startingFrom: string;
    /**
     * 
     * @type {string}
     * @memberof IntervalWeather
     */
    endingAt: string;
    /**
     * 
     * @type {WeatherDescription}
     * @memberof IntervalWeather
     */
    weather?: WeatherDescription;
    /**
     * 
     * @type {number}
     * @memberof IntervalWeather
     */
    temperature?: number;
    /**
     * 
     * @type {number}
     * @memberof IntervalWeather
     */
    precipitation?: number;
}

export function IntervalWeatherFromJSON(json: any): IntervalWeather {
    return IntervalWeatherFromJSONTyped(json, false);
}

export function IntervalWeatherFromJSONTyped(json: any, ignoreDiscriminator: boolean): IntervalWeather {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'startingFrom': json['starting_from'],
        'endingAt': json['ending_at'],
        'weather': !exists(json, 'weather') ? undefined : WeatherDescriptionFromJSON(json['weather']),
        'temperature': !exists(json, 'temperature') ? undefined : json['temperature'],
        'precipitation': !exists(json, 'precipitation') ? undefined : json['precipitation'],
    };
}

export function IntervalWeatherToJSON(value?: IntervalWeather | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'starting_from': value.startingFrom,
        'ending_at': value.endingAt,
        'weather': WeatherDescriptionToJSON(value.weather),
        'temperature': value.temperature,
        'precipitation': value.precipitation,
    };
}

