/**
 * Vividi Dashboard API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime'
import {UseQueryOptions, QueryKey} from 'react-query'

import {
    DockerImageListResponse,
    DockerImageListResponseFromJSON,
    DockerImageListResponseToJSON,
    SuccessResponse,
    SuccessResponseFromJSON,
    SuccessResponseToJSON,
} from '../models';

export interface DeleteImageRequest {
    name: string;
    tag: string;
}


/**
 * 
 */
export class VividiImageManagementApi extends runtime.BaseAPI {
    readonly name = 'VividiImageManagementApi'

    /**
     * Delete an image
     */
    deleteImage = Object.assign(
        async (requestParameters: DeleteImageRequest, initOverrides?: RequestInit): Promise<SuccessResponse> => {
            const response = await this.deleteImage.invokeRaw(requestParameters, initOverrides);
            return await response.value();
        },
        {
            invokeRaw: async (requestParameters: DeleteImageRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SuccessResponse>> => {
                if (requestParameters.name === null || requestParameters.name === undefined) {
                    throw new runtime.RequiredError('name','Required parameter requestParameters.name was null or undefined when calling deleteImage.');
                }
            
                if (requestParameters.tag === null || requestParameters.tag === undefined) {
                    throw new runtime.RequiredError('tag','Required parameter requestParameters.tag was null or undefined when calling deleteImage.');
                }
            
                const queryParameters: any = {};
            
                const headerParameters: runtime.HTTPHeaders = {};
            
                if (this.configuration && this.configuration.accessToken) {
                    const token = this.configuration.accessToken;
                    const tokenString = await token("token", []);
            
                    if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                    }
                }
                const response = await this.request({
                    path: `/api/images/{name}/{tag}`.replace(`{${"name"}}`, encodeURIComponent(String(requestParameters.name))).replace(`{${"tag"}}`, encodeURIComponent(String(requestParameters.tag))),
                    method: 'DELETE',
                    headers: headerParameters,
                    query: queryParameters,
                }, initOverrides);
            
                return new runtime.JSONApiResponse(response, (jsonValue) => SuccessResponseFromJSON(jsonValue));
            },
            mutationKey: [
                'VividiImageManagementApi',
                'deleteImage', 
            ],
            queryKey: (requestParameters: DeleteImageRequest, initOverrides?: RequestInit) => ([
                    ...this.deleteImage.mutationKey, 
                {
                name: requestParameters.name,
                tag: requestParameters.tag,
                }
            ]),
            query: (requestParameters: DeleteImageRequest, initOverrides?: RequestInit): UseQueryOptions<SuccessResponse> & {queryKey: QueryKey} => ({
                queryFn: () => this.deleteImage(requestParameters, initOverrides),
                queryKey: this.deleteImage.queryKey(requestParameters, initOverrides)
            })
        }
    )

    /**
     * Retrieve a list of images that are accessible in the shared Docker registry.
     */
    getImages = Object.assign(
        async (initOverrides?: RequestInit): Promise<DockerImageListResponse> => {
            const response = await this.getImages.invokeRaw(initOverrides);
            return await response.value();
        },
        {
            invokeRaw: async (initOverrides?: RequestInit): Promise<runtime.ApiResponse<DockerImageListResponse>> => {
                const queryParameters: any = {};
            
                const headerParameters: runtime.HTTPHeaders = {};
            
                if (this.configuration && this.configuration.accessToken) {
                    const token = this.configuration.accessToken;
                    const tokenString = await token("token", []);
            
                    if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                    }
                }
                const response = await this.request({
                    path: `/api/images/`,
                    method: 'GET',
                    headers: headerParameters,
                    query: queryParameters,
                }, initOverrides);
            
                return new runtime.JSONApiResponse(response, (jsonValue) => DockerImageListResponseFromJSON(jsonValue));
            },
            mutationKey: [
                'VividiImageManagementApi',
                'getImages', 
            ],
            queryKey: (initOverrides?: RequestInit) => ([
                    ...this.getImages.mutationKey, 
                {
                }
            ]),
            query: (initOverrides?: RequestInit): UseQueryOptions<DockerImageListResponse> & {queryKey: QueryKey} => ({
                queryFn: () => this.getImages(initOverrides),
                queryKey: this.getImages.queryKey(initOverrides)
            })
        }
    )

}
