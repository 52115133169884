import React, { useState } from 'react'
import { Button, ButtonProps, ButtonToolbar, OverlayTriggerProps } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import PopoverButton from '@components/PopoverButton/PopoverButton'

type ConfirmProps = {
    onConfirm: () => void
    onCancel?: () => void
    confirmMessage?: string | JSX.Element
    cancelText?: string
    confirmText?: string
    confirmButtonVariant?: ButtonProps['variant']
    cancelButtonVariant?: ButtonProps['variant']
}

const Confirm = ({
    onConfirm,
    onCancel,
    confirmMessage,
    cancelText,
    confirmText,
    cancelButtonVariant,
    confirmButtonVariant,
}: ConfirmProps) => {
    const { t } = useTranslation()

    return (
        <>
            {confirmMessage && <p>{confirmMessage}</p>}
            <ButtonToolbar>
                <Button variant={cancelButtonVariant} onClick={() => onCancel && onCancel()}>
                    {cancelText ?? t('button.cancel', 'Cancel')}
                </Button>
                <Button variant={confirmButtonVariant} onClick={() => onConfirm && onConfirm()}>
                    {confirmText ?? t('button.confirm', 'Confirm')}
                </Button>
            </ButtonToolbar>
        </>
    )
}

type Props = ConfirmProps & {
    children: JSX.Element
    title?: string
    placement?: OverlayTriggerProps['placement']
}

const PopConfirm = ({ children, onConfirm, onCancel, title, placement, ...rest }: Props) => {
    const { t } = useTranslation()

    const [popoverOpen, setPopoverOpen] = useState(false)

    const handleConfirm = () => {
        onConfirm()
        setPopoverOpen(false)
    }

    const handleCancel = () => {
        if (onCancel) {
            onCancel()
        }
        setPopoverOpen(false)
    }

    return (
        <PopoverButton
            content={<Confirm {...rest} onCancel={handleCancel} onConfirm={handleConfirm} />}
            isOpen={popoverOpen}
            placement={placement ?? 'bottom'}
            title={title ?? t('button.areYouSure', 'Are you sure?')}
            trigger={['click']}
            onToggle={setPopoverOpen}
        >
            {children}
        </PopoverButton>
    )
}

export default PopConfirm
