import { faDownload } from '@fortawesome/free-solid-svg-icons'
import { noop } from 'lodash'
import { ButtonGroup } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import IconButton from '@elements/Buttons/IconButton'

type Props = {
    className?: string
    buttonText?: string
} & (
    | {
          disabled?: false
          onClick: () => void
      }
    | {
          disabled: true
          onClick?: () => void
      }
)

const DownloadButtonWrapper = ({ className, onClick, disabled = false, buttonText }: Props) => {
    const { t } = useTranslation()

    return (
        <ButtonGroup className={className}>
            <IconButton
                disabled={disabled}
                icon={faDownload}
                tooltipText={buttonText ?? t('button.downloadCSV', 'Download CSV')}
                onClick={onClick ?? noop}
            />
        </ButtonGroup>
    )
}

export default DownloadButtonWrapper
