/* tslint:disable */
/* eslint-disable */
/**
 * Vividi Dashboard API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PersonPosition,
    PersonPositionFromJSON,
    PersonPositionFromJSONTyped,
    PersonPositionToJSON,
} from './PersonPosition';

/**
 * 
 * @export
 * @interface FloorplanCrowdSizeResponse
 */
export interface FloorplanCrowdSizeResponse {
    /**
     * 
     * @type {number}
     * @memberof FloorplanCrowdSizeResponse
     */
    currentCrowdSize: number;
    /**
     * 
     * @type {number}
     * @memberof FloorplanCrowdSizeResponse
     */
    maximumCapacity: number;
    /**
     * Estimated waiting time in seconds
     * @type {number}
     * @memberof FloorplanCrowdSizeResponse
     */
    estimatedWaitTime: number;
    /**
     * 
     * @type {string}
     * @memberof FloorplanCrowdSizeResponse
     */
    observedAt: string;
    /**
     * The time point used as a starting point for crowd size calculation (debug information)
     * @type {string}
     * @memberof FloorplanCrowdSizeResponse
     */
    calculationBase: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof FloorplanCrowdSizeResponse
     */
    crowdSizeHistory?: Array<number>;
    /**
     * 
     * @type {Array<string>}
     * @memberof FloorplanCrowdSizeResponse
     */
    crowdSizeHistoryDatetime?: Array<string>;
    /**
     * Mapping from floorplan_id to list of person positions (floorplan coordinates).
     * @type {{ [key: string]: Array<PersonPosition>; }}
     * @memberof FloorplanCrowdSizeResponse
     */
    personPositions?: { [key: string]: Array<PersonPosition>; };
}

export function FloorplanCrowdSizeResponseFromJSON(json: any): FloorplanCrowdSizeResponse {
    return FloorplanCrowdSizeResponseFromJSONTyped(json, false);
}

export function FloorplanCrowdSizeResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): FloorplanCrowdSizeResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'currentCrowdSize': json['current_crowd_size'],
        'maximumCapacity': json['maximum_capacity'],
        'estimatedWaitTime': json['estimated_wait_time'],
        'observedAt': json['observed_at'],
        'calculationBase': json['calculation_base'],
        'crowdSizeHistory': !exists(json, 'crowd_size_history') ? undefined : json['crowd_size_history'],
        'crowdSizeHistoryDatetime': !exists(json, 'crowd_size_history_datetime') ? undefined : json['crowd_size_history_datetime'],
        'personPositions': !exists(json, 'person_positions') ? undefined : json['person_positions'],
    };
}

export function FloorplanCrowdSizeResponseToJSON(value?: FloorplanCrowdSizeResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'current_crowd_size': value.currentCrowdSize,
        'maximum_capacity': value.maximumCapacity,
        'estimated_wait_time': value.estimatedWaitTime,
        'observed_at': value.observedAt,
        'calculation_base': value.calculationBase,
        'crowd_size_history': value.crowdSizeHistory,
        'crowd_size_history_datetime': value.crowdSizeHistoryDatetime,
        'person_positions': value.personPositions,
    };
}

