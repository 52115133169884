import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import React from 'react'
import { Placement } from 'react-bootstrap/esm/Overlay'

import TooltipWrapper from '@components/TooltipWrapper'

import styles from './IconButton.module.scss'

interface IconButtonProps {
    icon: IconProp
    disabled?: boolean
    tooltipText?: string
    onClick?: () => void
    className?: string
    tooltipPosition?: Placement
}

const IconButton: React.FC<IconButtonProps> = ({
    icon,
    disabled,
    tooltipText,
    onClick,
    className,
    tooltipPosition = undefined,
}) => (
    <>
        {!disabled && tooltipText && (
            <TooltipWrapper tooltipPosition={tooltipPosition} tooltipText={tooltipText}>
                <button
                    className={classNames(styles.iconButton, className)}
                    onClick={(e) => {
                        e.preventDefault()
                        onClick?.()
                    }}
                >
                    <FontAwesomeIcon icon={icon} />
                </button>
            </TooltipWrapper>
        )}
        {!disabled && !tooltipText && (
            <button
                className={classNames(styles.iconButton, className)}
                onClick={(e) => {
                    e.preventDefault()
                    onClick?.()
                }}
            >
                <FontAwesomeIcon icon={icon} />
            </button>
        )}
        {disabled && (
            <button className={classNames(styles.iconButton, className)} disabled={disabled}>
                <FontAwesomeIcon icon={icon} />
            </button>
        )}
    </>
)
export default IconButton
