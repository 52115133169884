import { faSquare } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { groupBy, isEmpty, round, fromPairs, min, max, capitalize, last, Dictionary, orderBy, head } from 'lodash'
import { DateTime, Interval } from 'luxon'
import React, { useMemo, useState } from 'react'
import { OverlayTrigger, Popover } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import { LocalityResponse, ZoneOccupancySessionsResponse, ZoneOccupancySummaryResponse } from '@api/models'

import { passCSSVariable } from '@helpers/cssUtils'
import { displayPreciseDuration } from '@helpers/displayUtils'
import { IntervalType } from '@helpers/types'

import CardTitle from '@elements/Card/CardTitle'

import { displayDateTimes } from '@components/StatisticsSummary/utils'
import palette from '@components/palette.module.scss'
import { TimelinePagination } from '@components/plots/TimelinePagination'
import TooltipContent from '@components/plots/TooltipContent'
import { parseDate } from '@components/plots/common'

import styles from './OccupancyTimeline.module.scss'
import { prepareSessionsData, TimelineDisplayData } from './occupancyDetailsPreproccessors'

interface OccupancyTimelineProps {
    now: DateTime
    locality: LocalityResponse
    interval: Interval
    zoneOccupancyQuarterHourData: ZoneOccupancySummaryResponse /* todo precompute this data in some better form, e.g. parse and localize all the dates!! lets split data transformation and presentation!!!! */
    localize: (datetime: DateTime) => DateTime
    zoneOccupancySessions: ZoneOccupancySessionsResponse
    intervalType: IntervalType
}

const passSubsectionProps = (width: number, color: string, opacity?: number) =>
    ({
        '--SubsectionWidth': `${width}%`,
        '--SubsectionColor': color,
        '--SubsectionOpacity': opacity ? `${opacity}%` : '100%',
    } as React.CSSProperties)

interface OccupancyTimelineRowProps {
    now: DateTime
    zoneRow: {
        cells: Dictionary<
            {
                id: string
                date: string
                value: number
                interval: Interval
                isOpen: boolean
            }[]
        >
        deviceId: number
        zoneName: string
        zoneId: number
    }
    sessionsByDayByZoneId: TimelineDisplayData
}

const OccupancyTimelineRow: React.FC<OccupancyTimelineRowProps> = ({ zoneRow, sessionsByDayByZoneId, now }) => {
    const { t } = useTranslation()

    return (
        <React.Fragment>
            <div className={styles.zoneName}>{zoneRow.zoneName}</div>

            {Object.entries(zoneRow.cells).map(([day, cells], index) => {
                const daySessions = sessionsByDayByZoneId[day]?.[zoneRow.zoneId] ?? null

                return (
                    <div key={index} className={styles.timelineSection}>
                        {cells.map((cell) => {
                            const stripeWidth = 100 / Object.values(cells).length

                            return (
                                <React.Fragment key={cell.id}>
                                    {cell.value >
                                        0.3 /* todo calculating this condition looks like a lot of work, mb we can pre-compute it? */ &&
                                    !isEmpty(daySessions) &&
                                    !isEmpty(
                                        daySessions
                                            .filter((it) => it.sessionInterval.overlaps(cell.interval))
                                            .slice(0, 3)
                                    ) ? (
                                        <OverlayTrigger
                                            overlay={
                                                <Popover
                                                    id={`popover-${cell}`}
                                                    style={{
                                                        maxWidth: '100%',
                                                    }}
                                                >
                                                    <TooltipContent
                                                        active={true}
                                                        formatter={(value: number, name: string) => {
                                                            if (name.startsWith('numberOfPeople')) {
                                                                return [
                                                                    round(value),
                                                                    t('tooltip.numberOfPeople', 'Number of people'),
                                                                ]
                                                            }

                                                            if (name.startsWith('sessionDuration')) {
                                                                return [
                                                                    displayPreciseDuration(value),
                                                                    t('tooltip.sessionDuration', 'Session duration'),
                                                                ]
                                                            }

                                                            return [value, name]
                                                        }}
                                                        label={`${zoneRow.zoneName} - ${displayDateTimes({
                                                            date: parseDate(cell.date),
                                                        })}, ${cell.interval.start.toFormat(
                                                            'HH:mm'
                                                        )}-${cell.interval.end.toFormat('HH:mm')}`}
                                                        now={now}
                                                        payload={daySessions
                                                            .filter((it) => it.sessionInterval.overlaps(cell.interval))
                                                            .slice(0, 3)
                                                            .flatMap((session, i) => [
                                                                {
                                                                    color: 'black',
                                                                    name: `numberOfPeople ${i}`,
                                                                    value: session.averagePersons || 1,
                                                                    payload: { hasData: true },
                                                                },
                                                                {
                                                                    color: 'black',
                                                                    name: `sessionDuration ${i}`,
                                                                    value: session.sessionDurationInSeconds,
                                                                    payload: { hasData: true },
                                                                },
                                                            ])}
                                                    />
                                                </Popover>
                                            }
                                        >
                                            <span
                                                className={styles.timelineSubSection}
                                                style={passSubsectionProps(stripeWidth, palette.vividiPrimary)}
                                            />
                                        </OverlayTrigger>
                                    ) : (
                                        <span
                                            className={styles.inactiveTimelineSubSection}
                                            style={passSubsectionProps(
                                                stripeWidth,
                                                cell.isOpen
                                                    ? cell.value > 0.3
                                                        ? palette.vividiPrimary
                                                        : palette.grey90
                                                    : palette.lightGrey,
                                                !cell.isOpen ? 50 : undefined
                                            )}
                                        />
                                    )}
                                </React.Fragment>
                            )
                        })}
                    </div>
                )
            })}
        </React.Fragment>
    )
}

const OccupancyTimeline: React.FC<OccupancyTimelineProps> = ({
    locality,
    zoneOccupancyQuarterHourData,
    localize,
    zoneOccupancySessions,
    now,
}) => {
    const { t } = useTranslation()

    const openedHours = zoneOccupancyQuarterHourData.timeBrackets
        .filter((_, i) => zoneOccupancyQuarterHourData.openedPortions[i] > 0)
        .map((interval) => localize(parseDate(interval.startingFrom)).hour)
    const minHour = min(openedHours) ?? 0
    const maxHour = max(openedHours) ?? 23
    const openedIntervals = zoneOccupancyQuarterHourData.timeBrackets
        .map((interval, i) => {
            return {
                interval,
                isOpen: zoneOccupancyQuarterHourData.openedPortions[i] > 0,
            }
        })
        .filter((_, i) => {
            const hour = localize(parseDate(zoneOccupancyQuarterHourData.timeBrackets[i].startingFrom)).hour

            return minHour <= hour && hour <= maxHour
        })

    const activeZoneOccupancy = zoneOccupancyQuarterHourData.zoneRows.map((zoneRow) => ({
        ...zoneRow,
        cells: zoneRow.cells.filter((_, i) => {
            const hour = localize(parseDate(zoneOccupancyQuarterHourData.timeBrackets[i].startingFrom)).hour

            return minHour <= hour && hour <= maxHour
        }),
    }))

    const timelineDataByDay = activeZoneOccupancy.map((zone) => ({
        ...zone,
        cells: groupBy(
            zone.cells.map((zoneValue, index) => {
                const startingFrom = parseDate(openedIntervals[index].interval.startingFrom)
                const endingAt = parseDate(openedIntervals[index].interval.endingAt)

                return {
                    id: `${startingFrom}-${index}`,
                    date: localize(startingFrom).toISODate(),
                    value: zoneValue,
                    interval: Interval.fromDateTimes(localize(startingFrom), localize(endingAt)),
                    isOpen: openedIntervals[index].isOpen,
                }
            }),
            'date'
        ),
    }))

    // Filter out days when closed

    const openedDays = Object.entries(locality.openingHours)
        .filter(([_, openedHours]) => !isEmpty(openedHours))
        .map(([weekday]) => capitalize(weekday))

    const timelineDataWithoutOffDays = orderBy(
        timelineDataByDay.map((zone) => ({
            ...zone,
            cells: fromPairs(
                Object.entries(zone.cells).filter(([date]) =>
                    openedDays.includes(
                        localize(parseDate(date)).setLocale('en-gb').toLocaleString({
                            weekday: 'short',
                        })
                    )
                )
            ),
        })),
        ({ zoneName }) => zoneName
    )

    const paginationSize = 5

    const pages = !isEmpty(timelineDataWithoutOffDays[0].cells)
        ? Math.ceil(Object.keys(timelineDataWithoutOffDays[0].cells).length / paginationSize)
        : 0

    const [selectedPage, setSelectedPage] = useState<number>(1)

    const rows = useMemo(
        () =>
            [...timelineDataWithoutOffDays].map((row) => ({
                ...row,
                cells: fromPairs(
                    Object.entries(row.cells).slice((selectedPage - 1) * paginationSize, selectedPage * paginationSize)
                ),
            })),
        [timelineDataWithoutOffDays, selectedPage]
    )

    const sessionsByDayByZoneName = prepareSessionsData(zoneOccupancySessions.sessions, localize)

    const dataValues = Object.values(rows[0].cells)

    //Convert dates back to DateTime

    const dates = Object.keys(rows[0].cells).map(parseDate)

    return (
        <div>
            <CardTitle
                buttons={
                    pages > 1 && (
                        <TimelinePagination
                            displayedDates={{
                                startDate: {
                                    date: head(dates)!,
                                },
                                endDate: {
                                    date: last(dates)!,
                                },
                            }}
                            pageCount={pages}
                            selectedPage={selectedPage}
                            onNextPage={() => setSelectedPage((prev) => (prev + 1 < pages ? prev + 1 : pages))}
                            onPreviousPage={() => setSelectedPage((prev) => (prev - 1 > 0 ? prev - 1 : 1))}
                        />
                    )
                }
                text={t('heading.occupancySessionsInTime', 'Occupancy sessions in time')}
            />

            <div className={styles.occupancyTimelineContainer} style={passCSSVariable('ColumnCount', dates.length)}>
                <>
                    <div />
                    {dates.map((date) => (
                        <div key={date.toISO()} className={styles.weekdayContainer}>
                            <div>
                                {date.weekdayLong}, {displayDateTimes({ date })}
                            </div>
                        </div>
                    ))}
                </>
                {rows.map((zoneRow) => (
                    <OccupancyTimelineRow
                        key={zoneRow.zoneId}
                        now={now}
                        sessionsByDayByZoneId={sessionsByDayByZoneName}
                        zoneRow={zoneRow}
                    />
                ))}
                <>
                    <div />
                    {dataValues.map((row, index) => {
                        return row.length > 0 ? (
                            <div key={index} className={styles.openHoursContainer}>
                                <div>{row[0].interval.start.toFormat('HH:mm')}</div>
                                <div>{row[row.length - 1].interval.start.toFormat('HH:mm')}</div>
                            </div>
                        ) : (
                            <div key={index} className={styles.openHoursContainer} />
                        )
                    })}
                </>
                <div className={styles.legendContainer} style={passCSSVariable('ColumnSpan', dates.length + 2)}>
                    <div>
                        <FontAwesomeIcon className={styles.occupiedSquare} icon={faSquare} />
                        <span>{t('occupancy.seatOccupied', 'Seat occupied')}</span>
                    </div>
                    <div>
                        <FontAwesomeIcon className={styles.freeSquare} icon={faSquare} />
                        <span>{t('occupancy.seatFree', 'Seat free')}</span>
                    </div>
                    <div>
                        <FontAwesomeIcon className={styles.closedSquare} icon={faSquare} />
                        <span>{t('occupancy.outsideOfOpeningHours', 'Outside of opening hours')}</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OccupancyTimeline
