import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { MutationStatus } from 'react-query'
import { useHistory, useParams } from 'react-router'

import { userApi } from '@services'

import { VIVIDI_APP } from '@helpers/VividiURLs'
import { storeToken } from '@helpers/auth'

import PageContentLayout from '@components/Layouts/PageContentLayout'
import SetPasswordForm from '@components/SetPasswordForm'
import { useNotify } from '@components/notifications/NotificationsContext'

const AcceptInvitationPage = () => {
    const { t } = useTranslation()
    const { token } = useParams<{ token: string }>()
    const [requestState, setRequestState] = useState<MutationStatus>('idle')
    const history = useHistory()
    const notify = useNotify()

    const api = userApi.withPreMiddleware(async (context) => {
        context.init.headers = {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
        }

        return context
    })
    const documentTitle = t('title.setPassword', 'Set password')

    const heading = t('heading.configurePassword', 'Configure a Password')

    return (
        <>
            <PageContentLayout documentTitle={documentTitle} heading={heading}>
                <SetPasswordForm
                    askCurrentPassword={false}
                    submissionStatus={requestState}
                    onSubmit={async (password) => {
                        setRequestState('loading')

                        try {
                            const response = await api.resetOwnPassword({
                                body: { password },
                            })
                            storeToken(response)
                            setRequestState('success')
                            history.replace(VIVIDI_APP)
                            notify({
                                title: t('notification.accountCreatedTitle', 'Account creation finished'),
                                content: t(
                                    'notification.accountCreatedContent',
                                    'Your password was set and you were logged in'
                                ),
                                variant: 'success',
                            })
                        } catch (e) {
                            setRequestState('error')
                            notify({
                                title: t('notification.settingPasswordFailedTitle', 'Setting the password failed'),
                                content: t(
                                    'notification.settingPasswordFailedContent',
                                    'There was an error during the processing of your request'
                                ),
                                variant: 'danger',
                            })
                        }
                    }}
                />
            </PageContentLayout>
        </>
    )
}

export default AcceptInvitationPage
