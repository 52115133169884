/* tslint:disable */
/* eslint-disable */
/**
 * Vividi Dashboard API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IntervalPropertyBreakdown,
    IntervalPropertyBreakdownFromJSON,
    IntervalPropertyBreakdownFromJSONTyped,
    IntervalPropertyBreakdownToJSON,
} from './IntervalPropertyBreakdown';

/**
 * 
 * @export
 * @interface IntervalPropertyBreakdownByBoundary
 */
export interface IntervalPropertyBreakdownByBoundary {
    /**
     * 
     * @type {string}
     * @memberof IntervalPropertyBreakdownByBoundary
     */
    boundaryName: string;
    /**
     * 
     * @type {number}
     * @memberof IntervalPropertyBreakdownByBoundary
     */
    boundaryId: number;
    /**
     * 
     * @type {number}
     * @memberof IntervalPropertyBreakdownByBoundary
     */
    deviceId: number;
    /**
     * 
     * @type {number}
     * @memberof IntervalPropertyBreakdownByBoundary
     */
    sceneId: number;
    /**
     * 
     * @type {Array<IntervalPropertyBreakdown>}
     * @memberof IntervalPropertyBreakdownByBoundary
     */
    items: Array<IntervalPropertyBreakdown>;
}

export function IntervalPropertyBreakdownByBoundaryFromJSON(json: any): IntervalPropertyBreakdownByBoundary {
    return IntervalPropertyBreakdownByBoundaryFromJSONTyped(json, false);
}

export function IntervalPropertyBreakdownByBoundaryFromJSONTyped(json: any, ignoreDiscriminator: boolean): IntervalPropertyBreakdownByBoundary {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'boundaryName': json['boundary_name'],
        'boundaryId': json['boundary_id'],
        'deviceId': json['device_id'],
        'sceneId': json['scene_id'],
        'items': ((json['items'] as Array<any>).map(IntervalPropertyBreakdownFromJSON)),
    };
}

export function IntervalPropertyBreakdownByBoundaryToJSON(value?: IntervalPropertyBreakdownByBoundary | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'boundary_name': value.boundaryName,
        'boundary_id': value.boundaryId,
        'device_id': value.deviceId,
        'scene_id': value.sceneId,
        'items': ((value.items as Array<any>).map(IntervalPropertyBreakdownToJSON)),
    };
}

