import { faFemale, faMale } from '@fortawesome/free-solid-svg-icons'
import _, { round, partition, sum, mapValues, isEmpty, pick, keyBy, fromPairs } from 'lodash'
import { Interval, IANAZone, DateTime } from 'luxon'
import { Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'

import { OrganizationResponse, LocalityConfigurationStateModel, CategorySet, FootfallOverviewItem } from '@api'

import { statisticsApi } from '@services'

import { generateFootfallDetailsCustomIntervalPath, generateOrganizationsEditLocalitiesPath } from '@helpers/VividiURLs'
import { useAddBackLink } from '@helpers/backlinks'
import { gdynusaReadableInterval } from '@helpers/datetimeUtils'
import { GoogleEventName } from '@helpers/gtag'
import { formatIntervalToFilename } from '@helpers/intervals'
import { orderLocalities } from '@helpers/orderFunctions'
import { useTimezoneConfig, migrateTimestamp, useLocalizeDateTime } from '@helpers/timezoneConfig'
import { percentage, pickColumn } from '@helpers/utils'
import { exportToExcel } from '@helpers/xlsxExporter'

import Box from '@elements/Box/Box'
import TableWithSorting, { CellContent } from '@elements/Table/TableWithSorting'

import AdminOnlyLinkRenderer from '@components/AdminOnlyLinkRenderer'
import LegacyLoadingWrapper from '@components/LegacyLoadingWrapper'
import LoadingSpinner from '@components/LoadingSpinner'
import ChangeIndicator from '@components/StatisticsSummary/LocalityTable/ChangeIndicator'
import NotConfiguredLocalitiesList from '@components/StatisticsSummary/NotConfiguredLocalitiesList'
import SectionSubheading from '@components/StatisticsSummary/SectionSubheading'
import StatisticBox, { StatisticBoxItem } from '@components/StatisticsSummary/StatisticBox'
import styles from '@components/StatisticsSummary/StatisticBox.module.scss'
import { calculatePercentageChange, weightedAverage, partitionByInterval } from '@components/StatisticsSummary/utils'
import { useNotify } from '@components/notifications/NotificationsContext'
import { parseDate } from '@components/plots/common'
import DownloadButtonWrapper from '@components/plots/downloadButtons/DownloadButtonWrapper'

import DetailLink from './DetailLink'
import { FootfallStatistics } from './SectionsTypes'

type Props = {
    localities: LocalityConfigurationStateModel[]
    selectedLocalities: LocalityConfigurationStateModel[]
    interval: Interval
    comparisonInterval?: Interval
    days: Array<DateTime>
    comparisonDays?: Array<DateTime>
    selectedOrganization: OrganizationResponse
    shouldDisplayLocalitySummary: boolean
}

const FootfallSection = ({
    localities,
    selectedLocalities,
    interval,
    comparisonInterval,
    selectedOrganization,
    shouldDisplayLocalitySummary,
    days,
    comparisonDays,
}: Props) => {
    const { t } = useTranslation()

    const notify = useNotify()

    const [configuredLocalities, notConfiguredLocalities] = partition(
        orderLocalities(selectedLocalities),
        (l) => l.isFootfallConfigured
    )

    const footfallOverviewCall = useQuery(
        statisticsApi.getFootfallOverview.query({
            body: {
                dates: [...days, ...(comparisonDays ?? [])].map((d) => d.toISODate()),
                localities: pickColumn(localities, 'id'),
                categorySet: CategorySet.Sex,
            },
        })
    )

    const zone = useTimezoneConfig()
    const localize = useLocalizeDateTime()
    const addBackLink = useAddBackLink()

    return (
        <LegacyLoadingWrapper
            placeholder={
                <Box paddingSize="lg">
                    <Box.Title text={t('heading.footfallStatistics', 'Footfall Statistics')} />
                    <LoadingSpinner bare />
                </Box>
            }
            request={footfallOverviewCall}
        >
            {(footfallOverview) => {
                const itemsByLocality = fromPairs(
                    footfallOverview.itemsByLocality.map(({ localityId, items }) => [localityId, items])
                )
                const totalVisitorsByGenderByLocality = mapValues(itemsByLocality, (items) => {
                    const { currentInterval, previousInterval } = partitionByInterval(interval, items)

                    const readCategories = (items?: FootfallOverviewItem[]) => ({
                        male: sum(items?.map(({ properties }) => properties['1'] ?? 0)) ?? 0,
                        female: sum(items?.map(({ properties }) => properties['0'] ?? 0)) ?? 0,
                    })

                    return {
                        current: readCategories(currentInterval),
                        previous: readCategories(previousInterval),
                    }
                })

                const overallDailyMovement = keyBy(
                    localities.map(({ id }) => {
                        const { currentInterval, previousInterval } = partitionByInterval(
                            interval,
                            itemsByLocality[id.toString()] ?? []
                        )

                        return { id, currentInterval, previousInterval }
                    }),
                    ({ id }) => id
                )

                const dailyAverageVisitorsByLocality = keyBy(
                    localities.map(({ id }) => {
                        const { currentInterval: currentMovement, previousInterval: previousMovement } =
                            overallDailyMovement[id]

                        const localityCurrentIntervalWeightedAverage = round(
                            weightedAverage(
                                currentMovement.map(({ numberOfIncoming }) => numberOfIncoming ?? 0),
                                currentMovement.map(({ openedPortion }) => openedPortion ?? 0)
                            )
                        )

                        const localityPreviousIntervalWeightedAverage =
                            previousMovement.length > 0
                                ? round(
                                      weightedAverage(
                                          previousMovement.map(({ numberOfIncoming }) => numberOfIncoming ?? 0),
                                          previousMovement.map(({ openedPortion }) => openedPortion ?? 0)
                                      )
                                  )
                                : undefined

                        return {
                            id,
                            current: localityCurrentIntervalWeightedAverage,
                            previous: localityPreviousIntervalWeightedAverage,
                        }
                    }),
                    ({ id }) => id
                )

                const currentIntervalDailyAverageVisitorsAllLocalities = sum(
                    Object.values(
                        pick(
                            dailyAverageVisitorsByLocality,
                            configuredLocalities.map(({ id }) => id)
                        )
                    ).map(({ current }) => current)
                )
                const previousIntervalDailyAverageVisitorsAllLocalities = sum(
                    Object.values(
                        pick(
                            dailyAverageVisitorsByLocality,
                            configuredLocalities.map(({ id }) => id)
                        )
                    ).map(({ previous }) => previous)
                )

                const countByGenderInAllLocalities = configuredLocalities
                    .map(({ id }) => totalVisitorsByGenderByLocality[id])
                    .filter((it) => it !== undefined)
                    .reduce(
                        (acc, curr) => ({
                            current: {
                                male: acc.current.male + curr.current.male,
                                female: acc.current.female + curr.current.female,
                            },
                            previous: {
                                male: acc.previous.male + curr.previous.male,
                                female: acc.previous.female + curr.previous.female,
                            },
                        }),
                        {
                            current: { male: 0, female: 0 },
                            previous: { male: 0, female: 0 },
                        }
                    )

                const totalGenderPercentage = mapValues(countByGenderInAllLocalities, (record) => {
                    const total = sum(Object.values(record))

                    return mapValues(record, (value) => (total > 0 ? round((100 * value) / total) : 0))
                })

                const footfallData: FootfallStatistics = {
                    currentInterval: {
                        intervalVisitors: mapValues(
                            itemsByLocality,
                            (items) =>
                                sum(
                                    partitionByInterval(interval, items).currentInterval.map(
                                        ({ numberOfIncoming }) => numberOfIncoming
                                    )
                                ) ?? 0
                        ),
                        intervalLocalitiesSum: sum(
                            configuredLocalities.map(
                                ({ id }) =>
                                    sum(
                                        partitionByInterval(
                                            interval,
                                            itemsByLocality[id.toString()] ?? []
                                        ).currentInterval.map(({ numberOfIncoming }) => numberOfIncoming)
                                    ) ?? 0
                            )
                        ),
                        intervalDuration: round(interval.length('days')),
                        intervalDailyAverageVisitors: currentIntervalDailyAverageVisitorsAllLocalities,
                        visitorPercentageByGender: totalGenderPercentage.current,
                    },
                    previousInterval:
                        comparisonInterval !== undefined
                            ? {
                                  intervalVisitors: mapValues(
                                      itemsByLocality,
                                      (items) =>
                                          sum(
                                              partitionByInterval(interval, items).previousInterval.map(
                                                  ({ numberOfIncoming }) => numberOfIncoming
                                              )
                                          ) ?? 0
                                  ),
                                  intervalLocalitiesSum: sum(
                                      configuredLocalities.map(
                                          ({ id }) =>
                                              sum(
                                                  partitionByInterval(
                                                      interval,
                                                      itemsByLocality[id.toString()] ?? []
                                                  ).previousInterval.map(({ numberOfIncoming }) => numberOfIncoming)
                                              ) ?? 0
                                      )
                                  ),
                                  intervalDuration:
                                      comparisonInterval !== undefined ? round(comparisonInterval.length('days')) : 0,
                                  intervalDailyAverageVisitors: previousIntervalDailyAverageVisitorsAllLocalities,
                                  visitorPercentageByGender: totalGenderPercentage.previous,
                              }
                            : undefined,
                }

                const footfallFormattedData = configuredLocalities.map(({ id, name, timezone }) => {
                    const localityCurrentVisitorSum = footfallData.currentInterval.intervalVisitors[id]

                    const localityPreviousVisitorSum =
                        footfallData.previousInterval !== undefined &&
                        footfallData.previousInterval.intervalVisitors !== undefined
                            ? footfallData.previousInterval.intervalVisitors[id]
                            : NaN

                    const calculatedChange = calculatePercentageChange(
                        localityCurrentVisitorSum,
                        localityPreviousVisitorSum
                    )

                    const dailySourceAverageVisitorsInLocality = dailyAverageVisitorsByLocality[id].current

                    const dailyTargetAverageVisitorsInLocality =
                        dailyAverageVisitorsByLocality[id].previous !== undefined &&
                        dailyAverageVisitorsByLocality[id].previous! >= 0
                            ? dailyAverageVisitorsByLocality[id].previous!
                            : NaN

                    const dailyAverageChange = calculatePercentageChange(
                        dailySourceAverageVisitorsInLocality,
                        dailyTargetAverageVisitorsInLocality
                    )

                    const totalVisitorsInLocalityByGender = totalVisitorsByGenderByLocality[id.toString()] ?? {
                        male: 0,
                        female: 0,
                    }

                    const genderPercentageInLocality = mapValues(totalVisitorsInLocalityByGender, (record) =>
                        mapValues(record, (value) => percentage(value, sum(Object.values(record))))
                    )

                    const cells: {
                        [key: string]: CellContent
                    } = {
                        locality: {
                            content: name,
                            cellType: 'text',
                            targetLink: addBackLink(
                                generateOrganizationsEditLocalitiesPath(selectedOrganization.id, id)
                            ),
                            renderer: AdminOnlyLinkRenderer,
                        },
                        totalVisitors: {
                            content: localityCurrentVisitorSum,
                            cellType: 'numeric',
                            numberFormat: 'count',
                            comparisonValue: localityPreviousVisitorSum,
                            valueChange: calculatedChange,
                            isChangeAbsolute: false,
                        },
                        dailyAverageVisitors: {
                            content: dailySourceAverageVisitorsInLocality,
                            cellType: 'numeric',
                            numberFormat: 'count',
                            comparisonValue: dailyTargetAverageVisitorsInLocality,
                            valueChange: dailyAverageChange,
                            isChangeAbsolute: false,
                        },
                        men: {
                            content: genderPercentageInLocality.current.male,
                            cellType: 'numeric',
                            numberFormat: 'percentage',
                            comparisonValue: genderPercentageInLocality.previous.male,
                            valueChange: round(
                                genderPercentageInLocality.current.male - genderPercentageInLocality.previous.male
                            ),
                            isChangeAbsolute: true,
                        },
                        women: {
                            content: genderPercentageInLocality.current.female,
                            cellType: 'numeric',
                            numberFormat: 'percentage',
                            comparisonValue: genderPercentageInLocality.previous.female,
                            valueChange: round(
                                genderPercentageInLocality.current.female - genderPercentageInLocality.previous.female
                            ),
                            isChangeAbsolute: true,
                        },
                        detail: {
                            cellType: 'rawElement',
                            element: (
                                <DetailLink
                                    to={generateFootfallDetailsCustomIntervalPath(
                                        id,
                                        migrateTimestamp(interval.start, zone, new IANAZone(timezone)),
                                        migrateTimestamp(interval.end, zone, new IANAZone(timezone))
                                    )}
                                />
                            ),
                        },
                    }

                    return cells
                })

                const handleDownload = () => {
                    const exportData = _(itemsByLocality)
                        .mapValues((items) =>
                            items.map(({ properties, startingFrom, numberOfIncoming }) => {
                                const maleVisitorCount = properties['1'] ?? 0
                                const femaleVisitorCount = properties['0'] ?? 0
                                const total = maleVisitorCount + femaleVisitorCount

                                return {
                                    [t('others.date', 'Date')]: localize(parseDate(startingFrom)).toLocaleString({
                                        ...DateTime.DATETIME_SHORT,
                                        weekday: 'short',
                                    }),
                                    [t('statistics.totalVisitors', 'Total visitors')]: numberOfIncoming ?? 0,
                                    [t('footfall.menPercentage', 'Men %')]: round(percentage(maleVisitorCount, total)),
                                    [t('footfall.womenPercentage', 'Women %')]: round(
                                        percentage(femaleVisitorCount, total)
                                    ),
                                }
                            })
                        )
                        .mapKeys(
                            (_, localityId) =>
                                localities.find(({ id }) => id.toString() === localityId)?.name ?? localityId
                        )
                        .value()

                    if (!isEmpty(exportData)) {
                        exportToExcel(
                            exportData,
                            `${selectedOrganization.name}-footfall-${formatIntervalToFilename(
                                gdynusaReadableInterval(interval.mapEndpoints(localize))
                            )}`,
                            GoogleEventName.DOWNLOAD_FOOTFALL_ORGANIZATION_EXCEL,
                            true
                        )
                    } else {
                        notify({
                            title: t('notification.error', 'Error'),
                            content: t('notification.nothingToExport', 'Nothing to export'),
                            variant: 'danger',
                        })
                    }
                }

                const isComparisonIntervalEnabled =
                    comparisonInterval !== undefined && footfallData.previousInterval !== undefined

                return (
                    <Box paddingSize="lg">
                        <Box.Title
                            buttons={
                                <DownloadButtonWrapper
                                    buttonText={t('button.downloadXLSX', 'Download XLSX')}
                                    onClick={handleDownload}
                                />
                            }
                            hideButtons={isEmpty(configuredLocalities)}
                            text={t('heading.footfallStatistics', 'Footfall Statistics')}
                        />
                        {!isEmpty(configuredLocalities) && (
                            <>
                                {shouldDisplayLocalitySummary && (
                                    <Row>
                                        <StatisticBox
                                            interval={interval}
                                            label={t('statistics.totalVisitors', 'Total visitors')}
                                            localities={configuredLocalities}
                                            md={4}
                                            sm={12}
                                            tooltip={t(
                                                'footfall.totalVisitorsDescription',
                                                'total sum of visitors across all selected localities'
                                            )}
                                        >
                                            <StatisticBoxItem
                                                changeIndicator={
                                                    isComparisonIntervalEnabled ? (
                                                        <ChangeIndicator
                                                            change={calculatePercentageChange(
                                                                footfallData.currentInterval.intervalLocalitiesSum,
                                                                footfallData.previousInterval?.intervalLocalitiesSum
                                                            )}
                                                            comparisonInterval={comparisonInterval!}
                                                            dataType="people"
                                                            isVisible={Boolean(comparisonInterval)}
                                                            valueToCompare={
                                                                footfallData.previousInterval!.intervalLocalitiesSum
                                                            }
                                                            isBigger
                                                        />
                                                    ) : undefined
                                                }
                                                value={footfallData.currentInterval.intervalLocalitiesSum}
                                            />
                                        </StatisticBox>
                                        <StatisticBox
                                            interval={interval}
                                            label={t('footfall.dailyAverageVisitors', 'Daily average visitors')}
                                            localities={configuredLocalities}
                                            md={4}
                                            sm={12}
                                            tooltip={t(
                                                'footfall.dailyAverageVisitorsDescription',
                                                'daily average number of visitors from all selected localities all together'
                                            )}
                                        >
                                            <StatisticBoxItem
                                                changeIndicator={
                                                    isComparisonIntervalEnabled ? (
                                                        <ChangeIndicator
                                                            change={calculatePercentageChange(
                                                                footfallData.currentInterval
                                                                    .intervalDailyAverageVisitors,
                                                                footfallData.previousInterval
                                                                    ?.intervalDailyAverageVisitors
                                                            )}
                                                            comparisonInterval={comparisonInterval!}
                                                            dataType="people"
                                                            isVisible={Boolean(comparisonInterval)}
                                                            valueToCompare={
                                                                footfallData.previousInterval!
                                                                    .intervalDailyAverageVisitors
                                                            }
                                                            isBigger
                                                        />
                                                    ) : undefined
                                                }
                                                value={footfallData.currentInterval.intervalDailyAverageVisitors}
                                            />
                                        </StatisticBox>
                                        <StatisticBox
                                            interval={interval}
                                            label={t('statistics.visitorsDemography', 'Average visitors demography')}
                                            localities={configuredLocalities}
                                            md={4}
                                            sm={12}
                                            tooltip={t(
                                                'tooltip.visitorsDemography',
                                                'average distribution of male and female gender visitors across all selected localities'
                                            )}
                                        >
                                            <StatisticBoxItem
                                                className={styles.boxGender}
                                                icon={faMale}
                                                iconClass={styles.iconGender}
                                                unit="%"
                                                value={round(
                                                    footfallData.currentInterval.visitorPercentageByGender.male
                                                )}
                                            />
                                            <StatisticBoxItem
                                                className={styles.boxGender}
                                                icon={faFemale}
                                                iconClass={styles.iconGender}
                                                unit="%"
                                                value={round(
                                                    footfallData.currentInterval.visitorPercentageByGender.female
                                                )}
                                            />
                                        </StatisticBox>
                                    </Row>
                                )}
                                <SectionSubheading selectedOrganization={selectedOrganization}>
                                    {t('heading.localityComparison', 'Comparison of localities')}
                                </SectionSubheading>
                                <TableWithSorting
                                    bodyRows={footfallFormattedData}
                                    comparisonInterval={comparisonInterval}
                                    defaultSortingColumn="totalVisitors"
                                    defaultSortingOrder="desc"
                                    excludedSortingColumns={['detail']}
                                    headRow={[
                                        {
                                            name: 'locality',
                                            displayName: t('heading.locality', 'Locality'),
                                        },
                                        {
                                            name: 'totalVisitors',
                                            displayName: t('statistics.totalVisitors', 'Total visitors'),
                                            align: 'right',
                                        },
                                        {
                                            name: 'dailyAverageVisitors',
                                            displayName: t('footfall.dailyVisitors', 'Daily avg. visitors'),
                                            align: 'right',
                                        },
                                        {
                                            name: 'men',
                                            displayName: t('footfall.men', 'Men'),
                                            align: 'right',
                                        },
                                        {
                                            name: 'women',
                                            displayName: t('footfall.women', 'Women'),
                                            align: 'right',
                                        },
                                        {
                                            name: 'detail',
                                            displayName: t('table.actions', 'Actions'),
                                            align: 'right',
                                        },
                                    ]}
                                    paginationSize={15}
                                />
                            </>
                        )}
                        {!isEmpty(notConfiguredLocalities) && (
                            <NotConfiguredLocalitiesList
                                notConfiguredLocalities={notConfiguredLocalities}
                                nothingConfigured={isEmpty(configuredLocalities)}
                            />
                        )}
                    </Box>
                )
            }}
        </LegacyLoadingWrapper>
    )
}

export default FootfallSection
