/* tslint:disable */
/* eslint-disable */
/**
 * Vividi Dashboard API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DatetimeInterval,
    DatetimeIntervalFromJSON,
    DatetimeIntervalFromJSONTyped,
    DatetimeIntervalToJSON,
} from './DatetimeInterval';
import {
    ZoneOccupancySummaryRow,
    ZoneOccupancySummaryRowFromJSON,
    ZoneOccupancySummaryRowFromJSONTyped,
    ZoneOccupancySummaryRowToJSON,
} from './ZoneOccupancySummaryRow';

/**
 * 
 * @export
 * @interface ZoneOccupancySummaryResponse
 */
export interface ZoneOccupancySummaryResponse {
    /**
     * 
     * @type {Array<ZoneOccupancySummaryRow>}
     * @memberof ZoneOccupancySummaryResponse
     */
    zoneRows: Array<ZoneOccupancySummaryRow>;
    /**
     * 
     * @type {Array<DatetimeInterval>}
     * @memberof ZoneOccupancySummaryResponse
     */
    timeBrackets: Array<DatetimeInterval>;
    /**
     * 
     * @type {Array<number>}
     * @memberof ZoneOccupancySummaryResponse
     */
    openedPortions: Array<number>;
}

export function ZoneOccupancySummaryResponseFromJSON(json: any): ZoneOccupancySummaryResponse {
    return ZoneOccupancySummaryResponseFromJSONTyped(json, false);
}

export function ZoneOccupancySummaryResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ZoneOccupancySummaryResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'zoneRows': ((json['zone_rows'] as Array<any>).map(ZoneOccupancySummaryRowFromJSON)),
        'timeBrackets': ((json['time_brackets'] as Array<any>).map(DatetimeIntervalFromJSON)),
        'openedPortions': json['opened_portions'],
    };
}

export function ZoneOccupancySummaryResponseToJSON(value?: ZoneOccupancySummaryResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'zone_rows': ((value.zoneRows as Array<any>).map(ZoneOccupancySummaryRowToJSON)),
        'time_brackets': ((value.timeBrackets as Array<any>).map(DatetimeIntervalToJSON)),
        'opened_portions': value.openedPortions,
    };
}

