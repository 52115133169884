/* tslint:disable */
/* eslint-disable */
/**
 * Vividi Dashboard API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DeviceStatusModel,
    DeviceStatusModelFromJSON,
    DeviceStatusModelFromJSONTyped,
    DeviceStatusModelToJSON,
} from './DeviceStatusModel';
import {
    DeviceTransferInfo,
    DeviceTransferInfoFromJSON,
    DeviceTransferInfoFromJSONTyped,
    DeviceTransferInfoToJSON,
} from './DeviceTransferInfo';

/**
 * 
 * @export
 * @interface DeviceResponse
 */
export interface DeviceResponse {
    /**
     * 
     * @type {string}
     * @memberof DeviceResponse
     */
    note?: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceResponse
     */
    color?: string;
    /**
     * 
     * @type {number}
     * @memberof DeviceResponse
     */
    organizationId?: number;
    /**
     * 
     * @type {string}
     * @memberof DeviceResponse
     */
    simPhoneNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceResponse
     */
    vividiImageName?: string;
    /**
     * 
     * @type {number}
     * @memberof DeviceResponse
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof DeviceResponse
     */
    registeredAt: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceResponse
     */
    lastCaptureAt?: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceResponse
     */
    lastConnectedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceResponse
     */
    deviceId: string;
    /**
     * 
     * @type {DeviceStatusModel}
     * @memberof DeviceResponse
     */
    lastStatus?: DeviceStatusModel;
    /**
     * 
     * @type {boolean}
     * @memberof DeviceResponse
     */
    isApproved: boolean;
    /**
     * 
     * @type {DeviceTransferInfo}
     * @memberof DeviceResponse
     */
    transferred?: DeviceTransferInfo;
}

export function DeviceResponseFromJSON(json: any): DeviceResponse {
    return DeviceResponseFromJSONTyped(json, false);
}

export function DeviceResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeviceResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'note': !exists(json, 'note') ? undefined : json['note'],
        'color': !exists(json, 'color') ? undefined : json['color'],
        'organizationId': !exists(json, 'organization_id') ? undefined : json['organization_id'],
        'simPhoneNumber': !exists(json, 'sim_phone_number') ? undefined : json['sim_phone_number'],
        'vividiImageName': !exists(json, 'vividi_image_name') ? undefined : json['vividi_image_name'],
        'id': json['id'],
        'registeredAt': json['registered_at'],
        'lastCaptureAt': !exists(json, 'last_capture_at') ? undefined : json['last_capture_at'],
        'lastConnectedAt': !exists(json, 'last_connected_at') ? undefined : json['last_connected_at'],
        'deviceId': json['device_id'],
        'lastStatus': !exists(json, 'last_status') ? undefined : DeviceStatusModelFromJSON(json['last_status']),
        'isApproved': json['is_approved'],
        'transferred': !exists(json, 'transferred') ? undefined : DeviceTransferInfoFromJSON(json['transferred']),
    };
}

export function DeviceResponseToJSON(value?: DeviceResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'note': value.note,
        'color': value.color,
        'organization_id': value.organizationId,
        'sim_phone_number': value.simPhoneNumber,
        'vividi_image_name': value.vividiImageName,
        'id': value.id,
        'registered_at': value.registeredAt,
        'last_capture_at': value.lastCaptureAt,
        'last_connected_at': value.lastConnectedAt,
        'device_id': value.deviceId,
        'last_status': DeviceStatusModelToJSON(value.lastStatus),
        'is_approved': value.isApproved,
        'transferred': DeviceTransferInfoToJSON(value.transferred),
    };
}

