import React from 'react'
import { Table, TableProps } from 'react-bootstrap'

import styles from './ResponsiveTable.module.scss'

interface Props extends TableProps {
    children: JSX.Element[]
    className?: string
}

const ResponsiveTable = (props: Props) => (
    <Table {...props} className={`${styles.responsiveTable} ${props.className}`} />
)

export default ResponsiveTable
