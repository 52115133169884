import { isEmpty } from 'lodash'
import { useEffect, useState } from 'react'
import Helmet from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useHistory, useParams, useRouteMatch } from 'react-router'

import { LocalityFloorplanDetails, LocalityResponse, OrganizationResponse } from '@api'

import { localityApi } from '@services'

import {
    generateOrganizationEditLocalityFloorplanPath,
    generateOrganizationsEditLocalitiesPath,
    ORGANIZATION_LOCALITY_EDIT_FLOORPLAN_PATH,
} from '@helpers/VividiURLs'
import { useApiCallCleaner } from '@helpers/api'

import { useUploadFloorplan } from '@hooks/useUploadFloorplan'

import LegacyLoadingWrapper from '@components/LegacyLoadingWrapper'
import { useNotify } from '@components/notifications/NotificationsContext'

import Floorplan from './Floorplan'

interface Props {
    organization: OrganizationResponse
    locality: LocalityResponse
    onModalClose: () => void
}

export const FloorplanForm = ({ organization, locality, onModalClose }: Props) => {
    const { t } = useTranslation()
    const history = useHistory()

    const notify = useNotify()
    const queryClient = useQueryClient()
    const params = useParams<{ floorplanId?: string }>()

    const floorplanId = params.floorplanId ? parseInt(params.floorplanId, 10) : undefined

    const isFloorplanTabPage = useRouteMatch(ORGANIZATION_LOCALITY_EDIT_FLOORPLAN_PATH) !== null

    const documentTitle = `${locality.name} - ${t('tab.floorplan', 'Floor plan')}`

    const { open } = useUploadFloorplan({
        organizationId: organization.id,
        localityId: locality.id,
    })

    const [selectedFloorplan, setSelectedFloorplan] = useState<number | undefined>(undefined)

    useEffect(() => {
        if (floorplanId) {
            setSelectedFloorplan(floorplanId)
        } else if (selectedFloorplan === undefined && !isEmpty(locality.floorplans) && isFloorplanTabPage) {
            handleFloorplanSelection(Number(Object.keys(locality.floorplans)[0]))
        }
    }, [floorplanId, isFloorplanTabPage, locality.floorplans])

    const handleFloorplanSelection = (floorplanId: number) => {
        const url = generateOrganizationEditLocalityFloorplanPath(organization.id, locality.id, floorplanId)
        history.replace(url)
    }

    const clean = useApiCallCleaner()

    const { mutate: deleteFloorplan } = useMutation(localityApi.removeLocalityFloorplan, {
        onSuccess: () => {
            clean(localityApi)
            notify({
                title: t('notification.floorplanDeleted', 'Floor plan deleted'),
                content: t('notification.floorplanDeletedContent', 'Floor plan deleted successfully.'),
                variant: 'success',
            })
            history.push(generateOrganizationsEditLocalitiesPath(organization.id, locality.id))
        },
        onError: () => {
            notify({
                title: t('notification.floorplanDeletedFailed', 'Floor plan delete failed'),
                content: t(
                    'notification.floorplanDeletedFailedContent',
                    'An error occurred while attempting to delete the floor plan.'
                ),
                variant: 'danger',
            })
        },
    })
    const detailsCall = useQuery({
        ...localityApi.getLocalityFloorplanDetails.query({
            organizationId: organization.id,
            localityId: locality.id,
            floorplanId: selectedFloorplan!,
        }),
        enabled: selectedFloorplan !== -1 && selectedFloorplan !== undefined,
    })

    const {
        mutate: updateDetails,
        status: detailsStatus,
        reset: floorplanDetailsReset,
    } = useMutation(localityApi.updateLocalityFloorplanDetails, {
        onSuccess: () => {
            floorplanDetailsReset()
            onModalClose()

            //invalidate floorplan details and locality floorplan list to update the floorplan name and picker
            queryClient.invalidateQueries(localityApi.getLocalityFloorplanDetails.mutationKey)
            queryClient.invalidateQueries(localityApi.getLocality.mutationKey)
        },
    })

    const handleFloorplanDelete = (floorplanId: number) =>
        deleteFloorplan({
            organizationId: organization.id,
            localityId: locality.id,
            floorplanId,
        })

    const handleFloorplanUpdate = (details: LocalityFloorplanDetails) =>
        updateDetails({
            organizationId: organization.id,
            localityId: locality.id,
            floorplanId: selectedFloorplan!,
            body: details,
        })

    return (
        <>
            <Helmet>
                <title>{documentTitle}</title>
            </Helmet>
            <div>
                {!isEmpty(locality.floorplans) && (
                    <LegacyLoadingWrapper request={detailsCall}>
                        {(details) => (
                            <Floorplan
                                key={selectedFloorplan!}
                                floorplanDetails={details}
                                locality={locality}
                                organization={organization}
                                selectedFloorplan={selectedFloorplan!}
                                updateDetailsState={detailsStatus}
                                onCreate={open}
                                onDelete={handleFloorplanDelete}
                                onSelect={handleFloorplanSelection}
                                onUpdateDetails={handleFloorplanUpdate}
                            />
                        )}
                    </LegacyLoadingWrapper>
                )}
            </div>
        </>
    )
}
