import { isEmpty, noop } from 'lodash'
import { DateTime, Interval } from 'luxon'
import { useCookies } from 'react-cookie'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { Redirect, useHistory, useParams } from 'react-router'

import { LocalityNameModel, OrganizationResponse } from '@api'

import { localityApi } from '@services'

import { generateStatisticsSummaryPath } from '@helpers/VividiURLs'
import { mergeQueryResults, visibleLocalitiesQuery, visibleOrganizationsQuery } from '@helpers/api'
import { localeAwareAlign, splitAtMidnight } from '@helpers/datetimeUtils'
import { getComparisonInterval } from '@helpers/intervals'
import { orderLocalities, orderOrganizations } from '@helpers/orderFunctions'
import useProfile from '@helpers/profile'
import { TimezoneConfigProvider, useTimezoneConfig } from '@helpers/timezoneConfig'
import { IntervalType } from '@helpers/types'

import Box from '@elements/Box/Box'

import ErrorView from '@components/ErrorView'
import FeatureChecker from '@components/FeatureChecker'
import BarePageLayout from '@components/Layouts/BarePageLayout'
import SpacedLayout from '@components/Layouts/SpacedLayout'
import LegacyLoadingWrapper from '@components/LegacyLoadingWrapper'
import LoadingSpinner from '@components/LoadingSpinner'
import LoadingWrapper from '@components/LoadingWrapper'
import PageTitle from '@components/StatisticsSummary/PageTitle'
import titleStyles from '@components/StatisticsSummary/PageTitle.module.scss'
import ConversionSection from '@components/StatisticsSummary/Sections/ConversionSection'
import EmotionsSection from '@components/StatisticsSummary/Sections/EmotionsSection'
import HeatmapSectionWrapper from '@components/StatisticsSummary/Sections/FloorplanHeatmapSection'
import FootfallSection from '@components/StatisticsSummary/Sections/FootfallSection'
import OccupancySection from '@components/StatisticsSummary/Sections/OccupancySection'
import QueueSection from '@components/StatisticsSummary/Sections/QueueSection'
import StaySafeSection from '@components/StatisticsSummary/Sections/StaySafeSection'
import { useNotify } from '@components/notifications/NotificationsContext'

type Params = {
    localityIds: string
    intervalStart: string
    intervalEnd: string
    organizationId?: string
    comparisonIntervalStart?: string
    comparisonIntervalEnd?: string
}

const StatisticsSummaryPageParamsNormalizer = () => {
    const { t } = useTranslation()
    const params = useParams<Params>()
    const [cookies] = useCookies(['showHistoricalTrend'])
    const zone = useTimezoneConfig()

    const profileCall = useProfile()

    const localitiesCall = useQuery({
        ...visibleLocalitiesQuery(profileCall.data),
    })

    const organizationsCall = useQuery({
        ...visibleOrganizationsQuery(profileCall.data),
    })

    const request = mergeQueryResults(localitiesCall, organizationsCall)

    const intervalStart =
        params.intervalStart !== undefined
            ? localeAwareAlign(
                  DateTime.fromMillis(Number(params.intervalStart), {
                      zone: 'UTC',
                  }),
                  IntervalType.DAY,
                  zone
              )
            : DateTime.utc().setZone(zone).minus({ week: 1 }).startOf('week').toUTC()

    const intervalEnd =
        params.intervalEnd !== undefined
            ? localeAwareAlign(
                  DateTime.fromMillis(Number(params.intervalEnd), {
                      zone: 'UTC',
                  }),
                  IntervalType.DAY,
                  zone
              )
            : intervalStart.setZone(zone).plus({ week: 1 }).toUTC()

    const comparisonIntervalStart =
        params.comparisonIntervalStart !== undefined
            ? DateTime.fromMillis(Number(params.comparisonIntervalStart), {
                  zone: 'UTC',
              })
            : undefined

    const comparisonIntervalEnd =
        params.comparisonIntervalEnd !== undefined
            ? DateTime.fromMillis(Number(params.comparisonIntervalEnd), {
                  zone: 'UTC',
              })
            : undefined

    const interval = Interval.fromDateTimes(intervalStart, intervalEnd)

    const comparisonInterval =
        comparisonIntervalStart && comparisonIntervalEnd
            ? Interval.fromDateTimes(comparisonIntervalStart, comparisonIntervalEnd)
            : undefined

    const selectedOrganizationId = isNaN(Number(params.organizationId)) ? undefined : Number(params.organizationId)

    const heading = (
        <PageTitle
            comparisonInterval={comparisonInterval}
            interval={interval}
            localities={[]}
            organizations={[]}
            selectedLocalities={[]}
            onCustomIntervalChange={noop}
            onLocalitiesChange={noop}
            onSelectOrganization={noop}
        >
            {t('heading.statisticsSummary', 'Statistics Summary')}
        </PageTitle>
    )

    if (request.status === 'idle' || request.status === 'loading') {
        return (
            <BarePageLayout heading={heading} headingClassName={titleStyles.heading}>
                <LoadingSpinner />
            </BarePageLayout>
        )
    }

    if (request.status === 'error') {
        return (
            <BarePageLayout heading={heading} headingClassName={titleStyles.heading}>
                <ErrorView
                    message={t('others.noLocalitiesToView', 'There are no localities for you to view.')}
                    title={t('others.nothingToDisplay', 'Nothing to display')}
                />
            </BarePageLayout>
        )
    }

    let selectedLocalityIds: number[] = []
    const organizations = organizationsCall.data!.organizations

    if (organizations.length === 0) {
        return (
            <BarePageLayout heading={heading} headingClassName={titleStyles.heading}>
                <ErrorView
                    message={t('others.noOrganizationsToView', 'There are no organizations for you to view.')}
                    title={t('others.nothingToDisplay', 'Nothing to display')}
                />
            </BarePageLayout>
        )
    }

    const sortedOrganizations = orderOrganizations(organizations)

    if (params.organizationId === undefined) {
        return (
            <Redirect
                to={generateStatisticsSummaryPath(
                    true,
                    selectedLocalityIds,
                    interval,
                    comparisonInterval,
                    sortedOrganizations[0].id
                )}
            />
        )
    }

    const selectedOrganization =
        sortedOrganizations.find((o) => o.id === selectedOrganizationId) ?? sortedOrganizations[0]

    if (selectedOrganization === undefined) {
        return (
            <Redirect
                to={generateStatisticsSummaryPath(
                    selectedLocalityIds.length === localitiesCall.data!.localities.length,
                    selectedLocalityIds,
                    interval,
                    comparisonInterval
                )}
            />
        )
    }

    selectedLocalityIds = localitiesCall
        .data!.localities.filter((l) => l.organizationId === selectedOrganization.id)
        .map((l) => l.id)

    if (localitiesCall.data!.localities.length !== 0) {
        const firstLocality = localitiesCall.data!.localities[0]
        selectedLocalityIds = localitiesCall
            .data!.localities.filter((l) => l.organizationId === firstLocality.organizationId)
            .map((l) => l.id)
    }

    if (localitiesCall.data!.localities.length === 0) {
        return (
            <BarePageLayout heading={heading} headingClassName={titleStyles.heading}>
                <ErrorView
                    message={t('others.noAssignedLocalities', 'You have no assigned localities.')}
                    title={t('others.nothingToDisplay', 'Nothing to display')}
                />
            </BarePageLayout>
        )
    }

    if (isEmpty(selectedLocalityIds)) {
        return <Redirect to={generateStatisticsSummaryPath(true, selectedLocalityIds, interval, comparisonInterval)} />
    }

    if (params.intervalStart === undefined || params.intervalEnd === undefined) {
        return (
            <Redirect
                to={generateStatisticsSummaryPath(
                    selectedLocalityIds.length === localitiesCall.data!.localities.length,
                    selectedLocalityIds,
                    interval,
                    comparisonInterval,
                    selectedOrganization.id
                )}
            />
        )
    }

    if (
        (params.comparisonIntervalStart === undefined || params.comparisonIntervalEnd === undefined) &&
        cookies.showHistoricalTrend === 'true'
    ) {
        return (
            <Redirect
                to={generateStatisticsSummaryPath(
                    selectedLocalityIds.length === localitiesCall.data!.localities.length,
                    selectedLocalityIds,
                    interval,
                    getComparisonInterval(interval, zone),
                    selectedOrganization.id
                )}
            />
        )
    }

    if (params.comparisonIntervalStart && params.comparisonIntervalEnd && cookies.showHistoricalTrend === 'false') {
        return (
            <Redirect
                to={generateStatisticsSummaryPath(
                    selectedLocalityIds.length === localitiesCall.data!.localities.length,
                    selectedLocalityIds,
                    interval,
                    undefined,
                    selectedOrganization.id
                )}
            />
        )
    }

    const firstLocalityZoneName =
        localitiesCall.data!.localities.find((l) => l.organizationId === selectedOrganization.id)?.timezone ?? 'UTC'

    return (
        <TimezoneConfigProvider timezone={firstLocalityZoneName}>
            <StatisticsSummaryPage />
        </TimezoneConfigProvider>
    )
}

const StatisticsSummaryPage = () => {
    const [, setCookie] = useCookies(['showHistoricalTrend'])
    const { t } = useTranslation()
    const params = useParams<Params>()
    const notify = useNotify()
    const history = useHistory()
    const selectedLocalityIds =
        params.localityIds === 'all' ? undefined : params.localityIds.split('-').map((localityId) => Number(localityId))

    const intervalStart = DateTime.fromMillis(Number(params.intervalStart), {
        zone: 'UTC',
    })
    const intervalEnd = DateTime.fromMillis(Number(params.intervalEnd), {
        zone: 'UTC',
    })
    const comparisonIntervalStart =
        params.comparisonIntervalStart !== undefined
            ? DateTime.fromMillis(Number(params.comparisonIntervalStart), {
                  zone: 'UTC',
              })
            : undefined

    const comparisonIntervalEnd =
        params.comparisonIntervalEnd !== undefined
            ? DateTime.fromMillis(Number(params.comparisonIntervalEnd), {
                  zone: 'UTC',
              })
            : undefined

    const selectedOrganizationId = Number(params.organizationId)

    const zone = useTimezoneConfig()
    const interval = Interval.fromDateTimes(intervalStart, intervalEnd)

    const comparisonInterval =
        comparisonIntervalStart && comparisonIntervalEnd
            ? Interval.fromDateTimes(comparisonIntervalStart, comparisonIntervalEnd)
            : undefined

    const profileCall = useProfile()

    const localitiesCall = useQuery(localityApi.getLocalityConfigurationStates.query())

    const organizationsCall = useQuery({
        ...visibleOrganizationsQuery(profileCall.data),
        enabled: profileCall.data !== undefined,
    })

    const partitionedInterval = {
        intervals: splitAtMidnight(interval, zone).map((i) => ({
            startingFrom: i.start.toISO(),
            endingAt: i.end.toISO(),
        })),
    }
    const partitionedComparisonInterval = {
        intervals: comparisonInterval
            ? splitAtMidnight(comparisonInterval, zone).map((i) => ({
                  startingFrom: i.start.toISO(),
                  endingAt: i.end.toISO(),
              }))
            : [],
    }

    const intervalDays = splitAtMidnight(interval, zone).map(({ start }) => start.setZone(zone))
    const comparisonIntervalDays = comparisonInterval
        ? splitAtMidnight(comparisonInterval, zone).map(({ start }) => start.setZone(zone))
        : undefined

    const handleOrganizationSelect = (organization: OrganizationResponse, localities: LocalityNameModel[]) => {
        const localitiesInOrganization = localities.filter((l) => organization.id === l.organizationId)

        history.push(
            generateStatisticsSummaryPath(
                true,
                localitiesInOrganization.map((l) => l.id),
                interval,
                comparisonInterval,
                organization.id
            )
        )
    }

    const handleLocalitiesSelect = (localities: LocalityNameModel[]) => {
        if (localities.length === 0) {
            notify({
                title: t('notification.localitySelection', 'Locality selection'),
                content: t('notification.atLeastOneLocality', 'At least one locality needs to be selected'),
                variant: 'warning',
                timeoutSeconds: 1.5,
            })

            return
        }

        const localityOrganization = localities[0]
        const selectableLocalities = localitiesCall.data?.localities.filter(
            (l) => l.organizationId === localityOrganization.organizationId
        )

        history.push(
            generateStatisticsSummaryPath(
                selectableLocalities?.length === localities.length,
                localities.map((l) => l.id),
                interval,
                comparisonInterval,
                selectedOrganizationId
            )
        )
    }

    const handleIntervalSelect = (interval: Interval, comparingInterval?: Interval) => {
        const intervalLengthDays = interval.count('days') - 1

        if (intervalLengthDays > 180) {
            notify({
                title: t('notification.loadingData', 'Loading your data'),
                content: t(
                    'notification.longIntervalSelected',
                    `You selected ${intervalLengthDays} days long interval which might take longer to load.`,
                    { intervalLengthDays }
                ),
                variant: 'warning',
                timeoutSeconds: 5,
            })
        }

        const isAllLocalities = params.localityIds === 'all'
        const selectedLocalityIds = isAllLocalities ? [] : params.localityIds.split('-').map(Number)

        setCookie('showHistoricalTrend', comparingInterval !== undefined, {
            path: '/',
        })

        history.push(
            generateStatisticsSummaryPath(
                isAllLocalities,
                selectedLocalityIds,
                interval,
                comparingInterval,
                selectedOrganizationId
            )
        )
    }

    const request = mergeQueryResults(organizationsCall, localitiesCall)
    const documentTitle = t('title.statisticsSummary', 'Statistics Summary')

    return (
        <LegacyLoadingWrapper
            placeholder={
                <BarePageLayout
                    documentTitle={documentTitle}
                    heading={
                        <PageTitle
                            comparisonInterval={comparisonInterval}
                            interval={interval}
                            localities={[]}
                            organizations={[]}
                            selectedLocalities={[]}
                            onCustomIntervalChange={noop}
                            onLocalitiesChange={noop}
                            onSelectOrganization={noop}
                        >
                            {t('heading.statisticsSummary', 'Statistics Summary')}
                        </PageTitle>
                    }
                    headingClassName={titleStyles.heading}
                >
                    <LoadingSpinner />
                </BarePageLayout>
            }
            request={request}
        >
            {([organizations, localities]) => {
                const organization =
                    organizations.organizations.find((o) => o.id === selectedOrganizationId) ??
                    organizations.organizations[0]

                const selectedLocalities = localities.localities.filter((l) => {
                    if (selectedLocalityIds === undefined) {
                        return true
                    }

                    return selectedLocalityIds.includes(l.id)
                })
                const isLocalitySelectionValid =
                    selectedLocalities && selectedLocalities.every((l) => l.organizationId === organization.id)

                const offeredLocalities = orderLocalities(
                    localities.localities.filter((l) => l.organizationId === organization.id)
                )

                const localitySelection = orderLocalities(
                    isLocalitySelectionValid ? selectedLocalities! : offeredLocalities
                )

                const shouldDisplayLocalitySummary =
                    organization.isLocalitySummaryEnabled !== false || selectedLocalityIds !== undefined

                return (
                    <BarePageLayout
                        key={organization.id}
                        documentTitle={`${organization.name} ${documentTitle}`}
                        heading={
                            <PageTitle
                                comparisonInterval={comparisonInterval}
                                interval={interval}
                                localities={offeredLocalities}
                                organizations={organizations.organizations}
                                selectedLocalities={localitySelection}
                                selectedOrganization={organization}
                                onCustomIntervalChange={handleIntervalSelect}
                                onLocalitiesChange={handleLocalitiesSelect}
                                onSelectOrganization={(o) => handleOrganizationSelect(o, localities.localities)}
                            >
                                {t('heading.statisticsSummary', 'Statistics Summary')}
                            </PageTitle>
                        }
                        headingClassName={titleStyles.heading}
                    >
                        <SpacedLayout>
                            <FeatureChecker
                                key="footfall"
                                allowForAdmin={false}
                                feature="footfall"
                                organization={organization}
                            >
                                <FootfallSection
                                    comparisonDays={comparisonIntervalDays}
                                    comparisonInterval={comparisonInterval}
                                    days={intervalDays}
                                    interval={interval}
                                    localities={offeredLocalities}
                                    selectedLocalities={localitySelection}
                                    selectedOrganization={organization}
                                    shouldDisplayLocalitySummary={shouldDisplayLocalitySummary}
                                />
                            </FeatureChecker>
                            <FeatureChecker
                                key="realtimeOccupancy"
                                allowForAdmin={false}
                                feature="realtimeOccupancy"
                                organization={organization}
                            >
                                <StaySafeSection
                                    interval={interval}
                                    sectionType="StaySafe"
                                    selectedLocalities={localitySelection}
                                />
                            </FeatureChecker>
                            <FeatureChecker
                                key="conversions"
                                allowForAdmin={false}
                                feature="conversions"
                                organization={organization}
                            >
                                <LoadingWrapper
                                    placeholder={
                                        <Box paddingSize="lg">
                                            <Box.Title
                                                text={t('heading.conversionsStatistics', 'Conversions Statistics')}
                                            />
                                            <LoadingSpinner bare />
                                        </Box>
                                    }
                                >
                                    <ConversionSection
                                        comparisonDays={comparisonIntervalDays}
                                        comparisonInterval={comparisonInterval}
                                        days={intervalDays}
                                        interval={interval}
                                        localities={offeredLocalities}
                                        selectedLocalities={localitySelection}
                                        selectedOrganization={organization}
                                        shouldDisplayLocalitySummary={shouldDisplayLocalitySummary}
                                    />
                                </LoadingWrapper>
                            </FeatureChecker>
                            <FeatureChecker
                                key="queueMonitoring"
                                allowForAdmin={false}
                                feature="queueMonitoring"
                                organization={organization}
                            >
                                <QueueSection
                                    comparisonInterval={comparisonInterval}
                                    interval={interval}
                                    localities={offeredLocalities}
                                    partitionedInterval={partitionedInterval}
                                    selectedLocalities={localitySelection}
                                    selectedOrganization={organization}
                                    shouldDisplayLocalitySummary={shouldDisplayLocalitySummary}
                                />
                            </FeatureChecker>
                            <FeatureChecker
                                key="staySafeFloorplan"
                                allowForAdmin={false}
                                feature="staySafeFloorplan"
                                organization={organization}
                            >
                                <StaySafeSection
                                    interval={interval}
                                    sectionType="StaySafeFloorplan"
                                    selectedLocalities={localitySelection}
                                />
                            </FeatureChecker>
                            <FeatureChecker
                                key="floorplans"
                                allowForAdmin={false}
                                feature="floorplans"
                                organization={organization}
                            >
                                <HeatmapSectionWrapper selectedLocalities={localitySelection} />
                            </FeatureChecker>
                            <FeatureChecker
                                key="zoneOccupancy"
                                allowForAdmin={false}
                                feature="zoneOccupancy"
                                organization={organization}
                            >
                                <OccupancySection
                                    comparisonInterval={comparisonInterval}
                                    interval={interval}
                                    localities={offeredLocalities}
                                    selectedLocalities={localitySelection}
                                    selectedOrganization={organization}
                                    shouldDisplayLocalitySummary={shouldDisplayLocalitySummary}
                                    zone={zone}
                                />
                            </FeatureChecker>
                            <FeatureChecker
                                key="emotions"
                                allowForAdmin={false}
                                feature="emotions"
                                organization={organization}
                            >
                                <EmotionsSection
                                    comparisonInterval={comparisonInterval}
                                    interval={interval}
                                    localities={offeredLocalities}
                                    partitionedComparisonInterval={partitionedComparisonInterval}
                                    partitionedInterval={partitionedInterval}
                                    selectedLocalities={localitySelection}
                                    selectedOrganization={organization}
                                    shouldDisplayLocalitySummary={shouldDisplayLocalitySummary}
                                />
                            </FeatureChecker>
                        </SpacedLayout>
                    </BarePageLayout>
                )
            }}
        </LegacyLoadingWrapper>
    )
}

export default StatisticsSummaryPageParamsNormalizer
