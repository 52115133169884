import { Trans, useTranslation } from 'react-i18next'
import { useMutation, UseQueryResult } from 'react-query'

import { deviceApi, sceneApi } from '@services'

import { useNotify } from '@components/notifications/NotificationsContext'

import { CameraTab } from './DevicesEditForm'

const useDeviceEditActions = ({
    referenceCaptureRequest,
    storedSnapshotRequest,
    handleTabChange,
    updateLiveView,
}: {
    referenceCaptureRequest: UseQueryResult<Blob>
    storedSnapshotRequest: UseQueryResult<Blob>
    handleTabChange: (tab: CameraTab) => void
    updateLiveView: () => void
}) => {
    const notify = useNotify()
    const { t } = useTranslation()

    const { mutate: requestSystemRestart, status: requestSystemRestartState } = useMutation(
        deviceApi.requestSystemRestart,
        {
            onSuccess: () => {
                notify({
                    title: t('notification.success', 'Success'),
                    variant: 'success',
                    timeoutSeconds: 5,
                    content: t('notification.systemRestart', 'System restarted successfully'),
                })
            },
            onError: () => {
                notify({
                    title: t('notification.warning', 'Warning'),
                    variant: 'warning',
                    timeoutSeconds: 5,
                    content: t('notification.systemRestartFailed', 'System restart failed'),
                })
            },
        }
    )

    const { mutate: requestWifiReset, status: requestWifiResetState } = useMutation(deviceApi.requestWifiReset, {
        onSuccess: () => {
            notify({
                title: t('notification.success', 'Success'),
                variant: 'success',
                timeoutSeconds: 5,
                content: t('notification.wifiDisconnect', 'Wi-Fi disconnected successfully'),
            })
        },
        onError: () => {
            notify({
                title: t('notification.warning', 'Warning'),
                variant: 'warning',
                timeoutSeconds: 5,
                content: t('notification.cameraRefocusFailed', 'Wi-Fi connection termination failed'),
            })
        },
    })

    const { mutate: requestCameraRefocus, status: requestCameraRefocusState } = useMutation(deviceApi.sendCommand, {
        onSuccess: () => {
            notify({
                title: t('notification.success', 'Success'),
                variant: 'success',
                timeoutSeconds: 5,
                content: t('notification.cameraRefocus', 'Camera refocused successfully'),
            })
        },
        onError: () => {
            notify({
                title: t('notification.warning', 'Warning'),
                variant: 'warning',
                timeoutSeconds: 5,
                content: t('notification.cameraRefocusFailed', 'Camera refocus failed'),
            })
        },
    })

    const { mutate: setRequestRefCapture, status: setRequestRefCaptureState } = useMutation(deviceApi.sendCommand, {
        onSuccess: () => {
            referenceCaptureRequest.refetch()
            notify({
                title: t('notification.success', 'Success'),
                variant: 'success',
                timeoutSeconds: 5,
                content: t('notification.setRefCapture', 'Reference capture updated successfully '),
            })

            handleTabChange(CameraTab.REFERENCE_CAPTURE)
        },
        onError: () => {
            notify({
                title: t('notification.warning', 'Warning'),
                variant: 'warning',
                timeoutSeconds: 5,
                content: t('notification.setRefCaptureFailed', 'Reference capture update failed'),
            })
        },
    })

    const { mutate: updateSnapshot, status: updateSnapshotState } = useMutation(sceneApi.updateSceneSnapshot, {
        onSuccess: () => {
            storedSnapshotRequest.refetch()

            handleTabChange(CameraTab.STORED_IMAGE)

            notify({
                title: t('notification.snapshotSaved', 'Snapshot successfully saved'),
                variant: 'success',
                timeoutSeconds: 5,
                content: (
                    <Trans i18nKey="notification.snapshotWarningContent">
                        Please, make sure the newly updated snapshot does <strong>not contain images of people</strong>.
                        The snapshots are used for illustrative purposes in pages that are visible to{' '}
                        <strong>all members</strong> of your organization.
                    </Trans>
                ),
            })
        },
    })

    const { mutate: updateCaptureSettings } = useMutation(sceneApi.updateCaptureSettings, {
        onSuccess: () => {
            //device needs some time to actually set the flipped live view as the current one
            setTimeout(() => {
                updateLiveView()
            }, 2000)

            notify({
                title: t('notification.warning', 'Warning'),
                content: t(
                    'notification.snapshotFlipped',
                    'Please retake the snapshot once the change is propagated to the device.'
                ),
                variant: 'warning',
                timeoutSeconds: 5,
            })
        },
        onError: () => {
            notify({
                title: t('notification.error', 'Error'),
                content: t('notification.deviceImageFlipWentWrong', 'Something went wrong. Device image flip failed.'),
                variant: 'danger',
                timeoutSeconds: 4,
            })
        },
    })

    return {
        actions: {
            requestSystemRestart,
            requestWifiReset,
            requestCameraRefocus,
            setRequestRefCapture,
            updateSnapshot,
            updateCaptureSettings,
        },
        states: {
            requestSystemRestartState,
            requestWifiResetState,
            requestCameraRefocusState,
            setRequestRefCaptureState,
            updateSnapshotState,
        },
    }
}

export default useDeviceEditActions
