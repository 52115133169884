import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import { deburr, isEmpty, uniq } from 'lodash'
import React, { useMemo, useState } from 'react'
import { Dropdown, DropdownButtonProps } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import { LocalityNameModel, OrganizationFeatures, OrganizationResponse } from '@api/models'

import { orderLocalities, orderOrganizations } from '@helpers/orderFunctions'

import TextInput from './GenericInputs/TextInput'
import styles from './StatisticsSummary/FilterButton.module.scss'

interface Props {
    localities: { [localityId: number]: LocalityNameModel }
    organizations: { [organizationId: number]: OrganizationResponse }
    features: Array<keyof OrganizationFeatures>
    onSelect: (locality: LocalityNameModel) => void
    selectedLocality?: LocalityNameModel
    className?: string
    toggleClassName?: string
    variant?: DropdownButtonProps['variant']
    block?: boolean
    placeholder?: string
}

const LocalityPicker: React.FC<Props> = ({
    organizations,
    localities,
    features,
    selectedLocality,
    onSelect,
    className,
    variant = 'secondary',
    block = true,
    placeholder,
    toggleClassName,
}) => {
    const { t } = useTranslation()

    const [search, setSearch] = useState('')

    const orderedOrganizationsWithFeatures = useMemo(
        () =>
            orderOrganizations(
                Object.values(organizations).filter((organization) => features.every((f) => organization.features[f]))
            ).map((organization) => ({ ...organization, normalizedName: deburr(organization.name) })),

        [organizations]
    )

    const filteredLocalities = useMemo(
        () =>
            search !== ''
                ? Object.values(localities)
                      .map((locality) => ({ ...locality, normalizedName: deburr(locality.name) }))
                      .filter((locality) =>
                          locality.normalizedName.toLowerCase().includes(deburr(search).toLowerCase())
                      )
                : [],
        [search, localities]
    )

    const orderedLocalities = useMemo(
        () =>
            orderLocalities(
                Object.values(localities).map((locality) => ({ ...locality, normalizedName: deburr(locality.name) }))
            ),
        [filteredLocalities]
    )

    const filteredOrganizations = useMemo(() => {
        return orderedOrganizationsWithFeatures.filter((organization) =>
            organization.normalizedName.toLowerCase().includes(deburr(search).toLowerCase())
        )
    }, [search, organizations, orderedOrganizationsWithFeatures])

    return (
        <Dropdown
            className={className}
            onSelect={(eventKey) => {
                if (eventKey === null) {
                    return
                }

                if (onSelect) {
                    onSelect(Object.values(localities).find((l) => l.id.toString() === eventKey)!)
                }
            }}
        >
            <Dropdown.Toggle
                className={classNames({ 'btn-block': block }, toggleClassName)}
                disabled={isEmpty(Object.values(localities))}
                variant={variant}
            >
                <FontAwesomeIcon icon={faMapMarkerAlt} />
                {isEmpty(Object.values(localities))
                    ? t('others.nothingToView', 'Nothing to view')
                    : selectedLocality === undefined
                    ? placeholder
                        ? placeholder
                        : t('button.allLocalities', 'All localities')
                    : `${selectedLocality.name}`}
            </Dropdown.Toggle>
            <Dropdown.Menu>
                <TextInput
                    autofocus={true}
                    className={styles.searchInput}
                    placeholder={t('others.searchLocalities', 'Search for localities')}
                    value={search}
                    onChange={setSearch}
                />
                {uniq([
                    ...filteredOrganizations,
                    ...orderedOrganizationsWithFeatures.filter((organization) =>
                        filteredLocalities.map((it) => it.organizationId).includes(organization.id)
                    ),
                ]).map((organization) => {
                    const orgNameMatchesSearch = filteredOrganizations.some((it) => it.id === organization.id)
                    const allOrgLocalities = orderedLocalities.filter((it) => it.organizationId === organization.id)
                    const filteredOrgLocalities = filteredLocalities.filter(
                        (it) => it.organizationId === organization.id
                    )

                    // If the organization name matches the search, show all localities, otherwise show localities that match the search
                    const filteredLocalitiesInOrganization = orgNameMatchesSearch
                        ? allOrgLocalities
                        : filteredOrgLocalities

                    const localitiesInOrganization = !isEmpty(filteredLocalitiesInOrganization)
                        ? filteredLocalitiesInOrganization
                        : allOrgLocalities

                    if (isEmpty(localitiesInOrganization)) {
                        return null
                    }

                    const options = orderLocalities(localitiesInOrganization).map((locality) => (
                        <Dropdown.Item key={`locality-${locality.id.toString()}`} eventKey={locality.id.toString()}>
                            {locality.name}
                        </Dropdown.Item>
                    ))

                    if (organization.name === undefined) {
                        return options
                    }

                    return (
                        <React.Fragment key={organization.name}>
                            {Object.values(organizations).length > 1 && (
                                <Dropdown.Header>{organization.name}</Dropdown.Header>
                            )}
                            {options}
                        </React.Fragment>
                    )
                })}
            </Dropdown.Menu>
        </Dropdown>
    )
}

export default LocalityPicker
