import { DateTime, Interval } from 'luxon'
import React from 'react'

import { gdynusaReadableInterval } from '@helpers/datetimeUtils'
import { useLocalizeDateTime } from '@helpers/timezoneConfig'

import styles from './TimeLineChart.module.scss'

type Props = {
    interval: Interval
    startDate: DateTime
    endDate: DateTime
    now: DateTime
}

const TooltipLabel = (props: Props) => {
    const localize = useLocalizeDateTime()

    const startTime = localize(props.interval.start).toLocaleString(DateTime.TIME_SIMPLE)
    const endTimeString = localize(gdynusaReadableInterval(props.interval).end).toLocaleString(DateTime.TIME_SIMPLE)

    const dateString = localize(props.interval.start).toLocaleString({
        weekday: 'short',
        day: '2-digit',
        month: '2-digit',
    })

    const endInFuture = props.interval.end > props.now
    const endTime = endInFuture ? <span className={styles.future}>{endTimeString}</span> : endTimeString

    if (props.startDate.toISODate() === props.endDate.toISODate()) {
        return (
            <>
                {dateString} {startTime} - {endTime}
            </>
        )
    }

    if (localize(props.interval.start).day === localize(props.interval.end).day) {
        const startDate = localize(props.interval.start).toLocaleString(DateTime.DATE_SHORT)

        return (
            <>
                {dateString} {startDate}, {startTime} - {endTime}
            </>
        )
    }

    if (props.interval.end.diff(props.interval.start).milliseconds === 24 * 60 * 60 * 1000) {
        const startDate = localize(props.interval.start).toLocaleString(DateTime.DATE_SHORT)

        return (
            <>
                {localize(props.interval.start).weekdayLong}, {startDate}
            </>
        )
    }

    const startDate = localize(props.interval.start).toLocaleString(DateTime.DATETIME_SHORT)
    const endDateString = localize(gdynusaReadableInterval(props.interval).end).toLocaleString(DateTime.DATETIME_SHORT)

    const endDate = endInFuture ? <span className={styles.future}>{endDateString}</span> : endDateString

    return (
        <>
            {dateString} {startDate} - {endDate}
        </>
    )
}

export default TooltipLabel
