/* tslint:disable */
/* eslint-disable */
/**
 * Vividi Dashboard API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Point,
    PointFromJSON,
    PointFromJSONTyped,
    PointToJSON,
} from './Point';

/**
 * 
 * @export
 * @interface FloorplanMapping
 */
export interface FloorplanMapping {
    /**
     * 
     * @type {number}
     * @memberof FloorplanMapping
     */
    sceneId: number;
    /**
     * 
     * @type {number}
     * @memberof FloorplanMapping
     */
    localityId: number;
    /**
     * 
     * @type {number}
     * @memberof FloorplanMapping
     */
    floorplanId: number;
    /**
     * A quadrilateral used for transformation of camera coordinates to floorplan coordinates. This must represent the same area as the floorplan mapping polygon.
     * @type {Array<Point>}
     * @memberof FloorplanMapping
     */
    sceneMappingPolygon: Array<Point>;
    /**
     * A quadrilateral used for transformation of camera coordinates to floorplan coordinates. This must represent the same area as the scene mapping polygon.
     * @type {Array<Point>}
     * @memberof FloorplanMapping
     */
    floorplanMappingPolygon: Array<Point>;
    /**
     * Position of the camera on the floorplan. Serves presentation purposes only.
     * @type {Point}
     * @memberof FloorplanMapping
     */
    cameraPosition?: Point | null;
    /**
     * Rotation of the camera on the floorplan in degrees, clockwise, relative to 12:00. Serves presentation purposes only.
     * @type {number}
     * @memberof FloorplanMapping
     */
    cameraRotation?: number;
}

export function FloorplanMappingFromJSON(json: any): FloorplanMapping {
    return FloorplanMappingFromJSONTyped(json, false);
}

export function FloorplanMappingFromJSONTyped(json: any, ignoreDiscriminator: boolean): FloorplanMapping {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sceneId': json['scene_id'],
        'localityId': json['locality_id'],
        'floorplanId': json['floorplan_id'],
        'sceneMappingPolygon': ((json['scene_mapping_polygon'] as Array<any>).map(PointFromJSON)),
        'floorplanMappingPolygon': ((json['floorplan_mapping_polygon'] as Array<any>).map(PointFromJSON)),
        'cameraPosition': !exists(json, 'camera_position') ? undefined : PointFromJSON(json['camera_position']),
        'cameraRotation': !exists(json, 'camera_rotation') ? undefined : json['camera_rotation'],
    };
}

export function FloorplanMappingToJSON(value?: FloorplanMapping | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'scene_id': value.sceneId,
        'locality_id': value.localityId,
        'floorplan_id': value.floorplanId,
        'scene_mapping_polygon': ((value.sceneMappingPolygon as Array<any>).map(PointToJSON)),
        'floorplan_mapping_polygon': ((value.floorplanMappingPolygon as Array<any>).map(PointToJSON)),
        'camera_position': PointToJSON(value.cameraPosition),
        'camera_rotation': value.cameraRotation,
    };
}

