import { Size } from './types'

export const getImageSize = (url: string): Promise<Size> => {
    return new Promise((resolve, reject) => {
        const image = new Image()

        image.onload = () => {
            resolve({
                width: image.width,
                height: image.height,
            })
        }

        image.onerror = () => {
            reject()
        }

        image.src = url
    })
}
