import { faImage, faSync, faTrash, faVideoSlash } from '@fortawesome/free-solid-svg-icons'
import { faBracketsCurly } from '@fortawesome/pro-light-svg-icons'
import { faRotateLeft, faRotateRight } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import React, { useEffect, useState } from 'react'
import { Alert, Button, Col, Form, InputGroup, Row } from 'react-bootstrap'
import { Trans, useTranslation } from 'react-i18next'
import { QueryStatus, useMutation, useQuery } from 'react-query'
import { Link } from 'react-router-dom'

import { CaptureSettings, DeviceResponse, LocalityNameModel, Role, SceneResponse } from '@api'

import { deviceApi, sceneApi } from '@services'

import { generateOrganizationEditDevicePath } from '@helpers/VividiURLs'
import { ErrorEntry } from '@helpers/types'

import IconButton from '@elements/Buttons/IconButton'
import ToggleButton from '@elements/Buttons/ToggleButton'

import AsyncButton from '@components/AsyncButton'
import ControlledModal from '@components/ControlledModal'
import DeviceIcon from '@components/DeviceIcon'
import DevicePicker from '@components/DevicePicker'
import LocalityPickerInput, { LocalityTokenRenderer } from '@components/GenericInputs/LocalityPickerInput'
import TextInput from '@components/GenericInputs/TextInput'
import OverlayedImageWithModal from '@components/OverlayedImageWithModal/OverlayedImageWithModal'
import PopConfirm from '@components/PopConfirm/PopConfirm'
import RoleChecker from '@components/RoleChecker'
import { useNotify } from '@components/notifications/NotificationsContext'

import styles from './SceneEditForm.module.scss'

export const FlipUpsideDownButton: React.FC<{
    deviceUpsideDown: boolean
    liveViewRequestState: QueryStatus
    disabled: boolean
    onClick: () => void
    showWarning: boolean
}> = ({ deviceUpsideDown, onClick, liveViewRequestState, disabled, showWarning }) => {
    const { t } = useTranslation()

    return (
        <>
            {showWarning && (
                <Alert className={styles.alertContainer} variant="warning">
                    <p>
                        {t(
                            'notification.snapshotFlipped',
                            'Please retake the snapshot once the change is propagated to the device.'
                        )}
                    </p>
                </Alert>
            )}
            <Button
                className={classNames(styles.saveButton, {
                    [styles.hiddenSaveButton]: liveViewRequestState === 'error' || liveViewRequestState === 'loading',
                })}
                disabled={disabled}
                variant="secondary"
                onClick={() => onClick()}
            >
                <FontAwesomeIcon icon={deviceUpsideDown ? faRotateLeft : faRotateRight} />
                {t('form.flipImage', 'Flip image upside down')}
            </Button>
        </>
    )
}

export const SceneRow: React.FC<{
    errors: { label: ErrorEntry }
    label: string
    onLabelUpdate: (value: string) => void
    isNewScene?: boolean
}> = ({ errors, label, onLabelUpdate, isNewScene = false }) => {
    const { t } = useTranslation()

    return (
        <Form.Group as={Row}>
            <Col className={styles.label} md={3} sm={12}>
                {t('form.sceneName', 'Name')}
            </Col>
            <Col md={9} sm={12}>
                <InputGroup>
                    <TextInput
                        autofocus={isNewScene}
                        isInvalid={errors.label.isTouched && errors.label.isInvalid}
                        value={label}
                        onChange={onLabelUpdate}
                    />
                </InputGroup>
            </Col>
        </Form.Group>
    )
}

const TrackerConfigurationModal: React.FC<{
    showModal: boolean
    onHide: () => void
    onOpen: () => void
    sceneId: number
}> = ({ showModal, onHide, onOpen, sceneId }) => {
    const { t } = useTranslation()
    const notify = useNotify()

    const options = [
        { label: t('sceneEdit.tracker', 'Tracker'), value: 'tracker' },
        { label: t('sceneEdit.boxManager', 'Box manager'), value: 'boxManager' },
    ]

    const [selectedModule, setSelectedModule] = useState<{
        label: string
        value: string
    }>(options[0])

    const trackerConfigCall = useQuery(
        sceneApi.getTrackerConfig.query({
            sceneId,
        })
    )

    const boxManagerCall = useQuery(
        sceneApi.getBoxManagerConfig.query({
            sceneId,
        })
    )

    const successNotification = {
        title: t('notification.success', 'Success'),
        content: t('sceneEdit.sceneModuleConfigurationUpdated', '{{value}} configuration updated', {
            value: selectedModule.label,
        }),
        variant: 'success',
        timeoutSeconds: 4,
    }

    const failureNotification = {
        title: t('notification.error', 'Error'),
        content: t('sceneEdit.sceneModuleConfigurationUpdateFailed', 'Something went wrong. {{value}} update failed.', {
            value: selectedModule.label,
        }),
        variant: 'danger',
        timeoutSeconds: 4,
    }

    const { mutate: updateTrackerConfig, status: updateTrackerConfigState } = useMutation(sceneApi.setTrackerConfig, {
        onSuccess: () => {
            notify(successNotification)
        },
        onError: () => {
            notify(failureNotification)
        },
    })

    const { mutate: updateBoxManagerConfig } = useMutation(sceneApi.setBoxManagerConfig, {
        onSuccess: () => {
            notify(successNotification)
        },
        onError: () => {
            notify(failureNotification)
        },
    })

    // the type reflects api
    const [script, setScript] = useState<string | null>(null)

    const [errors, setErrors] = useState<ErrorEntry>({
        isTouched: false,
        isInvalid: false,
    })

    const validateJSON = (script: string) => {
        try {
            JSON.parse(script)
            setErrors({
                isTouched: true,
                isInvalid: false,
            })
        } catch (error) {
            setErrors({
                isTouched: true,
                isInvalid: true,
            })
        }

        return script
    }

    const handleCancel = () => {
        setScript(null)
        onHide()
    }

    const handleUpdateScript = () => {
        if (selectedModule.value === 'tracker') {
            updateTrackerConfig({
                sceneId,
                body: { content: script },
            })
        }

        if (selectedModule.value === 'boxManager') {
            updateBoxManagerConfig({
                sceneId,
                body: { content: script },
            })
        }
    }

    useEffect(() => {
        if (trackerConfigCall.status === 'success' && selectedModule?.value === 'tracker') {
            setScript(trackerConfigCall.data.content)
        }

        if (boxManagerCall.status === 'success' && selectedModule?.value === 'boxManager') {
            setScript(boxManagerCall.data.content)
        }
    }, [trackerConfigCall.status, boxManagerCall.status, selectedModule.value])

    return (
        <ControlledModal
            className={styles.trackerConfigDialog}
            control={
                <IconButton
                    icon={faBracketsCurly}
                    tooltipText={t('sceneEdit.sendConfigurationScript', 'Send configuration script')}
                    onClick={onOpen}
                />
            }
            header={t('sceneEdit.sendConfigurationScript', 'Send configuration script')}
            show={showModal}
            centered
            onHide={onHide}
        >
            <div className={styles.optionSelectRow}>
                {t('sceneEdit.sendTo', 'Send to')}
                <div>
                    {options.map(({ label, value }) => (
                        <Form.Check
                            key={value}
                            checked={selectedModule?.value === value}
                            id={`radio-${value}`}
                            label={label}
                            type="radio"
                            value={value}
                            custom
                            inline
                            onChange={() => setSelectedModule({ label, value })}
                        />
                    ))}
                </div>
            </div>
            <Form.Control
                as="textarea"
                className={styles.configurationInput}
                isInvalid={errors.isTouched && errors.isInvalid}
                name="content"
                placeholder={t(
                    'sceneEdit.configurationScriptPlaceholder',
                    'Placeholder script to be sent to {{value}}',
                    { value: selectedModule.label }
                )}
                rows={4}
                value={script ?? ''}
                onChange={(e) => setScript(validateJSON(e.target.value))}
            />
            <div className={styles.warning}>
                {t(
                    'sceneEdit.configurationScriptWarning',
                    "WARNING! Close this window if you don't know what you are doing!"
                )}
            </div>
            <div className={styles.actionsRow}>
                <Button variant="secondary" onClick={handleCancel}>
                    {t('button.cancel', 'Cancel')}
                </Button>
                <AsyncButton
                    allowRefetch={true}
                    disabled={!script || errors.isInvalid}
                    retryTimeoutSeconds={1}
                    status={updateTrackerConfigState}
                    text={t('button.sendScript', 'Send script')}
                    type="submit"
                    onClick={(e) => {
                        e.preventDefault()
                        handleUpdateScript()
                    }}
                />
            </div>
        </ControlledModal>
    )
}

export const ToggleRow: React.FC<{
    isLongRange: boolean
    detectVehicles: boolean
    onLongRangeUpdate: (value: boolean) => void
    onDetectVehiclesUpdate: (value: boolean) => void
    sceneId?: number
}> = ({ isLongRange, detectVehicles, onLongRangeUpdate, onDetectVehiclesUpdate, sceneId }) => {
    const { t } = useTranslation()

    const [showModal, setShowModal] = useState(false)

    const handleOpenModal = () => {
        setShowModal(true)
    }

    const handleCloseModal = () => {
        setShowModal(false)
    }

    return (
        <Form.Group as={Row}>
            <Col className={styles.label} md={3} sm={12}>
                {t('sceneEdit.detects', 'Detects')}
            </Col>
            <Col className={styles.detectSettingsColumn} md={9} sm={12}>
                <ToggleButton
                    leftToggle={{
                        toggleText: t('sceneEdit.detectPeople', 'people'),
                        toggleValue: 'people',
                    }}
                    rightToggle={{
                        toggleText: t('sceneEdit.detectCars', 'cars'),
                        toggleValue: 'cars',
                    }}
                    toggleName="device-type-toggle"
                    toggleValue={detectVehicles ? 'cars' : 'people'}
                    onToggle={(value) => onDetectVehiclesUpdate(value === 'cars')}
                />
                <span className={styles.toggleTextContainer}>{t('sceneEdit.in', 'in')}</span>
                <ToggleButton
                    leftToggle={{
                        toggleText: t('sceneEdit.shortRange', 'short'),
                        toggleValue: 'regular',
                    }}
                    rightToggle={{
                        toggleText: t('sceneEdit.longRange', 'long'),
                        toggleValue: 'long-range',
                    }}
                    toggleName="device-type-toggle"
                    toggleValue={isLongRange ? 'long-range' : 'regular'}
                    onToggle={(value) => onLongRangeUpdate(value === 'long-range')}
                />
                <span className={styles.toggleTextContainer}>{t('sceneEdit.range', 'range')}</span>
                {sceneId && (
                    <RoleChecker whitelist={[Role.Administrator]}>
                        <TrackerConfigurationModal
                            sceneId={sceneId}
                            showModal={showModal}
                            onHide={handleCloseModal}
                            onOpen={handleOpenModal}
                        />
                    </RoleChecker>
                )}
            </Col>
        </Form.Group>
    )
}

export const NoteRow: React.FC<{ note: string; onNoteUpdate: (value: string) => void }> = ({ note, onNoteUpdate }) => {
    const { t } = useTranslation()

    return (
        <Form.Group as={Row}>
            <Col className={styles.label} md={3} sm={12}>
                {t('form.note', 'Note')}
            </Col>
            <Col md={9} sm={12}>
                <InputGroup>
                    <TextInput
                        placeholder={t('form.notePlaceholder', 'Add note')}
                        value={note}
                        onChange={onNoteUpdate}
                    />
                </InputGroup>
            </Col>
        </Form.Group>
    )
}

const AssignedDeviceRow: React.FC<{ device: DeviceResponse; onUnassign: () => void; organizationId: number }> = ({
    device,
    organizationId,
    onUnassign,
}) => {
    const { t } = useTranslation()

    return (
        <Form.Group as={Row}>
            <Col className={styles.label} md={3} sm={12}>
                {t('form.device', 'Device')}
            </Col>

            <Col className={styles.deviceContainer} md={9} sm={12}>
                <span>
                    <DeviceIcon color={device.color} />
                    <Link className={styles.link} to={generateOrganizationEditDevicePath(organizationId, device.id)}>
                        #{device.id}
                    </Link>
                    {device.note && <span className={styles.deviceNote}>{device.note}</span>}
                </span>
                <Button className={styles.removeDevice} variant="danger secondary" onClick={onUnassign}>
                    <FontAwesomeIcon icon={faTrash} /> {t('form.removeDeviceFromScene', 'Remove device')}
                </Button>
            </Col>
        </Form.Group>
    )
}

const DevicePickerRow: React.FC<{ onSelect: (device: DeviceResponse) => void; devices: Array<DeviceResponse> }> = ({
    devices,
    onSelect,
}) => {
    const { t } = useTranslation()

    return (
        <Form.Group as={Row}>
            <Col className={styles.label} md={3} sm={12}>
                {t('form.device', 'Device')}
            </Col>
            <Col md={9} sm={12}>
                <DevicePicker devices={devices} onSelect={onSelect} />
            </Col>
        </Form.Group>
    )
}

const ActionsRow = ({ actionButtons }: { actionButtons?: JSX.Element }) => {
    const { t } = useTranslation()

    if (!actionButtons) {
        return null
    }

    return (
        <Form.Group as={Row}>
            <Col className={styles.label} md={3} sm={12}>
                {t('table.actions', 'Actions')}
            </Col>
            <Col className={styles.actionsColumn} md={9} sm={12}>
                {actionButtons}
            </Col>
        </Form.Group>
    )
}

const SnapshotRow: React.FC<{
    selectedScene: SceneResponse
    renderCount: number
    onReload: () => void
}> = ({ selectedScene, renderCount, onReload }) => {
    const { t } = useTranslation()
    const notify = useNotify()

    const [isLiveViewOpen, setLiveViewOpen] = useState(false)
    const [isSnapshotOpen, setSnapshotOpen] = useState(false)
    const [upsideDown, setUpsideDown] = useState<boolean>(selectedScene.captureSettings.upsideDown)
    const [showWarning, setShowWarning] = useState(false)

    const handleCloseLiveView = () => {
        setLiveViewOpen(false)
    }

    const handleOpenLiveView = () => {
        setLiveViewOpen(true)
    }

    const handleCloseSnapshot = () => {
        setSnapshotOpen(false)
    }

    const handleOpenSnapshot = () => {
        setSnapshotOpen(true)
    }

    const liveViewRequest = useQuery(
        deviceApi.fetchSnapshot.query({
            deviceId: selectedScene.deviceId ?? -1,
        })
    )

    const storedSnapshotRequest = useQuery(
        sceneApi.getSceneSnapshot.query({
            sceneId: selectedScene.id,
        })
    )

    const { mutate: deleteSnapshot } = useMutation(sceneApi.deleteSceneSnapshot)
    const { mutate: updateCaptureSettings } = useMutation(sceneApi.updateCaptureSettings, {
        onError: () => {
            notify({
                title: t('notification.error', 'Error'),
                content: t('notification.deviceImageFlipWentWrong', 'Something went wrong. Device image flip failed.'),
                variant: 'danger',
                timeoutSeconds: 4,
            })
        },
    })

    const { mutate: updateSnapshot, status: updateSnapshotStatus } = useMutation(sceneApi.updateSceneSnapshot, {
        onSuccess: () => {
            storedSnapshotRequest.refetch()
            notify({
                title: t('notification.snapshotSaved', 'Snapshot successfully saved'),
                variant: 'success',
                timeoutSeconds: 5,
                content: (
                    <p>
                        <Trans i18nKey="notification.snapshotWarningContent">
                            Please, make sure the newly updated snapshot does{' '}
                            <strong>not contain images of people</strong>. The snapshots are used for illustrative
                            purposes in pages that are visible to <strong>all members</strong> of your organization.
                        </Trans>
                    </p>
                ),
            })
        },
    })

    const handleDeviceUpsideDownUpdate = (upsideDown: boolean) => {
        setUpsideDown(upsideDown)

        if (selectedScene) {
            setShowWarning(true)

            updateCaptureSettings({
                sceneId: selectedScene.id,
                body: {
                    upsideDown,
                },
            })
            liveViewRequest.refetch()
        }
    }

    const snapshotUpdate = () => {
        setShowWarning(false)

        updateSnapshot({
            sceneId: selectedScene.id,
            body: liveViewRequest.data,
        })
    }

    const snapshotDelete = () => {
        deleteSnapshot({
            sceneId: selectedScene.id,
        })
        storedSnapshotRequest.refetch()
    }

    useEffect(() => {
        if (renderCount > 0) {
            liveViewRequest.refetch()
        }
    }, [renderCount])

    return (
        <Row className={styles.imageContainer}>
            <Col md={6} sm={12}>
                <div className={styles.imageHeaderContainer}>
                    <div>{t('title.deviceLiveView', 'Live view')}</div>
                    <Button variant="secondary" onClick={onReload}>
                        <FontAwesomeIcon icon={faSync} />
                        {t('button.reload', 'Reload')}
                    </Button>
                </div>
                <OverlayedImageWithModal
                    errorComponent={
                        <div className={styles.errorContainer}>
                            <span>
                                <FontAwesomeIcon icon={faVideoSlash} size="sm" />
                                {t('realTime.deviceIsOffline', 'Device is offline')}
                            </span>
                            <span className={styles.infoText}>
                                {t(
                                    'others.deviceIsOfflineInfo',
                                    'Check the electric socket powering the device and make sure internet connection is up. Reload the device once the issue is resolved.'
                                )}
                            </span>
                        </div>
                    }
                    imageRequest={liveViewRequest}
                    isOpen={isLiveViewOpen}
                    onClose={handleCloseLiveView}
                    onOpen={handleOpenLiveView}
                />
                <div>
                    <Button
                        className={classNames(styles.saveButton, {
                            [styles.hiddenSaveButton]:
                                liveViewRequest.status === 'error' || liveViewRequest.status === 'loading',
                        })}
                        disabled={updateSnapshotStatus === 'loading'}
                        variant="primary"
                        onClick={(e) => {
                            e.preventDefault()
                            snapshotUpdate()
                        }}
                    >
                        <FontAwesomeIcon icon={faImage} />
                        {t('button.saveAsSnapshot', 'Save as snapshot')}
                    </Button>
                    <RoleChecker whitelist={[Role.Administrator, Role.OrganizationAdministrator]}>
                        <FlipUpsideDownButton
                            deviceUpsideDown={upsideDown}
                            disabled={updateSnapshotStatus === 'loading'}
                            liveViewRequestState={liveViewRequest.status}
                            showWarning={showWarning}
                            onClick={() => handleDeviceUpsideDownUpdate(!upsideDown)}
                        />
                    </RoleChecker>
                </div>
            </Col>
            <Col md={6} sm={12}>
                <div className={styles.imageHeaderContainer}>
                    <div>{t('button.Snapshot', 'Snapshot')}</div>
                    <PopConfirm
                        cancelButtonVariant="secondary"
                        confirmButtonVariant="danger"
                        confirmMessage={t(
                            'others.deleteSnapshot',
                            'Are you sure you want to delete the current snapshot?'
                        )}
                        onConfirm={snapshotDelete}
                    >
                        <Button variant="danger">
                            <FontAwesomeIcon icon={faTrash} />
                            {t('button.delete', 'Delete')}
                        </Button>
                    </PopConfirm>
                </div>
                <OverlayedImageWithModal
                    errorComponent={
                        <div className={styles.errorContainer}>
                            <FontAwesomeIcon icon={faVideoSlash} size="lg" />
                            <span>{t('realTime.deviceIsOffline', 'Device is offline')}</span>
                        </div>
                    }
                    imageRequest={storedSnapshotRequest}
                    isOpen={isSnapshotOpen}
                    onClose={handleCloseSnapshot}
                    onOpen={handleOpenSnapshot}
                />
            </Col>
        </Row>
    )
}

export type SceneData = { label: string; note: string }

const SceneEditForm: React.FC<{
    selectedScene: SceneResponse
    sceneData: SceneData
    captureSettings: CaptureSettings
    localities: Array<LocalityNameModel>
    selectedLocalities: Array<LocalityNameModel>
    assignableDevices: Array<DeviceResponse>
    assignedDevice?: DeviceResponse
    onSceneChange: (sceneData: SceneData) => void
    onCaptureSettingsChange: (captureSettings: CaptureSettings) => void
    onLocalitiesChange: (localities: Array<LocalityNameModel>) => void
    localityPickerTokenRenderer: LocalityTokenRenderer
    actionButtons?: JSX.Element
    errors: { label: ErrorEntry }
    onUnassignDevice: () => void
    onAssignDevice: (device: DeviceResponse) => void
    organizationId?: number
}> = ({
    actionButtons,
    selectedScene,
    captureSettings,
    sceneData,
    onSceneChange,
    onCaptureSettingsChange,
    onLocalitiesChange,
    localityPickerTokenRenderer,
    localities,
    selectedLocalities,
    assignableDevices,
    assignedDevice,
    errors,
    onUnassignDevice,
    onAssignDevice,
    organizationId,
}) => {
    const [renderCount, reRender] = useState(0)

    const handleDeviceReload = () => {
        reRender((prev) => prev + 1)
    }

    return (
        <Form className={styles.scenesEditForm}>
            <SceneRow
                errors={errors}
                label={sceneData.label}
                onLabelUpdate={(label) => onSceneChange({ ...sceneData, label })}
            />
            {assignedDevice && organizationId ? (
                <AssignedDeviceRow
                    device={assignedDevice}
                    organizationId={organizationId}
                    onUnassign={onUnassignDevice}
                />
            ) : (
                <DevicePickerRow devices={assignableDevices} onSelect={onAssignDevice} />
            )}
            <ToggleRow
                detectVehicles={captureSettings.detectVehicles}
                isLongRange={captureSettings.isLongRange}
                sceneId={selectedScene.id}
                onDetectVehiclesUpdate={(detectVehicles) =>
                    onCaptureSettingsChange({ ...captureSettings, detectVehicles })
                }
                onLongRangeUpdate={(isLongRange) => onCaptureSettingsChange({ ...captureSettings, isLongRange })}
            />
            <NoteRow note={sceneData.note} onNoteUpdate={(note) => onSceneChange({ ...sceneData, note })} />
            <Form.Group as={Row}>
                <LocalityPickerInput
                    localityChoices={Object.fromEntries(localities.map((l) => [l.id.toString(), l.name]))}
                    selection={selectedLocalities}
                    tokenRenderer={localityPickerTokenRenderer}
                    onSelect={(ids) =>
                        onLocalitiesChange(ids.map((id) => localities.find((l) => l.id.toString() === id)!))
                    }
                />
            </Form.Group>
            <ActionsRow actionButtons={actionButtons} />
            <div className={styles.divider}>
                <hr />
            </div>
            <SnapshotRow
                renderCount={renderCount}
                selectedScene={{ ...selectedScene, deviceId: assignedDevice?.id ?? selectedScene.deviceId }}
                onReload={handleDeviceReload}
            />
        </Form>
    )
}

export default SceneEditForm
