import React from 'react'
import { Container, Button } from 'react-bootstrap'
import { Trans, useTranslation } from 'react-i18next'
import { useLocation } from 'react-router'

import { SUPPORT_EMAIL } from '@meta'

import logo from '@images/logo/logo.svg'

import ErrorBoundary from './ErrorBoundary'
import styles from './GlobalErrorView.module.scss'
import Navbar from './Navbar/Navbar'

const GlobalErrorView: React.FC = () => {
    const location = useLocation()

    const { t } = useTranslation()
    const supportEmail = SUPPORT_EMAIL

    return (
        <>
            <ErrorBoundary location={location} placeholder={null}>
                <Navbar />
            </ErrorBoundary>
            <Container className={styles.errorContainer} fluid={true}>
                <img alt="Iterait logo" className={styles.logo} src={logo} />
                <span className={styles.title}>{t('errorViewPage.title', 'Something went wrong')}</span>
                <p className={styles.message}>
                    <Trans i18nKey="errorViewPage.message" values={{ supportEmail }}>
                        Please contact us at{' '}
                        <a className={styles.link} href={`mailto:${supportEmail}`}>
                            {{ supportEmail }}
                        </a>{' '}
                        to resolve this issue.
                    </Trans>
                </p>
                <Button
                    className={styles.goBackButton}
                    onClick={() => {
                        window.location.reload()
                    }}
                >
                    {t('button.goBack', 'Go Back')}
                </Button>
            </Container>
        </>
    )
}

export default GlobalErrorView
