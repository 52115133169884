export const isEmailValid = (email: string): boolean => {
    const basicEmailRegex =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

    return email.match(basicEmailRegex) !== null
}

export const isAddressHttps = (address: string): boolean => {
    const basicHttpsRegex =
        /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/

    return address.match(basicHttpsRegex) !== null
}

export const isPhoneNumberValid = (phoneNumber: string): boolean => {
    const regex = /^\+420[0-9]{9}$/

    return phoneNumber.match(regex) !== null
}

export const isPhoneNumberWithoutCountryCodeValid = (phoneNumber: string): boolean => {
    const regex = /^[0-9]{9}$/

    return phoneNumber.match(regex) !== null
}
