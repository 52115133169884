import React from 'react'
import { Row } from 'react-bootstrap'

import { OpeningHoursInterval, OpeningHours } from '@api'

import OpeningHoursPerDayPicker from './OpeningHoursPerDayPicker'
import styles from './OpeningHoursPicker.module.scss'

export enum DaysInWeekShortened {
    MONDAY = 'mon',
    TUESDAY = 'tue',
    WEDNESDAY = 'wed',
    THURSDAY = 'thu',
    FRIDAY = 'fri',
    SATURDAY = 'sat',
    SUNDAY = 'sun',
}

export const defaultOpeningHours = {
    mon: [],
    tue: [],
    wed: [],
    thu: [],
    fri: [],
    sat: [],
    sun: [],
}

export const defaultOpeningHoursInterval: OpeningHoursInterval = {
    openingTime: {
        hour: 0,
        minute: 0,
    },
    closingTime: {
        hour: 1,
        minute: 0,
    },
}

interface Props {
    openingHours: OpeningHours
    onIntervalAdd: (day: DaysInWeekShortened) => void
    onIntervalDelete: (day: DaysInWeekShortened, index?: number) => void
    onChange: (OpeningHoursInterval: OpeningHoursInterval[], day: DaysInWeekShortened) => void
}

const getPreviousDay = (dayShort: DaysInWeekShortened) => {
    const days = Object.values(DaysInWeekShortened)
    const indexOfDay = days.indexOf(dayShort)

    return indexOfDay === 0 ? days[days.length - 1] : days[indexOfDay - 1]
}

const OpeningHoursPicker: React.FC<Props> = (props) => {
    const handleClonePreviousDayData = (day: DaysInWeekShortened) => {
        props.onChange(props.openingHours[getPreviousDay(day)], day)
    }

    return (
        <div>
            {Object.values(DaysInWeekShortened).map((dayShort) => (
                <Row key={dayShort} className={styles.dayPickerBackground}>
                    <OpeningHoursPerDayPicker
                        dayInWeek={dayShort}
                        openingHours={props.openingHours[dayShort]}
                        onChange={props.onChange}
                        onIntervalAdd={props.onIntervalAdd}
                        onIntervalDelete={props.onIntervalDelete}
                        onPreviousDayDataClone={handleClonePreviousDayData}
                    />
                </Row>
            ))}
        </div>
    )
}

export default OpeningHoursPicker
