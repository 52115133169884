import React from 'react'

import {
    EditableSceneObject,
    isVisitBoundary,
    isDetectionZone,
    isSceneObjectEqual,
    SceneObjectIdentifier,
} from '@helpers/describeScene'
import { scale } from '@helpers/points'

import { Boundary } from './elements/Boundary'
import { Zone } from './elements/Zone'
import { EditorDimensions } from './useSvgEditorData'

const SceneConfigurationVisualizationLayer: React.FC<{
    sceneObjects: Array<EditableSceneObject>
    editorDimensions: EditorDimensions
    highlightedObject?: SceneObjectIdentifier
    onHighlightObject: (object?: SceneObjectIdentifier) => void
    onSelectObject: (object?: SceneObjectIdentifier) => void
}> = ({ sceneObjects, editorDimensions, highlightedObject, onHighlightObject, onSelectObject }) => (
    <>
        {sceneObjects
            .filter(({ isVisible }) => isVisible)
            .map((sceneObject, i) => {
                const commonProps = {
                    key: i,
                    isEditable: false,
                    isHighlighted:
                        highlightedObject !== undefined && isSceneObjectEqual(sceneObject, highlightedObject),
                    points: sceneObject.points.map((p) => scale(p, editorDimensions.scale)),
                    onClick: () => onSelectObject(sceneObject),
                    onMouseOut: () => onHighlightObject(undefined),
                    onMouseOver: () => onHighlightObject(sceneObject),
                }

                if (isVisitBoundary(sceneObject)) {
                    return <Boundary editorWidth={editorDimensions.width} type={sceneObject.type} {...commonProps} />
                }

                if (isDetectionZone(sceneObject)) {
                    return <Zone type={sceneObject.type} {...commonProps} />
                }

                return null
            })}
    </>
)

export default SceneConfigurationVisualizationLayer
