import React from 'react'
import { Card, Col, Container, Row } from 'react-bootstrap'
import { TFunction, useTranslation } from 'react-i18next'
import { QueryObserverResult } from 'react-query'

import { hasRequestId, isApiError } from '@helpers/apiCallManipulation'

import styles from './ErrorView.module.scss'

type ApiErrors = {
    [errorCode: number]: string
}

const determineApiErrorMessage = (errorCode: number, providedErrors?: ApiErrors) => {
    if (providedErrors?.[errorCode]) {
        return providedErrors[errorCode]
    }

    switch (errorCode) {
        case 404:
            return 'Not found'
        case 409:
            return 'Conflict'
        case 406:
            return 'Not acceptable'
        case 500:
            return 'Internal server error'
        default:
            return 'Unknown error'
    }
}

const determineUIErrorMessage = (t: TFunction, request: QueryObserverResult<unknown>, providedErrors?: ApiErrors) => {
    return isApiError(request.error)
        ? hasRequestId(request.error)
            ? t(
                  'others.serverErrorWithId',
                  'Server returned an error "{{ errorCode }}: {{ errorMessage }}". Please contact the administrator and provide the following request id - {{ requestId }}.',
                  {
                      errorCode: request.error.status,
                      errorMessage: determineApiErrorMessage(request.error.status, providedErrors),
                      requestId: request.error.headers.get('X-Request-Id'),
                  }
              )
            : t('others.serverErrorWithCode', 'Server returned an error "{{ errorCode }}: {{ errorMessage }}".', {
                  errorCode: request.error.status,
                  errorMessage: determineApiErrorMessage(request.error.status, providedErrors),
              })
        : t('others.problemFetchingData', 'There was a problem fetching data.')
}

const ApiCallErrorView: React.FC<{ request: QueryObserverResult<unknown>; apiErrors: ApiErrors }> = ({
    request,
    apiErrors,
}) => {
    const { t } = useTranslation()

    return request.status !== 'error' ? null : (
        <Container className={styles.errorContainer} fluid={true}>
            <Row>
                <Col md={12}>
                    <Card>
                        <Card.Header>{t('notification.error', 'Error')}</Card.Header>
                        <Card.Body>{determineUIErrorMessage(t, request, apiErrors)}</Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}

export default ApiCallErrorView
