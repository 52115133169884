/* tslint:disable */
/* eslint-disable */
/**
 * Vividi Dashboard API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CrowdSizeResponse
 */
export interface CrowdSizeResponse {
    /**
     * 
     * @type {number}
     * @memberof CrowdSizeResponse
     */
    currentCrowdSize: number;
    /**
     * 
     * @type {number}
     * @memberof CrowdSizeResponse
     */
    maximumCapacity: number;
    /**
     * Estimated waiting time in seconds
     * @type {number}
     * @memberof CrowdSizeResponse
     */
    estimatedWaitTime: number;
    /**
     * 
     * @type {string}
     * @memberof CrowdSizeResponse
     */
    observedAt: string;
    /**
     * The time point used as a starting point for crowd size calculation (debug information)
     * @type {string}
     * @memberof CrowdSizeResponse
     */
    calculationBase: string;
}

export function CrowdSizeResponseFromJSON(json: any): CrowdSizeResponse {
    return CrowdSizeResponseFromJSONTyped(json, false);
}

export function CrowdSizeResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CrowdSizeResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'currentCrowdSize': json['current_crowd_size'],
        'maximumCapacity': json['maximum_capacity'],
        'estimatedWaitTime': json['estimated_wait_time'],
        'observedAt': json['observed_at'],
        'calculationBase': json['calculation_base'],
    };
}

export function CrowdSizeResponseToJSON(value?: CrowdSizeResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'current_crowd_size': value.currentCrowdSize,
        'maximum_capacity': value.maximumCapacity,
        'estimated_wait_time': value.estimatedWaitTime,
        'observed_at': value.observedAt,
        'calculation_base': value.calculationBase,
    };
}

