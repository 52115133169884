import React from 'react'

const PrivacyPolicy = () => {
    return (
        <>
            <h1 id="privacy-policy">Privacy Policy</h1>
            <p>How We Treat Your Personal Data</p>
            <h2 id="what-is-vividi">What is Vividi?</h2>
            <p>
                Vividi is an IT solution provided by our company that allows users to obtain important statistics and
                information about the demographics and behavior of customers in their establishments. To list a few
                examples, Vividi may be able to identify the duration of a customer´s stay, their age and/or their
                gender.
            </p>
            <p>
                We provide our customers with (i) the Vividi Box, which is a hardware device used to monitor their
                establishments and to analyze gathered data, and also with (ii) Vividi Dashboard, which is a service
                used either to access the Vividi Box output or to analyze data gathered from other video-recording
                devices.
            </p>
            <h2 id="why-should-i-read-this-document">Why should I read this document?</h2>
            <p>
                You may have heard about the Regulation (EU) 2016/679 of the European Parliament and of the Council of
                27 April 2016 (the “Regulation”), more commonly known as the General Data Protection Regulation (GDPR).
            </p>
            <p>
                Under Articles 13 and 14 of the Regulation we must provide our customers and other data subjects with
                information on the collection, handling, protection and processing of their personal data.
            </p>
            <p>
                Therefore, in this Privacy Policy you can learn more about these topics regarding the personal data that
                we collect when
                <span>providing Vividi Dashboard services</span>.
            </p>
            <h2 id="who-collects-my-personal-data">Who collects my personal data?</h2>
            <p>
                We are a commercial company called Iterait a.s., ID No. 074&nbsp;05&nbsp;821, incorporated and existing
                under the laws of the Czech Republic. Our legal address is Hybernská 1034/5, 110 00 Prague 1, Czech
                Republic; we’re registered in the commercial register kept by the Municipal Court in Prague under file
                No. B 23719.
            </p>
            <h2 id="what-personal-data-do-you-process">What personal data do you process?</h2>
            <p>
                As mentioned above, Vividi is a solution that allows users to analyze video footage of customers in
                order to obtain information about their gender, age, behavior, etc.
            </p>
            <h3 id="personal-data-processed-for-development">Personal data processed for development</h3>
            <p>
                To develop and test the solution, we collect and process videos and images of people that may under some
                circumstances be considered as personal data within the meaning of the Regulation.
            </p>
            <p>
                Sometimes we use our own materials, and sometimes we use third-party videos or images. In both events we
                may be considered
                <span>a data controller,</span> collecting, storing and processing personal data (images) of people
                captured in such materials.
            </p>
            <h3 id="personal-data-collected-via-contact-form">Personal data collected via contact form</h3>
            <p>
                On our website we also provide you with a contact form, where you may request information on our
                products.
            </p>
            <p>
                If provided with any information that may be considered personal data (such as first and last name,
                email address, etc.), we will
                <span>as a data controller</span> collect, store and process such personal data.
            </p>
            <h3 id="personal-data-collected-through-the-sale-of-the-vividi-box">
                Personal data collected through the sale of the Vividi Box
            </h3>
            <p>
                When we sell you the Vividi Box, we will
                <span>as a data controller</span> collect, store and process any personal data communicated to us during
                the purchase process.
            </p>
            <p>
                Such personal data include but are not limited to your first and last name, identification number, date
                of birth, business address, residential address, email address, tax identification number, phone number,
                bank account number, etc.
            </p>
            <h3 id="personal-data-collected-when-providing-vividi-dashboard-services">
                Personal data collected when providing Vividi Dashboard services
            </h3>
            <p>
                As you probably already know, Vividi Dashboard may be used in two ways. You may either use Vividi
                Dashboard with the Vividi Box device developed by us, or you may use it with your own video-recording
                device.
            </p>
            <p>
                In both cases we will <span>as a personal data controller</span> collect and process your personal data
                provided by you when we create your user account and, later, in the user-account interface. Such
                personal data will usually include (but are not limited to) the following:
            </p>
            <ul>
                <li>username;</li>
                <li>email address;</li>
                <li>password;</li>
                <li>
                    IP address, including IP address or another identifier of a device used to access Vividi Dashboard;
                </li>
                <li>payment information (credit or debit card number, bank account number);</li>
                <li>
                    and other data voluntarily provided in the user-account interface or during any other contact with
                    us (such as the personal data provided when requesting support, etc.).
                </li>
            </ul>
            <p>
                If you use Vividi Dashboard with the Vividi Box device obtained from us, the video feed containing
                personal data of third parties is analyzed within the device itself. The statistics that are then
                transmitted to Vividi Dashboard may not be considered as personal data.
            </p>
            <p>
                On the other hand, if you use your own video-recording device, the whole video feed is transmitted to
                us, including any captured personal data (such as human faces) of third parties. We will
                <span>as your processor</span> process such personal data for the purpose set by you (to obtain
                statistics and information about the demographics and behavior of customers in your establishment).
            </p>
            <p>
                We would like to remind you that under some laws, you may be, as the operator of the video-recording
                device (whether the Vividi Box or any other), considered a personal data controller as well and may
                yourself be subject to some rules and obligations. If you are ever not sure, do not hesitate to ask us
                for more detailed information.
            </p>
            <h2 id="why-do-you-process-my-personal-data">Why do you process my personal data?</h2>
            <h3 id="personal-data-processed-for-development-1">Personal data processed for development</h3>
            <p>
                It is quite simple: We collect, store and process the above-mentioned videos and images to develop and
                test our Vividi products.
            </p>
            <p>
                To comply with legal requirements, we must mention that we collect, store and process such personal data
                pursuant to Article 6 (1) (f) of the Regulation. That means that it is in our legitimate interest to
                collect, store and process such personal data, since there is no other way to effectively train, test
                and use any artificial intelligence software and hardware to obtain information on recorded individuals
                without allowing it to visually perceive the surrounding world.
            </p>
            <p>
                It is important to us that we do not excessively interfere with any personal or other rights of data
                subjects. Therefore, we adhere to the following principles when we develop and operate Vividi:
            </p>
            <ul>
                <li>
                    We use only such videos and images that are focused not on any individual person but rather on
                    crowds of people.
                </li>
                <li>
                    We do not capture any individual person for an amount of time that is beyond what is usual and
                    lawful when visually interacting with other people in public places.
                </li>
                <li>
                    We do not attempt to and are not able to identify by name any of the individual people captured in
                    the processed images and videos.
                </li>
                <li>
                    We do not conceal the purpose of the videos and images, and we operate in such a way that it is
                    apparent to people that they are being captured. We will use visible signs, including a link to this
                    Privacy Policy.
                </li>
            </ul>
            <h3 id="personal-data-collected-via-contact-form-and-through-the-sale-of-the-vividi-box">
                Personal data collected via contact form and through the sale of the Vividi Box
            </h3>
            <p>
                As for the information provided by you through the contact form, we collect, store and process it for
                the purpose of providing you with the requested goods, services, support, or any other information, as
                well as for the purpose of any further communication with you. To comply with legal requirements, we
                must mention that we collect, store and process such personal data mainly pursuant to Article 6 (1) (b)
                of the Regulation.
            </p>
            <p>
                As for the information provided during the purchase of the Vividi Box, we collect, store and process it
                for the purpose of exercising any rights and fulfilling any obligations related to the contract of
                purchase and sale. To comply with legal requirements, we must mention that we collect, store and process
                such personal data mainly pursuant to Article 6 (1) (b) of the Regulation.
            </p>
            <p>
                We collect the information provided by you through the contact form or when making a purchase of a
                Vividi device also for marketing purposes, for example to inform you about our latest products, services
                and features, and to establish, exercise or defend any related legal claims. To comply with legal
                requirements, we must mention that we collect, store and process such personal data pursuant to Article
                6 (1) (f) of the Regulation.
            </p>
            <p>
                That means it is in our legitimate interest to collect, store and process such personal data for
                marketing purposes, since it is in our interest to inform our customers about such news and is
                reasonable to expect that the customers who have contacted us before may be interested in such news.
                Additionally, the customers may at any time opt out from receiving such news.
            </p>
            <p>
                It is also in our legitimate interest to collect, store and process this kind of personal data to
                establish, exercise or defend any related legal claims, since otherwise we could not exercise our legal
                rights and as such use of personal data is visible to data subjects and represents little to no
                restriction of data-subject rights.
            </p>
            <h3 id="personal-data-collected-when-using-vividi-dashboard">
                Personal data collected when using Vividi Dashboard
            </h3>
            <p>
                It is quite simple: We collect, process and store your personal data only so we can provide you services
                pursuant to Vividi Dashboard Terms of Service.
            </p>
            <p>We will specifically process your personal data:</p>
            <ul>
                <li>
                    to fulfill any of our obligations under the Vividi Dashboard Terms of Service and under any
                    applicable law and to protect our own rights thereunder;
                </li>
                <li>to create, control and manage your user account;</li>
                <li>
                    to secure the quality of our services and to use it for other commercial purposes, including to
                    inform you of products and services related to Vividi;
                </li>
                <li>to use it for marketing purposes (quality of user experience research).</li>
            </ul>
            <p>
                To comply with legal requirements, we must mention that we collect, store and process such personal data
                mainly pursuant to Article 6 (1) (b) and (f) of the Regulation. For the legitimate-interest explanation,
                please read the section dedicated to the personal data collected via contact form and through the sale
                of the Vividi Box, as similar legitimate interest applies here.
            </p>
            <p>
                We collect, process and store your personal data only for the above defined purposes and only to the
                extent necessary to fulfill those purposes. We never conceal the purpose for which the personal data are
                collected and further processed.
            </p>
            <h2 id="how-long-will-you-store-my-personal-data">How long will you store my personal data?</h2>
            <p>
                We process and store your personal data only for the time necessary to meet the purposes of its
                processing, which are specified above, or for the time consented to by you, or for the time that is
                either necessary in order to adhere to our obligations under the applicable law or set forth by the
                applicable law or in accordance therewith. We comply with the mandatory rules for data archiving.
            </p>
            <p>Once the purpose of the personal-data processing disappears, we will destroy the personal data.</p>
            <p>
                As for the personal data voluntarily provided by you through the contact form, we will process and store
                such personal data until the requested issue is solved and also for the subsequent period thereafter
                that is either necessary in order to adhere to our obligations under the applicable law or set forth by
                the applicable law or in accordance therewith, or during which you or any third party may contest that
                we have broken any of our obligations or have violated any applicable laws in relation to the requested
                issue.
            </p>
            <p>
                As for the personal data voluntarily provided by you when purchasing a Vividi Box, we will process and
                store such personal data until all the obligations under the contract of purchase and sale have been
                fulfilled and also for the subsequent period thereafter that is either necessary in order to adhere to
                our obligations under the applicable law or set forth by the applicable law or in accordance therewith,
                or during which you or any third party may contest that we have broken any of our obligations or have
                violated any applicable laws in relation to the sale of the Vividi Box.
            </p>
            <p>
                As for the personal data collected when you are using Vividi Dashboard, we will process and store said
                personal data for as long as we provide you Vividi Dashboard services and also for the subsequent period
                thereafter that is either necessary in order to adhere to our obligations under the applicable law or
                set forth by the applicable law or in accordance therewith, or during which you or any third party may
                contest that we have broken any of our obligations or have violated any applicable laws in relation to
                the provided services.
            </p>
            <h2 id="who-will-have-access-to-my-personal-data">Who will have access to my personal data?</h2>
            <p>
                We take care of your personal-data security, and we choose very carefully the partners to whom we
                entrust your personal data.
            </p>
            <p>
                All our partners must be able to provide sufficient security of your personal data to prevent
                unauthorized or accidental access thereto or other abuse thereof, and all our partners must undertake a
                confidentiality obligation and must not use your personal data for any purpose other than the purpose
                for which the data were made available to them.
            </p>
            <p>The recipients who may have access to your personal data are:</p>
            <ul>
                <li>
                    persons who provide us with the technological services or technology operators that we use to
                    provide our services;
                </li>
                <li>
                    persons who provide our services and websites with security and integrity and who regularly test
                    such security and integrity;
                </li>
                <li>providers of accounting, legal and administrative services;</li>
                <li>our staff.</li>
            </ul>
            <p>
                Our aim is and always will be to ensure your personal data are as anonymous as possible and unavailable
                to all third parties. However, under certain specifically defined conditions, we will be in some
                circumstances required, in accordance to the applicable law, to transfer certain personal data to the
                public authorities.
            </p>
            <p>
                When collecting, storing and processing personal data, we sometimes may use personal-data processors,
                such as Google, that under some circumstances transmit your personal data to third countries. In such
                cases, we always make sure the transmission is compliant with the Regulation. In particular we specify
                that the personal data may be transmitted to the United States of America, and in such cases the
                transmission is compliant with a so-called Privacy Shield program.
            </p>
            <h2 id="how-are-my-personal-data-protected">How are my personal data protected?</h2>
            <p>
                All your personal data are secured by standard procedures and technologies. We provide data protection
                against unauthorized or accidental access, alteration, destruction, loss, unauthorized transmission or
                any other unauthorized processing, as well as against any other abuse of records containing the personal
                data.
            </p>
            <p>
                We are not able to guarantee the security of your personal data without your help and responsible
                behavior. Therefore, we ask you to help us ensure the security of your data by keeping your user-account
                login information secret and secure by following common security standards.
            </p>
            <h2 id="what-are-my-rights-with-regard-to-personal-data-protection">
                What are my rights with regard to personal-data protection?
            </h2>
            <p>With regard to personal data, you shall have in particular the following rights:</p>
            <ul>
                <li>a right to withdraw your consent at any time;</li>
                <li>a right to correct or make additions to your personal data;</li>
                <li>a right to request restrictions to your personal-data processing;</li>
                <li>
                    a right to object or complain against your personal-data processing under certain circumstances;
                </li>
                <li>a right to request your personal-data transfer;</li>
                <li>a right to access your personal data;</li>
                <li>a right to be informed of a personal-data security breach under certain circumstances;</li>
                <li>
                    a right to request deletion of your personal data (a right to be “forgotten“) under certain
                    circumstances; and
                </li>
                <li>
                    other rights set forth in Act No. 110/2019 Coll., on personal-data processing (Personal Data
                    Protection Act) and the Regulation.
                </li>
            </ul>
            <p>
                Additionally, in the event of any violation of the obligations set forth in the Regulation, you have the
                right to contact the Office for Personal Data Protection and make a request for remediate measures at
                the following address: Office for Personal Data Protection, Pplk. Sochorova 27, 170 00 Prague 7, Czech
                Republic; phone number +420 234 665 111 (central telephone exchange).
            </p>
            <h3 id="how-can-i-contact-you">How can I contact you?</h3>
            <p>
                If you have any questions regarding this Privacy Policy, please do not hesitate to contact us using the
                contact details below:
            </p>
            <p>Contact address: Hybernská 1034/5, 110 00 Prague 1, Czech Republic</p>
            <p>
                Contact point (support):{' '}
                <a href="https://support.slushpool.com/">
                    <span>https://www.iterait.com/</span>
                </a>
            </p>
            <p>
                Email address:{' '}
                <a href="mailto:hello@iterait.com">
                    <span>hello@iterait.com</span>
                </a>
            </p>
            <h3 id="when-is-this-privacy-policy-effective">When is this Privacy Policy effective?</h3>
            <p>This Privacy Policy comes into effect on December 1st, 2019.</p>
            <p>We reserve the right to amend and update this Privacy Policy at any time.</p>
            <blockquote>
                <p>
                    In Prague on December 1st, 2019
                    <br />
                    Iterait a.s.
                </p>
            </blockquote>
        </>
    )
}

export default PrivacyPolicy
