import { faCheckSquare, faSquare } from '@fortawesome/free-regular-svg-icons'
import { faCircleNotch, faExclamationCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { MutationStatus } from 'react-query'

import styles from './TransferStateIndicator.module.scss'

export interface TransferState {
    overallState: MutationStatus
    deviceCreationState: MutationStatus
    creationResult: 'created' | 'existsOk' | 'existsConflict'
    imageConfigurationState: MutationStatus
    markTransferredState: MutationStatus
}

export const defaultTransferState: TransferState = {
    overallState: 'idle',
    deviceCreationState: 'idle',
    creationResult: 'created',
    imageConfigurationState: 'idle',
    markTransferredState: 'idle',
}

interface ItemProps {
    loadingState: MutationStatus
    children: React.ReactNode
}

const Item = ({ loadingState, children }: ItemProps) => {
    const icon =
        loadingState === 'loading' ? (
            <FontAwesomeIcon icon={faCircleNotch} listItem={true} pulse={true} />
        ) : loadingState === 'error' ? (
            <FontAwesomeIcon icon={faExclamationCircle} listItem={true} />
        ) : loadingState === 'success' ? (
            <FontAwesomeIcon icon={faCheckSquare} listItem={true} />
        ) : (
            <FontAwesomeIcon icon={faSquare} listItem={true} size="1x" />
        )

    return (
        <li
            className={classNames({
                [styles.notRunning]: loadingState === 'idle',
                [styles.pending]: loadingState === 'loading',
                [styles.done]: loadingState === 'success',
                [styles.failed]: loadingState === 'error',
            })}
        >
            {icon} {children}
        </li>
    )
}

interface Props {
    transferState: TransferState
}

const TransferStateIndicator = ({ transferState }: Props) => {
    const { t } = useTranslation()

    return (
        <ul className="fa-ul">
            <Item loadingState={transferState.deviceCreationState}>
                {t('transferPage.copyDevice', 'Copy the device to the target backend')}
                {transferState.deviceCreationState === 'success' && transferState.creationResult === 'existsOk' && (
                    <>
                        <br />
                        <small>{t('transferPage.deviceExists', 'The device already exists')}</small>
                    </>
                )}
                {transferState.deviceCreationState === 'error' && transferState.creationResult === 'existsConflict' && (
                    <>
                        <br />
                        <small>
                            {t(
                                'transferPage.deviceExistsId',
                                'The device already exists with a different serial number'
                            )}
                        </small>
                    </>
                )}
            </Item>
            <Item loadingState={transferState.imageConfigurationState}>
                {t(
                    'transferPage.configureImage',
                    'Configure the image for our copy of the device so that it can move over to the target API'
                )}
            </Item>
            <Item loadingState={transferState.markTransferredState}>
                {t('transferPage.markCopy', 'Mark our copy of the device as transferred')}
            </Item>
        </ul>
    )
}

export default TransferStateIndicator
