import React from 'react'

import { Point } from '@api'

import palette from '@components/palette.module.scss'

interface CursorProps {
    dataLength: number
    points: [Point, Point]
    width: number
    height: number
}

const Cursor = (rawProps: any) => {
    const props = rawProps as CursorProps
    const barWidth = props.width / props.dataLength

    return (
        <g transform={`translate(${props.points[0].x - barWidth / 2},${props.points[0].y})`}>
            <path
                d={`M 0,0 h ${barWidth} v ${props.height} h ${-barWidth} Z`}
                fill={palette.darkgrey}
                fillOpacity={0.2}
                height={props.height}
                width={barWidth}
            />
        </g>
    )
}

export default Cursor
