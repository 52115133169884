/* tslint:disable */
/* eslint-disable */
/**
 * Vividi Dashboard API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SalesStatisticsDetailsSummaryRow
 */
export interface SalesStatisticsDetailsSummaryRow {
    /**
     * 
     * @type {number}
     * @memberof SalesStatisticsDetailsSummaryRow
     */
    deviceId: number;
    /**
     * 
     * @type {number}
     * @memberof SalesStatisticsDetailsSummaryRow
     */
    sceneId: number;
    /**
     * 
     * @type {string}
     * @memberof SalesStatisticsDetailsSummaryRow
     */
    zoneName: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof SalesStatisticsDetailsSummaryRow
     */
    serveTimeAverage: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof SalesStatisticsDetailsSummaryRow
     */
    serveTimeMax: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof SalesStatisticsDetailsSummaryRow
     */
    serveTimeSampleCount: Array<number>;
}

export function SalesStatisticsDetailsSummaryRowFromJSON(json: any): SalesStatisticsDetailsSummaryRow {
    return SalesStatisticsDetailsSummaryRowFromJSONTyped(json, false);
}

export function SalesStatisticsDetailsSummaryRowFromJSONTyped(json: any, ignoreDiscriminator: boolean): SalesStatisticsDetailsSummaryRow {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'deviceId': json['device_id'],
        'sceneId': json['scene_id'],
        'zoneName': json['zone_name'],
        'serveTimeAverage': json['serve_time_average'],
        'serveTimeMax': json['serve_time_max'],
        'serveTimeSampleCount': json['serve_time_sample_count'],
    };
}

export function SalesStatisticsDetailsSummaryRowToJSON(value?: SalesStatisticsDetailsSummaryRow | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'device_id': value.deviceId,
        'scene_id': value.sceneId,
        'zone_name': value.zoneName,
        'serve_time_average': value.serveTimeAverage,
        'serve_time_max': value.serveTimeMax,
        'serve_time_sample_count': value.serveTimeSampleCount,
    };
}

