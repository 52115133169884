import classNames from 'classnames'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { LocalityNameModel, Role } from '@api'

import { generateOrganizationsEditLocalitiesPath } from '@helpers/VividiURLs'
import { useAddBackLink } from '@helpers/backlinks'

import RoleChecker from '@components/RoleChecker'

import styles from './NotConfiguredLocalitiesList.module.scss'

type Props = {
    notConfiguredLocalities: LocalityNameModel[]
    nothingConfigured?: boolean
}

const NotConfiguredLocalitiesList = ({ notConfiguredLocalities, nothingConfigured }: Props) => {
    const { t } = useTranslation()

    const addBackLink = useAddBackLink()

    return (
        <div
            className={classNames({
                [styles.notConfiguredLocalitiesContainer]: true,
                [styles.nothingConfiguredContainer]: nothingConfigured,
            })}
        >
            {t('others.notConfiguredFor', 'Not configured for ')}
            {notConfiguredLocalities.map((locality, i) => (
                <React.Fragment key={locality.id}>
                    <RoleChecker whitelist={[Role.User]}>
                        {i > 0 && ', '}
                        <strong>{locality.name}</strong>
                    </RoleChecker>
                    <RoleChecker
                        whitelist={[Role.OrganizationAdministrator, Role.OrganizationOwner, Role.Administrator]}
                    >
                        {i > 0 && ', '}
                        <Link
                            to={addBackLink(
                                generateOrganizationsEditLocalitiesPath(locality.organizationId, locality.id)
                            )}
                        >
                            {locality.name}
                        </Link>
                    </RoleChecker>
                </React.Fragment>
            ))}
        </div>
    )
}

export default NotConfiguredLocalitiesList
