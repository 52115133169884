/* tslint:disable */
/* eslint-disable */
/**
 * Vividi Dashboard API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Time
 */
export interface Time {
    /**
     * 
     * @type {number}
     * @memberof Time
     */
    hour: number;
    /**
     * 
     * @type {number}
     * @memberof Time
     */
    minute: number;
}

export function TimeFromJSON(json: any): Time {
    return TimeFromJSONTyped(json, false);
}

export function TimeFromJSONTyped(json: any, ignoreDiscriminator: boolean): Time {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'hour': json['hour'],
        'minute': json['minute'],
    };
}

export function TimeToJSON(value?: Time | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'hour': value.hour,
        'minute': value.minute,
    };
}

