import React from 'react'
import { useTranslation } from 'react-i18next'
import { QueryObserverResult } from 'react-query'

import ErrorView from './ErrorView'
import LoadingSpinner from './LoadingSpinner'
import LoadingWrapper from './LoadingWrapper'

export interface Props<ValueType> {
    request: QueryObserverResult<ValueType>
    children: (value: ValueType) => JSX.Element
    placeholder?: JSX.Element
    errorComponent?: JSX.Element | React.FC<{ request: QueryObserverResult<ValueType> }>
    bare?: boolean
    allowBuffering?: boolean
}

function LegacyWrapper<ValueType>({
    request,
    children,
    placeholder,
    errorComponent,
    bare,
    allowBuffering = false,
}: Omit<Props<ValueType>, 'errorComponent'> & { errorComponent?: JSX.Element }): JSX.Element {
    const { t } = useTranslation()
    const canDisplayBufferedValue = allowBuffering && request.data !== undefined

    if ((request.status === 'loading' || request.status === 'idle') && !canDisplayBufferedValue) {
        return placeholder ?? <LoadingSpinner bare={bare} />
    }

    if (request.status === 'error') {
        if (errorComponent) {
            return errorComponent
        }

        return <ErrorView message={t('others.problemFetchingData', 'There was a problem fetching data.')} />
    }

    return children(request.data!)
}

export default function LegacyLoadingWrapper<ValueType>({
    request,
    children,
    placeholder,
    errorComponent,
    bare,
    allowBuffering = false,
}: Props<ValueType>): JSX.Element {
    let loadingErrorComponent: JSX.Element | undefined = undefined

    if (errorComponent) {
        if (React.isValidElement(errorComponent)) {
            loadingErrorComponent = errorComponent
        } else {
            const ErrorComponent = errorComponent as React.FC<{ request: QueryObserverResult<ValueType> }>

            loadingErrorComponent = <ErrorComponent request={request} />
        }
    }

    return (
        <LoadingWrapper bare={bare} errorComponent={loadingErrorComponent} placeholder={placeholder}>
            <LegacyWrapper
                children={children}
                allowBuffering={allowBuffering}
                bare={bare}
                errorComponent={loadingErrorComponent}
                placeholder={placeholder}
                request={request}
            />
        </LoadingWrapper>
    )
}
