import { flatMap, noop } from 'lodash'
import { DateTime, Duration, Interval } from 'luxon'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { useParams } from 'react-router'
import { useHistory } from 'react-router-dom'

import { LocalityNameModel, VisitBoundaryWithId } from '@api'

import { localityApi, statisticsApi, weatherApi } from '@services'

import { generateFootfallDetailsCustomIntervalPath, generateFootfallDetailsPath } from '@helpers/VividiURLs'
import { mergeQueryResults, visibleSceneDescriptionsQuery } from '@helpers/api'
import { ageCategory, isMaleCategory } from '@helpers/footfallData'
import { useTimezoneConfig } from '@helpers/timezoneConfig'
import { IntervalType } from '@helpers/types'
import { pickColumn } from '@helpers/utils'

import DashboardParamsNormalizer, { NormalizedDashboardParams } from '@components/DashboardParamsNormalizer'
import DetailsLayout from '@components/DetailsLayout'
import ErrorView from '@components/ErrorView'
import { FootfallDetailExportButton } from '@components/FootfallDetails/FootfallDetailExportButton'
import FootfallDetailsContent from '@components/FootfallDetails/FootfallDetailsContent'
import Helmet from '@components/Helmet'
import LegacyLoadingWrapper from '@components/LegacyLoadingWrapper'
import LoadingSpinner from '@components/LoadingSpinner'
import { partitionInterval } from '@components/plots/common'

interface Params {
    boundariesIds?: string
}

const FootfallDetailsPage: React.FC<NormalizedDashboardParams> = ({ locality, interval, intervalType }) => {
    const { t } = useTranslation()

    const params = useParams<Params>()
    const history = useHistory()

    const zone = useTimezoneConfig()
    const [now] = useState(DateTime.utc())

    const selectedBoundariesIds = params.boundariesIds === undefined ? [] : params.boundariesIds.split('-').map(Number)

    const partitionedInterval = partitionInterval(
        interval,
        zone,
        Duration.fromObject(
            interval.end.diff(interval.start) > Duration.fromObject({ days: 7 }) ? { days: 1 } : { hours: 1 }
        )
    )

    const scenesCall = useQuery(
        localityApi.getLocalityScenes.query({
            localityId: locality.id,
        })
    )

    const sceneConfigurationCall = useQuery({
        ...visibleSceneDescriptionsQuery(pickColumn(scenesCall.data?.scenes ?? [], 'id')),
        enabled: scenesCall.isSuccess,
    })

    const outagesCall = useQuery({
        ...statisticsApi.getMultiDeviceOutages.query({
            body: {
                sceneIds: pickColumn(scenesCall.data?.scenes ?? [], 'id'),
                startingFrom: interval.start.toISO(),
                endingAt: interval.end.toISO(),
            },
        }),
        enabled: scenesCall.isSuccess,
    })

    const propertiesByBoundaryCall = useQuery(
        statisticsApi.getPropertiesByBoundary.query({
            body: {
                locality: locality.id,
                intervals: [
                    {
                        startingFrom: interval.start.toISO(),
                        endingAt: interval.end.toISO(),
                    },
                ],
                propertyCategories: [isMaleCategory, ageCategory],
            },
        })
    )

    const visitorsByBoundaryCall = useQuery(
        statisticsApi.getMovementByBoundary.query({
            body: {
                locality: locality.id,
                ...partitionedInterval,
            },
        })
    )

    const weatherCall = useQuery(
        weatherApi.getLocalityWeather.query({
            localityId: locality.id,
            body: partitionedInterval,
        })
    )

    const genderAgeBreakdownCall = useQuery(
        statisticsApi.getPropertyBreakdownByBoundary.query({
            body: {
                locality: locality.id,
                intervals: [
                    {
                        startingFrom: interval.start.toISO(),
                        endingAt: interval.end.toISO(),
                    },
                ],
                propertyCategories: [isMaleCategory, ageCategory],
            },
        })
    )

    const handleIntervalStartChange = (intervalStart: DateTime) => {
        history.push(generateFootfallDetailsPath(locality.id, intervalStart, intervalType, selectedBoundariesIds))
    }

    const handleIntervalTypeChange = (newIntervalType: IntervalType) => {
        history.push(generateFootfallDetailsPath(locality.id, interval.start, newIntervalType, selectedBoundariesIds))
    }

    const handleLocalityChange = (locality: LocalityNameModel) => {
        const isCustomIntervalSelected = intervalType === IntervalType.CUSTOM && interval.start && interval.end

        if (isCustomIntervalSelected) {
            return history.push(generateFootfallDetailsCustomIntervalPath(locality.id, interval.start, interval.end))
        }

        return history.push(generateFootfallDetailsPath(locality.id, interval.start, intervalType))
    }

    const handleBoundariesChange = (boundaries: VisitBoundaryWithId[]) => {
        return history.push(
            generateFootfallDetailsCustomIntervalPath(
                locality.id,
                interval.start,
                interval.end,
                boundaries.map((b) => b.id)
            )
        )
    }

    const handleCustomIntervalChange = (interval: Interval) => {
        history.push(
            generateFootfallDetailsCustomIntervalPath(locality.id, interval.start, interval.end, selectedBoundariesIds)
        )
    }

    const documentTitle = t('title.footfallStatistics', 'Footfall Statistics')

    return (
        <>
            <Helmet>
                <title>{documentTitle}</title>
            </Helmet>
            <LegacyLoadingWrapper
                errorComponent={
                    <DetailsLayout
                        intervalType={intervalType}
                        organizationFilter={(org) => org.features.footfall}
                        pickedInterval={interval}
                        selectedLocality={locality}
                        onCustomIntervalChange={handleCustomIntervalChange}
                        onIntervalStartChange={handleIntervalStartChange}
                        onIntervalTypeChange={handleIntervalTypeChange}
                        onLocalityChange={handleLocalityChange}
                    >
                        <ErrorView message={t('others.problemFetchingData', 'There was a problem fetching data.')} />
                    </DetailsLayout>
                }
                placeholder={
                    <DetailsLayout
                        intervalType={intervalType}
                        organizationFilter={(org) => org.features.footfall}
                        pickedInterval={interval}
                        selectedLocality={locality}
                        onCustomIntervalChange={handleCustomIntervalChange}
                        onIntervalStartChange={handleIntervalStartChange}
                        onIntervalTypeChange={handleIntervalTypeChange}
                        onLocalityChange={handleLocalityChange}
                    >
                        <LoadingSpinner />
                    </DetailsLayout>
                }
                request={mergeQueryResults(
                    propertiesByBoundaryCall,
                    visitorsByBoundaryCall,
                    genderAgeBreakdownCall,
                    scenesCall,
                    sceneConfigurationCall,
                    weatherCall,
                    outagesCall
                )}
            >
                {([
                    propertiesByBoundary,
                    visitorsByBoundary,
                    genderAgeBreakdown,
                    scenes,
                    sceneConfiguration,
                    weather,
                    outages,
                ]) => {
                    const deviceBoundaries = flatMap(
                        flatMap(sceneConfiguration).map((device) => device.visitBoundaries)
                    )

                    const selectedLocalityBoundaries = deviceBoundaries.filter((boundary) =>
                        selectedBoundariesIds.includes(boundary.id)
                    )

                    return (
                        <>
                            <Helmet>
                                <title>{`${locality.name} ${documentTitle}`}</title>
                            </Helmet>
                            <DetailsLayout
                                key={locality.id}
                                intervalType={intervalType}
                                layoutActionButton={
                                    <FootfallDetailExportButton
                                        genderAgeBreakdown={genderAgeBreakdown}
                                        now={now}
                                        pickedInterval={interval}
                                        propertiesByBoundary={propertiesByBoundary}
                                        scenes={scenes}
                                        selectedBoundaries={selectedLocalityBoundaries}
                                        selectedLocality={locality}
                                        visitorsByBoundary={visitorsByBoundary}
                                        weather={weather.intervals}
                                    />
                                }
                                organizationFilter={(org) => org.features.footfall}
                                pickedInterval={interval}
                                sceneConfiguration={sceneConfiguration}
                                scenes={scenes}
                                selectedBoundaries={selectedLocalityBoundaries}
                                selectedLocality={locality}
                                onBoundariesChange={handleBoundariesChange}
                                onCustomIntervalChange={handleCustomIntervalChange}
                                onIntervalStartChange={handleIntervalStartChange}
                                onIntervalTypeChange={handleIntervalTypeChange}
                                onLocalityChange={handleLocalityChange}
                            >
                                <FootfallDetailsContent
                                    genderAgeBreakdown={genderAgeBreakdown}
                                    interval={interval}
                                    intervalType={intervalType}
                                    locality={locality}
                                    now={now}
                                    outages={outages.outages}
                                    propertiesByBoundary={propertiesByBoundary}
                                    sceneConfiguration={sceneConfiguration}
                                    scenes={scenes}
                                    selectedBoundaries={selectedLocalityBoundaries}
                                    visitorsByBoundary={visitorsByBoundary}
                                    weather={weather}
                                />
                            </DetailsLayout>
                        </>
                    )
                }}
            </LegacyLoadingWrapper>
        </>
    )
}

const FootfallDetailsParamsNormalizer: React.FC = () => {
    const { t } = useTranslation()

    return (
        <DashboardParamsNormalizer
            customIntervalPathGenerator={generateFootfallDetailsCustomIntervalPath}
            defaultLocalityPathGenerator={generateFootfallDetailsPath}
            errorComponent={({ intervalType, interval }) => (
                <DetailsLayout
                    intervalType={intervalType}
                    organizationFilter={(org) => org.features.footfall}
                    pickedInterval={interval}
                    onCustomIntervalChange={noop}
                    onIntervalStartChange={noop}
                    onIntervalTypeChange={noop}
                    onLocalityChange={noop}
                >
                    <ErrorView
                        message={t('others.fetchingListFailed', 'Fetching the list of localities failed')}
                        title={t('others.errorWhileFetchingData', 'Error while fetching data')}
                    />
                </DetailsLayout>
            )}
            intervalPathGenerator={generateFootfallDetailsPath}
            loadingComponent={({ intervalType, interval }) => (
                <DetailsLayout
                    intervalType={intervalType}
                    organizationFilter={(org) => org.features.footfall}
                    pickedInterval={interval}
                    onCustomIntervalChange={noop}
                    onIntervalStartChange={noop}
                    onIntervalTypeChange={noop}
                    onLocalityChange={noop}
                >
                    <LoadingSpinner />
                </DetailsLayout>
            )}
            successComponent={(params) => <FootfallDetailsPage {...params} />}
        />
    )
}

export default FootfallDetailsParamsNormalizer
