/* tslint:disable */
/* eslint-disable */
/**
 * Vividi Dashboard API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    FootfallOverviewResponseItem,
    FootfallOverviewResponseItemFromJSON,
    FootfallOverviewResponseItemFromJSONTyped,
    FootfallOverviewResponseItemToJSON,
} from './FootfallOverviewResponseItem';

/**
 * 
 * @export
 * @interface FootfallOverviewResponse
 */
export interface FootfallOverviewResponse {
    /**
     * Footfall overview lists for each requested locality
     * @type {Array<FootfallOverviewResponseItem>}
     * @memberof FootfallOverviewResponse
     */
    itemsByLocality: Array<FootfallOverviewResponseItem>;
}

export function FootfallOverviewResponseFromJSON(json: any): FootfallOverviewResponse {
    return FootfallOverviewResponseFromJSONTyped(json, false);
}

export function FootfallOverviewResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): FootfallOverviewResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'itemsByLocality': ((json['items_by_locality'] as Array<any>).map(FootfallOverviewResponseItemFromJSON)),
    };
}

export function FootfallOverviewResponseToJSON(value?: FootfallOverviewResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'items_by_locality': ((value.itemsByLocality as Array<any>).map(FootfallOverviewResponseItemToJSON)),
    };
}

