import { keyBy } from 'lodash'
import { Form, Row, Col, Button } from 'react-bootstrap'
import { TFunction } from 'react-i18next'
import { MutationStatus } from 'react-query'

import { LocalityNameModel, OrganizationResponse, StaySafeNotificationPreferences } from '@api'

import AsyncButton from './AsyncButton'
import NumberInput from './GenericInputs/NumberInput'
import LocalityPicker from './LocalityPicker'
import { NotificationErrors } from './NotificationPreferencesForm'
import styles from './SetPasswordForm.module.scss'

interface SetNotificationsFormProps {
    organizations: Array<OrganizationResponse>
    pickedLocality?: LocalityNameModel
    localities: Array<LocalityNameModel>
    currentValues: StaySafeNotificationPreferences | undefined
    errors: NotificationErrors
    disabled?: boolean
    submissionStatus: MutationStatus
    onUpdate: (updatedNotification: StaySafeNotificationPreferences) => void
    t: TFunction
    handleLocalitySelect: (localityId: number) => void
    onErrors: (errorData: NotificationErrors) => void
    onSubmit: () => void
    onCancel: () => void
}

const SetNotificationsForm = ({
    t,
    currentValues,
    onUpdate: handleUpdate,
    errors,
    organizations,
    localities,
    handleLocalitySelect,
    pickedLocality,
    disabled,
    onErrors: handleErrors,
    onSubmit,
    onCancel,
    submissionStatus,
}: SetNotificationsFormProps) => {
    if (!currentValues) {
        return <div>The selected user has no assigned localities</div>
    }

    return (
        <Form
            onSubmit={(e) => {
                e.preventDefault()
                onSubmit()
            }}
        >
            <Form.Group as={Row}>
                <Form.Label column={true} sm={5}>
                    {t('table.locality', 'Locality')}
                </Form.Label>
                <Col sm={7}>
                    <LocalityPicker
                        features={['staySafeNotifier', 'realtimeOccupancy']}
                        localities={localities}
                        organizations={keyBy(organizations, (org) => org.id)}
                        selectedLocality={pickedLocality}
                        onSelect={({ id }) => {
                            handleLocalitySelect(id)
                        }}
                    />
                </Col>
            </Form.Group>
            <Form.Group as={Row}>
                <Form.Label column={true} sm={5}>
                    {t('table.staySafeLimit', 'Stay Safe limit')}
                </Form.Label>
                <Col sm={7}>
                    <NumberInput
                        disabled={disabled}
                        isInvalid={
                            errors.capacityExceededNotificationThreshold.isTouched &&
                            errors.capacityExceededNotificationThreshold.isInvalid
                        }
                        name="capacityExceededNotificationThreshold"
                        value={currentValues.capacityExceededNotificationThreshold}
                        onChange={(val) => {
                            handleUpdate({
                                ...currentValues,
                                capacityExceededNotificationThreshold: val ?? 1,
                            })
                            handleErrors({
                                ...errors,
                                capacityExceededNotificationThreshold: {
                                    isTouched: true,
                                    isInvalid: val !== undefined && val <= 0,
                                },
                            })
                        }}
                    />
                </Col>
            </Form.Group>
            <Form.Group as={Row}>
                <Form.Label column={true} sm={5}>
                    {t('table.sendNotificationAfter', 'Send notification after (sec)')}
                </Form.Label>
                <Col sm={7}>
                    <NumberInput
                        disabled={disabled}
                        isInvalid={
                            errors.capacityExceededNotificationGracePeriod.isTouched &&
                            errors.capacityExceededNotificationGracePeriod.isInvalid
                        }
                        name="capacityExceededNotificationGracePeriod"
                        value={currentValues.capacityExceededNotificationGracePeriod}
                        onChange={(val) => {
                            handleUpdate({
                                ...currentValues,
                                capacityExceededNotificationGracePeriod: val ?? 0,
                            })
                            handleErrors({
                                ...errors,
                                capacityExceededNotificationGracePeriod: {
                                    isTouched: true,
                                    isInvalid: val !== undefined && val < 0,
                                },
                            })
                        }}
                    />
                </Col>
            </Form.Group>
            <Form.Group as={Row}>
                <Form.Label column={true} sm={5}>
                    {t('table.repeatNotificationAfter', 'Repeat notification after (sec)')}
                </Form.Label>
                <Col sm={7}>
                    <NumberInput
                        disabled={disabled}
                        isInvalid={
                            errors.capacityExceededNotificationCooldown.isTouched &&
                            errors.capacityExceededNotificationCooldown.isInvalid
                        }
                        name="capacityExceededNotificationCooldown"
                        value={currentValues.capacityExceededNotificationCooldown}
                        onChange={(val) => {
                            handleUpdate({
                                ...currentValues,
                                capacityExceededNotificationCooldown: val ?? 0,
                            })
                            handleErrors({
                                ...errors,
                                capacityExceededNotificationCooldown: {
                                    isTouched: true,
                                    isInvalid: val !== undefined && val < 1800,
                                },
                            })
                        }}
                    />
                </Col>
            </Form.Group>

            <Form.Group as={Row}>
                <Col className={styles.controlButtonsRow} sm={12}>
                    <Button variant="secondary" onClick={() => onCancel()}>
                        {t('button.cancel', 'Cancel')}
                    </Button>
                    <AsyncButton
                        allowRefetch={true}
                        loadingText={t('button.saving', 'Saving...')}
                        status={submissionStatus}
                        text={t('button.save', 'Save')}
                        type="submit"
                        variant="primary"
                    />
                </Col>
            </Form.Group>
        </Form>
    )
}

export default SetNotificationsForm
