/* tslint:disable */
/* eslint-disable */
/**
 * Vividi Dashboard API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface NumericBound
 */
export interface NumericBound {
    /**
     * 
     * @type {number}
     * @memberof NumericBound
     */
    value: number;
    /**
     * 
     * @type {boolean}
     * @memberof NumericBound
     */
    inclusive: boolean;
}

export function NumericBoundFromJSON(json: any): NumericBound {
    return NumericBoundFromJSONTyped(json, false);
}

export function NumericBoundFromJSONTyped(json: any, ignoreDiscriminator: boolean): NumericBound {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'value': json['value'],
        'inclusive': json['inclusive'],
    };
}

export function NumericBoundToJSON(value?: NumericBound | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'value': value.value,
        'inclusive': value.inclusive,
    };
}

