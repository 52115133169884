import { zip, mean } from 'lodash'

import { Point } from '@api'

import { pickColumn } from './utils'

const plus = (a: Point, b: Point) => ({ x: a.x + b.x, y: a.y + b.y })
const minus = (a: Point, b: Point) => ({ x: a.x - b.x, y: a.y - b.y })
const scale = (p: Point, c: number) => ({ x: p.x * c, y: p.y * c })

const vectorLength = ({ x, y }: { x: number; y: number }): number => Math.sqrt(x ** 2 + y ** 2)
const calculateNormalVector = (p: Point) => ({ x: -1 * p.y, y: p.x })
const normalize = (p: Point) => scale(p, 1 / vectorLength(p))
const dot = (a: Point, b: Point) => a.x * b.x + a.y * b.y
const det = (a: Point, b: Point) => a.x * b.y - a.y * b.x
const floorPoint = ({ x, y }: Point) => ({ x: Math.floor(x), y: Math.floor(y) })

export const edges = (points: Point[]): Array<[Point, Point]> => {
    if (points.length <= 1) {
        return []
    }

    if (points.length === 2) {
        return [[points[0], points[1]]]
    }

    const edgeEnds = [...points.slice(1), points[0]]

    return zip(points, edgeEnds) as Array<[Point, Point]>
}

export const calculatePolygonCenterAverage = (points: Point[]) => ({
    x: mean(pickColumn(points, 'x')),
    y: mean(pickColumn(points, 'y')),
})

export const calculateClockwiseAngle = (v1: Point, v2: Point) => {
    const result = Math.atan2(det(v1, v2), dot(v1, v2))

    if (result < 0) {
        return result + 2 * Math.PI
    }

    return result
}

export { plus, minus, scale, vectorLength, calculateNormalVector, normalize, floorPoint }
