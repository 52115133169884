/* tslint:disable */
/* eslint-disable */
/**
 * Vividi Dashboard API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ConversionStatistics
 */
export interface ConversionStatistics {
    /**
     * 
     * @type {string}
     * @memberof ConversionStatistics
     */
    startingFrom: string;
    /**
     * 
     * @type {string}
     * @memberof ConversionStatistics
     */
    endingAt: string;
    /**
     * Portion (0-1) of interval within opening hours
     * @type {number}
     * @memberof ConversionStatistics
     */
    openedPortion: number;
    /**
     * Number of people who visited the location.
     * @type {number}
     * @memberof ConversionStatistics
     */
    visitors?: number;
    /**
     * Number of people who walked near the location.
     * @type {number}
     * @memberof ConversionStatistics
     */
    passerBy?: number;
    /**
     * Number of people who reached on of the point of sales.
     * @type {number}
     * @memberof ConversionStatistics
     */
    sales?: number;
    /**
     * Number of people who visited the location yet left promptly.
     * @type {number}
     * @memberof ConversionStatistics
     */
    bounced?: number;
}

export function ConversionStatisticsFromJSON(json: any): ConversionStatistics {
    return ConversionStatisticsFromJSONTyped(json, false);
}

export function ConversionStatisticsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConversionStatistics {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'startingFrom': json['starting_from'],
        'endingAt': json['ending_at'],
        'openedPortion': json['opened_portion'],
        'visitors': !exists(json, 'visitors') ? undefined : json['visitors'],
        'passerBy': !exists(json, 'passer_by') ? undefined : json['passer_by'],
        'sales': !exists(json, 'sales') ? undefined : json['sales'],
        'bounced': !exists(json, 'bounced') ? undefined : json['bounced'],
    };
}

export function ConversionStatisticsToJSON(value?: ConversionStatistics | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'starting_from': value.startingFrom,
        'ending_at': value.endingAt,
        'opened_portion': value.openedPortion,
        'visitors': value.visitors,
        'passer_by': value.passerBy,
        'sales': value.sales,
        'bounced': value.bounced,
    };
}

