import { faDownload } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Dictionary, keyBy } from 'lodash'
import { Interval, DateTime } from 'luxon'
import React, { useState } from 'react'
import { Button, Container } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'

import { OrganizationResponse } from '@api'
import { LocalityNameModel, VisitBoundaryWithId, SceneDescription, DetectionZoneWithId, SceneListResponse } from '@api'

import { visibleLocalitiesQuery, visibleOrganizationsQuery } from '@helpers/api'
import { passCSSVariable } from '@helpers/cssUtils'
import useProfile from '@helpers/profile'
import { IntervalType } from '@helpers/types'

import BoundaryPicker from './BoundaryPicker'
import styles from './DetailsLayout.module.scss'
import GranularityPicker from './GranularityPicker/GranularityPicker'
import IntervalPicker from './IntervalPicker/IntervalPicker'
import LiveIndicator from './LiveIndicator/LiveIndicator'
import LocalityPicker from './LocalityPicker'
import Navbar from './Navbar/Navbar'
import ZonePicker from './ZonePicker'

export enum LayoutModules {
    LOCALITYPICKER = 'localityPicker',
    GRANULARITYPICKER = 'granularityPicker',
    INTERVALPICKER = 'intervalPicker',
}

interface Props {
    children?: React.ReactNode | React.ReactNode[]
    pickedInterval: Interval
    intervalType: IntervalType
    intervalDisplayClassName?: string
    onIntervalStartChange: (intervalStart: DateTime) => void
    onCustomIntervalChange: (interval: Interval) => void
    onIntervalTypeChange: (intervalType: IntervalType) => void
    onLocalityChange: (locality: LocalityNameModel) => void
    onBoundariesChange?: (boundaries: VisitBoundaryWithId[]) => void
    onZonesChange?: (zones: DetectionZoneWithId[]) => void
    layoutActionButton?: JSX.Element
    selectedLocality?: LocalityNameModel
    selectedBoundaries?: VisitBoundaryWithId[]
    selectedZones?: DetectionZoneWithId[]
    organizationFilter?: (organization: OrganizationResponse) => boolean
    localityFilter?: (locality: LocalityNameModel) => boolean
    layoutModules?: Array<LayoutModules>
    containerWidth?: string
    showLiveIndicator?: boolean
    sceneConfiguration?: Dictionary<SceneDescription>
    scenes?: SceneListResponse
}

const DetailsLayout: React.FC<Props> = ({
    children = null,
    pickedInterval,
    intervalType,
    onIntervalStartChange,
    onCustomIntervalChange,
    onIntervalTypeChange,
    onLocalityChange,
    onBoundariesChange,
    onZonesChange,
    selectedLocality,
    selectedBoundaries,
    selectedZones,
    layoutActionButton,
    containerWidth,
    layoutModules,
    showLiveIndicator,
    sceneConfiguration,
    scenes,
    organizationFilter = () => true,
    localityFilter = () => true,
}) => {
    const { t } = useTranslation()
    const [now] = useState(DateTime.utc())
    const profileCall = useProfile()
    const visibleLocalitiesCall = useQuery(visibleLocalitiesQuery(profileCall.data))
    const visibleOrganizationsCall = useQuery(visibleOrganizationsQuery(profileCall.data))

    return (
        <>
            <Navbar />
            <div
                className={styles.toolbar}
                style={{
                    ...passCSSVariable('ContainerWidth', containerWidth),
                }}
            >
                {!layoutModules || layoutModules.includes(LayoutModules.LOCALITYPICKER) ? (
                    <div className={styles.localityPickerContainer}>
                        <LocalityPicker
                            features={[]}
                            localities={keyBy(
                                visibleLocalitiesCall.data?.localities.filter(localityFilter) ?? [],
                                'id'
                            )}
                            organizations={keyBy(
                                visibleOrganizationsCall.data?.organizations.filter(organizationFilter) ?? [],
                                'id'
                            )}
                            selectedLocality={selectedLocality}
                            onSelect={onLocalityChange}
                        />
                        {selectedLocality &&
                            onBoundariesChange &&
                            selectedBoundaries !== undefined &&
                            sceneConfiguration !== undefined &&
                            scenes && (
                                <BoundaryPicker
                                    sceneConfiguration={sceneConfiguration}
                                    scenes={scenes}
                                    selectedBoundaries={selectedBoundaries}
                                    selectedLocality={selectedLocality}
                                    onBoundariesChange={onBoundariesChange}
                                />
                            )}
                        {selectedLocality &&
                            onZonesChange &&
                            selectedZones !== undefined &&
                            sceneConfiguration !== undefined &&
                            scenes && (
                                <ZonePicker
                                    sceneConfiguration={sceneConfiguration}
                                    scenes={scenes}
                                    selectedLocality={selectedLocality}
                                    selectedZones={selectedZones}
                                    onZonesChange={onZonesChange}
                                />
                            )}
                    </div>
                ) : null}
                {!layoutModules || layoutModules.includes(LayoutModules.GRANULARITYPICKER) ? (
                    <GranularityPicker
                        availableGranularity={[
                            IntervalType.DAY,
                            IntervalType.WEEK,
                            IntervalType.MONTH,
                            IntervalType.CUSTOM,
                        ]}
                        intervalPickerClassName={styles.granularityPicker}
                        intervalType={intervalType}
                        pickedInterval={pickedInterval}
                        onCustomIntervalChange={onCustomIntervalChange}
                        onIntervalTypeChange={onIntervalTypeChange}
                    />
                ) : null}
                {!layoutModules || layoutModules.includes(LayoutModules.INTERVALPICKER) ? (
                    <IntervalPicker
                        intervalDisplayClassName={styles.intervalPicker}
                        intervalType={intervalType}
                        now={now}
                        pickedInterval={pickedInterval}
                        onCustomIntervalChange={onCustomIntervalChange}
                        onIntervalStartChange={onIntervalStartChange}
                    />
                ) : null}
                {showLiveIndicator && <LiveIndicator />}
                <div>
                    {layoutActionButton ? (
                        layoutActionButton
                    ) : (
                        <Button disabled={true} variant="secondary">
                            <FontAwesomeIcon icon={faDownload} />
                            {t('button.downloadExcel', 'Download Excel')}
                        </Button>
                    )}
                </div>
            </div>
            <Container className={styles.content} style={{ maxWidth: containerWidth }}>
                {children}
            </Container>
        </>
    )
}

export default DetailsLayout
