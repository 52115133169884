import { useEffect } from 'react'

import { useApiCallCleaner } from '@helpers/api'

import { AUTH_KEY, useAuthContext } from './auth'

const useApiCallsReset = () => {
    const clean = useApiCallCleaner()
    const authContext = useAuthContext()
    const isTokenPresent = authContext?.accessToken

    // Whenever a user logs out, forget API call results
    useEffect(() => {
        return () => {
            const currentAuthContext = window.localStorage.getItem(AUTH_KEY)

            if (!currentAuthContext || JSON.parse(currentAuthContext).accessToken === undefined) {
                clean()
            }
        }
    }, [isTokenPresent, clean])
}

export default useApiCallsReset
