/* tslint:disable */
/* eslint-disable */
/**
 * Vividi Dashboard API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OrganizationModel
 */
export interface OrganizationModel {
    /**
     * 
     * @type {string}
     * @memberof OrganizationModel
     */
    name: string;
    /**
     * Report device being offline
     * @type {boolean}
     * @memberof OrganizationModel
     */
    isMonitoringEnabled?: boolean;
    /**
     * Display statistics summary over all localities on home page
     * @type {boolean}
     * @memberof OrganizationModel
     */
    isLocalitySummaryEnabled?: boolean;
    /**
     * Create a github ticket after specified number of minutes of device being offline
     * @type {number}
     * @memberof OrganizationModel
     */
    minutesToDeviceDownTicket?: number;
}

export function OrganizationModelFromJSON(json: any): OrganizationModel {
    return OrganizationModelFromJSONTyped(json, false);
}

export function OrganizationModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrganizationModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'isMonitoringEnabled': !exists(json, 'is_monitoring_enabled') ? undefined : json['is_monitoring_enabled'],
        'isLocalitySummaryEnabled': !exists(json, 'is_locality_summary_enabled') ? undefined : json['is_locality_summary_enabled'],
        'minutesToDeviceDownTicket': !exists(json, 'minutes_to_device_down_ticket') ? undefined : json['minutes_to_device_down_ticket'],
    };
}

export function OrganizationModelToJSON(value?: OrganizationModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'is_monitoring_enabled': value.isMonitoringEnabled,
        'is_locality_summary_enabled': value.isLocalitySummaryEnabled,
        'minutes_to_device_down_ticket': value.minutesToDeviceDownTicket,
    };
}

