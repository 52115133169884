import { faFrown, faGrin, faMeh } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import { Dictionary, isEmpty, mapValues, partition, pick, round, sum } from 'lodash'
import { IANAZone, Interval } from 'luxon'
import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'

import { LocalityConfigurationStateModel, OrganizationResponse } from '@api'

import { generateEmotionsDetailsCustomIntervalPath, generateOrganizationsEditLocalitiesPath } from '@helpers/VividiURLs'
import { emotionsSummaryByLocalityQuery } from '@helpers/api'
import { useAddBackLink } from '@helpers/backlinks'
import { emptyEmotionDataItem, prepareEmotionDataItem, sumEmotionDataItems } from '@helpers/emotionsData'
import { apiInterval } from '@helpers/intervals'
import { orderLocalities } from '@helpers/orderFunctions'
import { migrateTimestamp, useTimezoneConfig } from '@helpers/timezoneConfig'
import { SummaryRequest } from '@helpers/types'
import { percentage, pickColumn, recordFromEntries } from '@helpers/utils'

import Box from '@elements/Box/Box'
import TableWithSorting, { CellContent } from '@elements/Table/TableWithSorting'

import AdminOnlyLinkRenderer from '@components/AdminOnlyLinkRenderer'
import emotionsCellStyles from '@components/EmotionsDetails/EmotionsDetails.module.scss'
import { EmotionsCell } from '@components/EmotionsDetails/EmotionsDetailsContent'
import LegacyLoadingWrapper from '@components/LegacyLoadingWrapper'
import LoadingSpinner from '@components/LoadingSpinner'
import ChangeIndicator from '@components/StatisticsSummary/LocalityTable/ChangeIndicator'
import NotConfiguredLocalitiesList from '@components/StatisticsSummary/NotConfiguredLocalitiesList'
import SectionSubheading from '@components/StatisticsSummary/SectionSubheading'
import StatisticBox, { StatisticBoxItem } from '@components/StatisticsSummary/StatisticBox'
import { partitionByInterval } from '@components/StatisticsSummary/utils'
import { EmotionScoreChart } from '@components/plots/EmotionScoreChart'

import DetailLink from './DetailLink'
import styles from './EmotionsSection.module.scss'
import EmotionsSummaryDownloadButton from './EmotionsSummaryDownloadButton'

const EmotionsSummaryCell: React.FC<EmotionsCell> = ({ value = 0, changeIndicator }) => (
    <td className={emotionsCellStyles.emotionsTableCell}>
        <div className="d-flex justify-content-end">
            <span>
                <FontAwesomeIcon
                    className={value < 4 ? styles.negativeIcon : value > 7 ? styles.positiveIcon : styles.neutralIcon}
                    icon={value < 4 ? faFrown : value > 7 ? faGrin : faMeh}
                />
                {value}
            </span>
            {changeIndicator}
        </div>
    </td>
)

type Props = {
    selectedOrganization: OrganizationResponse
    localities: LocalityConfigurationStateModel[]
    selectedLocalities: LocalityConfigurationStateModel[]
    interval: Interval
    comparisonInterval?: Interval
    partitionedInterval: SummaryRequest
    partitionedComparisonInterval?: SummaryRequest
    shouldDisplayLocalitySummary: boolean
}

const EmotionsSection: React.FC<Props> = ({
    localities,
    selectedLocalities,
    interval,
    comparisonInterval,
    selectedOrganization,
    partitionedInterval,
    partitionedComparisonInterval,
    shouldDisplayLocalitySummary,
}) => {
    const zone = useTimezoneConfig()

    const { t } = useTranslation()
    const addBackLink = useAddBackLink()

    const [configuredLocalities, notConfiguredLocalities] = partition(
        orderLocalities(selectedLocalities),
        (l) => l.isEmotionsConfigured
    )

    const summariesCall = useQuery({
        ...emotionsSummaryByLocalityQuery({
            intervals: (comparisonInterval ? [interval, comparisonInterval] : [interval]).map(apiInterval),
            localityIds: pickColumn(localities, 'id'),
        }),
    })

    return (
        <LegacyLoadingWrapper
            placeholder={
                <Box paddingSize="lg">
                    <Box.Title text={t('heading.emotionsStatistics', 'Emotions Statistics')} />
                    <LoadingSpinner bare />
                </Box>
            }
            request={summariesCall}
        >
            {(summaries) => {
                const localityData = mapValues(pick(summaries, pickColumn(configuredLocalities, 'id')), ({ items }) =>
                    mapValues(
                        partitionByInterval(
                            interval,
                            items.flatMap((zoneBreakdown) => zoneBreakdown.items)
                        ),
                        (intervalData) =>
                            intervalData.map(prepareEmotionDataItem).reduce(sumEmotionDataItems, emptyEmotionDataItem)
                    )
                )

                const overallData = {
                    currentInterval: Object.values(localityData)
                        .map((it) => it.currentInterval)
                        .reduce(sumEmotionDataItems, emptyEmotionDataItem),
                    previousInterval: Object.values(localityData)
                        .map((it) => it.previousInterval)
                        .reduce(sumEmotionDataItems, emptyEmotionDataItem),
                }

                const isEmptySummary = sum(Object.values(overallData.currentInterval)) === 0

                const overallPercentages = recordFromEntries(
                    ['positiveObservations', 'neutralObservations', 'negativeObservations'],
                    (key) => ({
                        currentInterval: round(
                            percentage(overallData.currentInterval[key], overallData.currentInterval.totalObservations)
                        ),
                        previousInterval: comparisonInterval
                            ? round(
                                  percentage(
                                      overallData.previousInterval[key],
                                      overallData.previousInterval.totalObservations
                                  )
                              )
                            : undefined,
                    })
                )

                const localityTableContent: Array<Dictionary<CellContent>> = Object.entries(localityData).map(
                    ([localityId, localityData]) => ({
                        locality: {
                            content: localities.find((l) => l.id.toString() === localityId)!.name,
                            cellType: 'text',
                            targetLink: addBackLink(
                                generateOrganizationsEditLocalitiesPath(selectedOrganization.id, Number(localityId))
                            ),
                            renderer: AdminOnlyLinkRenderer,
                        },
                        avgEmotionScore: {
                            content:
                                round(
                                    localityData.currentInterval.totalEmotionScore /
                                        (localityData.currentInterval.totalObservations || 1),
                                    1
                                ) === 0
                                    ? undefined
                                    : round(
                                          localityData.currentInterval.totalEmotionScore /
                                              (localityData.currentInterval.totalObservations || 1),
                                          1
                                      ),
                            comparisonValue: round(
                                localityData.previousInterval.totalEmotionScore /
                                    (localityData.previousInterval.totalObservations || 1),
                                1
                            ),
                            get valueChange() {
                                return this.content && this.comparisonValue
                                    ? round(this.content - this.comparisonValue, 1)
                                    : 0
                            },
                            cellType: 'numeric',
                            numberFormat: 'count',
                            isChangeAbsolute: true,
                            renderer: EmotionsSummaryCell,
                        },
                        positiveObservations: {
                            content: round(
                                percentage(
                                    localityData.currentInterval.positiveObservations,
                                    localityData.currentInterval.totalObservations
                                )
                            ),
                            comparisonValue: round(
                                percentage(
                                    localityData.previousInterval.positiveObservations,
                                    localityData.currentInterval.totalObservations
                                )
                            ),
                            get valueChange() {
                                return round(this.content - this.comparisonValue)
                            },
                            cellType: 'numeric',
                            numberFormat: 'percentage',
                            isChangeAbsolute: true,
                        },
                        neutralObservations: {
                            content: round(
                                percentage(
                                    localityData.currentInterval.neutralObservations,
                                    localityData.currentInterval.totalObservations
                                ),
                                1
                            ),
                            comparisonValue: round(
                                percentage(
                                    localityData.previousInterval.neutralObservations,
                                    localityData.currentInterval.totalObservations
                                )
                            ),
                            get valueChange() {
                                return round(this.content - this.comparisonValue)
                            },
                            cellType: 'numeric',
                            numberFormat: 'percentage',
                            desiredTrend: 'neutral',
                            isChangeAbsolute: true,
                        },
                        negativeObservations: {
                            content: round(
                                percentage(
                                    localityData.currentInterval.negativeObservations,
                                    localityData.currentInterval.totalObservations
                                ),
                                1
                            ),
                            comparisonValue: round(
                                percentage(
                                    localityData.previousInterval.negativeObservations,
                                    localityData.currentInterval.negativeObservations
                                )
                            ),
                            get valueChange() {
                                return round(this.content - this.comparisonValue)
                            },
                            cellType: 'numeric',
                            numberFormat: 'percentage',
                            desiredTrend: 'negative',
                            isChangeAbsolute: true,
                        },
                        detail: {
                            cellType: 'rawElement',
                            element: (
                                <DetailLink
                                    to={generateEmotionsDetailsCustomIntervalPath(
                                        Number(localityId),
                                        migrateTimestamp(
                                            interval.start,
                                            zone,
                                            new IANAZone(
                                                localities.find((l) => l.id.toString() === localityId)!.timezone
                                            )
                                        ),
                                        migrateTimestamp(
                                            interval.end,
                                            zone,
                                            new IANAZone(
                                                localities.find((l) => l.id.toString() === localityId)!.timezone
                                            )
                                        )
                                    )}
                                />
                            ),
                        },
                    })
                )

                const positiveObservationsIndicator =
                    comparisonInterval && overallPercentages.positiveObservations.previousInterval ? (
                        <ChangeIndicator
                            change={
                                overallPercentages.positiveObservations.currentInterval -
                                (overallPercentages.positiveObservations.previousInterval ?? 0)
                            }
                            comparisonInterval={comparisonInterval}
                            dataType="percentage"
                            isChangeAbsolute={true}
                            isVisible={Boolean(comparisonInterval)}
                            valueToCompare={overallPercentages.positiveObservations.previousInterval}
                            isBigger
                        />
                    ) : undefined

                const neutralObservationsIndicator =
                    comparisonInterval && overallPercentages.neutralObservations.previousInterval ? (
                        <ChangeIndicator
                            change={
                                overallPercentages.neutralObservations.currentInterval -
                                (overallPercentages.neutralObservations.previousInterval ?? 0)
                            }
                            comparisonInterval={comparisonInterval}
                            dataType="percentage"
                            isChangeAbsolute={true}
                            isVisible={Boolean(comparisonInterval)}
                            valueToCompare={overallPercentages.neutralObservations.previousInterval}
                            isBigger
                        />
                    ) : undefined

                const negativeObservationsIndicator =
                    comparisonInterval && overallPercentages.negativeObservations.previousInterval ? (
                        <ChangeIndicator
                            change={
                                overallPercentages.negativeObservations.currentInterval -
                                (overallPercentages.negativeObservations.previousInterval ?? 0)
                            }
                            comparisonInterval={comparisonInterval}
                            dataType="percentage"
                            isChangeAbsolute={true}
                            isVisible={Boolean(comparisonInterval)}
                            valueToCompare={overallPercentages.negativeObservations.previousInterval}
                            isBigger
                        />
                    ) : undefined

                return (
                    <Box paddingSize="lg">
                        <Box.Title
                            buttons={
                                <EmotionsSummaryDownloadButton
                                    interval={interval}
                                    localities={localities}
                                    partitionedComparisonInterval={partitionedComparisonInterval}
                                    partitionedInterval={partitionedInterval}
                                    selectedOrganization={selectedOrganization}
                                />
                            }
                            hideButtons={isEmpty(configuredLocalities)}
                            text={t('heading.emotionsStatistics', 'Emotions Statistics')}
                        />
                        {shouldDisplayLocalitySummary && (
                            <Row
                                className={classNames({
                                    [styles.emptySummaryRow]: isEmptySummary,
                                })}
                            >
                                {!isEmptySummary && (
                                    <Col className={styles.scoreChartColumn} md={3} sm={12}>
                                        <EmotionScoreChart data={overallData.currentInterval} fontSize="300%" />
                                        <div className={styles.maxEmotionScoreLabel}>/ 10</div>
                                    </Col>
                                )}
                                <StatisticBox
                                    interval={interval}
                                    label={t('emotions.positiveFaces', 'Positive faces')}
                                    localities={configuredLocalities}
                                    md={3}
                                    sm={12}
                                    tooltip={t(
                                        'emotions.positiveFacesDescription',
                                        'total portion of positive faces across all selected localities'
                                    )}
                                    verticalPlacement={true}
                                >
                                    <StatisticBoxItem
                                        changeIndicator={positiveObservationsIndicator}
                                        className={styles.greyLabel}
                                        icon={faGrin}
                                        iconClass={styles.positiveIcon}
                                        unit=" %"
                                        value={overallPercentages.positiveObservations.currentInterval}
                                        verticalPlacement={true}
                                    />
                                </StatisticBox>
                                <StatisticBox
                                    interval={interval}
                                    label={t('emotions.neutralFaces', 'Neutral faces')}
                                    localities={configuredLocalities}
                                    md={3}
                                    sm={12}
                                    tooltip={t(
                                        'emotions.neutralFacesDescription',
                                        'total portion of neutral faces across all selected localities'
                                    )}
                                    verticalPlacement={true}
                                >
                                    <StatisticBoxItem
                                        changeIndicator={neutralObservationsIndicator}
                                        className={styles.greyLabel}
                                        icon={faMeh}
                                        iconClass={styles.neutralIcon}
                                        unit=" %"
                                        value={overallPercentages.neutralObservations.currentInterval}
                                        verticalPlacement={true}
                                    />
                                </StatisticBox>
                                <StatisticBox
                                    interval={interval}
                                    label={t('emotions.negativeFaces', 'Negative faces')}
                                    localities={configuredLocalities}
                                    md={3}
                                    sm={12}
                                    tooltip={t(
                                        'emotions.negativeFacesDescription',
                                        'total portion of negative faces across all selected localities'
                                    )}
                                    verticalPlacement={true}
                                >
                                    <StatisticBoxItem
                                        changeIndicator={negativeObservationsIndicator}
                                        className={styles.greyLabel}
                                        icon={faFrown}
                                        iconClass={styles.negativeIcon}
                                        unit=" %"
                                        value={overallPercentages.negativeObservations.currentInterval}
                                        verticalPlacement={true}
                                    />
                                </StatisticBox>
                            </Row>
                        )}
                        <SectionSubheading selectedOrganization={selectedOrganization}>
                            {t('heading.localityComparison', 'Comparison of localities')}
                        </SectionSubheading>
                        <TableWithSorting
                            bodyRows={localityTableContent}
                            comparisonInterval={comparisonInterval}
                            defaultSortingColumn="avgEmotionScore"
                            defaultSortingOrder="desc"
                            excludedSortingColumns={['detail']}
                            headRow={[
                                {
                                    name: 'locality',
                                    displayName: t('heading.locality', 'Locality'),
                                },
                                {
                                    name: 'avgEmotionScore',
                                    displayName: t('emotions.avgEmotionScore', 'Avg. emotion score'),
                                    align: 'right',
                                },
                                {
                                    name: 'positiveObservations',
                                    displayName: t('emotions.positiveFaces', 'Positive faces'),
                                    align: 'right',
                                },
                                {
                                    name: 'neutralObservations',
                                    displayName: t('emotions.neutralFaces', 'Neutral faces'),
                                    align: 'right',
                                },
                                {
                                    name: 'negativeObservations',
                                    displayName: t('emotions.negativeFaces', 'Negative faces'),
                                    align: 'right',
                                },
                                {
                                    name: 'detail',
                                    displayName: t('table.actions', 'Actions'),
                                    align: 'right',
                                },
                            ]}
                            paginationSize={15}
                        />
                        {!isEmpty(notConfiguredLocalities) && (
                            <NotConfiguredLocalitiesList
                                notConfiguredLocalities={notConfiguredLocalities}
                                nothingConfigured={isEmpty(configuredLocalities)}
                            />
                        )}
                    </Box>
                )
            }}
        </LegacyLoadingWrapper>
    )
}

export default EmotionsSection
