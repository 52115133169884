/**
 * Vividi Dashboard API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime'
import {UseQueryOptions, QueryKey} from 'react-query'

import {
    DeviceListResponse,
    DeviceListResponseFromJSON,
    DeviceListResponseToJSON,
    ErrorResponse,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    InviteUsersRequestBody,
    InviteUsersRequestBodyFromJSON,
    InviteUsersRequestBodyToJSON,
    MQTTPublisherPreferencesList,
    MQTTPublisherPreferencesListFromJSON,
    MQTTPublisherPreferencesListToJSON,
    OrganizationFeatures,
    OrganizationFeaturesFromJSON,
    OrganizationFeaturesToJSON,
    OrganizationListResponse,
    OrganizationListResponseFromJSON,
    OrganizationListResponseToJSON,
    OrganizationModel,
    OrganizationModelFromJSON,
    OrganizationModelToJSON,
    OrganizationResponse,
    OrganizationResponseFromJSON,
    OrganizationResponseToJSON,
    RegisterOrganizationRequestBody,
    RegisterOrganizationRequestBodyFromJSON,
    RegisterOrganizationRequestBodyToJSON,
    SceneListResponse,
    SceneListResponseFromJSON,
    SceneListResponseToJSON,
    SuccessResponse,
    SuccessResponseFromJSON,
    SuccessResponseToJSON,
    UserListResponse,
    UserListResponseFromJSON,
    UserListResponseToJSON,
} from '../models';

export interface AddOrganizationRequest {
    body: OrganizationModel;
}

export interface AddOrganizationAdminRequest {
    organizationId: number;
    userId: number;
}

export interface AddOrganizationMemberRequest {
    organizationId: number;
    userId: number;
}

export interface DeleteOrganizationRequest {
    organizationId: number;
}

export interface GetMqttPublishersRequest {
    organizationId: number;
}

export interface GetOrganizationRequest {
    organizationId: number;
}

export interface GetOrganizationDevicesRequest {
    organizationId: number;
}

export interface GetOrganizationMembersRequest {
    organizationId: number;
}

export interface GetOrganizationScenesRequest {
    organizationId: number;
}

export interface InviteUsersToOrganizationRequest {
    organizationId: number;
    body: InviteUsersRequestBody;
}

export interface RegisterOrganizationRequest {
    body: RegisterOrganizationRequestBody;
}

export interface SetMqttPublishersRequest {
    organizationId: number;
    body: MQTTPublisherPreferencesList;
}

export interface UpdateOrganizationRequest {
    organizationId: number;
    body: OrganizationModel;
}

export interface UpdateOrganizationFeaturesRequest {
    organizationId: number;
    body: OrganizationFeatures;
}


/**
 * 
 */
export class OrganizationsApi extends runtime.BaseAPI {
    readonly name = 'OrganizationsApi'

    /**
     * Create a new organization.
     */
    addOrganization = Object.assign(
        async (requestParameters: AddOrganizationRequest, initOverrides?: RequestInit): Promise<OrganizationResponse> => {
            const response = await this.addOrganization.invokeRaw(requestParameters, initOverrides);
            return await response.value();
        },
        {
            invokeRaw: async (requestParameters: AddOrganizationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<OrganizationResponse>> => {
                if (requestParameters.body === null || requestParameters.body === undefined) {
                    throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling addOrganization.');
                }
            
                const queryParameters: any = {};
            
                const headerParameters: runtime.HTTPHeaders = {};
            
                headerParameters['Content-Type'] = 'application/json';
            
                if (this.configuration && this.configuration.accessToken) {
                    const token = this.configuration.accessToken;
                    const tokenString = await token("token", []);
            
                    if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                    }
                }
                const response = await this.request({
                    path: `/api/organizations/`,
                    method: 'POST',
                    headers: headerParameters,
                    query: queryParameters,
                    body: OrganizationModelToJSON(requestParameters.body),
                }, initOverrides);
            
                return new runtime.JSONApiResponse(response, (jsonValue) => OrganizationResponseFromJSON(jsonValue));
            },
            mutationKey: [
                'OrganizationsApi',
                'addOrganization', 
            ],
            queryKey: (requestParameters: AddOrganizationRequest, initOverrides?: RequestInit) => ([
                    ...this.addOrganization.mutationKey, 
                {
                body: requestParameters.body,
                }
            ]),
            query: (requestParameters: AddOrganizationRequest, initOverrides?: RequestInit): UseQueryOptions<OrganizationResponse> & {queryKey: QueryKey} => ({
                queryFn: () => this.addOrganization(requestParameters, initOverrides),
                queryKey: this.addOrganization.queryKey(requestParameters, initOverrides)
            })
        }
    )

    /**
     * Make a user an administrator of an organization.
     */
    addOrganizationAdmin = Object.assign(
        async (requestParameters: AddOrganizationAdminRequest, initOverrides?: RequestInit): Promise<SuccessResponse> => {
            const response = await this.addOrganizationAdmin.invokeRaw(requestParameters, initOverrides);
            return await response.value();
        },
        {
            invokeRaw: async (requestParameters: AddOrganizationAdminRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SuccessResponse>> => {
                if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
                    throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling addOrganizationAdmin.');
                }
            
                if (requestParameters.userId === null || requestParameters.userId === undefined) {
                    throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling addOrganizationAdmin.');
                }
            
                const queryParameters: any = {};
            
                const headerParameters: runtime.HTTPHeaders = {};
            
                if (this.configuration && this.configuration.accessToken) {
                    const token = this.configuration.accessToken;
                    const tokenString = await token("token", []);
            
                    if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                    }
                }
                const response = await this.request({
                    path: `/api/organizations/{organization_id}/administrators/{user_id}`.replace(`{${"organization_id"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))),
                    method: 'PUT',
                    headers: headerParameters,
                    query: queryParameters,
                }, initOverrides);
            
                return new runtime.JSONApiResponse(response, (jsonValue) => SuccessResponseFromJSON(jsonValue));
            },
            mutationKey: [
                'OrganizationsApi',
                'addOrganizationAdmin', 
            ],
            queryKey: (requestParameters: AddOrganizationAdminRequest, initOverrides?: RequestInit) => ([
                    ...this.addOrganizationAdmin.mutationKey, 
                {
                organizationId: requestParameters.organizationId,
                userId: requestParameters.userId,
                }
            ]),
            query: (requestParameters: AddOrganizationAdminRequest, initOverrides?: RequestInit): UseQueryOptions<SuccessResponse> & {queryKey: QueryKey} => ({
                queryFn: () => this.addOrganizationAdmin(requestParameters, initOverrides),
                queryKey: this.addOrganizationAdmin.queryKey(requestParameters, initOverrides)
            })
        }
    )

    /**
     * Transfer a user into an organization.
     */
    addOrganizationMember = Object.assign(
        async (requestParameters: AddOrganizationMemberRequest, initOverrides?: RequestInit): Promise<SuccessResponse> => {
            const response = await this.addOrganizationMember.invokeRaw(requestParameters, initOverrides);
            return await response.value();
        },
        {
            invokeRaw: async (requestParameters: AddOrganizationMemberRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SuccessResponse>> => {
                if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
                    throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling addOrganizationMember.');
                }
            
                if (requestParameters.userId === null || requestParameters.userId === undefined) {
                    throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling addOrganizationMember.');
                }
            
                const queryParameters: any = {};
            
                const headerParameters: runtime.HTTPHeaders = {};
            
                if (this.configuration && this.configuration.accessToken) {
                    const token = this.configuration.accessToken;
                    const tokenString = await token("token", []);
            
                    if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                    }
                }
                const response = await this.request({
                    path: `/api/organizations/{organization_id}/members/{user_id}`.replace(`{${"organization_id"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))),
                    method: 'PUT',
                    headers: headerParameters,
                    query: queryParameters,
                }, initOverrides);
            
                return new runtime.JSONApiResponse(response, (jsonValue) => SuccessResponseFromJSON(jsonValue));
            },
            mutationKey: [
                'OrganizationsApi',
                'addOrganizationMember', 
            ],
            queryKey: (requestParameters: AddOrganizationMemberRequest, initOverrides?: RequestInit) => ([
                    ...this.addOrganizationMember.mutationKey, 
                {
                organizationId: requestParameters.organizationId,
                userId: requestParameters.userId,
                }
            ]),
            query: (requestParameters: AddOrganizationMemberRequest, initOverrides?: RequestInit): UseQueryOptions<SuccessResponse> & {queryKey: QueryKey} => ({
                queryFn: () => this.addOrganizationMember(requestParameters, initOverrides),
                queryKey: this.addOrganizationMember.queryKey(requestParameters, initOverrides)
            })
        }
    )

    /**
     * Delete an organization.
     */
    deleteOrganization = Object.assign(
        async (requestParameters: DeleteOrganizationRequest, initOverrides?: RequestInit): Promise<SuccessResponse> => {
            const response = await this.deleteOrganization.invokeRaw(requestParameters, initOverrides);
            return await response.value();
        },
        {
            invokeRaw: async (requestParameters: DeleteOrganizationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SuccessResponse>> => {
                if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
                    throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling deleteOrganization.');
                }
            
                const queryParameters: any = {};
            
                const headerParameters: runtime.HTTPHeaders = {};
            
                if (this.configuration && this.configuration.accessToken) {
                    const token = this.configuration.accessToken;
                    const tokenString = await token("token", []);
            
                    if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                    }
                }
                const response = await this.request({
                    path: `/api/organizations/{organization_id}/`.replace(`{${"organization_id"}}`, encodeURIComponent(String(requestParameters.organizationId))),
                    method: 'DELETE',
                    headers: headerParameters,
                    query: queryParameters,
                }, initOverrides);
            
                return new runtime.JSONApiResponse(response, (jsonValue) => SuccessResponseFromJSON(jsonValue));
            },
            mutationKey: [
                'OrganizationsApi',
                'deleteOrganization', 
            ],
            queryKey: (requestParameters: DeleteOrganizationRequest, initOverrides?: RequestInit) => ([
                    ...this.deleteOrganization.mutationKey, 
                {
                organizationId: requestParameters.organizationId,
                }
            ]),
            query: (requestParameters: DeleteOrganizationRequest, initOverrides?: RequestInit): UseQueryOptions<SuccessResponse> & {queryKey: QueryKey} => ({
                queryFn: () => this.deleteOrganization(requestParameters, initOverrides),
                queryKey: this.deleteOrganization.queryKey(requestParameters, initOverrides)
            })
        }
    )

    /**
     * Get MQTT publisher settings for the given locality.
     */
    getMqttPublishers = Object.assign(
        async (requestParameters: GetMqttPublishersRequest, initOverrides?: RequestInit): Promise<MQTTPublisherPreferencesList> => {
            const response = await this.getMqttPublishers.invokeRaw(requestParameters, initOverrides);
            return await response.value();
        },
        {
            invokeRaw: async (requestParameters: GetMqttPublishersRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<MQTTPublisherPreferencesList>> => {
                if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
                    throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling getMqttPublishers.');
                }
            
                const queryParameters: any = {};
            
                const headerParameters: runtime.HTTPHeaders = {};
            
                if (this.configuration && this.configuration.accessToken) {
                    const token = this.configuration.accessToken;
                    const tokenString = await token("token", []);
            
                    if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                    }
                }
                const response = await this.request({
                    path: `/api/organizations/{organization_id}/mqtt_publishers`.replace(`{${"organization_id"}}`, encodeURIComponent(String(requestParameters.organizationId))),
                    method: 'GET',
                    headers: headerParameters,
                    query: queryParameters,
                }, initOverrides);
            
                return new runtime.JSONApiResponse(response, (jsonValue) => MQTTPublisherPreferencesListFromJSON(jsonValue));
            },
            mutationKey: [
                'OrganizationsApi',
                'getMqttPublishers', 
            ],
            queryKey: (requestParameters: GetMqttPublishersRequest, initOverrides?: RequestInit) => ([
                    ...this.getMqttPublishers.mutationKey, 
                {
                organizationId: requestParameters.organizationId,
                }
            ]),
            query: (requestParameters: GetMqttPublishersRequest, initOverrides?: RequestInit): UseQueryOptions<MQTTPublisherPreferencesList> & {queryKey: QueryKey} => ({
                queryFn: () => this.getMqttPublishers(requestParameters, initOverrides),
                queryKey: this.getMqttPublishers.queryKey(requestParameters, initOverrides)
            })
        }
    )

    /**
     * Fetch details of an organization.
     */
    getOrganization = Object.assign(
        async (requestParameters: GetOrganizationRequest, initOverrides?: RequestInit): Promise<OrganizationResponse> => {
            const response = await this.getOrganization.invokeRaw(requestParameters, initOverrides);
            return await response.value();
        },
        {
            invokeRaw: async (requestParameters: GetOrganizationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<OrganizationResponse>> => {
                if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
                    throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling getOrganization.');
                }
            
                const queryParameters: any = {};
            
                const headerParameters: runtime.HTTPHeaders = {};
            
                if (this.configuration && this.configuration.accessToken) {
                    const token = this.configuration.accessToken;
                    const tokenString = await token("token", []);
            
                    if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                    }
                }
                const response = await this.request({
                    path: `/api/organizations/{organization_id}/`.replace(`{${"organization_id"}}`, encodeURIComponent(String(requestParameters.organizationId))),
                    method: 'GET',
                    headers: headerParameters,
                    query: queryParameters,
                }, initOverrides);
            
                return new runtime.JSONApiResponse(response, (jsonValue) => OrganizationResponseFromJSON(jsonValue));
            },
            mutationKey: [
                'OrganizationsApi',
                'getOrganization', 
            ],
            queryKey: (requestParameters: GetOrganizationRequest, initOverrides?: RequestInit) => ([
                    ...this.getOrganization.mutationKey, 
                {
                organizationId: requestParameters.organizationId,
                }
            ]),
            query: (requestParameters: GetOrganizationRequest, initOverrides?: RequestInit): UseQueryOptions<OrganizationResponse> & {queryKey: QueryKey} => ({
                queryFn: () => this.getOrganization(requestParameters, initOverrides),
                queryKey: this.getOrganization.queryKey(requestParameters, initOverrides)
            })
        }
    )

    /**
     * Get a list of all devices in an organization.
     */
    getOrganizationDevices = Object.assign(
        async (requestParameters: GetOrganizationDevicesRequest, initOverrides?: RequestInit): Promise<DeviceListResponse> => {
            const response = await this.getOrganizationDevices.invokeRaw(requestParameters, initOverrides);
            return await response.value();
        },
        {
            invokeRaw: async (requestParameters: GetOrganizationDevicesRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<DeviceListResponse>> => {
                if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
                    throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling getOrganizationDevices.');
                }
            
                const queryParameters: any = {};
            
                const headerParameters: runtime.HTTPHeaders = {};
            
                if (this.configuration && this.configuration.accessToken) {
                    const token = this.configuration.accessToken;
                    const tokenString = await token("token", []);
            
                    if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                    }
                }
                const response = await this.request({
                    path: `/api/organizations/{organization_id}/devices/`.replace(`{${"organization_id"}}`, encodeURIComponent(String(requestParameters.organizationId))),
                    method: 'GET',
                    headers: headerParameters,
                    query: queryParameters,
                }, initOverrides);
            
                return new runtime.JSONApiResponse(response, (jsonValue) => DeviceListResponseFromJSON(jsonValue));
            },
            mutationKey: [
                'OrganizationsApi',
                'getOrganizationDevices', 
            ],
            queryKey: (requestParameters: GetOrganizationDevicesRequest, initOverrides?: RequestInit) => ([
                    ...this.getOrganizationDevices.mutationKey, 
                {
                organizationId: requestParameters.organizationId,
                }
            ]),
            query: (requestParameters: GetOrganizationDevicesRequest, initOverrides?: RequestInit): UseQueryOptions<DeviceListResponse> & {queryKey: QueryKey} => ({
                queryFn: () => this.getOrganizationDevices(requestParameters, initOverrides),
                queryKey: this.getOrganizationDevices.queryKey(requestParameters, initOverrides)
            })
        }
    )

    /**
     * Get a list of all members in an organization.
     */
    getOrganizationMembers = Object.assign(
        async (requestParameters: GetOrganizationMembersRequest, initOverrides?: RequestInit): Promise<UserListResponse> => {
            const response = await this.getOrganizationMembers.invokeRaw(requestParameters, initOverrides);
            return await response.value();
        },
        {
            invokeRaw: async (requestParameters: GetOrganizationMembersRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<UserListResponse>> => {
                if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
                    throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling getOrganizationMembers.');
                }
            
                const queryParameters: any = {};
            
                const headerParameters: runtime.HTTPHeaders = {};
            
                if (this.configuration && this.configuration.accessToken) {
                    const token = this.configuration.accessToken;
                    const tokenString = await token("token", []);
            
                    if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                    }
                }
                const response = await this.request({
                    path: `/api/organizations/{organization_id}/members/`.replace(`{${"organization_id"}}`, encodeURIComponent(String(requestParameters.organizationId))),
                    method: 'GET',
                    headers: headerParameters,
                    query: queryParameters,
                }, initOverrides);
            
                return new runtime.JSONApiResponse(response, (jsonValue) => UserListResponseFromJSON(jsonValue));
            },
            mutationKey: [
                'OrganizationsApi',
                'getOrganizationMembers', 
            ],
            queryKey: (requestParameters: GetOrganizationMembersRequest, initOverrides?: RequestInit) => ([
                    ...this.getOrganizationMembers.mutationKey, 
                {
                organizationId: requestParameters.organizationId,
                }
            ]),
            query: (requestParameters: GetOrganizationMembersRequest, initOverrides?: RequestInit): UseQueryOptions<UserListResponse> & {queryKey: QueryKey} => ({
                queryFn: () => this.getOrganizationMembers(requestParameters, initOverrides),
                queryKey: this.getOrganizationMembers.queryKey(requestParameters, initOverrides)
            })
        }
    )

    /**
     * Get a list of all scenes in an organization.
     */
    getOrganizationScenes = Object.assign(
        async (requestParameters: GetOrganizationScenesRequest, initOverrides?: RequestInit): Promise<SceneListResponse> => {
            const response = await this.getOrganizationScenes.invokeRaw(requestParameters, initOverrides);
            return await response.value();
        },
        {
            invokeRaw: async (requestParameters: GetOrganizationScenesRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SceneListResponse>> => {
                if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
                    throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling getOrganizationScenes.');
                }
            
                const queryParameters: any = {};
            
                const headerParameters: runtime.HTTPHeaders = {};
            
                if (this.configuration && this.configuration.accessToken) {
                    const token = this.configuration.accessToken;
                    const tokenString = await token("token", []);
            
                    if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                    }
                }
                const response = await this.request({
                    path: `/api/organizations/{organization_id}/scenes/`.replace(`{${"organization_id"}}`, encodeURIComponent(String(requestParameters.organizationId))),
                    method: 'GET',
                    headers: headerParameters,
                    query: queryParameters,
                }, initOverrides);
            
                return new runtime.JSONApiResponse(response, (jsonValue) => SceneListResponseFromJSON(jsonValue));
            },
            mutationKey: [
                'OrganizationsApi',
                'getOrganizationScenes', 
            ],
            queryKey: (requestParameters: GetOrganizationScenesRequest, initOverrides?: RequestInit) => ([
                    ...this.getOrganizationScenes.mutationKey, 
                {
                organizationId: requestParameters.organizationId,
                }
            ]),
            query: (requestParameters: GetOrganizationScenesRequest, initOverrides?: RequestInit): UseQueryOptions<SceneListResponse> & {queryKey: QueryKey} => ({
                queryFn: () => this.getOrganizationScenes(requestParameters, initOverrides),
                queryKey: this.getOrganizationScenes.queryKey(requestParameters, initOverrides)
            })
        }
    )

    /**
     * Get a list of organizations.
     */
    getOrganizations = Object.assign(
        async (initOverrides?: RequestInit): Promise<OrganizationListResponse> => {
            const response = await this.getOrganizations.invokeRaw(initOverrides);
            return await response.value();
        },
        {
            invokeRaw: async (initOverrides?: RequestInit): Promise<runtime.ApiResponse<OrganizationListResponse>> => {
                const queryParameters: any = {};
            
                const headerParameters: runtime.HTTPHeaders = {};
            
                if (this.configuration && this.configuration.accessToken) {
                    const token = this.configuration.accessToken;
                    const tokenString = await token("token", []);
            
                    if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                    }
                }
                const response = await this.request({
                    path: `/api/organizations/`,
                    method: 'GET',
                    headers: headerParameters,
                    query: queryParameters,
                }, initOverrides);
            
                return new runtime.JSONApiResponse(response, (jsonValue) => OrganizationListResponseFromJSON(jsonValue));
            },
            mutationKey: [
                'OrganizationsApi',
                'getOrganizations', 
            ],
            queryKey: (initOverrides?: RequestInit) => ([
                    ...this.getOrganizations.mutationKey, 
                {
                }
            ]),
            query: (initOverrides?: RequestInit): UseQueryOptions<OrganizationListResponse> & {queryKey: QueryKey} => ({
                queryFn: () => this.getOrganizations(initOverrides),
                queryKey: this.getOrganizations.queryKey(initOverrides)
            })
        }
    )

    /**
     * Invite users (specified by a list of e-mail addresses) to join an organization by e-mail.
     * User accounts are created, but they cannot be logged into until an invitation link is used to set up a password.
     */
    inviteUsersToOrganization = Object.assign(
        async (requestParameters: InviteUsersToOrganizationRequest, initOverrides?: RequestInit): Promise<SuccessResponse> => {
            const response = await this.inviteUsersToOrganization.invokeRaw(requestParameters, initOverrides);
            return await response.value();
        },
        {
            invokeRaw: async (requestParameters: InviteUsersToOrganizationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SuccessResponse>> => {
                if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
                    throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling inviteUsersToOrganization.');
                }
            
                if (requestParameters.body === null || requestParameters.body === undefined) {
                    throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling inviteUsersToOrganization.');
                }
            
                const queryParameters: any = {};
            
                const headerParameters: runtime.HTTPHeaders = {};
            
                headerParameters['Content-Type'] = 'application/json';
            
                if (this.configuration && this.configuration.accessToken) {
                    const token = this.configuration.accessToken;
                    const tokenString = await token("token", []);
            
                    if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                    }
                }
                const response = await this.request({
                    path: `/api/organizations/{organization_id}/members/`.replace(`{${"organization_id"}}`, encodeURIComponent(String(requestParameters.organizationId))),
                    method: 'POST',
                    headers: headerParameters,
                    query: queryParameters,
                    body: InviteUsersRequestBodyToJSON(requestParameters.body),
                }, initOverrides);
            
                return new runtime.JSONApiResponse(response, (jsonValue) => SuccessResponseFromJSON(jsonValue));
            },
            mutationKey: [
                'OrganizationsApi',
                'inviteUsersToOrganization', 
            ],
            queryKey: (requestParameters: InviteUsersToOrganizationRequest, initOverrides?: RequestInit) => ([
                    ...this.inviteUsersToOrganization.mutationKey, 
                {
                organizationId: requestParameters.organizationId,
                body: requestParameters.body,
                }
            ]),
            query: (requestParameters: InviteUsersToOrganizationRequest, initOverrides?: RequestInit): UseQueryOptions<SuccessResponse> & {queryKey: QueryKey} => ({
                queryFn: () => this.inviteUsersToOrganization(requestParameters, initOverrides),
                queryKey: this.inviteUsersToOrganization.queryKey(requestParameters, initOverrides)
            })
        }
    )

    /**
     * Register a new organization, together with a new administrator account. This endpoint is meant to be used by
     * new users (organization owners). Invitation e-mail is currently not sent when using this endpoint.
     */
    registerOrganization = Object.assign(
        async (requestParameters: RegisterOrganizationRequest, initOverrides?: RequestInit): Promise<OrganizationResponse> => {
            const response = await this.registerOrganization.invokeRaw(requestParameters, initOverrides);
            return await response.value();
        },
        {
            invokeRaw: async (requestParameters: RegisterOrganizationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<OrganizationResponse>> => {
                if (requestParameters.body === null || requestParameters.body === undefined) {
                    throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling registerOrganization.');
                }
            
                const queryParameters: any = {};
            
                const headerParameters: runtime.HTTPHeaders = {};
            
                headerParameters['Content-Type'] = 'application/json';
            
                const response = await this.request({
                    path: `/api/organizations/register`,
                    method: 'POST',
                    headers: headerParameters,
                    query: queryParameters,
                    body: RegisterOrganizationRequestBodyToJSON(requestParameters.body),
                }, initOverrides);
            
                return new runtime.JSONApiResponse(response, (jsonValue) => OrganizationResponseFromJSON(jsonValue));
            },
            mutationKey: [
                'OrganizationsApi',
                'registerOrganization', 
            ],
            queryKey: (requestParameters: RegisterOrganizationRequest, initOverrides?: RequestInit) => ([
                    ...this.registerOrganization.mutationKey, 
                {
                body: requestParameters.body,
                }
            ]),
            query: (requestParameters: RegisterOrganizationRequest, initOverrides?: RequestInit): UseQueryOptions<OrganizationResponse> & {queryKey: QueryKey} => ({
                queryFn: () => this.registerOrganization(requestParameters, initOverrides),
                queryKey: this.registerOrganization.queryKey(requestParameters, initOverrides)
            })
        }
    )

    /**
     * Configure MQTT publisher settings for the given locality.
     */
    setMqttPublishers = Object.assign(
        async (requestParameters: SetMqttPublishersRequest, initOverrides?: RequestInit): Promise<SuccessResponse> => {
            const response = await this.setMqttPublishers.invokeRaw(requestParameters, initOverrides);
            return await response.value();
        },
        {
            invokeRaw: async (requestParameters: SetMqttPublishersRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SuccessResponse>> => {
                if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
                    throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling setMqttPublishers.');
                }
            
                if (requestParameters.body === null || requestParameters.body === undefined) {
                    throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling setMqttPublishers.');
                }
            
                const queryParameters: any = {};
            
                const headerParameters: runtime.HTTPHeaders = {};
            
                headerParameters['Content-Type'] = 'application/json';
            
                if (this.configuration && this.configuration.accessToken) {
                    const token = this.configuration.accessToken;
                    const tokenString = await token("token", []);
            
                    if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                    }
                }
                const response = await this.request({
                    path: `/api/organizations/{organization_id}/mqtt_publishers`.replace(`{${"organization_id"}}`, encodeURIComponent(String(requestParameters.organizationId))),
                    method: 'PUT',
                    headers: headerParameters,
                    query: queryParameters,
                    body: MQTTPublisherPreferencesListToJSON(requestParameters.body),
                }, initOverrides);
            
                return new runtime.JSONApiResponse(response, (jsonValue) => SuccessResponseFromJSON(jsonValue));
            },
            mutationKey: [
                'OrganizationsApi',
                'setMqttPublishers', 
            ],
            queryKey: (requestParameters: SetMqttPublishersRequest, initOverrides?: RequestInit) => ([
                    ...this.setMqttPublishers.mutationKey, 
                {
                organizationId: requestParameters.organizationId,
                body: requestParameters.body,
                }
            ]),
            query: (requestParameters: SetMqttPublishersRequest, initOverrides?: RequestInit): UseQueryOptions<SuccessResponse> & {queryKey: QueryKey} => ({
                queryFn: () => this.setMqttPublishers(requestParameters, initOverrides),
                queryKey: this.setMqttPublishers.queryKey(requestParameters, initOverrides)
            })
        }
    )

    /**
     * Update organization data.
     */
    updateOrganization = Object.assign(
        async (requestParameters: UpdateOrganizationRequest, initOverrides?: RequestInit): Promise<OrganizationResponse> => {
            const response = await this.updateOrganization.invokeRaw(requestParameters, initOverrides);
            return await response.value();
        },
        {
            invokeRaw: async (requestParameters: UpdateOrganizationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<OrganizationResponse>> => {
                if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
                    throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling updateOrganization.');
                }
            
                if (requestParameters.body === null || requestParameters.body === undefined) {
                    throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling updateOrganization.');
                }
            
                const queryParameters: any = {};
            
                const headerParameters: runtime.HTTPHeaders = {};
            
                headerParameters['Content-Type'] = 'application/json';
            
                if (this.configuration && this.configuration.accessToken) {
                    const token = this.configuration.accessToken;
                    const tokenString = await token("token", []);
            
                    if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                    }
                }
                const response = await this.request({
                    path: `/api/organizations/{organization_id}/`.replace(`{${"organization_id"}}`, encodeURIComponent(String(requestParameters.organizationId))),
                    method: 'PUT',
                    headers: headerParameters,
                    query: queryParameters,
                    body: OrganizationModelToJSON(requestParameters.body),
                }, initOverrides);
            
                return new runtime.JSONApiResponse(response, (jsonValue) => OrganizationResponseFromJSON(jsonValue));
            },
            mutationKey: [
                'OrganizationsApi',
                'updateOrganization', 
            ],
            queryKey: (requestParameters: UpdateOrganizationRequest, initOverrides?: RequestInit) => ([
                    ...this.updateOrganization.mutationKey, 
                {
                organizationId: requestParameters.organizationId,
                body: requestParameters.body,
                }
            ]),
            query: (requestParameters: UpdateOrganizationRequest, initOverrides?: RequestInit): UseQueryOptions<OrganizationResponse> & {queryKey: QueryKey} => ({
                queryFn: () => this.updateOrganization(requestParameters, initOverrides),
                queryKey: this.updateOrganization.queryKey(requestParameters, initOverrides)
            })
        }
    )

    /**
     * Update organization features.
     */
    updateOrganizationFeatures = Object.assign(
        async (requestParameters: UpdateOrganizationFeaturesRequest, initOverrides?: RequestInit): Promise<OrganizationResponse> => {
            const response = await this.updateOrganizationFeatures.invokeRaw(requestParameters, initOverrides);
            return await response.value();
        },
        {
            invokeRaw: async (requestParameters: UpdateOrganizationFeaturesRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<OrganizationResponse>> => {
                if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
                    throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling updateOrganizationFeatures.');
                }
            
                if (requestParameters.body === null || requestParameters.body === undefined) {
                    throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling updateOrganizationFeatures.');
                }
            
                const queryParameters: any = {};
            
                const headerParameters: runtime.HTTPHeaders = {};
            
                headerParameters['Content-Type'] = 'application/json';
            
                if (this.configuration && this.configuration.accessToken) {
                    const token = this.configuration.accessToken;
                    const tokenString = await token("token", []);
            
                    if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                    }
                }
                const response = await this.request({
                    path: `/api/organizations/{organization_id}/features`.replace(`{${"organization_id"}}`, encodeURIComponent(String(requestParameters.organizationId))),
                    method: 'PUT',
                    headers: headerParameters,
                    query: queryParameters,
                    body: OrganizationFeaturesToJSON(requestParameters.body),
                }, initOverrides);
            
                return new runtime.JSONApiResponse(response, (jsonValue) => OrganizationResponseFromJSON(jsonValue));
            },
            mutationKey: [
                'OrganizationsApi',
                'updateOrganizationFeatures', 
            ],
            queryKey: (requestParameters: UpdateOrganizationFeaturesRequest, initOverrides?: RequestInit) => ([
                    ...this.updateOrganizationFeatures.mutationKey, 
                {
                organizationId: requestParameters.organizationId,
                body: requestParameters.body,
                }
            ]),
            query: (requestParameters: UpdateOrganizationFeaturesRequest, initOverrides?: RequestInit): UseQueryOptions<OrganizationResponse> & {queryKey: QueryKey} => ({
                queryFn: () => this.updateOrganizationFeatures(requestParameters, initOverrides),
                queryKey: this.updateOrganizationFeatures.queryKey(requestParameters, initOverrides)
            })
        }
    )

}
