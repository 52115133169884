import produce from 'immer'
import { isEmpty, partial } from 'lodash'
import React, { useCallback, useRef, useState, MouseEventHandler, MouseEvent, useEffect } from 'react'

import { Point } from '@api'

import { EditableSceneObject, isDetectionZone, isVisitBoundary } from '@helpers/describeScene'
import { minus, plus, scale } from '@helpers/points'
import { XOR } from '@helpers/types'

import { useRenderTrigger } from '@hooks/useRenderTrigger'

import styles from './DescribeSceneEditor.module.scss'
import { Boundary } from './elements/Boundary'
import { Zone } from './elements/Zone'
import { EditorDimensions } from './useSvgEditorData'

type DraggingState = XOR<{ origin: Point }, { index: number }>

const determineMousePosition = (svg: SVGSVGElement, event: MouseEvent): Point => {
    const { left, top } = svg.getBoundingClientRect()

    return { x: event.clientX - left, y: event.clientY - top }
}

const SceneObjectEditLayer: React.FC<{
    svgElement: SVGSVGElement
    sceneObject: EditableSceneObject
    onChange: (sceneObject: EditableSceneObject) => void
    editorDimensions: EditorDimensions
}> = ({ sceneObject: rawSceneObject, onChange, editorDimensions, svgElement }) => {
    const [draggingState, setDraggingState] = useState<DraggingState>()
    const modifiedPoints = useRef<Array<Point>>([])

    const scaleIn = useCallback((p: Point) => scale(p, editorDimensions.scale), [editorDimensions.scale])
    const scaleOut = useCallback((p: Point) => scale(p, 1 / editorDimensions.scale), [editorDimensions.scale])

    const render = useRenderTrigger(66)

    const handleMouseMove: MouseEventHandler = useCallback(
        (e) => {
            if (draggingState === undefined) {
                return
            }

            const mousePosition = determineMousePosition(svgElement, e)

            // Moving a single point
            if (draggingState.index !== undefined) {
                modifiedPoints.current[draggingState.index] = mousePosition
            }

            // Moving the entire object
            if (draggingState.origin !== undefined) {
                const direction = minus(mousePosition, draggingState.origin)
                modifiedPoints.current = rawSceneObject.points.map(scaleIn).map(partial(plus, direction))
            }

            render()
        },
        [draggingState, rawSceneObject.points, scaleIn, render]
    )

    const handlePointDragStart = useCallback(
        (index: number) => {
            modifiedPoints.current = rawSceneObject.points.map(scaleIn)
            setDraggingState({ index })
        },
        [rawSceneObject, scaleIn]
    )

    const handleObjectDragStart = useCallback(
        (event: MouseEvent) => {
            if (svgElement === null) {
                return
            }

            modifiedPoints.current = rawSceneObject.points.map(scaleIn)
            setDraggingState({ origin: determineMousePosition(svgElement, event) })
        },
        [rawSceneObject, scaleIn]
    )

    const handlePointInsert = useCallback(
        (event: MouseEvent, index: number) => {
            modifiedPoints.current = rawSceneObject.points.map(scaleIn)
            modifiedPoints.current.splice(index, 0, determineMousePosition(svgElement, event))
            setDraggingState({ index })
        },
        [rawSceneObject, scaleIn]
    )

    const handlePointDelete = useCallback(
        (pointIndex: number) =>
            onChange(
                produce(rawSceneObject, (draft) => {
                    draft.points.splice(pointIndex, 1)
                })
            ),
        [rawSceneObject, onChange]
    )

    const finishDragging: MouseEventHandler = useCallback(() => {
        if (draggingState !== undefined) {
            setDraggingState(undefined)
            onChange({ ...rawSceneObject, points: modifiedPoints.current.map(scaleOut) })
        }
    }, [rawSceneObject, draggingState, scaleOut])

    // If given an empty object, replace it with a reasonable default
    useEffect(() => {
        if (isEmpty(rawSceneObject.points)) {
            const width = editorDimensions.width
            const height = editorDimensions.height ?? 300

            if (isVisitBoundary(rawSceneObject)) {
                onChange({
                    ...rawSceneObject,
                    points: [
                        { x: width / 3, y: height / 2 },
                        { x: (2 * width) / 3, y: height / 2 },
                    ].map(scaleOut),
                })
            }

            if (isDetectionZone(rawSceneObject)) {
                onChange({
                    ...rawSceneObject,
                    points: [
                        { x: width / 3, y: height / 3 },
                        { x: (2 * width) / 3, y: height / 3 },
                        { x: (2 * width) / 3, y: (2 * height) / 3 },
                        { x: width / 3, y: (2 * height) / 3 },
                    ].map(scaleOut),
                })
            }
        }
    }, [rawSceneObject, onChange, scaleOut])

    const commonProps = {
        isHighlighted: false,
        isEditable: true,
        points: draggingState !== undefined ? modifiedPoints.current : rawSceneObject.points.map(scaleIn),
        onPointDelete: handlePointDelete,
        onPointDragStart: handlePointDragStart,
        onPointInsert: handlePointInsert,
    }

    const objectVisualization = isVisitBoundary(rawSceneObject) ? (
        <Boundary editorWidth={editorDimensions.width} type={rawSceneObject.type} {...commonProps} />
    ) : isDetectionZone(rawSceneObject) ? (
        <Zone type={rawSceneObject.type} onZoneDragStart={handleObjectDragStart} {...commonProps} />
    ) : null

    return (
        <svg
            height={editorDimensions.height}
            width={editorDimensions.width}
            onMouseLeave={finishDragging}
            onMouseMove={handleMouseMove}
            onMouseUp={finishDragging}
        >
            {draggingState !== undefined && <rect className={styles.draggingLid} />}
            {objectVisualization}
        </svg>
    )
}

export default SceneObjectEditLayer
