import classNames from 'classnames'
import produce from 'immer'
import { groupBy, head, isEmpty, isEqual, keyBy, omit, orderBy } from 'lodash'
import React, { useState } from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import { Trans, useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import { useHistory } from 'react-router'
import AutoSizer from 'react-virtualized-auto-sizer'

import { FloorplanMapping, LocalityResponse, OrganizationResponse, SceneResponse } from '@api'

import { localityApi } from '@services'

import { generateOrganizationsEditLocalitiesPath } from '@helpers/VividiURLs'
import { useApiCallCleaner } from '@helpers/api'

import Box from '@elements/Box/Box'

import ErrorView from '@components/ErrorView'
import FloorplanDropdown from '@components/FloorplanForm/FloorplanDropdown'
import { useNotify } from '@components/notifications/NotificationsContext'

import FloorplanPolygonConfiguration from './FloorplanPolygonConfiguration'
import ScenePolygonConfiguration from './ScenePolygonConfiguration'
import DevicesListGroup from './ScenesListGroup'
import styles from './ScenesToFloorplan.module.scss'
import { FloorplanMappingPolygon } from './ScenesTypes'

const defaultPolygon = [
    {
        x: 220,
        y: 210,
    },
    {
        x: 230,
        y: 510,
    },
    {
        x: 500,
        y: 530,
    },
    {
        x: 500,
        y: 310,
    },
]

enum DeviceHeaderViews {
    DevicesToFloorplanView = 'devices-to-floorplan',
    DescribeSceneView = 'describe-scene',
}

interface Props {
    scenesInLocality: SceneResponse[]
    locality: LocalityResponse
    organization: OrganizationResponse
    mappings: Array<FloorplanMapping>
    selectedFloorplanNumber?: number
}

const ScenesToFloorplan: React.FC<Props> = ({
    scenesInLocality,
    locality,
    organization,
    mappings,
    selectedFloorplanNumber,
}) => {
    const { t } = useTranslation()

    const notify = useNotify()
    const history = useHistory()
    const clean = useApiCallCleaner()

    const { mutateAsync: updateFloorplanMappings } = useMutation(localityApi.updateLocalityFloorplanMappings)

    const [selectedFloorplan, setSelectedFloorplan] = useState<number>(selectedFloorplanNumber ?? 0)

    // eslint-disable-next-line
    const [view, setView] = useState(DeviceHeaderViews.DevicesToFloorplanView)

    const devicesDictionary = keyBy(scenesInLocality, 'id')

    const defaultFloorplanMappings = keyBy(mappings, (m) => m.sceneId)

    const [floorplanMappings, setFloorplanMappings] = useState(defaultFloorplanMappings)

    // order by deviceId to prevent inconsistent order with deviceAssignment

    const assignedScenes = orderBy(
        scenesInLocality.filter((scene) => floorplanMappings[scene.id]?.floorplanId === selectedFloorplan),
        'id',
        'asc'
    )
    const unassignedScenes = scenesInLocality.filter((scene) => floorplanMappings[scene.id] === undefined)

    const [selectedScene, setSelectedScene] = useState<SceneResponse | null>(head(assignedScenes) ?? null)

    const handleMappingUpdate = (floorplanMapping: FloorplanMappingPolygon[], isDeviceMappingPolygon?: boolean) => {
        setFloorplanMappings((prev) =>
            produce(prev, (draft) => {
                for (const mapping of floorplanMapping) {
                    const entry = draft[mapping.sceneId]

                    if (entry === undefined) {
                        continue
                    }

                    if (isDeviceMappingPolygon && !isEqual(entry.sceneMappingPolygon, mapping.points)) {
                        entry.sceneMappingPolygon = mapping.points
                    } else if (!isDeviceMappingPolygon && !isEqual(entry.floorplanMappingPolygon, mapping.points)) {
                        entry.floorplanMappingPolygon = mapping.points
                    }
                }
            })
        )
    }

    const handleSave = () => {
        Promise.all(
            Object.entries(groupBy(floorplanMappings, 'floorplanId')).map(([floorplanId, mappings]) =>
                updateFloorplanMappings({
                    organizationId: organization.id,
                    localityId: locality.id,
                    floorplanId: Number(floorplanId),
                    body: { mappings },
                })
            )
        )
            .then(() => {
                clean(localityApi)
                notify({
                    title: t('notification.floorplanConfiguredTitle', 'Floor plan mapping configured'),
                    content: t('notification.floorplanConfiguredContent', 'Floor mapping successfully configured.'),
                    variant: 'success',
                    timeoutSeconds: 3,
                })
            })
            .catch(() => {
                notify({
                    title: t('notification.floorplanUpdateFailedTitle', 'Updating floor plan mapping failed'),
                    content: t(
                        'notification.floorplanUpdateFailedContent',
                        'An error occurred while attempting to configure the floor plan mapping.'
                    ),
                    variant: 'danger',
                    timeoutSeconds: 3,
                })
            })
    }

    const handleRemoveFloorplanMapping = (deviceId: number) => {
        setFloorplanMappings((prev) => omit(prev, deviceId))

        // on remove set one of the remaining assigned devices as selected

        const selectedDeviceId = Object.keys(floorplanMappings).find(
            (deviceKey) =>
                deviceKey !== deviceId.toString() && floorplanMappings[deviceKey].floorplanId === selectedFloorplan
        )

        setSelectedScene(scenesInLocality.find((d) => d.id.toString() === selectedDeviceId) ?? null)
    }

    const handleCancelFloorplanMapping = () => {
        setFloorplanMappings(defaultFloorplanMappings)
        history.push(generateOrganizationsEditLocalitiesPath(organization.id, locality.id))
    }

    const handleSelectScene = (sceneId: number) => {
        setSelectedScene(scenesInLocality.find((device) => device.id === sceneId)!)

        // If the device has no mapping yet, insert a default
        setFloorplanMappings((prev) =>
            produce(prev, (draft) => {
                if (draft[sceneId] === undefined) {
                    draft[sceneId] = {
                        sceneId,
                        floorplanId: selectedFloorplan,
                        localityId: locality.id,
                        sceneMappingPolygon: defaultPolygon,
                        floorplanMappingPolygon: defaultPolygon,
                    }
                }
            })
        )
    }

    const handleSelectedFloorplan = (floorplanId: number) => {
        setSelectedFloorplan(floorplanId)

        // Floorplan changed -> we need to select a device as well
        const selectedDeviceId = Object.keys(floorplanMappings).find(
            (deviceId) => floorplanMappings[deviceId].floorplanId === floorplanId
        )

        setSelectedScene(scenesInLocality.find((d) => d.id.toString() === selectedDeviceId) ?? null)
    }

    const containsMultipleFloorplans = Object.keys(locality.floorplans).length > 1

    return (
        <Box paddingSize="lg">
            <Box.Title
                buttons={
                    <div className={styles.buttonGroup}>
                        {containsMultipleFloorplans && (
                            <FloorplanDropdown
                                floorplans={Object.entries(locality.floorplans)}
                                selectedFloorplan={selectedFloorplan}
                                onSelect={(id) => handleSelectedFloorplan(id)}
                            />
                        )}

                        <div className={styles.buttonGroupRight}>
                            <Button variant="secondary" onClick={handleCancelFloorplanMapping}>
                                {t('button.cancel', 'Cancel')}
                            </Button>
                            <Button variant="primary" onClick={handleSave}>
                                {t('button.saveChanges', 'Save changes')}
                            </Button>
                        </div>
                    </div>
                }
                text={t('heading.mapToFloorplan', 'Map to floor plan')}
            />
            {!isEmpty(unassignedScenes) || !isEmpty(assignedScenes) ? (
                <Row>
                    <Col lg={12}>
                        {view === DeviceHeaderViews.DescribeSceneView ? (
                            <span>
                                {t(
                                    'localityDevices.describeScene',
                                    'Add line if you want to count people crossing a specific boundary (entrance,  exit, street) for footfall or Stay Safe screen. Add zone and Point of sale zone if you want measure queue size, wait time in the queue and serve time.'
                                )}
                            </span>
                        ) : (
                            <span>
                                <Trans i18nKey="localityDevices.mapToFloorplan">
                                    Select the scene and
                                    <strong> map the points A,B,C,D on the camera scene </strong>
                                    and <strong> on the floorplan </strong> below so that the AB side is parallel with
                                    DC and AD side is parallel with BC side of the polygon. The
                                    <strong> same points</strong> should correspond to the
                                    <strong> same position</strong>.
                                </Trans>
                            </span>
                        )}
                    </Col>
                    <Col className={styles.devicesGridColumn} lg={3}>
                        <DevicesListGroup
                            assignedScenes={assignedScenes}
                            selectedScene={selectedScene ? selectedScene.id : null}
                            unassignedScenes={unassignedScenes}
                            onRemove={handleRemoveFloorplanMapping}
                            onSelectionChange={handleSelectScene}
                        />
                    </Col>
                    {view === DeviceHeaderViews.DevicesToFloorplanView && !selectedScene && (
                        <Col className={classNames([styles.devicesGridColumn, styles.devicesGridColumnCentered])}>
                            <ErrorView
                                message={t(
                                    'others.noScenesAssignedMessage',
                                    'No scenes are assigned to current floor plan. Please assign at least one device to the floor plan by selecting it from "Unassigned scenes" list to continue.'
                                )}
                                title={t('others.noScenesAssigned', 'No scenes assigned')}
                            />
                        </Col>
                    )}
                    {view === DeviceHeaderViews.DevicesToFloorplanView && selectedScene && (
                        <Col className={styles.devicesGridColumn}>
                            <AutoSizer key={selectedScene.id} disableHeight={true}>
                                {({ width }) => (
                                    <ScenePolygonConfiguration
                                        handleSetFloorplanMapping={(deviceMappingPolygon) =>
                                            handleMappingUpdate(deviceMappingPolygon, true)
                                        }
                                        mappingPolygons={
                                            floorplanMappings[selectedScene.id] !== undefined
                                                ? [
                                                      {
                                                          ...floorplanMappings[selectedScene.id],
                                                          sceneId: selectedScene.id,
                                                          points: floorplanMappings[selectedScene.id]
                                                              .sceneMappingPolygon,
                                                          isSelected: true,
                                                      },
                                                  ]
                                                : []
                                        }
                                        scene={selectedScene!}
                                        scenesInLocality={devicesDictionary}
                                        width={width}
                                    />
                                )}
                            </AutoSizer>
                        </Col>
                    )}
                </Row>
            ) : (
                <ErrorView
                    message={t(
                        'others.noScenesForFloorplan',
                        'No scenes are available for selected floor plan. Please unassign some scenes from other floor plans to enable their mapping.'
                    )}
                    title={t('appDashboard.noDevices', 'No devices')}
                />
            )}
            {(!isEmpty(assignedScenes) || !isEmpty(unassignedScenes)) && selectedScene && (
                <Row>
                    <Col className={styles.devicesGridColumn}>
                        <AutoSizer disableHeight={true}>
                            {({ width }) => (
                                <FloorplanPolygonConfiguration
                                    floorplanId={selectedFloorplan}
                                    handleSetFloorplanMapping={handleMappingUpdate}
                                    localityId={locality.id}
                                    mappingPolygons={Object.entries(floorplanMappings)
                                        .filter(([_, mapping]) => mapping.floorplanId === selectedFloorplan)
                                        .map(([deviceId, mapping]) => ({
                                            ...mapping,
                                            deviceId: Number(deviceId),
                                            points: mapping!.floorplanMappingPolygon,
                                            isSelected: selectedScene.id.toString() === deviceId,
                                        }))}
                                    organizationId={organization.id}
                                    scene={selectedScene}
                                    scenesInLocality={devicesDictionary}
                                    width={width}
                                    onFloorplanSelect={handleSelectScene}
                                />
                            )}
                        </AutoSizer>
                    </Col>
                </Row>
            )}
        </Box>
    )
}

export default ScenesToFloorplan
