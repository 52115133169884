import React from 'react'
import { Trans } from 'react-i18next'

const VividiBoxEULA = () => {
    return (
        <>
            <Trans i18nKey="termsAndConditions.vividiBoxEULA">
                <h1 id="end-user-license-agreement">End User License Agreement</h1>
                <p>What about the magic inside the box?</p>
                <h2 id="preamble">Preamble</h2>
                <h3 id="why-you-are-here">Why you are here:</h3>
                <p>
                    If you are reading this document, you have probably already bought the Vividi Box, or you are about
                    to buy one soon.
                </p>
                <p>
                    You will read (or you may already have read) through the Terms of Sale that govern your purchase of
                    the Vividi Box, and when you do so, you will learn that by purchasing the Vividi Box, you are buying
                    just the hardware, but the embedded software (firmware) is licensed to you.
                </p>
                <p>
                    To learn more about the licensing terms, please read through this Agreement that governs the license
                    grant and any rights and obligations of licensor and licensee thereunder.
                </p>
                <p>
                    If you accept the terms of this Agreement, you may go ahead and use the Vividi Box´s firmware as
                    described herein; otherwise we must ask you not to use it.
                </p>
                <p>As always, we will keep it brief.</p>
                <h2 id="parties">Parties</h2>
                <h3 id="lets-introduce-ourselves.">Let's introduce ourselves.</h3>
                <p>
                    We are a commercial company called Iterait a.s., ID No. 074&nbsp;05&nbsp;821, incorporated and
                    existing under the laws of the Czech Republic. Our legal address is Hybernská 1034/5, 110 00 Prague
                    1, Czech Republic; we are registered in the commercial register kept by the Municipal Court in
                    Prague under file No. B 23719.
                </p>
                <h3 id="okay-now-a-little-bit-about-you">Okay, now a little bit about “you”:</h3>
                <p>
                    When we refer to “you” in this Agreement, we are actually referring to anybody, whether individual
                    or legal entity, who uses our original software (firmware) embedded in any Vividi Box device.
                </p>
                <p>
                    It probably goes without saying, but your legal capacity must not be limited to the extent that
                    could in any way prevent you from effectively agreeing to and complying with this Agreement.
                </p>
                <p>
                    If you plan to use the Vividi Box on behalf of any other person, whether individual or legal entity,
                    you must obviously have legal authority to do so.
                </p>
                <h2 id="software">Software</h2>
                <h3 id="so-what-exactly-is-inside-the-vividi-box">So, what exactly is inside the Vividi Box?</h3>
                <p>
                    Inside the Vividi Box there is software (firmware) that allows the Vividi Box to function properly.
                    The software is embedded in the device and is not meant for use with any other device or for
                    separate use.
                </p>
                <p>
                    If we mention software again, we mean the embedded software (firmware) that comes with any Vividi
                    device, including any and all future upgrades, updates, patches, hotfixes and additional versions of
                    that software that replace or supplement the original software distributed by us (the “Software”).
                </p>
                <h2 id="license">License</h2>
                <h3 id="wait-so-can-i-use-the-software-or-not">Wait, so can I use the Software or not?</h3>
                <p>Keep reading; we were just about to grant you the license to its use.</p>
                <h3 id="okay-if-you-say-so.">Okay, if you say so.</h3>
                <p>
                    So, let’s get into it. We hereby grant you a limited, personal, nonexclusive, non-transferable,
                    non-assignable license, without rights to sublicense, to use the Software (in object code form only)
                    solely for internal purposes, only on the purchased device, and in such configurations as designed
                    for or expressly permitted by us.
                </p>
                <p>
                    The license is conditional upon our receiving your timely payment of any purchase price for the
                    Vividi Box in which the Software is embedded.
                </p>
                <p>
                    You may not copy the Software. You may not modify or remove any titles, trademarks or trade names,
                    copyright notices, legends, or other proprietary notices or markings on or in the Software.
                </p>
                <h3 id="so-i-can-use-it-and-you-still-own-it">So, I can use it, and you still own it?</h3>
                <p>
                    Exactly: The Software is licensed to you, not sold. Except for the license expressly granted in this
                    Agreement, we retain the right, title, and interest in and to the Software and in all related
                    intellectual and industrial property and proprietary rights.
                </p>
                <h2 id="limitations">Limitations</h2>
                <h3 id="are-there-any-specific-limitations">Are there any specific limitations?</h3>
                <p>
                    Any use of the Software and any related rights other than what are expressly set forth herein are
                    strictly prohibited. We retain all rights not expressly granted herein.
                </p>
                <p>
                    Except as otherwise provided herein or expressly agreed to by us, you may not, nor will you allow a
                    third party to:
                </p>
                <ul>
                    <li>
                        sell, lease, license, sublicense, assign, distribute, or otherwise transfer or encumber by any
                        means in whole or in part the Software;
                    </li>
                    <li>
                        provide, make available to, or permit use of the Software in whole or in part by any third party
                        without our prior written consent, unless such use by the third party is solely on your behalf,
                        is strictly in compliance with this Agreement, and you are liable for any breach of this
                        Agreement by such third party;
                    </li>
                    <li>copy, reproduce, republish, upload, post or transmit the Software in any way;</li>
                    <li>
                        decompile, disassemble, reverse engineer, or otherwise attempt to derive source code (or
                        underlying ideas, algorithms, structure or organization) from the Software, in whole or in part;
                    </li>
                    <li>
                        attack or attempt to undermine the security, integrity, authentication or intended operation of
                        the Software;
                    </li>
                    <li>modify or create derivative works based upon the Software; or</li>
                    <li>
                        use the Software to create a competitive offering or to create other software, products or
                        technologies.
                    </li>
                </ul>
                <p>What if I decide to sell the Vividi Box to someone else — can they use the Software?</p>
                <p>
                    Of course, you have the limited right to transfer the Software on a permanent basis as part of the
                    sale or transfer of the Vividi Box on which the Software is embedded, provided that: (i) you retain
                    no copies of any version of the Software and (ii) the transfer includes the most recent update and
                    all prior versions of the Software.
                </p>
                <p>
                    The terms of this Agreement apply to any transferee (you provide them with a copy of this
                    Agreement).
                </p>
                <h3 id="do-you-provide-any-support-under-this-agreement">
                    Do you provide any support under this Agreement?
                </h3>
                <p>We do not provide any maintenance or support services under this Agreement.</p>
                <h2 id="termination">Termination</h2>
                <h3 id="can-this-agreement-terminate-early">Can this Agreement terminate early?</h3>
                <p>
                    We may terminate this Agreement immediately and without prior notice if you fail to comply with any
                    term or condition of this Agreement or if we do not receive timely payment for the Vividi Box in
                    which Software is embedded.
                </p>
                <p>You may terminate this Agreement at any time by a written notice to us.</p>
                <p>
                    In the event of termination of this Agreement, all licenses granted hereunder shall automatically
                    terminate and you must immediately cease use of the Software.
                </p>
                <p>
                    We will not have any obligation upon the termination of this Agreement to refund any portion of any
                    purchase price paid for the Vividi Box.
                </p>
                <h2 id="liability-and-warranty">Liability and Warranty</h2>
                <h3 id="lets-hear-the-legal-disclaimers.">Let's hear the legal disclaimers.</h3>
                <p>
                    The Software may be subject to the export laws of the Czech Republic as well as to the laws of the
                    country where it is delivered or used. You agree to abide by these laws.
                </p>
                <p>
                    You understand that certain functionality of the Software, such as encryption, may be subject to
                    import or export restrictions in the event that you transfer the Software from the country of
                    delivery, and you are responsible for complying with applicable restrictions.
                </p>
                <p>
                    We provide the Software “as is” and we make, and you receive, no other warranties related to the
                    Software, whether express, implied or statutory, and we specifically disclaim any implied warranties
                    of merchantability, fitness for a particular purpose, and non infringement.
                </p>
                <p>
                    We do not warrant that the functions of the Software will meet your requirements or that operation
                    of the Software will be uninterrupted or error free.
                </p>
                <p>
                    We shall not under any circumstances be held liable to you for any direct, indirect, special,
                    consequential, punitive, or any other damages and costs, including but not limited to loss of
                    profit, loss of revenue, loss of business opportunity arising out of or in connection with this
                    Agreement under any theory of liability, even if advised or aware of the possibility of such
                    damages.
                </p>
                <p>
                    Under certain laws it may not be possible to disclaim our liability and warranties completely. In
                    such cases, we hereby disclaim our liability and warranties to the fullest extent permissible by the
                    applicable law.
                </p>
                <p>
                    If the warranties and liabilities disclaimers above are found void or invalid or otherwise
                    unenforceable, you agree that our total liability is limited to the amount received by us for the
                    specific Vividi Box in which the Software was embedded.
                </p>
                <h2 id="governing-law-and-choice-of-court">Governing Law and Choice of Court</h2>
                <h3 id="what-laws-do-apply">What laws do apply?</h3>
                <p>
                    The laws of the Czech Republic, without regard to principles of conflict of laws, will control this
                    Agreement and any dispute of any sort that might arise between you and us in connection herewith
                    (including any claims under any liability theory).
                </p>
                <p>The United Nations Convention on Contracts for the International Sale of Goods does not apply.</p>
                <h3 id="what-if-we-argue">What if we argue?</h3>
                <p>
                    We always operate in a customer-friendly manner and attempt to solve any issues peacefully, and we
                    ask you to do the same. Therefore, you agree to notify us of any disputes arising out of or in
                    connection with this Agreement and you agree that you will always attempt an out-of-court dispute
                    resolution before you bring the dispute before the courts.
                </p>
                <p>
                    However, sometimes out-of-court dispute resolution is just not possible. In that event, any dispute
                    relating in any way to this Agreement shall be decided with final effect before the courts of the
                    Czech Republic, in particular before the District Court for Prague 8 and (in the event that the
                    regional court decides as a first instance court) before the Municipal Court in Prague.
                </p>
                <p>
                    Should you violate or threaten to violate our intellectual property rights in any manner, we may
                    seek injunctive or other relief as may be appropriate in order to preserve all of our rights in any
                    court of our choice. You consent to exclusive jurisdiction and venue of such courts.
                </p>
                <h2 id="miscellaneous">Miscellaneous</h2>
                <h3 id="some-necessary-boilerplates">Some necessary boilerplates:</h3>
                <p>
                    If any clause of this Agreement is determined to be illegal, invalid or unenforceable, in whole or
                    in part, under any law, the legality, validity and enforceability of the other clauses hereof shall
                    not be affected. In the event that any clause or part thereof is determined to be illegal, invalid
                    or unenforceable, that clause shall be replaced by the parties with a legal, valid and enforceable
                    clause that has, to the greatest extent possible, a similar effect to the illegal, invalid or
                    unenforceable clause, given the content and purpose of this Agreement.
                </p>
                <p>
                    You may not assign or transfer any of your rights or obligations assumed under this Agreement or in
                    any other way related hereto without our prior written consent.
                </p>
                <p>
                    If you do not comply with this Agreement and we do not take action right away, or if we do not
                    enforce any other provision hereof, it doesn’t mean that we are giving up any rights that we may
                    have (such as taking action in the future).
                </p>
                <p>
                    All the provisions of this Agreement that by their nature extend beyond the termination of the
                    mutual legal relationship, including but not limited to dispute resolution and applicable law
                    clauses, shall survive the termination of the mutual legal relationship.
                </p>
                <h3 id="i-found-out-there-is-a-conflict-between-this-document-and-the-terms-i-found-elsewhere.">
                    I found out there is a conflict between this document and the terms I found elsewhere.
                </h3>
                <p>
                    If there is such a conflict, please let us know immediately so we can attend to it and improve our
                    documentation. Meanwhile, this Agreement will control for that conflict.
                </p>
                <p>
                    In Prague on November 26<sup>th</sup>, 2019
                    <br />
                    Iterait a.s.
                </p>
            </Trans>
        </>
    )
}

export default VividiBoxEULA
