import { sortBy, uniq, uniqueId } from 'lodash'
import { DateTime, Interval } from 'luxon'
import React, { useMemo } from 'react'
import { Dropdown } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import { gdynusaReadableInterval } from '@helpers/datetimeUtils'
import { formatInterval, Precision } from '@helpers/intervals'
import { useLocalizeDateTime } from '@helpers/timezoneConfig'

import { parseDate } from '@components/plots/common'

import styles from './FloorplanHeatmapIntervalsDropdown.module.scss'

const FloorplanHeatmapIntervalsDropdown = ({
    onSelect,
    availableIntervals,
    selectedInterval,
    isLastWeek,
}: {
    selectedInterval: Interval
    onSelect: (interval: Interval) => void
    availableIntervals: Array<Array<string>>
    isLastWeek: boolean
}) => {
    const localize = useLocalizeDateTime()
    const { t } = useTranslation()

    const id = useMemo(uniqueId, [])

    const formattedIntervals = Object.fromEntries(
        sortBy(
            uniq(
                availableIntervals.map(([start, end]) => {
                    const interval = gdynusaReadableInterval(
                        Interval.fromDateTimes(parseDate(start), parseDate(end)).mapEndpoints(localize)
                    )

                    return [formatInterval(interval, Precision.DAY, DateTime.DATE_SHORT), interval]
                })
            ),
            ([, interval]) => -interval.start.toMillis()
        )
    )

    const localizedSelectedInterval = selectedInterval.mapEndpoints(localize)

    return (
        <Dropdown
            onSelect={(eventKey) => {
                if (eventKey === null) {
                    return
                }

                onSelect(formattedIntervals[eventKey])
            }}
        >
            <Dropdown.Toggle className={styles.picker} id={id} variant="secondary">
                <span>
                    {isLastWeek
                        ? t('others.lastWeek', 'Last Week')
                        : formatInterval(
                              gdynusaReadableInterval(localizedSelectedInterval),
                              Precision.DAY,
                              DateTime.DATE_SHORT
                          )}
                </span>
            </Dropdown.Toggle>
            <Dropdown.Menu>
                {Object.keys(formattedIntervals).map((name) => (
                    <Dropdown.Item
                        key={`interval-${name}`}
                        active={formattedIntervals[name].equals(gdynusaReadableInterval(localizedSelectedInterval))}
                        eventKey={name}
                    >
                        {name}
                    </Dropdown.Item>
                ))}
            </Dropdown.Menu>
        </Dropdown>
    )
}

export default FloorplanHeatmapIntervalsDropdown
