import { Dictionary } from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'

import { SceneResponse } from '@api'

import { sceneApi } from '@services'

import FloorplanMappingEditor from '@components/DeviceSceneConfiguration/FloorplanMappingEditor'
import useSvgEditorData from '@components/DeviceSceneConfiguration/useSvgEditorData'
import ErrorView from '@components/ErrorView'
import LoadingSpinner from '@components/LoadingSpinner'

import { FloorplanMappingPolygon } from './ScenesTypes'

interface Props {
    width: number
    scene: SceneResponse
    mappingPolygons: FloorplanMappingPolygon[]
    scenesInLocality: Dictionary<SceneResponse>
    handleSetFloorplanMapping: (floorplanMapping: FloorplanMappingPolygon[]) => void
}

const ScenePolygonConfiguration: React.FC<Props> = ({
    width,
    scene,
    mappingPolygons,
    scenesInLocality,
    handleSetFloorplanMapping,
}) => {
    const { t } = useTranslation()

    const backgroundCall = useQuery(
        sceneApi.getTrajectoryHeatmap.query({
            sceneId: scene.id,
        })
    )

    const { imageDimensions, editorDimensions, imageUrl } = useSvgEditorData({
        backgroundCall,
        width,
        disableContentScaling: false,
    })

    if (backgroundCall.status === 'error') {
        return <ErrorView message={t('others.deviceOffline', 'The device is probably offline')} />
    }

    if (backgroundCall.status === 'idle' || backgroundCall.status === 'loading' || imageDimensions === undefined) {
        return <LoadingSpinner />
    }

    if (editorDimensions.height === undefined) {
        return <LoadingSpinner />
    }

    return (
        <div style={{ width }}>
            {mappingPolygons !== null ? (
                <FloorplanMappingEditor
                    key={`scene-polygon-${scene.id}`}
                    editorDimensions={editorDimensions}
                    imageSize={imageDimensions}
                    imageUrl={imageUrl!}
                    isDeviceMappingPolygon={true}
                    mappingPolygons={mappingPolygons}
                    scenesInLocality={scenesInLocality}
                    onFloorplanMapChange={handleSetFloorplanMapping}
                />
            ) : null}
        </div>
    )
}

export default ScenePolygonConfiguration
