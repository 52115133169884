/* tslint:disable */
/* eslint-disable */
/**
 * Vividi Dashboard API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Role,
    RoleFromJSON,
    RoleFromJSONTyped,
    RoleToJSON,
} from './Role';

/**
 * 
 * @export
 * @interface SetUserRoleRequestBody
 */
export interface SetUserRoleRequestBody {
    /**
     * 
     * @type {Role}
     * @memberof SetUserRoleRequestBody
     */
    role: Role;
}

export function SetUserRoleRequestBodyFromJSON(json: any): SetUserRoleRequestBody {
    return SetUserRoleRequestBodyFromJSONTyped(json, false);
}

export function SetUserRoleRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): SetUserRoleRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'role': RoleFromJSON(json['role']),
    };
}

export function SetUserRoleRequestBodyToJSON(value?: SetUserRoleRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'role': RoleToJSON(value.role),
    };
}

