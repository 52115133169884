import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import useProfile from './profile'

const useAppLanguageSwitcher = () => {
    const { i18n } = useTranslation()
    const profile = useProfile()

    // Updates language whenever user language gets updated
    useEffect(() => {
        const isLanguageValid =
            profile.data?.language !== undefined && (i18n.options.supportedLngs || []).includes(profile.data.language)

        if (isLanguageValid) {
            i18n.changeLanguage(profile.data?.language)
        }
    }, [profile.data?.language, i18n])
}

export default useAppLanguageSwitcher
