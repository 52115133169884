/* tslint:disable */
/* eslint-disable */
/**
 * Vividi Dashboard API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ReceiveDeviceTransferRequestBody
 */
export interface ReceiveDeviceTransferRequestBody {
    /**
     * 
     * @type {number}
     * @memberof ReceiveDeviceTransferRequestBody
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof ReceiveDeviceTransferRequestBody
     */
    deviceId: string;
    /**
     * 
     * @type {string}
     * @memberof ReceiveDeviceTransferRequestBody
     */
    simPhoneNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof ReceiveDeviceTransferRequestBody
     */
    vividiImageName: string;
    /**
     * 
     * @type {string}
     * @memberof ReceiveDeviceTransferRequestBody
     */
    note?: string;
    /**
     * 
     * @type {string}
     * @memberof ReceiveDeviceTransferRequestBody
     */
    color?: string;
}

export function ReceiveDeviceTransferRequestBodyFromJSON(json: any): ReceiveDeviceTransferRequestBody {
    return ReceiveDeviceTransferRequestBodyFromJSONTyped(json, false);
}

export function ReceiveDeviceTransferRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReceiveDeviceTransferRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'deviceId': json['device_id'],
        'simPhoneNumber': !exists(json, 'sim_phone_number') ? undefined : json['sim_phone_number'],
        'vividiImageName': json['vividi_image_name'],
        'note': !exists(json, 'note') ? undefined : json['note'],
        'color': !exists(json, 'color') ? undefined : json['color'],
    };
}

export function ReceiveDeviceTransferRequestBodyToJSON(value?: ReceiveDeviceTransferRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'device_id': value.deviceId,
        'sim_phone_number': value.simPhoneNumber,
        'vividi_image_name': value.vividiImageName,
        'note': value.note,
        'color': value.color,
    };
}

