import React from 'react'
import { useTranslation } from 'react-i18next'

const CookiePolicy = () => {
    const { t } = useTranslation()

    return (
        <>
            <h1 id="cookie-policy">Cookie Policy</h1>
            <p>Let's bake some cookies.</p>
            <h2 id="who-we-are-and-why-you-are-here">Who We Are and Why You Are Here</h2>
            <p>
                We are a commercial company called <strong>Iterait a.s.</strong>, ID No. 074 05 821, incorporated and
                existing under the laws of the Czech Republic. Our legal address is Hybernská 1023/5, 110 00 Prague 1,
                Czech Republic; we’re registered in the commercial register kept by the Municipal Court in Prague under
                file No. B 23719.
            </p>
            <p>
                We operate the website <a href="https://www.iterait.com/">https://www.iterait.com/</a> and{' '}
                <a href="https://www.vividi.io">https://www.vividi.io</a> (and any subdomains thereof). Since we use
                cookies on our website to analyze traffic and to personalize content and ads, we prepared this Cookie
                Policy to help you understand what cookies are and how we use them (and also to comply with applicable
                law).
            </p>
            <p>
                If you are uncomfortable with anything you read in this Cookie Policy, we must ask you not to use our
                website, to delete any cookies already stored therefrom, and/or to change the settings of your web
                browser to disable the storing of any cookies from our website.
            </p>
            <p>
                For further information on how we collect, process, store, and keep secure your personal data, please
                refer to the privacy policies available on our website.
            </p>
            <h2 id="what-are-cookies">What are cookies?</h2>
            <p>
                Cookies are small text files that are downloaded to your device by websites you visit. They allow
                websites to recognize your device and store certain information, such as your location and past actions.
            </p>
            <p>
                There are different types of cookies. Some cookies may be stored on your device till their expiration
                date, unless you delete them earlier (“persistent cookies”), and some may expire at the end of your
                browsing session, when your web browser is closed (“session cookies”). Some cookies may come directly
                from the web server of our website (“first-party cookies”), and some are stored by a different domain
                (“third-party cookies”).
            </p>
            <p>
                You can find more information about cookies at{' '}
                <a href="http://www.allaboutcookies.org">www.allaboutcookies.org</a>.
            </p>
            <h2 id="what-cookies-do-you-use">What cookies do you use?</h2>
            <p>
                The cookies we use do not grant us any access to your computer. These cookies are not viruses or spyware
                and are not built to damage your device. We use both persistent and session cookies, as well as both
                first-party and third-party cookies on our website.
            </p>
            <p>
                We have listed the cookies that we use on our website, what each cookie is used for, and how long each
                is retained below:
            </p>
            <h3 id="statistics-cookies">Statistics Cookies</h3>
            <p>(to help us understand how visitors interact with our website by collecting anonymous information)</p>
            <table>
                <thead>
                    <tr>
                        <th>{t('table.name', 'Name')}</th>
                        <th>{t('table.purpose', 'Purpose')}</th>
                        <th>{t('table.type', 'Type')}</th>
                        <th>{t('table.expiration', 'Expiration')}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <strong>_ga</strong>
                        </td>
                        <td>
                            This Google Analytics cookie is used to register a unique ID that is used to generate
                            statistical data on how the visitor uses our website.
                        </td>
                        <td>
                            First-party
                            <br />
                            Persistent
                        </td>
                        <td>700 days</td>
                    </tr>
                    <tr>
                        <td>
                            <strong>_gid</strong>
                        </td>
                        <td>
                            This Google Analytics cookie is used to register a unique ID that is used to generate
                            statistical data on how the visitor uses our website.
                        </td>
                        <td>
                            First-party
                            <br />
                            Persistent
                        </td>
                        <td>1 day</td>
                    </tr>
                    <tr>
                        <td>
                            <strong>_gat</strong>
                        </td>
                        <td>This Google Analytics cookie is used to throttle request rate.</td>
                        <td>
                            First-party
                            <br />
                            Persistent
                        </td>
                        <td>1 minute</td>
                    </tr>
                </tbody>
            </table>
            <h3 id="marketing">Marketing</h3>
            <p>(to display ads that are relevant for visitors)</p>
            <table>
                <thead>
                    <tr>
                        <th>{t('table.name', 'Name')}</th>
                        <th>{t('table.purpose', 'Purpose')}</th>
                        <th>{t('table.type', 'Type')}</th>
                        <th>{t('table.expiration', 'Expiration')}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <strong>_fbp</strong>
                        </td>
                        <td>
                            This Facebook cookie is used to provide the visitor with more personalized advertisement.
                        </td>
                        <td>
                            First-party
                            <br />
                            Persistent
                        </td>
                        <td>90 days</td>
                    </tr>
                    <tr>
                        <td>
                            <strong>fr</strong>
                        </td>
                        <td>
                            This third-party cookie (from Facebook.com) is used to provide the visitor with more
                            personalized advertisement and to measure and improve advertisements.
                        </td>
                        <td>
                            Third-party
                            <br />
                            Persistent
                        </td>
                        <td>90 days</td>
                    </tr>
                    <tr>
                        <td>
                            <strong>fcsid</strong>
                        </td>
                        <td>___</td>
                        <td>
                            First-party
                            <br />
                            Session
                        </td>
                        <td>-</td>
                    </tr>
                </tbody>
            </table>
            <h2 id="what-choices-do-i-have">What choices do I have?</h2>
            <p>
                If you do not agree with this Cookie Policy, you may at any time easily manage stored cookies in your
                web browser settings (that means you can delete them), and you can also disable or limit the storing of
                any new cookies. The exact procedure to manage the cookies depends on your web browser.
            </p>
            <p>
                You can find more information at{' '}
                <a href="https://www.aboutcookies.org/how-to-delete-cookies/">
                    https://www.aboutcookies.org/how-to-delete-cookies/
                </a>
                .
            </p>
            <p>
                You may choose to disable the storing of cookies in general or only for certain web servers. Please be
                aware that in the event you decide to disable the cookies (in general or partially) or to delete stored
                cookies that we use, you may not be able to enjoy all the features of our website and our services.
            </p>
            <h2 id="how-can-i-contact-you">How can I contact you?</h2>
            <p>
                If you have any questions about cookies and this Cookie Policy, please contact our support team at{' '}
                <a href="mailto:hello@iterait.com">
                    <span>hello@iterait.com</span>
                </a>
                .
            </p>
            <h2 id="changes-to-the-cookie-policy">Changes to the Cookie Policy</h2>
            <p>
                We reserve the right to amend and/or change this Cookie Policy from time to time. Therefore, please
                check this Cookie Policy regularly.
            </p>
            <p>
                In Prague on November 26th, 2019
                <br />
                <strong>Iterait a.s.</strong>
            </p>
        </>
    )
}

export default CookiePolicy
