import { debounce } from 'lodash'
import { useMemo, useState } from 'react'

export const useRenderTrigger = (fpsCap: number): (() => void) => {
    const [, setRenderCount] = useState(0)
    const maxWait = Math.ceil(1000 / fpsCap)

    return useMemo(
        () =>
            debounce(
                () => {
                    setRenderCount((prev) => prev + 1)
                },
                maxWait,
                { leading: true, trailing: true, maxWait }
            ),
        [maxWait]
    )
}
