import { Duration } from 'luxon'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams, Redirect } from 'react-router'

import { FloorplanCrowdSizeResponseFromJSON } from '@api'

import { generateFloorplanLocalityOccupancyDashboardPath } from '@helpers/VividiURLs'

import Helmet from '@components/Helmet'
import LanguageOverride from '@components/LanguageOverride'
import LocalityOccupancyConsumer, { OccupancyData } from '@components/LocalityOccupancyConsumer'
import StaySafeDashboardView from '@components/StaySafeDashboardView/StaySafeDashboardView'

const LocalityOccupancyDashboard: React.FC = () => {
    const { i18n } = useTranslation()
    const params = useParams<{
        id: string
        lang?: string
    }>()
    const localityId = parseInt(params.id, 10)

    const isLanguageValid = params.lang !== undefined && (i18n.options.supportedLngs || []).includes(params.lang)

    // Reload the page every 12 hours
    useEffect(() => {
        const timeout = setTimeout(() => {
            window.location.reload()
        }, 12 * 3600 * 1000)

        return () => clearTimeout(timeout)
    }, [])

    if (!isLanguageValid) {
        return <Redirect to={generateFloorplanLocalityOccupancyDashboardPath(localityId, params.lang ?? 'en')} />
    }

    const documentTitle = i18n.t('title.staySafeFloorplanScreen', 'Stay Safe Floorplan')

    return (
        <>
            <Helmet>
                <title>{documentTitle}</title>
            </Helmet>
            <LanguageOverride>
                <LocalityOccupancyConsumer
                    connectionCheckInterval={Duration.fromObject({ seconds: 30 })}
                    dataRenderer={({ data }: { data: OccupancyData }) => (
                        <StaySafeDashboardView data={data} showCapacity={true} showExactNumbers={true} />
                    )}
                    endpointName="floorplan_crowd_size"
                    localityId={localityId}
                    parseFunction={FloorplanCrowdSizeResponseFromJSON}
                    subscriptionType="stay_safe_floorplan"
                    updateInterval={3}
                />
            </LanguageOverride>
        </>
    )
}

export default LocalityOccupancyDashboard
