import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import { useHistory } from 'react-router'

import { OrganizationFeatures, OrganizationModel } from '@api/models'

import { organizationApi } from '@services'

import { generateEditOrganizationPath, OrganizationEditPageTabs } from '@helpers/VividiURLs'
import { useApiCallCleaner } from '@helpers/api'

import PageContentLayout from '@components/Layouts/PageContentLayout'
import OrganizationEditForm from '@components/OrganizationEditForms/OrganizationEditForm'
import { useNotify } from '@components/notifications/NotificationsContext'

const CreateOrganizationPage = () => {
    const { t } = useTranslation()
    const notify = useNotify()

    const {
        mutateAsync: createOrganization,
        status: createOrganizationState,
        reset,
    } = useMutation(organizationApi.addOrganization, {
        onSuccess: () => {
            reset()
        },
    })

    const { mutateAsync: updateOrganizationFeatures } = useMutation(organizationApi.updateOrganizationFeatures)

    const history = useHistory()
    const clean = useApiCallCleaner()

    async function createOrganizationWithFeatures(organization: OrganizationModel, features?: OrganizationFeatures) {
        if (!organization.name.trim()) {
            notify({
                title: t('notification.invalidName', 'Invalid name'),
                content: t('notification.provideOrganizationName', 'Please provide valid organization name.'),
                variant: 'warning',
                timeoutSeconds: 4,
            })

            return
        }

        try {
            const organizationResponse = await createOrganization({
                body: organization,
            })

            if (features) {
                await updateOrganizationFeatures({
                    organizationId: organizationResponse.id,
                    body: features,
                })
            }
            clean(organizationApi)
            history.push(generateEditOrganizationPath(organizationResponse.id, OrganizationEditPageTabs.localities))
        } catch (e) {
            notify({
                title: t('notification.error', 'Error'),
                content: t(
                    'notification.somethingWentWrong',
                    'Something went wrong. The submission was not successful'
                ),
                variant: 'danger',
            })
        }
    }

    const heading = t('heading.createOrganization', 'Create Organization')

    return (
        <PageContentLayout documentTitle={heading} heading={heading}>
            <OrganizationEditForm
                submissionStatus={createOrganizationState}
                onSubmit={(organization, features) => createOrganizationWithFeatures(organization, features)}
            />
        </PageContentLayout>
    )
}

export default CreateOrganizationPage
