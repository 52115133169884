/* tslint:disable */
/* eslint-disable */
/**
 * Vividi Dashboard API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IntervalPropertyBreakdown,
    IntervalPropertyBreakdownFromJSON,
    IntervalPropertyBreakdownFromJSONTyped,
    IntervalPropertyBreakdownToJSON,
} from './IntervalPropertyBreakdown';

/**
 * 
 * @export
 * @interface PropertyBreakdownResponse
 */
export interface PropertyBreakdownResponse {
    /**
     * 
     * @type {Array<IntervalPropertyBreakdown>}
     * @memberof PropertyBreakdownResponse
     */
    intervals: Array<IntervalPropertyBreakdown>;
}

export function PropertyBreakdownResponseFromJSON(json: any): PropertyBreakdownResponse {
    return PropertyBreakdownResponseFromJSONTyped(json, false);
}

export function PropertyBreakdownResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): PropertyBreakdownResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'intervals': ((json['intervals'] as Array<any>).map(IntervalPropertyBreakdownFromJSON)),
    };
}

export function PropertyBreakdownResponseToJSON(value?: PropertyBreakdownResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'intervals': ((value.intervals as Array<any>).map(IntervalPropertyBreakdownToJSON)),
    };
}

