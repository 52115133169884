import { noop } from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery } from 'react-query'
import { useParams } from 'react-router'

import { LocalityResponse } from '@api'

import { localityApi, statisticsApi, profileApi } from '@services'

import { useApiCallCleaner } from '@helpers/api'
import { mergeQueryResults } from '@helpers/api'
import { maskQueryFailure } from '@helpers/apiCallManipulation'
import { useBackLink } from '@helpers/backlinks'

import ErrorView from '@components/ErrorView'
import PageContentLayout from '@components/Layouts/PageContentLayout'
import LegacyLoadingWrapper from '@components/LegacyLoadingWrapper'
import LocalityOccupancyEditForm from '@components/LocalityOccupancyEditForm'

const EditLocalityOccupancyPage: React.FC = () => {
    const { t } = useTranslation()
    const backLink = useBackLink()
    const params = useParams<{ localityId: string }>()
    const localityId = parseInt(params.localityId, 10)

    const getCrowdSizeCall = useQuery(
        statisticsApi.getLocalityCrowdSize.query({
            localityId,
        })
    )

    const localityCall = useQuery(
        localityApi.getLocality.query({
            localityId,
        })
    )

    const clean = useApiCallCleaner()
    const request = mergeQueryResults(maskQueryFailure(getCrowdSizeCall), localityCall)
    const heading = t('appDashboard.editOccupancy', 'Edit occupancy')
    const documentTitle = t('title.occupancySettings', 'Occupancy settings')

    const handleSubmit = async ({
        crowdSize,
        capacity,
        locality,
    }: {
        crowdSize: number
        capacity: number
        locality: LocalityResponse
    }) => {
        const promises: Promise<any>[] = []

        promises.push(
            localityApi.setLocalityCapacity({
                organizationId: locality.organizationId,
                localityId,
                body: { capacity },
            })
        )

        if (crowdSize !== undefined) {
            promises.push(
                localityApi.setLocalityCrowdSize({
                    organizationId: locality.organizationId,
                    localityId,
                    body: {
                        crowdSize,
                    },
                })
            )
        }

        await Promise.all(promises)
    }

    const {
        mutate: onSubmit,
        status: submissionState,
        reset,
    } = useMutation(handleSubmit, {
        onSuccess: () => {
            clean(localityApi)
            clean(profileApi)
            clean(statisticsApi)
            reset()
            backLink.follow()
        },
        onError: noop,
    })

    return (
        <>
            <LegacyLoadingWrapper
                placeholder={
                    <PageContentLayout documentTitle={documentTitle} heading={heading} loadingState={request.status} />
                }
                request={request}
            >
                {([crowdSizeResponse, locality]) => {
                    if (locality === undefined) {
                        return (
                            <PageContentLayout documentTitle={documentTitle} heading={heading}>
                                <ErrorView message={t('others.localityNotFound', 'Locality not found')} />
                            </PageContentLayout>
                        )
                    }

                    return (
                        <PageContentLayout documentTitle={documentTitle} heading={heading}>
                            <LocalityOccupancyEditForm
                                defaultCrowdSize={crowdSizeResponse?.currentCrowdSize}
                                locality={locality}
                                submissionState={submissionState}
                                onSubmit={onSubmit}
                            />
                        </PageContentLayout>
                    )
                }}
            </LegacyLoadingWrapper>
        </>
    )
}

export default EditLocalityOccupancyPage
