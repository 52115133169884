import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import { max, noop } from 'lodash'
import { DateTime, Interval } from 'luxon'
import { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Bar, CartesianGrid, Cell, Tooltip, YAxis } from 'recharts'

import { IntervalType } from '@helpers/types'

import palette from '@components/palette.module.scss'
import Cursor from '@components/plots/Cursor'
import TimeLineChart from '@components/plots/TimeLineChart'
import TooltipContent from '@components/plots/TooltipContent'
import TooltipLabel from '@components/plots/TooltipLabel'
import TooltipTable from '@components/plots/TooltipTable'

import styles from './EmotionsDetails.module.scss'
import { determineEmotionIcon, determineEmotionIconStyle } from './EmotionsDetailsContent'
import { TimelineEmotionDataItem } from './emotionsDetailsPreprocessors'

const EmotionScoreAverageTooltipItem: React.FC<{
    label: string
    tooltipItemValue: number
}> = ({ tooltipItemValue, label }) => {
    const Icon = (
        <FontAwesomeIcon
            className={classNames(determineEmotionIconStyle(tooltipItemValue), styles.emotionIconSmall)}
            icon={determineEmotionIcon(tooltipItemValue)}
        />
    )

    return (
        <TooltipTable.Item label={label}>
            <>
                {Icon}
                <span className={styles.emotionScoreTitle}>
                    <strong>{tooltipItemValue}</strong>
                </span>
                <span className={styles.emotionMaxScore}>/ 10</span>
            </>
        </TooltipTable.Item>
    )
}

const EmotionsScoreAverageInTimePlot: React.FC<{
    emotionData: Array<TimelineEmotionDataItem>
    now: DateTime
    intervalType: IntervalType
    interval: Interval
}> = ({ emotionData, now, intervalType, interval }) => {
    const { t } = useTranslation()

    const canvasRef = useRef<HTMLCanvasElement>(null)

    const maxData = max(emotionData.flatMap((it) => [it.averageEmotionScore])) ?? 0

    const canvasContext = canvasRef.current?.getContext('2d')
    const axisWidth = canvasContext?.measureText((-maxData).toString()).width

    const determinedIntervalType = interval.length('hours') <= 22 ? IntervalType.WEEK : IntervalType.MONTH

    return (
        <>
            <TimeLineChart
                canvasRef={canvasRef}
                className={styles.emotionsDistributionPlot}
                data={emotionData}
                determinedIntervalType={determinedIntervalType}
                endDate={interval.end}
                intervalType={intervalType}
                now={now}
                outages={[]}
                startDate={interval.end}
                onSelectInterval={noop}
            >
                <YAxis
                    allowDecimals={false}
                    domain={[1, 10]}
                    tick={(tickProps) => {
                        return (
                            <g transform={`translate(${tickProps.x},${tickProps.y})`}>
                                <text className="small" fill={palette.darkgrey} textAnchor="end" x={-4} y={4}>
                                    {tickProps.payload.value}
                                </text>
                            </g>
                        )
                    }}
                    ticks={[1, 4, 7, 10]}
                    width={Math.ceil(axisWidth ?? 30) + 6}
                />
                <Bar
                    barSize={intervalType === IntervalType.WEEK ? 50 : undefined}
                    dataKey="averageEmotionScore"
                    strokeWidth={2}
                >
                    {emotionData.map((entry, index) => {
                        const emotionEntry = entry.averageEmotionScore ?? 0

                        return (
                            <Cell
                                key={`emotion-bar-${index}`}
                                fill={
                                    emotionEntry < 4
                                        ? palette.emotionsPlotNegative
                                        : emotionEntry > 7
                                        ? palette.emotionsPlotPositive
                                        : palette.emotionsPlotNeutral
                                }
                            />
                        )
                    })}
                </Bar>

                <CartesianGrid strokeOpacity={0.3} strokeWidth={1} vertical={false} />
                <Tooltip
                    content={
                        <TooltipContent
                            intervalResolver={(label) => emotionData[Number(label)].interval!}
                            labelFormatter={(label) => label}
                            now={now}
                            outages={[]}
                        >
                            {(label) => {
                                const record = emotionData.find((it) => it.name === label)!

                                return (
                                    <TooltipTable>
                                        <EmotionScoreAverageTooltipItem
                                            label={t('emotions.avgEmotionScore', 'Avg. emotion score')}
                                            tooltipItemValue={record.averageEmotionScore ?? 0}
                                        />
                                    </TooltipTable>
                                )
                            }}
                        </TooltipContent>
                    }
                    cursor={<Cursor dataLength={emotionData.length} />}
                    filterNull={false}
                    labelFormatter={(index) => {
                        const item = emotionData[parseInt(index as string, 10)]

                        if (item === undefined) {
                            return
                        }

                        const interval = item.interval

                        return (
                            <TooltipLabel
                                endDate={interval!.end}
                                interval={interval!}
                                now={now}
                                startDate={interval!.start}
                            />
                        )
                    }}
                />
            </TimeLineChart>
        </>
    )
}

export default EmotionsScoreAverageInTimePlot
