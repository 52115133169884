import { isEmpty, noop } from 'lodash'
import { DateTime, Interval } from 'luxon'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { useHistory, useParams } from 'react-router-dom'

import { LocalityNameModel } from '@api'

import { localityApi, statisticsApi } from '@services'

import { generateConversionDashboardCustomIntervalPath, generateConversionDashboardPath } from '@helpers/VividiURLs'
import { mergeQueryResults, visibleSceneDescriptionsQuery } from '@helpers/api'
import { alignPickedInterval, localeAwareAlign } from '@helpers/datetimeUtils'
import { useTimezoneConfig } from '@helpers/timezoneConfig'
import { IntervalType, ZoneOccupancyGranularity } from '@helpers/types'
import { assertIsIntervalType, pickColumn } from '@helpers/utils'

import ConversionDetailsContent from '@components/ConversionDetails/ConversionDetailsContent'
import { ConversionDetailsExportButton } from '@components/ConversionDetails/ConversionDetailsExportButton'
import DashboardParamsNormalizer, { NormalizedDashboardParams } from '@components/DashboardParamsNormalizer'
import DetailsLayout from '@components/DetailsLayout'
import ErrorView from '@components/ErrorView'
import Helmet from '@components/Helmet'
import LegacyLoadingWrapper from '@components/LegacyLoadingWrapper'
import LoadingSpinner from '@components/LoadingSpinner'
import { granularityToDuration } from '@components/ZoneOccupancyStatistics/zoneOccupancyData'
import { partitionInterval } from '@components/plots/common'

export interface Params {
    localityId: string
    intervalType: IntervalType
    intervalStart: string
    intervalEnd?: string
}

const ConversionDetailsPage: React.FC<NormalizedDashboardParams> = ({ locality, interval, intervalType }) => {
    const { t } = useTranslation()

    const params = useParams<Params>()
    const history = useHistory()

    const zone = useTimezoneConfig()
    const [now] = useState<DateTime>(localeAwareAlign(DateTime.utc(), IntervalType.HOUR, zone))

    const startDate = DateTime.fromMillis(Number(params.intervalStart), {
        zone: 'UTC',
    })
    const endDate = params.intervalEnd ? DateTime.fromMillis(Number(params.intervalEnd), { zone: 'UTC' }) : undefined

    assertIsIntervalType(params.intervalType)

    const processedInterval = alignPickedInterval(zone, params.intervalType, startDate, endDate)
    const intervalPartitions = partitionInterval(interval, useTimezoneConfig(), undefined)
    const hourlyIntervalPartitions = partitionInterval(
        interval,
        useTimezoneConfig(),
        granularityToDuration(ZoneOccupancyGranularity.HOUR)
    )

    const conversionDataCall = useQuery(
        statisticsApi.getConversionStatistics.query({
            body: { ...intervalPartitions, locality: locality.id },
        })
    )

    const hourlyDataCall = useQuery(
        statisticsApi.getConversionStatistics.query({
            body: { ...hourlyIntervalPartitions, locality: locality.id },
        })
    )

    const scenesCall = useQuery(
        localityApi.getLocalityScenes.query({
            localityId: locality.id,
        })
    )

    const sceneConfigurationsCall = useQuery(
        visibleSceneDescriptionsQuery(scenesCall.data ? pickColumn(scenesCall.data?.scenes, 'id') : undefined)
    )

    const outagesCall = useQuery({
        ...statisticsApi.getMultiDeviceOutages.query({
            body: {
                sceneIds: pickColumn(scenesCall.data?.scenes ?? [], 'id'),
                startingFrom: interval.start.toISO(),
                endingAt: scenesCall.data?.scenes.length === 1 ? interval.start.toISO() : interval.end.toISO(),
            },
        }),
        enabled: scenesCall.data !== undefined,
    })

    // end of hooks

    const handleIntervalStartChange = (intervalStart: DateTime) => {
        assertIsIntervalType(params.intervalType)

        history.push(generateConversionDashboardPath(locality.id, intervalStart, params.intervalType))
    }

    const handleIntervalTypeChange = (intervalType: IntervalType) => {
        history.push(generateConversionDashboardPath(locality.id, startDate, intervalType))
    }

    const handleLocalityChange = (locality: LocalityNameModel) => {
        if (processedInterval === undefined) {
            return history.push(generateConversionDashboardPath(locality.id, now, IntervalType.DAY))
        }

        const isCustomIntervalSelected =
            params.intervalType === IntervalType.CUSTOM && processedInterval.start && processedInterval.end

        if (isCustomIntervalSelected) {
            return history.push(
                generateConversionDashboardCustomIntervalPath(
                    locality.id,
                    processedInterval.start,
                    processedInterval.end
                )
            )
        }

        assertIsIntervalType(params.intervalType)

        return history.push(generateConversionDashboardPath(locality.id, processedInterval.start, params.intervalType))
    }

    const handleCustomIntervalChange = (interval: Interval) => {
        history.push(generateConversionDashboardCustomIntervalPath(locality.id, interval.start, interval.end))
    }

    const documentTitle = t('title.conversionsStatistics', 'Conversions Statistics')

    return (
        <>
            <Helmet>
                <title>{documentTitle}</title>
            </Helmet>
            <LegacyLoadingWrapper
                errorComponent={
                    <DetailsLayout
                        intervalType={intervalType}
                        organizationFilter={(org) => org.features.conversions}
                        pickedInterval={interval}
                        selectedLocality={locality}
                        onCustomIntervalChange={handleCustomIntervalChange}
                        onIntervalStartChange={handleIntervalStartChange}
                        onIntervalTypeChange={handleIntervalTypeChange}
                        onLocalityChange={handleLocalityChange}
                    >
                        <ErrorView message={t('others.problemFetchingData', 'There was a problem fetching data.')} />
                    </DetailsLayout>
                }
                placeholder={
                    <DetailsLayout
                        intervalType={intervalType}
                        organizationFilter={(org) => org.features.conversions}
                        pickedInterval={interval}
                        selectedLocality={locality}
                        onCustomIntervalChange={handleCustomIntervalChange}
                        onIntervalStartChange={handleIntervalStartChange}
                        onIntervalTypeChange={handleIntervalTypeChange}
                        onLocalityChange={handleLocalityChange}
                    >
                        <LoadingSpinner />
                    </DetailsLayout>
                }
                request={mergeQueryResults(
                    scenesCall,
                    sceneConfigurationsCall,
                    conversionDataCall,
                    hourlyDataCall,
                    outagesCall
                )}
            >
                {([scenes, sceneConfigurations, conversionData, hourlyData, outages]) => (
                    <>
                        <Helmet>
                            <title>{`${locality.name} ${documentTitle}`}</title>
                        </Helmet>
                        <DetailsLayout
                            key={locality.id}
                            intervalType={intervalType}
                            layoutActionButton={
                                !isEmpty(conversionDataCall) && !isEmpty(hourlyDataCall) ? (
                                    <ConversionDetailsExportButton
                                        conversionStatistics={conversionData}
                                        hourlyConversions={hourlyData}
                                        now={now}
                                        outages={outages}
                                        pickedInterval={interval}
                                        selectedLocality={locality}
                                    />
                                ) : undefined
                            }
                            organizationFilter={(org) => org.features.conversions}
                            pickedInterval={interval}
                            selectedLocality={locality}
                            onCustomIntervalChange={handleCustomIntervalChange}
                            onIntervalStartChange={handleIntervalStartChange}
                            onIntervalTypeChange={handleIntervalTypeChange}
                            onLocalityChange={handleLocalityChange}
                        >
                            <ConversionDetailsContent
                                conversionStatistics={conversionData}
                                hourlyConversions={hourlyData}
                                interval={interval}
                                intervalType={intervalType}
                                locality={locality}
                                now={now}
                                outages={outages}
                                sceneConfiguration={sceneConfigurations}
                                scenes={scenes}
                            />
                        </DetailsLayout>
                    </>
                )}
            </LegacyLoadingWrapper>
        </>
    )
}

const ConversionsDetailsParamsNormalizer: React.FC = () => (
    <DashboardParamsNormalizer
        customIntervalPathGenerator={generateConversionDashboardCustomIntervalPath}
        defaultLocalityPathGenerator={generateConversionDashboardPath}
        errorComponent={({ intervalType, interval }) => (
            <DetailsLayout
                intervalType={intervalType}
                organizationFilter={(org) => org.features.conversions}
                pickedInterval={interval}
                onCustomIntervalChange={noop}
                onIntervalStartChange={noop}
                onIntervalTypeChange={noop}
                onLocalityChange={noop}
            >
                <ErrorView message="Fetching the list of localities failed" title="Error while fetching data" />
            </DetailsLayout>
        )}
        intervalPathGenerator={generateConversionDashboardPath}
        loadingComponent={({ intervalType, interval }) => (
            <DetailsLayout
                intervalType={intervalType}
                organizationFilter={(org) => org.features.conversions}
                pickedInterval={interval}
                onCustomIntervalChange={noop}
                onIntervalStartChange={noop}
                onIntervalTypeChange={noop}
                onLocalityChange={noop}
            >
                <LoadingSpinner />
            </DetailsLayout>
        )}
        successComponent={(params) => <ConversionDetailsPage {...params} />}
    />
)

export default ConversionsDetailsParamsNormalizer
