/* tslint:disable */
/* eslint-disable */
/**
 * Vividi Dashboard API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * An enumeration.
 * @export
 * @enum {string}
 */
export enum FloorplanHeatmapType {
    Trajectories = 'trajectories',
    UtilityGrid = 'utility_grid',
    UtilityGridData = 'utility_grid_data',
    DwellTime = 'dwell_time',
    DwellTimeData = 'dwell_time_data'
}

export function FloorplanHeatmapTypeFromJSON(json: any): FloorplanHeatmapType {
    return FloorplanHeatmapTypeFromJSONTyped(json, false);
}

export function FloorplanHeatmapTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): FloorplanHeatmapType {
    return json as FloorplanHeatmapType;
}

export function FloorplanHeatmapTypeToJSON(value?: FloorplanHeatmapType | null): any {
    return value as any;
}

