import { uniqueId } from 'lodash'
import React, { useMemo } from 'react'
import { Modal, ModalProps } from 'react-bootstrap'

type Props = {
    control: React.ReactNode
    className?: string
    modalContainerClassName?: string
    header?: React.ReactNode
} & ModalProps

const ControlledModal: React.FC<Props> = ({
    show,
    onHide,
    children,
    control,
    className,
    centered = false,
    animation = false,
    modalContainerClassName,
    header,
}) => {
    const id = useMemo(uniqueId, [])

    return (
        <div className={modalContainerClassName}>
            <Modal
                animation={animation}
                centered={centered}
                className={className}
                id={id}
                show={show}
                onHide={() => onHide()}
            >
                {header && <Modal.Header>{header}</Modal.Header>}
                <Modal.Body>{children}</Modal.Body>
            </Modal>
            {control}
        </div>
    )
}

export default ControlledModal
