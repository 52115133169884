import { faStreetView } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import { sortBy, pick, isEmpty } from 'lodash'
import React, { useMemo, useState } from 'react'
import { Dropdown, DropdownButtonProps } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import { LocalityNameModel, OrganizationResponse, SceneResponse } from '@api/models'

import { chunkBy } from '@helpers/utils'

import TextInput from './GenericInputs/TextInput'
import styles from './StatisticsSummary/FilterButton.module.scss'

interface Props {
    scenes: Array<SceneResponse>
    localities: { [localityId: number]: LocalityNameModel }
    organizations: { [organizationId: number]: OrganizationResponse }
    onSelect: (scenes: SceneResponse[]) => void
    selectedScene?: SceneResponse
    className?: string
    variant?: DropdownButtonProps['variant']
    block?: boolean
}

const ScenesPicker: React.FC<Props> = ({
    scenes,
    organizations,
    localities,
    selectedScene,
    className,
    onSelect,
    variant = 'secondary',
    block = true,
}) => {
    const { t } = useTranslation()

    const [search, setSearch] = useState('')

    const filteredDevices = useMemo(() => {
        return scenes.filter(
            (device) =>
                device.id.toString().startsWith(search) || device.label.toLowerCase().includes(search.toLowerCase())
        )
    }, [search, scenes])

    const entries = chunkBy(
        sortBy(
            sortBy(
                filteredDevices.flatMap((d) => {
                    const deviceLocalities = Object.values(pick(localities ?? {}, d.localityIds)).filter(
                        (it) => it !== undefined
                    )

                    if (isEmpty(deviceLocalities)) {
                        return [
                            [
                                d,
                                d.organizationId !== undefined ? organizations?.[d.organizationId] : undefined,
                                undefined,
                            ] as [SceneResponse, OrganizationResponse, LocalityNameModel?],
                        ]
                    }

                    return deviceLocalities.map(
                        (locality) =>
                            [
                                d,
                                d.organizationId !== undefined ? organizations?.[d.organizationId] : undefined,
                                locality,
                            ] as [SceneResponse, OrganizationResponse, LocalityNameModel?]
                    )
                }),
                ([_, o]) => o?.name ?? '',
                ([_, _o, l]) => l?.name ?? '',
                ([s]) => s.label
            ),
            ([_, o]) => (o === undefined ? 1 : 0)
        ),
        ([_, o, l]) => [o, l]
    )

    const sectionLabel = (organization: OrganizationResponse, locality?: LocalityNameModel) => {
        if (organization === undefined) {
            return t('button.noOrganization', 'No organization')
        }

        if (locality === undefined) {
            return t('button.noOrganizationLocality', '({{organization}} No locality)', {
                organization: organization.name,
            })
        }

        return `${organization.name} :: ${locality.name}`
    }

    const title = (() => {
        if (scenes.length === 0) {
            return t('others.nothingToView', 'Nothing to view')
        }

        if (selectedScene === undefined) {
            return t('others.noDevicesSelected', 'No devices selected')
        }

        return `${selectedScene.label} (#${selectedScene.id})`
    })()

    return (
        <Dropdown
            className={className}
            onSelect={(eventKey) => {
                if (eventKey === null) {
                    return
                }

                onSelect(scenes.filter((d) => eventKey.split('-').includes(d.id.toString())))
            }}
        >
            <Dropdown.Toggle
                className={classNames({ 'btn-block': block })}
                disabled={scenes.length === 0}
                variant={variant}
            >
                <FontAwesomeIcon icon={faStreetView} />
                {title}
            </Dropdown.Toggle>
            <Dropdown.Menu>
                <TextInput
                    autofocus={true}
                    className={styles.searchInput}
                    placeholder={t('button.searchDevice', 'Search device')}
                    value={search}
                    onChange={setSearch}
                />
                {entries.flatMap((chunk, index) => {
                    const [, organization, locality] = chunk[0]
                    const label = sectionLabel(organization, locality)
                    const options = chunk.map(([device]) => (
                        <Dropdown.Item key={`${device.id.toString()}-${index}`} eventKey={device.id.toString()}>
                            {device.label} (#{device.id})
                        </Dropdown.Item>
                    ))

                    if (label === undefined) {
                        return null
                    }

                    const header = <Dropdown.Header key={label + index}>{label}</Dropdown.Header>

                    if (locality !== undefined) {
                        const localityDevices = filteredDevices.filter((d) => {
                            return d.localityIds.includes(locality!.id)
                        })
                        const ids = localityDevices
                            .map((d) => {
                                return d.id.toString()
                            })
                            .join('-')
                        const all =
                            options.length > 1
                                ? [
                                      <Dropdown.Item key={ids + index} eventKey={ids}>
                                          {label}: {t('button.allDevices', 'all devices')}
                                      </Dropdown.Item>,
                                  ]
                                : []

                        return [header, ...all, ...options]
                    }

                    return [header, ...options]
                })}
            </Dropdown.Menu>
        </Dropdown>
    )
}

export default ScenesPicker
