import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import humanizeDuration from 'humanize-duration'
import { Button, Table } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import { LocalityNameModel, MQTTPublisherPreferencesItem, MQTTPublisherType } from '@api'

import styles from '@components/StatisticsSummary/LocalityTable/LocalityTable.module.scss'
import TooltipIcon from '@components/TooltipIcon'

interface Props {
    localities: LocalityNameModel[]
    publisherData: MQTTPublisherPreferencesItem[]
    onEdit: (data: MQTTPublisherPreferencesItem, index: number) => void
    onDelete: (index: number) => void
}

const DataPublishersTable = ({ publisherData, localities, onEdit, onDelete }: Props) => {
    const { t } = useTranslation()

    const headers = [
        {
            name: t('table.locality', 'Locality'),
            key: 0,
            headTitleDescription: null,
        },
        { name: t('table.type', 'Type'), key: 1, headTitleDescription: null },
        {
            name: t('form.pushInterval', 'Push interval'),
            key: 2,
            headTitleDescription: null,
        },
        { name: t('form.topic', 'Topic'), key: 3, headTitleDescription: null },
        {
            name: t('form.hostPort', 'Host and port'),
            key: 4,
            headTitleDescription: null,
        },
        {
            name: t('table.actions', 'Actions'),
            key: 5,
            headTitleDescription: null,
        },
    ]

    const spanClassName = publisherData.length > 1 ? styles.dataValueAligned : styles.dataValue

    return (
        <Table className={styles.localityTable} responsive>
            <thead>
                <tr>
                    {headers.map((header) => (
                        <th key={header.key}>
                            {header.name}
                            {header.headTitleDescription && <TooltipIcon>{header.headTitleDescription}</TooltipIcon>}
                        </th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {publisherData.map((chunk, i) => (
                    // eslint-disable-next-line
                    <tr key={i}>
                        <td>
                            <span className={styles.name}>
                                {localities?.find((l: { id: number }) => l.id === chunk.localityId)?.name ?? ''}
                            </span>
                        </td>
                        <td>
                            <div className="d-flex">
                                <span className={spanClassName}>
                                    {chunk.type === MQTTPublisherType.CrowdSize ? 'Stay-safe' : 'Stay-safe Floorplan'}
                                </span>
                            </div>
                        </td>
                        <td>
                            <div className="d-flex">
                                <span className={spanClassName}>{humanizeDuration(1000 * chunk.interval)}</span>
                            </div>
                        </td>
                        <td>
                            <div className="d-flex">
                                <span className={spanClassName}>{chunk.topic}</span>
                            </div>
                        </td>
                        <td>
                            <div className="d-flex">
                                <span className={spanClassName}>{`${chunk.host}:${chunk.port}`}</span>
                            </div>
                        </td>
                        <td>
                            <div className="d-flex">
                                <Button
                                    variant="link"
                                    onClick={() => {
                                        onEdit(chunk, i)
                                    }}
                                >
                                    <FontAwesomeIcon icon={faEdit} />
                                    {t('button.edit', 'Edit')}
                                </Button>
                                <Button
                                    className="ml-3"
                                    variant="link danger"
                                    onClick={() => {
                                        onDelete(i)
                                    }}
                                >
                                    <FontAwesomeIcon icon={faTrash} />
                                    {t('button.delete', 'Delete')}
                                </Button>
                            </div>
                        </td>
                    </tr>
                ))}
            </tbody>
        </Table>
    )
}

export default DataPublishersTable
