import { faUserFriends } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import { max, partition, flatten, sum, fromPairs, round, isEmpty } from 'lodash'
import { Interval, IANAZone, DateTime } from 'luxon'
import { Row, Image } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'

import { LocalityConfigurationStateModel, OrganizationResponse } from '@api'

import { generateOrganizationsEditLocalitiesPath, generateQueueDetailsCustomIntervalPath } from '@helpers/VividiURLs'
import { mergeQueryResults, visibleQueueStatisticsByLocalityQuery } from '@helpers/api'
import { useAddBackLink } from '@helpers/backlinks'
import { gdynusaReadableInterval } from '@helpers/datetimeUtils'
import { displayPreciseDuration } from '@helpers/displayUtils'
import { GoogleEventName } from '@helpers/gtag'
import { formatIntervalToFilename } from '@helpers/intervals'
import { orderLocalities } from '@helpers/orderFunctions'
import { useTimezoneConfig, migrateTimestamp, useLocalizeDateTime } from '@helpers/timezoneConfig'
import { SummaryRequest } from '@helpers/types'
import { exportToExcel } from '@helpers/xlsxExporter'

import Box from '@elements/Box/Box'
import TableWithSorting, { CellContent } from '@elements/Table/TableWithSorting'

import AdminOnlyLinkRenderer from '@components/AdminOnlyLinkRenderer'
import LegacyLoadingWrapper from '@components/LegacyLoadingWrapper'
import LoadingSpinner from '@components/LoadingSpinner'
import ChangeIndicator from '@components/StatisticsSummary/LocalityTable/ChangeIndicator'
import { TrendType } from '@components/StatisticsSummary/LocalityTable/ChangeIndicator'
import NotConfiguredLocalitiesList from '@components/StatisticsSummary/NotConfiguredLocalitiesList'
import SectionSubheading from '@components/StatisticsSummary/SectionSubheading'
import StatisticBox, { StatisticBoxItem } from '@components/StatisticsSummary/StatisticBox'
import styles from '@components/StatisticsSummary/StatisticBox.module.scss'
import { partitionByInterval } from '@components/StatisticsSummary/utils'
import { useNotify } from '@components/notifications/NotificationsContext'
import { parseDate } from '@components/plots/common'
import DownloadButtonWrapper from '@components/plots/downloadButtons/DownloadButtonWrapper'

import queueFlow from '@images/highlights/queue-flow.png'

import DetailLink from './DetailLink'
import { QueuesStatistics } from './SectionsTypes'

type Props = {
    localities: LocalityConfigurationStateModel[]
    selectedLocalities: LocalityConfigurationStateModel[]
    interval: Interval
    comparisonInterval?: Interval
    selectedOrganization: OrganizationResponse
    partitionedInterval: SummaryRequest
    shouldDisplayLocalitySummary: boolean
}

const QueueSection = ({
    localities,
    selectedLocalities,
    interval,
    comparisonInterval,
    selectedOrganization,
    partitionedInterval,
    shouldDisplayLocalitySummary,
}: Props) => {
    const { t } = useTranslation()

    const notify = useNotify()

    const [configuredLocalities, notConfiguredLocalities] = partition(
        orderLocalities(selectedLocalities),
        (l) => l.isQueuesConfigured
    )

    const currentInterval = {
        startingFrom: interval.start.toISO(),
        endingAt: interval.end.toISO(),
    }

    const queueStatisticsByLocalityCall = useQuery({
        ...visibleQueueStatisticsByLocalityQuery({
            intervals:
                comparisonInterval !== undefined
                    ? [
                          currentInterval,
                          {
                              startingFrom: comparisonInterval.start.toISO(),
                              endingAt: comparisonInterval.end.toISO(),
                          },
                      ]
                    : [currentInterval],
            localityIds: localities.map(({ id }) => id),
        }),
    })

    const partitionedQueueStatisticsByLocalityCall = useQuery({
        ...visibleQueueStatisticsByLocalityQuery({
            intervals: partitionedInterval.intervals,
            localityIds: localities.map(({ id }) => id),
        }),
    })

    const zone = useTimezoneConfig()
    const localize = useLocalizeDateTime()
    const addBackLink = useAddBackLink()

    const calls = mergeQueryResults(queueStatisticsByLocalityCall, partitionedQueueStatisticsByLocalityCall)

    return (
        <LegacyLoadingWrapper
            placeholder={
                <Box paddingSize="lg">
                    <Box.Title text={t('heading.queueStatistics', 'Queues Statistics')} />
                    <LoadingSpinner bare />
                </Box>
            }
            request={calls}
        >
            {([queues, partitionedQueues]) => {
                const queuesTransformed = configuredLocalities.map(({ id }) => {
                    return {
                        localityId: id,
                        queue: queues[id],
                    }
                })

                const {
                    currentInterval: currentIntervalAggregateData,
                    previousInterval: previousIntervalAggregateData,
                } = partitionByInterval(
                    interval,
                    flatten(
                        configuredLocalities.map(
                            ({ id }) => queuesTransformed.find(({ localityId }) => localityId === id)?.queue.items ?? []
                        )
                    )
                )

                const localitiesDenominator = configuredLocalities.length || 1

                const average = (items: Array<number | undefined>) => {
                    return sum(items.map((item) => item ?? 0)) / localitiesDenominator
                }

                const queuesData: QueuesStatistics = {
                    currentInterval: {
                        intervalAverageQueueSize: average(
                            currentIntervalAggregateData.map(({ queueSizeAverage }) => queueSizeAverage)
                        ),
                        intervalAverageWaitTime: average(
                            currentIntervalAggregateData.map(({ waitTimeAverage }) => waitTimeAverage)
                        ),
                        intervalWaitTimeMax:
                            max(currentIntervalAggregateData.map(({ waitTimeMax }) => waitTimeMax)) ?? 0,
                        intervalAverageServeTime: average(
                            currentIntervalAggregateData.map(({ serveTimeAverage }) => serveTimeAverage)
                        ),
                        intervalServeTimeMax:
                            max(currentIntervalAggregateData.map(({ serveTimeMax }) => serveTimeMax)) ?? 0,
                    },
                    previousInterval:
                        comparisonInterval !== undefined
                            ? {
                                  intervalAverageQueueSize: average(
                                      previousIntervalAggregateData.map(({ queueSizeAverage }) => queueSizeAverage)
                                  ),
                                  intervalAverageWaitTime: average(
                                      previousIntervalAggregateData.map(({ waitTimeAverage }) => waitTimeAverage)
                                  ),
                                  intervalWaitTimeMax:
                                      max(previousIntervalAggregateData.map(({ waitTimeMax }) => waitTimeMax)) ?? 0,
                                  intervalAverageServeTime: average(
                                      previousIntervalAggregateData.map(({ serveTimeAverage }) => serveTimeAverage)
                                  ),
                                  intervalServeTimeMax:
                                      max(previousIntervalAggregateData.map(({ serveTimeMax }) => serveTimeMax)) ?? 0,
                              }
                            : undefined,
                }

                const queuesByLocalityByInterval = fromPairs(
                    queuesTransformed
                        .filter(({ localityId }) => configuredLocalities.some(({ id }) => id === localityId))
                        .map(({ queue, localityId }) => {
                            const [curr, prev] = flatten(Object.values(partitionByInterval(interval, queue.items)))

                            return [localityId, { curr, prev }]
                        })
                )

                const queuesDataLocalities = configuredLocalities.map(({ id, name, timezone }) => {
                    const localityData = queuesByLocalityByInterval[id]

                    const currentQueueSizeAverage = localityData.curr.queueSizeAverage ?? 0
                    const currentWaitTimeAverage = localityData.curr.waitTimeAverage ?? 0
                    const currentServeTimeAverage = localityData.curr.serveTimeAverage ?? 0

                    const previousQueueSizeAverage =
                        localityData.prev !== undefined ? localityData.prev.queueSizeAverage ?? 0 : 0
                    const previousWaitTimeAverage =
                        localityData.prev !== undefined ? localityData.prev.waitTimeAverage ?? 0 : 0
                    const previousServeTimeAverage =
                        localityData.prev !== undefined ? localityData.prev.serveTimeAverage ?? 0 : 0

                    const cells: {
                        [key: string]: CellContent
                    } = {
                        locality: {
                            content: name,
                            cellType: 'text',
                            targetLink: addBackLink(
                                generateOrganizationsEditLocalitiesPath(selectedOrganization.id, id)
                            ),
                            renderer: AdminOnlyLinkRenderer,
                        },
                        currentQueueSizeAverage: {
                            content: currentQueueSizeAverage,
                            cellType: 'numeric',
                            numberFormat: 'count',
                            valueChange: round(currentQueueSizeAverage - previousQueueSizeAverage, 1),
                            comparisonValue: round(previousQueueSizeAverage, 1),
                            desiredTrend: 'negative' as TrendType,
                            isChangeAbsolute: true,
                        },
                        currentWaitTimeAverage: {
                            content: currentWaitTimeAverage,
                            cellType: 'numeric',
                            numberFormat: 'time',
                            valueChange: round(currentWaitTimeAverage - previousWaitTimeAverage),
                            comparisonValue: previousWaitTimeAverage,
                            desiredTrend: 'negative' as TrendType,
                            isChangeAbsolute: true,
                        },
                        currentServeTimeAverage: {
                            content: currentServeTimeAverage,
                            cellType: 'numeric',
                            numberFormat: 'time',
                            valueChange: round(currentServeTimeAverage - previousServeTimeAverage),
                            comparisonValue: previousServeTimeAverage,
                            isChangeAbsolute: true,
                            desiredTrend: 'negative' as TrendType,
                        },
                        detail: {
                            cellType: 'rawElement',
                            element: (
                                <DetailLink
                                    to={generateQueueDetailsCustomIntervalPath(
                                        id,
                                        migrateTimestamp(interval.start, zone, new IANAZone(timezone)),
                                        migrateTimestamp(interval.end, zone, new IANAZone(timezone))
                                    )}
                                />
                            ),
                        },
                    }

                    return cells
                })

                const handleDownload = () => {
                    const exportData = fromPairs(
                        Object.entries(partitionedQueues).map(([localityId, queueData]) => [
                            localities.find((l) => l.id === Number(localityId))?.name ?? localityId,
                            queueData.items.map(
                                ({
                                    startingFrom,
                                    queueSizeAverage,
                                    waitTimeAverage,
                                    serveTimeAverage,
                                    waitTimeMax,
                                    serveTimeMax,
                                }) => ({
                                    Date: localize(parseDate(startingFrom)).toLocaleString({
                                        ...DateTime.DATETIME_SHORT,
                                        weekday: 'short',
                                    }),
                                    [t('statistics.avgQueueSize', 'Average queue size')]: round(
                                        queueSizeAverage ?? 0,
                                        1
                                    ),
                                    [t('statistics.avgWaitTime', 'Average wait time')]: round(waitTimeAverage ?? 0, 1),
                                    [t('statistics.averageServeTime', 'Average serve time')]: round(
                                        serveTimeAverage ?? 0,
                                        1
                                    ),
                                    [t('statistics.maxWaitTime', 'Max. wait time')]: round(waitTimeMax ?? 0, 1),
                                    [t('statistics.maxServeTime', 'Max. serve time')]: round(serveTimeMax ?? 0, 1),
                                })
                            ),
                        ])
                    )

                    if (!isEmpty(exportData)) {
                        exportToExcel(
                            exportData,
                            `${selectedOrganization.name}-queues-${formatIntervalToFilename(
                                gdynusaReadableInterval(interval.mapEndpoints(localize))
                            )}`,
                            GoogleEventName.DOWNLOAD_QUEUES_ORGANIZATION_EXCEL,
                            true
                        )
                    } else {
                        notify({
                            title: t('notification.error', 'Error'),
                            content: t('notification.nothingToExport', 'Nothing to export'),
                            variant: 'danger',
                        })
                    }
                }

                const isComparisonIntervalEnabled = (comparisonInterval && queuesData.previousInterval) !== undefined

                return (
                    <Box paddingSize="lg">
                        <Box.Title
                            buttons={
                                <DownloadButtonWrapper
                                    buttonText={t('button.downloadXLSX', 'Download XLSX')}
                                    onClick={handleDownload}
                                />
                            }
                            hideButtons={isEmpty(configuredLocalities)}
                            text={t('heading.queueStatistics', 'Queues Statistics')}
                        />
                        {!isEmpty(configuredLocalities) && (
                            <>
                                {shouldDisplayLocalitySummary && (
                                    <>
                                        <Row>
                                            <StatisticBox
                                                interval={interval}
                                                label={t('statistics.avgQueueSize', 'Average queue size')}
                                                localities={configuredLocalities}
                                                md={4}
                                                sm={12}
                                                tooltip={t(
                                                    'statistics.avgQueueSizeDescription',
                                                    'average queue size across selected localities'
                                                )}
                                            >
                                                <StatisticBoxItem
                                                    changeIndicator={
                                                        isComparisonIntervalEnabled ? (
                                                            <ChangeIndicator
                                                                change={round(
                                                                    queuesData.currentInterval
                                                                        .intervalAverageQueueSize -
                                                                        (queuesData.previousInterval
                                                                            ?.intervalAverageQueueSize ?? 0),
                                                                    1
                                                                )}
                                                                comparisonInterval={comparisonInterval!}
                                                                dataType="people"
                                                                desiredTrend="negative"
                                                                isChangeAbsolute={true}
                                                                isVisible={Boolean(comparisonInterval)}
                                                                valueToCompare={
                                                                    queuesData.previousInterval
                                                                        ?.intervalAverageQueueSize ?? 0
                                                                }
                                                                isBigger
                                                            />
                                                        ) : undefined
                                                    }
                                                    unit={
                                                        <FontAwesomeIcon
                                                            className={classNames(styles.icon, styles.dataSizeLarge)}
                                                            icon={faUserFriends}
                                                        />
                                                    }
                                                    value={queuesData.currentInterval.intervalAverageQueueSize}
                                                />
                                            </StatisticBox>
                                            <StatisticBox
                                                interval={interval}
                                                label={t('statistics.avgWaitTime', 'Average wait time')}
                                                localities={configuredLocalities}
                                                md={4}
                                                sm={12}
                                                tooltip={t(
                                                    'statistics.avgWaitTimeDescription',
                                                    'average wait time across selected localities'
                                                )}
                                            >
                                                <StatisticBoxItem
                                                    changeIndicator={
                                                        isComparisonIntervalEnabled ? (
                                                            <ChangeIndicator
                                                                change={round(
                                                                    queuesData.currentInterval.intervalAverageWaitTime -
                                                                        (queuesData.previousInterval
                                                                            ?.intervalAverageWaitTime ?? 0)
                                                                )}
                                                                comparisonInterval={comparisonInterval!}
                                                                dataType="time"
                                                                desiredTrend="negative"
                                                                isChangeAbsolute={true}
                                                                isVisible={Boolean(comparisonInterval)}
                                                                valueToCompare={
                                                                    queuesData.previousInterval
                                                                        ?.intervalAverageWaitTime ?? 0
                                                                }
                                                                isBigger
                                                            />
                                                        ) : undefined
                                                    }
                                                    value={displayPreciseDuration(
                                                        Math.round(queuesData.currentInterval.intervalAverageWaitTime)
                                                    )}
                                                />
                                            </StatisticBox>
                                            <StatisticBox
                                                interval={interval}
                                                label={t('statistics.averageServeTime', 'Average serve time')}
                                                localities={configuredLocalities}
                                                md={4}
                                                sm={12}
                                                tooltip={t(
                                                    'statistics.avgServeTimeDescription',
                                                    'average serve time across selected localities'
                                                )}
                                            >
                                                <StatisticBoxItem
                                                    changeIndicator={
                                                        isComparisonIntervalEnabled ? (
                                                            <ChangeIndicator
                                                                change={round(
                                                                    queuesData.currentInterval
                                                                        .intervalAverageServeTime -
                                                                        (queuesData.previousInterval
                                                                            ?.intervalAverageServeTime ?? 0)
                                                                )}
                                                                comparisonInterval={comparisonInterval!}
                                                                dataType="time"
                                                                desiredTrend="negative"
                                                                isChangeAbsolute={true}
                                                                isVisible={Boolean(comparisonInterval)}
                                                                valueToCompare={
                                                                    queuesData.previousInterval
                                                                        ?.intervalAverageServeTime ?? 0
                                                                }
                                                                isBigger
                                                            />
                                                        ) : undefined
                                                    }
                                                    value={displayPreciseDuration(
                                                        Math.round(queuesData.currentInterval.intervalAverageServeTime)
                                                    )}
                                                />
                                            </StatisticBox>
                                        </Row>
                                        <Image className={styles.queueFlowImage} src={queueFlow} />
                                    </>
                                )}
                                <SectionSubheading selectedOrganization={selectedOrganization}>
                                    {t('heading.localityComparison', 'Comparison of localities')}
                                </SectionSubheading>
                                <TableWithSorting
                                    bodyRows={queuesDataLocalities}
                                    comparisonInterval={comparisonInterval}
                                    defaultSortingColumn="currentQueueSizeAverage"
                                    defaultSortingOrder="desc"
                                    excludedSortingColumns={['detail']}
                                    headRow={[
                                        {
                                            name: 'locality',
                                            displayName: t('heading.locality', 'Locality'),
                                        },
                                        {
                                            name: 'currentQueueSizeAverage',
                                            displayName: t('statistics.avgQueueSize', 'Average queue size'),
                                            align: 'right',
                                        },
                                        {
                                            name: 'currentWaitTimeAverage',
                                            displayName: t('statistics.avgWaitTime', 'Average wait time'),
                                            align: 'right',
                                        },
                                        {
                                            name: 'currentServeTimeAverage',
                                            displayName: t('statistics.averageServeTime', 'Average serve time'),
                                            align: 'right',
                                        },
                                        {
                                            name: 'detail',
                                            displayName: t('table.actions', 'Actions'),
                                            align: 'right',
                                        },
                                    ]}
                                    paginationSize={15}
                                />
                            </>
                        )}
                        {!isEmpty(notConfiguredLocalities) && (
                            <NotConfiguredLocalitiesList
                                notConfiguredLocalities={notConfiguredLocalities}
                                nothingConfigured={isEmpty(configuredLocalities)}
                            />
                        )}
                    </Box>
                )
            }}
        </LegacyLoadingWrapper>
    )
}

export default QueueSection
