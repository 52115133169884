import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams, Redirect } from 'react-router'

import { generateLocalityOccupancyDashboardPath, STAY_SAFE_HIDE_STORE_CAPACITY } from '@helpers/VividiURLs'

import Helmet from '@components/Helmet'
import LanguageOverride from '@components/LanguageOverride'
import LocalityOccupancyConsumer, { OccupancyData } from '@components/LocalityOccupancyConsumer'
import StaySafeDashboardView from '@components/StaySafeDashboardView/StaySafeDashboardView'

const LocalityOccupancyDashboard: React.FC = () => {
    const { i18n } = useTranslation()
    const params = useParams<{
        id: string
        lang?: string
        hideCapacity?: string
    }>()
    const localityId = parseInt(params.id, 10)

    const isLanguageValid = params.lang !== undefined && (i18n.options.supportedLngs || []).includes(params.lang)

    const isShowCapacityValid = params.hideCapacity === STAY_SAFE_HIDE_STORE_CAPACITY

    const showCapacity = !params.hideCapacity || (params.hideCapacity !== undefined && !isShowCapacityValid)

    // Reload the page every 12 hours
    useEffect(() => {
        const timeout = setTimeout(() => {
            window.location.reload()
        }, 12 * 3600 * 1000)

        return () => clearTimeout(timeout)
    }, [])

    if (!isLanguageValid || (params.hideCapacity && !isShowCapacityValid)) {
        return <Redirect to={generateLocalityOccupancyDashboardPath(localityId, 'en')} />
    }

    const documentTitle = i18n.t('title.staySafeScreen', 'Stay Safe')

    return (
        <>
            <Helmet>
                <title>{documentTitle}</title>
            </Helmet>
            <LanguageOverride>
                <LocalityOccupancyConsumer
                    dataRenderer={({ data }: { data: OccupancyData }) => (
                        <StaySafeDashboardView data={data} showCapacity={showCapacity} showExactNumbers={true} />
                    )}
                    localityId={localityId}
                    subscriptionType="stay_safe"
                />
            </LanguageOverride>
        </>
    )
}

export default LocalityOccupancyDashboard
