/* tslint:disable */
/* eslint-disable */
/**
 * Vividi Dashboard API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DatetimeInterval,
    DatetimeIntervalFromJSON,
    DatetimeIntervalFromJSONTyped,
    DatetimeIntervalToJSON,
} from './DatetimeInterval';
import {
    SalesStatisticsDetailsSummaryRow,
    SalesStatisticsDetailsSummaryRowFromJSON,
    SalesStatisticsDetailsSummaryRowFromJSONTyped,
    SalesStatisticsDetailsSummaryRowToJSON,
} from './SalesStatisticsDetailsSummaryRow';

/**
 * 
 * @export
 * @interface SalesStatisticsDetailsSummaryResponse
 */
export interface SalesStatisticsDetailsSummaryResponse {
    /**
     * 
     * @type {Array<SalesStatisticsDetailsSummaryRow>}
     * @memberof SalesStatisticsDetailsSummaryResponse
     */
    zoneRows: Array<SalesStatisticsDetailsSummaryRow>;
    /**
     * 
     * @type {Array<DatetimeInterval>}
     * @memberof SalesStatisticsDetailsSummaryResponse
     */
    timeBrackets: Array<DatetimeInterval>;
}

export function SalesStatisticsDetailsSummaryResponseFromJSON(json: any): SalesStatisticsDetailsSummaryResponse {
    return SalesStatisticsDetailsSummaryResponseFromJSONTyped(json, false);
}

export function SalesStatisticsDetailsSummaryResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): SalesStatisticsDetailsSummaryResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'zoneRows': ((json['zone_rows'] as Array<any>).map(SalesStatisticsDetailsSummaryRowFromJSON)),
        'timeBrackets': ((json['time_brackets'] as Array<any>).map(DatetimeIntervalFromJSON)),
    };
}

export function SalesStatisticsDetailsSummaryResponseToJSON(value?: SalesStatisticsDetailsSummaryResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'zone_rows': ((value.zoneRows as Array<any>).map(SalesStatisticsDetailsSummaryRowToJSON)),
        'time_brackets': ((value.timeBrackets as Array<any>).map(DatetimeIntervalToJSON)),
    };
}

