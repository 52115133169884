import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faCheckCircle, faExclamationCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import { Button, ButtonProps, Spinner } from 'react-bootstrap'
import { MutationStatus } from 'react-query'

import { useIsPreRendering } from '@helpers/preRendering'

interface Props {
    status: MutationStatus
    type?: ButtonProps['type']
    variant?: ButtonProps['variant']
    size?: ButtonProps['size']
    text: string
    loadingText?: string
    doneText?: string
    failedText?: string
    onClick?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void
    className?: string
    retryTimeoutSeconds?: number
    icon?: IconProp
    allowRefetch?: boolean
    bsPrefix?: string
    disabled?: boolean
}

const getButtonText = (
    isSubmittable: boolean,
    status: MutationStatus,
    text: string,
    loadingText?: string,
    failedText?: string,
    doneText?: string,
    icon?: IconProp
) => {
    if (status === 'loading') {
        return (
            <>
                <Spinner animation="border" size="sm" /> {loadingText ?? text}
            </>
        )
    } else if (status === 'error' && !isSubmittable) {
        return (
            <>
                <FontAwesomeIcon icon={faExclamationCircle} /> {failedText}
            </>
        )
    } else if (!isSubmittable && status === 'success') {
        return (
            <>
                <FontAwesomeIcon icon={faCheckCircle} /> {doneText ?? text}
            </>
        )
    } else if (isSubmittable) {
        return (
            <>
                {status === 'idle' && icon && (
                    <>
                        <FontAwesomeIcon icon={icon} />{' '}
                    </>
                )}
                {status === 'success' && (
                    <>
                        <FontAwesomeIcon icon={faCheckCircle} />{' '}
                    </>
                )}
                {status === 'error' && icon && (
                    <>
                        <FontAwesomeIcon icon={icon} />{' '}
                    </>
                )}
                {text}
            </>
        )
    } else if (!isSubmittable) {
        return <>{text}</>
    }
}

const AsyncButton: React.FunctionComponent<Props> = ({
    allowRefetch,
    retryTimeoutSeconds,
    bsPrefix,
    className,
    size,
    text,
    loadingText,
    failedText,
    doneText,
    icon,
    status,
    onClick: onClickHandler,
    disabled = false,
    type = 'submit',
    variant = 'primary',
    ...rest
}) => {
    const [allowRetry, setAllowRetry] = useState<boolean | undefined>()

    const isPreRendering = useIsPreRendering()

    const onClick: Props['onClick'] = (e) => {
        setAllowRetry(allowRefetch)

        if (retryTimeoutSeconds !== undefined) {
            setTimeout(() => {
                setAllowRetry(true)
            }, retryTimeoutSeconds * 1000)
        }

        onClickHandler?.(e)
    }

    const isSubmittable =
        status === 'idle' ||
        (Boolean(allowRetry) && status === 'error') ||
        (Boolean(allowRefetch) && status === 'success')

    return (
        <Button
            bsPrefix={bsPrefix}
            className={className}
            disabled={disabled || !isSubmittable || isPreRendering}
            size={size}
            type={type}
            variant={variant}
            onClick={onClick}
            {...rest}
        >
            {getButtonText(isSubmittable, status, text, loadingText, failedText, doneText, icon)}
        </Button>
    )
}

export default AsyncButton
