import React from 'react'
import { useQuery } from 'react-query'
import { useHistory } from 'react-router'

import { LOGIN_PATH } from '@helpers/VividiURLs'
import { visibleOrganizationsQuery } from '@helpers/api'
import { deleteAuthContext, useAuthContext } from '@helpers/auth'
import { GoogleEventCategory, GoogleEventName, trackEvent } from '@helpers/gtag'
import useProfile from '@helpers/profile'

import NavbarComponent from './NavbarView'

interface Props {
    className?: string
}

const Navbar: React.FC<Props> = (props) => {
    const history = useHistory()
    const authContext = useAuthContext()
    const isAuthenticated = Boolean(authContext)
    const profileCall = useProfile()

    const organizationsCall = useQuery({
        ...visibleOrganizationsQuery(profileCall.data),
    })

    const logOut = () => {
        deleteAuthContext()
        trackEvent(GoogleEventCategory.USER, GoogleEventName.LOGOUT, profileCall.data?.email, profileCall.data?.email)
        history.push(LOGIN_PATH)
    }

    const organization = profileCall.data?.organizationId
        ? organizationsCall.data?.organizations.find((o) => o.id === profileCall.data?.organizationId)
        : undefined

    return (
        <NavbarComponent
            className={props.className}
            isAuthenticated={isAuthenticated}
            organization={organization}
            organizationFeatures={organization?.features}
            profile={profileCall.data}
            profileLoadingState={profileCall.status}
            onLogOut={logOut}
        />
    )
}

export default Navbar
