import classNames from 'classnames'
import { LocationDescriptor } from 'history'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { Role } from '@api'

import useProfile from '@helpers/profile'

import styles from '@components/OrganizationList.module.scss'

const AdminOnlyLinkRenderer: React.FC<{
    text: string
    className?: string
    targetLink?: string | LocationDescriptor
}> = ({ text, className, targetLink }) => {
    const { t } = useTranslation()
    const profileCall = useProfile()

    return text !== '' ? (
        <td className={className}>
            {profileCall.data === undefined ? null : profileCall.data.role === Role.Administrator &&
              targetLink !== undefined ? (
                <>
                    <Link className={styles.linkCell} to={targetLink}>
                        {text}
                    </Link>
                </>
            ) : (
                text
            )}
        </td>
    ) : (
        <td className={classNames(className, 'text-muted')}>{t('others.notSet', 'Not set')}</td>
    )
}

export default AdminOnlyLinkRenderer
