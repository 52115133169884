/* tslint:disable */
/* eslint-disable */
/**
 * Vividi Dashboard API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ReportFrequency,
    ReportFrequencyFromJSON,
    ReportFrequencyFromJSONTyped,
    ReportFrequencyToJSON,
} from './ReportFrequency';

/**
 * 
 * @export
 * @interface GeneratedReport
 */
export interface GeneratedReport {
    /**
     * 
     * @type {number}
     * @memberof GeneratedReport
     */
    reportId: number;
    /**
     * 
     * @type {string}
     * @memberof GeneratedReport
     */
    startingFrom: string;
    /**
     * 
     * @type {string}
     * @memberof GeneratedReport
     */
    endingAt: string;
    /**
     * 
     * @type {string}
     * @memberof GeneratedReport
     */
    generatedAt: string;
    /**
     * 
     * @type {ReportFrequency}
     * @memberof GeneratedReport
     */
    generationFrequency: ReportFrequency;
}

export function GeneratedReportFromJSON(json: any): GeneratedReport {
    return GeneratedReportFromJSONTyped(json, false);
}

export function GeneratedReportFromJSONTyped(json: any, ignoreDiscriminator: boolean): GeneratedReport {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'reportId': json['report_id'],
        'startingFrom': json['starting_from'],
        'endingAt': json['ending_at'],
        'generatedAt': json['generated_at'],
        'generationFrequency': ReportFrequencyFromJSON(json['generation_frequency']),
    };
}

export function GeneratedReportToJSON(value?: GeneratedReport | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'report_id': value.reportId,
        'starting_from': value.startingFrom,
        'ending_at': value.endingAt,
        'generated_at': value.generatedAt,
        'generation_frequency': ReportFrequencyToJSON(value.generationFrequency),
    };
}

