import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons'
import classNames from 'classnames'
import { isEmpty, uniqBy } from 'lodash'
import { DateTime, Interval } from 'luxon'
import React from 'react'
import { Button } from 'react-bootstrap'

import { gdynusaReadableInterval } from '@helpers/datetimeUtils'
import { formatInterval, Precision } from '@helpers/intervals'
import { useLocalizeDateTime } from '@helpers/timezoneConfig'

import IconButton from '@elements/Buttons/IconButton'

import { parseDate } from '@components/plots/common'

import styles from './FloorplanHeatmapDetail.module.scss'
import FloorplanHeatmapIntervalsDropdown from './FloorplanHeatmapIntervalsDropdown'

interface PickerProps {
    availableIntervals: Array<Array<string>>
    onNavigation: (interval: Interval) => void
    selectedInterval: Interval
}

const FloorplanHeatmapDetailDatePaginator: React.FC<PickerProps> = ({
    availableIntervals,
    onNavigation,
    selectedInterval,
}) => {
    const localize = useLocalizeDateTime()

    if (isEmpty(availableIntervals)) {
        return (
            <div className={styles.floorplanHeatmapDetailDatePicker}>
                <IconButton disabled={true} icon={faAngleLeft} />
                <Button className={styles.rangeDisplayButton} variant="secondary">
                    <strong>
                        {formatInterval(
                            gdynusaReadableInterval(
                                Interval.fromDateTimes(localize(selectedInterval.start), localize(selectedInterval.end))
                            ),
                            Precision.DAY,
                            DateTime.DATE_SHORT
                        )}
                    </strong>
                </Button>
                <IconButton disabled={true} icon={faAngleRight} />
            </div>
        )
    }

    const mostRecentInterval = Interval.fromDateTimes(
        parseDate(availableIntervals[availableIntervals.length - 1][0]),
        parseDate(availableIntervals[availableIntervals.length - 1][1])
    )

    const oldestInterval = Interval.fromDateTimes(
        parseDate(availableIntervals[0][0]),
        parseDate(availableIntervals[0][1])
    )

    const handleNavigation = (direction: 'lookahead' | 'lookbehind') => {
        const intervalArray = uniqBy(availableIntervals, (item) => JSON.stringify(item)).map(
            ([intervalStart, intervalEnd]) => Interval.fromDateTimes(parseDate(intervalStart), parseDate(intervalEnd))
        )

        const navigationBoundary = intervalArray.length - 1

        const cursorPosition = intervalArray.findIndex((it) => it.equals(selectedInterval))

        if (direction === 'lookahead' && cursorPosition < navigationBoundary) {
            onNavigation(intervalArray[cursorPosition + 1])
        }

        if (direction === 'lookbehind' && cursorPosition > 0) {
            onNavigation(intervalArray[cursorPosition - 1])
        }
    }

    const isLookBehindBoundary = oldestInterval.equals(selectedInterval)
    const isLookAheadBoundary = mostRecentInterval.equals(selectedInterval)

    return (
        <div className={styles.floorplanHeatmapDetailDatePicker}>
            <IconButton
                className={classNames({
                    [styles.navigationButtonHidden]: isLookBehindBoundary,
                })}
                disabled={isLookBehindBoundary}
                icon={faAngleLeft}
                onClick={() => handleNavigation('lookbehind')}
            />
            <FloorplanHeatmapIntervalsDropdown
                availableIntervals={availableIntervals}
                isLastWeek={mostRecentInterval.equals(selectedInterval)}
                selectedInterval={selectedInterval}
                onSelect={onNavigation}
            />
            <IconButton
                className={classNames({
                    [styles.navigationButtonHidden]: isLookAheadBoundary,
                })}
                disabled={isLookAheadBoundary}
                icon={faAngleRight}
                onClick={() => handleNavigation('lookahead')}
            />
        </div>
    )
}

export default FloorplanHeatmapDetailDatePaginator
