import React from 'react'
import { Helmet } from 'react-helmet'
import { useLocation } from 'react-router'

import appleTouchIcon from './images/logo/apple-touch-icon.png'
import favicon32 from './images/logo/favicon-32x32.png'
import favicon from './images/logo/favicon.ico'
import webManifest from './site.webmanifest'

export const SUPPORT_EMAIL = 'support@vividi.io'

export const VividiMeta = () => {
    const location = useLocation()

    return (
        <Helmet>
            <title>VIVIDI | Thinking inside the box</title>
            <meta
                content="VIVIDI is an innovative smart device providing fully anonymized information about customers. It is a multifunctional marketing tool covering all aspects of offline marketing. It reveals who the customers are, explores their behavior or shopping habits. VIVIDI helps the client better understand their customers and adapt the business to the right audience's needs."
                name="description"
            />
            <meta content="" name="keywords" />
            <meta content="Iterait" name="author" />
            <meta content="VIVIDI" property="og:title" />
            <meta content="website" property="og:type" />
            <meta content={`https://vividi.io${location.pathname}`} property="og:url" />
            <meta content="https://vividi.io/images/logo.svg" property="og:image" />
            <meta content="VIVIDI" property="og:description" />
            <meta content="VIVIDI" property="og:site_name" />
            <link href={favicon} rel="icon" />
            <link href={appleTouchIcon} rel="apple-touch-icon" sizes="180x180" />
            <link href={favicon32} rel="icon" sizes="32x32" type="image/png" />
            <link href={webManifest} rel="manifest" />
            <meta content="on" http-equiv="cleartype" />
            <script type="application/ld+json">{`{
                                "@context": "https://schema.org",
                                "@type": "WebSite",
                                "name": "VIVIDI",
                                "description": "VIVIDI is an innovative smart device providing fully anonymized information about customers. It is a multifunctional marketing tool covering all aspects of offline marketing. It reveals who the customers are, explores their behavior or shopping habits. VIVIDI helps the client better understand their customers and adapt the business to the right audience's needs.",
                                "telephone": "+420 775 616 433",
                                "email": "${SUPPORT_EMAIL}",
                                "url": "https://vividi.io/",
                                "logo": "https://res.cloudinary.com/iterait-a-s/images/v1626855918/Vividi-logo/Vividi-logo-webp?_i=AA",
                                "sameAs": ["https://www.linkedin.com/company/vividi-ai/"]
                            }`}</script>
        </Helmet>
    )
}
