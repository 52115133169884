import { fromPairs, isEmpty } from 'lodash'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { DeviceResponse, OrganizationResponse, SceneResponse } from '@api'

import FullTextSearchBox from '@components/FullTextSearchBox'

import styles from './DeviceList.module.scss'

type Props = {
    selectableDevices: DeviceResponse[]
    scenes: { [deviceId: string]: SceneResponse }
    selectableOrganizations: {
        [key: string]: OrganizationResponse
    }
    selectedDevices: DeviceResponse[]
    selectedOrganizations: OrganizationResponse[]
    onDeviceSelect: (selectableDevices: DeviceResponse[]) => void
    onOrganizationSelect: (selectableOrganizations: { [key: string]: OrganizationResponse }) => void
    handleResetSearch: () => void
}

const DeviceListSearchToolbar = ({
    selectedDevices,
    selectedOrganizations,
    selectableDevices,
    scenes,
    selectableOrganizations,
    onDeviceSelect,
    onOrganizationSelect,
    handleResetSearch,
}: Props) => {
    const { t } = useTranslation()

    const deviceChoices = useMemo(
        () =>
            fromPairs(
                selectableDevices.map((d) => [
                    `d-${d.id.toString()}`,
                    {
                        itemId: d.id.toString(),
                        label: scenes[d.id.toString()]?.label ?? '',
                        simPhoneNumber: d.simPhoneNumber ?? '',
                        selectableType: 'device',
                    },
                ])
            ),
        [selectableDevices]
    )

    const organizationChoices = useMemo(
        () =>
            fromPairs(
                Object.values(selectableOrganizations).map((o) => [
                    `o-${o.id.toString()}`,
                    {
                        itemId: o.id.toString(),
                        label: o.name,
                        selectableType: 'organization',
                        // Added empty simPhoneNumber to suppress "Fields passed to `filterBy` should have string values" error (field cannot be undefined)
                        simPhoneNumber: '',
                    },
                ])
            ),
        [selectableOrganizations]
    )

    const choices = useMemo(
        () => Object.assign(organizationChoices, deviceChoices),
        [organizationChoices, deviceChoices]
    )

    const handleSelect = (uIds?: string[]) => {
        const selectedType = uIds?.map((uid) => uid.substr(0, 2))[0]

        if (uIds === undefined) {
            onDeviceSelect(selectableDevices)
            onOrganizationSelect(selectableOrganizations)

            return
        }

        const selectedDevices = selectableDevices.filter((device) => uIds.includes(`d-${device.id.toString()}`))

        const selectedOrganizations = Object.values(selectableOrganizations).filter((organization) =>
            uIds.includes(`o-${organization.id.toString()}`)
        )

        switch (selectedType) {
            case 'd-': {
                onDeviceSelect(selectedDevices)

                return
            }

            case 'o-': {
                onDeviceSelect(
                    selectableDevices.filter(
                        (device) =>
                            device.organizationId &&
                            selectedOrganizations.map((o) => o.id).includes(device.organizationId)
                    )
                )

                onOrganizationSelect(
                    selectedOrganizations.reduce(
                        (acc, curr) =>
                            Object.assign(acc, {
                                [curr.id.toString()]: curr,
                            }),
                        {} as { [id: number]: OrganizationResponse }
                    )
                )

                return
            }
            default: {
                onDeviceSelect(selectableDevices)
                onOrganizationSelect(selectableOrganizations)
            }
        }
    }

    return (
        <div className={styles.searchInput}>
            <FullTextSearchBox
                choices={choices}
                disabled={isEmpty(deviceChoices) || isEmpty(organizationChoices)}
                filterBy={['simPhoneNumber']}
                handleResetSearch={handleResetSearch}
                optionalListAttributes={fromPairs(
                    selectableDevices.map((d) => [d.id.toString(), { simPhoneNumber: d.simPhoneNumber }])
                )}
                placeholder={
                    isEmpty(deviceChoices) || isEmpty(organizationChoices)
                        ? t('others.nothingToSearch', 'Nothing to search')
                        : t('others.typeDeviceOrOrganizationName', 'Type device or organization name to search...')
                }
                selected={[
                    ...selectedDevices.map((d) => `d-${d.id}`),
                    ...selectedOrganizations.map((o) => `o-${o.id}`),
                ]}
                onSelect={handleSelect}
            />
        </div>
    )
}

export default DeviceListSearchToolbar
