import React from 'react'
import { useTranslation } from 'react-i18next'

const AppFooter: React.FC = () => {
    const { t } = useTranslation()

    return (
        <>
            <span>
                Copyright© {new Date().getFullYear()} Iterait a.s, {t('others.allRights', 'All rights reserved')}
            </span>
            {process.env.REACT_APP_GIT_VERSION_ID && (
                <span>
                    Vividi {t('others.version', 'version')} - {process.env.REACT_APP_GIT_VERSION_ID}
                </span>
            )}
            <a href="https://vividi.io/terms-of-service/">{t('others.termsAndConditions', 'Terms and conditions')}</a>
        </>
    )
}

export default AppFooter
