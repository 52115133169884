import { sum, round, partition } from 'lodash'
import { DateTime, Interval } from 'luxon'

import { exists, mapValues } from '@api'

import { DatetimeInterval, FloorplanCrowdSizeResponse, PersonPositionFromJSON } from '@api/models'

import { parseDate } from '@components/plots/common'

export const calculatePercentageChange = (current: number, previous?: number) => {
    if (previous !== undefined && previous > 0) {
        const value = (previous - current) / previous

        return value !== 0 ? round(value * -100) : 0
    } else if ((current > 0 && previous === 0) || previous === null) {
        return 100
    }

    return 0
}
export const weightedAverage = (items: Array<number>, weights: Array<number>): number => {
    if (items.length !== weights.length) {
        throw new Error('Array length does not match weights')
    }

    const result = sum(items.map((item, i) => item * weights[i])) / sum(weights)

    return isNaN(result) ? 0 : result
}

/**
 * Create a function that can be used to partition statistic results into those for the current interval and those we compare the current interval to.
 *
 * @param currentInterval Interval selected as current in a summary screen
 * @returns A function that determines whether an interval is part of the selected interval
 */
const createIsInCurrentInterval =
    (currentInterval: Interval) =>
    (subject: Interval): boolean =>
        currentInterval.engulfs(subject)

export function partitionByInterval<Type extends DatetimeInterval>(
    currentInterval: Interval,
    dataArray: Array<Type>
): {
    currentInterval: Array<Type>
    previousInterval: Array<Type>
} {
    const isInCurrentInterval = createIsInCurrentInterval(currentInterval)

    const [currentIntervalData, previousInterval] = partition(dataArray, (it) => isInCurrentInterval(parseInterval(it)))

    return { currentInterval: currentIntervalData, previousInterval }
}

export const parseInterval = (intervalRecord: { startingFrom: string; endingAt: string }): Interval =>
    Interval.fromDateTimes(parseDate(intervalRecord.startingFrom), parseDate(intervalRecord.endingAt))

export function countIf<Type>(items: Array<Type> | undefined, checkValues: (item: Type) => boolean): number {
    if (!items) {
        return 0
    }

    const checkedResult = items.filter((item) => checkValues(item))

    return checkedResult.length
}

/**
 * Exported as StatisticsSummary utils function instead of incorrect autogenerated one
 * @param json
 * @returns FloorplanCrowdSizeResponse
 */
export function FloorplanCrowdSizeResponseFromJSON(json: any): FloorplanCrowdSizeResponse {
    return FloorplanCrowdSizeResponseFromJSONTyped(json, false)
}

function FloorplanCrowdSizeResponseFromJSONTyped(json: any, _ignoreDiscriminator: boolean): FloorplanCrowdSizeResponse {
    if (json === undefined || json === null) {
        return json
    }

    return {
        currentCrowdSize: json['current_crowd_size'],
        maximumCapacity: json['maximum_capacity'],
        estimatedWaitTime: json['estimated_wait_time'],
        observedAt: json['observed_at'],
        calculationBase: json['calculation_base'],
        crowdSizeHistory: !exists(json, 'crowd_size_history') ? undefined : json['crowd_size_history'],
        crowdSizeHistoryDatetime: !exists(json, 'crowd_size_history_datetime')
            ? undefined
            : json['crowd_size_history_datetime'],
        personPositions: !exists(json, 'person_positions')
            ? undefined
            : mapValues(json['person_positions'], (positions) => positions.map(PersonPositionFromJSON)),
    }
}

export type DateTimeFormat = {
    date: DateTime
    dateStyle?: 'medium' | 'full' | 'long' | 'short'
}

export const displayDateTimes = (startDate?: DateTimeFormat, endDate?: DateTimeFormat) => {
    let dateString = ''

    if (startDate) {
        dateString += startDate.date.toLocaleString({
            dateStyle: startDate.dateStyle ?? 'medium',
        })
    }

    if (endDate) {
        dateString += startDate ? ' - ' : ''
        dateString += endDate.date.toLocaleString({
            dateStyle: endDate.dateStyle ?? 'medium',
        })
    }

    return dateString
}
