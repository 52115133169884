import { head, last } from 'lodash'
import { Interval, Duration } from 'luxon'
import { QueryObserverResult, QueryStatus, useQuery } from 'react-query'

import {
    ZoneOccupancySummaryResponse,
    OutagesResponse,
    OrganizationListResponse,
    LocalityNameListResponse,
    SceneListResponse,
} from '@api'

import { localityApi, statisticsApi } from '@services'

import { mergeQueryResults, visibleLocalitiesQuery, visibleOrganizationsQuery } from '@helpers/api'
import useProfile from '@helpers/profile'
import { useTimezoneConfig } from '@helpers/timezoneConfig'
import { ZoneOccupancyGranularity } from '@helpers/types'

import { parseDate, partitionInterval } from '@components/plots/common'

type ZoneOccupancyData = {
    status: QueryStatus
    request: QueryObserverResult<
        [
            ZoneOccupancySummaryResponse,
            SceneListResponse,
            LocalityNameListResponse,
            OutagesResponse,
            OrganizationListResponse
        ]
    >
}

export const granularityToDuration = (granularity: ZoneOccupancyGranularity): Duration => {
    switch (granularity) {
        case ZoneOccupancyGranularity.MINUTE:
            return Duration.fromObject({ minute: 1 })
        case ZoneOccupancyGranularity.FIVE_MINUTES:
            return Duration.fromObject({ minute: 5 })
        case ZoneOccupancyGranularity.QUARTER_HOUR:
            return Duration.fromObject({ minutes: 15 })
        case ZoneOccupancyGranularity.HALF_HOUR:
            return Duration.fromObject({ minutes: 30 })
        case ZoneOccupancyGranularity.HOUR:
            return Duration.fromObject({ minutes: 60 })
        case ZoneOccupancyGranularity.DAY:
            return Duration.fromObject({ day: 1 })
        case ZoneOccupancyGranularity.WEEK:
            return Duration.fromObject({ week: 1 })
    }
}

export const useZoneOccupancyData = (
    interval: Interval,
    selectedLocalityId: number,
    granularity?: ZoneOccupancyGranularity
): ZoneOccupancyData => {
    const profileCall = useProfile()

    const localitiesCall = useQuery({
        ...visibleLocalitiesQuery(profileCall.data),
        enabled: profileCall.data !== undefined,
    })

    const organizationsCall = useQuery({
        ...visibleOrganizationsQuery(profileCall.data),
        enabled: profileCall.data !== undefined,
    })

    const scenesCall = useQuery(
        localityApi.getLocalityScenes.query({
            localityId: selectedLocalityId,
        })
    )

    const zoneOccupancySummaryCall = useQuery(
        statisticsApi.getZoneOccupancySummary.query({
            body: {
                ...partitionInterval(
                    interval,
                    useTimezoneConfig(),
                    granularity ? granularityToDuration(granularity) : undefined
                ),
                locality: selectedLocalityId,
            },
        })
    )

    const outagesCall = useQuery({
        ...statisticsApi.getMultiDeviceOutages.query({
            body: {
                sceneIds: scenesCall.data?.scenes.map((s) => s.id) ?? [],
                startingFrom: parseDate(head(zoneOccupancySummaryCall.data?.timeBrackets)?.startingFrom ?? '').toISO(),
                endingAt: parseDate(last(zoneOccupancySummaryCall.data?.timeBrackets)?.endingAt ?? '').toISO(),
            },
        }),
        enabled: zoneOccupancySummaryCall.isSuccess,
    })

    const requests = mergeQueryResults(
        zoneOccupancySummaryCall,
        scenesCall,
        localitiesCall,
        outagesCall,
        organizationsCall
    )

    return {
        status: requests.status,
        request: requests,
    }
}
