/* tslint:disable */
/* eslint-disable */
/**
 * Vividi Dashboard API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OrganizationFeatures
 */
export interface OrganizationFeatures {
    /**
     * 
     * @type {boolean}
     * @memberof OrganizationFeatures
     */
    footfall: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OrganizationFeatures
     */
    zoneOccupancy: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OrganizationFeatures
     */
    realtimeOccupancy: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OrganizationFeatures
     */
    queueMonitoring: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OrganizationFeatures
     */
    emotions: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OrganizationFeatures
     */
    staySafeNotifier: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OrganizationFeatures
     */
    floorplans: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OrganizationFeatures
     */
    staySafeFloorplan: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OrganizationFeatures
     */
    conversions: boolean;
}

export function OrganizationFeaturesFromJSON(json: any): OrganizationFeatures {
    return OrganizationFeaturesFromJSONTyped(json, false);
}

export function OrganizationFeaturesFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrganizationFeatures {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'footfall': json['footfall'],
        'zoneOccupancy': json['zone_occupancy'],
        'realtimeOccupancy': json['realtime_occupancy'],
        'queueMonitoring': json['queue_monitoring'],
        'emotions': json['emotions'],
        'staySafeNotifier': json['stay_safe_notifier'],
        'floorplans': json['floorplans'],
        'staySafeFloorplan': json['stay_safe_floorplan'],
        'conversions': json['conversions'],
    };
}

export function OrganizationFeaturesToJSON(value?: OrganizationFeatures | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'footfall': value.footfall,
        'zone_occupancy': value.zoneOccupancy,
        'realtime_occupancy': value.realtimeOccupancy,
        'queue_monitoring': value.queueMonitoring,
        'emotions': value.emotions,
        'stay_safe_notifier': value.staySafeNotifier,
        'floorplans': value.floorplans,
        'stay_safe_floorplan': value.staySafeFloorplan,
        'conversions': value.conversions,
    };
}

