import React from 'react'
import { Form, Row } from 'react-bootstrap'

import { CaptureSettings, LocalityNameModel } from '@api'

import { ErrorEntry } from '@helpers/types'

import LocalityPickerInput, { LocalityTokenRenderer } from '@components/GenericInputs/LocalityPickerInput'

import { SceneData, SceneRow, NoteRow, ToggleRow } from './SceneEditForm'

type Props = {
    sceneData: SceneData
    captureSettings: CaptureSettings
    localities: Array<LocalityNameModel>
    selectedLocalities: Array<LocalityNameModel>
    onSceneChange: (sceneData: SceneData) => void
    onCaptureSettingsChange: (captureSettings: CaptureSettings) => void
    onLocalitiesChange: (localities: Array<LocalityNameModel>) => void
    localityPickerTokenRenderer: LocalityTokenRenderer
    errors: { label: ErrorEntry }
    actionButtons?: JSX.Element
}

const AddSceneForm: React.FC<Props> = ({
    captureSettings,
    errors,
    sceneData,
    onSceneChange,
    onCaptureSettingsChange,
    onLocalitiesChange,
    localities,
    selectedLocalities,
    localityPickerTokenRenderer,
    actionButtons,
}) => (
    <Form>
        <ToggleRow
            detectVehicles={captureSettings.detectVehicles}
            isLongRange={captureSettings.isLongRange}
            onDetectVehiclesUpdate={(detectVehicles) => onCaptureSettingsChange({ ...captureSettings, detectVehicles })}
            onLongRangeUpdate={(isLongRange) => onCaptureSettingsChange({ ...captureSettings, isLongRange })}
        />
        <SceneRow
            errors={errors}
            isNewScene={true}
            label={sceneData.label}
            onLabelUpdate={(label) => onSceneChange({ ...sceneData, label })}
        />
        <NoteRow note={sceneData.note} onNoteUpdate={(note) => onSceneChange({ ...sceneData, note })} />
        <Form.Group as={Row}>
            <LocalityPickerInput
                localityChoices={Object.fromEntries(localities.map((l) => [l.id.toString(), l.name]))}
                selection={selectedLocalities}
                tokenRenderer={localityPickerTokenRenderer}
                onSelect={(ids) => onLocalitiesChange(ids.map((id) => localities.find((l) => l.id.toString() === id)!))}
            />
        </Form.Group>
        {actionButtons}
    </Form>
)

export default AddSceneForm
