import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import { identity, isEmpty, min } from 'lodash'
import { DateTime, Interval } from 'luxon'
import React from 'react'
import { Card } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import { OutageModel, Role } from '@api'

import { formatInterval, Precision } from '@helpers/intervals'
import { useTimezoneConfig } from '@helpers/timezoneConfig'
import { outageInterval } from '@helpers/utils'

import RoleChecker from '@components/RoleChecker'

import styles from './TooltipContent.module.scss'
import TooltipTable from './TooltipTable'

interface OwnProps {
    outages?: OutageModel[]
    now: DateTime
    intervalResolver?: (label: string) => Interval
    children?: (label: string, interval: Interval) => JSX.Element
    className?: string
    customLabel?: string
}

interface ForwardedProps {
    active: boolean
    label: string
    labelFormatter: (label: string) => React.ReactNode
    formatter: (value: number, name: string) => [number | string, string]
    payload: Array<{ color: string; name: string; value: number; payload: any }>
}

const TooltipContent: React.FC<OwnProps & Partial<ForwardedProps>> = ({ labelFormatter = identity, ...props }) => {
    const { t } = useTranslation()

    const zone = useTimezoneConfig()

    if (!props.active || props.payload === undefined || props.payload === null || props.label === undefined) {
        return null
    }

    const interval = props.intervalResolver?.(props.label)

    const outages =
        props.outages && props.intervalResolver && interval
            ? props.outages
                  .filter((o) => outageInterval(o).overlaps(interval))
                  .filter((o) => {
                      const outage = outageInterval(o)

                      return !outage.isAfter(props.now)
                  })
            : []

    if (isEmpty(outages) && !props.payload.some((it) => it.payload?.hasData)) {
        return null
    }

    return (
        <Card className={classNames(styles.wrapper, props.className)}>
            <Card.Header>{props.label !== undefined && labelFormatter(props.label)}</Card.Header>
            <Card.Body>
                {(interval && props.children?.(props.label, interval)) ?? (
                    <TooltipTable>
                        {props.payload.map((item) => {
                            const [value, name] = props.formatter?.(item.value, item.name) ?? [item.value, item.name]

                            return (
                                <TooltipTable.Item key={item.name} label={props.customLabel ? props.customLabel : name}>
                                    {value}
                                </TooltipTable.Item>
                            )
                        })}
                    </TooltipTable>
                )}
                {!isEmpty(outages) && (
                    <RoleChecker whitelist={[Role.Administrator]}>
                        {outages.map((o, i) => {
                            const interval = outageInterval(o)

                            return (
                                // eslint-disable-next-line react/no-array-index-key
                                <div key={i} className={styles.outage}>
                                    <FontAwesomeIcon className={styles.icon} icon={faExclamationTriangle} />
                                    <strong>
                                        {formatInterval(
                                            Interval.fromDateTimes(
                                                interval.start.setZone(zone),
                                                min([interval.end, props.now])!.setZone(zone)
                                            ),
                                            Precision.MINUTE
                                        )}
                                    </strong>
                                    :{' '}
                                    {t('statistics.outage', 'Outage on device {{value}}', {
                                        value: o.deviceId.toString(),
                                    })}
                                </div>
                            )
                        })}
                    </RoleChecker>
                )}
            </Card.Body>
        </Card>
    )
}

export default TooltipContent
