import { sortBy } from 'lodash'
import { Interval } from 'luxon'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { GeneratedReport } from '@api/models'

import { gdynusaReadableInterval } from '@helpers/datetimeUtils'
import { formatInterval, Precision } from '@helpers/intervals'
import { useLocalizeDateTime } from '@helpers/timezoneConfig'

import ResponsiveTable from '@components/ResponsiveTable/ResponsiveTable'
import tableStyles from '@components/ResponsiveTable/ResponsiveTable.module.scss'
import { parseDate } from '@components/plots/common'

import DownloadButton from './DownloadButton'

interface Props {
    reports: GeneratedReport[]
    organizationId: number
}

const OccupancyReportList: React.FC<Props> = (props) => {
    const { t } = useTranslation()

    const reports = sortBy(props.reports, (r) => -parseDate(r.startingFrom).toMillis())

    const localize = useLocalizeDateTime()

    return (
        <ResponsiveTable className={tableStyles.horizontalTable}>
            <thead>
                <tr>
                    <th>{t('table.interval', 'Interval')}</th>
                    <th>{t('table.actions', 'Actions')}</th>
                </tr>
            </thead>
            <tbody>
                {reports.map((report) => (
                    <tr key={report.reportId}>
                        <td>
                            {formatInterval(
                                gdynusaReadableInterval(
                                    Interval.fromDateTimes(
                                        localize(parseDate(report.startingFrom)),
                                        localize(parseDate(report.endingAt))
                                    )
                                ),
                                Precision.DAY
                            )}
                        </td>
                        <td>
                            <DownloadButton
                                organizationId={props.organizationId}
                                reportId={report.reportId}
                                text={t('button.downloadPDF', 'Download PDF')}
                            />
                        </td>
                    </tr>
                ))}
            </tbody>
        </ResponsiveTable>
    )
}

export default OccupancyReportList
