/* tslint:disable */
/* eslint-disable */
/**
 * Vividi Dashboard API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DeviceStatusModel,
    DeviceStatusModelFromJSON,
    DeviceStatusModelFromJSONTyped,
    DeviceStatusModelToJSON,
} from './DeviceStatusModel';

/**
 * 
 * @export
 * @interface DeviceDiagnostics
 */
export interface DeviceDiagnostics {
    /**
     * 
     * @type {DeviceStatusModel}
     * @memberof DeviceDiagnostics
     */
    lastStatus?: DeviceStatusModel;
    /**
     * 
     * @type {string}
     * @memberof DeviceDiagnostics
     */
    lastCaptureAt?: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceDiagnostics
     */
    lastConfigurationFetchAt?: string;
}

export function DeviceDiagnosticsFromJSON(json: any): DeviceDiagnostics {
    return DeviceDiagnosticsFromJSONTyped(json, false);
}

export function DeviceDiagnosticsFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeviceDiagnostics {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'lastStatus': !exists(json, 'last_status') ? undefined : DeviceStatusModelFromJSON(json['last_status']),
        'lastCaptureAt': !exists(json, 'last_capture_at') ? undefined : json['last_capture_at'],
        'lastConfigurationFetchAt': !exists(json, 'last_configuration_fetch_at') ? undefined : json['last_configuration_fetch_at'],
    };
}

export function DeviceDiagnosticsToJSON(value?: DeviceDiagnostics | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'last_status': DeviceStatusModelToJSON(value.lastStatus),
        'last_capture_at': value.lastCaptureAt,
        'last_configuration_fetch_at': value.lastConfigurationFetchAt,
    };
}

