/* tslint:disable */
/* eslint-disable */
/**
 * Vividi Dashboard API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Point,
    PointFromJSON,
    PointFromJSONTyped,
    PointToJSON,
} from './Point';

/**
 * 
 * @export
 * @interface VisitBoundary
 */
export interface VisitBoundary {
    /**
     * 
     * @type {number}
     * @memberof VisitBoundary
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof VisitBoundary
     */
    name: string;
    /**
     * 
     * @type {Array<Point>}
     * @memberof VisitBoundary
     */
    points: Array<Point>;
}

export function VisitBoundaryFromJSON(json: any): VisitBoundary {
    return VisitBoundaryFromJSONTyped(json, false);
}

export function VisitBoundaryFromJSONTyped(json: any, ignoreDiscriminator: boolean): VisitBoundary {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': json['name'],
        'points': ((json['points'] as Array<any>).map(PointFromJSON)),
    };
}

export function VisitBoundaryToJSON(value?: VisitBoundary | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'points': ((value.points as Array<any>).map(PointToJSON)),
    };
}

