import { PropertyName } from '@api/models'

const ageGroups: Array<[number, number]> = [
    [0, 17],
    [18, 24],
    [25, 34],
    [35, 44],
    [45, 54],
    [55, 64],
    [65, Infinity],
]

const ageGroupName = ([lower, upper]: [number, number]) => (isFinite(upper) ? `${lower}-${upper}` : `${lower}+`)

export const ageCategory = {
    propertyName: PropertyName.Age,
    brackets: ageGroups.map(([lower, upper]) => ({
        label: ageGroupName([lower, upper]),
        lowerBound: {
            value: lower,
            inclusive: true,
        },
        upperBound: isFinite(upper)
            ? {
                  value: upper,
                  inclusive: true,
              }
            : undefined,
    })),
}

export const isMaleCategory = {
    propertyName: PropertyName.IsMale,
    brackets: [
        {
            label: '0',
            lowerBound: {
                value: 0,
                inclusive: true,
            },
            upperBound: {
                value: 0.5,
                inclusive: false,
            },
        },
        {
            label: '1',
            lowerBound: {
                value: 0.5,
                inclusive: true,
            },
        },
    ],
}
