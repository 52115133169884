import classNames from 'classnames'
import { Dictionary, findIndex } from 'lodash'
import React, { useState, useRef, RefObject, Children } from 'react'
import { Row, Col } from 'react-bootstrap'

import { SceneResponse } from '@api/models'

import { calculatePolygonCenterAverage, edges } from '@helpers/points'
import { Size } from '@helpers/types'

import { FloorplanMappingPolygon } from '@components/FloorplanMapping/ScenesTypes'

import styles from './FloorplanMappingEditor.module.scss'
import { EditorDimensions } from './useSvgEditorData'

const pointLabels = ['A', 'B', 'C', 'D']

const ZoneArranger: React.FC = ({ children }) => {
    /**
     * Rearrange zone polygons, so that the active one is the last child of the parent, giving it the highest z-index
     */

    const childrenArray = Children.toArray(children)

    const selectedChild = findIndex(childrenArray, (child) => React.isValidElement(child) && child.props.isSelectedZone)

    if (selectedChild === -1) {
        return <>{children}</>
    }

    const rearranged = childrenArray.filter((_, index) => index !== selectedChild).concat(childrenArray[selectedChild])

    return <>{rearranged}</>
}

const Zone = (props: {
    key: number | string
    floorplanMapping: FloorplanMappingPolygon
    scenesInLocality: Dictionary<SceneResponse>
    isSelectedZone: boolean
    onPointDragStart: (pointIndex: number) => void
    onZoneDragStart: () => void
    svgRef: RefObject<SVGSVGElement>
    className: string
    editorDimensions: EditorDimensions
    onMouseOver: () => void
    onMouseOut: () => void
    isDeviceMappingPolygon?: boolean
}) => {
    const averageCenter = calculatePolygonCenterAverage(props.floorplanMapping.points)

    return (
        <g key={props.key} className={props.className} onMouseOut={props.onMouseOut} onMouseOver={props.onMouseOver}>
            <polygon
                className={props.isSelectedZone ? styles.zone : styles.zoneInactive}
                points={props.floorplanMapping.points.map((p) => `${p.x},${p.y}`).join(' ')}
                onMouseDown={(e) => {
                    e.preventDefault()
                    e.stopPropagation()

                    props.onZoneDragStart()
                }}
            />
            {edges(props.floorplanMapping.points).map(([a, b], edgeIndex) => (
                <line
                    // eslint-disable-next-line react/no-array-index-key
                    key={`line-${edgeIndex}`}
                    className={props.isSelectedZone ? styles.zoneEdge : styles.zoneEdgeInactive}
                    x1={a.x}
                    x2={b.x}
                    y1={a.y}
                    y2={b.y}
                    onMouseDown={(e) => {
                        if (!props.svgRef.current) {
                            return
                        }

                        e.preventDefault()
                        e.stopPropagation()

                        props.onPointDragStart(edgeIndex + 1)
                    }}
                />
            ))}
            {props.floorplanMapping.points.map((point, pointIndex) => (
                // eslint-disable-next-line
                <React.Fragment key={`${pointIndex}`}>
                    {props.isSelectedZone ? (
                        <>
                            <circle
                                // eslint-disable-next-line
                                key={`circle-${pointIndex}`}
                                className={styles.zoneDragHandle}
                                cx={point.x}
                                cy={point.y}
                                r={props.isDeviceMappingPolygon ? 15 : 12}
                                onMouseDown={(e) => {
                                    e.preventDefault()
                                    e.stopPropagation()

                                    props.onPointDragStart(pointIndex)
                                }}
                            />
                            <text
                                // eslint-disable-next-line
                                key={`device-${pointIndex}`}
                                alignmentBaseline="middle"
                                className={styles.svgText}
                                fontSize="14px"
                                stroke="black"
                                strokeWidth="1"
                                textAnchor="middle"
                                x={point.x}
                                y={point.y}
                                onMouseDown={(e) => {
                                    e.preventDefault()
                                    e.stopPropagation()
                                    props.onPointDragStart(pointIndex)
                                }}
                            >
                                {pointLabels[pointIndex]}
                            </text>
                        </>
                    ) : null}
                    {pointIndex === 0 ? (
                        <text
                            // eslint-disable-next-line
                            key={`label-${pointIndex}`}
                            alignmentBaseline="middle"
                            className={styles.svgText}
                            fontSize="14px"
                            stroke="black"
                            strokeWidth="1"
                            textAnchor="middle"
                            x={averageCenter.x}
                            y={averageCenter.y}
                            onMouseDown={(e) => {
                                e.preventDefault()
                                e.stopPropagation()
                                props.onPointDragStart(pointIndex)
                            }}
                        >
                            {props.scenesInLocality[props.floorplanMapping.sceneId]?.id}
                        </text>
                    ) : null}
                </React.Fragment>
            ))}
        </g>
    )
}

const EditorLayout = (props: { image: JSX.Element; overlay: JSX.Element }) => (
    <>
        <Row>
            <Col className={styles.editor} sm={12}>
                <div className={styles.editorImage}>{props.image}</div>
                <div className={styles.editorImage}>{props.overlay}</div>
            </Col>
        </Row>
    </>
)

interface Props {
    scenesInLocality: Dictionary<SceneResponse>
    mappingPolygons: FloorplanMappingPolygon[]
    onFloorplanMapChange: (mappingPolygons: FloorplanMappingPolygon[]) => void
    imageUrl: string
    imageSize: Size
    editorDimensions: EditorDimensions
    isDeviceMappingPolygon?: boolean
    onFloorplanSelect?: (deviceId: number) => void
}

const FloorplanMappingEditor = (props: Props) => {
    const [draggedPoint, setDraggedPoint] = useState<{
        zoneIndex: number
        pointIndex: number
    }>()
    const [draggedZone, setDraggedZone] = useState<number>()

    const [hoveredZone, setHoveredZone] = useState<number>()

    const svgRef = useRef<SVGSVGElement>(null)

    /**
     * All the scaling is managed here
     * On component input props are point values recalculated according to the dimensions
     * (with the correct scale) and vise versa.
     */

    const scaledZones = props.mappingPolygons.map((zones) => ({
        ...zones,
        points: zones.points.map((p) => {
            const scale = props.editorDimensions.scale ?? 1

            return { x: p.x * scale, y: p.y * scale }
        }),
    }))

    const handleChange = (scaledZones: FloorplanMappingPolygon[]) => {
        const zones = scaledZones.map((zones) => ({
            ...zones,
            points: zones.points.map((p) => {
                const scale = props.editorDimensions.scale ?? 1

                return { x: p.x * (1 / scale), y: p.y * (1 / scale) }
            }),
            isEdited: true,
        }))
        props.onFloorplanMapChange(zones)
    }

    return (
        <EditorLayout
            image={
                <img
                    alt=""
                    src={props.imageUrl}
                    style={{
                        width: props.editorDimensions.width,
                        height: props.editorDimensions.height,
                    }}
                />
            }
            overlay={
                <svg
                    ref={svgRef}
                    height={props.editorDimensions.height}
                    width={props.editorDimensions.width}
                    onContextMenu={(e) => e.preventDefault()}
                    onMouseLeave={() => {
                        setDraggedPoint(undefined)
                        setDraggedZone(undefined)
                    }}
                    onMouseMove={(e) => {
                        if (draggedPoint) {
                            const point = scaledZones[draggedPoint.zoneIndex].points[draggedPoint.pointIndex]

                            point.x += e.movementX
                            point.y += e.movementY
                            handleChange([...scaledZones])
                        }

                        if (draggedZone !== undefined) {
                            const points = scaledZones[draggedZone].points.map((point) => ({
                                x: point.x + e.movementX,
                                y: point.y + e.movementY,
                            }))

                            handleChange([
                                ...scaledZones.slice(0, draggedZone),
                                { ...scaledZones[draggedZone], points },
                                ...scaledZones.slice(draggedZone + 1),
                            ])
                        }
                    }}
                    onMouseUp={() => {
                        setDraggedPoint(undefined)
                        setDraggedZone(undefined)
                    }}
                >
                    <ZoneArranger>
                        {scaledZones.map((floorplanConfiguration, zoneIndex) => (
                            <Zone
                                key={floorplanConfiguration.sceneId}
                                className={classNames({
                                    [styles.activeZone]: zoneIndex === hoveredZone,
                                })}
                                editorDimensions={props.editorDimensions}
                                floorplanMapping={floorplanConfiguration}
                                isDeviceMappingPolygon={props.isDeviceMappingPolygon}
                                isSelectedZone={floorplanConfiguration.isSelected}
                                scenesInLocality={props.scenesInLocality}
                                svgRef={svgRef}
                                onMouseOut={() => setHoveredZone(undefined)}
                                onMouseOver={() => setHoveredZone(zoneIndex)}
                                onPointDragStart={(pointIndex) => {
                                    if (props.onFloorplanSelect) {
                                        props.onFloorplanSelect(floorplanConfiguration.sceneId)
                                    }
                                    setDraggedPoint({
                                        zoneIndex,
                                        pointIndex,
                                    })
                                }}
                                onZoneDragStart={() => {
                                    if (props.onFloorplanSelect) {
                                        props.onFloorplanSelect(floorplanConfiguration.sceneId)
                                    }
                                    setDraggedZone(zoneIndex)
                                }}
                            />
                        ))}
                    </ZoneArranger>
                </svg>
            }
        />
    )
}

export default FloorplanMappingEditor
