import { range, sum } from 'lodash'
import { Interval } from 'luxon'

import { IntervalPropertyBreakdown, PropertyName } from '@api'

import { parseInterval } from '@components/StatisticsSummary/utils'

export interface EmotionDataItem {
    totalEmotionScore: number
    totalObservations: number
    positiveObservations: number
    negativeObservations: number
    neutralObservations: number
    interval?: Interval
    openedPortion?: number
}

export const prepareEmotionDataItem = ({
    items,
    startingFrom,
    endingAt,
    openedPortion,
}: IntervalPropertyBreakdown & { openedPortion: number }): EmotionDataItem => {
    const summary = Object.fromEntries(
        range(1, 11).map((score) => [
            score.toString(),
            sum(
                items
                    .filter((it) => it.brackets[PropertyName.EmotionScore] === score.toString())
                    .map((it) => it.numberOfVisits)
            ),
        ])
    )

    return {
        totalEmotionScore: sum(Object.entries(summary).map(([score, visitors]) => Number(score) * visitors)),
        totalObservations: sum(Object.values(summary)),
        positiveObservations: summary['8'] + summary['9'] + summary['10'],
        neutralObservations: summary['4'] + summary['5'] + summary['6'] + summary['7'],
        negativeObservations: summary['1'] + summary['2'] + summary['3'],
        interval: startingFrom && endingAt ? parseInterval({ startingFrom, endingAt }) : undefined,
        openedPortion,
    }
}

export const sumEmotionDataItems = (a: EmotionDataItem, b: EmotionDataItem): EmotionDataItem => ({
    totalEmotionScore: a.totalEmotionScore + b.totalEmotionScore,
    totalObservations: a.totalObservations + b.totalObservations,
    positiveObservations: a.positiveObservations + b.positiveObservations,
    neutralObservations: a.neutralObservations + b.neutralObservations,
    negativeObservations: a.negativeObservations + b.negativeObservations,
})

export const emptyEmotionDataItem = {
    totalEmotionScore: 0,
    totalObservations: 0,
    positiveObservations: 0,
    neutralObservations: 0,
    negativeObservations: 0,
}
