import classNames from 'classnames'
import React from 'react'

import styles from './TooltipTable.module.scss'

const Item: React.FC<{
    label: string
    children: React.ReactNode | React.ReactNode[]
    total?: boolean
}> = ({ label, children, total = false }) => (
    <>
        <dt className={classNames({ [styles.total]: total })}>{label}</dt>
        <dd className={classNames({ [styles.total]: total })}>{children}</dd>
    </>
)

const TooltipTable: React.FC<{
    children: React.ReactNode | React.ReactNode[]
}> = ({ children }) => {
    return <dl className={styles.tooltipTable}>{children}</dl>
}
;(TooltipTable as any).Item = Item
export default TooltipTable as typeof TooltipTable & { Item: typeof Item }
