import React from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'

import { VIVIDI_APP } from '@helpers/VividiURLs'

import PageContentLayout from '@components/Layouts/PageContentLayout'
import PasswordResetForm from '@components/PasswordResetForm/PasswordResetForm'

const PasswordResetPage = () => {
    const { t } = useTranslation()
    const { token } = useParams<{ token: string }>()
    const documentTitle = t('title.resetPassword', 'Reset password')

    const heading = t('heading.setNewPassword', 'Set a New Password')

    return (
        <PageContentLayout documentTitle={documentTitle} heading={heading}>
            <PasswordResetForm redirectPath={VIVIDI_APP} token={token} />
        </PageContentLayout>
    )
}

export default PasswordResetPage
