import { LocationDescriptorObject } from 'history'
import { useDebugValue } from 'react'
import { useQuery, UseQueryResult } from 'react-query'
import { useLocation, matchPath, useHistory } from 'react-router'

import { ProfileResponse } from '@api/models'

import { profileApi } from '@services'

import vividiRoutes from '../routes/vividiRoutes' // eslint-disable-line replace-relative-imports/replace

import { LOGIN_PATH } from './VividiURLs'
import { isApiError } from './apiCallManipulation'
import { useAuthContext } from './auth'
import { useIsPreRendering } from './preRendering'

const useProfile = (loginPath: string = LOGIN_PATH, suspense = false): UseQueryResult<ProfileResponse> => {
    const location = useLocation()
    const history = useHistory()
    const isPreRendering = useIsPreRendering()

    const authContext = useAuthContext()
    const isAuthenticated = Boolean(authContext)

    const profileCall = useQuery({
        queryKey: [...profileApi.getProfile.queryKey(), authContext?.accessToken ?? ''],
        queryFn: profileApi.getProfile,
        suspense,
    })

    useDebugValue(
        profileCall.status === 'idle'
            ? 'Not started fetching'
            : profileCall.status === 'loading'
            ? 'Loading'
            : profileCall.status === 'error'
            ? 'Fetching failed'
            : `${profileCall.data.email} (${profileCall.data.role})`
    )

    // If the current page requires authentication and the user is not authenticated, redirect to the login page
    const matchingRoute = vividiRoutes.find((route) => matchPath(location.pathname, route))

    const isHTTPUnauthenticated =
        profileCall.status === 'error' && isApiError(profileCall.error) && profileCall.error.status === 401

    if (!matchingRoute?.isNotSecured && (!isAuthenticated || isHTTPUnauthenticated) && !isPreRendering) {
        history.replace(loginPath, {
            backLink: {
                pathname: location.pathname,
                search: location.search,
                hash: location.hash,
            } as LocationDescriptorObject,
        })
    }

    return profileCall
}

export default useProfile
