/* tslint:disable */
/* eslint-disable */
/**
 * Vividi Dashboard API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DeviceStatusInformation,
    DeviceStatusInformationFromJSON,
    DeviceStatusInformationFromJSONTyped,
    DeviceStatusInformationToJSON,
} from './DeviceStatusInformation';

/**
 * 
 * @export
 * @interface DeviceStatusResponse
 */
export interface DeviceStatusResponse {
    /**
     * 
     * @type {boolean}
     * @memberof DeviceStatusResponse
     */
    isConnected: boolean;
    /**
     * 
     * @type {DeviceStatusInformation}
     * @memberof DeviceStatusResponse
     */
    status?: DeviceStatusInformation;
}

export function DeviceStatusResponseFromJSON(json: any): DeviceStatusResponse {
    return DeviceStatusResponseFromJSONTyped(json, false);
}

export function DeviceStatusResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeviceStatusResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'isConnected': json['is_connected'],
        'status': !exists(json, 'status') ? undefined : DeviceStatusInformationFromJSON(json['status']),
    };
}

export function DeviceStatusResponseToJSON(value?: DeviceStatusResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'is_connected': value.isConnected,
        'status': DeviceStatusInformationToJSON(value.status),
    };
}

