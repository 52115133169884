/* tslint:disable */
/* eslint-disable */
/**
 * Vividi Dashboard API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IntervalPropertyBreakdownByBoundary,
    IntervalPropertyBreakdownByBoundaryFromJSON,
    IntervalPropertyBreakdownByBoundaryFromJSONTyped,
    IntervalPropertyBreakdownByBoundaryToJSON,
} from './IntervalPropertyBreakdownByBoundary';

/**
 * 
 * @export
 * @interface PropertyBreakdownByBoundaryResponse
 */
export interface PropertyBreakdownByBoundaryResponse {
    /**
     * Statistics items for boundaries configured as footfall entrances for the locality
     * @type {Array<IntervalPropertyBreakdownByBoundary>}
     * @memberof PropertyBreakdownByBoundaryResponse
     */
    footfallItems: Array<IntervalPropertyBreakdownByBoundary>;
    /**
     * Statistics items for boundaries other than those configured as footfall entrances for the locality
     * @type {Array<IntervalPropertyBreakdownByBoundary>}
     * @memberof PropertyBreakdownByBoundaryResponse
     */
    nonFootfallItems: Array<IntervalPropertyBreakdownByBoundary>;
}

export function PropertyBreakdownByBoundaryResponseFromJSON(json: any): PropertyBreakdownByBoundaryResponse {
    return PropertyBreakdownByBoundaryResponseFromJSONTyped(json, false);
}

export function PropertyBreakdownByBoundaryResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): PropertyBreakdownByBoundaryResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'footfallItems': ((json['footfall_items'] as Array<any>).map(IntervalPropertyBreakdownByBoundaryFromJSON)),
        'nonFootfallItems': ((json['non_footfall_items'] as Array<any>).map(IntervalPropertyBreakdownByBoundaryFromJSON)),
    };
}

export function PropertyBreakdownByBoundaryResponseToJSON(value?: PropertyBreakdownByBoundaryResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'footfall_items': ((value.footfallItems as Array<any>).map(IntervalPropertyBreakdownByBoundaryToJSON)),
        'non_footfall_items': ((value.nonFootfallItems as Array<any>).map(IntervalPropertyBreakdownByBoundaryToJSON)),
    };
}

