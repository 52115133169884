import { sortBy } from 'lodash'

import { DeviceResponse, LocalityNameModel, OrganizationResponse, SceneResponse } from '@api'

import { CellContent } from '@elements/Table/TableWithSorting'

export const orderBy = <T, K extends keyof T>(items: T[], key: K): T[] => {
    if (items.some((it) => typeof it[key] !== 'string')) {
        throw new Error(`Cannot sort by non-string field ${key}`)
    }

    return [...items].sort((a, b) =>
        ((a as any)[key] as string)
            .toUpperCase()
            .localeCompare(((b as any)[key] as string).toUpperCase(), undefined, { numeric: true })
    )
}

export const orderDevices = (devices: DeviceResponse[]): DeviceResponse[] => sortBy(devices, 'id')
export const orderScenes = (scenes: SceneResponse[]): SceneResponse[] => orderBy(scenes, 'label')
export const orderLocalities = <T extends LocalityNameModel>(localities: T[]): T[] => orderBy(localities, 'name')
export const orderOrganizations = (organizations: OrganizationResponse[]): OrganizationResponse[] =>
    orderBy(organizations, 'name')

export const stableSort = <T>(array: T[], comparator: (a: T, b: T) => number) => {
    const stabilized = array.map((el, index) => [el, index] as [T, number])
    stabilized.sort((a, b) => {
        const order = comparator(a[0], b[0])

        if (order !== 0) return order

        return a[1] - b[1]
    })

    return stabilized.map((el) => el[0])
}

export const orderTableRowsByProperty = (
    a: {
        [key: string]: CellContent
    },
    b: {
        [key: string]: CellContent
    },
    property: string
) => {
    if (typeof a[property].content === 'string' && typeof b[property].content === 'string') {
        return String(b[property].content).toUpperCase().localeCompare(String(a[property].content).toUpperCase())
    }

    if ((b[property].content ?? 0) < (a[property].content ?? 0)) {
        return -1
    }

    if ((b[property].content ?? 0) > (a[property].content ?? 0)) {
        return 1
    }

    return 0
}
