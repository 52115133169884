import classNames from 'classnames'
import React from 'react'
import { Form, Row, Col, Button } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { MutationStatus } from 'react-query'
import { useHistory } from 'react-router'

import { DeviceResponse } from '@api'

import AsyncButton from '@components/AsyncButton'
import DevicePicker from '@components/DevicePicker'
import { LayoutHeading } from '@components/Layouts/LayoutHeading'

import styles from './AddDeviceForm.module.scss'

const AddDeviceForm: React.FC<{
    assignableDevices: Array<DeviceResponse>
    submissionStatus: MutationStatus
    selectedPickerDevice: DeviceResponse | undefined
    onAddDevice: () => void
    onDeviceSelect: (device: DeviceResponse) => void
}> = ({ assignableDevices, submissionStatus, selectedPickerDevice, onAddDevice, onDeviceSelect }) => {
    const { t } = useTranslation()
    const history = useHistory()

    return (
        <>
            <LayoutHeading heading={t('form.newDeviceForm', 'New device form')} headingType="section" />
            <Form>
                <Row>
                    <Col className={styles.column} md={3} sm={12}>
                        <DevicePicker
                            devices={assignableDevices}
                            selectedDevice={selectedPickerDevice}
                            onSelect={onDeviceSelect}
                        />
                    </Col>
                    <Col className={classNames(styles.column, styles.actionButtonsContainer)} md={9} sm={12}>
                        <AsyncButton
                            disabled={selectedPickerDevice === undefined}
                            status={submissionStatus}
                            text={t('form.confirm', 'Confirm')}
                            variant="primary"
                            onClick={onAddDevice}
                        />
                        <Button variant="secondary" onClick={() => history.goBack()}>
                            {t('button.cancel', 'Cancel')}
                        </Button>
                    </Col>
                </Row>
            </Form>
        </>
    )
}

export default AddDeviceForm
