/* tslint:disable */
/* eslint-disable */
/**
 * Vividi Dashboard API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ZoneOccupancySession
 */
export interface ZoneOccupancySession {
    /**
     * 
     * @type {string}
     * @memberof ZoneOccupancySession
     */
    startingFrom: string;
    /**
     * 
     * @type {string}
     * @memberof ZoneOccupancySession
     */
    endingAt: string;
    /**
     * 
     * @type {number}
     * @memberof ZoneOccupancySession
     */
    zoneId: number;
    /**
     * 
     * @type {string}
     * @memberof ZoneOccupancySession
     */
    zoneName: string;
    /**
     * 
     * @type {number}
     * @memberof ZoneOccupancySession
     */
    averagePersons?: number;
    /**
     * 
     * @type {number}
     * @memberof ZoneOccupancySession
     */
    maxPersons?: number;
}

export function ZoneOccupancySessionFromJSON(json: any): ZoneOccupancySession {
    return ZoneOccupancySessionFromJSONTyped(json, false);
}

export function ZoneOccupancySessionFromJSONTyped(json: any, ignoreDiscriminator: boolean): ZoneOccupancySession {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'startingFrom': json['starting_from'],
        'endingAt': json['ending_at'],
        'zoneId': json['zone_id'],
        'zoneName': json['zone_name'],
        'averagePersons': !exists(json, 'average_persons') ? undefined : json['average_persons'],
        'maxPersons': !exists(json, 'max_persons') ? undefined : json['max_persons'],
    };
}

export function ZoneOccupancySessionToJSON(value?: ZoneOccupancySession | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'starting_from': value.startingFrom,
        'ending_at': value.endingAt,
        'zone_id': value.zoneId,
        'zone_name': value.zoneName,
        'average_persons': value.averagePersons,
        'max_persons': value.maxPersons,
    };
}

