/* tslint:disable */
/* eslint-disable */
/**
 * Vividi Dashboard API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * An enumeration.
 * @export
 * @enum {string}
 */
export enum CategorySet {
    Empty = 'EMPTY',
    Age = 'AGE',
    Sex = 'SEX',
    EmotionScore = 'EMOTION_SCORE',
    SexAge = 'SEX_AGE'
}

export function CategorySetFromJSON(json: any): CategorySet {
    return CategorySetFromJSONTyped(json, false);
}

export function CategorySetFromJSONTyped(json: any, ignoreDiscriminator: boolean): CategorySet {
    return json as CategorySet;
}

export function CategorySetToJSON(value?: CategorySet | null): any {
    return value as any;
}

