import { faDownload } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { isEmpty, omitBy, round } from 'lodash'
import { DateTime, Interval } from 'luxon'
import React from 'react'
import { Button } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import {
    LocalityResponse,
    MovementByBoundaryResponse,
    PropertiesByBoundaryResponse,
    IntervalWeather,
    PropertyBreakdownByBoundaryResponse,
    VisitBoundaryWithId,
    SceneListResponse,
} from '@api'

import { gdynusaReadableInterval } from '@helpers/datetimeUtils'
import { displayWeather, displayTemperature } from '@helpers/displayUtils'
import { GoogleEventName } from '@helpers/gtag'
import { formatIntervalToFilename } from '@helpers/intervals'
import { useLocalizeDateTime } from '@helpers/timezoneConfig'
import { ExportData, exportToExcel } from '@helpers/xlsxExporter'

import { useNotify } from '@components/notifications/NotificationsContext'

import { pickVisitorsByBoundary } from './FootfallDetailsContent'
import {
    prepareGenderAgeBreakdown,
    prepareMovementData,
    preparePropertiesPerBoundary,
} from './footfallDetailsPreprocessors'

interface ExportButtonProps {
    selectedLocality: LocalityResponse
    selectedBoundaries?: VisitBoundaryWithId[]
    propertiesByBoundary: PropertiesByBoundaryResponse
    scenes: SceneListResponse
    visitorsByBoundary: MovementByBoundaryResponse
    genderAgeBreakdown: PropertyBreakdownByBoundaryResponse
    weather?: Array<IntervalWeather>
    now: DateTime
    pickedInterval: Interval
}

export const FootfallDetailExportButton: React.FC<ExportButtonProps> = ({
    selectedLocality,
    selectedBoundaries,
    propertiesByBoundary,
    scenes,
    visitorsByBoundary,
    genderAgeBreakdown,
    now,
    pickedInterval,
    weather,
}) => {
    const { t } = useTranslation()

    const localize = useLocalizeDateTime()
    const notify = useNotify()

    let allBoundaries: ExportData = {}
    let footfallMovement: ExportData = {}

    const footfallBoundaries = pickVisitorsByBoundary(propertiesByBoundary.footfallItems, selectedBoundaries)
    const nonFootfallBoundaries = pickVisitorsByBoundary(propertiesByBoundary.nonFootfallItems, selectedBoundaries)
    const footfallVisitorBoundaries = pickVisitorsByBoundary(visitorsByBoundary.footfallItems, selectedBoundaries)
    const nonFootfallVisitorBoundaries = pickVisitorsByBoundary(visitorsByBoundary.nonFootfallItems, selectedBoundaries)

    const footfallBoundariesExportData = {
        [t('footfall.footfallBoundaries', 'Footfall boundaries breakdown')]: preparePropertiesPerBoundary(
            footfallBoundaries,
            footfallVisitorBoundaries,
            scenes
        ).map((it) => {
            const granularity =
                it.granularity === 'Daily'
                    ? {
                          [t('footfall.dailyVisitors', 'Daily avg. visitors')]: round(it.granularVisitors),
                      }
                    : {
                          [t('footfall.hourlyVisitors', 'Hourly avg. visitors')]: round(it.granularVisitors),
                      }

            return {
                [t('table.scene', 'Scene')]: it.scene,
                [t('footfall.visitBoundary', 'Visit boundary')]:
                    it.visitBoundary !== '' ? it.visitBoundary : t('others.notSet', 'Not set'),
                [t('statistics.totalVisitors', 'Total visitors')]: it.totalVisitors,
                ...granularity,
                [t('footfall.male', 'Male %')]: it.male,
                [t('footfall.female', 'Female %')]: it.female,
                [t('footfall.mostCommonAge', 'Most common age')]: it.mostCommonAge,
            }
        }),
    }

    const nonFootfallBoundariesExportData = {
        [t('footfall.nonFootfallBoundaries', 'Non-footfall boundaries breakdown')]: preparePropertiesPerBoundary(
            nonFootfallBoundaries,
            nonFootfallVisitorBoundaries,
            scenes
        ).map((it) => {
            const granularity =
                it.granularity === 'Daily'
                    ? {
                          [t('footfall.dailyVisitors', 'Daily avg. visitors')]: round(it.granularVisitors),
                      }
                    : {
                          [t('footfall.hourlyVisitors', 'Hourly avg. visitors')]: round(it.granularVisitors),
                      }

            return {
                [t('table.device', 'Device')]: it.scene,
                [t('footfall.visitBoundary', 'Visit boundary')]:
                    it.visitBoundary !== '' ? it.visitBoundary : t('others.notSet', 'Not set'),
                [t('statistics.totalVisitors', 'Total visitors')]: it.totalVisitors,
                ...granularity,
                [t('footfall.male', 'Male %')]: it.male,
                [t('footfall.female', 'Female %')]: it.female,
                [t('footfall.mostCommonAge', 'Most common age')]: it.mostCommonAge,
            }
        }),
    }

    if (!isEmpty(footfallBoundaries) && !isEmpty(footfallVisitorBoundaries)) {
        allBoundaries = {
            ...footfallBoundariesExportData,
        }
    }

    if (!isEmpty(nonFootfallBoundaries) && !isEmpty(nonFootfallVisitorBoundaries)) {
        allBoundaries = {
            ...allBoundaries,
            ...nonFootfallBoundariesExportData,
        }
    }

    const exportData = prepareMovementData(footfallVisitorBoundaries, now, weather)

    const containsWeatherData = exportData.some((it) => it.weather?.weather)

    const containsTemperatureData = exportData.some((it) => it.weather?.temperature !== undefined)

    const localityVisitorsInTimeTitle = t(
        'footfall.localityVisitorsInTime',
        `${selectedLocality?.name} visitors in time`,
        {
            locality: selectedLocality?.name,
        }
    )

    if (!isEmpty(footfallVisitorBoundaries)) {
        footfallMovement = {
            [localityVisitorsInTimeTitle]: exportData.map((it) => ({
                [t('others.time', 'Time')]: localize(it.interval.start).toLocaleString({
                    ...DateTime.DATETIME_SHORT,
                    weekday: 'short',
                }),
                [t('footfall.incoming', 'Incoming')]: it.incoming ?? 0,
                [t('footfall.outgoing', 'Outgoing')]: it.outgoing ?? 0,
                [t('footfall.weather', 'Weather')]: it.weather?.weather ? displayWeather(it.weather?.weather) : '',
                [t('footfall.temperature', 'Temperature')]:
                    it.weather?.temperature !== undefined ? displayTemperature(it.weather?.temperature) : '',
            })),
        }

        if (!containsWeatherData || !containsTemperatureData) {
            footfallMovement[localityVisitorsInTimeTitle] = footfallMovement[localityVisitorsInTimeTitle].map((it) =>
                omitBy(
                    it,
                    (_, key) =>
                        (key === t('footfall.weather', 'Weather') && !containsWeatherData) ||
                        (key === t('footfall.temperature', 'Temperature') && !containsTemperatureData)
                )
            )
        }
    }

    const genderAgeBreakdownExport: ExportData = {
        [t('statistics.visitorDemographics', 'Visitor demographics')]: prepareGenderAgeBreakdown(
            genderAgeBreakdown
        ).map((it) => ({
            [t('statistics.ageGroup', ' age group ')]: it.ageGroup,
            [t('statistics.menAgeGroup', 'Men in age group %')]: round(100 * it.maleRatio, 1),
            [t('statistics.womenAgeGroup', 'Women in age group %')]: round(100 * it.femaleRatio, 1),
            [t('statistics.ageGroupShare', 'Age group share %')]: round(100 * it.ageGroupToTotalRatio, 1),
        })),
    }

    const handleDownload = () => {
        const exportData = {
            ...footfallMovement,
            ...allBoundaries,
            ...genderAgeBreakdownExport,
        }

        if (!isEmpty(exportData)) {
            exportToExcel(
                exportData,
                `${selectedLocality?.name}-footfall-details-${formatIntervalToFilename(
                    gdynusaReadableInterval(pickedInterval.mapEndpoints(localize))
                )}`,
                GoogleEventName.DOWNLOAD_FOOTFALL_LOCALITY_EXCEL
            )
        } else {
            notify({
                title: t('notification.error', 'Error'),
                content: t('notification.nothingToExport', 'Nothing to export'),
                variant: 'danger',
            })
        }
    }

    return (
        <Button variant="secondary" onClick={handleDownload}>
            <FontAwesomeIcon icon={faDownload} />
            {t('button.downloadExcel', 'Download Excel')}
        </Button>
    )
}
