/**
 * Vividi Dashboard API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime'
import {UseQueryOptions, QueryKey} from 'react-query'

import {
    LocalityWeatherRequestBody,
    LocalityWeatherRequestBodyFromJSON,
    LocalityWeatherRequestBodyToJSON,
    LocalityWeatherResponse,
    LocalityWeatherResponseFromJSON,
    LocalityWeatherResponseToJSON,
} from '../models';

export interface GetLocalityWeatherRequest {
    localityId: number;
    body: LocalityWeatherRequestBody;
}


/**
 * 
 */
export class WeatherApi extends runtime.BaseAPI {
    readonly name = 'WeatherApi'

    /**
     * Retrieve weather information for a locality.
     */
    getLocalityWeather = Object.assign(
        async (requestParameters: GetLocalityWeatherRequest, initOverrides?: RequestInit): Promise<LocalityWeatherResponse> => {
            const response = await this.getLocalityWeather.invokeRaw(requestParameters, initOverrides);
            return await response.value();
        },
        {
            invokeRaw: async (requestParameters: GetLocalityWeatherRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<LocalityWeatherResponse>> => {
                if (requestParameters.localityId === null || requestParameters.localityId === undefined) {
                    throw new runtime.RequiredError('localityId','Required parameter requestParameters.localityId was null or undefined when calling getLocalityWeather.');
                }
            
                if (requestParameters.body === null || requestParameters.body === undefined) {
                    throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling getLocalityWeather.');
                }
            
                const queryParameters: any = {};
            
                const headerParameters: runtime.HTTPHeaders = {};
            
                headerParameters['Content-Type'] = 'application/json';
            
                if (this.configuration && this.configuration.accessToken) {
                    const token = this.configuration.accessToken;
                    const tokenString = await token("token", []);
            
                    if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                    }
                }
                const response = await this.request({
                    path: `/api/weather/by-locality/{locality_id}`.replace(`{${"locality_id"}}`, encodeURIComponent(String(requestParameters.localityId))),
                    method: 'POST',
                    headers: headerParameters,
                    query: queryParameters,
                    body: LocalityWeatherRequestBodyToJSON(requestParameters.body),
                }, initOverrides);
            
                return new runtime.JSONApiResponse(response, (jsonValue) => LocalityWeatherResponseFromJSON(jsonValue));
            },
            mutationKey: [
                'WeatherApi',
                'getLocalityWeather', 
            ],
            queryKey: (requestParameters: GetLocalityWeatherRequest, initOverrides?: RequestInit) => ([
                    ...this.getLocalityWeather.mutationKey, 
                {
                localityId: requestParameters.localityId,
                body: requestParameters.body,
                }
            ]),
            query: (requestParameters: GetLocalityWeatherRequest, initOverrides?: RequestInit): UseQueryOptions<LocalityWeatherResponse> & {queryKey: QueryKey} => ({
                queryFn: () => this.getLocalityWeather(requestParameters, initOverrides),
                queryKey: this.getLocalityWeather.queryKey(requestParameters, initOverrides)
            })
        }
    )

}
