import { uniqueId } from 'lodash'
import React, { useMemo } from 'react'
import { Dropdown } from 'react-bootstrap'

import { LocalityResponse } from '@api'

interface Props {
    onSelect: (locality: LocalityResponse) => void
    localities: LocalityResponse[]
    selectedLocality: LocalityResponse
}

const LocalityDropdown: React.FC<Props> = ({ onSelect, localities, selectedLocality }) => {
    const id = useMemo(uniqueId, [])

    return (
        <Dropdown
            onSelect={(eventKey) => {
                if (eventKey === null) {
                    return
                }

                const selected = localities.find((it) => it.id === Number(eventKey))

                if (selected !== undefined) {
                    onSelect(selected)
                }
            }}
        >
            <Dropdown.Toggle id={id} variant="secondary">
                <span>
                    <b>{selectedLocality.name}</b>
                </span>
            </Dropdown.Toggle>
            <Dropdown.Menu>
                {localities.map(({ id, name }) => (
                    <Dropdown.Item key={`locality-${id}`} eventKey={String(id)}>
                        {name}
                    </Dropdown.Item>
                ))}
            </Dropdown.Menu>
        </Dropdown>
    )
}

export default LocalityDropdown
