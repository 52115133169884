/* tslint:disable */
/* eslint-disable */
/**
 * Vividi Dashboard API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    LocalityConfigurationStateModel,
    LocalityConfigurationStateModelFromJSON,
    LocalityConfigurationStateModelFromJSONTyped,
    LocalityConfigurationStateModelToJSON,
} from './LocalityConfigurationStateModel';

/**
 * 
 * @export
 * @interface LocalityConfigurationStateListResponse
 */
export interface LocalityConfigurationStateListResponse {
    /**
     * 
     * @type {Array<LocalityConfigurationStateModel>}
     * @memberof LocalityConfigurationStateListResponse
     */
    localities: Array<LocalityConfigurationStateModel>;
}

export function LocalityConfigurationStateListResponseFromJSON(json: any): LocalityConfigurationStateListResponse {
    return LocalityConfigurationStateListResponseFromJSONTyped(json, false);
}

export function LocalityConfigurationStateListResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): LocalityConfigurationStateListResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'localities': ((json['localities'] as Array<any>).map(LocalityConfigurationStateModelFromJSON)),
    };
}

export function LocalityConfigurationStateListResponseToJSON(value?: LocalityConfigurationStateListResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'localities': ((value.localities as Array<any>).map(LocalityConfigurationStateModelToJSON)),
    };
}

