import { faTrash, faPlusCircle, faClone } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon as FAIcon } from '@fortawesome/react-fontawesome'
import { DateTime } from 'luxon'
import React from 'react'
import { Col, Badge, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { Trans, useTranslation } from 'react-i18next'

import { OpeningHoursInterval } from '@api'

import { displayTranslatedDayInWeek } from '@helpers/translateUtils'

import MultiRangeSlider from './MultiRangeSlider'
import { DaysInWeekShortened } from './OpeningHoursPicker'
import styles from './OpeningHoursPicker.module.scss'

interface Props {
    dayInWeek: DaysInWeekShortened
    openingHours: OpeningHoursInterval[]
    onPreviousDayDataClone: (day: DaysInWeekShortened) => void
    onIntervalAdd: (day: DaysInWeekShortened) => void
    onIntervalDelete: (day: DaysInWeekShortened, index?: number) => void
    onChange: (openingHours: OpeningHoursInterval[], day: DaysInWeekShortened) => void
}

const OpeningHoursPerDayPicker: React.FC<Props> = (props) => {
    const { t } = useTranslation()

    const handleChange = (openingHours: OpeningHoursInterval[]) => {
        props.onChange(
            openingHours.map((openingHoursInterval) => {
                if (openingHoursInterval.closingTime.hour === 24) {
                    return {
                        ...openingHoursInterval,
                        closingTime: { hour: 23, minute: 59 },
                    }
                }

                return openingHoursInterval
            }),
            props.dayInWeek
        )
    }

    const getPreviousDayName = (day: DaysInWeekShortened) => {
        const dayIndex = Object.values(DaysInWeekShortened).findIndex((weekDay) => weekDay === day)

        if (dayIndex === 0) {
            return displayTranslatedDayInWeek(
                Object.values(DaysInWeekShortened)[Object.values(DaysInWeekShortened).length - 1],
                t
            )
        }

        return displayTranslatedDayInWeek(Object.values(DaysInWeekShortened)[dayIndex - 1], t)
    }

    return (
        <>
            <Col className={styles.noRightPadding} md={1} sm={12}>
                <div className={styles.center}>{displayTranslatedDayInWeek(props.dayInWeek, t)}</div>
            </Col>
            <Col className={styles.noRightPadding} md={8} sm={12}>
                <div className={styles.sliderWrapper}>
                    <OverlayTrigger
                        overlay={
                            <Tooltip id="add-new-interval-tooltip">
                                <Trans i18nKey="tooltip.addNewInterval">
                                    Add <strong>new</strong> interval
                                </Trans>
                            </Tooltip>
                        }
                        placement="bottom"
                    >
                        <Badge
                            className={styles.addRangeBadge}
                            variant="success"
                            onClick={() => props.onIntervalAdd(props.dayInWeek)}
                        >
                            <FAIcon icon={faPlusCircle} />
                        </Badge>
                    </OverlayTrigger>
                    <MultiRangeSlider openingHours={props.openingHours ?? []} onOpeningHoursChange={handleChange} />
                    <OverlayTrigger
                        overlay={
                            <Tooltip id="add-new-interval-tooltip">
                                <Trans i18nKey="tooltip.copyPreviousDay">
                                    Copy <strong>previous</strong> day (
                                </Trans>
                                {getPreviousDayName(props.dayInWeek)})
                            </Tooltip>
                        }
                        placement="bottom"
                    >
                        <Badge
                            className={styles.addRangeBadge}
                            variant="warning"
                            onClick={() => props.onPreviousDayDataClone(props.dayInWeek)}
                        >
                            <FAIcon icon={faClone} />
                        </Badge>
                    </OverlayTrigger>
                </div>
            </Col>
            <Col className={styles.timeIntervalsTable} md={3} sm={12}>
                {props.openingHours.map((hours, index) => (
                    <div
                        key={`${props.dayInWeek}-${hours.openingTime.hour}-${hours.closingTime.minute}`}
                        className={styles.timeRangeWrapper}
                    >
                        <div className={styles.timeRange}>
                            <div className={styles.center}>
                                {DateTime.local()
                                    .set({
                                        hour: hours.openingTime.hour,
                                        minute: hours.openingTime.minute,
                                    })
                                    .toFormat('HH:mm')}
                            </div>
                        </div>
                        <div className={styles.timeRange}>-</div>
                        <div className={styles.timeRange}>
                            <div className={styles.center}>
                                {DateTime.local()
                                    .set({
                                        hour: hours.closingTime.hour,
                                        minute: hours.closingTime.minute,
                                    })
                                    .toFormat('HH:mm')}
                            </div>
                        </div>
                        <div className={styles.timeRange}>
                            <Badge
                                className={styles.addRangeBadge}
                                variant="danger"
                                onClick={() => props.onIntervalDelete(props.dayInWeek, index)}
                            >
                                <FAIcon icon={faTrash} />
                            </Badge>
                        </div>
                    </div>
                ))}
            </Col>
        </>
    )
}

export default OpeningHoursPerDayPicker
