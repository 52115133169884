/* tslint:disable */
/* eslint-disable */
/**
 * Vividi Dashboard API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EmotionConfiguration
 */
export interface EmotionConfiguration {
    /**
     * Zones to use for emotion analysis and statistics.
     * @type {Array<number>}
     * @memberof EmotionConfiguration
     */
    emotionZones: Array<number>;
}

export function EmotionConfigurationFromJSON(json: any): EmotionConfiguration {
    return EmotionConfigurationFromJSONTyped(json, false);
}

export function EmotionConfigurationFromJSONTyped(json: any, ignoreDiscriminator: boolean): EmotionConfiguration {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'emotionZones': json['emotion_zones'],
    };
}

export function EmotionConfigurationToJSON(value?: EmotionConfiguration | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'emotion_zones': value.emotionZones,
    };
}

