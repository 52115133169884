import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { MutationStatus, useMutation, useQuery } from 'react-query'
import { useHistory, useParams } from 'react-router'

import { LocalityModel, UserResponse } from '@api/models'

import { deviceApi, localityApi, organizationApi, userApi } from '@services'

import { generateEditLocalityPath, LocalityEditTabs } from '@helpers/VividiURLs'
import { useApiCallCleaner } from '@helpers/api'
import { mergeQueryResults } from '@helpers/api'

import PageContentLayout from '@components/Layouts/PageContentLayout'
import LegacyLoadingWrapper from '@components/LegacyLoadingWrapper'
import LocalityEditForm from '@components/LocalityEditForm'

const CreateLocalityPage = () => {
    const { t } = useTranslation()
    const match = useParams<{ organizationId: string }>()
    const history = useHistory()

    const organizationId = parseInt(match.organizationId, 10)

    const organizationCall = useQuery(organizationApi.getOrganization.query({ organizationId }))
    const organizationMembersCall = useQuery(organizationApi.getOrganizationMembers.query({ organizationId }))
    const localitiesCall = useQuery(localityApi.getLocalityNames.query())

    const clean = useApiCallCleaner()
    const [creationStatus, setCreationStatus] = useState<MutationStatus>('idle')
    const request = mergeQueryResults(organizationCall, organizationMembersCall, localitiesCall)

    const heading = t('heading.createLocality', 'Create Locality')

    const localityCreator = async ({
        locality,
        selectedUsers,
    }: {
        locality: LocalityModel
        selectedUsers: Array<UserResponse>
    }) => {
        try {
            setCreationStatus('loading')
            const response = await localityApi.addLocality({
                organizationId,
                body: locality,
            })

            await localityApi.setLocalityUsers({
                localityId: response.id,
                organizationId,
                body: { users: selectedUsers.map(({ id }) => id) },
            })

            clean(deviceApi)
            clean(localityApi)
            clean(userApi)
            setCreationStatus('success')
            history.push(generateEditLocalityPath(organizationId, response.id, LocalityEditTabs.scenes))
        } catch (e) {
            setCreationStatus('error')
        }
    }

    const { mutate: handleCreateLocality } = useMutation(localityCreator)

    return (
        <LegacyLoadingWrapper
            placeholder={<PageContentLayout heading={heading} loadingState={request.status} />}
            request={request}
        >
            {([organization, members, localities]) => (
                <PageContentLayout
                    heading={
                        <>
                            {`${heading} `}
                            <small>(Organization {organization.name})</small>
                        </>
                    }
                >
                    <LocalityEditForm
                        availableUsers={members.users}
                        organization={organization}
                        submissionStatus={creationStatus}
                        usersInLocality={members.users}
                        visibleLocalities={localities.localities.filter((l) => l.organizationId === organizationId)}
                        onSubmit={handleCreateLocality}
                    />
                </PageContentLayout>
            )}
        </LegacyLoadingWrapper>
    )
}

export default CreateLocalityPage
