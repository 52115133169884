import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import { useHistory } from 'react-router'

import { userApi } from '@services'

import { storeToken } from '@helpers/auth'

import SetPasswordForm from '@components/SetPasswordForm'
import { useNotify } from '@components/notifications/NotificationsContext'

interface Props {
    token: string
    redirectPath: string
}

const PasswordResetForm = (props: Props) => {
    const { t } = useTranslation()

    const history = useHistory()
    const notify = useNotify()

    const api = userApi.withPreMiddleware(async (context) => {
        context.init.headers = {
            Authorization: `Bearer ${props.token}`,
            'Content-Type': 'application/json',
        }

        return context
    })

    const { mutateAsync: handleSubmit, status: requestState } = useMutation(api.resetOwnPassword, {
        onSuccess: (response) => {
            storeToken(response)
            history.replace(props.redirectPath)
            notify({
                title: t('notification.passwordResetSuccess', 'Password reset successful'),
                content: t(
                    'notification.passwordResetSuccessContent',
                    'Your password was changed and you were logged in'
                ),
                variant: 'success',
            })
        },
        onError: () => {
            notify({
                title: t('notification.passwordResetFailed', 'Password reset failed'),
                content: t(
                    'notification.passwordResetFailedContent',
                    'There was an error during the processing of your request'
                ),
                variant: 'danger',
            })
        },
    })

    return (
        <SetPasswordForm
            askCurrentPassword={false}
            submissionStatus={requestState}
            onSubmit={(password) => handleSubmit({ body: { password } })}
        />
    )
}

export default PasswordResetForm
