import { keyBy } from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { RouteComponentProps, withRouter } from 'react-router'

import { deviceApi, localityApi, sceneApi } from '@services'

import { mergeQueryResults, visibleOrganizationsQuery } from '@helpers/api'
import useProfile from '@helpers/profile'
import { pickColumn } from '@helpers/utils'

import DeviceListByOrganizationWithSearchBar from '@components/DeviceList/DeviceListByOrganizationWithSearchBar'
import PageContentLayout from '@components/Layouts/PageContentLayout'
import LegacyLoadingWrapper from '@components/LegacyLoadingWrapper'

type Props = RouteComponentProps

const DeviceListPage: React.FC<Props> = () => {
    const { t } = useTranslation()
    const profileCall = useProfile()

    const devicesCall = useQuery({ ...deviceApi.getDeviceList.query(), refetchInterval: 60 * 1000 })

    const scenesCall = useQuery(sceneApi.getAllScenes.query())

    const localitiesCall = useQuery(localityApi.getLocalityConfigurationStates.query())

    const statusCall = useQuery({
        ...deviceApi.getDeviceLastStatusMultiple.query({
            body: { devices: pickColumn(devicesCall.data?.devices ?? [], 'id') },
        }),
        enabled: devicesCall !== undefined,
    })

    const organizationsCall = useQuery({
        ...visibleOrganizationsQuery(profileCall.data),
    })

    const documentTitle = t('title.devicesList', 'List of devices')

    const heading = t('heading.devicesList', 'List of Devices')

    return (
        <>
            <PageContentLayout documentTitle={documentTitle} heading={heading}>
                <LegacyLoadingWrapper
                    bare={true}
                    request={mergeQueryResults(devicesCall, localitiesCall, organizationsCall, statusCall, scenesCall)}
                >
                    {([devices, localities, organizations, status, scenes]) => (
                        <DeviceListByOrganizationWithSearchBar
                            devices={devices}
                            localities={localities.localities}
                            organizations={organizations.organizations}
                            scenes={keyBy(
                                scenes.scenes.filter((s) => s.deviceId !== undefined),
                                'deviceId'
                            )}
                            status={status.status}
                        />
                    )}
                </LegacyLoadingWrapper>
            </PageContentLayout>
        </>
    )
}

export default withRouter(DeviceListPage)
