import React from 'react'
import { useTranslation } from 'react-i18next'

import PageContentLayout from '@components/Layouts/PageContentLayout'
import PasswordResetRequestForm from '@components/PasswordResetForm/PasswordResetRequestForm'

const RequestPasswordResetPage = () => {
    const { t } = useTranslation()
    const documentTitle = t('title.resetPassword', 'Reset password')
    const heading = t('heading.requestPasswordReset', 'Request a Password Reset')

    return (
        <PageContentLayout documentTitle={documentTitle} heading={heading}>
            <PasswordResetRequestForm />
        </PageContentLayout>
    )
}

export default RequestPasswordResetPage
