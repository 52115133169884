import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { faStreetView } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { isEmpty, noop } from 'lodash'
import React from 'react'
import { Badge, ListGroup } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import { SceneResponse } from '@api'

import { displayScene } from '@helpers/displayUtils'

import palette from '@components/palette.module.scss'

import styles from './ScenesListGroup.module.scss'

interface ListItemProps {
    scene: SceneResponse
    selectedScene: number | null
    allowUnassign: boolean
    removeSelected?: () => void
    className: string
}

// inline badge styles to allow for z-index overlay - fails with scss
const badgeStyles = {
    display: 'flex',
    alignItems: 'center',
    color: palette.negativeTrend,
    background: palette.negativeButton,
    marginLeft: '-10px',
    height: '16px',
    zIndex: 100,
    borderRadius: '50%',
    cursor: 'pointer',
} as const

const ListItem = ({ scene, selectedScene, allowUnassign, removeSelected, className }: ListItemProps) => (
    <div className={styles.devicesListGroupItemContainer}>
        <ListGroup.Item
            className={className}
            disabled={Number(scene.id) === selectedScene}
            eventKey={String(scene.id)}
            action
        >
            <span>
                <FontAwesomeIcon icon={faStreetView} size="lg" />
                {displayScene(scene)}
            </span>
        </ListGroup.Item>
        {allowUnassign && (
            <Badge style={badgeStyles} onClick={removeSelected ?? noop}>
                <FontAwesomeIcon icon={faTimes} size="xs" />
            </Badge>
        )}
    </div>
)

interface Props {
    selectedScene: number | null
    assignedScenes: SceneResponse[] | null
    unassignedScenes: SceneResponse[]
    onSelectionChange: (sceneId: number) => void
    onRemove: (sceneId: number) => void
}

const DevicesListGroup: React.FC<Props> = ({
    selectedScene,
    assignedScenes,
    unassignedScenes,
    onSelectionChange,
    onRemove,
}) => {
    const { t } = useTranslation()

    return (
        <div>
            <ListGroup activeKey={String(selectedScene)} onSelect={(eventKey) => onSelectionChange(Number(eventKey))}>
                <>
                    {!isEmpty(unassignedScenes) && (
                        <span>
                            <strong>{t('others.unassignedScenes', 'Unassigned scenes')}</strong>
                        </span>
                    )}
                    {unassignedScenes.map((scene) => (
                        <ListItem
                            key={scene.id}
                            allowUnassign={false}
                            className={styles.devicesListGroupItem}
                            scene={scene}
                            selectedScene={selectedScene}
                        />
                    ))}
                    {assignedScenes !== null && !isEmpty(assignedScenes) && (
                        <span>
                            <strong>{t('others.assignedScenes', 'Assigned scenes:')}</strong>
                        </span>
                    )}
                    {assignedScenes !== null &&
                        !isEmpty(assignedScenes) &&
                        assignedScenes.map((scene) => (
                            <ListItem
                                key={scene.id}
                                allowUnassign={true}
                                className={styles.devicesListGroupItem}
                                removeSelected={() => onRemove(scene.id)}
                                scene={scene}
                                selectedScene={selectedScene}
                            />
                        ))}
                </>
            </ListGroup>
        </div>
    )
}

export default DevicesListGroup
