import React from 'react'

import { DatetimeInterval } from '@api'

export type GetProps<Component> = Component extends React.ComponentType<infer PropsType> ? PropsType : never

export interface Size {
    width: number
    height: number
}

export interface ErrorEntry {
    isInvalid: boolean
    isTouched: boolean
}

export const defaultErrorEntry: ErrorEntry = {
    isTouched: false,
    isInvalid: false,
}

export enum IntervalType {
    HOUR = 'hour',
    DAY = 'day',
    WEEK = 'week',
    MONTH = 'month',
    CUSTOM = 'custom',
}

export type SummaryRequest = {
    intervals: DatetimeInterval[]
}

export enum ZoneOccupancyGranularity {
    MINUTE = 'minute',
    FIVE_MINUTES = 'five_minutes',
    QUARTER_HOUR = 'quarter_hour',
    HALF_HOUR = 'half_hour',
    HOUR = 'hour',
    DAY = 'day',
    WEEK = 'week',
}

export type IndicatorValueType = 'count' | 'time' | 'percentage'

export type NumberFormat = 'count' | 'time' | 'percentage' | 'people'

export type Possibly<T extends Record<string, unknown>> = T | { [k in keyof T]?: never }

type Without<T, U> = { [P in Exclude<keyof T, keyof U>]?: never }

export type XOR<T, U> = T | U extends Record<string, unknown> ? (Without<T, U> & U) | (Without<U, T> & T) : T | U

export type TooltipFormatter = (value: number, name: string) => [number | string, string]

export type Replace<T, R> = Omit<T, keyof R> & R
