import React from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { useParams } from 'react-router'

import { localityApi, reportsApi } from '@services'

import { mergeQueryResults } from '@helpers/api'

import FootfallReportList from '@components/FootfallReportList/FootfallReportList'
import PageContentLayout from '@components/Layouts/PageContentLayout'
import LegacyLoadingWrapper from '@components/LegacyLoadingWrapper'

const FootfallReportListPage: React.FC = () => {
    const { t } = useTranslation()
    const params = useParams<{ localityId: string }>()
    const localityId = parseInt(params.localityId, 10)

    const reportsCall = useQuery(reportsApi.listLocalityFootfallReports.query({ localityId }))

    const localityCall = useQuery(
        localityApi.getLocality.query({
            localityId: parseInt(params.localityId),
        })
    )
    const request = mergeQueryResults(reportsCall, localityCall)
    const heading = t('heading.footfallReports', 'Footfall Reports')
    const documentTitle = t('title.footfallReports', 'Footfall reports')

    return (
        <>
            <LegacyLoadingWrapper
                bare={true}
                placeholder={
                    <PageContentLayout documentTitle={documentTitle} heading={heading} loadingState={request.status} />
                }
                request={request}
            >
                {([reports, locality]) => (
                    <>
                        <PageContentLayout
                            documentTitle={`${locality.name} - ${documentTitle}`}
                            heading={`${locality.name} - ${heading}`}
                        >
                            <FootfallReportList localityId={localityId} reports={reports.reports} />
                        </PageContentLayout>
                    </>
                )}
            </LegacyLoadingWrapper>
        </>
    )
}

export default FootfallReportListPage
