/* tslint:disable */
/* eslint-disable */
/**
 * Vividi Dashboard API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DatetimeInterval,
    DatetimeIntervalFromJSON,
    DatetimeIntervalFromJSONTyped,
    DatetimeIntervalToJSON,
} from './DatetimeInterval';

/**
 * 
 * @export
 * @interface MovementSummaryRequestBody
 */
export interface MovementSummaryRequestBody {
    /**
     * List of UTC intervals to fetch statistics for
     * @type {Array<DatetimeInterval>}
     * @memberof MovementSummaryRequestBody
     */
    intervals: Array<DatetimeInterval>;
    /**
     * Requested locality id
     * @type {number}
     * @memberof MovementSummaryRequestBody
     */
    locality: number;
    /**
     * 
     * @type {boolean}
     * @memberof MovementSummaryRequestBody
     */
    includeBounce: boolean;
}

export function MovementSummaryRequestBodyFromJSON(json: any): MovementSummaryRequestBody {
    return MovementSummaryRequestBodyFromJSONTyped(json, false);
}

export function MovementSummaryRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): MovementSummaryRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'intervals': ((json['intervals'] as Array<any>).map(DatetimeIntervalFromJSON)),
        'locality': json['locality'],
        'includeBounce': json['include_bounce'],
    };
}

export function MovementSummaryRequestBodyToJSON(value?: MovementSummaryRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'intervals': ((value.intervals as Array<any>).map(DatetimeIntervalToJSON)),
        'locality': value.locality,
        'include_bounce': value.includeBounce,
    };
}

