import { faVideo } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import React from 'react'

import styles from './DeviceIcon.module.scss'

interface Props {
    color: string | undefined
}

const DeviceIcon: React.FC<Props> = ({ color }) => (
    <FontAwesomeIcon
        className={classNames(styles.deviceIcon, {
            [styles.white]: color === 'white',
        })}
        icon={faVideo}
    />
)

export default DeviceIcon
