import { FC, Suspense } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router'

import ErrorBoundary from './ErrorBoundary'
import ErrorView from './ErrorView'
import LoadingSpinner from './LoadingSpinner'

type Props = {
    placeholder?: JSX.Element
    errorComponent?: JSX.Element
    bare?: boolean
}

const LoadingWrapper: FC<Props> = ({ placeholder, errorComponent, bare = false, children }) => {
    const { t } = useTranslation()
    const location = useLocation()

    return (
        <ErrorBoundary
            location={location}
            placeholder={
                errorComponent ?? (
                    <ErrorView message={t('others.problemFetchingData', 'There was a problem fetching data.')} />
                )
            }
        >
            <Suspense fallback={placeholder ?? <LoadingSpinner bare={bare} />}>{children}</Suspense>
        </ErrorBoundary>
    )
}

export default LoadingWrapper
