import { faSquare } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import React from 'react'

import { passCSSVariable } from '@helpers/cssUtils'

import styles from './Histogram2D.module.scss'

export interface SegmentationLevels {
    null: number
    low: number
    midLow: number
    mid: number
    midHigh: number
    high: number
}

interface HistogramProps {
    data: Array<Array<string | number>>
    xLabels: Array<string | number>
    yLabels: Array<string | number>
    segmentationLevels: SegmentationLevels
    legendValues: Array<string>
    determineHistogramSegmentColor: (value: number, segmentationLevels: SegmentationLevels) => string | undefined
}

interface HistogramLegendProps {
    segmentationLevels: SegmentationLevels
    legendValues: Array<string>
    determineHistogramSegmentColor: (value: number, segmentationLevels: SegmentationLevels) => string | undefined
}

const HistogramLegend: React.FC<HistogramLegendProps> = ({
    segmentationLevels,
    legendValues,
    determineHistogramSegmentColor,
}) => {
    const segmentArray = Object.entries(segmentationLevels)

    return (
        <div className={styles.histogramLegendContainer}>
            {segmentArray.map(([level, value], index) => {
                return (
                    <div key={level}>
                        <FontAwesomeIcon
                            className={determineHistogramSegmentColor(value as number, segmentationLevels)}
                            icon={faSquare}
                        />
                        <span>{legendValues[index]}</span>
                    </div>
                )
            })}
        </div>
    )
}

const Histogram2D: React.FC<HistogramProps> = ({
    data,
    xLabels,
    yLabels,
    segmentationLevels,
    legendValues,
    determineHistogramSegmentColor,
}) => {
    const rowWithLabelLength = data[0].length

    if (data.length !== yLabels.length) {
        throw new Error("Number of data rows doesn't match the number of y-labels")
    }

    if (data.some((row) => row.length !== xLabels.length)) {
        throw new Error("Number of data columns doesn't match the number of x-labels")
    }

    return (
        <div>
            <div className={styles.histogramContainer} style={passCSSVariable('ColumnCount', rowWithLabelLength)}>
                {yLabels.map((it, index) => {
                    const row = data[index]

                    return (
                        <React.Fragment key={index}>
                            {/* yLabel as the first cell in the row */}
                            <div className={styles.rowTitle}>{String(it)}</div>
                            {/* Content cells */}
                            {row.map((cell, index) => {
                                return (
                                    <div
                                        key={`${cell}-${index}`}
                                        className={classNames(
                                            styles.histogramSegment,
                                            determineHistogramSegmentColor(cell as number, segmentationLevels)
                                        )}
                                    />
                                )
                            })}
                        </React.Fragment>
                    )
                })}
                <div /> {/* An intentional empty div to offset the x labels */}
                {xLabels.map((xLabel) => (
                    <div key={xLabel} className={styles.histogramSegment}>
                        {xLabel}
                    </div>
                ))}
            </div>
            <HistogramLegend
                determineHistogramSegmentColor={determineHistogramSegmentColor}
                legendValues={legendValues}
                segmentationLevels={segmentationLevels}
            />
        </div>
    )
}

export default Histogram2D
