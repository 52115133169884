/* tslint:disable */
/* eslint-disable */
/**
 * Vividi Dashboard API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IntervalPropertiesByBoundary,
    IntervalPropertiesByBoundaryFromJSON,
    IntervalPropertiesByBoundaryFromJSONTyped,
    IntervalPropertiesByBoundaryToJSON,
} from './IntervalPropertiesByBoundary';

/**
 * 
 * @export
 * @interface PropertiesByBoundaryResponse
 */
export interface PropertiesByBoundaryResponse {
    /**
     * Statistics items for boundaries configured as footfall entrances for the locality
     * @type {Array<IntervalPropertiesByBoundary>}
     * @memberof PropertiesByBoundaryResponse
     */
    footfallItems: Array<IntervalPropertiesByBoundary>;
    /**
     * Statistics items for boundaries other than those configured as footfall entrances for the locality
     * @type {Array<IntervalPropertiesByBoundary>}
     * @memberof PropertiesByBoundaryResponse
     */
    nonFootfallItems: Array<IntervalPropertiesByBoundary>;
}

export function PropertiesByBoundaryResponseFromJSON(json: any): PropertiesByBoundaryResponse {
    return PropertiesByBoundaryResponseFromJSONTyped(json, false);
}

export function PropertiesByBoundaryResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): PropertiesByBoundaryResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'footfallItems': ((json['footfall_items'] as Array<any>).map(IntervalPropertiesByBoundaryFromJSON)),
        'nonFootfallItems': ((json['non_footfall_items'] as Array<any>).map(IntervalPropertiesByBoundaryFromJSON)),
    };
}

export function PropertiesByBoundaryResponseToJSON(value?: PropertiesByBoundaryResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'footfall_items': ((value.footfallItems as Array<any>).map(IntervalPropertiesByBoundaryToJSON)),
        'non_footfall_items': ((value.nonFootfallItems as Array<any>).map(IntervalPropertiesByBoundaryToJSON)),
    };
}

