import { faSync, faVideoSlash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { DateTime, Duration } from 'luxon'
import React, { useEffect, useMemo, useState } from 'react'
import { Button, Col, Image, Row, Spinner } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import { DeviceResponse, LocalityResponse, Role, SceneResponse } from '@api'

import { deviceApi, localityApi, organizationApi, sceneApi } from '@services'

import { useSuspendingQuery } from '@helpers/api'
import useProfile from '@helpers/profile'
import { useLocalizeDateTime } from '@helpers/timezoneConfig'

import CardTitle from '@elements/Card/CardTitle'

import ImageWithModal from './ImageWithModal/ImageWithModal'
import LiveIndicator from './LiveIndicator/LiveIndicator'
import LoadingWrapper from './LoadingWrapper'
import styles from './SnapshotGrid.module.scss'
import palette from './palette.module.scss'
import { parseDate } from './plots/common'

const SnapshotHeader: React.FC<{
    scene: SceneResponse
    device?: DeviceResponse
    now: DateTime
    isAdmin: boolean
}> = ({ device, scene, now, isAdmin }) => {
    const localize = useLocalizeDateTime()

    const lastCapture = device?.lastCaptureAt ? localize(parseDate(device.lastCaptureAt)) : undefined

    const isDeviceOnline = lastCapture ? now.diff(lastCapture) < Duration.fromObject({ minutes: 1 }) : false

    return (
        <div className={styles.snapshotHeaderContainer}>
            <span>
                {isAdmin && (
                    <LiveIndicator
                        hideTitle={true}
                        pulseCircleAfterColor={isDeviceOnline ? palette.positiveTrendGreen : palette.negativeTrendRed}
                        pulseCircleBeforeColor={isDeviceOnline ? palette.positiveTrendGreen : palette.negativeTrendRed}
                    />
                )}
                <strong>{scene.label}</strong>
            </span>
        </div>
    )
}

const SnapshotCard: React.FC<{
    scene: SceneResponse
    device?: DeviceResponse
    renderCount: number
    lastCaptureAt?: string
    isAdmin: boolean
    now: DateTime
}> = ({ device, scene, renderCount, isAdmin, now }) => {
    const fetchCurrent = isAdmin && scene.deviceId !== undefined

    const { data: storedSnapshotRequest, refetch: refetchSnapshot } = useSuspendingQuery(
        fetchCurrent
            ? deviceApi.fetchSnapshot.query({ deviceId: scene.deviceId! })
            : sceneApi.getSceneSnapshot.query({ sceneId: scene.id })
    )

    const src = useMemo(() => window.URL.createObjectURL(storedSnapshotRequest), [storedSnapshotRequest])

    useEffect(() => {
        if (renderCount > 0) {
            refetchSnapshot()
        }
    }, [renderCount])

    return (
        <div>
            <SnapshotHeader device={device} isAdmin={isAdmin} now={now} scene={scene} />
            <ImageWithModal src={src}>
                <Image src={src} fluid />
            </ImageWithModal>
        </div>
    )
}

const SnapshotGrid: React.FC<{
    locality: LocalityResponse
}> = ({ locality }) => {
    const [renderCount, reRender] = useState(0)
    const { t } = useTranslation()

    const profileCall = useProfile()
    const isAdmin = profileCall.data?.role === Role.Administrator
    const localize = useLocalizeDateTime()

    const now = localize(DateTime.utc())

    const { data: scenesCall, refetch: refetchScenes } = useSuspendingQuery(
        localityApi.getLocalityScenes.query({
            localityId: locality.id,
        })
    )

    const { data: devicesCall, refetch: refetchDevices } = useSuspendingQuery(
        organizationApi.getOrganizationDevices.query({
            organizationId: locality.organizationId,
        })
    )

    const { scenes } = scenesCall
    const { devices } = devicesCall

    const refetchAndRerender = () => {
        reRender((curr) => curr + 1)
        refetchScenes()
        refetchDevices()
    }

    return (
        <>
            <CardTitle
                buttons={
                    isAdmin ? (
                        <Button variant="secondary" onClick={refetchAndRerender}>
                            <FontAwesomeIcon icon={faSync} />
                            {t('button.reload', 'Reload')}
                        </Button>
                    ) : undefined
                }
                text={t('realTime.floorplanDevices', '{{locality}} floor plan devices', {
                    locality: locality.name,
                })}
            />
            <Row>
                {scenes.map((scene, index) => (
                    <Col key={`column-${index}`} className={styles.snapshotGridColumn} md={3}>
                        <LoadingWrapper
                            errorComponent={
                                <div className={styles.errorContainer}>
                                    <FontAwesomeIcon icon={faVideoSlash} size="lg" />
                                    <span>{t('realTime.deviceIsOffline', 'Device is offline')}</span>
                                </div>
                            }
                            placeholder={
                                <div className={styles.loadingContainer}>
                                    <Spinner animation="border" />
                                </div>
                            }
                        >
                            <SnapshotCard
                                key={index}
                                device={devices.find((d) => d.id === scene.deviceId)}
                                isAdmin={isAdmin}
                                now={now}
                                renderCount={renderCount}
                                scene={scene}
                            />
                        </LoadingWrapper>
                    </Col>
                ))}
            </Row>
        </>
    )
}

export default SnapshotGrid
