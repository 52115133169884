import classNames from 'classnames'
import { uniqueId } from 'lodash'
import React, { useMemo } from 'react'
import { Badge, Popover, OverlayTrigger } from 'react-bootstrap'
import { Variant } from 'react-bootstrap/esm/types'
import { useTranslation } from 'react-i18next'

import styles from './BadgeList.module.scss'

const BadgeList = ({
    items,
    maxDisplayed = 2,
    displayType = 'inline',
    badgeVariant = 'secondary',
}: {
    items: string[]
    maxDisplayed?: number
    displayType?: 'inline' | 'grid'
    badgeVariant?: Variant
}) => {
    const { t } = useTranslation()

    const id = useMemo(uniqueId, [])

    return (
        <div
            className={classNames({
                [styles.inlineBadgeList]: displayType === 'inline',
                [styles.badgeList]: displayType === 'grid',
            })}
        >
            {items.slice(0, maxDisplayed).map((item, index) => (
                <Badge
                    // eslint-disable-next-line
                    key={index}
                    className={classNames({
                        [styles.badgeEllipsis]: displayType !== 'grid',
                        [styles.badge]: displayType === 'grid',
                    })}
                    variant={badgeVariant}
                >
                    {item}
                </Badge>
            ))}
            {items.length > maxDisplayed && (
                <OverlayTrigger
                    overlay={
                        <Popover id={id}>
                            <Popover.Content>
                                {items.slice(maxDisplayed, items.length).map((item, index) => (
                                    <div key={index}>{item}</div> // eslint-disable-line
                                ))}
                            </Popover.Content>
                        </Popover>
                    }
                >
                    {displayType === 'grid' ? (
                        <span className={styles.moreText}>
                            {t('others.more', {
                                value: items.length - maxDisplayed,
                            })}
                        </span>
                    ) : (
                        <Badge className={styles.badgeEllipsis} variant="secondary">
                            {t('others.more', {
                                value: items.length - maxDisplayed,
                            })}
                        </Badge>
                    )}
                </OverlayTrigger>
            )}
        </div>
    )
}

export default BadgeList
