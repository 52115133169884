/**
 * Vividi Dashboard API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime'
import {UseQueryOptions, QueryKey} from 'react-query'

import {
    AuthenticationRequestBody,
    AuthenticationRequestBodyFromJSON,
    AuthenticationRequestBodyToJSON,
    ErrorResponse,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    InstantLoginRequestBody,
    InstantLoginRequestBodyFromJSON,
    InstantLoginRequestBodyToJSON,
    TokenResponse,
    TokenResponseFromJSON,
    TokenResponseToJSON,
} from '../models';

export interface AuthenticateRequest {
    body: AuthenticationRequestBody;
}

export interface AuthenticateInstantRequest {
    body: InstantLoginRequestBody;
}

export interface TakeoverRequest {
    userId: number;
}


/**
 * 
 */
export class AuthenticationAndTokenManagementApi extends runtime.BaseAPI {
    readonly name = 'AuthenticationAndTokenManagementApi'

    /**
     * Perform authentication with user credentials and receive an access token.
     */
    authenticate = Object.assign(
        async (requestParameters: AuthenticateRequest, initOverrides?: RequestInit): Promise<TokenResponse> => {
            const response = await this.authenticate.invokeRaw(requestParameters, initOverrides);
            return await response.value();
        },
        {
            invokeRaw: async (requestParameters: AuthenticateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<TokenResponse>> => {
                if (requestParameters.body === null || requestParameters.body === undefined) {
                    throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling authenticate.');
                }
            
                const queryParameters: any = {};
            
                const headerParameters: runtime.HTTPHeaders = {};
            
                headerParameters['Content-Type'] = 'application/json';
            
                const response = await this.request({
                    path: `/api/auth/token`,
                    method: 'POST',
                    headers: headerParameters,
                    query: queryParameters,
                    body: AuthenticationRequestBodyToJSON(requestParameters.body),
                }, initOverrides);
            
                return new runtime.JSONApiResponse(response, (jsonValue) => TokenResponseFromJSON(jsonValue));
            },
            mutationKey: [
                'AuthenticationAndTokenManagementApi',
                'authenticate', 
            ],
            queryKey: (requestParameters: AuthenticateRequest, initOverrides?: RequestInit) => ([
                    ...this.authenticate.mutationKey, 
                {
                body: requestParameters.body,
                }
            ]),
            query: (requestParameters: AuthenticateRequest, initOverrides?: RequestInit): UseQueryOptions<TokenResponse> & {queryKey: QueryKey} => ({
                queryFn: () => this.authenticate(requestParameters, initOverrides),
                queryKey: this.authenticate.queryKey(requestParameters, initOverrides)
            })
        }
    )

    /**
     * Perform authentication with an instant-login token and receive an access token.
     */
    authenticateInstant = Object.assign(
        async (requestParameters: AuthenticateInstantRequest, initOverrides?: RequestInit): Promise<TokenResponse> => {
            const response = await this.authenticateInstant.invokeRaw(requestParameters, initOverrides);
            return await response.value();
        },
        {
            invokeRaw: async (requestParameters: AuthenticateInstantRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<TokenResponse>> => {
                if (requestParameters.body === null || requestParameters.body === undefined) {
                    throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling authenticateInstant.');
                }
            
                const queryParameters: any = {};
            
                const headerParameters: runtime.HTTPHeaders = {};
            
                headerParameters['Content-Type'] = 'application/json';
            
                const response = await this.request({
                    path: `/api/auth/token-instant`,
                    method: 'POST',
                    headers: headerParameters,
                    query: queryParameters,
                    body: InstantLoginRequestBodyToJSON(requestParameters.body),
                }, initOverrides);
            
                return new runtime.JSONApiResponse(response, (jsonValue) => TokenResponseFromJSON(jsonValue));
            },
            mutationKey: [
                'AuthenticationAndTokenManagementApi',
                'authenticateInstant', 
            ],
            queryKey: (requestParameters: AuthenticateInstantRequest, initOverrides?: RequestInit) => ([
                    ...this.authenticateInstant.mutationKey, 
                {
                body: requestParameters.body,
                }
            ]),
            query: (requestParameters: AuthenticateInstantRequest, initOverrides?: RequestInit): UseQueryOptions<TokenResponse> & {queryKey: QueryKey} => ({
                queryFn: () => this.authenticateInstant(requestParameters, initOverrides),
                queryKey: this.authenticateInstant.queryKey(requestParameters, initOverrides)
            })
        }
    )

    /**
     * Exchange a token that will expire soon for a new one.
     */
    refresh = Object.assign(
        async (initOverrides?: RequestInit): Promise<TokenResponse> => {
            const response = await this.refresh.invokeRaw(initOverrides);
            return await response.value();
        },
        {
            invokeRaw: async (initOverrides?: RequestInit): Promise<runtime.ApiResponse<TokenResponse>> => {
                const queryParameters: any = {};
            
                const headerParameters: runtime.HTTPHeaders = {};
            
                if (this.configuration && this.configuration.accessToken) {
                    const token = this.configuration.accessToken;
                    const tokenString = await token("token", []);
            
                    if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                    }
                }
                const response = await this.request({
                    path: `/api/auth/refresh`,
                    method: 'POST',
                    headers: headerParameters,
                    query: queryParameters,
                }, initOverrides);
            
                return new runtime.JSONApiResponse(response, (jsonValue) => TokenResponseFromJSON(jsonValue));
            },
            mutationKey: [
                'AuthenticationAndTokenManagementApi',
                'refresh', 
            ],
            queryKey: (initOverrides?: RequestInit) => ([
                    ...this.refresh.mutationKey, 
                {
                }
            ]),
            query: (initOverrides?: RequestInit): UseQueryOptions<TokenResponse> & {queryKey: QueryKey} => ({
                queryFn: () => this.refresh(initOverrides),
                queryKey: this.refresh.queryKey(initOverrides)
            })
        }
    )

    /**
     * Revoke all previously received tokens and receive a new one.
     */
    revoke = Object.assign(
        async (initOverrides?: RequestInit): Promise<TokenResponse> => {
            const response = await this.revoke.invokeRaw(initOverrides);
            return await response.value();
        },
        {
            invokeRaw: async (initOverrides?: RequestInit): Promise<runtime.ApiResponse<TokenResponse>> => {
                const queryParameters: any = {};
            
                const headerParameters: runtime.HTTPHeaders = {};
            
                if (this.configuration && this.configuration.accessToken) {
                    const token = this.configuration.accessToken;
                    const tokenString = await token("token", []);
            
                    if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                    }
                }
                const response = await this.request({
                    path: `/api/auth/revoke`,
                    method: 'POST',
                    headers: headerParameters,
                    query: queryParameters,
                }, initOverrides);
            
                return new runtime.JSONApiResponse(response, (jsonValue) => TokenResponseFromJSON(jsonValue));
            },
            mutationKey: [
                'AuthenticationAndTokenManagementApi',
                'revoke', 
            ],
            queryKey: (initOverrides?: RequestInit) => ([
                    ...this.revoke.mutationKey, 
                {
                }
            ]),
            query: (initOverrides?: RequestInit): UseQueryOptions<TokenResponse> & {queryKey: QueryKey} => ({
                queryFn: () => this.revoke(initOverrides),
                queryKey: this.revoke.queryKey(initOverrides)
            })
        }
    )

    /**
     * Get an access token for a user without authentication.
     */
    takeover = Object.assign(
        async (requestParameters: TakeoverRequest, initOverrides?: RequestInit): Promise<TokenResponse> => {
            const response = await this.takeover.invokeRaw(requestParameters, initOverrides);
            return await response.value();
        },
        {
            invokeRaw: async (requestParameters: TakeoverRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<TokenResponse>> => {
                if (requestParameters.userId === null || requestParameters.userId === undefined) {
                    throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling takeover.');
                }
            
                const queryParameters: any = {};
            
                const headerParameters: runtime.HTTPHeaders = {};
            
                const response = await this.request({
                    path: `/api/auth/users/{user_id}/takeover/`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))),
                    method: 'POST',
                    headers: headerParameters,
                    query: queryParameters,
                }, initOverrides);
            
                return new runtime.JSONApiResponse(response, (jsonValue) => TokenResponseFromJSON(jsonValue));
            },
            mutationKey: [
                'AuthenticationAndTokenManagementApi',
                'takeover', 
            ],
            queryKey: (requestParameters: TakeoverRequest, initOverrides?: RequestInit) => ([
                    ...this.takeover.mutationKey, 
                {
                userId: requestParameters.userId,
                }
            ]),
            query: (requestParameters: TakeoverRequest, initOverrides?: RequestInit): UseQueryOptions<TokenResponse> & {queryKey: QueryKey} => ({
                queryFn: () => this.takeover(requestParameters, initOverrides),
                queryKey: this.takeover.queryKey(requestParameters, initOverrides)
            })
        }
    )

}
