/* tslint:disable */
/* eslint-disable */
/**
 * Vividi Dashboard API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CreateUserRequestBody,
    CreateUserRequestBodyFromJSON,
    CreateUserRequestBodyFromJSONTyped,
    CreateUserRequestBodyToJSON,
} from './CreateUserRequestBody';
import {
    OrganizationModel,
    OrganizationModelFromJSON,
    OrganizationModelFromJSONTyped,
    OrganizationModelToJSON,
} from './OrganizationModel';

/**
 * 
 * @export
 * @interface RegisterOrganizationRequestBody
 */
export interface RegisterOrganizationRequestBody {
    /**
     * 
     * @type {OrganizationModel}
     * @memberof RegisterOrganizationRequestBody
     */
    organization: OrganizationModel;
    /**
     * 
     * @type {CreateUserRequestBody}
     * @memberof RegisterOrganizationRequestBody
     */
    owner: CreateUserRequestBody;
}

export function RegisterOrganizationRequestBodyFromJSON(json: any): RegisterOrganizationRequestBody {
    return RegisterOrganizationRequestBodyFromJSONTyped(json, false);
}

export function RegisterOrganizationRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): RegisterOrganizationRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'organization': OrganizationModelFromJSON(json['organization']),
        'owner': CreateUserRequestBodyFromJSON(json['owner']),
    };
}

export function RegisterOrganizationRequestBodyToJSON(value?: RegisterOrganizationRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'organization': OrganizationModelToJSON(value.organization),
        'owner': CreateUserRequestBodyToJSON(value.owner),
    };
}

