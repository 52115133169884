import { VisitBoundary, DetectionZone, Point } from '@api'

import {
    floorPoint,
    vectorLength,
    calculateNormalVector,
    calculateClockwiseAngle,
    minus,
    plus,
    scale,
    normalize,
} from './points'
import { XOR } from './types'

enum SceneObjectType {
    entrance = 'entrance',
    passage = 'passage',
    otherBoundary = 'otherBoundary',
    pos = 'pos',
    queue = 'queue',
    seat = 'seat',
    stopZone = 'stopZone',
    otherZone = 'otherZone',
}

export type SceneObjectIdentifier = XOR<{ id: number }, { tempId: string }> & { backendType: SceneObjectBackendType }

type SceneObjectMetadata = {
    isVisible: boolean
} & SceneObjectIdentifier

export enum SceneObjectBackendType {
    visitBoundaries = 'visitBoundaries',
    detectionZones = 'detectionZones',
    stopZones = 'stopZones',
}

export type EditableVisitBoundary = VisitBoundary &
    SceneObjectMetadata & {
        backendType: SceneObjectBackendType.visitBoundaries
        type: SceneObjectType.entrance | SceneObjectType.passage | SceneObjectType.otherBoundary
    }

export type EditableDetectionZone = DetectionZone &
    SceneObjectMetadata & {
        backendType: SceneObjectBackendType.detectionZones | SceneObjectBackendType.stopZones
        type:
            | SceneObjectType.pos
            | SceneObjectType.queue
            | SceneObjectType.seat
            | SceneObjectType.stopZone
            | SceneObjectType.otherZone
    }

export type EditableSceneObject = XOR<EditableVisitBoundary, EditableDetectionZone>

const isSceneObjectEqual = (obj: SceneObjectIdentifier, other: SceneObjectIdentifier) => {
    const isNewlyAddedObject = Boolean(obj.tempId)
    const isAPIObject = Boolean(obj.id)

    return (
        (obj.backendType === other.backendType && isAPIObject && obj.id === other.id) ||
        (isNewlyAddedObject && obj.tempId === other.tempId)
    )
}

const sceneObjectBackendType = (type: SceneObjectType): SceneObjectBackendType =>
    type === SceneObjectType.stopZone
        ? SceneObjectBackendType.stopZones
        : [SceneObjectType.entrance, SceneObjectType.passage, SceneObjectType.otherBoundary].includes(type)
        ? SceneObjectBackendType.visitBoundaries
        : SceneObjectBackendType.detectionZones

const isVisitBoundary = (object: EditableSceneObject): object is EditableVisitBoundary =>
    object.backendType === SceneObjectBackendType.visitBoundaries

const isDetectionZone = (object: EditableSceneObject): object is EditableDetectionZone =>
    object.backendType === SceneObjectBackendType.detectionZones ||
    object.backendType === SceneObjectBackendType.stopZones

export const determineBoundaryArrowRotation = (previous: Point, anchor: Point, next: Point): number => {
    const v1 = normalize(minus(floorPoint(previous), floorPoint(anchor)))
    const v2 = normalize(minus(floorPoint(next), floorPoint(anchor)))

    const isTurningLeft = calculateClockwiseAngle(v1, v2) <= Math.PI

    const arrowDirection =
        vectorLength(plus(v1, v2)) > 0
            ? scale(normalize(plus(v1, v2)), isTurningLeft ? -1 : 1)
            : calculateNormalVector(v2)

    return calculateClockwiseAngle({ x: 0, y: -1 }, arrowDirection)
}

export { isVisitBoundary, isDetectionZone, SceneObjectType, isSceneObjectEqual, sceneObjectBackendType }
