import React from 'react'
import { useTranslation } from 'react-i18next'

import chromeLogo from '@images/chrome.png'
import edgeLogo from '@images/edge.png'
import logo from '@images/logo/logo.svg'
import mozillaLogo from '@images/mozilla.png'

import styles from './BrowserNotSupported.module.scss'

const BrowserNotSupportedPage: React.FC = () => {
    const { t } = useTranslation()

    return (
        <div className={styles.container}>
            <a href="https://vividi.io/">
                <img alt="Iterait logo" className={styles.logo} src={logo} />
            </a>
            <div className={styles.content}>
                <h1 className={styles.title}>
                    {t('browserNotSupported.title', "Sorry, we don't support this browser")}
                </h1>

                <p className={styles.subtitle}>
                    {t(
                        'browserNotSupported.subtitle',
                        'Open the VIVIDI Dashboard in one of the recommended browsers below'
                    )}
                </p>

                <div className={styles.imagesContainer}>
                    <a href="https://www.google.com/chrome/" rel="noopener noreferrer" target="_blank">
                        <img alt="Chrome logo" src={chromeLogo} />
                    </a>
                    <a href="https://www.mozilla.org/firefox/new/" rel="noopener noreferrer" target="_blank">
                        <img alt="Mozilla logo" src={mozillaLogo} />
                    </a>
                    <a href="https://www.microsoft.com/edge" rel="noopener noreferrer" target="_blank">
                        <img alt="Edge logo" src={edgeLogo} />
                    </a>
                </div>
            </div>
        </div>
    )
}

export default BrowserNotSupportedPage
