/* tslint:disable */
/* eslint-disable */
/**
 * Vividi Dashboard API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Time,
    TimeFromJSON,
    TimeFromJSONTyped,
    TimeToJSON,
} from './Time';

/**
 * 
 * @export
 * @interface OpeningHoursInterval
 */
export interface OpeningHoursInterval {
    /**
     * 
     * @type {Time}
     * @memberof OpeningHoursInterval
     */
    openingTime: Time;
    /**
     * 
     * @type {Time}
     * @memberof OpeningHoursInterval
     */
    closingTime: Time;
}

export function OpeningHoursIntervalFromJSON(json: any): OpeningHoursInterval {
    return OpeningHoursIntervalFromJSONTyped(json, false);
}

export function OpeningHoursIntervalFromJSONTyped(json: any, ignoreDiscriminator: boolean): OpeningHoursInterval {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'openingTime': TimeFromJSON(json['opening_time']),
        'closingTime': TimeFromJSON(json['closing_time']),
    };
}

export function OpeningHoursIntervalToJSON(value?: OpeningHoursInterval | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'opening_time': TimeToJSON(value.openingTime),
        'closing_time': TimeToJSON(value.closingTime),
    };
}

