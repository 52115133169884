import { faBox, faMapMarkerAlt, faUserFriends, faVideo } from '@fortawesome/free-solid-svg-icons'
import { faInboxOut, faStreetView } from '@fortawesome/pro-solid-svg-icons'
import { keyBy, noop, remove } from 'lodash'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery } from 'react-query'
import { Redirect, useHistory, useLocation, useParams, useRouteMatch } from 'react-router'

import { LocalityNameModel, OpeningHours, Role, SuccessResponse, UserResponse, Location } from '@api'

import { deviceApi, localityApi, organizationApi, sceneApi, userApi } from '@services'

import {
    generateEditOrganizationPath,
    generateOrganizationCreateScenePath,
    generateOrganizationCreateUserPath,
    generateOrganizationEditDeviceCreatePath,
    generateOrganizationEditDevicePath,
    generateOrganizationEditDevicesPath,
    generateOrganizationEditSceneDescribePath,
    generateOrganizationEditScenePath,
    generateOrganizationEditUserPath,
    generateOrganizationScenesPath,
    generateOrganizationsEditLocalitiesPath,
    MY_ORGANIZATION_PATH,
    ORGANIZATION_DEVICES_NEW_TAB_PATH,
    ORGANIZATION_DEVICES_TAB_PATH,
    ORGANIZATION_EDIT_PATH,
    ORGANIZATION_LIST_PATH,
    ORGANIZATION_SCENES_NEW_TAB_PATH,
    ORGANIZATION_SCENES_TAB_PATH,
    ORGANIZATION_USER_NEW_PATH,
    ORGANIZATION_USERS_TAB_PATH,
    OrganizationEditPageTabs,
} from '@helpers/VividiURLs'
import { mergeQueryResults, useApiCallCleaner, visibleSceneDescriptionsQuery } from '@helpers/api'
import { isApiError, maskQueryFailure } from '@helpers/apiCallManipulation'
import { GoogleEventCategory, organizationTabEvent, trackEvent } from '@helpers/gtag'
import useProfile from '@helpers/profile'
import { pickColumn } from '@helpers/utils'

import Box from '@elements/Box/Box'
import TabTitle from '@elements/Tabs/TabTitle'

import { BreadcrumbsList } from '@components/Breadcrumbs'
import DescribeSceneTabModal from '@components/DescribeSceneTab/DescribeSceneTabModal'
import ErrorView from '@components/ErrorView'
import BarePageLayout from '@components/Layouts/BarePageLayout'
import TabbedPageLayout, { TabList } from '@components/Layouts/TabbedPageLayout'
import LegacyLoadingWrapper from '@components/LegacyLoadingWrapper'
import LoadingSpinner from '@components/LoadingSpinner'
import LocalityPicker from '@components/LocalityPicker'
import DataPublishersForm from '@components/OrganizationEditForms/DataPublishersForm'
import DevicesTab from '@components/OrganizationEditForms/DevicesTab/DevicesTab'
import LocalitiesTab, { LocalitiesEditData } from '@components/OrganizationEditForms/LocalitiesTab/LocalitiesTab'
import OrganizationEditForm from '@components/OrganizationEditForms/OrganizationEditForm'
import UsersTab from '@components/OrganizationEditForms/UsersTab/UsersTab'
import ScenesTab from '@components/ScenesTab/ScenesTab'
import { useNotify } from '@components/notifications/NotificationsContext'

import styles from './EditOrganizationPage.module.scss'
import {
    determineOrganizationDescribeSceneModalProps,
    determineOrganizationInstallerUIProps,
} from './editOrganizationPageModalProps'

const EditOrganizationPage = () => {
    const { t } = useTranslation()
    const params = useParams<{ id: string; tab?: string; sceneId: string; localityId?: string }>()
    const notify = useNotify()
    const organizationId = parseInt(params.id, 10)
    const sceneId = parseInt(params.sceneId, 10)
    const localityId = params.localityId ? parseInt(params.localityId, 10) : undefined
    const tabName = params.tab
    const profileCall = useProfile()
    const clean = useApiCallCleaner()
    const history = useHistory()
    const location = useLocation()

    const myOrganization = useRouteMatch(MY_ORGANIZATION_PATH)
    const isAdmin = profileCall.data?.role === Role.Administrator
    const isOrgOwnerOrAdmin = profileCall.data?.role === Role.OrganizationOwner || Role.OrganizationAdministrator
    const isOrganizationDeviceListPage = useRouteMatch(ORGANIZATION_SCENES_TAB_PATH) !== null
    const isNewOrganizationScene = useRouteMatch(ORGANIZATION_SCENES_NEW_TAB_PATH) !== null
    const isOrganizationDeviceTabPage = useRouteMatch(ORGANIZATION_DEVICES_TAB_PATH) !== null
    const isNewOrganizationDevice = useRouteMatch(ORGANIZATION_DEVICES_NEW_TAB_PATH) !== null
    const isOrganizationUserListPage = useRouteMatch(ORGANIZATION_USERS_TAB_PATH) !== null
    const isNewOrganizationUser = useRouteMatch(ORGANIZATION_USER_NEW_PATH) !== null

    const heading = t('heading.editOrganization', 'Edit Organization')

    const tabKey = tabName ?? OrganizationEditPageTabs.localities

    const organizationCall = useQuery(
        organizationApi.getOrganization.query({
            organizationId,
        })
    )

    const scenesInOrganizationCall = useQuery(
        organizationApi.getOrganizationScenes.query({
            organizationId,
        })
    )

    const sceneInOrganizationConfigurationsCall = useQuery({
        ...visibleSceneDescriptionsQuery(pickColumn(scenesInOrganizationCall.data?.scenes ?? [], 'id')),
    })

    const dataPublishersCall = useQuery(
        organizationApi.getMqttPublishers.query({
            organizationId,
        })
    )

    const organizationDevicesCall = useQuery(
        organizationApi.getOrganizationDevices.query({
            organizationId,
        })
    )

    const readyForUseDevicesCall = maskQueryFailure(
        useQuery({
            ...organizationApi.getOrganizationDevices.query({
                organizationId: profileCall.data?.applicationConfiguration?.readyToUseOrganizationId ?? -1,
            }),
            enabled: profileCall !== undefined,
        })
    )

    const localitiesCall = useQuery(localityApi.getLocalityNames.query())

    const usersCall = useQuery(organizationApi.getOrganizationMembers.query({ organizationId }))
    const allVisibleDevicesCall = maskQueryFailure(useQuery(deviceApi.getDeviceList.query()))
    const allVisibleScenesCall = maskQueryFailure(useQuery(sceneApi.getAllScenes.query()))
    const allVisibleOrganizationsCall = maskQueryFailure(useQuery(organizationApi.getOrganizations.query()))

    const {
        mutateAsync: updateOrganization,
        status: updateOrganizationState,
        reset,
    } = useMutation(organizationApi.updateOrganization, {
        onSuccess: () => {
            reset()
        },
    })
    const { mutateAsync: updateOrganizationFeatures } = useMutation(organizationApi.updateOrganizationFeatures)

    // Localities tab triggers

    const localityCreator = async ({
        organizationId,
        values,
    }: {
        organizationId: number
        values: LocalitiesEditData
    }) => {
        try {
            const { users, scenes } = values

            const createdLocality = await localityApi.addLocality({
                organizationId,
                body: { ...values.locality, timezone: 'Europe/Prague' },
            })

            const requests: Array<Promise<SuccessResponse>> = [
                localityApi.setLocalityScenes({
                    localityId: createdLocality.id,
                    body: {
                        scenes: scenes ?? [],
                    },
                }),
                localityApi.setLocalityUsers({
                    localityId: createdLocality.id,
                    organizationId,
                    body: {
                        users: users ?? [],
                    },
                }),
            ]

            await Promise.all(requests)

            clean(userApi)
            clean(organizationApi)
            clean(localityApi)

            history.push(generateOrganizationsEditLocalitiesPath(organizationId, createdLocality.id))
        } catch (error) {
            if (isApiError(error) && error.status === 409) {
                notify({
                    title: t('notification.error', 'Error'),
                    content: t('notification.userAlreadyExists', 'User you are trying to add already exists.'),
                    variant: 'danger',
                    timeoutSeconds: 4,
                })
            }
        }
    }

    const localityUpdater = async ({
        organizationId,
        localityData,
    }: {
        organizationId: number
        localityData: {
            locality: LocalityNameModel
            scenes?: number[] | undefined
            users?: number[] | undefined
            openingHours?: OpeningHours
            location?: Location
            isRealTimeOccupancyEnabled?: boolean
            isFloorplanOccupancyEnabled?: boolean
        }
    }) => {
        try {
            const { users, scenes, openingHours, location, isFloorplanOccupancyEnabled, isRealTimeOccupancyEnabled } =
                localityData

            await localityApi.updateLocality({
                organizationId,
                localityId: localityData.locality.id,
                body: {
                    ...localityData.locality,
                    openingHours,
                    location,
                    isFloorplanOccupancyEnabled,
                    isRealTimeOccupancyEnabled,
                },
            })

            await Promise.all([
                scenes
                    ? localityApi.setLocalityScenes({
                          localityId: localityData.locality.id,
                          body: { scenes },
                      })
                    : undefined,
                users
                    ? localityApi.setLocalityUsers({
                          localityId: localityData.locality.id,
                          organizationId,
                          body: { users },
                      })
                    : undefined,
            ])

            notify({
                variant: 'success',
                title: t('notification.localityUpdateSuccessful', 'Locality update successful'),
                content: t(
                    'notification.localityUpdateSuccessfulContent',
                    'Changes in locality {{ value }} were successfully saved',
                    {
                        value: localityData.locality?.name,
                    }
                ),
                timeoutSeconds: 4,
            })

            clean(userApi)
            clean(organizationApi)
            clean(localityApi)
            clean(deviceApi)
        } catch (error) {
            notify({
                title: t('notification.error', 'Error'),
                content: t('notification.localityUpdateFailed', 'Locality update failed'),
                variant: 'danger',
                timeoutSeconds: 4,
            })
        }
    }

    const deleteLocality = ({ organizationId, localityId }: { organizationId: number; localityId: number }) =>
        localityApi
            .deleteLocality({ organizationId, localityId })
            .then(() => {
                clean(localityApi)
                clean(organizationApi)
            })
            .catch(noop)

    const {
        mutate: localityCreate,
        status: localityCreateState,
        reset: localityCreateReset,
    } = useMutation(localityCreator, {
        onSuccess: () => {
            localityCreateReset()
        },
    })

    const {
        mutate: localityUpdate,
        status: localityUpdateState,
        reset: localityUpdateReset,
    } = useMutation(localityUpdater, {
        onSuccess: () => {
            localityUpdateReset()
        },
    })

    const { mutate: localityDelete } = useMutation(deleteLocality)

    // Redirect to the edit organization page if /my-organization is accessed

    if (myOrganization && profileCall.data?.organizationId) {
        return (
            <Redirect
                to={generateEditOrganizationPath(
                    Number(profileCall.data.organizationId),
                    OrganizationEditPageTabs.localities
                )}
            />
        )
    }

    const breadcrumbs: BreadcrumbsList = [
        {
            linkDisplayName: t('navbar.organizations', 'Organizations'),
            link: ORGANIZATION_LIST_PATH,
        },
    ]

    const loadingTabs = [
        {
            eventKey: OrganizationEditPageTabs.modules,
            title: <TabTitle counter={0} icon={faBox} title={t('tab.modules', 'Modules')} />,
            children: (
                <Box className={styles.placeholderBox} paddingSize="lg">
                    <LoadingSpinner bare />
                </Box>
            ),
        },
        {
            eventKey: OrganizationEditPageTabs.localities,
            title: <TabTitle counter={0} icon={faMapMarkerAlt} title={t('table.localities', 'Localities')} />,
            children: (
                <Box className={styles.placeholderBox} paddingSize="lg">
                    <LoadingSpinner bare />
                </Box>
            ),
        },
        {
            eventKey: OrganizationEditPageTabs.scenes,
            title: <TabTitle counter={0} icon={faStreetView} title={t('tab.scenes', 'Scenes')} />,
            children: (
                <Box className={styles.placeholderBox} paddingSize="lg">
                    <LoadingSpinner bare />
                </Box>
            ),
        },
        {
            eventKey: OrganizationEditPageTabs.devices,
            title: <TabTitle counter={0} icon={faVideo} title={t('tab.devices', 'Devices')} />,
            children: (
                <Box className={styles.placeholderBox} paddingSize="lg">
                    <LoadingSpinner bare />
                </Box>
            ),
        },
        {
            eventKey: OrganizationEditPageTabs.users,
            title: <TabTitle counter={0} icon={faUserFriends} title={t('form.users', 'Users')} />,
            children: (
                <Box className={styles.placeholderBox} paddingSize="lg">
                    <LoadingSpinner bare />
                </Box>
            ),
        },
        {
            eventKey: OrganizationEditPageTabs.dataPublishers,
            title: <TabTitle counter={0} icon={faInboxOut} title={t('tab.dataPublishers', 'Data publishers')} />,
            children: (
                <Box className={styles.placeholderBox} paddingSize="lg">
                    <LoadingSpinner bare />
                </Box>
            ),
        },
    ]

    if (!isAdmin) {
        remove(loadingTabs, (tab) => tab.eventKey === OrganizationEditPageTabs.modules)
    }

    return (
        <LegacyLoadingWrapper
            errorComponent={
                <BarePageLayout heading={heading} headingType="page">
                    <ErrorView message={t('others.organizationNotFound', 'Organization not found')} />
                </BarePageLayout>
            }
            placeholder={
                <TabbedPageLayout
                    activeKey={tabKey}
                    breadcrumbs={[]}
                    documentTitle={heading}
                    heading={heading}
                    tabs={loadingTabs}
                    onSelect={noop}
                />
            }
            request={mergeQueryResults(
                organizationCall,
                localitiesCall,
                scenesInOrganizationCall,
                dataPublishersCall,
                usersCall,
                readyForUseDevicesCall,
                organizationDevicesCall,
                allVisibleDevicesCall,
                allVisibleScenesCall,
                allVisibleOrganizationsCall,
                sceneInOrganizationConfigurationsCall
            )}
        >
            {([
                organization,
                { localities },
                { scenes: organizationScenes },
                dataPublishers,
                { users },
                readyForUseDevices,
                organizationDevices,
                devices,
                scenes,
                organizations,
                sceneInOrganizationConfigurations,
            ]) => {
                const visibleDevices = devices?.devices ?? []
                const visibleScenes = scenes?.scenes ?? []
                const visibleOrganizations = organizations?.organizations ?? []

                const allDevicesWithNoScene = visibleDevices.filter(
                    ({ id }) => !visibleScenes.some(({ deviceId }) => deviceId === id)
                )

                const visibleLocalities = localities.filter((l) => l.organizationId === organization.id)

                breadcrumbs.push({
                    linkDisplayName: organization.name,
                    link: generateEditOrganizationPath(organization.id, OrganizationEditPageTabs.modules),
                    path: ORGANIZATION_EDIT_PATH,
                })

                const orgLocalities = localities.filter(({ organizationId }) => organizationId === organization.id)

                const determineOrganizationEditSceneUrl = (sceneId?: number) =>
                    sceneId !== undefined
                        ? generateOrganizationScenesPath(organization.id, sceneId)
                        : generateOrganizationEditScenePath(organization.id)

                const determineOrganizationCreateSceneUrl = () => generateOrganizationCreateScenePath(organization.id)

                const determineOrganizationEditUserUrl = (user?: UserResponse) =>
                    user !== undefined
                        ? generateOrganizationEditUserPath(user.id, organization.id)
                        : generateEditOrganizationPath(organization.id, OrganizationEditPageTabs.users)

                const determineOrganizationCreateUserUrl = () => generateOrganizationCreateUserPath(organization.id)

                const determineOrganizationEditDeviceUrl = (deviceId?: number) =>
                    deviceId !== undefined
                        ? generateOrganizationEditDevicePath(organization.id, deviceId)
                        : generateOrganizationEditDevicesPath(organization.id)

                const determineOrganizationCreateDeviceUrl = () =>
                    generateOrganizationEditDeviceCreatePath(organization.id)

                const readyDevices = readyForUseDevices ? readyForUseDevices.devices : []

                // Describe scene modal

                const describeSceneModalProps = determineOrganizationDescribeSceneModalProps(location, history)(
                    organizationScenes,
                    localities,
                    sceneId,
                    organization,
                    localityId
                )

                const picker =
                    describeSceneModalProps.selectedSceneLocalities.length > 1 ? (
                        <LocalityPicker
                            className={styles.localityPicker}
                            features={[]}
                            localities={keyBy(describeSceneModalProps.selectedSceneLocalities, 'id')}
                            organizations={keyBy([organization], 'id')}
                            placeholder={t('button.locality', 'Locality')}
                            selectedLocality={describeSceneModalProps.selectedLocality}
                            onSelect={(locality) =>
                                describeSceneModalProps.selectedScene
                                    ? history.push(
                                          generateOrganizationEditSceneDescribePath(
                                              organization.id,
                                              describeSceneModalProps.selectedScene.id,
                                              locality.id
                                          )
                                      )
                                    : noop
                            }
                        />
                    ) : undefined

                const describeSceneModal = describeSceneModalProps.selectedLocality && (
                    <DescribeSceneTabModal
                        determineSelectScenePath={describeSceneModalProps.determineSelectScenePath}
                        isDescribeSceneTabModalDisabled={!describeSceneModalProps.isSceneWithLocalities}
                        isDescribeSceneTabModalOpen={describeSceneModalProps.isDescribeSceneModalOpen}
                        organization={organization}
                        picker={picker}
                        sceneDescriptions={sceneInOrganizationConfigurations}
                        scenes={describeSceneModalProps.scenesByLocality}
                        selectedLocality={describeSceneModalProps.selectedLocality}
                        onDescribeSceneTabModalClose={describeSceneModalProps.handleCloseDescribeSceneModal}
                        onDescribeSceneTabModalOpen={describeSceneModalProps.handleOpenDescribeSceneModal}
                    />
                )

                // Installer UI Modal

                const installerUIProps = determineOrganizationInstallerUIProps(location, history, organization.id)

                const tabs: TabList = [
                    {
                        eventKey: OrganizationEditPageTabs.modules,
                        title: (
                            <TabTitle
                                counter={Object.values(organization.features).filter((it) => it === true).length ?? 0}
                                icon={faBox}
                                title={t('tab.modules', 'Modules')}
                            />
                        ),
                        children: (
                            <OrganizationEditForm
                                initialData={organization}
                                initialFeatures={organization.features}
                                submissionStatus={updateOrganizationState}
                                onSubmit={(organization, features) => {
                                    if (!organization.name.trim()) {
                                        notify({
                                            title: t('notification.invalidName', 'Invalid name'),
                                            content: t(
                                                'notification.provideOrganizationName',
                                                'Please provide valid organization name.'
                                            ),
                                            variant: 'warning',
                                            timeoutSeconds: 4,
                                        })

                                        return
                                    }

                                    const promises: Promise<unknown>[] = []

                                    promises.push(
                                        updateOrganization({
                                            organizationId,
                                            body: organization,
                                        })
                                    )

                                    if (features) {
                                        promises.push(
                                            updateOrganizationFeatures({
                                                organizationId,
                                                body: features,
                                            })
                                        )
                                    }

                                    Promise.all(promises)
                                        .then(() => {
                                            clean(organizationApi)
                                        })
                                        .catch(noop)
                                }}
                            />
                        ),
                        renderOnDemand: true,
                    },
                    {
                        eventKey: OrganizationEditPageTabs.localities,
                        title: (
                            <TabTitle
                                counter={orgLocalities.length ?? 0}
                                icon={faMapMarkerAlt}
                                title={t('table.localities', 'Localities')}
                            />
                        ),
                        children: (
                            <LocalitiesTab
                                localities={orgLocalities}
                                localityCreationState={localityCreateState}
                                localityModificationState={localityUpdateState}
                                organization={organization}
                                scenesInOrganization={organizationScenes}
                                users={users}
                                onLocalityCreate={localityCreate}
                                onLocalityDelete={localityDelete}
                                onLocalityUpdate={localityUpdate}
                            />
                        ),
                        renderOnDemand: true,
                    },
                    {
                        eventKey: OrganizationEditPageTabs.scenes,
                        title: (
                            <TabTitle
                                counter={organizationScenes.length}
                                icon={faStreetView}
                                title={t('tab.scenes', 'Scenes')}
                            />
                        ),
                        children: (
                            <ScenesTab
                                key={location.pathname}
                                assignableDevices={organizationDevices.devices}
                                describeSceneModal={describeSceneModal}
                                determineAssignDevicePath={installerUIProps.determineAssignDevicePath}
                                determineCreateScenePath={determineOrganizationCreateSceneUrl}
                                determineEditScenePath={determineOrganizationEditSceneUrl}
                                handleCloseInstallerUIModal={installerUIProps.handleCloseInstallerUIModal}
                                handleInstallerUIModalSceneSelect={installerUIProps.handleInstallerUIModalSceneSelect}
                                handleOpenInstallerUIModal={installerUIProps.handleOpenInstallerUIModal}
                                installerUIView={installerUIProps.installerUIView}
                                isInstallerUIOpen={installerUIProps.isInstallerUIOpen}
                                isNewScene={isNewOrganizationScene}
                                isTabActive={tabKey === OrganizationEditPageTabs.scenes && isOrganizationDeviceListPage}
                                localities={visibleLocalities}
                                mappings={undefined}
                                organization={organization}
                                sceneDevices={organizationDevices.devices}
                                selectableScenes={organizationScenes}
                                visibleDevices={visibleDevices}
                            />
                        ),
                        renderOnDemand: true,
                    },
                    {
                        eventKey: OrganizationEditPageTabs.users,
                        title: (
                            <TabTitle counter={users.length} icon={faUserFriends} title={t('form.users', 'Users')} />
                        ),
                        children: (
                            <UsersTab
                                determineCreateUserPath={determineOrganizationCreateUserUrl}
                                determineEditUsersPath={determineOrganizationEditUserUrl}
                                isNewUser={isNewOrganizationUser}
                                isTabActive={tabKey === OrganizationEditPageTabs.users && isOrganizationUserListPage}
                                localities={orgLocalities}
                                organization={organization}
                                users={users}
                            />
                        ),
                        renderOnDemand: true,
                    },
                    {
                        eventKey: OrganizationEditPageTabs.dataPublishers,
                        title: (
                            <TabTitle
                                counter={dataPublishers.mqttPublishers.length ?? 0}
                                icon={faInboxOut}
                                title={t('tab.dataPublishers', 'Data publishers')}
                            />
                        ),
                        children: (
                            <DataPublishersForm
                                dataPublishers={dataPublishers}
                                localities={localities}
                                organization={organization}
                            />
                        ),
                        renderOnDemand: true,
                    },
                ]

                if (isAdmin || isOrgOwnerOrAdmin) {
                    tabs.splice(3, 0, {
                        eventKey: OrganizationEditPageTabs.devices,
                        title: (
                            <TabTitle
                                counter={organizationDevices.devices.length}
                                icon={faVideo}
                                title={t('tab.devices', 'Devices')}
                            />
                        ),
                        children: (
                            <DevicesTab
                                allDevicesWithNoScene={allDevicesWithNoScene}
                                assignableDevices={readyDevices}
                                determineCreateDevicePath={determineOrganizationCreateDeviceUrl}
                                determineEditDevicePath={determineOrganizationEditDeviceUrl}
                                isNewDevice={isNewOrganizationDevice}
                                isTabActive={tabKey === OrganizationEditPageTabs.devices && isOrganizationDeviceTabPage}
                                organizationDevices={organizationDevices.devices}
                                organizationScenes={organizationScenes}
                                profile={profileCall}
                                selectedOrganization={organization}
                                visibleOrganizations={visibleOrganizations}
                            />
                        ),
                    })
                }

                if (!isAdmin) {
                    remove(tabs, (tab) => tab.eventKey === OrganizationEditPageTabs.modules)
                }

                return (
                    <TabbedPageLayout
                        activeKey={tabKey}
                        breadcrumbs={isAdmin ? breadcrumbs : []}
                        documentTitle={heading}
                        heading={organization.name}
                        tabs={tabs}
                        onSelect={(tab) => {
                            trackEvent(
                                GoogleEventCategory.ORGANIZATION,
                                organizationTabEvent[tab ?? OrganizationEditPageTabs.modules],
                                organization.name,
                                profileCall.data?.email
                            )
                            history.push(
                                generateEditOrganizationPath(organization.id, tab ?? OrganizationEditPageTabs.modules)
                            )
                        }}
                    />
                )
            }}
        </LegacyLoadingWrapper>
    )
}

export default EditOrganizationPage
