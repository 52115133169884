import { uniqueId } from 'lodash'
import React, { useMemo } from 'react'
import { OverlayTrigger, Popover } from 'react-bootstrap'
import { Placement } from 'react-bootstrap/Overlay'

import styles from './TooltipIcon.module.scss'

interface TooltipProps {
    tooltipText: string
    tooltipPosition?: Placement
    children: JSX.Element
}

const TooltipWrapper: React.FC<TooltipProps> = ({ children, tooltipText, tooltipPosition }) => {
    const popoverId = useMemo(uniqueId, [])

    const PopoverBody = (
        <Popover className={styles.overlayPopover} id={popoverId}>
            <Popover.Content>
                <span>{tooltipText}</span>
            </Popover.Content>
        </Popover>
    )

    return (
        <OverlayTrigger overlay={PopoverBody} placement={tooltipPosition ?? 'left'}>
            {children}
        </OverlayTrigger>
    )
}

export default TooltipWrapper
