import { faDownload } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { flatten, isEmpty, orderBy, round } from 'lodash'
import { DateTime, Interval } from 'luxon'
import React from 'react'
import { Button } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import { LocalityResponse, ZoneOccupancySessionsResponse, ZoneOccupancySummaryResponse } from '@api'

import { gdynusaReadableInterval } from '@helpers/datetimeUtils'
import { GoogleEventName } from '@helpers/gtag'
import { formatIntervalToFilename } from '@helpers/intervals'
import { useLocalizeDateTime } from '@helpers/timezoneConfig'
import { ExportData, exportToExcel } from '@helpers/xlsxExporter'

import { parseInterval } from '@components/StatisticsSummary/utils'
import { useNotify } from '@components/notifications/NotificationsContext'

interface ExportButtonProps {
    selectedLocality: LocalityResponse
    zoneOccupancySummary: ZoneOccupancySummaryResponse
    zoneOccupancySessions: ZoneOccupancySessionsResponse
    pickedInterval: Interval
}

export const OccupancyDetailsExportButton: React.FC<ExportButtonProps> = ({
    selectedLocality,
    zoneOccupancySessions,
    pickedInterval,
    zoneOccupancySummary,
}) => {
    const { t } = useTranslation()

    const localize = useLocalizeDateTime()
    const notify = useNotify()

    const activeTimeBrackets = zoneOccupancySummary.timeBrackets.filter(
        (_, i) => zoneOccupancySummary.openedPortions[i] !== 0
    )

    const activeZoneRows = zoneOccupancySummary.zoneRows.map(({ zoneName, deviceId, cells }) => ({
        deviceId,
        zoneName,
        cells: cells.filter((_, i) => zoneOccupancySummary.openedPortions[i] !== 0),
    }))

    const handleDownload = () => {
        const exportData: ExportData = {
            [t('occupancy.sessionsExcelSheetTitle', 'Occupancy sessions list')]: orderBy(
                zoneOccupancySessions.sessions.map((it) => {
                    const interval = parseInterval(it).mapEndpoints(localize)

                    return {
                        [t('table.zone', 'Zone')]: it.zoneName,
                        [t('table.sessionStart', 'Session starting at')]: interval.start.toLocaleString({
                            ...DateTime.DATETIME_SHORT,
                            weekday: 'short',
                        }),
                        [t('table.sessionEnd', 'Session ending at')]: interval.end.toLocaleString({
                            ...DateTime.DATETIME_SHORT,
                            weekday: 'short',
                        }),
                        [t('table.avgNumberPeople', 'Number of people')]: Math.max(1, round(it.averagePersons ?? 0)),
                        [t('table.sessionDuration', 'Session duration (s)')]:
                            interval.toDuration('seconds').toObject().seconds ?? 0,
                    }
                }),
                t('table.zone', 'Zone'),
                'desc'
            ),
            [t('occupancy.occupancyExcelSheetTitle', 'Occupancy summary')]: flatten(
                activeZoneRows.map((zone) =>
                    activeTimeBrackets.map((bracket, index) => {
                        const interval = parseInterval(bracket).mapEndpoints(localize)

                        return {
                            [t('table.zone', 'Zone')]: zone.zoneName,
                            [t('table.occupancyIntervalStart', 'Occupancy interval starting at')]:
                                interval.start.toLocaleString({
                                    ...DateTime.DATETIME_SHORT,
                                    weekday: 'short',
                                }),
                            [t('table.occupancyIntervalEnd', 'Occupancy interval ending at')]:
                                interval.end.toLocaleString({
                                    ...DateTime.DATETIME_SHORT,
                                    weekday: 'short',
                                }),
                            [t('button.occupancyPercentage', 'Occupancy (%)')]: round(zone.cells[index], 2) * 100,
                        }
                    })
                )
            ),
        }

        if (!isEmpty(exportData)) {
            exportToExcel(
                exportData,
                `${selectedLocality?.name}-occupancy-details-${formatIntervalToFilename(
                    gdynusaReadableInterval(pickedInterval.mapEndpoints(localize))
                )}`,
                GoogleEventName.DOWNLOAD_OCCUPANCY_LOCALITY_EXCEL
            )
        } else {
            notify({
                title: t('notification.error', 'Error'),
                content: t('notification.nothingToExport', 'Nothing to export'),
                variant: 'danger',
            })
        }
    }

    return (
        <Button variant="secondary" onClick={handleDownload}>
            <FontAwesomeIcon icon={faDownload} />
            {t('button.downloadExcel', 'Download Excel')}
        </Button>
    )
}
