import React from 'react'
import { Card, Col, Container, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import styles from './ErrorView.module.scss'

interface Props {
    message: string
    title?: string
}

const ErrorView: React.FC<Props> = (props) => {
    const { t } = useTranslation()

    return (
        <Container className={styles.errorContainer} fluid={true}>
            <Row>
                <Col md={12}>
                    <Card>
                        <Card.Header>{props.title ?? t('notification.error', 'Error')}</Card.Header>
                        <Card.Body>{props.message}</Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}

export default ErrorView
