import produce from 'immer'
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { DetectionZoneWithId, LocalityResponse, SceneResponse, VisitBoundaryWithId } from '@api'

import { sceneApi } from '@services'

import { useSuspendingQuery } from '@helpers/api'
import { passCSSVariable } from '@helpers/cssUtils'
import { getImageSize } from '@helpers/images'

import styles from './ConfigurationHeatmapCarousel.module.scss'
import LoadingSpinner from './LoadingSpinner'
import LoadingWrapper from './LoadingWrapper'
import { OverlayedBoundaryHeatmapWithModal } from './OverlayedBoundaryHeatmap'

type HeatmapThumbnailProps = {
    locality?: LocalityResponse
    scene: SceneResponse
    drawBoundaries: boolean
    drawBoundaryDirection: boolean
    drawZones: boolean
    boundaries?: VisitBoundaryWithId[]
    zones?: DetectionZoneWithId[]
    zoneLabels?: { [zoneId: number]: string }
    zoneLabelRenderer?: React.FC<CarouselCardHeader>
}

export type CarouselCardHeader = {
    name: string
    value: string
}

const HeatmapThumbnail = ({
    locality,
    scene,
    drawBoundaries,
    drawBoundaryDirection,
    drawZones,
    boundaries,
    zones,
    zoneLabels,
    zoneLabelRenderer,
}: HeatmapThumbnailProps) => {
    const { data: snapshotRequest } = useSuspendingQuery(
        sceneApi.getTrajectoryHeatmap.query({
            sceneId: scene.id,
        })
    )

    const src = useMemo(() => window.URL.createObjectURL(snapshotRequest), [snapshotRequest])

    return (
        <ImageView
            boundaries={boundaries}
            drawBoundaries={drawBoundaries}
            drawBoundaryDirection={drawBoundaryDirection}
            drawZones={drawZones}
            locality={locality}
            scene={scene}
            src={src}
            zoneLabelRenderer={zoneLabelRenderer}
            zoneLabels={zoneLabels}
            zones={zones}
        />
    )
}

interface ImageViewProps extends HeatmapThumbnailProps {
    src?: string
}

const ImageView: React.FC<ImageViewProps> = ({
    src,
    scene,
    drawBoundaries,
    drawZones,
    boundaries,
    zones,
    zoneLabels,
    zoneLabelRenderer,
    drawBoundaryDirection,
}) => {
    const scale = 0.25

    const [dimensions, setDimensions] = useState<{
        width: number
        height: number
    } | null>(null)

    useEffect(() => {
        if (!src) {
            return
        }
        getImageSize(src).then((size) => {
            setDimensions({
                height: size.height * scale,
                width: size.width * scale,
            })
        })
    }, [src])

    const configurationObjects: Array<
        VisitBoundaryWithId & {
            label?: string
        }
    > = [
        ...(drawBoundaries && boundaries !== undefined ? boundaries : []),
        ...(drawZones && zones !== undefined
            ? zones.map((z) => ({
                  ...z,
                  label: zoneLabels?.[z.id],
                  points: [...z.points, z.points[0]],
              }))
            : []),
    ]

    const scaledConfigurationObjects = produce(configurationObjects, (draft) => {
        for (const item of draft) {
            for (const point of item.points) {
                point.x *= scale
                point.y *= scale
            }
        }
    })

    const OverlayedBoundaryHeader = ({ name, value }: CarouselCardHeader) => {
        return (
            <div className={styles.overlayedBoundaryHeader}>
                <div className={styles.highlightedValue}>{value}</div>
                <div>
                    <strong>{name}</strong>
                </div>
            </div>
        )
    }

    const HeaderComponent = zoneLabelRenderer ?? OverlayedBoundaryHeader

    return (
        <>
            {scaledConfigurationObjects.map((configurationObject) => {
                return (
                    dimensions &&
                    src && (
                        <OverlayedBoundaryHeatmapWithModal
                            key={configurationObject.id}
                            boundary={configurationObject}
                            dimensions={dimensions}
                            drawBoundaryDirection={drawBoundaryDirection}
                            overlayedBoundaryHeader={
                                configurationObject.label ? (
                                    <HeaderComponent
                                        key={`header-${configurationObject.id}`}
                                        name={configurationObject.name}
                                        value={configurationObject.label}
                                    />
                                ) : undefined
                            }
                            scale={scale}
                            scene={scene}
                            src={src}
                        />
                    )
                )
            })}
        </>
    )
}

interface ConfigurationHeatmapCarouselProps {
    locality?: LocalityResponse
    scenes: Array<SceneResponse>
    drawBoundaries: boolean
    drawBoundaryDirection?: boolean
    drawZones: boolean
    carouselWidth?: string
    boundaries?: { [deviceId: number]: VisitBoundaryWithId[] }
    zones?: { [deviceId: number]: DetectionZoneWithId[] }
    zoneLabels?: { [zoneId: number]: string }
    zoneLabelRenderer?: React.FC<CarouselCardHeader>
}

export const ConfigurationHeatmapCarousel: React.FC<ConfigurationHeatmapCarouselProps> = ({
    scenes,
    drawBoundaries,
    drawBoundaryDirection = false,
    drawZones,
    locality,
    carouselWidth,
    boundaries,
    zones,
    zoneLabels,
    zoneLabelRenderer,
}) => {
    const { t } = useTranslation()

    return (
        <div
            className={styles.heatmapThumbnailContainer}
            style={carouselWidth ? passCSSVariable('CarouselWidth', carouselWidth) : undefined}
        >
            {scenes.map((scene) => (
                <LoadingWrapper
                    key={scene.id}
                    errorComponent={<div>{t('others.imageNotAvailable', 'Image not available')}</div>}
                    placeholder={<LoadingSpinner bare={true} />}
                >
                    <HeatmapThumbnail
                        boundaries={boundaries?.[scene.id]}
                        drawBoundaries={drawBoundaries}
                        drawBoundaryDirection={drawBoundaryDirection}
                        drawZones={drawZones}
                        locality={locality}
                        scene={scene}
                        zoneLabelRenderer={zoneLabelRenderer}
                        zoneLabels={zoneLabels}
                        zones={zones?.[scene.id]}
                    />
                </LoadingWrapper>
            ))}
        </div>
    )
}
