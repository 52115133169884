import { noop } from 'lodash'
import React from 'react'
import { Accordion, Card, Button } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import styles from './TermsAndConditions.module.scss'
import { TermsAndConditions } from './content'

const Section: React.FC<{
    eventKey: TermsAndConditionsTab
    selectedEventKey?: TermsAndConditionsTab
    title: string
    content: JSX.Element
    onSelect: (tab?: TermsAndConditionsTab) => void
    hiddenTabs: TermsAndConditionsTab[]
}> = (props) => {
    if (props.hiddenTabs.includes(props.eventKey)) {
        return null
    }

    return (
        <Card>
            <Card.Header>
                <Accordion.Toggle
                    as={Button}
                    eventKey={props.eventKey}
                    variant="button"
                    onClick={() =>
                        props.onSelect(props.eventKey !== props.selectedEventKey ? props.eventKey : undefined)
                    }
                >
                    {props.title}
                </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey={props.eventKey}>
                <Card.Body>{props.content}</Card.Body>
            </Accordion.Collapse>
        </Card>
    )
}

export enum TermsAndConditionsTab {
    COOKIES = 'cookies',
    EULA = 'eula',
    PRIVACY = 'privacy-policy',
    SALE = 'terms-of-sale',
    SERVICE = 'terms-of-service',
}

interface Props {
    lastSeenVersion?: TermsAndConditions
    currentVersion: TermsAndConditions
    selectedTab?: TermsAndConditionsTab
    onSelect?: (tab?: TermsAndConditionsTab) => void
    hiddenTabs?: TermsAndConditionsTab[]
}

const TermsAndConditionsView: React.FC<Props> = ({ currentVersion, selectedTab, onSelect = noop, hiddenTabs = [] }) => {
    const { t } = useTranslation()

    return (
        <Accordion activeKey={selectedTab} className={styles.termsAndConditions}>
            <Section
                content={currentVersion.cookiePolicy}
                eventKey={TermsAndConditionsTab.COOKIES}
                hiddenTabs={hiddenTabs}
                selectedEventKey={selectedTab}
                title={t('termsAndConditions.cookiePolicy', 'Cookie Policy')}
                onSelect={onSelect}
            />
            <Section
                content={currentVersion.eula}
                eventKey={TermsAndConditionsTab.EULA}
                hiddenTabs={hiddenTabs}
                selectedEventKey={selectedTab}
                title={t('termsAndConditions.endUserLicenseAgreement', 'End User License Agreement')}
                onSelect={onSelect}
            />
            <Section
                content={currentVersion.privacyPolicy}
                eventKey={TermsAndConditionsTab.PRIVACY}
                hiddenTabs={hiddenTabs}
                selectedEventKey={selectedTab}
                title={t('termsAndConditions.privacyPolicy', 'Privacy Policy')}
                onSelect={onSelect}
            />
            <Section
                content={currentVersion.termsOfSale}
                eventKey={TermsAndConditionsTab.SALE}
                hiddenTabs={hiddenTabs}
                selectedEventKey={selectedTab}
                title={t('termsAndConditions.termsOfSaleTitle', 'Terms of Sale')}
                onSelect={onSelect}
            />
            <Section
                content={currentVersion.termsOfService}
                eventKey={TermsAndConditionsTab.SERVICE}
                hiddenTabs={hiddenTabs}
                selectedEventKey={selectedTab}
                title={t('termsAndConditions.termsOfServiceTitle', 'Terms of Service')}
                onSelect={onSelect}
            />
        </Accordion>
    )
}

export default TermsAndConditionsView
