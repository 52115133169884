import { DateTime, DurationUnit, Interval, Zone } from 'luxon'

import { IntervalType } from './types'

export const localeAwareAlign = (dateTime: DateTime, unit: DurationUnit, zone: Zone) =>
    dateTime.setZone(zone).startOf(unit).toUTC()

export const alignPickedInterval = (
    zone: Zone,
    intervalType: IntervalType,
    intervalStart: DateTime,
    intervalEnd?: DateTime
): Interval => {
    if (intervalType === IntervalType.CUSTOM) {
        if (intervalEnd === undefined) {
            throw new Error('End date missing for custom interval')
        }

        return Interval.fromDateTimes(intervalStart, intervalEnd)
    }

    const alignedStart = localeAwareAlign(intervalStart, intervalType, zone)

    if (intervalType === IntervalType.MONTH) {
        const localizedStart = alignedStart.setZone(zone)
        const localizedEnd =
            localizedStart.month === 12
                ? localizedStart.set({
                      year: localizedStart.year + 1,
                      month: 1,
                  })
                : localizedStart.set({ month: localizedStart.month + 1 })

        return Interval.fromDateTimes(alignedStart, localizedEnd.setZone('utc'))
    }

    return Interval.fromDateTimes(
        alignedStart,
        alignedStart
            .setZone(zone)
            .plus({ [intervalType]: 1 })
            .setZone('utc')
    )
}

/**
 * For better readability of the displayed interval ranges we decided to cut the last millisecond.
 *
 * The effect is that two chained intervals do not overlap on midnight so
 * (10/2/2020 23:00 - 00:00, 0/2/2020 00:00 - 01:00) becomes (10/2/2020 23:00 - 23:59, 10/3/2020 00:00 - 01:00)
 * and so on for quarter hour, half hour, hour, weeks, etc.
 * @param interval
 */
export const gdynusaReadableInterval = (interval: Interval): Interval =>
    interval.length('milliseconds') >= 1
        ? Interval.fromDateTimes(interval.start, interval.end.minus({ milliseconds: 1 }))
        : interval

export const splitAtMidnight = (interval: Interval, zone: Zone): Array<Interval> => {
    const nodes: Array<DateTime> = []
    let cursor = interval.start.setZone(zone).startOf('day').plus({ days: 1 })

    while (cursor < interval.end.setZone(zone)) {
        nodes.push(cursor.setZone('utc'))
        cursor = cursor.plus({ days: 1 })
    }

    if (nodes.length === 0) {
        return [interval]
    }

    return interval.splitAt(...nodes)
}
