/* tslint:disable */
/* eslint-disable */
/**
 * Vividi Dashboard API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SceneDeviceAssignment
 */
export interface SceneDeviceAssignment {
    /**
     * 
     * @type {number}
     * @memberof SceneDeviceAssignment
     */
    deviceId: number;
    /**
     * 
     * @type {string}
     * @memberof SceneDeviceAssignment
     */
    assignedAt: string;
    /**
     * 
     * @type {string}
     * @memberof SceneDeviceAssignment
     */
    removedAt?: string;
}

export function SceneDeviceAssignmentFromJSON(json: any): SceneDeviceAssignment {
    return SceneDeviceAssignmentFromJSONTyped(json, false);
}

export function SceneDeviceAssignmentFromJSONTyped(json: any, ignoreDiscriminator: boolean): SceneDeviceAssignment {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'deviceId': json['device_id'],
        'assignedAt': json['assigned_at'],
        'removedAt': !exists(json, 'removed_at') ? undefined : json['removed_at'],
    };
}

export function SceneDeviceAssignmentToJSON(value?: SceneDeviceAssignment | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'device_id': value.deviceId,
        'assigned_at': value.assignedAt,
        'removed_at': value.removedAt,
    };
}

