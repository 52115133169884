/* tslint:disable */
/* eslint-disable */
/**
 * Vividi Dashboard API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LocalityFloorplanDetails
 */
export interface LocalityFloorplanDetails {
    /**
     * 
     * @type {string}
     * @memberof LocalityFloorplanDetails
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof LocalityFloorplanDetails
     */
    scale?: number;
}

export function LocalityFloorplanDetailsFromJSON(json: any): LocalityFloorplanDetails {
    return LocalityFloorplanDetailsFromJSONTyped(json, false);
}

export function LocalityFloorplanDetailsFromJSONTyped(json: any, ignoreDiscriminator: boolean): LocalityFloorplanDetails {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'scale': !exists(json, 'scale') ? undefined : json['scale'],
    };
}

export function LocalityFloorplanDetailsToJSON(value?: LocalityFloorplanDetails | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'scale': value.scale,
    };
}

