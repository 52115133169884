import { faMapMarkerAlt, faCalendar, faHistory } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import { first, pickBy } from 'lodash'
import { DateTime, Interval } from 'luxon'
import React, { useState, useRef } from 'react'
import { Button } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import { LocalityNameModel, OrganizationResponse, Role } from '@api/models'

import { gdynusaReadableInterval } from '@helpers/datetimeUtils'
import { GoogleEventCategory, GoogleEventName, trackEvent } from '@helpers/gtag'
import { formatInterval, getComparisonInterval, getComparisonIntervalText, Precision } from '@helpers/intervals'
import { useLocalizeDateTime, useTimezoneConfig } from '@helpers/timezoneConfig'

import Heading from '@elements/Heading/Heading'
import ToggleIcon from '@elements/ToggleIcon/ToggleIcon'

import IntervalComparisonPicker from '@components/DateTimeIntervalPicker/IntervalComparisonPicker'
import { createDateRanges } from '@components/DateTimeIntervalPicker/dateRangePicker'
import RoleChecker from '@components/RoleChecker'

import buttonStyles from './FilterButton.module.scss'
import LocalityFilterButton from './LocalityFilterButton'
import OrganizationFilterButton from './OrganizationFilterButton'
import styles from './PageTitle.module.scss'

type Props = {
    children: React.ReactNode
    interval: Interval
    comparisonInterval?: Interval
    localities: LocalityNameModel[]
    selectedLocalities: LocalityNameModel[]
    onLocalitiesChange: (localities: LocalityNameModel[]) => void
    organizations: OrganizationResponse[]
    selectedOrganization?: OrganizationResponse
    onSelectOrganization: (organization: OrganizationResponse) => void
    onCustomIntervalChange: (interval: Interval, comparisonInterval?: Interval) => void
}

const PageTitle = ({
    children,
    interval,
    comparisonInterval,
    localities,
    organizations,
    selectedOrganization,
    onSelectOrganization,
    selectedLocalities,
    onLocalitiesChange,
    onCustomIntervalChange,
}: Props) => {
    const { t } = useTranslation()

    const localize = useLocalizeDateTime()
    const zone = useTimezoneConfig()
    const [isCustomPopoverOpen, setCustomPopoverOpen] = useState(false)
    const [now] = useState(DateTime.utc())

    const buttonRef = useRef<HTMLButtonElement>()

    const handleSubmit = (interval: Interval, comparingInterval?: Interval) => {
        onCustomIntervalChange(interval, comparingInterval)
        setCustomPopoverOpen(false)
    }

    const ranges = createDateRanges(now, zone, t)

    const matchRange = (interval: Interval) =>
        first(
            Object.keys(
                pickBy(ranges, ([startDate, endDate]) => {
                    const subject = interval.mapEndpoints(localize)

                    return (
                        subject.start.toJSDate().getTime() === startDate.getTime() &&
                        subject.end.toJSDate().getTime() === endDate.getTime()
                    )
                })
            )
        )

    const currentIntervalLabel =
        matchRange(interval) ??
        formatInterval(gdynusaReadableInterval(interval.mapEndpoints(localize)), Precision.DAY, DateTime.DATE_SHORT)

    const comparingIntervalLabel =
        comparisonInterval &&
        (matchRange(comparisonInterval) ??
            formatInterval(
                gdynusaReadableInterval(comparisonInterval.mapEndpoints(localize)),
                Precision.DAY,
                DateTime.DATE_SHORT
            ))

    const handleCheckCompareWithPreviousPeriod = () => {
        if (comparisonInterval === undefined) {
            const comparisonInterval = getComparisonInterval(interval, zone)
            onCustomIntervalChange(interval, comparisonInterval)

            const comparingIntervalLabel =
                matchRange(comparisonInterval) ??
                formatInterval(
                    gdynusaReadableInterval(comparisonInterval.mapEndpoints(localize)),
                    Precision.DAY,
                    DateTime.DATE_SHORT
                )

            trackEvent(
                GoogleEventCategory.STATISTICS,
                GoogleEventName.COMPARE_WITH_PREVIOUS_INTERVAL_BUTTON,
                `${currentIntervalLabel} vs. ${comparingIntervalLabel}`
            )
        } else {
            onCustomIntervalChange(interval)
        }
    }

    const getTooltipLabel = () => {
        if (comparisonInterval === undefined) {
            if (interval === undefined || interval.start >= interval.end) {
                return t('others.compareWithPreviousPeriod', 'Compare with previous period')
            }

            return getComparisonIntervalText(interval, zone, t)
        }

        return t('tooltip.hideTrends', 'Hide history trends')
    }

    return (
        <div className={styles.titleContainer}>
            <Heading children={children} level={1} variant="page" />
            <div className={styles.buttonsContainer}>
                <RoleChecker whitelist={[Role.Administrator]}>
                    <OrganizationFilterButton
                        organizations={organizations}
                        selectedOrganization={selectedOrganization}
                        onSelectOrganization={onSelectOrganization}
                    />
                </RoleChecker>
                <LocalityFilterButton
                    icon={faMapMarkerAlt}
                    localities={localities}
                    selectedLocalities={selectedLocalities}
                    onLocalitiesChange={onLocalitiesChange}
                />
                <Button
                    ref={buttonRef as any}
                    className={classNames(
                        buttonStyles.filterButton,
                        buttonStyles.intervalPicker,
                        comparisonInterval && buttonStyles.comparing
                    )}
                    onClick={() => setCustomPopoverOpen(!isCustomPopoverOpen)}
                >
                    <FontAwesomeIcon className={buttonStyles.icon} icon={faCalendar} />
                    <span className={buttonStyles.intervalTextLabel}>
                        {currentIntervalLabel}
                        <br />
                        {comparisonInterval !== undefined && comparingIntervalLabel}
                    </span>
                    {comparisonInterval && <span className={buttonStyles.vsText}>vs.</span>}
                </Button>
                <IntervalComparisonPicker
                    key={isCustomPopoverOpen.toString()}
                    buttonRef={buttonRef}
                    isOpen={isCustomPopoverOpen}
                    isTimeDisabled={true}
                    now={now}
                    pickedComparisonInterval={comparisonInterval}
                    pickedInterval={interval}
                    onOpen={setCustomPopoverOpen}
                    onSubmit={handleSubmit}
                />
                <ToggleIcon
                    icon={faHistory}
                    selected={comparisonInterval !== undefined}
                    tooltipText={getTooltipLabel()}
                    onToggle={handleCheckCompareWithPreviousPeriod}
                />
            </div>
        </div>
    )
}

export default PageTitle
