/* tslint:disable */
/* eslint-disable */
/**
 * Vividi Dashboard API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ConversionsOverviewItem,
    ConversionsOverviewItemFromJSON,
    ConversionsOverviewItemFromJSONTyped,
    ConversionsOverviewItemToJSON,
} from './ConversionsOverviewItem';

/**
 * 
 * @export
 * @interface ConversionsOverviewResponse
 */
export interface ConversionsOverviewResponse {
    /**
     * Conversion overview list for each requested locality
     * @type {Array<ConversionsOverviewItem>}
     * @memberof ConversionsOverviewResponse
     */
    itemsByLocality: Array<ConversionsOverviewItem>;
}

export function ConversionsOverviewResponseFromJSON(json: any): ConversionsOverviewResponse {
    return ConversionsOverviewResponseFromJSONTyped(json, false);
}

export function ConversionsOverviewResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConversionsOverviewResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'itemsByLocality': ((json['items_by_locality'] as Array<any>).map(ConversionsOverviewItemFromJSON)),
    };
}

export function ConversionsOverviewResponseToJSON(value?: ConversionsOverviewResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'items_by_locality': ((value.itemsByLocality as Array<any>).map(ConversionsOverviewItemToJSON)),
    };
}

