import { Location } from 'history'
import React from 'react'

interface Props {
    placeholder: JSX.Element | null
    location: Location
}

interface State {
    error?: any
}

class ErrorBoundary extends React.Component<Props, State> {
    state: State = {}

    public static getDerivedStateFromError(error: any) {
        return { error }
    }

    componentDidUpdate(prevProps: Props) {
        if (prevProps.location.pathname !== this.props.location.pathname) {
            this.setState({ error: undefined })
        }
    }

    public render() {
        if (this.state.error !== undefined) {
            return this.props.placeholder
        }

        return this.props.children
    }
}

export default ErrorBoundary
