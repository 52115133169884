/* tslint:disable */
/* eslint-disable */
/**
 * Vividi Dashboard API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GeneratedReport,
    GeneratedReportFromJSON,
    GeneratedReportFromJSONTyped,
    GeneratedReportToJSON,
} from './GeneratedReport';

/**
 * 
 * @export
 * @interface GeneratedReportList
 */
export interface GeneratedReportList {
    /**
     * 
     * @type {Array<GeneratedReport>}
     * @memberof GeneratedReportList
     */
    reports: Array<GeneratedReport>;
}

export function GeneratedReportListFromJSON(json: any): GeneratedReportList {
    return GeneratedReportListFromJSONTyped(json, false);
}

export function GeneratedReportListFromJSONTyped(json: any, ignoreDiscriminator: boolean): GeneratedReportList {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'reports': ((json['reports'] as Array<any>).map(GeneratedReportFromJSON)),
    };
}

export function GeneratedReportListToJSON(value?: GeneratedReportList | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'reports': ((value.reports as Array<any>).map(GeneratedReportToJSON)),
    };
}

