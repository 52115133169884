/* tslint:disable */
/* eslint-disable */
/**
 * Vividi Dashboard API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DeviceStatusInformation
 */
export interface DeviceStatusInformation {
    /**
     * 
     * @type {string}
     * @memberof DeviceStatusInformation
     */
    vividiVersion?: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceStatusInformation
     */
    vbmVersion?: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceStatusInformation
     */
    vbmVividiImageName?: string;
    /**
     * 
     * @type {number}
     * @memberof DeviceStatusInformation
     */
    uptime?: number;
    /**
     * 
     * @type {number}
     * @memberof DeviceStatusInformation
     */
    fps?: number;
    /**
     * 
     * @type {number}
     * @memberof DeviceStatusInformation
     */
    cpuTemperature?: number;
    /**
     * 
     * @type {number}
     * @memberof DeviceStatusInformation
     */
    tpuTemperature?: number;
    /**
     * 
     * @type {number}
     * @memberof DeviceStatusInformation
     */
    ramTotal?: number;
    /**
     * 
     * @type {number}
     * @memberof DeviceStatusInformation
     */
    ramFree?: number;
    /**
     * 
     * @type {number}
     * @memberof DeviceStatusInformation
     */
    swapTotal?: number;
    /**
     * 
     * @type {number}
     * @memberof DeviceStatusInformation
     */
    swapFree?: number;
    /**
     * 
     * @type {number}
     * @memberof DeviceStatusInformation
     */
    spaceCapacity?: number;
    /**
     * 
     * @type {number}
     * @memberof DeviceStatusInformation
     */
    spaceAvailable?: number;
    /**
     * 
     * @type {number}
     * @memberof DeviceStatusInformation
     */
    cpuLoadavg1?: number;
    /**
     * 
     * @type {number}
     * @memberof DeviceStatusInformation
     */
    cpuLoadavg5?: number;
    /**
     * 
     * @type {number}
     * @memberof DeviceStatusInformation
     */
    cpuLoadavg15?: number;
    /**
     * 
     * @type {boolean}
     * @memberof DeviceStatusInformation
     */
    undervoltageNow?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DeviceStatusInformation
     */
    undervoltageEver?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DeviceStatusInformation
     */
    armFreqCappedNow?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DeviceStatusInformation
     */
    armFreqCappedEver?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DeviceStatusInformation
     */
    throttledNow?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DeviceStatusInformation
     */
    throttledEver?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DeviceStatusInformation
     */
    softTempLimitNow?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DeviceStatusInformation
     */
    softTempLimitEver?: boolean;
    /**
     * 
     * @type {number}
     * @memberof DeviceStatusInformation
     */
    generation?: number;
    /**
     * 
     * @type {string}
     * @memberof DeviceStatusInformation
     */
    imsi?: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceStatusInformation
     */
    iccid?: string;
    /**
     * 
     * @type {number}
     * @memberof DeviceStatusInformation
     */
    socketUptime?: number;
    /**
     * 
     * @type {number}
     * @memberof DeviceStatusInformation
     */
    brightness?: number;
    /**
     * 
     * @type {number}
     * @memberof DeviceStatusInformation
     */
    cameraShiftProbability?: number;
    /**
     * 
     * @type {boolean}
     * @memberof DeviceStatusInformation
     */
    isReferenceCaptureSet?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DeviceStatusInformation
     */
    lastProcessedCaptureTimestamp?: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceStatusInformation
     */
    lastDatabaseTimestamp?: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceStatusInformation
     */
    lastRefocusTimestamp?: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceStatusInformation
     */
    modelVersion?: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceStatusInformation
     */
    vehicleModelVersion?: string;
}

export function DeviceStatusInformationFromJSON(json: any): DeviceStatusInformation {
    return DeviceStatusInformationFromJSONTyped(json, false);
}

export function DeviceStatusInformationFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeviceStatusInformation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'vividiVersion': !exists(json, 'vividi_version') ? undefined : json['vividi_version'],
        'vbmVersion': !exists(json, 'vbm_version') ? undefined : json['vbm_version'],
        'vbmVividiImageName': !exists(json, 'vbm_vividi_image_name') ? undefined : json['vbm_vividi_image_name'],
        'uptime': !exists(json, 'uptime') ? undefined : json['uptime'],
        'fps': !exists(json, 'fps') ? undefined : json['fps'],
        'cpuTemperature': !exists(json, 'cpu_temperature') ? undefined : json['cpu_temperature'],
        'tpuTemperature': !exists(json, 'tpu_temperature') ? undefined : json['tpu_temperature'],
        'ramTotal': !exists(json, 'ram_total') ? undefined : json['ram_total'],
        'ramFree': !exists(json, 'ram_free') ? undefined : json['ram_free'],
        'swapTotal': !exists(json, 'swap_total') ? undefined : json['swap_total'],
        'swapFree': !exists(json, 'swap_free') ? undefined : json['swap_free'],
        'spaceCapacity': !exists(json, 'space_capacity') ? undefined : json['space_capacity'],
        'spaceAvailable': !exists(json, 'space_available') ? undefined : json['space_available'],
        'cpuLoadavg1': !exists(json, 'cpu_loadavg_1') ? undefined : json['cpu_loadavg_1'],
        'cpuLoadavg5': !exists(json, 'cpu_loadavg_5') ? undefined : json['cpu_loadavg_5'],
        'cpuLoadavg15': !exists(json, 'cpu_loadavg_15') ? undefined : json['cpu_loadavg_15'],
        'undervoltageNow': !exists(json, 'undervoltage_now') ? undefined : json['undervoltage_now'],
        'undervoltageEver': !exists(json, 'undervoltage_ever') ? undefined : json['undervoltage_ever'],
        'armFreqCappedNow': !exists(json, 'arm_freq_capped_now') ? undefined : json['arm_freq_capped_now'],
        'armFreqCappedEver': !exists(json, 'arm_freq_capped_ever') ? undefined : json['arm_freq_capped_ever'],
        'throttledNow': !exists(json, 'throttled_now') ? undefined : json['throttled_now'],
        'throttledEver': !exists(json, 'throttled_ever') ? undefined : json['throttled_ever'],
        'softTempLimitNow': !exists(json, 'soft_temp_limit_now') ? undefined : json['soft_temp_limit_now'],
        'softTempLimitEver': !exists(json, 'soft_temp_limit_ever') ? undefined : json['soft_temp_limit_ever'],
        'generation': !exists(json, 'generation') ? undefined : json['generation'],
        'imsi': !exists(json, 'imsi') ? undefined : json['imsi'],
        'iccid': !exists(json, 'iccid') ? undefined : json['iccid'],
        'socketUptime': !exists(json, 'socket_uptime') ? undefined : json['socket_uptime'],
        'brightness': !exists(json, 'brightness') ? undefined : json['brightness'],
        'cameraShiftProbability': !exists(json, 'camera_shift_probability') ? undefined : json['camera_shift_probability'],
        'isReferenceCaptureSet': !exists(json, 'is_reference_capture_set') ? undefined : json['is_reference_capture_set'],
        'lastProcessedCaptureTimestamp': !exists(json, 'last_processed_capture_timestamp') ? undefined : json['last_processed_capture_timestamp'],
        'lastDatabaseTimestamp': !exists(json, 'last_database_timestamp') ? undefined : json['last_database_timestamp'],
        'lastRefocusTimestamp': !exists(json, 'last_refocus_timestamp') ? undefined : json['last_refocus_timestamp'],
        'modelVersion': !exists(json, 'model_version') ? undefined : json['model_version'],
        'vehicleModelVersion': !exists(json, 'vehicle_model_version') ? undefined : json['vehicle_model_version'],
    };
}

export function DeviceStatusInformationToJSON(value?: DeviceStatusInformation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'vividi_version': value.vividiVersion,
        'vbm_version': value.vbmVersion,
        'vbm_vividi_image_name': value.vbmVividiImageName,
        'uptime': value.uptime,
        'fps': value.fps,
        'cpu_temperature': value.cpuTemperature,
        'tpu_temperature': value.tpuTemperature,
        'ram_total': value.ramTotal,
        'ram_free': value.ramFree,
        'swap_total': value.swapTotal,
        'swap_free': value.swapFree,
        'space_capacity': value.spaceCapacity,
        'space_available': value.spaceAvailable,
        'cpu_loadavg_1': value.cpuLoadavg1,
        'cpu_loadavg_5': value.cpuLoadavg5,
        'cpu_loadavg_15': value.cpuLoadavg15,
        'undervoltage_now': value.undervoltageNow,
        'undervoltage_ever': value.undervoltageEver,
        'arm_freq_capped_now': value.armFreqCappedNow,
        'arm_freq_capped_ever': value.armFreqCappedEver,
        'throttled_now': value.throttledNow,
        'throttled_ever': value.throttledEver,
        'soft_temp_limit_now': value.softTempLimitNow,
        'soft_temp_limit_ever': value.softTempLimitEver,
        'generation': value.generation,
        'imsi': value.imsi,
        'iccid': value.iccid,
        'socket_uptime': value.socketUptime,
        'brightness': value.brightness,
        'camera_shift_probability': value.cameraShiftProbability,
        'is_reference_capture_set': value.isReferenceCaptureSet,
        'last_processed_capture_timestamp': value.lastProcessedCaptureTimestamp,
        'last_database_timestamp': value.lastDatabaseTimestamp,
        'last_refocus_timestamp': value.lastRefocusTimestamp,
        'model_version': value.modelVersion,
        'vehicle_model_version': value.vehicleModelVersion,
    };
}

