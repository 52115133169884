import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon as FAIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import React from 'react'

import TooltipWrapper from '@components/TooltipWrapper'

import styles from './ToggleIcon.module.scss'

interface ToggleButtonProps {
    selected: boolean
    icon: IconProp
    tooltipText: string
    onToggle: () => void
    iconContainerClass?: string
}

const ToggleIcon: React.FC<ToggleButtonProps> = ({ selected, icon, tooltipText, onToggle, iconContainerClass }) => {
    return (
        <TooltipWrapper tooltipPosition="right" tooltipText={tooltipText}>
            <div className={classNames(styles.toggleIconContainer, iconContainerClass)} onClick={onToggle}>
                <FAIcon
                    className={classNames({
                        [styles.selected]: selected,
                        [styles.toggleIcon]: true,
                    })}
                    icon={icon}
                />
            </div>
        </TooltipWrapper>
    )
}

export default ToggleIcon
