/* tslint:disable */
/* eslint-disable */
/**
 * Vividi Dashboard API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OutageModel,
    OutageModelFromJSON,
    OutageModelFromJSONTyped,
    OutageModelToJSON,
} from './OutageModel';

/**
 * 
 * @export
 * @interface OutagesResponse
 */
export interface OutagesResponse {
    /**
     * 
     * @type {string}
     * @memberof OutagesResponse
     */
    startingFrom: string;
    /**
     * 
     * @type {string}
     * @memberof OutagesResponse
     */
    endingAt: string;
    /**
     * 
     * @type {Array<OutageModel>}
     * @memberof OutagesResponse
     */
    outages: Array<OutageModel>;
}

export function OutagesResponseFromJSON(json: any): OutagesResponse {
    return OutagesResponseFromJSONTyped(json, false);
}

export function OutagesResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): OutagesResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'startingFrom': json['starting_from'],
        'endingAt': json['ending_at'],
        'outages': ((json['outages'] as Array<any>).map(OutageModelFromJSON)),
    };
}

export function OutagesResponseToJSON(value?: OutagesResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'starting_from': value.startingFrom,
        'ending_at': value.endingAt,
        'outages': ((value.outages as Array<any>).map(OutageModelToJSON)),
    };
}

