/* tslint:disable */
/* eslint-disable */
/**
 * Vividi Dashboard API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Point,
    PointFromJSON,
    PointFromJSONTyped,
    PointToJSON,
} from './Point';

/**
 * 
 * @export
 * @interface DetectionZoneWithId
 */
export interface DetectionZoneWithId {
    /**
     * 
     * @type {number}
     * @memberof DetectionZoneWithId
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof DetectionZoneWithId
     */
    name: string;
    /**
     * 
     * @type {Array<Point>}
     * @memberof DetectionZoneWithId
     */
    points: Array<Point>;
}

export function DetectionZoneWithIdFromJSON(json: any): DetectionZoneWithId {
    return DetectionZoneWithIdFromJSONTyped(json, false);
}

export function DetectionZoneWithIdFromJSONTyped(json: any, ignoreDiscriminator: boolean): DetectionZoneWithId {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'points': ((json['points'] as Array<any>).map(PointFromJSON)),
    };
}

export function DetectionZoneWithIdToJSON(value?: DetectionZoneWithId | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'points': ((value.points as Array<any>).map(PointToJSON)),
    };
}

