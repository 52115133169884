import { DateTime } from 'luxon'
import React from 'react'
import { Alert, Button } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { MutationStatus } from 'react-query'

import { ExternalAuthContext } from '@helpers/auth'

import ExternalLoginForm from './ExternalLoginForm'

interface Props {
    authContext: ExternalAuthContext | null
    onLogin: (apiUrl: string, email: string, password: string) => void
    onLogout: () => void
    loginState: MutationStatus
}

const ExternalAuthStatus = (props: Props) => {
    const { t } = useTranslation()

    if (!props.authContext) {
        return (
            <>
                <Alert variant="info">
                    {t('notification.authFirst', 'You need to authenticate with an external Vividi API first.')}
                </Alert>
                <ExternalLoginForm loginState={props.loginState} onSubmit={props.onLogin} />
            </>
        )
    }

    const authExpired = props.authContext.issueDate + props.authContext.expiresIn <= DateTime.utc().toSeconds()

    if (authExpired) {
        return (
            <>
                <Alert variant="warning">
                    {t('notification.authWithExpired-1', 'Your authentication with ')}
                    <Alert.Link href={props.authContext.apiUrl}>{props.authContext.apiUrl}</Alert.Link>
                    {t('notification.authWithExpired-2', ' expired.')}
                </Alert>
                <ExternalLoginForm
                    initialApiUrl={props.authContext.apiUrl}
                    loginState={props.loginState}
                    onSubmit={props.onLogin}
                />
            </>
        )
    }

    return (
        <Alert variant="success">
            {t('notification.authWith', 'Authenticated with ')}
            <Alert.Link href={props.authContext.apiUrl}>{props.authContext.apiUrl}</Alert.Link>.
            <div className="d-flex justify-content-end">
                <Button variant="outline-success" onClick={props.onLogout}>
                    {t('button.logOut', 'Log out')}
                </Button>
            </div>
        </Alert>
    )
}

export default ExternalAuthStatus
