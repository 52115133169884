import React from 'react'
import { Form } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import { Role } from '@api'

import useProfile from '@helpers/profile'
import { displayTranslatedRole } from '@helpers/translateUtils'

interface Props {
    role: Role
    onChange: (role: Role) => void
}

const RolePicker = (props: Props) => {
    const { t } = useTranslation()

    const profileCall = useProfile()
    const isAdmin = profileCall.data?.role === Role.Administrator

    return (
        <Form.Control
            as="select"
            value={props.role}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => props.onChange(e.target.value as Role)}
        >
            {Object.entries(Role)
                .filter(([_, role]) => isAdmin || role === Role.User || role === Role.OrganizationAdministrator)
                .map(([roleId, role]) => (
                    <option key={roleId} value={role}>
                        {displayTranslatedRole(role, t)}
                    </option>
                ))}
        </Form.Control>
    )
}

export default RolePicker
