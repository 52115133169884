/* tslint:disable */
/* eslint-disable */
/**
 * Vividi Dashboard API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ConversionsConfiguration
 */
export interface ConversionsConfiguration {
    /**
     * How long (in seconds) does a visit have to be before it is considered a visit and not a bounce
     * @type {number}
     * @memberof ConversionsConfiguration
     */
    bounceThreshold?: number;
    /**
     * Boundaries for detection of passersby (people outside of the locality)
     * @type {Array<number>}
     * @memberof ConversionsConfiguration
     */
    passerByBoundaries: Array<number>;
    /**
     * Boundaries for detection of visitors (people who have entered the locality)
     * @type {Array<number>}
     * @memberof ConversionsConfiguration
     */
    visitorBoundaries: Array<number>;
}

export function ConversionsConfigurationFromJSON(json: any): ConversionsConfiguration {
    return ConversionsConfigurationFromJSONTyped(json, false);
}

export function ConversionsConfigurationFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConversionsConfiguration {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'bounceThreshold': !exists(json, 'bounce_threshold') ? undefined : json['bounce_threshold'],
        'passerByBoundaries': json['passer_by_boundaries'],
        'visitorBoundaries': json['visitor_boundaries'],
    };
}

export function ConversionsConfigurationToJSON(value?: ConversionsConfiguration | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'bounce_threshold': value.bounceThreshold,
        'passer_by_boundaries': value.passerByBoundaries,
        'visitor_boundaries': value.visitorBoundaries,
    };
}

