import { faExternalLinkAlt, faEye, faEdit } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Button, Table, Spinner } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'

import { LocalityConfigurationStateModel } from '@api'

import { generateOrganizationsEditLocalitiesPath } from '@helpers/VividiURLs'
import { useAddBackLink } from '@helpers/backlinks'
import { useLocalityOccupancyAdjuster } from '@helpers/localityOccupancy'

import AdminOnlyLinkRenderer from '@components/AdminOnlyLinkRenderer'
import LocalityOccupancyConsumer, { OccupancyData } from '@components/LocalityOccupancyConsumer'
import styles from '@components/StatisticsSummary/LocalityTable/LocalityTable.module.scss'

import CurrentOccupancySetter from './CurrentOccupancySetter'
import staySafeLocalityTableStyles from './StaySafeLocalityTableStyles.module.scss'

type Props = {
    localities: LocalityConfigurationStateModel[]
    createDetailLink: (locality: LocalityConfigurationStateModel) => string
    createStaySafeScreenLink: (locality: LocalityConfigurationStateModel) => string
    onMaximalOccupancyEdit: (locality: LocalityConfigurationStateModel) => void
    onCurrentOccupancySort?: () => void
    onMaximumOccupancySort?: () => void
    type: 'StaySafe' | 'StaySafeFloorplan'
}

const StaySafeLocalityTable = ({
    localities,
    createDetailLink,
    onMaximalOccupancyEdit,
    onCurrentOccupancySort,
    onMaximumOccupancySort,
    createStaySafeScreenLink,
    type,
}: Props) => {
    const { t } = useTranslation()
    const addBackLink = useAddBackLink()

    return (
        <Table className={styles.localityTable} responsive>
            <thead>
                <tr>
                    <th>{t('table.locality', 'Locality')}</th>
                    <th
                        className={staySafeLocalityTableStyles.headingCenterText}
                        onClick={() => onCurrentOccupancySort && onCurrentOccupancySort()}
                    >
                        {t('form.currentOccupancy', 'Current occupancy')}
                    </th>
                    <th
                        className={staySafeLocalityTableStyles.headingCenterText}
                        onClick={() => onMaximumOccupancySort && onMaximumOccupancySort()}
                    >
                        {t('form.maximumOccupancy', 'Maximum occupancy')}
                    </th>
                    <th className={staySafeLocalityTableStyles.headingCenterText}>
                        {t('button.staySafeScreen', 'Stay Safe screen')}
                    </th>
                    <th>{t('table.actions', 'Actions')}</th>
                </tr>
            </thead>
            <tbody>
                {localities.map((locality) => {
                    const StaySafeLocalityRow: React.FC<{
                        data: OccupancyData
                    }> = ({ data }) => {
                        const { increaseOccupancy, decreaseOccupancy, displayedOccupancy } =
                            useLocalityOccupancyAdjuster(locality, data)

                        return (
                            <tr key={locality.id}>
                                <AdminOnlyLinkRenderer
                                    targetLink={addBackLink(
                                        generateOrganizationsEditLocalitiesPath(locality.organizationId, locality.id)
                                    )}
                                    text={locality.name}
                                />
                                <td>
                                    <div className={staySafeLocalityTableStyles.cell}>
                                        {displayedOccupancy !== undefined ? (
                                            type === 'StaySafe' ? (
                                                <CurrentOccupancySetter
                                                    currentOccupancy={displayedOccupancy}
                                                    onOccupancyDecrement={decreaseOccupancy}
                                                    onOccupancyIncrement={increaseOccupancy}
                                                />
                                            ) : (
                                                <span className={styles.currentOccupancyText}>
                                                    {displayedOccupancy}
                                                </span>
                                            )
                                        ) : data.isOffline ? (
                                            data.everHadError && !data.everReceivedData ? (
                                                <span className="text-muted">
                                                    {t('others.notConfigured', 'Not configured')}
                                                </span>
                                            ) : (
                                                <Spinner animation="border" />
                                            )
                                        ) : null}
                                    </div>
                                </td>
                                <td>
                                    <div className={staySafeLocalityTableStyles.cell}>
                                        <div className={staySafeLocalityTableStyles.maximumOccupancy}>
                                            <span>{data.maximumCapacity ? data.maximumCapacity : '?'}</span>
                                            <Button
                                                variant="link"
                                                onClick={() => {
                                                    onMaximalOccupancyEdit(locality)
                                                }}
                                            >
                                                <FontAwesomeIcon icon={faEdit} />
                                            </Button>
                                        </div>
                                    </div>
                                </td>

                                <td>
                                    <div className={staySafeLocalityTableStyles.cell}>
                                        <Button
                                            as={NavLink}
                                            disabled={!locality.isStaySafeConfigured}
                                            to={createStaySafeScreenLink(locality)}
                                            variant="primary"
                                        >
                                            <FontAwesomeIcon icon={faEye} />
                                            {t('button.staySafe', 'Stay Safe')}
                                            <span className={styles.screenText}>{t('button.screen', ' screen')}</span>
                                        </Button>
                                    </div>
                                </td>
                                <td>
                                    <div className="d-flex">
                                        <Button as={NavLink} to={createDetailLink(locality)} variant="link">
                                            <FontAwesomeIcon icon={faExternalLinkAlt} />
                                            {t('button.detail', 'Detail')}
                                        </Button>
                                    </div>
                                </td>
                            </tr>
                        )
                    }

                    return (
                        <LocalityOccupancyConsumer
                            key={locality.id}
                            dataRenderer={StaySafeLocalityRow}
                            endpointName={
                                type === 'StaySafe'
                                    ? 'crowd_size'
                                    : type === 'StaySafeFloorplan'
                                    ? 'floorplan_crowd_size'
                                    : undefined
                            }
                            localityId={locality.id}
                            subscriptionType={
                                type === 'StaySafe' ? 'crowd_size_summary' : 'floorplan_crowd_size_summary'
                            }
                        />
                    )
                })}
            </tbody>
        </Table>
    )
}

export default StaySafeLocalityTable
