import { Dictionary, flatten, groupBy, isEmpty, mapValues, maxBy, minBy, round } from 'lodash'
import { DateTime, Interval, Zone } from 'luxon'

import { DetectionZoneWithId, EmotionSummaryResponse, LocalityResponse } from '@api/models'

import {
    EmotionDataItem,
    emptyEmotionDataItem,
    prepareEmotionDataItem,
    sumEmotionDataItems,
} from '@helpers/emotionsData'
import { percentage, pickColumn } from '@helpers/utils'

export interface TimelineEmotionDataItem extends EmotionDataItem {
    interval: Interval
    name: string
    hasData: boolean
    openedPortion: number
    averageEmotionScore: number
}

type EmotionHighlightsPercentages = {
    positiveObservations: number
    neutralObservations: number
    negativeObservations: number
}

export type EmotionDetailsHighlights = {
    overallPercentages: EmotionHighlightsPercentages
    overallData: EmotionDataItem
}

type DetectionZonesByScene = Array<{
    detectionZones: Array<DetectionZoneWithId>
    sceneName: string
    sceneId: string
}>

const averageEmotionScore = (item: EmotionDataItem) =>
    item.totalObservations > 0 ? item.totalEmotionScore / item.totalObservations : undefined

function pickEmotionsSummaryByZones<T extends { zoneId: number }>(
    items: Array<T>,
    selectedZones: DetectionZoneWithId[] | undefined
) {
    if (selectedZones && !isEmpty(selectedZones)) {
        return items.filter((visitors) => pickColumn(selectedZones, 'id').includes(visitors.zoneId))
    }

    return items
}

export const prepareEmotionDetailsHighlightsData = (
    emotionsSummary: Dictionary<EmotionSummaryResponse>,
    locality: LocalityResponse,
    selectedZones: DetectionZoneWithId[] | undefined
): EmotionDetailsHighlights => {
    const emotionsSummaryByZones = pickEmotionsSummaryByZones(emotionsSummary[locality.id].items, selectedZones).map(
        (summaryData) => ({
            ...summaryData,
            items: summaryData.items.filter((it) => it.openedPortion > 0),
        })
    )

    const localityData = emotionsSummaryByZones.map((zone) =>
        zone.items.map(prepareEmotionDataItem).reduce(sumEmotionDataItems, emptyEmotionDataItem)
    )

    const overallData = localityData.reduce(sumEmotionDataItems, emptyEmotionDataItem)

    const overallPercentages: EmotionHighlightsPercentages = {
        positiveObservations: round(percentage(overallData.positiveObservations, overallData.totalObservations)),
        neutralObservations: round(percentage(overallData.neutralObservations, overallData.totalObservations)),
        negativeObservations: round(percentage(overallData.negativeObservations, overallData.totalObservations)),
    }

    return { overallPercentages, overallData }
}

export type DayHighlights = {
    timeKey: undefined | DateTime
    averageScore: number | undefined
}

export const prepareScoreDistributionInTimePlotData = (
    zone: Zone,
    granularEmotionsSummary: Dictionary<EmotionSummaryResponse>,
    locality: LocalityResponse,
    selectedZones: DetectionZoneWithId[] | undefined
): {
    diagramData: Array<TimelineEmotionDataItem>
} => {
    const summary = pickEmotionsSummaryByZones(granularEmotionsSummary[locality.id].items, selectedZones)

    const valuesByDayByHour = mapValues(
        groupBy(
            summary.flatMap(({ items }) => items.map(prepareEmotionDataItem)),
            ({ interval }) => interval!.start.toISODate()
        ),
        (dayValues) => groupBy(dayValues, ({ interval }) => interval!.start.setZone(zone).toFormat('HH:00'))
    )

    const groupedHourly = mapValues(valuesByDayByHour, (hourlyDistribution) =>
        mapValues(hourlyDistribution, (items) => ({
            ...items.reduce(sumEmotionDataItems, emptyEmotionDataItem),
            interval: items[0].interval!,
        }))
    )

    const diagramData = flatten(
        Object.values(mapValues(groupedHourly, (emotionData) => Object.values(emotionData)))
    ).map((it, index) => ({
        ...it,
        name: index.toString(),
        averageEmotionScore: round(averageEmotionScore(it) ?? 0, 1),
        hasData: [it.negativeObservations, it.neutralObservations, it.positiveObservations].some(
            (it) => it !== undefined
        ),
        openedPortion: it.openedPortion ?? 0,
    }))

    return {
        diagramData,
    }
}

export const prepareEmotionsBreakDownTableData = (
    detectionZonesBySceneName: DetectionZonesByScene,
    granularEmotionsSummary: Dictionary<EmotionSummaryResponse>,
    locality: LocalityResponse,
    selectedZones: DetectionZoneWithId[] | undefined
) => {
    const summary = pickEmotionsSummaryByZones(granularEmotionsSummary[locality.id].items, selectedZones).map(
        (summaryData) => ({
            ...summaryData,
            items: summaryData.items.filter((it) => it.openedPortion > 0),
        })
    )

    const zoneData = summary.map(({ items, zoneName, zoneId }) => ({
        scene:
            detectionZonesBySceneName.find((scene) => scene.detectionZones.some((zone) => zone.id === zoneId))
                ?.sceneName ?? 'Unnamed',
        sceneId:
            detectionZonesBySceneName.find((scene) => scene.detectionZones.some((zone) => zone.id === zoneId))
                ?.sceneId ?? 'Unnamed',
        zoneName,
        zoneId,
        items: items.map(prepareEmotionDataItem),
        get overallData() {
            return this.items.reduce(sumEmotionDataItems, emptyEmotionDataItem)
        },
        get averageEmotionScore() {
            return this.overallData.totalObservations !== 0
                ? this.overallData.totalEmotionScore / (this.overallData.totalObservations || 1)
                : undefined
        },
        get mostPositiveDay() {
            let timeKey = undefined
            let averageScore = undefined

            const mostPositive = maxBy(
                this.items.filter((it) => it.totalObservations > 0),
                (value) => averageEmotionScore(value)
            )

            if (mostPositive) {
                timeKey = mostPositive.interval!.start
                averageScore = averageEmotionScore(mostPositive)
            }

            return {
                timeKey,
                averageScore,
            }
        },
        get theMostNegativeDay() {
            let timeKey = undefined
            let averageScore = undefined

            const mostNegative = minBy(
                this.items.filter((it) => it.totalObservations > 0),
                (value) => averageEmotionScore(value)
            )

            if (mostNegative) {
                timeKey = mostNegative.interval!.start
                averageScore = averageEmotionScore(mostNegative)
            }

            return {
                timeKey,
                averageScore,
            }
        },
    }))

    return zoneData
}
