/* tslint:disable */
/* eslint-disable */
/**
 * Vividi Dashboard API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    VisibleEntitiesByDeviceItem,
    VisibleEntitiesByDeviceItemFromJSON,
    VisibleEntitiesByDeviceItemFromJSONTyped,
    VisibleEntitiesByDeviceItemToJSON,
} from './VisibleEntitiesByDeviceItem';
import {
    VisibleEntitiesByZoneItem,
    VisibleEntitiesByZoneItemFromJSON,
    VisibleEntitiesByZoneItemFromJSONTyped,
    VisibleEntitiesByZoneItemToJSON,
} from './VisibleEntitiesByZoneItem';

/**
 * 
 * @export
 * @interface LocalityVisibleEntitiesResponse
 */
export interface LocalityVisibleEntitiesResponse {
    /**
     * An entry for each scene (device) present in the locality
     * @type {Array<VisibleEntitiesByDeviceItem>}
     * @memberof LocalityVisibleEntitiesResponse
     */
    itemsByScene: Array<VisibleEntitiesByDeviceItem>;
    /**
     * An entry for each zone defined in the locality
     * @type {Array<VisibleEntitiesByZoneItem>}
     * @memberof LocalityVisibleEntitiesResponse
     */
    itemsByZone: Array<VisibleEntitiesByZoneItem>;
    /**
     * Timestamps of last observation indexed by scene ID
     * @type {{ [key: string]: string; }}
     * @memberof LocalityVisibleEntitiesResponse
     */
    observationDates: { [key: string]: string; };
}

export function LocalityVisibleEntitiesResponseFromJSON(json: any): LocalityVisibleEntitiesResponse {
    return LocalityVisibleEntitiesResponseFromJSONTyped(json, false);
}

export function LocalityVisibleEntitiesResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): LocalityVisibleEntitiesResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'itemsByScene': ((json['items_by_scene'] as Array<any>).map(VisibleEntitiesByDeviceItemFromJSON)),
        'itemsByZone': ((json['items_by_zone'] as Array<any>).map(VisibleEntitiesByZoneItemFromJSON)),
        'observationDates': json['observation_dates'],
    };
}

export function LocalityVisibleEntitiesResponseToJSON(value?: LocalityVisibleEntitiesResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'items_by_scene': ((value.itemsByScene as Array<any>).map(VisibleEntitiesByDeviceItemToJSON)),
        'items_by_zone': ((value.itemsByZone as Array<any>).map(VisibleEntitiesByZoneItemToJSON)),
        'observation_dates': value.observationDates,
    };
}

