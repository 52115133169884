/* tslint:disable */
/* eslint-disable */
/**
 * Vividi Dashboard API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DatetimeInterval,
    DatetimeIntervalFromJSON,
    DatetimeIntervalFromJSONTyped,
    DatetimeIntervalToJSON,
} from './DatetimeInterval';
import {
    SummaryCategory,
    SummaryCategoryFromJSON,
    SummaryCategoryFromJSONTyped,
    SummaryCategoryToJSON,
} from './SummaryCategory';

/**
 * 
 * @export
 * @interface PropertySummaryRequestBody
 */
export interface PropertySummaryRequestBody {
    /**
     * List of UTC intervals to fetch statistics for
     * @type {Array<DatetimeInterval>}
     * @memberof PropertySummaryRequestBody
     */
    intervals: Array<DatetimeInterval>;
    /**
     * Requested locality id
     * @type {number}
     * @memberof PropertySummaryRequestBody
     */
    locality: number;
    /**
     * 
     * @type {Array<SummaryCategory>}
     * @memberof PropertySummaryRequestBody
     */
    propertyCategories: Array<SummaryCategory>;
}

export function PropertySummaryRequestBodyFromJSON(json: any): PropertySummaryRequestBody {
    return PropertySummaryRequestBodyFromJSONTyped(json, false);
}

export function PropertySummaryRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): PropertySummaryRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'intervals': ((json['intervals'] as Array<any>).map(DatetimeIntervalFromJSON)),
        'locality': json['locality'],
        'propertyCategories': ((json['property_categories'] as Array<any>).map(SummaryCategoryFromJSON)),
    };
}

export function PropertySummaryRequestBodyToJSON(value?: PropertySummaryRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'intervals': ((value.intervals as Array<any>).map(DatetimeIntervalToJSON)),
        'locality': value.locality,
        'property_categories': ((value.propertyCategories as Array<any>).map(SummaryCategoryToJSON)),
    };
}

