import { isEmpty, partition } from 'lodash'
import { Interval } from 'luxon'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'

import { LocalityConfigurationStateModel } from '@api'

import {
    generateLocalityOccupancyDashboardPath,
    generateEditLocalityOccupancyPath,
    generateStaySafeDetailsPath,
    generateFloorplanLocalityOccupancyDashboardPath,
} from '@helpers/VividiURLs'
import { useAddBackLink } from '@helpers/backlinks'
import { orderLocalities } from '@helpers/orderFunctions'
import { IntervalType } from '@helpers/types'

import Box from '@elements/Box/Box'

import LiveIndicator from '@components/LiveIndicator/LiveIndicator'
import NotConfiguredLocalitiesList from '@components/StatisticsSummary/NotConfiguredLocalitiesList'
import StaySafeLocalityTable from '@components/StatisticsSummary/StaySafeLocalityTable/StaySafeLocalityTable'

type Props = {
    interval: Interval
    selectedLocalities: LocalityConfigurationStateModel[]
    sectionType: 'StaySafe' | 'StaySafeFloorplan'
}

const StaySafeSection = ({ selectedLocalities, interval, sectionType }: Props) => {
    const { t, i18n } = useTranslation()

    const appLanguage = i18n.language

    const history = useHistory()
    const addBackLink = useAddBackLink()

    const [configuredLocalities, notConfiguredLocalities] = partition(
        orderLocalities(selectedLocalities),
        (l) =>
            (sectionType === 'StaySafe' && l.isStaySafeConfigured) ||
            (sectionType === 'StaySafeFloorplan' && l.isStaySafeFloorplanConfigured)
    )

    const isStaySafe = sectionType === 'StaySafe'

    return (
        <Box paddingSize="lg">
            <Box.Title
                buttons={<LiveIndicator />}
                text={
                    isStaySafe
                        ? t('realTime.realTimeStatistics', 'Real-time Statistics')
                        : t('realTime.realTimeFloorplanStatistics', 'Real-time Statistics - Floor plan')
                }
            />
            {!isEmpty(configuredLocalities) && (
                <StaySafeLocalityTable
                    createDetailLink={({ id }) => generateStaySafeDetailsPath(id, interval.end, IntervalType.DAY)}
                    createStaySafeScreenLink={({ id }) =>
                        isStaySafe
                            ? generateLocalityOccupancyDashboardPath(id, appLanguage ?? 'en')
                            : generateFloorplanLocalityOccupancyDashboardPath(id, appLanguage ?? 'en')
                    }
                    localities={configuredLocalities}
                    type={sectionType}
                    onMaximalOccupancyEdit={({ organizationId, id }) =>
                        history.push(addBackLink(generateEditLocalityOccupancyPath(organizationId, id)))
                    }
                />
            )}
            {!isEmpty(notConfiguredLocalities) && (
                <NotConfiguredLocalitiesList
                    notConfiguredLocalities={notConfiguredLocalities}
                    nothingConfigured={isEmpty(configuredLocalities)}
                />
            )}
        </Box>
    )
}

export default StaySafeSection
