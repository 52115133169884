import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import { uniqueId } from 'lodash'
import React, { useMemo } from 'react'
import { Popover, OverlayTrigger } from 'react-bootstrap'

import styles from './TooltipIcon.module.scss'

interface Props {
    className?: string
}

const TooltipIcon: React.FC<Props> = ({ className, children }) => {
    const tooltipId = useMemo(uniqueId, [])

    const TooltipBody = (
        <Popover id={tooltipId}>
            <Popover.Content>
                <div>{children}</div>
            </Popover.Content>
        </Popover>
    )

    return (
        <OverlayTrigger overlay={TooltipBody} placement="top" trigger={['hover', 'focus']}>
            <span className={classNames(className, styles.infoCircle)}>
                <FontAwesomeIcon className={styles.icon} icon={faInfoCircle} />
            </span>
        </OverlayTrigger>
    )
}

export default TooltipIcon
