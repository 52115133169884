import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Badge } from 'react-bootstrap'

import styles from './TabTitle.module.scss'

interface Props {
    title: string
    icon: JSX.Element | IconDefinition
    counter?: number
}

const TabTitle: React.FC<Props> = ({ title, icon, counter }) => (
    <>
        {(icon as any).icon === undefined ? (
            React.cloneElement(icon as JSX.Element, {
                className: styles.icon,
            })
        ) : (
            <FontAwesomeIcon className={styles.iconFA} icon={icon as IconDefinition} />
        )}
        {title}
        {counter !== undefined && (
            <Badge className={styles.counter} pill>
                {counter}
            </Badge>
        )}
    </>
)

export default TabTitle
