import { round, sum } from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'
import AutoSizer from 'react-virtualized-auto-sizer'
import { Tooltip, PieChart, Pie, Cell, Label } from 'recharts'

import { EmotionDataItem } from '@helpers/emotionsData'
import { TooltipFormatter } from '@helpers/types'
import { percentage, pickColumn } from '@helpers/utils'

import palette from '@components/palette.module.scss'

export const EmotionScoreChart: React.FC<{
    data: EmotionDataItem
    fontSize: string
}> = (props) => {
    const { t } = useTranslation()

    const data = [
        {
            name: t('emotions.positiveFaces', 'Positive faces'),
            value: props.data.positiveObservations,
        },
        {
            name: t('emotions.negativeFaces', 'Negative faces'),
            value: props.data.negativeObservations,
        },
        {
            name: t('emotions.neutralFaces', 'Neutral faces'),
            value: props.data.neutralObservations,
        },
    ]

    const averageScore = round(props.data.totalEmotionScore / (props.data.totalObservations || 1), 1)

    return (
        <AutoSizer>
            {({ width, height }) => (
                <PieChart height={height} margin={{ top: 0, right: 0, bottom: 0, left: 0 }} width={width}>
                    <Pie
                        animationBegin={100}
                        animationDuration={500}
                        data={data}
                        dataKey="value"
                        innerRadius="70%"
                        nameKey="name"
                        outerRadius="100%"
                    >
                        <Cell fill={palette.emotionsPositive} />
                        <Cell fill={palette.emotionsNegative} />
                        <Cell fill={palette.emotionsNeutral} />
                        <Label
                            fill={palette.darkgrey}
                            fontSize={props.fontSize}
                            fontWeight="bold"
                            position="center"
                            value={averageScore}
                        />
                    </Pie>
                    <Tooltip
                        formatter={
                            ((value: number, name: string) => {
                                const percentageValue = percentage(value, sum(pickColumn(data, 'value')))

                                return [`${percentageValue.toFixed(2)}%`, name]
                            }) as TooltipFormatter
                        }
                    />
                </PieChart>
            )}
        </AutoSizer>
    )
}
