import { faDownload } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { isEmpty } from 'lodash'
import { DateTime, Interval } from 'luxon'
import React from 'react'
import { Button } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { UseQueryResult } from 'react-query'

import { CrowdSizeHistoryResponse, LocalityResponse } from '@api'

import { mergeQueryResults } from '@helpers/api'
import { gdynusaReadableInterval } from '@helpers/datetimeUtils'
import { GoogleEventName } from '@helpers/gtag'
import { formatIntervalToFilename } from '@helpers/intervals'
import { useLocalizeDateTime } from '@helpers/timezoneConfig'
import { ExportData, exportToExcel } from '@helpers/xlsxExporter'

import LegacyLoadingWrapper from '@components/LegacyLoadingWrapper'
import { parseInterval } from '@components/StatisticsSummary/utils'
import { useNotify } from '@components/notifications/NotificationsContext'
import { parseDate } from '@components/plots/common'

interface ExportButtonProps {
    crowdSizeHistoryCall: UseQueryResult<CrowdSizeHistoryResponse>
    floorplanCrowdSizeHistoryCall: UseQueryResult<CrowdSizeHistoryResponse>
    selectedLocality: LocalityResponse
    pickedInterval: Interval
    sceneCount: number
    now: DateTime
}

export const StaySafeDetailsExportButton: React.FC<ExportButtonProps> = ({
    crowdSizeHistoryCall,
    floorplanCrowdSizeHistoryCall,
    selectedLocality,
    pickedInterval,
    sceneCount,
    now,
}) => {
    const { t } = useTranslation()

    const localize = useLocalizeDateTime()
    const notify = useNotify()

    const placeholderButton = (
        <Button disabled={true} variant="secondary">
            <FontAwesomeIcon icon={faDownload} />
            {t('button.downloadExcel', 'Download Excel')}
        </Button>
    )

    return (
        <LegacyLoadingWrapper
            errorComponent={placeholderButton}
            placeholder={placeholderButton}
            request={mergeQueryResults(crowdSizeHistoryCall, floorplanCrowdSizeHistoryCall)}
        >
            {([crowdSizeHistory, floorplanCrowdSizeHistory]) => {
                let crowdSizeHistoryData: ExportData = {}
                let floorplanCrowdSizeHistoryData: ExportData = {}

                if (crowdSizeHistory.items && !isEmpty(crowdSizeHistory.items)) {
                    crowdSizeHistoryData = {
                        [t('statistics.visitorsInTime', 'Visitors in time')]: crowdSizeHistory.items
                            .filter((it) => parseDate(it.endingAt) < now)
                            .map((it) => ({
                                [t('others.time', 'Time')]: localize(parseInterval(it).start).toLocaleString({
                                    ...DateTime.DATETIME_SHORT,
                                    weekday: 'short',
                                }),
                                [t('statistics.visitorsInTime', 'Visitors in time')]: it.maxCrowdSize ?? 0,
                            })),
                    }
                }

                if (floorplanCrowdSizeHistory.items && !isEmpty(floorplanCrowdSizeHistory.items)) {
                    floorplanCrowdSizeHistoryData = {
                        [t('realTime.currentlySeenBy', 'Seen by {{value}} scenes', {
                            value: sceneCount,
                        })]: floorplanCrowdSizeHistory.items
                            .filter((it) => parseDate(it.endingAt) < now)
                            .map((it) => ({
                                [t('others.time', 'Time')]: localize(parseInterval(it).start).toLocaleString({
                                    ...DateTime.DATETIME_SHORT,
                                    weekday: 'short',
                                }),
                                [t('statistics.visitorsInTime', 'Visitors in time')]: it.maxCrowdSize ?? 0,
                            })),
                    }
                }

                const handleDownload = () => {
                    const exportData = {
                        ...crowdSizeHistoryData,
                        ...floorplanCrowdSizeHistoryData,
                    }

                    if (!isEmpty(exportData)) {
                        exportToExcel(
                            exportData,
                            `${selectedLocality?.name}-staySafe-details-${formatIntervalToFilename(
                                gdynusaReadableInterval(pickedInterval.mapEndpoints(localize))
                            )}`,
                            GoogleEventName.DOWNLOAD_REALTIME_LOCALITY_EXCEL
                        )
                    } else {
                        notify({
                            title: t('notification.error', 'Error'),
                            content: t('notification.nothingToExport', 'Nothing to export'),
                            variant: 'danger',
                        })
                    }
                }

                return (
                    <Button variant="secondary" onClick={handleDownload}>
                        <FontAwesomeIcon icon={faDownload} />
                        {t('button.downloadExcel', 'Download Excel')}
                    </Button>
                )
            }}
        </LegacyLoadingWrapper>
    )
}
