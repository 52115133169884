import { faList } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Button } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'

import { OrganizationResponse, Role } from '@api/models'

import { generateEditOrganizationPath, OrganizationEditPageTabs } from '@helpers/VividiURLs'

import Heading from '@elements/Heading/Heading'

import RoleChecker from '@components/RoleChecker'

import styles from './SectionSubheading.module.scss'

type Props = {
    children: React.ReactNode
    selectedOrganization: OrganizationResponse
}

const SectionSubheading = ({ children, selectedOrganization }: Props) => {
    const { t } = useTranslation()

    return (
        <Heading className={styles.sectionSubheading}>
            {children}

            <RoleChecker whitelist={[Role.Administrator]}>
                <Button
                    as={NavLink}
                    to={generateEditOrganizationPath(selectedOrganization.id, OrganizationEditPageTabs.localities)}
                    variant="link"
                >
                    <FontAwesomeIcon icon={faList} />
                    {t('table.organizationLocalities', '{{organization}} localities', {
                        organization: selectedOrganization.name,
                    })}
                </Button>
            </RoleChecker>
        </Heading>
    )
}

export default SectionSubheading
