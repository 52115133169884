import { head, isEmpty, last, minBy } from 'lodash'
import { DateTime, Duration, IANAZone, Interval } from 'luxon'
import React from 'react'
import { useQuery, UseQueryResult } from 'react-query'
import { useParams, Redirect } from 'react-router'

import { LocalityResponse, OrganizationListResponse, LocalityConfigurationStateListResponse } from '@api'

import PageNotFound from '@pages/PageNotFound'

import { localityApi, statisticsApi } from '@services'

import { generateHeatmapDetailPath } from '@helpers/VividiURLs'
import { mergeQueryResults, visibleOrganizationsQuery } from '@helpers/api'
import useProfile from '@helpers/profile'
import { TimezoneConfigProvider } from '@helpers/timezoneConfig'
import { IntervalType } from '@helpers/types'

import { parseDate } from '@components/plots/common'

interface Params {
    localityId: string
    intervalType: string
    intervalStart: string
    intervalEnd?: string
    floorplanId?: string
}

export interface DashboardApiCalls {
    localityConfigurations: UseQueryResult<LocalityConfigurationStateListResponse, unknown>
    visibleOrganizationsCall: UseQueryResult<OrganizationListResponse, unknown>
}

interface PartialParams {
    intervalType: IntervalType
    interval: Interval
}

interface NormalizedDashboardParams extends PartialParams {
    locality: LocalityResponse
}

interface Props {
    defaultLocalityPathGenerator: (localityId: number) => string
    errorComponent: (params: PartialParams, apiCalls: DashboardApiCalls) => JSX.Element
    loadingComponent: (params: PartialParams, apiCalls: DashboardApiCalls) => JSX.Element
    successComponent: (params: NormalizedDashboardParams, apiCalls: DashboardApiCalls) => JSX.Element
}

const getFirstConfiguredLocalityId = (config: LocalityConfigurationStateListResponse) =>
    config.localities.find((l) => l.isStaySafeFloorplanConfigured)?.id

const getFirstFloorplanId = (locality: LocalityResponse) => {
    const idKey = head(Object.keys(locality.floorplans))

    return idKey !== undefined ? Number(idKey) : undefined
}

const FloorplanHeatmapDetailParamsNormalizer: React.FC<Props> = ({
    errorComponent,
    loadingComponent,
    successComponent,
}) => {
    const profileCall = useProfile()
    const params = useParams<Partial<Params>>()

    const localityConfigurationsCall = useQuery(localityApi.getLocalityConfigurationStates.query())

    const visibleOrganizationsCall = useQuery({
        ...visibleOrganizationsQuery(profileCall.data),
        enabled: profileCall.data !== undefined,
    })

    const selectedLocalityCall = useQuery({
        ...localityApi.getLocality.query({
            localityId:
                params.localityId !== undefined
                    ? Number(params.localityId)
                    : (localityConfigurationsCall.data &&
                          getFirstConfiguredLocalityId(localityConfigurationsCall.data)) ??
                      -1,
        }),
        enabled: localityConfigurationsCall.isSuccess,
    })

    const heatmapListCall = useQuery({
        ...statisticsApi.getFloorplanHeatmapList.query({
            floorplanId:
                params.floorplanId !== undefined
                    ? Number(params.floorplanId)
                    : (selectedLocalityCall.data && getFirstFloorplanId(selectedLocalityCall.data)) ?? -1,
        }),
        enabled: selectedLocalityCall.isSuccess,
    })

    const intervalType = IntervalType.WEEK

    if (params.intervalStart !== undefined && isNaN(Number(params.intervalStart))) {
        return <PageNotFound />
    }

    if (params.intervalEnd !== undefined && isNaN(Number(params.intervalEnd))) {
        return <PageNotFound />
    }

    const placeholderIntervalStart =
        params.intervalStart !== undefined
            ? DateTime.fromMillis(Number(params.intervalStart), { zone: 'utc' })
            : DateTime.utc()

    const placeholderInterval = Interval.fromDateTimes(
        placeholderIntervalStart.minus(Duration.fromObject({ weeks: 1 })),
        placeholderIntervalStart
    )

    const apiCalls = mergeQueryResults(
        localityConfigurationsCall,
        visibleOrganizationsCall,
        selectedLocalityCall,
        heatmapListCall
    )

    // Locality and heatmap list are required for successful routing
    if (apiCalls.status === 'idle' || apiCalls.status === 'loading') {
        return loadingComponent(
            { interval: placeholderInterval, intervalType },
            {
                localityConfigurations: localityConfigurationsCall,
                visibleOrganizationsCall,
            }
        )
    }

    if (apiCalls.status === 'error') {
        if (selectedLocalityCall.status === 'error' && heatmapListCall.status === 'error') {
            return <PageNotFound />
        }

        return errorComponent(
            { interval: placeholderInterval, intervalType },
            {
                localityConfigurations: localityConfigurationsCall,
                visibleOrganizationsCall,
            }
        )
    }

    const locality = selectedLocalityCall.data!
    const localityTimezone = new IANAZone(locality.timezone)
    const floorplanId = params.floorplanId !== undefined ? Number(params.floorplanId) : getFirstFloorplanId(locality)

    if (floorplanId === undefined || isEmpty(heatmapListCall.data?.heatmaps)) {
        return <PageNotFound />
    }

    const heatmapList = heatmapListCall.data!.heatmaps

    const intervalStart =
        params.intervalStart !== undefined
            ? DateTime.fromMillis(Number(params.intervalStart), { zone: 'utc' })
            : parseDate(last(heatmapList)!.startingFrom)

    const closestHeatmap = minBy(heatmapList, ({ startingFrom }) =>
        Math.abs(parseDate(startingFrom).diff(intervalStart, 'milliseconds').valueOf())
    )!

    if (
        !intervalStart.equals(parseDate(closestHeatmap.startingFrom)) ||
        locality.id.toString() !== params.localityId ||
        floorplanId.toString() !== params.floorplanId
    ) {
        return (
            <Redirect
                to={generateHeatmapDetailPath(
                    locality.id,
                    parseDate(closestHeatmap.startingFrom),
                    intervalType,
                    floorplanId
                )}
            />
        )
    }

    const interval = Interval.fromDateTimes(parseDate(closestHeatmap.startingFrom), parseDate(closestHeatmap.endingAt))

    return (
        <TimezoneConfigProvider timezone={localityTimezone.name}>
            {successComponent(
                {
                    interval,
                    intervalType,
                    locality,
                },
                { localityConfigurations: localityConfigurationsCall, visibleOrganizationsCall }
            )}
        </TimezoneConfigProvider>
    )
}

export default FloorplanHeatmapDetailParamsNormalizer
