/* tslint:disable */
/* eslint-disable */
/**
 * Vividi Dashboard API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RetryJobRequestBody
 */
export interface RetryJobRequestBody {
    /**
     * 
     * @type {number}
     * @memberof RetryJobRequestBody
     */
    priority?: number;
    /**
     * 
     * @type {number}
     * @memberof RetryJobRequestBody
     */
    timeLimit?: number;
}

export function RetryJobRequestBodyFromJSON(json: any): RetryJobRequestBody {
    return RetryJobRequestBodyFromJSONTyped(json, false);
}

export function RetryJobRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): RetryJobRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'priority': !exists(json, 'priority') ? undefined : json['priority'],
        'timeLimit': !exists(json, 'time_limit') ? undefined : json['time_limit'],
    };
}

export function RetryJobRequestBodyToJSON(value?: RetryJobRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'priority': value.priority,
        'time_limit': value.timeLimit,
    };
}

