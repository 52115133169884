import * as H from 'history'
import { head, isEmpty, noop } from 'lodash'
import { matchPath } from 'react-router'

import { SceneResponse, OrganizationResponse, LocalityNameModel } from '@api'

import {
    ORGANIZATION_SCENE_INSTALLER_UI_PATH,
    ORGANIZATION_SCENE_INSTALLER_UI_ASSIGN_TO_SCENE_PATH,
    ORGANIZATION_SCENE_INSTALLER_UI_ASSIGNED_DEVICE_PATH,
    ORGANIZATION_SCENE_EDIT_DESCRIBE_SCENE_MODAL_PATH,
    generateOrganizationEditSceneDescribePath,
    generateOrganizationScenesPath,
    generateOrganizationEditSceneInstallerUIPath,
    generateOrganizationEditScenePath,
    generateOrganizationEditSceneInstallerUIAssignToScenePath,
    generateOrganizationEditSceneInstallerUIAssignDevicePath,
} from '@helpers/VividiURLs'

const determineOrganizationDescribeSceneModalProps =
    (location: H.Location<unknown>, history: H.History<unknown>) =>
    (
        organizationScenes: SceneResponse[],
        localities: LocalityNameModel[],
        sceneId: number,
        organization: OrganizationResponse,
        localityId?: number
    ) => ({
        selectedScene: organizationScenes.find(({ id }) => id === sceneId),
        get selectedSceneLocalities() {
            return localities.filter(({ id }) => this.selectedScene?.localityIds.some((l) => l === id))
        },
        get selectedLocality() {
            return localities.find(({ id }) => id === localityId) ?? head(this.selectedSceneLocalities)
        },
        scenesByLocality: organizationScenes.filter(({ localityIds }) => localityIds.some((l) => localityId === l)),
        get isSceneWithLocalities() {
            return !isEmpty(this.selectedSceneLocalities)
        },
        isDescribeSceneModalOpen: Boolean(
            matchPath(location.pathname, {
                path: ORGANIZATION_SCENE_EDIT_DESCRIBE_SCENE_MODAL_PATH,
                exact: true,
            })
        ),
        get handleOpenDescribeSceneModal() {
            return this.selectedScene
                ? () =>
                      history.push(
                          generateOrganizationEditSceneDescribePath(
                              organization.id,
                              this.selectedScene!.id,
                              this.selectedLocality?.id
                          )
                      )
                : noop
        },
        get handleCloseDescribeSceneModal() {
            return this.selectedScene
                ? () => history.push(generateOrganizationScenesPath(organization.id, this.selectedScene!.id))
                : noop
        },
        get determineSelectScenePath() {
            return (sceneId: number) =>
                generateOrganizationEditSceneDescribePath(organization.id, sceneId, this.selectedLocality?.id)
        },
    })

const determineOrganizationInstallerUIProps = (
    location: H.Location<unknown>,
    history: H.History<unknown>,
    organizationId: number
) => ({
    isInstallerUIOpen: Boolean(matchPath(location.pathname, ORGANIZATION_SCENE_INSTALLER_UI_PATH)),
    get installerUIView() {
        const listView = Boolean(
            matchPath(location.pathname, {
                path: ORGANIZATION_SCENE_INSTALLER_UI_PATH,
                exact: true,
            })
        )
        const assignView = Boolean(
            matchPath(location.pathname, {
                path: ORGANIZATION_SCENE_INSTALLER_UI_ASSIGN_TO_SCENE_PATH,
                exact: true,
            })
        )
        const deviceView = Boolean(
            matchPath(location.pathname, {
                path: ORGANIZATION_SCENE_INSTALLER_UI_ASSIGNED_DEVICE_PATH,
                exact: true,
            })
        )

        return listView ? 'list' : assignView ? 'assign' : deviceView ? 'device' : undefined
    },
    get determineAssignDevicePath() {
        return (sceneId: number, deviceId: number) =>
            generateOrganizationEditSceneInstallerUIAssignDevicePath(organizationId, sceneId, deviceId)
    },
    get handleOpenInstallerUIModal() {
        return () => history.push(generateOrganizationEditSceneInstallerUIPath(organizationId))
    },
    get handleCloseInstallerUIModal() {
        return () => history.push(generateOrganizationEditScenePath(organizationId))
    },
    get handleInstallerUIModalSceneSelect() {
        return (sceneId: string) =>
            history.push(generateOrganizationEditSceneInstallerUIAssignToScenePath(organizationId, Number(sceneId)))
    },
})

export { determineOrganizationInstallerUIProps, determineOrganizationDescribeSceneModalProps }
