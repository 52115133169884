import classNames from 'classnames'
import React from 'react'

import { LocalityResponse, OrganizationResponse } from '@api'

import ControlledModal from '@components/ControlledModal'

import { ReactComponent as FloorplanIcon } from '@images/icons/floorplan_icon.svg'

import { FloorplanForm } from './FloorplanForm/FloorplanForm'
import styles from './LocalityFloorplanModal.module.scss'

const LocalityFloorplanModal: React.FC<{
    organization: OrganizationResponse
    locality: LocalityResponse
    isFloorplanModalOpen: boolean
    onFloorplanModalOpen: () => void
    onFloorplanModalClose: () => void
    floorplanName: string
}> = ({ isFloorplanModalOpen, onFloorplanModalOpen, onFloorplanModalClose, floorplanName, organization, locality }) => {
    return (
        <ControlledModal
            className={styles.modal}
            control={
                <span className={classNames([styles.floorplanNames, styles.link])} onClick={onFloorplanModalOpen}>
                    <FloorplanIcon />
                    {floorplanName}
                </span>
            }
            show={isFloorplanModalOpen}
            centered
            onHide={onFloorplanModalClose}
        >
            <FloorplanForm locality={locality} organization={organization} onModalClose={onFloorplanModalClose} />
        </ControlledModal>
    )
}

export default LocalityFloorplanModal
