import classNames from 'classnames'
import React from 'react'

import styles from './SpacedLayout.module.scss'

const SpacedLayout: React.FC<{
    children: React.ReactNode[] | React.ReactNode
    gapSize?: 'xs' | 'sm' | 'md' | 'lg'
    direction?: 'vertical' | 'horizontal'
    className?: string
}> = ({ children, gapSize = 'md', direction = 'vertical', className }) => {
    return (
        <div
            className={classNames(className, {
                [styles.layoutHorizontal]: direction === 'horizontal',
                [styles.layoutVertical]: direction === 'vertical',
                [styles.extraSmallGap]: gapSize === 'xs',
                [styles.smallGap]: gapSize === 'sm',
                [styles.mediumGap]: gapSize === 'md',
                [styles.largeGap]: gapSize === 'lg',
            })}
        >
            {children}
        </div>
    )
}

export default SpacedLayout
