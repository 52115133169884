import React from 'react'

import { SceneResponse } from '@api/models'

import { displayScene } from '@helpers/displayUtils'

import BadgeList from './BadgeList'

const DeviceBadgeList: React.FC<{
    scenes: SceneResponse[]
    maxDisplayed?: number
}> = ({ scenes, maxDisplayed }) => <BadgeList items={scenes.map(displayScene)} maxDisplayed={maxDisplayed} />

export default DeviceBadgeList
