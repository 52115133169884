import { faVideo } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState, useMemo } from 'react'
import { Dropdown } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import { DeviceResponse } from '@api'

import { orderDevices } from '@helpers/orderFunctions'

import DeviceIcon from '@components/DeviceIcon'
import TextInput from '@components/GenericInputs/TextInput'
import styles from '@components/StatisticsSummary/FilterButton.module.scss'

export type Props = {
    selectedDevice?: DeviceResponse
    devices: Array<DeviceResponse>
    onSelect: (device: DeviceResponse) => void
}
const DevicePicker: React.FC<Props> = ({ selectedDevice, devices, onSelect }) => {
    const [search, setSearch] = useState('')
    const { t } = useTranslation()

    const filteredDevices = useMemo(
        () => orderDevices(devices).filter((d) => d.id.toString().includes(search) || d.note?.includes(search)),
        [search, devices]
    )

    return (
        <Dropdown
            onSelect={(eventKey) => {
                if (eventKey === null) {
                    return
                }

                onSelect(devices.find((d) => eventKey === d.id.toString())!)
            }}
        >
            <Dropdown.Toggle className="btn-block" disabled={devices.length === 0} variant="secondary">
                <FontAwesomeIcon icon={faVideo} />
                {selectedDevice ? `#${selectedDevice.id}` : t('others.nothingSelected', 'Nothing selected')}
            </Dropdown.Toggle>
            <Dropdown.Menu>
                <TextInput
                    autofocus={true}
                    className={styles.searchInput}
                    placeholder={t('button.searchDevice', 'Search device')}
                    value={search}
                    onChange={setSearch}
                />
                {filteredDevices.map((device) => (
                    <Dropdown.Item key={device.id} eventKey={device.id.toString()}>
                        <DeviceIcon color={device.color} />#{device.id}
                        <span className="ml-3">{device.note}</span>
                    </Dropdown.Item>
                ))}
            </Dropdown.Menu>
        </Dropdown>
    )
}

export default DevicePicker
