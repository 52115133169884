import { noop } from 'lodash'
import React from 'react'
import { Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery } from 'react-query'

import { ProfileResponse } from '@api/models'

import { profileApi, userApi } from '@services'

import AsyncButton from '@components/AsyncButton'

import TermsAndConditionsView, { TermsAndConditionsTab } from './TermsAndConditionsView'
import content, { getCurrentVersion, TermsAndConditions } from './content'

const getLastTermsAndConditionsVersion = (profile?: ProfileResponse): TermsAndConditions | undefined => {
    if (profile?.lastTermsAndConditionsVersion === undefined) {
        return undefined
    }

    return content[profile.lastTermsAndConditionsVersion]
}

const TermsAndConditionsModal = () => {
    const { t } = useTranslation()

    const profileCall = useQuery(profileApi.getProfile.query())

    const { mutate: agreeCall, status: agreeCallState } = useMutation(userApi.agreeToTermsAndConditions, {
        onSuccess: () => {
            profileCall.refetch()
        },
        onError: noop,
    })

    const lastSeenVersion = getLastTermsAndConditionsVersion(profileCall.data)

    const [currentVersion, currentTermsAndConditions] = getCurrentVersion()

    return (
        <Modal
            show={
                profileCall.status === 'success' &&
                (lastSeenVersion === undefined || lastSeenVersion.revisedBy !== undefined)
            }
            size="lg"
        >
            <Modal.Header>
                <Modal.Title>{t('termsAndConditions.title', 'Please, review our Terms and Conditions')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <TermsAndConditionsView
                    currentVersion={currentTermsAndConditions}
                    hiddenTabs={[TermsAndConditionsTab.SALE, TermsAndConditionsTab.COOKIES]}
                    lastSeenVersion={lastSeenVersion}
                />
            </Modal.Body>
            <Modal.Footer>
                <AsyncButton
                    status={agreeCallState}
                    text={t('termsAndConditions.button', 'I have read the Terms and Conditions and I agree')}
                    onClick={() =>
                        agreeCall({
                            version: currentVersion,
                        })
                    }
                />
            </Modal.Footer>
        </Modal>
    )
}

export default TermsAndConditionsModal
