export type LanguageSelection = {
    ISO639: string
    ISO: string
}

export const languages: Array<LanguageSelection> = [
    {
        ISO639: 'EN',
        ISO: 'English',
    },
    { ISO639: 'CS', ISO: 'Czech' },
    {
        ISO639: 'FR',
        ISO: 'French',
    },
]
