import React, { useEffect, useState } from 'react'
import { Spinner } from 'react-bootstrap'
import Geosuggest from 'react-geosuggest'
import { useTranslation } from 'react-i18next'
import scriptJs from 'scriptjs'

import { Location } from '@api/models'

import { googleMapsUrl } from '@services'

import styles from './PlacePicker.module.scss'

interface Props {
    defaultValue?: Location
    onChange: (location?: Location) => void
}

const PlacePicker: React.FC<Props> = (props) => {
    const { t } = useTranslation()

    const [loading, setLoading] = useState(true)

    useEffect(() => {
        scriptJs(googleMapsUrl, () => {
            setLoading(false)
        })
    })

    if (loading) {
        return <Spinner animation="border" />
    }

    return (
        <Geosuggest
            initialValue={props.defaultValue?.label}
            inputClassName={styles.input}
            placeholder={t('form.searchPlaces', 'Search places')}
            suggestItemActiveClassName={styles.suggestsItemActive}
            suggestItemClassName={styles.suggestsItem}
            suggestsClassName={styles.suggests}
            suggestsHiddenClassName={styles.suggestsHidden}
            onSuggestSelect={(suggest) => {
                if (!suggest) {
                    props.onChange(undefined)

                    return
                }

                props.onChange({
                    label: suggest.label,
                    coords: {
                        lat: suggest.location.lat,
                        lng: suggest.location.lng,
                    },
                })
            }}
        />
    )
}

export default PlacePicker
