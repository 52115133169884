import { faCog, faHighlighter } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon as FAIcon } from '@fortawesome/react-fontawesome'
import { Button } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { Link } from 'react-router-dom'

import { SceneResponse } from '@api'

import { sceneApi } from '@services'

import { generateOrganizationEditDevicePath, generateOrganizationScenesPath } from '@helpers/VividiURLs'
import { useAddBackLink } from '@helpers/backlinks'

import Heading from '@elements/Heading/Heading'

import ImageLoadingWrapper from '@components/ImageLoadingWrapper'
import PageContentLayout from '@components/Layouts/PageContentLayout'
import LegacyLoadingWrapper from '@components/LegacyLoadingWrapper'
import LoadingSpinner from '@components/LoadingSpinner'

import styles from './FalsePositiveDiagnosticsPage.module.scss'

type SimpleHeatmapProps = {
    scene: SceneResponse
}

const SimpleHeatmap = ({ scene }: SimpleHeatmapProps) => {
    const { t } = useTranslation()
    const snapshotRequest = useQuery(
        sceneApi.getTrajectoryHeatmap.query({
            sceneId: scene.id,
        })
    )

    const addBackLink = useAddBackLink()

    return (
        <div className={styles.heatmapBlock}>
            <Heading level={3}>
                #{scene.deviceId}
                <Button
                    as={Link}
                    size="sm"
                    to={addBackLink(generateOrganizationScenesPath(scene.organizationId ?? 0, scene.id))}
                    variant="outline-primary"
                >
                    <FAIcon icon={faHighlighter} /> {t('button.detail', 'Detail')}
                </Button>
                {scene.deviceId && (
                    <Button
                        as={Link}
                        size="sm"
                        to={generateOrganizationEditDevicePath(scene.organizationId, scene.deviceId)}
                        variant="outline-primary"
                    >
                        <FAIcon icon={faCog} /> {t('button.configure', 'Configure')}
                    </Button>
                )}
            </Heading>
            {snapshotRequest.status === 'error' ? (
                <Button onClick={() => snapshotRequest.refetch()}>{t('button.retry', 'Retry')}</Button>
            ) : (
                <ImageLoadingWrapper
                    errorComponent={<div>{t('others.imageNotAvailable', 'Image not available')}</div>}
                    placeholder={<LoadingSpinner bare={true} />}
                    request={snapshotRequest}
                >
                    <img alt="" src="" />
                </ImageLoadingWrapper>
            )}
        </div>
    )
}

const FalsePositiveDiagnosticsPage = () => {
    const { t } = useTranslation()

    const scenesCall = useQuery(sceneApi.getAllScenes.query())
    const documentTitle = t('title.fpDiagnostics', 'FP Diagnostics')

    const heading = t('heading.falsePositives', 'False Positive diagnostics')

    return (
        <>
            <LegacyLoadingWrapper
                placeholder={
                    <PageContentLayout
                        documentTitle={documentTitle}
                        fluid={true}
                        heading={heading}
                        loadingState={scenesCall.status}
                    />
                }
                request={scenesCall}
            >
                {(scenes) => (
                    <PageContentLayout documentTitle={documentTitle} fluid={true} heading={heading}>
                        <div className={styles.heatmapsContainer}>
                            {scenes.scenes
                                .filter((scene) => scene.deviceId !== undefined)
                                .map((scene) => (
                                    <SimpleHeatmap key={scene.id} scene={scene} />
                                ))}
                        </div>
                    </PageContentLayout>
                )}
            </LegacyLoadingWrapper>
        </>
    )
}

export default FalsePositiveDiagnosticsPage
