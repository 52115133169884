/* tslint:disable */
/* eslint-disable */
/**
 * Vividi Dashboard API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateDeviceRequestBody
 */
export interface UpdateDeviceRequestBody {
    /**
     * 
     * @type {string}
     * @memberof UpdateDeviceRequestBody
     */
    note?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDeviceRequestBody
     */
    color?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateDeviceRequestBody
     */
    organizationId?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateDeviceRequestBody
     */
    simPhoneNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDeviceRequestBody
     */
    vividiImageName?: string;
}

export function UpdateDeviceRequestBodyFromJSON(json: any): UpdateDeviceRequestBody {
    return UpdateDeviceRequestBodyFromJSONTyped(json, false);
}

export function UpdateDeviceRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateDeviceRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'note': !exists(json, 'note') ? undefined : json['note'],
        'color': !exists(json, 'color') ? undefined : json['color'],
        'organizationId': !exists(json, 'organization_id') ? undefined : json['organization_id'],
        'simPhoneNumber': !exists(json, 'sim_phone_number') ? undefined : json['sim_phone_number'],
        'vividiImageName': !exists(json, 'vividi_image_name') ? undefined : json['vividi_image_name'],
    };
}

export function UpdateDeviceRequestBodyToJSON(value?: UpdateDeviceRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'note': value.note,
        'color': value.color,
        'organization_id': value.organizationId,
        'sim_phone_number': value.simPhoneNumber,
        'vividi_image_name': value.vividiImageName,
    };
}

