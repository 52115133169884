import { IconDefinition } from '@fortawesome/fontawesome-common-types'
import { faFrown, faGrin, faMeh } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import { Dictionary, fromPairs, head, isEmpty, maxBy, minBy, omitBy, orderBy } from 'lodash'
import { DateTime, Interval } from 'luxon'
import React, { ReactNode, useMemo, useState } from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import {
    DetectionZoneWithId,
    EmotionSummaryResponse,
    LocalityResponse,
    OutageModel,
    SceneDescription,
    SceneListResponse,
} from '@api'

import { generateOrganizationScenesPath } from '@helpers/VividiURLs'
import { useAddBackLink } from '@helpers/backlinks'
import { useLocalizeDateTime, useTimezoneConfig } from '@helpers/timezoneConfig'
import { IntervalType, NumberFormat } from '@helpers/types'
import { pickColumn } from '@helpers/utils'

import Box from '@elements/Box/Box'
import ToggleButton from '@elements/Buttons/ToggleButton'
import CardTitle from '@elements/Card/CardTitle'
import TableWithSorting, { CellContent } from '@elements/Table/TableWithSorting'

import AdminOnlyLinkRenderer from '@components/AdminOnlyLinkRenderer'
import { CarouselCardHeader, ConfigurationHeatmapCarousel } from '@components/ConfigurationHeatmapCarousel'
import { displayDateTimes } from '@components/StatisticsSummary/utils'
import palette from '@components/palette.module.scss'

import styles from './EmotionsDetails.module.scss'
import EmotionsHighlights from './EmotionsHighlights'
import EmotionsScoreAverageInTimePlot from './EmotionsScoreAverageInTimePlot'
import EmotionsScoreDistributionInTimePlot, { EmotionsLegend } from './EmotionsScoreDistributionInTimePlot'
import {
    prepareEmotionDetailsHighlightsData,
    prepareEmotionsBreakDownTableData,
    prepareScoreDistributionInTimePlotData,
} from './emotionsDetailsPreprocessors'

export type EmotionsCell = {
    value?: number
    valueType: NumberFormat
    icon?: IconDefinition
    changeIndicator?: ReactNode
} & { explanatoryString?: string }

interface Props {
    detectionZonesBySceneName: {
        detectionZones: DetectionZoneWithId[]
        sceneName: string
        sceneId: string
    }[]
    emotionsSummary: Dictionary<EmotionSummaryResponse>
    granularEmotionsSummary: Dictionary<EmotionSummaryResponse>
    scenes: SceneListResponse
    sceneConfiguration: Dictionary<SceneDescription>
    locality: LocalityResponse
    interval: Interval
    now: DateTime
    intervalType: IntervalType
    outages: OutageModel[]
    selectedZones: DetectionZoneWithId[] | undefined
    localityDetectionZones: DetectionZoneWithId[]
}

const EmotionsCarouselHeader: React.FC<CarouselCardHeader> = ({ name, value }) => {
    const numericValue = parseInt(value)

    return (
        <div className={styles.emotionsCarouselHeader}>
            <div className={styles.highlightedValue} style={{ color: determineEmotionColor(numericValue) }}>
                <FontAwesomeIcon
                    className={classNames(determineEmotionIconStyle(numericValue), styles.emotionIconSmall)}
                    icon={determineEmotionIcon(numericValue)}
                />
                {value}
                <span className={classNames('text-muted', styles.maxValueIndicator)}>&nbsp; / 10</span>
            </div>
            <div>
                <span>
                    <strong> {name} </strong>
                </span>
            </div>
        </div>
    )
}

const determineEmotion = (value: number) => (value < 4 ? 'negative' : value > 7 ? 'positive' : 'neutral')

export const determineEmotionIconStyle = (value: number): string => {
    switch (determineEmotion(value)) {
        case 'negative':
            return styles.negativeIcon
        case 'positive':
            return styles.positiveIcon
        case 'neutral':
            return styles.neutralIcon
    }
}

export const determineEmotionIcon = (value: number): IconDefinition => {
    switch (determineEmotion(value)) {
        case 'negative':
            return faFrown
        case 'positive':
            return faGrin
        case 'neutral':
            return faMeh
    }
}

const determineEmotionColor = (value: number): string => {
    switch (determineEmotion(value)) {
        case 'negative':
            return palette.emotionsNegative
        case 'positive':
            return palette.emotionsPositive
        case 'neutral':
            return palette.black
    }
}

const EmotionCell: React.FC<
    {
        value?: number
        valueType: NumberFormat
        icon?: IconDefinition
        changeIndicator?: ReactNode
    } & { explanatoryString?: string }
> = ({ value = 0, explanatoryString }) => (
    <td className={styles.emotionsTableCell}>
        <div>
            <span>
                <FontAwesomeIcon className={determineEmotionIconStyle(value)} icon={determineEmotionIcon(value)} />
                {value}
                <span className={styles.emotionMaxScore}>/ 10</span>
            </span>
            <span className={styles.emotionsCellExplanatoryString}>{explanatoryString}</span>
        </div>
    </td>
)

export const formatEmotionTimeKey =
    (localize: (dateTime: DateTime) => DateTime) =>
    (intervalType: IntervalType, dateTime: DateTime, dateStyle?: 'medium' | 'full' | 'long' | 'short') =>
        intervalType !== IntervalType.DAY
            ? displayDateTimes({
                  date: localize(dateTime),
                  dateStyle,
              })
            : localize(dateTime).toFormat('HH:00')

const EmotionsDetailsContent: React.FC<Props> = ({
    detectionZonesBySceneName,
    emotionsSummary,
    granularEmotionsSummary,
    locality,
    scenes,
    sceneConfiguration,
    selectedZones,
    interval,
    intervalType,
    now,
}) => {
    const localize = useLocalizeDateTime()

    const zone = useTimezoneConfig()

    const { t } = useTranslation()
    const addBackLink = useAddBackLink()

    const dateFormatter = formatEmotionTimeKey(localize)

    const [aggType, setAggType] = useState<'average' | 'distribution'>('distribution')

    const scenesInLocality = scenes.scenes.filter((s) => s.localityIds.includes(Number(locality.id)))

    const emotionsBreakDown = prepareEmotionsBreakDownTableData(
        detectionZonesBySceneName,
        granularEmotionsSummary,
        locality,
        selectedZones
    )

    const highlightsData = prepareEmotionDetailsHighlightsData(emotionsSummary, locality, selectedZones)

    const distributionPlotData = prepareScoreDistributionInTimePlotData(
        zone,
        granularEmotionsSummary,
        locality,
        selectedZones
    )
    const organizationId = head(scenesInLocality)?.organizationId ?? -1

    const emotionsBreakdownTableData: Array<Dictionary<CellContent>> = emotionsBreakDown.map(
        ({ scene, zoneName, averageEmotionScore, overallData, mostPositiveDay, theMostNegativeDay }) => ({
            scene: {
                content: scene,
                cellType: 'text',
                targetLink: addBackLink(
                    generateOrganizationScenesPath(
                        organizationId,
                        scenes.scenes.find((s) => s.label === scene)?.id ?? 0
                    )
                ),
                renderer: AdminOnlyLinkRenderer,
            },
            zoneName: {
                content: zoneName,
                cellType: 'text',
            },
            averageEmotionScore: {
                content: averageEmotionScore === 0 ? undefined : averageEmotionScore?.toFixed(1),
                cellType: 'numeric',
                numberFormat: 'count',
                renderer: EmotionCell,
                explanatoryString: `${String(overallData.totalObservations)} people`,
            },
            mostPositiveDay: {
                content: mostPositiveDay.averageScore?.toFixed(1),
                cellType: 'numeric',
                numberFormat: 'count',
                renderer: EmotionCell,
                explanatoryString: mostPositiveDay.timeKey
                    ? dateFormatter(intervalType, mostPositiveDay.timeKey)
                    : 'N/A',
            },
            mostNegativeDay: {
                content: theMostNegativeDay.averageScore?.toFixed(1),
                cellType: 'numeric',
                numberFormat: 'count',
                renderer: EmotionCell,
                explanatoryString: theMostNegativeDay.timeKey
                    ? dateFormatter(intervalType, theMostNegativeDay.timeKey)
                    : 'N/A',
            },
        })
    )

    let zones = Object.fromEntries(
        Object.entries(sceneConfiguration).map(([sceneId, configuration]) => [
            Number(sceneId),
            configuration.detectionZones,
        ])
    )

    if (!isEmpty(selectedZones)) {
        zones = omitBy(
            zones,
            (detectionZones) => !detectionZones.some((zone) => selectedZones?.find((it) => it.id === zone.id))
        )
    }

    const orderedSceneIds = pickColumn(
        orderBy(emotionsBreakDown, (zone) => zone.mostPositiveDay.averageScore ?? 0, 'desc'),
        'sceneId'
    )

    const orderedZones = fromPairs(
        Object.entries(zones).sort((a, b) => orderedSceneIds.indexOf(a[0]) - orderedSceneIds.indexOf(b[0]))
    )

    const orderedScenes = scenesInLocality
        .filter((it) => orderedSceneIds.includes(it.id.toString()))
        .sort((a, b) => orderedSceneIds.indexOf(a.id.toString()) - orderedSceneIds.indexOf(b.id.toString()))
        .slice(0, 3)

    const memoizedData = useMemo(() => emotionsBreakdownTableData, [granularEmotionsSummary])

    const localityMostPositiveTime = maxBy(
        emotionsBreakDown,
        (zone) => zone.mostPositiveDay.averageScore
    )?.mostPositiveDay

    const localityMostNegativeTime = minBy(
        emotionsBreakDown,
        (zone) => zone.theMostNegativeDay.averageScore
    )?.theMostNegativeDay

    return (
        <>
            <Row>
                <Col lg={8}>
                    <Box className={styles.emotionHighlightCard}>
                        <Box.Title text={t('emotions.avgDistribution', 'Emotions highlights')} />
                        <EmotionsHighlights
                            highlightsData={highlightsData}
                            interval={interval}
                            intervalType={intervalType}
                            locality={locality}
                            localize={localize}
                            theMostNegativeDay={localityMostNegativeTime}
                            theMostPositiveDay={localityMostPositiveTime}
                        />
                    </Box>
                </Col>
                <Col className="pt-4 pt-lg-0" lg={4}>
                    <Card className={styles.heatmapCard}>
                        <Card.Body>
                            <CardTitle text={t('heading.highestEmotionScore', 'Highest emotion score')} />
                        </Card.Body>
                        <ConfigurationHeatmapCarousel
                            carouselWidth="370px"
                            drawBoundaries={false}
                            drawZones={true}
                            scenes={orderedScenes}
                            zoneLabelRenderer={EmotionsCarouselHeader}
                            zoneLabels={Object.fromEntries(
                                emotionsBreakDown.map(({ zoneId, mostPositiveDay }) => [
                                    zoneId,
                                    mostPositiveDay.averageScore?.toFixed(1) ?? '0',
                                ])
                            )}
                            zones={orderedZones}
                        />
                    </Card>
                </Col>
            </Row>

            <Row>
                <Col>
                    <Box className={styles.plot}>
                        <Box.Title
                            buttons={
                                <ToggleButton
                                    leftToggle={{
                                        toggleText: t('statistics.distribution', 'Distribution'),
                                        toggleValue: 'distribution',
                                    }}
                                    rightToggle={{
                                        toggleText: t('statistics.average', 'Average'),
                                        toggleValue: 'average',
                                    }}
                                    toggleName="view-toggle"
                                    toggleValue={aggType}
                                    onToggle={() =>
                                        setAggType((prevState) =>
                                            prevState === 'average' ? 'distribution' : 'average'
                                        )
                                    }
                                />
                            }
                            text={
                                aggType === 'distribution'
                                    ? t('emotions.scoreDistributionInTimePlot', 'Faces distribution in time')
                                    : t('emotions.avgEmotionInTimePlot', 'Average emotion score in time')
                            }
                        />
                        {aggType === 'distribution' && (
                            <EmotionsScoreDistributionInTimePlot
                                emotionData={distributionPlotData.diagramData}
                                interval={interval}
                                intervalType={intervalType}
                                now={now}
                            />
                        )}
                        {aggType === 'average' && (
                            <EmotionsScoreAverageInTimePlot
                                emotionData={distributionPlotData.diagramData}
                                interval={interval}
                                intervalType={intervalType}
                                now={now}
                            />
                        )}
                        <EmotionsLegend />
                    </Box>
                </Col>
            </Row>

            <Row>
                <Col>
                    <Box>
                        <Box.Title text={t('emotions.zoneBreakdown', 'Emotion zones breakdown')} />
                        <TableWithSorting
                            bodyRows={memoizedData}
                            defaultSortingColumn="averageEmotionScore"
                            defaultSortingOrder="desc"
                            excludedSortingColumns={['detail']}
                            headRow={[
                                {
                                    name: 'scene',
                                    displayName: t('table.scene', 'Scene'),
                                },
                                {
                                    name: 'zoneName',
                                    displayName: t('table.zone', 'Zone'),
                                },
                                {
                                    name: 'averageEmotionScore',
                                    displayName: t('emotions.avgEmotionScore', 'Avg. emotion score'),
                                },
                                {
                                    name: 'mostPositiveDay',
                                    displayName:
                                        intervalType === IntervalType.DAY
                                            ? t('emotions.theMostPositiveHour', 'The most positive hour')
                                            : t('emotions.theMostPositiveDay', 'The most positive day'),
                                },
                                {
                                    name: 'mostNegativeDay',
                                    displayName:
                                        intervalType === IntervalType.DAY
                                            ? t('emotions.theMostNegativeHour', 'The most negative hour')
                                            : t('emotions.theMostNegativeDay', 'The most negative day'),
                                },
                            ]}
                            paginationSize={15}
                        />
                    </Box>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Box className={styles.heatmapCard}>
                        <Box.Title text={`${locality.name} ${t('others.emotions', 'emotion zones')}`} />
                        <ConfigurationHeatmapCarousel
                            carouselWidth="1300px"
                            drawBoundaries={false}
                            drawZones={true}
                            scenes={scenesInLocality}
                            zones={zones}
                        />
                    </Box>
                </Col>
            </Row>
        </>
    )
}

export default EmotionsDetailsContent
