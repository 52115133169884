import classNames from 'classnames'
import React from 'react'
import { Badge } from 'react-bootstrap'

import styles from './ToggleBadge.module.scss'

interface ToggleButtonProps {
    selected: boolean
    name: string
    onToggle: (label: string) => void
}

const ToggleBadge: React.FC<ToggleButtonProps> = ({ selected, name, onToggle }) => {
    return (
        <div onClick={() => onToggle(name)}>
            <Badge
                className={classNames({
                    [styles.toggleBadge]: true,
                })}
                variant={selected ? 'primary' : 'secondary'}
            >
                {name}
            </Badge>
        </div>
    )
}

export default ToggleBadge
