import { faCheck, faPlus, faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import produce from 'immer'
import { fromPairs } from 'lodash'
import { useState, useCallback } from 'react'
import { Form, Col, Row, Button } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import { useStateWithCallbackLazy } from 'use-state-with-callback'

import {
    OrganizationListResponse,
    StaySafeNotificationPreferences,
    UserNotificationPreferences,
    UserResponse,
    LocalityNameListResponse,
} from '@api'

import { profileApi, userApi } from '@services'

import { useApiCallCleaner } from '@helpers/api'
import { defaultErrorEntry, ErrorEntry } from '@helpers/types'

import NumberInput from './GenericInputs/NumberInput'
import LocalityPicker from './LocalityPicker'
import styles from './NotificationPreferencesForm.module.scss'
import NotificationPreferencesTable from './NotificationPreferencesTable'
import TooltipIcon from './TooltipIcon'
import { useNotify } from './notifications/NotificationsContext'

export const initialNotificationPreferences: StaySafeNotificationPreferences = {
    localityId: -1,
    capacityExceededNotificationThreshold: 1,
    capacityExceededNotificationGracePeriod: 60,
    capacityExceededNotificationCooldown: 1800,
    useEmail: false,
    useSms: true,
}

export const initialNotificationErrorState = {
    localityId: defaultErrorEntry,
    capacityExceededNotificationThreshold: defaultErrorEntry,
    capacityExceededNotificationGracePeriod: defaultErrorEntry,
    capacityExceededNotificationCooldown: defaultErrorEntry,
}

export interface NotificationErrors {
    localityId: ErrorEntry
    capacityExceededNotificationThreshold: ErrorEntry
    capacityExceededNotificationGracePeriod: ErrorEntry
    capacityExceededNotificationCooldown: ErrorEntry
}

interface NotificationEditFormProps {
    localities: LocalityNameListResponse
    organizations: OrganizationListResponse
    currentValues: StaySafeNotificationPreferences
    onUpdate: (updatedNotification: StaySafeNotificationPreferences) => void
    onErrors: (errorData: NotificationErrors) => void
    errors: NotificationErrors
}
const NotificationEditForm = ({
    localities,
    organizations,
    currentValues,
    onErrors: handleErrors,
    onUpdate: handleUpdate,
    errors,
}: NotificationEditFormProps) => {
    const pickedLocality = currentValues
        ? localities.localities.find((l) => l.id === currentValues.localityId)
        : localities.localities[0]

    return (
        <Form.Group as={Row}>
            <Col sm={3}>
                <LocalityPicker
                    features={['realtimeOccupancy', 'staySafeNotifier']}
                    localities={localities.localities}
                    organizations={fromPairs(organizations.organizations.map((org) => [org.id, org]))}
                    selectedLocality={pickedLocality}
                    onSelect={(locality) => {
                        handleUpdate({
                            ...currentValues,
                            localityId: locality.id ?? -1,
                        })

                        handleErrors({
                            ...errors,
                            localityId: {
                                isTouched: true,
                                isInvalid: locality.id !== undefined && locality.id <= 0,
                            },
                        })
                    }}
                />
            </Col>
            <Col sm={3}>
                <NumberInput
                    isInvalid={
                        errors.capacityExceededNotificationThreshold.isTouched &&
                        errors.capacityExceededNotificationThreshold.isInvalid
                    }
                    name="capacityExceededNotificationThreshold"
                    value={currentValues.capacityExceededNotificationThreshold}
                    onChange={(val) => {
                        handleUpdate({
                            ...currentValues,
                            capacityExceededNotificationThreshold: val ?? 1,
                        })

                        handleErrors({
                            ...errors,
                            capacityExceededNotificationThreshold: {
                                isTouched: true,
                                isInvalid: val !== undefined && val <= 0,
                            },
                        })
                    }}
                />
            </Col>
            <Col sm={3}>
                <NumberInput
                    isInvalid={
                        errors.capacityExceededNotificationGracePeriod.isTouched &&
                        errors.capacityExceededNotificationGracePeriod.isInvalid
                    }
                    name="capacityExceededNotificationGracePeriod"
                    value={currentValues.capacityExceededNotificationGracePeriod}
                    onChange={(val) => {
                        handleUpdate({
                            ...currentValues,
                            capacityExceededNotificationGracePeriod: val ?? 0,
                        })

                        handleErrors({
                            ...errors,
                            capacityExceededNotificationGracePeriod: {
                                isTouched: true,
                                isInvalid: val !== undefined && val < 0,
                            },
                        })
                    }}
                />
            </Col>
            <Col sm={3}>
                <NumberInput
                    isInvalid={
                        errors.capacityExceededNotificationCooldown.isTouched &&
                        errors.capacityExceededNotificationCooldown.isInvalid
                    }
                    name="capacityExceededNotificationCooldown"
                    value={currentValues.capacityExceededNotificationCooldown}
                    onChange={(val) => {
                        handleUpdate({
                            ...currentValues,
                            capacityExceededNotificationCooldown: val ?? 0,
                        })

                        handleErrors({
                            ...errors,
                            capacityExceededNotificationCooldown: {
                                isTouched: true,
                                isInvalid: val !== undefined && val < 1800,
                            },
                        })
                    }}
                />
            </Col>
        </Form.Group>
    )
}

const NotificationPreferencesForm = ({
    organizations,
    localities,
    notificationPreferences,
    user,
}: {
    organizations: OrganizationListResponse
    localities: LocalityNameListResponse
    notificationPreferences: UserNotificationPreferences
    user: Omit<UserResponse, 'localityIds'>
}) => {
    const { t } = useTranslation()

    const notify = useNotify()
    const clean = useApiCallCleaner()

    const { mutate: editNotificationPreferences } = useMutation(userApi.setNotificationPreferences, {
        onSuccess: () => {
            clean(userApi)
            clean(profileApi)
            notify({
                variant: 'success',
                title: t('notification.updateSuccessful', 'Update successful'),
                content: t(
                    'notification.notificationUpdateSuccessful',
                    'Notification preferences were updated successfully'
                ),
                timeoutSeconds: 3,
            })
        },
        onError: () => {
            notify({
                title: t('notification.updateFailed', 'Update failed'),
                content: t(
                    'notification.notificationUpdateFailedContent',
                    'An error was encountered when updating notification preferences.'
                ),
                variant: 'danger',
            })
        },
    })

    const [staySafeNotifications, setStaySafeNotifications] = useStateWithCallbackLazy(notificationPreferences.staySafe)
    const [isNewEntry, setIsNewEntry] = useState(false)
    const [updateEntryPosition, setUpdateEntryPosition] = useState<number | null>(null)
    const [editedStaySafeSubscription, setEditedStaySafeSubscription] = useState(initialNotificationPreferences)
    const [errors, setErrors] = useState<NotificationErrors>(initialNotificationErrorState)
    const handleStaySafeNotificationsEdit = (data: StaySafeNotificationPreferences, index: number) => {
        setEditedStaySafeSubscription(data)
        setUpdateEntryPosition(index)
    }

    const handleStaySafeNotificationsDelete = (index: number) => {
        setStaySafeNotifications(
            (prev) =>
                produce(prev, (draft) => {
                    draft.splice(index, 1)
                }),
            updateNotifications
        )
    }

    const updateNotifications = useCallback(
        (notifications: StaySafeNotificationPreferences[]) => {
            const body = { staySafe: notifications }

            editNotificationPreferences({
                userId: user.id,
                body,
            })
        },
        [user.id, editNotificationPreferences]
    )

    return (
        <>
            <div className={styles.notificationsHeaderContainer}>
                <h2 className={styles.notificationHeading}>
                    {isNewEntry
                        ? t('heading.newNotification', 'New notification')
                        : updateEntryPosition !== null
                        ? t('heading.notificationUpdate', 'Notification preferences update')
                        : t('heading.notifications', 'Notifications')}
                </h2>

                {isNewEntry || updateEntryPosition !== null ? (
                    <div className={styles.notificationsActionButtonContainer}>
                        <Button
                            className={styles.cancelButton}
                            variant="secondary"
                            onClick={() => {
                                isNewEntry ? setIsNewEntry(false) : setUpdateEntryPosition(null)
                                setErrors(initialNotificationErrorState)
                            }}
                        >
                            <FontAwesomeIcon icon={faTimes} />
                            {t('button.cancel', 'Cancel')}
                        </Button>
                        <Button
                            variant="primary"
                            onClick={() => {
                                if (Object.values(errors).some((it) => it.isInvalid)) {
                                    notify({
                                        variant: 'danger',
                                        title: t('notification.validationFailed', 'Validation failed'),
                                        content: t(
                                            'notification.invalidFields',
                                            'Some of the fields are not filled in correctly'
                                        ),
                                    })

                                    return
                                } else if (editedStaySafeSubscription.localityId < 0) {
                                    notify({
                                        variant: 'danger',
                                        title: t('notification.validationFailed', 'Validation failed'),
                                        content: t('notification.noLocalitySelected', 'No locality selected'),
                                    })

                                    return
                                }

                                if (isNewEntry) {
                                    setStaySafeNotifications(
                                        (prev) => [...prev, editedStaySafeSubscription],
                                        updateNotifications
                                    )
                                } else {
                                    setStaySafeNotifications(
                                        (prev) =>
                                            produce(prev, (draft) => {
                                                if (updateEntryPosition !== null) {
                                                    draft[updateEntryPosition] = editedStaySafeSubscription
                                                }
                                            }),
                                        updateNotifications
                                    )
                                }
                                setUpdateEntryPosition(null)
                                setIsNewEntry(false)
                            }}
                        >
                            <FontAwesomeIcon icon={faCheck} />
                            {t('button.save', 'Save')}
                        </Button>
                    </div>
                ) : (
                    <Button
                        variant="primary"
                        onClick={() => {
                            setIsNewEntry((prev) => !prev)
                            setEditedStaySafeSubscription(initialNotificationPreferences)
                        }}
                    >
                        <FontAwesomeIcon icon={faPlus} />
                        {t('button.addNotification', 'Add notification')}
                    </Button>
                )}
            </div>

            {isNewEntry || updateEntryPosition !== null ? (
                <Row className={styles.headerRowContainer}>
                    <Col sm={3}>
                        <b> {t('heading.locality', 'Locality')}</b>
                    </Col>
                    <Col sm={3}>
                        <b>{t('table.staySafeLimit', 'Stay Safe limit')}</b>
                        <TooltipIcon>
                            {t(
                                'tooltip.staySafeLimit',
                                'The number of people above the capacity threshold triggering the sending of notification.'
                            )}
                        </TooltipIcon>
                    </Col>
                    <Col sm={3}>
                        <b>{t('table.sendNotificationAfter', 'Send notification after (sec)')}</b>
                        <TooltipIcon>
                            {t(
                                'tooltip.sendNotificationAfter',
                                "The period of time before exceeded Stay Safe limit triggers the first SMS notification to user's telephone number."
                            )}
                        </TooltipIcon>
                    </Col>
                    <Col sm={3}>
                        <b>{t('table.repeatNotificationAfter', 'Repeat notification after (sec)')}</b>
                        <TooltipIcon>
                            {t(
                                'tooltip.repeatNotificationAfter',
                                'The interval between notifications in case the limit remains exceeded.'
                            )}
                        </TooltipIcon>
                    </Col>
                    {!isNewEntry && updateEntryPosition === null && <Col sm={2}>{t('table.actions', 'Actions')}</Col>}
                </Row>
            ) : (
                ''
            )}
            {!isNewEntry && updateEntryPosition === null && staySafeNotifications.length > 0 ? (
                <NotificationPreferencesTable
                    localities={localities}
                    notificationData={staySafeNotifications}
                    onDelete={handleStaySafeNotificationsDelete}
                    onEdit={handleStaySafeNotificationsEdit}
                />
            ) : staySafeNotifications.length === 0 ? (
                <div className={styles.noActiveNotificationContainer}>
                    <span className={styles.noActiveNotificationsSpan}>
                        {t('others.noActiveNotifications', 'No active notifications')}
                    </span>
                </div>
            ) : (
                ''
            )}
            {isNewEntry && (
                <div className={isNewEntry ? styles.fadeIn : styles.fadeOut}>
                    <NotificationEditForm
                        currentValues={editedStaySafeSubscription}
                        errors={errors}
                        localities={localities}
                        organizations={organizations}
                        onErrors={setErrors}
                        onUpdate={setEditedStaySafeSubscription}
                    />
                </div>
            )}
            {updateEntryPosition !== null && (
                <div className={updateEntryPosition !== null ? styles.fadeIn : styles.fadeOut}>
                    <NotificationEditForm
                        currentValues={editedStaySafeSubscription}
                        errors={errors}
                        localities={localities}
                        organizations={organizations}
                        onErrors={setErrors}
                        onUpdate={setEditedStaySafeSubscription}
                    />
                </div>
            )}
        </>
    )
}

export default NotificationPreferencesForm
