import { sortBy } from 'lodash'
import React from 'react'
import { Form } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import { DockerImageResponse } from '@api/models'

import { parseDate } from './plots/common'

export interface Props {
    availableImages: DockerImageResponse[]
    onSelectImage: (image: DockerImageResponse) => void
    selectedImage?: DockerImageResponse
    disabled?: boolean
}

const imageDisplayName = (image: DockerImageResponse) => `${image.name}:${image.tag}`
const findImageByDisplayName = (displayName: string, images: DockerImageResponse[]) =>
    images.find((i) => displayName === imageDisplayName(i))

const VividiImagePicker = (props: Props) => {
    const { t } = useTranslation()

    const images = sortBy(
        props.availableImages,
        (image) => image.name,
        (image) => -parseDate(image.createdAt).toSeconds()
    )

    return (
        <Form.Control
            as="select"
            disabled={images.length === 0 || props.disabled === true}
            value={props.selectedImage ? imageDisplayName(props.selectedImage) : ''}
            onChange={(e) => {
                const image = findImageByDisplayName(e.currentTarget.value, props.availableImages)

                if (image) {
                    props.onSelectImage(image)
                }
            }}
        >
            <option disabled={true} value="">
                {images.length === 0
                    ? t('others.noUsableImages', 'No usable images are available')
                    : t('button.selectImage', 'Select an image')}
            </option>
            {images.map((image) => (
                <option key={imageDisplayName(image)} value={imageDisplayName(image)}>
                    {imageDisplayName(image)}
                </option>
            ))}
        </Form.Control>
    )
}

export default VividiImagePicker
