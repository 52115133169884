import { faUser, faUserCog } from '@fortawesome/free-solid-svg-icons'
import { faUserCrown } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

import { Role } from '@api'

export const determineIconByRole = (role: Role) =>
    role === Role.Administrator
        ? faUserCrown
        : role === Role.OrganizationAdministrator || role === Role.OrganizationOwner
        ? faUserCog
        : faUser

const UserRoleIndicator: React.FC<{ user: { role: Role }; className?: string }> = ({ user, className }) => (
    <FontAwesomeIcon className={className} icon={determineIconByRole(user.role)} />
)

export default UserRoleIndicator
