/* tslint:disable */
/* eslint-disable */
/**
 * Vividi Dashboard API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface VisibleEntitiesByZoneItem
 */
export interface VisibleEntitiesByZoneItem {
    /**
     * Identifier of the scene in which the entities were observed
     * @type {number}
     * @memberof VisibleEntitiesByZoneItem
     */
    sceneId: number;
    /**
     * Number of entities currently visible in a single zone
     * @type {number}
     * @memberof VisibleEntitiesByZoneItem
     */
    visibleEntities: number;
    /**
     * Identifier of the zone in which the entities were observed
     * @type {number}
     * @memberof VisibleEntitiesByZoneItem
     */
    zoneId: number;
    /**
     * Name of the zone in which the entities were observed
     * @type {string}
     * @memberof VisibleEntitiesByZoneItem
     */
    zoneName?: string;
}

export function VisibleEntitiesByZoneItemFromJSON(json: any): VisibleEntitiesByZoneItem {
    return VisibleEntitiesByZoneItemFromJSONTyped(json, false);
}

export function VisibleEntitiesByZoneItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): VisibleEntitiesByZoneItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sceneId': json['scene_id'],
        'visibleEntities': json['visible_entities'],
        'zoneId': json['zone_id'],
        'zoneName': !exists(json, 'zone_name') ? undefined : json['zone_name'],
    };
}

export function VisibleEntitiesByZoneItemToJSON(value?: VisibleEntitiesByZoneItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'scene_id': value.sceneId,
        'visible_entities': value.visibleEntities,
        'zone_id': value.zoneId,
        'zone_name': value.zoneName,
    };
}

