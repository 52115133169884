import {
    Dictionary,
    findIndex,
    flatten,
    fromPairs,
    groupBy,
    isEmpty,
    mapValues,
    maxBy,
    mean,
    minBy,
    omitBy,
    orderBy,
    round,
    sum,
    union,
    uniq,
} from 'lodash'
import { DateTime, Interval, Zone } from 'luxon'

import {
    DatetimeInterval,
    SceneDescription,
    SceneListResponse,
    ZoneOccupancySession,
    ZoneOccupancySummaryResponse,
} from '@api/models'

import { gdynusaReadableInterval } from '@helpers/datetimeUtils'

import { weekDays } from '@components/ConversionDashboard/conversionDetailsPreprocessor'
import { parseDate } from '@components/plots/common'

import { getDateWeekday } from './OccupancyDetailsHistogram'

export type TimelineDisplayData = {
    [day: string]: {
        [zoneId: number]: {
            sessionInterval: Interval
            sessionDurationInSeconds: number
            startingFrom: string
            endingAt: string
            zoneId: number
            zoneName: string
            averagePersons?: number | undefined
            maxPersons?: number | undefined
        }[]
    }
}

export const prepareSessionsData = (
    sessions: ZoneOccupancySession[],
    localize: (datetime: DateTime) => DateTime
): TimelineDisplayData =>
    mapValues(
        groupBy(sessions, ({ startingFrom }) => localize(parseDate(startingFrom)).toISODate()),
        (sessions) =>
            mapValues(groupBy(sessions, 'zoneId'), (zoneSessions) =>
                zoneSessions.map((session) => ({
                    ...session,
                    sessionInterval: Interval.fromDateTimes(
                        localize(parseDate(session.startingFrom)),
                        localize(parseDate(session.endingAt))
                    ),
                    sessionDurationInSeconds:
                        Interval.fromDateTimes(
                            localize(parseDate(session.startingFrom)),
                            localize(parseDate(session.endingAt))
                        )
                            .toDuration('seconds')
                            .toObject().seconds ?? 0,
                }))
            )
    )

type HistogramDisplayData = {
    [zoneId: number]: Array<{
        name: string
        value: number
        frequency: number
        average: number
    }>
}

export const prepareHistogramData = (
    timeZone: Zone,
    timeBrackets: DatetimeInterval[],
    histogramData: {
        cells: Dictionary<number> | Array<number>
        sceneId: number
        zoneName: string
        zoneId: number
    }[],
    histogramType: 'week' | 'day'
): {
    displayData: HistogramDisplayData
    xLabels: Array<string>
    yLabels: Array<string>
} => {
    const orderedHistogramData = orderBy([...histogramData], ({ zoneName }) => zoneName)

    const displayData: HistogramDisplayData = fromPairs(
        orderedHistogramData.map((it) => [
            it.zoneId,
            histogramType === 'week'
                ? weekDays.map((name) => ({
                      name,
                      value: 0,
                      frequency: 0,
                      average: 0,
                  }))
                : uniq(timeBrackets.map((it) => parseDate(it.startingFrom).setZone(timeZone).toFormat('HH:00'))).map(
                      (name) => ({
                          name,
                          value: 0,
                          frequency: 0,
                          average: 0,
                      })
                  ),
        ])
    )

    let xLabels: Array<string> = []
    let yLabels: Array<string> = []

    if (histogramType === 'week') {
        Object.values(orderedHistogramData).forEach((zone) => {
            Object.entries(zone.cells).forEach(([day, value]) => {
                const index = findIndex(displayData[zone.zoneId], {
                    name: getDateWeekday(day),
                })

                if (index !== -1) {
                    displayData[zone.zoneId][index].value += value ?? 0
                    displayData[zone.zoneId][index].frequency += 1
                }
            })
            displayData[zone.zoneId] = displayData[zone.zoneId].map((day) => ({
                ...day,
                average: round(day.value / (day.frequency !== 0 ? day.frequency : 1) / 3600, 2),
            }))
        })
        xLabels = [...weekDays]
        yLabels = Object.values(orderedHistogramData).map((it) => it.zoneName)
    }

    if (histogramType === 'day') {
        Object.values(orderedHistogramData).forEach((zone) => {
            Object.entries(zone.cells).forEach(([_, value], i) => {
                const index = findIndex(displayData[zone.zoneId], {
                    name: parseDate(timeBrackets[i].startingFrom).setZone(timeZone).toFormat('HH:00'),
                })

                if (index !== -1) {
                    displayData[zone.zoneId][index].value += value * 3600 ?? 0
                    displayData[zone.zoneId][index].frequency += 1
                }
            })

            displayData[zone.zoneId] = displayData[zone.zoneId].map((hour) => ({
                ...hour,
                average: round(hour.value / (hour.frequency !== 0 ? hour.frequency : 1) / 3600, 2),
            }))
        })

        xLabels = uniq(timeBrackets?.map((it) => parseDate(it.startingFrom).setZone(timeZone).toFormat('HH:00')) ?? [])
        yLabels = Object.values(orderedHistogramData).map((it) => it.zoneName)
    }

    return { displayData, xLabels, yLabels }
}

interface OccupancyHighlightsData {
    averageSeatOccupancy: number
    maximalSeatOccupancy: number
    theMostBusyDay: string | null
    theLeastBusyDay: string | null
}

interface OccupancySessionsHighlightsData {
    totalSessions: number
    dailySessionsPerSeat: number
    averageSessionDuration: number
    theBusiestWeekDay: string | null
    theBusiestHour: string | null
}

export const prepareOccupancyDetailsContentData = (
    sessions: ZoneOccupancySession[],
    localize: (datetime: DateTime) => DateTime,
    zoneOccupancy: {
        zoneOccupancySummary: ZoneOccupancySummaryResponse
        scenes: SceneListResponse
    },
    sceneConfiguration: Dictionary<SceneDescription>,
    timeZone: Zone
) => {
    const occupancyHighlightsData: OccupancyHighlightsData = {
        averageSeatOccupancy: 0,
        maximalSeatOccupancy: 0,
        theMostBusyDay: null,
        theLeastBusyDay: null,
    }

    const occupancySessionsHighlightsData: OccupancySessionsHighlightsData = {
        totalSessions: sessions.length,
        dailySessionsPerSeat: 0,
        averageSessionDuration: 0,
        theBusiestHour: null,
        theBusiestWeekDay: null,
    }

    const occupancySessionByDayByZoneId = mapValues(
        groupBy(sessions, ({ startingFrom }) => localize(parseDate(startingFrom)).toISODate()),
        (sessions) => groupBy(sessions, 'zoneId')
    )

    const activeTimeBrackets = zoneOccupancy.zoneOccupancySummary.timeBrackets.filter(
        (_, i) => zoneOccupancy.zoneOccupancySummary.openedPortions[i] !== 0
    )

    const activeZoneRows = zoneOccupancy.zoneOccupancySummary.zoneRows.map(({ zoneName, zoneId, sceneId, cells }) => ({
        sceneId,
        zoneName,
        zoneId,
        cells: cells.filter((_, i) => zoneOccupancy.zoneOccupancySummary.openedPortions[i] !== 0),
    }))

    // Group occupancy by days, then sum the same day values
    const summedOccupancyByZoneByDay: {
        cells: {
            [x: string]: number
        }
        sceneId: number
        zoneName: string
        zoneId: number
    }[] = activeZoneRows
        .map((zone) => ({
            ...zone,
            cells: groupBy(
                zone.cells.map((zoneValue, index) => ({
                    date: localize(parseDate(activeTimeBrackets[index].startingFrom)).toISODate(),
                    value: zoneValue,
                })),
                'date'
            ),
        }))
        .map((it) => ({
            ...it,
            cells: mapValues(it.cells, (entry) => sum(entry.map(({ value }) => value)) * 3600),
        }))

    const averageSeatOccupancy = round(
        mean(flatten(summedOccupancyByZoneByDay.map(({ cells }) => Object.values(cells))))
    )

    // [date, duration] tuple
    const maxDailyOccupancy = maxBy(
        summedOccupancyByZoneByDay.flatMap(({ cells }) => Object.entries(cells)),
        ([_, duration]) => duration
    )

    // [date, duration] tuple
    const minDailyOccupancy = minBy(
        summedOccupancyByZoneByDay.flatMap(({ cells }) => Object.entries(cells)),
        ([_, duration]) => duration
    )

    const mostOccupiedZones = orderBy(zoneOccupancy.zoneOccupancySummary.zoneRows, ({ cells }) => sum(cells), 'desc')

    // Order scenes by most occupancy
    const orderedOccupancyScenes = uniq(mostOccupiedZones.map(({ sceneId }) => sceneId))

    // Order zones before passing them to ConfigurationHeatmapCarousel
    const orderedOccupancyZones = mostOccupiedZones.map(({ zoneName }) => zoneName)

    const seats = omitBy(
        mapValues(sceneConfiguration, ({ detectionZones }) =>
            detectionZones
                .filter((zone) => orderedOccupancyZones.includes(zone.name))
                .sort((a, b) => orderedOccupancyZones.indexOf(a.name) - orderedOccupancyZones.indexOf(b.name))
        ),
        isEmpty
    )

    //union between what seats sessions return and what sceneConfigurations has to offer - not to rely on one or the other
    const seatDenominator = union(
        flatten(Object.values(mapValues(occupancySessionByDayByZoneId, (seat) => Object.keys(seat)))),
        flatten(Object.values(mapValues(seats, (seatArray) => seatArray.map((it) => it.name))))
    )

    occupancySessionsHighlightsData.dailySessionsPerSeat = round(
        sessions.length /
            (seatDenominator.length ?? 1) /
            (!isEmpty(summedOccupancyByZoneByDay) ? Object.keys(summedOccupancyByZoneByDay[0].cells).length : 1)
    )

    occupancySessionsHighlightsData.averageSessionDuration = round(
        mean(
            sessions.map(
                ({ startingFrom, endingAt }) =>
                    gdynusaReadableInterval(
                        Interval.fromDateTimes(localize(parseDate(startingFrom)), localize(parseDate(endingAt)))
                    )
                        .toDuration('seconds')
                        .toObject().seconds ?? 0
            )
        )
    )

    const theBusiestDay = maxBy(
        Object.entries(groupBy(sessions, ({ startingFrom }) => localize(parseDate(startingFrom)).toISODate())),
        ([_, sessions]) => sessions.length
    )

    const theBusiestHour = maxBy(
        Object.entries(
            groupBy(sessions, ({ startingFrom }) => parseDate(startingFrom).setZone(timeZone).toFormat('HH:00'))
        ),
        ([_, sessions]) => sessions.length
    )

    const orderedOccupancySessionsZones = orderBy(
        Object.entries(
            mapValues(
                groupBy(
                    flatten(
                        Object.values(mapValues(occupancySessionByDayByZoneId, (seats) => Object.entries(seats)))
                    ).map(([zoneId, sessions]) => [zoneId, sessions.length]),
                    ([zoneId]) => zoneId
                ),
                (sessionArray) => sum(sessionArray.map((it) => it[1]))
            )
        ),
        ([_, sessions]) => sessions,
        'desc'
    ).map(([zoneId]) => Number(zoneId))

    const orderedOccupancySessionsScenes = orderedOccupancySessionsZones.map((zoneId) =>
        sceneConfiguration
            ? Number(
                  Object.entries(sceneConfiguration).find(([_, description]) =>
                      description.detectionZones.find((detectionZone) => detectionZone.id === zoneId)
                  )?.[0]
              )
            : undefined
    )

    occupancySessionsHighlightsData.theBusiestWeekDay = theBusiestDay ? getDateWeekday(theBusiestDay[0], 'long') : null

    occupancySessionsHighlightsData.theBusiestHour = theBusiestHour ? theBusiestHour[0] : null

    const zoneOccupancyScenes = zoneOccupancy.scenes.scenes
        .filter(({ id }) => orderedOccupancyScenes.includes(id))
        .sort((a, b) => orderedOccupancyScenes.indexOf(a.id) - orderedOccupancyScenes.indexOf(b.id))

    const zoneOccupancySessionsScenes = zoneOccupancy.scenes.scenes
        .filter(({ id }) => orderedOccupancySessionsScenes.includes(id))
        .sort((a, b) => orderedOccupancySessionsScenes.indexOf(a.id) - orderedOccupancySessionsScenes.indexOf(b.id))

    const mostPerformingSeats = sceneConfiguration
        ? omitBy(
              mapValues(sceneConfiguration, ({ detectionZones }) =>
                  detectionZones
                      .filter((zone) => orderedOccupancySessionsZones.includes(zone.id))
                      .sort(
                          (a, b) =>
                              orderedOccupancySessionsZones.indexOf(a.id) - orderedOccupancySessionsZones.indexOf(b.id)
                      )
              ),
              isEmpty
          )
        : undefined

    return {
        occupancySessionsHighlightsData,
        zoneOccupancySessionsScenes,
        mostPerformingSeats,
        occupancyHighlightsData,
        zoneOccupancyScenes,
        seats,
        summedOccupancyByZoneByDay,
        activeTimeBrackets,
        activeZoneRows,
        averageSeatOccupancy,
        maxDailyOccupancy,
        minDailyOccupancy,
    }
}
