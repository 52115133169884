import download from 'downloadjs'
import { truncate } from 'lodash'
import XLSX from 'xlsx'

import { GoogleEventCategory, GoogleEventName, trackEvent } from '@helpers/gtag'

export interface ExportData {
    [sheetName: string]: Array<{
        [columnName: string]: string | number | boolean
    }>
}

const enumerateSheets = (exportData: ExportData) => {
    const sheets: Array<
        [
            sheetName: string,
            sheetData: Array<{
                [columnName: string]: string | number | boolean
            }>
        ]
    > = []

    const sheetNames = Object.keys(exportData).map((name, i) => `${i + 1}_${name}`)
    const sheetData = Object.values(exportData)

    sheetData.forEach((el, index) => {
        sheets.push([sheetNames[index], el])
    })

    return Object.fromEntries(sheets)
}

export function exportToExcel(
    exportData: ExportData,
    fileName: string,
    eventName: GoogleEventName,
    sortWorkSheetsAlphabetically?: boolean
): void {
    // Sort unicode strings using localeCompare to ensure correct order

    const sheets = enumerateSheets(exportData)

    const sheetNames = sortWorkSheetsAlphabetically
        ? Object.keys(sheets).sort((a, b) => a.localeCompare(b, undefined, { numeric: true }))
        : Object.keys(sheets)

    const workBook = XLSX.utils.book_new()

    sheetNames.forEach((sheetName) =>
        XLSX.utils.book_append_sheet(
            workBook,
            XLSX.utils.json_to_sheet(sheets[sheetName]),
            truncate(sheetName.replaceAll(/[\][*?/\\]/g, '_'), { length: 31 })
        )
    )

    const excelBuffer = XLSX.write(workBook, {
        bookType: 'xlsx',
        type: 'array',
    })

    download(
        new Blob([excelBuffer]),
        `${fileName}.xlsx`,
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
    )

    trackEvent(GoogleEventCategory.REPORT, eventName, fileName)
}
