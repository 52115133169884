import { faBuilding } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import { deburr, uniqueId } from 'lodash'
import React, { useMemo, useState } from 'react'
import { Dropdown, ButtonGroup } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import { OrganizationResponse } from '@api'

import { orderOrganizations } from '@helpers/orderFunctions'

import TextInput from '@components/GenericInputs/TextInput'

import styles from './FilterButton.module.scss'

interface Props {
    organizations: OrganizationResponse[]
    selectedOrganization?: OrganizationResponse
    onSelectOrganization: (organization: OrganizationResponse) => void
}

const OrganizationFilterButton: React.FC<Props> = (props) => {
    const { t } = useTranslation()

    const id = useMemo(uniqueId, [])
    const [search, setSearch] = useState('')

    const filteredOrganizations = useMemo(() => {
        const sortedOrganizations = orderOrganizations(props.organizations).map((organization) => ({
            ...organization,
            normalizedName: deburr(organization.name).toLowerCase(),
        }))

        return sortedOrganizations.filter((organization) =>
            organization.normalizedName.toLowerCase().includes(deburr(search).toLowerCase())
        )
    }, [search, props.organizations])

    if (props.selectedOrganization === undefined) {
        return (
            <Dropdown as={ButtonGroup}>
                <Dropdown.Toggle className={styles.filterButton} id={id} variant="secondary">
                    <FontAwesomeIcon className={styles.icon} icon={faBuilding} />
                </Dropdown.Toggle>
            </Dropdown>
        )
    }

    return (
        <Dropdown as={ButtonGroup}>
            <Dropdown.Toggle className={styles.filterButton} id={id} variant="secondary">
                <FontAwesomeIcon icon={faBuilding} />
                <span className={classNames(styles.filterTextLabel, styles.textShorten)}>
                    {props.selectedOrganization?.name ?? t('others.nothingSelected', 'Nothing selected')}
                </span>
            </Dropdown.Toggle>
            <Dropdown.Menu>
                <TextInput
                    autofocus={true}
                    className={styles.searchInput}
                    placeholder={t('others.searchOrganization', 'Search organization')}
                    value={search}
                    onChange={setSearch}
                />
                {filteredOrganizations.map((o) => (
                    <Dropdown.Item
                        key={o.id}
                        active={props.selectedOrganization?.id === o.id}
                        eventKey={o.id.toString()}
                        onSelect={() => props.onSelectOrganization(o)}
                    >
                        {o.name}
                    </Dropdown.Item>
                ))}
            </Dropdown.Menu>
        </Dropdown>
    )
}

export default OrganizationFilterButton
