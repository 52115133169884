import { faSquare } from '@fortawesome/free-solid-svg-icons'
import { faTriangleExclamation } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { last } from 'lodash'
import { Duration } from 'luxon'
import React, { useEffect, useMemo, useState } from 'react'
import { Alert } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import AutoSizer from 'react-virtualized-auto-sizer'

import { FloorplanHeatmapType, LocalityResponse, Role } from '@api'

import { localityApi, statisticsApi } from '@services'

import { mergeQueryResults } from '@helpers/api'
import { getImageSize } from '@helpers/images'
import useProfile from '@helpers/profile'
import { Size } from '@helpers/types'

import Box from '@elements/Box/Box'

import { AutoSizedImageWithLegendWrapper } from '@components/FloorplanForm/Floorplan'
import FloorplanDropdown from '@components/FloorplanForm/FloorplanDropdown'
import FloorplanVisualizer from '@components/FloorplanVisualizer/FloorplanVisualizer'
import LegacyLoadingWrapper from '@components/LegacyLoadingWrapper'
import LoadingSpinner from '@components/LoadingSpinner'
import LoadingWrapper from '@components/LoadingWrapper'
import LocalityOccupancyConsumer from '@components/LocalityOccupancyConsumer'
import SnapshotGrid from '@components/SnapshotGrid'
import { FloorplanCrowdSizeResponseFromJSON } from '@components/StatisticsSummary/utils'
import palette from '@components/palette.module.scss'

import heatmapLegend from '@images/heatmap_legend.png'

import styles from './FloorplanLiveView.module.scss'

interface Props {
    organizationId: number
    locality: LocalityResponse
    floorplans: { [key: string]: string }
    displayTrajectoryHeatmap: boolean
}

const Legend: React.FC = () => {
    const { t } = useTranslation()

    return (
        <div className={styles.legend}>
            <div>
                <FontAwesomeIcon color={palette.floorplanLiveViewMale} icon={faSquare} />
                <span className={styles.labelText}>{t('statistics.male', 'Male')}</span>
            </div>
            <div>
                <FontAwesomeIcon color={palette.floorplanLiveViewFemale} icon={faSquare} />
                <span className={styles.labelText}>{t('statistics.female', 'Female')}</span>
            </div>
            <div>
                <FontAwesomeIcon color={palette.conversionYellowFill} icon={faSquare} />
                <span className={styles.labelText}>{t('statistics.unknown', 'Unknown')}</span>
            </div>
        </div>
    )
}

const FloorplanLiveView: React.FC<Props> = ({ organizationId, locality, floorplans, displayTrajectoryHeatmap }) => {
    const { t } = useTranslation()
    const profileCall = useProfile()

    const localityFloorplans = Object.entries(floorplans)

    const isAdmin = profileCall.data?.role === Role.Administrator

    const [selectedFloorplan, setSelectedFloorplan] = useState<number>(Number(Object.keys(floorplans)[0]))

    const floorplanCall = useQuery(
        localityApi.getLocalityFloorplan.query({
            floorplanId: selectedFloorplan,
            localityId: locality.id,
            organizationId,
        })
    )

    const heatmapsCall = useQuery(
        statisticsApi.getFloorplanHeatmapList.query({
            floorplanId: selectedFloorplan,
        })
    )

    const heatmaps = heatmapsCall.data?.heatmaps.filter(
        (heatmap) => heatmap.heatmapType === FloorplanHeatmapType.Trajectories
    )

    const heatmapContentCall = useQuery({
        ...statisticsApi.getFloorplanHeatmapContent.query({
            heatmapId: last(heatmaps)?.heatmapId ?? 0,
        }),
        enabled: heatmapsCall.isSuccess,
    })

    const heatmapSnapshotUrl = useMemo(
        () =>
            displayTrajectoryHeatmap && heatmapContentCall.data !== undefined
                ? window.URL.createObjectURL(heatmapContentCall.data!)
                : '',
        [heatmapContentCall.data]
    )

    const floorplanSnapshotUrl = useMemo(
        () => (floorplanCall.data !== undefined ? window.URL.createObjectURL(floorplanCall.data!) : ''),
        [floorplanCall.data]
    )

    const [snapshotSize, setSnapshotSize] = useState<Size>()

    useEffect(() => {
        if (heatmapSnapshotUrl !== '') {
            getImageSize(heatmapSnapshotUrl).then(setSnapshotSize)
        } else if (floorplanSnapshotUrl) {
            getImageSize(floorplanSnapshotUrl).then(setSnapshotSize)
        }
    }, [heatmapSnapshotUrl, floorplanSnapshotUrl])

    const floorplanIdStr = selectedFloorplan.toString()

    return (
        <Box className={styles.liveViewGridCard}>
            <Box.Title
                buttons={
                    localityFloorplans.length > 1 ? (
                        <FloorplanDropdown
                            floorplans={localityFloorplans}
                            selectedFloorplan={selectedFloorplan}
                            onSelect={setSelectedFloorplan}
                        />
                    ) : null
                }
                text={t('realTime.liveView', '{{value}} Live view', {
                    value: locality.name,
                })}
            />
            <div className={styles.liveView}>
                <Alert className={styles.alertContainer} variant="warning">
                    <FontAwesomeIcon icon={faTriangleExclamation} />
                    {t(
                        'notification.liveViewWarning',
                        'Floor plan live view is a part of the VIVIDI insider preview features. Temporary inconsistencies might occur.'
                    )}
                </Alert>
                <LegacyLoadingWrapper
                    placeholder={<LoadingSpinner bare={true} />}
                    request={mergeQueryResults(heatmapContentCall, floorplanCall)}
                >
                    {(_snapshot) => (
                        <LocalityOccupancyConsumer
                            connectionCheckInterval={Duration.fromObject({
                                seconds: 30,
                            })}
                            dataRenderer={({ data }) => (
                                <AutoSizer>
                                    {({ height, width }) => (
                                        <FloorplanVisualizer
                                            floorplanSize={snapshotSize}
                                            floorplanUrl={
                                                displayTrajectoryHeatmap && heatmapSnapshotUrl !== ''
                                                    ? heatmapSnapshotUrl
                                                    : floorplanSnapshotUrl
                                            }
                                            height={height}
                                            isAdmin={isAdmin}
                                            personPositions={data.locations?.[floorplanIdStr] ?? []}
                                            width={width}
                                        />
                                    )}
                                </AutoSizer>
                            )}
                            endpointName="floorplan_crowd_size"
                            localityId={locality.id}
                            parseFunction={FloorplanCrowdSizeResponseFromJSON}
                            subscriptionType="floorplan_live_view"
                        />
                    )}
                </LegacyLoadingWrapper>
            </div>
            {displayTrajectoryHeatmap && isAdmin && <Legend />}
            {displayTrajectoryHeatmap && (
                <div className={styles.intensityImageContainer}>
                    <AutoSizedImageWithLegendWrapper
                        legendName={t('heatmapDetailPage.visitorCountLegend', 'Visitor count')}
                        src={heatmapLegend}
                    />
                </div>
            )}
            <LoadingWrapper placeholder={<LoadingSpinner bare={true} />}>
                <SnapshotGrid locality={locality} />
            </LoadingWrapper>
        </Box>
    )
}

export default FloorplanLiveView
