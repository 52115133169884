/* tslint:disable */
/* eslint-disable */
/**
 * Vividi Dashboard API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OutageModel
 */
export interface OutageModel {
    /**
     * 
     * @type {number}
     * @memberof OutageModel
     */
    deviceId: number;
    /**
     * 
     * @type {number}
     * @memberof OutageModel
     */
    sceneId: number;
    /**
     * 
     * @type {string}
     * @memberof OutageModel
     */
    startedAt: string;
    /**
     * 
     * @type {number}
     * @memberof OutageModel
     */
    duration: number;
}

export function OutageModelFromJSON(json: any): OutageModel {
    return OutageModelFromJSONTyped(json, false);
}

export function OutageModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): OutageModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'deviceId': json['device_id'],
        'sceneId': json['scene_id'],
        'startedAt': json['started_at'],
        'duration': json['duration'],
    };
}

export function OutageModelToJSON(value?: OutageModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'device_id': value.deviceId,
        'scene_id': value.sceneId,
        'started_at': value.startedAt,
        'duration': value.duration,
    };
}

