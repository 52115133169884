/* tslint:disable */
/* eslint-disable */
/**
 * Vividi Dashboard API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LocalityFloorplanResponse
 */
export interface LocalityFloorplanResponse {
    /**
     * 
     * @type {number}
     * @memberof LocalityFloorplanResponse
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof LocalityFloorplanResponse
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof LocalityFloorplanResponse
     */
    localityId: number;
    /**
     * 
     * @type {number}
     * @memberof LocalityFloorplanResponse
     */
    scale?: number;
}

export function LocalityFloorplanResponseFromJSON(json: any): LocalityFloorplanResponse {
    return LocalityFloorplanResponseFromJSONTyped(json, false);
}

export function LocalityFloorplanResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): LocalityFloorplanResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'localityId': json['locality_id'],
        'scale': !exists(json, 'scale') ? undefined : json['scale'],
    };
}

export function LocalityFloorplanResponseToJSON(value?: LocalityFloorplanResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'locality_id': value.localityId,
        'scale': value.scale,
    };
}

