import { useState, useCallback } from 'react'
import { FileRejection, useDropzone } from 'react-dropzone'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'

import { localityApi } from '@services'

import { useApiCallCleaner } from '@helpers/api'

import { convertImageToPng, formatBytes } from '@components/FloorplanForm/utils'
import { useNotify } from '@components/notifications/NotificationsContext'

const maxSize = 20 * 1024 * 1024

/**
 * Evaluates the size of the file passed, throws an error with bytes formatted into 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'
 *
 * @param file File to evaluate
 * @returns Error object with formatted bytes
 */
function sizeValidator(file: File) {
    if (file.size > maxSize) {
        return {
            code: 'size-too-large',
            message: `File size is larger than ${formatBytes(maxSize)}`,
        }
    }

    return null
}

export const useUploadFloorplan = ({ organizationId, localityId }: { organizationId: number; localityId: number }) => {
    const { t } = useTranslation()
    const notify = useNotify()
    const clean = useApiCallCleaner()

    const [isDropZoneActive] = useState(false)
    const [isUploading, setIsUploading] = useState(false)
    const [uploadedFileName, setUploadedFileName] = useState<null | string>(null)

    const { mutate: uploadFloorplan } = useMutation(localityApi.addLocalityFloorplan, {
        onSuccess: () => {
            clean(localityApi)
            notify({
                variant: 'success',
                title: t('notification.floorplanUploaded', 'Floor plan uploaded'),
                content: t('notification.floorplanUploadedContent', 'Floor plan uploaded successfully'),
                timeoutSeconds: 3,
            })
        },
        onError: () => {
            notify({
                title: t('notification.floorplanUploadFailed', 'Floor plan upload failed'),
                content: t(
                    'notification.floorplanUploadFailedContent',
                    'An error occurred while attempting to upload the floor plan.'
                ),
                variant: 'danger',
            })
        },
    })

    const handleFloorplanUpload = async (file: File) => {
        setIsUploading(true)
        setUploadedFileName(file.name)
        let convertedFile = undefined

        try {
            convertedFile = await convertImageToPng(file)
        } catch (error) {
            notify({
                title: t('notification.imgConversionFailed', 'Conversion failed'),
                content: t('notification.imgConversionFailedContent', 'Provided image failed to convert to .png'),
                variant: 'danger',
            })
            setIsUploading(false)
            setUploadedFileName(null)

            return
        }

        uploadFloorplan({
            organizationId,
            localityId,
            fileName: file.name,
            body: convertedFile,
        })
    }

    const onDrop = useCallback(
        async (acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
            if (rejectedFiles.length) {
                const errors = rejectedFiles[0].errors.map((err) => err.message)
                notify({
                    title: t('notification.errorUploadingFile', 'Error uploading file'),
                    content: errors[0],
                    variant: 'danger',
                })
            } else if (acceptedFiles.length) {
                const acceptedFile = acceptedFiles[0]
                handleFloorplanUpload(acceptedFile)
            }
        },
        [notify]
    )

    const { getInputProps, open, getRootProps } = useDropzone({
        onDrop,
        multiple: false,
        accept: 'image/jpeg, image/png',
        validator: sizeValidator,
        noClick: true,
        noKeyboard: true,
    })

    return {
        getInputProps,
        open,
        getRootProps,
        isDropZoneActive,
        isUploading,
        uploadedFileName,
        onDrop,
        maxSize,
    }
}
