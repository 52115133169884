import { uniqueId } from 'lodash'
import React, { useMemo } from 'react'
import { Token, TokenProps, Typeahead } from 'react-bootstrap-typeahead'
import 'react-bootstrap-typeahead/css/Typeahead.css'
import { useTranslation } from 'react-i18next' // TODO: make this follow our Bootstrap settings (colors)

interface ItemType {
    itemId: string
    label: string
}

interface Props {
    choices: { [id: string]: string }
    selection: Array<string>
    onSelect: (selection: Array<string>) => void
    placeholder?: string
    disabled?: boolean
    allowNew?: boolean
    displayId?: boolean
    tokenRenderer?: React.FC<TokenProps & { tokenId?: number }>
    onMouseOver?: (item: ItemType) => void
    onMouseOut?: () => void
}

const MultiSelectBox = ({
    choices,
    onSelect,
    selection,
    allowNew,
    disabled,
    displayId,
    placeholder,
    tokenRenderer,
    onMouseOver,
    onMouseOut,
}: Props) => {
    const { t } = useTranslation()

    const boxId = useMemo(uniqueId, [])

    const options = choices
        ? Object.entries(choices).map(([key, value]) => ({
              itemId: key,
              label: displayId ? `${value} | #${key}` : value,
          }))
        : []

    const checkedIds = selection.filter((id) => allowNew || Object.keys(choices).includes(id))
    const defaultSelected = checkedIds.map((itemId) => ({
        itemId,
        label: options.find((o) => o.itemId === itemId)?.label ?? itemId,
    }))

    const setMultiSelections = (selected: Array<ItemType & { customOption?: boolean }>) => {
        onSelect(selected.map((item) => (!item.customOption ? item.itemId : item.label)))
    }

    const onRemove = (
        option: ItemType & {
            customOption?: boolean
        }
    ) => {
        if (!disabled) {
            onSelect(selection.filter((itemId) => itemId !== option.itemId))
        }
    }

    const TokenComponent = tokenRenderer

    return (
        <Typeahead
            allowNew={allowNew}
            clearButton={!disabled}
            emptyLabel={t('form.noMatchesFound', 'No matches found')}
            id={boxId}
            labelKey="label"
            options={options}
            placeholder={disabled ? t('others.noItemsToSelect', 'No items to select') : placeholder}
            renderToken={(option) =>
                TokenComponent ? (
                    <TokenComponent
                        key={option.itemId}
                        disabled={disabled}
                        tokenId={option.itemId ? Number(option.itemId) : -1}
                        onMouseEnter={() => onMouseOver?.(option)}
                        onMouseLeave={() => onMouseOut?.()}
                        onRemove={() => onRemove(option)}
                    >
                        {option.label}
                    </TokenComponent>
                ) : (
                    <Token
                        key={option.itemId}
                        disabled={disabled}
                        onMouseEnter={() => onMouseOver?.(option)}
                        onMouseLeave={() => onMouseOut?.()}
                        onRemove={() => onRemove(option)}
                    >
                        {option.label}
                    </Token>
                )
            }
            selected={defaultSelected}
            multiple
            onChange={setMultiSelections}
        />
    )
}

export default MultiSelectBox
