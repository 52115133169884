import { LocationDescriptorObject } from 'history'
import { useCallback } from 'react'
import { useHistory, useLocation } from 'react-router'

type FollowFunction = (mode?: 'push' | 'replace') => void

export const useBackLink = (defaultPath = '/'): { follow: FollowFunction } => {
    const history = useHistory()
    const location = useLocation<{ backLink: LocationDescriptorObject<any> }>()

    const follow = useCallback(
        (mode = 'push') => {
            const search = new URLSearchParams(location.search)
            const backLink: LocationDescriptorObject<any> | undefined = search.has('backlink')
                ? { pathname: search.get('backlink')! }
                : location.state?.backLink

            // Check if back-link is present
            if (backLink === undefined) {
                return history.push(defaultPath)
            }

            // Check if back-link is valid
            if (typeof backLink.pathname !== 'string') {
                return history.push(defaultPath)
            }

            if (!['string', 'undefined'].includes(typeof backLink.search)) {
                return history.push(defaultPath)
            }

            if (!['string', 'undefined'].includes(typeof backLink.hash)) {
                return history.push(defaultPath)
            }

            // Push back-link on top of history stack
            const historyEntry = {
                pathname: backLink.pathname,
                search: backLink.search,
                hash: backLink.hash,
                state: backLink.state,
            }

            if (mode === 'push') {
                history.push(historyEntry)
            }

            if (mode === 'replace') {
                history.replace(historyEntry)
            }
        },
        [location, history, defaultPath]
    )

    return {
        follow,
    }
}

type LinkFactoryFunction = (
    destination: string
) => LocationDescriptorObject<{ backLink: LocationDescriptorObject<any> }>

export const useAddBackLink = (): LinkFactoryFunction => {
    const location = useLocation()

    return useCallback((pathname: string) => pathWithBackLink(pathname, location), [location])
}

const pathWithBackLink = (pathname: string, backLink: LocationDescriptorObject<any>): LocationDescriptorObject<any> => {
    return {
        pathname,
        state: {
            backLink,
        },
    }
}
