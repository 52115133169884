import { faArrowRight, faCheck, faImage, faSync } from '@fortawesome/free-solid-svg-icons'
import { faArrowLeft, faLeftFromLine, faRightFromLine, faStreetView } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import { isEmpty, noop } from 'lodash'
import { Duration } from 'luxon'
import React, { useState } from 'react'
import { Alert, Button, Image } from 'react-bootstrap'
import { TFunction, Trans, useTranslation } from 'react-i18next'
import { useMutation, useQuery } from 'react-query'
import { useHistory, useParams } from 'react-router'

import { DeviceResponse, Role, SceneResponse } from '@api'

import { deviceApi, localityApi, organizationApi, sceneApi } from '@services'

import { useApiCallCleaner } from '@helpers/api'
import { orderScenes } from '@helpers/orderFunctions'
import useProfile from '@helpers/profile'

import useAutoRefetchWithCountDown from '@hooks/useAutoRefetchWithCountDown'

import ControlledModal from '@components/ControlledModal'
import NumberInput from '@components/GenericInputs/NumberInput'
import ImageLoadingWrapper from '@components/ImageLoadingWrapper'
import VerticalListGroup, { VerticalListEntry } from '@components/NavigationLists/VerticalListGroup'
import RoleChecker from '@components/RoleChecker'
import { useNotify } from '@components/notifications/NotificationsContext'

import { FlipUpsideDownButton } from './SceneEditForm'
import styles from './SceneInstallerUI.module.scss'

const determineDeviceValidationAlert =
    (t: TFunction) =>
    (isDeviceAssignable: boolean, isDeviceAssigned: boolean, isDeviceInAnotherOrg: boolean, deviceId?: number) => {
        if (!deviceId) {
            return
        }

        if (isDeviceInAnotherOrg) {
            return (
                <Alert variant="warning">
                    <Trans i18nKey="installerUI.deviceNotInOrganization" values={{ deviceId }}>
                        <strong>Device {{ deviceId }}</strong> isn't in this organization. Enter other device id.
                    </Trans>
                </Alert>
            )
        }

        if (isDeviceAssigned) {
            return (
                <Alert variant="warning">
                    <Trans i18nKey="installerUI.deviceAlreadyAssigned" values={{ deviceId }}>
                        <strong>Device {{ deviceId }}</strong> is already assigned to other scene in this organization.
                        Enter other device id.
                    </Trans>
                </Alert>
            )
        }

        if (!isDeviceAssignable) {
            return <Alert variant="warning">{t('installerUI.invalidDeviceID', 'Device ID has invalid format.')}</Alert>
        }
    }

const AssignToSceneView: React.FC<{
    organizationId: number
    devicesWithNoScene: DeviceResponse[]
    devicesWithScene: DeviceResponse[]
    visibleDevices?: DeviceResponse[]
    isAdmin: boolean
    determineAssignDevicePath: (sceneId: number, deviceId: number) => string
}> = ({ organizationId, devicesWithNoScene, devicesWithScene, visibleDevices, isAdmin, determineAssignDevicePath }) => {
    const { t } = useTranslation()
    const notify = useNotify()
    const history = useHistory()
    const { sceneId } = useParams<{ sceneId: string }>()
    const [deviceId, setDeviceId] = useState<number | undefined>(undefined)

    const isDeviceInAnotherOrg =
        isAdmin &&
        Boolean(visibleDevices?.find(({ id, organizationId: orgId }) => id === deviceId && orgId !== organizationId))
    const isAssignable = Boolean(devicesWithNoScene.find(({ id }) => id === deviceId))
    const isAssigned = Boolean(devicesWithScene.find(({ id }) => id === deviceId))

    const alert = determineDeviceValidationAlert(t)(isAssignable, isAssigned, isDeviceInAnotherOrg, deviceId)

    const handleDeviceIdChange = (deviceId?: number) => setDeviceId(deviceId)
    const handleAssignToScene = (deviceId: number) => {
        if (isEmpty(devicesWithNoScene) || !isAssignable) {
            notify({
                title: t('notification.error', 'Error'),
                content: t(
                    'notification.deviceIsNotAssignableToScene',
                    'Device is not assignable to the selected scene. Please make sure the device is not already assigned to other scene and that the ID you entered is correct.'
                ),
                variant: 'danger',
                timeoutSeconds: 8,
            })

            return
        }

        return history.push(determineAssignDevicePath(Number(sceneId), deviceId))
    }

    return (
        <div className={styles.assignToSceneViewContainer}>
            <Trans i18nKey="installerUI.step1">
                <h1>STEP 1</h1>
                <h2>Enter device ID of the new device</h2>
                <NumberInput
                    className={styles.numberInput}
                    maxDigits={4}
                    placeholder="5xxx"
                    value={deviceId}
                    onChange={handleDeviceIdChange}
                />
                <h2>and plug it to electricity</h2>
            </Trans>
            <>
                {deviceId && deviceId >= 1000 && alert}
                <Button
                    disabled={!isAssignable}
                    variant="primary"
                    onClick={deviceId ? () => handleAssignToScene(deviceId) : noop}
                >
                    <FontAwesomeIcon icon={faArrowRight} size="2x" />
                    {t('installerUI.nextStep', 'Next step')}
                </Button>
                <Button className={styles.previousStepButton} variant="link" onClick={() => history.goBack()}>
                    <FontAwesomeIcon icon={faArrowLeft} size="2x" />
                    {t('installerUI.previousStep', 'Previous step')}
                </Button>
            </>
        </div>
    )
}

enum View {
    waitForConnect = 'waitForConnect',
    saveSnapshot = 'saveSnapshot',
    finalize = 'finalize',
}

const DeviceView: React.FC<{ scenesWithNoDevice: SceneResponse[]; handleOpenScenesList: () => void }> = ({
    scenesWithNoDevice,
    handleOpenScenesList,
}) => {
    const { t } = useTranslation()
    const notify = useNotify()
    const history = useHistory()

    const { deviceId, sceneId } = useParams<{ deviceId: string; sceneId: string }>()
    const sceneName = scenesWithNoDevice.find((s) => s.id === Number(sceneId))?.label
    const [isConnected, setIsConnected] = useState(false)
    const [upsideDown, setUpsideDown] = useState(false)
    const [isSnapshotSaved, setSnapshotSaved] = useState(false)

    const {
        countDown,
        request: liveViewRequest,
        manualRefetch,
    } = useAutoRefetchWithCountDown(
        Duration.fromObject({ seconds: 5 }),
        useQuery(
            deviceApi.fetchSnapshot.query({
                deviceId: Number(deviceId) ?? -1,
            })
        )
    )

    const storedSnapshotRequest = useQuery(sceneApi.getSceneSnapshot.query({ sceneId: Number(sceneId) ?? -1 }))

    const sceneRequest = useQuery(
        sceneApi.getScene.query({
            sceneId: Number(sceneId) ?? -1,
        })
    )

    React.useEffect(() => {
        if (liveViewRequest.data && liveViewRequest.status === 'success') {
            setIsConnected(true)
        }
    }, [liveViewRequest])

    React.useEffect(() => {
        if (sceneRequest.data && sceneRequest.status === 'success') {
            setUpsideDown(sceneRequest.data.captureSettings.upsideDown)
        }
    }, [sceneRequest])

    const handleManualReload = () => manualRefetch()

    const handleDeviceUpsideDownUpdate = async (upsideDown: boolean) => {
        setUpsideDown(upsideDown)
    }

    const snapshotUpdate = async () => {
        if (liveViewRequest.data === undefined) {
            return
        }

        try {
            //assign device only if device is not already set, otherwise update snapshot
            if (sceneRequest.data?.deviceId === undefined) {
                await sceneApi.setSceneDevice({
                    sceneId: Number(sceneId),
                    body: {
                        deviceId: Number(deviceId),
                    },
                })
            }

            await sceneApi.updateSceneSnapshot({
                sceneId: Number(sceneId),
                body: liveViewRequest.data,
            })

            await sceneApi.updateCaptureSettings({
                sceneId: Number(sceneId),
                body: {
                    upsideDown,
                },
            })

            handleManualReload()
            setSnapshotSaved(true)
            setIsConnected(false)
        } catch (e) {
            notify({
                title: t('notification.error', 'Error'),
                content: t('notification.snapshotCaptureFailed', 'Something went wrong. Snapshot capture failed.'),
                variant: 'danger',
                timeoutSeconds: 4,
            })
        }

        notify({
            title: t('notification.snapshotSaved', 'Snapshot successfully saved'),
            variant: 'success',
            timeoutSeconds: 5,
            content: (
                <p>
                    <Trans i18nKey="notification.snapshotWarningContent">
                        Please, make sure the newly updated snapshot does <strong>not contain images of people</strong>.
                        The snapshots are used for illustrative purposes in pages that are visible to{' '}
                        <strong>all members</strong> of your organization.
                    </Trans>
                </p>
            ),
        })
    }

    const { mutateAsync: snapshotUpdater } = useMutation(snapshotUpdate)

    const view =
        !isConnected && !isSnapshotSaved
            ? View.waitForConnect
            : isConnected && !isSnapshotSaved
            ? View.saveSnapshot
            : isSnapshotSaved
            ? View.finalize
            : undefined

    return (
        <div className={styles.assignToSceneViewContainer}>
            {view === View.waitForConnect && (
                <>
                    <h1>{t('installerUI.step2', 'STEP 2')}</h1>
                    <h2>{t('installerUI.waitForDeviceToConnect', 'Wait for the device to connect to the network')}</h2>
                </>
            )}
            {view === View.saveSnapshot && (
                <>
                    <h1>{t('installerUI.step3', 'STEP 3')}</h1>
                    <h2>{t('installerUI.deviceIsConnected', 'Device {{deviceId}} is connected.', { deviceId })}</h2>
                </>
            )}
            {view === View.finalize && (
                <>
                    <h1>{t('installerUI.success', 'SUCCESS')}</h1>
                    <h2>
                        <Trans i18nKey="installerUI.deviceSuccessfullyAssigned" values={{ deviceId, sceneName }}>
                            Device <strong>{{ deviceId }}</strong> successfully assigned to scene{' '}
                            <strong>{{ sceneName }}</strong>
                        </Trans>
                    </h2>
                </>
            )}
            <div className={styles.liveViewCounterRow}>
                {view === View.finalize ? (
                    t('button.snapshot', 'Snapshot')
                ) : (
                    <div className={styles.liveViewText}>
                        {t('installerUI.liveView', 'Live view')}
                        {liveViewRequest.status === 'loading' && (
                            <span
                                className={classNames(styles.loadingLiveViewIndicator, 'spinner-border')}
                                role="status"
                            />
                        )}
                    </div>
                )}
                {view !== View.finalize && (
                    <div>
                        Auto reload in <span className={styles.liveViewCounter}>{countDown}s</span>
                    </div>
                )}
            </div>
            <div className={styles.imageWrapperContainer}>
                <ImageLoadingWrapper
                    allowBuffering={true}
                    errorComponent={<div> {t('installerUI.snapshotFailedToFetch', 'Snapshot failed to fetch')}</div>}
                    placeholder={
                        <div className={styles.loadingContainer}>
                            <div className={styles.infoText}>
                                {t('installerUI.deviceLiveViewIsBeingLoaded', 'Device live view is being loaded')}
                            </div>
                            <div className="spinner-border" role="status">
                                <span className="sr-only">{t('others.loading', 'Loading')}</span>
                            </div>
                        </div>
                    }
                    request={view === View.finalize ? storedSnapshotRequest : liveViewRequest}
                >
                    <Image className={classNames({ [styles.flippedImage]: upsideDown })} src="" />
                </ImageLoadingWrapper>
            </div>

            {view === View.waitForConnect && (
                <>
                    <Button variant="secondary" onClick={handleManualReload}>
                        <FontAwesomeIcon icon={faSync} />
                        {t('button.reload', 'Reload')}
                    </Button>
                    <Button className={styles.previousStepButton} variant="link" onClick={() => history.goBack()}>
                        <FontAwesomeIcon icon={faArrowLeft} size="2x" />
                        {t('installerUI.previousStep', 'Previous step')}
                    </Button>
                </>
            )}
            {view === View.saveSnapshot && (
                <div className={styles.saveSnapshotButtonContainer}>
                    <Button
                        variant="primary"
                        onClick={(e) => {
                            e.preventDefault()
                            snapshotUpdater()
                        }}
                    >
                        <FontAwesomeIcon icon={faImage} />
                        {t('button.saveAsSnapshot', 'Save as snapshot')}
                    </Button>
                    <RoleChecker whitelist={[Role.Administrator, Role.OrganizationAdministrator]}>
                        <FlipUpsideDownButton
                            deviceUpsideDown={upsideDown}
                            disabled={false}
                            liveViewRequestState={liveViewRequest.status}
                            showWarning={false}
                            onClick={() => handleDeviceUpsideDownUpdate(!upsideDown)}
                        />
                    </RoleChecker>
                    <Button className={styles.previousStepButton} variant="link" onClick={() => history.goBack()}>
                        <FontAwesomeIcon icon={faArrowLeft} size="2x" />
                        {t('installerUI.previousStep', 'Previous step')}
                    </Button>
                </div>
            )}
            {view === View.finalize && (
                <Button variant="primary" onClick={handleOpenScenesList}>
                    <FontAwesomeIcon icon={faCheck} />
                    {t('button.done', 'Done')}
                </Button>
            )}
        </div>
    )
}

const InstallerUI: React.FC<{
    devicesWithScene: DeviceResponse[]
    devicesWithNoScene: DeviceResponse[]
    isInstallerUIModalOpen: boolean
    scenesWithNoDevice: SceneResponse[]
    organizationId: number
    installerUIView?: 'list' | 'assign' | 'device'
    visibleDevices?: DeviceResponse[]
    handleOpenModal: () => void
    handleCloseModal: () => void
    handleSceneSelect: (sceneId: string) => void
    determineAssignDevicePath: (sceneId: number, deviceId: number) => string
}> = ({
    devicesWithScene,
    scenesWithNoDevice,
    isInstallerUIModalOpen,
    organizationId,
    devicesWithNoScene,
    visibleDevices,
    installerUIView,
    handleOpenModal,
    handleCloseModal,
    handleSceneSelect,
    determineAssignDevicePath,
}) => {
    const { t } = useTranslation()
    const apiCleaner = useApiCallCleaner()
    const profileCall = useProfile()
    const isAdmin = profileCall.data?.role === Role.Administrator

    const navigationItems: Array<VerticalListEntry> = orderScenes(scenesWithNoDevice).map(({ id, label }) => ({
        id: String(id),
        displayText: label,
        icon: faStreetView,
        className: styles.sceneEntry,
    }))

    return (
        <ControlledModal
            className={styles.modalContainer}
            control={
                <Button variant="secondary" onClick={handleOpenModal}>
                    <FontAwesomeIcon icon={faRightFromLine} size="2x" />
                    {t('button.openInstallerUI', 'Open Installer UI')}
                </Button>
            }
            modalContainerClassName={styles.installerUIContainer}
            show={isInstallerUIModalOpen}
            centered
            onHide={handleCloseModal}
        >
            <Button variant="secondary" onClick={handleCloseModal}>
                <FontAwesomeIcon icon={faLeftFromLine} size="2x" />
                {t('button.closeInstallerUI', 'Exit Installer UI')}
            </Button>
            {installerUIView === 'device' && (
                <DeviceView
                    handleOpenScenesList={() => {
                        handleOpenModal()
                        apiCleaner(organizationApi)
                        apiCleaner(deviceApi)
                        apiCleaner(sceneApi)
                        apiCleaner(localityApi)
                    }}
                    scenesWithNoDevice={scenesWithNoDevice}
                />
            )}
            {installerUIView === 'assign' && (
                <AssignToSceneView
                    determineAssignDevicePath={determineAssignDevicePath}
                    devicesWithNoScene={devicesWithNoScene}
                    devicesWithScene={devicesWithScene}
                    isAdmin={isAdmin}
                    organizationId={organizationId}
                    visibleDevices={visibleDevices}
                />
            )}
            {installerUIView === 'list' && (
                <>
                    <div className={styles.header}>
                        {t('installerUI.description', 'Click to assign a device to scene below')}
                    </div>
                    <VerticalListGroup
                        selectableEntries={navigationItems}
                        selectedEntry={undefined}
                        unfilteredSelectableEntries={navigationItems}
                        onSelectionChange={handleSceneSelect}
                    />
                </>
            )}
        </ControlledModal>
    )
}

export default InstallerUI
