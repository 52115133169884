import { DateTime, IANAZone, Interval } from 'luxon'
import React from 'react'
import { useQuery } from 'react-query'
import { useParams, Redirect } from 'react-router'

import { LocalityResponse } from '@api'

import { localityApi } from '@services'

import { visibleLocalitiesQuery } from '@helpers/api'
import { localeAwareAlign, alignPickedInterval } from '@helpers/datetimeUtils'
import useProfile from '@helpers/profile'
import { useTimezoneConfig, TimezoneConfigProvider } from '@helpers/timezoneConfig'
import { IntervalType } from '@helpers/types'
import { assertIsIntervalType } from '@helpers/utils'

import PageNotFound from '../pages/PageNotFound' // eslint-disable-line replace-relative-imports/replace

export interface Params {
    localityId: string
    intervalType: string
    intervalStart: string
    intervalEnd?: string
}

interface PartialParams {
    intervalType: IntervalType
    interval: Interval
}

export interface NormalizedDashboardParams extends PartialParams {
    locality: LocalityResponse
}

interface Props {
    defaultLocalityPathGenerator: (localityId: number) => string
    intervalPathGenerator: (localityId: number, intervalStart: DateTime, intervalType: IntervalType) => string
    customIntervalPathGenerator: (localityId: number, intervalStart: DateTime, intervalEnd: DateTime) => string
    errorComponent: (params: PartialParams) => JSX.Element
    loadingComponent: (params: PartialParams) => JSX.Element
    successComponent: (params: NormalizedDashboardParams) => JSX.Element
}

const DashboardParamsNormalizer: React.FC<Props> = ({
    defaultLocalityPathGenerator,
    customIntervalPathGenerator,
    intervalPathGenerator,
    errorComponent,
    loadingComponent,
    successComponent,
}) => {
    const profileCall = useProfile()
    const zone = useTimezoneConfig()
    const params = useParams<Partial<Params>>()

    const visibleLocalitiesCall = useQuery(visibleLocalitiesQuery(profileCall.data))

    const localityId = Number(params.localityId)
    const locality = (visibleLocalitiesCall.data?.localities ?? []).find((l) => l.id === localityId)

    const selectedLocalityCall = useQuery({
        ...localityApi.getLocality.query({
            localityId: locality?.id ?? -1,
        }),
        enabled: visibleLocalitiesCall.isSuccess,
    })

    const intervalType =
        params.intervalType ?? (params.intervalEnd !== undefined ? IntervalType.CUSTOM : IntervalType.WEEK)

    assertIsIntervalType(intervalType)

    if (
        intervalType === IntervalType.CUSTOM &&
        (params.intervalStart === undefined || params.intervalEnd === undefined)
    ) {
        return <PageNotFound />
    }

    if (params.intervalStart !== undefined && isNaN(Number(params.intervalStart))) {
        return <PageNotFound />
    }

    if (params.intervalEnd !== undefined && isNaN(Number(params.intervalEnd))) {
        return <PageNotFound />
    }

    const intervalStart =
        params.intervalStart !== undefined
            ? DateTime.fromMillis(Number(params.intervalStart), { zone: 'UTC' })
            : localeAwareAlign(DateTime.utc(), intervalType as any, zone)

    const intervalEnd =
        params.intervalEnd !== undefined ? DateTime.fromMillis(Number(params.intervalEnd), { zone: 'UTC' }) : undefined

    const placeholderInterval = alignPickedInterval(new IANAZone('UTC'), intervalType, intervalStart, intervalEnd)

    // Locality list is required for successful routing
    if (visibleLocalitiesCall.status === 'idle' || visibleLocalitiesCall.status === 'loading') {
        return loadingComponent({ interval: placeholderInterval, intervalType })
    }

    if (visibleLocalitiesCall.status === 'error') {
        return errorComponent({ interval: placeholderInterval, intervalType })
    }

    if (params.localityId === undefined && visibleLocalitiesCall.data.localities.length > 0) {
        return <Redirect to={defaultLocalityPathGenerator(visibleLocalitiesCall.data.localities[0].id)} />
    }

    if (isNaN(localityId)) {
        return <PageNotFound />
    }

    if (!locality) {
        return <PageNotFound />
    }

    if (
        intervalType === IntervalType.CUSTOM &&
        (params.intervalStart === undefined || params.intervalEnd === undefined)
    ) {
        return <Redirect to={customIntervalPathGenerator(localityId, intervalStart, intervalEnd!)} />
    }

    if (
        (intervalType !== IntervalType.CUSTOM && params.intervalType === undefined) ||
        params.intervalStart === undefined
    ) {
        return <Redirect to={intervalPathGenerator(localityId, intervalStart, intervalType)} />
    }

    if (selectedLocalityCall.status === 'idle' || selectedLocalityCall.status === 'loading') {
        return loadingComponent({ interval: placeholderInterval, intervalType })
    }

    if (selectedLocalityCall.status === 'error') {
        return errorComponent({ interval: placeholderInterval, intervalType })
    }

    const selectedLocality = selectedLocalityCall.data

    const localityTimezone = new IANAZone(selectedLocality.timezone)

    const interval =
        intervalType !== IntervalType.CUSTOM
            ? alignPickedInterval(localityTimezone, intervalType, intervalStart)
            : Interval.fromDateTimes(intervalStart, intervalEnd!)

    if (intervalType !== IntervalType.CUSTOM) {
        // Redirect to properly aligned interval start
        if (!intervalStart.equals(interval.start)) {
            return <Redirect to={intervalPathGenerator(localityId, interval.start, intervalType)} />
        }
    }

    return (
        <TimezoneConfigProvider timezone={localityTimezone.name}>
            {successComponent({
                interval,
                intervalType,
                locality: selectedLocality,
            })}
        </TimezoneConfigProvider>
    )
}

export default DashboardParamsNormalizer
