import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import { noop } from 'lodash'
import React from 'react'

import { Possibly } from '@helpers/types'

import SearchBoxInput from '@components/GenericInputs/SearchBoxInput'

import { VerticalListEntry } from './VerticalListGroup'
import styles from './VerticalListGroup.module.scss'

type VerticalListGroupProps = {
    selectedEntries: Array<VerticalListEntry['id']> | undefined
    selectableEntries: Array<VerticalListEntry>
    onSelectionChange: (id: string) => void
} & Possibly<{
    search: string
    onSearch: (search: string) => void
    placeholder: string
}>

const MultiSelectVerticalListGroup: React.FC<VerticalListGroupProps> = ({
    selectedEntries,
    selectableEntries,
    onSelectionChange,
    search,
    onSearch,
    placeholder,
}) => {
    return (
        <div className={classNames(styles.verticalListGroupContainer, styles.multiSelectListContainer)}>
            {onSearch && (
                <SearchBoxInput
                    className={styles.stickySearch}
                    placeholder={placeholder ?? ''}
                    value={search}
                    onChange={onSearch}
                />
            )}
            <div>
                {selectableEntries.map(({ id, className, icon, displayText, indicators, disabled = false }) => (
                    <div
                        key={id}
                        className={classNames(
                            {
                                [styles.verticalListGroupItem]: true,
                                [styles.active]: selectedEntries?.includes(id),
                                [styles.disabled]: disabled,
                            },
                            styles.multiSelectItem,
                            className
                        )}
                        onClick={!disabled ? () => onSelectionChange(id) : noop}
                    >
                        <div className={styles.titleContainer}>
                            {icon && <FontAwesomeIcon icon={icon} />}
                            {displayText}
                        </div>
                        {indicators && <div className={styles.indicators}>{indicators}</div>}
                    </div>
                ))}
            </div>
        </div>
    )
}

export default MultiSelectVerticalListGroup
