import React, { useState } from 'react'
import { Form, Row, Col, Button, Container } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { MutationStatus } from 'react-query'

import { isEmailValid } from '@helpers/validations'

import AsyncButton from './AsyncButton'
import { useNotify } from './notifications/NotificationsContext'

interface Props {
    onSubmit: (emails: Array<string>) => void
    submissionStatus: MutationStatus
}

const InviteUsersForm = (props: Props) => {
    const { t } = useTranslation()

    const [state, setState] = useState({
        emails: [] as string[],
        editedValue: '',
    })
    const [isTouched, setIsTouched] = useState(false)

    const notify = useNotify()

    const onSubmit: React.FormEventHandler = async (e) => {
        e.preventDefault()

        if (state.editedValue) {
            setState((prevState) => ({
                emails: [...prevState.emails, state.editedValue],
                editedValue: '',
            }))
        } else {
            const areEmailsValid = state.emails.every(isEmailValid)

            if (!areEmailsValid) {
                notify({
                    title: t('notification.invalidInput', 'Invalid input'),
                    content: t('notification.invalidEmails', 'One or more emails are invalid.'),
                    variant: 'warning',
                })

                return
            }

            try {
                await props.onSubmit(state.emails)

                notify({
                    title: t('notification.invitationSent', 'Invitations sent'),
                    content: t('notification.invitationSentContent', 'Invitations sent to provided email addresses.'),
                    variant: 'success',
                })
            } catch (error) {
                notify({
                    title: t('notification.error', 'Error'),
                    content: t(
                        'notification.invitationSentFailedContent',
                        'Something went wrong. The invitations were not sent.'
                    ),
                    variant: 'danger',
                })
            }
        }
    }

    return (
        <Container fluid={true}>
            <Form onSubmit={onSubmit}>
                {state.emails.map((email) => (
                    <Form.Group key={email} as={Row}>
                        <Col sm={11}>
                            <Form.Control
                                isInvalid={!isEmailValid(email)}
                                plaintext={true}
                                readOnly={true}
                                value={email}
                            />
                        </Col>
                        <Col sm={1}>
                            <Button
                                variant="outline-danger"
                                onClick={() => {
                                    setState((prevState) => ({
                                        ...prevState,
                                        emails: prevState.emails.filter((it) => it !== email),
                                    }))
                                }}
                            >
                                {t('button.remove', 'Remove')}
                            </Button>
                        </Col>
                    </Form.Group>
                ))}
                <Form.Group as={Row}>
                    <Col sm={11}>
                        <Form.Control
                            isInvalid={isTouched && !isEmailValid(state.editedValue)}
                            type="email"
                            value={state.editedValue}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                const editedValue = e.target.value
                                setIsTouched(true)
                                setState((prevState) => ({
                                    ...prevState,
                                    editedValue,
                                }))
                            }}
                        />
                    </Col>
                    <Col sm={1}>
                        <Button
                            disabled={!state.editedValue}
                            variant="outline-secondary"
                            onClick={() => {
                                setIsTouched(false)
                                setState((prevState) => ({
                                    emails: [...prevState.emails, state.editedValue],
                                    editedValue: '',
                                }))
                            }}
                        >
                            {t('button.add', 'Add')}
                        </Button>
                    </Col>
                </Form.Group>
                <Form.Group>
                    <AsyncButton
                        retryTimeoutSeconds={3}
                        status={props.submissionStatus}
                        text={t('button.inviteUsers', 'Invite users')}
                    />
                </Form.Group>
            </Form>
        </Container>
    )
}

export default InviteUsersForm
