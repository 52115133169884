import React from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { MutationStatus } from 'react-query'

import AsyncButton from '@components/AsyncButton'
import DevicePicker, { Props as DevicePickerProps } from '@components/DevicePicker'
import VividiImagePicker, { Props as ImagePickerProps } from '@components/VividiImagePicker'

interface Props {
    devicePickerProps: DevicePickerProps
    imagePickerProps: ImagePickerProps
    onSubmit: () => void
    submissionState: MutationStatus
    destinationApi: string
}

const DeviceTransferForm = (props: Props) => {
    const { t } = useTranslation()

    const disabled = props.submissionState !== 'idle'

    const onSubmit: React.FormEventHandler = (e) => {
        e.preventDefault()
        props.onSubmit()
    }

    return (
        <Form onSubmit={onSubmit}>
            <Form.Group as={Row}>
                <Form.Label column={true}>{t('form.deviceToTransfer', 'Device to transfer')}</Form.Label>
                <Col sm={10}>
                    <DevicePicker {...props.devicePickerProps} />
                </Col>
            </Form.Group>
            <Form.Group as={Row}>
                <Form.Label column={true}>{t('form.vividiImage', 'Vividi Image')}</Form.Label>
                <Col sm={10}>
                    <VividiImagePicker {...props.imagePickerProps} disabled={disabled} />
                </Col>
            </Form.Group>
            <AsyncButton status={props.submissionState} text={t('button.startTransfer', 'Start transfer')} />
        </Form>
    )
}

export default DeviceTransferForm
