import { faEdit, faLink, faSquare } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import { flatten, isEmpty, mapValues, round } from 'lodash'
import { DateTime, Duration, Interval } from 'luxon'
import React, { useState } from 'react'
import { Button, Card, Col, Row } from 'react-bootstrap'
import { TFunction, useTranslation } from 'react-i18next'
import { UseQueryResult } from 'react-query'
import { useHistory } from 'react-router'
import { Cell, Label, Pie, PieChart } from 'recharts'

import {
    CrowdSizeHistoryItem,
    CrowdSizeHistoryResponse,
    CrowdSizeResponseFromJSON,
    LocalityConfigurationStateModel,
    LocalityNameModel,
    LocalityResponse,
    OrganizationResponse,
    PropertySummaryResponse,
    SceneDescription,
    SceneResponse,
} from '@api/models'

import {
    generateEditLocalityOccupancyPath,
    generateFloorplanLocalityOccupancyDashboardPath,
    generateLocalityOccupancyDashboardPath,
    generateStaySafeDetailsPath,
} from '@helpers/VividiURLs'
import { useAddBackLink } from '@helpers/backlinks'
import { useLocalizeDateTime } from '@helpers/timezoneConfig'
import { IntervalType } from '@helpers/types'

import Box from '@elements/Box/Box'
import CardTitle from '@elements/Card/CardTitle'

import { ConfigurationHeatmapCarousel } from '@components/ConfigurationHeatmapCarousel'
import FeatureChecker from '@components/FeatureChecker'
import FloorplanLiveView from '@components/FloorplanVisualizer/FloorplanLiveView'
import LanguageOverride from '@components/LanguageOverride'
import LegacyLoadingWrapper from '@components/LegacyLoadingWrapper'
import LocalityOccupancyConsumer, { OccupancyData } from '@components/LocalityOccupancyConsumer'
import { countIf, FloorplanCrowdSizeResponseFromJSON } from '@components/StatisticsSummary/utils'
import TooltipIcon from '@components/TooltipIcon'
import { useNotify } from '@components/notifications/NotificationsContext'
import palette from '@components/palette.module.scss'
import { TimelinePagination } from '@components/plots/TimelinePagination'
import { parseDate } from '@components/plots/common'

import StaySafeDashboardMinified from './StaySafeDashboardMinified'
import styles from './StaySafeDetails.module.scss'
import StaySafeDetailsLanguagePicker from './StaySafeDetailsLanguagePicker'
import { StaySafeVisitorsInTimePlot } from './StaySafeVisitorsInTimePlot'
import { languages, LanguageSelection } from './staySafeData'
import { prepareGenderBreakdownPieChartData } from './staySafeDetailsPreprocessors'

interface LiveOccupancyPieChartProps {
    centerLabel: string
    className?: string
    genderBreakdown: {
        male: number
        female: number
    }
}

interface StaySafeViewCardProps {
    handleLanguageChange: (language: LanguageSelection) => void
    selectedLanguage: string
    locality: LocalityNameModel
    t: TFunction
    URLGenerator: (localityId: number, selectedLanguage: string) => string
    cardTitle: string
    endpointName?: 'crowd_size' | 'floorplan_crowd_size'
}

const StaySafeViewCard: React.FC<StaySafeViewCardProps> = ({
    handleLanguageChange,
    selectedLanguage,
    locality,
    t,
    URLGenerator,
    cardTitle,
    endpointName,
}) => {
    const notify = useNotify()

    return (
        <Card>
            <Card.Body>
                <CardTitle
                    buttons={
                        <div className={styles.staySafeScreenButtonContainer}>
                            <StaySafeDetailsLanguagePicker languages={languages} onSelect={handleLanguageChange} />
                            <Button
                                variant="secondary"
                                onClick={() => {
                                    navigator.clipboard
                                        .writeText(window.location.origin + URLGenerator(locality.id, selectedLanguage))
                                        .then(() =>
                                            notify({
                                                title: t('others.success', 'Success'),
                                                content: t('realTime.linkCopiedContent', 'Link copied to clipboard'),
                                                variant: 'success',
                                                timeoutSeconds: 5,
                                            })
                                        )
                                        .catch(() => {
                                            notify({
                                                title: t('realTime.linkCopyFailedTitle', 'Copy to clipboard failed'),
                                                content: t(
                                                    'realTime.linkCopyFailedContent',
                                                    'Here is the link you tried to copy: {{link}}',
                                                    {
                                                        link:
                                                            window.location.origin +
                                                            URLGenerator(locality.id, selectedLanguage),
                                                    }
                                                ),
                                                variant: 'danger',
                                                timeoutSeconds: 15,
                                            })
                                        })
                                }}
                            >
                                <FontAwesomeIcon icon={faLink} />
                                {t('button.copyLink', 'Copy link')}
                            </Button>
                        </div>
                    }
                    text={cardTitle}
                />
                <LocalityOccupancyConsumer
                    connectionCheckInterval={Duration.fromObject({
                        seconds: 30,
                    })}
                    dataRenderer={({ data }: { data: OccupancyData }) => (
                        <LanguageOverride language={selectedLanguage}>
                            <StaySafeDashboardMinified
                                data={data}
                                language={selectedLanguage}
                                locality={locality}
                                redirectTarget={URLGenerator(locality.id, selectedLanguage)}
                                showCapacity={true}
                                showExactNumbers={true}
                            />
                        </LanguageOverride>
                    )}
                    endpointName={endpointName}
                    localityId={locality.id}
                    subscriptionType={`${endpointName}_details`}
                    updateInterval={3}
                />
            </Card.Body>
        </Card>
    )
}

interface StaySafeTimelineCardProps {
    interval: Interval
    locality: LocalityNameModel
    crowdSizeHistory: Array<CrowdSizeHistoryItem>
    now: DateTime
    title: string
    maximumCapacity?: number
}

const StaySafeTimeLineCard: React.FC<StaySafeTimelineCardProps> = ({
    interval,
    locality,
    crowdSizeHistory,
    now,
    title,
    maximumCapacity,
}) => {
    const history = useHistory()
    const localize = useLocalizeDateTime()

    return (
        <Box className={styles.plot}>
            <Box.Title
                buttons={
                    <TimelinePagination
                        disableNextPage={parseDate(interval.end.toISO()) > now}
                        displayedDates={{
                            endDate: {
                                date: localize(interval.start),
                                dateStyle: 'short',
                            },
                        }}
                        pageCount={-1}
                        selectedPage={0}
                        onNextPage={() =>
                            history.push(
                                generateStaySafeDetailsPath(
                                    locality.id,
                                    parseDate(interval.start.toISO()).plus({
                                        days: 1,
                                    }),
                                    IntervalType.DAY
                                )
                            )
                        }
                        onPreviousPage={() =>
                            history.push(
                                generateStaySafeDetailsPath(
                                    locality.id,
                                    parseDate(interval.start.toISO()).minus({
                                        days: 1,
                                    }),
                                    IntervalType.DAY
                                )
                            )
                        }
                    />
                }
                text={title}
            />
            <StaySafeVisitorsInTimePlot
                crowdSizeHistory={crowdSizeHistory}
                interval={interval}
                maximumCapacity={maximumCapacity}
                now={now}
            />
        </Box>
    )
}

const LiveOccupancyPieChart: React.FC<LiveOccupancyPieChartProps> = ({ className, centerLabel, genderBreakdown }) => {
    const pieValues =
        Number(centerLabel) > 0
            ? Object.entries(genderBreakdown).map(([name, value]) => ({
                  name,
                  value,
              }))
            : [{ name: 'placeholder', value: 100 }]

    return (
        <PieChart className={className} height={240} width={240}>
            <Pie cx={120} cy={120} data={pieValues} dataKey="value" innerRadius={80} outerRadius={110}>
                {pieValues.map((value, index) => (
                    <Cell
                        key={`cell-${index}`}
                        fill={
                            value.name === 'placeholder'
                                ? palette.grey90
                                : value.name === 'male'
                                ? palette.vividiPrimary
                                : palette.vividiPrimaryLight
                        }
                    />
                ))}
                <Label
                    className={styles.crowdDisplayNumber}
                    fill={palette.darkgrey}
                    position="center"
                    value={centerLabel}
                />
            </Pie>
        </PieChart>
    )
}

interface PieChartCardProps {
    genderBreakdown:
        | {
              male: number
              female: number
          }
        | undefined

    t: TFunction
    locality: LocalityNameModel
    endpointName: 'crowd_size' | 'floorplan_crowd_size'
    sceneCount: number
}

const PieChartCard: React.FC<PieChartCardProps> = ({ genderBreakdown, t, locality, endpointName, sceneCount }) => {
    const history = useHistory()

    const addBackLink = useAddBackLink()

    return (
        <Box>
            <Box.Title
                text={
                    endpointName === 'crowd_size'
                        ? t('realTime.numberOfVisitors', 'Real-time number of visitors')
                        : t('realTime.currentlySeenBy', 'Seen by {{value}} scenes', { value: sceneCount })
                }
            />
            <LocalityOccupancyConsumer
                connectionCheckInterval={Duration.fromObject({
                    seconds: 30,
                })}
                dataRenderer={({ data }: { data: OccupancyData }) => {
                    const wsGenderBreakdown = { male: 0, female: 0 }

                    const locationData = data.locations ? flatten(Object.values(data.locations)) : undefined

                    wsGenderBreakdown.male = countIf(locationData, (item) => item.isMale === true)
                    wsGenderBreakdown.female = countIf(locationData, (item) => item.isMale === false)

                    const total = locationData?.filter(({ isMale }) => isMale !== undefined).length ?? 1

                    wsGenderBreakdown.male = round((wsGenderBreakdown.male * 100) / total)
                    wsGenderBreakdown.female = round((wsGenderBreakdown.female * 100) / total)

                    if (
                        (endpointName === 'crowd_size' && isEmpty(genderBreakdown)) ||
                        (endpointName === 'floorplan_crowd_size' && isEmpty(locationData))
                    ) {
                        return <span>No breakdown data available</span>
                    }

                    const selectedBreakdown = endpointName === 'crowd_size' ? genderBreakdown! : wsGenderBreakdown

                    const femalePercentage = `${
                        selectedBreakdown.female > 0 && (data.currentCrowdSize ?? 0) > 0 ? selectedBreakdown.female : 0
                    }%`

                    const malePercentage = `${
                        selectedBreakdown.male > 0 && (data.currentCrowdSize ?? 0) ? selectedBreakdown.male : 0
                    }%`

                    return (
                        <>
                            <div className={styles.pieChartContainer}>
                                <LiveOccupancyPieChart
                                    centerLabel={String(data.currentCrowdSize ?? 0)}
                                    className={classNames({
                                        [styles.centeredPieChart]: endpointName === 'floorplan_crowd_size',
                                    })}
                                    genderBreakdown={selectedBreakdown}
                                />
                                <div className={styles.pieChartLegendWideColumn}>
                                    <div>
                                        <FontAwesomeIcon color={palette.vividiPrimary} icon={faSquare} />
                                        <span className={styles.labelText}>
                                            {t('statistics.male', 'Male')}
                                            {endpointName === 'crowd_size' && (
                                                <TooltipIcon>
                                                    <span>
                                                        {t(
                                                            'realTime.realTimePieChartTooltip',
                                                            'Estimated demography distribution based on last 15 minutes with data.'
                                                        )}
                                                    </span>
                                                </TooltipIcon>
                                            )}
                                        </span>
                                        <div className={styles.labelValue}>{malePercentage}</div>
                                    </div>
                                    <div>
                                        <FontAwesomeIcon color={palette.vividiPrimaryLight} icon={faSquare} />
                                        <span className={styles.labelText}>
                                            {t('statistics.female', 'Female')}
                                            {endpointName === 'crowd_size' && (
                                                <TooltipIcon>
                                                    <span>
                                                        {t(
                                                            'realTime.realTimePieChartTooltip',
                                                            'Estimated demography distribution based on last 15 minutes with data.'
                                                        )}
                                                    </span>
                                                </TooltipIcon>
                                            )}
                                        </span>
                                        <div className={styles.labelValue}>{femalePercentage}</div>
                                    </div>
                                </div>
                            </div>
                            {endpointName === 'crowd_size' && (
                                <div className={styles.capacityEditButton}>
                                    <span>{data.maximumCapacity ? data.maximumCapacity : '?'}</span>
                                    <Button
                                        variant="link"
                                        onClick={() =>
                                            history.push(
                                                addBackLink(
                                                    generateEditLocalityOccupancyPath(
                                                        locality.organizationId,
                                                        locality.id
                                                    )
                                                )
                                            )
                                        }
                                    >
                                        <FontAwesomeIcon icon={faEdit} />
                                    </Button>
                                </div>
                            )}
                        </>
                    )
                }}
                endpointName={endpointName}
                localityId={locality.id}
                parseFunction={
                    endpointName === 'crowd_size' ? CrowdSizeResponseFromJSON : FloorplanCrowdSizeResponseFromJSON
                }
                subscriptionType={`${endpointName}_details`}
                updateInterval={3}
            />
        </Box>
    )
}

interface StaySafeDetailsContentProps {
    now: DateTime
    locality: LocalityResponse
    localityConfiguration: LocalityConfigurationStateModel
    scenes: SceneResponse[]
    sceneConfiguration: { [sceneId: number]: SceneDescription }
    interval: Interval
    crowdSizeHistory: UseQueryResult<CrowdSizeHistoryResponse>
    floorplanCrowdSizeHistory: UseQueryResult<CrowdSizeHistoryResponse>
    propertySummary: UseQueryResult<PropertySummaryResponse>
    organization: OrganizationResponse
}

const StaySafeDetailsContent: React.FC<StaySafeDetailsContentProps> = ({
    locality,
    localityConfiguration,
    crowdSizeHistory,
    floorplanCrowdSizeHistory,
    interval,
    now,
    organization,
    scenes,
    sceneConfiguration,
    propertySummary,
}) => {
    const [language, setLanguage] = useState<LanguageSelection>(languages[0])

    const handleLanguageChange = (language: LanguageSelection) => {
        setLanguage(language)
    }

    const { t } = useTranslation()

    const genderBreakdown = propertySummary?.data ? prepareGenderBreakdownPieChartData(propertySummary.data) : undefined

    const selectedLanguage = language.ISO639.toLowerCase()

    return (
        <>
            {localityConfiguration.isStaySafeConfigured && (
                <FeatureChecker allowForAdmin={false} feature="realtimeOccupancy" organization={organization}>
                    <Row>
                        <Col xl={4}>
                            <PieChartCard
                                endpointName="crowd_size"
                                genderBreakdown={genderBreakdown}
                                locality={locality}
                                sceneCount={scenes.length}
                                t={t}
                            />
                        </Col>
                        <Col className="pt-4 pt-xl-0" xl={4}>
                            <StaySafeViewCard
                                URLGenerator={generateLocalityOccupancyDashboardPath}
                                cardTitle={t('button.staySafeScreen', 'Stay Safe screen')}
                                handleLanguageChange={handleLanguageChange}
                                locality={locality}
                                selectedLanguage={selectedLanguage}
                                t={t}
                            />
                        </Col>
                        <Col className="pt-4 pt-xl-0" xl={4}>
                            <Card className={styles.heatmapCard}>
                                <Card.Body>
                                    <CardTitle text={t('form.visitBoundaries', 'Visit boundaries')} />
                                </Card.Body>
                                {!isEmpty(scenes) && (
                                    <ConfigurationHeatmapCarousel
                                        boundaries={mapValues(sceneConfiguration, (config) =>
                                            config.visitBoundaries.filter((b) =>
                                                locality.staySafeConfiguration?.staySafeBoundaries.includes(b.id)
                                            )
                                        )}
                                        carouselWidth="450px"
                                        drawBoundaries={true}
                                        drawZones={false}
                                        scenes={scenes}
                                    />
                                )}
                            </Card>
                        </Col>
                    </Row>
                    {scenes && (
                        <LegacyLoadingWrapper request={crowdSizeHistory}>
                            {(crowdSizeHistory) => (
                                <Row>
                                    <Col>
                                        <StaySafeTimeLineCard
                                            crowdSizeHistory={crowdSizeHistory.items ?? []}
                                            interval={interval}
                                            locality={locality}
                                            maximumCapacity={locality.capacity}
                                            now={now}
                                            title={t('statistics.visitorsInTime', 'Visitors in time')}
                                        />
                                    </Col>
                                </Row>
                            )}
                        </LegacyLoadingWrapper>
                    )}
                </FeatureChecker>
            )}
            {localityConfiguration.isStaySafeFloorplanConfigured && (
                <FeatureChecker allowForAdmin={false} feature="staySafeFloorplan" organization={organization}>
                    <Row>
                        <Col lg={8}>
                            <PieChartCard
                                endpointName="floorplan_crowd_size"
                                genderBreakdown={genderBreakdown}
                                locality={locality}
                                sceneCount={scenes.length ?? 0}
                                t={t}
                            />
                        </Col>
                        <Col className="pt-4 pt-lg-0" lg={4}>
                            <StaySafeViewCard
                                URLGenerator={generateFloorplanLocalityOccupancyDashboardPath}
                                cardTitle={t('realTime.liveScreen', 'Live Screen')}
                                endpointName="floorplan_crowd_size"
                                handleLanguageChange={handleLanguageChange}
                                locality={locality}
                                selectedLanguage={selectedLanguage}
                                t={t}
                            />
                        </Col>
                    </Row>
                    {scenes && (
                        <LegacyLoadingWrapper request={floorplanCrowdSizeHistory}>
                            {(floorplanCrowdSizeHistory) => (
                                <Row>
                                    <Col>
                                        <StaySafeTimeLineCard
                                            crowdSizeHistory={floorplanCrowdSizeHistory.items ?? []}
                                            interval={interval}
                                            locality={locality}
                                            maximumCapacity={locality.capacity}
                                            now={now}
                                            title={t('realTime.currentlySeenBy', 'Seen by {{value}} scenes', {
                                                value: scenes.length,
                                            })}
                                        />
                                    </Col>
                                </Row>
                            )}
                        </LegacyLoadingWrapper>
                    )}
                    {scenes && !isEmpty(locality.floorplans) && (
                        <FloorplanLiveView
                            displayTrajectoryHeatmap={true}
                            floorplans={locality.floorplans}
                            locality={locality}
                            organizationId={organization.id}
                        />
                    )}
                </FeatureChecker>
            )}
        </>
    )
}

export default StaySafeDetailsContent
