import { faMale, faFemale, faCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    Dictionary,
    capitalize,
    pickBy,
    sum,
    round,
    isEmpty,
    noop,
    max,
    range,
    uniqueId,
    mapValues,
    head,
} from 'lodash'
import { Interval, DateTime, Duration } from 'luxon'
import React, { useRef, useState, useMemo } from 'react'
import { Row, Col, Card, Container, Form } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import AutoSizer from 'react-virtualized-auto-sizer'
import { YAxis, Area, CartesianGrid, Tooltip, BarChart, XAxis, Bar, ReferenceLine } from 'recharts'

import {
    PropertiesByBoundaryResponse,
    MovementByBoundaryResponse,
    IntervalPropertiesByBoundary,
    IntervalMovementByBoundary,
    PropertyName,
    LocalityResponse,
    Role,
    LocalityWeatherResponse,
    IntervalWeather,
    VisitBoundaryWithId,
    PropertyBreakdownByBoundaryResponse,
    SceneDescription,
    OutageModel,
    SceneListResponse,
} from '@api'

import { generateOrganizationScenesPath } from '@helpers/VividiURLs'
import { useAddBackLink } from '@helpers/backlinks'
import { gdynusaReadableInterval } from '@helpers/datetimeUtils'
import { displayTemperature, weatherIcon } from '@helpers/displayUtils'
import { useLocalizeDateTime } from '@helpers/timezoneConfig'
import { displayTranslatedWeather } from '@helpers/translateUtils'
import { IntervalType, TooltipFormatter } from '@helpers/types'

import i18next from '@i18n'

import Box from '@elements/Box/Box'
import PlotButton from '@elements/Buttons/PlotButton'
import ToggleButton from '@elements/Buttons/ToggleButton'
import CardTitle from '@elements/Card/CardTitle'
import TableWithSorting from '@elements/Table/TableWithSorting'

import AdminOnlyLinkRenderer from '@components/AdminOnlyLinkRenderer'
import { ConfigurationHeatmapCarousel } from '@components/ConfigurationHeatmapCarousel'
import SpacedLayout from '@components/Layouts/SpacedLayout'
import RoleChecker from '@components/RoleChecker'
import StatisticBox, { StatisticBoxItem } from '@components/StatisticsSummary/StatisticBox'
import statisticBoxStyles from '@components/StatisticsSummary/StatisticBox.module.scss'
import { weightedAverage } from '@components/StatisticsSummary/utils'
import palette from '@components/palette.module.scss'
import Cursor from '@components/plots/Cursor'
import TimeLineChart from '@components/plots/TimeLineChart'
import TooltipContent from '@components/plots/TooltipContent'
import TooltipLabel from '@components/plots/TooltipLabel'
import TooltipTable from '@components/plots/TooltipTable'
import { parseDate } from '@components/plots/common'

import styles from './FootfallDetails.module.scss'
import {
    GenderAgeBreakdown,
    prepareGenderAgeBreakdown,
    prepareMovementData,
    preparePropertiesPerBoundary,
} from './footfallDetailsPreprocessors'

export const removeUnclassified = (data: Dictionary<number>) => pickBy(data, (_, key) => key !== 'None')

export const propertiesPercentage = (
    properties: IntervalPropertiesByBoundary[],
    property: PropertyName,
    subject: string
) =>
    (100 * sum(properties.map(({ items }) => removeUnclassified(items[0].properties[property])[subject]))) /
    sum(properties.map(({ items }) => sum(Object.values(removeUnclassified(items[0].properties[property])))))

export const getGranularityLabel = (visitors: IntervalMovementByBoundary[]) => {
    if (visitors.length === 0 || visitors[0].items.length === 0) return i18next.t('others.dailyGranularity', 'Daily ')
    const firstRecord = visitors[0].items[0]
    const itemInterval = Interval.fromDateTimes(parseDate(firstRecord.startingFrom), parseDate(firstRecord.endingAt))

    return itemInterval.end.diff(itemInterval.start) > Duration.fromObject({ hours: 1 })
        ? i18next.t('others.dailyGranularity', 'Daily ')
        : i18next.t('others.hourlyGranularity', 'Hourly ')
}

const FootfallHighlights: React.FC<{
    properties: IntervalPropertiesByBoundary[]
    visitors: IntervalMovementByBoundary[]
    interval: Interval
    locality: LocalityResponse
}> = ({ interval, locality, visitors, properties }) => {
    const { t } = useTranslation()

    const granularityLabel = getGranularityLabel(visitors).toLowerCase()

    const malePercentage = round(propertiesPercentage(properties, PropertyName.IsMale, '1'))
    const femalePercentage = round(propertiesPercentage(properties, PropertyName.IsMale, '0'))
    const totalVisitors = sum(visitors.flatMap((group) => group.items.map((it) => it.numberOfIncoming ?? 0)))
    const averageVisitors = round(
        sum(
            visitors.map(({ items }) =>
                weightedAverage(
                    items.map((it) => it.numberOfIncoming ?? 0),
                    items.map((it) => it.openedPortion)
                )
            )
        )
    )

    return (
        <Container>
            <Row>
                <StatisticBox
                    interval={interval}
                    label={t('statistics.totalVisitors', 'Total visitors')}
                    localities={[locality]}
                    sm={6}
                    tooltip={t('tooltip.totalVisitors', 'the total sum of visitors in the locality')}
                >
                    <StatisticBoxItem value={totalVisitors} />
                </StatisticBox>
                <StatisticBox
                    interval={interval}
                    label={capitalize(
                        t('statistics.averageVisitors', `{{value}} average visitors`, {
                            value: granularityLabel,
                        })
                    )}
                    localities={[locality]}
                    sm={6}
                    tooltip={t('tooltip.averageVisitors', `the {{value}} average number of visitors in the locality`, {
                        value: granularityLabel,
                    })}
                >
                    <StatisticBoxItem value={averageVisitors} />
                </StatisticBox>
            </Row>
            <Row>
                <StatisticBox
                    boxClassName={statisticBoxStyles.genderStatisticsBox}
                    interval={interval}
                    label={t('statistics.visitorsDemography', 'Average visitors demography')}
                    localities={[locality]}
                    tooltip={t(
                        'tooltip.visitorsDemography',
                        'average distribution of male and female gender visitors across all selected localities'
                    )}
                >
                    <StatisticBoxItem
                        icon={faMale}
                        iconClass={statisticBoxStyles.iconGender}
                        unit="%"
                        value={malePercentage}
                    />
                    <StatisticBoxItem
                        icon={faFemale}
                        iconClass={statisticBoxStyles.iconGender}
                        unit="%"
                        value={femalePercentage}
                    />
                </StatisticBox>
            </Row>
        </Container>
    )
}

const FootfallMovementPlot: React.FC<{
    visitors: IntervalMovementByBoundary[]
    now: DateTime
    intervalType: IntervalType
    interval: Interval
    weather: IntervalWeather[]
    outages: OutageModel[]
}> = ({ visitors, now, intervalType, interval, weather, outages }) => {
    const { t } = useTranslation()

    const canvasRef = useRef<HTMLCanvasElement>(null)
    const ids = useMemo(() => range(2).map(() => uniqueId()), [])
    const [displayedGraphs, setDisplayedGraphs] = useState({
        incoming: true,
        outgoing: false,
    })

    const data = prepareMovementData(visitors, now, weather)

    const maxData =
        max(
            data.flatMap((it) => [
                displayedGraphs.incoming && it.incoming ? it.incoming : 0,
                displayedGraphs.outgoing && it.outgoing ? it.outgoing : 0,
            ])
        ) ?? 0

    const canvasContext = canvasRef.current?.getContext('2d')
    const axisWidth = canvasContext?.measureText((-maxData).toString()).width

    return (
        <>
            <div>
                <RoleChecker whitelist={[Role.Administrator]}>
                    <Form>
                        <Form.Group>
                            <span className={styles.plotControlsLabel}>
                                <strong>{t('others.show', 'Show:')}</strong>
                            </span>
                            <PlotButton
                                buttonText={t('footfall.incoming', 'Incoming')}
                                id={ids[0]}
                                isActive={displayedGraphs.incoming}
                                onClick={() =>
                                    setDisplayedGraphs((prev) => ({
                                        ...prev,
                                        incoming: !prev.incoming,
                                    }))
                                }
                            />
                            <PlotButton
                                buttonText={t('footfall.outgoing', 'Outgoing')}
                                id={ids[1]}
                                isActive={displayedGraphs.outgoing}
                                underlineColor={palette.vividiPrimaryDark}
                                onClick={() =>
                                    setDisplayedGraphs((prev) => ({
                                        ...prev,
                                        outgoing: !prev.outgoing,
                                    }))
                                }
                            />
                        </Form.Group>
                    </Form>
                </RoleChecker>
            </div>
            <TimeLineChart
                canvasRef={canvasRef}
                data={data}
                endDate={interval.end}
                intervalType={intervalType}
                now={now}
                outages={outages}
                startDate={interval.start}
                onSelectInterval={noop}
            >
                <YAxis
                    allowDecimals={false}
                    domain={[0, 1.1 * maxData]}
                    tick={(tickProps) => {
                        return (
                            <g transform={`translate(${tickProps.x},${tickProps.y})`}>
                                <text className="small" fill={palette.darkgrey} textAnchor="end" x={-4} y={4}>
                                    {tickProps.payload.value}
                                </text>
                            </g>
                        )
                    }}
                    ticks={[0, Math.round(maxData / 2), maxData]}
                    width={Math.ceil(axisWidth ?? 30) + 6}
                />
                {displayedGraphs.incoming && (
                    <Area
                        dataKey="incoming"
                        dot={true}
                        fill={palette.vividiPrimary}
                        fillOpacity={0.2}
                        r={1}
                        stroke={palette.vividiPrimary}
                        strokeWidth={2}
                        type="monotone"
                    />
                )}
                {displayedGraphs.outgoing && (
                    <Area
                        dataKey="outgoing"
                        dot={true}
                        fill={palette.vividiPrimaryDark}
                        fillOpacity={0.3}
                        r={1}
                        stroke={palette.vividiPrimaryDark}
                        strokeWidth={2}
                        type="monotone"
                    />
                )}
                <CartesianGrid strokeOpacity={0.3} strokeWidth={1} vertical={false} />
                <Tooltip
                    content={
                        <TooltipContent
                            intervalResolver={(label) => data[Number(label)].interval}
                            labelFormatter={(label) => label}
                            now={now}
                            outages={outages}
                        >
                            {(label) => {
                                const record = data.find((it) => it.name === label)!

                                return (
                                    <TooltipTable>
                                        <TooltipTable.Item label={t('statistics.numberIncoming', 'Number of incoming')}>
                                            {record.incoming ?? 0}
                                        </TooltipTable.Item>
                                        <TooltipTable.Item label={t('statistics.numberOutgoing', 'Number of outgoing')}>
                                            {record.outgoing ?? 0}
                                        </TooltipTable.Item>
                                        {record.weather?.weather && (
                                            <TooltipTable.Item label={t('footfall.weather', 'Weather')}>
                                                {displayTranslatedWeather(record.weather.weather, t)}{' '}
                                                <FontAwesomeIcon icon={weatherIcon(record.weather.weather)} />
                                            </TooltipTable.Item>
                                        )}
                                        {record.weather?.temperature !== undefined && (
                                            <TooltipTable.Item label={t('footfall.temperature', 'Temperature')}>
                                                {displayTemperature(record.weather.temperature)}
                                            </TooltipTable.Item>
                                        )}
                                    </TooltipTable>
                                )
                            }}
                        </TooltipContent>
                    }
                    cursor={<Cursor dataLength={data.length} />}
                    filterNull={false}
                    formatter={
                        ((value: number, name: string) => {
                            if (name === 'incoming') {
                                return [value, t('statistics.numberIncoming', 'Number of incoming')]
                            }

                            if (name === 'outgoing') {
                                return [value, t('statistics.numberOutgoing', 'Number of outgoing')]
                            }

                            return [value, name]
                        }) as TooltipFormatter
                    }
                    labelFormatter={(index) => {
                        const item = data[parseInt(index as string, 10)]

                        if (item === undefined) {
                            return
                        }

                        const interval = item.interval

                        return (
                            <TooltipLabel
                                endDate={interval.end}
                                interval={interval}
                                now={now}
                                startDate={interval.start}
                            />
                        )
                    }}
                />
            </TimeLineChart>
        </>
    )
}

const GenderAgeBreakdownPlot: React.FC<{
    genderAgeBreakdown: GenderAgeBreakdown
    interval: Interval
    now: DateTime
}> = ({ genderAgeBreakdown, interval, now }) => {
    const { t } = useTranslation()

    const localize = useLocalizeDateTime()

    const data = genderAgeBreakdown.map((item) => ({
        name: item.ageGroup,
        male: round(-100 * item.maleRatio * item.ageGroupToTotalRatio, 1),
        female: round(100 * item.femaleRatio * item.ageGroupToTotalRatio, 1),
        ageGroupToTotal: round(100 * item.ageGroupToTotalRatio, 1),
        hasData: true,
    }))
    const maxData = max(data.flatMap((it) => [-it.male, it.female])) ?? 0

    const formatTooltipTitle = (label: string) => {
        const readableInterval = gdynusaReadableInterval(interval.mapEndpoints(localize))
        const startDate = readableInterval.start.toLocaleString(DateTime.DATE_SHORT)
        const endDate = readableInterval.end.toLocaleString(DateTime.DATE_SHORT)

        return t('statistics.ageGroupLabelDate', '{{label}} age group {{date}}', {
            label,
            date: `${startDate} - ${endDate}`,
        })
    }

    const Legend = () => (
        <div className={styles.genderAgeLegend}>
            <div>
                <FontAwesomeIcon color={palette.vividiPrimary} icon={faCircle} />
                <span className={styles.labelText}>{t('statistics.male', 'Male')}</span>
            </div>
            <div>
                <FontAwesomeIcon color={palette.vividiPrimaryLight} icon={faCircle} />
                <span className={styles.labelText}>{t('statistics.female', 'Female')}</span>
            </div>
        </div>
    )

    const ticks = [-maxData, -(maxData / 2), 0, maxData / 2, maxData].map(Math.round)

    return (
        <>
            <div className={styles.genderAgePlot}>
                <AutoSizer>
                    {({ width, height }) => (
                        <BarChart
                            data={data}
                            height={height}
                            layout="vertical"
                            margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
                            stackOffset="sign"
                            width={width}
                        >
                            {ticks.map((tick) => (
                                <ReferenceLine key={tick} strokeOpacity={0.5} x={tick} />
                            ))}
                            <XAxis
                                axisLine={false}
                                domain={[-1.1 * maxData, 1.1 * maxData]}
                                tickFormatter={(tickValue: number) => `${Math.abs(tickValue)}%`}
                                tickLine={false}
                                ticks={ticks}
                                type="number"
                            />
                            <YAxis
                                axisLine={false}
                                dataKey="name"
                                interval={0}
                                label={{
                                    value: `${t('footfall.age', 'Age')}`,
                                    position: 'insideTopRight',
                                }}
                                minTickGap={0}
                                padding={{ top: 24, bottom: 0 }}
                                tickLine={false}
                                type="category"
                            />
                            <Tooltip
                                allowEscapeViewBox={{ x: false, y: true }}
                                content={
                                    <TooltipContent
                                        intervalResolver={() => interval}
                                        labelFormatter={formatTooltipTitle}
                                        now={now}
                                        outages={[]}
                                    >
                                        {(label) => {
                                            const record = genderAgeBreakdown.find((it) => it.ageGroup === label)!

                                            return (
                                                <TooltipTable>
                                                    <TooltipTable.Item
                                                        label={t('statistics.ageGroupShare', 'Age group share %')}
                                                        total
                                                    >
                                                        {round(record.ageGroupToTotalRatio * 100, 1)}%
                                                    </TooltipTable.Item>
                                                    <TooltipTable.Item
                                                        label={t('statistics.menAgeGroup', 'Men in age group %')}
                                                    >
                                                        {round(record.maleRatio * 100, 1)}%
                                                    </TooltipTable.Item>
                                                    <TooltipTable.Item
                                                        label={t('statistics.womenAgeGroup', 'Women in age group %')}
                                                    >
                                                        {round(record.femaleRatio * 100, 1)}%
                                                    </TooltipTable.Item>
                                                </TooltipTable>
                                            )
                                        }}
                                    </TooltipContent>
                                }
                                cursor={{ fill: palette.grey }}
                            />
                            <Bar barSize={23} dataKey="female" fill={palette.vividiPrimaryLight} stackId="a" />
                            <Bar barSize={23} dataKey="male" fill={palette.vividiPrimary} stackId="a" />
                            <Bar dataKey="total" hide={true} />
                        </BarChart>
                    )}
                </AutoSizer>
            </div>
            <Legend />
        </>
    )
}

const FootfallByBoundaryTable: React.FC<{
    scenes: SceneListResponse
    properties: IntervalPropertiesByBoundary[]
    visitors: IntervalMovementByBoundary[]
    containsFootfallBoundaries?: boolean
}> = ({ properties, visitors: dailyVisitors, scenes, containsFootfallBoundaries = true }) => {
    const { t } = useTranslation()

    const granularityLabel = getGranularityLabel(dailyVisitors).toLowerCase()
    const addBackLink = useAddBackLink()

    const propertiesPerBoundary = preparePropertiesPerBoundary(properties, dailyVisitors, scenes)
    const organizationId = head(scenes.scenes)?.organizationId ?? -1

    return (
        <TableWithSorting
            bodyRows={propertiesPerBoundary.map((boundaryRecord) => {
                const scene = scenes.scenes.find((s) => s.id === boundaryRecord.sceneId)

                return {
                    scene: scene
                        ? {
                              content: boundaryRecord.scene,
                              cellType: 'text',
                              targetLink: addBackLink(generateOrganizationScenesPath(organizationId, scene.id)),

                              renderer: AdminOnlyLinkRenderer,
                          }
                        : {
                              content: boundaryRecord.scene,
                              cellType: 'text',
                          },
                    visitBoundary: {
                        content: boundaryRecord.visitBoundary,
                        cellType: 'text',
                    },
                    totalVisitors: {
                        content: boundaryRecord.totalVisitors,
                        cellType: 'numeric',
                        numberFormat: 'people',
                    },
                    granularVisitors: {
                        content: boundaryRecord.granularVisitors,
                        cellType: 'numeric',
                        numberFormat: 'people',
                    },
                    male: {
                        content: boundaryRecord.male,
                        cellType: 'numeric',
                        numberFormat: 'percentage',
                    },
                    female: {
                        content: boundaryRecord.female,
                        cellType: 'numeric',
                        numberFormat: 'percentage',
                    },
                    mostCommonAge: {
                        content: boundaryRecord.mostCommonAge,
                        cellType: 'text',
                        align: 'right',
                    },
                }
            })}
            className={styles.table}
            defaultSortingColumn="totalVisitors"
            defaultSortingOrder="desc"
            excludedSortingColumns={['visitBoundary']}
            headRow={[
                { name: 'scene', displayName: t('table.scene', 'Scene') },
                {
                    name: 'visitBoundary',
                    displayName: t('footfall.visitBoundary', 'Visit boundary'),
                },
                {
                    name: 'totalVisitors',
                    displayName: containsFootfallBoundaries
                        ? t('statistics.totalVisitors', 'Total visitors')
                        : t('statistics.totalCount', 'Total count'),
                    align: 'right',
                },
                {
                    name: 'granularVisitors',
                    displayName: capitalize(
                        containsFootfallBoundaries
                            ? t('footfall.granularityVisitors', `{{value}} visitors`, {
                                  value: granularityLabel,
                              })
                            : t('footfall.granularityCount', `{{value}} count`, {
                                  value: granularityLabel,
                              })
                    ),
                    align: 'right',
                },
                {
                    name: 'male',
                    displayName: t('footfall.men', 'Men'),
                    align: 'right',
                },
                {
                    name: 'female',
                    displayName: t('footfall.women', 'Women'),
                    align: 'right',
                },
                {
                    name: 'mostCommonAge',
                    displayName: t('footfall.mostCommonAge', 'Most common age'),
                    align: 'right',
                },
            ]}
            paginationSize={15}
        />
    )
}

interface Props {
    propertiesByBoundary: PropertiesByBoundaryResponse
    visitorsByBoundary: MovementByBoundaryResponse
    genderAgeBreakdown: PropertyBreakdownByBoundaryResponse
    scenes: SceneListResponse
    sceneConfiguration: Dictionary<SceneDescription>
    locality: LocalityResponse
    selectedBoundaries?: VisitBoundaryWithId[]
    interval: Interval
    now: DateTime
    intervalType: IntervalType
    weather: LocalityWeatherResponse
    outages: OutageModel[]
}

export function pickVisitorsByBoundary<T extends { boundaryId: number }>(
    items: Array<T>,
    selectedBoundaries: VisitBoundaryWithId[] | undefined
) {
    if (selectedBoundaries && !isEmpty(selectedBoundaries)) {
        return items.filter((visitors) => selectedBoundaries.map((b) => b.id).includes(visitors.boundaryId))
    }

    return items
}

const FootfallDetailsContent: React.FC<Props> = (props) => {
    const { t } = useTranslation()

    const devicesInLocality = props.scenes.scenes.filter((s) => s.localityIds.includes(Number(props.locality.id)))
    const [visibleBoundaries, setVisibleBoundaries] = useState('footfall')

    const areSelectedBoundaries = !isEmpty(props.selectedBoundaries)

    const determineBoundaryDevice = (boundaryId: number) => {
        const sceneId = Object.entries(props.sceneConfiguration).find(([, config]) =>
            config.visitBoundaries.some((b) => b.id === boundaryId)
        )?.[0]

        return sceneId !== undefined ? props.scenes.scenes.find((s) => s.id.toString() === sceneId) : undefined
    }

    const allBoundariesPropertiesByBoundary = pickVisitorsByBoundary(
        props.propertiesByBoundary.footfallItems.concat(props.propertiesByBoundary.nonFootfallItems),
        props.selectedBoundaries
    )
    const footfallPropertiesByBoundary = pickVisitorsByBoundary(
        props.propertiesByBoundary.footfallItems,
        props.selectedBoundaries
    )
    const nonFootfallPropertiesByBoundary = pickVisitorsByBoundary(
        props.propertiesByBoundary.nonFootfallItems,
        props.selectedBoundaries
    )

    const allBoundariesVisitorsByBoundary = pickVisitorsByBoundary(
        props.visitorsByBoundary.footfallItems.concat(props.visitorsByBoundary.nonFootfallItems),
        props.selectedBoundaries
    )
    const footfallVisitorsByBoundary = pickVisitorsByBoundary(
        props.visitorsByBoundary.footfallItems,
        props.selectedBoundaries
    )
    const nonFootfallVisitorsByBoundary = pickVisitorsByBoundary(
        props.visitorsByBoundary.nonFootfallItems,
        props.selectedBoundaries
    )

    const footfallBoundaries = props.visitorsByBoundary.footfallItems.map((i) => i.boundaryId)
    const nonFootfallBoundaries = props.visitorsByBoundary.nonFootfallItems.map((i) => i.boundaryId)

    return (
        <>
            <Row>
                <Col md={6}>
                    <Box>
                        <Box.Title text={t('heading.footfallHighlights', 'Footfall highlights')} />
                        <FootfallHighlights
                            interval={props.interval}
                            locality={props.locality}
                            properties={
                                areSelectedBoundaries ? allBoundariesPropertiesByBoundary : footfallPropertiesByBoundary
                            }
                            visitors={
                                areSelectedBoundaries ? allBoundariesVisitorsByBoundary : footfallVisitorsByBoundary
                            }
                        />
                    </Box>
                </Col>
                <Col md={6}>
                    <Card>
                        <Card.Body className={styles.genderAgeBreakdown}>
                            <CardTitle text={t('statistics.visitorDemographics', 'Visitor demographics')} />
                            <GenderAgeBreakdownPlot
                                genderAgeBreakdown={prepareGenderAgeBreakdown(
                                    props.genderAgeBreakdown,
                                    props.selectedBoundaries
                                )}
                                interval={props.interval}
                                now={props.now}
                            />
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Box className={styles.plot}>
                        <Box.Title text={t('heading.incomingVisitorsInTime', 'Incoming visitors in time')} />
                        <FootfallMovementPlot
                            interval={props.interval}
                            intervalType={props.intervalType}
                            now={props.now}
                            outages={props.outages.filter((o) =>
                                areSelectedBoundaries
                                    ? props.selectedBoundaries?.some(
                                          (b) => determineBoundaryDevice(b.id)?.id === o.deviceId
                                      )
                                    : footfallVisitorsByBoundary
                                          .map((it) => determineBoundaryDevice(it.boundaryId))
                                          .some((d) => d?.id === o.deviceId)
                            )}
                            visitors={
                                areSelectedBoundaries ? allBoundariesVisitorsByBoundary : footfallVisitorsByBoundary
                            }
                            weather={props.weather.intervals}
                        />
                    </Box>
                </Col>
            </Row>

            <Row>
                <Col>
                    <Box className={styles.heatmapCard}>
                        <Box.Title
                            buttons={
                                <ToggleButton
                                    leftToggle={{
                                        toggleText: t('button.footfall', 'Footfall'),
                                        toggleValue: 'footfall',
                                    }}
                                    rightToggle={{
                                        toggleText: t('button.nonFootfall', 'Non-footfall'),
                                        toggleValue: 'non-footfall',
                                    }}
                                    toggleName="view-toggle"
                                    toggleValue={visibleBoundaries}
                                    onToggle={setVisibleBoundaries}
                                />
                            }
                            text={t(
                                'others.footfallBoundariesCarousel',
                                '{{ locality }} {{ visibleBoundaries }} boundaries',
                                {
                                    locality: props.locality.name,
                                    visibleBoundaries,
                                }
                            )}
                        />
                        <ConfigurationHeatmapCarousel
                            boundaries={mapValues(props.sceneConfiguration, (it) =>
                                it.visitBoundaries
                                    .filter(
                                        (b) =>
                                            isEmpty(props.selectedBoundaries) ||
                                            props.selectedBoundaries!.some((other) => other.id === b.id)
                                    )
                                    .filter((b) =>
                                        visibleBoundaries === 'footfall'
                                            ? footfallBoundaries.includes(b.id)
                                            : nonFootfallBoundaries.includes(b.id)
                                    )
                            )}
                            drawBoundaries={true}
                            drawBoundaryDirection={true}
                            drawZones={false}
                            scenes={devicesInLocality}
                        />
                    </Box>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Box>
                        <SpacedLayout gapSize="lg">
                            <div>
                                <Box.Title text={t('footfall.footfallBoundaries', 'Footfall boundaries breakdown')} />
                                <FootfallByBoundaryTable
                                    properties={footfallPropertiesByBoundary}
                                    scenes={props.scenes}
                                    visitors={footfallVisitorsByBoundary}
                                />
                            </div>
                            {!isEmpty(nonFootfallVisitorsByBoundary) && (
                                <div>
                                    <Box.Title
                                        text={t('footfall.nonFootfallBoundaries', 'Non-footfall boundaries breakdown')}
                                    />
                                    <FootfallByBoundaryTable
                                        containsFootfallBoundaries={false}
                                        properties={nonFootfallPropertiesByBoundary}
                                        scenes={props.scenes}
                                        visitors={nonFootfallVisitorsByBoundary}
                                    />
                                </div>
                            )}
                        </SpacedLayout>
                    </Box>
                </Col>
            </Row>
        </>
    )
}

export default FootfallDetailsContent
