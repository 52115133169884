/* tslint:disable */
/* eslint-disable */
/**
 * Vividi Dashboard API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    NumericBound,
    NumericBoundFromJSON,
    NumericBoundFromJSONTyped,
    NumericBoundToJSON,
} from './NumericBound';

/**
 * 
 * @export
 * @interface SummaryBracket
 */
export interface SummaryBracket {
    /**
     * 
     * @type {NumericBound}
     * @memberof SummaryBracket
     */
    lowerBound?: NumericBound;
    /**
     * 
     * @type {NumericBound}
     * @memberof SummaryBracket
     */
    upperBound?: NumericBound;
    /**
     * 
     * @type {string}
     * @memberof SummaryBracket
     */
    label: string;
}

export function SummaryBracketFromJSON(json: any): SummaryBracket {
    return SummaryBracketFromJSONTyped(json, false);
}

export function SummaryBracketFromJSONTyped(json: any, ignoreDiscriminator: boolean): SummaryBracket {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'lowerBound': !exists(json, 'lower_bound') ? undefined : NumericBoundFromJSON(json['lower_bound']),
        'upperBound': !exists(json, 'upper_bound') ? undefined : NumericBoundFromJSON(json['upper_bound']),
        'label': json['label'],
    };
}

export function SummaryBracketToJSON(value?: SummaryBracket | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'lower_bound': NumericBoundToJSON(value.lowerBound),
        'upper_bound': NumericBoundToJSON(value.upperBound),
        'label': value.label,
    };
}

