import { faDownload } from '@fortawesome/free-solid-svg-icons'
import { flatten, groupBy, isEmpty, mapValues, omit, round } from 'lodash'
import { DateTime, Interval } from 'luxon'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'

import { OrganizationResponse, LocalityConfigurationStateModel } from '@api'

import { statisticsApi } from '@services'

import { gdynusaReadableInterval } from '@helpers/datetimeUtils'
import { prepareEmotionDataItem, sumEmotionDataItems, emptyEmotionDataItem } from '@helpers/emotionsData'
import { GoogleEventName } from '@helpers/gtag'
import { formatIntervalToFilename } from '@helpers/intervals'
import { useLocalizeDateTime } from '@helpers/timezoneConfig'
import { SummaryRequest } from '@helpers/types'
import { pickColumn } from '@helpers/utils'
import { exportToExcel } from '@helpers/xlsxExporter'

import buttonStyles from '@elements/Buttons/IconButton.module.scss'

import AsyncButton from '@components/AsyncButton'
import { partitionByInterval, parseInterval } from '@components/StatisticsSummary/utils'
import TooltipWrapper from '@components/TooltipWrapper'
import { useNotify } from '@components/notifications/NotificationsContext'

type EmotionsSummaryDownloadButtonProps = {
    interval: Interval
    selectedOrganization: OrganizationResponse
    localities: LocalityConfigurationStateModel[]
    partitionedInterval: SummaryRequest
    partitionedComparisonInterval?: SummaryRequest
}

const EmotionsSummaryDownloadButton: React.FC<EmotionsSummaryDownloadButtonProps> = ({
    interval,
    selectedOrganization,
    localities,
    partitionedInterval,
    partitionedComparisonInterval,
}) => {
    const notify = useNotify()

    const { t } = useTranslation()
    const localize = useLocalizeDateTime()

    const { mutateAsync: downloadCall, status } = useMutation(statisticsApi.getEmotionsSummaryByLocality)

    const handleDownload = async () => {
        try {
            const response = await downloadCall({
                intervals: [...partitionedInterval.intervals, ...(partitionedComparisonInterval?.intervals || [])],
                localityIds: pickColumn(localities, 'id'),
            })

            const summaries = partitionedInterval.intervals.map((interval) => [
                localize(DateTime.fromISO(interval.startingFrom)).toLocaleString(),
                mapValues(response, ({ items }) =>
                    mapValues(
                        partitionByInterval(
                            parseInterval(interval),
                            items.flatMap((zoneBreakdown) => zoneBreakdown.items)
                        ),
                        (intervalData) =>
                            intervalData.map(prepareEmotionDataItem).reduce(sumEmotionDataItems, emptyEmotionDataItem)
                    )
                ),
            ])

            const data = groupBy(
                flatten(
                    summaries.map(([date, summaryData]) =>
                        Object.entries(summaryData).map(([localityId, localityData]) => ({
                            locality: localities.find((l) => l.id.toString() === localityId)!.name,
                            [t('others.date', 'Date')]: date,
                            [t('emotions.avgEmotionScore', 'Avg. emotion score')]: round(
                                localityData.currentInterval.totalEmotionScore /
                                    (localityData.currentInterval.totalObservations || 1),
                                1
                            ),
                            [t('emotions.positiveFaces', 'Positive faces')]:
                                localityData.currentInterval.positiveObservations,
                            [t('emotions.neutralFaces', 'Neutral faces')]:
                                localityData.currentInterval.neutralObservations,
                            [t('emotions.negativeFaces', 'Negative faces')]:
                                localityData.currentInterval.negativeObservations,
                        }))
                    )
                ),
                'locality'
            )

            const exportData = Object.fromEntries(
                Object.entries(data).map(([locality, localityData]) => [
                    locality,
                    localityData.map((it) => omit(it, 'locality')),
                ])
            )

            if (!isEmpty(exportData)) {
                exportToExcel(
                    exportData,
                    `${selectedOrganization.name}-emotions-${formatIntervalToFilename(
                        gdynusaReadableInterval(interval.mapEndpoints(localize))
                    )}`,
                    GoogleEventName.DOWNLOAD_EMOTIONS_ORGANIZATION_EXCEL,
                    true
                )
            } else {
                notify({
                    title: t('notification.error', 'Error'),
                    content: t('notification.nothingToExport', 'Nothing to export'),
                    variant: 'danger',
                })
            }
        } catch (e) {
            notify({
                title: t('notification.error', 'Error'),
                content: t('notification.nothingToExport', 'Nothing to export'),
                variant: 'danger',
            })
        }
    }

    return (
        <TooltipWrapper tooltipText={t('button.downloadXLSX', 'Download XLSX')}>
            <AsyncButton
                allowRefetch={true}
                bsPrefix={buttonStyles.iconButton}
                icon={faDownload}
                size="sm"
                status={status}
                text=""
                onClick={handleDownload}
            />
        </TooltipWrapper>
    )
}

export default EmotionsSummaryDownloadButton
