/* tslint:disable */
/* eslint-disable */
/**
 * Vividi Dashboard API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * An enumeration.
 * @export
 * @enum {string}
 */
export enum DeviceProtocolCommand {
    Entities = 'entities',
    EntitiesExtended = 'entities-extended',
    EntitiesAck = 'entities-ack',
    LastCaptureOverlay = 'last-capture-overlay',
    LastCapture = 'last-capture',
    LastCaptureFullResolution = 'last-capture-full-resolution',
    DataCollectionFaceBodies = 'data-collection-face-bodies',
    DataCollectionCacheBodies = 'data-collection-cache-bodies',
    DataCollectionGetBodies = 'data-collection-get-bodies',
    DataCollectionFrames = 'data-collection-frames',
    Status = 'status',
    Configuration = 'configuration',
    Terminate = 'terminate',
    GetReferenceCapture = 'get-reference-capture',
    SetReferenceCapture = 'set-reference-capture',
    Refocus = 'refocus'
}

export function DeviceProtocolCommandFromJSON(json: any): DeviceProtocolCommand {
    return DeviceProtocolCommandFromJSONTyped(json, false);
}

export function DeviceProtocolCommandFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeviceProtocolCommand {
    return json as DeviceProtocolCommand;
}

export function DeviceProtocolCommandToJSON(value?: DeviceProtocolCommand | null): any {
    return value as any;
}

