import classNames from 'classnames'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { passCSSVariable } from '@helpers/cssUtils'

import styles from './LiveIndicator.module.scss'

type Props = {
    freeze?: boolean
    disable?: boolean
    hideTitle?: boolean
    indicatorColor?: string
    pulseCircleBeforeColor?: string
    pulseCircleAfterColor?: string
}

const LiveIndicator = ({
    hideTitle = false,
    indicatorColor,
    pulseCircleBeforeColor,
    pulseCircleAfterColor,
    freeze = false,
    disable = false,
}: Props) => {
    const { t } = useTranslation()

    return (
        <div className={styles.indicator} style={passCSSVariable('IndicatorColor', indicatorColor)}>
            <div
                className={classNames({
                    [styles.pulseCircle]: !freeze,
                    [styles.pulseCircleFrozen]: freeze,
                    [styles.pulseCircleDisabled]: disable,
                })}
                style={{
                    ...passCSSVariable('PulseCircleBeforeColor', pulseCircleBeforeColor),
                    ...passCSSVariable('PulseCircleAfterColor', pulseCircleAfterColor),
                }}
            />
            {!hideTitle && <span>{t('others.live', 'Live')}</span>}
        </div>
    )
}

export default LiveIndicator
