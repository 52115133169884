import { Interval, DateTime, DateTimeFormatOptions, Zone } from 'luxon'
import { TFunction } from 'react-i18next'

import { DatetimeInterval } from '@api'

export enum Precision {
    YEAR,
    MONTH,
    DAY,
    HOUR,
    MINUTE,
    SECOND,
    MILLISECOND,
}

export const formatInterval = (interval: Interval, precision: Precision, format?: DateTimeFormatOptions): string => {
    const timeFormat = precision >= Precision.SECOND ? DateTime.TIME_24_WITH_SECONDS : DateTime.TIME_24_SIMPLE

    if (interval.start.toISODate() === interval.end.toISODate()) {
        const date = interval.start.toLocaleString(DateTime.DATE_SHORT)
        const start = interval.start.toLocaleString(timeFormat)
        const end = interval.end.toLocaleString(timeFormat)

        if (format === DateTime.DATE_SHORT) {
            return date
        }

        return `${date} ${start} - ${end}`
    }

    const dateTimeFormat = format
        ? format
        : precision >= Precision.SECOND
        ? DateTime.DATETIME_SHORT_WITH_SECONDS
        : precision >= Precision.HOUR
        ? DateTime.DATETIME_SHORT
        : DateTime.DATE_SHORT

    const start = interval.start.toLocaleString({
        ...dateTimeFormat,
        // If both ends are in the same year, do not display it
        year: interval.start.year !== interval.end.year ? dateTimeFormat.year : undefined,
    })
    const end = interval.end.toLocaleString(dateTimeFormat)

    return `${start} - ${end}`
}

/**
 * Date range interval format to csv filename
 */
export const formatIntervalToFilename = (interval: Interval): string => {
    if (interval.length('hour') <= 24) {
        return formatInterval(interval, Precision.MINUTE)
    }

    return formatInterval(interval, Precision.DAY)
}

/**
 *
 * @param interval initial interval from the comparison is generated
 * generates interval that is in front of initial interval with the same length
 * eg.
 *  Selected: Week (7 days), Previous period: 'Compare with previous week'
 *  Selected: 2 weeks (14 days), Previous period: 'Compare with previous 2 weeks'
 *  Selected: Today, Previous period: 'Compare with yesterday'
 *  Selected: 1 day (not today), Previous period: 'Compare with previous day'
 *  Selected: Other ranges (etc. 2-6 days, 8-13 days), Previous period: 'Compare with previous period'
 */
export const getComparisonInterval = (interval: Interval, zone: Zone): Interval => {
    const localizedInterval = interval.mapEndpoints((it) => it.setZone(zone))
    const shift =
        localizedInterval.length('month') === 1 && localizedInterval.hasSame('month')
            ? { month: 1 }
            : { days: localizedInterval.length('days') }

    return localizedInterval.mapEndpoints((it) => it.minus(shift)).mapEndpoints((it) => it.setZone('utc'))
}

export const getComparisonIntervalText = (interval: Interval, zone: Zone, t: TFunction): string => {
    const today = DateTime.utc().setZone(zone).startOf('day').setZone('utc')

    const localizedInterval = interval.mapEndpoints((it) => it.setZone(zone))

    const isDayInterval = localizedInterval.hasSame('day') && localizedInterval.length('day') === 1
    const isMonthInterval = localizedInterval.hasSame('month') && localizedInterval.length('month') === 1
    const isWeekAligned = localizedInterval.start.weekday === 1 && localizedInterval.end.weekday === 1

    if (isMonthInterval) {
        return t('others.previousMonth', 'Compare with previous month')
    }

    if (isDayInterval) {
        if (today.toISODate() === interval.start.toISODate()) {
            return t('others.yesterday', 'Compare with yesterday')
        }

        return t('others.previousDay', 'Compare with previous day')
    }

    if (isWeekAligned) {
        if (localizedInterval.length('weeks') === 1) {
            return t('others.previousWeek', 'Compare with previous week')
        }

        return t('others.previousWeeks', `Compare with previous ${interval.length('weeks')} weeks`, {
            value: interval.length('weeks'),
        })
    }

    return t('others.compareWithPreviousPeriod', 'Compare with previous period')
}

export const apiInterval = (interval: Interval): DatetimeInterval => ({
    startingFrom: interval.start.toISO(),
    endingAt: interval.end.toISO(),
})
