import classNames from 'classnames'
import React from 'react'

import HeadingElement from '@elements/Heading/Heading'

import styles from './layouts.module.scss'

export type LayoutHeadingProps = {
    headingClassName?: string
    heading: string | JSX.Element
    headingButtons?: JSX.Element | null
    headingType?: 'page' | 'section'
}

export const LayoutHeading: React.FC<LayoutHeadingProps> = ({
    heading,
    headingButtons,
    headingClassName,
    headingType,
}) => {
    const content =
        typeof heading === 'string' ? <HeadingElement level={1} text={heading} variant={headingType} /> : heading

    const headingStyles = classNames(
        headingType === 'page' ? styles.pageHeading : styles.sectionHeading,
        headingClassName
    )

    return (
        <div className={headingStyles}>
            {content}
            {headingButtons ?? null}
        </div>
    )
}
