/* tslint:disable */
/* eslint-disable */
/**
 * Vividi Dashboard API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * An enumeration.
 * @export
 * @enum {string}
 */
export enum WeatherDescription {
    Sunny = 'sunny',
    Overcast = 'overcast',
    Cloudy = 'cloudy',
    LightRain = 'light-rain',
    Rain = 'rain',
    HeavyRain = 'heavy-rain',
    Snow = 'snow',
    Fog = 'fog',
    Storm = 'storm'
}

export function WeatherDescriptionFromJSON(json: any): WeatherDescription {
    return WeatherDescriptionFromJSONTyped(json, false);
}

export function WeatherDescriptionFromJSONTyped(json: any, ignoreDiscriminator: boolean): WeatherDescription {
    return json as WeatherDescription;
}

export function WeatherDescriptionToJSON(value?: WeatherDescription | null): any {
    return value as any;
}

