import {
    faBuilding,
    faExchangeAlt,
    faImage,
    faPowerOff,
    faQrcode,
    faStreetView,
    faSync,
    faVideoSlash,
} from '@fortawesome/free-solid-svg-icons'
import { faAperture, faImagePolaroid, faScribble, faWifiSlash } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import { noop, uniqueId } from 'lodash'
import QRCode from 'qrcode.react'
import React, { useMemo, useState } from 'react'
import { Button, Col, Form, InputGroup, OverlayTrigger, Popover, Row, Spinner, Image } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { MutationStatus, useQuery, UseQueryResult } from 'react-query'
import { Link, useParams } from 'react-router-dom'
import AutoSizer from 'react-virtualized-auto-sizer'

import {
    DeviceProtocolCommand,
    DeviceResponse,
    DockerImageListResponse,
    DockerImageResponse,
    OrganizationResponse,
    SceneResponse,
} from '@api'

import { deviceApi, organizationApi, sceneApi, vividiImagesApi } from '@services'

import {
    generateDeviceTransferPath,
    generateOrganizationScenesPath,
    generateSceneDiagnosticsPath,
} from '@helpers/VividiURLs'

import { useDetectMobile } from '@hooks/useDetectDevice'

import ToggleButton from '@elements/Buttons/ToggleButton'

import AsyncButton from '@components/AsyncButton'
import ControlledModal from '@components/ControlledModal'
import ErrorView from '@components/ErrorView'
import PhoneNumberInput from '@components/GenericInputs/PhoneNumberInput'
import TextInput from '@components/GenericInputs/TextInput'
import ImageLoadingWrapper from '@components/ImageLoadingWrapper'
import ImageWithModal from '@components/ImageWithModal/ImageWithModal'
import LegacyLoadingWrapper from '@components/LegacyLoadingWrapper'
import PopConfirm from '@components/PopConfirm/PopConfirm'
import { FlipUpsideDownButton } from '@components/ScenesTab/SceneEditForm'
import OrganizationFilterButton from '@components/StatisticsSummary/OrganizationFilterButton'
import { PhoneNumberErrors } from '@components/UserForm/UserEditForm'
import VividiImagePicker from '@components/VividiImagePicker'

import styles from './DevicesEditForm.module.scss'
import { DeviceConfigurationData } from './DevicesTab'
import useDeviceEditActions from './useDevicesEditActions'

const NoteRow: React.FC<{
    note?: string
    onNoteUpdate: (value: string) => void
    deviceColorButton: JSX.Element
    isAdmin: boolean
}> = ({ note, onNoteUpdate, deviceColorButton, isAdmin }) => {
    const { t } = useTranslation()

    return (
        <Form.Group as={Row} className={styles.row}>
            <Col className={styles.label} md={3} sm={12}>
                {t('table.colorIdNoteRow', 'Color, ID, Note')}
            </Col>
            <Col md={isAdmin ? 2 : 9} sm={12}>
                {deviceColorButton}
            </Col>
            {isAdmin && (
                <Col md={7} sm={12}>
                    <InputGroup>
                        <TextInput
                            placeholder={t('devices.AddNote', 'Add note')}
                            value={note}
                            onChange={onNoteUpdate}
                        />
                    </InputGroup>
                </Col>
            )}
        </Form.Group>
    )
}

const OrganizationRow: React.FC<{
    selectedOrganization?: OrganizationResponse
    selectableOrganizations: Array<OrganizationResponse>
    onOrganizationUpdate: (value: OrganizationResponse) => void
    isOrgsPickerDisabled?: boolean
    scene?: SceneResponse
    isAdmin: boolean
}> = ({
    selectedOrganization,
    selectableOrganizations,
    onOrganizationUpdate,
    isOrgsPickerDisabled = false,
    scene,
    isAdmin,
}) => {
    const { t } = useTranslation()
    const id = useMemo(uniqueId, [])
    const { id: orgId } = useParams<{ id?: string }>()
    const organizationId = selectedOrganization?.id ?? orgId ? Number(orgId) : undefined

    return isAdmin ? (
        <Form.Group as={Row} className={styles.row}>
            <Col className={styles.label} md={3} sm={12}>
                Organization
            </Col>
            <Col className={styles.actionsColumn} md={4} sm={12}>
                {isOrgsPickerDisabled ? (
                    <OverlayTrigger
                        overlay={
                            <Popover id={id}>
                                <Popover.Content>
                                    {t(
                                        'devices.assignedDevicesAreNotMovable',
                                        'You cannot move the device to different organization when it is assigned to the scene.'
                                    )}
                                </Popover.Content>
                            </Popover>
                        }
                        placement="top"
                        trigger={['hover', 'focus']}
                    >
                        <Button className={styles.disabledOrganizationButton} variant="secondary" onClick={noop}>
                            <FontAwesomeIcon className={styles.icon} icon={faBuilding} />
                            {selectedOrganization?.name}
                        </Button>
                    </OverlayTrigger>
                ) : (
                    <OrganizationFilterButton
                        organizations={selectableOrganizations}
                        selectedOrganization={selectedOrganization}
                        onSelectOrganization={onOrganizationUpdate}
                    />
                )}
            </Col>
            {selectedOrganization && scene && (
                <>
                    <Col className={styles.label} md={2} sm={12}>
                        {t('table.scene', 'Scene')}
                    </Col>
                    <Col className={styles.sceneContainer} md={3} sm={12}>
                        <span>
                            <FontAwesomeIcon icon={faStreetView} />
                            <Link
                                className={styles.link}
                                to={generateOrganizationScenesPath(selectedOrganization.id, scene.id)}
                            >
                                {scene.label}
                            </Link>
                        </span>
                    </Col>
                </>
            )}
        </Form.Group>
    ) : (
        <Form.Group as={Row} className={styles.row}>
            <Col className={styles.label} md={3} sm={12}>
                {t('table.scenes', 'Scenes')}
            </Col>
            <Col className={styles.sceneContainer} md={9} sm={12}>
                {scene ? (
                    <span>
                        <FontAwesomeIcon icon={faStreetView} />

                        {organizationId ? (
                            <Link className={styles.link} to={generateOrganizationScenesPath(organizationId, scene.id)}>
                                {scene.label}
                            </Link>
                        ) : (
                            <span>{scene.label}</span>
                        )}
                    </span>
                ) : (
                    <span className="text-muted">{t('others.noSceneAssigned', 'No scene assigned')}</span>
                )}
            </Col>
        </Form.Group>
    )
}

type ActionRowCalls = {
    requestSystemRestart: MutationStatus
    requestCameraRefocus: MutationStatus
}

export enum CameraTab {
    CAMERA_FEED,
    STORED_IMAGE,
    REFERENCE_CAPTURE,
}

const CaptureRow: React.FC<{
    referenceCaptureRequest: UseQueryResult<Blob>
    liveViewRequest: UseQueryResult<Blob>
    storedSnapshotRequest: UseQueryResult<Blob>
    loading: boolean
    isAdmin: boolean
    onTabSelect: (tab: CameraTab) => void
    selectedTab: CameraTab
}> = ({
    liveViewRequest,
    referenceCaptureRequest,
    storedSnapshotRequest,
    loading,
    isAdmin,
    onTabSelect,
    selectedTab,
}) => {
    const { t } = useTranslation()
    const mobile = useDetectMobile()

    const determineImageRequest = (selectedTab: CameraTab) => {
        if (selectedTab === CameraTab.CAMERA_FEED) {
            return liveViewRequest
        }

        if (selectedTab === CameraTab.STORED_IMAGE) {
            return storedSnapshotRequest
        }

        if (selectedTab === CameraTab.REFERENCE_CAPTURE) {
            return referenceCaptureRequest
        }

        return liveViewRequest
    }

    const determineCaptureLoadingMessage = (selectedTab: CameraTab) => {
        if (selectedTab === CameraTab.CAMERA_FEED) {
            return t('devices.deviceLiveViewIsBeingLoaded', 'Device live view is being loaded')
        }

        if (selectedTab === CameraTab.STORED_IMAGE) {
            return t('devices.deviceSnapShotIsBeingLoaded', 'Device snapshot is being loaded')
        }

        if (selectedTab === CameraTab.REFERENCE_CAPTURE) {
            return t('devices.deviceReferenceCaptureIsBeingLoaded', 'Device reference capture is being loaded')
        }

        return t('devices.deviceLiveViewIsBeingLoaded', 'Device live view is being loaded')
    }

    const errorPlaceholder = (
        <div className={styles.loadingContainer}>
            <span>
                <FontAwesomeIcon icon={faVideoSlash} size="sm" />
                {t('realTime.deviceIsOffline', 'Device is offline')}
            </span>
            <span className={styles.infoText}>
                {t(
                    'others.deviceIsOfflineInfo',
                    'Check the electric socket powering the device and make sure internet connection is up. Reload the device once the issue is resolved.'
                )}
            </span>
        </div>
    )

    const loadingPlaceholder = (
        <div className={styles.loadingContainer}>
            <div className="spinner-border" role="status">
                <span className="sr-only">{t('others.loading', 'Loading')}</span>
            </div>
            <div className={styles.infoText}>{determineCaptureLoadingMessage(selectedTab)}</div>
        </div>
    )

    return (
        <div className={styles.captureRowContainer}>
            <div className={styles.captureActionsContainer}>
                {isAdmin && (
                    <ToggleButton
                        leftToggle={{
                            toggleText: t('tab.liveView', 'Live view'),
                            toggleValue: CameraTab.CAMERA_FEED.toString(),
                        }}
                        middleToggle={{
                            toggleText: t('button.snapshot', 'Snapshot'),
                            toggleValue: CameraTab.STORED_IMAGE.toString(),
                        }}
                        rightToggle={{
                            toggleText: mobile
                                ? t('button.referenceCaptureMobile', 'Capture')
                                : t('button.referenceCapture', 'Reference capture'),
                            toggleValue: CameraTab.REFERENCE_CAPTURE.toString(),
                        }}
                        toggleName="Camera-tab-picker"
                        toggleValue={selectedTab.toString()}
                        onToggle={(value) => {
                            if (value.toString() === CameraTab.CAMERA_FEED.toString()) {
                                onTabSelect(CameraTab.CAMERA_FEED)
                            }

                            if (value.toString() === CameraTab.STORED_IMAGE.toString()) {
                                onTabSelect(CameraTab.STORED_IMAGE)
                            }

                            if (value.toString() === CameraTab.REFERENCE_CAPTURE.toString()) {
                                onTabSelect(CameraTab.REFERENCE_CAPTURE)
                            }
                        }}
                    />
                )}
                {!isAdmin && t('button.snapshot', 'Snapshot')}
                {selectedTab === CameraTab.CAMERA_FEED && !mobile && isAdmin && (
                    <Button variant="secondary" onClick={() => liveViewRequest.refetch()}>
                        <FontAwesomeIcon icon={faSync} /> {t('button.reload', 'Reload')}
                    </Button>
                )}
            </div>
            {loading ? (
                loadingPlaceholder
            ) : (
                <ImageLoadingWrapper
                    errorComponent={errorPlaceholder}
                    placeholder={loadingPlaceholder}
                    request={determineImageRequest(selectedTab)}
                >
                    <ImageWithModal src="">
                        <Image src="" fluid />
                    </ImageWithModal>
                </ImageLoadingWrapper>
            )}

            {selectedTab === CameraTab.CAMERA_FEED && mobile && isAdmin && (
                <Button variant="secondary" onClick={() => liveViewRequest.refetch()}>
                    <FontAwesomeIcon icon={faSync} /> {t('button.reload', 'Reload')}
                </Button>
            )}
        </div>
    )
}

const VividiImageRow: React.FC<{
    onVividiImageSelected: (image: DockerImageResponse) => void
    imagesCall: UseQueryResult<DockerImageListResponse>
    vividiImage?: string
    deviceVividiImage?: string
}> = ({ onVividiImageSelected, imagesCall, vividiImage, deviceVividiImage }) => {
    const { t } = useTranslation()

    return (
        <Form.Group as={Row} className={styles.row}>
            <Col className={styles.label} md={3} sm={12}>
                {t('form.vividiImage', 'Vividi Image')}
            </Col>
            <Col md={9} sm={12}>
                <LegacyLoadingWrapper
                    errorComponent={
                        <ErrorView
                            message={t('others.imagesCouldNotBeFetched', 'Available images could not be fetched')}
                        />
                    }
                    placeholder={
                        <InputGroup>
                            <InputGroup.Prepend>
                                <InputGroup.Text>
                                    <Spinner animation="border" size="sm" />
                                </InputGroup.Text>
                            </InputGroup.Prepend>
                            <TextInput value={vividiImage} disabled />
                        </InputGroup>
                    }
                    request={imagesCall}
                >
                    {({ images }) => {
                        const selectedImage = images.find((image) => `${image.name}:${image.tag}` === vividiImage)

                        return (
                            <>
                                {t('form.currentVividiImage', 'Current Vividi Image')}:{' '}
                                <strong>{deviceVividiImage}</strong>
                                <VividiImagePicker
                                    availableImages={images}
                                    selectedImage={selectedImage}
                                    onSelectImage={onVividiImageSelected}
                                />
                            </>
                        )
                    }}
                </LegacyLoadingWrapper>
            </Col>
        </Form.Group>
    )
}

const AdditionalActionsRow: React.FC<
    {
        requestSetRefCapture: MutationStatus
        scene?: SceneResponse
        deviceId?: number
        liveViewRequest: UseQueryResult<Blob>
        storedSnapshotRequest: UseQueryResult<Blob>
        onRequestSetRefCapture: () => void
        onRequestFlipUpsideDown: (upsideDown: boolean) => void
        onRequestSnapshotUpdate: () => void
        upsideDown: boolean
        updateSnapshotState: MutationStatus
        onRequestCameraRefocus: () => void
        onSystemRestart: () => void
    } & ActionRowCalls
> = ({
    requestSetRefCapture,
    scene,
    deviceId,
    liveViewRequest,
    storedSnapshotRequest,
    upsideDown,
    updateSnapshotState,
    requestCameraRefocus,
    requestSystemRestart,
    onRequestSetRefCapture,
    onRequestFlipUpsideDown,
    onRequestSnapshotUpdate,
    onRequestCameraRefocus,
    onSystemRestart,
}) => {
    const { t } = useTranslation()

    const failedToFetch = liveViewRequest.status === 'error'

    return (
        <Form.Group as={Row} className={styles.row}>
            <Col className={styles.label} md={3} sm={12}>
                {t('table.actions', 'Actions')}
            </Col>
            <Col className={styles.actionsColumn} md={9} sm={12}>
                {!failedToFetch && (
                    <>
                        <Button
                            className={classNames(styles.saveButton)}
                            disabled={storedSnapshotRequest.status === 'loading'}
                            variant="secondary"
                            onClick={(e) => {
                                e.preventDefault()
                                onRequestSnapshotUpdate()
                            }}
                        >
                            <FontAwesomeIcon icon={faImage} />
                            {t('button.saveAsSnapshot', 'Save as snapshot')}
                        </Button>
                        <FlipUpsideDownButton
                            deviceUpsideDown={upsideDown}
                            disabled={updateSnapshotState === 'loading'}
                            liveViewRequestState={liveViewRequest.status}
                            showWarning={false}
                            onClick={() => onRequestFlipUpsideDown(!upsideDown)}
                        />
                    </>
                )}
                <AsyncButton
                    icon={faAperture}
                    status={requestCameraRefocus}
                    text={t('button.refocus', 'Refocus')}
                    variant="secondary"
                    onClick={onRequestCameraRefocus}
                />
                <PopConfirm
                    cancelButtonVariant="secondary"
                    confirmButtonVariant="primary"
                    confirmMessage={t(
                        'devices.restartSystemConfirmation',
                        'Are you sure you want to restart system on device #{{value}}?',
                        { value: deviceId }
                    )}
                    onConfirm={onSystemRestart}
                >
                    <AsyncButton
                        icon={faPowerOff}
                        status={requestSystemRestart}
                        text={t('button.restartSystem', 'Restart system')}
                        variant="secondary"
                        onClick={(e) => e.preventDefault()}
                    />
                </PopConfirm>
                <AsyncButton
                    icon={faImagePolaroid}
                    status={requestSetRefCapture}
                    text={t('button.setReference', 'Set reference')}
                    variant="secondary"
                    onClick={onRequestSetRefCapture}
                />
                {scene && (
                    <Button as={Link} to={generateSceneDiagnosticsPath(scene.id)} variant="secondary">
                        <FontAwesomeIcon icon={faScribble} /> {t('button.diagnostics', 'Diagnostics')}
                    </Button>
                )}
                {deviceId && (
                    <Button as={Link} to={generateDeviceTransferPath(deviceId)} variant="secondary">
                        <FontAwesomeIcon icon={faExchangeAlt} /> {t('button.transfer', 'Transfer')}
                    </Button>
                )}
            </Col>
        </Form.Group>
    )
}

const NetworkSection: React.FC<{
    phoneNumber?: string
    errors: PhoneNumberErrors
    configuration: DeviceConfigurationData
    qrPayload: string
    onNetworkConfigChange: (configuration: DeviceConfigurationData) => void
    onPhoneNumberChange: (phoneNumber: string) => void
}> = ({ phoneNumber, errors, configuration, onNetworkConfigChange, onPhoneNumberChange, qrPayload }) => {
    const { t } = useTranslation()

    const [openModal, setOpenModal] = useState(false)
    const [passwordVisible] = useState(true)

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) =>
        onNetworkConfigChange({
            ...configuration,
            [e.target.name]: e.target.value,
        })

    const handleOpenModal = () => setOpenModal(true)
    const handleCloseModal = () => setOpenModal(false)

    return (
        <>
            <Form.Group as={Row} className={styles.row}>
                <Col className={styles.label} md={12} sm={12}>
                    <strong>{t('table.networkSettings', 'Network settings')}</strong>
                </Col>
                <PhoneNumberInput
                    errors={errors}
                    label={t('form.simNumber', 'SIM Phone number')}
                    labelBoxMd={3}
                    phoneNumber={phoneNumber}
                    phoneNumberBoxMd={9}
                    onUpdate={onPhoneNumberChange}
                />
            </Form.Group>
            <Form.Group as={Row} className={styles.row}>
                <Col className={styles.label} md={3} sm={12}>
                    {t('form.wifiSettings', 'Wi-Fi settings')}
                </Col>
                <Col className={styles.networkColumn} md={3} sm={12}>
                    <Form.Control
                        name="ssid"
                        placeholder={t('form.networkName', 'Network name')}
                        type="text"
                        value={configuration.ssid}
                        onChange={handleChange}
                    />
                </Col>
                <Col className={styles.networkColumn} md={3} sm={12}>
                    <Form.Control
                        name="password"
                        placeholder={t('form.password', 'Password')}
                        type={passwordVisible ? 'text' : 'password'}
                        value={configuration.password}
                        onChange={handleChange}
                    />
                </Col>
                <Col className={styles.showQRcodeColumn} md={3} sm={12}>
                    <ControlledModal
                        className={styles.qrCodeModal}
                        control={
                            <Button variant="primary" onClick={handleOpenModal}>
                                <FontAwesomeIcon icon={faQrcode} />
                                {t('button.showQRCode', 'Show QR code')}
                            </Button>
                        }
                        show={openModal}
                        centered
                        onHide={handleCloseModal}
                    >
                        <AutoSizer disableHeight={true}>
                            {({ width }) => <QRCode renderAs="svg" size={width} value={qrPayload} />}
                        </AutoSizer>
                    </ControlledModal>
                </Col>
            </Form.Group>
        </>
    )
}

const DisconnectRow: React.FC<{
    onDisconnect: () => void
    wifiResetState: MutationStatus
    device: DeviceResponse
}> = ({ onDisconnect, wifiResetState, device }) => {
    const { t } = useTranslation()

    return (
        <Form.Group as={Row} className={styles.row}>
            <Col className={styles.label} md={3} sm={12} />
            <Col className={styles.disconnectColumn} md={9} sm={12}>
                <PopConfirm
                    cancelButtonVariant="secondary"
                    confirmButtonVariant="primary"
                    confirmMessage={t(
                        'devices.restartTrackerConfirmation',
                        'Are you sure you want to disconnect the device {{value}} from Wi-Fi access point ? ',
                        { value: `${device.id}` }
                    )}
                    onConfirm={onDisconnect}
                >
                    <AsyncButton
                        icon={faWifiSlash}
                        status={wifiResetState}
                        text={t('button.disconnect', 'Disconnect')}
                        variant="secondary"
                        onClick={(e) => e.preventDefault()}
                    />
                </PopConfirm>
            </Col>
        </Form.Group>
    )
}

const findSceneId = (scenes: Array<SceneResponse>, deviceId: number) =>
    scenes.find(({ deviceId: id }) => id === deviceId)?.id ?? -1

const DevicesEditForm: React.FC<{
    actionButtons?: JSX.Element
    deviceColorButton: JSX.Element
    errors: PhoneNumberErrors
    note?: string
    phoneNumber?: string
    phoneNumberLabel?: string
    onPhoneNumberUpdate: (value: string) => void
    onNoteUpdate: (value: string) => void
    onOrganizationUpdate: (value: OrganizationResponse) => void
    selectedDevice?: DeviceResponse
    selectedOrganizationId: number
    organizationScenes: Array<SceneResponse>
    isAdmin: boolean
    networkConfig: DeviceConfigurationData
    onNetworkConfigUpdate: (configuration: DeviceConfigurationData) => void
    onDeviceImageUpdate: (image: DockerImageResponse) => void
    vividiImage?: string
}> = ({
    phoneNumber,
    errors,
    onPhoneNumberUpdate,
    actionButtons,
    note,
    onNoteUpdate,
    deviceColorButton,
    selectedOrganizationId,
    onOrganizationUpdate,
    organizationScenes,
    selectedDevice,
    networkConfig,
    onNetworkConfigUpdate,
    onDeviceImageUpdate,
    vividiImage,
    isAdmin,
}) => {
    const { t } = useTranslation()
    const { id: deviceId, deviceId: deviceSecretKey } = selectedDevice || {}
    const scene = organizationScenes.find(({ deviceId: id }) => id === deviceId)
    const [upsideDown, setUpsideDown] = useState<boolean>(scene?.captureSettings.upsideDown ?? false)
    const [loading, setLoading] = useState<boolean>(false)
    const payload = `${deviceSecretKey}|${networkConfig.ssid}|${networkConfig.password}`

    const organizationsRequest = useQuery({ ...organizationApi.getOrganizations.query() })

    const liveViewRequest = useQuery({
        ...deviceApi.fetchSnapshot.query({ deviceId: deviceId! }),
        enabled: deviceId !== undefined,
    })

    const referenceCaptureRequest = useQuery(deviceApi.fetchReferenceCapture.query({ deviceId: deviceId ?? -1 }))

    const storedSnapshotRequest = useQuery(
        sceneApi.getSceneSnapshot.query({
            sceneId: findSceneId(organizationScenes, deviceId ?? -1),
        })
    )
    const imagesCall = useQuery(vividiImagesApi.getImages.query())

    const [selectedTab, setSelectedTab] = useState<CameraTab>(isAdmin ? CameraTab.CAMERA_FEED : CameraTab.STORED_IMAGE)
    const handleTabChange = (tab: CameraTab) => setSelectedTab(tab)

    // triggers

    const updateLiveView = () => {
        liveViewRequest.refetch()
        setLoading(false)
        setUpsideDown(upsideDown)
    }

    const { actions, states } = useDeviceEditActions({
        handleTabChange,
        referenceCaptureRequest,
        storedSnapshotRequest,
        updateLiveView,
    })

    const handleSystemRestart = () => {
        if (deviceId) {
            actions.requestSystemRestart({
                deviceId,
            })
        }
    }

    const handleWifiReset = () => {
        if (deviceId) {
            actions.requestWifiReset({
                deviceId,
            })
        }
    }

    const handleRequestCameraRefocus = () => {
        if (deviceId) {
            actions.requestCameraRefocus({
                deviceId,
                body: {
                    command: DeviceProtocolCommand.Refocus,
                },
            })
        }
    }

    const handleSnapshotUpdate = () => {
        if (scene === undefined || liveViewRequest.data === undefined) {
            return
        }

        actions.updateSnapshot({
            sceneId: scene.id,
            body: liveViewRequest.data,
        })
    }

    const handleSetRefCapture = async () =>
        actions.setRequestRefCapture({
            deviceId: deviceId ?? -1,
            body: {
                command: DeviceProtocolCommand.SetReferenceCapture,
                arguments: {},
            },
        })

    const handleDeviceUpsideDownUpdate = async (upsideDown: boolean) => {
        if (scene) {
            setLoading(true)

            actions.updateCaptureSettings({
                sceneId: scene.id,
                body: {
                    upsideDown,
                },
            })
        }
    }

    const deviceScene = organizationScenes.find(({ deviceId }) => deviceId === selectedDevice?.id)

    return (
        <Form>
            <NoteRow deviceColorButton={deviceColorButton} isAdmin={isAdmin} note={note} onNoteUpdate={onNoteUpdate} />
            {organizationsRequest.isLoading ? (
                <Form.Group as={Row}>
                    <Col className={styles.label} md={2}>
                        {t('table.organization', 'Organization Name')}
                    </Col>
                    <Col>
                        <span className="text-muted">{t('others.Loading', 'Loading')}</span>
                    </Col>
                </Form.Group>
            ) : (
                <OrganizationRow
                    isAdmin={isAdmin}
                    isOrgsPickerDisabled={deviceScene !== undefined}
                    scene={deviceScene}
                    selectableOrganizations={organizationsRequest.data?.organizations ?? []}
                    selectedOrganization={(organizationsRequest.data?.organizations ?? []).find(
                        ({ id }) => id === selectedOrganizationId
                    )}
                    onOrganizationUpdate={onOrganizationUpdate}
                />
            )}
            {actionButtons && (
                <Form.Group as={Row} className={styles.row}>
                    <Col className={styles.label} md={3} sm={12}>
                        {t('table.actions', 'Actions')}
                    </Col>
                    <Col className={styles.actionsColumn} md={9}>
                        {actionButtons}
                    </Col>
                </Form.Group>
            )}
            <div className={styles.divider}>
                <hr />
            </div>
            {deviceId && (
                <CaptureRow
                    isAdmin={isAdmin}
                    liveViewRequest={liveViewRequest}
                    loading={loading}
                    referenceCaptureRequest={referenceCaptureRequest}
                    selectedTab={selectedTab}
                    storedSnapshotRequest={storedSnapshotRequest}
                    onTabSelect={handleTabChange}
                />
            )}
            {isAdmin && (
                <>
                    <AdditionalActionsRow
                        deviceId={deviceId}
                        liveViewRequest={liveViewRequest}
                        requestCameraRefocus={states.requestCameraRefocusState}
                        requestSetRefCapture={states.setRequestRefCaptureState}
                        requestSystemRestart={states.requestSystemRestartState}
                        scene={scene}
                        storedSnapshotRequest={storedSnapshotRequest}
                        updateSnapshotState={states.updateSnapshotState}
                        upsideDown={upsideDown}
                        onRequestCameraRefocus={handleRequestCameraRefocus}
                        onRequestFlipUpsideDown={handleDeviceUpsideDownUpdate}
                        onRequestSetRefCapture={handleSetRefCapture}
                        onRequestSnapshotUpdate={handleSnapshotUpdate}
                        onSystemRestart={handleSystemRestart}
                    />
                    <VividiImageRow
                        deviceVividiImage={selectedDevice?.vividiImageName}
                        imagesCall={imagesCall}
                        vividiImage={vividiImage}
                        onVividiImageSelected={onDeviceImageUpdate}
                    />
                </>
            )}
            {selectedDevice && (
                <div className={styles.networkSectionContainer}>
                    <NetworkSection
                        configuration={networkConfig}
                        errors={errors}
                        phoneNumber={phoneNumber}
                        qrPayload={payload}
                        onNetworkConfigChange={onNetworkConfigUpdate}
                        onPhoneNumberChange={onPhoneNumberUpdate}
                    />
                    <DisconnectRow
                        device={selectedDevice}
                        wifiResetState={states.requestWifiResetState}
                        onDisconnect={handleWifiReset}
                    />
                </div>
            )}
        </Form>
    )
}

export default DevicesEditForm
