import classNames from 'classnames'
import React, { useState } from 'react'

import styles from './ImageWithModal.module.scss'
import ImageWithModalControlled from './ImageWithModalControlled'

interface Props {
    src: string
    children: React.ReactElement<{
        src: string
        onClick: () => void
        className?: string
    }>
}

const ImageWithModal: React.FC<Props> = ({ src, children }) => {
    const [isOpen, setOpen] = useState(false)

    return (
        <ImageWithModalControlled
            children={children}
            className={classNames(children.props.className, styles.thumbnail)}
            isOpen={isOpen}
            src={src}
            onClick={() => setOpen(true)}
            onClose={() => setOpen(false)}
        />
    )
}

export default ImageWithModal
