import { faMapMarkerAlt, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { deburr, isEmpty, noop, uniqueId } from 'lodash'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Button, OverlayTrigger, Popover } from 'react-bootstrap'
import { Token, TokenProps } from 'react-bootstrap-typeahead'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import { Link, useHistory, useParams } from 'react-router-dom'
import { useLocation } from 'react-use'

import {
    CaptureSettings,
    DeviceResponse,
    FloorplanMapping,
    LocalityNameModel,
    LocalityResponse,
    OrganizationResponse,
    SceneResponse,
} from '@api'

import { localityApi, organizationApi, sceneApi } from '@services'

import { generateOrganizationsEditLocalitiesPath, VIVIDI_APP } from '@helpers/VividiURLs'
import { useApiCallCleaner } from '@helpers/api'
import { orderBy } from '@helpers/orderFunctions'
import { pickColumn } from '@helpers/utils'

import { useDetectMobile } from '@hooks/useDetectDevice'

import Box from '@elements/Box/Box'

import AsyncButton from '@components/AsyncButton'
import AccordionLayout from '@components/Layouts/AccordionLayout'
import SideNavigationListLayout from '@components/Layouts/SideNavigationLayout'
import VerticalListGroup, { VerticalListEntry } from '@components/NavigationLists/VerticalListGroup'
import PopConfirm from '@components/PopConfirm/PopConfirm'
import { useNotify } from '@components/notifications/NotificationsContext'

import AddSceneForm from './AddSceneForm'
import SceneEditForm, { SceneData } from './SceneEditForm'
import InstallerUI from './SceneInstallerUI'
import styles from './ScenesTab.module.scss'

const defaultSceneData = { label: '', note: '' }
const defaultCaptureSettings = { detectVehicles: false, isLongRange: false, upsideDown: false }
const NEW_ITEM_ID = 'new'

const determineSelectedLocalities = (
    localities: LocalityNameModel[],
    selectedScene?: SceneResponse,
    locality?: LocalityResponse
) => {
    if (selectedScene) {
        return selectedScene?.localityIds.map((localityId) => localities.find(({ id }) => id === localityId)!)
    }

    if (locality) {
        return [locality]
    }

    return []
}

const determineIndicators = (deviceId: number | undefined, localityIds: number[]) => {
    const indicators: Array<JSX.Element> = []

    if (deviceId !== undefined) {
        indicators.push(<span key="deviceId">#{deviceId}</span>)
    } else {
        indicators.push(<span key="deviceId">----</span>)
    }

    if (!isEmpty(localityIds)) {
        indicators.push(<FontAwesomeIcon key="localityIndicator" icon={faMapMarkerAlt} />)
    }

    return indicators
}

const ScenesTab: React.FC<{
    isTabActive: boolean
    isNewScene: boolean
    selectableScenes: SceneResponse[]
    sceneDevices: DeviceResponse[]
    assignableDevices: DeviceResponse[]
    locality?: LocalityResponse
    organization: OrganizationResponse
    localities: LocalityNameModel[]
    mappings?: Array<FloorplanMapping>
    determineEditScenePath: (sceneId?: number) => string | undefined
    determineCreateScenePath: () => string
    visibleDevices?: DeviceResponse[]
    describeSceneModal?: JSX.Element
    installerUIView?: 'list' | 'assign' | 'device'
    isInstallerUIOpen: boolean
    handleOpenInstallerUIModal: () => void
    handleCloseInstallerUIModal: () => void
    handleInstallerUIModalSceneSelect: (sceneId: string) => void
    determineAssignDevicePath: (sceneId: number, deviceId: number) => string
}> = ({
    selectableScenes,
    assignableDevices,
    sceneDevices,
    localities,
    locality,
    organization,
    determineEditScenePath,
    determineCreateScenePath,
    isNewScene,
    isTabActive,
    visibleDevices,
    describeSceneModal,
    installerUIView,
    isInstallerUIOpen,
    handleOpenInstallerUIModal,
    handleCloseInstallerUIModal,
    handleInstallerUIModalSceneSelect,
    determineAssignDevicePath,
}) => {
    const mobile = useDetectMobile()
    const { t } = useTranslation()
    const apiCleaner = useApiCallCleaner()
    const notify = useNotify()
    const location = useLocation()

    const history = useHistory()
    const params = useParams<{ sceneId?: string; id?: string }>()
    const id = useMemo(uniqueId, [])

    const sceneId = params.sceneId !== undefined ? parseInt(params.sceneId, 10) : undefined
    const search = useMemo(() => new URLSearchParams(location.search).get('search') ?? '', [location.search])

    const selectedScene = selectableScenes.find(({ id }) => id === sceneId)

    const hasScenes = !isEmpty(selectableScenes)

    const normalizedScenes = useMemo(
        () => selectableScenes.map((scene) => ({ ...scene, normalizedName: deburr(scene.label) })),
        [localities]
    )

    const filteredScenes = normalizedScenes.filter(
        ({ normalizedName, id }) =>
            normalizedName.toLowerCase().includes(deburr(search).toLowerCase()) || id.toString().includes(search)
    )

    const unfilteredNavigationEntries: Array<VerticalListEntry> =
        normalizedScenes.map(({ id, label, localityIds, deviceId }) => {
            const indicators = determineIndicators(deviceId, localityIds)

            return {
                id: String(id),
                displayText: label,
                indicators: !isEmpty(indicators) ? indicators : undefined,
            }
        }) ?? []

    const navigationEntries: Array<VerticalListEntry> =
        filteredScenes.map(({ id, label, localityIds, deviceId }) => {
            const indicators = determineIndicators(deviceId, localityIds)

            return {
                id: String(id),
                displayText: label,
                indicators: !isEmpty(indicators) ? indicators : undefined,
            }
        }) ?? []

    const sortedNavigationEntries = isNewScene
        ? [
              {
                  id: NEW_ITEM_ID,
                  displayText: t('others.newScene', 'New scene'),
              },
              ...orderBy(navigationEntries, 'displayText'),
          ]
        : orderBy(navigationEntries, 'displayText')

    const [selectedLocalities, setSelectedLocalities] = useState<Array<LocalityNameModel>>(() =>
        determineSelectedLocalities(localities, selectedScene, locality)
    )

    const [sceneData, setSceneData] = useState<SceneData>(
        selectedScene ? { label: selectedScene.label, note: selectedScene.note ?? '' } : defaultSceneData
    )

    const [captureSettings, setCaptureSettings] = useState<CaptureSettings>(
        selectedScene ? selectedScene.captureSettings : defaultCaptureSettings
    )

    const [assignedDevice, setAssignedDevice] = useState<DeviceResponse | undefined>(
        sceneDevices.find((d) => d.id === selectedScene?.deviceId)
    )

    const [isEdited, setEdited] = useState(false)

    const [errors, setErrors] = useState({
        label: {
            isTouched: false,
            isInvalid: isEmpty(sceneData?.label),
        },
    })

    const LocalityTokenWithLink: React.FC<TokenProps & { tokenId?: number }> = useCallback(
        ({ disabled, onRemove, children, tokenId }) => (
            <Token key={String(tokenId)} disabled={disabled} onRemove={onRemove}>
                <Link to={generateOrganizationsEditLocalitiesPath(organization.id, tokenId ?? -1)}>{children}</Link>
            </Token>
        ),
        [organization]
    )

    const { mutateAsync: createScene } = useMutation(sceneApi.addScene)
    const { mutateAsync: updateScene } = useMutation(sceneApi.updateScene)
    const { mutateAsync: updateCaptureSettings } = useMutation(sceneApi.updateCaptureSettings)
    const { mutateAsync: setSceneLocalities } = useMutation(sceneApi.setSceneLocalities)
    const { mutateAsync: assignDevice } = useMutation(sceneApi.setSceneDevice)
    const { mutateAsync: unassignDevice } = useMutation(sceneApi.removeSceneDevice)
    const { mutateAsync: deleteScene } = useMutation(sceneApi.deleteScene)

    const handleSearch = (search: string) => {
        history.replace({ ...location, search: search ? `search=${search}` : undefined })
    }

    const handleSceneDeletion =
        ({ id: sceneId, label }: SceneResponse) =>
        async () => {
            try {
                await unassignDevice({ sceneId })
                await deleteScene({ sceneId })

                notify({
                    variant: 'success',
                    title: t('notification.success', 'Success'),
                    content: t('notification.sceneDeletedSuccessfully', 'Scene {{label}} deleted successfully.', {
                        label,
                    }),
                    timeoutSeconds: 4,
                })

                apiCleaner(organizationApi)
                apiCleaner(localityApi)
                apiCleaner(sceneApi)
            } catch (error) {
                notify({
                    title: t('notification.error', 'Error'),
                    content: t('notification.sceneDeletionFailed', 'Something went wrong. Scene deletion failed.'),
                    variant: 'danger',
                    timeoutSeconds: 4,
                })
            }
        }

    const onCancel = () => {
        if (selectedScene) {
            setSceneData({ label: selectedScene.label, note: selectedScene.note ?? '' })
            setCaptureSettings(selectedScene.captureSettings)
            setSelectedLocalities(selectedScene.localityIds.map((id) => localities.find((l) => l.id === id)!))
            setAssignedDevice(sceneDevices.find((d) => d.id === selectedScene.deviceId))
        } else {
            if (isNewScene) {
                handleSceneSelection()
            }
            setSceneData(defaultSceneData)
            setCaptureSettings(defaultCaptureSettings)
            setSelectedLocalities(locality ? [locality] : [])
            setAssignedDevice(undefined)
        }

        setEdited(false)
    }

    const onSave = async () => {
        if (Object.values(errors).some((it) => it.isInvalid)) {
            return
        }

        if (!isNewScene && selectedScene === undefined) {
            return
        }

        try {
            const sceneId = await (async () => {
                if (isNewScene) {
                    const response = await createScene({
                        body: {
                            label: sceneData.label,
                            note: sceneData.note || undefined,
                            organizationId: organization.id,
                        },
                    })

                    return response.id
                }

                await updateScene({
                    sceneId: selectedScene!.id,
                    body: {
                        label: sceneData.label,
                        note: sceneData.note || (null as any),
                    },
                })

                return selectedScene!.id
            })()

            await setSceneLocalities({
                sceneId,
                body: { localities: pickColumn(selectedLocalities, 'id') },
            })

            await updateCaptureSettings({ sceneId, body: captureSettings })

            if (selectedScene?.deviceId !== assignedDevice?.id) {
                if (selectedScene?.deviceId !== undefined) {
                    await unassignDevice({ sceneId })
                }

                if (assignedDevice !== undefined) {
                    await assignDevice({ sceneId, body: { deviceId: assignedDevice.id } })
                }
            }

            apiCleaner(organizationApi)
            apiCleaner(localityApi)
            apiCleaner(sceneApi)

            history.push(determineEditScenePath(sceneId) ?? VIVIDI_APP)
        } catch (e) {
            notify({
                title: t('notification.error', 'Error'),
                content: t('notification.sceneUpdateFailed', 'Something went wrong. Scene update failed.'),
                variant: 'danger',
                timeoutSeconds: 4,
            })
        }
    }

    const handleSceneSelection = (scene?: SceneResponse, forceReplace = false) => {
        const scenePath = determineEditScenePath(scene?.id)

        const url = search && scenePath ? `${scenePath}?search=${search}` : scenePath ?? VIVIDI_APP

        if (selectedScene || forceReplace) {
            history.replace(url)
        } else {
            history.push(url)
        }
    }

    useEffect(() => {
        if (isTabActive && !mobile && selectedScene === undefined && !isEmpty(selectableScenes) && !isNewScene) {
            handleSceneSelection(
                selectableScenes.find(({ id }) => Number(sortedNavigationEntries[0].id) === id),
                true
            )
        }
    }, [selectedScene, isNewScene, mobile, isTabActive, selectableScenes, sortedNavigationEntries])

    const actionButtons =
        selectedScene !== undefined ? (
            <>
                {describeSceneModal}
                {selectedScene &&
                    (selectedScene.deviceId ? (
                        <OverlayTrigger
                            overlay={
                                <Popover id={id}>
                                    <Popover.Content>
                                        {t(
                                            'others.deleteSceneExplanation',
                                            'Unassign the device from the scene to enable scene deletion'
                                        )}
                                    </Popover.Content>
                                </Popover>
                            }
                            placement="top"
                            trigger={['hover', 'focus']}
                        >
                            <Button
                                className={styles.disabledDeleteSceneButton}
                                variant="danger secondary"
                                onClick={noop}
                            >
                                <FontAwesomeIcon icon={faTrash} />
                                {t('button.deleteScene', 'Delete scene')}
                            </Button>
                        </OverlayTrigger>
                    ) : (
                        <PopConfirm
                            cancelButtonVariant="secondary"
                            confirmButtonVariant="danger"
                            confirmMessage={t(
                                'others.deleteSceneConfirmation',
                                'Are you sure you want to delete scene {{value}}?',
                                { value: selectedScene.label }
                            )}
                            onConfirm={handleSceneDeletion(selectedScene)}
                        >
                            <Button variant="danger secondary">
                                <FontAwesomeIcon icon={faTrash} />
                                {t('button.deleteScene', 'Delete scene')}
                            </Button>
                        </PopConfirm>
                    ))}
            </>
        ) : undefined

    const saveButton = (
        <AsyncButton
            disabled={errors.label.isInvalid}
            loadingText={t('button.savingChanges', 'Saving changes...')}
            status="idle"
            text={!isNewScene ? t('button.saveChanges', 'Save changes') : t('button.createScene', 'Create scene')}
            type="submit"
            variant="primary"
            onClick={(e) => {
                e.preventDefault()
                onSave()
            }}
        />
    )

    const cancelButton = (
        <Button variant="secondary" onClick={onCancel}>
            {t('button.cancel', 'Cancel')}
        </Button>
    )

    const addSceneButton = (
        <Button as={Link} to={determineCreateScenePath()} variant="secondary">
            <FontAwesomeIcon icon={faPlus} />
            {t('others.addScene', 'Add scene')}
        </Button>
    )

    const placeholder = (
        <div className={styles.noItemsCreatedContainer}>
            <span className={styles.noItemsCreated}>{t('others.noScenesCreated', 'No scenes created')}</span>
        </div>
    )

    const handleCaptureSettingsChange = (captureSettings: CaptureSettings) => {
        setCaptureSettings(captureSettings)
        setEdited(true)
    }

    const handleLocalitiesChange = (localities: Array<LocalityNameModel>) => {
        setSelectedLocalities(localities)
        setEdited(true)
    }

    const handleSceneChange = (scene: SceneData) => {
        setSceneData(scene)
        setErrors({ label: { isTouched: true, isInvalid: isEmpty(scene.label) } })
        setEdited(true)
    }

    const handleDeviceAssignment = (device?: DeviceResponse) => {
        setAssignedDevice(device)
        setEdited(true)
    }

    const unassignedDevices = assignableDevices.filter(
        ({ id }) => !selectableScenes.find(({ deviceId }) => deviceId === id)
    )

    const form =
        selectedScene && sceneData ? (
            <SceneEditForm
                key={`form-${selectedScene?.id}`}
                actionButtons={
                    mobile ? (
                        <>
                            {isEdited || isNewScene ? (
                                <div className={styles.createButtonContainer}>
                                    {saveButton}
                                    {cancelButton}
                                    {actionButtons}
                                </div>
                            ) : (
                                actionButtons
                            )}
                        </>
                    ) : (
                        actionButtons
                    )
                }
                assignableDevices={unassignedDevices}
                assignedDevice={assignedDevice}
                captureSettings={captureSettings}
                errors={errors}
                localities={localities}
                localityPickerTokenRenderer={LocalityTokenWithLink}
                organizationId={organization.id}
                sceneData={sceneData}
                selectedLocalities={selectedLocalities}
                selectedScene={selectedScene}
                onAssignDevice={handleDeviceAssignment}
                onCaptureSettingsChange={handleCaptureSettingsChange}
                onLocalitiesChange={handleLocalitiesChange}
                onSceneChange={handleSceneChange}
                onUnassignDevice={() => handleDeviceAssignment(undefined)}
            />
        ) : (
            <></>
        )

    const newSceneToLocalityForm = (
        <AddSceneForm
            actionButtons={
                <div className={styles.createButtonContainer}>
                    {mobile && (
                        <div className={styles.createButtonContainer}>
                            {saveButton}
                            {cancelButton}
                        </div>
                    )}
                </div>
            }
            captureSettings={captureSettings}
            errors={errors}
            localities={localities}
            localityPickerTokenRenderer={LocalityTokenWithLink}
            sceneData={sceneData}
            selectedLocalities={selectedLocalities}
            onCaptureSettingsChange={handleCaptureSettingsChange}
            onLocalitiesChange={handleLocalitiesChange}
            onSceneChange={handleSceneChange}
        />
    )

    const newSceneForm: JSX.Element = isNewScene ? newSceneToLocalityForm : form

    const desktopNavigation = (
        <VerticalListGroup
            placeholder={t('form.sceneName', 'Name')}
            search={search}
            selectableEntries={sortedNavigationEntries}
            selectedEntry={isNewScene ? NEW_ITEM_ID : sceneId?.toString()}
            unfilteredSelectableEntries={unfilteredNavigationEntries}
            scrollToSelected
            onSearch={handleSearch}
            onSelectionChange={(id) => handleSceneSelection(filteredScenes.find((scene) => id === scene.id.toString()))}
        />
    )

    return (
        <Box paddingSize="lg">
            {mobile ? (
                <AccordionLayout
                    heading={t('table.scenes', 'Scenes')!}
                    headingButtons={<>{addSceneButton}</>}
                    items={sortedNavigationEntries}
                    placeholder={placeholder}
                    selectedItem={isNewScene ? NEW_ITEM_ID : selectedScene?.id.toString()}
                    selectedItemContent={newSceneForm}
                    subHeading={
                        <InstallerUI
                            determineAssignDevicePath={determineAssignDevicePath}
                            devicesWithNoScene={assignableDevices.filter(
                                ({ id }) => !selectableScenes.find(({ deviceId }) => deviceId === id)
                            )}
                            devicesWithScene={assignableDevices.filter(({ id }) =>
                                selectableScenes.find(({ deviceId }) => deviceId === id)
                            )}
                            handleCloseModal={handleCloseInstallerUIModal}
                            handleOpenModal={handleOpenInstallerUIModal}
                            handleSceneSelect={handleInstallerUIModalSceneSelect}
                            installerUIView={installerUIView}
                            isInstallerUIModalOpen={isInstallerUIOpen}
                            organizationId={organization.id}
                            scenesWithNoDevice={selectableScenes.filter(({ deviceId }) => !deviceId)}
                            visibleDevices={visibleDevices}
                        />
                    }
                    onSelect={(id) =>
                        handleSceneSelection(selectableScenes.find((device) => id === device.id.toString()))
                    }
                />
            ) : (
                <SideNavigationListLayout
                    content={newSceneForm}
                    contentHeading={{
                        heading: !hasScenes && !isNewScene ? placeholder : '',
                        headingButtons: (
                            <>
                                {(isEdited || isNewScene) && (
                                    <div className={styles.controlButtonRow}>
                                        {saveButton}
                                        {cancelButton}
                                    </div>
                                )}
                            </>
                        ),
                    }}
                    listHeading={{
                        heading: t('table.scenes', 'Scenes'),
                        headingButtons: addSceneButton,
                    }}
                    navigation={desktopNavigation}
                />
            )}
        </Box>
    )
}

export default ScenesTab
