import { head, max, maxBy, mean, minBy, round } from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { DatetimeInterval, SceneListResponse } from '@api/models'

import { generateOrganizationScenesPath } from '@helpers/VividiURLs'
import { useAddBackLink } from '@helpers/backlinks'
import { displayScene } from '@helpers/displayUtils'
import { useTimezoneConfig } from '@helpers/timezoneConfig'

import TableWithSorting from '@elements/Table/TableWithSorting'

import AdminOnlyLinkRenderer from '@components/AdminOnlyLinkRenderer'
import { displayDateTimes } from '@components/StatisticsSummary/utils'
import { parseDate } from '@components/plots/common'

interface OccupancyDetailsTableProps {
    activeZoneRows: {
        sceneId: number
        zoneName: string
        zoneId: number
        cells: number[]
    }[]
    groupedOccupancyData: {
        cells: { [date: string]: number }
        sceneId: number
        zoneName: string
        zoneId: number
    }[]
    timeBrackets: DatetimeInterval[]
    scenes: SceneListResponse
}

type OccupancyBreakDownData = Array<{
    sceneName: string
    zoneName: string
    occupancyBreakdown: {
        averageSeatOccupancy: number
        maximalSeatOccupancy: number
        theMostBusyDay: string
        theLeastBusyDay: string
        busiestHour: string
    }
}>

const OccupancyDetailsBreakdownTable: React.FC<OccupancyDetailsTableProps> = ({
    groupedOccupancyData,
    activeZoneRows,
    timeBrackets,
    scenes,
}) => {
    const { t } = useTranslation()

    const timeZone = useTimezoneConfig()
    const addBackLink = useAddBackLink()
    const organizationId = head(scenes.scenes)?.organizationId ?? -1

    const occupancyBreakdown: OccupancyBreakDownData = activeZoneRows.map((zone) => {
        const groupedZoneData = groupedOccupancyData.find((it) => it.zoneId === zone.zoneId)?.cells

        const scene = scenes.scenes.find((d) => d.id === zone.sceneId)

        const zoneData = activeZoneRows
            .map((it) => ({
                ...it,
                cells: it.cells.map((entry, index) => ({
                    value: entry,
                    hour: parseDate(timeBrackets[index].startingFrom).setZone(timeZone).toFormat('HH:00'),
                })),
            }))
            .find((it) => it.zoneId === zone.zoneId)?.cells

        if (!groupedZoneData || !zoneData) {
            return {
                sceneName: scene ? displayScene(scene) : '',
                zoneName: zone.zoneName,
                occupancyBreakdown: {
                    averageSeatOccupancy: 0,
                    maximalSeatOccupancy: 0,
                    theMostBusyDay: 'N/A',
                    theLeastBusyDay: 'N/A',
                    busiestHour: 'N/A',
                },
            }
        }

        const theMostBusyDay = maxBy(Object.entries(groupedZoneData), (it) => it[1])?.[0]
        const theLeastBusyDay = minBy(Object.entries(groupedZoneData), (it) => it[1])?.[0]

        const busiestHour = maxBy(zoneData, (it) => it.value)?.hour

        return {
            sceneName: scene ? displayScene(scene) : '',
            zoneName: zone.zoneName,
            occupancyBreakdown: {
                averageSeatOccupancy: groupedZoneData ? round(mean(Object.values(groupedZoneData))) : 0,
                maximalSeatOccupancy: groupedZoneData ? round(max(Object.values(groupedZoneData)) ?? 0) : 0,
                theMostBusyDay: theMostBusyDay
                    ? `${displayDateTimes({
                          date: parseDate(theMostBusyDay),
                          dateStyle: 'short',
                      })}, ${parseDate(theMostBusyDay).weekdayShort}`
                    : 'N/A',
                theLeastBusyDay: theLeastBusyDay
                    ? `${displayDateTimes({
                          date: parseDate(theLeastBusyDay),
                          dateStyle: 'short',
                      })}, ${parseDate(theLeastBusyDay).weekdayShort}`
                    : 'N/A',
                busiestHour: busiestHour ? busiestHour : 'N/A',
            },
        }
    })

    return (
        <TableWithSorting
            bodyRows={occupancyBreakdown.map((zone) => ({
                device: {
                    content: zone.sceneName,
                    cellType: 'text',
                    targetLink: addBackLink(
                        generateOrganizationScenesPath(
                            organizationId,
                            scenes.scenes.find((s) => s.label === zone.sceneName)?.id ?? 0
                        )
                    ),
                    renderer: AdminOnlyLinkRenderer,
                },
                zone: {
                    content: zone.zoneName,
                    cellType: 'text',
                },
                averageSeatOccupancy: {
                    content: zone.occupancyBreakdown.averageSeatOccupancy,
                    cellType: 'numeric',
                    numberFormat: 'time',
                },
                maximalSeatOccupancy: {
                    content: zone.occupancyBreakdown.maximalSeatOccupancy,
                    cellType: 'numeric',
                    numberFormat: 'time',
                },
                theMostBusyDay: {
                    content: zone.occupancyBreakdown.theMostBusyDay,
                    cellType: 'text',
                    align: 'center',
                },
                theLeastBusyDay: {
                    content: zone.occupancyBreakdown.theLeastBusyDay,
                    cellType: 'text',
                    align: 'center',
                },
                busiestHour: {
                    content: zone.occupancyBreakdown.busiestHour,
                    cellType: 'text',
                    align: 'center',
                },
            }))}
            defaultSortingColumn="averageSeatOccupancy"
            defaultSortingOrder="desc"
            headRow={[
                { name: 'device', displayName: t('table.device', 'Device') },
                { name: 'zone', displayName: t('table.zone', 'Zone') },
                {
                    name: 'averageSeatOccupancy',
                    displayName: t('table.avgDailyOccupancy', 'Avg. daily occupancy'),
                    align: 'right',
                },
                {
                    name: 'maximalSeatOccupancy',
                    displayName: t('table.maxSeatOccupancy', 'Max. seat occupancy'),
                    align: 'right',
                },
                {
                    name: 'theMostBusyDay',
                    displayName: t('table.busiestDay', 'Busiest day'),
                    align: 'center',
                },
                {
                    name: 'theLeastBusyDay',
                    displayName: t('table.leastBusyDay', 'Least busy day'),
                    align: 'center',
                },
                {
                    name: 'busiestHour',
                    displayName: t('table.busiestHour', 'Busiest hour'),
                    align: 'center',
                },
            ]}
            paginationSize={15}
        />
    )
}

export default OccupancyDetailsBreakdownTable
