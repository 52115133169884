import classNames from 'classnames'
import { noop } from 'lodash'
import React from 'react'
import { Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import { SceneResponse } from '@api'

import { Point } from '@api/models/Point'

import { edges } from '@helpers/points'
import { Size } from '@helpers/types'

import { useDetectMobile, useDetectTablet } from '@hooks/useDetectDevice'

import styles from './ConfigurationHeatmapCarousel.module.scss'
import BoundaryDirectionArrow from './DeviceSceneConfiguration/BoundaryDirectionArrow'

interface OverlayedBoundaryHeatmapProps {
    boundary: {
        points: Point[]
        name: string
        id: number
    }
    drawBoundaryDirection: boolean
    overlayedBoundaryHeader?: JSX.Element
    dimensions: Size
    src: string
    scene: SceneResponse
    onSelect?: () => void
    scale?: number
    isOpen?: boolean
}

const OverlayedBoundaryHeatmap: React.FC<OverlayedBoundaryHeatmapProps> = ({
    boundary,
    dimensions,
    src,
    scene,
    onSelect,
    isOpen,
    overlayedBoundaryHeader,
    drawBoundaryDirection,
}) => {
    const { t } = useTranslation()

    return (
        <div
            className={
                isOpen
                    ? styles.heatmapThumbnailModal
                    : overlayedBoundaryHeader
                    ? styles.heatmapCustomThumbnail
                    : styles.heatmapThumbnail
            }
            onClick={onSelect ?? noop}
        >
            {!overlayedBoundaryHeader && (
                <>
                    <div style={{ maxWidth: dimensions.width }}>
                        <span className={styles.deviceLabel}>
                            <strong>{scene.label}</strong>
                        </span>
                    </div>
                    <span
                        className={classNames({
                            'text-muted': !boundary.name,
                        })}
                    >
                        {boundary.name ? boundary.name : t('others.untitledBoundary', 'Untitled boundary')}
                    </span>
                </>
            )}
            {overlayedBoundaryHeader && (
                <>
                    <div style={{ maxWidth: dimensions.width }}>{overlayedBoundaryHeader}</div>
                </>
            )}
            <div className={styles.heatmapOverlayContainer}>
                <div>
                    <img
                        alt=""
                        className={styles.heatmapImage}
                        src={src}
                        style={{
                            width: dimensions.width,
                            height: dimensions.height,
                        }}
                    />
                </div>
                <div>
                    <svg height={dimensions.height} width={dimensions.width}>
                        <g>
                            {edges(boundary.points).map((segment: [Point, Point], edgeIndex: number) => {
                                if (edgeIndex === boundary.points.length - 1) {
                                    return null
                                }

                                const [a, b] = segment

                                return (
                                    <React.Fragment key={edgeIndex}>
                                        <line className={styles.boundaryLine} x1={a.x} x2={b.x} y1={a.y} y2={b.y} />
                                    </React.Fragment>
                                )
                            })}
                        </g>
                        {drawBoundaryDirection && (
                            <BoundaryDirectionArrow
                                className={styles.arrow}
                                points={boundary.points}
                                scale={dimensions.width * 0.003}
                            />
                        )}
                    </svg>
                </div>
            </div>
        </div>
    )
}

export const OverlayedBoundaryHeatmapWithModal: React.FC<OverlayedBoundaryHeatmapProps> = ({
    boundary,
    dimensions,
    src,
    scene,
    scale,
    overlayedBoundaryHeader,
    drawBoundaryDirection,
}) => {
    const scales = {
        tablet: 0.4,
        desktop: 0.7,
    }

    const mobile = useDetectMobile()
    const tablet = useDetectTablet()

    const [isOpen, setIsOpen] = React.useState(false)

    const handleOpenModal = () => {
        setIsOpen(true)
    }

    const effectiveScale = tablet ? scales.tablet : scales.desktop

    return (
        <React.Fragment>
            <Modal dialogClassName={styles.heatmapImageModal} show={isOpen} onHide={() => setIsOpen(false)}>
                <Modal.Body>
                    {/* rescale programmatically for modal based on media queries*/}
                    <OverlayedBoundaryHeatmap
                        boundary={{
                            ...boundary,
                            points: boundary.points.map((p) => ({
                                x: mobile ? p.x : (p.x / (scale ?? 1)) * effectiveScale,
                                y: mobile ? p.y : (p.y / (scale ?? 1)) * effectiveScale,
                            })),
                        }}
                        dimensions={{
                            width: mobile ? dimensions.width : (dimensions.width / (scale ?? 1)) * effectiveScale,
                            height: mobile ? dimensions.height : (dimensions.height / (scale ?? 1)) * effectiveScale,
                        }}
                        drawBoundaryDirection={drawBoundaryDirection}
                        isOpen={true}
                        scene={scene}
                        src={src}
                    />
                </Modal.Body>
            </Modal>
            <OverlayedBoundaryHeatmap
                key={boundary.id}
                boundary={boundary}
                dimensions={dimensions}
                drawBoundaryDirection={drawBoundaryDirection}
                isOpen={false}
                overlayedBoundaryHeader={overlayedBoundaryHeader}
                scene={scene}
                src={src}
                onSelect={handleOpenModal}
            />
        </React.Fragment>
    )
}
