import { TFunction } from 'react-i18next'

import { ReportFrequency, Role, WeatherDescription } from '@api/models'

import { DaysInWeekShortened } from '@components/OpeningHoursPicker/OpeningHoursPicker'

import { IntervalType } from './types'

export const displayTranslatedRole = (role: Role, t: TFunction): string => {
    switch (role) {
        case Role.Administrator:
            return t('role.vividiAdministrator', 'Vividi Administrator')
        case Role.OrganizationOwner:
            return t('role.organizationOwner', 'Organization owner')
        case Role.OrganizationAdministrator:
            return t('role.organizationAdministrator', 'Organization administrator')
        case Role.User:
            return t('role.regularUser', 'Regular user')
    }
}

export const displayTranslatedWeather = (weather: WeatherDescription, t: TFunction): string => {
    switch (weather) {
        case WeatherDescription.Fog:
            return t('weather.fog', 'Fog')
        case WeatherDescription.Rain:
            return t('weather.rain', 'Rain')
        case WeatherDescription.Snow:
            return t('weather.snow', 'Snow')
        case WeatherDescription.Storm:
            return t('weather.storm', 'Storm')
        case WeatherDescription.Sunny:
            return t('weather.sunny', 'Sunny')
        case WeatherDescription.Cloudy:
            return t('weather.cloudy', 'Cloudy')
        case WeatherDescription.Overcast:
            return t('weather.overcast', 'Overcast')
        case WeatherDescription.HeavyRain:
            return t('weather.heavyRain', 'Heavy rain')
        case WeatherDescription.LightRain:
            return t('weather.lightRain', 'Light rain')
    }
}

export const displayTranslatedDayInWeek = (day: DaysInWeekShortened, t: TFunction): string => {
    switch (day) {
        case DaysInWeekShortened.MONDAY:
            return t('dayInWeek.mon', 'Monday')
        case DaysInWeekShortened.TUESDAY:
            return t('dayInWeek.tue', 'Tuesday')
        case DaysInWeekShortened.WEDNESDAY:
            return t('dayInWeek.wed', 'Wednesday')
        case DaysInWeekShortened.THURSDAY:
            return t('dayInWeek.thu', 'Thursday')
        case DaysInWeekShortened.FRIDAY:
            return t('dayInWeek.fri', 'Friday')
        case DaysInWeekShortened.SATURDAY:
            return t('dayInWeek.sat', 'Saturday')
        case DaysInWeekShortened.SUNDAY:
            return t('dayInWeek.sun', 'Sunday')
    }
}

export const displayTranslatedGranularity = (granularity: IntervalType, t: TFunction): string => {
    switch (granularity) {
        case IntervalType.HOUR:
            return t('others.hour', 'hour')
        case IntervalType.DAY:
            return t('others.day', 'day')
        case IntervalType.WEEK:
            return t('others.week', 'week')
        case IntervalType.MONTH:
            return t('others.month', 'month')
        case IntervalType.CUSTOM:
            return t('others.customGranularity', 'custom')
    }
}

export const displayTranslatedGenerationFrequency = (frequency: ReportFrequency, t: TFunction): string => {
    switch (frequency) {
        case ReportFrequency.Weekly:
            return t('others.weekly', 'weekly')
        case ReportFrequency.Monthly:
            return t('others.monthly', 'monthly')
    }
}
