import React from 'react'
import { Form } from 'react-bootstrap'

import { Possibly } from '@helpers/types'

type Props = Omit<
    React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
    'onChange' | 'value'
> & {
    value?: number
    onChange: (value?: number) => void
    isInvalid?: boolean
    disabled?: boolean
    maxDigits?: number
    className?: string
} & Possibly<{
        step?: number
        allowDecimals?: boolean
    }>

const NumberInput = ({
    isInvalid,
    value,
    onChange,
    placeholder,
    id,
    disabled = false,
    maxDigits,
    className,
    step = 1,
    allowDecimals,
}: Props) => {
    const handleChange = (value: string) => {
        if (allowDecimals) {
            const number = parseFloat(value)

            if (isNaN(number) || number < 0) {
                onChange(undefined)

                return
            }

            onChange(number)

            return
        }

        const num = Number.parseInt(value, 10)

        if (isNaN(num)) {
            onChange(undefined)

            return
        }

        if (maxDigits && value.length > maxDigits) {
            const newValue = value.substring(0, maxDigits)

            onChange(Number.parseInt(newValue, 10))

            return
        }

        onChange(num)
    }

    return (
        <Form.Control
            className={className}
            disabled={disabled}
            id={id}
            isInvalid={isInvalid}
            placeholder={placeholder}
            step={step}
            type="number"
            value={value ?? ''}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e.target.value)}
        />
    )
}
export default NumberInput
