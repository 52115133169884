import React, { useMemo } from 'react'

import LegacyLoadingWrapper, { Props as LoadingWrapperProps } from './LegacyLoadingWrapper'

interface Props extends Omit<LoadingWrapperProps<Blob>, 'children'> {
    children: React.ReactElement<{ src: string }>
}

const ImageLoadingWrapper: React.FC<Props> = (props) => {
    const src = useMemo(
        () => (props.request.data ? window.URL.createObjectURL(props.request.data) : ''),
        [props.request.data]
    )

    return <LegacyLoadingWrapper {...props}>{() => React.cloneElement(props.children, { src })}</LegacyLoadingWrapper>
}

export default ImageLoadingWrapper
