/* tslint:disable */
/* eslint-disable */
/**
 * Vividi Dashboard API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DetectionZone,
    DetectionZoneFromJSON,
    DetectionZoneFromJSONTyped,
    DetectionZoneToJSON,
} from './DetectionZone';
import {
    VisitBoundary,
    VisitBoundaryFromJSON,
    VisitBoundaryFromJSONTyped,
    VisitBoundaryToJSON,
} from './VisitBoundary';

/**
 * 
 * @export
 * @interface UpdateSceneDescriptionRequestBody
 */
export interface UpdateSceneDescriptionRequestBody {
    /**
     * 
     * @type {Array<DetectionZone>}
     * @memberof UpdateSceneDescriptionRequestBody
     */
    detectionZones?: Array<DetectionZone>;
    /**
     * 
     * @type {Array<DetectionZone>}
     * @memberof UpdateSceneDescriptionRequestBody
     */
    stopZones?: Array<DetectionZone>;
    /**
     * 
     * @type {Array<VisitBoundary>}
     * @memberof UpdateSceneDescriptionRequestBody
     */
    visitBoundaries?: Array<VisitBoundary>;
}

export function UpdateSceneDescriptionRequestBodyFromJSON(json: any): UpdateSceneDescriptionRequestBody {
    return UpdateSceneDescriptionRequestBodyFromJSONTyped(json, false);
}

export function UpdateSceneDescriptionRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateSceneDescriptionRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'detectionZones': !exists(json, 'detection_zones') ? undefined : ((json['detection_zones'] as Array<any>).map(DetectionZoneFromJSON)),
        'stopZones': !exists(json, 'stop_zones') ? undefined : ((json['stop_zones'] as Array<any>).map(DetectionZoneFromJSON)),
        'visitBoundaries': !exists(json, 'visit_boundaries') ? undefined : ((json['visit_boundaries'] as Array<any>).map(VisitBoundaryFromJSON)),
    };
}

export function UpdateSceneDescriptionRequestBodyToJSON(value?: UpdateSceneDescriptionRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'detection_zones': value.detectionZones === undefined ? undefined : ((value.detectionZones as Array<any>).map(DetectionZoneToJSON)),
        'stop_zones': value.stopZones === undefined ? undefined : ((value.stopZones as Array<any>).map(DetectionZoneToJSON)),
        'visit_boundaries': value.visitBoundaries === undefined ? undefined : ((value.visitBoundaries as Array<any>).map(VisitBoundaryToJSON)),
    };
}

