/**
 * Vividi Dashboard API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime'
import {UseQueryOptions, QueryKey} from 'react-query'

import {
    ErrorResponse,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    FloorplanListResponse,
    FloorplanListResponseFromJSON,
    FloorplanListResponseToJSON,
    LocalityConfigurationStateListResponse,
    LocalityConfigurationStateListResponseFromJSON,
    LocalityConfigurationStateListResponseToJSON,
    LocalityFloorplanDetails,
    LocalityFloorplanDetailsFromJSON,
    LocalityFloorplanDetailsToJSON,
    LocalityFloorplanMappingList,
    LocalityFloorplanMappingListFromJSON,
    LocalityFloorplanMappingListToJSON,
    LocalityModel,
    LocalityModelFromJSON,
    LocalityModelToJSON,
    LocalityNameListResponse,
    LocalityNameListResponseFromJSON,
    LocalityNameListResponseToJSON,
    LocalityOpeningHoursListResponse,
    LocalityOpeningHoursListResponseFromJSON,
    LocalityOpeningHoursListResponseToJSON,
    LocalityResponse,
    LocalityResponseFromJSON,
    LocalityResponseToJSON,
    SceneIdListModel,
    SceneIdListModelFromJSON,
    SceneIdListModelToJSON,
    SceneListResponse,
    SceneListResponseFromJSON,
    SceneListResponseToJSON,
    SetLocalityCapacityRequestBody,
    SetLocalityCapacityRequestBodyFromJSON,
    SetLocalityCapacityRequestBodyToJSON,
    SetLocalityCrowdSizeRequestBody,
    SetLocalityCrowdSizeRequestBodyFromJSON,
    SetLocalityCrowdSizeRequestBodyToJSON,
    SuccessResponse,
    SuccessResponseFromJSON,
    SuccessResponseToJSON,
    UserIdListModel,
    UserIdListModelFromJSON,
    UserIdListModelToJSON,
    UserListResponse,
    UserListResponseFromJSON,
    UserListResponseToJSON,
} from '../models';

export interface AddLocalityRequest {
    organizationId: number;
    body: LocalityModel;
}

export interface AddLocalityFloorplanRequest {
    organizationId: number;
    localityId: number;
    fileName: string;
    body?: Blob;
}

export interface DeleteLocalityRequest {
    organizationId: number;
    localityId: number;
}

export interface GetLocalityRequest {
    localityId: number;
}

export interface GetLocalityFloorplanRequest {
    organizationId: number;
    localityId: number;
    floorplanId: number;
}

export interface GetLocalityFloorplanDetailsRequest {
    organizationId: number;
    localityId: number;
    floorplanId: number;
}

export interface GetLocalityFloorplanMappingsRequest {
    organizationId: number;
    localityId: number;
    floorplanId: number;
}

export interface GetLocalityFloorplansRequest {
    organizationId: number;
    localityId: number;
}

export interface GetLocalityScenesRequest {
    localityId: number;
}

export interface GetLocalityUsersRequest {
    organizationId: number;
    localityId: number;
}

export interface RemoveLocalityFloorplanRequest {
    organizationId: number;
    localityId: number;
    floorplanId: number;
}

export interface SetLocalityCapacityRequest {
    organizationId: number;
    localityId: number;
    body: SetLocalityCapacityRequestBody;
}

export interface SetLocalityCrowdSizeRequest {
    organizationId: number;
    localityId: number;
    body: SetLocalityCrowdSizeRequestBody;
}

export interface SetLocalityScenesRequest {
    localityId: number;
    body: SceneIdListModel;
}

export interface SetLocalityUsersRequest {
    organizationId: number;
    localityId: number;
    body: UserIdListModel;
}

export interface UpdateLocalityRequest {
    organizationId: number;
    localityId: number;
    body: LocalityModel;
}

export interface UpdateLocalityFloorplanDetailsRequest {
    organizationId: number;
    localityId: number;
    floorplanId: number;
    body: LocalityFloorplanDetails;
}

export interface UpdateLocalityFloorplanImageRequest {
    organizationId: number;
    localityId: number;
    floorplanId: number;
}

export interface UpdateLocalityFloorplanMappingsRequest {
    organizationId: number;
    localityId: number;
    floorplanId: number;
    body: LocalityFloorplanMappingList;
}


/**
 * 
 */
export class LocalitiesApi extends runtime.BaseAPI {
    readonly name = 'LocalitiesApi'

    /**
     * Add a new locality to an organization.
     */
    addLocality = Object.assign(
        async (requestParameters: AddLocalityRequest, initOverrides?: RequestInit): Promise<LocalityResponse> => {
            const response = await this.addLocality.invokeRaw(requestParameters, initOverrides);
            return await response.value();
        },
        {
            invokeRaw: async (requestParameters: AddLocalityRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<LocalityResponse>> => {
                if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
                    throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling addLocality.');
                }
            
                if (requestParameters.body === null || requestParameters.body === undefined) {
                    throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling addLocality.');
                }
            
                const queryParameters: any = {};
            
                const headerParameters: runtime.HTTPHeaders = {};
            
                headerParameters['Content-Type'] = 'application/json';
            
                if (this.configuration && this.configuration.accessToken) {
                    const token = this.configuration.accessToken;
                    const tokenString = await token("token", []);
            
                    if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                    }
                }
                const response = await this.request({
                    path: `/api/organizations/{organization_id}/localities/`.replace(`{${"organization_id"}}`, encodeURIComponent(String(requestParameters.organizationId))),
                    method: 'POST',
                    headers: headerParameters,
                    query: queryParameters,
                    body: LocalityModelToJSON(requestParameters.body),
                }, initOverrides);
            
                return new runtime.JSONApiResponse(response, (jsonValue) => LocalityResponseFromJSON(jsonValue));
            },
            mutationKey: [
                'LocalitiesApi',
                'addLocality', 
            ],
            queryKey: (requestParameters: AddLocalityRequest, initOverrides?: RequestInit) => ([
                    ...this.addLocality.mutationKey, 
                {
                organizationId: requestParameters.organizationId,
                body: requestParameters.body,
                }
            ]),
            query: (requestParameters: AddLocalityRequest, initOverrides?: RequestInit): UseQueryOptions<LocalityResponse> & {queryKey: QueryKey} => ({
                queryFn: () => this.addLocality(requestParameters, initOverrides),
                queryKey: this.addLocality.queryKey(requestParameters, initOverrides)
            })
        }
    )

    /**
     */
    addLocalityFloorplan = Object.assign(
        async (requestParameters: AddLocalityFloorplanRequest, initOverrides?: RequestInit): Promise<SuccessResponse> => {
            const response = await this.addLocalityFloorplan.invokeRaw(requestParameters, initOverrides);
            return await response.value();
        },
        {
            invokeRaw: async (requestParameters: AddLocalityFloorplanRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SuccessResponse>> => {
                if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
                    throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling addLocalityFloorplan.');
                }
            
                if (requestParameters.localityId === null || requestParameters.localityId === undefined) {
                    throw new runtime.RequiredError('localityId','Required parameter requestParameters.localityId was null or undefined when calling addLocalityFloorplan.');
                }
            
                if (requestParameters.fileName === null || requestParameters.fileName === undefined) {
                    throw new runtime.RequiredError('fileName','Required parameter requestParameters.fileName was null or undefined when calling addLocalityFloorplan.');
                }
            
                const queryParameters: any = {};
            
                const headerParameters: runtime.HTTPHeaders = {};
            
                headerParameters['Content-Type'] = 'image/png';
            
                if (this.configuration && this.configuration.accessToken) {
                    const token = this.configuration.accessToken;
                    const tokenString = await token("token", []);
            
                    if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                    }
                }
                const response = await this.request({
                    path: `/api/organizations/{organization_id}/localities/{locality_id}/floorplans/{file_name}`.replace(`{${"organization_id"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"locality_id"}}`, encodeURIComponent(String(requestParameters.localityId))).replace(`{${"file_name"}}`, encodeURIComponent(String(requestParameters.fileName))),
                    method: 'POST',
                    headers: headerParameters,
                    query: queryParameters,
                    body: requestParameters.body as any,
                }, initOverrides);
            
                return new runtime.JSONApiResponse(response, (jsonValue) => SuccessResponseFromJSON(jsonValue));
            },
            mutationKey: [
                'LocalitiesApi',
                'addLocalityFloorplan', 
            ],
            queryKey: (requestParameters: AddLocalityFloorplanRequest, initOverrides?: RequestInit) => ([
                    ...this.addLocalityFloorplan.mutationKey, 
                {
                organizationId: requestParameters.organizationId,
                localityId: requestParameters.localityId,
                fileName: requestParameters.fileName,
                body: requestParameters.body,
                }
            ]),
            query: (requestParameters: AddLocalityFloorplanRequest, initOverrides?: RequestInit): UseQueryOptions<SuccessResponse> & {queryKey: QueryKey} => ({
                queryFn: () => this.addLocalityFloorplan(requestParameters, initOverrides),
                queryKey: this.addLocalityFloorplan.queryKey(requestParameters, initOverrides)
            })
        }
    )

    /**
     * Delete a locality.
     */
    deleteLocality = Object.assign(
        async (requestParameters: DeleteLocalityRequest, initOverrides?: RequestInit): Promise<SuccessResponse> => {
            const response = await this.deleteLocality.invokeRaw(requestParameters, initOverrides);
            return await response.value();
        },
        {
            invokeRaw: async (requestParameters: DeleteLocalityRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SuccessResponse>> => {
                if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
                    throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling deleteLocality.');
                }
            
                if (requestParameters.localityId === null || requestParameters.localityId === undefined) {
                    throw new runtime.RequiredError('localityId','Required parameter requestParameters.localityId was null or undefined when calling deleteLocality.');
                }
            
                const queryParameters: any = {};
            
                const headerParameters: runtime.HTTPHeaders = {};
            
                if (this.configuration && this.configuration.accessToken) {
                    const token = this.configuration.accessToken;
                    const tokenString = await token("token", []);
            
                    if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                    }
                }
                const response = await this.request({
                    path: `/api/organizations/{organization_id}/localities/{locality_id}`.replace(`{${"organization_id"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"locality_id"}}`, encodeURIComponent(String(requestParameters.localityId))),
                    method: 'DELETE',
                    headers: headerParameters,
                    query: queryParameters,
                }, initOverrides);
            
                return new runtime.JSONApiResponse(response, (jsonValue) => SuccessResponseFromJSON(jsonValue));
            },
            mutationKey: [
                'LocalitiesApi',
                'deleteLocality', 
            ],
            queryKey: (requestParameters: DeleteLocalityRequest, initOverrides?: RequestInit) => ([
                    ...this.deleteLocality.mutationKey, 
                {
                organizationId: requestParameters.organizationId,
                localityId: requestParameters.localityId,
                }
            ]),
            query: (requestParameters: DeleteLocalityRequest, initOverrides?: RequestInit): UseQueryOptions<SuccessResponse> & {queryKey: QueryKey} => ({
                queryFn: () => this.deleteLocality(requestParameters, initOverrides),
                queryKey: this.deleteLocality.queryKey(requestParameters, initOverrides)
            })
        }
    )

    /**
     * Get details of the specified locality.
     */
    getLocality = Object.assign(
        async (requestParameters: GetLocalityRequest, initOverrides?: RequestInit): Promise<LocalityResponse> => {
            const response = await this.getLocality.invokeRaw(requestParameters, initOverrides);
            return await response.value();
        },
        {
            invokeRaw: async (requestParameters: GetLocalityRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<LocalityResponse>> => {
                if (requestParameters.localityId === null || requestParameters.localityId === undefined) {
                    throw new runtime.RequiredError('localityId','Required parameter requestParameters.localityId was null or undefined when calling getLocality.');
                }
            
                const queryParameters: any = {};
            
                const headerParameters: runtime.HTTPHeaders = {};
            
                if (this.configuration && this.configuration.accessToken) {
                    const token = this.configuration.accessToken;
                    const tokenString = await token("token", []);
            
                    if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                    }
                }
                const response = await this.request({
                    path: `/api/localities/{locality_id}`.replace(`{${"locality_id"}}`, encodeURIComponent(String(requestParameters.localityId))),
                    method: 'GET',
                    headers: headerParameters,
                    query: queryParameters,
                }, initOverrides);
            
                return new runtime.JSONApiResponse(response, (jsonValue) => LocalityResponseFromJSON(jsonValue));
            },
            mutationKey: [
                'LocalitiesApi',
                'getLocality', 
            ],
            queryKey: (requestParameters: GetLocalityRequest, initOverrides?: RequestInit) => ([
                    ...this.getLocality.mutationKey, 
                {
                localityId: requestParameters.localityId,
                }
            ]),
            query: (requestParameters: GetLocalityRequest, initOverrides?: RequestInit): UseQueryOptions<LocalityResponse> & {queryKey: QueryKey} => ({
                queryFn: () => this.getLocality(requestParameters, initOverrides),
                queryKey: this.getLocality.queryKey(requestParameters, initOverrides)
            })
        }
    )

    /**
     * Get a list of module configuration states of localities visible to the requesting user.
     */
    getLocalityConfigurationStates = Object.assign(
        async (initOverrides?: RequestInit): Promise<LocalityConfigurationStateListResponse> => {
            const response = await this.getLocalityConfigurationStates.invokeRaw(initOverrides);
            return await response.value();
        },
        {
            invokeRaw: async (initOverrides?: RequestInit): Promise<runtime.ApiResponse<LocalityConfigurationStateListResponse>> => {
                const queryParameters: any = {};
            
                const headerParameters: runtime.HTTPHeaders = {};
            
                if (this.configuration && this.configuration.accessToken) {
                    const token = this.configuration.accessToken;
                    const tokenString = await token("token", []);
            
                    if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                    }
                }
                const response = await this.request({
                    path: `/api/localities/locality-configuration-states`,
                    method: 'GET',
                    headers: headerParameters,
                    query: queryParameters,
                }, initOverrides);
            
                return new runtime.JSONApiResponse(response, (jsonValue) => LocalityConfigurationStateListResponseFromJSON(jsonValue));
            },
            mutationKey: [
                'LocalitiesApi',
                'getLocalityConfigurationStates', 
            ],
            queryKey: (initOverrides?: RequestInit) => ([
                    ...this.getLocalityConfigurationStates.mutationKey, 
                {
                }
            ]),
            query: (initOverrides?: RequestInit): UseQueryOptions<LocalityConfigurationStateListResponse> & {queryKey: QueryKey} => ({
                queryFn: () => this.getLocalityConfigurationStates(initOverrides),
                queryKey: this.getLocalityConfigurationStates.queryKey(initOverrides)
            })
        }
    )

    /**
     */
    getLocalityFloorplan = Object.assign(
        async (requestParameters: GetLocalityFloorplanRequest, initOverrides?: RequestInit): Promise<Blob> => {
            const response = await this.getLocalityFloorplan.invokeRaw(requestParameters, initOverrides);
            return await response.value();
        },
        {
            invokeRaw: async (requestParameters: GetLocalityFloorplanRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Blob>> => {
                if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
                    throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling getLocalityFloorplan.');
                }
            
                if (requestParameters.localityId === null || requestParameters.localityId === undefined) {
                    throw new runtime.RequiredError('localityId','Required parameter requestParameters.localityId was null or undefined when calling getLocalityFloorplan.');
                }
            
                if (requestParameters.floorplanId === null || requestParameters.floorplanId === undefined) {
                    throw new runtime.RequiredError('floorplanId','Required parameter requestParameters.floorplanId was null or undefined when calling getLocalityFloorplan.');
                }
            
                const queryParameters: any = {};
            
                const headerParameters: runtime.HTTPHeaders = {};
            
                if (this.configuration && this.configuration.accessToken) {
                    const token = this.configuration.accessToken;
                    const tokenString = await token("token", []);
            
                    if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                    }
                }
                const response = await this.request({
                    path: `/api/organizations/{organization_id}/localities/{locality_id}/floorplans/{floorplan_id}`.replace(`{${"organization_id"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"locality_id"}}`, encodeURIComponent(String(requestParameters.localityId))).replace(`{${"floorplan_id"}}`, encodeURIComponent(String(requestParameters.floorplanId))),
                    method: 'GET',
                    headers: headerParameters,
                    query: queryParameters,
                }, initOverrides);
            
                return new runtime.BlobApiResponse(response);
            },
            mutationKey: [
                'LocalitiesApi',
                'getLocalityFloorplan', 
            ],
            queryKey: (requestParameters: GetLocalityFloorplanRequest, initOverrides?: RequestInit) => ([
                    ...this.getLocalityFloorplan.mutationKey, 
                {
                organizationId: requestParameters.organizationId,
                localityId: requestParameters.localityId,
                floorplanId: requestParameters.floorplanId,
                }
            ]),
            query: (requestParameters: GetLocalityFloorplanRequest, initOverrides?: RequestInit): UseQueryOptions<Blob> & {queryKey: QueryKey} => ({
                queryFn: () => this.getLocalityFloorplan(requestParameters, initOverrides),
                queryKey: this.getLocalityFloorplan.queryKey(requestParameters, initOverrides)
            })
        }
    )

    /**
     */
    getLocalityFloorplanDetails = Object.assign(
        async (requestParameters: GetLocalityFloorplanDetailsRequest, initOverrides?: RequestInit): Promise<LocalityFloorplanDetails> => {
            const response = await this.getLocalityFloorplanDetails.invokeRaw(requestParameters, initOverrides);
            return await response.value();
        },
        {
            invokeRaw: async (requestParameters: GetLocalityFloorplanDetailsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<LocalityFloorplanDetails>> => {
                if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
                    throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling getLocalityFloorplanDetails.');
                }
            
                if (requestParameters.localityId === null || requestParameters.localityId === undefined) {
                    throw new runtime.RequiredError('localityId','Required parameter requestParameters.localityId was null or undefined when calling getLocalityFloorplanDetails.');
                }
            
                if (requestParameters.floorplanId === null || requestParameters.floorplanId === undefined) {
                    throw new runtime.RequiredError('floorplanId','Required parameter requestParameters.floorplanId was null or undefined when calling getLocalityFloorplanDetails.');
                }
            
                const queryParameters: any = {};
            
                const headerParameters: runtime.HTTPHeaders = {};
            
                if (this.configuration && this.configuration.accessToken) {
                    const token = this.configuration.accessToken;
                    const tokenString = await token("token", []);
            
                    if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                    }
                }
                const response = await this.request({
                    path: `/api/organizations/{organization_id}/localities/{locality_id}/floorplans/{floorplan_id}/details`.replace(`{${"organization_id"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"locality_id"}}`, encodeURIComponent(String(requestParameters.localityId))).replace(`{${"floorplan_id"}}`, encodeURIComponent(String(requestParameters.floorplanId))),
                    method: 'GET',
                    headers: headerParameters,
                    query: queryParameters,
                }, initOverrides);
            
                return new runtime.JSONApiResponse(response, (jsonValue) => LocalityFloorplanDetailsFromJSON(jsonValue));
            },
            mutationKey: [
                'LocalitiesApi',
                'getLocalityFloorplanDetails', 
            ],
            queryKey: (requestParameters: GetLocalityFloorplanDetailsRequest, initOverrides?: RequestInit) => ([
                    ...this.getLocalityFloorplanDetails.mutationKey, 
                {
                organizationId: requestParameters.organizationId,
                localityId: requestParameters.localityId,
                floorplanId: requestParameters.floorplanId,
                }
            ]),
            query: (requestParameters: GetLocalityFloorplanDetailsRequest, initOverrides?: RequestInit): UseQueryOptions<LocalityFloorplanDetails> & {queryKey: QueryKey} => ({
                queryFn: () => this.getLocalityFloorplanDetails(requestParameters, initOverrides),
                queryKey: this.getLocalityFloorplanDetails.queryKey(requestParameters, initOverrides)
            })
        }
    )

    /**
     */
    getLocalityFloorplanMappings = Object.assign(
        async (requestParameters: GetLocalityFloorplanMappingsRequest, initOverrides?: RequestInit): Promise<LocalityFloorplanMappingList> => {
            const response = await this.getLocalityFloorplanMappings.invokeRaw(requestParameters, initOverrides);
            return await response.value();
        },
        {
            invokeRaw: async (requestParameters: GetLocalityFloorplanMappingsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<LocalityFloorplanMappingList>> => {
                if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
                    throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling getLocalityFloorplanMappings.');
                }
            
                if (requestParameters.localityId === null || requestParameters.localityId === undefined) {
                    throw new runtime.RequiredError('localityId','Required parameter requestParameters.localityId was null or undefined when calling getLocalityFloorplanMappings.');
                }
            
                if (requestParameters.floorplanId === null || requestParameters.floorplanId === undefined) {
                    throw new runtime.RequiredError('floorplanId','Required parameter requestParameters.floorplanId was null or undefined when calling getLocalityFloorplanMappings.');
                }
            
                const queryParameters: any = {};
            
                const headerParameters: runtime.HTTPHeaders = {};
            
                if (this.configuration && this.configuration.accessToken) {
                    const token = this.configuration.accessToken;
                    const tokenString = await token("token", []);
            
                    if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                    }
                }
                const response = await this.request({
                    path: `/api/organizations/{organization_id}/localities/{locality_id}/floorplans/{floorplan_id}/mappings`.replace(`{${"organization_id"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"locality_id"}}`, encodeURIComponent(String(requestParameters.localityId))).replace(`{${"floorplan_id"}}`, encodeURIComponent(String(requestParameters.floorplanId))),
                    method: 'GET',
                    headers: headerParameters,
                    query: queryParameters,
                }, initOverrides);
            
                return new runtime.JSONApiResponse(response, (jsonValue) => LocalityFloorplanMappingListFromJSON(jsonValue));
            },
            mutationKey: [
                'LocalitiesApi',
                'getLocalityFloorplanMappings', 
            ],
            queryKey: (requestParameters: GetLocalityFloorplanMappingsRequest, initOverrides?: RequestInit) => ([
                    ...this.getLocalityFloorplanMappings.mutationKey, 
                {
                organizationId: requestParameters.organizationId,
                localityId: requestParameters.localityId,
                floorplanId: requestParameters.floorplanId,
                }
            ]),
            query: (requestParameters: GetLocalityFloorplanMappingsRequest, initOverrides?: RequestInit): UseQueryOptions<LocalityFloorplanMappingList> & {queryKey: QueryKey} => ({
                queryFn: () => this.getLocalityFloorplanMappings(requestParameters, initOverrides),
                queryKey: this.getLocalityFloorplanMappings.queryKey(requestParameters, initOverrides)
            })
        }
    )

    /**
     * Get a list of floorplans associated with a locality.
     */
    getLocalityFloorplans = Object.assign(
        async (requestParameters: GetLocalityFloorplansRequest, initOverrides?: RequestInit): Promise<FloorplanListResponse> => {
            const response = await this.getLocalityFloorplans.invokeRaw(requestParameters, initOverrides);
            return await response.value();
        },
        {
            invokeRaw: async (requestParameters: GetLocalityFloorplansRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<FloorplanListResponse>> => {
                if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
                    throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling getLocalityFloorplans.');
                }
            
                if (requestParameters.localityId === null || requestParameters.localityId === undefined) {
                    throw new runtime.RequiredError('localityId','Required parameter requestParameters.localityId was null or undefined when calling getLocalityFloorplans.');
                }
            
                const queryParameters: any = {};
            
                const headerParameters: runtime.HTTPHeaders = {};
            
                if (this.configuration && this.configuration.accessToken) {
                    const token = this.configuration.accessToken;
                    const tokenString = await token("token", []);
            
                    if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                    }
                }
                const response = await this.request({
                    path: `/api/organizations/{organization_id}/localities/{locality_id}/floorplans`.replace(`{${"organization_id"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"locality_id"}}`, encodeURIComponent(String(requestParameters.localityId))),
                    method: 'GET',
                    headers: headerParameters,
                    query: queryParameters,
                }, initOverrides);
            
                return new runtime.JSONApiResponse(response, (jsonValue) => FloorplanListResponseFromJSON(jsonValue));
            },
            mutationKey: [
                'LocalitiesApi',
                'getLocalityFloorplans', 
            ],
            queryKey: (requestParameters: GetLocalityFloorplansRequest, initOverrides?: RequestInit) => ([
                    ...this.getLocalityFloorplans.mutationKey, 
                {
                organizationId: requestParameters.organizationId,
                localityId: requestParameters.localityId,
                }
            ]),
            query: (requestParameters: GetLocalityFloorplansRequest, initOverrides?: RequestInit): UseQueryOptions<FloorplanListResponse> & {queryKey: QueryKey} => ({
                queryFn: () => this.getLocalityFloorplans(requestParameters, initOverrides),
                queryKey: this.getLocalityFloorplans.queryKey(requestParameters, initOverrides)
            })
        }
    )

    /**
     * Get a list of names and labels of localities visible to the requesting user.
     */
    getLocalityNames = Object.assign(
        async (initOverrides?: RequestInit): Promise<LocalityNameListResponse> => {
            const response = await this.getLocalityNames.invokeRaw(initOverrides);
            return await response.value();
        },
        {
            invokeRaw: async (initOverrides?: RequestInit): Promise<runtime.ApiResponse<LocalityNameListResponse>> => {
                const queryParameters: any = {};
            
                const headerParameters: runtime.HTTPHeaders = {};
            
                if (this.configuration && this.configuration.accessToken) {
                    const token = this.configuration.accessToken;
                    const tokenString = await token("token", []);
            
                    if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                    }
                }
                const response = await this.request({
                    path: `/api/localities/locality-names`,
                    method: 'GET',
                    headers: headerParameters,
                    query: queryParameters,
                }, initOverrides);
            
                return new runtime.JSONApiResponse(response, (jsonValue) => LocalityNameListResponseFromJSON(jsonValue));
            },
            mutationKey: [
                'LocalitiesApi',
                'getLocalityNames', 
            ],
            queryKey: (initOverrides?: RequestInit) => ([
                    ...this.getLocalityNames.mutationKey, 
                {
                }
            ]),
            query: (initOverrides?: RequestInit): UseQueryOptions<LocalityNameListResponse> & {queryKey: QueryKey} => ({
                queryFn: () => this.getLocalityNames(initOverrides),
                queryKey: this.getLocalityNames.queryKey(initOverrides)
            })
        }
    )

    /**
     * Get a list of opening hours configurations of localities visible to the requesting user.
     */
    getLocalityOpeningHours = Object.assign(
        async (initOverrides?: RequestInit): Promise<LocalityOpeningHoursListResponse> => {
            const response = await this.getLocalityOpeningHours.invokeRaw(initOverrides);
            return await response.value();
        },
        {
            invokeRaw: async (initOverrides?: RequestInit): Promise<runtime.ApiResponse<LocalityOpeningHoursListResponse>> => {
                const queryParameters: any = {};
            
                const headerParameters: runtime.HTTPHeaders = {};
            
                if (this.configuration && this.configuration.accessToken) {
                    const token = this.configuration.accessToken;
                    const tokenString = await token("token", []);
            
                    if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                    }
                }
                const response = await this.request({
                    path: `/api/localities/locality-opening-hours`,
                    method: 'GET',
                    headers: headerParameters,
                    query: queryParameters,
                }, initOverrides);
            
                return new runtime.JSONApiResponse(response, (jsonValue) => LocalityOpeningHoursListResponseFromJSON(jsonValue));
            },
            mutationKey: [
                'LocalitiesApi',
                'getLocalityOpeningHours', 
            ],
            queryKey: (initOverrides?: RequestInit) => ([
                    ...this.getLocalityOpeningHours.mutationKey, 
                {
                }
            ]),
            query: (initOverrides?: RequestInit): UseQueryOptions<LocalityOpeningHoursListResponse> & {queryKey: QueryKey} => ({
                queryFn: () => this.getLocalityOpeningHours(initOverrides),
                queryKey: this.getLocalityOpeningHours.queryKey(initOverrides)
            })
        }
    )

    /**
     * Get a list of scenes associated with a locality.
     */
    getLocalityScenes = Object.assign(
        async (requestParameters: GetLocalityScenesRequest, initOverrides?: RequestInit): Promise<SceneListResponse> => {
            const response = await this.getLocalityScenes.invokeRaw(requestParameters, initOverrides);
            return await response.value();
        },
        {
            invokeRaw: async (requestParameters: GetLocalityScenesRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SceneListResponse>> => {
                if (requestParameters.localityId === null || requestParameters.localityId === undefined) {
                    throw new runtime.RequiredError('localityId','Required parameter requestParameters.localityId was null or undefined when calling getLocalityScenes.');
                }
            
                const queryParameters: any = {};
            
                const headerParameters: runtime.HTTPHeaders = {};
            
                if (this.configuration && this.configuration.accessToken) {
                    const token = this.configuration.accessToken;
                    const tokenString = await token("token", []);
            
                    if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                    }
                }
                const response = await this.request({
                    path: `/api/localities/{locality_id}/scenes`.replace(`{${"locality_id"}}`, encodeURIComponent(String(requestParameters.localityId))),
                    method: 'GET',
                    headers: headerParameters,
                    query: queryParameters,
                }, initOverrides);
            
                return new runtime.JSONApiResponse(response, (jsonValue) => SceneListResponseFromJSON(jsonValue));
            },
            mutationKey: [
                'LocalitiesApi',
                'getLocalityScenes', 
            ],
            queryKey: (requestParameters: GetLocalityScenesRequest, initOverrides?: RequestInit) => ([
                    ...this.getLocalityScenes.mutationKey, 
                {
                localityId: requestParameters.localityId,
                }
            ]),
            query: (requestParameters: GetLocalityScenesRequest, initOverrides?: RequestInit): UseQueryOptions<SceneListResponse> & {queryKey: QueryKey} => ({
                queryFn: () => this.getLocalityScenes(requestParameters, initOverrides),
                queryKey: this.getLocalityScenes.queryKey(requestParameters, initOverrides)
            })
        }
    )

    /**
     * Get a list of users associated with a locality.
     */
    getLocalityUsers = Object.assign(
        async (requestParameters: GetLocalityUsersRequest, initOverrides?: RequestInit): Promise<UserListResponse> => {
            const response = await this.getLocalityUsers.invokeRaw(requestParameters, initOverrides);
            return await response.value();
        },
        {
            invokeRaw: async (requestParameters: GetLocalityUsersRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<UserListResponse>> => {
                if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
                    throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling getLocalityUsers.');
                }
            
                if (requestParameters.localityId === null || requestParameters.localityId === undefined) {
                    throw new runtime.RequiredError('localityId','Required parameter requestParameters.localityId was null or undefined when calling getLocalityUsers.');
                }
            
                const queryParameters: any = {};
            
                const headerParameters: runtime.HTTPHeaders = {};
            
                if (this.configuration && this.configuration.accessToken) {
                    const token = this.configuration.accessToken;
                    const tokenString = await token("token", []);
            
                    if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                    }
                }
                const response = await this.request({
                    path: `/api/organizations/{organization_id}/localities/{locality_id}/users`.replace(`{${"organization_id"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"locality_id"}}`, encodeURIComponent(String(requestParameters.localityId))),
                    method: 'GET',
                    headers: headerParameters,
                    query: queryParameters,
                }, initOverrides);
            
                return new runtime.JSONApiResponse(response, (jsonValue) => UserListResponseFromJSON(jsonValue));
            },
            mutationKey: [
                'LocalitiesApi',
                'getLocalityUsers', 
            ],
            queryKey: (requestParameters: GetLocalityUsersRequest, initOverrides?: RequestInit) => ([
                    ...this.getLocalityUsers.mutationKey, 
                {
                organizationId: requestParameters.organizationId,
                localityId: requestParameters.localityId,
                }
            ]),
            query: (requestParameters: GetLocalityUsersRequest, initOverrides?: RequestInit): UseQueryOptions<UserListResponse> & {queryKey: QueryKey} => ({
                queryFn: () => this.getLocalityUsers(requestParameters, initOverrides),
                queryKey: this.getLocalityUsers.queryKey(requestParameters, initOverrides)
            })
        }
    )

    /**
     */
    removeLocalityFloorplan = Object.assign(
        async (requestParameters: RemoveLocalityFloorplanRequest, initOverrides?: RequestInit): Promise<SuccessResponse> => {
            const response = await this.removeLocalityFloorplan.invokeRaw(requestParameters, initOverrides);
            return await response.value();
        },
        {
            invokeRaw: async (requestParameters: RemoveLocalityFloorplanRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SuccessResponse>> => {
                if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
                    throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling removeLocalityFloorplan.');
                }
            
                if (requestParameters.localityId === null || requestParameters.localityId === undefined) {
                    throw new runtime.RequiredError('localityId','Required parameter requestParameters.localityId was null or undefined when calling removeLocalityFloorplan.');
                }
            
                if (requestParameters.floorplanId === null || requestParameters.floorplanId === undefined) {
                    throw new runtime.RequiredError('floorplanId','Required parameter requestParameters.floorplanId was null or undefined when calling removeLocalityFloorplan.');
                }
            
                const queryParameters: any = {};
            
                const headerParameters: runtime.HTTPHeaders = {};
            
                if (this.configuration && this.configuration.accessToken) {
                    const token = this.configuration.accessToken;
                    const tokenString = await token("token", []);
            
                    if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                    }
                }
                const response = await this.request({
                    path: `/api/organizations/{organization_id}/localities/{locality_id}/floorplans/{floorplan_id}`.replace(`{${"organization_id"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"locality_id"}}`, encodeURIComponent(String(requestParameters.localityId))).replace(`{${"floorplan_id"}}`, encodeURIComponent(String(requestParameters.floorplanId))),
                    method: 'DELETE',
                    headers: headerParameters,
                    query: queryParameters,
                }, initOverrides);
            
                return new runtime.JSONApiResponse(response, (jsonValue) => SuccessResponseFromJSON(jsonValue));
            },
            mutationKey: [
                'LocalitiesApi',
                'removeLocalityFloorplan', 
            ],
            queryKey: (requestParameters: RemoveLocalityFloorplanRequest, initOverrides?: RequestInit) => ([
                    ...this.removeLocalityFloorplan.mutationKey, 
                {
                organizationId: requestParameters.organizationId,
                localityId: requestParameters.localityId,
                floorplanId: requestParameters.floorplanId,
                }
            ]),
            query: (requestParameters: RemoveLocalityFloorplanRequest, initOverrides?: RequestInit): UseQueryOptions<SuccessResponse> & {queryKey: QueryKey} => ({
                queryFn: () => this.removeLocalityFloorplan(requestParameters, initOverrides),
                queryKey: this.removeLocalityFloorplan.queryKey(requestParameters, initOverrides)
            })
        }
    )

    /**
     * Set the maximum capacity of a locality.
     */
    setLocalityCapacity = Object.assign(
        async (requestParameters: SetLocalityCapacityRequest, initOverrides?: RequestInit): Promise<LocalityResponse> => {
            const response = await this.setLocalityCapacity.invokeRaw(requestParameters, initOverrides);
            return await response.value();
        },
        {
            invokeRaw: async (requestParameters: SetLocalityCapacityRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<LocalityResponse>> => {
                if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
                    throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling setLocalityCapacity.');
                }
            
                if (requestParameters.localityId === null || requestParameters.localityId === undefined) {
                    throw new runtime.RequiredError('localityId','Required parameter requestParameters.localityId was null or undefined when calling setLocalityCapacity.');
                }
            
                if (requestParameters.body === null || requestParameters.body === undefined) {
                    throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling setLocalityCapacity.');
                }
            
                const queryParameters: any = {};
            
                const headerParameters: runtime.HTTPHeaders = {};
            
                headerParameters['Content-Type'] = 'application/json';
            
                if (this.configuration && this.configuration.accessToken) {
                    const token = this.configuration.accessToken;
                    const tokenString = await token("token", []);
            
                    if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                    }
                }
                const response = await this.request({
                    path: `/api/organizations/{organization_id}/localities/{locality_id}/capacity`.replace(`{${"organization_id"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"locality_id"}}`, encodeURIComponent(String(requestParameters.localityId))),
                    method: 'PUT',
                    headers: headerParameters,
                    query: queryParameters,
                    body: SetLocalityCapacityRequestBodyToJSON(requestParameters.body),
                }, initOverrides);
            
                return new runtime.JSONApiResponse(response, (jsonValue) => LocalityResponseFromJSON(jsonValue));
            },
            mutationKey: [
                'LocalitiesApi',
                'setLocalityCapacity', 
            ],
            queryKey: (requestParameters: SetLocalityCapacityRequest, initOverrides?: RequestInit) => ([
                    ...this.setLocalityCapacity.mutationKey, 
                {
                organizationId: requestParameters.organizationId,
                localityId: requestParameters.localityId,
                body: requestParameters.body,
                }
            ]),
            query: (requestParameters: SetLocalityCapacityRequest, initOverrides?: RequestInit): UseQueryOptions<LocalityResponse> & {queryKey: QueryKey} => ({
                queryFn: () => this.setLocalityCapacity(requestParameters, initOverrides),
                queryKey: this.setLocalityCapacity.queryKey(requestParameters, initOverrides)
            })
        }
    )

    /**
     * Set the current crowd size for a locality.
     */
    setLocalityCrowdSize = Object.assign(
        async (requestParameters: SetLocalityCrowdSizeRequest, initOverrides?: RequestInit): Promise<SuccessResponse> => {
            const response = await this.setLocalityCrowdSize.invokeRaw(requestParameters, initOverrides);
            return await response.value();
        },
        {
            invokeRaw: async (requestParameters: SetLocalityCrowdSizeRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SuccessResponse>> => {
                if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
                    throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling setLocalityCrowdSize.');
                }
            
                if (requestParameters.localityId === null || requestParameters.localityId === undefined) {
                    throw new runtime.RequiredError('localityId','Required parameter requestParameters.localityId was null or undefined when calling setLocalityCrowdSize.');
                }
            
                if (requestParameters.body === null || requestParameters.body === undefined) {
                    throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling setLocalityCrowdSize.');
                }
            
                const queryParameters: any = {};
            
                const headerParameters: runtime.HTTPHeaders = {};
            
                headerParameters['Content-Type'] = 'application/json';
            
                if (this.configuration && this.configuration.accessToken) {
                    const token = this.configuration.accessToken;
                    const tokenString = await token("token", []);
            
                    if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                    }
                }
                const response = await this.request({
                    path: `/api/organizations/{organization_id}/localities/{locality_id}/crowd_size`.replace(`{${"organization_id"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"locality_id"}}`, encodeURIComponent(String(requestParameters.localityId))),
                    method: 'PUT',
                    headers: headerParameters,
                    query: queryParameters,
                    body: SetLocalityCrowdSizeRequestBodyToJSON(requestParameters.body),
                }, initOverrides);
            
                return new runtime.JSONApiResponse(response, (jsonValue) => SuccessResponseFromJSON(jsonValue));
            },
            mutationKey: [
                'LocalitiesApi',
                'setLocalityCrowdSize', 
            ],
            queryKey: (requestParameters: SetLocalityCrowdSizeRequest, initOverrides?: RequestInit) => ([
                    ...this.setLocalityCrowdSize.mutationKey, 
                {
                organizationId: requestParameters.organizationId,
                localityId: requestParameters.localityId,
                body: requestParameters.body,
                }
            ]),
            query: (requestParameters: SetLocalityCrowdSizeRequest, initOverrides?: RequestInit): UseQueryOptions<SuccessResponse> & {queryKey: QueryKey} => ({
                queryFn: () => this.setLocalityCrowdSize(requestParameters, initOverrides),
                queryKey: this.setLocalityCrowdSize.queryKey(requestParameters, initOverrides)
            })
        }
    )

    /**
     * Set the scenes in a locality (any scenes that are not included in the supplied list will be removed from the locality).
     */
    setLocalityScenes = Object.assign(
        async (requestParameters: SetLocalityScenesRequest, initOverrides?: RequestInit): Promise<SuccessResponse> => {
            const response = await this.setLocalityScenes.invokeRaw(requestParameters, initOverrides);
            return await response.value();
        },
        {
            invokeRaw: async (requestParameters: SetLocalityScenesRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SuccessResponse>> => {
                if (requestParameters.localityId === null || requestParameters.localityId === undefined) {
                    throw new runtime.RequiredError('localityId','Required parameter requestParameters.localityId was null or undefined when calling setLocalityScenes.');
                }
            
                if (requestParameters.body === null || requestParameters.body === undefined) {
                    throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling setLocalityScenes.');
                }
            
                const queryParameters: any = {};
            
                const headerParameters: runtime.HTTPHeaders = {};
            
                headerParameters['Content-Type'] = 'application/json';
            
                if (this.configuration && this.configuration.accessToken) {
                    const token = this.configuration.accessToken;
                    const tokenString = await token("token", []);
            
                    if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                    }
                }
                const response = await this.request({
                    path: `/api/localities/{locality_id}/scenes`.replace(`{${"locality_id"}}`, encodeURIComponent(String(requestParameters.localityId))),
                    method: 'PUT',
                    headers: headerParameters,
                    query: queryParameters,
                    body: SceneIdListModelToJSON(requestParameters.body),
                }, initOverrides);
            
                return new runtime.JSONApiResponse(response, (jsonValue) => SuccessResponseFromJSON(jsonValue));
            },
            mutationKey: [
                'LocalitiesApi',
                'setLocalityScenes', 
            ],
            queryKey: (requestParameters: SetLocalityScenesRequest, initOverrides?: RequestInit) => ([
                    ...this.setLocalityScenes.mutationKey, 
                {
                localityId: requestParameters.localityId,
                body: requestParameters.body,
                }
            ]),
            query: (requestParameters: SetLocalityScenesRequest, initOverrides?: RequestInit): UseQueryOptions<SuccessResponse> & {queryKey: QueryKey} => ({
                queryFn: () => this.setLocalityScenes(requestParameters, initOverrides),
                queryKey: this.setLocalityScenes.queryKey(requestParameters, initOverrides)
            })
        }
    )

    /**
     * Set the members of a locality (any users that are not included in the supplied list will be removed from the locality).
     */
    setLocalityUsers = Object.assign(
        async (requestParameters: SetLocalityUsersRequest, initOverrides?: RequestInit): Promise<SuccessResponse> => {
            const response = await this.setLocalityUsers.invokeRaw(requestParameters, initOverrides);
            return await response.value();
        },
        {
            invokeRaw: async (requestParameters: SetLocalityUsersRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SuccessResponse>> => {
                if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
                    throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling setLocalityUsers.');
                }
            
                if (requestParameters.localityId === null || requestParameters.localityId === undefined) {
                    throw new runtime.RequiredError('localityId','Required parameter requestParameters.localityId was null or undefined when calling setLocalityUsers.');
                }
            
                if (requestParameters.body === null || requestParameters.body === undefined) {
                    throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling setLocalityUsers.');
                }
            
                const queryParameters: any = {};
            
                const headerParameters: runtime.HTTPHeaders = {};
            
                headerParameters['Content-Type'] = 'application/json';
            
                if (this.configuration && this.configuration.accessToken) {
                    const token = this.configuration.accessToken;
                    const tokenString = await token("token", []);
            
                    if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                    }
                }
                const response = await this.request({
                    path: `/api/organizations/{organization_id}/localities/{locality_id}/users`.replace(`{${"organization_id"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"locality_id"}}`, encodeURIComponent(String(requestParameters.localityId))),
                    method: 'PUT',
                    headers: headerParameters,
                    query: queryParameters,
                    body: UserIdListModelToJSON(requestParameters.body),
                }, initOverrides);
            
                return new runtime.JSONApiResponse(response, (jsonValue) => SuccessResponseFromJSON(jsonValue));
            },
            mutationKey: [
                'LocalitiesApi',
                'setLocalityUsers', 
            ],
            queryKey: (requestParameters: SetLocalityUsersRequest, initOverrides?: RequestInit) => ([
                    ...this.setLocalityUsers.mutationKey, 
                {
                organizationId: requestParameters.organizationId,
                localityId: requestParameters.localityId,
                body: requestParameters.body,
                }
            ]),
            query: (requestParameters: SetLocalityUsersRequest, initOverrides?: RequestInit): UseQueryOptions<SuccessResponse> & {queryKey: QueryKey} => ({
                queryFn: () => this.setLocalityUsers(requestParameters, initOverrides),
                queryKey: this.setLocalityUsers.queryKey(requestParameters, initOverrides)
            })
        }
    )

    /**
     * Update a locality.
     */
    updateLocality = Object.assign(
        async (requestParameters: UpdateLocalityRequest, initOverrides?: RequestInit): Promise<LocalityResponse> => {
            const response = await this.updateLocality.invokeRaw(requestParameters, initOverrides);
            return await response.value();
        },
        {
            invokeRaw: async (requestParameters: UpdateLocalityRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<LocalityResponse>> => {
                if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
                    throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling updateLocality.');
                }
            
                if (requestParameters.localityId === null || requestParameters.localityId === undefined) {
                    throw new runtime.RequiredError('localityId','Required parameter requestParameters.localityId was null or undefined when calling updateLocality.');
                }
            
                if (requestParameters.body === null || requestParameters.body === undefined) {
                    throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling updateLocality.');
                }
            
                const queryParameters: any = {};
            
                const headerParameters: runtime.HTTPHeaders = {};
            
                headerParameters['Content-Type'] = 'application/json';
            
                if (this.configuration && this.configuration.accessToken) {
                    const token = this.configuration.accessToken;
                    const tokenString = await token("token", []);
            
                    if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                    }
                }
                const response = await this.request({
                    path: `/api/organizations/{organization_id}/localities/{locality_id}`.replace(`{${"organization_id"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"locality_id"}}`, encodeURIComponent(String(requestParameters.localityId))),
                    method: 'PUT',
                    headers: headerParameters,
                    query: queryParameters,
                    body: LocalityModelToJSON(requestParameters.body),
                }, initOverrides);
            
                return new runtime.JSONApiResponse(response, (jsonValue) => LocalityResponseFromJSON(jsonValue));
            },
            mutationKey: [
                'LocalitiesApi',
                'updateLocality', 
            ],
            queryKey: (requestParameters: UpdateLocalityRequest, initOverrides?: RequestInit) => ([
                    ...this.updateLocality.mutationKey, 
                {
                organizationId: requestParameters.organizationId,
                localityId: requestParameters.localityId,
                body: requestParameters.body,
                }
            ]),
            query: (requestParameters: UpdateLocalityRequest, initOverrides?: RequestInit): UseQueryOptions<LocalityResponse> & {queryKey: QueryKey} => ({
                queryFn: () => this.updateLocality(requestParameters, initOverrides),
                queryKey: this.updateLocality.queryKey(requestParameters, initOverrides)
            })
        }
    )

    /**
     */
    updateLocalityFloorplanDetails = Object.assign(
        async (requestParameters: UpdateLocalityFloorplanDetailsRequest, initOverrides?: RequestInit): Promise<SuccessResponse> => {
            const response = await this.updateLocalityFloorplanDetails.invokeRaw(requestParameters, initOverrides);
            return await response.value();
        },
        {
            invokeRaw: async (requestParameters: UpdateLocalityFloorplanDetailsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SuccessResponse>> => {
                if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
                    throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling updateLocalityFloorplanDetails.');
                }
            
                if (requestParameters.localityId === null || requestParameters.localityId === undefined) {
                    throw new runtime.RequiredError('localityId','Required parameter requestParameters.localityId was null or undefined when calling updateLocalityFloorplanDetails.');
                }
            
                if (requestParameters.floorplanId === null || requestParameters.floorplanId === undefined) {
                    throw new runtime.RequiredError('floorplanId','Required parameter requestParameters.floorplanId was null or undefined when calling updateLocalityFloorplanDetails.');
                }
            
                if (requestParameters.body === null || requestParameters.body === undefined) {
                    throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling updateLocalityFloorplanDetails.');
                }
            
                const queryParameters: any = {};
            
                const headerParameters: runtime.HTTPHeaders = {};
            
                headerParameters['Content-Type'] = 'application/json';
            
                if (this.configuration && this.configuration.accessToken) {
                    const token = this.configuration.accessToken;
                    const tokenString = await token("token", []);
            
                    if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                    }
                }
                const response = await this.request({
                    path: `/api/organizations/{organization_id}/localities/{locality_id}/floorplans/{floorplan_id}/details`.replace(`{${"organization_id"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"locality_id"}}`, encodeURIComponent(String(requestParameters.localityId))).replace(`{${"floorplan_id"}}`, encodeURIComponent(String(requestParameters.floorplanId))),
                    method: 'PUT',
                    headers: headerParameters,
                    query: queryParameters,
                    body: LocalityFloorplanDetailsToJSON(requestParameters.body),
                }, initOverrides);
            
                return new runtime.JSONApiResponse(response, (jsonValue) => SuccessResponseFromJSON(jsonValue));
            },
            mutationKey: [
                'LocalitiesApi',
                'updateLocalityFloorplanDetails', 
            ],
            queryKey: (requestParameters: UpdateLocalityFloorplanDetailsRequest, initOverrides?: RequestInit) => ([
                    ...this.updateLocalityFloorplanDetails.mutationKey, 
                {
                organizationId: requestParameters.organizationId,
                localityId: requestParameters.localityId,
                floorplanId: requestParameters.floorplanId,
                body: requestParameters.body,
                }
            ]),
            query: (requestParameters: UpdateLocalityFloorplanDetailsRequest, initOverrides?: RequestInit): UseQueryOptions<SuccessResponse> & {queryKey: QueryKey} => ({
                queryFn: () => this.updateLocalityFloorplanDetails(requestParameters, initOverrides),
                queryKey: this.updateLocalityFloorplanDetails.queryKey(requestParameters, initOverrides)
            })
        }
    )

    /**
     */
    updateLocalityFloorplanImage = Object.assign(
        async (requestParameters: UpdateLocalityFloorplanImageRequest, initOverrides?: RequestInit): Promise<void> => {
            await this.updateLocalityFloorplanImage.invokeRaw(requestParameters, initOverrides);
        },
        {
            invokeRaw: async (requestParameters: UpdateLocalityFloorplanImageRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> => {
                if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
                    throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling updateLocalityFloorplanImage.');
                }
            
                if (requestParameters.localityId === null || requestParameters.localityId === undefined) {
                    throw new runtime.RequiredError('localityId','Required parameter requestParameters.localityId was null or undefined when calling updateLocalityFloorplanImage.');
                }
            
                if (requestParameters.floorplanId === null || requestParameters.floorplanId === undefined) {
                    throw new runtime.RequiredError('floorplanId','Required parameter requestParameters.floorplanId was null or undefined when calling updateLocalityFloorplanImage.');
                }
            
                const queryParameters: any = {};
            
                const headerParameters: runtime.HTTPHeaders = {};
            
                if (this.configuration && this.configuration.accessToken) {
                    const token = this.configuration.accessToken;
                    const tokenString = await token("token", []);
            
                    if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                    }
                }
                const response = await this.request({
                    path: `/api/organizations/{organization_id}/localities/{locality_id}/floorplans/{floorplan_id}`.replace(`{${"organization_id"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"locality_id"}}`, encodeURIComponent(String(requestParameters.localityId))).replace(`{${"floorplan_id"}}`, encodeURIComponent(String(requestParameters.floorplanId))),
                    method: 'PUT',
                    headers: headerParameters,
                    query: queryParameters,
                }, initOverrides);
            
                return new runtime.VoidApiResponse(response);
            },
            mutationKey: [
                'LocalitiesApi',
                'updateLocalityFloorplanImage', 
            ],
            queryKey: (requestParameters: UpdateLocalityFloorplanImageRequest, initOverrides?: RequestInit) => ([
                    ...this.updateLocalityFloorplanImage.mutationKey, 
                {
                organizationId: requestParameters.organizationId,
                localityId: requestParameters.localityId,
                floorplanId: requestParameters.floorplanId,
                }
            ]),
            query: (requestParameters: UpdateLocalityFloorplanImageRequest, initOverrides?: RequestInit): UseQueryOptions<void> & {queryKey: QueryKey} => ({
                queryFn: () => this.updateLocalityFloorplanImage(requestParameters, initOverrides),
                queryKey: this.updateLocalityFloorplanImage.queryKey(requestParameters, initOverrides)
            })
        }
    )

    /**
     */
    updateLocalityFloorplanMappings = Object.assign(
        async (requestParameters: UpdateLocalityFloorplanMappingsRequest, initOverrides?: RequestInit): Promise<SuccessResponse> => {
            const response = await this.updateLocalityFloorplanMappings.invokeRaw(requestParameters, initOverrides);
            return await response.value();
        },
        {
            invokeRaw: async (requestParameters: UpdateLocalityFloorplanMappingsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SuccessResponse>> => {
                if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
                    throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling updateLocalityFloorplanMappings.');
                }
            
                if (requestParameters.localityId === null || requestParameters.localityId === undefined) {
                    throw new runtime.RequiredError('localityId','Required parameter requestParameters.localityId was null or undefined when calling updateLocalityFloorplanMappings.');
                }
            
                if (requestParameters.floorplanId === null || requestParameters.floorplanId === undefined) {
                    throw new runtime.RequiredError('floorplanId','Required parameter requestParameters.floorplanId was null or undefined when calling updateLocalityFloorplanMappings.');
                }
            
                if (requestParameters.body === null || requestParameters.body === undefined) {
                    throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling updateLocalityFloorplanMappings.');
                }
            
                const queryParameters: any = {};
            
                const headerParameters: runtime.HTTPHeaders = {};
            
                headerParameters['Content-Type'] = 'application/json';
            
                if (this.configuration && this.configuration.accessToken) {
                    const token = this.configuration.accessToken;
                    const tokenString = await token("token", []);
            
                    if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                    }
                }
                const response = await this.request({
                    path: `/api/organizations/{organization_id}/localities/{locality_id}/floorplans/{floorplan_id}/mappings`.replace(`{${"organization_id"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"locality_id"}}`, encodeURIComponent(String(requestParameters.localityId))).replace(`{${"floorplan_id"}}`, encodeURIComponent(String(requestParameters.floorplanId))),
                    method: 'PUT',
                    headers: headerParameters,
                    query: queryParameters,
                    body: LocalityFloorplanMappingListToJSON(requestParameters.body),
                }, initOverrides);
            
                return new runtime.JSONApiResponse(response, (jsonValue) => SuccessResponseFromJSON(jsonValue));
            },
            mutationKey: [
                'LocalitiesApi',
                'updateLocalityFloorplanMappings', 
            ],
            queryKey: (requestParameters: UpdateLocalityFloorplanMappingsRequest, initOverrides?: RequestInit) => ([
                    ...this.updateLocalityFloorplanMappings.mutationKey, 
                {
                organizationId: requestParameters.organizationId,
                localityId: requestParameters.localityId,
                floorplanId: requestParameters.floorplanId,
                body: requestParameters.body,
                }
            ]),
            query: (requestParameters: UpdateLocalityFloorplanMappingsRequest, initOverrides?: RequestInit): UseQueryOptions<SuccessResponse> & {queryKey: QueryKey} => ({
                queryFn: () => this.updateLocalityFloorplanMappings(requestParameters, initOverrides),
                queryKey: this.updateLocalityFloorplanMappings.queryKey(requestParameters, initOverrides)
            })
        }
    )

}
