import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon as FAIcon } from '@fortawesome/react-fontawesome'
import { countBy, noop, groupBy, deburr } from 'lodash'
import React, { useMemo } from 'react'
import { Button } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery } from 'react-query'
import { Link, useHistory, useLocation } from 'react-router-dom'

import { Role } from '@api/models'

import { deviceApi, organizationApi, sceneApi } from '@services'

import { CREATE_ORGANIZATION_PATH } from '@helpers/VividiURLs'
import { useApiCallCleaner } from '@helpers/api'
import { mergeQueryResults, visibleLocalitiesQuery, visibleUsersQuery } from '@helpers/api'
import { useAddBackLink } from '@helpers/backlinks'
import { orderOrganizations } from '@helpers/orderFunctions'
import useProfile from '@helpers/profile'
import { filterIndexes } from '@helpers/utils'

import { useDetectTablet } from '@hooks/useDetectDevice'

import ErrorView from '@components/ErrorView'
import TextInput from '@components/GenericInputs/TextInput'
import BarePageLayout from '@components/Layouts/BarePageLayout'
import LegacyLoadingWrapper from '@components/LegacyLoadingWrapper'
import LoadingSpinner from '@components/LoadingSpinner'
import OrganizationList from '@components/OrganizationList'
import RoleChecker from '@components/RoleChecker'

import styles from './OrganizationListPage.module.scss'

const OrganizationListHeading: React.FC<{
    headingButton: JSX.Element
    heading: string
    search: string
    onSearch: (search: string) => void
    placeholder: string
}> = ({ headingButton, heading, search, onSearch, placeholder }) => (
    <div className={styles.headingContainer}>
        <h1 className={styles.heading}>{heading}</h1>
        <TextInput
            autofocus={true}
            className={styles.search}
            placeholder={placeholder}
            value={search}
            onChange={onSearch}
        />
        <div className={styles.button}>{headingButton}</div>
    </div>
)

const OrganizationListPage = () => {
    const tablet = useDetectTablet()

    const profileCall = useProfile()
    const { t } = useTranslation()
    const placeholder = t('others.searchOrganizationOrUser', 'Search organization or user')

    const organizationsCall = useQuery(organizationApi.getOrganizations.query())

    const visibleLocalitiesCall = useQuery({
        ...visibleLocalitiesQuery(profileCall.data),
    })

    const visibleUsersCall = useQuery({
        ...visibleUsersQuery(profileCall.data),
    })

    const visibleDevicesCall = useQuery(deviceApi.getDeviceList.query())
    const visibleScenesCall = useQuery(sceneApi.getAllScenes.query())

    const { mutate: deleteOrganization } = useMutation(organizationApi.deleteOrganization, {
        onSuccess: () => {
            clean(organizationApi)
        },
        onError: noop,
    })

    const addBackLink = useAddBackLink()
    const history = useHistory()
    const location = useLocation()
    const clean = useApiCallCleaner()

    const request = mergeQueryResults(
        organizationsCall,
        visibleLocalitiesCall,
        visibleUsersCall,
        visibleDevicesCall,
        visibleScenesCall
    )

    const heading = t('heading.organizationList', 'List of Organizations')
    const documentTitle = t('title.organizationsList', 'List of organizations')

    const search = useMemo(() => new URLSearchParams(location.search).get('search') ?? '', [location.search])

    const handleSearch = (search: string) => {
        history.replace({ ...location, search: search ? `search=${search}` : undefined })
    }

    return (
        <LegacyLoadingWrapper
            errorComponent={
                <BarePageLayout documentTitle={documentTitle} heading={heading} headingType="page">
                    <ErrorView message={t('others.organizationNotFound', 'Organization not found')} />
                </BarePageLayout>
            }
            placeholder={
                <BarePageLayout documentTitle={documentTitle} loadingState={organizationsCall.status}>
                    <OrganizationListHeading
                        heading={heading}
                        headingButton={
                            <RoleChecker whitelist={[Role.Administrator]}>
                                <Button as={Link} to={addBackLink(CREATE_ORGANIZATION_PATH)} variant="primary">
                                    <FAIcon icon={faPlus} />{' '}
                                    {tablet ? t('others.new', 'New') : t('button.newOrganization', 'New organization')}
                                </Button>
                            </RoleChecker>
                        }
                        placeholder={placeholder}
                        search={search}
                        onSearch={handleSearch}
                    />
                    <LoadingSpinner />
                </BarePageLayout>
            }
            request={request}
        >
            {([{ organizations }, { localities }, { users }, { devices }, { scenes }]) => {
                const usersByOrganization = groupBy(users, (u) => u.organizationId)
                const orderedOrganizations = orderOrganizations(organizations).map((organization) => ({
                    ...organization,
                    localitiesCount:
                        countBy(localities, ({ organizationId }) => organizationId === organization.id).true ?? 0,
                    devicesCount:
                        countBy(devices, ({ organizationId }) => organizationId === organization.id).true ?? 0,
                    scenesCount: countBy(scenes, ({ organizationId }) => organizationId === organization.id).true ?? 0,
                    users: usersByOrganization[organization.id] ?? [],
                    filteredUserIndexes: filterIndexes(usersByOrganization[organization.id] ?? [], (user) =>
                        user.email.toLowerCase().includes(search.toLowerCase())
                    ),
                    normalizedName: deburr(organization.name),
                }))

                const filteredIndexes = filterIndexes(
                    orderedOrganizations,
                    (org) =>
                        org.normalizedName.toLowerCase().includes(deburr(search).toLowerCase()) ||
                        usersByOrganization[org.id]?.some((u) => u.email.toLowerCase().includes(search.toLowerCase()))
                )

                return (
                    <BarePageLayout documentTitle={documentTitle}>
                        <OrganizationListHeading
                            heading={heading}
                            headingButton={
                                <RoleChecker whitelist={[Role.Administrator]}>
                                    <Button as={Link} to={addBackLink(CREATE_ORGANIZATION_PATH)} variant="primary">
                                        <FAIcon icon={faPlus} />{' '}
                                        {tablet
                                            ? t('others.new', 'New')
                                            : t('button.newOrganization', 'New organization')}
                                    </Button>
                                </RoleChecker>
                            }
                            placeholder={placeholder}
                            search={search}
                            onSearch={handleSearch}
                        />
                        <OrganizationList
                            filteredIndexes={filteredIndexes}
                            isUserSearchActive={search.length >= 3 || search.includes('@')}
                            organizations={orderedOrganizations}
                            onDelete={({ id }) =>
                                deleteOrganization({
                                    organizationId: id,
                                })
                            }
                        />
                    </BarePageLayout>
                )
            }}
        </LegacyLoadingWrapper>
    )
}

export default OrganizationListPage
