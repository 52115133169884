/* tslint:disable */
/* eslint-disable */
/**
 * Vividi Dashboard API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IntervalMovementByBoundary,
    IntervalMovementByBoundaryFromJSON,
    IntervalMovementByBoundaryFromJSONTyped,
    IntervalMovementByBoundaryToJSON,
} from './IntervalMovementByBoundary';

/**
 * 
 * @export
 * @interface MovementByBoundaryResponse
 */
export interface MovementByBoundaryResponse {
    /**
     * Statistics items for boundaries configured as footfall entrances for the locality
     * @type {Array<IntervalMovementByBoundary>}
     * @memberof MovementByBoundaryResponse
     */
    footfallItems: Array<IntervalMovementByBoundary>;
    /**
     * Statistics items for boundaries other than those configured as footfall entrances for the locality
     * @type {Array<IntervalMovementByBoundary>}
     * @memberof MovementByBoundaryResponse
     */
    nonFootfallItems: Array<IntervalMovementByBoundary>;
}

export function MovementByBoundaryResponseFromJSON(json: any): MovementByBoundaryResponse {
    return MovementByBoundaryResponseFromJSONTyped(json, false);
}

export function MovementByBoundaryResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): MovementByBoundaryResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'footfallItems': ((json['footfall_items'] as Array<any>).map(IntervalMovementByBoundaryFromJSON)),
        'nonFootfallItems': ((json['non_footfall_items'] as Array<any>).map(IntervalMovementByBoundaryFromJSON)),
    };
}

export function MovementByBoundaryResponseToJSON(value?: MovementByBoundaryResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'footfall_items': ((value.footfallItems as Array<any>).map(IntervalMovementByBoundaryToJSON)),
        'non_footfall_items': ((value.nonFootfallItems as Array<any>).map(IntervalMovementByBoundaryToJSON)),
    };
}

