import { range } from 'lodash'
import { DateTime, Zone } from 'luxon'
import { TFunction } from 'react-i18next'

export const createDateRangePickerLocale = (t: TFunction) => {
    const locale = DateTime.local().locale

    const monthFormat = new Intl.DateTimeFormat(locale, { month: 'long' })
    const monthNames = range(1, 13).map((month) => monthFormat.format(DateTime.local(2000, month).toJSDate()))

    const dayOfWeekFormat = new Intl.DateTimeFormat(locale, { weekday: 'short' })
    const daysOfWeek = range(1, 8).map((day) => dayOfWeekFormat.format(DateTime.local(2000, 1, day + 1).toJSDate()))

    return {
        applyLabel: t('button.apply', 'Apply'),
        cancelLabel: t('button.cancel', 'Cancel'),
        fromLabel: t('table.from', 'From'),
        toLabel: t('table.to', 'To'),
        customRangeLabel: t('others.customInterval', 'Custom interval'),
        monthNames,
        daysOfWeek,
        firstDay: 1,
    }
}

export const createDateRanges = (now: DateTime, zone: Zone, t: TFunction) => {
    const today = now.setZone(zone).startOf('day')
    const yesterday = today.minus({ days: 1 })

    const weekStart = today.startOf('week')
    const weekEnd = weekStart.plus({ weeks: 1 })
    const lastWeekStart = weekStart.minus({ weeks: 1 })
    const secondLastWeekStart = lastWeekStart.minus({ weeks: 1 })

    const monthStart = today.startOf('month')
    const monthEnd = monthStart.plus({ months: 1 })
    const lastMonthStart = monthStart.minus({ months: 1 })

    const yearStart = today.startOf('year')
    const yearEnd = yearStart.plus({ years: 1 })
    const lastYearStart = yearStart.minus({ years: 1 })

    return {
        [t('dateInterval.today', 'Today')]: [today.toJSDate(), today.plus({ day: 1 }).toJSDate()],
        [t('dateInterval.yesterday', 'Yesterday')]: [yesterday.toJSDate(), yesterday.plus({ day: 1 }).toJSDate()],
        [t('dateInterval.thisWeek', 'This week')]: [weekStart.toJSDate(), weekEnd.toJSDate()],
        [t('dateInterval.lastWeek', 'Last week')]: [lastWeekStart.toJSDate(), weekStart.toJSDate()],
        [t('dateInterval.lastTwoWeeks', 'Past two weeks')]: [secondLastWeekStart.toJSDate(), weekStart.toJSDate()],
        [t('dateInterval.thisMonth', 'This month')]: [monthStart.toJSDate(), monthEnd.toJSDate()],
        [t('dateInterval.lastMonth', 'Last month')]: [lastMonthStart.toJSDate(), monthStart.toJSDate()],
        [t('dateInterval.thisYear', 'This year')]: [yearStart.toJSDate(), yearEnd.toJSDate()],
        [t('dateInterval.lastYear', 'Last year')]: [lastYearStart.toJSDate(), yearStart.toJSDate()],
    }
}
