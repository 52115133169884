import { isEmpty, mean } from 'lodash'
import React from 'react'

import { Point } from '@api'

import { determineBoundaryArrowRotation } from '@helpers/describeScene'
import { pickColumn } from '@helpers/utils'

import { ReactComponent as Arrow } from '@images/boundary_arrow.svg'

const arrowSize = { x: 26, y: 26 }

const BoundaryDirectionArrow: React.FC<{ scale: number; points: Point[]; className?: string }> = ({
    scale,
    points,
    className,
}) => {
    if (isEmpty(points)) {
        return null
    }

    const middlePoint = Math.floor(points.length / 2)
    const isPointCountOdd = points.length % 2 === 1

    const arrowNeighbors: [Point, Point] = isPointCountOdd
        ? [points[middlePoint - 1], points[middlePoint + 1]]
        : [points[middlePoint - 1], points[middlePoint]]

    const arrowAnchorPoint: Point = isPointCountOdd
        ? points[middlePoint]
        : {
              x: mean(pickColumn(arrowNeighbors, 'x')),
              y: mean(pickColumn(arrowNeighbors, 'y')),
          }

    const arrowRotation = determineBoundaryArrowRotation(arrowNeighbors[0], arrowAnchorPoint, arrowNeighbors[1])
    const arrowGap = arrowSize.y * 0.3

    const transform = [
        `scale(${scale})`,
        `translate(${arrowAnchorPoint.x / scale} ${arrowAnchorPoint.y / scale})`, // Move arrow to anchor point
        `translate(${-arrowSize.x / 2} ${-arrowSize.y - arrowGap})`, // Adjust the position so that the bottom of the arrow rests at the anchor point
        `rotate(${(arrowRotation * 180) / Math.PI} ${arrowSize.x / 2} ${arrowSize.y + arrowGap})`, // Rotate the arrow with center of rotation in the anchor point
    ].join(' ')

    return (
        <g transform={transform}>
            <Arrow className={className} />
        </g>
    )
}

export default BoundaryDirectionArrow
