import { isEmpty } from 'lodash'
import React, { useState } from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery } from 'react-query'

import { profileApi, userApi } from '@services'

import { useApiCallCleaner } from '@helpers/api'
import { mergeQueryResults, visibleLocalitiesQuery, visibleOrganizationsQuery } from '@helpers/api'
import { isApiError } from '@helpers/apiCallManipulation'
import { useBackLink } from '@helpers/backlinks'
import { GoogleEventCategory, GoogleEventName, trackEvent } from '@helpers/gtag'
import useProfile from '@helpers/profile'

import { BoxWithContainer } from '@elements/Box/BoxWithContainer'

import AppFooter from '@components/AppFooter/AppFooter'
import FeatureChecker from '@components/FeatureChecker'
import SpacedLayout from '@components/Layouts/SpacedLayout'
import LegacyLoadingWrapper from '@components/LegacyLoadingWrapper'
import Navbar from '@components/Navbar/Navbar'
import NotificationPreferencesForm from '@components/NotificationPreferencesForm'
import SetPasswordForm from '@components/SetPasswordForm'
import { useNotify } from '@components/notifications/NotificationsContext'

import styles from './SettingsPage.module.scss'

const SettingsPage = () => {
    const { t, i18n } = useTranslation()
    const profileCall = useProfile()
    const clean = useApiCallCleaner()

    const { mutate: changePassword } = useMutation(userApi.changeOwnPassword, {
        onSuccess: () => {
            notify({
                title: t('notification.passwordChanged', 'Password changed'),
                content: t('notification.passwordChangedContent', 'Your password has been changed successfully'),
                variant: 'success',
                timeoutSeconds: 10,
            })
            backLink.follow()
        },
        onError: (error) => {
            if (isApiError(error) && error.status === 403) {
                notify({
                    title: t('notification.passwordIncorrect', 'Password is not correct'),
                    content: t(
                        'notification.passwordChangedFailedIncorrectPasword',
                        'Pasword change failed - the password you entered is not correct. Please, try again.'
                    ),
                    variant: 'danger',
                })
            } else {
                notify({
                    title: t('notification.passwordChangedFailed', 'Password change failed'),
                    content: t(
                        'notification.passwordChangedFailedContent',
                        'An error was encountered when changing your password.'
                    ),
                    variant: 'danger',
                })
            }
        },
        onSettled: () => {
            setPasswordFormReset((previous) => previous + 1)
        },
    })

    const { mutate: changeLanguage, status: changeLanguageState } = useMutation(userApi.changeOwnLanguage, {
        onSuccess: () => {
            clean(userApi)
            clean(profileApi)
            trackEvent(
                GoogleEventCategory.USER,
                GoogleEventName.CHANGE_LANGUAGE,
                profileCall.data?.language,
                profileCall.data?.email
            )
            notify({
                variant: 'success',
                title: t('notification.updateSuccessful', 'Update successful'),
                content: t('notification.setLanguageContent', 'Application language was updated successfully'),
                timeoutSeconds: 5,
            })
        },
        onError: () => {
            notify({
                title: t('notification.updateFailed', 'Update failed'),
                content: t(
                    'notification.setLanguageFailedContent',
                    'An error was encountered when updating application language.'
                ),
                variant: 'danger',
            })
        },
    })

    const [passwordFormReset, setPasswordFormReset] = useState(0)
    const notify = useNotify()
    const backLink = useBackLink()

    const notificationsCall = useQuery({
        ...userApi.getNotificationPreferences.query({ userId: profileCall.data?.id ?? -1 }),
        enabled: profileCall.data !== undefined,
    })

    const localitiesCall = useQuery({
        ...visibleLocalitiesQuery(profileCall.data),
    })

    const organizationsCall = useQuery({
        ...visibleOrganizationsQuery(profileCall.data),
    })

    const request = mergeQueryResults(notificationsCall, localitiesCall, organizationsCall)

    const documentTitle = t('title.userSettings', 'User settings')
    const heading = t('heading.changePassword', 'Change Password')
    const appLanguageHeading = t('heading.language', 'App language')

    return (
        <SpacedLayout>
            <Navbar />
            <BoxWithContainer heading={heading}>
                <SetPasswordForm
                    key={passwordFormReset}
                    askCurrentPassword={true}
                    submissionStatus="idle"
                    onSubmit={(newPassword, currentPassword) =>
                        changePassword({
                            body: { currentPassword, newPassword },
                        })
                    }
                />
            </BoxWithContainer>
            <BoxWithContainer heading={appLanguageHeading}>
                <Form.Group as={Row}>
                    <Form.Label column={true} sm={2}>
                        {t('form.setLanguage', 'Select language')}
                    </Form.Label>
                    <Col sm={3}>
                        <Form.Control
                            as="select"
                            disabled={changeLanguageState === 'loading'}
                            value={profileCall.data?.language ?? (i18n.options.fallbackLng as string)}
                            onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                                changeLanguage({
                                    body: {
                                        language: e.target.value,
                                    },
                                })
                            }
                        >
                            <option key="english" value="en">
                                {t('form.english', 'English')}
                            </option>
                            <option key="czech" value="cs">
                                {t('form.czech', 'Czech')}
                            </option>
                            <option key="french" value="fr">
                                {t('form.french', 'French')}
                            </option>
                        </Form.Control>
                    </Col>
                </Form.Group>
            </BoxWithContainer>

            <LegacyLoadingWrapper placeholder={<></>} request={request}>
                {([notifications, localities, organizations]) => {
                    return (
                        <FeatureChecker
                            allowForAdmin={true}
                            feature="staySafeNotifier"
                            organization={organizations.organizations.find(
                                (o) => o.id === profileCall.data?.organizationId
                            )}
                        >
                            <BoxWithContainer heading={documentTitle}>
                                {!isEmpty(localities.localities) && profileCall.data && (
                                    <NotificationPreferencesForm
                                        localities={localities}
                                        notificationPreferences={notifications}
                                        organizations={organizations}
                                        user={profileCall.data}
                                    />
                                )}
                            </BoxWithContainer>
                        </FeatureChecker>
                    )
                }}
            </LegacyLoadingWrapper>
            <footer className={styles.footer}>
                <AppFooter />
            </footer>
        </SpacedLayout>
    )
}

export default SettingsPage
