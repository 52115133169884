import classNames from 'classnames'
import { isEmpty } from 'lodash'
import React from 'react'
import { Breadcrumb } from 'react-bootstrap'
import { useLocation } from 'react-router'
import { Link, matchPath } from 'react-router-dom'

import styles from './Breadcrumbs.module.scss'

export type BreadcrumbsList = Array<{ linkDisplayName: string; link: string; path?: string }>

const Breadcrumbs = ({ breadcrumbs, className }: { breadcrumbs: BreadcrumbsList; className?: string }) => {
    const location = useLocation()

    const checkMatch = (locationPathname: string, path: string) => !isEmpty(matchPath(locationPathname, { path }))

    return !isEmpty(breadcrumbs) ? (
        <Breadcrumb className={classNames(styles.breadcrumbContainer, className)}>
            {breadcrumbs.map(({ linkDisplayName, link, path }, index) => (
                <Breadcrumb.Item
                    key={`breadcrumb-${index}`}
                    // determine if active based on provided path OR on exact match
                    active={path ? checkMatch(location.pathname, path) : location.pathname === link}
                    linkAs={Link}
                    linkProps={{ to: link }}
                >
                    {linkDisplayName}
                </Breadcrumb.Item>
            ))}
        </Breadcrumb>
    ) : null
}

export default Breadcrumbs
