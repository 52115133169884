import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { noop } from 'lodash'
import React from 'react'
import { Button } from 'react-bootstrap'

import styles from './ToggleButton.module.scss'

type Toggle = {
    toggleIcon?: IconProp
    toggleText: string
    toggleValue: string
}

interface ToggleButtonProps {
    leftToggle: Toggle
    rightToggle: Toggle
    toggleValue: string
    toggleName: string
    onToggle: (value: string) => void
    middleToggle?: Toggle
    containerClassName?: string
}

const ToggleButton: React.FC<ToggleButtonProps> = ({
    leftToggle,
    rightToggle,
    toggleValue,
    onToggle,
    toggleName,
    containerClassName,
    middleToggle,
}) => {
    return (
        <div className={containerClassName ? containerClassName : styles.toggleButtonContainer} id={toggleName}>
            <Button
                className={styles.toggleButtonLeft}
                value={leftToggle.toggleValue}
                variant={toggleValue === leftToggle.toggleValue ? 'primary' : 'secondary'}
                onClick={() => (leftToggle.toggleValue !== toggleValue ? onToggle(leftToggle.toggleValue) : noop)}
            >
                {leftToggle.toggleIcon && <FontAwesomeIcon icon={leftToggle.toggleIcon} />}
                {leftToggle.toggleText}
            </Button>
            {middleToggle && (
                <Button
                    className={styles.toggleButtonMiddle}
                    value={middleToggle.toggleValue}
                    variant={toggleValue === middleToggle.toggleValue ? 'primary' : 'secondary'}
                    onClick={() =>
                        middleToggle.toggleValue !== toggleValue ? onToggle(middleToggle.toggleValue) : noop
                    }
                >
                    {middleToggle.toggleIcon && <FontAwesomeIcon icon={middleToggle.toggleIcon} />}
                    {middleToggle.toggleText}
                </Button>
            )}
            <Button
                className={styles.toggleButtonRight}
                value={rightToggle.toggleValue}
                variant={toggleValue === rightToggle.toggleValue ? 'primary' : 'secondary'}
                onClick={() => (rightToggle.toggleValue !== toggleValue ? onToggle(rightToggle.toggleValue) : noop)}
            >
                {rightToggle.toggleIcon && <FontAwesomeIcon icon={rightToggle.toggleIcon} />}
                {rightToggle.toggleText}
            </Button>
        </div>
    )
}

export default ToggleButton
