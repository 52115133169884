import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import { LocationDescriptor } from 'history'
import { FC } from 'react'
import { Button } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import tableStyles from '@elements/Table/Table.module.scss'

const DetailLink: FC<{ className?: string; to: string | LocationDescriptor }> = ({ className, to }) => {
    const { t } = useTranslation()

    return (
        <td className={classNames(tableStyles.plainCell, className)}>
            <Button as={Link} to={to} variant="link">
                <FontAwesomeIcon icon={faExternalLinkAlt} />
                {t('button.detail', 'Detail')}
            </Button>
        </td>
    )
}

export default DetailLink
