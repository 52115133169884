/* tslint:disable */
/* eslint-disable */
/**
 * Vividi Dashboard API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IntervalMovement,
    IntervalMovementFromJSON,
    IntervalMovementFromJSONTyped,
    IntervalMovementToJSON,
} from './IntervalMovement';

/**
 * 
 * @export
 * @interface MovementResponse
 */
export interface MovementResponse {
    /**
     * 
     * @type {Array<IntervalMovement>}
     * @memberof MovementResponse
     */
    items: Array<IntervalMovement>;
}

export function MovementResponseFromJSON(json: any): MovementResponse {
    return MovementResponseFromJSONTyped(json, false);
}

export function MovementResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): MovementResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'items': ((json['items'] as Array<any>).map(IntervalMovementFromJSON)),
    };
}

export function MovementResponseToJSON(value?: MovementResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'items': ((value.items as Array<any>).map(IntervalMovementToJSON)),
    };
}

