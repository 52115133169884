import { range } from 'lodash'
import React from 'react'
import { AreaChart, Area, CartesianGrid } from 'recharts'

import styles from './StatisticsSummaryConversionPlot.module.scss'

type Props = {
    width: number
    height: number
    className: string
    data: Array<{
        name: string
        value: number
    }>
}

const StatisticsSummaryConversionPlot = ({ width, height, className, data }: Props) => {
    return (
        <div className={className}>
            <AreaChart data={data} height={height} width={width}>
                <Area dataKey="value" fill="#00c6c1" opacity={0.6} stroke="transparent" type="monotone" />
                <CartesianGrid
                    horizontal={false}
                    stroke={styles.dividerColor}
                    verticalPoints={range(1, data.length - 1).map(
                        (i) => i * Math.floor((width - data.length + 1) / (data.length - 1))
                    )}
                />
            </AreaChart>
        </div>
    )
}

export default StatisticsSummaryConversionPlot
