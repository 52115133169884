/**
 * Vividi Dashboard API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime'
import {UseQueryOptions, QueryKey} from 'react-query'

import {
    ConversionStatisticsResponse,
    ConversionStatisticsResponseFromJSON,
    ConversionStatisticsResponseToJSON,
    ConversionsOverviewRequestBody,
    ConversionsOverviewRequestBodyFromJSON,
    ConversionsOverviewRequestBodyToJSON,
    ConversionsOverviewResponse,
    ConversionsOverviewResponseFromJSON,
    ConversionsOverviewResponseToJSON,
    CrowdSizeHistoryResponse,
    CrowdSizeHistoryResponseFromJSON,
    CrowdSizeHistoryResponseToJSON,
    CrowdSizeResponse,
    CrowdSizeResponseFromJSON,
    CrowdSizeResponseToJSON,
    DatetimeInterval,
    DatetimeIntervalFromJSON,
    DatetimeIntervalToJSON,
    EmotionSummaryResponse,
    EmotionSummaryResponseFromJSON,
    EmotionSummaryResponseToJSON,
    EntityListResponse,
    EntityListResponseFromJSON,
    EntityListResponseToJSON,
    FloorplanCrowdSizeResponse,
    FloorplanCrowdSizeResponseFromJSON,
    FloorplanCrowdSizeResponseToJSON,
    FloorplanHeatmapListResponse,
    FloorplanHeatmapListResponseFromJSON,
    FloorplanHeatmapListResponseToJSON,
    FootfallOverviewRequestBody,
    FootfallOverviewRequestBodyFromJSON,
    FootfallOverviewRequestBodyToJSON,
    FootfallOverviewResponse,
    FootfallOverviewResponseFromJSON,
    FootfallOverviewResponseToJSON,
    LocalitySummaryRequestBody,
    LocalitySummaryRequestBodyFromJSON,
    LocalitySummaryRequestBodyToJSON,
    LocalityVisibleEntitiesResponse,
    LocalityVisibleEntitiesResponseFromJSON,
    LocalityVisibleEntitiesResponseToJSON,
    MovementByBoundaryRequestBody,
    MovementByBoundaryRequestBodyFromJSON,
    MovementByBoundaryRequestBodyToJSON,
    MovementByBoundaryResponse,
    MovementByBoundaryResponseFromJSON,
    MovementByBoundaryResponseToJSON,
    MovementResponse,
    MovementResponseFromJSON,
    MovementResponseToJSON,
    MovementSummaryRequestBody,
    MovementSummaryRequestBodyFromJSON,
    MovementSummaryRequestBodyToJSON,
    MultiDeviceOutagesRequestBody,
    MultiDeviceOutagesRequestBodyFromJSON,
    MultiDeviceOutagesRequestBodyToJSON,
    OutagesResponse,
    OutagesResponseFromJSON,
    OutagesResponseToJSON,
    PropertiesByBoundaryResponse,
    PropertiesByBoundaryResponseFromJSON,
    PropertiesByBoundaryResponseToJSON,
    PropertyBreakdownByBoundaryResponse,
    PropertyBreakdownByBoundaryResponseFromJSON,
    PropertyBreakdownByBoundaryResponseToJSON,
    PropertyBreakdownResponse,
    PropertyBreakdownResponseFromJSON,
    PropertyBreakdownResponseToJSON,
    PropertySummaryRequestBody,
    PropertySummaryRequestBodyFromJSON,
    PropertySummaryRequestBodyToJSON,
    PropertySummaryResponse,
    PropertySummaryResponseFromJSON,
    PropertySummaryResponseToJSON,
    QueueStatisticsResponse,
    QueueStatisticsResponseFromJSON,
    QueueStatisticsResponseToJSON,
    SalesStatisticsDetailsSummaryResponse,
    SalesStatisticsDetailsSummaryResponseFromJSON,
    SalesStatisticsDetailsSummaryResponseToJSON,
    SuccessResponse,
    SuccessResponseFromJSON,
    SuccessResponseToJSON,
    SummaryRequestBody,
    SummaryRequestBodyFromJSON,
    SummaryRequestBodyToJSON,
    ZoneOccupancySessionsRequestBody,
    ZoneOccupancySessionsRequestBodyFromJSON,
    ZoneOccupancySessionsRequestBodyToJSON,
    ZoneOccupancySessionsResponse,
    ZoneOccupancySessionsResponseFromJSON,
    ZoneOccupancySessionsResponseToJSON,
    ZoneOccupancySummaryRequestBody,
    ZoneOccupancySummaryRequestBodyFromJSON,
    ZoneOccupancySummaryRequestBodyToJSON,
    ZoneOccupancySummaryResponse,
    ZoneOccupancySummaryResponseFromJSON,
    ZoneOccupancySummaryResponseToJSON,
} from '../models';

export interface DeleteEntityRequest {
    globalEntityId: number;
}

export interface GetConversionStatisticsRequest {
    body: LocalitySummaryRequestBody;
}

export interface GetConversionsOverviewRequest {
    body: ConversionsOverviewRequestBody;
}

export interface GetCrowdSizeHistoryRequest {
    localityId: number;
    body: SummaryRequestBody;
}

export interface GetEmotionsSummaryRequest {
    localityId: number;
    body: SummaryRequestBody;
}

export interface GetEntitiesRequest {
    sceneId: number;
    body: DatetimeInterval;
    omitTrajectories?: string;
}

export interface GetFloorplanCrowdSizeHistoryRequest {
    localityId: number;
    body: SummaryRequestBody;
}

export interface GetFloorplanDwellTimeSampleRequest {
    floorplanId: number;
}

export interface GetFloorplanHeatmapContentRequest {
    heatmapId: number;
}

export interface GetFloorplanHeatmapListRequest {
    floorplanId: number;
}

export interface GetFloorplanHeatmapSampleRequest {
    floorplanId: number;
}

export interface GetFloorplanHeatmapSingleSceneSampleRequest {
    floorplanId: number;
    sceneId: number;
}

export interface GetFloorplanLocalityCrowdSizeRequest {
    localityId: number;
}

export interface GetFloorplanUtilizationGridSampleRequest {
    floorplanId: number;
}

export interface GetFootfallOverviewRequest {
    body: FootfallOverviewRequestBody;
}

export interface GetLocalityCrowdSizeRequest {
    localityId: number;
    at?: string;
}

export interface GetMovementByBoundaryRequest {
    body: MovementByBoundaryRequestBody;
}

export interface GetMovementSummaryRequest {
    body: MovementSummaryRequestBody;
}

export interface GetMultiDeviceOutagesRequest {
    body: MultiDeviceOutagesRequestBody;
}

export interface GetOutagesRequest {
    sceneId: number;
    body: DatetimeInterval;
}

export interface GetPropertiesByBoundaryRequest {
    body: PropertySummaryRequestBody;
}

export interface GetPropertyBreakdownRequest {
    body: PropertySummaryRequestBody;
}

export interface GetPropertyBreakdownByBoundaryRequest {
    body: PropertySummaryRequestBody;
}

export interface GetPropertySummaryRequest {
    body: PropertySummaryRequestBody;
}

export interface GetQueueStatisticsRequest {
    body: LocalitySummaryRequestBody;
}

export interface GetSalesStatisticsDetailsRequest {
    body: LocalitySummaryRequestBody;
}

export interface GetVisibleEntitiesRequest {
    localityId: number;
}

export interface GetZoneOccupancySessionsRequest {
    body: ZoneOccupancySessionsRequestBody;
}

export interface GetZoneOccupancySummaryRequest {
    body: ZoneOccupancySummaryRequestBody;
}

export interface SubscribeToLocalityCrowdSizeRequest {
    localityId: number;
    subscriptionType?: string;
    interval?: number;
}

export interface SubscribeToLocalityFloorplanCrowdSizeRequest {
    localityId: number;
    subscriptionType?: string;
    interval?: number;
}


/**
 * 
 */
export class StatisticsApi extends runtime.BaseAPI {
    readonly name = 'StatisticsApi'

    /**
     * Delete the given entity permanently.
     */
    deleteEntity = Object.assign(
        async (requestParameters: DeleteEntityRequest, initOverrides?: RequestInit): Promise<SuccessResponse> => {
            const response = await this.deleteEntity.invokeRaw(requestParameters, initOverrides);
            return await response.value();
        },
        {
            invokeRaw: async (requestParameters: DeleteEntityRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SuccessResponse>> => {
                if (requestParameters.globalEntityId === null || requestParameters.globalEntityId === undefined) {
                    throw new runtime.RequiredError('globalEntityId','Required parameter requestParameters.globalEntityId was null or undefined when calling deleteEntity.');
                }
            
                const queryParameters: any = {};
            
                const headerParameters: runtime.HTTPHeaders = {};
            
                if (this.configuration && this.configuration.accessToken) {
                    const token = this.configuration.accessToken;
                    const tokenString = await token("token", []);
            
                    if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                    }
                }
                const response = await this.request({
                    path: `/api/statistics/entities/{global_entity_id}`.replace(`{${"global_entity_id"}}`, encodeURIComponent(String(requestParameters.globalEntityId))),
                    method: 'DELETE',
                    headers: headerParameters,
                    query: queryParameters,
                }, initOverrides);
            
                return new runtime.JSONApiResponse(response, (jsonValue) => SuccessResponseFromJSON(jsonValue));
            },
            mutationKey: [
                'StatisticsApi',
                'deleteEntity', 
            ],
            queryKey: (requestParameters: DeleteEntityRequest, initOverrides?: RequestInit) => ([
                    ...this.deleteEntity.mutationKey, 
                {
                globalEntityId: requestParameters.globalEntityId,
                }
            ]),
            query: (requestParameters: DeleteEntityRequest, initOverrides?: RequestInit): UseQueryOptions<SuccessResponse> & {queryKey: QueryKey} => ({
                queryFn: () => this.deleteEntity(requestParameters, initOverrides),
                queryKey: this.deleteEntity.queryKey(requestParameters, initOverrides)
            })
        }
    )

    /**
     */
    getConversionStatistics = Object.assign(
        async (requestParameters: GetConversionStatisticsRequest, initOverrides?: RequestInit): Promise<ConversionStatisticsResponse> => {
            const response = await this.getConversionStatistics.invokeRaw(requestParameters, initOverrides);
            return await response.value();
        },
        {
            invokeRaw: async (requestParameters: GetConversionStatisticsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ConversionStatisticsResponse>> => {
                if (requestParameters.body === null || requestParameters.body === undefined) {
                    throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling getConversionStatistics.');
                }
            
                const queryParameters: any = {};
            
                const headerParameters: runtime.HTTPHeaders = {};
            
                headerParameters['Content-Type'] = 'application/json';
            
                if (this.configuration && this.configuration.accessToken) {
                    const token = this.configuration.accessToken;
                    const tokenString = await token("token", []);
            
                    if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                    }
                }
                const response = await this.request({
                    path: `/api/statistics/conversions/summary`,
                    method: 'POST',
                    headers: headerParameters,
                    query: queryParameters,
                    body: LocalitySummaryRequestBodyToJSON(requestParameters.body),
                }, initOverrides);
            
                return new runtime.JSONApiResponse(response, (jsonValue) => ConversionStatisticsResponseFromJSON(jsonValue));
            },
            mutationKey: [
                'StatisticsApi',
                'getConversionStatistics', 
            ],
            queryKey: (requestParameters: GetConversionStatisticsRequest, initOverrides?: RequestInit) => ([
                    ...this.getConversionStatistics.mutationKey, 
                {
                body: requestParameters.body,
                }
            ]),
            query: (requestParameters: GetConversionStatisticsRequest, initOverrides?: RequestInit): UseQueryOptions<ConversionStatisticsResponse> & {queryKey: QueryKey} => ({
                queryFn: () => this.getConversionStatistics(requestParameters, initOverrides),
                queryKey: this.getConversionStatistics.queryKey(requestParameters, initOverrides)
            })
        }
    )

    /**
     * Get a multi-locality overview of conversion statistics
     */
    getConversionsOverview = Object.assign(
        async (requestParameters: GetConversionsOverviewRequest, initOverrides?: RequestInit): Promise<ConversionsOverviewResponse> => {
            const response = await this.getConversionsOverview.invokeRaw(requestParameters, initOverrides);
            return await response.value();
        },
        {
            invokeRaw: async (requestParameters: GetConversionsOverviewRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ConversionsOverviewResponse>> => {
                if (requestParameters.body === null || requestParameters.body === undefined) {
                    throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling getConversionsOverview.');
                }
            
                const queryParameters: any = {};
            
                const headerParameters: runtime.HTTPHeaders = {};
            
                headerParameters['Content-Type'] = 'application/json';
            
                if (this.configuration && this.configuration.accessToken) {
                    const token = this.configuration.accessToken;
                    const tokenString = await token("token", []);
            
                    if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                    }
                }
                const response = await this.request({
                    path: `/api/statistics/conversions/overview`,
                    method: 'POST',
                    headers: headerParameters,
                    query: queryParameters,
                    body: ConversionsOverviewRequestBodyToJSON(requestParameters.body),
                }, initOverrides);
            
                return new runtime.JSONApiResponse(response, (jsonValue) => ConversionsOverviewResponseFromJSON(jsonValue));
            },
            mutationKey: [
                'StatisticsApi',
                'getConversionsOverview', 
            ],
            queryKey: (requestParameters: GetConversionsOverviewRequest, initOverrides?: RequestInit) => ([
                    ...this.getConversionsOverview.mutationKey, 
                {
                body: requestParameters.body,
                }
            ]),
            query: (requestParameters: GetConversionsOverviewRequest, initOverrides?: RequestInit): UseQueryOptions<ConversionsOverviewResponse> & {queryKey: QueryKey} => ({
                queryFn: () => this.getConversionsOverview(requestParameters, initOverrides),
                queryKey: this.getConversionsOverview.queryKey(requestParameters, initOverrides)
            })
        }
    )

    /**
     * Get the history of crowd sizes observed by Vividi Stay Safe.
     */
    getCrowdSizeHistory = Object.assign(
        async (requestParameters: GetCrowdSizeHistoryRequest, initOverrides?: RequestInit): Promise<CrowdSizeHistoryResponse> => {
            const response = await this.getCrowdSizeHistory.invokeRaw(requestParameters, initOverrides);
            return await response.value();
        },
        {
            invokeRaw: async (requestParameters: GetCrowdSizeHistoryRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CrowdSizeHistoryResponse>> => {
                if (requestParameters.localityId === null || requestParameters.localityId === undefined) {
                    throw new runtime.RequiredError('localityId','Required parameter requestParameters.localityId was null or undefined when calling getCrowdSizeHistory.');
                }
            
                if (requestParameters.body === null || requestParameters.body === undefined) {
                    throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling getCrowdSizeHistory.');
                }
            
                const queryParameters: any = {};
            
                const headerParameters: runtime.HTTPHeaders = {};
            
                headerParameters['Content-Type'] = 'application/json';
            
                if (this.configuration && this.configuration.accessToken) {
                    const token = this.configuration.accessToken;
                    const tokenString = await token("token", []);
            
                    if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                    }
                }
                const response = await this.request({
                    path: `/api/statistics/by-locality/{locality_id}/crowd-size-history`.replace(`{${"locality_id"}}`, encodeURIComponent(String(requestParameters.localityId))),
                    method: 'POST',
                    headers: headerParameters,
                    query: queryParameters,
                    body: SummaryRequestBodyToJSON(requestParameters.body),
                }, initOverrides);
            
                return new runtime.JSONApiResponse(response, (jsonValue) => CrowdSizeHistoryResponseFromJSON(jsonValue));
            },
            mutationKey: [
                'StatisticsApi',
                'getCrowdSizeHistory', 
            ],
            queryKey: (requestParameters: GetCrowdSizeHistoryRequest, initOverrides?: RequestInit) => ([
                    ...this.getCrowdSizeHistory.mutationKey, 
                {
                localityId: requestParameters.localityId,
                body: requestParameters.body,
                }
            ]),
            query: (requestParameters: GetCrowdSizeHistoryRequest, initOverrides?: RequestInit): UseQueryOptions<CrowdSizeHistoryResponse> & {queryKey: QueryKey} => ({
                queryFn: () => this.getCrowdSizeHistory(requestParameters, initOverrides),
                queryKey: this.getCrowdSizeHistory.queryKey(requestParameters, initOverrides)
            })
        }
    )

    /**
     * Get a summary of emotion scores broken down by zone.
     */
    getEmotionsSummary = Object.assign(
        async (requestParameters: GetEmotionsSummaryRequest, initOverrides?: RequestInit): Promise<EmotionSummaryResponse> => {
            const response = await this.getEmotionsSummary.invokeRaw(requestParameters, initOverrides);
            return await response.value();
        },
        {
            invokeRaw: async (requestParameters: GetEmotionsSummaryRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<EmotionSummaryResponse>> => {
                if (requestParameters.localityId === null || requestParameters.localityId === undefined) {
                    throw new runtime.RequiredError('localityId','Required parameter requestParameters.localityId was null or undefined when calling getEmotionsSummary.');
                }
            
                if (requestParameters.body === null || requestParameters.body === undefined) {
                    throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling getEmotionsSummary.');
                }
            
                const queryParameters: any = {};
            
                const headerParameters: runtime.HTTPHeaders = {};
            
                headerParameters['Content-Type'] = 'application/json';
            
                if (this.configuration && this.configuration.accessToken) {
                    const token = this.configuration.accessToken;
                    const tokenString = await token("token", []);
            
                    if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                    }
                }
                const response = await this.request({
                    path: `/api/statistics/by-locality/{locality_id}/emotions/by-zone`.replace(`{${"locality_id"}}`, encodeURIComponent(String(requestParameters.localityId))),
                    method: 'POST',
                    headers: headerParameters,
                    query: queryParameters,
                    body: SummaryRequestBodyToJSON(requestParameters.body),
                }, initOverrides);
            
                return new runtime.JSONApiResponse(response, (jsonValue) => EmotionSummaryResponseFromJSON(jsonValue));
            },
            mutationKey: [
                'StatisticsApi',
                'getEmotionsSummary', 
            ],
            queryKey: (requestParameters: GetEmotionsSummaryRequest, initOverrides?: RequestInit) => ([
                    ...this.getEmotionsSummary.mutationKey, 
                {
                localityId: requestParameters.localityId,
                body: requestParameters.body,
                }
            ]),
            query: (requestParameters: GetEmotionsSummaryRequest, initOverrides?: RequestInit): UseQueryOptions<EmotionSummaryResponse> & {queryKey: QueryKey} => ({
                queryFn: () => this.getEmotionsSummary(requestParameters, initOverrides),
                queryKey: this.getEmotionsSummary.queryKey(requestParameters, initOverrides)
            })
        }
    )

    /**
     * Get a list of entities observed in a given interval.
     */
    getEntities = Object.assign(
        async (requestParameters: GetEntitiesRequest, initOverrides?: RequestInit): Promise<EntityListResponse> => {
            const response = await this.getEntities.invokeRaw(requestParameters, initOverrides);
            return await response.value();
        },
        {
            invokeRaw: async (requestParameters: GetEntitiesRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<EntityListResponse>> => {
                if (requestParameters.sceneId === null || requestParameters.sceneId === undefined) {
                    throw new runtime.RequiredError('sceneId','Required parameter requestParameters.sceneId was null or undefined when calling getEntities.');
                }
            
                if (requestParameters.body === null || requestParameters.body === undefined) {
                    throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling getEntities.');
                }
            
                const queryParameters: any = {};
            
                if (requestParameters.omitTrajectories !== undefined) {
                    queryParameters['omit_trajectories'] = requestParameters.omitTrajectories;
                }
            
                const headerParameters: runtime.HTTPHeaders = {};
            
                headerParameters['Content-Type'] = 'application/json';
            
                if (this.configuration && this.configuration.accessToken) {
                    const token = this.configuration.accessToken;
                    const tokenString = await token("token", []);
            
                    if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                    }
                }
                const response = await this.request({
                    path: `/api/statistics/{scene_id}/entities`.replace(`{${"scene_id"}}`, encodeURIComponent(String(requestParameters.sceneId))),
                    method: 'POST',
                    headers: headerParameters,
                    query: queryParameters,
                    body: DatetimeIntervalToJSON(requestParameters.body),
                }, initOverrides);
            
                return new runtime.JSONApiResponse(response, (jsonValue) => EntityListResponseFromJSON(jsonValue));
            },
            mutationKey: [
                'StatisticsApi',
                'getEntities', 
            ],
            queryKey: (requestParameters: GetEntitiesRequest, initOverrides?: RequestInit) => ([
                    ...this.getEntities.mutationKey, 
                {
                sceneId: requestParameters.sceneId,
                body: requestParameters.body,
                omitTrajectories: requestParameters.omitTrajectories,
                }
            ]),
            query: (requestParameters: GetEntitiesRequest, initOverrides?: RequestInit): UseQueryOptions<EntityListResponse> & {queryKey: QueryKey} => ({
                queryFn: () => this.getEntities(requestParameters, initOverrides),
                queryKey: this.getEntities.queryKey(requestParameters, initOverrides)
            })
        }
    )

    /**
     * Get the history of crowd sizes observed by Vividi Stay Safe Floorplan.
     */
    getFloorplanCrowdSizeHistory = Object.assign(
        async (requestParameters: GetFloorplanCrowdSizeHistoryRequest, initOverrides?: RequestInit): Promise<CrowdSizeHistoryResponse> => {
            const response = await this.getFloorplanCrowdSizeHistory.invokeRaw(requestParameters, initOverrides);
            return await response.value();
        },
        {
            invokeRaw: async (requestParameters: GetFloorplanCrowdSizeHistoryRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CrowdSizeHistoryResponse>> => {
                if (requestParameters.localityId === null || requestParameters.localityId === undefined) {
                    throw new runtime.RequiredError('localityId','Required parameter requestParameters.localityId was null or undefined when calling getFloorplanCrowdSizeHistory.');
                }
            
                if (requestParameters.body === null || requestParameters.body === undefined) {
                    throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling getFloorplanCrowdSizeHistory.');
                }
            
                const queryParameters: any = {};
            
                const headerParameters: runtime.HTTPHeaders = {};
            
                headerParameters['Content-Type'] = 'application/json';
            
                if (this.configuration && this.configuration.accessToken) {
                    const token = this.configuration.accessToken;
                    const tokenString = await token("token", []);
            
                    if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                    }
                }
                const response = await this.request({
                    path: `/api/statistics/by-locality/{locality_id}/floorplan-crowd-size-history`.replace(`{${"locality_id"}}`, encodeURIComponent(String(requestParameters.localityId))),
                    method: 'POST',
                    headers: headerParameters,
                    query: queryParameters,
                    body: SummaryRequestBodyToJSON(requestParameters.body),
                }, initOverrides);
            
                return new runtime.JSONApiResponse(response, (jsonValue) => CrowdSizeHistoryResponseFromJSON(jsonValue));
            },
            mutationKey: [
                'StatisticsApi',
                'getFloorplanCrowdSizeHistory', 
            ],
            queryKey: (requestParameters: GetFloorplanCrowdSizeHistoryRequest, initOverrides?: RequestInit) => ([
                    ...this.getFloorplanCrowdSizeHistory.mutationKey, 
                {
                localityId: requestParameters.localityId,
                body: requestParameters.body,
                }
            ]),
            query: (requestParameters: GetFloorplanCrowdSizeHistoryRequest, initOverrides?: RequestInit): UseQueryOptions<CrowdSizeHistoryResponse> & {queryKey: QueryKey} => ({
                queryFn: () => this.getFloorplanCrowdSizeHistory(requestParameters, initOverrides),
                queryKey: this.getFloorplanCrowdSizeHistory.queryKey(requestParameters, initOverrides)
            })
        }
    )

    /**
     */
    getFloorplanDwellTimeSample = Object.assign(
        async (requestParameters: GetFloorplanDwellTimeSampleRequest, initOverrides?: RequestInit): Promise<Blob> => {
            const response = await this.getFloorplanDwellTimeSample.invokeRaw(requestParameters, initOverrides);
            return await response.value();
        },
        {
            invokeRaw: async (requestParameters: GetFloorplanDwellTimeSampleRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Blob>> => {
                if (requestParameters.floorplanId === null || requestParameters.floorplanId === undefined) {
                    throw new runtime.RequiredError('floorplanId','Required parameter requestParameters.floorplanId was null or undefined when calling getFloorplanDwellTimeSample.');
                }
            
                const queryParameters: any = {};
            
                const headerParameters: runtime.HTTPHeaders = {};
            
                if (this.configuration && this.configuration.accessToken) {
                    const token = this.configuration.accessToken;
                    const tokenString = await token("token", []);
            
                    if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                    }
                }
                const response = await this.request({
                    path: `/api/statistics/by-floorplan/{floorplan_id}/dwell-times/sample`.replace(`{${"floorplan_id"}}`, encodeURIComponent(String(requestParameters.floorplanId))),
                    method: 'POST',
                    headers: headerParameters,
                    query: queryParameters,
                }, initOverrides);
            
                return new runtime.BlobApiResponse(response);
            },
            mutationKey: [
                'StatisticsApi',
                'getFloorplanDwellTimeSample', 
            ],
            queryKey: (requestParameters: GetFloorplanDwellTimeSampleRequest, initOverrides?: RequestInit) => ([
                    ...this.getFloorplanDwellTimeSample.mutationKey, 
                {
                floorplanId: requestParameters.floorplanId,
                }
            ]),
            query: (requestParameters: GetFloorplanDwellTimeSampleRequest, initOverrides?: RequestInit): UseQueryOptions<Blob> & {queryKey: QueryKey} => ({
                queryFn: () => this.getFloorplanDwellTimeSample(requestParameters, initOverrides),
                queryKey: this.getFloorplanDwellTimeSample.queryKey(requestParameters, initOverrides)
            })
        }
    )

    /**
     * Get the image content of a floorplan heatmap (floorplan image with overlay).
     */
    getFloorplanHeatmapContent = Object.assign(
        async (requestParameters: GetFloorplanHeatmapContentRequest, initOverrides?: RequestInit): Promise<Blob> => {
            const response = await this.getFloorplanHeatmapContent.invokeRaw(requestParameters, initOverrides);
            return await response.value();
        },
        {
            invokeRaw: async (requestParameters: GetFloorplanHeatmapContentRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Blob>> => {
                if (requestParameters.heatmapId === null || requestParameters.heatmapId === undefined) {
                    throw new runtime.RequiredError('heatmapId','Required parameter requestParameters.heatmapId was null or undefined when calling getFloorplanHeatmapContent.');
                }
            
                const queryParameters: any = {};
            
                const headerParameters: runtime.HTTPHeaders = {};
            
                if (this.configuration && this.configuration.accessToken) {
                    const token = this.configuration.accessToken;
                    const tokenString = await token("token", []);
            
                    if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                    }
                }
                const response = await this.request({
                    path: `/api/statistics/heatmaps/{heatmap_id}`.replace(`{${"heatmap_id"}}`, encodeURIComponent(String(requestParameters.heatmapId))),
                    method: 'GET',
                    headers: headerParameters,
                    query: queryParameters,
                }, initOverrides);
            
                return new runtime.BlobApiResponse(response);
            },
            mutationKey: [
                'StatisticsApi',
                'getFloorplanHeatmapContent', 
            ],
            queryKey: (requestParameters: GetFloorplanHeatmapContentRequest, initOverrides?: RequestInit) => ([
                    ...this.getFloorplanHeatmapContent.mutationKey, 
                {
                heatmapId: requestParameters.heatmapId,
                }
            ]),
            query: (requestParameters: GetFloorplanHeatmapContentRequest, initOverrides?: RequestInit): UseQueryOptions<Blob> & {queryKey: QueryKey} => ({
                queryFn: () => this.getFloorplanHeatmapContent(requestParameters, initOverrides),
                queryKey: this.getFloorplanHeatmapContent.queryKey(requestParameters, initOverrides)
            })
        }
    )

    /**
     * List of all floorplan heatmaps generated for given floorplan, oldest first.
     */
    getFloorplanHeatmapList = Object.assign(
        async (requestParameters: GetFloorplanHeatmapListRequest, initOverrides?: RequestInit): Promise<FloorplanHeatmapListResponse> => {
            const response = await this.getFloorplanHeatmapList.invokeRaw(requestParameters, initOverrides);
            return await response.value();
        },
        {
            invokeRaw: async (requestParameters: GetFloorplanHeatmapListRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<FloorplanHeatmapListResponse>> => {
                if (requestParameters.floorplanId === null || requestParameters.floorplanId === undefined) {
                    throw new runtime.RequiredError('floorplanId','Required parameter requestParameters.floorplanId was null or undefined when calling getFloorplanHeatmapList.');
                }
            
                const queryParameters: any = {};
            
                const headerParameters: runtime.HTTPHeaders = {};
            
                if (this.configuration && this.configuration.accessToken) {
                    const token = this.configuration.accessToken;
                    const tokenString = await token("token", []);
            
                    if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                    }
                }
                const response = await this.request({
                    path: `/api/statistics/by-floorplan/{floorplan_id}/heatmaps`.replace(`{${"floorplan_id"}}`, encodeURIComponent(String(requestParameters.floorplanId))),
                    method: 'GET',
                    headers: headerParameters,
                    query: queryParameters,
                }, initOverrides);
            
                return new runtime.JSONApiResponse(response, (jsonValue) => FloorplanHeatmapListResponseFromJSON(jsonValue));
            },
            mutationKey: [
                'StatisticsApi',
                'getFloorplanHeatmapList', 
            ],
            queryKey: (requestParameters: GetFloorplanHeatmapListRequest, initOverrides?: RequestInit) => ([
                    ...this.getFloorplanHeatmapList.mutationKey, 
                {
                floorplanId: requestParameters.floorplanId,
                }
            ]),
            query: (requestParameters: GetFloorplanHeatmapListRequest, initOverrides?: RequestInit): UseQueryOptions<FloorplanHeatmapListResponse> & {queryKey: QueryKey} => ({
                queryFn: () => this.getFloorplanHeatmapList(requestParameters, initOverrides),
                queryKey: this.getFloorplanHeatmapList.queryKey(requestParameters, initOverrides)
            })
        }
    )

    /**
     */
    getFloorplanHeatmapSample = Object.assign(
        async (requestParameters: GetFloorplanHeatmapSampleRequest, initOverrides?: RequestInit): Promise<Blob> => {
            const response = await this.getFloorplanHeatmapSample.invokeRaw(requestParameters, initOverrides);
            return await response.value();
        },
        {
            invokeRaw: async (requestParameters: GetFloorplanHeatmapSampleRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Blob>> => {
                if (requestParameters.floorplanId === null || requestParameters.floorplanId === undefined) {
                    throw new runtime.RequiredError('floorplanId','Required parameter requestParameters.floorplanId was null or undefined when calling getFloorplanHeatmapSample.');
                }
            
                const queryParameters: any = {};
            
                const headerParameters: runtime.HTTPHeaders = {};
            
                if (this.configuration && this.configuration.accessToken) {
                    const token = this.configuration.accessToken;
                    const tokenString = await token("token", []);
            
                    if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                    }
                }
                const response = await this.request({
                    path: `/api/statistics/by-floorplan/{floorplan_id}/heatmaps/sample`.replace(`{${"floorplan_id"}}`, encodeURIComponent(String(requestParameters.floorplanId))),
                    method: 'POST',
                    headers: headerParameters,
                    query: queryParameters,
                }, initOverrides);
            
                return new runtime.BlobApiResponse(response);
            },
            mutationKey: [
                'StatisticsApi',
                'getFloorplanHeatmapSample', 
            ],
            queryKey: (requestParameters: GetFloorplanHeatmapSampleRequest, initOverrides?: RequestInit) => ([
                    ...this.getFloorplanHeatmapSample.mutationKey, 
                {
                floorplanId: requestParameters.floorplanId,
                }
            ]),
            query: (requestParameters: GetFloorplanHeatmapSampleRequest, initOverrides?: RequestInit): UseQueryOptions<Blob> & {queryKey: QueryKey} => ({
                queryFn: () => this.getFloorplanHeatmapSample(requestParameters, initOverrides),
                queryKey: this.getFloorplanHeatmapSample.queryKey(requestParameters, initOverrides)
            })
        }
    )

    /**
     */
    getFloorplanHeatmapSingleSceneSample = Object.assign(
        async (requestParameters: GetFloorplanHeatmapSingleSceneSampleRequest, initOverrides?: RequestInit): Promise<Blob> => {
            const response = await this.getFloorplanHeatmapSingleSceneSample.invokeRaw(requestParameters, initOverrides);
            return await response.value();
        },
        {
            invokeRaw: async (requestParameters: GetFloorplanHeatmapSingleSceneSampleRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Blob>> => {
                if (requestParameters.floorplanId === null || requestParameters.floorplanId === undefined) {
                    throw new runtime.RequiredError('floorplanId','Required parameter requestParameters.floorplanId was null or undefined when calling getFloorplanHeatmapSingleSceneSample.');
                }
            
                if (requestParameters.sceneId === null || requestParameters.sceneId === undefined) {
                    throw new runtime.RequiredError('sceneId','Required parameter requestParameters.sceneId was null or undefined when calling getFloorplanHeatmapSingleSceneSample.');
                }
            
                const queryParameters: any = {};
            
                const headerParameters: runtime.HTTPHeaders = {};
            
                if (this.configuration && this.configuration.accessToken) {
                    const token = this.configuration.accessToken;
                    const tokenString = await token("token", []);
            
                    if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                    }
                }
                const response = await this.request({
                    path: `/api/statistics/by-floorplan/{floorplan_id}/heatmaps/sample/{scene_id}`.replace(`{${"floorplan_id"}}`, encodeURIComponent(String(requestParameters.floorplanId))).replace(`{${"scene_id"}}`, encodeURIComponent(String(requestParameters.sceneId))),
                    method: 'POST',
                    headers: headerParameters,
                    query: queryParameters,
                }, initOverrides);
            
                return new runtime.BlobApiResponse(response);
            },
            mutationKey: [
                'StatisticsApi',
                'getFloorplanHeatmapSingleSceneSample', 
            ],
            queryKey: (requestParameters: GetFloorplanHeatmapSingleSceneSampleRequest, initOverrides?: RequestInit) => ([
                    ...this.getFloorplanHeatmapSingleSceneSample.mutationKey, 
                {
                floorplanId: requestParameters.floorplanId,
                sceneId: requestParameters.sceneId,
                }
            ]),
            query: (requestParameters: GetFloorplanHeatmapSingleSceneSampleRequest, initOverrides?: RequestInit): UseQueryOptions<Blob> & {queryKey: QueryKey} => ({
                queryFn: () => this.getFloorplanHeatmapSingleSceneSample(requestParameters, initOverrides),
                queryKey: this.getFloorplanHeatmapSingleSceneSample.queryKey(requestParameters, initOverrides)
            })
        }
    )

    /**
     * End-point not implemented, use `subscribe_to_locality_floorplan_crowd_size` instead.
     */
    getFloorplanLocalityCrowdSize = Object.assign(
        async (requestParameters: GetFloorplanLocalityCrowdSizeRequest, initOverrides?: RequestInit): Promise<FloorplanCrowdSizeResponse> => {
            const response = await this.getFloorplanLocalityCrowdSize.invokeRaw(requestParameters, initOverrides);
            return await response.value();
        },
        {
            invokeRaw: async (requestParameters: GetFloorplanLocalityCrowdSizeRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<FloorplanCrowdSizeResponse>> => {
                if (requestParameters.localityId === null || requestParameters.localityId === undefined) {
                    throw new runtime.RequiredError('localityId','Required parameter requestParameters.localityId was null or undefined when calling getFloorplanLocalityCrowdSize.');
                }
            
                const queryParameters: any = {};
            
                const headerParameters: runtime.HTTPHeaders = {};
            
                const response = await this.request({
                    path: `/api/statistics/by-locality/{locality_id}/floorplan_crowd_size`.replace(`{${"locality_id"}}`, encodeURIComponent(String(requestParameters.localityId))),
                    method: 'GET',
                    headers: headerParameters,
                    query: queryParameters,
                }, initOverrides);
            
                return new runtime.JSONApiResponse(response, (jsonValue) => FloorplanCrowdSizeResponseFromJSON(jsonValue));
            },
            mutationKey: [
                'StatisticsApi',
                'getFloorplanLocalityCrowdSize', 
            ],
            queryKey: (requestParameters: GetFloorplanLocalityCrowdSizeRequest, initOverrides?: RequestInit) => ([
                    ...this.getFloorplanLocalityCrowdSize.mutationKey, 
                {
                localityId: requestParameters.localityId,
                }
            ]),
            query: (requestParameters: GetFloorplanLocalityCrowdSizeRequest, initOverrides?: RequestInit): UseQueryOptions<FloorplanCrowdSizeResponse> & {queryKey: QueryKey} => ({
                queryFn: () => this.getFloorplanLocalityCrowdSize(requestParameters, initOverrides),
                queryKey: this.getFloorplanLocalityCrowdSize.queryKey(requestParameters, initOverrides)
            })
        }
    )

    /**
     */
    getFloorplanUtilizationGridSample = Object.assign(
        async (requestParameters: GetFloorplanUtilizationGridSampleRequest, initOverrides?: RequestInit): Promise<Blob> => {
            const response = await this.getFloorplanUtilizationGridSample.invokeRaw(requestParameters, initOverrides);
            return await response.value();
        },
        {
            invokeRaw: async (requestParameters: GetFloorplanUtilizationGridSampleRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Blob>> => {
                if (requestParameters.floorplanId === null || requestParameters.floorplanId === undefined) {
                    throw new runtime.RequiredError('floorplanId','Required parameter requestParameters.floorplanId was null or undefined when calling getFloorplanUtilizationGridSample.');
                }
            
                const queryParameters: any = {};
            
                const headerParameters: runtime.HTTPHeaders = {};
            
                if (this.configuration && this.configuration.accessToken) {
                    const token = this.configuration.accessToken;
                    const tokenString = await token("token", []);
            
                    if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                    }
                }
                const response = await this.request({
                    path: `/api/statistics/by-floorplan/{floorplan_id}/utilization-grids/sample`.replace(`{${"floorplan_id"}}`, encodeURIComponent(String(requestParameters.floorplanId))),
                    method: 'POST',
                    headers: headerParameters,
                    query: queryParameters,
                }, initOverrides);
            
                return new runtime.BlobApiResponse(response);
            },
            mutationKey: [
                'StatisticsApi',
                'getFloorplanUtilizationGridSample', 
            ],
            queryKey: (requestParameters: GetFloorplanUtilizationGridSampleRequest, initOverrides?: RequestInit) => ([
                    ...this.getFloorplanUtilizationGridSample.mutationKey, 
                {
                floorplanId: requestParameters.floorplanId,
                }
            ]),
            query: (requestParameters: GetFloorplanUtilizationGridSampleRequest, initOverrides?: RequestInit): UseQueryOptions<Blob> & {queryKey: QueryKey} => ({
                queryFn: () => this.getFloorplanUtilizationGridSample(requestParameters, initOverrides),
                queryKey: this.getFloorplanUtilizationGridSample.queryKey(requestParameters, initOverrides)
            })
        }
    )

    /**
     * Get a multi-locality overview of footfall statistics
     */
    getFootfallOverview = Object.assign(
        async (requestParameters: GetFootfallOverviewRequest, initOverrides?: RequestInit): Promise<FootfallOverviewResponse> => {
            const response = await this.getFootfallOverview.invokeRaw(requestParameters, initOverrides);
            return await response.value();
        },
        {
            invokeRaw: async (requestParameters: GetFootfallOverviewRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<FootfallOverviewResponse>> => {
                if (requestParameters.body === null || requestParameters.body === undefined) {
                    throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling getFootfallOverview.');
                }
            
                const queryParameters: any = {};
            
                const headerParameters: runtime.HTTPHeaders = {};
            
                headerParameters['Content-Type'] = 'application/json';
            
                if (this.configuration && this.configuration.accessToken) {
                    const token = this.configuration.accessToken;
                    const tokenString = await token("token", []);
            
                    if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                    }
                }
                const response = await this.request({
                    path: `/api/statistics/footfall/overview`,
                    method: 'POST',
                    headers: headerParameters,
                    query: queryParameters,
                    body: FootfallOverviewRequestBodyToJSON(requestParameters.body),
                }, initOverrides);
            
                return new runtime.JSONApiResponse(response, (jsonValue) => FootfallOverviewResponseFromJSON(jsonValue));
            },
            mutationKey: [
                'StatisticsApi',
                'getFootfallOverview', 
            ],
            queryKey: (requestParameters: GetFootfallOverviewRequest, initOverrides?: RequestInit) => ([
                    ...this.getFootfallOverview.mutationKey, 
                {
                body: requestParameters.body,
                }
            ]),
            query: (requestParameters: GetFootfallOverviewRequest, initOverrides?: RequestInit): UseQueryOptions<FootfallOverviewResponse> & {queryKey: QueryKey} => ({
                queryFn: () => this.getFootfallOverview(requestParameters, initOverrides),
                queryKey: this.getFootfallOverview.queryKey(requestParameters, initOverrides)
            })
        }
    )

    /**
     * Get the current crowd size for a locality.
     */
    getLocalityCrowdSize = Object.assign(
        async (requestParameters: GetLocalityCrowdSizeRequest, initOverrides?: RequestInit): Promise<CrowdSizeResponse> => {
            const response = await this.getLocalityCrowdSize.invokeRaw(requestParameters, initOverrides);
            return await response.value();
        },
        {
            invokeRaw: async (requestParameters: GetLocalityCrowdSizeRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CrowdSizeResponse>> => {
                if (requestParameters.localityId === null || requestParameters.localityId === undefined) {
                    throw new runtime.RequiredError('localityId','Required parameter requestParameters.localityId was null or undefined when calling getLocalityCrowdSize.');
                }
            
                const queryParameters: any = {};
            
                if (requestParameters.at !== undefined) {
                    queryParameters['at'] = requestParameters.at;
                }
            
                const headerParameters: runtime.HTTPHeaders = {};
            
                const response = await this.request({
                    path: `/api/statistics/by-locality/{locality_id}/crowd_size`.replace(`{${"locality_id"}}`, encodeURIComponent(String(requestParameters.localityId))),
                    method: 'GET',
                    headers: headerParameters,
                    query: queryParameters,
                }, initOverrides);
            
                return new runtime.JSONApiResponse(response, (jsonValue) => CrowdSizeResponseFromJSON(jsonValue));
            },
            mutationKey: [
                'StatisticsApi',
                'getLocalityCrowdSize', 
            ],
            queryKey: (requestParameters: GetLocalityCrowdSizeRequest, initOverrides?: RequestInit) => ([
                    ...this.getLocalityCrowdSize.mutationKey, 
                {
                localityId: requestParameters.localityId,
                at: requestParameters.at,
                }
            ]),
            query: (requestParameters: GetLocalityCrowdSizeRequest, initOverrides?: RequestInit): UseQueryOptions<CrowdSizeResponse> & {queryKey: QueryKey} => ({
                queryFn: () => this.getLocalityCrowdSize(requestParameters, initOverrides),
                queryKey: this.getLocalityCrowdSize.queryKey(requestParameters, initOverrides)
            })
        }
    )

    /**
     * Get a summary of visitor movement (in/out), broken down by visit boundaries.
     */
    getMovementByBoundary = Object.assign(
        async (requestParameters: GetMovementByBoundaryRequest, initOverrides?: RequestInit): Promise<MovementByBoundaryResponse> => {
            const response = await this.getMovementByBoundary.invokeRaw(requestParameters, initOverrides);
            return await response.value();
        },
        {
            invokeRaw: async (requestParameters: GetMovementByBoundaryRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<MovementByBoundaryResponse>> => {
                if (requestParameters.body === null || requestParameters.body === undefined) {
                    throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling getMovementByBoundary.');
                }
            
                const queryParameters: any = {};
            
                const headerParameters: runtime.HTTPHeaders = {};
            
                headerParameters['Content-Type'] = 'application/json';
            
                if (this.configuration && this.configuration.accessToken) {
                    const token = this.configuration.accessToken;
                    const tokenString = await token("token", []);
            
                    if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                    }
                }
                const response = await this.request({
                    path: `/api/statistics/footfall/movement/by-boundary`,
                    method: 'POST',
                    headers: headerParameters,
                    query: queryParameters,
                    body: MovementByBoundaryRequestBodyToJSON(requestParameters.body),
                }, initOverrides);
            
                return new runtime.JSONApiResponse(response, (jsonValue) => MovementByBoundaryResponseFromJSON(jsonValue));
            },
            mutationKey: [
                'StatisticsApi',
                'getMovementByBoundary', 
            ],
            queryKey: (requestParameters: GetMovementByBoundaryRequest, initOverrides?: RequestInit) => ([
                    ...this.getMovementByBoundary.mutationKey, 
                {
                body: requestParameters.body,
                }
            ]),
            query: (requestParameters: GetMovementByBoundaryRequest, initOverrides?: RequestInit): UseQueryOptions<MovementByBoundaryResponse> & {queryKey: QueryKey} => ({
                queryFn: () => this.getMovementByBoundary(requestParameters, initOverrides),
                queryKey: this.getMovementByBoundary.queryKey(requestParameters, initOverrides)
            })
        }
    )

    /**
     * Get a summary of visitor movement.
     */
    getMovementSummary = Object.assign(
        async (requestParameters: GetMovementSummaryRequest, initOverrides?: RequestInit): Promise<MovementResponse> => {
            const response = await this.getMovementSummary.invokeRaw(requestParameters, initOverrides);
            return await response.value();
        },
        {
            invokeRaw: async (requestParameters: GetMovementSummaryRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<MovementResponse>> => {
                if (requestParameters.body === null || requestParameters.body === undefined) {
                    throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling getMovementSummary.');
                }
            
                const queryParameters: any = {};
            
                const headerParameters: runtime.HTTPHeaders = {};
            
                headerParameters['Content-Type'] = 'application/json';
            
                if (this.configuration && this.configuration.accessToken) {
                    const token = this.configuration.accessToken;
                    const tokenString = await token("token", []);
            
                    if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                    }
                }
                const response = await this.request({
                    path: `/api/statistics/movements/summary`,
                    method: 'POST',
                    headers: headerParameters,
                    query: queryParameters,
                    body: MovementSummaryRequestBodyToJSON(requestParameters.body),
                }, initOverrides);
            
                return new runtime.JSONApiResponse(response, (jsonValue) => MovementResponseFromJSON(jsonValue));
            },
            mutationKey: [
                'StatisticsApi',
                'getMovementSummary', 
            ],
            queryKey: (requestParameters: GetMovementSummaryRequest, initOverrides?: RequestInit) => ([
                    ...this.getMovementSummary.mutationKey, 
                {
                body: requestParameters.body,
                }
            ]),
            query: (requestParameters: GetMovementSummaryRequest, initOverrides?: RequestInit): UseQueryOptions<MovementResponse> & {queryKey: QueryKey} => ({
                queryFn: () => this.getMovementSummary(requestParameters, initOverrides),
                queryKey: this.getMovementSummary.queryKey(requestParameters, initOverrides)
            })
        }
    )

    /**
     * Get a list of outages for multiple scenes (intervals when no observations arrived).
     */
    getMultiDeviceOutages = Object.assign(
        async (requestParameters: GetMultiDeviceOutagesRequest, initOverrides?: RequestInit): Promise<OutagesResponse> => {
            const response = await this.getMultiDeviceOutages.invokeRaw(requestParameters, initOverrides);
            return await response.value();
        },
        {
            invokeRaw: async (requestParameters: GetMultiDeviceOutagesRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<OutagesResponse>> => {
                if (requestParameters.body === null || requestParameters.body === undefined) {
                    throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling getMultiDeviceOutages.');
                }
            
                const queryParameters: any = {};
            
                const headerParameters: runtime.HTTPHeaders = {};
            
                headerParameters['Content-Type'] = 'application/json';
            
                if (this.configuration && this.configuration.accessToken) {
                    const token = this.configuration.accessToken;
                    const tokenString = await token("token", []);
            
                    if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                    }
                }
                const response = await this.request({
                    path: `/api/statistics/outages`,
                    method: 'POST',
                    headers: headerParameters,
                    query: queryParameters,
                    body: MultiDeviceOutagesRequestBodyToJSON(requestParameters.body),
                }, initOverrides);
            
                return new runtime.JSONApiResponse(response, (jsonValue) => OutagesResponseFromJSON(jsonValue));
            },
            mutationKey: [
                'StatisticsApi',
                'getMultiDeviceOutages', 
            ],
            queryKey: (requestParameters: GetMultiDeviceOutagesRequest, initOverrides?: RequestInit) => ([
                    ...this.getMultiDeviceOutages.mutationKey, 
                {
                body: requestParameters.body,
                }
            ]),
            query: (requestParameters: GetMultiDeviceOutagesRequest, initOverrides?: RequestInit): UseQueryOptions<OutagesResponse> & {queryKey: QueryKey} => ({
                queryFn: () => this.getMultiDeviceOutages(requestParameters, initOverrides),
                queryKey: this.getMultiDeviceOutages.queryKey(requestParameters, initOverrides)
            })
        }
    )

    /**
     * Get a list of outages for a scene (intervals when no observations arrived).
     */
    getOutages = Object.assign(
        async (requestParameters: GetOutagesRequest, initOverrides?: RequestInit): Promise<OutagesResponse> => {
            const response = await this.getOutages.invokeRaw(requestParameters, initOverrides);
            return await response.value();
        },
        {
            invokeRaw: async (requestParameters: GetOutagesRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<OutagesResponse>> => {
                if (requestParameters.sceneId === null || requestParameters.sceneId === undefined) {
                    throw new runtime.RequiredError('sceneId','Required parameter requestParameters.sceneId was null or undefined when calling getOutages.');
                }
            
                if (requestParameters.body === null || requestParameters.body === undefined) {
                    throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling getOutages.');
                }
            
                const queryParameters: any = {};
            
                const headerParameters: runtime.HTTPHeaders = {};
            
                headerParameters['Content-Type'] = 'application/json';
            
                if (this.configuration && this.configuration.accessToken) {
                    const token = this.configuration.accessToken;
                    const tokenString = await token("token", []);
            
                    if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                    }
                }
                const response = await this.request({
                    path: `/api/statistics/{scene_id}/outages`.replace(`{${"scene_id"}}`, encodeURIComponent(String(requestParameters.sceneId))),
                    method: 'POST',
                    headers: headerParameters,
                    query: queryParameters,
                    body: DatetimeIntervalToJSON(requestParameters.body),
                }, initOverrides);
            
                return new runtime.JSONApiResponse(response, (jsonValue) => OutagesResponseFromJSON(jsonValue));
            },
            mutationKey: [
                'StatisticsApi',
                'getOutages', 
            ],
            queryKey: (requestParameters: GetOutagesRequest, initOverrides?: RequestInit) => ([
                    ...this.getOutages.mutationKey, 
                {
                sceneId: requestParameters.sceneId,
                body: requestParameters.body,
                }
            ]),
            query: (requestParameters: GetOutagesRequest, initOverrides?: RequestInit): UseQueryOptions<OutagesResponse> & {queryKey: QueryKey} => ({
                queryFn: () => this.getOutages(requestParameters, initOverrides),
                queryKey: this.getOutages.queryKey(requestParameters, initOverrides)
            })
        }
    )

    /**
     * Get a summary of properties of entities observed in a time interval, broken down by visit boundaries.
     */
    getPropertiesByBoundary = Object.assign(
        async (requestParameters: GetPropertiesByBoundaryRequest, initOverrides?: RequestInit): Promise<PropertiesByBoundaryResponse> => {
            const response = await this.getPropertiesByBoundary.invokeRaw(requestParameters, initOverrides);
            return await response.value();
        },
        {
            invokeRaw: async (requestParameters: GetPropertiesByBoundaryRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PropertiesByBoundaryResponse>> => {
                if (requestParameters.body === null || requestParameters.body === undefined) {
                    throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling getPropertiesByBoundary.');
                }
            
                const queryParameters: any = {};
            
                const headerParameters: runtime.HTTPHeaders = {};
            
                headerParameters['Content-Type'] = 'application/json';
            
                if (this.configuration && this.configuration.accessToken) {
                    const token = this.configuration.accessToken;
                    const tokenString = await token("token", []);
            
                    if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                    }
                }
                const response = await this.request({
                    path: `/api/statistics/footfall/properties/by-boundary`,
                    method: 'POST',
                    headers: headerParameters,
                    query: queryParameters,
                    body: PropertySummaryRequestBodyToJSON(requestParameters.body),
                }, initOverrides);
            
                return new runtime.JSONApiResponse(response, (jsonValue) => PropertiesByBoundaryResponseFromJSON(jsonValue));
            },
            mutationKey: [
                'StatisticsApi',
                'getPropertiesByBoundary', 
            ],
            queryKey: (requestParameters: GetPropertiesByBoundaryRequest, initOverrides?: RequestInit) => ([
                    ...this.getPropertiesByBoundary.mutationKey, 
                {
                body: requestParameters.body,
                }
            ]),
            query: (requestParameters: GetPropertiesByBoundaryRequest, initOverrides?: RequestInit): UseQueryOptions<PropertiesByBoundaryResponse> & {queryKey: QueryKey} => ({
                queryFn: () => this.getPropertiesByBoundary(requestParameters, initOverrides),
                queryKey: this.getPropertiesByBoundary.queryKey(requestParameters, initOverrides)
            })
        }
    )

    /**
     * Get a breakdown of visitor counts observed in a time interval, based on a cross product of given property categories.
     */
    getPropertyBreakdown = Object.assign(
        async (requestParameters: GetPropertyBreakdownRequest, initOverrides?: RequestInit): Promise<PropertyBreakdownResponse> => {
            const response = await this.getPropertyBreakdown.invokeRaw(requestParameters, initOverrides);
            return await response.value();
        },
        {
            invokeRaw: async (requestParameters: GetPropertyBreakdownRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PropertyBreakdownResponse>> => {
                if (requestParameters.body === null || requestParameters.body === undefined) {
                    throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling getPropertyBreakdown.');
                }
            
                const queryParameters: any = {};
            
                const headerParameters: runtime.HTTPHeaders = {};
            
                headerParameters['Content-Type'] = 'application/json';
            
                if (this.configuration && this.configuration.accessToken) {
                    const token = this.configuration.accessToken;
                    const tokenString = await token("token", []);
            
                    if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                    }
                }
                const response = await this.request({
                    path: `/api/statistics/footfall/properties/breakdown`,
                    method: 'POST',
                    headers: headerParameters,
                    query: queryParameters,
                    body: PropertySummaryRequestBodyToJSON(requestParameters.body),
                }, initOverrides);
            
                return new runtime.JSONApiResponse(response, (jsonValue) => PropertyBreakdownResponseFromJSON(jsonValue));
            },
            mutationKey: [
                'StatisticsApi',
                'getPropertyBreakdown', 
            ],
            queryKey: (requestParameters: GetPropertyBreakdownRequest, initOverrides?: RequestInit) => ([
                    ...this.getPropertyBreakdown.mutationKey, 
                {
                body: requestParameters.body,
                }
            ]),
            query: (requestParameters: GetPropertyBreakdownRequest, initOverrides?: RequestInit): UseQueryOptions<PropertyBreakdownResponse> & {queryKey: QueryKey} => ({
                queryFn: () => this.getPropertyBreakdown(requestParameters, initOverrides),
                queryKey: this.getPropertyBreakdown.queryKey(requestParameters, initOverrides)
            })
        }
    )

    /**
     * Get a per-boundary breakdown of visitor counts observed in a time interval, based on a cross product of given property categories.
     */
    getPropertyBreakdownByBoundary = Object.assign(
        async (requestParameters: GetPropertyBreakdownByBoundaryRequest, initOverrides?: RequestInit): Promise<PropertyBreakdownByBoundaryResponse> => {
            const response = await this.getPropertyBreakdownByBoundary.invokeRaw(requestParameters, initOverrides);
            return await response.value();
        },
        {
            invokeRaw: async (requestParameters: GetPropertyBreakdownByBoundaryRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PropertyBreakdownByBoundaryResponse>> => {
                if (requestParameters.body === null || requestParameters.body === undefined) {
                    throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling getPropertyBreakdownByBoundary.');
                }
            
                const queryParameters: any = {};
            
                const headerParameters: runtime.HTTPHeaders = {};
            
                headerParameters['Content-Type'] = 'application/json';
            
                if (this.configuration && this.configuration.accessToken) {
                    const token = this.configuration.accessToken;
                    const tokenString = await token("token", []);
            
                    if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                    }
                }
                const response = await this.request({
                    path: `/api/statistics/footfall/properties/breakdown-by-boundary`,
                    method: 'POST',
                    headers: headerParameters,
                    query: queryParameters,
                    body: PropertySummaryRequestBodyToJSON(requestParameters.body),
                }, initOverrides);
            
                return new runtime.JSONApiResponse(response, (jsonValue) => PropertyBreakdownByBoundaryResponseFromJSON(jsonValue));
            },
            mutationKey: [
                'StatisticsApi',
                'getPropertyBreakdownByBoundary', 
            ],
            queryKey: (requestParameters: GetPropertyBreakdownByBoundaryRequest, initOverrides?: RequestInit) => ([
                    ...this.getPropertyBreakdownByBoundary.mutationKey, 
                {
                body: requestParameters.body,
                }
            ]),
            query: (requestParameters: GetPropertyBreakdownByBoundaryRequest, initOverrides?: RequestInit): UseQueryOptions<PropertyBreakdownByBoundaryResponse> & {queryKey: QueryKey} => ({
                queryFn: () => this.getPropertyBreakdownByBoundary(requestParameters, initOverrides),
                queryKey: this.getPropertyBreakdownByBoundary.queryKey(requestParameters, initOverrides)
            })
        }
    )

    /**
     * Get a summary of properties of entities observed in a time interval.
     */
    getPropertySummary = Object.assign(
        async (requestParameters: GetPropertySummaryRequest, initOverrides?: RequestInit): Promise<PropertySummaryResponse> => {
            const response = await this.getPropertySummary.invokeRaw(requestParameters, initOverrides);
            return await response.value();
        },
        {
            invokeRaw: async (requestParameters: GetPropertySummaryRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PropertySummaryResponse>> => {
                if (requestParameters.body === null || requestParameters.body === undefined) {
                    throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling getPropertySummary.');
                }
            
                const queryParameters: any = {};
            
                const headerParameters: runtime.HTTPHeaders = {};
            
                headerParameters['Content-Type'] = 'application/json';
            
                if (this.configuration && this.configuration.accessToken) {
                    const token = this.configuration.accessToken;
                    const tokenString = await token("token", []);
            
                    if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                    }
                }
                const response = await this.request({
                    path: `/api/statistics/properties/summary`,
                    method: 'POST',
                    headers: headerParameters,
                    query: queryParameters,
                    body: PropertySummaryRequestBodyToJSON(requestParameters.body),
                }, initOverrides);
            
                return new runtime.JSONApiResponse(response, (jsonValue) => PropertySummaryResponseFromJSON(jsonValue));
            },
            mutationKey: [
                'StatisticsApi',
                'getPropertySummary', 
            ],
            queryKey: (requestParameters: GetPropertySummaryRequest, initOverrides?: RequestInit) => ([
                    ...this.getPropertySummary.mutationKey, 
                {
                body: requestParameters.body,
                }
            ]),
            query: (requestParameters: GetPropertySummaryRequest, initOverrides?: RequestInit): UseQueryOptions<PropertySummaryResponse> & {queryKey: QueryKey} => ({
                queryFn: () => this.getPropertySummary(requestParameters, initOverrides),
                queryKey: this.getPropertySummary.queryKey(requestParameters, initOverrides)
            })
        }
    )

    /**
     */
    getQueueStatistics = Object.assign(
        async (requestParameters: GetQueueStatisticsRequest, initOverrides?: RequestInit): Promise<QueueStatisticsResponse> => {
            const response = await this.getQueueStatistics.invokeRaw(requestParameters, initOverrides);
            return await response.value();
        },
        {
            invokeRaw: async (requestParameters: GetQueueStatisticsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<QueueStatisticsResponse>> => {
                if (requestParameters.body === null || requestParameters.body === undefined) {
                    throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling getQueueStatistics.');
                }
            
                const queryParameters: any = {};
            
                const headerParameters: runtime.HTTPHeaders = {};
            
                headerParameters['Content-Type'] = 'application/json';
            
                if (this.configuration && this.configuration.accessToken) {
                    const token = this.configuration.accessToken;
                    const tokenString = await token("token", []);
            
                    if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                    }
                }
                const response = await this.request({
                    path: `/api/statistics/queue-monitoring/summary`,
                    method: 'POST',
                    headers: headerParameters,
                    query: queryParameters,
                    body: LocalitySummaryRequestBodyToJSON(requestParameters.body),
                }, initOverrides);
            
                return new runtime.JSONApiResponse(response, (jsonValue) => QueueStatisticsResponseFromJSON(jsonValue));
            },
            mutationKey: [
                'StatisticsApi',
                'getQueueStatistics', 
            ],
            queryKey: (requestParameters: GetQueueStatisticsRequest, initOverrides?: RequestInit) => ([
                    ...this.getQueueStatistics.mutationKey, 
                {
                body: requestParameters.body,
                }
            ]),
            query: (requestParameters: GetQueueStatisticsRequest, initOverrides?: RequestInit): UseQueryOptions<QueueStatisticsResponse> & {queryKey: QueryKey} => ({
                queryFn: () => this.getQueueStatistics(requestParameters, initOverrides),
                queryKey: this.getQueueStatistics.queryKey(requestParameters, initOverrides)
            })
        }
    )

    /**
     */
    getSalesStatisticsDetails = Object.assign(
        async (requestParameters: GetSalesStatisticsDetailsRequest, initOverrides?: RequestInit): Promise<SalesStatisticsDetailsSummaryResponse> => {
            const response = await this.getSalesStatisticsDetails.invokeRaw(requestParameters, initOverrides);
            return await response.value();
        },
        {
            invokeRaw: async (requestParameters: GetSalesStatisticsDetailsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SalesStatisticsDetailsSummaryResponse>> => {
                if (requestParameters.body === null || requestParameters.body === undefined) {
                    throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling getSalesStatisticsDetails.');
                }
            
                const queryParameters: any = {};
            
                const headerParameters: runtime.HTTPHeaders = {};
            
                headerParameters['Content-Type'] = 'application/json';
            
                if (this.configuration && this.configuration.accessToken) {
                    const token = this.configuration.accessToken;
                    const tokenString = await token("token", []);
            
                    if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                    }
                }
                const response = await this.request({
                    path: `/api/statistics/sales-details/summary`,
                    method: 'POST',
                    headers: headerParameters,
                    query: queryParameters,
                    body: LocalitySummaryRequestBodyToJSON(requestParameters.body),
                }, initOverrides);
            
                return new runtime.JSONApiResponse(response, (jsonValue) => SalesStatisticsDetailsSummaryResponseFromJSON(jsonValue));
            },
            mutationKey: [
                'StatisticsApi',
                'getSalesStatisticsDetails', 
            ],
            queryKey: (requestParameters: GetSalesStatisticsDetailsRequest, initOverrides?: RequestInit) => ([
                    ...this.getSalesStatisticsDetails.mutationKey, 
                {
                body: requestParameters.body,
                }
            ]),
            query: (requestParameters: GetSalesStatisticsDetailsRequest, initOverrides?: RequestInit): UseQueryOptions<SalesStatisticsDetailsSummaryResponse> & {queryKey: QueryKey} => ({
                queryFn: () => this.getSalesStatisticsDetails(requestParameters, initOverrides),
                queryKey: this.getSalesStatisticsDetails.queryKey(requestParameters, initOverrides)
            })
        }
    )

    /**
     * Get a summary of currently visible entities in a locality, broken down by scene and zone.
     */
    getVisibleEntities = Object.assign(
        async (requestParameters: GetVisibleEntitiesRequest, initOverrides?: RequestInit): Promise<LocalityVisibleEntitiesResponse> => {
            const response = await this.getVisibleEntities.invokeRaw(requestParameters, initOverrides);
            return await response.value();
        },
        {
            invokeRaw: async (requestParameters: GetVisibleEntitiesRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<LocalityVisibleEntitiesResponse>> => {
                if (requestParameters.localityId === null || requestParameters.localityId === undefined) {
                    throw new runtime.RequiredError('localityId','Required parameter requestParameters.localityId was null or undefined when calling getVisibleEntities.');
                }
            
                const queryParameters: any = {};
            
                const headerParameters: runtime.HTTPHeaders = {};
            
                if (this.configuration && this.configuration.accessToken) {
                    const token = this.configuration.accessToken;
                    const tokenString = await token("token", []);
            
                    if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                    }
                }
                const response = await this.request({
                    path: `/api/statistics/by-locality/{locality_id}/visible-entities`.replace(`{${"locality_id"}}`, encodeURIComponent(String(requestParameters.localityId))),
                    method: 'GET',
                    headers: headerParameters,
                    query: queryParameters,
                }, initOverrides);
            
                return new runtime.JSONApiResponse(response, (jsonValue) => LocalityVisibleEntitiesResponseFromJSON(jsonValue));
            },
            mutationKey: [
                'StatisticsApi',
                'getVisibleEntities', 
            ],
            queryKey: (requestParameters: GetVisibleEntitiesRequest, initOverrides?: RequestInit) => ([
                    ...this.getVisibleEntities.mutationKey, 
                {
                localityId: requestParameters.localityId,
                }
            ]),
            query: (requestParameters: GetVisibleEntitiesRequest, initOverrides?: RequestInit): UseQueryOptions<LocalityVisibleEntitiesResponse> & {queryKey: QueryKey} => ({
                queryFn: () => this.getVisibleEntities(requestParameters, initOverrides),
                queryKey: this.getVisibleEntities.queryKey(requestParameters, initOverrides)
            })
        }
    )

    /**
     * Get a list of occupancy sessions in a given interval.
     * A session is a time-period with uninterrupted (except for configurable breaks) presence of at least one person. The number of people may change during a session.
     */
    getZoneOccupancySessions = Object.assign(
        async (requestParameters: GetZoneOccupancySessionsRequest, initOverrides?: RequestInit): Promise<ZoneOccupancySessionsResponse> => {
            const response = await this.getZoneOccupancySessions.invokeRaw(requestParameters, initOverrides);
            return await response.value();
        },
        {
            invokeRaw: async (requestParameters: GetZoneOccupancySessionsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ZoneOccupancySessionsResponse>> => {
                if (requestParameters.body === null || requestParameters.body === undefined) {
                    throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling getZoneOccupancySessions.');
                }
            
                const queryParameters: any = {};
            
                const headerParameters: runtime.HTTPHeaders = {};
            
                headerParameters['Content-Type'] = 'application/json';
            
                if (this.configuration && this.configuration.accessToken) {
                    const token = this.configuration.accessToken;
                    const tokenString = await token("token", []);
            
                    if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                    }
                }
                const response = await this.request({
                    path: `/api/statistics/zone-occupancy-sessions`,
                    method: 'POST',
                    headers: headerParameters,
                    query: queryParameters,
                    body: ZoneOccupancySessionsRequestBodyToJSON(requestParameters.body),
                }, initOverrides);
            
                return new runtime.JSONApiResponse(response, (jsonValue) => ZoneOccupancySessionsResponseFromJSON(jsonValue));
            },
            mutationKey: [
                'StatisticsApi',
                'getZoneOccupancySessions', 
            ],
            queryKey: (requestParameters: GetZoneOccupancySessionsRequest, initOverrides?: RequestInit) => ([
                    ...this.getZoneOccupancySessions.mutationKey, 
                {
                body: requestParameters.body,
                }
            ]),
            query: (requestParameters: GetZoneOccupancySessionsRequest, initOverrides?: RequestInit): UseQueryOptions<ZoneOccupancySessionsResponse> & {queryKey: QueryKey} => ({
                queryFn: () => this.getZoneOccupancySessions(requestParameters, initOverrides),
                queryKey: this.getZoneOccupancySessions.queryKey(requestParameters, initOverrides)
            })
        }
    )

    /**
     * Get a summary of zone occupancy in time for a locality.
     */
    getZoneOccupancySummary = Object.assign(
        async (requestParameters: GetZoneOccupancySummaryRequest, initOverrides?: RequestInit): Promise<ZoneOccupancySummaryResponse> => {
            const response = await this.getZoneOccupancySummary.invokeRaw(requestParameters, initOverrides);
            return await response.value();
        },
        {
            invokeRaw: async (requestParameters: GetZoneOccupancySummaryRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ZoneOccupancySummaryResponse>> => {
                if (requestParameters.body === null || requestParameters.body === undefined) {
                    throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling getZoneOccupancySummary.');
                }
            
                const queryParameters: any = {};
            
                const headerParameters: runtime.HTTPHeaders = {};
            
                headerParameters['Content-Type'] = 'application/json';
            
                if (this.configuration && this.configuration.accessToken) {
                    const token = this.configuration.accessToken;
                    const tokenString = await token("token", []);
            
                    if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                    }
                }
                const response = await this.request({
                    path: `/api/statistics/zone_occupancy_summary`,
                    method: 'POST',
                    headers: headerParameters,
                    query: queryParameters,
                    body: ZoneOccupancySummaryRequestBodyToJSON(requestParameters.body),
                }, initOverrides);
            
                return new runtime.JSONApiResponse(response, (jsonValue) => ZoneOccupancySummaryResponseFromJSON(jsonValue));
            },
            mutationKey: [
                'StatisticsApi',
                'getZoneOccupancySummary', 
            ],
            queryKey: (requestParameters: GetZoneOccupancySummaryRequest, initOverrides?: RequestInit) => ([
                    ...this.getZoneOccupancySummary.mutationKey, 
                {
                body: requestParameters.body,
                }
            ]),
            query: (requestParameters: GetZoneOccupancySummaryRequest, initOverrides?: RequestInit): UseQueryOptions<ZoneOccupancySummaryResponse> & {queryKey: QueryKey} => ({
                queryFn: () => this.getZoneOccupancySummary(requestParameters, initOverrides),
                queryKey: this.getZoneOccupancySummary.queryKey(requestParameters, initOverrides)
            })
        }
    )

    /**
     * Subscribe to a WebSocket that periodically sends the crowd size for a locality.
     */
    subscribeToLocalityCrowdSize = Object.assign(
        async (requestParameters: SubscribeToLocalityCrowdSizeRequest, initOverrides?: RequestInit): Promise<void> => {
            await this.subscribeToLocalityCrowdSize.invokeRaw(requestParameters, initOverrides);
        },
        {
            invokeRaw: async (requestParameters: SubscribeToLocalityCrowdSizeRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> => {
                if (requestParameters.localityId === null || requestParameters.localityId === undefined) {
                    throw new runtime.RequiredError('localityId','Required parameter requestParameters.localityId was null or undefined when calling subscribeToLocalityCrowdSize.');
                }
            
                const queryParameters: any = {};
            
                if (requestParameters.subscriptionType !== undefined) {
                    queryParameters['subscription_type'] = requestParameters.subscriptionType;
                }
            
                if (requestParameters.interval !== undefined) {
                    queryParameters['interval'] = requestParameters.interval;
                }
            
                const headerParameters: runtime.HTTPHeaders = {};
            
                const response = await this.request({
                    path: `/api/statistics/by-locality/{locality_id}/crowd_size/ws`.replace(`{${"locality_id"}}`, encodeURIComponent(String(requestParameters.localityId))),
                    method: 'GET',
                    headers: headerParameters,
                    query: queryParameters,
                }, initOverrides);
            
                return new runtime.VoidApiResponse(response);
            },
            mutationKey: [
                'StatisticsApi',
                'subscribeToLocalityCrowdSize', 
            ],
            queryKey: (requestParameters: SubscribeToLocalityCrowdSizeRequest, initOverrides?: RequestInit) => ([
                    ...this.subscribeToLocalityCrowdSize.mutationKey, 
                {
                localityId: requestParameters.localityId,
                subscriptionType: requestParameters.subscriptionType,
                interval: requestParameters.interval,
                }
            ]),
            query: (requestParameters: SubscribeToLocalityCrowdSizeRequest, initOverrides?: RequestInit): UseQueryOptions<void> & {queryKey: QueryKey} => ({
                queryFn: () => this.subscribeToLocalityCrowdSize(requestParameters, initOverrides),
                queryKey: this.subscribeToLocalityCrowdSize.queryKey(requestParameters, initOverrides)
            })
        }
    )

    /**
     * Subscribe to a WebSocket that periodically sends the floorplan-based crowd size based on number of currently visible people.
     */
    subscribeToLocalityFloorplanCrowdSize = Object.assign(
        async (requestParameters: SubscribeToLocalityFloorplanCrowdSizeRequest, initOverrides?: RequestInit): Promise<void> => {
            await this.subscribeToLocalityFloorplanCrowdSize.invokeRaw(requestParameters, initOverrides);
        },
        {
            invokeRaw: async (requestParameters: SubscribeToLocalityFloorplanCrowdSizeRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> => {
                if (requestParameters.localityId === null || requestParameters.localityId === undefined) {
                    throw new runtime.RequiredError('localityId','Required parameter requestParameters.localityId was null or undefined when calling subscribeToLocalityFloorplanCrowdSize.');
                }
            
                const queryParameters: any = {};
            
                if (requestParameters.subscriptionType !== undefined) {
                    queryParameters['subscription_type'] = requestParameters.subscriptionType;
                }
            
                if (requestParameters.interval !== undefined) {
                    queryParameters['interval'] = requestParameters.interval;
                }
            
                const headerParameters: runtime.HTTPHeaders = {};
            
                const response = await this.request({
                    path: `/api/statistics/by-locality/{locality_id}/floorplan_crowd_size/ws`.replace(`{${"locality_id"}}`, encodeURIComponent(String(requestParameters.localityId))),
                    method: 'GET',
                    headers: headerParameters,
                    query: queryParameters,
                }, initOverrides);
            
                return new runtime.VoidApiResponse(response);
            },
            mutationKey: [
                'StatisticsApi',
                'subscribeToLocalityFloorplanCrowdSize', 
            ],
            queryKey: (requestParameters: SubscribeToLocalityFloorplanCrowdSizeRequest, initOverrides?: RequestInit) => ([
                    ...this.subscribeToLocalityFloorplanCrowdSize.mutationKey, 
                {
                localityId: requestParameters.localityId,
                subscriptionType: requestParameters.subscriptionType,
                interval: requestParameters.interval,
                }
            ]),
            query: (requestParameters: SubscribeToLocalityFloorplanCrowdSizeRequest, initOverrides?: RequestInit): UseQueryOptions<void> & {queryKey: QueryKey} => ({
                queryFn: () => this.subscribeToLocalityFloorplanCrowdSize(requestParameters, initOverrides),
                queryKey: this.subscribeToLocalityFloorplanCrowdSize.queryKey(requestParameters, initOverrides)
            })
        }
    )

}
