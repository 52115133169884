/* tslint:disable */
/* eslint-disable */
/**
 * Vividi Dashboard API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DetectionZoneWithId,
    DetectionZoneWithIdFromJSON,
    DetectionZoneWithIdFromJSONTyped,
    DetectionZoneWithIdToJSON,
} from './DetectionZoneWithId';
import {
    VisitBoundaryWithId,
    VisitBoundaryWithIdFromJSON,
    VisitBoundaryWithIdFromJSONTyped,
    VisitBoundaryWithIdToJSON,
} from './VisitBoundaryWithId';

/**
 * 
 * @export
 * @interface SceneDescription
 */
export interface SceneDescription {
    /**
     * 
     * @type {Array<DetectionZoneWithId>}
     * @memberof SceneDescription
     */
    detectionZones: Array<DetectionZoneWithId>;
    /**
     * 
     * @type {Array<DetectionZoneWithId>}
     * @memberof SceneDescription
     */
    stopZones: Array<DetectionZoneWithId>;
    /**
     * 
     * @type {Array<VisitBoundaryWithId>}
     * @memberof SceneDescription
     */
    visitBoundaries: Array<VisitBoundaryWithId>;
}

export function SceneDescriptionFromJSON(json: any): SceneDescription {
    return SceneDescriptionFromJSONTyped(json, false);
}

export function SceneDescriptionFromJSONTyped(json: any, ignoreDiscriminator: boolean): SceneDescription {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'detectionZones': ((json['detection_zones'] as Array<any>).map(DetectionZoneWithIdFromJSON)),
        'stopZones': ((json['stop_zones'] as Array<any>).map(DetectionZoneWithIdFromJSON)),
        'visitBoundaries': ((json['visit_boundaries'] as Array<any>).map(VisitBoundaryWithIdFromJSON)),
    };
}

export function SceneDescriptionToJSON(value?: SceneDescription | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'detection_zones': ((value.detectionZones as Array<any>).map(DetectionZoneWithIdToJSON)),
        'stop_zones': ((value.stopZones as Array<any>).map(DetectionZoneWithIdToJSON)),
        'visit_boundaries': ((value.visitBoundaries as Array<any>).map(VisitBoundaryWithIdToJSON)),
    };
}

