/* tslint:disable */
/* eslint-disable */
/**
 * Vividi Dashboard API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EntityVisitRecord,
    EntityVisitRecordFromJSON,
    EntityVisitRecordFromJSONTyped,
    EntityVisitRecordToJSON,
} from './EntityVisitRecord';

/**
 * 
 * @export
 * @interface EntityListResponse
 */
export interface EntityListResponse {
    /**
     * 
     * @type {Array<EntityVisitRecord>}
     * @memberof EntityListResponse
     */
    entities: Array<EntityVisitRecord>;
}

export function EntityListResponseFromJSON(json: any): EntityListResponse {
    return EntityListResponseFromJSONTyped(json, false);
}

export function EntityListResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): EntityListResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'entities': ((json['entities'] as Array<any>).map(EntityVisitRecordFromJSON)),
    };
}

export function EntityListResponseToJSON(value?: EntityListResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'entities': ((value.entities as Array<any>).map(EntityVisitRecordToJSON)),
    };
}

