import { Dictionary } from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'

import { SceneResponse } from '@api'

import { localityApi } from '@services'

import FloorplanMappingEditor from '@components/DeviceSceneConfiguration/FloorplanMappingEditor'
import useSvgEditorData from '@components/DeviceSceneConfiguration/useSvgEditorData'
import ErrorView from '@components/ErrorView'
import LoadingSpinner from '@components/LoadingSpinner'

import { FloorplanMappingPolygon } from './ScenesTypes'

interface Props {
    width: number
    organizationId: number
    scene: SceneResponse
    localityId: number
    floorplanId: number
    mappingPolygons: FloorplanMappingPolygon[]
    handleSetFloorplanMapping: (floorplanMapping: FloorplanMappingPolygon[]) => void
    scenesInLocality: Dictionary<SceneResponse>
    onFloorplanSelect: (deviceId: number) => void
}

const FloorplanPolygonConfiguration: React.FC<Props> = ({
    width,
    organizationId,
    scene,
    localityId,
    floorplanId,
    mappingPolygons,
    handleSetFloorplanMapping,
    scenesInLocality,
    onFloorplanSelect,
}) => {
    const { t } = useTranslation()

    const backgroundCall = useQuery(
        localityApi.getLocalityFloorplan.query({
            organizationId,
            localityId,
            floorplanId,
        })
    )

    const { imageDimensions, editorDimensions, imageUrl } = useSvgEditorData({
        backgroundCall,
        width,
        disableContentScaling: false,
    })

    if (backgroundCall.status === 'error') {
        return <ErrorView message={t('others.deviceOffline', 'The device is probably offline')} />
    }

    if (backgroundCall.status === 'idle' || backgroundCall.status === 'loading' || imageDimensions === undefined) {
        return <LoadingSpinner />
    }

    if (editorDimensions.height === undefined) {
        return <LoadingSpinner />
    }

    return (
        <div style={{ width }}>
            {mappingPolygons !== null ? (
                <FloorplanMappingEditor
                    key={`floorplan-polygon-${scene.id}`}
                    editorDimensions={editorDimensions}
                    imageSize={imageDimensions}
                    imageUrl={imageUrl!}
                    mappingPolygons={mappingPolygons}
                    scenesInLocality={scenesInLocality}
                    onFloorplanMapChange={handleSetFloorplanMapping}
                    onFloorplanSelect={onFloorplanSelect}
                />
            ) : null}
        </div>
    )
}

export default FloorplanPolygonConfiguration
