import { Container } from 'react-bootstrap'

import Box from './Box'

export const BoxWithContainer: React.FC<{ heading: string }> = ({ heading, children }) => {
    return (
        <Container>
            <Box>
                <Box.Title text={heading} />
                {children}
            </Box>
        </Container>
    )
}
