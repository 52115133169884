import { flatten, groupBy, isEmpty, keyBy, mapValues, mean, round } from 'lodash'
import { DateTime, Interval } from 'luxon'
import React from 'react'
import { Card, Col, Container, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'

import {
    LocalityNameListResponse,
    LocalityNameModel,
    LocalityResponse,
    OrganizationListResponse,
    OutagesResponse,
    SceneListResponse,
    ZoneOccupancySessionsResponse,
    ZoneOccupancySummaryResponse,
} from '@api/models'

import { visibleSceneDescriptionsQuery } from '@helpers/api'
import { displayPreciseDuration } from '@helpers/displayUtils'
import { useLocalizeDateTime, useTimezoneConfig } from '@helpers/timezoneConfig'
import { IntervalType } from '@helpers/types'
import { pickColumn } from '@helpers/utils'

import Box from '@elements/Box/Box'
import CardTitle from '@elements/Card/CardTitle'

import { ConfigurationHeatmapCarousel } from '@components/ConfigurationHeatmapCarousel'
import ErrorView from '@components/ErrorView'
import StatisticBox, { StatisticBoxItem } from '@components/StatisticsSummary/StatisticBox'
import { displayDateTimes } from '@components/StatisticsSummary/utils'
import { parseDate } from '@components/plots/common'

import styles from './OccupancyDetails.module.scss'
import OccupancyDetailsHistogram from './OccupancyDetailsHistogram'
import OccupancyDetailsBreakdownTable from './OccupancyDetailsTable'
import OccupancyTimeline from './OccupancyTimeline'
import { prepareHistogramData, prepareOccupancyDetailsContentData } from './occupancyDetailsPreproccessors'

interface OccupancySessionsHighlightsProps {
    interval: Interval
    locality: LocalityNameModel
    occupancySessionsHighlightsData: OccupancySessionsHighlightsData
}

interface OccupancySessionsHighlightsData {
    totalSessions: number
    dailySessionsPerSeat: number
    averageSessionDuration: number
    theBusiestWeekDay: string | null
    theBusiestHour: string | null
}
const OccupancySessionsHighlights: React.FC<OccupancySessionsHighlightsProps> = ({
    interval,
    locality,
    occupancySessionsHighlightsData,
}) => {
    const { t } = useTranslation()

    return (
        <Container>
            <Row>
                <StatisticBox
                    interval={interval}
                    label={t('occupancy.totalSessions', 'Total sessions')}
                    localities={[locality]}
                    md={4}
                    tooltip={t(
                        'occupancy.totalSessionsDescription',
                        'total number of sessions from all seats in the locality'
                    )}
                >
                    <StatisticBoxItem value={occupancySessionsHighlightsData.totalSessions} />
                </StatisticBox>
                <StatisticBox
                    interval={interval}
                    label={t('occupancy.dailySessionsPerSeat', 'Daily sessions per seat')}
                    localities={[locality]}
                    md={4}
                    tooltip={t(
                        'occupancy.dailySessionsPerSeatDescription',
                        'average daily number of sessions per seat in the locality'
                    )}
                >
                    <StatisticBoxItem value={occupancySessionsHighlightsData.dailySessionsPerSeat} />
                </StatisticBox>
                <StatisticBox
                    interval={interval}
                    label={t('occupancy.avgSessionDuration', 'Average session duration')}
                    localities={[locality]}
                    md={4}
                    tooltip={t(
                        'occupancy.avgSessionDurationDescription',
                        'average session duration from all seat sessions in the locality'
                    )}
                >
                    <StatisticBoxItem
                        value={displayPreciseDuration(occupancySessionsHighlightsData.averageSessionDuration)}
                    />
                </StatisticBox>
            </Row>
            <Row>
                <StatisticBox
                    interval={interval}
                    label={t('occupancy.busiestWeekday', 'The busiest week day')}
                    localities={[locality]}
                    md={6}
                    tooltip={t(
                        'occupancy.busiestWeekdayDescription',
                        'the day with the highest number of sessions across all seats in the locality'
                    )}
                >
                    <StatisticBoxItem value={occupancySessionsHighlightsData.theBusiestWeekDay ?? 'N/A'} />
                </StatisticBox>
                <StatisticBox
                    interval={interval}
                    label={t('occupancy.busiestHour', 'The busiest hour')}
                    localities={[locality]}
                    md={6}
                    tooltip={t(
                        'occupancy.busiestHourDescription',
                        'the hour of the day with the highest number of sessions across all seats in the locality'
                    )}
                >
                    <StatisticBoxItem value={occupancySessionsHighlightsData.theBusiestHour ?? 'N/A'} />
                </StatisticBox>
            </Row>
        </Container>
    )
}

interface SeatOccupancyHighlightsProps {
    interval: Interval
    locality: LocalityNameModel
    occupancyHighlightsData: OccupancyHighlightsData
}

const SeatOccupancyHighlights: React.FC<SeatOccupancyHighlightsProps> = ({
    interval,
    locality,
    occupancyHighlightsData,
}) => {
    const { t } = useTranslation()

    return (
        <Container>
            <Row>
                <StatisticBox
                    interval={interval}
                    label={t('occupancy.avgDailySeatOccupancy', 'Average daily seat occupancy')}
                    localities={[locality]}
                    md={6}
                    tooltip={t(
                        'occupancy.avgDailySeatOccupancyDescription',
                        'average daily seat occupancy across all seats in the locality'
                    )}
                >
                    <StatisticBoxItem value={displayPreciseDuration(occupancyHighlightsData.averageSeatOccupancy)} />
                </StatisticBox>
                <StatisticBox
                    interval={interval}
                    label={t('occupancy.maxSeatOccupancy', 'Maximal seat occupancy')}
                    localities={[locality]}
                    md={6}
                    tooltip={t(
                        'occupancy.maxSeatOccupancyDescription',
                        'the longest seat occupancy in a day across all seats in the locality'
                    )}
                >
                    <StatisticBoxItem value={displayPreciseDuration(occupancyHighlightsData.maximalSeatOccupancy)} />
                </StatisticBox>
            </Row>
            <Row>
                <StatisticBox
                    interval={interval}
                    label={t('occupancy.busiestWeekday', 'The busiest week day')}
                    localities={[locality]}
                    md={6}
                    tooltip={t(
                        'occupancy.busiestWeekdayOccupancyDescription',
                        'the day with the most occupied seat across all seats in the locality'
                    )}
                >
                    <StatisticBoxItem
                        value={
                            occupancyHighlightsData.theMostBusyDay
                                ? displayDateTimes({
                                      date: occupancyHighlightsData.theMostBusyDay,
                                  })
                                : 'N/A'
                        }
                    />
                </StatisticBox>
                <StatisticBox
                    interval={interval}
                    label={t('occupancy.leastBusyWeekday', 'The least busy day')}
                    localities={[locality]}
                    md={6}
                    tooltip={t(
                        'occupancy.leastBusyWeekdayOccupancyDescription',
                        'the day with the least occupied seat across all seats in the locality'
                    )}
                >
                    <StatisticBoxItem
                        value={
                            occupancyHighlightsData.theLeastBusyDay
                                ? displayDateTimes({
                                      date: occupancyHighlightsData.theLeastBusyDay,
                                  })
                                : 'N/A'
                        }
                    />
                </StatisticBox>
            </Row>
        </Container>
    )
}

interface OccupancyDetailsContentProps {
    locality: LocalityNameModel
    localityInfo: LocalityResponse
    interval: Interval
    zoneOccupancy: {
        zoneOccupancySummary: ZoneOccupancySummaryResponse
        scenes: SceneListResponse
        localities: LocalityNameListResponse
        outages: OutagesResponse
        organizations: OrganizationListResponse
    }
    zoneOccupancySessions: ZoneOccupancySessionsResponse
    zoneOccupancyQuarterHourData: ZoneOccupancySummaryResponse
    intervalType: IntervalType
    now: DateTime
}

interface OccupancyHighlightsData {
    averageSeatOccupancy: number
    maximalSeatOccupancy: number
    theMostBusyDay: DateTime | undefined
    theLeastBusyDay: DateTime | undefined
}

const OccupancyDetailsContent: React.FC<OccupancyDetailsContentProps> = ({
    locality,
    localityInfo,
    interval,
    zoneOccupancy,
    zoneOccupancySessions,
    zoneOccupancyQuarterHourData,
    intervalType,
    now,
}) => {
    const { t } = useTranslation()
    const timeZone = useTimezoneConfig()

    const sceneConfigurationCall = useQuery({
        ...visibleSceneDescriptionsQuery(pickColumn(zoneOccupancy.scenes.scenes, 'id')),
    })

    const localize = useLocalizeDateTime()

    if (sceneConfigurationCall.status === 'success' && sceneConfigurationCall.data === undefined) {
        return <ErrorView message={t('others.errorWhileFetchingData', 'Error while fetching data')} />
    }

    const occupancyDetailsContentData = prepareOccupancyDetailsContentData(
        zoneOccupancySessions.sessions,
        localize,
        zoneOccupancy,
        sceneConfigurationCall.data!,
        timeZone
    )

    const histogramData = prepareHistogramData(
        timeZone,
        occupancyDetailsContentData.activeTimeBrackets,
        intervalType === IntervalType.DAY
            ? occupancyDetailsContentData.activeZoneRows
            : occupancyDetailsContentData.summedOccupancyByZoneByDay,
        intervalType === IntervalType.DAY ? IntervalType.DAY : IntervalType.WEEK
    ).displayData

    return (
        <>
            <Row>
                <Col lg={7}>
                    <Box>
                        <Box.Title text={t('heading.occupancySessionsHighlights', 'Occupancy sessions highlights')} />
                        <OccupancySessionsHighlights
                            interval={interval}
                            locality={locality}
                            occupancySessionsHighlightsData={
                                occupancyDetailsContentData.occupancySessionsHighlightsData
                            }
                        />
                    </Box>
                </Col>
                <Col className="pt-4 pt-lg-0" lg={5}>
                    <Card className={styles.heatmapCard}>
                        <Card.Body>
                            <CardTitle text={t('heading.mostPerformingSeats', 'Most performing seats')} />
                        </Card.Body>
                        <ConfigurationHeatmapCarousel
                            carouselWidth="490px"
                            //this can vary a lot depending on the card width, thus setting the value programmatically instead of css
                            drawBoundaries={false}
                            drawZones={true}
                            scenes={occupancyDetailsContentData.zoneOccupancyScenes.slice(0, 3)}
                            zoneLabels={mapValues(
                                groupBy(zoneOccupancySessions.sessions, (it) => it.zoneId),
                                (sessions) => `${sessions.length} sessions`
                            )}
                            zones={mapValues(occupancyDetailsContentData.mostPerformingSeats, (it) => it.slice(0, 3))}
                        />
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Box>
                        {!isEmpty(zoneOccupancyQuarterHourData.zoneRows) && (
                            <OccupancyTimeline
                                interval={interval}
                                intervalType={intervalType}
                                locality={localityInfo}
                                localize={localize}
                                now={now}
                                zoneOccupancyQuarterHourData={zoneOccupancyQuarterHourData}
                                zoneOccupancySessions={zoneOccupancySessions}
                            />
                        )}
                    </Box>
                </Col>
            </Row>
            <Row>
                <Col lg={7}>
                    <Box>
                        <Box.Title text={t('heading.seatOccupancyHighlights', 'Seat occupancy highlights')} />
                        <SeatOccupancyHighlights
                            interval={interval}
                            locality={locality}
                            occupancyHighlightsData={{
                                ...occupancyDetailsContentData.occupancyHighlightsData,
                                averageSeatOccupancy: isNaN(occupancyDetailsContentData.averageSeatOccupancy)
                                    ? 0
                                    : occupancyDetailsContentData.averageSeatOccupancy,
                                maximalSeatOccupancy: round(occupancyDetailsContentData.maxDailyOccupancy?.[1] ?? 0),
                                theMostBusyDay: occupancyDetailsContentData.maxDailyOccupancy?.[0]
                                    ? parseDate(occupancyDetailsContentData.maxDailyOccupancy[0])
                                    : undefined,
                                theLeastBusyDay: occupancyDetailsContentData.minDailyOccupancy?.[0]
                                    ? parseDate(occupancyDetailsContentData.minDailyOccupancy[0])
                                    : undefined,
                            }}
                        />
                    </Box>
                </Col>

                <Col className="pt-4 pt-lg-0" lg={5}>
                    <Box className={styles.heatmapCard}>
                        <Box.Title text={t('heading.mostOccupiedSeats', 'Most occupied seats')} />
                        <ConfigurationHeatmapCarousel
                            //this can vary a lot depending on the card width, thus setting the value programmatically instead of css
                            carouselWidth="490px"
                            drawBoundaries={false}
                            drawZones={true}
                            scenes={occupancyDetailsContentData.zoneOccupancyScenes.slice(0, 3)}
                            zoneLabels={mapValues(
                                keyBy(flatten(Object.values(occupancyDetailsContentData.seats)), (it) => it.id),
                                (zone) => {
                                    const average = !isEmpty(histogramData[zone.id])
                                        ? mean(pickColumn(histogramData[zone.id], 'average'))
                                        : 0

                                    return t('statistics.hoursPerDay', '{{value}} hours/day', {
                                        value: round(average, 1),
                                    })
                                }
                            )}
                            zones={mapValues(occupancyDetailsContentData.seats, (it) => it.slice(0, 3))}
                        />
                    </Box>
                </Col>
            </Row>
            <Row>
                <Col lg={12} xl={5}>
                    <Box>
                        <Box.Title text={t('heading.seatOccupancyWeek', 'Seat occupancy during a week')} />
                        <div className={styles.histogramContainer}>
                            <OccupancyDetailsHistogram
                                histogramData={occupancyDetailsContentData.summedOccupancyByZoneByDay}
                                histogramType="week"
                                timeBrackets={occupancyDetailsContentData.activeTimeBrackets}
                            />
                        </div>
                    </Box>
                </Col>
                <Col className="pt-4 pt-xl-0" lg={12} xl={7}>
                    <Box>
                        <Box.Title text={t('heading.seatOccupancyDay', 'Seat occupancy during a day')} />
                        <div className={styles.histogramContainer}>
                            <OccupancyDetailsHistogram
                                histogramData={occupancyDetailsContentData.activeZoneRows}
                                histogramType="day"
                                timeBrackets={occupancyDetailsContentData.activeTimeBrackets}
                            />
                        </div>
                    </Box>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Box>
                        <Box.Title
                            text={t('heading.localitySeatsBreakdown', '{{locality}} seats breakdown', {
                                locality: locality.name,
                            })}
                        />
                        <OccupancyDetailsBreakdownTable
                            activeZoneRows={occupancyDetailsContentData.activeZoneRows}
                            groupedOccupancyData={occupancyDetailsContentData.summedOccupancyByZoneByDay}
                            scenes={zoneOccupancy.scenes}
                            timeBrackets={occupancyDetailsContentData.activeTimeBrackets}
                        />
                    </Box>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Box className={styles.heatmapCard}>
                        <Box.Title
                            text={t('heading.localitySeatsCarousel', '{{locality}} seats', {
                                locality: locality.name,
                            })}
                        />
                        <ConfigurationHeatmapCarousel
                            carouselWidth="1300px"
                            drawBoundaries={false}
                            drawZones={true}
                            scenes={occupancyDetailsContentData.zoneOccupancyScenes}
                            zones={occupancyDetailsContentData.seats}
                        />
                    </Box>
                </Col>
            </Row>
        </>
    )
}

export default OccupancyDetailsContent
