import {
    IconDefinition,
    faFog,
    faCloudRain,
    faSnowflake,
    faThunderstorm,
    faSun,
    faCloudSun,
    faCloud,
    faCloudShowersHeavy,
    faCloudDrizzle,
} from '@fortawesome/pro-light-svg-icons'
import { round } from 'lodash'

import { SceneResponse, WeatherDescription } from '@api/models'

import { NumberFormat } from './types'

export const displayPreciseDuration = (seconds: number) => {
    if (isNaN(seconds)) {
        return '-'
    }

    if (seconds < 60) {
        return `${seconds}s`
    }

    if (seconds > 3600) {
        return `${Math.floor(seconds / 3600)}h ${Math.floor((seconds % 3600) / 60)}m`
    }

    return `${Math.floor(seconds / 60)}m ${seconds % 60}s`
}

export const displayNumericValue = (value: number | string | undefined, precision = 1) => {
    if (typeof value === 'string') {
        return value
    }

    if (value === undefined || isNaN(value)) {
        return '-'
    }

    return round(value, precision)
}

export const formatLargeNumber = (x: string | number) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '\u00a0')
}

export const displayEnv = (apiUrl: string): string => {
    switch (apiUrl) {
        case 'https://api.vividi.io':
            return 'to production'
        case 'https://vividi-api.iterait.com':
            return 'to dev'
        default:
            return ''
    }
}

export const displayScene = (scene: SceneResponse): string =>
    scene.deviceId ? `${scene.label} (#${scene.deviceId})` : scene.label

export const displayNumericValueByType = (
    value: number | undefined,
    valueType: NumberFormat,
    roundingPrecision?: number
) =>
    value === undefined || isNaN(value)
        ? '-'
        : valueType === 'count'
        ? round(value, 1)
        : valueType === 'people'
        ? round(value, 0)
        : valueType === 'percentage'
        ? `${roundingPrecision ? round(value, roundingPrecision) : round(value)}%`
        : valueType === 'time'
        ? displayPreciseDuration(round(value))
        : ''

export const displayWeather = (weather: WeatherDescription): string => {
    switch (weather) {
        case WeatherDescription.Fog:
            return 'fog'
        case WeatherDescription.Rain:
            return 'rain'
        case WeatherDescription.Snow:
            return 'snow'
        case WeatherDescription.Storm:
            return 'storm'
        case WeatherDescription.Sunny:
            return 'sunny'
        case WeatherDescription.Cloudy:
            return 'cloudy'
        case WeatherDescription.Overcast:
            return 'overcast'
        case WeatherDescription.HeavyRain:
            return 'heavyRain'
        case WeatherDescription.LightRain:
            return 'lightRain'
    }
}

export const weatherIcon = (weather: WeatherDescription): IconDefinition => {
    const icons = {
        [WeatherDescription.Fog]: faFog,
        [WeatherDescription.Rain]: faCloudRain,
        [WeatherDescription.Snow]: faSnowflake,
        [WeatherDescription.Storm]: faThunderstorm,
        [WeatherDescription.Sunny]: faSun,
        [WeatherDescription.Cloudy]: faCloudSun,
        [WeatherDescription.Overcast]: faCloud,
        [WeatherDescription.HeavyRain]: faCloudShowersHeavy,
        [WeatherDescription.LightRain]: faCloudDrizzle,
    }

    return icons[weather] ?? faCloud
}

export const displayTemperature = (temperature: number): string => `${temperature} °C`

export const formatPhoneNumber = (number: string) => number.replace(/(.{3})/g, '$1 ').trim()
