import { Dictionary, uniqueId } from 'lodash'
import React, { useMemo } from 'react'
import { Badge } from 'react-bootstrap'
import { Typeahead as TypeAhead } from 'react-bootstrap-typeahead'
import { useTranslation } from 'react-i18next'

import { Role } from '@api'

import { formatPhoneNumber } from '@helpers/displayUtils'
import { exhaustiveCheck } from '@helpers/utils'

import styles from './FullTextSearchBox.module.scss'
import 'react-bootstrap-typeahead/css/Typeahead.css'
import RoleChecker from './RoleChecker'

type SelectableType = 'device' | 'organization' | 'locality'

type ItemType = {
    uid: string
    itemId: string
    label: string
    selectableType: SelectableType
}

interface OptionalListAttributesType {
    [itemId: string]: { [attributeName: string]: string | undefined }
}

interface Props {
    choices: Dictionary<{
        itemId: string
        label: string
        selectableType: string
        [tag: string]: string
    }>
    optionalListAttributes?: OptionalListAttributesType
    selected: string[]
    onSelect: (selection: string[] | undefined) => void
    handleResetSearch?: () => void
    placeholder?: string
    disabled?: boolean
    filterBy?: string[]
    displayIdOnly?: boolean
}

const FullTextSearchBox = (props: Props) => {
    const { t } = useTranslation()

    const boxId = useMemo(uniqueId, [])

    const options = Object.entries(props.choices).map(
        ([key, value]) =>
            ({
                ...value,
                uid: key,
                itemId: value.itemId,
                label: value.label,
                selectableType: value.selectableType,
                simPhoneNumber: value.simPhoneNumber,
            } as ItemType)
    )

    const setSingleSelections = (selected: ItemType[]) => {
        if (selected.length > 0) {
            props.onSelect(selected.map((item) => item.uid))
        } else {
            if (props.handleResetSearch) {
                props.handleResetSearch()
            }
        }
    }

    const getBadgeVariant = (selectableType: SelectableType) => {
        switch (selectableType) {
            case 'device':
                return 'light'
            case 'organization':
                return 'dark'
            case 'locality':
                return 'dark'

            default:
                exhaustiveCheck(selectableType)
        }
    }

    const customFilterItemsBy = props.filterBy ?? []

    return (
        <TypeAhead
            emptyLabel={t('form.noMatchesFound', 'No matches found')}
            filterBy={['label', 'itemId', ...customFilterItemsBy]}
            id={boxId}
            labelKey={(option) => (props.displayIdOnly ? option.itemId : option.label)}
            options={options}
            placeholder={props.disabled ? t('others.noItemsToSelect', 'No items to select') : props.placeholder}
            renderMenuItemChildren={(option) => (
                <div className={styles.menuItemWrapper}>
                    <div className={styles.menuItem}>
                        <span className={styles.optionLabel}>{option.label}</span>
                        <span className={styles.optionId}>id: {option.itemId}</span>
                    </div>
                    <div className={styles.menuItem}>
                        <Badge variant={getBadgeVariant(option.selectableType)} pill>
                            {option.selectableType}
                        </Badge>
                        <RoleChecker whitelist={[Role.Administrator]}>
                            {option.selectableType === 'device' && props.optionalListAttributes && (
                                <span className={styles.optionId}>
                                    {props.optionalListAttributes[option.itemId]['simPhoneNumber']
                                        ? `tel.: ${formatPhoneNumber(
                                              props.optionalListAttributes[option.itemId]['simPhoneNumber'] ?? ''
                                          )}`
                                        : null}
                                </span>
                            )}
                        </RoleChecker>
                    </div>
                </div>
            )}
            selected={props.selected.length ? ([props.choices[props.selected[0]]] as ItemType[]) : undefined}
            clearButton
            onChange={setSingleSelections}
        />
    )
}

export default FullTextSearchBox
