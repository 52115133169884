import React from 'react'
import { Trans } from 'react-i18next'

const TermsOfService = () => {
    return (
        <>
            <Trans i18nKey="termsAndConditions.termsOfService">
                <h1 id="terms-of-service">Terms of Service</h1>
                <p>Vividi Dashboard - What It Is and How (Not) To Use It</p>
                <h2 id="preamble">Preamble</h2>
                <h3 id="welcome-to-vividi-dashboard">Welcome to Vividi Dashboard!</h3>
                <p>
                    We would like to thank you for choosing Vividi. We know that you are eager to go ahead and start
                    using Vividi Dashboard, but we kindly ask you to spare a few minutes and read through these terms
                    governing your use thereof and access thereto.
                </p>
                <p>
                    We value your time, so we promise to keep the Terms of Service as brief as possible. If you are
                    comfortable with and agree with everything that you read, you may then go ahead and start using
                    Vividi Dashboard. So, let's get into it:
                </p>
                <h2 id="parties">Parties</h2>
                <h3 id="lets-introduce-ourselves.">Let's introduce ourselves.</h3>
                <p>
                    We are a commercial company called Iterait a.s., ID No. 074&nbsp;05&nbsp;821, incorporated and
                    existing under the laws of the Czech Republic. Our legal address is Hybernská 1034/5, 110 00 Prague
                    1, Czech Republic; we are registered in the commercial register kept by the Municipal Court in
                    Prague under file No. B 23719.
                </p>
                <h3 id="okay-now-a-little-bit-about-you">Okay, now a little bit about “you”:</h3>
                <p>
                    When we refer to “you” in these Terms of Service, we actually refer to anybody, whether individual
                    or legal entity, who visits our website and attempts to use Vividi Dashboard.
                </p>
                <p>
                    It probably goes without saying, but your legal capacity must not be limited to the extent that
                    could in any way prevent you from using Vividi Dashboard or from effectively agreeing to and
                    complying with these Terms of Service.
                </p>
                <p>
                    If you plan to use Vividi Dashboard on behalf of any other person, whether individual or legal
                    entity, you must obviously have the legal authority to do so.
                </p>
                <h2 id="vividi-services">Vividi Services</h2>
                <h3 id="i-already-know-what-vividi-is-but-remind-me-just-in-case.">
                    I already know what Vividi is, but remind me just in case.
                </h3>
                <p>
                    Vividi is an IT solution provided by our company that allows you to obtain important statistics and
                    information about the demographics and behavior of customers in your establishment. To list a few
                    examples, Vividi may be able to identify the duration of a customer´s stay, their age or gender.
                </p>
                <p>
                    On the other hand, we are not a spy service, and we are not able to identify the customers by name
                    or occupation. We also do not approve of discrimination, and therefore you should not use any of the
                    obtained statistics to discriminate against any of your customers.
                </p>
                <h3 id="how-does-it-work">How does it work?</h3>
                <p>Right now, there are two ways you can use Vividi.</p>
                <ol type="1">
                    <li>
                        <blockquote>
                            <p>
                                You can obtain the hardware device called the “Vividi Box,” developed by our company.
                                The hardware monitors your establishment and collects the above-mentioned data about
                                your customers. For the rules applicable to obtaining and using the Vividi Box, please
                                read the appropriate set of terms and conditions, as these Terms of Service do not
                                control it.
                            </p>
                        </blockquote>
                    </li>
                    <li>
                        <blockquote>
                            <p>
                                If you do not possess the Vividi Box, you may also choose your own video source to be
                                analyzed by us. In that case, you need to sync your video-recording device with Vividi
                                Dashboard, and the data about your customers will then be collected by us.
                            </p>
                        </blockquote>
                    </li>
                </ol>
                <p>
                    In both cases, you may then access the collected data in Vividi Dashboard, accessible on our website
                    (
                    <a href="https://iterait.com/">
                        <span>https://iterait.com/</span>
                    </a>
                    ). The use of Vividi Dashboard is controlled by these Terms of Service.
                </p>
                <h2 id="user-account">User Account</h2>
                <h3 id="so-how-do-i-start">So, how do I start?</h3>
                <p>
                    That's a good question. First you need to create a user account. We create all the user accounts
                    manually, so just go ahead and contact us at{' '}
                    <a href="mailto:hello@iterait.com">
                        <span>hello@iterait.com</span>
                    </a>{' '}
                    and we will get back to you in no time.
                </p>
                <p>
                    We will ask you to provide us with your first and last name (or company name, if applicable), email
                    address, and some other identification information, and we will then send you a preselected
                    password.
                </p>
                <p>
                    We strongly recommend changing the password after your first login in order to keep your user
                    account secure, and we also recommend that you do not reuse your user-account password on
                    third-party applications.
                </p>
                <h3 id="is-there-anything-else-i-should-know-about-the-user-account">
                    Is there anything else I should know about the user account?
                </h3>
                <p>
                    The user account is dedicated to you, and you are solely responsible for all activities that occur
                    on or through your user account, so it is important that you keep secure your password and devices
                    used to access the user account. You must let us know immediately if you suspect any unauthorized
                    access to your user account.
                </p>
                <h2 id="submitted-content">Submitted Content</h2>
                <h3 id="is-there-anything-interesting-i-should-know-about-the-submitted-content">
                    Is there anything interesting I should know about the submitted content?
                </h3>
                <p>
                    We trust our customers. Therefore, we expect any information submitted by you within Vividi
                    Dashboard (including any information provided in order to create your user account) to be accurate,
                    true and complete.
                </p>
                <p>
                    If any of the information changes, you should see to it that it is updated as soon as possible in
                    order to prevent any misunderstandings or problems down the line.
                </p>
                <p>
                    Any content submitted to or through Vividi Dashboard that belongs to you stays yours. (Or in legal
                    terms: You retain ownership of any intellectual property rights that you hold in submitted content).
                </p>
                <p>
                    When you submit content to or through Vividi Dashboard, you grant us a worldwide, royalty-free,
                    nonexclusive, perpetual license (with a right to sublicense) to use the submitted content for the
                    limited purpose of operating, promoting and improving our Vividi Dashboard, Vividi Box, and any
                    other related services. Make sure you have the necessary rights to grant us this license.
                </p>
                <h2 id="personal-data">Personal Data</h2>
                <h3 id="are-my-personal-data-safe-with-you">Are my personal data safe with you?</h3>
                <p>
                    First let us assure you that the collection of your personal data (e.g., the data submitted when
                    creating your user account) in connection to using Vividi Dashboard shall be as limited as possible.
                </p>
                <p>
                    Any collection, storage and handling of your personal data shall be controlled by a separate set of
                    terms on handling the personal data of our customers (Vividi Privacy Policy).
                </p>
                <h2 id="cost-and-payment">Cost and Payment</h2>
                <h3 id="how-much-does-it-cost">How much does it cost?</h3>
                <p>
                    You may access Vividi Dashboard free of charge, but it will not be of much use to you, obviously,
                    unless you sync your Vividi Box or other video-recording device therewith.
                </p>
                <p>We offer various pricing models to fit your circumstances.</p>
                <p>
                    Most often we will charge you a monthly fee on a “per synced device” basis. If you have already
                    obtained the Vividi Box from us and are using it in compliance with any applicable terms, you are
                    entitled to sync the device with Vividi Dashboard for a monthly fee.
                </p>
                <p>
                    In any case, the pricing for your particular circumstances is always subject to a mutual agreement.
                    We may send you the Price List, where you will find the available pricing models and also more
                    detailed information on payment methods, or we may discuss the pricing with you in any other
                    suitable way.
                </p>
                <p>
                    Once the period for which you have paid is over, you will not be entitled to sync the respective
                    device with Vividi Dashboard nor to access any new statistics and data. However, you will still be
                    entitled to access Vividi Dashboard and any previously saved data.
                </p>
                <p>
                    We may make changes and improvements to Vividi Dashboard in the future. Of course, we always attempt
                    to improve our services, but sometimes we must let some features go to make room for new ones.
                </p>
                <p>
                    Therefore, please be aware that we do not guarantee any specific Vividi Dashboard functionality
                    beyond the functionality specifically described above in these Terms of Service, and any changes to
                    its features will neither terminate nor release you from any of your paid subscriptions.
                </p>
                <h2 id="syncing-and-availability">Syncing and Availability</h2>
                <h3 id="i-want-to-learn-more-about-syncing-my-devices-with-vividi-dashboard">
                    I want to learn more about syncing my devices with Vividi Dashboard …
                </h3>
                <h3 id="and-i-am-using-the-vividi-box.">… and I am using the Vividi Box.</h3>
                <p>
                    If you use the Vividi Box, please read the applicable terms and conditions on obtaining and using
                    the Vividi Box first. Properly used, the Vividi Box will collect statistics about the demographics
                    and behavior of your customers and then send it to Vividi Dashboard, if synced properly.
                </p>
                <p>
                    In the interface of Vividi Dashboard, you will have a chance to check (by taking a screenshot of
                    video feed of the Vividi Box) whether the Vividi Box is properly synced with Vividi Dashboard. In
                    the event you are unable to sync it, please contact us immediately.
                </p>
                <p>
                    In the event that the Vividi Box is not installed properly or in the event it is not properly synced
                    to Vividi Dashboard, we of course cannot guarantee any functionality of Vividi Dashboard.
                </p>
                <h3 id="and-i-am-using-something-else.">… and I am using something else.</h3>
                <p>
                    If you use any other video-recording device, the video feed will be analyzed by us and we will then
                    make the collected statistics available for you to access in Vividi Dashboard.
                </p>
                <p>
                    We may analyze only the video feed that is transmitted to us via Vividi Dashboard, and therefore we
                    advise you to make sure that your video-recording device is installed and positioned properly, that
                    it is correctly synced with Vividi Dashboard, and that the video feed is of a sufficient quality for
                    us to be able to analyze it.
                </p>
                <h3 id="you-are-always-up-right">You are always up, right?</h3>
                <p>
                    We always attempt to provide continuous uninterrupted operation of Vividi Dashboard so you can
                    access and use it at any time.
                </p>
                <p>
                    However, due to maintenance, repairs or exceptional outages, there may be occasions when Vividi
                    Dashboard will not be available; therefore we cannot always guarantee completely uninterrupted
                    (100%) availability.
                </p>
                <p>
                    If it is unavailable due to a planned temporary maintenance shutdown, we will attempt to inform you
                    in advance. If due to the nature of unavailability we could not inform you in advance, we will at
                    least attempt to promptly solve any issues and make it available again.
                </p>
                <h2 id="liability-and-warranty-limitation">Liability and Warranty Limitation</h2>
                <h3 id="so-where-are-the-legal-disclaimers">So, where are the legal disclaimers?</h3>
                <p>You have just found them, so let's get it over with.</p>
                <p>
                    Please be aware that Vividi Dashboard is a service and we do not give you ownership of any
                    intellectual property rights in Vividi Dashboard or any other Vividi products or services associated
                    therewith. You are solely entitled to access and use Vividi Dashboard and to access, download, and
                    otherwise use the customer statistics from devices synced by you.
                </p>
                <p>
                    We provide you Vividi Dashboard using a reasonable level of skill and care, and we hope that you
                    will enjoy using it; however, be aware that Vividi is still offered to you on an “as is” and
                    “where-available” basis and therefore we expressly disclaim any warranties, express or implied,
                    relating thereto.
                </p>
                <p>
                    We shall not under any circumstances be held liable to you for any direct, indirect, special,
                    consequential, punitive, or any other damages and costs, including but not limited to loss of
                    profit, loss of revenue, loss of business opportunity arising out of or in connection with your
                    access and use or inability to access and use Vividi Dashboard.
                </p>
                <p>
                    We specifically do not warrant and do not assume any liability in the event that the video feed
                    provided by you could not be analyzed due to the quality of the video feed (e.g., resolution) being
                    too low or the distance between the video-recording device and the customers being too far or the
                    video-recording device being positioned in such a way that it does not record the entire premises of
                    the establishment.
                </p>
                <p>
                    Under certain laws it may not be possible to disclaim our liability and warranties completely. In
                    such cases, we hereby disclaim our liability and warranties to the fullest extent permissible by
                    such law.
                </p>
                <p>
                    If the warranties and liabilities disclaimers above are found void or invalid or otherwise
                    unenforceable, you agree that our total liability for any claims arising out of these Terms of
                    Service or in relation thereto is limited to the amount you paid us to use Vividi Dashboard.
                </p>
                <h2 id="notices">Notices</h2>
                <h3 id="how-do-i-get-in-touch-with-you-and-what-if-you-need-to-contact-me">
                    How do I get in touch with you? And what if you need to contact me?
                </h3>
                <p>
                    You can contact us electronically via tools available on our website or via the email address
                    hello@iterait.com. Your message is considered as delivered when we confirm its delivery to you,
                    which we always attempt to do promptly.
                </p>
                <p>
                    We will not bother you too much, but occasionally we may have to contact you. In that event, we will
                    use either the email address provided by you or a notice available to you upon login in the Vividi
                    Dashboard interface.
                </p>
                <p>
                    We will occasionally send you an email with commercial announcements; you may at any time without
                    having to give a reason opt out of those communications.
                </p>
                <h2 id="termination">Termination</h2>
                <h3 id="i-like-vividi-dashboard-so-far-but-what-if-i-change-my-mind">
                    I like Vividi Dashboard so far, but what if I change my mind?
                </h3>
                <p>
                    We always will be sorry to let you go, but we will respect your decision. We want happy customers,
                    and it is not in our interest to force onto you a service that you do not like or no longer wish to
                    use.
                </p>
                <p>
                    Therefore, you may terminate the legal contract established by these Terms and Conditions at any
                    time by sending us a termination notice.
                </p>
                <p>
                    If you own the Vividi Box and therefore use Vividi Dashboard for a monthly fee, the termination is
                    effective immediately when we receive your termination notice. On the other hand, if you have paid
                    for using Vividi Dashboard with any other video-recording device, the termination is effective at
                    the end of the period for which you have paid (or when we receive your termination notice, if we
                    receive it later).
                </p>
                <p>
                    We usually keep your data for some time after the termination in order to comply with any applicable
                    laws and regulations and also just in case you decide to come back. However, please be aware that we
                    are not obliged to do so and upon termination we may delete your user account and any data relating
                    to your use of Vividi Dashboard without any further notice.
                </p>
                <h3 id="can-you-terminate-our-relationship-without-my-consent">
                    Can you terminate our relationship without my consent?
                </h3>
                <p>Our relationships with our customers last, and we take pride in that fact.</p>
                <p>
                    However, we may have to and are entitled to suspend or permanently disable your access to Vividi
                    Dashboard if you materially or repeatedly breach these Terms of Service or any other applicable
                    terms (such as the Privacy Policy or the Price List), or if you violate any provision of the
                    applicable law in relation thereto.
                </p>
                <p>
                    Thirty (30) days after you have been notified of the fact that your access is permanently disabled,
                    our relationship with you is terminated and we may delete your user account and any data relating to
                    your use of Vividi Dashboard without any further notice.
                </p>
                <p>
                    In the event your user account has been inactive (you have not logged in) for more than twelve (12)
                    consecutive months, we are entitled to send you a notice of inactivity, and in the event your user
                    account continues to be inactive for another thirty (30) days, our relationship is terminated and we
                    may delete your user account and any data relating to your use of Vividi without any further notice.
                    The relationship cannot be terminated due to inactivity during the period for which you have paid to
                    use Vividi Dashboard and/or during the period for which you possess the Vividi Box that is synced to
                    your Vividi Dashboard.
                </p>
                <p>
                    We may decide to discontinue Vividi Dashboard completely. In that event, we will give you at least
                    thirty (30) days prior notice. After the end of this notice period, our relationship will be
                    terminated and you will not be able to access your data anymore. If we discontinue Vividi Dashboard,
                    you are entitled to a refund of fees paid for the period during which you will not be able to use
                    Vividi Dashboard due to the service termination.
                </p>
                <h2 id="applicable-law">Applicable Law</h2>
                <h3 id="what-laws-do-apply">What laws do apply?</h3>
                <p>
                    The laws of the Czech Republic, without regard to principles of conflict of laws, will control these
                    Terms of Service and any dispute of any sort that might arise between you and us in connection
                    herewith.
                </p>
                <h2 id="dispute-resolution">Dispute Resolution</h2>
                <h3 id="what-if-we-argue">What if we argue?</h3>
                <p>
                    We always operate in a customer-friendly manner and attempt to solve any issues peacefully, and we
                    ask you to do the same. Therefore, you agree to notify us of any disputes arising out of or in
                    connection with these Terms of Service and you agree that you will always attempt an out-of-court
                    dispute resolution before you bring the dispute before the courts.
                </p>
                <p>
                    However, sometimes out-of-court dispute resolution is just not possible. In that event, any dispute
                    relating in any way to these Terms of Service shall be decided with final effect before the courts
                    of the Czech Republic, in particular before the District Court for Prague and (in the event that the
                    regional court decides as a first instance court) before the Municipal Court in Prague.
                </p>
                <p>
                    Should you violate or threaten to violate our intellectual property rights in any manner, we may
                    seek injunctive or other appropriate relief in any court of our choice. You consent to exclusive
                    jurisdiction and venue in such courts.
                </p>
                <h2 id="miscellaneous">Miscellaneous</h2>
                <h3 id="some-necessary-boilerplates">Some necessary boilerplates:</h3>
                <p>
                    If any clause of these Terms of Service is determined to be illegal, invalid or unenforceable, in
                    whole or in part, under any law, the legality, validity and enforceability of the other clauses
                    hereof shall not be affected. In the event that any clause or part thereof is determined to be
                    illegal, invalid or unenforceable, that clause shall be replaced by the parties with a legal, valid
                    and enforceable clause that has, to the greatest extent possible, a similar effect to the illegal,
                    invalid or unenforceable clause, given the content and purpose of these Terms of Service.
                </p>
                <p>
                    You may not assign or transfer any of your rights or obligations assumed under these Terms of
                    Service or in any other way related thereto without our prior written consent.
                </p>
                <p>
                    If you do not comply with these Terms of Service and we do not take action right away, it doesn’t
                    mean that we are giving up any rights that we may have (such as taking action in the future).
                </p>
                <p>
                    All the provisions of these Terms of Service that by their nature extend beyond the termination of
                    the mutual legal relationship, including but not limited to dispute resolution and applicable law
                    clauses, shall survive the termination of the mutual legal relationship.
                </p>
                <h3 id="i-do-not-like-something-i-read-above.">I do not like something I read above.</h3>
                <p>
                    We always attempt to make legal documents as brief and user friendly as possible, but we cannot
                    completely make do without them.
                </p>
                <p>
                    So even though we regret it, if you do not agree with any part of these Terms of Service or if you
                    have any reservations, you may not use Vividi Dashboard and we must ask you to leave Vividi
                    Dashboard immediately.
                </p>
                <h3 id="i-found-out-there-is-a-conflict-between-this-document-and-the-terms-i-found-elsewhere.">
                    I found out there is a conflict between this document and the terms I found elsewhere.
                </h3>
                <p>
                    If there is such a conflict, please let us know immediately so we can attend to it and improve our
                    documentation. Meanwhile, these Terms of Service will control for that conflict.
                </p>
                <h3 id="so-is-that-it-may-i-go-ahead-and-start-using-vividi-dashboard">
                    So, is that it? May I go ahead and start using Vividi Dashboard?
                </h3>
                <p>
                    Just one last thing: We may change or amend these Terms of Service or any additional terms that
                    apply to Vividi Dashboard (such as the Privacy Policy) from time to time, and if we do so, we will
                    have to ask you to read through them and agree to them again.
                </p>
                <p>We realize it's inconvenient for you, so we promise not to do it too often.</p>
                <p>
                    So, that is all from us; if you are comfortable with everything you have read so far and agree to
                    it, you are free to go ahead and enjoy Vividi Dashboard!
                </p>
                <p>
                    In Prague on November 26<sup>th</sup> , 2019
                    <br />
                    Iterait a.s.
                </p>
            </Trans>
        </>
    )
}

export default TermsOfService
