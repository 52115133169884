import { Dictionary } from 'lodash'
import { useRef } from 'react'

const useRefMap = () => {
    const refs = useRef<Dictionary<HTMLElement | null>>({})

    const getRef = (key: string) => refs.current[key]

    const setRef = (key: string, element: HTMLElement | null) => {
        refs.current[key] = element
    }

    return { getRef, setRef }
}

export default useRefMap
