import {
    CREATE_LOCALITY_PATH,
    CREATE_ORGANIZATION_PATH,
    STATISTICS_SUMMARY_EMPTY_PATH,
    STATISTICS_SUMMARY_PATH,
    DEVICE_MANAGEMENT_PATH,
    ORGANIZATION_EDIT_PATH,
    LOGIN_PATH,
    MY_ORGANIZATION_PATH,
    ORGANIZATION_LIST_PATH,
    USER_EDIT_PATH,
    SETTINGS_PATH,
    RESET_PASSWORD_PATH,
    ACCEPT_INVITATION_PATH,
    REQUEST_PASSWORD_RESET_PATH,
    CREATE_USER_PATH,
    INVITE_USERS_PATH,
    DEVICE_TRANSFER_PATH,
    LOCALITY_OCCUPANCY_DASHBOARD_PATH,
    EDIT_LOCALITY_OCCUPANCY_PATH,
    DEVICE_DIAGNOSTICS_PATH,
    INSTANT_LOGIN_PATH,
    REPORT_LIST_PATH,
    FOOTFALL_REPORT_LIST_PATH,
    OCCUPANCY_REPORT_LIST_PATH,
    CONVERSION_DASHBOARD_PATH,
    CONVERSION_DASHBOARD_EMPTY_PATH,
    CONVERSION_DASHBOARD_CUSTOM_INTERVAL_PATH,
    CONVERSION_REPORT_LIST_PATH,
    QUEUE_REPORT_LIST_PATH,
    FOOTFALL_DETAILS_EMPTY_PATH,
    FOOTFALL_DETAILS_PATH,
    FOOTFALL_DETAILS_CUSTOM_INTERVAL_PATH,
    FLOORPLAN_LOCALITY_OCCUPANCY_DASHBOARD_PATH,
    HEATMAP_DETAIL_PATH,
    HEATMAP_DETAIL_EMPTY_PATH,
    QUEUE_DETAILS_EMPTY_PATH,
    QUEUE_DETAILS_PATH,
    QUEUE_DETAILS_CUSTOM_INTERVAL_PATH,
    FALSE_POSITIVE_DIAGNOSTICS_PATH,
    OCCUPANCY_DETAILS_PATH,
    OCCUPANCY_DETAILS_CUSTOM_INTERVAL_PATH,
    OCCUPANCY_DETAILS_EMPTY_PATH,
    HEATMAP_DETAIL_PATH_NO_FP,
    STAY_SAFE_DETAILS_PATH,
    STAY_SAFE_DETAILS_EMPTY_PATH,
    EMOTIONS_DETAILS_PATH,
    EMOTIONS_DETAILS_EMPTY_PATH,
    EMOTIONS_DETAILS_CUSTOM_INTERVAL_PATH,
    ORGANIZATION_USER_EDIT_PATH,
    ORGANIZATION_USER_NEW_PATH,
    ORGANIZATION_LOCALITIES_NEW_TAB_PATH,
    ORGANIZATION_LOCALITY_EDIT_PATH,
    ORGANIZATION_SCENE_EDIT_PATH,
    ORGANIZATION_SCENES_NEW_TAB_PATH,
    ORGANIZATION_DEVICE_EDIT_PATH,
    ORGANIZATION_DEVICES_NEW_TAB_PATH,
    HEATMAP_DETAIL_PATH_NO_LOCALITY_NO_FP,
    ORGANIZATION_DEVICES_TAB_PATH,
    ORGANIZATION_LOCALITY_DESCRIBE_SCENE_EDIT_TAB_PATH,
    ORGANIZATION_SCENE_INSTALLER_UI_PATH,
    ORGANIZATION_SCENE_INSTALLER_UI_ASSIGN_TO_SCENE_PATH,
    ORGANIZATION_SCENE_INSTALLER_UI_ASSIGNED_DEVICE_PATH,
    ORGANIZATION_SCENE_EDIT_DESCRIBE_SCENE_MODAL_PATH,
    ORGANIZATION_LOCALITY_EDIT_OPENING_HOURS_PATH,
    ORGANIZATION_LOCALITY_EDIT_FLOORPLAN_PATH,
    ORGANIZATION_LOCALITY_EDIT_FLOORPLAN_MAPPING_PATH,
} from '@helpers/VividiURLs'

/* eslint-disable replace-relative-imports/replace */
import AcceptInvitationPage from '../pages/app/AcceptInvitationPage'
import ConversionDetailsPage from '../pages/app/ConversionDetailsPage'
import ConversionReportListPage from '../pages/app/ConversionReportListPage'
import CreateLocalityPage from '../pages/app/CreateLocalityPage'
import CreateOrganizationPage from '../pages/app/CreateOrganizationPage'
import CreateUserPage from '../pages/app/CreateUserPage'
import DeviceListPage from '../pages/app/DeviceListPage'
import DeviceTransferPage from '../pages/app/DeviceTransferPage'
import EditLocalityOccupancyPage from '../pages/app/EditLocalityOccupancyPage'
import EditOrganizationPage from '../pages/app/EditOrganizationPage'
import EditUserPage from '../pages/app/EditUserPage'
import EmotionsDetailsPage from '../pages/app/EmotionsDetailsPage'
import FalsePositiveDiagnosticsPage from '../pages/app/FalsePositiveDiagnosticsPage'
import FloorplanHeatmapDetailPage from '../pages/app/FloorplanHeatmapDetailPage'
import FloorplanLocalityOccupancyDashboard from '../pages/app/FloorplanLocalityOccupancyDashboard'
import FootfallDetailsPage from '../pages/app/FootfallDetailsPage'
import FootfallReportListPage from '../pages/app/FootfallReportListPage'
import InstantLoginPage from '../pages/app/InstantLoginPage'
import InviteUsersPage from '../pages/app/InviteUsersPage'
import LocalityOccupancyDashboard from '../pages/app/LocalityOccupancyDashboard'
import LoginPage from '../pages/app/LoginPage'
import OccupancyDetailsPage from '../pages/app/OccupancyDetailsPage'
import OccupancyReportListPage from '../pages/app/OccupancyReportListPage'
import OrganizationListPage from '../pages/app/OrganizationListPage'
import PasswordResetPage from '../pages/app/PasswordResetPage'
import QueueDetailsPage from '../pages/app/QueueDetailsPage'
import QueueReportListPage from '../pages/app/QueueReportListPage'
import ReportListPage from '../pages/app/ReportListPage'
import RequestPasswordResetPage from '../pages/app/RequestPasswordResetPage'
import SceneDiagnosticsPage from '../pages/app/SceneDiagnosticsPage'
import SettingsPage from '../pages/app/SettingsPage'
import StatisticsSummaryPage from '../pages/app/StatisticsSummaryPage'
import StaySafeDetailsPage from '../pages/app/StaySafeDetailsPage'
/* eslint-enable replace-relative-imports/replace */

import { RouteType } from './AppRoutes'

const vividiRoutes: RouteType[] = [
    {
        Component: StaySafeDetailsPage,
        exact: true,
        path: [STAY_SAFE_DETAILS_PATH, STAY_SAFE_DETAILS_EMPTY_PATH],
    },
    {
        Component: QueueDetailsPage,
        exact: true,
        path: [QUEUE_DETAILS_PATH, QUEUE_DETAILS_EMPTY_PATH, QUEUE_DETAILS_CUSTOM_INTERVAL_PATH],
    },
    {
        Component: ConversionDetailsPage,
        exact: true,
        path: [CONVERSION_DASHBOARD_PATH, CONVERSION_DASHBOARD_EMPTY_PATH, CONVERSION_DASHBOARD_CUSTOM_INTERVAL_PATH],
    },
    {
        Component: FootfallDetailsPage,
        path: [FOOTFALL_DETAILS_PATH, FOOTFALL_DETAILS_CUSTOM_INTERVAL_PATH, FOOTFALL_DETAILS_EMPTY_PATH],
        exact: true,
    },
    {
        Component: FloorplanHeatmapDetailPage,
        path: [
            HEATMAP_DETAIL_PATH,
            HEATMAP_DETAIL_PATH_NO_FP,
            HEATMAP_DETAIL_PATH_NO_LOCALITY_NO_FP,
            HEATMAP_DETAIL_EMPTY_PATH,
        ],
        exact: true,
    },
    {
        Component: OccupancyDetailsPage,
        exact: true,
        path: [OCCUPANCY_DETAILS_PATH, OCCUPANCY_DETAILS_CUSTOM_INTERVAL_PATH, OCCUPANCY_DETAILS_EMPTY_PATH],
    },
    {
        Component: EmotionsDetailsPage,
        exact: true,
        path: [EMOTIONS_DETAILS_CUSTOM_INTERVAL_PATH, EMOTIONS_DETAILS_PATH, EMOTIONS_DETAILS_EMPTY_PATH],
    },
    {
        Component: DeviceListPage,
        exact: true,
        path: DEVICE_MANAGEMENT_PATH,
    },
    {
        Component: DeviceTransferPage,
        path: DEVICE_TRANSFER_PATH,
    },
    {
        Component: SceneDiagnosticsPage,
        path: DEVICE_DIAGNOSTICS_PATH,
    },
    {
        Component: FalsePositiveDiagnosticsPage,
        path: FALSE_POSITIVE_DIAGNOSTICS_PATH,
    },
    {
        Component: OrganizationListPage,
        exact: true,
        path: ORGANIZATION_LIST_PATH,
    },
    {
        Component: CreateOrganizationPage,
        exact: true,
        path: CREATE_ORGANIZATION_PATH,
    },
    {
        Component: EditOrganizationPage,
        exact: true,
        path: [
            ORGANIZATION_USER_NEW_PATH,
            ORGANIZATION_USER_EDIT_PATH,
            ORGANIZATION_LOCALITIES_NEW_TAB_PATH,
            ORGANIZATION_LOCALITY_EDIT_PATH,
            ORGANIZATION_SCENE_EDIT_PATH,
            ORGANIZATION_DEVICE_EDIT_PATH,
            ORGANIZATION_DEVICES_TAB_PATH,
            ORGANIZATION_DEVICES_NEW_TAB_PATH,
            ORGANIZATION_SCENES_NEW_TAB_PATH,
            ORGANIZATION_EDIT_PATH,
            ORGANIZATION_DEVICES_TAB_PATH,
            ORGANIZATION_LOCALITY_DESCRIBE_SCENE_EDIT_TAB_PATH,
            ORGANIZATION_SCENE_INSTALLER_UI_PATH,
            ORGANIZATION_SCENE_INSTALLER_UI_ASSIGN_TO_SCENE_PATH,
            ORGANIZATION_SCENE_INSTALLER_UI_ASSIGNED_DEVICE_PATH,
            ORGANIZATION_SCENE_EDIT_DESCRIBE_SCENE_MODAL_PATH,
            ORGANIZATION_LOCALITY_EDIT_OPENING_HOURS_PATH,
            ORGANIZATION_LOCALITY_EDIT_FLOORPLAN_PATH,
            ORGANIZATION_LOCALITY_EDIT_FLOORPLAN_MAPPING_PATH,
        ],
    },
    {
        Component: EditOrganizationPage,
        exact: true,
        path: [ORGANIZATION_EDIT_PATH, MY_ORGANIZATION_PATH],
    },
    {
        Component: CreateLocalityPage,
        exact: true,
        path: CREATE_LOCALITY_PATH,
    },
    {
        Component: EditLocalityOccupancyPage,
        exact: true,
        path: EDIT_LOCALITY_OCCUPANCY_PATH,
    },
    {
        Component: EditUserPage,
        exact: true,
        path: USER_EDIT_PATH,
    },
    {
        Component: SettingsPage,
        exact: true,
        path: SETTINGS_PATH,
    },
    {
        Component: PasswordResetPage,
        exact: true,
        path: RESET_PASSWORD_PATH,
        isNotSecured: true,
    },
    {
        Component: AcceptInvitationPage,
        exact: true,
        path: ACCEPT_INVITATION_PATH,
        isNotSecured: true,
    },
    {
        Component: CreateUserPage,
        exact: true,
        path: CREATE_USER_PATH,
    },
    {
        Component: RequestPasswordResetPage,
        exact: true,
        path: REQUEST_PASSWORD_RESET_PATH,
        isNotSecured: true,
    },
    {
        Component: InviteUsersPage,
        exact: true,
        path: INVITE_USERS_PATH,
    },
    {
        Component: LocalityOccupancyDashboard,
        exact: true,
        path: LOCALITY_OCCUPANCY_DASHBOARD_PATH,
        isNotSecured: true,
    },
    {
        Component: FloorplanLocalityOccupancyDashboard,
        exact: true,
        path: FLOORPLAN_LOCALITY_OCCUPANCY_DASHBOARD_PATH,
        isNotSecured: true,
    },
    { Component: LoginPage, exact: true, path: LOGIN_PATH, isNotSecured: true },
    {
        Component: InstantLoginPage,
        exact: true,
        path: INSTANT_LOGIN_PATH,
        isNotSecured: true,
    },
    {
        Component: ReportListPage,
        exact: true,
        path: REPORT_LIST_PATH,
    },
    {
        Component: FootfallReportListPage,
        exact: true,
        path: FOOTFALL_REPORT_LIST_PATH,
    },
    {
        Component: OccupancyReportListPage,
        exact: true,
        path: OCCUPANCY_REPORT_LIST_PATH,
    },
    {
        Component: QueueReportListPage,
        exact: true,
        path: QUEUE_REPORT_LIST_PATH,
    },
    {
        Component: ConversionReportListPage,
        exact: true,
        path: CONVERSION_REPORT_LIST_PATH,
    },
    {
        Component: StatisticsSummaryPage,
        exact: true,
        path: [STATISTICS_SUMMARY_PATH, STATISTICS_SUMMARY_EMPTY_PATH],
    },
]

export default vividiRoutes
