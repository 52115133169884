import { uniqueId, range } from 'lodash'
import React, { useState, useMemo, useCallback } from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import Helmet from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { MutationStatus } from 'react-query'

import { OrganizationFeatures, OrganizationModel, Role } from '@api/models'

import Box from '@elements/Box/Box'
import ToggleButton from '@elements/Buttons/ToggleButton'

import AsyncButton from '@components/AsyncButton'
import NumberInput from '@components/GenericInputs/NumberInput'
import TextInput from '@components/GenericInputs/TextInput'
import RoleChecker from '@components/RoleChecker'

import styles from './DataPublishersForm.module.scss'

interface Props {
    onSubmit: (organization: OrganizationModel, features?: OrganizationFeatures) => void
    submissionStatus: MutationStatus
    initialData?: OrganizationModel
    initialFeatures?: OrganizationFeatures
}

const OrganizationEditForm = (props: Props) => {
    const { t } = useTranslation()

    const [name, setName] = useState(props.initialData?.name ?? '')

    const [isMonitoringEnabled, setMonitoringEnabled] = useState(props.initialData?.isMonitoringEnabled ?? true)

    const [minutesToDeviceDownTicket, setMinutestToDeviceDownTicket] = useState(
        props.initialData?.minutesToDeviceDownTicket
    )

    const [isLocalitySummaryEnabled, setLocalitySummaryEnabled] = useState(
        props.initialData?.isLocalitySummaryEnabled ?? false
    )

    const [features, setFeatures] = useState(
        props.initialFeatures ?? {
            realtimeOccupancy: false,
            zoneOccupancy: false,
            footfall: false,
            queueMonitoring: false,
            conversions: false,
            emotions: false,
            staySafeNotifier: false,
            floorplans: false,
            staySafeFloorplan: false,
        }
    )

    const [featuresModified, setFeaturesModified] = useState(false)
    const [monitoringModified, setMonitoringModified] = useState(false)
    const [minutesToDeviceDownTicketModified, setMinutesToDeviceDownTicketModified] = useState(false)
    const [localitySummaryModified, setLocalitySummaryModified] = useState(false)

    const ids = useMemo(() => range(0, 11).map(() => uniqueId()), [])

    const documentTitle = name && `${name} - ${t('tab.modules', 'Modules')}`

    const submit = useCallback(() => {
        const body: OrganizationModel = { name }

        if (monitoringModified) {
            body.isMonitoringEnabled = isMonitoringEnabled
        }

        if (localitySummaryModified) {
            body.isLocalitySummaryEnabled = isLocalitySummaryEnabled
        }

        if (minutesToDeviceDownTicketModified) {
            body.minutesToDeviceDownTicket = minutesToDeviceDownTicket
        }

        props.onSubmit(body, featuresModified ? features : undefined)
    }, [
        props.onSubmit,
        name,
        features,
        featuresModified,
        isMonitoringEnabled,
        monitoringModified,
        isLocalitySummaryEnabled,
        localitySummaryModified,
        minutesToDeviceDownTicket,
        minutesToDeviceDownTicketModified,
    ])

    return (
        <>
            <Helmet>
                <title>{documentTitle}</title>
            </Helmet>
            <Box paddingSize="lg">
                <Box.Title
                    buttons={
                        <AsyncButton
                            loadingText={t('button.saving', 'Saving...')}
                            status={props.submissionStatus}
                            text={t('button.submit', 'Submit')}
                            type="button"
                            variant="primary"
                            onClick={submit}
                        />
                    }
                    text={t('tab.editInformation', 'Edit information')}
                />
                <Form
                    onSubmit={(e) => {
                        e.preventDefault()
                        submit()
                    }}
                >
                    <Form.Group as={Row}>
                        <Form.Label column={true} sm={2}>
                            {t('table.organization', 'Organization name')}
                        </Form.Label>
                        <Col sm={10}>
                            <TextInput value={name} onChange={setName} />
                        </Col>
                    </Form.Group>
                    <RoleChecker whitelist={[Role.Administrator]}>
                        <>
                            <Form.Group as={Row}>
                                <Form.Label column={true} sm={2}>
                                    {t('heading.localitySummaryVisibility', 'Locality summary visibility')}
                                </Form.Label>
                                <Col>
                                    <Form.Control
                                        as="select"
                                        className={styles.summaryVisibilityPicker}
                                        value={isLocalitySummaryEnabled ? '1' : ''}
                                        onChange={(e) => {
                                            setLocalitySummaryEnabled(Boolean(e.currentTarget.value))
                                            setLocalitySummaryModified(true)
                                        }}
                                    >
                                        <option value="1">{t('form.enableLocalitySummary', 'Always visible')}</option>
                                        <option value="">
                                            {t('form.disableLocalitySummary', 'Visible only when filtering localities')}
                                        </option>
                                    </Form.Control>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row}>
                                <Form.Label column={true} sm={2}>
                                    {t('heading.monitoringConfiguration', 'Monitoring')}
                                </Form.Label>
                                <Col>
                                    <ToggleButton
                                        leftToggle={{
                                            toggleValue: '1',
                                            toggleText: t('button.enabled', 'Enabled'),
                                        }}
                                        rightToggle={{
                                            toggleValue: '',
                                            toggleText: t('button.disabled', 'Disabled'),
                                        }}
                                        toggleName={ids[10]}
                                        toggleValue={isMonitoringEnabled ? '1' : ''}
                                        onToggle={(value) => {
                                            setMonitoringModified(true)
                                            setMonitoringEnabled(Boolean(value))
                                        }}
                                    />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row}>
                                <Form.Label column={true} sm={2}>
                                    {t('form.minutesToDeviceDownTicket', 'Minutes to "Device down" ticket')}
                                </Form.Label>
                                <Col>
                                    <NumberInput
                                        value={minutesToDeviceDownTicket}
                                        onChange={(value) => {
                                            setMinutesToDeviceDownTicketModified(true)
                                            setMinutestToDeviceDownTicket(value)
                                        }}
                                    />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row}>
                                <Form.Label column={true} sm={2}>
                                    {t('heading.modulesConfiguration', 'Configure modules')}
                                </Form.Label>
                                <Col className={styles.modulesContainer} sm={{ span: 10 }}>
                                    <Form.Check
                                        checked={features.footfall}
                                        id={ids[0]}
                                        label={t('form.enableFootfallModule', 'Enable footfall module')}
                                        custom
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            const footfall = e.currentTarget.checked
                                            setFeatures((prev) => ({
                                                ...prev,
                                                footfall,
                                            }))
                                            setFeaturesModified(true)
                                        }}
                                    />
                                    <Form.Check
                                        checked={features.realtimeOccupancy}
                                        id={ids[1]}
                                        label={t(
                                            'form.enableStaySafe',
                                            'Enable real-time occupancy tracking (Vividi Stay Safe)'
                                        )}
                                        custom
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            const realtimeOccupancy = e.currentTarget.checked
                                            setFeatures((prev) => ({
                                                ...prev,
                                                realtimeOccupancy,
                                            }))
                                            setFeaturesModified(true)
                                        }}
                                    />
                                    <Form.Check
                                        checked={features.queueMonitoring}
                                        id={ids[2]}
                                        label={t('form.enableQueuesModule', 'Enable queues module')}
                                        custom
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            const queueMonitoring = e.currentTarget.checked
                                            setFeatures((prev) => ({
                                                ...prev,
                                                queueMonitoring,
                                            }))
                                            setFeaturesModified(true)
                                        }}
                                    />
                                    <Form.Check
                                        checked={features.conversions}
                                        id={ids[3]}
                                        label={t('form.enableConversionsModule', 'Enable conversions module')}
                                        custom
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            const conversions = e.currentTarget.checked
                                            setFeatures((prev) => ({
                                                ...prev,
                                                conversions,
                                            }))
                                            setFeaturesModified(true)
                                        }}
                                    />
                                    <Form.Check
                                        checked={features.floorplans}
                                        id={ids[4]}
                                        label={t(
                                            'form.enableFloorplanHeatmapModule',
                                            'Enable floor plan heatmap module'
                                        )}
                                        custom
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            const floorplans = e.currentTarget.checked
                                            setFeatures((prev) => ({
                                                ...prev,
                                                floorplans,
                                            }))
                                            setFeaturesModified(true)
                                        }}
                                    />
                                    <Form.Check
                                        checked={features.zoneOccupancy}
                                        id={ids[5]}
                                        label={t('form.enableOccupancyModule', 'Enable occupancy module')}
                                        custom
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            const zoneOccupancy = e.currentTarget.checked
                                            setFeatures((prev) => ({
                                                ...prev,
                                                zoneOccupancy,
                                            }))
                                            setFeaturesModified(true)
                                        }}
                                    />
                                    <Form.Check
                                        checked={features.emotions}
                                        id={ids[6]}
                                        label={t('form.enableEmotionsModule', 'Enable emotions module')}
                                        custom
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            const emotions = e.currentTarget.checked
                                            setFeatures((prev) => ({
                                                ...prev,
                                                emotions,
                                            }))
                                            setFeaturesModified(true)
                                        }}
                                    />
                                    <Form.Check
                                        checked={features.staySafeNotifier}
                                        id={ids[7]}
                                        label={t('form.enableStaySafeNotifications', 'Enable StaySafe notifications')}
                                        type="checkbox"
                                        custom
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            const staySafeNotifier = e.currentTarget.checked
                                            setFeatures((prev) => ({
                                                ...prev,
                                                staySafeNotifier,
                                            }))
                                            setFeaturesModified(true)
                                        }}
                                    />
                                    <Form.Check
                                        checked={features.staySafeFloorplan}
                                        id={ids[8]}
                                        label={t(
                                            'form.enableStaySafeFloorplanModule',
                                            'Enable StaySafe floorplan module'
                                        )}
                                        type="checkbox"
                                        custom
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            const staySafeFloorplan = e.currentTarget.checked
                                            setFeatures((prev) => ({
                                                ...prev,
                                                staySafeFloorplan,
                                            }))
                                            setFeaturesModified(true)
                                        }}
                                    />
                                </Col>
                            </Form.Group>
                        </>
                    </RoleChecker>
                    {/* Allows submit by enter, while main control button is in layout */}
                    <Form.Group as={Row} className="d-none">
                        <Col sm={{ span: 10 }}>
                            <AsyncButton
                                loadingText="Saving..."
                                status={props.submissionStatus}
                                text="Submit"
                                type="submit"
                                variant="primary"
                            />
                        </Col>
                    </Form.Group>
                </Form>
            </Box>
        </>
    )
}

export default OrganizationEditForm
