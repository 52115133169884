import { faChevronLeft, faChevronRight, faVideo } from '@fortawesome/free-solid-svg-icons'
import classNames from 'classnames'
import { differenceBy, findIndex, groupBy, head, isEmpty } from 'lodash'
import React, { useMemo, useState } from 'react'
import { Button } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import { useHistory, useLocation } from 'react-router'

import { DeviceResponse, OrganizationResponse } from '@api'

import { deviceApi, localityApi, organizationApi } from '@services'

import { useApiCallCleaner } from '@helpers/api'

import IconButton from '@elements/Buttons/IconButton'

import { LayoutHeading } from '@components/Layouts/LayoutHeading'
import MultiSelectVerticalListGroup from '@components/NavigationLists/MultiSelectVerticalListGroup'
import { VerticalListEntry } from '@components/NavigationLists/VerticalListGroup'
import { useNotify } from '@components/notifications/NotificationsContext'

import styles from './AssignDevicesForm.module.scss'
import DevicesMultiselectList from './DevicesMultiselectList'

type ExtendedVerticalListEntry = { organizationId: string } & VerticalListEntry

const prepareDeviceListNavigationEntries = (
    devices: DeviceResponse[],
    getOrganizationName: (organizationId: string) => string | undefined
): Array<{
    organizationName: string
    organizationId: string
    devices: Array<ExtendedVerticalListEntry>
}> => {
    return Object.entries(groupBy(devices, 'organizationId')).map(([organizationId, devices]) => ({
        organizationId,
        organizationName: getOrganizationName(organizationId) ?? '',
        devices: devices.map(({ id, color }) => ({
            icon: faVideo,
            id: String(id),
            displayText: `#${id}`,
            className: classNames(
                styles.deviceIconContainer,
                { [styles.white]: color === 'white' },
                { [styles.black]: color === 'black' }
            ),
            organizationId,
        })),
    }))
}

const useSearchHandler = () => {
    const history = useHistory()
    const location = useLocation()

    const availableDevicesSearch = useMemo(
        () => new URLSearchParams(location.search).get('available-devices') ?? '',
        [location.search]
    )

    const organizationDevicesSearch = useMemo(
        () => new URLSearchParams(location.search).get('organization-devices') ?? '',
        [location.search]
    )

    return {
        onSearch: (selectedSearchBox: 'available-devices' | 'organization-devices', search: string) => {
            if (selectedSearchBox === 'organization-devices' && availableDevicesSearch) {
                if (search === '') {
                    history.replace({
                        ...location,
                        search: `available-devices=${availableDevicesSearch}`,
                    })
                } else {
                    history.replace({
                        ...location,
                        search: `available-devices=${availableDevicesSearch}&organization-devices=${search}`,
                    })
                }

                return
            }

            if (selectedSearchBox === 'available-devices' && organizationDevicesSearch) {
                if (search === '') {
                    history.replace({
                        ...location,
                        search: `organization-devices=${organizationDevicesSearch}`,
                    })
                } else {
                    history.replace({
                        ...location,
                        search: `available-devices=${search}&organization-devices=${organizationDevicesSearch}`,
                    })
                }

                return
            }

            history.replace({ ...location, search: search ? `${selectedSearchBox}=${search}` : undefined })
        },
        availableDevicesSearch,
        organizationDevicesSearch,
    }
}

const AssignDevicesForm: React.FC<{
    devices: Array<DeviceResponse>
    visibleOrganizations: Array<OrganizationResponse>
    selectedOrganization: OrganizationResponse
    organizationDevices: Array<DeviceResponse>
}> = ({ devices, visibleOrganizations, selectedOrganization, organizationDevices: devicesInOrganization }) => {
    const { t } = useTranslation()
    const clean = useApiCallCleaner()
    const notify = useNotify()
    const { onSearch, availableDevicesSearch, organizationDevicesSearch } = useSearchHandler()

    const { mutateAsync: addDevice } = useMutation(deviceApi.updateDevice)

    const [selectedAvailableDevices, setSelectedAvailableDevices] = useState<Array<ExtendedVerticalListEntry>>([])

    const leftListNavigationEntries = useMemo(
        () =>
            prepareDeviceListNavigationEntries(
                devices,
                (organizationId) => visibleOrganizations.find((o) => o.id === parseInt(organizationId, 10))?.name
            ),
        [devices, visibleOrganizations]
    )

    const rightListNavigationEntries = useMemo(
        () =>
            head(prepareDeviceListNavigationEntries(devicesInOrganization, (_) => selectedOrganization.name))
                ?.devices ?? [],
        [devicesInOrganization, selectedOrganization]
    )

    const filteredAvailableDevices = leftListNavigationEntries
        .filter(({ devices }) => devices.some(({ id }) => id.includes(availableDevicesSearch)))
        .map(({ organizationName, organizationId, devices }) => {
            return {
                organizationId,
                organizationName,
                devices: devices.filter(({ id }) => id.includes(availableDevicesSearch)),
            }
        })

    const selectableDevices = filteredAvailableDevices.filter(
        ({ organizationId }) => parseInt(organizationId, 10) !== selectedOrganization.id
    )

    const [selectedOrganizationDevices, setSelectedOrganizationDevices] = useState<Array<ExtendedVerticalListEntry>>(
        rightListNavigationEntries.map((device) => ({ ...device, disabled: true }))
    )

    const [selectedMoveCandidates, setSelectedMoveCandidates] = useState<Array<ExtendedVerticalListEntry>>([])

    if (!isEmpty(selectedOrganizationDevices)) {
        //filter out devices under organization that have been selected for move

        selectedOrganizationDevices.forEach((candidate) => {
            const orgIndex = selectableDevices.findIndex(
                ({ organizationId }) => organizationId === candidate.organizationId
            )

            if (orgIndex !== -1) {
                selectableDevices[orgIndex].devices = selectableDevices[orgIndex].devices.filter(
                    ({ id }) => id !== candidate.id
                )
            }
        })
    }

    const filteredOrganizationDevices = selectedOrganizationDevices.filter(({ id }) =>
        id.includes(organizationDevicesSearch)
    )

    const handleSelectAvailableDevices = (id: string) => {
        const organizationDevices = selectableDevices.find(({ devices }) =>
            devices.some(({ id: entryId }) => entryId === id)
        )

        if (organizationDevices) {
            const index = findIndex(organizationDevices.devices, (devices) => devices.id === id)
            const selectionCandidate = organizationDevices.devices[index]

            setSelectedAvailableDevices((prev) =>
                prev.map(({ id }) => id).includes(selectionCandidate.id)
                    ? prev.filter(({ id }) => id !== selectionCandidate.id)
                    : prev.concat(selectionCandidate)
            )
        }
    }

    const handleSelectMoveCandidates = (id: string) => {
        const moveCandidate = filteredOrganizationDevices.find((entry) => entry.id === id && !entry.disabled)

        if (moveCandidate) {
            const isSelected = selectedMoveCandidates.some(({ id }) => id === moveCandidate.id)

            setSelectedMoveCandidates((prev) =>
                isSelected ? prev.filter(({ id: candidateId }) => candidateId !== id) : prev.concat(moveCandidate)
            )
        }
    }

    const handleMoveToOrganization = () => {
        setSelectedOrganizationDevices((prev) =>
            prev.concat(selectedAvailableDevices.map((entry) => ({ ...entry, disabled: false })))
        )
        setSelectedAvailableDevices([])
    }

    const handleResetSelection = () => {
        const resetDevices = selectedOrganizationDevices.filter(
            ({ id }) => !selectedMoveCandidates.some(({ id: candidateId }) => id === candidateId)
        )
        setSelectedOrganizationDevices(resetDevices)
        setSelectedAvailableDevices([])
        setSelectedMoveCandidates([])
    }

    const handleDiscardAllActions = () => {
        setSelectedOrganizationDevices(
            rightListNavigationEntries.map((device) => ({ ...device, disabled: true })) ?? []
        )
        setSelectedAvailableDevices([])
        setSelectedMoveCandidates([])
    }

    const handleAssignDevicesToOrganization = async (devices: ExtendedVerticalListEntry[]) => {
        const deviceIds = devices.map(({ id }) => parseInt(id, 10))

        try {
            const promises = deviceIds.map((deviceId) =>
                addDevice({
                    deviceId,
                    body: { organizationId: selectedOrganization.id },
                })
            )

            await Promise.all(promises)

            clean(deviceApi)
            clean(organizationApi)
            clean(localityApi)
        } catch (error) {
            notify({
                title: t('notification.error', 'Error'),
                variant: 'Error',
                timeoutSeconds: 5,
                content: t('notification.addingDeviceFailed', 'Adding device failed'),
            })
        }
    }

    const movableDevices = rightListNavigationEntries
        ? differenceBy(
              selectedOrganizationDevices.filter(({ disabled }) => !disabled),
              rightListNavigationEntries,
              'id'
          )
        : []

    const hasMovableDevices = !isEmpty(movableDevices)

    return (
        <>
            <div className={styles.assignDeviceContainer}>
                <div className={styles.availableDevicesContainer}>
                    <LayoutHeading heading={t('title.availableDevices', 'Available Devices')} headingType="section" />
                    <DevicesMultiselectList
                        placeholder={t('form.deviceId', 'Device id')}
                        search={availableDevicesSearch}
                        selectableEntries={selectableDevices}
                        selectedEntries={selectedAvailableDevices.map(({ id }) => id)}
                        onSearch={(search) => onSearch('available-devices', search)}
                        onSelectionChange={(id) => handleSelectAvailableDevices(id)}
                    />
                </div>
                <div className={styles.controlSwitchContainer}>
                    <IconButton icon={faChevronRight} onClick={handleMoveToOrganization} />
                    <IconButton disabled={!hasMovableDevices} icon={faChevronLeft} onClick={handleResetSelection} />
                </div>
                <div className={styles.availableDevicesContainer}>
                    <LayoutHeading
                        heading={t('title.organizationDevices', '{{value}} devices', {
                            value: selectedOrganization.name,
                        })}
                        headingType="section"
                    />
                    <MultiSelectVerticalListGroup
                        placeholder={t('form.deviceId', 'Device id')}
                        search={organizationDevicesSearch}
                        selectableEntries={filteredOrganizationDevices}
                        selectedEntries={selectedMoveCandidates.map(({ id }) => id)}
                        onSearch={(search) => onSearch('organization-devices', search)}
                        onSelectionChange={(id) => handleSelectMoveCandidates(id)}
                    />
                </div>
            </div>
            {hasMovableDevices && (
                <>
                    <div className={styles.devicesToOrganizationButtonContainer}>
                        <Button variant="primary" onClick={() => handleAssignDevicesToOrganization(movableDevices)}>
                            {t('button.addDevicesToOrganization', 'Add {{value}} devices to {{organization}}', {
                                value: movableDevices.length,
                                organization: selectedOrganization.name,
                            })}
                        </Button>
                        <Button variant="secondary" onClick={handleDiscardAllActions}>
                            {t('button.cancel', 'Cancel')}
                        </Button>
                    </div>
                </>
            )}
        </>
    )
}

export default AssignDevicesForm
