import { faCaretDown, faCaretUp, IconDefinition } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import { isEmpty } from 'lodash'
import React, { ReactElement, ReactNode } from 'react'
import { Button, Accordion } from 'react-bootstrap'

import { Possibly } from '@helpers/types'

import styles from './AccordionLayout.module.scss'
import { LayoutHeading, LayoutHeadingProps } from './LayoutHeading'
import SpacedLayout from './SpacedLayout'

const AccordionLayout: React.FC<
    {
        items: Array<{ id: string; icon?: IconDefinition; displayText: string; className?: string }>
        selectedItem?: string
        onSelect: (item?: string) => void
        selectedItemContent?: ReactElement
        placeholder?: ReactNode
        subHeading?: ReactNode
    } & Possibly<LayoutHeadingProps>
> = ({
    items,
    selectedItem,
    onSelect,
    selectedItemContent,
    placeholder,
    heading,
    headingClassName,
    headingButtons,
    headingType,
    subHeading,
}) => (
    <SpacedLayout gapSize="sm">
        {heading && (
            <LayoutHeading
                heading={heading}
                headingButtons={headingButtons}
                headingClassName={headingClassName}
                headingType={headingType}
            />
        )}
        {subHeading}
        {(isEmpty(items) || selectedItemContent === undefined) && placeholder}
        {!isEmpty(items) && selectedItemContent !== undefined && (
            <Accordion activeKey={selectedItem} className={styles.accordion}>
                {items.map(({ id, icon, displayText, className }) => {
                    const isActive = selectedItem === id

                    return (
                        <React.Fragment key={id}>
                            <Accordion.Toggle
                                as={Button}
                                className={classNames(styles.toggle, { [styles.active]: isActive })}
                                eventKey={id}
                                onClick={() => onSelect(!isActive ? id : undefined)}
                            >
                                {icon && (
                                    <div className={className}>
                                        <FontAwesomeIcon icon={icon} />
                                    </div>
                                )}
                                {displayText}
                                <FontAwesomeIcon
                                    className={styles.caret}
                                    icon={id === selectedItem ? faCaretDown : faCaretUp}
                                />
                            </Accordion.Toggle>
                            {isActive && <Accordion.Collapse eventKey={id}>{selectedItemContent}</Accordion.Collapse>}
                        </React.Fragment>
                    )
                })}
            </Accordion>
        )}
    </SpacedLayout>
)

export default AccordionLayout
