/* tslint:disable */
/* eslint-disable */
/**
 * Vividi Dashboard API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OrganizationResponse,
    OrganizationResponseFromJSON,
    OrganizationResponseFromJSONTyped,
    OrganizationResponseToJSON,
} from './OrganizationResponse';
import {
    PlainUserResponse,
    PlainUserResponseFromJSON,
    PlainUserResponseFromJSONTyped,
    PlainUserResponseToJSON,
} from './PlainUserResponse';

/**
 * 
 * @export
 * @interface OrganizationListResponse
 */
export interface OrganizationListResponse {
    /**
     * 
     * @type {Array<OrganizationResponse>}
     * @memberof OrganizationListResponse
     */
    organizations: Array<OrganizationResponse>;
    /**
     * Owners of the organizations (indexed by their identifiers)
     * @type {{ [key: string]: PlainUserResponse; }}
     * @memberof OrganizationListResponse
     */
    owners: { [key: string]: PlainUserResponse; };
}

export function OrganizationListResponseFromJSON(json: any): OrganizationListResponse {
    return OrganizationListResponseFromJSONTyped(json, false);
}

export function OrganizationListResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrganizationListResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'organizations': ((json['organizations'] as Array<any>).map(OrganizationResponseFromJSON)),
        'owners': (mapValues(json['owners'], PlainUserResponseFromJSON)),
    };
}

export function OrganizationListResponseToJSON(value?: OrganizationListResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'organizations': ((value.organizations as Array<any>).map(OrganizationResponseToJSON)),
        'owners': (mapValues(value.owners, PlainUserResponseToJSON)),
    };
}

