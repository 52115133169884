import classNames from 'classnames'
import { uniqueId } from 'lodash'
import React, { useMemo } from 'react'
import { Popover, OverlayTrigger, OverlayTriggerProps } from 'react-bootstrap'

interface Props {
    title?: React.ReactNode
    content: React.ReactNode | React.ReactNode[]
    children: React.ReactElement
    trigger: OverlayTriggerProps['trigger']
    placement: OverlayTriggerProps['placement']
    isWide?: boolean
    isOpen?: boolean
    onToggle?: (isOpen: boolean) => void
    onExit?: () => void
}

const PopoverButton: React.FC<Props> = ({
    title,
    content,
    children,
    trigger,
    placement,
    isWide,
    isOpen,
    onToggle,
    onExit,
}) => {
    const id = useMemo(uniqueId, [])

    const Overlay = (
        <Popover className={classNames({ wide: isWide })} id={id}>
            {title !== undefined && <Popover.Title>{title}</Popover.Title>}
            <Popover.Content>{content}</Popover.Content>
        </Popover>
    )

    return (
        <OverlayTrigger
            overlay={Overlay}
            placement={placement}
            rootClose={true}
            show={isOpen !== undefined ? isOpen : undefined}
            trigger={trigger}
            onExit={onExit}
            onToggle={(isOpen) => {
                if (onToggle) {
                    onToggle(isOpen)
                }
            }}
        >
            {children}
        </OverlayTrigger>
    )
}

export default PopoverButton
