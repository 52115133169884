import { faCaretUp, faCaretDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import { detect } from 'detect-browser'
import { LocationDescriptor } from 'history'
import { Dictionary, range } from 'lodash'
import { isEmpty, noop, zip } from 'lodash'
import { Interval } from 'luxon'
import React, { useMemo, useState } from 'react'
import { Pagination } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import { passCSSVariable } from '@helpers/cssUtils'
import { orderTableRowsByProperty, stableSort } from '@helpers/orderFunctions'
import { GetProps, NumberFormat, Possibly } from '@helpers/types'

import ChangeIndicator, { TrendType } from '@components/StatisticsSummary/LocalityTable/ChangeIndicator'

import NameCell from './NameCell'
import NumericCell from './NumericCell'
import styles from './Table.module.scss'

type Order = 'asc' | 'desc'

type CellType = 'text' | 'numeric' | 'rawElement'

type Align = 'left' | 'center' | 'right'

export type HeaderCell = {
    name: string
    displayName: string
    align?: Align
}

type CellContentBase = {
    cellType: CellType
    content?: number | string
}

export type CellContent = CellContentBase &
    (
        | ({
              cellType: 'numeric'
              numberFormat: NumberFormat
              desiredTrend?: TrendType
              explanatoryString?: string
              targetLink?: string | LocationDescriptor
              renderer?: React.FC<
                  GetProps<typeof NumericCell> & {
                      explanatoryString?: string
                      targetLink?: string | LocationDescriptor
                      roundingPrecision?: number
                  }
              >
              roundingPrecision?: number
          } & Possibly<{
              valueChange: number
              comparisonValue: number
              isChangeAbsolute: boolean
          }>)
        | ({ cellType: 'text'; align?: Align; explanatoryString?: string } & Possibly<{
              targetLink: string | LocationDescriptor
              renderer: React.FC<
                  GetProps<typeof NameCell> & { explanatoryString?: string; targetLink?: string | LocationDescriptor }
              >
          }>)
        | {
              cellType: 'rawElement'
              element: JSX.Element
          }
    )

function getComparator(
    order: Order,
    orderBy: string
): (a: { [key: string]: CellContent }, b: { [key: string]: CellContent }) => number {
    return order === 'desc'
        ? (a, b) => orderTableRowsByProperty(a, b, orderBy)
        : (a, b) => -orderTableRowsByProperty(a, b, orderBy)
}

interface SortingIconProps {
    columnName: string
    orderPropertyName: string
    order: Order
}

const SortingIcon: React.FC<SortingIconProps> = ({ columnName, orderPropertyName, order }) => (
    <>
        {columnName !== orderPropertyName && (
            <span className={styles.sortIconContainer}>
                <FontAwesomeIcon className={styles.sortIcon} icon={faCaretDown} />
                <FontAwesomeIcon className={styles.sortIcon} icon={faCaretUp} />
            </span>
        )}
        {order === 'desc' && columnName === orderPropertyName && (
            <span className={styles.sortIconContainer}>
                <FontAwesomeIcon className={styles.sortIconActive} icon={faCaretDown} />
            </span>
        )}
        {order === 'asc' && columnName === orderPropertyName && (
            <span className={styles.sortIconContainer}>
                <FontAwesomeIcon className={styles.sortIconActive} icon={faCaretUp} />
            </span>
        )}
    </>
)

const determineAlignmentClass = (align?: Align) =>
    classNames({
        [styles.name]: true,
        [styles.leftAlign]: align === 'left',
        [styles.centerAlign]: align === 'center',
        [styles.rightAlign]: align === 'right',
    })

interface TableHeadProps {
    headRow: Array<HeaderCell>
    onSort: (property: string) => void
    order: Order
    orderBy: string
    excludedSortingColumns?: Array<string>
    mobileIncludedColumns?: Array<string>
    displaySortingIcons?: boolean
    hasActionColumn?: boolean
}

const TableHead: React.FC<TableHeadProps> = ({
    headRow,
    onSort,
    order,
    orderBy,
    excludedSortingColumns,
    mobileIncludedColumns,
    displaySortingIcons,
    hasActionColumn,
}) => {
    const { t } = useTranslation()

    return (
        <thead>
            <tr>
                {headRow.map(({ name, displayName, align }, index) => {
                    const isColumnExcludedFromSorting = excludedSortingColumns && excludedSortingColumns.includes(name)

                    const isColumnIncludedOnMobile =
                        mobileIncludedColumns === undefined || mobileIncludedColumns.includes(name)

                    return (
                        <th
                            key={index}
                            className={classNames(determineAlignmentClass(align), {
                                [styles.tableHeadWithPointer]: displaySortingIcons,
                                [styles.hiddenOnMobile]: !isColumnIncludedOnMobile,
                            })}
                            onClick={() => (isColumnExcludedFromSorting ? noop : onSort(name))}
                        >
                            {displayName}
                            {displaySortingIcons &&
                                (isColumnExcludedFromSorting ? null : (
                                    <SortingIcon columnName={name} order={order} orderPropertyName={orderBy} />
                                ))}
                        </th>
                    )
                })}
                {hasActionColumn && <th>{t('table.actions', 'Actions')}</th>}
            </tr>
        </thead>
    )
}

interface TableBodyProps {
    bodyRows: Array<Dictionary<CellContent>>
    addonRows?: Array<{ items: Array<JSX.Element>; filteredIndexes: Array<number> }>
    comparisonInterval?: Interval
    mobileIncludedColumns?: Array<string>
    filteredIndexes?: Array<number>
}

const TableBody: React.FC<TableBodyProps> = ({
    bodyRows,
    addonRows,
    comparisonInterval,
    mobileIncludedColumns,
    filteredIndexes,
}) => {
    const { t } = useTranslation()

    return (
        <tbody>
            {zip(bodyRows, addonRows ?? []).map(([row, addonRows], index) => {
                const cells = Object.entries(row!)
                const rowFilteredOut = filteredIndexes !== undefined && !filteredIndexes.includes(index)

                return (
                    <tr
                        key={`row-${index}`}
                        className={classNames({
                            [styles.filteredOut]: rowFilteredOut,
                        })}
                    >
                        {cells.map(([columnName, cell], index) => {
                            const isCellIncludedOnMobile =
                                mobileIncludedColumns === undefined || mobileIncludedColumns.includes(columnName)

                            if (cell.cellType === 'rawElement') {
                                return React.cloneElement(cell.element, {
                                    key: `-${index}`,
                                    className: classNames(cell.element.props.className, {
                                        [styles.hiddenOnMobile]: !isCellIncludedOnMobile,
                                    }),
                                })
                            }

                            if (cell.content === undefined) {
                                return (
                                    <td key={`${cell.content}-${index}`} className="d-flex justify-content-end">
                                        <span className="text-muted">{t('others.noData', 'No data')}</span>
                                    </td>
                                )
                            }

                            if (cell.cellType === 'text') {
                                const CellComponent = cell.renderer ?? NameCell

                                return (
                                    <CellComponent
                                        key={`${cell.content}-${index}`}
                                        className={classNames(determineAlignmentClass(cell?.align), {
                                            [styles.hiddenOnMobile]: !isCellIncludedOnMobile,
                                        })}
                                        explanatoryString={cell.explanatoryString}
                                        targetLink={cell.targetLink}
                                        text={cell.content as string}
                                    />
                                )
                            }

                            if (cell.cellType === 'numeric') {
                                const CellComponent = cell.renderer ?? NumericCell

                                const isComparisonDataPresent =
                                    cell.valueChange !== undefined &&
                                    cell.comparisonValue !== undefined &&
                                    comparisonInterval !== undefined

                                return (
                                    <CellComponent
                                        key={`${cell.content}-${index}`}
                                        changeIndicator={
                                            isComparisonDataPresent && (
                                                <ChangeIndicator
                                                    change={cell.valueChange!}
                                                    comparisonInterval={comparisonInterval!}
                                                    dataType={cell.numberFormat}
                                                    desiredTrend={cell.desiredTrend ?? 'positive'}
                                                    isChangeAbsolute={cell.isChangeAbsolute}
                                                    isVisible={true}
                                                    roundingPrecision={cell.roundingPrecision}
                                                    valueToCompare={cell.comparisonValue!}
                                                />
                                            )
                                        }
                                        className={classNames({
                                            [styles.hiddenOnMobile]: !isCellIncludedOnMobile,
                                        })}
                                        explanatoryString={cell.explanatoryString}
                                        roundingPrecision={cell.roundingPrecision}
                                        targetLink={cell.targetLink}
                                        value={cell.content as number}
                                        valueType={cell.numberFormat}
                                    />
                                )
                            }

                            return null
                        })}
                        {addonRows?.items.map((addon, i) => (
                            <td
                                key={addon.key ?? i}
                                className={classNames(styles.addonRow, {
                                    [styles.filteredOut]: rowFilteredOut || !addonRows?.filteredIndexes.includes(i),
                                })}
                            >
                                {addon}
                            </td>
                        )) ?? null}
                    </tr>
                )
            })}
        </tbody>
    )
}

interface Props {
    className?: string
    headRow: Array<HeaderCell>
    bodyRows: Array<Dictionary<CellContent>>
    addonRows?: TableBodyProps['addonRows']
    defaultSortingColumn: string
    defaultSortingOrder: Order
    excludedSortingColumns?: Array<string>
    mobileIncludedColumns?: Array<string>
    comparisonInterval?: Interval
    hasActionColumn?: boolean
    paginationSize: number
    /**
     * an optional list of indexes for rows you want displayed (hides non-included indexes)
     */
    filteredIndexes?: Array<number>
}

const TableWithSorting: React.FC<Props> = ({
    className,
    headRow,
    bodyRows,
    addonRows,
    defaultSortingColumn,
    defaultSortingOrder,
    excludedSortingColumns,
    mobileIncludedColumns,
    hasActionColumn,
    paginationSize,
    comparisonInterval,
    filteredIndexes,
}) => {
    const pages = !isEmpty(bodyRows) ? Math.ceil(bodyRows.length / paginationSize) : 0

    const [selectedPage, setSelectedPage] = useState<number>(1)

    const [order, setOrder] = React.useState<Order>(defaultSortingOrder)
    const [orderBy, setOrderBy] = React.useState<string>(defaultSortingColumn)
    const rows = useMemo(
        () =>
            stableSort(bodyRows, getComparator(order, orderBy)).slice(
                (selectedPage - 1) * paginationSize,
                selectedPage * paginationSize
            ),
        [bodyRows, order, orderBy, selectedPage]
    )

    const pageNumbers = range(1, pages + 1).map((pageNumber) => (
        <Pagination.Item
            key={pageNumber}
            active={pageNumber === selectedPage}
            onClick={() => setSelectedPage(pageNumber)}
        >
            {pageNumber}
        </Pagination.Item>
    ))

    const handleRequestSort = (property: string) => {
        const isSorted = orderBy === property

        const isNumericColumn = rows.some((it) => it[property].cellType === 'numeric')

        // sort numeric columns in descending order first, while text columns should be sorted alphabetically (ascending) first
        const newOrder = isSorted && order === 'asc' ? 'desc' : !isSorted && isNumericColumn ? 'desc' : 'asc'

        setOrder(newOrder)
        setOrderBy(property)
    }

    const browser = detect()
    const headRowCount = browser?.os === 'iOS' ? headRow.length + 1 : headRow.length
    const headRowCountMobile =
        mobileIncludedColumns !== undefined
            ? browser?.os === 'iOS'
                ? mobileIncludedColumns.length + 1
                : mobileIncludedColumns.length
            : headRowCount

    return (
        <>
            <table
                className={classNames(styles.table, className)}
                style={{
                    ...passCSSVariable('ColCount', headRowCount),
                    ...passCSSVariable('ColCountMobile', headRowCountMobile),
                }}
            >
                <TableHead
                    displaySortingIcons={!isEmpty(bodyRows) && !(Object.keys(bodyRows).length === 1)}
                    excludedSortingColumns={excludedSortingColumns}
                    hasActionColumn={hasActionColumn}
                    headRow={headRow}
                    mobileIncludedColumns={mobileIncludedColumns}
                    order={order}
                    orderBy={orderBy}
                    onSort={handleRequestSort}
                />
                <TableBody
                    addonRows={addonRows}
                    bodyRows={rows}
                    comparisonInterval={comparisonInterval}
                    filteredIndexes={filteredIndexes}
                    mobileIncludedColumns={mobileIncludedColumns}
                />
            </table>

            {pages > 1 && (
                <Pagination>
                    <Pagination.Prev onClick={() => (selectedPage === 1 ? 1 : setSelectedPage(selectedPage - 1))} />
                    {pageNumbers}
                    <Pagination.Next
                        onClick={() => (selectedPage === pages ? pages : setSelectedPage(selectedPage + 1))}
                    />
                </Pagination>
            )}
        </>
    )
}

export default TableWithSorting
