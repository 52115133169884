/* tslint:disable */
/* eslint-disable */
/**
 * Vividi Dashboard API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface IntervalPropertiesSummary
 */
export interface IntervalPropertiesSummary {
    /**
     * 
     * @type {string}
     * @memberof IntervalPropertiesSummary
     */
    startingFrom: string;
    /**
     * 
     * @type {string}
     * @memberof IntervalPropertiesSummary
     */
    endingAt: string;
    /**
     * Portion (0-1) of interval within opening hours
     * @type {number}
     * @memberof IntervalPropertiesSummary
     */
    openedPortion: number;
    /**
     * Property name => Property value => Number of visits with given property
     * @type {{ [key: string]: { [key: string]: number; }; }}
     * @memberof IntervalPropertiesSummary
     */
    properties: { [key: string]: { [key: string]: number; }; };
}

export function IntervalPropertiesSummaryFromJSON(json: any): IntervalPropertiesSummary {
    return IntervalPropertiesSummaryFromJSONTyped(json, false);
}

export function IntervalPropertiesSummaryFromJSONTyped(json: any, ignoreDiscriminator: boolean): IntervalPropertiesSummary {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'startingFrom': json['starting_from'],
        'endingAt': json['ending_at'],
        'openedPortion': json['opened_portion'],
        'properties': json['properties'],
    };
}

export function IntervalPropertiesSummaryToJSON(value?: IntervalPropertiesSummary | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'starting_from': value.startingFrom,
        'ending_at': value.endingAt,
        'opened_portion': value.openedPortion,
        'properties': value.properties,
    };
}

