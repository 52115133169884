import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { Interval } from 'luxon'

import { LocalityConfigurationStateModel } from '@api/models'

import { displayNumericValue, displayPreciseDuration, formatLargeNumber } from '@helpers/displayUtils'
import { IndicatorValueType } from '@helpers/types'

import ExplanationTooltip from '@components/ExplanationTooltip'

import ChangeIndicator, { TrendType } from './LocalityTable/ChangeIndicator'
import styles from './OccupancyStatisticBox.module.scss'

type Props = {
    data: Array<{
        name: string
        value: number
        icon?: IconDefinition
        unit?: JSX.Element | string
        change: number
        comparisonValue?: number
        valueType?: IndicatorValueType
        valueChangeType?: IndicatorValueType
        desiredTrend?: TrendType
        isChangeAbsolute?: boolean
        description: string
    }>
    interval: Interval
    comparisonInterval?: Interval
    localities: LocalityConfigurationStateModel[]
}

const OccupancyStatisticBox = ({ data, interval, comparisonInterval, localities }: Props) => {
    return (
        <div className={styles.wrapper}>
            {data.map((chunk) => (
                <div key={chunk.name} className={styles.column}>
                    <div className={styles.label}>
                        {chunk.name}
                        <ExplanationTooltip
                            selectedInterval={interval}
                            selectedLocalities={localities}
                            statisticsValue={
                                chunk.valueType === 'time' ? displayPreciseDuration(chunk.value) : chunk.value
                            }
                            statisticsValueDescription={chunk.description}
                        />
                    </div>
                    <div className={styles.dataDisplay}>
                        <div className={styles.data}>
                            {chunk.valueType === 'time'
                                ? displayPreciseDuration(chunk.value)
                                : formatLargeNumber(displayNumericValue(chunk.value, 0))}
                        </div>
                    </div>
                    {comparisonInterval && (
                        <div className={styles.changeContainer}>
                            <ChangeIndicator
                                change={chunk.change ?? 0}
                                comparisonInterval={comparisonInterval}
                                dataType={chunk.valueType !== 'time' ? undefined : 'time'}
                                desiredTrend={chunk.desiredTrend ?? 'positive'}
                                isChangeAbsolute={chunk.isChangeAbsolute}
                                isVisible={chunk.change !== undefined && comparisonInterval !== undefined}
                                valueToCompare={chunk.comparisonValue ?? 0}
                                isBigger
                            />
                        </div>
                    )}
                </div>
            ))}
        </div>
    )
}

export default OccupancyStatisticBox
