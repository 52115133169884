import { faClock } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { pickBy } from 'lodash'
import React from 'react'
import { Button, Spinner } from 'react-bootstrap'
import Helmet from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { Link } from 'react-router-dom'

import { ReportFrequency, LocalityNameModel } from '@api/models'

import { reportsApi, localityApi } from '@services'

import {
    generateFootfallReportListPath,
    generateOccupancyReportListPath,
    generateConversionReportListPath,
    generateQueueReportListPath,
} from '@helpers/VividiURLs'
import { mergeQueryResults, visibleOrganizationsQuery } from '@helpers/api'
import { orderScenes, orderLocalities, orderOrganizations } from '@helpers/orderFunctions'
import useProfile from '@helpers/profile'

import { BoxWithContainer } from '@elements/Box/BoxWithContainer'

import BadgeList from '@components/BadgeList'
import ConversionDownloadButton from '@components/ConversionReportList/DownloadButton'
import styles from '@components/DeviceList/DeviceList.module.scss'
import ErrorView from '@components/ErrorView'
import FeatureChecker from '@components/FeatureChecker'
import FootfallDownloadButton from '@components/FootfallReportList/DownloadButton'
import SpacedLayout from '@components/Layouts/SpacedLayout'
import LegacyLoadingWrapper from '@components/LegacyLoadingWrapper'
import Navbar from '@components/Navbar/Navbar'
import OccupancyDownloadButton from '@components/OccupancyReportList/DownloadButton'
import QueueDownloadButton from '@components/QueueReportList/DownloadButton'
import ResponsiveTable from '@components/ResponsiveTable/ResponsiveTable'
import tableStyles from '@components/ResponsiveTable/ResponsiveTable.module.scss'
import SceneBadgeList from '@components/SceneBadgeList'

const ScenesInLocalityList: React.FC<{ locality: LocalityNameModel }> = ({ locality }) => {
    const scenesCall = useQuery(
        localityApi.getLocalityScenes.query({
            localityId: locality.id,
        })
    )

    return (
        <LegacyLoadingWrapper placeholder={<Spinner animation="border" />} request={scenesCall}>
            {(scenes) => <SceneBadgeList maxDisplayed={1} scenes={orderScenes(scenes.scenes)} />}
        </LegacyLoadingWrapper>
    )
}

const FootfallLocalityList: React.FC = () => {
    const { t } = useTranslation()

    const profileCall = useProfile()

    const organizationsCall = useQuery({
        ...visibleOrganizationsQuery(profileCall.data),
    })

    const localitiesCall = useQuery(localityApi.getLocalityConfigurationStates.query())

    return (
        <LegacyLoadingWrapper bare={true} request={mergeQueryResults(localitiesCall, organizationsCall)}>
            {([localities, organizations]) => {
                const footfallLocalities = orderLocalities(
                    Object.values(
                        pickBy(
                            localities.localities,
                            (l) =>
                                l.isFootfallConfigured &&
                                organizations.organizations.find((o) => o.id === l.organizationId)!.features.footfall
                        )
                    )
                )

                if (Object.keys(footfallLocalities).length === 0) {
                    return (
                        <ErrorView
                            message={t(
                                'others.footfallNotConfigured',
                                'Visit boundaries are not configured for any of your devices'
                            )}
                        />
                    )
                }

                return (
                    <ResponsiveTable className={tableStyles.horizontalTable}>
                        <thead>
                            <tr>
                                <th>{t('table.locality', 'Locality')}</th>
                                <th className={styles.devicesHeader}>{t('table.devices', 'Devices')}</th>
                                <th>{t('table.actions', 'Actions')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {footfallLocalities.map((locality) => (
                                <tr key={locality.id}>
                                    <td>{locality.name}</td>
                                    <td className={styles.devicesValue}>
                                        <ScenesInLocalityList locality={locality} />
                                    </td>
                                    <td>
                                        <LatestFootfallReportButton
                                            frequency={ReportFrequency.Weekly}
                                            localityId={locality.id}
                                            text={t('button.weekly', 'Weekly')}
                                        />
                                        <LatestFootfallReportButton
                                            frequency={ReportFrequency.Monthly}
                                            localityId={locality.id}
                                            text={t('button.monthly', 'Monthly')}
                                        />
                                        <Button
                                            as={Link}
                                            size="sm"
                                            to={generateFootfallReportListPath(locality.id)}
                                            variant="secondary"
                                        >
                                            <FontAwesomeIcon icon={faClock} /> {t('button.history', 'History')}
                                        </Button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </ResponsiveTable>
                )
            }}
        </LegacyLoadingWrapper>
    )
}

const LatestFootfallReportButton: React.FC<{
    localityId: number
    frequency: ReportFrequency
    text: string
}> = ({ localityId, frequency, text }) => {
    const reportsCall = useQuery(reportsApi.listLocalityFootfallReports.query({ localityId }))

    return (
        <LegacyLoadingWrapper placeholder={<Spinner animation="border" />} request={reportsCall}>
            {(reports) => {
                const reps = reports.reports
                    .filter((r) => r.generationFrequency === frequency)
                    .sort((r1, r2) => r2.startingFrom.localeCompare(r1.startingFrom))

                return reps.length > 0 ? (
                    <FootfallDownloadButton localityId={localityId} reportId={reps[0].reportId} size="sm" text={text} />
                ) : (
                    <></>
                )
            }}
        </LegacyLoadingWrapper>
    )
}

const ConversionLocalityList: React.FC = () => {
    const { t } = useTranslation()

    const profileCall = useProfile()

    const organizationsCall = useQuery({
        ...visibleOrganizationsQuery(profileCall.data),
    })

    const localitiesCall = useQuery(localityApi.getLocalityConfigurationStates.query())

    return (
        <LegacyLoadingWrapper request={mergeQueryResults(localitiesCall, organizationsCall)} bare>
            {([localities, organizations]) => {
                const conversionLocalities = orderLocalities(
                    Object.values(localities.localities).filter(
                        (l) =>
                            l.isConversionsConfigured &&
                            organizations.organizations.find((o) => o.id === l.organizationId)!.features.conversions
                    )
                )

                if (Object.keys(conversionLocalities).length === 0) {
                    return (
                        <ErrorView
                            message={t(
                                'others.conversionsNotConfigured',
                                'Conversions are not configured for any of your localities'
                            )}
                        />
                    )
                }

                return (
                    <ResponsiveTable className={tableStyles.horizontalTable}>
                        <thead>
                            <tr>
                                <th>{t('table.locality', 'Locality')}</th>
                                <th className={styles.devicesHeader}>{t('table.devices', 'Devices')}</th>
                                <th>{t('table.latestReports', 'Latest reports')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {conversionLocalities.map((locality) => (
                                <tr key={locality.id}>
                                    <td>{locality.name}</td>
                                    <td className={styles.devicesValue}>
                                        <ScenesInLocalityList locality={locality} />
                                    </td>
                                    <td>
                                        <LatestConversionReportButton
                                            frequency={ReportFrequency.Weekly}
                                            localityId={locality.id}
                                            text={t('button.weekly', 'Weekly')}
                                        />
                                        <LatestConversionReportButton
                                            frequency={ReportFrequency.Monthly}
                                            localityId={locality.id}
                                            text={t('button.monthly', 'Monthly')}
                                        />
                                        <Button
                                            as={Link}
                                            size="sm"
                                            to={generateConversionReportListPath(locality.id)}
                                            variant="secondary"
                                        >
                                            <FontAwesomeIcon icon={faClock} /> {t('button.history', 'History')}
                                        </Button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </ResponsiveTable>
                )
            }}
        </LegacyLoadingWrapper>
    )
}

const LatestConversionReportButton: React.FC<{
    localityId: number
    frequency: ReportFrequency
    text: string
}> = ({ localityId, frequency, text }) => {
    const reportsCall = useQuery(reportsApi.listLocalityConversionReports.query({ localityId }))

    return (
        <LegacyLoadingWrapper placeholder={<Spinner animation="border" />} request={reportsCall}>
            {(reports) => {
                const reps = reports.reports
                    .filter((r) => r.generationFrequency === frequency)
                    .sort((r1, r2) => r2.startingFrom.localeCompare(r1.startingFrom))

                return reps.length > 0 ? (
                    <ConversionDownloadButton localityId={localityId} reportId={reps[0].reportId} text={text} />
                ) : (
                    <></>
                )
            }}
        </LegacyLoadingWrapper>
    )
}

const QueueLocalityList: React.FC = () => {
    const { t } = useTranslation()

    const profileCall = useProfile()

    const organizationsCall = useQuery({
        ...visibleOrganizationsQuery(profileCall.data),
    })

    const localitiesCall = useQuery(localityApi.getLocalityConfigurationStates.query())

    return (
        <LegacyLoadingWrapper bare={true} request={mergeQueryResults(organizationsCall, localitiesCall)}>
            {([organizations, localities]) => {
                const queueLocalities = orderLocalities(
                    Object.values(
                        pickBy(
                            localities.localities,
                            (l) =>
                                l.isQueuesConfigured &&
                                organizations.organizations.find((o) => o.id === l.organizationId)!.features
                                    .queueMonitoring
                        )
                    )
                )

                if (Object.keys(queueLocalities).length === 0) {
                    return (
                        <ErrorView
                            message={t(
                                'others.queuesNotConfigured',
                                'Queues are not configured for any of your devices'
                            )}
                        />
                    )
                }

                return (
                    <ResponsiveTable className={tableStyles.horizontalTable}>
                        <thead>
                            <tr>
                                <th>{t('table.locality', 'Locality')}</th>
                                <th className={styles.devicesHeader}>{t('table.devices', 'Devices')}</th>
                                <th>{t('table.actions', 'Actions')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {queueLocalities.map((locality) => (
                                <tr key={locality.id}>
                                    <td>{locality.name}</td>
                                    <td className={styles.devicesValue}>
                                        <ScenesInLocalityList locality={locality} />
                                    </td>
                                    <td>
                                        <LatestQueueReportButton
                                            frequency={ReportFrequency.Weekly}
                                            localityId={locality.id}
                                            text={t('button.weekly', 'Weekly')}
                                        />
                                        <LatestQueueReportButton
                                            frequency={ReportFrequency.Monthly}
                                            localityId={locality.id}
                                            text={t('button.monthly', 'Monthly')}
                                        />
                                        <Button
                                            as={Link}
                                            size="sm"
                                            to={generateQueueReportListPath(locality.id)}
                                            variant="secondary"
                                        >
                                            <FontAwesomeIcon icon={faClock} /> {t('button.history', 'History')}
                                        </Button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </ResponsiveTable>
                )
            }}
        </LegacyLoadingWrapper>
    )
}

const LatestQueueReportButton: React.FC<{
    localityId: number
    frequency: ReportFrequency
    text: string
}> = ({ localityId, frequency, text }) => {
    const reportsCall = useQuery(reportsApi.listLocalityQueueStatisticsReports.query({ localityId }))

    return (
        <LegacyLoadingWrapper placeholder={<Spinner animation="border" />} request={reportsCall}>
            {(reports) => {
                const reps = reports.reports
                    .filter((r) => r.generationFrequency === frequency)
                    .sort((r1, r2) => r2.startingFrom.localeCompare(r1.startingFrom))

                return reps.length > 0 ? (
                    <QueueDownloadButton localityId={localityId} reportId={reps[0].reportId} size="sm" text={text} />
                ) : (
                    <></>
                )
            }}
        </LegacyLoadingWrapper>
    )
}

const OccupancyOrganizationList: React.FC = () => {
    const { t } = useTranslation()

    const profileCall = useProfile()
    const organizationsCall = useQuery({
        ...visibleOrganizationsQuery(profileCall.data),
    })

    const localitiesCall = useQuery(localityApi.getLocalityConfigurationStates.query())

    return (
        <LegacyLoadingWrapper bare={true} request={mergeQueryResults(localitiesCall, organizationsCall)}>
            {([localities, organizations]) => {
                const occupancyLocalities = orderLocalities(
                    Object.values(
                        pickBy(
                            localities.localities,
                            (locality) =>
                                locality.isOccupancyConfigured &&
                                organizations.organizations.find((o) => o.id === locality.organizationId)!.features
                                    .zoneOccupancy
                        )
                    )
                )

                const occupancyOrganizations = orderOrganizations(
                    Object.values(
                        pickBy(organizations.organizations, (o) =>
                            occupancyLocalities.some((l) => l.organizationId === o.id)
                        )
                    )
                )

                return (
                    <ResponsiveTable className={tableStyles.horizontalTable}>
                        <thead>
                            <tr>
                                <th>{t('table.organization', 'Organization name')}</th>
                                <th className={styles.devicesHeader}>{t('table.localities', 'Localities')}</th>
                                <th>{t('table.actions', 'Actions')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {occupancyOrganizations.map((organization) => (
                                <tr key={organization.id}>
                                    <td>{organization.name}</td>
                                    <td className={styles.devicesValue}>
                                        <BadgeList
                                            items={occupancyLocalities
                                                .filter((l) => l.organizationId === organization.id)
                                                .map((l) => l.name)}
                                            maxDisplayed={1}
                                        />
                                    </td>
                                    <td>
                                        <LatestOccupancyReportButton organizationId={organization.id} />
                                        <Button
                                            as={Link}
                                            size="sm"
                                            to={generateOccupancyReportListPath(organization.id)}
                                            variant="secondary"
                                        >
                                            <FontAwesomeIcon icon={faClock} /> {t('button.history', 'History')}
                                        </Button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </ResponsiveTable>
                )
            }}
        </LegacyLoadingWrapper>
    )
}

const LatestOccupancyReportButton: React.FC<{ organizationId: number }> = ({ organizationId }) => {
    const { t } = useTranslation()

    const reportsCall = useQuery(reportsApi.listOrganizationOccupancyReports.query({ organizationId }))

    return (
        <LegacyLoadingWrapper placeholder={<Spinner animation="border" />} request={reportsCall}>
            {(reports) => {
                const reps = reports.reports.sort((r1, r2) => r2.startingFrom.localeCompare(r1.startingFrom))

                return reps.length > 0 ? (
                    <OccupancyDownloadButton
                        organizationId={organizationId}
                        reportId={reps[0].reportId}
                        size="sm"
                        text={t('button.weekly', 'Weekly')}
                    />
                ) : (
                    <></>
                )
            }}
        </LegacyLoadingWrapper>
    )
}

const ReportListPage: React.FC = () => {
    const { t } = useTranslation()
    const documentTitle = t('title.reportsList', 'List of reports')

    const conversionHeading = t('heading.conversionReports', 'Conversion Reports')

    const footfallHeading = t('heading.footfallReports', 'Footfall Reports')

    const occupancyHeading = t('heading.occupancyReports', 'Occupancy Reports')

    const queueHeading = t('heading.queueReports', 'Queue Reports')

    return (
        <>
            <SpacedLayout>
                <Navbar />
                <Helmet>
                    <title>{documentTitle}</title>
                </Helmet>
                <FeatureChecker feature="conversions">
                    <BoxWithContainer heading={conversionHeading}>
                        <ConversionLocalityList />
                    </BoxWithContainer>
                </FeatureChecker>
                <FeatureChecker feature="footfall">
                    <BoxWithContainer heading={footfallHeading}>
                        <FootfallLocalityList />
                    </BoxWithContainer>
                </FeatureChecker>
                <FeatureChecker feature="zoneOccupancy">
                    <BoxWithContainer heading={occupancyHeading}>
                        <OccupancyOrganizationList />
                    </BoxWithContainer>
                </FeatureChecker>
                <FeatureChecker feature="queueMonitoring">
                    <BoxWithContainer heading={queueHeading}>
                        <QueueLocalityList />
                    </BoxWithContainer>
                </FeatureChecker>
            </SpacedLayout>
        </>
    )
}

export default ReportListPage
