import classNames from 'classnames'
import { MouseEventHandler, MouseEvent } from 'react'

import { Point } from '@api'

import { EditableVisitBoundary, SceneObjectType } from '@helpers/describeScene'
import { edges } from '@helpers/points'

import BoundaryHeatmapArrow from '@components/DeviceSceneConfiguration/BoundaryDirectionArrow'

import styles from './elements.module.scss'

const determineClassName = (type: EditableVisitBoundary['type']): string => {
    switch (type) {
        case SceneObjectType.entrance:
            return styles.entranceBoundary
        case SceneObjectType.passage:
            return styles.passageBoundary
        case SceneObjectType.otherBoundary:
            return styles.otherBoundary
    }
}

export const Boundary = (props: {
    points: Array<Point>
    type: EditableVisitBoundary['type']
    isEditable: boolean
    isHighlighted: boolean
    editorWidth: number
    onPointInsert?: (event: MouseEvent, index: number) => void
    onPointDelete?: (index: number) => void
    onPointDragStart?: (pointIndex: number) => void
    onMouseOver?: MouseEventHandler
    onMouseOut?: MouseEventHandler
    onClick?: MouseEventHandler
}) => (
    <g
        className={classNames(styles.boundary, determineClassName(props.type), {
            [styles.editable]: props.isEditable,
            [styles.highlighted]: props.isHighlighted,
        })}
        onClick={props.onClick}
        onMouseOut={props.onMouseOut}
        onMouseOver={props.onMouseOver}
    >
        <BoundaryHeatmapArrow className={styles.arrow} points={props.points} scale={props.editorWidth * 0.0025} />
        <polyline
            className={styles.border}
            fill="none"
            points={props.points.map(({ x, y }) => `${x},${y}`).join(' ')}
        />
        <polyline
            className={styles.background}
            fill="none"
            points={props.points.map(({ x, y }) => `${x},${y}`).join(' ')}
        />
        {edges(props.points).map((segment, i) => (
            <BoundarySegment
                key={i}
                edgeIndex={i}
                points={props.points}
                segment={segment}
                onPointInsert={props.onPointInsert}
            />
        ))}
        {props.isEditable &&
            props.points.map((point, pointIndex) => (
                <circle
                    key={`${point.x}+${point.y}`}
                    className={styles.node}
                    cx={point.x}
                    cy={point.y}
                    r={10}
                    onMouseDown={(e) => {
                        e.preventDefault()
                        e.stopPropagation()

                        if (e.button === 2) {
                            if (props.points.length > 2) {
                                props.onPointDelete?.(pointIndex)
                            }
                        } else {
                            props.onPointDragStart?.(pointIndex)
                        }
                    }}
                />
            ))}
    </g>
)

const BoundarySegment = (props: {
    segment: [Point, Point]
    points: Point[]
    onPointInsert?: (event: MouseEvent, index: number) => void
    edgeIndex: number
}) => {
    if (props.edgeIndex === props.points.length - 1) {
        return null
    }

    const [a, b] = props.segment

    return (
        <line
            className={styles.segment}
            x1={a.x}
            x2={b.x}
            y1={a.y}
            y2={b.y}
            onMouseDown={(e) => {
                e.preventDefault()
                e.stopPropagation()

                props.onPointInsert?.(e, props.edgeIndex + 1)
            }}
        />
    )
}
