import { max, min, castArray, uniqueId } from 'lodash'
import { DateTime, Interval } from 'luxon'
import React, { useState, useMemo } from 'react'
import { Popover, OverlayTrigger } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import AutoSizer from 'react-virtualized-auto-sizer'
import { ComposedChart, ReferenceArea, ReferenceLine, XAxis } from 'recharts'

import { OutageModel, Role, IntervalWeather, WeatherDescription } from '@api'

import { weatherIcon, displayTemperature } from '@helpers/displayUtils'
import useProfile from '@helpers/profile'
import { useTimezoneConfig } from '@helpers/timezoneConfig'
import { displayTranslatedWeather } from '@helpers/translateUtils'
import { IntervalType } from '@helpers/types'
import { outageInterval } from '@helpers/utils'

import palette from '@components/palette.module.scss'

import styles from './TimeLineChart.module.scss'
import { EventData, filterTickIndexes, getDividersForInterval, tickFormatter, timePointToSeconds } from './common'

type Props = {
    children?: React.ReactNode | Array<React.ReactNode>
    canvasRef?: React.RefObject<HTMLCanvasElement>
    data: Array<{
        name: string
        hasData: boolean
        interval: Interval
        openedPortion: number
        weather?: IntervalWeather
    }>
    startDate: DateTime
    endDate: DateTime
    selectedInterval?: Interval
    intervalType: IntervalType
    onSelectInterval: (interval: Interval) => void
    determinedIntervalType?: Exclude<IntervalType, IntervalType.CUSTOM>
    outages: OutageModel[]
    now: DateTime
    className?: string
}

const extractWeatherIconData = (weather: WeatherDescription): string[] => castArray(weatherIcon(weather).icon[4])

const XAxisTick: React.FC<{
    x: number
    y: number
    width: number
    height: number
    payload: { value: string }
    data: Props['data']
    intervalType: IntervalType
    determinedIntervalType?: Exclude<IntervalType, IntervalType.CUSTOM>
}> = (props) => {
    const { t } = useTranslation()

    const barWidth = props.width / props.data.length
    const index = parseInt(props.payload.value, 10)
    const weather = props.data[index]?.weather
    const tooltipId = useMemo(uniqueId, [])

    const zone = useTimezoneConfig()

    return (
        <g transform={`translate(${props.x - barWidth / 2},${props.y})`}>
            <line stroke={palette.darkgrey} x1={0} x2={0} y1={-7} y2={0} />
            <text className="small" fill={palette.darkgrey} textAnchor="start" x={0} y={16}>
                {tickFormatter(
                    props.payload.value,
                    zone,
                    props.data[index]?.interval,
                    props.intervalType,
                    props.determinedIntervalType
                )}
            </text>
            {weather?.weather !== undefined && (
                <OverlayTrigger
                    overlay={
                        <Popover id={tooltipId}>
                            <Popover.Content>{displayTranslatedWeather(weather.weather, t)}</Popover.Content>
                        </Popover>
                    }
                    trigger={['hover', 'focus']}
                >
                    <g transform="translate(0, 27)">
                        <rect fill={palette.white} height={24} width={24} />
                        {extractWeatherIconData(weather.weather).map((data, i) => (
                            <path
                                key={i}
                                d={data}
                                fill={palette.darkGrey}
                                stroke={palette.darkGrey}
                                transform="scale(0.04)"
                            />
                        ))}
                    </g>
                </OverlayTrigger>
            )}
            {weather?.temperature !== undefined && (
                <text className="small" fill={palette.darkgrey} textAnchor="start" x={0} y={68}>
                    {displayTemperature(weather.temperature)}
                </text>
            )}
        </g>
    )
}

const TimeLineChart = ({
    children,
    canvasRef,
    data,
    startDate,
    endDate,
    selectedInterval,
    intervalType,
    onSelectInterval,
    outages,
    now,
    className,
    determinedIntervalType,
}: Props) => {
    const [downPoint, setDownPoint] = useState<Interval>()
    const [upPoint, setUpPoint] = useState<Interval>()
    const [active, setActive] = useState(false)

    const zone = useTimezoneConfig()
    const profile = useProfile()
    const shouldDisplayOutages = profile.status === 'success' && profile.data!.role === Role.Administrator

    const intervalsWithOutages = Interval.merge(
        data
            .filter((segment) => segment.openedPortion > 0)
            .map((segment) => segment.interval)
            .filter((interval) => interval.isBefore(now) || interval.contains(now))
            .filter((interval) => outages.map(outageInterval).some((o) => o.overlaps(interval)))
    )

    const nonTrivialIntervalSelected =
        selectedInterval !== undefined && !selectedInterval.equals(Interval.fromDateTimes(startDate, endDate))

    const onMouseDown = (event: EventData) => {
        if (event && event.activePayload !== undefined) {
            setDownPoint(event.activePayload[0].payload.interval as Interval)
            setUpPoint(event.activePayload[0].payload.interval as Interval)
            setActive(true)
        }
    }

    const onMouseMove = (event: EventData) => {
        if (event && event.activePayload !== undefined && active) {
            setUpPoint(event.activePayload[0].payload.interval as Interval)
        }
    }

    const onMouseUp = () => {
        setActive(false)

        if (downPoint === undefined || upPoint === undefined) {
            return
        }

        if (upPoint.equals(downPoint) && nonTrivialIntervalSelected && selectedInterval?.engulfs(upPoint)) {
            // Clicking outside of selected interval unselects it
            onSelectInterval(Interval.fromDateTimes(startDate, endDate))
        } else if (downPoint <= upPoint) {
            onSelectInterval(Interval.fromDateTimes(downPoint.start, upPoint.end))
        } else {
            onSelectInterval(Interval.fromDateTimes(upPoint.start, downPoint.end))
        }
    }

    const selectionMarker = (
        <ReferenceArea fill={palette.darkgrey} fillOpacity={0.2} stroke={palette.darkgrey} xAxisId="seconds" />
    )

    return (
        <div className={className}>
            <canvas ref={canvasRef} style={{ display: 'none' }} />
            <AutoSizer>
                {({ width, height }) => (
                    <ComposedChart
                        className={styles.rechartsSurface}
                        data={data}
                        height={height}
                        width={width}
                        onMouseDown={onMouseDown}
                        onMouseMove={onMouseMove}
                        onMouseUp={onMouseUp}
                    >
                        <XAxis
                            dataKey="name"
                            height={data.some((it) => it.weather !== undefined) ? 72 : undefined}
                            interval="preserveStartEnd"
                            tick={(tickProps) => (
                                <XAxisTick
                                    {...tickProps}
                                    data={data}
                                    determinedIntervalType={determinedIntervalType}
                                    intervalType={intervalType}
                                />
                            )}
                            tickFormatter={(i) =>
                                tickFormatter(
                                    i,
                                    zone,
                                    data[parseInt(i, 10)]?.interval,
                                    intervalType,
                                    determinedIntervalType
                                )
                            }
                            tickLine={false}
                            ticks={filterTickIndexes(zone, intervalType ?? IntervalType.DAY, data)}
                        />
                        {shouldDisplayOutages &&
                            intervalsWithOutages.map((interval) => (
                                <ReferenceArea
                                    key={`outage-${interval.start.toISO()}`}
                                    fill={palette.mediumGrey}
                                    x1={timePointToSeconds(startDate, endDate, interval.start)}
                                    x2={timePointToSeconds(startDate, endDate, interval.end)}
                                    xAxisId="seconds"
                                />
                            ))}
                        {getDividersForInterval(Interval.fromDateTimes(startDate, endDate), zone)?.map((date) => {
                            const intervalLength = Interval.fromDateTimes(startDate, date).length('seconds')

                            return (
                                <ReferenceLine
                                    key={intervalLength}
                                    stroke={palette.mediumGrey}
                                    x={intervalLength}
                                    xAxisId="seconds"
                                />
                            )
                        })}
                        <XAxis
                            dataKey="time"
                            domain={[0, endDate.diff(startDate).as('seconds')]}
                            hide={true}
                            type="number"
                            xAxisId="seconds"
                        />
                        {children}
                        {upPoint &&
                            downPoint &&
                            active &&
                            React.cloneElement(selectionMarker, {
                                x1: timePointToSeconds(startDate, endDate, min([upPoint, downPoint])!.start),
                                x2: timePointToSeconds(startDate, endDate, max([upPoint, downPoint])!.end),
                            })}
                        {!active &&
                            nonTrivialIntervalSelected &&
                            React.cloneElement(selectionMarker, {
                                x1: timePointToSeconds(startDate, endDate, selectedInterval!.start),
                                x2: timePointToSeconds(startDate, endDate, selectedInterval!.end),
                            })}
                    </ComposedChart>
                )}
            </AutoSizer>
        </div>
    )
}

export default TimeLineChart
