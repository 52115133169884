import { head, mapValues, max, noop, range, sum, uniqueId } from 'lodash'
import { Interval, DateTime } from 'luxon'
import React, { useMemo, useRef, useState } from 'react'
import { Row, Col, Form, Container, Image } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { Area, Bar, CartesianGrid, Tooltip, YAxis } from 'recharts'

import {
    LocalityResponse,
    QueueStatistics,
    QueueStatisticsResponse,
    SalesStatisticsDetailsSummaryResponse,
    SalesStatisticsDetailsSummaryRow,
    SceneDescription,
    SceneListResponse,
} from '@api'

import { generateOrganizationScenesPath } from '@helpers/VividiURLs'
import { useAddBackLink } from '@helpers/backlinks'
import { displayPreciseDuration } from '@helpers/displayUtils'
import { IntervalType } from '@helpers/types'

import Box from '@elements/Box/Box'
import PlotButton from '@elements/Buttons/PlotButton'
import ToggleButton from '@elements/Buttons/ToggleButton'
import TableWithSorting from '@elements/Table/TableWithSorting'

import AdminOnlyLinkRenderer from '@components/AdminOnlyLinkRenderer'
import { ConfigurationHeatmapCarousel } from '@components/ConfigurationHeatmapCarousel'
import StatisticBox, { StatisticBoxItem } from '@components/StatisticsSummary/StatisticBox'
import statisticsBoxStyles from '@components/StatisticsSummary/StatisticBox.module.scss'
import palette from '@components/palette.module.scss'
import Cursor from '@components/plots/Cursor'
import TimeLineChart from '@components/plots/TimeLineChart'
import TooltipContent from '@components/plots/TooltipContent'
import TooltipLabel from '@components/plots/TooltipLabel'
import TooltipTable from '@components/plots/TooltipTable'

import queueFlow from '@images/highlights/queue-flow.png'

import styles from './QueueDetails.module.scss'
import { prepareQueueData, prepareSalesPerZone } from './queueDetailsPreprocessors'

const QueueHighlights: React.FC<{
    queueData: QueueStatistics[]
    interval: Interval
    locality: LocalityResponse
}> = ({ queueData, interval, locality }) => {
    const { t } = useTranslation()

    const totalOpenedPortion = sum(
        queueData.map((q) => {
            return q.openedPortion
        })
    )
    const totalWaitSampleCount = sum(
        queueData.map((q) => {
            return q.waitTimeSampleCount
        })
    )
    const totalServeSampleCount = sum(
        queueData.map((q) => {
            return q.serveTimeSampleCount
        })
    )

    const averageQueueSize =
        sum(
            queueData.map((q) => {
                return q.openedPortion * (q.queueSizeAverage ?? 0)
            })
        ) / (totalOpenedPortion || 1)
    const averageWaitTime =
        sum(
            queueData.map((q) => {
                return Math.max(1, q.waitTimeSampleCount ?? 0) * (q.waitTimeAverage ?? 0)
            })
        ) / (totalWaitSampleCount || 1)
    const averageServeTime =
        sum(
            queueData.map((q) => {
                return Math.max(1, q.serveTimeSampleCount ?? 0) * (q.serveTimeAverage ?? 0)
            })
        ) / (totalServeSampleCount || 1)

    return (
        <Container>
            <Row className="mb-0">
                <Col className={styles.highlightsContainer} md={6}>
                    <StatisticBox
                        className={statisticsBoxStyles.rightBoxCol}
                        interval={interval}
                        label={t('statistics.avgQueueSize', 'Average queue size')}
                        localities={[locality]}
                        sm={3}
                        tooltip={t('statistics.avgQueueSizeInLocation', 'average queue size in the location')}
                    >
                        <StatisticBoxItem value={averageQueueSize} />
                    </StatisticBox>
                    <StatisticBox
                        className={`${statisticsBoxStyles.leftBoxCol} ${statisticsBoxStyles.queueHighlight}`}
                        interval={interval}
                        label={t('statistics.avgWaitTime', 'Average wait time')}
                        localities={[locality]}
                        sm={3}
                        tooltip={t('statistics.avgWaitTimeLocalityDescription', 'average wait time in the queue')}
                    >
                        <StatisticBoxItem value={displayPreciseDuration(Math.round(averageWaitTime))} />
                    </StatisticBox>
                </Col>
                <Col className={styles.highlightsContainer} md={6}>
                    <StatisticBox
                        className={`${statisticsBoxStyles.rightBoxCol} ${statisticsBoxStyles.serveHighlight}`}
                        interval={interval}
                        label={t('statistics.averageServeTime', 'Average serve time')}
                        localities={[locality]}
                        sm={3}
                        tooltip={t(
                            'statistics.avgServeTimeLocalityDescription',
                            'average serve time in a point-of-sale zone'
                        )}
                    >
                        <StatisticBoxItem value={displayPreciseDuration(Math.round(averageServeTime))} />
                    </StatisticBox>
                    <StatisticBox
                        className={`${statisticsBoxStyles.leftBoxCol} ${statisticsBoxStyles.salesHighlight}`}
                        interval={interval}
                        label={t('statistics.totalSales', 'Total sales')}
                        localities={[locality]}
                        sm={3}
                        tooltip={t('statistics.totalSalesDescription', 'total number of point-of-sales interactions')}
                    >
                        <StatisticBoxItem value={totalServeSampleCount ?? 0} />
                    </StatisticBox>
                </Col>
            </Row>
            <Image className={styles.queueFlowImage} src={queueFlow} />
        </Container>
    )
}

const QueueTimesPlot: React.FC<{
    queueData: QueueStatistics[]
    now: DateTime
    intervalType: IntervalType
    interval: Interval
    aggType: string
}> = ({ queueData, now, intervalType, interval, aggType }) => {
    const { t } = useTranslation()

    const canvasRef = useRef<HTMLCanvasElement>(null)
    const ids = useMemo(() => range(2).map(() => uniqueId()), [])

    const data = prepareQueueData(queueData, now)

    const canvasContext = canvasRef.current?.getContext('2d')

    const [displayedGraphs, setDisplayedGraphs] = useState({
        waitTime: true,
        serveTime: true,
    })
    const maxData =
        max(
            data.flatMap((it) =>
                aggType === 'average' ? [it.averageWaitTime, it.averageServeTime] : [it.maxWaitTime, it.maxServeTime]
            )
        ) ?? 0
    const axisWidth = canvasContext?.measureText((-maxData).toString()).width

    return (
        <>
            <div>
                <Form>
                    <Form.Group>
                        <span className={styles.plotControlsLabel}>
                            <strong>{t('others.show', 'Show:')}</strong>
                        </span>
                        <PlotButton
                            buttonText={t('statistics.waitTimeInQueue', 'Wait time in queue')}
                            id={ids[0]}
                            isActive={displayedGraphs.waitTime}
                            underlineColor={palette.queueDetailsWaitTime}
                            onClick={() =>
                                setDisplayedGraphs((prev) => ({
                                    ...prev,
                                    waitTime: !prev.waitTime,
                                }))
                            }
                        />
                        <PlotButton
                            buttonText={t('statistics.serveTime', 'Serve time')}
                            id={ids[1]}
                            isActive={displayedGraphs.serveTime}
                            underlineColor={palette.queueDetailsServeTime}
                            onClick={() =>
                                setDisplayedGraphs((prev) => ({
                                    ...prev,
                                    serveTime: !prev.serveTime,
                                }))
                            }
                        />
                    </Form.Group>
                </Form>
            </div>
            <TimeLineChart
                canvasRef={canvasRef}
                className={styles.queueTimesPlot}
                data={data}
                endDate={interval.end}
                intervalType={intervalType}
                now={now}
                outages={[]}
                startDate={interval.end}
                onSelectInterval={noop}
            >
                <YAxis
                    allowDecimals={false}
                    domain={[0, 1.1 * maxData]}
                    tick={(tickProps) => {
                        return (
                            <g transform={`translate(${tickProps.x},${tickProps.y})`}>
                                <text className="small" fill={palette.darkgrey} textAnchor="end" x={-4} y={4}>
                                    {displayPreciseDuration(tickProps.payload.value)}
                                </text>
                            </g>
                        )
                    }}
                    ticks={[0, Math.round(maxData / 3), Math.round((maxData / 3) * 2), Math.round(maxData)]}
                    width={(axisWidth ?? 30) + 6}
                />
                {displayedGraphs.waitTime && aggType === 'average' && (
                    <Area
                        dataKey="averageWaitTime"
                        fill={palette.queueDetailsWaitTime}
                        fillOpacity={0.2}
                        stroke={palette.queueDetailsWaitTime}
                        strokeWidth={2}
                        type="monotone"
                    />
                )}
                {displayedGraphs.serveTime && aggType === 'average' && (
                    <Area
                        dataKey="averageServeTime"
                        fill={palette.queueDetailsServeTime}
                        fillOpacity={0.2}
                        stroke={palette.queueDetailsServeTime}
                        strokeWidth={2}
                        type="monotone"
                    />
                )}
                {displayedGraphs.waitTime && aggType === 'max' && (
                    <Area
                        dataKey="maxWaitTime"
                        fill={palette.queueDetailsWaitTime}
                        fillOpacity={0.2}
                        stroke={palette.queueDetailsWaitTime}
                        strokeWidth={2}
                        type="monotone"
                    />
                )}
                {displayedGraphs.serveTime && aggType === 'max' && (
                    <Area
                        dataKey="maxServeTime"
                        fill={palette.queueDetailsServeTime}
                        fillOpacity={0.2}
                        stroke={palette.queueDetailsServeTime}
                        strokeWidth={2}
                        type="monotone"
                    />
                )}
                <CartesianGrid strokeOpacity={0.3} strokeWidth={1} vertical={false} />
                <Tooltip
                    content={
                        <TooltipContent
                            intervalResolver={(label) => data[Number(label)].interval}
                            labelFormatter={(label) => label}
                            now={now}
                            outages={[]}
                        >
                            {(label) => {
                                const record = data.find((it) => it.name === label)!

                                return (
                                    <TooltipTable>
                                        {aggType === 'average' && (
                                            <>
                                                {displayedGraphs.waitTime && (
                                                    <TooltipTable.Item
                                                        label={t('statistics.avgWaitTime', 'Average wait time')}
                                                    >
                                                        {displayPreciseDuration(
                                                            Math.round(record.averageWaitTime ?? 0)
                                                        )}
                                                    </TooltipTable.Item>
                                                )}

                                                {displayedGraphs.serveTime && (
                                                    <TooltipTable.Item
                                                        label={t('statistics.averageServeTime', 'Average serve time')}
                                                    >
                                                        {displayPreciseDuration(
                                                            Math.round(record.averageServeTime ?? 0)
                                                        )}
                                                    </TooltipTable.Item>
                                                )}
                                            </>
                                        )}
                                        {aggType === 'max' && (
                                            <>
                                                {displayedGraphs.waitTime && (
                                                    <TooltipTable.Item
                                                        label={t('statistics.maxWaitTime', 'Max. wait time')}
                                                    >
                                                        {displayPreciseDuration(Math.round(record.maxWaitTime ?? 0))}
                                                    </TooltipTable.Item>
                                                )}

                                                {displayedGraphs.serveTime && (
                                                    <TooltipTable.Item
                                                        label={t('statistics.maxServeTime', 'Max. serve time')}
                                                    >
                                                        {displayPreciseDuration(Math.round(record.maxServeTime ?? 0))}
                                                    </TooltipTable.Item>
                                                )}
                                            </>
                                        )}
                                    </TooltipTable>
                                )
                            }}
                        </TooltipContent>
                    }
                    cursor={<Cursor dataLength={data.length} />}
                    filterNull={false}
                    labelFormatter={(index) => {
                        const item = data[parseInt(index as string, 10)]

                        if (item === undefined) {
                            return
                        }

                        const interval = item.interval

                        return (
                            <TooltipLabel
                                endDate={interval.end}
                                interval={interval}
                                now={now}
                                startDate={interval.start}
                            />
                        )
                    }}
                />
            </TimeLineChart>
        </>
    )
}

const QueueSizePlot: React.FC<{
    queueData: QueueStatistics[]
    now: DateTime
    intervalType: IntervalType
    interval: Interval
}> = ({ queueData, now, intervalType, interval }) => {
    const { t } = useTranslation()

    const canvasRef = useRef<HTMLCanvasElement>(null)

    const data = prepareQueueData(queueData, now)

    const maxData = max(data.flatMap((it) => [it.queueSize])) ?? 0

    const canvasContext = canvasRef.current?.getContext('2d')
    const axisWidth = canvasContext?.measureText((-maxData).toString()).width

    return (
        <>
            <TimeLineChart
                canvasRef={canvasRef}
                className={styles.queueSizePlot}
                data={data}
                endDate={interval.end}
                intervalType={intervalType}
                now={now}
                outages={[]}
                startDate={interval.end}
                onSelectInterval={noop}
            >
                <YAxis
                    allowDecimals={false}
                    domain={[0, 1.1 * maxData]}
                    tick={(tickProps) => {
                        return (
                            <g transform={`translate(${tickProps.x},${tickProps.y})`}>
                                <text className="small" fill={palette.darkgrey} textAnchor="end" x={-4} y={4}>
                                    {tickProps.payload.value}
                                </text>
                            </g>
                        )
                    }}
                    ticks={[0, (maxData / 3).toFixed(1), ((2 * maxData) / 3).toFixed(1), maxData.toFixed(1)]}
                    width={Math.ceil(axisWidth ?? 30) + 6}
                />
                <Bar dataKey="queueSize" fill={palette.vividiPrimary} stroke={palette.vividiPrimary} strokeWidth={2} />
                <CartesianGrid strokeOpacity={0.3} strokeWidth={1} vertical={false} />
                <Tooltip
                    content={
                        <TooltipContent
                            intervalResolver={(label) => data[Number(label)].interval}
                            labelFormatter={(label) => label}
                            now={now}
                            outages={[]}
                        >
                            {(label) => {
                                const record = data.find((it) => it.name === label)!

                                return (
                                    <TooltipTable>
                                        <TooltipTable.Item label={t('statistics.queueSize', 'Queue size')}>
                                            {record.queueSize?.toFixed(1) ?? 0}
                                        </TooltipTable.Item>
                                    </TooltipTable>
                                )
                            }}
                        </TooltipContent>
                    }
                    cursor={<Cursor dataLength={data.length} />}
                    filterNull={false}
                    labelFormatter={(index) => {
                        const item = data[parseInt(index as string, 10)]

                        if (item === undefined) {
                            return
                        }

                        const interval = item.interval

                        return (
                            <TooltipLabel
                                endDate={interval.end}
                                interval={interval}
                                now={now}
                                startDate={interval.start}
                            />
                        )
                    }}
                />
            </TimeLineChart>
        </>
    )
}

const SalesDetailsTable: React.FC<{
    scenes: SceneListResponse
    salesRows: Array<SalesStatisticsDetailsSummaryRow>
}> = ({ scenes, salesRows }) => {
    const { t } = useTranslation()
    const addBackLink = useAddBackLink()

    const salesPerZone = prepareSalesPerZone(salesRows, scenes)
    const organizationId = head(scenes.scenes)?.organizationId ?? -1

    return (
        <TableWithSorting
            bodyRows={salesPerZone.map((sales) => ({
                scene: {
                    content: sales.scene,
                    cellType: 'text',
                    targetLink: addBackLink(
                        generateOrganizationScenesPath(
                            organizationId,
                            scenes.scenes.find((s) => s.label === sales.scene)?.id ?? 0
                        )
                    ),
                    renderer: AdminOnlyLinkRenderer,
                },
                posZone: {
                    content: sales.zone,
                    cellType: 'text',
                },
                totalSales: {
                    content: sales.totalSales,
                    cellType: 'numeric',
                    numberFormat: 'count',
                },
                averageServeTime: {
                    content: sales.averageServeTime,
                    cellType: 'numeric',
                    numberFormat: 'time',
                },
                maxServeTime: {
                    content: sales.maxServeTime,
                    cellType: 'numeric',
                    numberFormat: 'time',
                },
            }))}
            className={styles.table}
            defaultSortingColumn="totalSales"
            defaultSortingOrder="desc"
            headRow={[
                { name: 'scene', displayName: t('table.scene', 'Scene') },
                {
                    name: 'posZone',
                    displayName: t('statistics.posZone', 'PoS zone'),
                },
                {
                    name: 'totalSales',
                    displayName: t('statistics.totalSales', 'Total sales'),
                    align: 'right',
                },
                {
                    name: 'averageServeTime',
                    displayName: t('statistics.averageServeTime', 'Average serve time'),
                    align: 'right',
                },
                {
                    name: 'maxServeTime',
                    displayName: t('statistics.maxServeTime', 'Max. serve time'),
                    align: 'right',
                },
            ]}
            paginationSize={15}
        />
    )
}

interface Props {
    queueData: QueueStatisticsResponse
    salesDetails: SalesStatisticsDetailsSummaryResponse
    scenes: SceneListResponse
    sceneConfiguration: { [sceneId: number]: SceneDescription }
    locality: LocalityResponse
    interval: Interval
    now: DateTime
    intervalType: IntervalType
}

const QueueDetailsContent: React.FC<Props> = (props) => {
    const { t } = useTranslation()

    const scenesInLocality = props.scenes.scenes.filter((s) => s.localityIds.includes(Number(props.locality.id)))
    const [aggType, setAggType] = useState('average')

    return (
        <>
            <Row>
                <Col>
                    <Box>
                        <Box.Title text={t('heading.queueHighlights', 'Queue highlights')} />
                        <QueueHighlights
                            interval={props.interval}
                            locality={props.locality}
                            queueData={props.queueData.items}
                        />
                    </Box>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Box className={styles.plot}>
                        <Box.Title
                            buttons={
                                <ToggleButton
                                    leftToggle={{
                                        toggleText: t('statistics.average', 'Average'),
                                        toggleValue: 'average',
                                    }}
                                    rightToggle={{
                                        toggleText: t('statistics.maximum', 'Maximum'),
                                        toggleValue: 'max',
                                    }}
                                    toggleName="view-toggle"
                                    toggleValue={aggType}
                                    onToggle={setAggType}
                                />
                            }
                            text={t('heading.shopperActivitiesDuration', 'Shopper activities duration')}
                        />
                        <QueueTimesPlot
                            aggType={aggType}
                            interval={props.interval}
                            intervalType={props.intervalType}
                            now={props.now}
                            queueData={props.queueData.items}
                        />
                    </Box>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Box className={styles.plot}>
                        <Box.Title text={t('statistics.queueSizeInTime', 'Queue size in time')} />
                        <QueueSizePlot
                            interval={props.interval}
                            intervalType={props.intervalType}
                            now={props.now}
                            queueData={props.queueData.items}
                        />
                    </Box>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Box className={styles.heatmapCard}>
                        <Box.Title text={`${props.locality.name} ${t('others.zones', ' zones')}`} />
                        <ConfigurationHeatmapCarousel
                            drawBoundaries={false}
                            drawZones={true}
                            scenes={scenesInLocality}
                            zones={mapValues(props.sceneConfiguration, (config) =>
                                config.detectionZones.filter(
                                    (z) =>
                                        props.locality.queuesConfiguration?.queueZones.includes(z.id) ||
                                        props.locality.queuesConfiguration?.salesZones.includes(z.id)
                                )
                            )}
                        />
                    </Box>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Box>
                        <Box.Title text={t('heading.posBreakdown', 'Point-of-sale zones breakdown')} />
                        <SalesDetailsTable salesRows={props.salesDetails.zoneRows} scenes={props.scenes} />
                    </Box>
                </Col>
            </Row>
        </>
    )
}

export default QueueDetailsContent
