import React from 'react'

import { PersonPosition } from '@api/models/PersonPosition'

import { Size } from '@helpers/types'

import palette from '@components/palette.module.scss'

import styles from './FloorplanVisualizer.module.scss'

interface Props {
    personPositions: PersonPosition[]
    floorplanUrl?: string
    floorplanSize?: Size
    width: number
    height: number
    isAdmin: boolean
}

const getStrokeColor = (isMale: boolean | undefined, isAdmin: boolean) => {
    if (isAdmin && isMale === undefined) {
        return palette.black
    }

    if (isAdmin && !isMale) {
        return palette.negativeTrend
    }

    return palette.black
}

const FloorplanVisualizer: React.FC<Props> = (props) => {
    const scale =
        props.floorplanSize !== undefined
            ? Math.min(
                  1,
                  Math.min(props.width / props.floorplanSize!.width, props.height / props.floorplanSize!.height)
              )
            : 1
    const style = { maxWidth: props.width, maxHeight: props.height }

    return (
        <div
            className={styles.editor}
            style={{
                width: props.width,
            }}
        >
            {props.floorplanSize !== undefined && (
                <>
                    <div style={style}>
                        <img
                            alt=""
                            height={scale * props.floorplanSize.height}
                            src={props.floorplanUrl}
                            width={scale * props.floorplanSize.width}
                        />
                    </div>

                    <div style={style}>
                        <svg height={scale * props.floorplanSize.height} width={scale * props.floorplanSize.width}>
                            {props.personPositions.map((entity, i) => {
                                return (
                                    <circle
                                        /* eslint-disable-next-line react/no-array-index-key */
                                        key={i}
                                        cx={scale * entity.x}
                                        cy={scale * entity.y}
                                        fill={
                                            props.isAdmin
                                                ? entity.isMale === undefined
                                                    ? palette.conversionYellowFill
                                                    : entity.isMale
                                                    ? palette.floorplanLiveViewMale
                                                    : palette.floorplanLiveViewFemale
                                                : palette.darkgrey
                                        }
                                        r={5}
                                        stroke={getStrokeColor(entity.isMale, props.isAdmin)}
                                        strokeWidth={1}
                                    />
                                )
                            })}
                        </svg>
                    </div>
                </>
            )}
        </div>
    )
}

export default FloorplanVisualizer
