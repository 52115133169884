import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons'
import React from 'react'

import IconButton from '@elements/Buttons/IconButton'

import styles from './CurrentOccupancySetter.module.scss'

type Props = {
    currentOccupancy: number
    onOccupancyIncrement: () => void
    onOccupancyDecrement: () => void
}

const CurrentOccupancySetter = ({ currentOccupancy, onOccupancyIncrement, onOccupancyDecrement }: Props) => (
    <div className={styles.setter}>
        <IconButton
            className={styles.currentOccupancySetterButton}
            icon={faMinus}
            onClick={() => onOccupancyDecrement()}
        />
        <span className={styles.currentOccupancyText}>{currentOccupancy}</span>
        <IconButton
            className={styles.currentOccupancySetterButton}
            icon={faPlus}
            onClick={() => onOccupancyIncrement()}
        />
    </div>
)

export default CurrentOccupancySetter
