import { faDrawSquare } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { noop, uniqueId, Dictionary } from 'lodash'
import React, { useMemo } from 'react'
import { OverlayTrigger, Popover, Button } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'

import { LocalityNameModel, OrganizationResponse, SceneDescription, SceneResponse } from '@api'

import { localityApi } from '@services'

import Box from '@elements/Box/Box'

import ControlledModal from '@components/ControlledModal'
import LegacyLoadingWrapper from '@components/LegacyLoadingWrapper'
import LoadingSpinner from '@components/LoadingSpinner'

import DescribeSceneTab from './DescribeSceneTab'
import styles from './DescribeSceneTabModal.module.scss'

const DescribeSceneTabModal: React.FC<{
    isDescribeSceneTabModalOpen: boolean
    onDescribeSceneTabModalClose: () => void
    onDescribeSceneTabModalOpen: () => void
    isDescribeSceneTabModalDisabled?: boolean
    selectedLocality: LocalityNameModel
    organization: OrganizationResponse
    sceneDescriptions: Dictionary<SceneDescription>
    scenes: Array<SceneResponse>
    determineSelectScenePath: (sceneId: number) => string
    picker?: JSX.Element
}> = ({
    isDescribeSceneTabModalOpen,
    onDescribeSceneTabModalClose,
    onDescribeSceneTabModalOpen,
    isDescribeSceneTabModalDisabled = false,
    organization,
    selectedLocality,
    sceneDescriptions,
    scenes,
    determineSelectScenePath,
    picker,
}) => {
    const modalId = useMemo(uniqueId, [])
    const { t } = useTranslation()

    const localityCall = useQuery(
        localityApi.getLocality.query({
            localityId: selectedLocality.id,
        })
    )

    return (
        <ControlledModal
            className={styles.modal}
            control={
                isDescribeSceneTabModalDisabled ? (
                    <OverlayTrigger
                        overlay={
                            <Popover id={modalId}>
                                <Popover.Content>
                                    {t(
                                        'others.sceneNotAssignedToLocality',
                                        'Scene not assigned to any locality and cannot be described'
                                    )}
                                </Popover.Content>
                            </Popover>
                        }
                        placement="top"
                        trigger={['hover', 'focus']}
                    >
                        <Button className="btn btn-disabled" onClick={noop}>
                            <FontAwesomeIcon icon={faDrawSquare} />
                            {t('button.describeScene', 'Describe scene')}
                        </Button>
                    </OverlayTrigger>
                ) : (
                    <Button
                        className={styles.describeSceneButton}
                        variant="secondary"
                        onClick={onDescribeSceneTabModalOpen}
                    >
                        <FontAwesomeIcon icon={faDrawSquare} />
                        {t('button.describeScene', 'Describe scene')}
                    </Button>
                )
            }
            modalContainerClassName={styles.modalContainer}
            show={isDescribeSceneTabModalOpen}
            centered
            onHide={onDescribeSceneTabModalClose}
        >
            <LegacyLoadingWrapper
                placeholder={
                    <Box className={styles.placeholderBox} paddingSize="lg">
                        <LoadingSpinner bare />
                    </Box>
                }
                request={localityCall}
                allowBuffering
            >
                {(locality) => (
                    <DescribeSceneTab
                        determineSelectScenePath={determineSelectScenePath}
                        locality={locality}
                        organization={organization}
                        picker={picker}
                        sceneDescriptions={sceneDescriptions}
                        scenes={scenes}
                        onFinished={onDescribeSceneTabModalClose}
                    />
                )}
            </LegacyLoadingWrapper>
        </ControlledModal>
    )
}

export default DescribeSceneTabModal
