import classNames from 'classnames'
import { Interval } from 'luxon'
import React from 'react'
import { Col, ColProps } from 'react-bootstrap'

import { LocalityNameModel } from '@api/models'

import ExplanationTooltip from '@components/ExplanationTooltip'

import styles from './ConversionDataChangeBox.module.scss'
import ChangeIndicator, { TrendType } from './LocalityTable/ChangeIndicator'
import statisticBoxStyles from './StatisticBox.module.scss'

type Props = ColProps & {
    label: string | JSX.Element
    data: {
        value: number
        comparisonValue?: number
        change: number
        unit?: string
        roundingPrecision?: number
    }
    description: string
    interval: Interval
    desiredTrend: TrendType
    comparisonInterval?: Interval
    localities: LocalityNameModel[]
}

const ConversionDataChangeBox = ({
    label,
    data,
    description,
    interval,
    desiredTrend,
    comparisonInterval,
    localities,
    ...rest
}: Props) => {
    return (
        <Col
            {...rest}
            className={classNames({
                [styles.wrapper]: true,
                [styles.maxWidthFitContent]: comparisonInterval,
            })}
        >
            <span className={styles.label}>{label}</span>
            <ExplanationTooltip
                className="mr-3"
                selectedInterval={interval}
                selectedLocalities={localities}
                statisticsValue={[data]}
                statisticsValueDescription={description}
            />
            <div className={classNames(statisticBoxStyles.data, statisticBoxStyles.dataSize)}>{data.value}%</div>
            {comparisonInterval && (
                <ChangeIndicator
                    change={data.change}
                    comparisonInterval={comparisonInterval}
                    dataType="percentage"
                    desiredTrend={desiredTrend}
                    isVisible={comparisonInterval !== undefined}
                    roundingPrecision={data.roundingPrecision}
                    valueToCompare={data.comparisonValue ?? 0}
                    isChangeAbsolute
                />
            )}
        </Col>
    )
}

export default ConversionDataChangeBox
