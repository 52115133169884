import { Dictionary, isEmpty } from 'lodash'
import { DateTime } from 'luxon'
import React from 'react'

import { DatetimeInterval } from '@api/models'

import { useTimezoneConfig } from '@helpers/timezoneConfig'
import { pickColumn } from '@helpers/utils'

import Histogram2D, { SegmentationLevels } from '@components/Histogram2D'
import styles from '@components/Histogram2D.module.scss'

import { prepareHistogramData } from './occupancyDetailsPreproccessors'

interface OccupancyDetailsHistogramProps {
    histogramData: {
        cells: Dictionary<number> | Array<number>
        sceneId: number
        zoneName: string
        zoneId: number
    }[]
    histogramType: 'week' | 'day'
    histogramWidth?: number
    timeBrackets: DatetimeInterval[]
}

export const getDateWeekday = (date: string, formatOptions?: 'short' | 'long' | 'narrow' | undefined) =>
    DateTime.local(...date.split('-').map((it) => Number(it))).toLocaleString({
        weekday: formatOptions ?? 'short',
    })

const getOccupancyHistogramSegmentColor = (value: number, segmentationLevels: SegmentationLevels) => {
    if (value === segmentationLevels.null) {
        return styles.histogramSegmentNull
    }

    if (value <= segmentationLevels.low) {
        return styles.histogramSegmentLow
    }

    if (value > segmentationLevels.low && value <= segmentationLevels.midLow) {
        return styles.histogramSegmentMidLow
    }

    if (value > segmentationLevels.midLow && value <= segmentationLevels.mid) {
        return styles.histogramSegmentMid
    }

    if (value > segmentationLevels.mid && value <= segmentationLevels.midHigh) {
        return styles.histogramSegmentMidHigh
    }

    if (value >= segmentationLevels.midHigh) {
        return styles.histogramSegmentHigh
    }
}

const OccupancyDetailsHistogram: React.FC<OccupancyDetailsHistogramProps> = ({
    histogramData,
    histogramType,
    histogramWidth,
    timeBrackets,
}) => {
    const timeZone = useTimezoneConfig()

    const displayData = prepareHistogramData(timeZone, timeBrackets, histogramData, histogramType)

    return (
        <div style={histogramWidth ? { width: `${histogramWidth}px` } : {}}>
            {!isEmpty(displayData.xLabels) && !isEmpty(displayData.yLabels) && (
                <Histogram2D
                    data={Object.values(displayData.displayData).map((zone) => pickColumn(zone, 'average'))}
                    determineHistogramSegmentColor={getOccupancyHistogramSegmentColor}
                    legendValues={
                        histogramType === 'week'
                            ? ['0 h', '< 2 h', '2 - 4h', '4 - 6h', '6 - 8h ', '> 8h']
                            : ['0min', '< 12min', '12 - 24min', '24 - 36min', '36 - 48min', '> 48min']
                    }
                    segmentationLevels={
                        histogramType === 'week'
                            ? {
                                  null: 0,
                                  low: 2,
                                  midLow: 4,
                                  mid: 6,
                                  midHigh: 8,
                                  high: 10,
                              }
                            : {
                                  null: 0,
                                  low: 0.2,
                                  midLow: 0.4,
                                  mid: 0.6,
                                  midHigh: 0.8,
                                  high: 1,
                              }
                    }
                    xLabels={displayData.xLabels}
                    yLabels={displayData.yLabels}
                />
            )}
        </div>
    )
}

export default OccupancyDetailsHistogram
