import { max, noop } from 'lodash'
import { DateTime, Interval } from 'luxon'
import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Area, CartesianGrid, ReferenceLine, Tooltip, YAxis } from 'recharts'

import { CrowdSizeHistoryItem } from '@api'

import { IntervalType, TooltipFormatter } from '@helpers/types'

import palette from '@components/palette.module.scss'
import Cursor from '@components/plots/Cursor'
import TimeLineChart from '@components/plots/TimeLineChart'
import TooltipContent from '@components/plots/TooltipContent'
import TooltipLabel from '@components/plots/TooltipLabel'
import TooltipTable from '@components/plots/TooltipTable'

import styles from './StaySafeDetails.module.scss'
import { prepareVisitorsInTimeData } from './staySafeDetailsPreprocessors'

const StaySafeGradient: React.FC<{ gradientSplit: number }> = ({ gradientSplit }) => {
    return (
        <linearGradient id={String(gradientSplit)} x2="0%" y2={`${gradientSplit}%`}>
            <stop offset="1" stopColor={palette.negativeTrendRed} />
            <stop offset="1" stopColor={palette.vividiPrimary} />
        </linearGradient>
    )
}

export const StaySafeVisitorsInTimePlot: React.FC<{
    crowdSizeHistory: Array<CrowdSizeHistoryItem>
    now: DateTime
    interval: Interval
    maximumCapacity?: number
}> = ({ crowdSizeHistory, now, interval, maximumCapacity }) => {
    const { t } = useTranslation()

    const canvasRef = useRef<HTMLCanvasElement>(null)

    const data = prepareVisitorsInTimeData(crowdSizeHistory, now)

    const maxData = max(crowdSizeHistory.map((it) => it.maxCrowdSize)) ?? 0

    const canvasContext = canvasRef.current?.getContext('2d')
    const axisWidth = canvasContext?.measureText((-maxData).toString()).width

    const overTheLimit = maxData - (maximumCapacity ?? 0)

    const gradientSplit = (overTheLimit * 100) / (maxData ?? 1)

    return (
        <>
            <TimeLineChart
                key={maxData}
                canvasRef={canvasRef}
                className={styles.visitorsInTimePlot}
                data={data}
                endDate={interval.end}
                intervalType={IntervalType.DAY}
                now={now}
                outages={[]}
                startDate={interval.start}
                onSelectInterval={noop}
            >
                <YAxis
                    allowDecimals={false}
                    domain={[0, 1.1 * maxData]}
                    tick={(tickProps) => {
                        return (
                            <g transform={`translate(${tickProps.x},${tickProps.y})`}>
                                <text className="small" fill={palette.darkgrey} textAnchor="end" x={-4} y={4}>
                                    {tickProps.payload.value}
                                </text>
                            </g>
                        )
                    }}
                    ticks={[0, Math.round(maxData / 2), maxData]}
                    width={Math.ceil(axisWidth ?? 30) + 6}
                />
                <defs>
                    <StaySafeGradient gradientSplit={gradientSplit} />
                </defs>
                <Area
                    activeDot={{ fill: palette.vividiPrimary }}
                    dataKey="maxCrowdSize"
                    fill={
                        maximumCapacity && gradientSplit > 0 ? `url(#${String(gradientSplit)})` : palette.vividiPrimary
                    }
                    fillOpacity={0.2}
                    stroke={
                        maximumCapacity && gradientSplit > 0 ? `url(#${String(gradientSplit)})` : palette.vividiPrimary
                    }
                    strokeWidth={2}
                    type="monotone"
                />
                <CartesianGrid strokeOpacity={0.3} strokeWidth={1} vertical={false} />
                <Tooltip
                    content={
                        <TooltipContent
                            intervalResolver={(label) => data[Number(label)].interval}
                            labelFormatter={(label) => label}
                            now={now}
                            outages={[]}
                        >
                            {(label) => {
                                const record = data.find((it) => it.name === label)!

                                return (
                                    <TooltipTable>
                                        <TooltipTable.Item label={t('form.numberOfVisitors', 'Number of visitors')}>
                                            {record.maxCrowdSize ?? 0}
                                        </TooltipTable.Item>
                                    </TooltipTable>
                                )
                            }}
                        </TooltipContent>
                    }
                    cursor={<Cursor dataLength={data.length} />}
                    filterNull={false}
                    formatter={((value: number, name: string) => [value, name]) as TooltipFormatter}
                    labelFormatter={(index) => {
                        const item = data[parseInt(index as string, 10)]

                        if (item === undefined) {
                            return
                        }

                        const interval = item.interval

                        return (
                            <TooltipLabel
                                endDate={interval.end}
                                interval={interval}
                                now={now}
                                startDate={interval.start}
                            />
                        )
                    }}
                />
                {maximumCapacity && (
                    <ReferenceLine stroke={palette.negativeTrendRed} strokeWidth={2} y={maximumCapacity} />
                )}
            </TimeLineChart>
        </>
    )
}
