import React from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'

import { userApi } from '@services'

import { GetProps } from '@helpers/types'

import { useNotify } from '@components/notifications/NotificationsContext'

import PasswordResetRequestFormView from './PasswordResetRequestFormView'

const PasswordResetRequestForm = (props: {
    formComponent?: React.ComponentType<GetProps<typeof PasswordResetRequestFormView>>
}) => {
    const { t } = useTranslation()

    const {
        mutate: requestReset,
        status,
        reset,
    } = useMutation(userApi.requestPasswordReset.invokeRaw, {
        onSuccess: (data) => {
            if (data.raw.status === 200) {
                reset()
                notify({
                    variant: 'success',
                    title: t('notification.resetLinkSent', 'Reset link sent'),
                    content: t(
                        'notification.resetLinkSentContent',
                        'A link was sent to your e-mail address that you can use to reset your password'
                    ),
                })
            }
        },
        onError: (error) => {
            if (error instanceof Response) {
                if (error.status === 404) {
                    notify({
                        variant: 'warning',
                        title: t('notification.unknownEmail', 'Unknown e-mail address'),
                        content: t('notification.unknownEmailContent', 'Check the address and try again'),
                    })

                    return
                }

                if (error.status === 429) {
                    notify({
                        variant: 'warning',
                        title: t('notification.tooEarlyPasswordRequest', 'Password reset requested too early'),
                        content: t(
                            'notification.tooEarlyPasswordRequestContent',
                            'Wait a few seconds before requesting a reset again'
                        ),
                    })

                    return
                }
            }

            notify({
                variant: 'danger',
                title: t('notification.error', 'Error'),
                content: t('notification.errorPasswordRequest', 'An error occurred when requesting a password reset'),
            })
        },
    })

    const notify = useNotify()

    const Form = props.formComponent ?? PasswordResetRequestFormView

    return (
        <Form
            requestState={status}
            onSubmit={async (email) => {
                requestReset({
                    body: { email },
                })
            }}
        />
    )
}

export default PasswordResetRequestForm
