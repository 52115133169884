/* tslint:disable */
/* eslint-disable */
/**
 * Vividi Dashboard API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CrowdSizeHistoryItem
 */
export interface CrowdSizeHistoryItem {
    /**
     * 
     * @type {string}
     * @memberof CrowdSizeHistoryItem
     */
    startingFrom: string;
    /**
     * 
     * @type {string}
     * @memberof CrowdSizeHistoryItem
     */
    endingAt: string;
    /**
     * 
     * @type {number}
     * @memberof CrowdSizeHistoryItem
     */
    maxCrowdSize?: number;
    /**
     * Portion (0-1) of interval within opening hours
     * @type {number}
     * @memberof CrowdSizeHistoryItem
     */
    openedPortion: number;
}

export function CrowdSizeHistoryItemFromJSON(json: any): CrowdSizeHistoryItem {
    return CrowdSizeHistoryItemFromJSONTyped(json, false);
}

export function CrowdSizeHistoryItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): CrowdSizeHistoryItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'startingFrom': json['starting_from'],
        'endingAt': json['ending_at'],
        'maxCrowdSize': !exists(json, 'max_crowd_size') ? undefined : json['max_crowd_size'],
        'openedPortion': json['opened_portion'],
    };
}

export function CrowdSizeHistoryItemToJSON(value?: CrowdSizeHistoryItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'starting_from': value.startingFrom,
        'ending_at': value.endingAt,
        'max_crowd_size': value.maxCrowdSize,
        'opened_portion': value.openedPortion,
    };
}

