import { head, noop, partition } from 'lodash'
import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'

import { SceneResponse } from '@api/models'

import { sceneApi } from '@services'

import { EditableSceneObject, isSceneObjectEqual, SceneObjectIdentifier } from '@helpers/describeScene'

import { useDetectMobile } from '@hooks/useDetectDevice'

import useSvgEditorData, { EditorDataProps, EditorData } from '@components/DeviceSceneConfiguration/useSvgEditorData'
import ErrorView from '@components/ErrorView'
import LoadingSpinner from '@components/LoadingSpinner'

import styles from './DescribeSceneEditor.module.scss'
import SceneConfigurationVisualizationLayer from './SceneConfigurationVisualizationLayer'
import SceneObjectEditLayer from './SceneObjectEditLayer'

type DescribeSceneEditorProps = {
    scene: SceneResponse
    disableContentScaling: boolean
    width: number
    sceneObjects: Array<EditableSceneObject>
    onObjectChanged: (sceneObject: EditableSceneObject) => void
    selectedObject?: SceneObjectIdentifier
    highlightedObject?: SceneObjectIdentifier
    onSelect: (sceneObject?: SceneObjectIdentifier) => void
    onHighlight: (sceneObject?: SceneObjectIdentifier) => void
}

const DescribeSceneEditor: React.FC<DescribeSceneEditorProps> = ({ scene, disableContentScaling, width, ...props }) => {
    const { t } = useTranslation()

    const backgroundCall = useQuery(
        sceneApi.getTrajectoryHeatmap.query({
            sceneId: scene.id,
        })
    )

    const { imageDimensions, editorDimensions, imageUrl } = useSvgEditorData({
        backgroundCall,
        width,
        disableContentScaling,
    })

    if (backgroundCall.status === 'error') {
        return <ErrorView message={t('notification.heatmapFetchingFailed', 'Failed to fetch scene heatmap')} />
    }

    if (
        backgroundCall.status === 'idle' ||
        backgroundCall.status === 'loading' ||
        imageDimensions === undefined ||
        imageUrl === undefined
    ) {
        return <LoadingSpinner />
    }

    if (editorDimensions.height === undefined) {
        return <LoadingSpinner />
    }

    return (
        <DescribeSceneEditorView
            editorDimensions={editorDimensions}
            imageDimensions={imageDimensions}
            imageUrl={imageUrl}
            scene={scene}
            {...props}
        />
    )
}

type DescribeSceneEditorViewProps = Omit<
    DescribeSceneEditorProps & Required<EditorData>,
    keyof EditorDataProps | 'backgroundCall'
>

const DescribeSceneEditorView: React.FC<DescribeSceneEditorViewProps> = ({
    imageUrl,
    editorDimensions,
    sceneObjects,
    onObjectChanged,
    selectedObject,
    highlightedObject,
    onSelect,
    onHighlight,
}) => {
    const { width, height } = editorDimensions
    const [selectedObjects, notSelectedObjects] = partition(
        sceneObjects,
        (object) => selectedObject !== undefined && isSceneObjectEqual(object, selectedObject)
    )
    const svgRef = useRef<SVGSVGElement>(null)

    const mobile = useDetectMobile()

    return (
        <div className={styles.editor}>
            <img
                alt=""
                src={imageUrl}
                style={{
                    width,
                    height,
                }}
            />
            <svg ref={svgRef} height={height} width={width} onContextMenu={(e) => e.preventDefault()}>
                <SceneConfigurationVisualizationLayer
                    editorDimensions={editorDimensions}
                    highlightedObject={highlightedObject}
                    sceneObjects={notSelectedObjects}
                    onHighlightObject={onHighlight}
                    onSelectObject={!mobile ? onSelect : noop}
                />
                {!mobile && svgRef.current !== null && head(selectedObjects)?.isVisible && (
                    <SceneObjectEditLayer
                        editorDimensions={editorDimensions}
                        sceneObject={selectedObjects[0]}
                        svgElement={svgRef.current}
                        onChange={onObjectChanged}
                    />
                )}
            </svg>
        </div>
    )
}

export default DescribeSceneEditor
