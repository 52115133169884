import { faDownload } from '@fortawesome/free-solid-svg-icons'
import download from 'downloadjs'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'

import { ApiResponse } from '@api'

import { reportsApi } from '@services'

import { filenameFromHeaders } from '@helpers/utils'

import AsyncButton from '@components/AsyncButton'

interface Props {
    reportId: number
    localityId: number
    text?: string
}

export const downloadBlob = async (data: ApiResponse<Blob>) =>
    download(await data.value(), filenameFromHeaders(data.raw), data.raw.headers.get('Content-Type') ?? undefined)

const DownloadButton: React.FC<Props> = ({ reportId, localityId, text }) => {
    const { t } = useTranslation()

    const { mutate: downloadCall, status } = useMutation(reportsApi.getLocalityConversionsReportPdf.invokeRaw, {
        onSuccess: downloadBlob,
    })

    return (
        <AsyncButton
            allowRefetch={true}
            icon={faDownload}
            size="sm"
            status={status}
            text={text ?? t('button.downloadPDF', 'Download PDF')}
            onClick={() => downloadCall({ reportId, localityId })}
        />
    )
}

export default DownloadButton
