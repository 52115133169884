import React from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'

import { organizationApi, userApi } from '@services'

import { useApiCallCleaner } from '@helpers/api'
import { useBackLink } from '@helpers/backlinks'
import useProfile from '@helpers/profile'

import InviteUsersForm from '@components/InviteUsersForm'
import PageContentLayout from '@components/Layouts/PageContentLayout'
import LegacyLoadingWrapper from '@components/LegacyLoadingWrapper'

const InviteUsersPage = () => {
    const { t } = useTranslation()
    const {
        mutate: invite,
        status: inviteState,
        reset,
    } = useMutation(organizationApi.inviteUsersToOrganization, {
        onSuccess: () => {
            clean(userApi)
            clean(organizationApi)
            reset()
            backLink.follow()
        },
    })
    const profileCall = useProfile()
    const backLink = useBackLink()
    const clean = useApiCallCleaner()
    const heading = t('heading.inviteUsers', 'Invite users to your organization')

    return (
        <LegacyLoadingWrapper
            placeholder={<PageContentLayout heading={heading} loadingState={profileCall.status} />}
            request={profileCall}
        >
            {(profile) => (
                <PageContentLayout heading={heading}>
                    <InviteUsersForm
                        submissionStatus={inviteState}
                        onSubmit={(emails) => {
                            if (!profile.organizationId) {
                                return
                            }

                            invite({
                                organizationId: profile.organizationId,
                                body: { emails, language: profile.language },
                            })
                        }}
                    />
                </PageContentLayout>
            )}
        </LegacyLoadingWrapper>
    )
}

export default InviteUsersPage
