import { faExclamationTriangle } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import produce, { Draft } from 'immer'
import { fromPairs, isEmpty, partial, uniqueId } from 'lodash'
import React, { useMemo } from 'react'
import { Col, Form, OverlayTrigger, Popover, Row } from 'react-bootstrap'
import { TFunction, useTranslation } from 'react-i18next'

import {
    ConversionsConfiguration,
    EmotionConfiguration,
    FootfallConfiguration,
    LocalityResponse,
    OccupancyConfiguration,
    OrganizationFeatures,
    OrganizationResponse,
    QueuesConfiguration,
    StaySafeConfiguration,
} from '@api'

import { isSceneObjectEqual, SceneObjectIdentifier } from '@helpers/describeScene'
import { ErrorEntry, Replace } from '@helpers/types'
import { filterIndexes } from '@helpers/utils'

import NumberInput from './GenericInputs/NumberInput'
import styles from './LocalityModulesForm.module.scss'
import MultiSelectBox from './MultiSelectBox'

export interface LocalityModulesErrors {
    capacity: ErrorEntry
    bounceThreshold: ErrorEntry
    minSessionDuration: ErrorEntry
    minBreakDuration: ErrorEntry
}

export const emptyFootfallConfiguration = {
    footfallBoundaries: [],
}

export const emptyStaySafeConfiguration = {
    staySafeBoundaries: [],
}

export const emptyConversionsConfiguration = {
    bounceThreshold: 0,
    passerByBoundaries: [],
    visitorBoundaries: [],
}

export const emptyQueuesConfiguration = {
    salesZones: [],
    queueZones: [],
}

export const emptyOccupancyConfiguration = {
    occupancyZones: [],
    minBreakDuration: 1,
    minSessionDuration: 10,
}

const emptyEmotionConfiguration = {
    emotionZones: [],
}

const toChoices = (objects: Array<SceneObjectIdentifier & { label: string }>): { [index: string]: string } =>
    fromPairs(objects.map(({ label }, i) => [i.toString(), label]))

const determineSelection = (
    selectableObjects: Array<SceneObjectIdentifier>,
    selectedObjects: Array<SceneObjectIdentifier> = []
): Array<string> =>
    filterIndexes(selectableObjects, (obj) => selectedObjects.some(partial(isSceneObjectEqual, obj))).map(String)

export interface LocalityModulesConfiguration {
    capacity: LocalityResponse['capacity']
    footfallConfiguration?: Replace<FootfallConfiguration, { footfallBoundaries: Array<SceneObjectIdentifier> }>
    conversionsConfiguration?: Replace<
        ConversionsConfiguration,
        { passerByBoundaries: Array<SceneObjectIdentifier>; visitorBoundaries: Array<SceneObjectIdentifier> }
    >
    queuesConfiguration?: Replace<
        QueuesConfiguration,
        { queueZones: Array<SceneObjectIdentifier>; salesZones: Array<SceneObjectIdentifier> }
    >
    occupancyConfiguration?: Replace<OccupancyConfiguration, { occupancyZones: Array<SceneObjectIdentifier> }>
    emotionConfiguration?: Replace<EmotionConfiguration, { emotionZones: Array<SceneObjectIdentifier> }>
    staySafeConfiguration?: Replace<StaySafeConfiguration, { staySafeBoundaries: Array<SceneObjectIdentifier> }>
}

const DangerIconWithTooltip = ({ tooltipText, id }: { tooltipText: string; id: string }) => (
    <div className={styles.dangerIconContainer}>
        <OverlayTrigger
            overlay={
                <Popover id={id}>
                    <Popover.Content>{tooltipText}</Popover.Content>
                </Popover>
            }
        >
            <FontAwesomeIcon icon={faExclamationTriangle} />
        </OverlayTrigger>
    </div>
)

const checkIfEnabled = (organizationFeatures: OrganizationFeatures) => (feature: keyof OrganizationFeatures) =>
    organizationFeatures[feature]

const generateTooltipValue = (t: TFunction) => (value: string) =>
    t('form.moduleNotEnabled', '{{ value }} module is not enabled in the selected locality', { value })

const LocalityModulesForm: React.FC<{
    locality: LocalityResponse
    modulesConfiguration: LocalityModulesConfiguration
    organization: OrganizationResponse
    selectableBoundaries: Array<SceneObjectIdentifier & { label: string }>
    selectableZones: Array<SceneObjectIdentifier & { label: string }>
    onChange: (configuration: LocalityModulesConfiguration) => void
    errors: LocalityModulesErrors
    onErrors: (errors: LocalityModulesErrors) => void
    onHighlightSceneObject: (sceneObject?: SceneObjectIdentifier) => void
}> = ({ errors, onErrors, ...props }) => {
    const { t } = useTranslation()
    const id = useMemo(uniqueId, [])

    const featureCheck = useMemo(() => checkIfEnabled(props.organization.features), [props.organization])
    const tooltip = useMemo(() => generateTooltipValue(t), [t])

    const isConversionsEnabled = featureCheck('conversions')
    const isQueuesEnabled = featureCheck('queueMonitoring')
    const isOccupancyEnabled = featureCheck('zoneOccupancy')
    const isEmotionEnabled = featureCheck('emotions')
    const isFootFallEnabled = featureCheck('footfall')
    const isStaySafeEnabled = featureCheck('realtimeOccupancy') && props.locality.isRealTimeOccupancyEnabled

    const warningTooltips = {
        conversions: tooltip(t('navbar.conversions', 'Conversions')),
        queues: tooltip(t('navbar.queues', 'Queues')),
        occupancy: tooltip(t('navbar.occupancy', 'Occupancy')),
        emotions: tooltip(t('navbar.emotions', 'Emotions')),
        footfall: tooltip(t('navbar.footfall', 'Footfall')),
        staySafe: tooltip(t('navbar.realtimeOccupancy', 'Realtime occupancy')),
    }

    const boundaryChoices = toChoices(props.selectableBoundaries)
    const zoneChoices = toChoices(props.selectableZones)

    const handleChangeCapacity = (value?: number) => {
        const isInvalid = value ? value <= 0 : value !== undefined
        onErrors({
            ...errors,
            capacity: {
                isTouched: true,
                isInvalid,
            },
        })

        props.onChange(
            produce(props.modulesConfiguration, (draft) => {
                draft.capacity = value
            })
        )
    }

    const handleBounceThresholdChange = (threshold?: number) => {
        const isInvalid = threshold ? threshold < 0 : false
        onErrors({
            ...errors,
            bounceThreshold: {
                isTouched: true,
                isInvalid,
            },
        })
        props.onChange(
            produce(props.modulesConfiguration, (draft) => {
                draft.conversionsConfiguration = draft.conversionsConfiguration ?? emptyConversionsConfiguration
                draft.conversionsConfiguration.bounceThreshold = threshold
            })
        )
    }

    const handleMinBreakDurationChange = (duration?: number) => {
        const isInvalid = duration ? duration < 1 : false
        onErrors({
            ...errors,
            minBreakDuration: {
                isTouched: true,
                isInvalid,
            },
        })
        props.onChange(
            produce(props.modulesConfiguration, (draft) => {
                draft.occupancyConfiguration = draft.occupancyConfiguration ?? emptyOccupancyConfiguration
                draft.occupancyConfiguration.minBreakDuration = duration ?? 10
            })
        )
    }

    const handleMinSessionDurationChange = (duration?: number) => {
        const isInvalid = duration ? duration < 10 : false
        onErrors({
            ...errors,
            minSessionDuration: {
                isTouched: true,
                isInvalid,
            },
        })

        props.onChange(
            produce(props.modulesConfiguration, (draft) => {
                draft.occupancyConfiguration = draft.occupancyConfiguration ?? emptyOccupancyConfiguration
                draft.occupancyConfiguration.minSessionDuration = duration ?? 10
            })
        )
    }

    const createSelectionHandler =
        (
            sceneObjects: Array<SceneObjectIdentifier>,
            consumer: (
                selection: Array<SceneObjectIdentifier>,
                configurationDraft: Draft<LocalityModulesConfiguration>
            ) => void
        ) =>
        (boundaryIndexes: Array<string>) =>
            props.onChange(
                produce(props.modulesConfiguration, (draft) =>
                    consumer(
                        boundaryIndexes.map((idx) => sceneObjects[Number(idx)]),
                        draft
                    )
                )
            )

    return (
        <Form>
            <Form.Group as={Row}>
                {!isConversionsEnabled && <DangerIconWithTooltip id={id} tooltipText={warningTooltips.conversions} />}
                <Col>
                    <span className={classNames({ 'text-muted': !isConversionsEnabled })}>
                        <strong>{t('form.conversionSettings', 'Conversion settings')}</strong>
                    </span>
                </Col>
            </Form.Group>
            <Form.Group as={Row}>
                <Form.Label column={true} sm={2}>
                    {t('form.bounce', 'Bounce threshold')}
                </Form.Label>
                <Col sm={10}>
                    <NumberInput
                        isInvalid={errors.bounceThreshold.isTouched && errors.bounceThreshold.isInvalid}
                        min={0}
                        value={
                            props.modulesConfiguration.conversionsConfiguration?.bounceThreshold ??
                            emptyConversionsConfiguration.bounceThreshold
                        }
                        onChange={handleBounceThresholdChange}
                    />
                </Col>
            </Form.Group>
            <Form.Group as={Row}>
                <Col lg={6} sm={12}>
                    <Form.Label>{t('form.passerbyBoundaries', 'Passersby boundaries')}</Form.Label>
                    <MultiSelectBox
                        choices={boundaryChoices}
                        disabled={isEmpty(props.selectableBoundaries)}
                        placeholder={
                            isEmpty(props.selectableBoundaries)
                                ? t('form.noBoundariesToSelect', 'No boundaries to select')
                                : t('form.noBoundariesSelected', 'No boundaries selected')
                        }
                        selection={determineSelection(
                            props.selectableBoundaries,
                            props.modulesConfiguration.conversionsConfiguration?.passerByBoundaries
                        )}
                        onMouseOut={() => props.onHighlightSceneObject(undefined)}
                        onMouseOver={({ itemId }) =>
                            props.onHighlightSceneObject(props.selectableBoundaries[parseInt(itemId, 10)])
                        }
                        onSelect={createSelectionHandler(props.selectableBoundaries, (boundaries, draft) => {
                            draft.conversionsConfiguration =
                                draft.conversionsConfiguration ?? emptyConversionsConfiguration
                            draft.conversionsConfiguration.passerByBoundaries = boundaries
                        })}
                    />
                </Col>
                <Col lg={6} sm={12}>
                    <Form.Label>{t('form.visitorBoundaries', 'Visitor boundaries')}</Form.Label>
                    <MultiSelectBox
                        choices={boundaryChoices}
                        disabled={isEmpty(props.selectableBoundaries)}
                        placeholder={
                            isEmpty(props.selectableBoundaries)
                                ? t('form.noBoundariesToSelect', 'No boundaries to select')
                                : t('form.noBoundariesSelected', 'No boundaries selected')
                        }
                        selection={determineSelection(
                            props.selectableBoundaries,
                            props.modulesConfiguration.conversionsConfiguration?.visitorBoundaries
                        )}
                        onMouseOut={() => props.onHighlightSceneObject(undefined)}
                        onMouseOver={({ itemId }) =>
                            props.onHighlightSceneObject(props.selectableBoundaries[parseInt(itemId, 10)])
                        }
                        onSelect={createSelectionHandler(props.selectableBoundaries, (boundaries, draft) => {
                            draft.conversionsConfiguration =
                                draft.conversionsConfiguration ?? emptyConversionsConfiguration
                            draft.conversionsConfiguration.visitorBoundaries = boundaries
                        })}
                    />
                </Col>
            </Form.Group>
            <Form.Group as={Row}>
                {!isQueuesEnabled && <DangerIconWithTooltip id={id} tooltipText={warningTooltips.queues} />}
                <Col>
                    <span className={classNames({ 'text-muted': !isQueuesEnabled })}>
                        <strong>{t('form.queuesSettings', 'Queues settings')}</strong>
                    </span>
                </Col>
            </Form.Group>
            <Form.Group as={Row}>
                <Col lg={6} sm={12}>
                    <Form.Label>{t('form.queueZones', 'Queue zones')}</Form.Label>
                    <MultiSelectBox
                        choices={zoneChoices}
                        disabled={isEmpty(props.selectableZones)}
                        placeholder={
                            isEmpty(props.selectableZones)
                                ? t('form.noZonesToSelect', 'No zones to select')
                                : t('form.noZonesSelected', 'No zones selected')
                        }
                        selection={determineSelection(
                            props.selectableZones,
                            props.modulesConfiguration.queuesConfiguration?.queueZones
                        )}
                        onMouseOut={() => props.onHighlightSceneObject(undefined)}
                        onMouseOver={({ itemId }) =>
                            props.onHighlightSceneObject(props.selectableZones[parseInt(itemId, 10)])
                        }
                        onSelect={createSelectionHandler(props.selectableZones, (zones, draft) => {
                            draft.queuesConfiguration = draft.queuesConfiguration ?? emptyQueuesConfiguration
                            draft.queuesConfiguration.queueZones = zones
                        })}
                    />
                </Col>
                <Col lg={6} sm={12}>
                    <Form.Label>{t('form.posZones', 'Point-of-Sale zones')}</Form.Label>
                    <MultiSelectBox
                        choices={zoneChoices}
                        disabled={isEmpty(props.selectableZones)}
                        placeholder={
                            isEmpty(props.selectableZones)
                                ? t('form.noZonesToSelect', 'No zones to select')
                                : t('form.noZonesSelected', 'No zones selected')
                        }
                        selection={determineSelection(
                            props.selectableZones,
                            props.modulesConfiguration.queuesConfiguration?.salesZones
                        )}
                        onMouseOut={() => props.onHighlightSceneObject(undefined)}
                        onMouseOver={({ itemId }) =>
                            props.onHighlightSceneObject(props.selectableZones[parseInt(itemId, 10)])
                        }
                        onSelect={createSelectionHandler(props.selectableZones, (zones, draft) => {
                            draft.queuesConfiguration = draft.queuesConfiguration ?? emptyQueuesConfiguration
                            draft.queuesConfiguration.salesZones = zones
                        })}
                    />
                </Col>
            </Form.Group>
            <Form.Group as={Row}>
                {!isOccupancyEnabled && <DangerIconWithTooltip id={id} tooltipText={warningTooltips.occupancy} />}
                <Col>
                    <span className={classNames({ 'text-muted': !isOccupancyEnabled })}>
                        <strong>{t('form.occupancySettings', 'Occupancy settings')}</strong>
                    </span>
                </Col>
            </Form.Group>
            <Form.Group as={Row}>
                <Form.Label column={true} sm={2}>
                    {t('form.minSessionDuration', 'Min. session duration')}
                </Form.Label>
                <Col sm={10}>
                    <NumberInput
                        isInvalid={errors.minSessionDuration.isTouched && errors.minSessionDuration.isInvalid}
                        min={0}
                        value={
                            props.modulesConfiguration.occupancyConfiguration?.minSessionDuration ??
                            emptyOccupancyConfiguration.minSessionDuration
                        }
                        onChange={handleMinSessionDurationChange}
                    />
                </Col>
            </Form.Group>
            <Form.Group as={Row}>
                <Form.Label column={true} sm={2}>
                    {t('form.minBreakDuration', 'Min. break duration')}
                </Form.Label>
                <Col sm={10}>
                    <NumberInput
                        isInvalid={errors.minBreakDuration.isTouched && errors.minBreakDuration.isInvalid}
                        min={0}
                        value={
                            props.modulesConfiguration.occupancyConfiguration?.minBreakDuration ??
                            emptyOccupancyConfiguration.minBreakDuration
                        }
                        onChange={handleMinBreakDurationChange}
                    />
                </Col>
            </Form.Group>
            <Form.Group as={Row}>
                <Col lg={12} sm={12}>
                    <Form.Label>{t('form.occupancyZones', 'Occupancy zones')}</Form.Label>
                    <MultiSelectBox
                        choices={zoneChoices}
                        disabled={isEmpty(props.selectableZones)}
                        placeholder={
                            isEmpty(props.selectableZones)
                                ? t('form.noZonesToSelect', 'No zones to select')
                                : t('form.noZonesSelected', 'No zones selected')
                        }
                        selection={determineSelection(
                            props.selectableZones,
                            props.modulesConfiguration.occupancyConfiguration?.occupancyZones
                        )}
                        onMouseOut={() => props.onHighlightSceneObject(undefined)}
                        onMouseOver={({ itemId }) =>
                            props.onHighlightSceneObject(props.selectableZones[parseInt(itemId, 10)])
                        }
                        onSelect={createSelectionHandler(props.selectableZones, (zones, draft) => {
                            draft.occupancyConfiguration = draft.occupancyConfiguration ?? emptyOccupancyConfiguration
                            draft.occupancyConfiguration.occupancyZones = zones
                        })}
                    />
                </Col>
            </Form.Group>
            <Form.Group as={Row}>
                {!isEmotionEnabled && <DangerIconWithTooltip id={id} tooltipText={warningTooltips.emotions} />}
                <Col>
                    <span className={classNames({ 'text-muted': !isEmotionEnabled })}>
                        <strong>{t('form.emotionsSettings', 'Emotions settings')}</strong>
                    </span>
                </Col>
            </Form.Group>
            <Form.Group as={Row}>
                <Col lg={12} sm={12}>
                    <Form.Label>{t('form.emotionZones', 'Emotion zones')}</Form.Label>
                    <MultiSelectBox
                        choices={zoneChoices}
                        disabled={isEmpty(props.selectableZones)}
                        placeholder={
                            isEmpty(props.selectableZones)
                                ? t('form.noZonesToSelect', 'No zones to select')
                                : t('form.noZonesSelected', 'No zones selected')
                        }
                        selection={determineSelection(
                            props.selectableZones,
                            props.modulesConfiguration.emotionConfiguration?.emotionZones
                        )}
                        onMouseOut={() => props.onHighlightSceneObject(undefined)}
                        onMouseOver={({ itemId }) =>
                            props.onHighlightSceneObject(props.selectableZones[parseInt(itemId, 10)])
                        }
                        onSelect={createSelectionHandler(props.selectableZones, (zones, draft) => {
                            draft.emotionConfiguration = draft.emotionConfiguration ?? emptyEmotionConfiguration
                            draft.emotionConfiguration.emotionZones = zones
                        })}
                    />
                </Col>
            </Form.Group>
            <Form.Group as={Row}>
                {!isFootFallEnabled && <DangerIconWithTooltip id={id} tooltipText={warningTooltips.footfall} />}
                <Col>
                    <span className={classNames({ 'text-muted': !isFootFallEnabled })}>
                        <strong>{t('form.footfallSettings', 'Footfall boundaries settings')}</strong>
                    </span>
                </Col>
            </Form.Group>
            <Form.Group as={Row}>
                <Col sm={12}>
                    <MultiSelectBox
                        choices={boundaryChoices}
                        disabled={isEmpty(props.selectableBoundaries)}
                        placeholder={
                            isEmpty(props.selectableBoundaries)
                                ? t('form.noBoundariesToSelect', 'No boundaries to select')
                                : t('form.noBoundariesSelected', 'No boundaries selected')
                        }
                        selection={determineSelection(
                            props.selectableBoundaries,
                            props.modulesConfiguration.footfallConfiguration?.footfallBoundaries
                        )}
                        onMouseOut={() => props.onHighlightSceneObject(undefined)}
                        onMouseOver={({ itemId }) =>
                            props.onHighlightSceneObject(props.selectableBoundaries[parseInt(itemId, 10)])
                        }
                        onSelect={createSelectionHandler(props.selectableBoundaries, (boundaries, draft) => {
                            draft.footfallConfiguration = draft.footfallConfiguration ?? emptyFootfallConfiguration
                            draft.footfallConfiguration.footfallBoundaries = boundaries
                        })}
                    />
                </Col>
            </Form.Group>
            <Form.Group as={Row}>
                {!isStaySafeEnabled && <DangerIconWithTooltip id={id} tooltipText={warningTooltips.staySafe} />}
                <Col>
                    <span className={classNames({ 'text-muted': !isStaySafeEnabled })}>
                        <strong>{t('form.staySafeSettings', 'StaySafe settings')}</strong>
                    </span>
                </Col>
            </Form.Group>
            <Form.Group as={Row}>
                <Col lg={2} sm={12}>
                    <Form.Label>{t('form.staySafeBoundaries', 'StaySafe boundaries')}</Form.Label>
                </Col>
                <Col lg={5} sm={12}>
                    <MultiSelectBox
                        choices={boundaryChoices}
                        disabled={isEmpty(props.selectableBoundaries)}
                        placeholder={
                            isEmpty(props.selectableBoundaries)
                                ? t('form.noBoundariesToSelect', 'No boundaries to select')
                                : t('form.noBoundariesSelected', 'No boundaries selected')
                        }
                        selection={determineSelection(
                            props.selectableBoundaries,
                            props.modulesConfiguration.staySafeConfiguration?.staySafeBoundaries
                        )}
                        onMouseOut={() => props.onHighlightSceneObject(undefined)}
                        onMouseOver={({ itemId }) =>
                            props.onHighlightSceneObject(props.selectableBoundaries[parseInt(itemId, 10)])
                        }
                        onSelect={createSelectionHandler(props.selectableBoundaries, (boundaries, draft) => {
                            draft.staySafeConfiguration = draft.staySafeConfiguration ?? emptyStaySafeConfiguration
                            draft.staySafeConfiguration.staySafeBoundaries = boundaries
                        })}
                    />
                </Col>
                <Col lg={2} sm={12}>
                    <Form.Label>{t('form.capacity', 'Capacity')}</Form.Label>
                </Col>
                <Col lg={3} sm={12}>
                    <NumberInput
                        isInvalid={errors.capacity.isTouched && errors.capacity.isInvalid}
                        value={props.modulesConfiguration.capacity}
                        onChange={handleChangeCapacity}
                    />
                </Col>
            </Form.Group>
        </Form>
    )
}

export default LocalityModulesForm
