import { faArrowUp, faArrowDown, faCircle, faUser } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import { uniqueId, round } from 'lodash'
import { DateTime, Interval } from 'luxon'
import React, { useMemo } from 'react'
import { OverlayTrigger, Popover } from 'react-bootstrap'
import { Trans, useTranslation } from 'react-i18next'

import { gdynusaReadableInterval } from '@helpers/datetimeUtils'
import { displayNumericValue, displayPreciseDuration, formatLargeNumber } from '@helpers/displayUtils'
import { formatInterval, Precision } from '@helpers/intervals'
import { useLocalizeDateTime } from '@helpers/timezoneConfig'
import { NumberFormat } from '@helpers/types'

import styles from './ChangeIndicator.module.scss'

export type TrendType = 'positive' | 'negative' | 'neutral'

type Props = {
    change: number
    dataType: NumberFormat | undefined
    comparisonInterval: Interval
    valueToCompare: number
    isVisible: boolean
    isBigger?: boolean
    desiredTrend?: TrendType
    isChangeAbsolute?: boolean
    roundingPrecision?: number
}

const ChangeIndicator = ({
    change,
    isChangeAbsolute,
    desiredTrend = 'positive',
    isVisible,
    isBigger,
    dataType,
    comparisonInterval,
    valueToCompare,
    roundingPrecision,
}: Props) => {
    const { t } = useTranslation()

    const tooltipId = useMemo(uniqueId, [])
    const localize = useLocalizeDateTime()

    const actualTrend = change === 0 ? 'neutral' : change > 0 ? 'positive' : 'negative'
    const isTrendDesired = desiredTrend !== 'neutral' && actualTrend === desiredTrend

    if (isNaN(change) || !isVisible) {
        return null
    }

    const changeType = isChangeAbsolute
        ? dataType === 'percentage'
            ? t('others.difference', 'Difference')
            : t('others.absoluteChange', 'Absolute change')
        : t('others.relativeChange', 'Relative change')

    const displayedData =
        dataType === 'time'
            ? displayPreciseDuration(round(Number(valueToCompare)))
            : dataType === 'percentage'
            ? `${roundingPrecision ? round(valueToCompare, roundingPrecision) : round(valueToCompare)}%`
            : formatLargeNumber(displayNumericValue(valueToCompare))

    const displayedComparisonInterval = formatInterval(
        gdynusaReadableInterval(comparisonInterval.mapEndpoints(localize)),
        Precision.DAY,
        DateTime.DATE_SHORT
    )

    const Tooltip = (
        <Popover id={tooltipId}>
            <Popover.Content>
                <div>
                    <Trans
                        i18nKey="changeIndicator.tooltipContent"
                        values={{
                            changeType,
                            displayedData,
                            displayedComparisonInterval,
                        }}
                    >
                        {{ changeType }} in comparison to <strong>{{ displayedData }}</strong> from previous period{' '}
                        <strong>{{ displayedComparisonInterval }}</strong>
                    </Trans>
                </div>
            </Popover.Content>
        </Popover>
    )

    return (
        <OverlayTrigger overlay={Tooltip} placement="top" trigger={['hover', 'focus']}>
            <span className={styles.overlayContainer}>
                <span
                    className={classNames(styles.dataChange, {
                        [styles.bigger]: isBigger,
                        [styles.positive]: isTrendDesired,
                        [styles.negative]: !isTrendDesired && actualTrend !== 'neutral' && desiredTrend !== 'neutral',
                    })}
                >
                    {actualTrend === 'positive' && <FontAwesomeIcon className="mr-2" icon={faArrowUp} />}
                    {actualTrend === 'negative' && <FontAwesomeIcon className="mr-2" icon={faArrowDown} />}
                    {actualTrend === 'neutral' && (
                        <FontAwesomeIcon className={classNames(styles.smallIcon, 'mr-2')} icon={faCircle} size="xs" />
                    )}
                    <span
                        className={classNames({
                            [styles.dataChangeNumeric]: dataType !== 'time',
                            [styles.dataChangeDurationString]: dataType === 'time',
                        })}
                    >
                        {isChangeAbsolute
                            ? dataType === 'time' && change > 0
                                ? displayPreciseDuration(change)
                                : dataType === 'time' && change < 0
                                ? `-${displayPreciseDuration(change * -1)}`
                                : dataType === 'percentage'
                                ? `${roundingPrecision ? round(change, roundingPrecision) : round(change)}%`
                                : change
                            : `${roundingPrecision ? round(change, roundingPrecision) : round(change)}%`}
                    </span>
                    {isChangeAbsolute && dataType === 'people' && <FontAwesomeIcon className="pl-1" icon={faUser} />}
                </span>
            </span>
        </OverlayTrigger>
    )
}

export default ChangeIndicator
