import { faDownload } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Dictionary, isEmpty } from 'lodash'
import { Interval } from 'luxon'
import React from 'react'
import { Button } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import { DetectionZoneWithId, EmotionSummaryResponse, LocalityResponse } from '@api'

import { gdynusaReadableInterval } from '@helpers/datetimeUtils'
import { GoogleEventName } from '@helpers/gtag'
import { formatIntervalToFilename } from '@helpers/intervals'
import { useLocalizeDateTime } from '@helpers/timezoneConfig'
import { IntervalType } from '@helpers/types'
import { ExportData, exportToExcel } from '@helpers/xlsxExporter'

import { displayDateTimes } from '@components/StatisticsSummary/utils'
import { useNotify } from '@components/notifications/NotificationsContext'

import { formatEmotionTimeKey } from './EmotionsDetailsContent'
import { prepareEmotionsBreakDownTableData } from './emotionsDetailsPreprocessors'

interface ExportButtonProps {
    detectionZonesByDeviceName: Array<{
        detectionZones: Array<DetectionZoneWithId>
        sceneName: string
        sceneId: string
    }>
    granularEmotionsSummary: Dictionary<EmotionSummaryResponse>
    selectedZones: Array<DetectionZoneWithId> | undefined
    selectedLocality: LocalityResponse
    intervalType: IntervalType
    pickedInterval: Interval
}

export const EmotionsDetailsExportButton: React.FC<ExportButtonProps> = ({
    detectionZonesByDeviceName,
    granularEmotionsSummary,
    selectedZones,
    intervalType,
    selectedLocality,
    pickedInterval,
}) => {
    const { t } = useTranslation()

    const localize = useLocalizeDateTime()
    const notify = useNotify()
    const dateFormatter = formatEmotionTimeKey(localize)
    const displayInterval = gdynusaReadableInterval(pickedInterval.mapEndpoints(localize))

    const exportData: ExportData = {
        [t('statistics.emotions', 'Emotions')]: prepareEmotionsBreakDownTableData(
            detectionZonesByDeviceName,
            granularEmotionsSummary,
            selectedLocality,
            selectedZones
        ).map(({ scene, zoneName, averageEmotionScore, mostPositiveDay, theMostNegativeDay, overallData }) => {
            const startInterval = displayDateTimes({
                date: displayInterval.start,
                dateStyle: 'short',
            })
            const weekdayShort = displayInterval.start.weekdayShort

            const endInterval = displayDateTimes({
                date: displayInterval.end,
                dateStyle: 'short',
            })

            return {
                [t('table.scene', 'Scene')]: scene,
                [t('table.zone', 'Zone')]: zoneName,
                [t('table.from', 'From')]: `${weekdayShort} ${startInterval}`,
                [t('table.to', 'To')]: `${weekdayShort} ${endInterval}`,
                [t('emotions.positiveFaces', 'Positive faces')]: overallData.positiveObservations,
                [t('emotions.neutralFaces', 'Neutral faces')]: overallData.neutralObservations,
                [t('emotions.negativeFaces', 'Negative faces')]: overallData.negativeObservations,
                [t('emotions.avgEmotionScore', 'Avg. emotion score')]: averageEmotionScore?.toFixed(1) ?? 0,
                [t('emotions.theMostPositiveDay', 'The most positive day')]: mostPositiveDay.timeKey
                    ? dateFormatter(intervalType, mostPositiveDay.timeKey, 'short')
                    : 'N/A',
                [t('emotions.theMostNegativeDay', 'The most negative day')]: theMostNegativeDay.timeKey
                    ? dateFormatter(intervalType, theMostNegativeDay.timeKey, 'short')
                    : 'N/A',
            }
        }),
    }

    const handleDownload = () => {
        if (!isEmpty(exportData)) {
            exportToExcel(
                exportData,
                `${selectedLocality?.name}-emotions-details-${formatIntervalToFilename(
                    gdynusaReadableInterval(pickedInterval.mapEndpoints(localize))
                )}`,
                GoogleEventName.DOWNLOAD_EMOTIONS_LOCALITY_EXCEL
            )
        } else {
            notify({
                title: t('notification.error', 'Error'),
                content: t('notification.nothingToExport', 'Nothing to export'),
                variant: 'danger',
            })
        }
    }

    return (
        <Button variant="secondary" onClick={handleDownload}>
            <FontAwesomeIcon icon={faDownload} />
            {t('button.downloadExcel', 'Download Excel')}
        </Button>
    )
}
