import { DateTime, Interval } from 'luxon'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { LocalityNameModel } from '@api/models'

import { gdynusaReadableInterval } from '@helpers/datetimeUtils'
import { displayNumericValue, formatLargeNumber } from '@helpers/displayUtils'
import { formatInterval, Precision } from '@helpers/intervals'
import { useLocalizeDateTime } from '@helpers/timezoneConfig'

import TooltipIcon from './TooltipIcon'

type Props = {
    selectedInterval: Interval
    selectedComparisonInterval?: Interval
    // Statistic box data || Queue sub data format
    statisticsValue:
        | Array<{
              value?: string | number
              unit?: JSX.Element | string
          }>
        | (string | number)
    statisticsValueDescription: string
    selectedLocalities?: LocalityNameModel[]
    className?: string
}
const ExplanationTooltip = ({
    selectedInterval,
    statisticsValue,
    statisticsValueDescription,
    selectedLocalities,
    className,
}: Props) => {
    const { t } = useTranslation()

    const localize = useLocalizeDateTime()

    const formatStatisticsValues = (() => {
        if (Array.isArray(statisticsValue)) {
            return statisticsValue
                .map(
                    (statisticsItem) =>
                        `${formatLargeNumber(displayNumericValue(statisticsItem.value))}${
                            statisticsItem.unit && typeof statisticsItem.unit === 'string' ? statisticsItem.unit : ''
                        }`
                )
                .join(', ')
        }

        return statisticsValue
    })()

    const isValueInvalid = statisticsValue === undefined || statisticsValue === '-'

    const formatLocalities = () => {
        if (selectedLocalities && selectedLocalities.length > 0) {
            return selectedLocalities.map((locality) => locality.name).join(', ')
        }

        return undefined
    }

    return (
        <TooltipIcon className={className}>
            {!isValueInvalid && (
                <span>
                    <strong>{formatStatisticsValues}</strong>
                    {t('others.is', ' is ')}
                </span>
            )}
            {statisticsValueDescription} <strong>({formatLocalities()})</strong>
            {t('others.from', ' from ')}
            <strong>
                {formatInterval(
                    gdynusaReadableInterval(
                        Interval.fromDateTimes(localize(selectedInterval.start), localize(selectedInterval.end))
                    ),
                    Precision.DAY,
                    DateTime.DATE_SHORT
                )}
            </strong>
        </TooltipIcon>
    )
}

export default ExplanationTooltip
