import classNames from 'classnames'
import React from 'react'

import styles from './Heading.module.scss'

type Props = {
    variant?: 'section' | 'page'
    level?: 1 | 2 | 3
    className?: string
} & (
    | {
          children: React.ReactNode | React.ReactNode[]
          text?: never
      }
    | {
          text?: string
          children?: never
      }
)

const Heading: React.FC<Props> = ({ variant = 'section', children, level = 2, text, className }) => {
    const HeadingElement: keyof JSX.IntrinsicElements = `h${level}`

    return (
        <HeadingElement
            className={classNames(className, {
                [styles.pageHeading]: variant === 'page',
                [styles.screenHeading]: variant === 'section',
            })}
        >
            {children ?? text}
        </HeadingElement>
    )
}

export default Heading
