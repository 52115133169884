/* tslint:disable */
/* eslint-disable */
/**
 * Vividi Dashboard API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface QueueStatistics
 */
export interface QueueStatistics {
    /**
     * 
     * @type {string}
     * @memberof QueueStatistics
     */
    startingFrom: string;
    /**
     * 
     * @type {string}
     * @memberof QueueStatistics
     */
    endingAt: string;
    /**
     * Portion (0-1) of interval within opening hours
     * @type {number}
     * @memberof QueueStatistics
     */
    openedPortion: number;
    /**
     * 
     * @type {number}
     * @memberof QueueStatistics
     */
    queueSizeAverage?: number;
    /**
     * 
     * @type {number}
     * @memberof QueueStatistics
     */
    waitTimeAverage?: number;
    /**
     * 
     * @type {number}
     * @memberof QueueStatistics
     */
    waitTimeMax?: number;
    /**
     * 
     * @type {number}
     * @memberof QueueStatistics
     */
    waitTimeSampleCount?: number;
    /**
     * 
     * @type {number}
     * @memberof QueueStatistics
     */
    serveTimeAverage?: number;
    /**
     * 
     * @type {number}
     * @memberof QueueStatistics
     */
    serveTimeMax?: number;
    /**
     * 
     * @type {number}
     * @memberof QueueStatistics
     */
    serveTimeSampleCount?: number;
}

export function QueueStatisticsFromJSON(json: any): QueueStatistics {
    return QueueStatisticsFromJSONTyped(json, false);
}

export function QueueStatisticsFromJSONTyped(json: any, ignoreDiscriminator: boolean): QueueStatistics {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'startingFrom': json['starting_from'],
        'endingAt': json['ending_at'],
        'openedPortion': json['opened_portion'],
        'queueSizeAverage': !exists(json, 'queue_size_average') ? undefined : json['queue_size_average'],
        'waitTimeAverage': !exists(json, 'wait_time_average') ? undefined : json['wait_time_average'],
        'waitTimeMax': !exists(json, 'wait_time_max') ? undefined : json['wait_time_max'],
        'waitTimeSampleCount': !exists(json, 'wait_time_sample_count') ? undefined : json['wait_time_sample_count'],
        'serveTimeAverage': !exists(json, 'serve_time_average') ? undefined : json['serve_time_average'],
        'serveTimeMax': !exists(json, 'serve_time_max') ? undefined : json['serve_time_max'],
        'serveTimeSampleCount': !exists(json, 'serve_time_sample_count') ? undefined : json['serve_time_sample_count'],
    };
}

export function QueueStatisticsToJSON(value?: QueueStatistics | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'starting_from': value.startingFrom,
        'ending_at': value.endingAt,
        'opened_portion': value.openedPortion,
        'queue_size_average': value.queueSizeAverage,
        'wait_time_average': value.waitTimeAverage,
        'wait_time_max': value.waitTimeMax,
        'wait_time_sample_count': value.waitTimeSampleCount,
        'serve_time_average': value.serveTimeAverage,
        'serve_time_max': value.serveTimeMax,
        'serve_time_sample_count': value.serveTimeSampleCount,
    };
}

