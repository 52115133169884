/* tslint:disable */
/* eslint-disable */
/**
 * Vividi Dashboard API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ApplicationConfiguration,
    ApplicationConfigurationFromJSON,
    ApplicationConfigurationFromJSONTyped,
    ApplicationConfigurationToJSON,
} from './ApplicationConfiguration';
import {
    LocalityNameModel,
    LocalityNameModelFromJSON,
    LocalityNameModelFromJSONTyped,
    LocalityNameModelToJSON,
} from './LocalityNameModel';
import {
    Role,
    RoleFromJSON,
    RoleFromJSONTyped,
    RoleToJSON,
} from './Role';

/**
 * 
 * @export
 * @interface ProfileResponse
 */
export interface ProfileResponse {
    /**
     * 
     * @type {number}
     * @memberof ProfileResponse
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof ProfileResponse
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileResponse
     */
    language?: string;
    /**
     * 
     * @type {Role}
     * @memberof ProfileResponse
     */
    role: Role;
    /**
     * 
     * @type {number}
     * @memberof ProfileResponse
     */
    organizationId?: number;
    /**
     * 
     * @type {Array<LocalityNameModel>}
     * @memberof ProfileResponse
     */
    localities: Array<LocalityNameModel>;
    /**
     * 
     * @type {string}
     * @memberof ProfileResponse
     */
    lastTermsAndConditionsVersion?: string;
    /**
     * 
     * @type {ApplicationConfiguration}
     * @memberof ProfileResponse
     */
    applicationConfiguration?: ApplicationConfiguration;
}

export function ProfileResponseFromJSON(json: any): ProfileResponse {
    return ProfileResponseFromJSONTyped(json, false);
}

export function ProfileResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProfileResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'email': json['email'],
        'language': !exists(json, 'language') ? undefined : json['language'],
        'role': RoleFromJSON(json['role']),
        'organizationId': !exists(json, 'organization_id') ? undefined : json['organization_id'],
        'localities': ((json['localities'] as Array<any>).map(LocalityNameModelFromJSON)),
        'lastTermsAndConditionsVersion': !exists(json, 'last_terms_and_conditions_version') ? undefined : json['last_terms_and_conditions_version'],
        'applicationConfiguration': !exists(json, 'application_configuration') ? undefined : ApplicationConfigurationFromJSON(json['application_configuration']),
    };
}

export function ProfileResponseToJSON(value?: ProfileResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'email': value.email,
        'language': value.language,
        'role': RoleToJSON(value.role),
        'organization_id': value.organizationId,
        'localities': ((value.localities as Array<any>).map(LocalityNameModelToJSON)),
        'last_terms_and_conditions_version': value.lastTermsAndConditionsVersion,
        'application_configuration': ApplicationConfigurationToJSON(value.applicationConfiguration),
    };
}

