import { QueryObserverResult } from 'react-query'

type ApiError = {
    status: number
}

type ApiErrorWithRequestId = ApiError & {
    headers: {
        get(name: 'X-Request-Id'): string
    }
}

/**
 * Check if API returns an HTTP error code
 */
export const isApiError = (x: any): x is ApiError => {
    return typeof x?.status === 'number'
}

export const hasRequestId = (x: any): x is ApiErrorWithRequestId => {
    return typeof x?.headers?.get('X-Request-Id') === 'string'
}

/**
 * A utility that allows the caller to wait for an API call using mergeApiCalls()
 * and the like, without crashing if it does not succeed.  In case of failure,
 * `undefined` is returned.
 */
export const maskQueryFailure = <ValueType>(
    call: QueryObserverResult<ValueType>
): QueryObserverResult<ValueType | undefined> => {
    if (call.status === 'error' || (call.status === 'loading' && call.errorUpdateCount > 0)) {
        // adding additional condition to check if loading, but errorUpdateCount is more than 0, because getOrganizations
        // doesn't throw an error, which results in loading state, but error actually happening
        return {
            ...call,
            status: 'success',
            data: undefined,
            error: null,
            isError: false,
            isLoadingError: false,
            isSuccess: true,
            isRefetchError: false,
            isLoading: false,
        }
    }

    return call
}
