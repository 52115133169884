import { max, range, sum } from 'lodash'
import { DateTime, Interval } from 'luxon'

import { QueueStatistics, SalesStatisticsDetailsSummaryRow, SceneListResponse } from '@api'

import { parseDate } from '@components/plots/common'

type SalesPerZone = Array<{
    scene: string
    zone: string
    totalSales: number
    averageServeTime: number
    maxServeTime: number
}>

type QueueData = Array<{
    name: string
    openedPortion: number
    interval: Interval
    averageWaitTime?: number
    maxWaitTime?: number
    averageServeTime?: number
    maxServeTime?: number
    queueSize?: number
    hasData: boolean
}>

export const prepareQueueData = (queues: QueueStatistics[], now: DateTime): QueueData => {
    const data = range(queues.length ?? 0).map((i) => {
        const record = queues[i]
        const interval = Interval.fromDateTimes(parseDate(record.startingFrom), parseDate(record.endingAt))

        const isIntervalInFuture = interval.start > now

        if (isIntervalInFuture) {
            return {
                name: i.toString(),
                openedPortion: record.openedPortion,
                interval,
                hasData: false,
            }
        }

        const queueSize: number | undefined = record.queueSizeAverage
        const averageWaitTime: number | undefined = record.waitTimeAverage
        const maxWaitTime: number | undefined = record.waitTimeMax
        const averageServeTime: number | undefined = record.serveTimeAverage
        const maxServeTime: number | undefined = record.serveTimeMax

        return {
            name: i.toString(),
            openedPortion: record.openedPortion,
            interval,
            queueSize,
            averageWaitTime,
            maxWaitTime,
            averageServeTime,
            maxServeTime,
            hasData: [queueSize, averageServeTime, averageWaitTime, maxServeTime, maxWaitTime].some(
                (it) => it !== undefined
            ),
        }
    })

    return data
}

export const prepareSalesPerZone = (
    salesRows: Array<SalesStatisticsDetailsSummaryRow>,
    { scenes }: SceneListResponse
): SalesPerZone => {
    return salesRows.map((sales) => {
        const scene =
            scenes.find(({ id }) => {
                return id === sales.sceneId
            })?.label ?? ''
        const zone = sales.zoneName
        const totalSales = sum(sales.serveTimeSampleCount)
        const averageServeTime =
            sum(
                sales.serveTimeAverage.map((v, i) => {
                    return v * Math.max(sales.serveTimeSampleCount[i], 1)
                })
            ) / (totalSales ?? 1)
        const maxServeTime = max(sales.serveTimeMax) ?? 0

        return { scene, zone, totalSales, averageServeTime, maxServeTime }
    })
}
