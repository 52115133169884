import React from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { useParams } from 'react-router'

import { organizationApi, reportsApi } from '@services'

import { mergeQueryResults } from '@helpers/api'

import PageContentLayout from '@components/Layouts/PageContentLayout'
import LegacyLoadingWrapper from '@components/LegacyLoadingWrapper'
import OccupancyReportList from '@components/OccupancyReportList/OccupancyReportList'

const OccupancyReportListPage: React.FC = () => {
    const { t } = useTranslation()
    const params = useParams<{ organizationId: string }>()
    const organizationId = parseInt(params.organizationId, 10)

    const reportsCall = useQuery(reportsApi.listOrganizationOccupancyReports.query({ organizationId }))

    const organizationCall = useQuery(
        organizationApi.getOrganization.query({
            organizationId,
        })
    )

    const request = mergeQueryResults(reportsCall, organizationCall)
    const heading = t('heading.occupancyReports', 'Occupancy Reports')
    const documentTitle = t('title.occupancyReports', 'Occupancy reports')

    return (
        <>
            <LegacyLoadingWrapper
                bare={true}
                placeholder={
                    <PageContentLayout documentTitle={documentTitle} heading={heading} loadingState={request.status} />
                }
                request={request}
            >
                {([reports, organization]) => (
                    <>
                        <PageContentLayout
                            documentTitle={`${organization.name} - ${documentTitle}`}
                            heading={`${organization.name} ${heading}`}
                        >
                            <OccupancyReportList organizationId={organizationId} reports={reports.reports} />
                        </PageContentLayout>
                    </>
                )}
            </LegacyLoadingWrapper>
        </>
    )
}

export default OccupancyReportListPage
