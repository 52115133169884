import React, { useMemo } from 'react'
import { Col, Container, Row, Card } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import { Link } from 'react-router-dom'

import { AuthenticationRequestBody } from '@api/models'

import { authApi } from '@services'

import { REQUEST_PASSWORD_RESET_PATH, VIVIDI_APP } from '@helpers/VividiURLs'
import { useApiCallCleaner } from '@helpers/api'
import { storeToken } from '@helpers/auth'
import { useBackLink } from '@helpers/backlinks'
import { GoogleEventCategory, GoogleEventName, trackEvent } from '@helpers/gtag'

import Helmet from '@components/Helmet'
import LoginForm from '@components/LoginForm'

import logo from '@images/logo.svg'

import styles from './LoginPage.module.scss'

const LoginPage: React.FC = () => {
    const { t } = useTranslation()
    const { mutateAsync: triggerLogin, status: triggerLoginState, error, reset } = useMutation(authApi.authenticate)
    const backLink = useBackLink(VIVIDI_APP)
    const clean = useApiCallCleaner()

    const onSubmit = async (body: AuthenticationRequestBody) => {
        try {
            const response = await triggerLogin({ body })

            storeToken(response)
            reset()
            clean()
            backLink.follow()
        } catch (e) {}
    }

    const failedText = useMemo(() => {
        if (error !== undefined && error !== null) {
            if (error instanceof Response) {
                if (error.status === 401) {
                    return t('others.invalidCredentials', 'Incorrect e-mail or password')
                }

                if (error.status >= 500) {
                    return t('others.serverError', 'Server error')
                }
            }

            return t('others.applicationError', 'Application error')
        }

        return undefined
    }, [error, t])

    const documentTitle = t('title.login', 'Login')

    return (
        <>
            <Helmet>
                <title>{documentTitle}</title>
            </Helmet>
            <div className={styles.background}>
                <div />
            </div>
            <Container className={styles.formContainer}>
                <Row>
                    <Col lg={{ span: 6, offset: 3 }}>
                        <Card>
                            <Card.Body>
                                <img alt="VIVIDI logo" className={styles.logo} src={logo} />
                                <LoginForm
                                    className={styles.loginForm}
                                    failedText={failedText}
                                    loginRequestStatus={triggerLoginState}
                                    onSubmit={onSubmit}
                                />
                                <div className={styles.resetPasswordLink}>
                                    <Link
                                        to={REQUEST_PASSWORD_RESET_PATH}
                                        onClick={() =>
                                            trackEvent(
                                                GoogleEventCategory.OTHERS,
                                                GoogleEventName.CLICK_PASSWORD_RECOVERY
                                            )
                                        }
                                    >
                                        {t('loginPage.resetPassword', 'Password recovery')}
                                    </Link>
                                </div>
                                <div className={styles.demoLink}>
                                    {t('loginPage.noAccountYet', "Don't have an account yet?")}
                                    <br />
                                    <a
                                        href="https://vividi.io/contact"
                                        target="blank"
                                        onClick={() =>
                                            trackEvent(GoogleEventCategory.OTHERS, GoogleEventName.CLICK_REQUEST_DEMO)
                                        }
                                    >
                                        {t('loginPage.requestDemo', 'Request demo')}
                                    </a>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default LoginPage
