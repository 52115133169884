/* tslint:disable */
/* eslint-disable */
/**
 * Vividi Dashboard API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StaySafeConfiguration
 */
export interface StaySafeConfiguration {
    /**
     * Boundaries of shop entrances/exits for Vividi StaySafe. If no boundaries are specified, all boundaries defined in the locality are used.
     * @type {Array<number>}
     * @memberof StaySafeConfiguration
     */
    staySafeBoundaries: Array<number>;
}

export function StaySafeConfigurationFromJSON(json: any): StaySafeConfiguration {
    return StaySafeConfigurationFromJSONTyped(json, false);
}

export function StaySafeConfigurationFromJSONTyped(json: any, ignoreDiscriminator: boolean): StaySafeConfiguration {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'staySafeBoundaries': json['stay_safe_boundaries'],
    };
}

export function StaySafeConfigurationToJSON(value?: StaySafeConfiguration | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'stay_safe_boundaries': value.staySafeBoundaries,
    };
}

