import { noop } from 'lodash'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery } from 'react-query'
import { useParams } from 'react-router'

import { LocalityNameModel, Role, UserModel } from '@api'

import { localityApi, organizationApi, userApi } from '@services'

import { useApiCallCleaner } from '@helpers/api'
import { mergeQueryResults, visibleLocalitiesQuery, visibleOrganizationsQuery, visibleUsersQuery } from '@helpers/api'
import { useBackLink } from '@helpers/backlinks'
import useProfile from '@helpers/profile'

import Heading from '@elements/Heading/Heading'

import ErrorView from '@components/ErrorView'
import PageContentLayout from '@components/Layouts/PageContentLayout'
import LegacyLoadingWrapper from '@components/LegacyLoadingWrapper'
import NotificationPreferencesForm from '@components/NotificationPreferencesForm'
import SetPasswordForm from '@components/SetPasswordForm'
import UserEditForm from '@components/UserForm/UserEditForm'
import styles from '@components/UserForm/UserEditForm.module.scss'

const EditUserPage = () => {
    const { t } = useTranslation()
    const backLink = useBackLink()
    const clean = useApiCallCleaner()
    const match = useParams<{ id: string }>()
    const userId = parseInt(match.id, 10)
    const profileCall = useProfile()

    const usersCall = useQuery({
        ...visibleUsersQuery(profileCall.data),
    })

    const organizationsCall = useQuery({
        ...visibleOrganizationsQuery(profileCall.data),
    })

    const localitiesCall = useQuery({
        ...visibleLocalitiesQuery(profileCall.data),
    })

    const { mutate: setPasswordCall, status: PasswordResetState } = useMutation(userApi.setUserPassword, {
        onSuccess: () => {
            backLink.follow()
        },
        onError: noop,
    })

    const notificationsCall = useQuery(userApi.getNotificationPreferences.query({ userId }))
    const request = mergeQueryResults(usersCall, organizationsCall, localitiesCall, notificationsCall)

    const heading = t('heading.editUser', 'Edit User')

    const userUpdater = async ({
        role,
        localities,
        user: updatedUser,
    }: {
        role: Role
        localities: Array<LocalityNameModel>
        user: UserModel
    }) => {
        await userApi
            .setUserRole({
                userId,
                body: {
                    role,
                },
            })
            .catch(noop)
        await userApi
            .updateUser({
                userId,
                body: updatedUser,
            })
            .catch(noop)
        await userApi
            .setUserLocalities({
                userId,
                body: { localities: localities.map((l) => l.id) },
            })
            .catch(noop)

        clean(userApi)
        clean(organizationApi)
        clean(localityApi)
        backLink.follow()
    }

    const {
        mutate: handleUpdateUser,
        status: updateUserState,
        reset,
    } = useMutation(userUpdater, {
        onSuccess: () => {
            reset()
        },
    })

    return (
        <LegacyLoadingWrapper
            placeholder={<PageContentLayout heading={heading} loadingState={request.status} />}
            request={request}
        >
            {([users, organizations, localities, notifications]) => {
                const user = users.users.find((u) => u.id === userId)
                const selectedLocalities = localities.localities.filter(
                    (locality) => user?.localityIds && user.localityIds.includes(locality.id)
                )

                if (!user) {
                    return (
                        <div className={styles.errorWrapper}>
                            <ErrorView message={t('others.userNotFound', 'User not found')} />
                        </div>
                    )
                }

                const editForm = (
                    <UserEditForm
                        initialData={user}
                        localities={localities.localities}
                        organizations={organizations.organizations}
                        selectedLocalities={selectedLocalities}
                        submissionStatus={updateUserState}
                        onSubmit={handleUpdateUser}
                    />
                )

                const passwordForm = (
                    <>
                        <div className="screen-subheading">
                            <Heading level={2} text={t('heading.changePassword', 'Change Password')} />
                        </div>
                        <SetPasswordForm
                            askCurrentPassword={false}
                            submissionStatus={PasswordResetState}
                            onSubmit={(password) =>
                                setPasswordCall({
                                    userId,
                                    body: {
                                        password,
                                    },
                                })
                            }
                        />
                    </>
                )

                const notificationsForm = (
                    <NotificationPreferencesForm
                        localities={localities}
                        notificationPreferences={notifications}
                        organizations={organizations}
                        user={users.users.find((u) => u.id === userId)!}
                    />
                )

                return (
                    <PageContentLayout heading={heading}>
                        {editForm}
                        {(profileCall.data?.role === Role.Administrator || profileCall.data?.id === userId) &&
                            passwordForm}
                        {notificationsForm}
                    </PageContentLayout>
                )
            }}
        </LegacyLoadingWrapper>
    )
}

export default EditUserPage
