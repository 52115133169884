/* tslint:disable */
/* eslint-disable */
/**
 * Vividi Dashboard API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MQTTPublisherPreferencesItem,
    MQTTPublisherPreferencesItemFromJSON,
    MQTTPublisherPreferencesItemFromJSONTyped,
    MQTTPublisherPreferencesItemToJSON,
} from './MQTTPublisherPreferencesItem';

/**
 * 
 * @export
 * @interface MQTTPublisherPreferencesList
 */
export interface MQTTPublisherPreferencesList {
    /**
     * 
     * @type {Array<MQTTPublisherPreferencesItem>}
     * @memberof MQTTPublisherPreferencesList
     */
    mqttPublishers: Array<MQTTPublisherPreferencesItem>;
}

export function MQTTPublisherPreferencesListFromJSON(json: any): MQTTPublisherPreferencesList {
    return MQTTPublisherPreferencesListFromJSONTyped(json, false);
}

export function MQTTPublisherPreferencesListFromJSONTyped(json: any, ignoreDiscriminator: boolean): MQTTPublisherPreferencesList {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'mqttPublishers': ((json['mqtt_publishers'] as Array<any>).map(MQTTPublisherPreferencesItemFromJSON)),
    };
}

export function MQTTPublisherPreferencesListToJSON(value?: MQTTPublisherPreferencesList | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'mqtt_publishers': ((value.mqttPublishers as Array<any>).map(MQTTPublisherPreferencesItemToJSON)),
    };
}

