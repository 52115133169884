import { findIndex, round, sum, uniq } from 'lodash'
import { DateTime, Interval } from 'luxon'

import { ConversionStatisticsResponse, OutageModel, OutagesResponse } from '@api'

import { percentage } from '@helpers/utils'

import { conversionRatio } from '@components/StatisticsSummary/Sections/ConversionSection'
import { parseDate } from '@components/plots/common'

export type ConversionData = Array<{
    name: string
    interval: Interval
    openedPortion: number
    startingFrom: string
    endingAt: string
    now: DateTime
    index: number
    outages: OutageModel[]
    sales: number | undefined
    passersby: number | undefined
    bounced: number | undefined
    visitors: number | undefined
    browsingVisitors: number
    passerbyToVisitors: number
    bounceRate: number
    visitorsToSales: number
}>

type ConversionBarChartData = Array<{
    name: string
    bounced: number
    hasData: boolean
    totalVisitors: number
    bouncedPercentage: number
}>

export const prepareConversionData = (
    conversionStatistics: ConversionStatisticsResponse,
    now: DateTime,
    outages: OutagesResponse
): ConversionData =>
    conversionStatistics.items.map((s, i) => ({
        name: i.toString(),
        interval: Interval.fromDateTimes(parseDate(s.startingFrom), parseDate(s.endingAt)),
        openedPortion: s.openedPortion,
        startingFrom: s.startingFrom,
        endingAt: s.endingAt,
        now,
        index: i,
        outages: outages.outages,
        sales: s.sales,
        passersby: s.passerBy,
        bounced: s.bounced,
        visitors: s.visitors,
        browsingVisitors: (s.visitors ?? 0) - (s.bounced ?? 0),
        passerbyToVisitors: s.visitors && s.passerBy ? round(conversionRatio(s.visitors, s.passerBy), 1) : 0,
        bounceRate: s.bounced && s.visitors ? round(conversionRatio(s.bounced, s.visitors), 1) : 0,
        visitorsToSales: s.sales && s.visitors ? round(conversionRatio(s.sales, s.visitors), 1) : 0,
    }))

export const prepareSummedConversionData = (conversionStatistics: ConversionStatisticsResponse) => ({
    totalVisitors: sum(conversionStatistics.items.map((r) => r.visitors ?? 0)),
    totalBounced: sum(conversionStatistics.items.map((r) => r.bounced ?? 0)),
    totalPassersBy: sum(conversionStatistics.items.map((r) => r.passerBy ?? 0)),
    totalSales: sum(conversionStatistics.items.map((r) => r.sales ?? 0)),
    totalBrowsingVisitors:
        sum(conversionStatistics.items.map((r) => r.visitors ?? 0)) -
        sum(conversionStatistics.items.map((r) => r.bounced ?? 0)),
    get passerbyToVisitors() {
        return this.totalVisitors && this.totalPassersBy
            ? round(conversionRatio(this.totalVisitors, this.totalPassersBy), 1)
            : 0
    },
    get bounceRate() {
        return this.totalBounced && this.totalVisitors
            ? round(conversionRatio(this.totalBounced, this.totalVisitors), 1)
            : 0
    },
    get visitorsToSales() {
        return this.totalSales && this.totalVisitors
            ? round(conversionRatio(this.totalSales, this.totalVisitors), 1)
            : 0
    },
})

export const prepareHourlyBouncedData = (
    hourlyConversions: ConversionStatisticsResponse,
    localize: (datetime: DateTime) => DateTime
) => {
    const openHours = uniq(
        hourlyConversions.items
            .filter((it) => it.openedPortion > 0)
            .map((it) => localize(parseDate(it.startingFrom)).hour)
    )

    const plotData: ConversionBarChartData = openHours.map((it) => ({
        name: `${it}:00`,
        bounced: 0,
        hasData: true,
        totalVisitors: 0,
        bouncedPercentage: 0,
        browsingVisitors: 0,
    }))

    hourlyConversions.items.forEach((element) => {
        const index = findIndex(plotData, {
            name: `${localize(parseDate(element.startingFrom)).hour}:00`,
        })

        if (index !== -1) {
            plotData[index].bounced += element.bounced ?? 0
            plotData[index].totalVisitors += element.visitors ?? 0
        }
    })

    plotData.forEach((it) => (it.bouncedPercentage = round(percentage(it.bounced, it.totalVisitors), 1)))

    return plotData
}

export const weekDays = [
    DateTime.local(2021, 6, 7), // Mo
    DateTime.local(2021, 6, 8), // Tue
    DateTime.local(2021, 6, 9), // Wed
    DateTime.local(2021, 6, 10), // Thu
    DateTime.local(2021, 6, 11), // Fri
    DateTime.local(2021, 6, 12), // Sat
    DateTime.local(2021, 6, 13), // Sun
].map((d) => d.toLocaleString({ weekday: 'short' }))

export const prepareWeeklyBouncedData = (
    hourlyConversions: ConversionStatisticsResponse,
    localize: (datetime: DateTime) => DateTime
) => {
    const plotData: ConversionBarChartData = weekDays.map((name) => ({
        name,
        bounced: 0,
        hasData: true,
        totalVisitors: 0,
        bouncedPercentage: 0,
    }))

    hourlyConversions.items.forEach((element) => {
        const index = findIndex(plotData, {
            name: localize(parseDate(element.startingFrom)).toLocaleString({
                weekday: 'short',
            }),
        })

        if (index !== -1) {
            plotData[index].bounced += element.bounced ?? 0
            plotData[index].totalVisitors += element.visitors ?? 0
        }
    })

    plotData.forEach((it) => (it.bouncedPercentage = round(percentage(it.bounced, it.totalVisitors), 1)))

    return plotData
}
