import { faExchangeAlt, faEye, faEyeSlash, faTrash, faVectorSquare } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import { isEmpty } from 'lodash'
import React, { useEffect, useState } from 'react'
import { ListGroup } from 'react-bootstrap'

import { SceneResponse } from '@api'

import {
    EditableSceneObject,
    isVisitBoundary,
    sceneObjectBackendType,
    SceneObjectBackendType,
    SceneObjectIdentifier,
    SceneObjectType,
} from '@helpers/describeScene'

import { ReactComponent as BoundaryIcon } from '@images/icons/boundary_line.svg'

import styles from './DescribeSceneNestedList.module.scss'
import { determineSelectedSceneObject } from './DescribeSceneTab'

type SceneEditFunction = (sceneId: number, sceneObject: EditableSceneObject) => void

type DescribeSceneNestedListProps = {
    scenes: Array<SceneResponse>
    sceneConfigurations: {
        [sceneId: string]: Array<EditableSceneObject>
    }
    highlightedSceneObject?: SceneObjectIdentifier
    selectedSceneObject?: SceneObjectIdentifier
    selectedScene?: SceneResponse
    onSelectScene: (id: string, sceneObject?: SceneObjectIdentifier) => void
    onSelectSceneObject: SceneEditFunction
    onDeleteSceneObject: SceneEditFunction
    onEditSceneObject: SceneEditFunction
    onHighlightSceneObject: (sceneObject?: SceneObjectIdentifier) => void
    onFlipBoundary: (sceneId: number, boundary: SceneObjectIdentifier) => void
    picker?: JSX.Element
}

const determineSceneObjectStyle = (type: SceneObjectType): string => {
    switch (type) {
        case SceneObjectType.entrance:
            return styles.entrance
        case SceneObjectType.passage:
            return styles.passage
        case SceneObjectType.pos:
            return styles.pos
        case SceneObjectType.queue:
            return styles.queue
        case SceneObjectType.seat:
            return styles.seat
        case SceneObjectType.stopZone:
            return styles.stopZone
        case SceneObjectType.otherBoundary:
        case SceneObjectType.otherZone:
            return styles.other
    }
}

const determineSceneObjectIcons = (sceneConfig: SceneObjectType): JSX.Element => {
    switch (sceneObjectBackendType(sceneConfig)) {
        case SceneObjectBackendType.visitBoundaries:
            return <BoundaryIcon />
        default:
            return <FontAwesomeIcon icon={faVectorSquare} />
    }
}

const SceneObject: React.FC<{
    name: string
    highlighted: boolean
    selected: boolean
    visible: boolean
    eventKey: string
    onSelect: () => void
    onDeleteSceneObject: () => void
    onEditSceneObjectName: (name: string) => void
    onSetVisibility: (isVisible: boolean) => void
    onHighlightedChange: (highlighted: boolean) => void
    onFlipBoundary: () => void
    type: SceneObjectType
}> = ({
    name,
    selected,
    highlighted,
    onSelect,
    type,
    onDeleteSceneObject,
    onEditSceneObjectName,
    onHighlightedChange,
    onFlipBoundary,
    visible,
    onSetVisibility,
}) => {
    const [temporaryValue, setTemporaryValue] = useState('')
    const [active, setActive] = useState(false)

    useEffect(() => {
        setTemporaryValue(name)
    }, [name, active])

    const handleEscapeAndEnter = (event: React.KeyboardEvent<HTMLInputElement>) => {
        switch (event.key) {
            case 'Enter':
                onEditSceneObjectName(temporaryValue)
                setActive(false)
        }
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTemporaryValue(event.target.value)
    }

    const handleBlur = () => {
        setActive(false)
        onEditSceneObjectName(temporaryValue)
    }

    return (
        <div
            className={classNames(styles.sceneObject, determineSceneObjectStyle(type), {
                [styles.selected]: selected,
                [styles.highlighted]: highlighted,
            })}
            onClick={onSelect}
            onMouseEnter={() => onHighlightedChange(true)}
            onMouseLeave={() => onHighlightedChange(false)}
        >
            <div className={styles.sceneObjectNameContainer}>
                {determineSceneObjectIcons(type)}
                {!active && (
                    <span className={styles.input} onClick={() => setActive(true)}>
                        {name}
                    </span>
                )}
                {active && (
                    <input
                        key={`${name}-${type}}`}
                        className={styles.input}
                        value={temporaryValue}
                        autoFocus
                        onBlur={handleBlur}
                        onChange={handleChange}
                        onKeyDown={handleEscapeAndEnter}
                    />
                )}
            </div>
            <div className={styles.sceneObjectIconsContainer}>
                <FontAwesomeIcon
                    icon={visible ? faEye : faEyeSlash}
                    onClick={(e) => {
                        e.stopPropagation()
                        onSetVisibility(!visible)
                    }}
                />
                {[SceneObjectType.entrance, SceneObjectType.otherBoundary].includes(type) && (
                    <FontAwesomeIcon
                        className={styles.icon}
                        icon={faExchangeAlt}
                        onClick={(e) => {
                            e.stopPropagation()
                            onFlipBoundary()
                        }}
                    />
                )}
                <FontAwesomeIcon className={styles.icon} icon={faTrash} onClick={onDeleteSceneObject} />
            </div>
        </div>
    )
}

const DescribeSceneNestedList: React.FC<DescribeSceneNestedListProps> = ({
    scenes,
    highlightedSceneObject,
    sceneConfigurations,
    selectedScene,
    selectedSceneObject,
    onSelectScene,
    onDeleteSceneObject,
    onFlipBoundary,
    onSelectSceneObject,
    onEditSceneObject,
    onHighlightSceneObject,
    picker,
}) => (
    <div className={styles.describeSceneContainer}>
        {picker}
        <ListGroup
            activeKey={selectedScene?.id}
            onSelect={(eventKey) => (eventKey ? onSelectScene(eventKey, undefined) : undefined)}
        >
            {scenes.map(({ label, id, deviceId }) => {
                const sceneObjects = sceneConfigurations[id]

                const boundariesPresent = sceneObjects.filter(
                    (it) => it.backendType === SceneObjectBackendType.visitBoundaries
                ).length
                const detectionZonesPresent = sceneObjects.filter(
                    (it) => it.backendType === SceneObjectBackendType.detectionZones
                ).length

                return (
                    <React.Fragment key={id}>
                        <ListGroup.Item
                            className={styles.describeSceneListItem}
                            disabled={id === selectedScene?.id}
                            eventKey={String(id)}
                            action
                        >
                            {deviceId !== undefined ? `#${deviceId} - ${label}` : label}
                            <div className={styles.sceneIcons}>
                                {boundariesPresent > 0 && (
                                    <>
                                        <BoundaryIcon
                                            className={classNames({
                                                [styles.inactive]: id !== selectedScene?.id,
                                                [styles.selected]: id === selectedScene?.id,
                                            })}
                                        />
                                        {boundariesPresent}
                                    </>
                                )}
                                {detectionZonesPresent > 0 && (
                                    <>
                                        <FontAwesomeIcon
                                            className={classNames({
                                                [styles.inactive]: id !== selectedScene?.id,
                                                [styles.selected]: id === selectedScene?.id,
                                            })}
                                            icon={faVectorSquare}
                                        />
                                        {detectionZonesPresent}
                                    </>
                                )}
                            </div>
                        </ListGroup.Item>
                        {sceneObjects.map((sceneObject) => {
                            const isHighlighted = determineSelectedSceneObject(sceneObject)

                            const editSceneObjectName = (name: string) =>
                                onEditSceneObject(id, { ...sceneObject, name })

                            const setSceneObjectVisibility = (visible: boolean) =>
                                onEditSceneObject(id, { ...sceneObject, isVisible: visible })

                            return (
                                <SceneObject
                                    key={`scene-object-${sceneObject.id ?? sceneObject.tempId!}`}
                                    eventKey={String(id)}
                                    highlighted={isHighlighted(highlightedSceneObject)}
                                    name={isEmpty(sceneObject.name) ? 'Unnamed' : sceneObject.name}
                                    selected={isHighlighted(selectedSceneObject)}
                                    type={sceneObject.type}
                                    visible={sceneObject.isVisible}
                                    onDeleteSceneObject={() => onDeleteSceneObject(id, sceneObject)}
                                    onEditSceneObjectName={editSceneObjectName}
                                    onFlipBoundary={() =>
                                        isVisitBoundary(sceneObject) ? onFlipBoundary(id, sceneObject) : null
                                    }
                                    onHighlightedChange={(highlighted) =>
                                        onHighlightSceneObject(highlighted ? sceneObject : undefined)
                                    }
                                    onSelect={() => onSelectSceneObject(id, sceneObject)}
                                    onSetVisibility={setSceneObjectVisibility}
                                />
                            )
                        })}
                    </React.Fragment>
                )
            })}
        </ListGroup>
    </div>
)

export default DescribeSceneNestedList
