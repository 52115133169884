import classNames from 'classnames'
import React from 'react'
import { Container } from 'react-bootstrap'
import Helmet from 'react-helmet'

import useScrollToTopOnMount from '@helpers/scrolling'
import { Possibly } from '@helpers/types'

import Box from '@elements/Box/Box'

import Breadcrumbs, { BreadcrumbsList } from '@components/Breadcrumbs'
import Navbar from '@components/Navbar/Navbar'

import { Content, ContentProps } from './Content'
import { LayoutHeading, LayoutHeadingProps } from './LayoutHeading'
import SpacedLayout from './SpacedLayout'
import styles from './layouts.module.scss'

type Props = Possibly<LayoutHeadingProps> &
    ContentProps & {
        fluid?: boolean
        paddingSize?: 'md' | 'lg'
        footer?: React.ReactNode
    } & Possibly<{ breadcrumbs: BreadcrumbsList }> & { documentTitle?: string }

const PageContentLayout = ({
    children,
    fluid,
    heading,
    headingButtons,
    loadingState,
    headingClassName,
    headingType,
    paddingSize = 'md',
    footer = null,
    breadcrumbs,
    documentTitle,
}: Props) => {
    useScrollToTopOnMount()

    return (
        <>
            <Navbar />
            {documentTitle && (
                <Helmet>
                    <title>{documentTitle}</title>
                </Helmet>
            )}
            <Container
                className={classNames({
                    [styles.topPadding]: true,
                    [styles.defaultContainer]: true,
                })}
                fluid={fluid}
            >
                <Box paddingSize={paddingSize}>
                    <SpacedLayout gapSize="lg">
                        {breadcrumbs && <Breadcrumbs breadcrumbs={breadcrumbs} className={styles.breadcrumbs} />}
                        {heading !== undefined && (
                            <LayoutHeading
                                heading={heading}
                                headingButtons={headingButtons}
                                headingClassName={headingClassName}
                                headingType={headingType}
                            />
                        )}
                        <div>
                            <Content children={children} loadingState={loadingState} />
                        </div>
                    </SpacedLayout>
                </Box>
            </Container>
            {footer && <footer className={styles.footerContainer}>{footer}</footer>}
        </>
    )
}

export default PageContentLayout
