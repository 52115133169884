import React from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { useParams } from 'react-router'

import { localityApi, reportsApi } from '@services'

import { mergeQueryResults } from '@helpers/api'

import PageContentLayout from '@components/Layouts/PageContentLayout'
import LegacyLoadingWrapper from '@components/LegacyLoadingWrapper'
import QueueReportList from '@components/QueueReportList/QueueReportList'

const QueueReportListPage: React.FC = () => {
    const { t } = useTranslation()
    const params = useParams<{ localityId: string }>()
    const localityId = parseInt(params.localityId, 10)

    const reportsCall = useQuery(reportsApi.listLocalityQueueStatisticsReports.query({ localityId }))
    const localityCall = useQuery(
        localityApi.getLocality.query({
            localityId: parseInt(params.localityId),
        })
    )

    const request = mergeQueryResults(reportsCall, localityCall)
    const heading = t('heading.queueReports', 'Queue Reports')
    const documentTitle = t('title.queueReports', 'Queue reports')

    return (
        <LegacyLoadingWrapper
            bare={true}
            placeholder={
                <PageContentLayout documentTitle={documentTitle} heading={heading} loadingState={request.status} />
            }
            request={request}
        >
            {([reports, locality]) => (
                <PageContentLayout
                    documentTitle={`${locality.name} - ${documentTitle}`}
                    heading={`${locality.name} - ${heading}`}
                >
                    <QueueReportList localityId={localityId} reports={reports.reports} />
                </PageContentLayout>
            )}
        </LegacyLoadingWrapper>
    )
}

export default QueueReportListPage
