import React, { useCallback, useEffect } from 'react'
import { useDarkreader } from 'react-darkreader'
import { useLocalStorage } from 'react-use'

type DarkModeContextProps = {
    darkMode: boolean
    toggleDarkMode: () => void
}

export const DarkModeContext = React.createContext<DarkModeContextProps>({
    darkMode: false,
    toggleDarkMode: () => {},
})

const DarkModeProvider: React.FC = ({ children }) => {
    const [isDark, setIsDark] = useLocalStorage<boolean>('isDark')
    const [darkMode, { toggle }] = useDarkreader(isDark ?? false)

    const toggleDarkMode = useCallback(() => {
        setIsDark(!isDark)
        toggle()
    }, [isDark, setIsDark, toggle])

    useEffect(() => {
        if (isDark !== darkMode) {
            setIsDark(darkMode)
        }
    }, [isDark, darkMode])

    return <DarkModeContext.Provider value={{ darkMode, toggleDarkMode }}>{children}</DarkModeContext.Provider>
}

export default DarkModeProvider
