/* tslint:disable */
/* eslint-disable */
/**
 * Vividi Dashboard API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DatetimeInterval,
    DatetimeIntervalFromJSON,
    DatetimeIntervalFromJSONTyped,
    DatetimeIntervalToJSON,
} from './DatetimeInterval';
import {
    ZoneOccupancySession,
    ZoneOccupancySessionFromJSON,
    ZoneOccupancySessionFromJSONTyped,
    ZoneOccupancySessionToJSON,
} from './ZoneOccupancySession';

/**
 * 
 * @export
 * @interface ZoneOccupancySessionsResponse
 */
export interface ZoneOccupancySessionsResponse {
    /**
     * 
     * @type {string}
     * @memberof ZoneOccupancySessionsResponse
     */
    startingFrom: string;
    /**
     * 
     * @type {string}
     * @memberof ZoneOccupancySessionsResponse
     */
    endingAt: string;
    /**
     * 
     * @type {Array<ZoneOccupancySession>}
     * @memberof ZoneOccupancySessionsResponse
     */
    sessions: Array<ZoneOccupancySession>;
    /**
     * Time intervals in which the locality was closed
     * @type {Array<DatetimeInterval>}
     * @memberof ZoneOccupancySessionsResponse
     */
    closedIntervals: Array<DatetimeInterval>;
}

export function ZoneOccupancySessionsResponseFromJSON(json: any): ZoneOccupancySessionsResponse {
    return ZoneOccupancySessionsResponseFromJSONTyped(json, false);
}

export function ZoneOccupancySessionsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ZoneOccupancySessionsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'startingFrom': json['starting_from'],
        'endingAt': json['ending_at'],
        'sessions': ((json['sessions'] as Array<any>).map(ZoneOccupancySessionFromJSON)),
        'closedIntervals': ((json['closed_intervals'] as Array<any>).map(DatetimeIntervalFromJSON)),
    };
}

export function ZoneOccupancySessionsResponseToJSON(value?: ZoneOccupancySessionsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'starting_from': value.startingFrom,
        'ending_at': value.endingAt,
        'sessions': ((value.sessions as Array<any>).map(ZoneOccupancySessionToJSON)),
        'closed_intervals': ((value.closedIntervals as Array<any>).map(DatetimeIntervalToJSON)),
    };
}

