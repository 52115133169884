/* tslint:disable */
/* eslint-disable */
/**
 * Vividi Dashboard API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OrganizationResponse,
    OrganizationResponseFromJSON,
    OrganizationResponseFromJSONTyped,
    OrganizationResponseToJSON,
} from './OrganizationResponse';
import {
    UserResponse,
    UserResponseFromJSON,
    UserResponseFromJSONTyped,
    UserResponseToJSON,
} from './UserResponse';

/**
 * 
 * @export
 * @interface UserListResponse
 */
export interface UserListResponse {
    /**
     * 
     * @type {Array<UserResponse>}
     * @memberof UserListResponse
     */
    users: Array<UserResponse>;
    /**
     * Organizations related to users indexed by their identifiers
     * @type {{ [key: string]: OrganizationResponse; }}
     * @memberof UserListResponse
     */
    organizations: { [key: string]: OrganizationResponse; };
}

export function UserListResponseFromJSON(json: any): UserListResponse {
    return UserListResponseFromJSONTyped(json, false);
}

export function UserListResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserListResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'users': ((json['users'] as Array<any>).map(UserResponseFromJSON)),
        'organizations': (mapValues(json['organizations'], OrganizationResponseFromJSON)),
    };
}

export function UserListResponseToJSON(value?: UserListResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'users': ((value.users as Array<any>).map(UserResponseToJSON)),
        'organizations': (mapValues(value.organizations, OrganizationResponseToJSON)),
    };
}

