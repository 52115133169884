import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import { deburr, flatMap, fromPairs, isEmpty, uniq, uniqueId } from 'lodash'
import React, { useMemo, useState } from 'react'
import { Button, Form } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import { LocalityNameModel } from '@api/models'

import { GoogleEventCategory, GoogleEventName, trackEvent } from '@helpers/gtag'

import ToggleBadge from '@elements/ToggleBadge/ToggleBadge'

import TextInput from '@components/GenericInputs/TextInput'
import PopoverButton from '@components/PopoverButton/PopoverButton'

import styles from './FilterButton.module.scss'

type Props = {
    icon: IconDefinition
    localities: LocalityNameModel[]
    selectedLocalities: LocalityNameModel[]
    onLocalitiesChange: (localities: LocalityNameModel[]) => void
}

const LocalityFilterButton = ({ icon, localities, selectedLocalities, onLocalitiesChange }: Props) => {
    const { t } = useTranslation()

    const id = useMemo(uniqueId, [])

    const [search, setSearch] = useState('')

    const localitiesFilteredBySearch = useMemo(() => {
        const normalizedLocalities = localities.map((locality) => ({
            ...locality,
            normalizedName: deburr(locality.name).toLowerCase(),
        }))

        return normalizedLocalities.filter((locality) =>
            locality.normalizedName.toLowerCase().includes(deburr(search).toLowerCase())
        )
    }, [search, localities])

    const uniqueLocalityLabels = uniq(
        flatMap(
            localities.filter((locality) => !isEmpty(locality)),
            (it) => it.labels
        )
    ).sort((a, b) => a.toUpperCase().localeCompare(b.toUpperCase()))

    const initialLabels = fromPairs(uniqueLocalityLabels.map((l) => [l, false]))

    const [selectedLabels, setSelectedLabels] = useState(initialLabels)

    const buttonText = (() => {
        if (selectedLocalities.length === localities.length) {
            return t('button.allLocalities', 'All localities')
        }

        return selectedLocalities.map((l) => l.name).join(', ')
    })()

    const handleLocalitySwitch = (locality: LocalityNameModel) => {
        const isSelected = selectedLocalities.map((l) => l.id).includes(locality.id)

        if (isSelected) {
            onLocalitiesChange(selectedLocalities.filter((l) => l.id !== locality.id))

            return
        }
        onLocalitiesChange([...selectedLocalities, locality])
    }

    const handleLabelSelect = (label: string) => {
        const updatedSelection = {
            ...selectedLabels,
            [label]: !selectedLabels[label],
        }
        setSelectedLabels(updatedSelection)

        const activeLabels = Object.entries(updatedSelection)
            .filter(([_, isSelected]) => isSelected)
            .map((it) => it[0])

        const filteredLocalities = localitiesFilteredBySearch.filter((locality) =>
            locality.labels.some((it) => activeLabels.includes(it))
        )

        onLocalitiesChange(!isEmpty(filteredLocalities) ? filteredLocalities : localitiesFilteredBySearch)
    }

    const selectableLabels = Object.entries(selectedLabels).map(([label, isSelected]) => (
        <ToggleBadge key={`${label} filter`} name={label} selected={isSelected} onToggle={handleLabelSelect} />
    ))

    const selectableLocalities = localitiesFilteredBySearch.map((locality) => (
        <div key={`${locality.id} filter`} className={styles.localityContainer}>
            <Form.Check
                checked={selectedLocalities.map((l) => l.id).includes(locality.id)}
                id={`${id}_${locality.id}`}
                label={locality.name}
                type="checkbox"
                custom
                onChange={() => {
                    trackEvent(GoogleEventCategory.STATISTICS, GoogleEventName.FILTER_LOCALITY_CHECKBOX, locality.name)
                    setSelectedLabels(initialLabels)
                    handleLocalitySwitch(locality)
                }}
            />
            {localitiesFilteredBySearch.length > 1 && (
                <Button
                    className={styles.selectOnly}
                    variant="link"
                    onClick={() => {
                        trackEvent(
                            GoogleEventCategory.STATISTICS,
                            GoogleEventName.FILTER_LOCALITY_SELECT_ONLY,
                            locality.name
                        )
                        setSelectedLabels(initialLabels)
                        onLocalitiesChange([locality])
                    }}
                >
                    {t('button.selectOnly', 'select only')}
                </Button>
            )}
        </div>
    ))

    const searchInput = (
        <TextInput
            autofocus={true}
            className={styles.searchInputLocalities}
            placeholder={t('others.searchLocalities', 'Search for localities')}
            value={search}
            onChange={setSearch}
        />
    )

    const localitiesContainer = (
        <>
            {!isEmpty(uniqueLocalityLabels) && (
                <>
                    {searchInput}
                    <div className={styles.localityTitleContainer}>Labels</div>
                    <div className={styles.labelContainer}>{selectableLabels}</div>
                </>
            )}
            {localities.length > 1 && (
                <>
                    {isEmpty(uniqueLocalityLabels) && searchInput}
                    {!isEmpty(selectableLocalities) && (
                        <div className={styles.localityTitleContainer}>
                            {t('table.localities', 'Localities')}
                            <Button
                                className={styles.selectOnly}
                                variant="link"
                                onClick={() => {
                                    setSelectedLabels(initialLabels)
                                    onLocalitiesChange([...localities])
                                }}
                            >
                                {t('button.selectAll', 'select all')}
                            </Button>
                        </div>
                    )}
                </>
            )}
            {selectableLocalities}
        </>
    )

    return (
        <PopoverButton
            content={localitiesContainer}
            placement="bottom"
            trigger="click"
            isWide
            onExit={() => setSearch('')}
        >
            <div className={styles.filterButton}>
                <FontAwesomeIcon icon={icon} />
                <span className={classNames(styles.filterTextLabel, styles.localitiesShorten)}>{buttonText}</span>
            </div>
        </PopoverButton>
    )
}

export default LocalityFilterButton
