/**
 * Vividi Dashboard API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime'
import {UseQueryOptions, QueryKey} from 'react-query'

import {
    CreateUserRequestBody,
    CreateUserRequestBodyFromJSON,
    CreateUserRequestBodyToJSON,
    ErrorResponse,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    LanguageChangeRequestBody,
    LanguageChangeRequestBodyFromJSON,
    LanguageChangeRequestBodyToJSON,
    LocalityIdListModel,
    LocalityIdListModelFromJSON,
    LocalityIdListModelToJSON,
    PasswordChangeRequestBody,
    PasswordChangeRequestBodyFromJSON,
    PasswordChangeRequestBodyToJSON,
    PasswordChangeTokenRequestBody,
    PasswordChangeTokenRequestBodyFromJSON,
    PasswordChangeTokenRequestBodyToJSON,
    PasswordResetRequestBody,
    PasswordResetRequestBodyFromJSON,
    PasswordResetRequestBodyToJSON,
    SetUserRoleRequestBody,
    SetUserRoleRequestBodyFromJSON,
    SetUserRoleRequestBodyToJSON,
    SuccessResponse,
    SuccessResponseFromJSON,
    SuccessResponseToJSON,
    TokenResponse,
    TokenResponseFromJSON,
    TokenResponseToJSON,
    UserListResponse,
    UserListResponseFromJSON,
    UserListResponseToJSON,
    UserModel,
    UserModelFromJSON,
    UserModelToJSON,
    UserNotificationPreferences,
    UserNotificationPreferencesFromJSON,
    UserNotificationPreferencesToJSON,
    UserResponse,
    UserResponseFromJSON,
    UserResponseToJSON,
} from '../models';

export interface AddUserRequest {
    body: CreateUserRequestBody;
}

export interface AgreeToTermsAndConditionsRequest {
    version: string;
}

export interface ChangeOwnLanguageRequest {
    body: LanguageChangeRequestBody;
}

export interface ChangeOwnPasswordRequest {
    body: PasswordChangeRequestBody;
}

export interface DeleteUserRequest {
    userId: number;
}

export interface GetNotificationPreferencesRequest {
    userId: number;
}

export interface RequestPasswordResetRequest {
    body: PasswordChangeTokenRequestBody;
}

export interface ResetOwnPasswordRequest {
    body: PasswordResetRequestBody;
}

export interface SetNotificationPreferencesRequest {
    userId: number;
    body: UserNotificationPreferences;
}

export interface SetUserLocalitiesRequest {
    userId: number;
    body: LocalityIdListModel;
}

export interface SetUserPasswordRequest {
    userId: number;
    body: PasswordResetRequestBody;
}

export interface SetUserRoleRequest {
    userId: number;
    body: SetUserRoleRequestBody;
}

export interface UpdateUserRequest {
    userId: number;
    body: UserModel;
}


/**
 * 
 */
export class UserManagementApi extends runtime.BaseAPI {
    readonly name = 'UserManagementApi'

    /**
     * Create a new user account.
     */
    addUser = Object.assign(
        async (requestParameters: AddUserRequest, initOverrides?: RequestInit): Promise<UserResponse> => {
            const response = await this.addUser.invokeRaw(requestParameters, initOverrides);
            return await response.value();
        },
        {
            invokeRaw: async (requestParameters: AddUserRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<UserResponse>> => {
                if (requestParameters.body === null || requestParameters.body === undefined) {
                    throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling addUser.');
                }
            
                const queryParameters: any = {};
            
                const headerParameters: runtime.HTTPHeaders = {};
            
                headerParameters['Content-Type'] = 'application/json';
            
                if (this.configuration && this.configuration.accessToken) {
                    const token = this.configuration.accessToken;
                    const tokenString = await token("token", []);
            
                    if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                    }
                }
                const response = await this.request({
                    path: `/api/users`,
                    method: 'POST',
                    headers: headerParameters,
                    query: queryParameters,
                    body: CreateUserRequestBodyToJSON(requestParameters.body),
                }, initOverrides);
            
                return new runtime.JSONApiResponse(response, (jsonValue) => UserResponseFromJSON(jsonValue));
            },
            mutationKey: [
                'UserManagementApi',
                'addUser', 
            ],
            queryKey: (requestParameters: AddUserRequest, initOverrides?: RequestInit) => ([
                    ...this.addUser.mutationKey, 
                {
                body: requestParameters.body,
                }
            ]),
            query: (requestParameters: AddUserRequest, initOverrides?: RequestInit): UseQueryOptions<UserResponse> & {queryKey: QueryKey} => ({
                queryFn: () => this.addUser(requestParameters, initOverrides),
                queryKey: this.addUser.queryKey(requestParameters, initOverrides)
            })
        }
    )

    /**
     * Store the information that the user agrees to a version of the terms and conditions.
     */
    agreeToTermsAndConditions = Object.assign(
        async (requestParameters: AgreeToTermsAndConditionsRequest, initOverrides?: RequestInit): Promise<SuccessResponse> => {
            const response = await this.agreeToTermsAndConditions.invokeRaw(requestParameters, initOverrides);
            return await response.value();
        },
        {
            invokeRaw: async (requestParameters: AgreeToTermsAndConditionsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SuccessResponse>> => {
                if (requestParameters.version === null || requestParameters.version === undefined) {
                    throw new runtime.RequiredError('version','Required parameter requestParameters.version was null or undefined when calling agreeToTermsAndConditions.');
                }
            
                const queryParameters: any = {};
            
                if (requestParameters.version !== undefined) {
                    queryParameters['version'] = requestParameters.version;
                }
            
                const headerParameters: runtime.HTTPHeaders = {};
            
                if (this.configuration && this.configuration.accessToken) {
                    const token = this.configuration.accessToken;
                    const tokenString = await token("token", []);
            
                    if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                    }
                }
                const response = await this.request({
                    path: `/api/users/last_terms_and_conditions_version`,
                    method: 'POST',
                    headers: headerParameters,
                    query: queryParameters,
                }, initOverrides);
            
                return new runtime.JSONApiResponse(response, (jsonValue) => SuccessResponseFromJSON(jsonValue));
            },
            mutationKey: [
                'UserManagementApi',
                'agreeToTermsAndConditions', 
            ],
            queryKey: (requestParameters: AgreeToTermsAndConditionsRequest, initOverrides?: RequestInit) => ([
                    ...this.agreeToTermsAndConditions.mutationKey, 
                {
                version: requestParameters.version,
                }
            ]),
            query: (requestParameters: AgreeToTermsAndConditionsRequest, initOverrides?: RequestInit): UseQueryOptions<SuccessResponse> & {queryKey: QueryKey} => ({
                queryFn: () => this.agreeToTermsAndConditions(requestParameters, initOverrides),
                queryKey: this.agreeToTermsAndConditions.queryKey(requestParameters, initOverrides)
            })
        }
    )

    /**
     * Change the preferred language for the currently authenticated user.
     */
    changeOwnLanguage = Object.assign(
        async (requestParameters: ChangeOwnLanguageRequest, initOverrides?: RequestInit): Promise<SuccessResponse> => {
            const response = await this.changeOwnLanguage.invokeRaw(requestParameters, initOverrides);
            return await response.value();
        },
        {
            invokeRaw: async (requestParameters: ChangeOwnLanguageRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SuccessResponse>> => {
                if (requestParameters.body === null || requestParameters.body === undefined) {
                    throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling changeOwnLanguage.');
                }
            
                const queryParameters: any = {};
            
                const headerParameters: runtime.HTTPHeaders = {};
            
                headerParameters['Content-Type'] = 'application/json';
            
                if (this.configuration && this.configuration.accessToken) {
                    const token = this.configuration.accessToken;
                    const tokenString = await token("token", []);
            
                    if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                    }
                }
                const response = await this.request({
                    path: `/api/users/language-change`,
                    method: 'POST',
                    headers: headerParameters,
                    query: queryParameters,
                    body: LanguageChangeRequestBodyToJSON(requestParameters.body),
                }, initOverrides);
            
                return new runtime.JSONApiResponse(response, (jsonValue) => SuccessResponseFromJSON(jsonValue));
            },
            mutationKey: [
                'UserManagementApi',
                'changeOwnLanguage', 
            ],
            queryKey: (requestParameters: ChangeOwnLanguageRequest, initOverrides?: RequestInit) => ([
                    ...this.changeOwnLanguage.mutationKey, 
                {
                body: requestParameters.body,
                }
            ]),
            query: (requestParameters: ChangeOwnLanguageRequest, initOverrides?: RequestInit): UseQueryOptions<SuccessResponse> & {queryKey: QueryKey} => ({
                queryFn: () => this.changeOwnLanguage(requestParameters, initOverrides),
                queryKey: this.changeOwnLanguage.queryKey(requestParameters, initOverrides)
            })
        }
    )

    /**
     * Change the password of the current user, using the current password for authentication.
     */
    changeOwnPassword = Object.assign(
        async (requestParameters: ChangeOwnPasswordRequest, initOverrides?: RequestInit): Promise<TokenResponse> => {
            const response = await this.changeOwnPassword.invokeRaw(requestParameters, initOverrides);
            return await response.value();
        },
        {
            invokeRaw: async (requestParameters: ChangeOwnPasswordRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<TokenResponse>> => {
                if (requestParameters.body === null || requestParameters.body === undefined) {
                    throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling changeOwnPassword.');
                }
            
                const queryParameters: any = {};
            
                const headerParameters: runtime.HTTPHeaders = {};
            
                headerParameters['Content-Type'] = 'application/json';
            
                if (this.configuration && this.configuration.accessToken) {
                    const token = this.configuration.accessToken;
                    const tokenString = await token("token", []);
            
                    if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                    }
                }
                const response = await this.request({
                    path: `/api/users/password-change`,
                    method: 'POST',
                    headers: headerParameters,
                    query: queryParameters,
                    body: PasswordChangeRequestBodyToJSON(requestParameters.body),
                }, initOverrides);
            
                return new runtime.JSONApiResponse(response, (jsonValue) => TokenResponseFromJSON(jsonValue));
            },
            mutationKey: [
                'UserManagementApi',
                'changeOwnPassword', 
            ],
            queryKey: (requestParameters: ChangeOwnPasswordRequest, initOverrides?: RequestInit) => ([
                    ...this.changeOwnPassword.mutationKey, 
                {
                body: requestParameters.body,
                }
            ]),
            query: (requestParameters: ChangeOwnPasswordRequest, initOverrides?: RequestInit): UseQueryOptions<TokenResponse> & {queryKey: QueryKey} => ({
                queryFn: () => this.changeOwnPassword(requestParameters, initOverrides),
                queryKey: this.changeOwnPassword.queryKey(requestParameters, initOverrides)
            })
        }
    )

    /**
     * Delete a user account.
     */
    deleteUser = Object.assign(
        async (requestParameters: DeleteUserRequest, initOverrides?: RequestInit): Promise<SuccessResponse> => {
            const response = await this.deleteUser.invokeRaw(requestParameters, initOverrides);
            return await response.value();
        },
        {
            invokeRaw: async (requestParameters: DeleteUserRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SuccessResponse>> => {
                if (requestParameters.userId === null || requestParameters.userId === undefined) {
                    throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling deleteUser.');
                }
            
                const queryParameters: any = {};
            
                const headerParameters: runtime.HTTPHeaders = {};
            
                if (this.configuration && this.configuration.accessToken) {
                    const token = this.configuration.accessToken;
                    const tokenString = await token("token", []);
            
                    if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                    }
                }
                const response = await this.request({
                    path: `/api/users/{user_id}`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))),
                    method: 'DELETE',
                    headers: headerParameters,
                    query: queryParameters,
                }, initOverrides);
            
                return new runtime.JSONApiResponse(response, (jsonValue) => SuccessResponseFromJSON(jsonValue));
            },
            mutationKey: [
                'UserManagementApi',
                'deleteUser', 
            ],
            queryKey: (requestParameters: DeleteUserRequest, initOverrides?: RequestInit) => ([
                    ...this.deleteUser.mutationKey, 
                {
                userId: requestParameters.userId,
                }
            ]),
            query: (requestParameters: DeleteUserRequest, initOverrides?: RequestInit): UseQueryOptions<SuccessResponse> & {queryKey: QueryKey} => ({
                queryFn: () => this.deleteUser(requestParameters, initOverrides),
                queryKey: this.deleteUser.queryKey(requestParameters, initOverrides)
            })
        }
    )

    /**
     * Get notification preferences for a user.
     */
    getNotificationPreferences = Object.assign(
        async (requestParameters: GetNotificationPreferencesRequest, initOverrides?: RequestInit): Promise<UserNotificationPreferences> => {
            const response = await this.getNotificationPreferences.invokeRaw(requestParameters, initOverrides);
            return await response.value();
        },
        {
            invokeRaw: async (requestParameters: GetNotificationPreferencesRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<UserNotificationPreferences>> => {
                if (requestParameters.userId === null || requestParameters.userId === undefined) {
                    throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling getNotificationPreferences.');
                }
            
                const queryParameters: any = {};
            
                const headerParameters: runtime.HTTPHeaders = {};
            
                if (this.configuration && this.configuration.accessToken) {
                    const token = this.configuration.accessToken;
                    const tokenString = await token("token", []);
            
                    if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                    }
                }
                const response = await this.request({
                    path: `/api/users/{user_id}/notification-preferences`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))),
                    method: 'GET',
                    headers: headerParameters,
                    query: queryParameters,
                }, initOverrides);
            
                return new runtime.JSONApiResponse(response, (jsonValue) => UserNotificationPreferencesFromJSON(jsonValue));
            },
            mutationKey: [
                'UserManagementApi',
                'getNotificationPreferences', 
            ],
            queryKey: (requestParameters: GetNotificationPreferencesRequest, initOverrides?: RequestInit) => ([
                    ...this.getNotificationPreferences.mutationKey, 
                {
                userId: requestParameters.userId,
                }
            ]),
            query: (requestParameters: GetNotificationPreferencesRequest, initOverrides?: RequestInit): UseQueryOptions<UserNotificationPreferences> & {queryKey: QueryKey} => ({
                queryFn: () => this.getNotificationPreferences(requestParameters, initOverrides),
                queryKey: this.getNotificationPreferences.queryKey(requestParameters, initOverrides)
            })
        }
    )

    /**
     * Get a list of all users.
     */
    getUsers = Object.assign(
        async (initOverrides?: RequestInit): Promise<UserListResponse> => {
            const response = await this.getUsers.invokeRaw(initOverrides);
            return await response.value();
        },
        {
            invokeRaw: async (initOverrides?: RequestInit): Promise<runtime.ApiResponse<UserListResponse>> => {
                const queryParameters: any = {};
            
                const headerParameters: runtime.HTTPHeaders = {};
            
                if (this.configuration && this.configuration.accessToken) {
                    const token = this.configuration.accessToken;
                    const tokenString = await token("token", []);
            
                    if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                    }
                }
                const response = await this.request({
                    path: `/api/users`,
                    method: 'GET',
                    headers: headerParameters,
                    query: queryParameters,
                }, initOverrides);
            
                return new runtime.JSONApiResponse(response, (jsonValue) => UserListResponseFromJSON(jsonValue));
            },
            mutationKey: [
                'UserManagementApi',
                'getUsers', 
            ],
            queryKey: (initOverrides?: RequestInit) => ([
                    ...this.getUsers.mutationKey, 
                {
                }
            ]),
            query: (initOverrides?: RequestInit): UseQueryOptions<UserListResponse> & {queryKey: QueryKey} => ({
                queryFn: () => this.getUsers(initOverrides),
                queryKey: this.getUsers.queryKey(initOverrides)
            })
        }
    )

    /**
     * Request a one-time token for changing the password to an account to be sent to the e-mail of its owner.
     */
    requestPasswordReset = Object.assign(
        async (requestParameters: RequestPasswordResetRequest, initOverrides?: RequestInit): Promise<SuccessResponse> => {
            const response = await this.requestPasswordReset.invokeRaw(requestParameters, initOverrides);
            return await response.value();
        },
        {
            invokeRaw: async (requestParameters: RequestPasswordResetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SuccessResponse>> => {
                if (requestParameters.body === null || requestParameters.body === undefined) {
                    throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling requestPasswordReset.');
                }
            
                const queryParameters: any = {};
            
                const headerParameters: runtime.HTTPHeaders = {};
            
                headerParameters['Content-Type'] = 'application/json';
            
                const response = await this.request({
                    path: `/api/users/request-password-reset`,
                    method: 'POST',
                    headers: headerParameters,
                    query: queryParameters,
                    body: PasswordChangeTokenRequestBodyToJSON(requestParameters.body),
                }, initOverrides);
            
                return new runtime.JSONApiResponse(response, (jsonValue) => SuccessResponseFromJSON(jsonValue));
            },
            mutationKey: [
                'UserManagementApi',
                'requestPasswordReset', 
            ],
            queryKey: (requestParameters: RequestPasswordResetRequest, initOverrides?: RequestInit) => ([
                    ...this.requestPasswordReset.mutationKey, 
                {
                body: requestParameters.body,
                }
            ]),
            query: (requestParameters: RequestPasswordResetRequest, initOverrides?: RequestInit): UseQueryOptions<SuccessResponse> & {queryKey: QueryKey} => ({
                queryFn: () => this.requestPasswordReset(requestParameters, initOverrides),
                queryKey: this.requestPasswordReset.queryKey(requestParameters, initOverrides)
            })
        }
    )

    /**
     * Change the password of a user using a one-time token.
     */
    resetOwnPassword = Object.assign(
        async (requestParameters: ResetOwnPasswordRequest, initOverrides?: RequestInit): Promise<TokenResponse> => {
            const response = await this.resetOwnPassword.invokeRaw(requestParameters, initOverrides);
            return await response.value();
        },
        {
            invokeRaw: async (requestParameters: ResetOwnPasswordRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<TokenResponse>> => {
                if (requestParameters.body === null || requestParameters.body === undefined) {
                    throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling resetOwnPassword.');
                }
            
                const queryParameters: any = {};
            
                const headerParameters: runtime.HTTPHeaders = {};
            
                headerParameters['Content-Type'] = 'application/json';
            
                if (this.configuration && this.configuration.accessToken) {
                    const token = this.configuration.accessToken;
                    const tokenString = await token("token", []);
            
                    if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                    }
                }
                const response = await this.request({
                    path: `/api/users/password-reset`,
                    method: 'POST',
                    headers: headerParameters,
                    query: queryParameters,
                    body: PasswordResetRequestBodyToJSON(requestParameters.body),
                }, initOverrides);
            
                return new runtime.JSONApiResponse(response, (jsonValue) => TokenResponseFromJSON(jsonValue));
            },
            mutationKey: [
                'UserManagementApi',
                'resetOwnPassword', 
            ],
            queryKey: (requestParameters: ResetOwnPasswordRequest, initOverrides?: RequestInit) => ([
                    ...this.resetOwnPassword.mutationKey, 
                {
                body: requestParameters.body,
                }
            ]),
            query: (requestParameters: ResetOwnPasswordRequest, initOverrides?: RequestInit): UseQueryOptions<TokenResponse> & {queryKey: QueryKey} => ({
                queryFn: () => this.resetOwnPassword(requestParameters, initOverrides),
                queryKey: this.resetOwnPassword.queryKey(requestParameters, initOverrides)
            })
        }
    )

    /**
     * Configure notifications for a user.
     */
    setNotificationPreferences = Object.assign(
        async (requestParameters: SetNotificationPreferencesRequest, initOverrides?: RequestInit): Promise<SuccessResponse> => {
            const response = await this.setNotificationPreferences.invokeRaw(requestParameters, initOverrides);
            return await response.value();
        },
        {
            invokeRaw: async (requestParameters: SetNotificationPreferencesRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SuccessResponse>> => {
                if (requestParameters.userId === null || requestParameters.userId === undefined) {
                    throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling setNotificationPreferences.');
                }
            
                if (requestParameters.body === null || requestParameters.body === undefined) {
                    throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling setNotificationPreferences.');
                }
            
                const queryParameters: any = {};
            
                const headerParameters: runtime.HTTPHeaders = {};
            
                headerParameters['Content-Type'] = 'application/json';
            
                if (this.configuration && this.configuration.accessToken) {
                    const token = this.configuration.accessToken;
                    const tokenString = await token("token", []);
            
                    if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                    }
                }
                const response = await this.request({
                    path: `/api/users/{user_id}/notification-preferences`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))),
                    method: 'PUT',
                    headers: headerParameters,
                    query: queryParameters,
                    body: UserNotificationPreferencesToJSON(requestParameters.body),
                }, initOverrides);
            
                return new runtime.JSONApiResponse(response, (jsonValue) => SuccessResponseFromJSON(jsonValue));
            },
            mutationKey: [
                'UserManagementApi',
                'setNotificationPreferences', 
            ],
            queryKey: (requestParameters: SetNotificationPreferencesRequest, initOverrides?: RequestInit) => ([
                    ...this.setNotificationPreferences.mutationKey, 
                {
                userId: requestParameters.userId,
                body: requestParameters.body,
                }
            ]),
            query: (requestParameters: SetNotificationPreferencesRequest, initOverrides?: RequestInit): UseQueryOptions<SuccessResponse> & {queryKey: QueryKey} => ({
                queryFn: () => this.setNotificationPreferences(requestParameters, initOverrides),
                queryKey: this.setNotificationPreferences.queryKey(requestParameters, initOverrides)
            })
        }
    )

    /**
     * Set the list of localities a user is a member of.
     */
    setUserLocalities = Object.assign(
        async (requestParameters: SetUserLocalitiesRequest, initOverrides?: RequestInit): Promise<SuccessResponse> => {
            const response = await this.setUserLocalities.invokeRaw(requestParameters, initOverrides);
            return await response.value();
        },
        {
            invokeRaw: async (requestParameters: SetUserLocalitiesRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SuccessResponse>> => {
                if (requestParameters.userId === null || requestParameters.userId === undefined) {
                    throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling setUserLocalities.');
                }
            
                if (requestParameters.body === null || requestParameters.body === undefined) {
                    throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling setUserLocalities.');
                }
            
                const queryParameters: any = {};
            
                const headerParameters: runtime.HTTPHeaders = {};
            
                headerParameters['Content-Type'] = 'application/json';
            
                if (this.configuration && this.configuration.accessToken) {
                    const token = this.configuration.accessToken;
                    const tokenString = await token("token", []);
            
                    if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                    }
                }
                const response = await this.request({
                    path: `/api/users/{user_id}/localities`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))),
                    method: 'PUT',
                    headers: headerParameters,
                    query: queryParameters,
                    body: LocalityIdListModelToJSON(requestParameters.body),
                }, initOverrides);
            
                return new runtime.JSONApiResponse(response, (jsonValue) => SuccessResponseFromJSON(jsonValue));
            },
            mutationKey: [
                'UserManagementApi',
                'setUserLocalities', 
            ],
            queryKey: (requestParameters: SetUserLocalitiesRequest, initOverrides?: RequestInit) => ([
                    ...this.setUserLocalities.mutationKey, 
                {
                userId: requestParameters.userId,
                body: requestParameters.body,
                }
            ]),
            query: (requestParameters: SetUserLocalitiesRequest, initOverrides?: RequestInit): UseQueryOptions<SuccessResponse> & {queryKey: QueryKey} => ({
                queryFn: () => this.setUserLocalities(requestParameters, initOverrides),
                queryKey: this.setUserLocalities.queryKey(requestParameters, initOverrides)
            })
        }
    )

    /**
     * Change the password for any user.
     */
    setUserPassword = Object.assign(
        async (requestParameters: SetUserPasswordRequest, initOverrides?: RequestInit): Promise<SuccessResponse> => {
            const response = await this.setUserPassword.invokeRaw(requestParameters, initOverrides);
            return await response.value();
        },
        {
            invokeRaw: async (requestParameters: SetUserPasswordRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SuccessResponse>> => {
                if (requestParameters.userId === null || requestParameters.userId === undefined) {
                    throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling setUserPassword.');
                }
            
                if (requestParameters.body === null || requestParameters.body === undefined) {
                    throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling setUserPassword.');
                }
            
                const queryParameters: any = {};
            
                const headerParameters: runtime.HTTPHeaders = {};
            
                headerParameters['Content-Type'] = 'application/json';
            
                if (this.configuration && this.configuration.accessToken) {
                    const token = this.configuration.accessToken;
                    const tokenString = await token("token", []);
            
                    if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                    }
                }
                const response = await this.request({
                    path: `/api/users/{user_id}/password`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))),
                    method: 'PUT',
                    headers: headerParameters,
                    query: queryParameters,
                    body: PasswordResetRequestBodyToJSON(requestParameters.body),
                }, initOverrides);
            
                return new runtime.JSONApiResponse(response, (jsonValue) => SuccessResponseFromJSON(jsonValue));
            },
            mutationKey: [
                'UserManagementApi',
                'setUserPassword', 
            ],
            queryKey: (requestParameters: SetUserPasswordRequest, initOverrides?: RequestInit) => ([
                    ...this.setUserPassword.mutationKey, 
                {
                userId: requestParameters.userId,
                body: requestParameters.body,
                }
            ]),
            query: (requestParameters: SetUserPasswordRequest, initOverrides?: RequestInit): UseQueryOptions<SuccessResponse> & {queryKey: QueryKey} => ({
                queryFn: () => this.setUserPassword(requestParameters, initOverrides),
                queryKey: this.setUserPassword.queryKey(requestParameters, initOverrides)
            })
        }
    )

    /**
     * Set the role of a user.
     */
    setUserRole = Object.assign(
        async (requestParameters: SetUserRoleRequest, initOverrides?: RequestInit): Promise<SuccessResponse> => {
            const response = await this.setUserRole.invokeRaw(requestParameters, initOverrides);
            return await response.value();
        },
        {
            invokeRaw: async (requestParameters: SetUserRoleRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SuccessResponse>> => {
                if (requestParameters.userId === null || requestParameters.userId === undefined) {
                    throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling setUserRole.');
                }
            
                if (requestParameters.body === null || requestParameters.body === undefined) {
                    throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling setUserRole.');
                }
            
                const queryParameters: any = {};
            
                const headerParameters: runtime.HTTPHeaders = {};
            
                headerParameters['Content-Type'] = 'application/json';
            
                if (this.configuration && this.configuration.accessToken) {
                    const token = this.configuration.accessToken;
                    const tokenString = await token("token", []);
            
                    if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                    }
                }
                const response = await this.request({
                    path: `/api/users/{user_id}/role`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))),
                    method: 'PUT',
                    headers: headerParameters,
                    query: queryParameters,
                    body: SetUserRoleRequestBodyToJSON(requestParameters.body),
                }, initOverrides);
            
                return new runtime.JSONApiResponse(response, (jsonValue) => SuccessResponseFromJSON(jsonValue));
            },
            mutationKey: [
                'UserManagementApi',
                'setUserRole', 
            ],
            queryKey: (requestParameters: SetUserRoleRequest, initOverrides?: RequestInit) => ([
                    ...this.setUserRole.mutationKey, 
                {
                userId: requestParameters.userId,
                body: requestParameters.body,
                }
            ]),
            query: (requestParameters: SetUserRoleRequest, initOverrides?: RequestInit): UseQueryOptions<SuccessResponse> & {queryKey: QueryKey} => ({
                queryFn: () => this.setUserRole(requestParameters, initOverrides),
                queryKey: this.setUserRole.queryKey(requestParameters, initOverrides)
            })
        }
    )

    /**
     * Change an existing user account.
     */
    updateUser = Object.assign(
        async (requestParameters: UpdateUserRequest, initOverrides?: RequestInit): Promise<UserResponse> => {
            const response = await this.updateUser.invokeRaw(requestParameters, initOverrides);
            return await response.value();
        },
        {
            invokeRaw: async (requestParameters: UpdateUserRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<UserResponse>> => {
                if (requestParameters.userId === null || requestParameters.userId === undefined) {
                    throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling updateUser.');
                }
            
                if (requestParameters.body === null || requestParameters.body === undefined) {
                    throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling updateUser.');
                }
            
                const queryParameters: any = {};
            
                const headerParameters: runtime.HTTPHeaders = {};
            
                headerParameters['Content-Type'] = 'application/json';
            
                if (this.configuration && this.configuration.accessToken) {
                    const token = this.configuration.accessToken;
                    const tokenString = await token("token", []);
            
                    if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                    }
                }
                const response = await this.request({
                    path: `/api/users/{user_id}`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))),
                    method: 'PUT',
                    headers: headerParameters,
                    query: queryParameters,
                    body: UserModelToJSON(requestParameters.body),
                }, initOverrides);
            
                return new runtime.JSONApiResponse(response, (jsonValue) => UserResponseFromJSON(jsonValue));
            },
            mutationKey: [
                'UserManagementApi',
                'updateUser', 
            ],
            queryKey: (requestParameters: UpdateUserRequest, initOverrides?: RequestInit) => ([
                    ...this.updateUser.mutationKey, 
                {
                userId: requestParameters.userId,
                body: requestParameters.body,
                }
            ]),
            query: (requestParameters: UpdateUserRequest, initOverrides?: RequestInit): UseQueryOptions<UserResponse> & {queryKey: QueryKey} => ({
                queryFn: () => this.updateUser(requestParameters, initOverrides),
                queryKey: this.updateUser.queryKey(requestParameters, initOverrides)
            })
        }
    )

}
