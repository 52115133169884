export const determinePhoneNumberCountryCode = (language?: string, phoneNumber?: string) => {
    if (phoneNumber) {
        const frenchNumber = phoneNumber.match(/\+33/gi)?.[0] ?? undefined

        const czechNumber = phoneNumber.match(/\+420/gi)?.[0] ?? undefined

        if (frenchNumber) {
            return '+33'
        }

        if (czechNumber) {
            return '+420'
        }
    }

    switch (language) {
        case 'fr':
            return '+33'
        default:
            return '+420'
    }
}

export const subtractPhoneNumberCountryCode = (phoneNumber?: string) =>
    phoneNumber?.replace(new RegExp(/(\+33|\+420)/, 'gi'), '')
