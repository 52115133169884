import produce from 'immer'
import React, { useState } from 'react'
import { Form, Row, Col } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { MutationStatus } from 'react-query'

import { ErrorEntry } from '@helpers/types'
import { isEmailValid, isAddressHttps } from '@helpers/validations'

import AsyncButton from '@components/AsyncButton'
import TextInput from '@components/GenericInputs/TextInput'
import { useNotify } from '@components/notifications/NotificationsContext'

interface Errors {
    apiUrl: ErrorEntry
    email: ErrorEntry
    password: ErrorEntry
    [other: string]: ErrorEntry
}

interface Props {
    onSubmit: (apiUrl: string, email: string, password: string) => void
    loginState: MutationStatus
    initialApiUrl?: string
}

const ExternalLoginForm = (props: Props) => {
    const { t } = useTranslation()

    const [apiUrl, setApiUrl] = useState(props.initialApiUrl || 'https://')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [errors, setErrors] = useState<Errors>({
        apiUrl: {
            isInvalid: Boolean(props.initialApiUrl),
            isTouched: false,
        },
        email: {
            isInvalid: true,
            isTouched: false,
        },
        password: {
            isInvalid: true,
            isTouched: false,
        },
    })

    const notify = useNotify()

    const submit: React.FormEventHandler = (e) => {
        e.preventDefault()

        setErrors((prev) => produce(prev, (draft) => Object.keys(draft).forEach((k) => (draft[k].isTouched = true))))

        if (errors.apiUrl.isInvalid || errors.email.isInvalid || errors.password.isInvalid) {
            notify({
                title: t('notification.invalidInput', 'Invalid input'),
                content: t(
                    'notification.fillRequiredFields',
                    'Please, fill in all required fields with valid data so the login can be successful.'
                ),
                variant: 'warning',
            })

            return
        }

        props.onSubmit(apiUrl, email, password)

        setEmail('')
        setApiUrl(props.initialApiUrl || 'https://')
        setPassword('')
    }

    const handleSetApiUrl = (value: string) => {
        setErrors((prev) =>
            produce(prev, (draft) => {
                draft.apiUrl = {
                    isInvalid: value === '' || !isAddressHttps(value),
                    isTouched: true,
                }
            })
        )
        setApiUrl(value)
    }

    const handleSetEmail = (value: string) => {
        setErrors((prev) =>
            produce(prev, (draft) => {
                draft.email = {
                    isInvalid: value === '' || !isEmailValid(value),
                    isTouched: true,
                }
            })
        )
        setEmail(value)
    }

    const handleSetPassword = (value: string) => {
        setErrors((prev) =>
            produce(prev, (draft) => {
                draft.password = {
                    isInvalid: value === '',
                    isTouched: true,
                }
            })
        )
        setPassword(value)
    }

    return (
        <Form onSubmit={submit}>
            <Form.Group as={Row}>
                <Form.Label column={true} sm={2}>
                    API URL
                </Form.Label>
                <Col sm={10}>
                    <TextInput
                        isInvalid={errors.apiUrl.isInvalid && errors.apiUrl.isTouched}
                        value={apiUrl}
                        onChange={handleSetApiUrl}
                    />
                </Col>
            </Form.Group>
            <Form.Group as={Row}>
                <Form.Label column={true} sm={2}>
                    E-mail
                </Form.Label>
                <Col sm={10}>
                    <TextInput
                        isInvalid={errors.email.isInvalid && errors.email.isTouched}
                        value={email}
                        onChange={handleSetEmail}
                    />
                </Col>
            </Form.Group>
            <Form.Group as={Row}>
                <Form.Label column={true} sm={2}>
                    {t('form.password', 'Password')}
                </Form.Label>
                <Col sm={10}>
                    <Form.Control
                        isInvalid={errors.password.isInvalid && errors.password.isTouched}
                        type="password"
                        value={password}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleSetPassword(e.target.value)}
                    />
                </Col>
            </Form.Group>
            <Form.Group as={Row}>
                <Col sm={{ span: 10, offset: 2 }}>
                    <AsyncButton
                        loadingText={t('loginPage.loggingIn', 'Logging in...')}
                        status={props.loginState}
                        text={t('button.login', 'Log in')}
                        type="submit"
                        variant="primary"
                    />
                </Col>
            </Form.Group>
        </Form>
    )
}

export default ExternalLoginForm
