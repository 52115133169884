import React from 'react'
import { Trans } from 'react-i18next'

const TermsOfSale = () => {
    return (
        <>
            <Trans i18nKey="termsAndConditions.termsOfSale">
                <h1 id="terms-of-sale">Terms of Sale</h1>
                <p>What Should I Know Before I Order?</p>
                <h2 id="preamble">Preamble</h2>
                <h3 id="welcome-to-the-family">Welcome to the family!</h3>
                <p>
                    If you are reading this document, you are about to become a part of the Vividi family. While we pack
                    your Vividi device and prepare it for dispatch, please read through these Terms of Sale governing
                    your purchase and use of the Vividi device and related accessories (also known collectively as the “
                    <strong>Vividi Box</strong>”).
                </p>
                <p>We value your time, so we promise to keep this as brief as possible.</p>
                <p>
                    If you are comfortable with and agree with everything you read, you may then go ahead and place your
                    order (accept our quote). By placing your order, you let us know that you have agreed to these Terms
                    of Sale.
                </p>
                <p>
                    <strong>Please be aware that these Terms of Sale apply only to commercial (B2B) purchases</strong>
                    (including purchases made by entrepreneurs, businesses, legal entities, government and governmental
                    bodies or other public-sector customers)
                    <strong>
                        and are not meant for consumers, so if you are one, please let us know and we will send you
                        different terms of sale that do apply to you.
                    </strong>
                </p>
                <p>
                    Also, please keep in mind that if you do not buy your Vividi device directly from us, these Terms of
                    Sale do not apply and you must contact the seller (usually a reseller or distributor) for more
                    information on the terms of your purchase.
                </p>
                <p>So, without further ado, let's get into it:</p>
                <h2 id="parties">Parties</h2>
                <h3 id="lets-introduce-ourselves.">Let's introduce ourselves.</h3>
                <p>
                    We are a commercial company called Iterait a.s., ID No. 074&nbsp;05&nbsp;821, incorporated and
                    existing under the laws of the Czech Republic. Our legal address is Hybernská 1034/5, 110 00 Prague
                    1, Czech Republic; we are registered in the commercial register kept by the Municipal Court in
                    Prague under file No. B 23719.
                </p>
                <h3 id="okay-now-a-little-bit-about-you">Okay, now a little bit about “you”:</h3>
                <p>
                    When we refer to “you” in these Terms of Sale, we are actually referring to anybody, whether
                    individual or legal entity, who purchases the Vividi Box.
                </p>
                <p>
                    It probably goes without saying, but your legal capacity must not be limited to the extent that
                    could in any way prevent you from purchasing the Vividi Box or from effectively agreeing to and
                    complying with these Terms of Sale.
                </p>
                <p>
                    If you plan to purchase the Vividi Box on behalf of any other person, whether individual or legal
                    entity, you must obviously have the legal authority to do so.
                </p>
                <p>
                    As mentioned above, these Terms of Sale do not apply to consumers, so if you accept these Terms of
                    Sale, you confirm that you are
                    <strong>not</strong> purchasing the Vividi Box as a consumer.
                </p>
                <p>
                    If you are not sure, just ask yourself if you are an individual purchasing the Vividi Box for a
                    purpose that is outside your trade, business or profession. If the answer is “yes,” then you are
                    most likely considered a consumer, and in that case, you should contact us immediately and we will
                    send you different terms of sale that do apply to you.
                </p>
                <h2 id="order">Order</h2>
                <h3 id="how-do-i-purchase-the-vividi-box">How do I purchase the Vividi Box?</h3>
                <p>
                    If you wish to purchase our products, including the Vividi Box, you should start by contacting us.
                    You may use basically any means of communication, including email and the contact form on our
                    website (
                    <a href="https://www.iterait.com/">
                        <span>https://www.iterait.com/</span>
                    </a>
                    ).
                </p>
                <p>
                    Once we get in touch with you, we will discuss available possibilities and recommend a solution that
                    in our opinion best suits your needs. We will also send you <strong>a quote</strong> (legally
                    speaking, an offer to conclude a contract of purchase) for the recommended products.
                </p>
                <p>
                    In the quote we will list products, their quantities, their prices, your shipping address, a method
                    of shipping (including a specific carrier), estimated shipping charges, and the method of payment
                    (including currency). We will also let you know when the quote expires.
                </p>
                <p>
                    You may then freely decide to place or not to place your order (legally speaking, to accept our
                    offer to conclude a contract of purchase and sale), referencing our quote. If you place your order
                    before the quote expires, the contract of purchase and sale are concluded.
                </p>
                <p>
                    If you place your order after the quote expires, we may still choose to consider your acceptance as
                    valid within seven (7) days after we have received it. If you do not hear from us by then, consider
                    your acceptance void.
                </p>
                <h3 id="can-the-contract-be-changed-later">Can the contract be changed later?</h3>
                <p>
                    Once the contract is concluded, it cannot be unilaterally changed except in the events we describe
                    in these Terms of Sale.
                </p>
                <p>
                    We are fair to our customers, so we do not cancel the contracts unless there are some very
                    extraordinary circumstances, including instances involving an obvious pricing, typographical, or any
                    other obvious error that affected the order, or instances in which you have violated any applicable
                    laws in relation with the contract (e.g., if you have ordered on behalf of others without their
                    authorization, misused personal data of others, misused funds of others to pay for the order, etc.).
                    In such cases we reserve the right to declare the contract void.
                </p>
                <h2 id="delivery">Delivery</h2>
                <h3 id="how-and-when-do-i-receive-the-vividi-box">How and when do I receive the Vividi Box?</h3>
                <p>
                    According to the law, under the purchase agreement we undertake to deliver the ordered products to
                    you and enable you to acquire the title to them; on the other hand, you undertake to duly receive
                    the products from us and pay us the purchase price.
                </p>
                <p>Now, practically:</p>
                <p>
                    After you accept the quote and the contract of purchase is concluded, we will without undue delay or
                    within the time stipulated in the contract dispatch the ordered products to you using the shipping
                    method quoted.
                </p>
                <p>
                    If the quoted shipping method is not available anymore or if the shipping costs have risen in the
                    meantime, we will contact you and offer you alternate shipping. If you do not agree, we have the
                    right to withdraw from the contract.
                </p>
                <p>
                    If we provide you with any dispatch or delivery dates, please be aware that they are estimates only
                    and we cannot be responsible for any delayed delivery.
                </p>
                <p>
                    Products are considered as delivered to you when they are handed over to the carrier. We enable you
                    to claim any rights implied by the shipping agreement with the carrier.
                </p>
                <p>
                    Unless agreed otherwise in the contract of purchase (that means in the quote), we reserve the right
                    to dispatch, at our discretion, the goods to you only after the purchase price is paid in full.
                </p>
                <p>
                    We also reserve the right of ownership to the products (legally speaking, the title) until the
                    purchase price has been paid in full.
                </p>
                <h2 id="price-and-payment">Price and Payment</h2>
                <h3 id="lets-talk-money.">Let's talk money.</h3>
                <p>
                    The purchase price and the estimated shipping fees always will be quoted to you before the contract
                    is concluded. The quoted purchase prices are always final, and if not stated otherwise they always
                    exclude VAT and any other taxes, fees (including shipping fees), duties and dues. The charges are to
                    be paid by (international) wire transfer pursuant to the invoice we send you, and the payment must
                    be made in the quoted currency.
                </p>
                <p>
                    You must pay our invoice in full within the time noted thereon, or if not noted, then within thirty
                    (30) days after the date of the invoice. All invoices will be considered as accurate unless you
                    advise us in writing of any errors within ten (10) days following the receipt thereof.
                </p>
                <p>
                    If any amounts are corrected, they must be paid within fourteen (14) days of correction, and all
                    other amounts shall be paid by the original due date of the invoice.
                </p>
                <p>Please be aware that once the contract is concluded, the billing details may not be changed.</p>
                <h3 id="taxes-duties-and-other-payments">Taxes, duties and other payments:</h3>
                <p>
                    You are responsible for payment of any sales, use, value added, and other similar taxes or
                    governmental fees associated with your order, except for taxes based on our net income, our gross
                    revenue, or our employment obligations.
                </p>
                <p>
                    You must also pay for any necessary shipping costs, insurance, and import or export duties (if
                    applicable), even if they were not quoted in the quote we sent you.
                </p>
                <p>
                    If we have to under the applicable law collect and remit any taxes or fees, then we will add the
                    appropriate amount to your invoice as a separate line item. If you qualify for a tax exemption, you
                    must provide us with a valid certificate of exemption or other appropriate proof of exemption.
                </p>
                <h2 id="confidentiality">Confidentiality</h2>
                <h3 id="we-kindly-ask-you-to-be-discrete.">We kindly ask you to be discrete.</h3>
                <p>
                    We try to be open to our customers and share as much information about our products with you as
                    possible so you can make informed decisions. Sometimes we share with you information that is not
                    meant to be public. For that reason, we must ask you to keep confidential (not to disclose to third
                    parties) the specifics of the concluded contract, including any information you have learned through
                    the contracting process, as well as any other information that you have learned from us and that was
                    marked, accompanied, or supported by documents designated as “confidential” or the equivalent, or
                    that was identified as such by us, or that should be reasonably known to you to be confidential.
                </p>
                <p>
                    Of course this does not apply to information you can demonstrate was already in your possession
                    before receipt from us, that is or becomes publicly available through no fault of yours, that is
                    rightfully received by you from a third party who has no duty of confidentiality, or that is
                    independently developed by you without a breach of the confidentiality obligations hereunder.
                </p>
                <p>
                    Sometimes you may be required by a government body or court of law to disclose confidential
                    Information. That's fine by us, but we must ask you, to the extent permitted by law, to give us
                    reasonable advance notice so that we may contest the disclosure or seek a protective order.
                </p>
                <p>
                    You may disclose confidential Information to your directors, officers, and employees, as well as to
                    your contractors, advisors, and agents, so long as those individuals have a need to know in their
                    work for you and are bound by obligations of confidentiality at least as restrictive as those
                    imposed on you in this Terms of Sale. You are fully liable for any breach of this obligation by the
                    above mentioned third parties.
                </p>
                <p>
                    You will use the same degree of care to protect the confidential information as you would with your
                    own similar information, and certainly no less than what is reasonable.
                </p>
                <p>
                    Your duty to protect confidential Information expires three (3) years from the date of disclosure.
                    The obligation to protect technical information about our current products and services and all
                    information about possible unreleased products or services never expires.
                </p>
                <h2 id="personal-data">Personal Data</h2>
                <h3 id="are-my-personal-data-safe-with-you">Are my personal data safe with you?</h3>
                <p>
                    First let us assure you that the collection of your personal data (e.g., the data submitted when
                    placing your order or when notifying us of a defect) shall be as limited as possible.
                </p>
                <p>
                    Any collection, storage and handling of your personal data shall be controlled by a separate set of
                    terms on handling the personal data of our customers (Vividi Privacy Policy).
                </p>
                <h2 id="software-license">Software License</h2>
                <h3 id="do-i-need-any-software-to-use-the-vividi-box">Do I need any software to use the Vividi Box?</h3>
                <p>
                    The Vividi Box comes with embedded software (firmware), the use of which is subject to the Vividi
                    Box End User License Agreement available on our website (
                    <a href="https://www.iterait.com/">
                        <span>https://www.iterait.com/</span>
                    </a>
                    ).
                </p>
                <p>
                    If you provide the Vividi Box to any third party, you are obliged to provide them with a copy of the
                    End User License Agreement or to let them know where to find it.
                </p>
                <h3>Some things we just do not sell.</h3>
                <p>
                    Please be aware that you cannot use any of our trademarks, trade names, company logos, etc., by
                    virtue of having purchased our products hereunder.
                </p>
                <h2 id="defects">Defects</h2>
                <h3 id="what-should-i-do-if-my-device-is-broken">What should I do if my device is broken?</h3>
                <p>
                    First let us mention that a risk of loss passes to you when the Vividi Box is delivered. This means
                    that if something happens to your device after the delivery, we may not be held responsible.
                </p>
                <p>
                    On the other hand, we obviously guarantee that the Vividi Box is free from defects at the time it is
                    delivered.
                </p>
                <p>
                    In particular, we guarantee to you that at the moment of delivery, the Vividi Box (i) has the agreed
                    characteristics or (if nothing was agreed) the characteristics described by us or expected by you
                    with reference to its nature and related advertisement, (ii) can be used for the purposes stated by
                    us, (iii) is provided in the corresponding quantity, and (iv) complies with the requirements
                    stipulated by the law.
                </p>
                <p>
                    We advise you to examine the product, its characteristics, and quantity as soon as possible after
                    the risk of loss passes to you (usually at the moment the product is handed to you by the carrier).
                    If the shipment appears to be damaged, opened or otherwise tampered with, you must describe the
                    damage in the delivery note of the carrier at the time it is handed to you; otherwise the shipment
                    is considered undamaged.
                </p>
                <p>
                    You can claim improper performance with reference to the defect inherent to the product at the
                    moment of delivery without undue delay after it is discovered, or could have been discovered, had
                    you duly examined the product.
                </p>
                <p>
                    The defects that could not have been identified at the time of delivery but appear later may be
                    claimed within twenty-four (24) months after the delivery.
                </p>
                <p>
                    We do not offer any other statutory guarantees or commercial warranties other than expressly
                    described above.
                </p>
                <h2 id="liability">Liability</h2>
                <h3 id="so-where-are-all-the-other-legal-disclaimers">
                    So, where are all the other legal disclaimers?
                </h3>
                <p>You have just found them, so let's get it over with.</p>
                <p>
                    Our total liability to you for any direct, indirect, special, consequential, punitive, or other
                    damages and costs, including but not limited to loss of profit, loss of revenue, loss of business
                    opportunity arising out of or in connection with your purchase and use or inability to use the
                    Vividi Box, is under all circumstances limited to the amount you have paid for the product that gave
                    rise to the liability.
                </p>
                <p>
                    Under certain laws it may not be possible to disclaim our liability and warranties completely. In
                    such cases, we hereby disclaim our liability and warranties to the fullest extent permissible by
                    such law.
                </p>
                <h2 id="governing-law-and-choice-of-court">Governing Law and Choice of Court</h2>
                <h3 id="what-laws-do-apply">What laws do apply?</h3>
                <p>
                    The laws of the Czech Republic, without regard to principles of conflict of laws, will control these
                    Terms of Sale and any dispute of any sort that might arise between you and us in connection herewith
                    (including any claims under any liability theory).
                </p>
                <p>The United Nations Convention on Contracts for the International Sale of Goods does not apply.</p>
                <h3 id="what-if-we-argue">What if we argue?</h3>
                <p>
                    We always operate in a customer-friendly manner and attempt to solve any issues peacefully, and we
                    ask you to do the same. Therefore, you agree to notify us of any disputes arising out of or in
                    connection with these Terms of Sale and you agree that you will always attempt an out-of-court
                    dispute resolution before you bring the dispute before the courts.
                </p>
                <p>
                    However, sometimes out-of-court dispute resolution is just not possible. In that event, any dispute
                    relating in any way to these Terms of Sale shall be decided with final effect before the courts of
                    the Czech Republic, in particular before the District Court for Prague 8 and (in the event that the
                    regional court decides as a first instance court) before the Municipal Court in Prague.
                </p>
                <p>
                    Should you violate or threaten to violate our intellectual property rights in any manner, we may
                    seek injunctive or other appropriate relief in any court of our choice. You consent to exclusive
                    jurisdiction and venue of such courts.
                </p>
                <h2 id="notices">Notices</h2>
                <h3 id="how-do-i-get-in-touch-with-you-and-what-if-you-need-to-contact-me">
                    How do I get in touch with you? And what if you need to contact me?
                </h3>
                <p>
                    You can contact us electronically via tools available on our website or via the email address
                    hello@iterait.com. Your message is considered as delivered when we confirm its delivery to you,
                    which we always attempt to do promptly.
                </p>
                <p>
                    We will not bother you too much, but occasionally we may have to contact you. In that event, we will
                    use the email address provided by you.
                </p>
                <p>
                    We will occasionally send you an email with commercial announcements; you may at any time without
                    having to give a reason opt out of those communications.
                </p>
                <h2 id="miscellaneous">Miscellaneous</h2>
                <h3 id="some-necessary-boilerplates">Some necessary boilerplates:</h3>
                <p>
                    If any clause of these Terms of Sale is determined to be illegal, invalid or unenforceable, in whole
                    or in part, under any law, the legality, validity and enforceability of the other clauses hereof
                    shall not be affected. In the event that any clause or part thereof is determined to be illegal,
                    invalid or unenforceable, that clause shall be replaced by the parties with a legal, valid and
                    enforceable clause that has, to the greatest extent possible, a similar effect to the illegal,
                    invalid or unenforceable clause, given the content and purpose of these Terms of Sale.
                </p>
                <p>
                    You may not assign or transfer any of your rights or obligations assumed under these Terms of Sale
                    or in any other way related hereto without our prior written consent.
                </p>
                <p>
                    If you do not comply with these Terms of Sale and we do not take action right away, or if we do not
                    enforce any other provision hereof, it doesn’t mean that we are giving up any rights that we may
                    have (such as taking action in the future).
                </p>
                <p>
                    All the provisions of these Terms of Sale that by their nature extend beyond the termination of the
                    mutual legal relationship, including but not limited to dispute resolution and applicable law
                    clauses, shall survive the termination of the mutual legal relationship.
                </p>
                <h3 id="i-do-not-like-something-i-read-above.">I do not like something I read above.</h3>
                <p>
                    We always attempt to make legal documents as brief and user friendly as possible, but we cannot
                    completely make do without them.
                </p>
                <p>
                    So, even though we regret it, if you do not agree with any part of these Terms of Sale, we cannot
                    sell you the Vividi Box. If that is the case, please contact us, and we will do our best to find a
                    suitable solution for you.
                </p>
                <h3 id="i-found-out-there-is-a-conflict-between-this-document-and-the-terms-i-found-elsewhere.">
                    I found out there is a conflict between this document and the terms I found elsewhere.
                </h3>
                <p>
                    If there is such a conflict, please let us know immediately so we can attend to it and improve our
                    documentation. Meanwhile, these Terms of Sale will control for that conflict.
                </p>
                <p>
                    In the event that you as an entrepreneur use your own set of terms and conditions, those do not
                    apply to our mutual legal relationship, unless we have expressly agreed to them in writing.
                </p>
                <h3 id="so-is-that-it-may-i-go-ahead-and-place-my-order-accept-your-quote">
                    So, is that it? May I go ahead and place my order (accept your quote)?
                </h3>
                <p>
                    Just one last thing: Please remember that we may change or amend these Terms of Sale or any
                    additional terms that apply to your purchase and use of our products (such as the Vividi Privacy
                    Policy) from time to time, and if we do so, we will have to ask you to read through them and agree
                    to them again.
                </p>
                <p>We realize it's an inconvenience for you, so we promise not to do it too often.</p>
                <h2 id="use-of-vividi-box">Use of Vividi Box</h2>
                <h3 id="i-already-know-what-vividi-is-but-remind-me-just-in-case.">
                    I already know what Vividi is, but remind me just in case.
                </h3>
                <p>
                    Vividi is an IT solution provided by our company that allows you to obtain important statistics and
                    information about the demographics and behavior of customers in your establishment. To list a few
                    examples, Vividi may be able to identify the duration of a customer´s stay, their age or gender.
                </p>
                <h3 id="so-how-does-it-work">So, how does it work?</h3>
                <p>
                    The easiest way is to purchase the hardware called Vividi Box, developed by our company. The Vividi
                    Box monitors your establishment and collects the above-mentioned data about your customers.
                </p>
                <p>
                    You may then access the collected data in Vividi Dashboard, accessible on our website (
                    <a href="https://iterait.com/">
                        <span>https://iterait.com/</span>
                    </a>
                    ).
                </p>
                <p>
                    The use of Vividi Dashboard is controlled by the Vividi Dashboard Terms of Service and may be
                    subject to additional fees.
                </p>
                <h3 id="therefore-be-aware-that-to-be-able-to-fully-use-the-vividi-box-you-may-have-to-use-our-vividi-dashboard-paid-services.">
                    Therefore, be aware that to be able to fully use the Vividi Box, you may have to use our Vividi
                    Dashboard paid services.
                </h3>
                <p>
                    If for any reason you do not wish to use Vividi Dashboard, there are ways to use the Vividi Box
                    without it. However, please be aware that it might be difficult to set up your own front-end
                    solution and that we do not and may not guarantee full functionality of the Vividi Box device under
                    such circumstances. Similarly, we do not guarantee full functionality if you use the Vividi Box with
                    any third-party services.
                </p>
                <p>
                    Of course, if you need any help in this regard, do not hesitate to contact us, as we may be able to
                    offer you a personal solution that suits your needs.
                </p>
                <h3 id="is-there-anything-else-i-should-know-about-when-using-the-vividi-box">
                    Is there anything else I should know about when using the Vividi Box?
                </h3>
                <p>
                    Yes, be aware that use of video-recording devices in your establishment may be subject to personal
                    data protection regulations that apply in your country. Before you start using any video-recording
                    product or service to monitor your customers, please make sure that you are compliant with such laws
                    and regulations in order to prevent any problems down the road.
                </p>
                <p>
                    In Prague on November 26<sup>th</sup> , 2019
                    <br />
                    Iterait a.s.
                </p>
            </Trans>
        </>
    )
}

export default TermsOfSale
