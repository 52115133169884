import { noop } from 'lodash'
import { DateTime, Interval } from 'luxon'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { useHistory } from 'react-router-dom'

import { LocalityNameModel } from '@api'

import { statisticsApi } from '@services'

import { generateOccupancyDetailsPath, generateOccupancyDetailsCustomPath } from '@helpers/VividiURLs'
import { mergeQueryResults } from '@helpers/api'
import { IntervalType, ZoneOccupancyGranularity } from '@helpers/types'

import DashboardParamsNormalizer, { NormalizedDashboardParams } from '@components/DashboardParamsNormalizer'
import DetailsLayout from '@components/DetailsLayout'
import ErrorView from '@components/ErrorView'
import Helmet from '@components/Helmet'
import LegacyLoadingWrapper from '@components/LegacyLoadingWrapper'
import LoadingSpinner from '@components/LoadingSpinner'
import OccupancyDetailsContent from '@components/OccupancyDetails/OccupancyDetailsContent'
import { OccupancyDetailsExportButton } from '@components/OccupancyDetails/OccupancyDetailsExportButton'
import { useZoneOccupancyData } from '@components/ZoneOccupancyStatistics/zoneOccupancyData'

const OccupancyDetailsPage: React.FC<NormalizedDashboardParams> = ({ locality, interval, intervalType }) => {
    const { t } = useTranslation()
    const history = useHistory()
    const [now] = useState(DateTime.utc())

    const zoneOccupancyDataCall = useZoneOccupancyData(interval, locality.id, ZoneOccupancyGranularity.HOUR)

    const zoneOccupancyQuarterHourDataCall = useZoneOccupancyData(
        interval,
        locality.id,
        ZoneOccupancyGranularity.QUARTER_HOUR
    )

    const zoneOccupancySessionsCall = useQuery(
        statisticsApi.getZoneOccupancySessions.query({
            body: {
                startingFrom: interval.start.toISO(),
                endingAt: interval.end.toISO(),
                localityId: locality.id,
            },
        })
    )

    const handleIntervalStartChange = (intervalStart: DateTime) => {
        history.push(generateOccupancyDetailsPath(locality.id, intervalStart, intervalType))
    }

    const handleIntervalTypeChange = (newIntervalType: IntervalType) => {
        history.push(generateOccupancyDetailsPath(locality.id, interval.start, newIntervalType))
    }

    const handleLocalityChange = (locality: LocalityNameModel) => {
        const isCustomIntervalSelected = intervalType === IntervalType.CUSTOM && interval.start && interval.end

        if (isCustomIntervalSelected) {
            return history.push(generateOccupancyDetailsCustomPath(locality.id, interval.start, interval.end))
        }

        return history.push(generateOccupancyDetailsPath(locality.id, interval.start, intervalType))
    }

    const handleCustomIntervalChange = (interval: Interval) => {
        history.push(generateOccupancyDetailsCustomPath(locality.id, interval.start, interval.end))
    }

    const documentTitle = t('title.occupancyStatistics', 'Occupancy Statistics')

    return (
        <>
            <Helmet>
                <title>{documentTitle}</title>
            </Helmet>
            <LegacyLoadingWrapper
                errorComponent={
                    <DetailsLayout
                        containerWidth="1400px"
                        intervalType={intervalType}
                        organizationFilter={(org) => org.features.zoneOccupancy}
                        pickedInterval={interval}
                        selectedLocality={locality}
                        onCustomIntervalChange={handleCustomIntervalChange}
                        onIntervalStartChange={handleIntervalStartChange}
                        onIntervalTypeChange={handleIntervalTypeChange}
                        onLocalityChange={handleLocalityChange}
                    >
                        <ErrorView message={t('others.problemFetchingData', 'There was a problem fetching data.')} />
                    </DetailsLayout>
                }
                placeholder={
                    <DetailsLayout
                        containerWidth="1400px"
                        intervalType={intervalType}
                        organizationFilter={(org) => org.features.zoneOccupancy}
                        pickedInterval={interval}
                        selectedLocality={locality}
                        onCustomIntervalChange={handleCustomIntervalChange}
                        onIntervalStartChange={handleIntervalStartChange}
                        onIntervalTypeChange={handleIntervalTypeChange}
                        onLocalityChange={handleLocalityChange}
                    >
                        <LoadingSpinner />
                    </DetailsLayout>
                }
                request={mergeQueryResults(
                    zoneOccupancyDataCall.request,
                    zoneOccupancySessionsCall,
                    zoneOccupancyQuarterHourDataCall.request
                )}
            >
                {([zoneOccupancyData, zoneOccupancySessions, zoneOccupancyQuarterHourData]) => (
                    <>
                        <Helmet>
                            <title>{`${locality.name} ${documentTitle}`}</title>
                        </Helmet>
                        <DetailsLayout
                            key={locality.id}
                            containerWidth="1400px"
                            intervalType={intervalType}
                            layoutActionButton={
                                zoneOccupancyData !== undefined ? (
                                    <OccupancyDetailsExportButton
                                        pickedInterval={interval}
                                        selectedLocality={locality}
                                        zoneOccupancySessions={zoneOccupancySessions}
                                        zoneOccupancySummary={zoneOccupancyData[0]}
                                    />
                                ) : undefined
                            }
                            organizationFilter={(org) => org.features.zoneOccupancy}
                            pickedInterval={interval}
                            selectedLocality={locality}
                            onCustomIntervalChange={handleCustomIntervalChange}
                            onIntervalStartChange={handleIntervalStartChange}
                            onIntervalTypeChange={handleIntervalTypeChange}
                            onLocalityChange={handleLocalityChange}
                        >
                            {zoneOccupancyQuarterHourData && zoneOccupancyData ? (
                                <OccupancyDetailsContent
                                    interval={interval}
                                    intervalType={intervalType}
                                    locality={locality}
                                    localityInfo={locality}
                                    now={now}
                                    zoneOccupancy={{
                                        scenes: zoneOccupancyData[1],
                                        localities: zoneOccupancyData[2],
                                        zoneOccupancySummary: zoneOccupancyData[0],
                                        outages: zoneOccupancyData[3],
                                        organizations: zoneOccupancyData[4],
                                    }}
                                    zoneOccupancyQuarterHourData={zoneOccupancyQuarterHourData[0]}
                                    zoneOccupancySessions={zoneOccupancySessions}
                                />
                            ) : undefined}
                        </DetailsLayout>
                    </>
                )}
            </LegacyLoadingWrapper>
        </>
    )
}

const OccupancyDetailsParamsNormalizer: React.FC = () => (
    <DashboardParamsNormalizer
        customIntervalPathGenerator={generateOccupancyDetailsCustomPath}
        defaultLocalityPathGenerator={generateOccupancyDetailsPath}
        errorComponent={({ intervalType, interval }) => (
            <DetailsLayout
                containerWidth="1400px"
                intervalType={intervalType}
                organizationFilter={(org) => org.features.zoneOccupancy}
                pickedInterval={interval}
                onCustomIntervalChange={noop}
                onIntervalStartChange={noop}
                onIntervalTypeChange={noop}
                onLocalityChange={noop}
            >
                <ErrorView message="Fetching the list of localities failed" title="Error while fetching data" />
            </DetailsLayout>
        )}
        intervalPathGenerator={generateOccupancyDetailsPath}
        loadingComponent={({ intervalType, interval }) => (
            <DetailsLayout
                containerWidth="1400px"
                intervalType={intervalType}
                organizationFilter={(org) => org.features.zoneOccupancy}
                pickedInterval={interval}
                onCustomIntervalChange={noop}
                onIntervalStartChange={noop}
                onIntervalTypeChange={noop}
                onLocalityChange={noop}
            >
                <LoadingSpinner />
            </DetailsLayout>
        )}
        successComponent={(params) => <OccupancyDetailsPage {...params} />}
    />
)

export default OccupancyDetailsParamsNormalizer
