/* tslint:disable */
/* eslint-disable */
/**
 * Vividi Dashboard API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OpeningHours,
    OpeningHoursFromJSON,
    OpeningHoursFromJSONTyped,
    OpeningHoursToJSON,
} from './OpeningHours';

/**
 * 
 * @export
 * @interface LocalityOpeningHoursResponse
 */
export interface LocalityOpeningHoursResponse {
    /**
     * 
     * @type {number}
     * @memberof LocalityOpeningHoursResponse
     */
    localityId: number;
    /**
     * 
     * @type {OpeningHours}
     * @memberof LocalityOpeningHoursResponse
     */
    openingHours: OpeningHours;
}

export function LocalityOpeningHoursResponseFromJSON(json: any): LocalityOpeningHoursResponse {
    return LocalityOpeningHoursResponseFromJSONTyped(json, false);
}

export function LocalityOpeningHoursResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): LocalityOpeningHoursResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'localityId': json['locality_id'],
        'openingHours': OpeningHoursFromJSON(json['opening_hours']),
    };
}

export function LocalityOpeningHoursResponseToJSON(value?: LocalityOpeningHoursResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'locality_id': value.localityId,
        'opening_hours': OpeningHoursToJSON(value.openingHours),
    };
}

