/* tslint:disable */
/* eslint-disable */
/**
 * Vividi Dashboard API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BackgroundJobModel,
    BackgroundJobModelFromJSON,
    BackgroundJobModelFromJSONTyped,
    BackgroundJobModelToJSON,
} from './BackgroundJobModel';

/**
 * 
 * @export
 * @interface BackgroundJobList
 */
export interface BackgroundJobList {
    /**
     * 
     * @type {Array<BackgroundJobModel>}
     * @memberof BackgroundJobList
     */
    jobs: Array<BackgroundJobModel>;
}

export function BackgroundJobListFromJSON(json: any): BackgroundJobList {
    return BackgroundJobListFromJSONTyped(json, false);
}

export function BackgroundJobListFromJSONTyped(json: any, ignoreDiscriminator: boolean): BackgroundJobList {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'jobs': ((json['jobs'] as Array<any>).map(BackgroundJobModelFromJSON)),
    };
}

export function BackgroundJobListToJSON(value?: BackgroundJobList | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'jobs': ((value.jobs as Array<any>).map(BackgroundJobModelToJSON)),
    };
}

