/* tslint:disable */
/* eslint-disable */
/**
 * Vividi Dashboard API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateCaptureSettingsRequestBody
 */
export interface UpdateCaptureSettingsRequestBody {
    /**
     * 
     * @type {boolean}
     * @memberof UpdateCaptureSettingsRequestBody
     */
    isLongRange?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateCaptureSettingsRequestBody
     */
    detectVehicles?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateCaptureSettingsRequestBody
     */
    upsideDown?: boolean;
}

export function UpdateCaptureSettingsRequestBodyFromJSON(json: any): UpdateCaptureSettingsRequestBody {
    return UpdateCaptureSettingsRequestBodyFromJSONTyped(json, false);
}

export function UpdateCaptureSettingsRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateCaptureSettingsRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'isLongRange': !exists(json, 'is_long_range') ? undefined : json['is_long_range'],
        'detectVehicles': !exists(json, 'detect_vehicles') ? undefined : json['detect_vehicles'],
        'upsideDown': !exists(json, 'upside_down') ? undefined : json['upside_down'],
    };
}

export function UpdateCaptureSettingsRequestBodyToJSON(value?: UpdateCaptureSettingsRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'is_long_range': value.isLongRange,
        'detect_vehicles': value.detectVehicles,
        'upside_down': value.upsideDown,
    };
}

