import { LocalZone, Zone, DateTime, IANAZone } from 'luxon'
import React, { createContext, useContext, useCallback, useMemo, useDebugValue } from 'react'

const local = new LocalZone()
const TimezoneConfigContext = createContext<Zone>(local)

export const TimezoneConfigProvider: React.FC<{ timezone: string }> = ({ timezone, children }) => {
    const zone = useMemo(() => new IANAZone(timezone), [timezone])

    return <TimezoneConfigContext.Provider value={zone}>{children}</TimezoneConfigContext.Provider>
}

export const useTimezoneConfig = (): Zone => {
    const config = useContext(TimezoneConfigContext)
    const zone = config || local

    useDebugValue(zone.name)

    return zone
}

type LocalizeDateTime = (datetime: DateTime) => DateTime

export const useLocalizeDateTime = (): LocalizeDateTime => {
    const zone = useTimezoneConfig()

    return useCallback((datetime) => datetime.setZone(zone), [zone])
}

/**
 * Calculate a UTC timestamp whose local time in the target zone is the same as the local time of the subject in the current zone.
 */
export const migrateTimestamp = (subject: DateTime, currentZone: Zone, targetZone: Zone): DateTime =>
    subject.setZone(currentZone).setZone(targetZone, { keepLocalTime: true }).toUTC()
