import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import produce from 'immer'
import { isEmpty, keyBy } from 'lodash'
import { useCallback, useState } from 'react'
import { Button, Col, Form, Row } from 'react-bootstrap'
import Helmet from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import { useStateWithCallbackLazy } from 'use-state-with-callback'

import {
    MQTTPublisherPreferencesList,
    MQTTPublisherPreferencesItem,
    MQTTPublisherType,
    OrganizationResponse,
    LocalityNameModel,
} from '@api'

import { organizationApi } from '@services'

import { useApiCallCleaner } from '@helpers/api'
import { defaultErrorEntry, ErrorEntry } from '@helpers/types'

import Box from '@elements/Box/Box'
import ToggleButton from '@elements/Buttons/ToggleButton'

import NumberInput from '@components/GenericInputs/NumberInput'
import TextInput from '@components/GenericInputs/TextInput'
import TextareaInput from '@components/GenericInputs/TextareaInput'
import LocalityPicker from '@components/LocalityPicker'
import TooltipIcon from '@components/TooltipIcon'
import { useNotify } from '@components/notifications/NotificationsContext'

import styles from './DataPublishersForm.module.scss'
import DataPublishersTable from './DataPublishersTable'

const initialDataPublisher: MQTTPublisherPreferencesItem = {
    localityId: -1,
    type: MQTTPublisherType.CrowdSize,
    interval: 3,
    topic: '',
    host: '',
    port: 1883,
    certificate: undefined,
    key: undefined,
}

const initialErrorState = {
    localityId: defaultErrorEntry,
    interval: defaultErrorEntry,
    topic: defaultErrorEntry,
    host: defaultErrorEntry,
    port: defaultErrorEntry,
}

interface Errors {
    localityId: ErrorEntry
    interval: ErrorEntry
    topic: ErrorEntry
    host: ErrorEntry
    port: ErrorEntry
}

interface DataPublisherEditFormProps {
    organization: OrganizationResponse
    localities: LocalityNameModel[]
    currentValues: MQTTPublisherPreferencesItem
    onUpdate: (updatedNotification: MQTTPublisherPreferencesItem) => void
    onErrors: (errorData: Errors) => void
    errors: Errors
    isSubmitted: boolean
}

const DataPublisherEditForm = ({
    organization,
    localities,
    currentValues,
    onErrors: handleErrors,
    onUpdate: handleUpdate,
    errors,
    isSubmitted,
}: DataPublisherEditFormProps) => {
    const { t } = useTranslation()

    const pickedLocality = currentValues ? localities.find((l) => l.id === currentValues.localityId) : localities[0]

    return (
        <>
            <Form.Group as={Row}>
                <Col md={3}>
                    <span>{t('form.dataFromLocality', 'Data from locality')}</span>
                </Col>
                <Col md={4}>
                    <LocalityPicker
                        features={[]}
                        localities={localities}
                        organizations={keyBy([organization], (o) => o.id)}
                        selectedLocality={pickedLocality}
                        onSelect={(locality) => {
                            handleUpdate({
                                ...currentValues,
                                localityId: locality.id ?? -1,
                            })

                            handleErrors({
                                ...errors,
                                localityId: {
                                    isTouched: true,
                                    isInvalid: locality.id === undefined || locality.id < 0,
                                },
                            })
                        }}
                    />
                </Col>
            </Form.Group>
            <Form.Group as={Row}>
                <Col md={3}>
                    <span>{t('table.type', 'Type')}</span>
                    <TooltipIcon>
                        {t(
                            'tooltip.publisherDataType',
                            'Data type for publishing, either Stay-Safe or Stay-Safe Floorplan (FP)'
                        )}
                    </TooltipIcon>
                </Col>
                <Col md={4}>
                    <ToggleButton
                        leftToggle={{
                            toggleText: 'Stay-Safe',
                            toggleValue: MQTTPublisherType.CrowdSize.toString(),
                        }}
                        rightToggle={{
                            toggleText: 'Stay-Safe FP',
                            toggleValue: MQTTPublisherType.FloorplanCrowdSize.toString(),
                        }}
                        toggleName="publisherType"
                        toggleValue={currentValues.type.toString()}
                        onToggle={(value) => {
                            handleUpdate({
                                ...currentValues,
                                type:
                                    value === MQTTPublisherType.CrowdSize.toString()
                                        ? MQTTPublisherType.CrowdSize
                                        : MQTTPublisherType.FloorplanCrowdSize,
                            })
                        }}
                    />
                </Col>
            </Form.Group>
            <Form.Group as={Row}>
                <Col md={3}>
                    <span>{t('form.pushInterval', 'Push interval')}</span>
                    <TooltipIcon>
                        {t('tooltip.timeIntervalPush', 'Time interval between data pushes to specified host')}
                    </TooltipIcon>
                </Col>
                <Col md={4}>
                    <div className={styles.inputContainer}>
                        <NumberInput
                            isInvalid={(errors.interval.isTouched || isSubmitted) && errors.interval.isInvalid}
                            name="pushInterval"
                            value={currentValues.interval}
                            onChange={(val) => {
                                handleUpdate({
                                    ...currentValues,
                                    interval: val ?? 1,
                                })

                                handleErrors({
                                    ...errors,
                                    interval: {
                                        isTouched: true,
                                        isInvalid: val === undefined || val < 1,
                                    },
                                })
                            }}
                        />
                        <span className={styles.explanationText}>{t('others.seconds', 'seconds')}</span>
                    </div>
                </Col>
            </Form.Group>

            <Form.Group as={Row}>
                <Col md={3}>
                    <span>
                        {t('form.hostPort', 'Host and port')}
                        <TooltipIcon>{t('tooltip.hostPort', 'Host and port of the MQTT broker')}</TooltipIcon>
                    </span>
                </Col>
                <Col md={4}>
                    <div className={styles.inputContainer}>
                        <TextInput
                            isInvalid={(errors.host.isTouched || isSubmitted) && errors.host.isInvalid}
                            name="host"
                            value={currentValues.host}
                            onChange={(val) => {
                                handleUpdate({
                                    ...currentValues,
                                    host: val ?? '',
                                })

                                handleErrors({
                                    ...errors,
                                    host: {
                                        isTouched: true,
                                        isInvalid: val === undefined || val.length <= 0,
                                    },
                                })
                            }}
                        />
                        <span>:</span>
                        <NumberInput
                            isInvalid={(errors.port.isTouched || isSubmitted) && errors.port.isInvalid}
                            name="port"
                            value={currentValues.port}
                            onChange={(val) => {
                                handleUpdate({
                                    ...currentValues,
                                    port: val ?? 1,
                                })

                                handleErrors({
                                    ...errors,
                                    port: {
                                        isTouched: true,
                                        isInvalid: val === undefined || val < 1,
                                    },
                                })
                            }}
                        />
                    </div>
                </Col>
            </Form.Group>
            <Form.Group as={Row}>
                <Col md={3}>
                    <span>{t('form.topic', 'Topic')}</span>
                    <TooltipIcon>{t('tooltip.mqttTopic', 'MQTT topic to publish the data to')}</TooltipIcon>
                </Col>
                <Col md={4}>
                    <TextInput
                        isInvalid={(errors.topic.isTouched || isSubmitted) && errors.topic.isInvalid}
                        name="topic"
                        value={currentValues.topic}
                        onChange={(val) => {
                            handleUpdate({
                                ...currentValues,
                                topic: val ?? '',
                            })

                            handleErrors({
                                ...errors,
                                topic: {
                                    isTouched: true,
                                    isInvalid: val === undefined || val.length <= 0,
                                },
                            })
                        }}
                    />
                </Col>
            </Form.Group>
            <Form.Group as={Row}>
                <Col md={3}>
                    <span>{t('form.certificate', 'Certificate')}</span>
                    <TooltipIcon>
                        {t('tooltip.certificate', 'SSL certificate string (as generated with openssl command)')}
                    </TooltipIcon>
                </Col>
                <Col md={4}>
                    <TextareaInput
                        name="certificate"
                        value={currentValues.certificate ?? ''}
                        onChange={(val) => {
                            handleUpdate({
                                ...currentValues,
                                certificate: val,
                            })
                        }}
                    />
                </Col>
            </Form.Group>
            <Form.Group as={Row}>
                <Col md={3}>
                    <span>
                        {t('form.key', 'Key')}
                        <TooltipIcon>
                            {t('tooltip.sslKey', 'SSL key string (as generated with openssl command)')}
                        </TooltipIcon>
                    </span>
                </Col>
                <Col md={4}>
                    <TextareaInput
                        name="key"
                        value={currentValues.key ?? ''}
                        onChange={(val) => {
                            handleUpdate({
                                ...currentValues,
                                key: val,
                            })
                        }}
                    />
                </Col>
            </Form.Group>
        </>
    )
}

interface Props {
    localities: LocalityNameModel[]
    dataPublishers: MQTTPublisherPreferencesList
    organization: OrganizationResponse
}

const DataPublishersForm = ({ localities, dataPublishers, organization }: Props) => {
    const { t } = useTranslation()

    const notify = useNotify()
    const clean = useApiCallCleaner()

    const { mutate: updateMqttPublishers } = useMutation(organizationApi.setMqttPublishers, {
        onSuccess: () => {
            clean(organizationApi)
            notify({
                variant: 'success',
                title: t('notification.updateSuccessful', 'Update successful'),
                content: t('notification.dataPublishersUpdateSuccessful', 'Data publishers were updated successfully'),
                timeoutSeconds: 3,
            })
        },
        onError: () => {
            notify({
                title: t('notification.updateFailed', 'Update failed'),
                content: t(
                    'notification.dataPublishersUpdateFailedContent',
                    'An error was encountered when updating data publishers.'
                ),
                variant: 'danger',
                timeoutSeconds: 5,
            })
        },
    })

    const [publishers, setPublishers] = useStateWithCallbackLazy(dataPublishers.mqttPublishers)

    const [isNewEntry, setIsNewEntry] = useState(false)
    const [updateEntryPosition, setUpdateEntryPosition] = useState<number | null>(null)

    const [editedPublisher, setEditedPublisher] = useState(initialDataPublisher)

    const [isSubmitted, setIsSubmitted] = useState<boolean>(false)
    const [errors, setErrors] = useState<Errors>(initialErrorState)

    const documentTitle = organization && `${organization.name} - ${t('tab.dataPublishers', 'Data publishers')}`

    const handlePublisherEdit = (data: MQTTPublisherPreferencesItem, index: number) => {
        setEditedPublisher(data)
        setUpdateEntryPosition(index)
        setIsSubmitted(false)
    }

    const handlePublisherDelete = (index: number) => {
        setPublishers(
            (prev) =>
                produce(prev, (draft) => {
                    draft.splice(index, 1)
                }),
            updatePublishers
        )
    }

    const updatePublishers = useCallback(
        (publishers: MQTTPublisherPreferencesItem[]) =>
            updateMqttPublishers({
                organizationId: organization.id,
                body: { mqttPublishers: publishers },
            }),
        [updateMqttPublishers]
    )

    const displayPublishersForm = isNewEntry || updateEntryPosition !== null
    const displayTable = !displayPublishersForm && !isEmpty(publishers)
    const displayEmptyTable = !displayPublishersForm && isEmpty(publishers.length)

    return (
        <>
            <Helmet>
                <title>{documentTitle}</title>
            </Helmet>
            <Box paddingSize="lg">
                <Box.Title
                    buttons={
                        displayPublishersForm ? (
                            <div className={styles.formControlButtonContainer}>
                                <Button
                                    className={styles.cancelButton}
                                    variant="secondary"
                                    onClick={() => {
                                        isNewEntry ? setIsNewEntry(false) : setUpdateEntryPosition(null)
                                        setErrors(initialErrorState)
                                    }}
                                >
                                    {t('button.cancel', 'Cancel')}
                                </Button>
                                <Button
                                    variant="primary"
                                    onClick={() => {
                                        setIsSubmitted(true)

                                        const newErrors = {
                                            ...errors,
                                            interval: {
                                                isInvalid: editedPublisher.interval < 0 || !editedPublisher.interval,
                                                isTouched: errors.interval.isTouched,
                                            },
                                            host: {
                                                isInvalid: editedPublisher.host === '' || !editedPublisher.host,
                                                isTouched: errors.host.isTouched,
                                            },
                                            topic: {
                                                isInvalid: editedPublisher.topic === '' || !editedPublisher.topic,
                                                isTouched: errors.topic.isTouched,
                                            },
                                            port: {
                                                isInvalid: editedPublisher.port < 1 || !editedPublisher.port,
                                                isTouched: errors.topic.isTouched,
                                            },
                                        }
                                        setErrors(newErrors)

                                        if (Object.values(newErrors).some((it) => it.isInvalid)) {
                                            notify({
                                                variant: 'danger',
                                                title: t('notification.validationFailed', 'Validation failed'),
                                                content: t(
                                                    'notification.invalidFields',
                                                    'Some of the fields are not filled in correctly'
                                                ),
                                                timeoutSeconds: 5,
                                            })

                                            return
                                        } else if (editedPublisher.localityId < 0) {
                                            notify({
                                                variant: 'danger',
                                                title: t('notification.validationFailed', 'Validation failed'),
                                                content: t('notification.noLocalitySelected', 'No locality selected'),
                                                timeoutSeconds: 5,
                                            })

                                            return
                                        }

                                        if (isNewEntry) {
                                            setPublishers((prev) => [...prev, editedPublisher], updatePublishers)
                                        } else {
                                            setPublishers(
                                                (prev) =>
                                                    produce(prev, (draft) => {
                                                        if (updateEntryPosition !== null) {
                                                            draft[updateEntryPosition] = editedPublisher
                                                        }
                                                    }),
                                                updatePublishers
                                            )
                                        }
                                        setUpdateEntryPosition(null)
                                        setIsNewEntry(false)
                                    }}
                                >
                                    {t('button.save', 'Save')}
                                </Button>
                            </div>
                        ) : (
                            <Button
                                variant="primary"
                                onClick={() => {
                                    setIsNewEntry((prev) => !prev)
                                    setEditedPublisher({
                                        ...initialDataPublisher,
                                        localityId:
                                            localities.filter((l) => l.organizationId === organization.id)[0]?.id ?? -1,
                                    })
                                    setIsSubmitted(false)
                                }}
                            >
                                <FontAwesomeIcon icon={faPlus} />
                                {t('button.addDataPublisher', 'Add data publisher')}
                            </Button>
                        )
                    }
                    text={
                        isNewEntry
                            ? t('heading.newDataPublisher', 'New data publisher')
                            : updateEntryPosition !== null
                            ? t('heading.updateDataPublisher', 'Update data publisher')
                            : t('heading.dataPublishers', 'Data publishers')
                    }
                />
                {displayTable && (
                    <DataPublishersTable
                        localities={localities}
                        publisherData={publishers}
                        onDelete={handlePublisherDelete}
                        onEdit={handlePublisherEdit}
                    />
                )}
                {displayEmptyTable && (
                    <div className={styles.noItemsCreatedContainer}>
                        <span className={styles.noItemsCreated}>
                            {t('others.noDataPublishers', 'No data publishers created')}
                        </span>
                    </div>
                )}
                {displayPublishersForm && (
                    <div className={styles.fadeIn}>
                        <DataPublisherEditForm
                            currentValues={editedPublisher}
                            errors={errors}
                            isSubmitted={isSubmitted}
                            localities={localities}
                            organization={organization}
                            onErrors={setErrors}
                            onUpdate={setEditedPublisher}
                        />
                    </div>
                )}
            </Box>
        </>
    )
}

export default DataPublishersForm
