/* tslint:disable */
/* eslint-disable */
/**
 * Vividi Dashboard API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    FloorplanHeatmapType,
    FloorplanHeatmapTypeFromJSON,
    FloorplanHeatmapTypeFromJSONTyped,
    FloorplanHeatmapTypeToJSON,
} from './FloorplanHeatmapType';

/**
 * 
 * @export
 * @interface FloorplanHeatmapData
 */
export interface FloorplanHeatmapData {
    /**
     * 
     * @type {string}
     * @memberof FloorplanHeatmapData
     */
    startingFrom: string;
    /**
     * 
     * @type {string}
     * @memberof FloorplanHeatmapData
     */
    endingAt: string;
    /**
     * 
     * @type {number}
     * @memberof FloorplanHeatmapData
     */
    heatmapId: number;
    /**
     * 
     * @type {FloorplanHeatmapType}
     * @memberof FloorplanHeatmapData
     */
    heatmapType: FloorplanHeatmapType;
}

export function FloorplanHeatmapDataFromJSON(json: any): FloorplanHeatmapData {
    return FloorplanHeatmapDataFromJSONTyped(json, false);
}

export function FloorplanHeatmapDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): FloorplanHeatmapData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'startingFrom': json['starting_from'],
        'endingAt': json['ending_at'],
        'heatmapId': json['heatmap_id'],
        'heatmapType': FloorplanHeatmapTypeFromJSON(json['heatmap_type']),
    };
}

export function FloorplanHeatmapDataToJSON(value?: FloorplanHeatmapData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'starting_from': value.startingFrom,
        'ending_at': value.endingAt,
        'heatmap_id': value.heatmapId,
        'heatmap_type': FloorplanHeatmapTypeToJSON(value.heatmapType),
    };
}

