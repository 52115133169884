import { capitalize, isEmpty, range, round, sum, uniq } from 'lodash'
import { DateTime, Interval } from 'luxon'

import {
    IntervalMovementByBoundary,
    IntervalPropertiesByBoundary,
    PropertyName,
    IntervalWeather,
    PropertyBreakdownByBoundaryResponse,
    VisitBoundaryWithId,
    SceneListResponse,
} from '@api'

import { displayScene } from '@helpers/displayUtils'

import { weightedAverage } from '@components/StatisticsSummary/utils'
import { parseDate } from '@components/plots/common'

import { getGranularityLabel, propertiesPercentage, removeUnclassified } from './FootfallDetailsContent'

type PropertiesPerBoundary = Array<{
    scene: string
    sceneId?: number
    visitBoundary: string
    totalVisitors: number
    granularity: string
    granularVisitors: number
    male: number
    female: number
    mostCommonAge: string
}>

type MovementData = Array<{
    name: string
    openedPortion: number
    interval: Interval
    hasData: boolean
    incoming?: number
    outgoing?: number
    weather?: IntervalWeather
}>

export type GenderAgeBreakdown = Array<{
    ageGroup: string
    maleRatio: number
    femaleRatio: number
    ageGroupToTotalRatio: number
}>

export const preparePropertiesPerBoundary = (
    boundaries: IntervalPropertiesByBoundary[],
    visitorBoundaries: IntervalMovementByBoundary[],
    scenes: SceneListResponse
): PropertiesPerBoundary => {
    const granularityLabel = getGranularityLabel(visitorBoundaries)

    return boundaries.map((boundaryRecord) => {
        const boundaryProperties = boundaryRecord.items[0].properties

        const ageBreakdown = removeUnclassified(boundaryProperties[PropertyName.Age] ?? {})

        const boundaryDailyVisitors =
            visitorBoundaries.find(
                (visitorsRecord) =>
                    visitorsRecord.sceneId === boundaryRecord.sceneId &&
                    visitorsRecord.boundaryId === boundaryRecord.boundaryId
            )?.items ?? []

        const scene = scenes.scenes.find((s) => s.id === boundaryRecord.sceneId)

        const maleVisitorPercentage = propertiesPercentage([boundaryRecord], PropertyName.IsMale, '1')

        const femaleVisitorPercentage = propertiesPercentage([boundaryRecord], PropertyName.IsMale, '0')

        return {
            scene: scene ? displayScene(scene) : '',
            sceneId: scene?.id,
            visitBoundary: boundaryRecord.boundaryName,
            totalVisitors: sum(boundaryDailyVisitors.map((it) => it.numberOfIncoming ?? 0)),
            granularity: capitalize(granularityLabel),
            granularVisitors: round(
                weightedAverage(
                    boundaryDailyVisitors.map((it) => it.numberOfIncoming ?? 0),
                    boundaryDailyVisitors.map((it) => it.openedPortion)
                ),
                1
            ),

            male: isNaN(maleVisitorPercentage) ? 0 : round(maleVisitorPercentage),
            female: isNaN(femaleVisitorPercentage) ? 0 : round(femaleVisitorPercentage),
            mostCommonAge: Object.entries(ageBreakdown).reduce(
                (previous, current) => (previous[1] >= current[1] ? previous : current),
                ['-', 0]
            )[0],
        }
    })
}

export const prepareMovementData = (
    visitors: IntervalMovementByBoundary[],
    now: DateTime,
    weather?: IntervalWeather[]
): MovementData => {
    const data = range(visitors[0]?.items.length ?? 0).map((i) => {
        const firstRecord = visitors[0].items[i]
        const interval = Interval.fromDateTimes(parseDate(firstRecord.startingFrom), parseDate(firstRecord.endingAt))

        const isIntervalInFuture = interval.start > now

        if (isIntervalInFuture) {
            return {
                name: i.toString(),
                openedPortion: firstRecord.openedPortion,
                interval,
                hasData: false,
            }
        }

        const incoming: number | undefined = sum(visitors.map((boundaryData) => boundaryData.items[i].numberOfIncoming))
        const outgoing: number | undefined = sum(visitors.map((boundaryData) => boundaryData.items[i].numberOfOutgoing))

        return {
            name: i.toString(),
            openedPortion: firstRecord.openedPortion,
            weather: weather?.[i],
            interval,
            incoming,
            outgoing,
            hasData: incoming !== undefined || outgoing !== undefined,
        }
    })

    return data
}

export const prepareGenderAgeBreakdown = (
    genderAgeBreakdown: PropertyBreakdownByBoundaryResponse,
    boundaries?: Array<VisitBoundaryWithId>
): GenderAgeBreakdown => {
    const records = (
        isEmpty(boundaries)
            ? genderAgeBreakdown.footfallItems
            : [...genderAgeBreakdown.footfallItems, ...genderAgeBreakdown.nonFootfallItems]
    )
        .filter((item) => isEmpty(boundaries) || boundaries!.some((b) => b.id === item.boundaryId))
        .flatMap((item) => item.items)
        .flatMap((item) => item.items)
        .filter((item) => item.brackets[PropertyName.Age] && item.brackets[PropertyName.IsMale])

    const ageGroups = uniq(records.map((it) => it.brackets[PropertyName.Age]).filter((it) => it !== null))

    const totalVisitors = sum(records.map((it) => it.numberOfVisits))

    return ageGroups.map((ageGroup) => {
        const male = sum(
            records
                .filter((it) => it.brackets[PropertyName.Age] === ageGroup && it.brackets[PropertyName.IsMale] === '1')
                .map((it) => it.numberOfVisits ?? 0)
        )

        const female = sum(
            records
                .filter((it) => it.brackets[PropertyName.Age] === ageGroup && it.brackets[PropertyName.IsMale] === '0')
                .map((it) => it.numberOfVisits ?? 0)
        )

        const total = male + female

        return {
            ageGroup,
            maleRatio: total > 0 ? male / total : 0,
            femaleRatio: total > 0 ? female / total : 0,
            ageGroupToTotalRatio: totalVisitors > 0 ? total / totalVisitors : 0,
        }
    })
}
