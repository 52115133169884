import { noop } from 'lodash'
import React, { useEffect } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import { useLocation } from 'react-router'

import { authApi } from '@services'

import { SUPPORT_EMAIL } from '@meta'

import { VIVIDI_APP } from '@helpers/VividiURLs'
import { storeToken } from '@helpers/auth'
import { useBackLink } from '@helpers/backlinks'

import PageContentLayout from '@components/Layouts/PageContentLayout'
import LoadingSpinner from '@components/LoadingSpinner'

const InstantLoginPage = () => {
    const { t } = useTranslation()
    const location = useLocation()
    const backlink = useBackLink(VIVIDI_APP)

    const { mutate: authCall, status: authCallState } = useMutation(authApi.authenticateInstant, {
        onSuccess: (response) => {
            storeToken(response)
            backlink.follow('replace')
        },
        onError: noop,
    })

    useEffect(() => {
        authCall({
            body: {
                token: location.hash.slice(1),
            },
        })
    }, [location])

    const heading = t('heading.linkExpired', 'Direct link expired')

    const placeholderHeading = t('others.pleaseWait', 'Please wait')

    const successHeading = t('others.success', 'Success')
    const supportEmail = SUPPORT_EMAIL

    if (authCallState === 'loading' || authCallState === 'idle') {
        return (
            <PageContentLayout heading={placeholderHeading}>
                {t('loginPage.loggingIn', 'Logging in...')}
                <LoadingSpinner bare={true} />
            </PageContentLayout>
        )
    }

    if (authCallState === 'error') {
        return (
            <PageContentLayout heading={heading}>
                <Trans i18nKey="loginPage.linkExpiredContent" values={{ supportEmail }}>
                    Your link is <strong>malformed or expired</strong>. Please contact us at{' '}
                    <a href={`mailto:${supportEmail}`}>{{ supportEmail }}</a> to resolve this issue.
                </Trans>
            </PageContentLayout>
        )
    }

    return (
        <PageContentLayout heading={successHeading}>
            {t('loginPage.redirectShortly', 'You were logged in and will be redirected shortly.')}
        </PageContentLayout>
    )
}

export default InstantLoginPage
