import { fromPairs, isEmpty } from 'lodash'
import React, { useCallback, useState } from 'react'
import { Form, Row, Col, Button } from 'react-bootstrap'
import { TokenProps, Token } from 'react-bootstrap-typeahead'
import Helmet from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { MutationStatus } from 'react-query'
import { Link } from 'react-router-dom'

import {
    LocalityModel,
    LocalityResponse,
    Location,
    UserResponse,
    OrganizationResponse,
    LocalityNameModel,
} from '@api/models'

import { generateLocalityEditUserPath } from '@helpers/VividiURLs'
import { defaultErrorEntry, ErrorEntry } from '@helpers/types'

import Box from '@elements/Box/Box'

import TooltipIcon from '@components/TooltipIcon'

import FeatureChecker from './FeatureChecker'
import TextInput from './GenericInputs/TextInput'
import styles from './LocalityEditForm.module.scss'
import MultiSelectBox from './MultiSelectBox'
import PlacePicker from './PlacePicker'
import TimezonePicker from './TimezonePicker'
import { useNotify } from './notifications/NotificationsContext'

type Props = {
    availableUsers?: Array<UserResponse>
    locality?: LocalityResponse
    visibleLocalities?: LocalityNameModel[]
    usersInLocality?: Array<UserResponse>
    submissionStatus: MutationStatus
    organization: OrganizationResponse
    onSubmit: ({ locality, selectedUsers }: { locality: LocalityModel; selectedUsers: Array<UserResponse> }) => void
}

interface Errors {
    name: ErrorEntry
}

const LocalityEditForm = (props: Props) => {
    const { t } = useTranslation()

    const notify = useNotify()
    const [name, setName] = useState(props.locality?.name)
    const [location, setLocation] = useState<Location | undefined>(props.locality?.location)
    const [selectedUsers, setSelectedUsers] = useState<Array<UserResponse>>(props.usersInLocality ?? [])
    const [isRealTimeOccupancyEnabled, setRealTimeOccupancyEnabled] = useState(
        props.locality?.isRealTimeOccupancyEnabled ?? false
    )
    const [isFloorplanOccupancyEnabled, setFloorplanOccupancyEnabled] = useState(
        props.locality?.isFloorplanOccupancyEnabled ?? false
    )
    const [labels, setLabels] = useState(props.locality?.labels ?? [])
    const [timeZone, setTimeZone] = useState(props.locality?.timezone)
    const [errors, setErrors] = useState<Errors>({
        name: defaultErrorEntry,
    })

    const selectableUsers = fromPairs(props.availableUsers?.map((u) => [u.id, u.email])) ?? {}
    const documentTitle = name && `${name} - ${t('tab.editInformation', 'Edit information')}`

    const handleSelectUser = (ids: string[]) => {
        const selectedUsers = props.availableUsers?.filter((user) => ids.indexOf(user.id.toString()) >= 0)
        selectedUsers && setSelectedUsers(selectedUsers)
    }

    const handleChangeName = (value: string) => {
        setErrors((prev) => ({
            ...prev,
            name: {
                isTouched: true,
                isInvalid: value === '',
            },
        }))
        setName(value)
    }

    const onSubmit: React.FormEventHandler = (e) => {
        e.preventDefault()

        if (errors.name.isInvalid || name === undefined) {
            notify({
                variant: 'warning',
                title: t('notification.couldNotSend', 'Could not send the form'),
                content: t('notification.invalidInputFields', 'Some of the input fields have invalid values'),
                timeoutSeconds: 3,
            })

            return
        }

        props.onSubmit({
            locality: {
                name,
                labels,
                location,
                capacity: props.locality?.capacity,
                isRealTimeOccupancyEnabled,
                isFloorplanOccupancyEnabled,
                timezone: timeZone,
            },
            selectedUsers,
        })
    }

    const UserLinkBadge: React.FC<TokenProps & { tokenId?: number }> = useCallback(
        ({ disabled, onRemove, children, tokenId }) => (
            <Token key={String(tokenId)} disabled={disabled} onRemove={onRemove}>
                <Link to={generateLocalityEditUserPath(props.organization.id, props.locality?.id ?? -1, tokenId ?? -1)}>
                    {children}
                </Link>
            </Token>
        ),
        [props.locality, props.organization]
    )

    return (
        <>
            <Helmet>
                <title>{documentTitle}</title>
            </Helmet>
            <Box paddingSize="lg">
                <Box.Title
                    buttons={
                        <Button variant="primary" onClick={onSubmit}>
                            {t('button.saveChanges', 'Save changes')}
                        </Button>
                    }
                    text={t('tab.editInformation', 'Edit information')}
                />
                <Form className={styles.formContainer}>
                    <Form.Group as={Row}>
                        <Form.Label column={true} sm={2}>
                            {t('table.name', 'Name')}
                        </Form.Label>
                        <Col sm={10}>
                            <TextInput
                                isInvalid={errors.name.isTouched && errors.name.isInvalid}
                                value={name}
                                onChange={handleChangeName}
                            />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <Form.Label column={true} sm={2}>
                            {t('form.labels', 'Labels')}
                            <TooltipIcon>
                                {t(
                                    'tooltip.labelMaxLength',
                                    'You can insert multiple labels. Maximum length of the label is 20 characters.'
                                )}
                            </TooltipIcon>
                        </Form.Label>
                        <Col sm={10}>
                            <MultiSelectBox
                                choices={Object.fromEntries(
                                    props.visibleLocalities
                                        ?.flatMap((locality) => locality.labels)
                                        .map((l) => [l, l]) ?? []
                                )}
                                selection={labels}
                                allowNew
                                onSelect={setLabels}
                            />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <Form.Label column={true} sm={2}>
                            {t('form.location', 'Location')}
                        </Form.Label>
                        <Col sm={10}>
                            <PlacePicker defaultValue={location} onChange={setLocation} />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <Form.Label column={true} sm={2}>
                            {t('form.timezone', 'Time zone')}
                        </Form.Label>
                        <Col sm={10}>
                            <TimezonePicker zone={timeZone} allowUndefined onChange={setTimeZone} />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <Form.Label column={true} sm={2}>
                            {t('form.users', 'Users')}
                        </Form.Label>
                        <Col sm={10}>
                            <MultiSelectBox
                                choices={selectableUsers ?? {}}
                                disabled={isEmpty(selectableUsers)}
                                placeholder={
                                    isEmpty(selectableUsers)
                                        ? t('others.noUsersToSelect', 'No users to select')
                                        : t('others.noUsersSelected', 'No users selected')
                                }
                                selection={selectedUsers.map((u) => u.id.toString())}
                                tokenRenderer={UserLinkBadge}
                                onSelect={handleSelectUser}
                            />
                        </Col>
                    </Form.Group>
                    <FeatureChecker allowForAdmin={true} feature="realtimeOccupancy" organization={props.organization}>
                        <Form.Group as={Row}>
                            <Col sm={{ offset: 2 }}>
                                <Form.Check
                                    checked={isRealTimeOccupancyEnabled}
                                    label={t(
                                        'form.enableStaySafe',
                                        'Enable real-time occupancy tracking (Vividi Stay Safe)'
                                    )}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        setRealTimeOccupancyEnabled(e.target.checked)
                                    }}
                                />
                            </Col>
                        </Form.Group>
                    </FeatureChecker>
                    <FeatureChecker allowForAdmin={true} feature="staySafeFloorplan" organization={props.organization}>
                        <Form.Group as={Row}>
                            <Col sm={{ offset: 2 }}>
                                <Form.Check
                                    checked={isFloorplanOccupancyEnabled}
                                    label="Enable real-time floorplan occupancy tracking (Vividi Stay Safe FP)"
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        setFloorplanOccupancyEnabled(e.target.checked)
                                    }}
                                />
                            </Col>
                        </Form.Group>
                    </FeatureChecker>
                </Form>
            </Box>
        </>
    )
}

export default LocalityEditForm
