/* tslint:disable */
/* eslint-disable */
/**
 * Vividi Dashboard API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * All possible scopes for a token.
 * @export
 * @enum {string}
 */
export enum Scope {
    VividibrowseStatistics = 'vividi:browse_statistics',
    VividiManageOrganizations = 'vividi_manage_organizations',
    VividiManageLocalities = 'vividi_manage_localities',
    VividimanageDevices = 'vividi:manage_devices',
    VividimanageScenes = 'vividi:manage_scenes',
    VividimanageUsers = 'vividi:manage_users',
    VividimodifyProfile = 'vividi:modify_profile',
    VividiviewImages = 'vividi:view_images',
    VividiviewProfile = 'vividi:view_profile',
    VividireadReports = 'vividi:read_reports',
    VividimanageReports = 'vividi:manage_reports',
    VividiviewMonitoring = 'vividi:view_monitoring',
    VividimanageBackgroundJobs = 'vividi:manage_background_jobs'
}

export function ScopeFromJSON(json: any): Scope {
    return ScopeFromJSONTyped(json, false);
}

export function ScopeFromJSONTyped(json: any, ignoreDiscriminator: boolean): Scope {
    return json as Scope;
}

export function ScopeToJSON(value?: Scope | null): any {
    return value as any;
}

