/* tslint:disable */
/* eslint-disable */
/**
 * Vividi Dashboard API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    FootfallOverviewItem,
    FootfallOverviewItemFromJSON,
    FootfallOverviewItemFromJSONTyped,
    FootfallOverviewItemToJSON,
} from './FootfallOverviewItem';

/**
 * 
 * @export
 * @interface FootfallOverviewResponseItem
 */
export interface FootfallOverviewResponseItem {
    /**
     * 
     * @type {number}
     * @memberof FootfallOverviewResponseItem
     */
    localityId: number;
    /**
     * 
     * @type {Array<FootfallOverviewItem>}
     * @memberof FootfallOverviewResponseItem
     */
    items: Array<FootfallOverviewItem>;
}

export function FootfallOverviewResponseItemFromJSON(json: any): FootfallOverviewResponseItem {
    return FootfallOverviewResponseItemFromJSONTyped(json, false);
}

export function FootfallOverviewResponseItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): FootfallOverviewResponseItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'localityId': json['locality_id'],
        'items': ((json['items'] as Array<any>).map(FootfallOverviewItemFromJSON)),
    };
}

export function FootfallOverviewResponseItemToJSON(value?: FootfallOverviewResponseItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'locality_id': value.localityId,
        'items': ((value.items as Array<any>).map(FootfallOverviewItemToJSON)),
    };
}

