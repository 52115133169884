import useLocalStorage from '@rehooks/local-storage'
import { DateTime } from 'luxon'
import { useCallback, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { MutationStatus, useQuery } from 'react-query'
import { useHistory, useParams } from 'react-router'

import { Configuration, DeviceResponse, DockerImageResponse } from '@api'

import { AuthenticationAndTokenManagementApi, DeviceManagementApi } from '@api/apis'

import { deviceApi, vividiImagesApi } from '@services'

import { generateDeviceTransferPath } from '@helpers/VividiURLs'
import { mergeQueryResults } from '@helpers/api'
import { EXTERNAL_AUTH_KEY, ExternalAuthContext } from '@helpers/auth'

import DeviceTransferForm from '@components/DeviceTransfer/DeviceTransferForm'
import ExternalAuthStatus from '@components/DeviceTransfer/ExternalAuthStatus'
import TransferStateIndicator, {
    defaultTransferState,
    TransferState,
} from '@components/DeviceTransfer/TransferStateIndicator'
import ErrorView from '@components/ErrorView'
import PageContentLayout from '@components/Layouts/PageContentLayout'
import LegacyLoadingWrapper from '@components/LegacyLoadingWrapper'

const DeviceTransferPage = () => {
    const { t } = useTranslation()
    const matchParams = useParams<{ id: string }>()
    const history = useHistory()

    const getImages = useQuery(vividiImagesApi.getImages.query())
    const getDevices = useQuery(deviceApi.getDeviceList.query())

    const [authContext, setAuthContext, deleteAuthContext] = useLocalStorage<ExternalAuthContext>(EXTERNAL_AUTH_KEY)
    const [loginState, setLoginState] = useState<MutationStatus>('idle')
    const [selectedImage, setSelectedImage] = useState<DockerImageResponse>()
    const [transferState, setTransferState] = useState(defaultTransferState)

    const updateTransferState = useCallback(
        (values: Partial<TransferState>) => setTransferState((prevState) => ({ ...prevState, ...values })),
        [setTransferState]
    )

    useEffect(() => {
        setTransferState(defaultTransferState)
    }, [matchParams.id])

    const login = useCallback(
        async (apiUrl: string, email: string, password: string) => {
            setLoginState('loading')

            const api = new AuthenticationAndTokenManagementApi(new Configuration({ basePath: apiUrl }))

            try {
                const response = await api.authenticate({
                    body: {
                        email,
                        password,
                    },
                })

                setAuthContext({
                    apiUrl,
                    accessToken: response.accessToken,
                    expiresIn: response.expiresIn,
                    issueDate: DateTime.utc().toSeconds(),
                })
                setLoginState('success')
            } catch (e) {
                setLoginState('error')
            }
        },
        [setAuthContext, setLoginState]
    )

    const transfer = async (device: DeviceResponse) => {
        if (!authContext || !selectedImage) {
            return
        }

        const vividiImageName = `${selectedImage.name}:${selectedImage.tag}`

        const configuration = new Configuration({
            basePath: authContext.apiUrl,
            accessToken: authContext.accessToken,
        })
        const remoteDeviceApi = new DeviceManagementApi(configuration)

        // Create the device using the remote API
        updateTransferState({
            overallState: 'loading',
            deviceCreationState: 'loading',
        })

        try {
            await remoteDeviceApi.receiveTransferredDevice({
                body: {
                    id: device.id,
                    deviceId: device.deviceId,
                    vividiImageName,
                },
            })
            updateTransferState({
                deviceCreationState: 'success',
                creationResult: 'created',
            })
        } catch (e) {
            try {
                const devices = await remoteDeviceApi.getDeviceList()
                const existingDevice = devices.devices.find((d) => d.id === device.id)

                if (existingDevice?.deviceId === device.deviceId) {
                    updateTransferState({
                        creationResult: 'existsOk',
                        deviceCreationState: 'success',
                    })
                } else {
                    return updateTransferState({
                        creationResult: 'existsConflict',
                        deviceCreationState: 'error',
                        overallState: 'error',
                    })
                }
            } catch (e) {
                return updateTransferState({
                    deviceCreationState: 'error',
                    overallState: 'error',
                })
            }
        }

        // Configure the image
        updateTransferState({ imageConfigurationState: 'loading' })

        try {
            await deviceApi.updateDevice({
                deviceId: device.id,
                body: {
                    organizationId: device.organizationId,
                    vividiImageName,
                },
            })
            updateTransferState({ imageConfigurationState: 'success' })
        } catch (e) {
            return updateTransferState({
                imageConfigurationState: 'error',
                overallState: 'error',
            })
        }

        // Mark our copy of the device as transferred
        updateTransferState({ markTransferredState: 'loading' })

        try {
            await deviceApi.transferDevice({
                deviceId: device.id,
                destination: encodeURIComponent(authContext.apiUrl),
            })
            updateTransferState({ markTransferredState: 'success' })
        } catch (e) {
            return updateTransferState({
                markTransferredState: 'error',
                overallState: 'error',
            })
        }

        return updateTransferState({
            overallState: 'success',
        })
    }

    const isLoggedIn = authContext && authContext.issueDate + authContext.expiresIn > DateTime.utc().toSeconds()

    const heading = t('heading.externalApiAuth', 'External API Authentication')

    return (
        <PageContentLayout heading={heading}>
            <div>
                <ExternalAuthStatus
                    authContext={authContext}
                    loginState={loginState}
                    onLogin={login}
                    onLogout={() => deleteAuthContext()}
                />
            </div>
            {isLoggedIn && (
                <LegacyLoadingWrapper request={mergeQueryResults(getDevices, getImages)}>
                    {([devicesResponse, imagesResponse]) => {
                        const selectedDevice = devicesResponse.devices.find(
                            (d) => d.id === parseInt(matchParams.id, 10)
                        )

                        if (selectedDevice === undefined) {
                            return <></>
                        }

                        if (selectedDevice.transferred) {
                            return (
                                <ErrorView
                                    message={t(
                                        'others.transferred',
                                        'The device has been transferred to another backend'
                                    )}
                                />
                            )
                        }

                        return (
                            <>
                                <h2>{t('heading.transferParameters', 'Transfer Parameters')}</h2>
                                <div>
                                    <DeviceTransferForm
                                        destinationApi={authContext!.apiUrl}
                                        devicePickerProps={{
                                            devices: devicesResponse.devices.filter(
                                                (d) => !d.transferred && d.isApproved
                                            ),
                                            selectedDevice,
                                            onSelect: (device) => history.push(generateDeviceTransferPath(device.id)),
                                        }}
                                        imagePickerProps={{
                                            availableImages: imagesResponse.images,
                                            onSelectImage: setSelectedImage,
                                            selectedImage,
                                        }}
                                        submissionState={transferState.overallState}
                                        onSubmit={() => transfer(selectedDevice)}
                                    />
                                </div>
                                <h2>{t('heading.transferProgress', 'Transfer Progress')}</h2>
                                <TransferStateIndicator transferState={transferState} />
                            </>
                        )
                    }}
                </LegacyLoadingWrapper>
            )}
        </PageContentLayout>
    )
}

export default DeviceTransferPage
