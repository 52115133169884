import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import React, { ReactNode } from 'react'

import { displayNumericValueByType, formatLargeNumber } from '@helpers/displayUtils'
import { NumberFormat } from '@helpers/types'

import styles from './Table.module.scss'

const NumericCell: React.FC<{
    value?: number
    valueType: NumberFormat
    icon?: IconDefinition
    changeIndicator?: ReactNode
    className?: string
    roundingPrecision?: number
}> = ({ value, valueType, icon, changeIndicator, className, roundingPrecision }) => {
    return (
        <td className={classNames(styles.numericCell, className)}>
            {icon !== undefined ? <FontAwesomeIcon className={styles.icon} icon={icon} /> : null}
            <div className="d-flex justify-content-end">
                {formatLargeNumber(displayNumericValueByType(value, valueType, roundingPrecision))}
                {changeIndicator}
            </div>
        </td>
    )
}

export default NumericCell
