import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons'
import classNames from 'classnames'
import React from 'react'

import IconButton from '@elements/Buttons/IconButton'

import { DateTimeFormat, displayDateTimes } from '@components/StatisticsSummary/utils'

import styles from './TimelinePagination.module.scss'

interface TimelinePaginationProps {
    onNextPage: () => void
    onPreviousPage: () => void
    displayedDates: { startDate?: DateTimeFormat; endDate?: DateTimeFormat }
    selectedPage: number
    pageCount: number
    disableNextPage?: boolean
    disablePreviousPage?: boolean
    dateDisplayFormatter?: (startDate?: DateTimeFormat, endDate?: DateTimeFormat) => string
}

export const TimelinePagination: React.FC<TimelinePaginationProps> = ({
    onNextPage,
    onPreviousPage,
    displayedDates,
    selectedPage,
    pageCount,
    disableNextPage,
    disablePreviousPage,
    dateDisplayFormatter,
}) => {
    const { startDate, endDate } = displayedDates

    return (
        <div className={styles.timelineDatePicker}>
            <div>
                {dateDisplayFormatter ? dateDisplayFormatter(startDate, endDate) : displayDateTimes(startDate, endDate)}
            </div>
            <IconButton
                className={classNames({
                    [styles.navigationButtonHidden]: false,
                })}
                disabled={selectedPage === 1 || disablePreviousPage}
                icon={faAngleLeft}
                onClick={() => onPreviousPage()}
            />
            <IconButton
                className={classNames({
                    [styles.navigationButtonHidden]: false,
                })}
                disabled={selectedPage === pageCount || disableNextPage}
                icon={faAngleRight}
                onClick={() => onNextPage()}
            />
        </div>
    )
}
