import { useTranslation } from 'react-i18next'
import { useMutation, useQuery } from 'react-query'

import { LocalityNameModel, ProfileResponse, Role } from '@api'

import { localityApi, organizationApi, userApi } from '@services'

import { useApiCallCleaner } from '@helpers/api'
import { mergeQueryResults, visibleLocalitiesQuery, visibleOrganizationsQuery } from '@helpers/api'
import { useBackLink } from '@helpers/backlinks'
import useProfile from '@helpers/profile'

import PageContentLayout from '@components/Layouts/PageContentLayout'
import LegacyLoadingWrapper from '@components/LegacyLoadingWrapper'
import CreateUserForm from '@components/UserForm/CreateUserForm'

const CreateUserPage = () => {
    const { t } = useTranslation()
    const profileCall = useProfile()

    const organizationsCall = useQuery({
        ...visibleOrganizationsQuery(profileCall.data),
    })

    const localitiesCall = useQuery({
        ...visibleLocalitiesQuery(profileCall.data),
    })

    const request = mergeQueryResults(organizationsCall, localitiesCall)
    const backLink = useBackLink()
    const clean = useApiCallCleaner()

    const heading = t('heading.createUser', 'Create User')

    const userCreator = async ({
        profile,
        values,
    }: {
        profile: ProfileResponse
        values: { email: string; role: Role; organizationId?: number; localities: LocalityNameModel[] }
    }) => {
        const createdUser = await userApi.addUser({
            body: {
                email: values.email,
                sendInvitation: true,
                language: profile.language,
                organizationId: values.organizationId,
            },
        })

        await userApi.setUserRole({
            userId: createdUser.id,
            body: {
                role: values.role,
            },
        })

        await userApi.setUserLocalities({
            userId: createdUser.id,
            body: { localities: values.localities.map((locality) => locality.id) },
        })
        clean(userApi)
        clean(organizationApi)
        clean(localityApi)
        backLink.follow()
    }

    const {
        mutate: handleCreateUser,
        status: userCreationState,
        reset,
    } = useMutation(userCreator, {
        onSuccess: () => {
            reset()
        },
    })

    return (
        <LegacyLoadingWrapper
            placeholder={<PageContentLayout heading={heading} loadingState={organizationsCall.status} />}
            request={request}
        >
            {([organizations, localities]) => {
                return (
                    <PageContentLayout heading={heading}>
                        <CreateUserForm
                            localities={localities.localities}
                            organizations={organizations.organizations}
                            profile={profileCall}
                            submissionStatus={userCreationState}
                            onSubmit={handleCreateUser}
                        />
                    </PageContentLayout>
                )
            }}
        </LegacyLoadingWrapper>
    )
}

export default CreateUserPage
