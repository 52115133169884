/* tslint:disable */
/* eslint-disable */
/**
 * Vividi Dashboard API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StatisticsRecalculationRequestBody
 */
export interface StatisticsRecalculationRequestBody {
    /**
     * 
     * @type {string}
     * @memberof StatisticsRecalculationRequestBody
     */
    startingFrom?: string;
    /**
     * 
     * @type {string}
     * @memberof StatisticsRecalculationRequestBody
     */
    endingAt?: string;
    /**
     * List of localities whose statistics should be recalculated, if none is given, all localities are recalculated
     * @type {Array<number>}
     * @memberof StatisticsRecalculationRequestBody
     */
    localityIds?: Array<number>;
    /**
     * 
     * @type {boolean}
     * @memberof StatisticsRecalculationRequestBody
     */
    force?: boolean;
}

export function StatisticsRecalculationRequestBodyFromJSON(json: any): StatisticsRecalculationRequestBody {
    return StatisticsRecalculationRequestBodyFromJSONTyped(json, false);
}

export function StatisticsRecalculationRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): StatisticsRecalculationRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'startingFrom': !exists(json, 'starting_from') ? undefined : json['starting_from'],
        'endingAt': !exists(json, 'ending_at') ? undefined : json['ending_at'],
        'localityIds': !exists(json, 'locality_ids') ? undefined : json['locality_ids'],
        'force': !exists(json, 'force') ? undefined : json['force'],
    };
}

export function StatisticsRecalculationRequestBodyToJSON(value?: StatisticsRecalculationRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'starting_from': value.startingFrom,
        'ending_at': value.endingAt,
        'locality_ids': value.localityIds,
        'force': value.force,
    };
}

