/* tslint:disable */
/* eslint-disable */
/**
 * Vividi Dashboard API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IntervalPropertiesSummary,
    IntervalPropertiesSummaryFromJSON,
    IntervalPropertiesSummaryFromJSONTyped,
    IntervalPropertiesSummaryToJSON,
} from './IntervalPropertiesSummary';

/**
 * 
 * @export
 * @interface IntervalPropertiesByBoundary
 */
export interface IntervalPropertiesByBoundary {
    /**
     * 
     * @type {number}
     * @memberof IntervalPropertiesByBoundary
     */
    boundaryId: number;
    /**
     * 
     * @type {string}
     * @memberof IntervalPropertiesByBoundary
     */
    boundaryName: string;
    /**
     * 
     * @type {number}
     * @memberof IntervalPropertiesByBoundary
     */
    deviceId: number;
    /**
     * 
     * @type {number}
     * @memberof IntervalPropertiesByBoundary
     */
    sceneId: number;
    /**
     * 
     * @type {Array<IntervalPropertiesSummary>}
     * @memberof IntervalPropertiesByBoundary
     */
    items: Array<IntervalPropertiesSummary>;
}

export function IntervalPropertiesByBoundaryFromJSON(json: any): IntervalPropertiesByBoundary {
    return IntervalPropertiesByBoundaryFromJSONTyped(json, false);
}

export function IntervalPropertiesByBoundaryFromJSONTyped(json: any, ignoreDiscriminator: boolean): IntervalPropertiesByBoundary {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'boundaryId': json['boundary_id'],
        'boundaryName': json['boundary_name'],
        'deviceId': json['device_id'],
        'sceneId': json['scene_id'],
        'items': ((json['items'] as Array<any>).map(IntervalPropertiesSummaryFromJSON)),
    };
}

export function IntervalPropertiesByBoundaryToJSON(value?: IntervalPropertiesByBoundary | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'boundary_id': value.boundaryId,
        'boundary_name': value.boundaryName,
        'device_id': value.deviceId,
        'scene_id': value.sceneId,
        'items': ((value.items as Array<any>).map(IntervalPropertiesSummaryToJSON)),
    };
}

