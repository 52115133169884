/* tslint:disable */
/* eslint-disable */
/**
 * Vividi Dashboard API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MQTTPublisherType,
    MQTTPublisherTypeFromJSON,
    MQTTPublisherTypeFromJSONTyped,
    MQTTPublisherTypeToJSON,
} from './MQTTPublisherType';

/**
 * 
 * @export
 * @interface MQTTPublisherPreferencesItem
 */
export interface MQTTPublisherPreferencesItem {
    /**
     * 
     * @type {number}
     * @memberof MQTTPublisherPreferencesItem
     */
    localityId: number;
    /**
     * 
     * @type {MQTTPublisherType}
     * @memberof MQTTPublisherPreferencesItem
     */
    type: MQTTPublisherType;
    /**
     * 
     * @type {number}
     * @memberof MQTTPublisherPreferencesItem
     */
    interval: number;
    /**
     * 
     * @type {string}
     * @memberof MQTTPublisherPreferencesItem
     */
    topic: string;
    /**
     * 
     * @type {string}
     * @memberof MQTTPublisherPreferencesItem
     */
    host: string;
    /**
     * 
     * @type {number}
     * @memberof MQTTPublisherPreferencesItem
     */
    port: number;
    /**
     * 
     * @type {string}
     * @memberof MQTTPublisherPreferencesItem
     */
    certificate?: string;
    /**
     * 
     * @type {string}
     * @memberof MQTTPublisherPreferencesItem
     */
    key?: string;
}

export function MQTTPublisherPreferencesItemFromJSON(json: any): MQTTPublisherPreferencesItem {
    return MQTTPublisherPreferencesItemFromJSONTyped(json, false);
}

export function MQTTPublisherPreferencesItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): MQTTPublisherPreferencesItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'localityId': json['locality_id'],
        'type': MQTTPublisherTypeFromJSON(json['type']),
        'interval': json['interval'],
        'topic': json['topic'],
        'host': json['host'],
        'port': json['port'],
        'certificate': !exists(json, 'certificate') ? undefined : json['certificate'],
        'key': !exists(json, 'key') ? undefined : json['key'],
    };
}

export function MQTTPublisherPreferencesItemToJSON(value?: MQTTPublisherPreferencesItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'locality_id': value.localityId,
        'type': MQTTPublisherTypeToJSON(value.type),
        'interval': value.interval,
        'topic': value.topic,
        'host': value.host,
        'port': value.port,
        'certificate': value.certificate,
        'key': value.key,
    };
}

