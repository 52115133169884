/* tslint:disable */
/* eslint-disable */
/**
 * Vividi Dashboard API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SceneResponse,
    SceneResponseFromJSON,
    SceneResponseFromJSONTyped,
    SceneResponseToJSON,
} from './SceneResponse';

/**
 * 
 * @export
 * @interface SceneListResponse
 */
export interface SceneListResponse {
    /**
     * 
     * @type {Array<SceneResponse>}
     * @memberof SceneListResponse
     */
    scenes: Array<SceneResponse>;
}

export function SceneListResponseFromJSON(json: any): SceneListResponse {
    return SceneListResponseFromJSONTyped(json, false);
}

export function SceneListResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): SceneListResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'scenes': ((json['scenes'] as Array<any>).map(SceneResponseFromJSON)),
    };
}

export function SceneListResponseToJSON(value?: SceneListResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'scenes': ((value.scenes as Array<any>).map(SceneResponseToJSON)),
    };
}

