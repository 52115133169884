import { castArray } from 'lodash'
import React, { PropsWithChildren } from 'react'
import { Switch } from 'react-router'
import { Route } from 'react-router-dom'

import PageNotFound from '../pages/PageNotFound' // eslint-disable-line replace-relative-imports/replace

import vividiRoutes from './vividiRoutes'

export type RouteType = {
    Component: React.ComponentType<PropsWithChildren<any>>
    path: string | Array<string>
    exact?: boolean
    isNotSecured?: boolean
}

export const VividiRoutes = () => {
    return (
        <Switch>
            {vividiRoutes.map(({ Component, path, exact = false }: RouteType) => {
                return <Route key={castArray(path).join('#')} component={Component} exact={exact} path={path} />
            })}
            <Route component={PageNotFound} path="*" exact />
        </Switch>
    )
}
