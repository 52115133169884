import { flatten, isEqual, memoize } from 'lodash'
import { Duration, Interval } from 'luxon'

import { OpeningHoursInterval, OutageModel } from '@api'

import { parseDate } from '@components/plots/common'

import { IntervalType } from './types'

export function exhaustiveCheck(_: never): never {
    throw new Error('invalid input type')
}

export function assertIsIntervalType(val: string): asserts val is IntervalType {
    if (!Object.values(IntervalType).some((interval) => interval === val)) {
        throw new Error('invalid interval in IntervalType')
    }
}

export function convertOpeningHoursPerDayToTickValues(openingHours: OpeningHoursInterval[]): number[] {
    const tickValues = openingHours.map((hours) => {
        const minutesInDay = 1440
        const openHour = hours.openingTime.hour
        const openMinute = hours.openingTime.minute
        const closeHour = hours.closingTime.hour
        const closeMinute = hours.closingTime.minute

        const minFromMidnightToOpen = Duration.fromObject({
            hours: openHour,
            minutes: openMinute,
        }).as('minutes')
        const minFromMidnightToClose = Duration.fromObject({
            hours: closeHour,
            minutes: closeMinute,
        }).as('minutes')

        // edge cases
        if (minFromMidnightToOpen === 0 && minFromMidnightToClose === 0) {
            return [0, minutesInDay]
        }

        if (minFromMidnightToOpen > minFromMidnightToClose && minFromMidnightToClose === 0) {
            return [minFromMidnightToOpen, minutesInDay]
        }

        return [minFromMidnightToOpen, minFromMidnightToClose]
    })

    return flatten(tickValues)
}

const usingDotDecimal =
    new Intl.NumberFormat(undefined, {
        minimumFractionDigits: 1,
        maximumFractionDigits: 1,
    })
        .format(1.5)
        .charAt(1) === '.'

const numberCharsRegex = usingDotDecimal ? /[, ']*/g : /[. ']*/g

export function normalizeNumber(s: string) {
    return s.replace(numberCharsRegex, '').replace(',', '.')
}

export const chunkBy = <T>(items: Array<T>, keyFunction: (item: T) => any) => {
    let cursor: Array<T> | undefined = undefined
    const result: Array<Array<T>> = []

    for (const item of items) {
        if (cursor !== undefined && isEqual(keyFunction(cursor[0]), keyFunction(item))) {
            cursor.push(item)
        } else {
            cursor = [item]
            result.push(cursor)
        }
    }

    return result
}

export const pickColumn = <T, K extends keyof T>(table: Array<T>, key: K): Array<T[K]> => table.map((row) => row[key])

export const recordFromEntries = <K extends string, TEntry>(
    keys: readonly K[],
    entryBuilder: (key: K) => TEntry
): Record<K, TEntry> => Object.fromEntries(keys.map((k) => [k, entryBuilder(k)])) as Record<K, TEntry>

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const capitalizeFirstLetter = (subject: string) => {
    if (subject.length === 0) {
        return subject
    }

    return subject.charAt(0).toUpperCase() + subject.slice(1)
}

export const filenameFromHeaders = (response: Response) => {
    const header = response.headers.get('content-disposition')

    if (header === null) {
        return undefined
    }

    return header.match(/filename="(.+)"|filename=(.+)/)![1]
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const replaceUndefined = (list: Array<number | undefined>, replacement = 0) => list.map((it) => it ?? replacement)

export const outageInterval = memoize((outage: OutageModel) => {
    const startDate = parseDate(outage.startedAt)

    return Interval.fromDateTimes(startDate, startDate.plus({ seconds: outage.duration }))
})
outageInterval.cache = new WeakMap()

export const percentage = (value: number, total: number): number => {
    if (total === 0) {
        return 0
    }

    return (100 * value) / total
}

export const filterIndexes = <T>(subject: Array<T>, filterFunc: (item: T) => boolean): Array<number> =>
    pickColumn(
        Object.entries(subject).filter(([, item]) => filterFunc(item)),
        0
    ).map(Number)
