import useLocalStorage, { writeStorage, deleteFromStorage } from '@rehooks/local-storage'
import { DateTime, Duration } from 'luxon'
import { useEffect, useRef } from 'react'

import { TokenResponse } from '@api/models'

import { authApi } from '@services'

export const AUTH_KEY = 'auth'

export interface AuthContext {
    accessToken: string
    expiresIn: number
    issueDate: number
}

export const storeToken = (tokenResponse: TokenResponse) => {
    writeStorage<AuthContext>(AUTH_KEY, {
        accessToken: tokenResponse.accessToken,
        expiresIn: tokenResponse.expiresIn,
        issueDate: DateTime.utc().toSeconds(),
    })
}

export const EXTERNAL_AUTH_KEY = 'external_auth'

export interface ExternalAuthContext extends AuthContext {
    apiUrl: string
}

const cookiesDisabled = !navigator.cookieEnabled

export const useAuthContext = (): AuthContext | null => {
    if (cookiesDisabled) {
        return null
    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [authContext] = useLocalStorage<AuthContext>(AUTH_KEY)

    return authContext
}

export const deleteAuthContext = () => {
    deleteFromStorage(AUTH_KEY)
}

export const useTokenRefresher = () => {
    const authContext = useAuthContext()
    const isAborted = useRef(false)

    // When a new token is received, undo abortion.
    // We don't mind changing a ref doesn't force a redraw - the periodic code will eventually pick it up.
    useEffect(() => {
        if (authContext?.accessToken !== undefined) {
            isAborted.current = false
        }
    }, [authContext])

    useEffect(() => {
        const interval = setInterval(async () => {
            if (!authContext || isAborted.current === true) {
                return
            }

            const issueDate = DateTime.fromSeconds(authContext.issueDate, {
                zone: 'utc',
            })

            const secondsSinceIssueDate = DateTime.utc().diff(issueDate).as('seconds')

            if (secondsSinceIssueDate > authContext.expiresIn / 2) {
                try {
                    const response = await authApi.refresh()

                    storeToken(response)
                } catch (e) {
                    if ((e as any).status === 401) {
                        isAborted.current = true
                    }
                }
            }
        }, Duration.fromObject({ seconds: 10 }).as('milliseconds'))

        return () => clearInterval(interval)
    })
}
