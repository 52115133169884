import { detect } from 'detect-browser'
import React, { useEffect } from 'react'
import { CookiesProvider } from 'react-cookie'
import Helmet from 'react-helmet'
import { hotjar } from 'react-hotjar'
import './App.scss'
import { QueryClientProvider } from 'react-query'
import { matchPath, useLocation } from 'react-router'
import { BrowserRouter as Router } from 'react-router-dom'
import { HistoryListener } from 'react-router-navigation-confirm'

import { queryClient } from '@services'

import { GoogleEventCategory, GoogleEventName, installGTag, trackEvent } from '@helpers/gtag'
import useProfile from '@helpers/profile'

import DarkModeProvider from '@components/darkmode/DarkModeProvider'

import ErrorBoundary from './components/ErrorBoundary'
import GlobalErrorView from './components/GlobalErrorView'
import TermsAndConditionsModal from './components/TermsAndConditions/TermsAndConditionsModal'
import NotificationArea from './components/notifications/NotificationArea'
import { Provider as NotificationsProvider } from './components/notifications/NotificationsContext'
import { FLOORPLAN_LOCALITY_OCCUPANCY_DASHBOARD_PATH, LOCALITY_OCCUPANCY_DASHBOARD_PATH } from './helpers/VividiURLs'
import useApiCallsReset from './helpers/apiCallsReset'
import { useTokenRefresher } from './helpers/auth'
import { useIsPreRendering } from './helpers/preRendering'
import useAppLanguageSwitcher from './helpers/userLanguage'
import { VividiMeta } from './meta'
import './i18n'
import BrowserNotSupportedPage from './pages/BrowserNotSupportedPage'
import { VividiRoutes } from './routes/AppRoutes'

installGTag()

const Hooks = () => {
    useApiCallsReset()
    useTokenRefresher()
    useAppLanguageSwitcher()

    return null
}

const VividiApp = () => {
    const location = useLocation()
    const isPreRendering = useIsPreRendering()
    const browser = detect()
    const profile = useProfile()

    useEffect(() => {
        if (!isPreRendering && process.env.REACT_APP_HOTJAR_CODE !== undefined) {
            hotjar.initialize(Number(process.env.REACT_APP_HOTJAR_CODE), 6)
        }
    }, [isPreRendering])

    useEffect(() => {
        if (profile.data?.id && profile.data?.email) {
            trackEvent(GoogleEventCategory.USER, GoogleEventName.LOGIN, profile.data.email, profile.data.email)
        }
    }, [profile.data?.email, profile.data?.id])

    const isStaySafeKioskPage =
        matchPath(location.pathname, LOCALITY_OCCUPANCY_DASHBOARD_PATH) ||
        matchPath(location.pathname, FLOORPLAN_LOCALITY_OCCUPANCY_DASHBOARD_PATH)

    const isNotSupportedBrowser = browser?.name === 'ie'

    return (
        <ErrorBoundary location={location} placeholder={<GlobalErrorView />}>
            <VividiMeta />
            <Helmet>
                <title>Vividi | Thinking inside the box</title>
            </Helmet>
            <TermsAndConditionsModal />
            <NotificationArea />
            {!isPreRendering && isNotSupportedBrowser && !isStaySafeKioskPage ? (
                <BrowserNotSupportedPage />
            ) : !isPreRendering ? (
                <VividiRoutes />
            ) : null}
        </ErrorBoundary>
    )
}

const App: React.FC = () => (
    <QueryClientProvider client={queryClient}>
        <CookiesProvider>
            <NotificationsProvider>
                <DarkModeProvider>
                    <Router>
                        <HistoryListener>
                            <Hooks />
                            <VividiApp />
                        </HistoryListener>
                    </Router>
                </DarkModeProvider>
            </NotificationsProvider>
        </CookiesProvider>
    </QueryClientProvider>
)

export default App
