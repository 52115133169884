import classNames from 'classnames'
import { isEmpty } from 'lodash'
import React from 'react'
import { Container } from 'react-bootstrap'
import Helmet from 'react-helmet'

import useScrollToTopOnMount from '@helpers/scrolling'
import { Possibly } from '@helpers/types'

import Breadcrumbs, { BreadcrumbsList } from '@components/Breadcrumbs'
import Navbar from '@components/Navbar/Navbar'

import { Content, ContentProps } from './Content'
import { LayoutHeading, LayoutHeadingProps } from './LayoutHeading'
import styles from './layouts.module.scss'

type Props = Possibly<LayoutHeadingProps> &
    ContentProps & {
        fluid?: boolean
    } & Possibly<{ breadcrumbs: BreadcrumbsList }> & { documentTitle?: string }

const BarePageLayout: React.FC<Props> = ({
    fluid,
    heading,
    headingButtons,
    headingClassName,
    headingType,
    children,
    loadingState,
    breadcrumbs,
    documentTitle,
}) => {
    useScrollToTopOnMount()

    return (
        <>
            <Navbar />
            {documentTitle && (
                <Helmet>
                    <title>{documentTitle}</title>
                </Helmet>
            )}
            <Container
                className={classNames(styles.defaultContainer, { [styles.topPadding]: isEmpty(heading) })}
                fluid={fluid}
            >
                {breadcrumbs && <Breadcrumbs breadcrumbs={breadcrumbs} className={styles.breadcrumbs} />}
                {heading !== undefined && (
                    <LayoutHeading
                        heading={heading}
                        headingButtons={headingButtons}
                        headingClassName={headingClassName}
                        headingType={headingType}
                    />
                )}
                <Content children={children} bare={false} loadingState={loadingState} />
            </Container>
        </>
    )
}

export default BarePageLayout
