import { faEdit, faTrash, faCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { round } from 'lodash'
import React from 'react'
import { Button, Table } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import { LocalityNameListResponse, StaySafeNotificationPreferences } from '@api'

import { displayPreciseDuration, formatLargeNumber } from '@helpers/displayUtils'

import styles from '@components/StatisticsSummary/LocalityTable/LocalityTable.module.scss'

import additionalStyles from './NotificationPreferencesTable.module.scss'
import TooltipIcon from './TooltipIcon'

interface Props {
    localities: LocalityNameListResponse
    notificationData: StaySafeNotificationPreferences[]

    onEdit: (data: StaySafeNotificationPreferences, index: number) => void
    onDelete: (index: number) => void
}

const NotificationPreferencesTable = ({ notificationData, localities, onEdit, onDelete }: Props) => {
    const { t } = useTranslation()
    const headers = [
        {
            name: t('table.status', 'Status'),
            key: 0,
            headTitleDescription: null,
        },
        {
            name: t('heading.locality', 'Locality'),
            key: 1,
            headTitleDescription: null,
        },
        {
            name: t('table.staySafeLimit', 'Stay Safe limit'),
            key: 2,
            headTitleDescription:
                'The number of people above the capacity threshold triggering the sending of notification.',
        },
        {
            name: t('table.sendNotificationAfter', 'Send notification after (sec)'),
            key: 3,
            headTitleDescription: t(
                'tooltip.sendNotificationAfter',
                "The period of time before exceeded Stay Safe limit triggers the first SMS notification to user's telephone number."
            ),
        },
        {
            name: t('table.repeatNotificationAfter', 'Repeat notification after (sec)'),
            key: 4,
            headTitleDescription: t(
                'tooltip.repeatNotificationAfter',
                'The interval between notifications in case the limit remains exceeded.'
            ),
        },
        {
            name: t('table.actions', 'Actions'),
            key: 5,
            headTitleDescription: null,
        },
    ]

    return (
        <Table className={styles.localityTable} responsive>
            <thead>
                <tr>
                    {headers.map((header) => (
                        <th key={header.key}>
                            {header.name}
                            {header.headTitleDescription && <TooltipIcon>{header.headTitleDescription}</TooltipIcon>}
                        </th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {notificationData.map((chunk, i) => (
                    // eslint-disable-next-line
                    <tr key={i}>
                        <td className={additionalStyles.localityStatusContainer}>
                            <FontAwesomeIcon
                                icon={faCircle}
                                style={{
                                    color: '#73C831',
                                    fontSize: '0.7em',
                                }}
                            />
                        </td>
                        <td>
                            <span className={styles.name}>
                                {localities?.localities?.find((l: { id: number }) => l.id === chunk.localityId)?.name ??
                                    ''}
                            </span>
                        </td>
                        <td>
                            <div className="d-flex">
                                <span
                                    className={notificationData.length > 1 ? styles.dataValueAligned : styles.dataValue}
                                >
                                    {formatLargeNumber(chunk.capacityExceededNotificationThreshold)}
                                </span>
                            </div>
                        </td>
                        <td>
                            <div className="d-flex">
                                <span
                                    className={notificationData.length > 1 ? styles.dataValueAligned : styles.dataValue}
                                >
                                    {formatLargeNumber(
                                        displayPreciseDuration(round(chunk.capacityExceededNotificationGracePeriod))
                                    )}
                                </span>
                            </div>
                        </td>
                        <td>
                            <div className="d-flex">
                                <span
                                    className={notificationData.length > 1 ? styles.dataValueAligned : styles.dataValue}
                                >
                                    {formatLargeNumber(
                                        displayPreciseDuration(round(chunk.capacityExceededNotificationCooldown))
                                    )}
                                </span>
                            </div>
                        </td>
                        <td>
                            <div className="d-flex">
                                <Button
                                    variant="link"
                                    onClick={() => {
                                        onEdit(chunk, i)
                                    }}
                                >
                                    <FontAwesomeIcon icon={faEdit} />
                                    {t('button.edit', 'Edit')}
                                </Button>
                                <Button
                                    className="ml-3"
                                    variant="link danger"
                                    onClick={() => {
                                        onDelete(i)
                                    }}
                                >
                                    <FontAwesomeIcon icon={faTrash} />
                                    {t('button.delete', 'Delete')}
                                </Button>
                            </div>
                        </td>
                    </tr>
                ))}
            </tbody>
        </Table>
    )
}

export default NotificationPreferencesTable
