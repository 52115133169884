import { noop, uniqueId } from 'lodash'
import { DateTime, Interval } from 'luxon'
import React, { useCallback, useMemo, useState } from 'react'
import { Button, Form, Overlay, Popover } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import { gdynusaReadableInterval } from '@helpers/datetimeUtils'
import { GoogleEventCategory, GoogleEventName, trackEvent } from '@helpers/gtag'
import { formatInterval, Precision } from '@helpers/intervals'
import { useLocalizeDateTime } from '@helpers/timezoneConfig'

import { useNotify } from '@components/notifications/NotificationsContext'

import DateTimeIntervalPicker from './DateTimeIntervalPicker'
import styles from './IntervalComparisonPicker.module.scss'

interface Props {
    isTimeDisabled?: boolean
    isOpen: boolean
    buttonRef: React.MutableRefObject<HTMLButtonElement | undefined>
    pickedInterval: Interval
    pickedComparisonInterval?: Interval
    onOpen: (isOpen: boolean) => void
    onSubmit: (interval: Interval, comparingInterval?: Interval) => void
    now: DateTime
}

const IntervalComparisonPicker: React.FC<Props> = ({ isTimeDisabled = true, now, ...props }) => {
    const popoverId = useMemo(uniqueId, [])
    const checkboxId = useMemo(uniqueId, [])
    const { t } = useTranslation()
    const localize = useLocalizeDateTime()
    const [isComparisonEnabled, setComparisonEnabled] = useState(props.pickedComparisonInterval !== undefined)
    const [interval, setInterval] = useState(props.pickedInterval)
    const [comparisonInterval, setComparisonInterval] = useState(props.pickedComparisonInterval ?? props.pickedInterval)
    const notify = useNotify()

    const localizedInterval = gdynusaReadableInterval(interval.mapEndpoints(localize))
    const localizedComparisonInterval = gdynusaReadableInterval(comparisonInterval.mapEndpoints(localize))

    const intervalVsComparisonIntervalLabel = `${formatInterval(localizedInterval, Precision.DAY, DateTime.DATE_SHORT)} 
    vs. ${formatInterval(localizedComparisonInterval, Precision.DAY, DateTime.DATE_SHORT)}`

    const handleComparisonIntervalCheckboxChanged = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            setComparisonEnabled(e.target.checked)

            if (e.target.checked && comparisonInterval.equals(props.pickedInterval)) {
                setComparisonInterval(interval.mapEndpoints((it) => it.minus(interval.length())))

                trackEvent(
                    GoogleEventCategory.STATISTICS,
                    GoogleEventName.COMPARE_WITH_PREVIOUS_INTERVAL_CHECKBOX,
                    intervalVsComparisonIntervalLabel
                )
            }
        },
        [interval, comparisonInterval, props.pickedInterval]
    )

    const handleSubmit: React.FormEventHandler = (event) => {
        event.preventDefault()

        if (isComparisonEnabled && comparisonInterval?.overlaps(interval)) {
            notify({
                title: t('notification.invalidSelection', 'Invalid selection'),
                content: t(
                    'notification.comparisonIntervalOverlap',
                    'Select a comparison interval which is not overlapping the original one.'
                ),
                variant: 'danger',
                timeoutSeconds: 10,
            })

            return
        }

        trackEvent(
            GoogleEventCategory.STATISTICS,
            GoogleEventName.SELECT_DATE_INTERVAL,
            formatInterval(localizedInterval, Precision.DAY, DateTime.DATE_SHORT)
        )
        props.onSubmit(interval, isComparisonEnabled ? comparisonInterval : undefined)
    }

    if (!props.buttonRef.current) {
        return null
    }

    return (
        <Overlay
            placement="bottom"
            rootClose={false}
            show={props.isOpen}
            target={props.buttonRef.current}
            onHide={() => props.onOpen(false)}
        >
            {(overlayProps: any) => (
                <Popover
                    className={styles.popover}
                    id={popoverId}
                    title={t('others.customInterval', 'Custom interval')}
                    {...overlayProps}
                    show={props.isOpen.toString()}
                >
                    <div className={styles.intervalPickerForm}>
                        <Form onSubmit={handleSubmit}>
                            <Form.Group>
                                <Form.Label column={false}>{t('others.selectInterval', 'Select interval')}</Form.Label>
                                <Form.Row>
                                    <DateTimeIntervalPicker
                                        interval={interval}
                                        isTimeDisabled={isTimeDisabled}
                                        now={now}
                                        onSubmit={setInterval}
                                    >
                                        <Form.Control
                                            type="text"
                                            value={formatInterval(
                                                localizedInterval,
                                                Precision.DAY,
                                                DateTime.DATE_SHORT
                                            )}
                                            onChange={noop}
                                        />
                                    </DateTimeIntervalPicker>
                                </Form.Row>
                            </Form.Group>
                            <Form.Group>
                                <Form.Check
                                    checked={isComparisonEnabled}
                                    id={checkboxId}
                                    label={t('others.compareWith', 'Compare with')}
                                    type="checkbox"
                                    custom
                                    onChange={handleComparisonIntervalCheckboxChanged}
                                />
                            </Form.Group>
                            {isComparisonEnabled && (
                                <Form.Group>
                                    <Form.Label column={false}>
                                        {t('others.comparisonInterval', 'Comparison interval')}
                                    </Form.Label>
                                    <Form.Row>
                                        <DateTimeIntervalPicker
                                            interval={comparisonInterval}
                                            isTimeDisabled={isTimeDisabled}
                                            now={now}
                                            onSubmit={setComparisonInterval}
                                        >
                                            <Form.Control
                                                type="text"
                                                value={formatInterval(
                                                    localizedComparisonInterval,
                                                    Precision.DAY,
                                                    DateTime.DATE_SHORT
                                                )}
                                                onChange={noop}
                                            />
                                        </DateTimeIntervalPicker>
                                    </Form.Row>
                                </Form.Group>
                            )}
                            <Button className="w-100" type="submit" variant="primary">
                                {t('button.apply', 'Apply')}
                            </Button>
                        </Form>
                    </div>
                </Popover>
            )}
        </Overlay>
    )
}

export default IntervalComparisonPicker
