import classNames from 'classnames'
import React from 'react'

import { passCSSVariable } from '@helpers/cssUtils'

import styles from './PlotButton.module.scss'

interface PlotButtonProps {
    buttonText: string
    isActive: boolean
    underlineColor?: string
    onClick: () => void
    id: string
}

const PlotButton: React.FC<PlotButtonProps> = ({ buttonText, onClick, isActive, id, underlineColor }) => {
    return (
        <button
            className={classNames({
                [styles.plotControlButtonActive]: isActive,
                [styles.plotControlButton]: !isActive,
            })}
            id={id}
            style={passCSSVariable('UnderlineColor', underlineColor)}
            onClick={(e) => {
                e.preventDefault()

                return onClick()
            }}
        >
            {buttonText}
        </button>
    )
}

export default PlotButton
