import classNames from 'classnames'
import React from 'react'
import { useTranslation } from 'react-i18next'

const NameCell: React.FC<{
    text: string
    className?: string
}> = ({ text, className }) => {
    const { t } = useTranslation()

    return text !== '' ? (
        <td className={className}>{text}</td>
    ) : (
        <td className={classNames(className, 'text-muted')}>{t('others.notSet', 'Not set')}</td>
    )
}

export default NameCell
