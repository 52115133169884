import { faPlus, faUserPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { isEmpty, noop, sortBy } from 'lodash'
import React, { useCallback, useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import { TokenProps, Token } from 'react-bootstrap-typeahead'
import Helmet from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import { useHistory, useParams } from 'react-router'
import { Link } from 'react-router-dom'

import { LocalityNameModel, OrganizationResponse, ProfileResponse, Role, UserResponse } from '@api'

import { authApi, localityApi, organizationApi, userApi } from '@services'

import { generateOrganizationsEditLocalitiesPath, VIVIDI_APP } from '@helpers/VividiURLs'
import { useApiCallCleaner } from '@helpers/api'
import { isApiError } from '@helpers/apiCallManipulation'
import { storeToken } from '@helpers/auth'
import useProfile from '@helpers/profile'

import { useDetectMobile } from '@hooks/useDetectDevice'

import Box from '@elements/Box/Box'

import AsyncButton from '@components/AsyncButton'
import AccordionLayout from '@components/Layouts/AccordionLayout'
import SideNavigationListLayout from '@components/Layouts/SideNavigationLayout'
import VerticalListGroup from '@components/NavigationLists/VerticalListGroup'
import styles from '@components/OrganizationEditForms/UsersTab/UsersTabForm.module.scss'
import { determineIconByRole } from '@components/UserRoleIndicator'
import { useNotify } from '@components/notifications/NotificationsContext'

import UsersTabForm, { UsersEditFormErrors, UserEditData } from './UsersTabForm'

const newEmptyUser = (locality?: LocalityNameModel): UserEditData | undefined => {
    return {
        user: { email: '' },
        role: Role.User,
        localities: locality !== undefined ? [locality] : [],
        sendInvitation: false,
    }
}

const userToEditData = (
    user: UserResponse | undefined,
    localities: Array<LocalityNameModel>,
    sendInvitation = false
): UserEditData | undefined =>
    user
        ? {
              user,
              role: user.role,
              localities: user.localityIds.map((id) => localities.find((l) => l.id === id)!),
              sendInvitation,
          }
        : undefined

const NEW_ITEM_ID = 'new'

const UsersTab: React.FC<{
    users: UserResponse[]
    localities: LocalityNameModel[]
    organization: OrganizationResponse
    selectedLocality?: LocalityNameModel
    determineEditUsersPath: (user?: UserResponse) => string | undefined
    determineCreateUserPath: (user?: UserResponse) => string
    isNewUser: boolean
    isTabActive: boolean
}> = ({
    users,
    localities,
    organization,
    selectedLocality,
    determineCreateUserPath,
    determineEditUsersPath,
    isNewUser,
    isTabActive,
}) => {
    const profile = useProfile()
    const mobile = useDetectMobile()
    const notify = useNotify()
    const params = useParams<{ userId?: string }>()
    const rearrangedUsers = sortBy(users, [({ role }) => (role === Role.User ? 1 : 0), ({ email }) => email])
    const userId = params.userId !== undefined ? parseInt(params.userId, 10) : undefined
    const selectedUser = users.find((u) => u.id === userId)
    const history = useHistory()
    const clean = useApiCallCleaner()
    const { t } = useTranslation()
    const [userData, setUserData] = useState<UserEditData | undefined>(undefined)
    const [isEdited, setEdited] = useState(false)
    const [errors, setErrors] = useState<UsersEditFormErrors>()
    const [isDeletionInProgress, setDeletionInProgress] = useState(false)

    const isValid = errors && !Object.values(errors).some((it) => it.isInvalid)

    const documentTitle = `${organization.name} - ${selectedUser ? selectedUser.email : t('form.users', 'Users')}`

    // Users tab triggers

    const { mutate: takeoverUserCall } = useMutation(authApi.takeover, {
        onSuccess: (data) => {
            storeToken(data)
            clean()
            history.push(VIVIDI_APP)
        },
        onError: noop,
    })

    const { mutate: deleteUserCall } = useMutation(userApi.deleteUser, {
        onMutate: () => {
            notify({
                title: t('notification.info', 'Information'),
                content: t(
                    'notification.userDeletionInitiated',
                    'User {{email}} deletion initiated, please wait for the page to reload.',
                    {
                        email: selectedUser?.email,
                    }
                ),
                variant: 'warning',
                timeoutSeconds: 15,
            })

            setDeletionInProgress(true)
        },
        onSuccess: () => {
            clean(userApi)
            clean(organizationApi)
            clean(localityApi)

            notify({
                title: t('notification.success', 'Success'),
                content: t('notification.userDeletedSuccessfully', 'User {{email}} deleted successfully.', {
                    email: selectedUser?.email,
                }),
                variant: 'success',
                timeoutSeconds: 4,
            })
        },
        onError: noop,
    })

    const userCreator = async ({ profile, values }: { profile: ProfileResponse; values: UserEditData }) => {
        try {
            const createdUser = await userApi.addUser({
                body: {
                    ...values.user,
                    language: profile.language,
                    organizationId: organization.id,
                    sendInvitation: values.sendInvitation ?? false,
                },
            })

            await userApi.setUserRole({ userId: createdUser.id, body: { role: values.role } })

            await userApi.setUserLocalities({
                userId: createdUser.id,
                body: { localities: values.localities.map((locality) => locality.id) },
            })

            clean(userApi)
            clean(organizationApi)
            clean(localityApi)
        } catch (error) {
            if (isApiError(error) && error.status === 409) {
                notify({
                    title: t('notification.error', 'Error'),
                    content: t('notification.userAlreadyExists', 'User you are trying to add already exists.'),
                    variant: 'danger',
                    timeoutSeconds: 4,
                })
            }
        }
    }

    const userUpdater = async ({ user, data }: { user: UserResponse; data: UserEditData }) => {
        const { id } = user

        try {
            await userApi.setUserRole({
                userId: id,
                body: {
                    role: data.role,
                },
            })

            await userApi.updateUser({
                userId: id,
                body: { email: data.user.email, phoneNumber: data.user.phoneNumber, language: data.user.language },
            })

            await userApi.setUserLocalities({
                userId: id,
                body: { localities: data.localities.map((locality) => locality.id) },
            })

            clean(userApi)
            clean(organizationApi)
            clean(localityApi)
        } catch (error) {
            return noop
        }
    }

    const { mutate: userCreate, status: userCreateState } = useMutation(userCreator)
    const { mutate: userUpdate, status: userUpdateState } = useMutation(userUpdater)

    useEffect(() => {
        setEdited(false)

        if (isNewUser) {
            if (selectedLocality) {
                setUserData(newEmptyUser(selectedLocality))
            } else {
                setUserData(newEmptyUser())
            }
        } else if (isTabActive && !mobile && selectedUser === undefined && !isEmpty(rearrangedUsers)) {
            handleUserSelection(rearrangedUsers[0], true)
        } else {
            setUserData(userToEditData(selectedUser, localities))
        }
    }, [selectedUser, isNewUser, localities, mobile, isTabActive])

    const handleUserSelection = (user?: UserResponse, forceReplace = false) => {
        const url = determineEditUsersPath(user) ?? VIVIDI_APP

        if (selectedUser || forceReplace) {
            history.replace(url)
        } else {
            history.push(url)
        }
    }

    const handleNewUser = () => {
        history.push(determineCreateUserPath(selectedUser))
    }

    const deleteUser = (userId: number) => deleteUserCall({ userId })
    const takeoverUser = (userId: number) => takeoverUserCall({ userId })

    const saveButton = (
        <AsyncButton
            disabled={!isValid}
            loadingText={t('button.savingChanges', 'Saving changes...')}
            status={userUpdateState}
            text={t('button.saveChanges', 'Save changes')}
            type="submit"
            variant="primary"
            onClick={(e) => {
                e.preventDefault()

                if (userData && isValid) {
                    userUpdate({ user: selectedUser!, data: userData })
                }
            }}
        />
    )

    const createAndInviteButton = (
        <AsyncButton
            disabled={!isValid}
            loadingText={t('button.savingChanges', 'Saving changes...')}
            status={userCreateState}
            text={t('button.createAndInvite', 'Create and send invitation e-mail')}
            type="submit"
            variant="primary"
            onClick={(e) => {
                e.preventDefault()

                if (profile.data) {
                    userData && userCreate({ profile: profile.data, values: { ...userData, sendInvitation: true } })
                }
            }}
        />
    )

    const createWithoutInviteButton = (
        <AsyncButton
            disabled={!isValid}
            loadingText={t('button.savingChanges', 'Saving changes...')}
            status={userCreateState}
            text={t('button.createUser', 'Create User')}
            type="submit"
            variant="primary"
            onClick={(e) => {
                e.preventDefault()

                if (profile.data) {
                    userData && userCreate({ profile: profile.data, values: userData })
                }
            }}
        />
    )

    const cancelButton = (
        <Button
            variant="secondary"
            onClick={() => {
                if (isNewUser) {
                    const nextUser = mobile ? undefined : rearrangedUsers[0]
                    handleUserSelection(nextUser)
                } else {
                    setUserData(userToEditData(selectedUser, localities))
                    setEdited(false)
                }
            }}
        >
            {t('button.cancel', 'Cancel')}
        </Button>
    )

    const addUserButton = (
        <Button variant="secondary" onClick={handleNewUser}>
            <FontAwesomeIcon icon={faPlus} />
            {t('button.addUser', 'Add user')}
        </Button>
    )
    const LocalityTokenWithLink: React.FC<TokenProps & { tokenId?: number }> = useCallback(
        ({ disabled, onRemove, children, tokenId }) => (
            <Token key={String(tokenId)} disabled={disabled} onRemove={onRemove}>
                <Link to={generateOrganizationsEditLocalitiesPath(organization.id, tokenId ?? -1)}>{children}</Link>
            </Token>
        ),
        [organization]
    )

    const form = userData && (
        <UsersTabForm
            editedUser={selectedUser}
            errors={errors}
            isDeletionInProgress={isDeletionInProgress}
            isNewUser={isNewUser}
            localities={localities}
            localityPickerTokenRenderer={LocalityTokenWithLink}
            organization={organization}
            profile={profile}
            saveChangesButton={
                mobile ? (
                    <>
                        {(isEdited || isNewUser) && (
                            <div className={styles.createButtonContainer}>
                                {isNewUser ? (
                                    <>
                                        {createWithoutInviteButton}
                                        {createAndInviteButton}
                                    </>
                                ) : (
                                    saveButton
                                )}
                                {cancelButton}
                            </div>
                        )}
                    </>
                ) : undefined
            }
            user={userData}
            onDelete={() => selectedUser && deleteUser(selectedUser.id)}
            onErrorsChanged={setErrors}
            onTakeover={() => selectedUser && takeoverUser(selectedUser.id)}
            onUserUpdate={(updatedUser) => {
                setUserData((prev) => (prev ? { ...prev, ...updatedUser } : undefined))
                setEdited(true)
            }}
        />
    )

    const placeholder = (
        <div className={styles.noItemsCreatedContainer}>
            <span className={styles.noItemsCreated}>{t('others.noUsersCreated', 'No users created')}</span>
        </div>
    )

    const navigationItems = rearrangedUsers.map(({ id, email, role }) => ({
        id: String(id),
        displayText: email,
        icon: determineIconByRole(role),
    }))

    const navigationItemsWithNew = isNewUser
        ? [
              {
                  id: NEW_ITEM_ID,
                  displayText: t('others.newUser', 'New user'),
                  icon: faUserPlus,
              },
              ...navigationItems,
          ]
        : navigationItems

    const desktopNavigation = (
        <VerticalListGroup
            selectableEntries={navigationItemsWithNew}
            selectedEntry={isNewUser ? NEW_ITEM_ID : selectedUser?.id.toString()}
            unfilteredSelectableEntries={navigationItemsWithNew}
            onSelectionChange={(id) => handleUserSelection(users.find((u) => u.id.toString() === id))}
        />
    )

    return (
        <>
            <Helmet>
                <title>{documentTitle}</title>
            </Helmet>
            <Box paddingSize="lg">
                {mobile ? (
                    <AccordionLayout
                        heading={t('form.users', 'Users')!}
                        headingButtons={<>{!isNewUser && addUserButton}</>}
                        items={navigationItemsWithNew}
                        placeholder={placeholder}
                        selectedItem={isNewUser ? NEW_ITEM_ID : selectedUser?.id.toString()}
                        selectedItemContent={form ?? <></>}
                        onSelect={(id) => handleUserSelection(users.find((u) => u.id.toString() === id))}
                    />
                ) : (
                    <SideNavigationListLayout
                        content={form}
                        contentHeading={{
                            heading: !userData ? placeholder : '',
                            headingButtons: (
                                <>
                                    {(isEdited || isNewUser) && (
                                        <div className={styles.controlButtonRow}>
                                            {isNewUser ? (
                                                <>
                                                    {createWithoutInviteButton}
                                                    {createAndInviteButton}
                                                </>
                                            ) : (
                                                saveButton
                                            )}
                                            {cancelButton}
                                        </div>
                                    )}
                                </>
                            ),
                        }}
                        listHeading={{
                            heading: t('form.users', 'Users'),
                            headingButtons: !isNewUser ? addUserButton : undefined,
                        }}
                        navigation={desktopNavigation}
                    />
                )}
            </Box>
        </>
    )
}

export default UsersTab
