/* tslint:disable */
/* eslint-disable */
/**
 * Vividi Dashboard API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ZoneOccupancySummaryRow
 */
export interface ZoneOccupancySummaryRow {
    /**
     * 
     * @type {number}
     * @memberof ZoneOccupancySummaryRow
     */
    deviceId: number;
    /**
     * 
     * @type {number}
     * @memberof ZoneOccupancySummaryRow
     */
    sceneId: number;
    /**
     * 
     * @type {number}
     * @memberof ZoneOccupancySummaryRow
     */
    zoneId: number;
    /**
     * 
     * @type {string}
     * @memberof ZoneOccupancySummaryRow
     */
    zoneName: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof ZoneOccupancySummaryRow
     */
    cells: Array<number>;
}

export function ZoneOccupancySummaryRowFromJSON(json: any): ZoneOccupancySummaryRow {
    return ZoneOccupancySummaryRowFromJSONTyped(json, false);
}

export function ZoneOccupancySummaryRowFromJSONTyped(json: any, ignoreDiscriminator: boolean): ZoneOccupancySummaryRow {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'deviceId': json['device_id'],
        'sceneId': json['scene_id'],
        'zoneId': json['zone_id'],
        'zoneName': json['zone_name'],
        'cells': json['cells'],
    };
}

export function ZoneOccupancySummaryRowToJSON(value?: ZoneOccupancySummaryRow | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'device_id': value.deviceId,
        'scene_id': value.sceneId,
        'zone_id': value.zoneId,
        'zone_name': value.zoneName,
        'cells': value.cells,
    };
}

