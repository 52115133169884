import React from 'react'
import { useTranslation } from 'react-i18next'

import logo from '@images/logo/logo.svg'

import styles from './PageNotFound.module.scss'

const PageNotFound: React.FC = () => {
    const { t } = useTranslation()

    return (
        <div className={styles.container}>
            <img alt="Iterait logo" className={styles.logo} src={logo} />
            <div className={styles.content}>
                <span className={styles.notFound}>{t('pageNotFound.title', '404 Page not found')}</span>
                <p className={styles.message}>
                    {t('pageNotFound.message-1', 'It seems we missed something. Let us try it again and')}{' '}
                    <a className={styles.link} href="/">
                        {t('pageNotFound.message-2', 'go back to safety')}
                    </a>
                </p>
            </div>
        </div>
    )
}

export default PageNotFound
