import React from 'react'
import { Form, FormControlProps } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import { OrganizationResponse } from '@api/models'

import { orderOrganizations } from '@helpers/orderFunctions'

interface OrganizationPickerProps extends Omit<FormControlProps, 'onChange'> {
    organizationId?: number
    onChange: (organizationId?: number) => void
    organizations: { [organizationId: number]: OrganizationResponse }
}

const OrganizationPicker = ({ organizationId, organizations, ...props }: OrganizationPickerProps) => {
    const { t } = useTranslation()

    return (
        <Form.Control
            {...props}
            as="select"
            value={organizationId ? organizationId.toString() : ''}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => props.onChange(parseInt(e.target.value, 10))}
        >
            <option key="" value="">
                {t('button.noOrganization', 'No organization')}
            </option>
            {orderOrganizations(Object.values(organizations)).map((organization) => (
                <option key={organization.id} value={organization.id}>
                    {organization.name}
                </option>
            ))}
        </Form.Control>
    )
}
export default OrganizationPicker
