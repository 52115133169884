import classNames from 'classnames'
import { Interval } from 'luxon'
import React from 'react'
import { Container } from 'react-bootstrap'

import { OrganizationResponse, LocalityNameModel } from '@api'

import FloorplanDropdown from '@components/FloorplanForm/FloorplanDropdown'
import LocalityPicker from '@components/LocalityPicker'
import Navbar from '@components/Navbar/Navbar'

import styles from './FloorplanHeatmapDetail.module.scss'
import FloorplanHeatmapDetailDatePaginator from './FloorplanHeatmapDetailDatePaginator'

interface Props {
    selectedInterval: Interval
    availableIntervals: Array<Array<string>>
    selectedFloorplan?: number
    floorplans: [string, string][]
    children?: React.ReactNode | React.ReactNode[]
    localities: LocalityNameModel[]
    organizations: OrganizationResponse[]
    onLocalityChange: (locality: LocalityNameModel) => void
    onFloorplanSelect: (floorplanId: number) => void
    onNavigation: (interval: Interval) => void
    selectedLocality?: LocalityNameModel
}

const FloorplanHeatmapDetailLayout: React.FC<Props> = ({
    selectedInterval,
    availableIntervals,
    selectedFloorplan,
    floorplans,
    children = null,
    localities,
    organizations,
    onNavigation,
    onLocalityChange,
    onFloorplanSelect,
    selectedLocality,
}) => {
    const hasMultipleFloorplans = selectedFloorplan && floorplans.length > 1

    return (
        <>
            <Navbar />
            <div
                className={classNames(
                    { [styles.fourColumns]: hasMultipleFloorplans, [styles.threeColumns]: !hasMultipleFloorplans },
                    styles.toolbar
                )}
            >
                <LocalityPicker
                    features={['floorplans']}
                    localities={localities}
                    organizations={organizations}
                    selectedLocality={selectedLocality}
                    onSelect={onLocalityChange}
                />
                {hasMultipleFloorplans && (
                    <FloorplanDropdown
                        floorplans={floorplans}
                        selectedFloorplan={selectedFloorplan}
                        onSelect={onFloorplanSelect}
                    />
                )}
                <FloorplanHeatmapDetailDatePaginator
                    availableIntervals={availableIntervals}
                    selectedInterval={selectedInterval}
                    onNavigation={onNavigation}
                />
            </div>
            <Container className={styles.content}>{children}</Container>
        </>
    )
}

export default FloorplanHeatmapDetailLayout
