import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Form, InputGroup } from 'react-bootstrap'

import styles from './SearchBoxInput.module.scss'

type Props = {
    placeholder: string
    value?: string
    onChange?: (value: string) => void
    isInvalid?: boolean
    autoFocus?: boolean
    plaintext?: boolean
    className?: string
}

const SearchBoxInput = (props: Props) => {
    const { className, onChange } = props

    const handleChange = (value: string) => {
        if (onChange) {
            onChange(value)

            return
        }
    }

    return (
        <InputGroup className={className}>
            <InputGroup.Prepend>
                <InputGroup.Text className={styles.iconContainer}>
                    <FontAwesomeIcon icon={faSearch} />
                </InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Control
                {...props}
                className=""
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e.target.value)}
            />
        </InputGroup>
    )
}

export default SearchBoxInput
