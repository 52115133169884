/* tslint:disable */
/* eslint-disable */
/**
 * Vividi Dashboard API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LocalityConfigurationStateModel
 */
export interface LocalityConfigurationStateModel {
    /**
     * 
     * @type {number}
     * @memberof LocalityConfigurationStateModel
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof LocalityConfigurationStateModel
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof LocalityConfigurationStateModel
     */
    organizationId: number;
    /**
     * 
     * @type {string}
     * @memberof LocalityConfigurationStateModel
     */
    timezone: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof LocalityConfigurationStateModel
     */
    labels: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof LocalityConfigurationStateModel
     */
    isStaySafeConfigured: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LocalityConfigurationStateModel
     */
    isStaySafeFloorplanConfigured: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LocalityConfigurationStateModel
     */
    isFootfallConfigured: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LocalityConfigurationStateModel
     */
    isConversionsConfigured: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LocalityConfigurationStateModel
     */
    isQueuesConfigured: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LocalityConfigurationStateModel
     */
    isOccupancyConfigured: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LocalityConfigurationStateModel
     */
    isEmotionsConfigured: boolean;
}

export function LocalityConfigurationStateModelFromJSON(json: any): LocalityConfigurationStateModel {
    return LocalityConfigurationStateModelFromJSONTyped(json, false);
}

export function LocalityConfigurationStateModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): LocalityConfigurationStateModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'organizationId': json['organization_id'],
        'timezone': json['timezone'],
        'labels': json['labels'],
        'isStaySafeConfigured': json['is_stay_safe_configured'],
        'isStaySafeFloorplanConfigured': json['is_stay_safe_floorplan_configured'],
        'isFootfallConfigured': json['is_footfall_configured'],
        'isConversionsConfigured': json['is_conversions_configured'],
        'isQueuesConfigured': json['is_queues_configured'],
        'isOccupancyConfigured': json['is_occupancy_configured'],
        'isEmotionsConfigured': json['is_emotions_configured'],
    };
}

export function LocalityConfigurationStateModelToJSON(value?: LocalityConfigurationStateModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'organization_id': value.organizationId,
        'timezone': value.timezone,
        'labels': value.labels,
        'is_stay_safe_configured': value.isStaySafeConfigured,
        'is_stay_safe_floorplan_configured': value.isStaySafeFloorplanConfigured,
        'is_footfall_configured': value.isFootfallConfigured,
        'is_conversions_configured': value.isConversionsConfigured,
        'is_queues_configured': value.isQueuesConfigured,
        'is_occupancy_configured': value.isOccupancyConfigured,
        'is_emotions_configured': value.isEmotionsConfigured,
    };
}

