/* tslint:disable */
/* eslint-disable */
/**
 * Vividi Dashboard API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OpeningHoursInterval,
    OpeningHoursIntervalFromJSON,
    OpeningHoursIntervalFromJSONTyped,
    OpeningHoursIntervalToJSON,
} from './OpeningHoursInterval';

/**
 * 
 * @export
 * @interface OpeningHours
 */
export interface OpeningHours {
    /**
     * 
     * @type {Array<OpeningHoursInterval>}
     * @memberof OpeningHours
     */
    mon: Array<OpeningHoursInterval>;
    /**
     * 
     * @type {Array<OpeningHoursInterval>}
     * @memberof OpeningHours
     */
    tue: Array<OpeningHoursInterval>;
    /**
     * 
     * @type {Array<OpeningHoursInterval>}
     * @memberof OpeningHours
     */
    wed: Array<OpeningHoursInterval>;
    /**
     * 
     * @type {Array<OpeningHoursInterval>}
     * @memberof OpeningHours
     */
    thu: Array<OpeningHoursInterval>;
    /**
     * 
     * @type {Array<OpeningHoursInterval>}
     * @memberof OpeningHours
     */
    fri: Array<OpeningHoursInterval>;
    /**
     * 
     * @type {Array<OpeningHoursInterval>}
     * @memberof OpeningHours
     */
    sat: Array<OpeningHoursInterval>;
    /**
     * 
     * @type {Array<OpeningHoursInterval>}
     * @memberof OpeningHours
     */
    sun: Array<OpeningHoursInterval>;
}

export function OpeningHoursFromJSON(json: any): OpeningHours {
    return OpeningHoursFromJSONTyped(json, false);
}

export function OpeningHoursFromJSONTyped(json: any, ignoreDiscriminator: boolean): OpeningHours {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'mon': ((json['mon'] as Array<any>).map(OpeningHoursIntervalFromJSON)),
        'tue': ((json['tue'] as Array<any>).map(OpeningHoursIntervalFromJSON)),
        'wed': ((json['wed'] as Array<any>).map(OpeningHoursIntervalFromJSON)),
        'thu': ((json['thu'] as Array<any>).map(OpeningHoursIntervalFromJSON)),
        'fri': ((json['fri'] as Array<any>).map(OpeningHoursIntervalFromJSON)),
        'sat': ((json['sat'] as Array<any>).map(OpeningHoursIntervalFromJSON)),
        'sun': ((json['sun'] as Array<any>).map(OpeningHoursIntervalFromJSON)),
    };
}

export function OpeningHoursToJSON(value?: OpeningHours | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'mon': ((value.mon as Array<any>).map(OpeningHoursIntervalToJSON)),
        'tue': ((value.tue as Array<any>).map(OpeningHoursIntervalToJSON)),
        'wed': ((value.wed as Array<any>).map(OpeningHoursIntervalToJSON)),
        'thu': ((value.thu as Array<any>).map(OpeningHoursIntervalToJSON)),
        'fri': ((value.fri as Array<any>).map(OpeningHoursIntervalToJSON)),
        'sat': ((value.sat as Array<any>).map(OpeningHoursIntervalToJSON)),
        'sun': ((value.sun as Array<any>).map(OpeningHoursIntervalToJSON)),
    };
}

