import { faFilter } from '@fortawesome/pro-solid-svg-icons'
import { Dictionary, isEmpty, uniqueId } from 'lodash'
import React, { useMemo, useState } from 'react'
import { Button, Form, OverlayTrigger, Popover } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import { LocalityNameModel, SceneDescription, SceneListResponse, VisitBoundaryWithId } from '@api'

import { GoogleEventCategory, GoogleEventName, trackEvent } from '@helpers/gtag'
import { orderScenes } from '@helpers/orderFunctions'

import ToggleIcon from '@elements/ToggleIcon/ToggleIcon'

import styles from './BoundaryPicker.module.scss'
import filterButtonStyles from './StatisticsSummary/FilterButton.module.scss'

type Props = {
    selectedLocality: LocalityNameModel
    selectedBoundaries: VisitBoundaryWithId[]
    sceneConfiguration: Dictionary<SceneDescription>
    scenes: SceneListResponse
    onBoundariesChange: (boundaries: VisitBoundaryWithId[]) => void
}

const BoundaryPicker = ({
    selectedLocality,
    selectedBoundaries,
    sceneConfiguration,
    scenes,
    onBoundariesChange,
}: Props) => {
    const { t } = useTranslation()

    const id = useMemo(uniqueId, [])
    const [isPickerOpen, setIsPickerOpen] = useState<boolean>(false)

    const localityScenes = scenes.scenes.filter((s) => s.localityIds.includes(selectedLocality.id))

    const handleBoundarySwitch = (boundary: VisitBoundaryWithId) => {
        const isSelected = selectedBoundaries.map((b) => b.id).includes(boundary.id)

        if (isSelected) {
            const boundariesWithoutClickedBoundary = selectedBoundaries.filter((b) => b.id !== boundary.id)

            if (selectedBoundaries.length === 1) {
                onBoundariesChange([])
            } else {
                onBoundariesChange(boundariesWithoutClickedBoundary)
            }
        } else {
            onBoundariesChange([...selectedBoundaries, boundary])
        }
    }

    const scenesContainer = orderScenes(localityScenes).map((scene) => {
        const sceneBoundaries = sceneConfiguration[scene.id].visitBoundaries

        if (!isEmpty(sceneBoundaries)) {
            return (
                <React.Fragment key={`${scene.id} filter`}>
                    <div className={filterButtonStyles.localityTitleContainer}>
                        {scene.label}
                        {sceneBoundaries.length > 1 && (
                            <Button
                                className={filterButtonStyles.selectOnly}
                                variant="link"
                                onClick={() => onBoundariesChange(sceneBoundaries)}
                            >
                                {t('button.selectAll', 'select all')}
                            </Button>
                        )}
                    </div>
                    <div className={styles.boundariesContainer}>
                        {sceneBoundaries &&
                            sceneBoundaries.map((boundary) => (
                                <div key={`${id}_${boundary.id}_container`} className={styles.boundaryContainer}>
                                    <Form.Check
                                        checked={selectedBoundaries?.map((b) => b.id).includes(boundary.id)}
                                        id={`${id}_${boundary.id}`}
                                        label={
                                            boundary.name
                                                ? boundary.name
                                                : t('others.untitledBoundary', 'Untitled boundary')
                                        }
                                        type="checkbox"
                                        custom
                                        onChange={() => {
                                            handleBoundarySwitch(boundary)
                                        }}
                                    />
                                    {!isEmpty(sceneBoundaries) && (
                                        <Button
                                            className={filterButtonStyles.selectOnly}
                                            variant="link"
                                            onClick={() => onBoundariesChange([boundary])}
                                        >
                                            {t('button.selectOnly', 'select only')}
                                        </Button>
                                    )}
                                </div>
                            ))}
                    </div>
                </React.Fragment>
            )
        }

        return <React.Fragment key={`${scene.id} filter`} />
    })

    return (
        <>
            {!isEmpty(scenes.scenes) && (
                <OverlayTrigger
                    overlay={
                        <Popover className="wide" id={id}>
                            <Popover.Content>
                                <div className={filterButtonStyles.devicesContainer}>{scenesContainer}</div>
                            </Popover.Content>
                        </Popover>
                    }
                    placement="bottom"
                    rootClose={true}
                    show={isPickerOpen}
                    trigger={['click']}
                    onToggle={setIsPickerOpen}
                >
                    <ToggleIcon
                        icon={faFilter}
                        selected={!isEmpty(selectedBoundaries)}
                        tooltipText={
                            !isEmpty(selectedBoundaries)
                                ? t('button.cancelFilter', 'Cancel filter')
                                : t('button.filterBoundaries', 'Filter boundaries')
                        }
                        onToggle={() => {
                            if (!isEmpty(selectedBoundaries)) {
                                onBoundariesChange([])
                            } else {
                                trackEvent(
                                    GoogleEventCategory.STATISTICS,
                                    GoogleEventName.FOOTFALL_FILTER_BOUNDARIES_BUTTON_CLICK,
                                    selectedLocality.name
                                )
                                setIsPickerOpen((prev) => !prev)
                            }
                        }}
                    />
                </OverlayTrigger>
            )}
        </>
    )
}

export default BoundaryPicker
