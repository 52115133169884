import { fromPairs, keyBy } from 'lodash'
import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import {
    DeviceResponse,
    OrganizationResponse,
    DeviceListResponse,
    LocalityConfigurationStateModel,
    DeviceStatusInformation,
    SceneResponse,
} from '@api'

import DeviceListByOrganization from './DeviceListByOrganization'
import DeviceListSearchToolbar from './DeviceListSearchToolbar'

type Props = {
    devices: DeviceListResponse
    scenes: { [deviceId: string]: SceneResponse }
    status: { [deviceId: string]: DeviceStatusInformation }
    organizations: OrganizationResponse[]
    localities: LocalityConfigurationStateModel[]
}

const DeviceListByOrganizationWithSearchBar = ({ scenes, devices, organizations, status, localities }: Props) => {
    const history = useHistory()
    const location = useLocation()
    const { search } = location
    const deviceQuery = new URLSearchParams(search).get('device-id')
    const organizationQuery = new URLSearchParams(search).get('organization-id')

    const filteredDevices =
        deviceQuery !== null
            ? devices.devices.filter((it) => it.id === Number(deviceQuery))
            : organizationQuery !== null
            ? devices.devices.filter((it) => it.organizationId === Number(organizationQuery))
            : devices.devices

    const filteredOrganizations = fromPairs(
        organizations
            .filter((it) => organizationQuery === null || it.id === Number(organizationQuery))
            .map((organization) => [organization.id, organization])
    )

    const handleResetSearch = () => {
        history.replace(location.pathname, { ...location, search: '' })
    }

    const updateSelectedDevices = (devices: DeviceResponse[]) => {
        if (devices.length === 1) {
            history.replace(`?device-id=${devices[0].id}`)
        }
    }

    const updateSelectedOrganizations = (orgs: { [id: number]: OrganizationResponse }) => {
        if (Object.values(orgs).length === 1) {
            history.replace(`?organization-id=${Object.keys(orgs)[0]}`)
        }
    }

    return (
        <>
            <DeviceListSearchToolbar
                handleResetSearch={handleResetSearch}
                scenes={scenes}
                selectableDevices={devices.devices}
                selectableOrganizations={filteredOrganizations}
                selectedDevices={deviceQuery ? filteredDevices : []}
                selectedOrganizations={organizationQuery ? Object.values(filteredOrganizations) : []}
                onDeviceSelect={updateSelectedDevices}
                onOrganizationSelect={updateSelectedOrganizations}
            />
            <DeviceListByOrganization
                connections={devices.connections}
                devices={filteredDevices}
                localities={keyBy(localities, (l) => l.id)}
                organizations={keyBy(filteredOrganizations, (o) => o.id)}
                scenes={scenes}
                showLocality={false}
                status={status}
            />
        </>
    )
}

export default DeviceListByOrganizationWithSearchBar
