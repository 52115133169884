import { faSimCard, faWifi } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import { isEmpty, pick } from 'lodash'
import { DateTime, Duration } from 'luxon'
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Link, useHistory } from 'react-router-dom'

import { DeviceResponse, DeviceStatusInformation, LocalityConfigurationStateModel, SceneResponse } from '@api/models'

import { generateOrganizationEditDevicePath } from '@helpers/VividiURLs'
import { useAddBackLink } from '@helpers/backlinks'
import { displayEnv } from '@helpers/displayUtils'
import { orderLocalities } from '@helpers/orderFunctions'

import ResponsiveTable from '@components/ResponsiveTable/ResponsiveTable'
import tableStyles from '@components/ResponsiveTable/ResponsiveTable.module.scss'
import TooltipIcon from '@components/TooltipIcon'
import TooltipWrapper from '@components/TooltipWrapper'
import palette from '@components/palette.module.scss'
import { parseDate } from '@components/plots/common'

import styles from './DeviceList.module.scss'

const ConnectionTypeIndicator: React.FC<{
    status?: DeviceStatusInformation
    device: DeviceResponse
}> = ({ status, device }) => {
    const { t } = useTranslation()

    if (status === undefined) {
        return null
    }

    const isLTEDevice = !isEmpty(status.iccid) || !isEmpty(status.imsi)

    const isConnected =
        device.lastCaptureAt && DateTime.utc().diff(parseDate(device.lastCaptureAt)) <= Duration.fromMillis(60 * 1000)

    return (
        <TooltipWrapper
            tooltipText={
                isLTEDevice
                    ? device.simPhoneNumber !== undefined
                        ? t('table.phoneNumber', 'Phone number: {{value}}', {
                              value: device.simPhoneNumber,
                          })
                        : t('table.noPhoneNumber', 'No phone number is configured')
                    : t('table.wifiDevice', 'Wi-Fi device')
            }
        >
            <FontAwesomeIcon
                color={isConnected ? palette.positiveTrendGreen : undefined}
                icon={isLTEDevice ? faSimCard : faWifi}
            />
        </TooltipWrapper>
    )
}

const DeviceList: React.FC<{
    devices: DeviceResponse[]
    organizationId: number
    localities: { [id: number]: LocalityConfigurationStateModel }
    connections?: { [id: number]: boolean }
    showLocality: boolean
    status?: { [deviceId: string]: DeviceStatusInformation }
    scenes: { [deviceId: string]: SceneResponse }
}> = (props) => {
    const { t } = useTranslation()
    const history = useHistory()
    const addBackLink = useAddBackLink()

    if (props.devices.length === 0) {
        return <p className="lead"> {t('appDashboard.noDevices', 'No devices')}</p>
    }

    return (
        <>
            <ResponsiveTable className={tableStyles.horizontalTable}>
                <thead>
                    <tr>
                        <th>{t('table.device', 'Device')}</th>
                        <th>ID</th>
                        {props.showLocality && <th>Locality</th>}
                        {!isEmpty(props.status) && (
                            <>
                                <th className={styles.generationHeader}>{t('deviceTable.generation', 'Generation')}</th>
                                <th className={styles.connectionHeader}>{t('deviceTable.connectionType', 'Type')}</th>
                            </>
                        )}
                    </tr>
                </thead>
                <tbody>
                    {props.devices.map((device) => {
                        const scene = props.scenes[device.id.toString()]
                        const localities = pick(props.localities, scene?.localityIds ?? [])
                        const link = addBackLink(generateOrganizationEditDevicePath(props.organizationId, device.id))

                        return (
                            <tr
                                key={device.id}
                                className={classNames(styles.deviceLabelContainer, {
                                    'table-secondary': device.transferred,
                                    [styles.longRangeRow]: scene?.captureSettings.isLongRange,
                                    'table-warning': !device.isApproved,
                                })}
                                onClick={() => history.push(link)}
                            >
                                <td>
                                    <Link className={styles.deviceLink} to={link}>
                                        {device.transferred && (
                                            <strong className="text-uppercase">
                                                -- transferred {displayEnv(device.transferred.to)} --{' '}
                                            </strong>
                                        )}
                                        {scene?.captureSettings.isLongRange && (
                                            <strong className="text-uppercase">long range </strong>
                                        )}
                                        {!device.isApproved && (
                                            <strong className="text-uppercase">-- not approved -- </strong>
                                        )}
                                        {scene?.label || (
                                            <span className="text-muted">
                                                {t('others.anonymousDevice', 'Anonymous device')}
                                            </span>
                                        )}
                                        {device.transferred && (
                                            <TooltipIcon>
                                                <Trans
                                                    i18nKey="others.transferredTo"
                                                    values={{
                                                        value: device.transferred.to,
                                                        date: parseDate(device.transferred.at).toLocaleString(),
                                                    }}
                                                />
                                            </TooltipIcon>
                                        )}
                                    </Link>
                                </td>
                                <td>{device.id}</td>
                                {props.showLocality && (
                                    <td>
                                        {orderLocalities(Object.values(localities))
                                            .map((l) => l.name)
                                            .join(', ')}
                                    </td>
                                )}
                                {!isEmpty(props.status) && (
                                    <>
                                        <td className={styles.generationValue}>
                                            {props.status?.[device.id.toString()]?.generation}
                                        </td>
                                        <td className={styles.connectionValue}>
                                            <ConnectionTypeIndicator
                                                device={device}
                                                status={props.status?.[device.id.toString()]}
                                            />
                                        </td>
                                    </>
                                )}
                            </tr>
                        )
                    })}
                </tbody>
            </ResponsiveTable>
        </>
    )
}

export default DeviceList
