import { faExternalLinkAlt, faMapPin } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { head, isEmpty, last, maxBy, minBy } from 'lodash'
import { DateTime, Interval } from 'luxon'
import React, { ChangeEvent, useMemo, useState } from 'react'
import { Button, Form, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { NavLink, useParams } from 'react-router-dom'

import { FloorplanHeatmapType, LocalityConfigurationStateModel, LocalityResponse, Role } from '@api'

import { statisticsApi } from '@services'

import { generateOrganizationEditLocalityFloorplanMappingPath, generateHeatmapDetailPath } from '@helpers/VividiURLs'
import { useSuspendingQuery, visibleLocalitiesInfo } from '@helpers/api'
import { gdynusaReadableInterval } from '@helpers/datetimeUtils'
import { formatInterval, Precision } from '@helpers/intervals'
import { useLocalizeDateTime } from '@helpers/timezoneConfig'

import Box from '@elements/Box/Box'

import ErrorView from '@components/ErrorView'
import { AutoSizedImageWithLegendWrapper } from '@components/FloorplanForm/Floorplan'
import LegacyLoadingWrapper from '@components/LegacyLoadingWrapper'
import LoadingSpinner from '@components/LoadingSpinner'
import RoleChecker from '@components/RoleChecker'
import FloorplanHeatmapDisplayBox from '@components/StatisticsSummary/FloorplanHeatmapDisplayBox'
import LocalityDropdown from '@components/StatisticsSummary/LocalityDropdown'
import { parseDate } from '@components/plots/common'

import heatmapLegend from '@images/heatmap_legend.png'

import styles from './FloorplanHeatmapSection.module.scss'

type Params = {
    intervalStart: string
    intervalEnd: string
}

interface Props {
    selectedLocalities: LocalityConfigurationStateModel[]
}

const HeatmapSectionWrapper: React.FC<Props> = ({ selectedLocalities }) => {
    const { t } = useTranslation()

    const localitiesWithFloorplans = selectedLocalities.filter((l) => l.isStaySafeFloorplanConfigured)

    const localitiesInfoCall = useQuery({
        ...visibleLocalitiesInfo(localitiesWithFloorplans),
    })

    return (
        <LegacyLoadingWrapper
            errorComponent={
                <ErrorView message={t('others.couldNotFetchConfiguration', 'Could not fetch device configuration')} />
            }
            placeholder={
                <Box paddingSize="lg">
                    <Box.Title text={t('heading.spaceUtilization', 'Space Utilization')} />
                    <LoadingSpinner bare />
                </Box>
            }
            request={localitiesInfoCall}
        >
            {(localitiesInfo) => {
                return <HeatmapSection selectedLocalities={localitiesInfo} />
            }}
        </LegacyLoadingWrapper>
    )
}

interface HeatmapSectionProps {
    selectedLocalities: LocalityResponse[]
}

const HeatmapSection: React.FC<HeatmapSectionProps> = ({ selectedLocalities }: HeatmapSectionProps) => {
    const params = useParams<Params>()

    const { t } = useTranslation()

    const localize = useLocalizeDateTime()

    const [selectedLocality, setSelectedLocality] = useState(head(selectedLocalities) ?? null)

    const [selectedFloorplan, setSelectedFloorplan] = useState<number | null>(
        selectedLocality ? Number(Object.keys(selectedLocality.floorplans)[0]) : null
    )

    const heatmapList = useSuspendingQuery(
        statisticsApi.getFloorplanHeatmapList.query({
            floorplanId: selectedFloorplan ?? -1,
        })
    )

    const closestIntervalHeatmap = useMemo(() => {
        if (selectedFloorplan) {
            if (isEmpty(heatmapList.data?.heatmaps)) {
                return
            }

            const selectedIntervalEnd = localize(DateTime.fromMillis(Number(params.intervalEnd)))

            const heatmaps = heatmapList.data!.heatmaps

            const lastHeatmap = last(heatmaps)

            const closestIntervalHeatmap = minBy(
                heatmaps
                    .map((heatmapData) => ({
                        ...heatmapData,
                        diff: localize(parseDate(heatmapData.endingAt)).diff(selectedIntervalEnd, 'hours').toObject()[
                            'hours'
                        ],
                    }))
                    .filter((heatmapData) => (heatmapData.diff ?? 0) >= 0),
                (heatmapData) => heatmapData.diff
            )

            const closestIntervalLabel = closestIntervalHeatmap
                ? formatInterval(
                      gdynusaReadableInterval(
                          Interval.fromDateTimes(
                              localize(DateTime.fromISO(closestIntervalHeatmap.startingFrom)),
                              localize(DateTime.fromISO(closestIntervalHeatmap.endingAt))
                          )
                      ),
                      Precision.DAY,
                      DateTime.DATE_SHORT
                  )
                : undefined

            const fallbackIntervalLabel = formatInterval(
                gdynusaReadableInterval(
                    Interval.fromDateTimes(
                        localize(DateTime.fromISO(lastHeatmap!.startingFrom)),
                        localize(DateTime.fromISO(lastHeatmap!.endingAt))
                    )
                ),
                Precision.DAY,
                DateTime.DATE_SHORT
            )

            return {
                interval: closestIntervalHeatmap
                    ? localize(DateTime.fromISO(closestIntervalHeatmap.startingFrom))
                    : DateTime.fromISO(lastHeatmap!.startingFrom),
                label: t('heatmapDetailPage.heatmapShowingDataFromLabel', 'Heatmap showing data from {{label}}', {
                    label: closestIntervalLabel ?? fallbackIntervalLabel,
                }),
            }
        }
    }, [selectedFloorplan, localize, params.intervalEnd, heatmapList.data])

    if (isEmpty(selectedLocalities)) {
        return (
            <Box paddingSize="lg">
                <Box.Title text={t('heading.spaceUtilization', 'Space Utilization')} />
                <ErrorView
                    message={t(
                        'heatmapDetailPage.selectedLocalitiesNotConfigured',
                        'Selected localities do not contain configured floor plan mappings.'
                    )}
                    title={t('others.nothingToDisplay', 'Nothing to display')}
                />
            </Box>
        )
    }

    const radioHandler = (event: ChangeEvent<HTMLInputElement>) => setSelectedFloorplan(Number(event.target.value))

    const handleLocalitySelect = (locality: LocalityResponse) => {
        setSelectedLocality(locality)
        setSelectedFloorplan(Number(Object.keys(locality.floorplans)[0]))
    }

    const sectionTitle =
        selectedLocality !== null
            ? t('heading.spaceUtilizationLocality', 'Space Utilization - {{locality}}', {
                  locality: selectedLocality.name,
              })
            : t('heading.spaceUtilization', 'Space Utilization')

    return (
        selectedLocality && (
            <Box paddingSize="lg">
                <Box.Title
                    buttons={
                        <div className={styles.heatmapButtonsContainer}>
                            {selectedLocalities.length >= 1 && (
                                <LocalityDropdown
                                    localities={selectedLocalities}
                                    selectedLocality={selectedLocality}
                                    onSelect={handleLocalitySelect}
                                />
                            )}
                            {closestIntervalHeatmap?.interval && (
                                <Button
                                    as={NavLink}
                                    to={generateHeatmapDetailPath(
                                        selectedLocality.id,
                                        closestIntervalHeatmap.interval,
                                        null,
                                        selectedFloorplan
                                    )}
                                    variant="secondary"
                                >
                                    <FontAwesomeIcon icon={faExternalLinkAlt} />
                                    {t('button.detail', 'Detail')}
                                </Button>
                            )}
                        </div>
                    }
                    subtitle={closestIntervalHeatmap?.label}
                    text={sectionTitle}
                />
                {Object.entries(selectedLocality.floorplans).length > 1 && (
                    <Row className={styles.heatMapRowRadioContainer}>
                        {Object.entries(selectedLocality.floorplans).map(([floorplanId, floorplanName]) => (
                            <Form.Check
                                key={floorplanId}
                                checked={Number(floorplanId) === selectedFloorplan}
                                id={`radio-${floorplanId}`}
                                label={
                                    Number(floorplanId) === selectedFloorplan ? (
                                        <strong>{floorplanName}</strong>
                                    ) : (
                                        floorplanName
                                    )
                                }
                                type="radio"
                                value={floorplanId}
                                custom
                                inline
                                onChange={radioHandler}
                            />
                        ))}
                    </Row>
                )}
                <FloorplanHeatmapDisplayBox
                    heatmap={maxBy(
                        heatmapList.data.heatmaps.filter((it) => it.heatmapType === FloorplanHeatmapType.UtilityGrid),
                        (heatmap) => parseDate(heatmap.startingFrom)
                    )}
                />
                <div className={styles.intensityImageContainer}>
                    <AutoSizedImageWithLegendWrapper src={heatmapLegend} />
                </div>
                <RoleChecker whitelist={[Role.Administrator]}>
                    <div className={styles.mapToFloorplanContainer}>
                        <Button
                            as={NavLink}
                            to={generateOrganizationEditLocalityFloorplanMappingPath(
                                selectedLocality.organizationId,
                                selectedLocality.id,
                                selectedFloorplan!
                            )}
                            variant="secondary"
                        >
                            <FontAwesomeIcon icon={faMapPin} />
                            {t('heading.mapToFloorplan', 'Map to floor plan')}
                        </Button>
                    </div>
                </RoleChecker>
            </Box>
        )
    )
}

export default HeatmapSectionWrapper
