/* tslint:disable */
/* eslint-disable */
/**
 * Vividi Dashboard API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DeviceProtocolCommand,
    DeviceProtocolCommandFromJSON,
    DeviceProtocolCommandFromJSONTyped,
    DeviceProtocolCommandToJSON,
} from './DeviceProtocolCommand';

/**
 * 
 * @export
 * @interface SendCommandRequestBody
 */
export interface SendCommandRequestBody {
    /**
     * 
     * @type {DeviceProtocolCommand}
     * @memberof SendCommandRequestBody
     */
    command: DeviceProtocolCommand;
    /**
     * 
     * @type {object}
     * @memberof SendCommandRequestBody
     */
    arguments?: object;
}

export function SendCommandRequestBodyFromJSON(json: any): SendCommandRequestBody {
    return SendCommandRequestBodyFromJSONTyped(json, false);
}

export function SendCommandRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): SendCommandRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'command': DeviceProtocolCommandFromJSON(json['command']),
        'arguments': !exists(json, 'arguments') ? undefined : json['arguments'],
    };
}

export function SendCommandRequestBodyToJSON(value?: SendCommandRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'command': DeviceProtocolCommandToJSON(value.command),
        'arguments': value.arguments,
    };
}

