import { uniqueId } from 'lodash'
import React, { useMemo } from 'react'
import { Dropdown } from 'react-bootstrap'

import { ReactComponent as FloorplanIcon } from '@images/icons/floorplan_icon.svg'

import styles from './Floorplan.module.scss'

interface Props {
    onSelect: (floorplanId: number, floorplanName: string) => void
    floorplans: [string, string][]
    selectedFloorplan: number
}

const FloorplanDropdown: React.FC<Props> = ({ onSelect, floorplans, selectedFloorplan }) => {
    const id = useMemo(uniqueId, [])

    return (
        <Dropdown
            onSelect={(eventKey) => {
                if (eventKey === null) {
                    return
                }

                const selected = floorplans.find((it) => it[0] === eventKey)

                if (selected !== undefined) {
                    const [floorplanId, floorplanName] = selected

                    onSelect(Number(floorplanId), floorplanName)
                }
            }}
        >
            <Dropdown.Toggle className={styles.floorplanDropDown} id={id} variant="secondary">
                <FloorplanIcon className={styles.floorplanIcon} />
                <span>{Object.fromEntries(floorplans)[selectedFloorplan]}</span>
            </Dropdown.Toggle>
            <Dropdown.Menu>
                {floorplans.map(([id, name]) => (
                    <Dropdown.Item key={`floorplan-${id}`} eventKey={id}>
                        {name}
                    </Dropdown.Item>
                ))}
            </Dropdown.Menu>
        </Dropdown>
    )
}

export default FloorplanDropdown
