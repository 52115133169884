/* tslint:disable */
/* eslint-disable */
/**
 * Vividi Dashboard API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    LocalityNameModel,
    LocalityNameModelFromJSON,
    LocalityNameModelFromJSONTyped,
    LocalityNameModelToJSON,
} from './LocalityNameModel';

/**
 * 
 * @export
 * @interface LocalityNameListResponse
 */
export interface LocalityNameListResponse {
    /**
     * 
     * @type {Array<LocalityNameModel>}
     * @memberof LocalityNameListResponse
     */
    localities: Array<LocalityNameModel>;
}

export function LocalityNameListResponseFromJSON(json: any): LocalityNameListResponse {
    return LocalityNameListResponseFromJSONTyped(json, false);
}

export function LocalityNameListResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): LocalityNameListResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'localities': ((json['localities'] as Array<any>).map(LocalityNameModelFromJSON)),
    };
}

export function LocalityNameListResponseToJSON(value?: LocalityNameListResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'localities': ((value.localities as Array<any>).map(LocalityNameModelToJSON)),
    };
}

