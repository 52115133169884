import { faExpand } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Spinner, Image } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { UseQueryResult } from 'react-query'

import ImageLoadingWrapper from '@components/ImageLoadingWrapper'
import ImageWithModalControlled from '@components/ImageWithModal/ImageWithModalControlled'

import styles from './OverlayedImageWithModal.module.scss'

interface Props {
    errorComponent: React.ReactElement
    imageRequest: UseQueryResult<Blob>
    isOpen: boolean
    onClose: () => void
    onOpen: () => void
}

const OverlayedImageWithModal: React.FC<Props> = ({ errorComponent, imageRequest, isOpen, onClose, onOpen }) => {
    const { t } = useTranslation()

    return (
        <div className={styles.imageOverlay}>
            <ImageLoadingWrapper
                errorComponent={errorComponent}
                placeholder={
                    <div className={styles.loadingContainer}>
                        <Spinner animation="border" />
                    </div>
                }
                request={imageRequest}
            >
                <ImageWithModalControlled isOpen={isOpen} src="" onClose={onClose}>
                    <Image src="" fluid />
                </ImageWithModalControlled>
            </ImageLoadingWrapper>
            {imageRequest.status === 'success' && (
                <>
                    <div className={styles.overlayContainer} onClick={onOpen}>
                        <div className={styles.overlayText} onClick={onOpen}>
                            <span>
                                <FontAwesomeIcon icon={faExpand} />
                                {t('others.fullscreen', 'Fullscreen')}
                            </span>
                        </div>
                    </div>
                </>
            )}
        </div>
    )
}

export default OverlayedImageWithModal
