import React from 'react'
import { Form } from 'react-bootstrap'

interface Props {
    zone?: string
    allowUndefined?: boolean
    onChange: (zone: string) => void
}

const zoneNames = {
    'Europe/Prague': 'Europe/Prague',
    'Europe/London': 'Europe/London',
    'Asia/Seoul': 'Asia/Seoul',
    UTC: 'UTC',
}

const TimezonePicker = (props: Props) => {
    const timeZoneValue = (zone?: string): string | undefined => {
        if (!props.allowUndefined) {
            return zone !== undefined ? (Object.keys(zoneNames).includes(zone) ? (zone as string) : 'UTC') : 'UTC'
        }

        return zone !== undefined ? (Object.keys(zoneNames).includes(zone) ? (zone as string) : 'UTC') : undefined
    }

    return (
        <Form.Control
            as="select"
            value={timeZoneValue(props.zone)}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                const name = e.currentTarget.value

                if (Object.keys(zoneNames).includes(name)) {
                    props.onChange(name)
                } else {
                    props.onChange('UTC')
                }
            }}
        >
            {props.allowUndefined && <option value="">-</option>}
            {Object.entries(zoneNames).map(([name, label]) => (
                <option key={name} value={name}>
                    {label}
                </option>
            ))}
        </Form.Control>
    )
}

export default TimezonePicker
