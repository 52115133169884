import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons'
import classNames from 'classnames'
import { DateTime, Interval } from 'luxon'
import React from 'react'
import { ButtonGroup } from 'react-bootstrap'

import { gdynusaReadableInterval } from '@helpers/datetimeUtils'
import { formatInterval, Precision } from '@helpers/intervals'
import { useLocalizeDateTime } from '@helpers/timezoneConfig'
import { IntervalType } from '@helpers/types'

import IconButton from '@elements/Buttons/IconButton'

import DateTimeIntervalPicker from '@components/DateTimeIntervalPicker/DateTimeIntervalPicker'

import styles from './IntervalPicker.module.scss'

interface Props {
    pickedInterval: Interval
    intervalType: IntervalType
    intervalDisplayClassName?: string
    onIntervalStartChange: (intervalStart: DateTime) => void
    onCustomIntervalChange: (interval: Interval) => void
    now: DateTime
}

const IntervalPicker: React.FC<Props> = (props) => {
    const localize = useLocalizeDateTime()
    const isNextDisabled = props.pickedInterval.end >= DateTime.utc()

    const getIntervalGranularity = (intervalGranularity: IntervalType): string => {
        switch (intervalGranularity) {
            case IntervalType.HOUR:
            case IntervalType.DAY:
            case IntervalType.WEEK:
            case IntervalType.MONTH:
                return intervalGranularity
            case IntervalType.CUSTOM:
                return 'milliseconds'

            default:
                return 'day'
        }
    }

    const handleIntervalShift = (intervalGranularity: IntervalType, direction: 1 | -1) => {
        if (intervalGranularity === IntervalType.CUSTOM) {
            const intervalLength = props.pickedInterval.length('milliseconds')
            const startDate = props.pickedInterval.start.plus({
                milliseconds: intervalLength * direction,
            })
            const endDate = props.pickedInterval.end.plus({
                milliseconds: intervalLength * direction,
            })
            props.onCustomIntervalChange(Interval.fromDateTimes(startDate, endDate))
        } else {
            const newIntervalStart = localize(props.pickedInterval.start) // Conversion to local time so that the interval shifting works properly
                .plus({
                    [getIntervalGranularity(intervalGranularity)]: direction,
                })
                .toUTC()
            props.onIntervalStartChange(newIntervalStart)
        }
    }

    /**
     * An interval formatter that displays e.g. 02/2011 for monthly view (as opposed to formatInterval)
     */
    const formatDisplayedDateTime = (interval: Interval): string => {
        const readableInterval = gdynusaReadableInterval(interval)
        const intervalStart = localize(readableInterval.start)
        const intervalEnd = localize(readableInterval.end)
        const intervalTimeStart = localize(readableInterval.start).toLocaleString(DateTime.TIME_SIMPLE)
        const intervalTimeEnd = localize(readableInterval.end).toLocaleString(DateTime.TIME_SIMPLE)

        if (props.intervalType === IntervalType.HOUR) {
            return `${intervalStart.toLocaleString()}, ${intervalTimeStart} - ${intervalTimeEnd}`
        }

        if (props.intervalType === IntervalType.MONTH) {
            return intervalStart.toLocaleString({
                month: '2-digit',
                year: 'numeric',
            })
        }

        const containsWholeDays =
            intervalStart.equals(intervalStart.startOf('day')) && intervalEnd.equals(intervalEnd.endOf('day'))

        if (containsWholeDays) {
            if (intervalStart.toISODate() !== intervalEnd.toISODate()) {
                return `${intervalStart.toLocaleString()} - ${intervalEnd.toLocaleString()}`
            }

            return intervalStart.toLocaleString()
        }

        return formatInterval(readableInterval.mapEndpoints(localize), Precision.HOUR)
    }

    return (
        <ButtonGroup className={`${styles.intervalPicker} ${props.intervalDisplayClassName}`}>
            <DateTimeIntervalPicker
                interval={props.pickedInterval}
                now={props.now}
                onSubmit={props.onCustomIntervalChange}
            >
                <input
                    className={classNames(styles.rangeDisplayButton, 'btn', 'btn-secondary')}
                    type="button"
                    value={formatDisplayedDateTime(props.pickedInterval)}
                />
            </DateTimeIntervalPicker>
            <IconButton icon={faAngleLeft} onClick={() => handleIntervalShift(props.intervalType, -1)} />
            <IconButton
                className={classNames({
                    [styles.navigationButtonHidden]: isNextDisabled,
                })}
                disabled={isNextDisabled}
                icon={faAngleRight}
                onClick={() => !isNextDisabled && handleIntervalShift(props.intervalType, 1)}
            />
        </ButtonGroup>
    )
}

export default IntervalPicker
