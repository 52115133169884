/* tslint:disable */
/* eslint-disable */
/**
 * Vividi Dashboard API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ApplicationConfiguration
 */
export interface ApplicationConfiguration {
    /**
     * 
     * @type {number}
     * @memberof ApplicationConfiguration
     */
    readyToUseOrganizationId?: number;
    /**
     * 
     * @type {number}
     * @memberof ApplicationConfiguration
     */
    cemeteryOrganizationId?: number;
}

export function ApplicationConfigurationFromJSON(json: any): ApplicationConfiguration {
    return ApplicationConfigurationFromJSONTyped(json, false);
}

export function ApplicationConfigurationFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApplicationConfiguration {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'readyToUseOrganizationId': !exists(json, 'ready_to_use_organization_id') ? undefined : json['ready_to_use_organization_id'],
        'cemeteryOrganizationId': !exists(json, 'cemetery_organization_id') ? undefined : json['cemetery_organization_id'],
    };
}

export function ApplicationConfigurationToJSON(value?: ApplicationConfiguration | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'ready_to_use_organization_id': value.readyToUseOrganizationId,
        'cemetery_organization_id': value.cemeteryOrganizationId,
    };
}

