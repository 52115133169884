import * as React from 'react'
import { SliderItem, GetTrackProps } from 'react-compound-slider'

import styles from './OpeningHoursPicker.module.scss'

interface TrackProps {
    source: SliderItem
    target: SliderItem
    isDisplayed: boolean
    getTrackProps: GetTrackProps
}

const Track: React.FC<TrackProps> = ({ source, target, getTrackProps, isDisplayed }) => (
    <div
        className={styles.track}
        style={{
            left: `${source.percent}%`,
            width: `${target.percent - source.percent}%`,
            display: isDisplayed ? 'inherit' : 'none',
        }}
        {...getTrackProps()}
    />
)

export default Track
