import { chunk } from 'lodash'
import { Duration } from 'luxon'
import React from 'react'
import { Slider, Rail, Handles, Tracks, Ticks } from 'react-compound-slider'

import { OpeningHoursInterval } from '@api'

import { convertOpeningHoursPerDayToTickValues } from '@helpers/utils'

import styles from './OpeningHoursPicker.module.scss'
import Handle from './RangeSliderHandle'
import Tick from './RangeSliderTick'
import Track from './RangeSliderTrack'

const domain: number[] = [0, 1440] // 1440 minutes in day

interface Props {
    openingHours: OpeningHoursInterval[]
    onOpeningHoursChange(openingHours: OpeningHoursInterval[]): void
}

const MultiRangeSlider: React.FC<Props> = (props) => {
    const onChange = (values: readonly number[]) => {
        const chunkedValues = chunk(values, 2)

        const openingHours = chunkedValues.map((chunk) => {
            const openingTime = Duration.fromObject({
                hours: 0,
                minutes: chunk[0],
            }).normalize()
            const closingTime = Duration.fromObject({
                hours: 0,
                minutes: chunk[1],
            }).normalize()

            return {
                openingTime: {
                    hour: openingTime.hours,
                    minute: openingTime.minutes,
                },
                closingTime: {
                    hour: closingTime.hours,
                    minute: closingTime.minutes,
                },
            }
        })

        props.onOpeningHoursChange(openingHours)
    }

    return (
        <div className={styles.sliderWrapper}>
            <Slider
                className={styles.slider}
                domain={domain}
                mode={1}
                step={5}
                values={convertOpeningHoursPerDayToTickValues(props.openingHours)}
                onUpdate={onChange}
            >
                <Rail>{({ getRailProps }) => <div className={styles.rail} {...getRailProps()} />}</Rail>
                <Handles>
                    {({ handles, getHandleProps }) => (
                        <div className="slider-handles">
                            {handles.map((handle) => (
                                <Handle
                                    key={handle.id}
                                    domain={domain}
                                    getHandleProps={getHandleProps}
                                    handle={handle}
                                />
                            ))}
                        </div>
                    )}
                </Handles>
                <Tracks left={false} right={false}>
                    {({ tracks, getTrackProps }) => (
                        <div className="slider-tracks">
                            {tracks.map(({ id, source, target }, index) => (
                                <Track
                                    key={id}
                                    getTrackProps={getTrackProps}
                                    isDisplayed={index % 2 === 0}
                                    source={source}
                                    target={target}
                                />
                            ))}
                        </div>
                    )}
                </Tracks>
                <Ticks count={10}>
                    {({ ticks }) => (
                        <div className="slider-ticks">
                            {ticks.map((tick) => {
                                return (
                                    <Tick
                                        key={tick.id}
                                        count={ticks.length}
                                        label={Duration.fromObject({
                                            minutes: tick.value,
                                        })}
                                        tick={tick}
                                    />
                                )
                            })}
                        </div>
                    )}
                </Ticks>
            </Slider>
        </div>
    )
}

export default MultiRangeSlider
