import { faFilter } from '@fortawesome/pro-solid-svg-icons'
import { Dictionary, isEmpty, uniqueId } from 'lodash'
import React, { useMemo, useState } from 'react'
import { Button, Form, OverlayTrigger, Popover } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import { DetectionZoneWithId, LocalityNameModel, SceneDescription, SceneListResponse } from '@api'

import { orderScenes } from '@helpers/orderFunctions'

import ToggleIcon from '@elements/ToggleIcon/ToggleIcon'

import styles from './BoundaryPicker.module.scss'
import filterButtonStyles from './StatisticsSummary/FilterButton.module.scss'

type Props = {
    selectedLocality: LocalityNameModel
    selectedZones: DetectionZoneWithId[]
    sceneConfiguration: Dictionary<SceneDescription>
    scenes: SceneListResponse
    onZonesChange: (zones: DetectionZoneWithId[]) => void
}

const ZonePicker = ({ selectedLocality, selectedZones, sceneConfiguration, scenes, onZonesChange }: Props) => {
    const { t } = useTranslation()

    const id = useMemo(uniqueId, [])
    const [isPickerOpen, setIsPickerOpen] = useState<boolean>(false)

    const localityScenes = scenes.scenes.filter((d) => d.localityIds.includes(selectedLocality.id))

    const handleZoneSwitch = (zone: DetectionZoneWithId) => {
        const isSelected = selectedZones.map((z) => z.id).includes(zone.id)

        if (isSelected) {
            const zonesWithoutClickedZone = selectedZones.filter((z) => z.id !== zone.id)

            if (selectedZones.length === 1) {
                onZonesChange([])
            } else {
                onZonesChange(zonesWithoutClickedZone)
            }
        } else {
            onZonesChange([...selectedZones, zone])
        }
    }

    const scenesContainer = orderScenes(localityScenes).map((scene) => {
        const sceneZones = sceneConfiguration[scene.id].detectionZones

        if (!isEmpty(sceneZones)) {
            return (
                <React.Fragment key={`${scene.id} filter`}>
                    <div className={filterButtonStyles.localityTitleContainer}>
                        {scene.label}
                        {sceneZones.length > 1 && (
                            <Button
                                className={filterButtonStyles.selectOnly}
                                variant="link"
                                onClick={() => onZonesChange(sceneZones)}
                            >
                                {t('button.selectAll', 'select all')}
                            </Button>
                        )}
                    </div>
                    <div className={styles.boundariesContainer}>
                        {sceneZones &&
                            sceneZones.map((zone) => (
                                <div key={`${id}_${zone.id}_container`} className={styles.boundaryContainer}>
                                    <Form.Check
                                        checked={selectedZones?.map((b) => b.id).includes(zone.id)}
                                        id={`${id}_${zone.id}`}
                                        label={zone.name ? zone.name : t('others.untitledZone', 'Untitled zone')}
                                        type="checkbox"
                                        custom
                                        onChange={() => {
                                            handleZoneSwitch(zone)
                                        }}
                                    />
                                    {!isEmpty(sceneZones) && (
                                        <Button
                                            className={filterButtonStyles.selectOnly}
                                            variant="link"
                                            onClick={() => onZonesChange([zone])}
                                        >
                                            {t('button.selectOnly', 'select only')}
                                        </Button>
                                    )}
                                </div>
                            ))}
                    </div>
                </React.Fragment>
            )
        }

        return <React.Fragment key={`${scene.id} filter`} />
    })

    return (
        <>
            {!isEmpty(scenes.scenes) && (
                <OverlayTrigger
                    overlay={
                        <Popover className="wide" id={id}>
                            <Popover.Content>
                                <div className={filterButtonStyles.devicesContainer}>{scenesContainer}</div>
                            </Popover.Content>
                        </Popover>
                    }
                    placement="bottom"
                    rootClose={true}
                    show={isPickerOpen}
                    trigger={['click']}
                    onToggle={setIsPickerOpen}
                >
                    <ToggleIcon
                        icon={faFilter}
                        selected={!isEmpty(selectedZones)}
                        tooltipText={
                            !isEmpty(selectedZones)
                                ? t('button.cancelFilter', 'Cancel filter')
                                : t('button.filterZones', 'Filter zones')
                        }
                        onToggle={() => {
                            if (!isEmpty(selectedZones)) {
                                onZonesChange([])
                            } else {
                                setIsPickerOpen((prev) => !prev)
                            }
                        }}
                    />
                </OverlayTrigger>
            )}
        </>
    )
}

export default ZonePicker
