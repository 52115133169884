import produce from 'immer'
import React, { useState } from 'react'
import { Form, Row, Col } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { MutationStatus } from 'react-query'

import { ErrorEntry } from '@helpers/types'
import { isEmailValid } from '@helpers/validations'

import AsyncButton from '@components/AsyncButton'
import TextInput from '@components/GenericInputs/TextInput'
import { useNotify } from '@components/notifications/NotificationsContext'

interface Errors {
    email: ErrorEntry
    [other: string]: ErrorEntry
}

interface Props {
    onSubmit: (email: string) => void
    requestState: MutationStatus
}

const PasswordResetRequestFormView = (props: Props) => {
    const { t } = useTranslation()

    const [email, setEmail] = useState<string>('')
    const [errors, setErrors] = useState<Errors>({
        email: {
            isInvalid: true,
            isTouched: false,
        },
    })

    const notify = useNotify()

    const onSubmit: React.FormEventHandler = (e) => {
        e.preventDefault()

        setErrors((prev) => produce(prev, (draft) => Object.keys(draft).forEach((k) => (draft[k].isTouched = true))))

        if (errors.email.isInvalid) {
            notify({
                title: t('notification.invalidInput', 'Invalid input'),
                content: t('notification.invalidEmail', 'Email address is not valid'),
                variant: 'warning',
            })

            return
        }

        props.onSubmit(email)
    }

    const handleSetEmail = (value: string) => {
        setErrors((prev) =>
            produce(prev, (draft) => {
                draft.email = {
                    isInvalid: value === '' || !isEmailValid(value),
                    isTouched: true,
                }
            })
        )
        setEmail(value)
    }

    return (
        <Form onSubmit={onSubmit}>
            <Form.Group as={Row}>
                <Form.Label column={true} sm={2}>
                    {t('form.email', 'E-mail')}
                </Form.Label>
                <Col sm={10}>
                    <TextInput
                        isInvalid={errors.email.isInvalid && errors.email.isTouched}
                        placeholder="email@address.com"
                        value={email}
                        onChange={handleSetEmail}
                    />
                </Col>
            </Form.Group>
            <Form.Group as={Row}>
                <Col sm={{ span: 10, offset: 2 }}>
                    <AsyncButton
                        allowRefetch={true}
                        doneText={t('button.resetLinkSent', 'Reset link sent')}
                        loadingText={t('button.sendingRequest', 'Sending request...')}
                        retryTimeoutSeconds={3}
                        status={props.requestState}
                        text={t('button.submit', 'Submit')}
                        type="submit"
                        variant="primary"
                    />
                </Col>
            </Form.Group>
        </Form>
    )
}

export default PasswordResetRequestFormView
