import React from 'react'
import { Container } from 'react-bootstrap'

import styles from './NodataView.module.scss'

interface Props {
    message: string
    title?: string
}

const NodataView: React.FC<Props> = ({ message }) => {
    return (
        <Container className={styles.nodataContainer} fluid={true}>
            <span className="text-muted"> {message}</span>
        </Container>
    )
}

export default NodataView
