/* tslint:disable */
/* eslint-disable */
/**
 * Vividi Dashboard API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * An enumeration.
 * @export
 * @enum {string}
 */
export enum PropertyName {
    Age = 'age',
    IsMale = 'is_male',
    EmotionScore = 'emotion_score'
}

export function PropertyNameFromJSON(json: any): PropertyName {
    return PropertyNameFromJSONTyped(json, false);
}

export function PropertyNameFromJSONTyped(json: any, ignoreDiscriminator: boolean): PropertyName {
    return json as PropertyName;
}

export function PropertyNameToJSON(value?: PropertyName | null): any {
    return value as any;
}

