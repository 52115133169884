import { fromPairs, isEmpty, mapValues, mean, partition, pick, round, sumBy } from 'lodash'
import { IANAZone, Interval, Zone } from 'luxon'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'

import {
    DatetimeInterval,
    LocalityConfigurationStateModel,
    OrganizationResponse,
    ZoneOccupancySummaryResponse,
} from '@api'

import { organizationApi } from '@services'

import { generateOccupancyDetailsCustomPath, generateOrganizationsEditLocalitiesPath } from '@helpers/VividiURLs'
import {
    mergeQueryResults,
    visibleMultipleLocalityZoneOccupancySessionsQuery,
    visibleSceneDescriptionsQuery,
    visibleZoneOccupancyStatisticsByLocalityQuery,
} from '@helpers/api'
import { useAddBackLink } from '@helpers/backlinks'
import { gdynusaReadableInterval } from '@helpers/datetimeUtils'
import { GoogleEventName } from '@helpers/gtag'
import { formatIntervalToFilename } from '@helpers/intervals'
import { orderLocalities } from '@helpers/orderFunctions'
import { migrateTimestamp, useLocalizeDateTime } from '@helpers/timezoneConfig'
import { IndicatorValueType, ZoneOccupancyGranularity } from '@helpers/types'
import { pickColumn, recordFromEntries } from '@helpers/utils'
import { ExportData, exportToExcel } from '@helpers/xlsxExporter'

import Box from '@elements/Box/Box'
import TableWithSorting, { CellContent } from '@elements/Table/TableWithSorting'

import AdminOnlyLinkRenderer from '@components/AdminOnlyLinkRenderer'
import LegacyLoadingWrapper from '@components/LegacyLoadingWrapper'
import LoadingSpinner from '@components/LoadingSpinner'
import { prepareOccupancyDetailsContentData } from '@components/OccupancyDetails/occupancyDetailsPreproccessors'
import NotConfiguredLocalitiesList from '@components/StatisticsSummary/NotConfiguredLocalitiesList'
import OccupancyStatisticBox from '@components/StatisticsSummary/OccupancyStatisticBox'
import SectionSubheading from '@components/StatisticsSummary/SectionSubheading'
import { partitionByInterval } from '@components/StatisticsSummary/utils'
import { granularityToDuration } from '@components/ZoneOccupancyStatistics/zoneOccupancyData'
import { useNotify } from '@components/notifications/NotificationsContext'
import { partitionInterval } from '@components/plots/common'
import DownloadButtonWrapper from '@components/plots/downloadButtons/DownloadButtonWrapper'

import DetailLink from './DetailLink'

type Props = {
    localities: LocalityConfigurationStateModel[]
    interval: Interval
    comparisonInterval?: Interval
    selectedLocalities: LocalityConfigurationStateModel[]
    selectedOrganization: OrganizationResponse
    zone: Zone
    shouldDisplayLocalitySummary: boolean
}

const OccupancySection = ({
    localities,
    interval,
    comparisonInterval,
    selectedLocalities,
    selectedOrganization,
    zone,
    shouldDisplayLocalitySummary,
}: Props) => {
    const { t } = useTranslation()

    const notify = useNotify()

    const [configuredLocalities, notConfiguredLocalities] = partition(
        orderLocalities(selectedLocalities),
        (l) => l.isOccupancyConfigured
    )

    const partitionedCurrentInterval = partitionInterval(
        interval,
        zone,
        granularityToDuration(ZoneOccupancyGranularity.HOUR)
    ).intervals

    const partitionedComparisonInterval = comparisonInterval
        ? partitionInterval(comparisonInterval, zone, granularityToDuration(ZoneOccupancyGranularity.HOUR)).intervals
        : undefined

    const localityIds = localities.map(({ id }) => id)

    const occupancyStatisticsCall = useQuery({
        ...visibleZoneOccupancyStatisticsByLocalityQuery({
            intervals:
                partitionedComparisonInterval !== undefined
                    ? [...partitionedCurrentInterval, ...partitionedComparisonInterval]
                    : partitionedCurrentInterval,
            localityIds,
        }),
    })

    const zoneOccupancySessionsCall = useQuery({
        ...visibleMultipleLocalityZoneOccupancySessionsQuery({
            startingFrom: comparisonInterval ? comparisonInterval.start.toISO() : interval.start.toISO(),
            endingAt: interval.end.toISO(),
            localityIds,
        }),
    })

    const scenesCall = useQuery(
        organizationApi.getOrganizationScenes.query({ organizationId: selectedOrganization.id })
    )

    const sceneDescriptionsCall = useQuery({
        ...visibleSceneDescriptionsQuery(pickColumn(scenesCall.data?.scenes ?? [], 'id')),
        enabled: scenesCall.data !== undefined,
    })

    const apiCalls = mergeQueryResults(
        occupancyStatisticsCall,
        zoneOccupancySessionsCall,
        scenesCall,
        sceneDescriptionsCall
    )

    const localize = useLocalizeDateTime()
    const addBackLink = useAddBackLink()

    return (
        <LegacyLoadingWrapper
            placeholder={
                <Box paddingSize="lg">
                    <Box.Title text={t('heading.occupancyStatistics', 'Occupancy Statistics')} />
                    <LoadingSpinner bare />
                </Box>
            }
            request={apiCalls}
        >
            {([occupancyStatistics, zoneOccupancySessions, scenes, sceneDescriptions]) => {
                const occupancyData = configuredLocalities
                    .map((configuredLocality) => configuredLocality.id)
                    .map((localityId) => {
                        const occupancySummary = occupancyStatistics[localityId.toString()]

                        const partitionedTimeBrackets = partitionByInterval(interval, occupancySummary.timeBrackets)

                        const bracketsFilter = (whitelist: DatetimeInterval[]) => (_: unknown, i: number) =>
                            whitelist.includes(occupancySummary.timeBrackets[i])

                        const partitionedSummaryRows = mapValues(partitionedTimeBrackets, (brackets) =>
                            occupancySummary.zoneRows.map((row) => ({
                                ...row,
                                cells: row.cells.filter(bracketsFilter(brackets)),
                            }))
                        )

                        const partitionedOpenedPortions = mapValues(partitionedTimeBrackets, (brackets) =>
                            occupancySummary.openedPortions.filter(bracketsFilter(brackets))
                        )

                        const partitionedSessions = partitionByInterval(
                            interval,
                            zoneOccupancySessions[localityId.toString()].sessions
                        )

                        const data = recordFromEntries(['currentInterval', 'previousInterval'], (key) => {
                            const timeBrackets = partitionedTimeBrackets[key]
                            const zoneRows = partitionedSummaryRows[key]
                            const openedPortions = partitionedOpenedPortions[key]
                            const sessions = partitionedSessions[key]
                            const localityScenes = scenes.scenes.filter(({ localityIds }) =>
                                localityIds.includes(localityId)
                            )

                            return prepareOccupancyDetailsContentData(
                                sessions,
                                localize,
                                {
                                    zoneOccupancySummary: {
                                        timeBrackets,
                                        openedPortions,
                                        zoneRows,
                                    } as ZoneOccupancySummaryResponse,
                                    scenes: {
                                        scenes: localityScenes,
                                    },
                                },
                                pick(sceneDescriptions, pickColumn(localityScenes, 'id')),
                                zone
                            )
                        })

                        return {
                            localityId,
                            ...data,
                        }
                    })

                const occupancyHighlights: Array<{
                    name: string
                    value: number
                    readonly change: number
                    comparisonValue?: number
                    valueType: IndicatorValueType
                    isChangeAbsolute: boolean
                    description: string
                }> = [
                    {
                        name: t('occupancy.avgDailySeatOccupancy', 'Average daily seat occupancy'),
                        value: round(
                            mean(occupancyData.map(({ currentInterval }) => currentInterval.averageSeatOccupancy))
                        ),
                        comparisonValue: round(
                            mean(
                                occupancyData.map(({ previousInterval }) => previousInterval?.averageSeatOccupancy ?? 0)
                            )
                        ),
                        valueType: 'time',
                        isChangeAbsolute: true,
                        description: t(
                            'occupancy.avgDailySeatOccupancyDescription',
                            'average daily seat occupancy across all seats in the locality'
                        ),
                        get change() {
                            return round(this.value - (this.comparisonValue ?? 0))
                        },
                    },
                    {
                        name: t('occupancy.totalSessions', 'Total sessions'),
                        value: sumBy(
                            occupancyData,
                            ({ currentInterval }) => currentInterval.occupancySessionsHighlightsData.totalSessions
                        ),
                        comparisonValue: sumBy(occupancyData, ({ previousInterval }) =>
                            comparisonInterval ? previousInterval.occupancySessionsHighlightsData.totalSessions : 0
                        ),
                        valueType: 'count',
                        isChangeAbsolute: true,
                        description: t(
                            'occupancy.totalSessionsDescription',
                            'total number of sessions from all seats in the locality'
                        ),
                        get change() {
                            return this.value - (this.comparisonValue ?? 0)
                        },
                    },
                    {
                        name: t('occupancy.dailySessionsPerSeat', 'Daily sessions per seat'),
                        value: round(
                            mean(
                                occupancyData.map(
                                    ({ currentInterval }) =>
                                        currentInterval.occupancySessionsHighlightsData.dailySessionsPerSeat
                                )
                            )
                        ),
                        comparisonValue: round(
                            mean(
                                occupancyData.map(({ previousInterval }) =>
                                    comparisonInterval
                                        ? previousInterval.occupancySessionsHighlightsData.dailySessionsPerSeat
                                        : 0
                                )
                            )
                        ),
                        valueType: 'count',
                        isChangeAbsolute: true,
                        description: t(
                            'occupancy.dailySessionsPerSeatDescription',
                            'average daily number of sessions per seat in the locality'
                        ),
                        get change() {
                            return this.value - (this.comparisonValue ?? 0)
                        },
                    },
                    {
                        name: t('occupancy.avgSessionDuration', 'Average session duration'),
                        value: round(
                            mean(
                                occupancyData.map(
                                    ({ currentInterval }) =>
                                        currentInterval.occupancySessionsHighlightsData.averageSessionDuration
                                )
                            )
                        ),
                        comparisonValue: round(
                            mean(
                                occupancyData.map(({ previousInterval }) =>
                                    comparisonInterval
                                        ? previousInterval.occupancySessionsHighlightsData.averageSessionDuration
                                        : 0
                                )
                            )
                        ),
                        valueType: 'time',
                        isChangeAbsolute: true,
                        description: t(
                            'occupancy.avgSessionDurationDescription',
                            'average session duration from all seat sessions in the locality'
                        ),
                        get change() {
                            return this.value - (this.comparisonValue ?? 0)
                        },
                    },
                ]

                const occupancyRows = occupancyData.map(({ currentInterval, previousInterval, localityId }) => {
                    const configuredLocality = configuredLocalities.find(({ id }) => id === localityId)

                    const cells: { [key: string]: CellContent } = {
                        locality: {
                            content: configuredLocality?.name ?? '',
                            cellType: 'text',
                            targetLink: addBackLink(
                                generateOrganizationsEditLocalitiesPath(selectedOrganization.id, localityId)
                            ),
                            renderer: AdminOnlyLinkRenderer,
                        },
                        averageDailySeatOccupancy: {
                            content: currentInterval.averageSeatOccupancy,
                            cellType: 'numeric',
                            numberFormat: 'time',
                            comparisonValue: comparisonInterval ? previousInterval.averageSeatOccupancy : 0,
                            valueChange: comparisonInterval
                                ? currentInterval.averageSeatOccupancy - previousInterval.averageSeatOccupancy
                                : 0,
                            isChangeAbsolute: true,
                        },
                        totalSessions: {
                            content: currentInterval.occupancySessionsHighlightsData.totalSessions,
                            cellType: 'numeric',
                            numberFormat: 'count',
                            comparisonValue: comparisonInterval
                                ? previousInterval.occupancySessionsHighlightsData.totalSessions
                                : 0,
                            valueChange: comparisonInterval
                                ? currentInterval.occupancySessionsHighlightsData.totalSessions -
                                  previousInterval.occupancySessionsHighlightsData.totalSessions
                                : 0,
                            isChangeAbsolute: true,
                        },
                        dailySessionsPerSeat: {
                            content: currentInterval.occupancySessionsHighlightsData.dailySessionsPerSeat,
                            cellType: 'numeric',
                            numberFormat: 'count',
                            comparisonValue: comparisonInterval
                                ? previousInterval.occupancySessionsHighlightsData.dailySessionsPerSeat
                                : 0,
                            valueChange: comparisonInterval
                                ? currentInterval.occupancySessionsHighlightsData.dailySessionsPerSeat -
                                  previousInterval.occupancySessionsHighlightsData.dailySessionsPerSeat
                                : 0,
                            isChangeAbsolute: true,
                        },
                        averageSessionDuration: {
                            content: currentInterval.occupancySessionsHighlightsData.averageSessionDuration,
                            cellType: 'numeric',
                            numberFormat: 'time',
                            comparisonValue: comparisonInterval
                                ? previousInterval.occupancySessionsHighlightsData.averageSessionDuration
                                : 0,
                            valueChange: comparisonInterval
                                ? currentInterval.occupancySessionsHighlightsData.averageSessionDuration -
                                  previousInterval.occupancySessionsHighlightsData.averageSessionDuration
                                : 0,
                            isChangeAbsolute: true,
                        },
                        detail: {
                            cellType: 'rawElement',
                            element: (
                                <DetailLink
                                    to={generateOccupancyDetailsCustomPath(
                                        localityId,
                                        migrateTimestamp(
                                            interval.start,
                                            zone,
                                            configuredLocality ? new IANAZone(configuredLocality?.timezone) : zone
                                        ),
                                        migrateTimestamp(
                                            interval.end,
                                            zone,
                                            configuredLocality ? new IANAZone(configuredLocality?.timezone) : zone
                                        )
                                    )}
                                />
                            ),
                        },
                    }

                    return cells
                })

                const handleDownload = () => {
                    const exportData: ExportData = fromPairs(
                        occupancyRows.map((row) => [
                            row.locality.content,
                            [
                                {
                                    [t(
                                        'occupancy.avgDailySeatOccupancyWithUnit',
                                        `Average daily seat occupancy ${t('units.secondsShort', '(s)')}`,
                                        { unit: t('units.secondsShort', '(s)') }
                                    )]: row.averageDailySeatOccupancy.content,
                                    [t('occupancy.totalSessions', 'Total sessions')]: row.totalSessions.content,
                                    [t('occupancy.dailySessionsPerSeat', 'Daily sessions per seat')]:
                                        row.dailySessionsPerSeat.content,
                                    [t(
                                        'occupancy.avgSessionDurationWithUnit',
                                        `Average session duration ${t('units.secondsShort', '(s)')}`,
                                        { unit: t('units.secondsShort', '(s)') }
                                    )]: row.averageSessionDuration.content,
                                },
                            ],
                        ])
                    )

                    if (!isEmpty(exportData)) {
                        exportToExcel(
                            exportData,
                            `${selectedOrganization.name}-occupancy-${formatIntervalToFilename(
                                gdynusaReadableInterval(interval.mapEndpoints(localize))
                            )}`,
                            GoogleEventName.DOWNLOAD_OCCUPANCY_ORGANIZATION_EXCEL,
                            true
                        )
                    } else {
                        notify({
                            title: t('notification.error', 'Error'),
                            content: t('notification.nothingToExport', 'Nothing to export'),
                            variant: 'danger',
                        })
                    }
                }

                return (
                    <Box paddingSize="lg">
                        <Box.Title
                            buttons={
                                <DownloadButtonWrapper
                                    buttonText={t('button.downloadXLSX', 'Download XLSX')}
                                    onClick={handleDownload}
                                />
                            }
                            hideButtons={isEmpty(configuredLocalities)}
                            text={t('heading.occupancyStatistics', 'Occupancy Statistics')}
                        />
                        {!isEmpty(configuredLocalities) && (
                            <>
                                {shouldDisplayLocalitySummary && (
                                    <OccupancyStatisticBox
                                        comparisonInterval={comparisonInterval}
                                        data={occupancyHighlights}
                                        interval={interval}
                                        localities={configuredLocalities}
                                    />
                                )}
                                <SectionSubheading selectedOrganization={selectedOrganization}>
                                    {t('heading.localityComparison', 'Comparison of localities')}
                                </SectionSubheading>
                                <TableWithSorting
                                    bodyRows={occupancyRows}
                                    comparisonInterval={comparisonInterval}
                                    defaultSortingColumn="averageDailySeatOccupancy"
                                    defaultSortingOrder="desc"
                                    excludedSortingColumns={['detail']}
                                    headRow={[
                                        {
                                            name: 'locality',
                                            displayName: t('heading.locality', 'Locality'),
                                        },
                                        {
                                            name: 'averageDailySeatOccupancy',
                                            displayName: t(
                                                'occupancy.avgDailySeatOccupancy',
                                                'Average daily seat occupancy'
                                            ),
                                            align: 'right',
                                        },
                                        {
                                            name: 'totalSessions',
                                            displayName: t('occupancy.totalSessions', 'Total sessions'),
                                            align: 'right',
                                        },
                                        {
                                            name: 'dailySessionsPerSeat',
                                            displayName: t('occupancy.dailySessionsPerSeat', 'Daily sessions per seat'),
                                            align: 'right',
                                        },
                                        {
                                            name: 'averageSessionDuration',
                                            displayName: t('occupancy.avgSessionDuration', 'Average session duration'),
                                            align: 'right',
                                        },
                                        {
                                            name: 'detail',
                                            displayName: t('table.actions', 'Actions'),
                                            align: 'right',
                                        },
                                    ]}
                                    paginationSize={15}
                                />
                            </>
                        )}
                        {!isEmpty(notConfiguredLocalities) && (
                            <NotConfiguredLocalitiesList
                                notConfiguredLocalities={notConfiguredLocalities}
                                nothingConfigured={isEmpty(configuredLocalities)}
                            />
                        )}
                    </Box>
                )
            }}
        </LegacyLoadingWrapper>
    )
}

export default OccupancySection
