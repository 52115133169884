/* tslint:disable */
/* eslint-disable */
/**
 * Vividi Dashboard API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OrganizationFeatures,
    OrganizationFeaturesFromJSON,
    OrganizationFeaturesFromJSONTyped,
    OrganizationFeaturesToJSON,
} from './OrganizationFeatures';

/**
 * 
 * @export
 * @interface OrganizationResponse
 */
export interface OrganizationResponse {
    /**
     * 
     * @type {string}
     * @memberof OrganizationResponse
     */
    name: string;
    /**
     * Report device being offline
     * @type {boolean}
     * @memberof OrganizationResponse
     */
    isMonitoringEnabled?: boolean;
    /**
     * Display statistics summary over all localities on home page
     * @type {boolean}
     * @memberof OrganizationResponse
     */
    isLocalitySummaryEnabled?: boolean;
    /**
     * Create a github ticket after specified number of minutes of device being offline
     * @type {number}
     * @memberof OrganizationResponse
     */
    minutesToDeviceDownTicket?: number;
    /**
     * 
     * @type {number}
     * @memberof OrganizationResponse
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof OrganizationResponse
     */
    ownerId?: number;
    /**
     * 
     * @type {OrganizationFeatures}
     * @memberof OrganizationResponse
     */
    features: OrganizationFeatures;
}

export function OrganizationResponseFromJSON(json: any): OrganizationResponse {
    return OrganizationResponseFromJSONTyped(json, false);
}

export function OrganizationResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrganizationResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'isMonitoringEnabled': !exists(json, 'is_monitoring_enabled') ? undefined : json['is_monitoring_enabled'],
        'isLocalitySummaryEnabled': !exists(json, 'is_locality_summary_enabled') ? undefined : json['is_locality_summary_enabled'],
        'minutesToDeviceDownTicket': !exists(json, 'minutes_to_device_down_ticket') ? undefined : json['minutes_to_device_down_ticket'],
        'id': json['id'],
        'ownerId': !exists(json, 'owner_id') ? undefined : json['owner_id'],
        'features': OrganizationFeaturesFromJSON(json['features']),
    };
}

export function OrganizationResponseToJSON(value?: OrganizationResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'is_monitoring_enabled': value.isMonitoringEnabled,
        'is_locality_summary_enabled': value.isLocalitySummaryEnabled,
        'minutes_to_device_down_ticket': value.minutesToDeviceDownTicket,
        'id': value.id,
        'owner_id': value.ownerId,
        'features': OrganizationFeaturesToJSON(value.features),
    };
}

