import { faTriangleExclamation } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import download from 'downloadjs'
import { isEmpty, last } from 'lodash'
import React from 'react'
import { Alert, Col, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'

import { FloorplanHeatmapData, FloorplanHeatmapType, LocalityResponse } from '@api'

import { statisticsApi } from '@services'

import { GoogleEventCategory, heatmapEvent, trackEvent } from '@helpers/gtag'

import Box from '@elements/Box/Box'

import { AutoSizedImageWithLegendWrapper } from '@components/FloorplanForm/Floorplan'
import NodataView from '@components/NodataView'
import FloorplanDataHeatmapDisplayBox from '@components/StatisticsSummary/FloorplanDataHeatmapDisplayBox'
import FloorplanHeatmapDisplayBox from '@components/StatisticsSummary/FloorplanHeatmapDisplayBox'
import { useNotify } from '@components/notifications/NotificationsContext'
import DownloadButtonWrapper from '@components/plots/downloadButtons/DownloadButtonWrapper'

import heatmapLegend from '@images/heatmap_legend.png'

import styles from './FloorplanHeatmapDetail.module.scss'

interface ButtonProps {
    downloadableHeatmap: FloorplanHeatmapData
    heatmapDownloadType: FloorplanHeatmapType
    selectedLocality: LocalityResponse
}

const FloorplanHeatmapDownloadButton: React.FC<ButtonProps> = ({
    downloadableHeatmap,
    heatmapDownloadType,
    selectedLocality,
}) => {
    const { t } = useTranslation()

    const notify = useNotify()

    const heatmapContentCall = useQuery(
        statisticsApi.getFloorplanHeatmapContent.query({
            heatmapId: downloadableHeatmap.heatmapId,
        })
    )

    const handleDownload = () => {
        if (!heatmapContentCall.data) {
            notify({
                title: t('notification.cannotDownloadImage', 'Image cannot be downloaded'),
                content: t('notification.cannotDownloadImageContent', 'Image download could not be initialized'),
                variant: 'warning',
            })

            return
        }

        const filename = `${selectedLocality.name}-${heatmapDownloadType}.png`

        download(heatmapContentCall.data, filename, 'content-type: image/png')
        trackEvent(GoogleEventCategory.STATISTICS, heatmapEvent[heatmapDownloadType], filename)
    }

    return (
        <DownloadButtonWrapper
            buttonText={t('button.downloadImage', 'Download image')}
            disabled={heatmapContentCall.status !== 'success'}
            onClick={handleDownload}
        />
    )
}

const FloorplanHeatmapDetailsContent: React.FC<{
    floorplan: number
    trajectoryHeatmap?: FloorplanHeatmapData
    utilityGridHeatmap?: FloorplanHeatmapData
    utilityGridDataHeatmap?: FloorplanHeatmapData
    dwellTimeHeatmap?: FloorplanHeatmapData
    dwellTimeDataHeatmap?: FloorplanHeatmapData
    selectedLocality: LocalityResponse
}> = ({
    floorplan,
    trajectoryHeatmap,
    utilityGridHeatmap,
    dwellTimeHeatmap,
    utilityGridDataHeatmap,
    dwellTimeDataHeatmap,
    selectedLocality,
}) => {
    const { t } = useTranslation()

    const isUtilityGridVisible = !isEmpty(utilityGridHeatmap) && !isEmpty(utilityGridDataHeatmap)

    const isDwellTimeGridVisible = !isEmpty(dwellTimeHeatmap) && !isEmpty(dwellTimeDataHeatmap)

    const heatmapsCall = useQuery({
        ...statisticsApi.getFloorplanHeatmapList.query({ floorplanId: floorplan }),
        enabled: !trajectoryHeatmap?.heatmapId,
    })

    const lastAvailableHeatmap = last(
        heatmapsCall.data?.heatmaps.filter((heatmap) => heatmap.heatmapType === FloorplanHeatmapType.Trajectories)
    )

    return (
        <>
            <Row>
                <Col>
                    <Box>
                        <Box.Title
                            buttons={
                                !isEmpty(trajectoryHeatmap) && (
                                    <FloorplanHeatmapDownloadButton
                                        downloadableHeatmap={trajectoryHeatmap!}
                                        heatmapDownloadType={FloorplanHeatmapType.Trajectories}
                                        selectedLocality={selectedLocality}
                                    />
                                )
                            }
                            text={t('heading.spaceUtilizationHeatmap', 'Space Utilization - Heatmap')}
                        />
                        <p className={styles.metricDescription}>
                            {t(
                                'heatmapDetailPage.spaceUtilizationHeatmapDescription',
                                'The space utilization heatmap shows continuous data about the floor plan utilization within the opening hours of the selected week. More intense color represents higher number of visits in that specific place on the floor plan.'
                            )}
                        </p>
                        {!isEmpty(trajectoryHeatmap) || !isEmpty(lastAvailableHeatmap) ? (
                            <>
                                <FloorplanHeatmapDisplayBox heatmap={trajectoryHeatmap ?? lastAvailableHeatmap} />
                                <div className={styles.intensityImageContainer}>
                                    <AutoSizedImageWithLegendWrapper src={heatmapLegend} />
                                </div>
                            </>
                        ) : (
                            <NodataView
                                message={t(
                                    'heatmapDetailPage.noSpaceUtilizationHeatmap',
                                    'No Space Utilization heatmap available for selected period'
                                )}
                            />
                        )}
                    </Box>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Box>
                        <Box.Title
                            buttons={
                                isUtilityGridVisible && (
                                    <FloorplanHeatmapDownloadButton
                                        downloadableHeatmap={utilityGridHeatmap!}
                                        heatmapDownloadType={FloorplanHeatmapType.UtilityGrid}
                                        selectedLocality={selectedLocality}
                                    />
                                )
                            }
                            text={t('heading.spaceUtilizationGrid', 'Space Utilization - Grid')}
                        />
                        <p className={styles.metricDescription}>
                            {t(
                                'heatmapDetailPage.spaceUtilizationGridDescription',
                                "The space utilization grid shows which sectors are most and least visited within the opening hours of the selected week. Only people who are present in the scene longer than 3 seconds are counted in the statistics. If the same person enters into the same sector multiple times, it's interpreted as multiple sector interactions."
                            )}
                        </p>
                        {isUtilityGridVisible ? (
                            <>
                                <Alert className={styles.alertContainer} variant="warning">
                                    <FontAwesomeIcon icon={faTriangleExclamation} />
                                    {t(
                                        'notification.gridHeatmapWarning',
                                        'Grid visualization is a part of the VIVIDI insider preview features. Temporary inconsistencies might occur.'
                                    )}
                                </Alert>
                                <FloorplanDataHeatmapDisplayBox
                                    dataHeatmap={utilityGridDataHeatmap!}
                                    heatmap={utilityGridHeatmap!}
                                    sectorValueType="people"
                                    tooltipText={t('tooltip.sectorInteractions', 'Sector interactions ')}
                                    tooltipTitle={t('tooltip.spaceUtilizationFrom', 'Space utilization from ')}
                                />
                            </>
                        ) : (
                            <NodataView
                                message={t(
                                    'heatmapDetailPage.noSpaceUtilizationGrid',
                                    'No space utilization grid available for selected period'
                                )}
                            />
                        )}
                    </Box>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Box>
                        <Box.Title
                            buttons={
                                isDwellTimeGridVisible && (
                                    <FloorplanHeatmapDownloadButton
                                        downloadableHeatmap={dwellTimeHeatmap!}
                                        heatmapDownloadType={FloorplanHeatmapType.DwellTime}
                                        selectedLocality={selectedLocality}
                                    />
                                )
                            }
                            text={t('heading.avgDwellTime', 'Average dwell time')}
                        />
                        <p className={styles.metricDescription}>
                            {t(
                                'heatmapDetailPage.avgDwellTimeDescription',
                                'The floor plan grid shows the average dwell time in individual floor plan sectors within the opening hours of the selected week. Only people who are present in the scene longer than 3 seconds are counted in the statistics.'
                            )}
                        </p>
                        {isDwellTimeGridVisible ? (
                            <>
                                <Alert className={styles.alertContainer} variant="warning">
                                    <FontAwesomeIcon icon={faTriangleExclamation} />
                                    {t(
                                        'notification.gridHeatmapWarning',
                                        'Grid visualization is a part of the VIVIDI insider preview features. Temporary inconsistencies might occur.'
                                    )}
                                </Alert>

                                <FloorplanDataHeatmapDisplayBox
                                    dataHeatmap={dwellTimeDataHeatmap!}
                                    heatmap={dwellTimeHeatmap!}
                                    sectorValueType="time"
                                    tooltipText={t('tooltip.dwellTimeInSector', 'Dwell time in sector ')}
                                    tooltipTitle={t('tooltip.avgDwellTimeFrom', 'Average dwell time from ')}
                                />
                            </>
                        ) : (
                            <NodataView
                                message={t(
                                    'heatmapDetailPage.noAvgDwellTimeGrid',
                                    'No average dwell time grid available for selected period'
                                )}
                            />
                        )}
                    </Box>
                </Col>
            </Row>
        </>
    )
}

export default FloorplanHeatmapDetailsContent
