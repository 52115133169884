export const formatBytes = (bytes: number, decimals = 2) => {
    if (bytes === 0) return '0 Bytes'

    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}

export const convertImageToPng = (file: File) =>
    new Promise<Blob>((resolve, reject) => {
        const reader = new FileReader()
        reader.onload = () => {
            const img = new Image()
            img.crossOrigin = 'Anonymous'
            img.onload = () => {
                const canvas = document.createElement('canvas')
                const ctx = canvas.getContext('2d')
                canvas.height = img.naturalHeight
                canvas.width = img.naturalWidth
                ctx!.drawImage(img, 0, 0)

                canvas.toBlob((blob) => {
                    if (blob) {
                        resolve(blob)
                    } else {
                        reject(new Error('Conversion to blob failed'))
                    }
                }, 'image/png')
            }

            img.onerror = () => {
                reject(new Error('Image failed to load'))
            }

            img.src = reader.result as string
        }
        reader.readAsDataURL(file)
    })
