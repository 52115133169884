import produce from 'immer'
import { first, fromPairs } from 'lodash'
import React, { useState } from 'react'
import { Form, Row, Col } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { MutationStatus, UseQueryResult } from 'react-query'

import { LocalityNameModel, OrganizationResponse, ProfileResponse, Role } from '@api/models'

import { ErrorEntry } from '@helpers/types'
import { isEmailValid } from '@helpers/validations'

import AsyncButton from '@components/AsyncButton'
import TextInput from '@components/GenericInputs/TextInput'
import MultiSelectBox from '@components/MultiSelectBox'
import RoleChecker from '@components/RoleChecker'
import { useNotify } from '@components/notifications/NotificationsContext'

import OrganizationPicker from './OrganizationPicker'
import RolePicker from './RolePicker'

interface Errors {
    email: ErrorEntry
    [other: string]: ErrorEntry
}
interface Props {
    onSubmit: ({
        profile,
        values,
    }: {
        profile: ProfileResponse
        values: {
            email: string
            role: Role
            organizationId?: number
            localities: LocalityNameModel[]
        }
    }) => void
    submissionStatus: MutationStatus
    organizations: OrganizationResponse[]
    localities: LocalityNameModel[]
    profile: UseQueryResult<ProfileResponse>
}

const CreateUserForm = (props: Props) => {
    const { t } = useTranslation()

    const [email, setEmail] = useState('')
    const [role, setRole] = useState(Role.User)
    const [selectedOrganization, setSelectedOrganization] = useState<OrganizationResponse | undefined>(
        first(props.organizations)
    )
    const [selectedLocalities, setSelectedLocalities] = useState<Array<LocalityNameModel>>([])
    const [errors, setErrors] = useState<Errors>({
        email: {
            isInvalid: true,
            isTouched: false,
        },
    })

    const notify = useNotify()

    const handleSetOrganization = (organizationId?: number) => {
        const organization = props.organizations.find((organization) => organization.id === organizationId)
        setSelectedOrganization(organization)
        const localities = props.localities.filter((locality) => locality.organizationId === organizationId)
        setSelectedLocalities(localities)
    }

    const handleLocalitiesSelect = (localityIds: Array<string>) => {
        const selectedLocalities = props.localities.filter((locality) => localityIds.includes(locality.id.toString()))

        setSelectedLocalities(selectedLocalities)
    }

    const localityChoices = fromPairs(
        props.localities
            .filter((locality) => locality.organizationId === selectedOrganization?.id)
            .map((l) => [l.id.toString(), l.name])
    )

    const onSubmit: React.FormEventHandler = async (e) => {
        e.preventDefault()

        setErrors((prev) => produce(prev, (draft) => Object.keys(draft).forEach((k) => (draft[k].isTouched = true))))

        if (errors.email.isInvalid) {
            notify({
                title: t('notification.invalidInput', 'Invalid input'),
                content: t('notification.invalidEmail', 'Email address is not valid'),
                variant: 'warning',
            })

            return
        }

        try {
            if (props.profile.data !== undefined) {
                await props.onSubmit({
                    profile: props.profile.data,
                    values: { email, role, organizationId: selectedOrganization?.id, localities: selectedLocalities },
                })

                notify({
                    title: t('notification.userCreated', 'User created'),
                    content: t('notification.userCreatedContent', 'New user has been successfully created.'),
                    variant: 'success',
                })
            }
        } catch (error) {
            if ((error as any).status === 409) {
                notify({
                    title: t('notification.error', 'Error'),
                    content: t('notification.userAlreadyExists', 'User you are trying to add already exists.'),
                    variant: 'warning',
                })

                return
            }
            notify({
                title: t('notification.error', 'Error'),
                content: t('notification.userCreateFailed', 'Something went wrong and the user was not added.'),
                variant: 'danger',
            })
        }
        setEmail('')
        setRole(Role.User)
    }

    const handleSetEmail = (value: string) => {
        setErrors((prev) =>
            produce(prev, (draft) => {
                draft.email = {
                    isInvalid: value === '' || !isEmailValid(value),
                    isTouched: true,
                }
            })
        )
        setEmail(value)
    }

    const isLocalityPickerDisabled = role === Role.OrganizationOwner || role === Role.OrganizationAdministrator

    return (
        <Form onSubmit={onSubmit}>
            <Form.Group as={Row}>
                <Form.Label column={true} sm={2}>
                    {t('form.email', 'E-mail')}
                </Form.Label>
                <Col sm={10}>
                    <TextInput
                        isInvalid={errors.email.isInvalid && errors.email.isTouched}
                        value={email}
                        onChange={handleSetEmail}
                    />
                </Col>
            </Form.Group>
            <RoleChecker whitelist={[Role.Administrator]}>
                <Form.Group as={Row}>
                    <Form.Label column={true} sm={2}>
                        {t('form.organization', 'Organization')}
                    </Form.Label>
                    <Col sm={10}>
                        <OrganizationPicker
                            organizationId={selectedOrganization?.id}
                            organizations={props.organizations}
                            onChange={handleSetOrganization}
                        />
                    </Col>
                </Form.Group>
            </RoleChecker>
            <Form.Group as={Row}>
                <Form.Label column={true} sm={2}>
                    {t('form.role', 'Role')}
                </Form.Label>
                <Col sm={10}>
                    <RolePicker role={role} onChange={setRole} />
                </Col>
            </Form.Group>
            <Form.Group as={Row}>
                <Form.Label column={true} sm={2}>
                    {t('table.localities', 'Localities')}
                </Form.Label>
                <Col sm={10}>
                    <MultiSelectBox
                        choices={localityChoices}
                        disabled={Object.values(localityChoices).length === 0 || isLocalityPickerDisabled}
                        selection={selectedLocalities.map((l) => l.id.toString())}
                        onSelect={handleLocalitiesSelect}
                    />
                </Col>
            </Form.Group>
            <Form.Group as={Row}>
                <Col sm={{ span: 10 }}>
                    <AsyncButton
                        loadingText={t('button.saving', 'Saving...')}
                        status={props.submissionStatus}
                        text={t('button.save', 'Save')}
                        type="submit"
                        variant="primary"
                    />
                </Col>
            </Form.Group>
        </Form>
    )
}

export default CreateUserForm
