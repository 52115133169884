import classNames from 'classnames'
import React from 'react'
import { Card } from 'react-bootstrap'

import CardTitle from '@elements/Card/CardTitle'

import styles from './Box.module.scss'

const Box: React.FC<{
    children: React.ReactNode | React.ReactNode[]
    paddingSize?: 'md' | 'lg'
    className?: string
}> = ({ children, paddingSize = 'md', className }) => (
    <Card>
        <Card.Body
            className={classNames(className, {
                [styles.cardPaddingLarge]: paddingSize === 'lg',
            })}
        >
            {children}
        </Card.Body>
    </Card>
)

export default Object.assign(Box, { Title: CardTitle })
