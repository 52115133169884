import { isEmpty, round, sum, takeRight } from 'lodash'
import { DateTime, Interval } from 'luxon'

import { CrowdSizeHistoryItem, PropertyName, PropertySummaryResponse } from '@api/models'

import { parseInterval } from '@components/StatisticsSummary/utils'

export const prepareGenderBreakdownPieChartData = (
    propertySummary: PropertySummaryResponse
):
    | {
          male: number
          female: number
      }
    | undefined => {
    const intervalsWithGenderDistribution = propertySummary.items.filter(
        (interval) => interval.properties[PropertyName.IsMale]
    )

    //take data from the last 4 intervals containing any data at all

    const last = takeRight(intervalsWithGenderDistribution, 4)

    const femaleCount = sum(last.map((it) => it.properties[PropertyName.IsMale]['0']))
    const maleCount = sum(last.map((it) => it.properties[PropertyName.IsMale]['1']))
    const total = maleCount + femaleCount

    const breakDown = {
        male: total > 0 ? round((maleCount * 100) / total) : 0,
        female: total > 0 ? round((femaleCount * 100) / total) : 0,
    }

    return !isEmpty(last) ? breakDown : undefined
}

type CrowdHistoryData = {
    name: string
    openedPortion: number
    interval: Interval
    maxCrowdSize?: number
    hasData: boolean
}

export const prepareVisitorsInTimeData = (
    crowdSizeHistory: CrowdSizeHistoryItem[],
    now: DateTime
): Array<CrowdHistoryData> => {
    const data = crowdSizeHistory.map((record, index) => {
        const interval = parseInterval(record)

        const isIntervalInFuture = interval.start > now

        if (isIntervalInFuture) {
            return {
                name: index.toString(),
                openedPortion: record.openedPortion,
                interval,
                hasData: false,
            }
        }

        return {
            name: index.toString(),
            openedPortion: record.openedPortion,
            interval,
            maxCrowdSize: record.maxCrowdSize ?? 0,
            hasData: record.maxCrowdSize !== undefined,
        }
    })

    return data
}
