/* tslint:disable */
/* eslint-disable */
/**
 * Vividi Dashboard API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DeviceTransferInfo
 */
export interface DeviceTransferInfo {
    /**
     * 
     * @type {string}
     * @memberof DeviceTransferInfo
     */
    at: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceTransferInfo
     */
    to: string;
}

export function DeviceTransferInfoFromJSON(json: any): DeviceTransferInfo {
    return DeviceTransferInfoFromJSONTyped(json, false);
}

export function DeviceTransferInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeviceTransferInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'at': json['at'],
        'to': json['to'],
    };
}

export function DeviceTransferInfoToJSON(value?: DeviceTransferInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'at': value.at,
        'to': value.to,
    };
}

