import { Duration } from 'luxon'
import React from 'react'
import { SliderItem } from 'react-compound-slider'

import styles from './OpeningHoursPicker.module.scss'

interface TickProps {
    label: Duration
    key: string
    tick: SliderItem
    count: number
}

const Tick: React.FC<TickProps> = ({ tick, count, label }) => (
    <div>
        <div
            className={styles.tickMarker}
            style={{
                left: `${tick.percent}%`,
            }}
        />
        <div
            className={styles.tickLabel}
            style={{
                marginLeft: `${-(100 / count) / 2}%`,
                width: `${100 / count}%`,
                left: `${tick.percent}%`,
            }}
        >
            {label.toFormat('hh:mm')}
        </div>
    </div>
)

export default Tick
