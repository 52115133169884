/**
 * Vividi Dashboard API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime'
import {UseQueryOptions, QueryKey} from 'react-query'

import {
    DeviceDiagnostics,
    DeviceDiagnosticsFromJSON,
    DeviceDiagnosticsToJSON,
    DeviceListResponse,
    DeviceListResponseFromJSON,
    DeviceListResponseToJSON,
    DeviceResponse,
    DeviceResponseFromJSON,
    DeviceResponseToJSON,
    DeviceStatusListRequestBody,
    DeviceStatusListRequestBodyFromJSON,
    DeviceStatusListRequestBodyToJSON,
    DeviceStatusListResponse,
    DeviceStatusListResponseFromJSON,
    DeviceStatusListResponseToJSON,
    DeviceStatusResponse,
    DeviceStatusResponseFromJSON,
    DeviceStatusResponseToJSON,
    ErrorResponse,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    ReceiveDeviceTransferRequestBody,
    ReceiveDeviceTransferRequestBodyFromJSON,
    ReceiveDeviceTransferRequestBodyToJSON,
    SendCommandRequestBody,
    SendCommandRequestBodyFromJSON,
    SendCommandRequestBodyToJSON,
    SuccessResponse,
    SuccessResponseFromJSON,
    SuccessResponseToJSON,
    UpdateDeviceRequestBody,
    UpdateDeviceRequestBodyFromJSON,
    UpdateDeviceRequestBodyToJSON,
} from '../models';

export interface ApproveDeviceRequest {
    deviceId: number;
    newId?: number;
}

export interface DeleteDeviceRequest {
    deviceId: number;
}

export interface FetchReferenceCaptureRequest {
    deviceId: number;
}

export interface FetchSnapshotRequest {
    deviceId: number;
    withOverlay?: string;
}

export interface GetDeviceRequest {
    deviceId: number;
}

export interface GetDeviceDiagnosticsRequest {
    deviceId: number;
}

export interface GetDeviceLastStatusMultipleRequest {
    body: DeviceStatusListRequestBody;
}

export interface GetDeviceStatusRequest {
    deviceId: number;
}

export interface ReceiveTransferredDeviceRequest {
    body: ReceiveDeviceTransferRequestBody;
}

export interface RequestSystemRestartRequest {
    deviceId: number;
}

export interface RequestTrackerRestartRequest {
    deviceId: number;
}

export interface RequestWifiResetRequest {
    deviceId: number;
}

export interface SendCommandRequest {
    deviceId: number;
    body: SendCommandRequestBody;
}

export interface TransferDeviceRequest {
    deviceId: number;
    destination: string;
}

export interface UpdateDeviceRequest {
    deviceId: number;
    body: UpdateDeviceRequestBody;
}


/**
 * 
 */
export class DeviceManagementApi extends runtime.BaseAPI {
    readonly name = 'DeviceManagementApi'

    /**
     * Approve a generation 1 device for use and allow it to connect.
     */
    approveDevice = Object.assign(
        async (requestParameters: ApproveDeviceRequest, initOverrides?: RequestInit): Promise<DeviceResponse> => {
            const response = await this.approveDevice.invokeRaw(requestParameters, initOverrides);
            return await response.value();
        },
        {
            invokeRaw: async (requestParameters: ApproveDeviceRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<DeviceResponse>> => {
                if (requestParameters.deviceId === null || requestParameters.deviceId === undefined) {
                    throw new runtime.RequiredError('deviceId','Required parameter requestParameters.deviceId was null or undefined when calling approveDevice.');
                }
            
                const queryParameters: any = {};
            
                if (requestParameters.newId !== undefined) {
                    queryParameters['new_id'] = requestParameters.newId;
                }
            
                const headerParameters: runtime.HTTPHeaders = {};
            
                if (this.configuration && this.configuration.accessToken) {
                    const token = this.configuration.accessToken;
                    const tokenString = await token("token", []);
            
                    if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                    }
                }
                const response = await this.request({
                    path: `/api/devices/{device_id}/approve`.replace(`{${"device_id"}}`, encodeURIComponent(String(requestParameters.deviceId))),
                    method: 'POST',
                    headers: headerParameters,
                    query: queryParameters,
                }, initOverrides);
            
                return new runtime.JSONApiResponse(response, (jsonValue) => DeviceResponseFromJSON(jsonValue));
            },
            mutationKey: [
                'DeviceManagementApi',
                'approveDevice', 
            ],
            queryKey: (requestParameters: ApproveDeviceRequest, initOverrides?: RequestInit) => ([
                    ...this.approveDevice.mutationKey, 
                {
                deviceId: requestParameters.deviceId,
                newId: requestParameters.newId,
                }
            ]),
            query: (requestParameters: ApproveDeviceRequest, initOverrides?: RequestInit): UseQueryOptions<DeviceResponse> & {queryKey: QueryKey} => ({
                queryFn: () => this.approveDevice(requestParameters, initOverrides),
                queryKey: this.approveDevice.queryKey(requestParameters, initOverrides)
            })
        }
    )

    /**
     * Delete a device, along with all its statistics, configuration and other information.
     */
    deleteDevice = Object.assign(
        async (requestParameters: DeleteDeviceRequest, initOverrides?: RequestInit): Promise<SuccessResponse> => {
            const response = await this.deleteDevice.invokeRaw(requestParameters, initOverrides);
            return await response.value();
        },
        {
            invokeRaw: async (requestParameters: DeleteDeviceRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SuccessResponse>> => {
                if (requestParameters.deviceId === null || requestParameters.deviceId === undefined) {
                    throw new runtime.RequiredError('deviceId','Required parameter requestParameters.deviceId was null or undefined when calling deleteDevice.');
                }
            
                const queryParameters: any = {};
            
                const headerParameters: runtime.HTTPHeaders = {};
            
                if (this.configuration && this.configuration.accessToken) {
                    const token = this.configuration.accessToken;
                    const tokenString = await token("token", []);
            
                    if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                    }
                }
                const response = await this.request({
                    path: `/api/devices/{device_id}`.replace(`{${"device_id"}}`, encodeURIComponent(String(requestParameters.deviceId))),
                    method: 'DELETE',
                    headers: headerParameters,
                    query: queryParameters,
                }, initOverrides);
            
                return new runtime.JSONApiResponse(response, (jsonValue) => SuccessResponseFromJSON(jsonValue));
            },
            mutationKey: [
                'DeviceManagementApi',
                'deleteDevice', 
            ],
            queryKey: (requestParameters: DeleteDeviceRequest, initOverrides?: RequestInit) => ([
                    ...this.deleteDevice.mutationKey, 
                {
                deviceId: requestParameters.deviceId,
                }
            ]),
            query: (requestParameters: DeleteDeviceRequest, initOverrides?: RequestInit): UseQueryOptions<SuccessResponse> & {queryKey: QueryKey} => ({
                queryFn: () => this.deleteDevice(requestParameters, initOverrides),
                queryKey: this.deleteDevice.queryKey(requestParameters, initOverrides)
            })
        }
    )

    /**
     * Fetch the reference capture (for camera shift detection) from the device.
     */
    fetchReferenceCapture = Object.assign(
        async (requestParameters: FetchReferenceCaptureRequest, initOverrides?: RequestInit): Promise<Blob> => {
            const response = await this.fetchReferenceCapture.invokeRaw(requestParameters, initOverrides);
            return await response.value();
        },
        {
            invokeRaw: async (requestParameters: FetchReferenceCaptureRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Blob>> => {
                if (requestParameters.deviceId === null || requestParameters.deviceId === undefined) {
                    throw new runtime.RequiredError('deviceId','Required parameter requestParameters.deviceId was null or undefined when calling fetchReferenceCapture.');
                }
            
                const queryParameters: any = {};
            
                const headerParameters: runtime.HTTPHeaders = {};
            
                if (this.configuration && this.configuration.accessToken) {
                    const token = this.configuration.accessToken;
                    const tokenString = await token("token", []);
            
                    if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                    }
                }
                const response = await this.request({
                    path: `/api/devices/{device_id}/reference-capture`.replace(`{${"device_id"}}`, encodeURIComponent(String(requestParameters.deviceId))),
                    method: 'POST',
                    headers: headerParameters,
                    query: queryParameters,
                }, initOverrides);
            
                return new runtime.BlobApiResponse(response);
            },
            mutationKey: [
                'DeviceManagementApi',
                'fetchReferenceCapture', 
            ],
            queryKey: (requestParameters: FetchReferenceCaptureRequest, initOverrides?: RequestInit) => ([
                    ...this.fetchReferenceCapture.mutationKey, 
                {
                deviceId: requestParameters.deviceId,
                }
            ]),
            query: (requestParameters: FetchReferenceCaptureRequest, initOverrides?: RequestInit): UseQueryOptions<Blob> & {queryKey: QueryKey} => ({
                queryFn: () => this.fetchReferenceCapture(requestParameters, initOverrides),
                queryKey: this.fetchReferenceCapture.queryKey(requestParameters, initOverrides)
            })
        }
    )

    /**
     * Request a snapshot from the device.
     */
    fetchSnapshot = Object.assign(
        async (requestParameters: FetchSnapshotRequest, initOverrides?: RequestInit): Promise<Blob> => {
            const response = await this.fetchSnapshot.invokeRaw(requestParameters, initOverrides);
            return await response.value();
        },
        {
            invokeRaw: async (requestParameters: FetchSnapshotRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Blob>> => {
                if (requestParameters.deviceId === null || requestParameters.deviceId === undefined) {
                    throw new runtime.RequiredError('deviceId','Required parameter requestParameters.deviceId was null or undefined when calling fetchSnapshot.');
                }
            
                const queryParameters: any = {};
            
                if (requestParameters.withOverlay !== undefined) {
                    queryParameters['with_overlay'] = requestParameters.withOverlay;
                }
            
                const headerParameters: runtime.HTTPHeaders = {};
            
                if (this.configuration && this.configuration.accessToken) {
                    const token = this.configuration.accessToken;
                    const tokenString = await token("token", []);
            
                    if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                    }
                }
                const response = await this.request({
                    path: `/api/devices/{device_id}/snapshot`.replace(`{${"device_id"}}`, encodeURIComponent(String(requestParameters.deviceId))),
                    method: 'POST',
                    headers: headerParameters,
                    query: queryParameters,
                }, initOverrides);
            
                return new runtime.BlobApiResponse(response);
            },
            mutationKey: [
                'DeviceManagementApi',
                'fetchSnapshot', 
            ],
            queryKey: (requestParameters: FetchSnapshotRequest, initOverrides?: RequestInit) => ([
                    ...this.fetchSnapshot.mutationKey, 
                {
                deviceId: requestParameters.deviceId,
                withOverlay: requestParameters.withOverlay,
                }
            ]),
            query: (requestParameters: FetchSnapshotRequest, initOverrides?: RequestInit): UseQueryOptions<Blob> & {queryKey: QueryKey} => ({
                queryFn: () => this.fetchSnapshot(requestParameters, initOverrides),
                queryKey: this.fetchSnapshot.queryKey(requestParameters, initOverrides)
            })
        }
    )

    /**
     */
    getDevice = Object.assign(
        async (requestParameters: GetDeviceRequest, initOverrides?: RequestInit): Promise<DeviceResponse> => {
            const response = await this.getDevice.invokeRaw(requestParameters, initOverrides);
            return await response.value();
        },
        {
            invokeRaw: async (requestParameters: GetDeviceRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<DeviceResponse>> => {
                if (requestParameters.deviceId === null || requestParameters.deviceId === undefined) {
                    throw new runtime.RequiredError('deviceId','Required parameter requestParameters.deviceId was null or undefined when calling getDevice.');
                }
            
                const queryParameters: any = {};
            
                const headerParameters: runtime.HTTPHeaders = {};
            
                if (this.configuration && this.configuration.accessToken) {
                    const token = this.configuration.accessToken;
                    const tokenString = await token("token", []);
            
                    if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                    }
                }
                const response = await this.request({
                    path: `/api/devices/{device_id}`.replace(`{${"device_id"}}`, encodeURIComponent(String(requestParameters.deviceId))),
                    method: 'GET',
                    headers: headerParameters,
                    query: queryParameters,
                }, initOverrides);
            
                return new runtime.JSONApiResponse(response, (jsonValue) => DeviceResponseFromJSON(jsonValue));
            },
            mutationKey: [
                'DeviceManagementApi',
                'getDevice', 
            ],
            queryKey: (requestParameters: GetDeviceRequest, initOverrides?: RequestInit) => ([
                    ...this.getDevice.mutationKey, 
                {
                deviceId: requestParameters.deviceId,
                }
            ]),
            query: (requestParameters: GetDeviceRequest, initOverrides?: RequestInit): UseQueryOptions<DeviceResponse> & {queryKey: QueryKey} => ({
                queryFn: () => this.getDevice(requestParameters, initOverrides),
                queryKey: this.getDevice.queryKey(requestParameters, initOverrides)
            })
        }
    )

    /**
     */
    getDeviceDiagnostics = Object.assign(
        async (requestParameters: GetDeviceDiagnosticsRequest, initOverrides?: RequestInit): Promise<DeviceDiagnostics> => {
            const response = await this.getDeviceDiagnostics.invokeRaw(requestParameters, initOverrides);
            return await response.value();
        },
        {
            invokeRaw: async (requestParameters: GetDeviceDiagnosticsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<DeviceDiagnostics>> => {
                if (requestParameters.deviceId === null || requestParameters.deviceId === undefined) {
                    throw new runtime.RequiredError('deviceId','Required parameter requestParameters.deviceId was null or undefined when calling getDeviceDiagnostics.');
                }
            
                const queryParameters: any = {};
            
                const headerParameters: runtime.HTTPHeaders = {};
            
                if (this.configuration && this.configuration.accessToken) {
                    const token = this.configuration.accessToken;
                    const tokenString = await token("secret_key", []);
            
                    if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                    }
                }
                if (this.configuration && this.configuration.accessToken) {
                    const token = this.configuration.accessToken;
                    const tokenString = await token("token", []);
            
                    if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                    }
                }
                const response = await this.request({
                    path: `/api/devices/{device_id}/diagnostics`.replace(`{${"device_id"}}`, encodeURIComponent(String(requestParameters.deviceId))),
                    method: 'GET',
                    headers: headerParameters,
                    query: queryParameters,
                }, initOverrides);
            
                return new runtime.JSONApiResponse(response, (jsonValue) => DeviceDiagnosticsFromJSON(jsonValue));
            },
            mutationKey: [
                'DeviceManagementApi',
                'getDeviceDiagnostics', 
            ],
            queryKey: (requestParameters: GetDeviceDiagnosticsRequest, initOverrides?: RequestInit) => ([
                    ...this.getDeviceDiagnostics.mutationKey, 
                {
                deviceId: requestParameters.deviceId,
                }
            ]),
            query: (requestParameters: GetDeviceDiagnosticsRequest, initOverrides?: RequestInit): UseQueryOptions<DeviceDiagnostics> & {queryKey: QueryKey} => ({
                queryFn: () => this.getDeviceDiagnostics(requestParameters, initOverrides),
                queryKey: this.getDeviceDiagnostics.queryKey(requestParameters, initOverrides)
            })
        }
    )

    /**
     * Get last recorded status information for multiple devices.
     */
    getDeviceLastStatusMultiple = Object.assign(
        async (requestParameters: GetDeviceLastStatusMultipleRequest, initOverrides?: RequestInit): Promise<DeviceStatusListResponse> => {
            const response = await this.getDeviceLastStatusMultiple.invokeRaw(requestParameters, initOverrides);
            return await response.value();
        },
        {
            invokeRaw: async (requestParameters: GetDeviceLastStatusMultipleRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<DeviceStatusListResponse>> => {
                if (requestParameters.body === null || requestParameters.body === undefined) {
                    throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling getDeviceLastStatusMultiple.');
                }
            
                const queryParameters: any = {};
            
                const headerParameters: runtime.HTTPHeaders = {};
            
                headerParameters['Content-Type'] = 'application/json';
            
                if (this.configuration && this.configuration.accessToken) {
                    const token = this.configuration.accessToken;
                    const tokenString = await token("token", []);
            
                    if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                    }
                }
                const response = await this.request({
                    path: `/api/devices/last-status`,
                    method: 'POST',
                    headers: headerParameters,
                    query: queryParameters,
                    body: DeviceStatusListRequestBodyToJSON(requestParameters.body),
                }, initOverrides);
            
                return new runtime.JSONApiResponse(response, (jsonValue) => DeviceStatusListResponseFromJSON(jsonValue));
            },
            mutationKey: [
                'DeviceManagementApi',
                'getDeviceLastStatusMultiple', 
            ],
            queryKey: (requestParameters: GetDeviceLastStatusMultipleRequest, initOverrides?: RequestInit) => ([
                    ...this.getDeviceLastStatusMultiple.mutationKey, 
                {
                body: requestParameters.body,
                }
            ]),
            query: (requestParameters: GetDeviceLastStatusMultipleRequest, initOverrides?: RequestInit): UseQueryOptions<DeviceStatusListResponse> & {queryKey: QueryKey} => ({
                queryFn: () => this.getDeviceLastStatusMultiple(requestParameters, initOverrides),
                queryKey: this.getDeviceLastStatusMultiple.queryKey(requestParameters, initOverrides)
            })
        }
    )

    /**
     */
    getDeviceList = Object.assign(
        async (initOverrides?: RequestInit): Promise<DeviceListResponse> => {
            const response = await this.getDeviceList.invokeRaw(initOverrides);
            return await response.value();
        },
        {
            invokeRaw: async (initOverrides?: RequestInit): Promise<runtime.ApiResponse<DeviceListResponse>> => {
                const queryParameters: any = {};
            
                const headerParameters: runtime.HTTPHeaders = {};
            
                if (this.configuration && this.configuration.accessToken) {
                    const token = this.configuration.accessToken;
                    const tokenString = await token("token", []);
            
                    if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                    }
                }
                const response = await this.request({
                    path: `/api/devices/`,
                    method: 'GET',
                    headers: headerParameters,
                    query: queryParameters,
                }, initOverrides);
            
                return new runtime.JSONApiResponse(response, (jsonValue) => DeviceListResponseFromJSON(jsonValue));
            },
            mutationKey: [
                'DeviceManagementApi',
                'getDeviceList', 
            ],
            queryKey: (initOverrides?: RequestInit) => ([
                    ...this.getDeviceList.mutationKey, 
                {
                }
            ]),
            query: (initOverrides?: RequestInit): UseQueryOptions<DeviceListResponse> & {queryKey: QueryKey} => ({
                queryFn: () => this.getDeviceList(initOverrides),
                queryKey: this.getDeviceList.queryKey(initOverrides)
            })
        }
    )

    /**
     * Get status information about a device.
     */
    getDeviceStatus = Object.assign(
        async (requestParameters: GetDeviceStatusRequest, initOverrides?: RequestInit): Promise<DeviceStatusResponse> => {
            const response = await this.getDeviceStatus.invokeRaw(requestParameters, initOverrides);
            return await response.value();
        },
        {
            invokeRaw: async (requestParameters: GetDeviceStatusRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<DeviceStatusResponse>> => {
                if (requestParameters.deviceId === null || requestParameters.deviceId === undefined) {
                    throw new runtime.RequiredError('deviceId','Required parameter requestParameters.deviceId was null or undefined when calling getDeviceStatus.');
                }
            
                const queryParameters: any = {};
            
                const headerParameters: runtime.HTTPHeaders = {};
            
                if (this.configuration && this.configuration.accessToken) {
                    const token = this.configuration.accessToken;
                    const tokenString = await token("token", []);
            
                    if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                    }
                }
                const response = await this.request({
                    path: `/api/devices/{device_id}/status`.replace(`{${"device_id"}}`, encodeURIComponent(String(requestParameters.deviceId))),
                    method: 'POST',
                    headers: headerParameters,
                    query: queryParameters,
                }, initOverrides);
            
                return new runtime.JSONApiResponse(response, (jsonValue) => DeviceStatusResponseFromJSON(jsonValue));
            },
            mutationKey: [
                'DeviceManagementApi',
                'getDeviceStatus', 
            ],
            queryKey: (requestParameters: GetDeviceStatusRequest, initOverrides?: RequestInit) => ([
                    ...this.getDeviceStatus.mutationKey, 
                {
                deviceId: requestParameters.deviceId,
                }
            ]),
            query: (requestParameters: GetDeviceStatusRequest, initOverrides?: RequestInit): UseQueryOptions<DeviceStatusResponse> & {queryKey: QueryKey} => ({
                queryFn: () => this.getDeviceStatus(requestParameters, initOverrides),
                queryKey: this.getDeviceStatus.queryKey(requestParameters, initOverrides)
            })
        }
    )

    /**
     * Receive a device that is being transferred from another backend.
     */
    receiveTransferredDevice = Object.assign(
        async (requestParameters: ReceiveTransferredDeviceRequest, initOverrides?: RequestInit): Promise<SuccessResponse> => {
            const response = await this.receiveTransferredDevice.invokeRaw(requestParameters, initOverrides);
            return await response.value();
        },
        {
            invokeRaw: async (requestParameters: ReceiveTransferredDeviceRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SuccessResponse>> => {
                if (requestParameters.body === null || requestParameters.body === undefined) {
                    throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling receiveTransferredDevice.');
                }
            
                const queryParameters: any = {};
            
                const headerParameters: runtime.HTTPHeaders = {};
            
                headerParameters['Content-Type'] = 'application/json';
            
                if (this.configuration && this.configuration.accessToken) {
                    const token = this.configuration.accessToken;
                    const tokenString = await token("token", []);
            
                    if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                    }
                }
                const response = await this.request({
                    path: `/api/devices/receive-transfer`,
                    method: 'POST',
                    headers: headerParameters,
                    query: queryParameters,
                    body: ReceiveDeviceTransferRequestBodyToJSON(requestParameters.body),
                }, initOverrides);
            
                return new runtime.JSONApiResponse(response, (jsonValue) => SuccessResponseFromJSON(jsonValue));
            },
            mutationKey: [
                'DeviceManagementApi',
                'receiveTransferredDevice', 
            ],
            queryKey: (requestParameters: ReceiveTransferredDeviceRequest, initOverrides?: RequestInit) => ([
                    ...this.receiveTransferredDevice.mutationKey, 
                {
                body: requestParameters.body,
                }
            ]),
            query: (requestParameters: ReceiveTransferredDeviceRequest, initOverrides?: RequestInit): UseQueryOptions<SuccessResponse> & {queryKey: QueryKey} => ({
                queryFn: () => this.receiveTransferredDevice(requestParameters, initOverrides),
                queryKey: this.receiveTransferredDevice.queryKey(requestParameters, initOverrides)
            })
        }
    )

    /**
     * Trigger a reboot of a device.
     */
    requestSystemRestart = Object.assign(
        async (requestParameters: RequestSystemRestartRequest, initOverrides?: RequestInit): Promise<SuccessResponse> => {
            const response = await this.requestSystemRestart.invokeRaw(requestParameters, initOverrides);
            return await response.value();
        },
        {
            invokeRaw: async (requestParameters: RequestSystemRestartRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SuccessResponse>> => {
                if (requestParameters.deviceId === null || requestParameters.deviceId === undefined) {
                    throw new runtime.RequiredError('deviceId','Required parameter requestParameters.deviceId was null or undefined when calling requestSystemRestart.');
                }
            
                const queryParameters: any = {};
            
                const headerParameters: runtime.HTTPHeaders = {};
            
                if (this.configuration && this.configuration.accessToken) {
                    const token = this.configuration.accessToken;
                    const tokenString = await token("token", []);
            
                    if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                    }
                }
                const response = await this.request({
                    path: `/api/devices/{device_id}/restart-system`.replace(`{${"device_id"}}`, encodeURIComponent(String(requestParameters.deviceId))),
                    method: 'POST',
                    headers: headerParameters,
                    query: queryParameters,
                }, initOverrides);
            
                return new runtime.JSONApiResponse(response, (jsonValue) => SuccessResponseFromJSON(jsonValue));
            },
            mutationKey: [
                'DeviceManagementApi',
                'requestSystemRestart', 
            ],
            queryKey: (requestParameters: RequestSystemRestartRequest, initOverrides?: RequestInit) => ([
                    ...this.requestSystemRestart.mutationKey, 
                {
                deviceId: requestParameters.deviceId,
                }
            ]),
            query: (requestParameters: RequestSystemRestartRequest, initOverrides?: RequestInit): UseQueryOptions<SuccessResponse> & {queryKey: QueryKey} => ({
                queryFn: () => this.requestSystemRestart(requestParameters, initOverrides),
                queryKey: this.requestSystemRestart.queryKey(requestParameters, initOverrides)
            })
        }
    )

    /**
     * Trigger a Vividi tracker restart on a device.
     */
    requestTrackerRestart = Object.assign(
        async (requestParameters: RequestTrackerRestartRequest, initOverrides?: RequestInit): Promise<SuccessResponse> => {
            const response = await this.requestTrackerRestart.invokeRaw(requestParameters, initOverrides);
            return await response.value();
        },
        {
            invokeRaw: async (requestParameters: RequestTrackerRestartRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SuccessResponse>> => {
                if (requestParameters.deviceId === null || requestParameters.deviceId === undefined) {
                    throw new runtime.RequiredError('deviceId','Required parameter requestParameters.deviceId was null or undefined when calling requestTrackerRestart.');
                }
            
                const queryParameters: any = {};
            
                const headerParameters: runtime.HTTPHeaders = {};
            
                if (this.configuration && this.configuration.accessToken) {
                    const token = this.configuration.accessToken;
                    const tokenString = await token("token", []);
            
                    if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                    }
                }
                const response = await this.request({
                    path: `/api/devices/{device_id}/restart-tracker`.replace(`{${"device_id"}}`, encodeURIComponent(String(requestParameters.deviceId))),
                    method: 'POST',
                    headers: headerParameters,
                    query: queryParameters,
                }, initOverrides);
            
                return new runtime.JSONApiResponse(response, (jsonValue) => SuccessResponseFromJSON(jsonValue));
            },
            mutationKey: [
                'DeviceManagementApi',
                'requestTrackerRestart', 
            ],
            queryKey: (requestParameters: RequestTrackerRestartRequest, initOverrides?: RequestInit) => ([
                    ...this.requestTrackerRestart.mutationKey, 
                {
                deviceId: requestParameters.deviceId,
                }
            ]),
            query: (requestParameters: RequestTrackerRestartRequest, initOverrides?: RequestInit): UseQueryOptions<SuccessResponse> & {queryKey: QueryKey} => ({
                queryFn: () => this.requestTrackerRestart(requestParameters, initOverrides),
                queryKey: this.requestTrackerRestart.queryKey(requestParameters, initOverrides)
            })
        }
    )

    /**
     * Reset Wi-Fi configuration on a device.
     */
    requestWifiReset = Object.assign(
        async (requestParameters: RequestWifiResetRequest, initOverrides?: RequestInit): Promise<SuccessResponse> => {
            const response = await this.requestWifiReset.invokeRaw(requestParameters, initOverrides);
            return await response.value();
        },
        {
            invokeRaw: async (requestParameters: RequestWifiResetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SuccessResponse>> => {
                if (requestParameters.deviceId === null || requestParameters.deviceId === undefined) {
                    throw new runtime.RequiredError('deviceId','Required parameter requestParameters.deviceId was null or undefined when calling requestWifiReset.');
                }
            
                const queryParameters: any = {};
            
                const headerParameters: runtime.HTTPHeaders = {};
            
                if (this.configuration && this.configuration.accessToken) {
                    const token = this.configuration.accessToken;
                    const tokenString = await token("token", []);
            
                    if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                    }
                }
                const response = await this.request({
                    path: `/api/devices/{device_id}/reset-wifi`.replace(`{${"device_id"}}`, encodeURIComponent(String(requestParameters.deviceId))),
                    method: 'POST',
                    headers: headerParameters,
                    query: queryParameters,
                }, initOverrides);
            
                return new runtime.JSONApiResponse(response, (jsonValue) => SuccessResponseFromJSON(jsonValue));
            },
            mutationKey: [
                'DeviceManagementApi',
                'requestWifiReset', 
            ],
            queryKey: (requestParameters: RequestWifiResetRequest, initOverrides?: RequestInit) => ([
                    ...this.requestWifiReset.mutationKey, 
                {
                deviceId: requestParameters.deviceId,
                }
            ]),
            query: (requestParameters: RequestWifiResetRequest, initOverrides?: RequestInit): UseQueryOptions<SuccessResponse> & {queryKey: QueryKey} => ({
                queryFn: () => this.requestWifiReset(requestParameters, initOverrides),
                queryKey: this.requestWifiReset.queryKey(requestParameters, initOverrides)
            })
        }
    )

    /**
     * Send a command to a device without any processing of the command or the response.
     */
    sendCommand = Object.assign(
        async (requestParameters: SendCommandRequest, initOverrides?: RequestInit): Promise<void> => {
            await this.sendCommand.invokeRaw(requestParameters, initOverrides);
        },
        {
            invokeRaw: async (requestParameters: SendCommandRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> => {
                if (requestParameters.deviceId === null || requestParameters.deviceId === undefined) {
                    throw new runtime.RequiredError('deviceId','Required parameter requestParameters.deviceId was null or undefined when calling sendCommand.');
                }
            
                if (requestParameters.body === null || requestParameters.body === undefined) {
                    throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling sendCommand.');
                }
            
                const queryParameters: any = {};
            
                const headerParameters: runtime.HTTPHeaders = {};
            
                headerParameters['Content-Type'] = 'application/json';
            
                if (this.configuration && this.configuration.accessToken) {
                    const token = this.configuration.accessToken;
                    const tokenString = await token("token", []);
            
                    if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                    }
                }
                const response = await this.request({
                    path: `/api/devices/{device_id}/send-command`.replace(`{${"device_id"}}`, encodeURIComponent(String(requestParameters.deviceId))),
                    method: 'POST',
                    headers: headerParameters,
                    query: queryParameters,
                    body: SendCommandRequestBodyToJSON(requestParameters.body),
                }, initOverrides);
            
                return new runtime.VoidApiResponse(response);
            },
            mutationKey: [
                'DeviceManagementApi',
                'sendCommand', 
            ],
            queryKey: (requestParameters: SendCommandRequest, initOverrides?: RequestInit) => ([
                    ...this.sendCommand.mutationKey, 
                {
                deviceId: requestParameters.deviceId,
                body: requestParameters.body,
                }
            ]),
            query: (requestParameters: SendCommandRequest, initOverrides?: RequestInit): UseQueryOptions<void> & {queryKey: QueryKey} => ({
                queryFn: () => this.sendCommand(requestParameters, initOverrides),
                queryKey: this.sendCommand.queryKey(requestParameters, initOverrides)
            })
        }
    )

    /**
     * Mark a device as transferred to a different Vividi backend. The actual transfer is NOT performed by calling
     * this endpoint - the device has to be created on the new backend using a separate API call.
     */
    transferDevice = Object.assign(
        async (requestParameters: TransferDeviceRequest, initOverrides?: RequestInit): Promise<SuccessResponse> => {
            const response = await this.transferDevice.invokeRaw(requestParameters, initOverrides);
            return await response.value();
        },
        {
            invokeRaw: async (requestParameters: TransferDeviceRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SuccessResponse>> => {
                if (requestParameters.deviceId === null || requestParameters.deviceId === undefined) {
                    throw new runtime.RequiredError('deviceId','Required parameter requestParameters.deviceId was null or undefined when calling transferDevice.');
                }
            
                if (requestParameters.destination === null || requestParameters.destination === undefined) {
                    throw new runtime.RequiredError('destination','Required parameter requestParameters.destination was null or undefined when calling transferDevice.');
                }
            
                const queryParameters: any = {};
            
                const headerParameters: runtime.HTTPHeaders = {};
            
                if (this.configuration && this.configuration.accessToken) {
                    const token = this.configuration.accessToken;
                    const tokenString = await token("token", []);
            
                    if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                    }
                }
                const response = await this.request({
                    path: `/api/devices/{device_id}/transfer/{destination}`.replace(`{${"device_id"}}`, encodeURIComponent(String(requestParameters.deviceId))).replace(`{${"destination"}}`, encodeURIComponent(String(requestParameters.destination))),
                    method: 'POST',
                    headers: headerParameters,
                    query: queryParameters,
                }, initOverrides);
            
                return new runtime.JSONApiResponse(response, (jsonValue) => SuccessResponseFromJSON(jsonValue));
            },
            mutationKey: [
                'DeviceManagementApi',
                'transferDevice', 
            ],
            queryKey: (requestParameters: TransferDeviceRequest, initOverrides?: RequestInit) => ([
                    ...this.transferDevice.mutationKey, 
                {
                deviceId: requestParameters.deviceId,
                destination: requestParameters.destination,
                }
            ]),
            query: (requestParameters: TransferDeviceRequest, initOverrides?: RequestInit): UseQueryOptions<SuccessResponse> & {queryKey: QueryKey} => ({
                queryFn: () => this.transferDevice(requestParameters, initOverrides),
                queryKey: this.transferDevice.queryKey(requestParameters, initOverrides)
            })
        }
    )

    /**
     */
    updateDevice = Object.assign(
        async (requestParameters: UpdateDeviceRequest, initOverrides?: RequestInit): Promise<DeviceResponse> => {
            const response = await this.updateDevice.invokeRaw(requestParameters, initOverrides);
            return await response.value();
        },
        {
            invokeRaw: async (requestParameters: UpdateDeviceRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<DeviceResponse>> => {
                if (requestParameters.deviceId === null || requestParameters.deviceId === undefined) {
                    throw new runtime.RequiredError('deviceId','Required parameter requestParameters.deviceId was null or undefined when calling updateDevice.');
                }
            
                if (requestParameters.body === null || requestParameters.body === undefined) {
                    throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling updateDevice.');
                }
            
                const queryParameters: any = {};
            
                const headerParameters: runtime.HTTPHeaders = {};
            
                headerParameters['Content-Type'] = 'application/json';
            
                if (this.configuration && this.configuration.accessToken) {
                    const token = this.configuration.accessToken;
                    const tokenString = await token("token", []);
            
                    if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                    }
                }
                const response = await this.request({
                    path: `/api/devices/{device_id}`.replace(`{${"device_id"}}`, encodeURIComponent(String(requestParameters.deviceId))),
                    method: 'PUT',
                    headers: headerParameters,
                    query: queryParameters,
                    body: UpdateDeviceRequestBodyToJSON(requestParameters.body),
                }, initOverrides);
            
                return new runtime.JSONApiResponse(response, (jsonValue) => DeviceResponseFromJSON(jsonValue));
            },
            mutationKey: [
                'DeviceManagementApi',
                'updateDevice', 
            ],
            queryKey: (requestParameters: UpdateDeviceRequest, initOverrides?: RequestInit) => ([
                    ...this.updateDevice.mutationKey, 
                {
                deviceId: requestParameters.deviceId,
                body: requestParameters.body,
                }
            ]),
            query: (requestParameters: UpdateDeviceRequest, initOverrides?: RequestInit): UseQueryOptions<DeviceResponse> & {queryKey: QueryKey} => ({
                queryFn: () => this.updateDevice(requestParameters, initOverrides),
                queryKey: this.updateDevice.queryKey(requestParameters, initOverrides)
            })
        }
    )

}
