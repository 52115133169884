import { faGrin, faMeh, faFrown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { sum } from 'lodash'
import { DateTime, Interval } from 'luxon'
import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import { LocalityResponse } from '@api/models'

import { IntervalType } from '@helpers/types'

import StatisticBox, { StatisticBoxItem } from '@components/StatisticsSummary/StatisticBox'
import { EmotionScoreChart } from '@components/plots/EmotionScoreChart'

import styles from './EmotionsDetails.module.scss'
import { determineEmotionIcon, determineEmotionIconStyle, formatEmotionTimeKey } from './EmotionsDetailsContent'
import { DayHighlights, EmotionDetailsHighlights } from './emotionsDetailsPreprocessors'

interface EmotionsHighlightsProps {
    highlightsData: EmotionDetailsHighlights
    interval: Interval
    intervalType: IntervalType
    locality: LocalityResponse
    theMostPositiveDay: DayHighlights | undefined
    theMostNegativeDay: DayHighlights | undefined
    localize: (dateTime: DateTime) => DateTime
}

const CustomEmotionsBoxItem: React.FC<{
    value?: number
    date: string
}> = ({ value, date }) => {
    return (
        <div className={styles.customBoxItem}>
            {value && (
                <FontAwesomeIcon className={determineEmotionIconStyle(value)} icon={determineEmotionIcon(value)} />
            )}
            <span className={styles.emotionScoreTitle}>{value?.toFixed(1) ?? 'No data'}</span>
            <span className={styles.emotionMaxScore}>/ 10</span>
            <span className={styles.emotionOutlierDayTitle}>{date}</span>
        </div>
    )
}

const EmotionsHighlights: React.FC<EmotionsHighlightsProps> = ({
    highlightsData,
    interval,
    intervalType,
    locality,
    theMostPositiveDay,
    theMostNegativeDay,
    localize,
}) => {
    const { t } = useTranslation()

    const dateFormatter = formatEmotionTimeKey(localize)

    const isEmptySummary = sum(Object.values(highlightsData.overallData)) === 0

    return (
        <Row>
            {!isEmptySummary && (
                <>
                    <Col className={styles.scoreChartColumn} md={5}>
                        <EmotionScoreChart data={highlightsData.overallData} fontSize="400%" />
                        <div className={styles.maxEmotionScoreLabel}>/ 10</div>
                    </Col>

                    <Col md={7}>
                        <Row>
                            <Col md={5}>
                                <StatisticBox
                                    boxClassName={styles.emotionsStatisticsBox}
                                    interval={interval}
                                    label={t('emotions.positiveFaces', 'Positive faces')}
                                    labelIcon={<FontAwesomeIcon className={styles.positiveIcon} icon={faGrin} />}
                                    localities={[locality]}
                                    tooltip={t(
                                        'emotions.positiveFacesDescriptionInLocality',
                                        'total portion of positive faces across all zones in the locality'
                                    )}
                                >
                                    <StatisticBoxItem
                                        className={styles.emotionsStatisticsBoxItem}
                                        value={`${highlightsData.overallPercentages.positiveObservations} %`}
                                    />
                                </StatisticBox>

                                <StatisticBox
                                    boxClassName={styles.emotionsStatisticsBox}
                                    interval={interval}
                                    label={t('emotions.neutralFaces', 'Neutral faces')}
                                    labelIcon={<FontAwesomeIcon className={styles.neutralIcon} icon={faMeh} />}
                                    localities={[locality]}
                                    tooltip={t(
                                        'emotions.neutralFacesDescriptionInLocality',
                                        'total portion of neutral faces across all zones in the locality'
                                    )}
                                >
                                    <StatisticBoxItem
                                        className={styles.emotionsStatisticsBoxItem}
                                        value={`${highlightsData.overallPercentages.neutralObservations} %`}
                                    />
                                </StatisticBox>

                                <StatisticBox
                                    boxClassName={styles.emotionsStatisticsBox}
                                    interval={interval}
                                    label={t('emotions.negativeFaces', 'Negative faces')}
                                    labelIcon={<FontAwesomeIcon className={styles.negativeIcon} icon={faFrown} />}
                                    localities={[locality]}
                                    tooltip={t(
                                        'emotions.negativeFacesDescriptionInLocality',
                                        'total portion of negative faces across all zones in the locality'
                                    )}
                                >
                                    <StatisticBoxItem
                                        className={styles.emotionsStatisticsBoxItem}
                                        value={`${highlightsData.overallPercentages.negativeObservations} %`}
                                    />
                                </StatisticBox>
                            </Col>
                            <Col md={7}>
                                {theMostPositiveDay !== undefined && (
                                    <StatisticBox
                                        boxClassName={styles.emotionHighlightBox}
                                        className={styles.highlightsColumn}
                                        interval={interval}
                                        label={
                                            intervalType === IntervalType.DAY
                                                ? t('emotions.theMostPositiveHour', 'The most positive hour')
                                                : t('emotions.theMostPositiveDay', 'The most positive day')
                                        }
                                        localities={[locality]}
                                        tooltip={t(
                                            'emotions.theMostPositiveDayTooltip',
                                            'is the highest emotion score in'
                                        )}
                                    >
                                        <CustomEmotionsBoxItem
                                            date={
                                                theMostPositiveDay.timeKey
                                                    ? dateFormatter(intervalType, theMostPositiveDay.timeKey)
                                                    : 'N/A'
                                            }
                                            value={theMostPositiveDay.averageScore}
                                        />
                                    </StatisticBox>
                                )}

                                {theMostNegativeDay !== undefined && (
                                    <StatisticBox
                                        boxClassName={styles.emotionHighlightBox}
                                        className={styles.highlightsColumn}
                                        interval={interval}
                                        label={
                                            intervalType === IntervalType.DAY
                                                ? t('emotions.theMostNegativeHour', 'The most negative hour')
                                                : t('emotions.theMostNegativeDay', 'The most negative day')
                                        }
                                        localities={[locality]}
                                        tooltip={t(
                                            'emotions.theMostNegativeDayTooltip',
                                            'is the lowest emotion score in'
                                        )}
                                    >
                                        <CustomEmotionsBoxItem
                                            date={
                                                theMostNegativeDay.timeKey
                                                    ? dateFormatter(intervalType, theMostNegativeDay.timeKey)
                                                    : 'N/A'
                                            }
                                            value={theMostNegativeDay.averageScore}
                                        />
                                    </StatisticBox>
                                )}
                            </Col>
                        </Row>
                    </Col>
                </>
            )}
        </Row>
    )
}

export default EmotionsHighlights
