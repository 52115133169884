/* tslint:disable */
/* eslint-disable */
/**
 * Vividi Dashboard API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * An enumeration.
 * @export
 * @enum {string}
 */
export enum MQTTPublisherType {
    CrowdSize = 'crowd_size',
    FloorplanCrowdSize = 'floorplan_crowd_size'
}

export function MQTTPublisherTypeFromJSON(json: any): MQTTPublisherType {
    return MQTTPublisherTypeFromJSONTyped(json, false);
}

export function MQTTPublisherTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): MQTTPublisherType {
    return json as MQTTPublisherType;
}

export function MQTTPublisherTypeToJSON(value?: MQTTPublisherType | null): any {
    return value as any;
}

