/* tslint:disable */
/* eslint-disable */
/**
 * Vividi Dashboard API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface QueuesConfiguration
 */
export interface QueuesConfiguration {
    /**
     * Queue zone ids.
     * @type {Array<number>}
     * @memberof QueuesConfiguration
     */
    queueZones: Array<number>;
    /**
     * Point-of-Sale zone ids.
     * @type {Array<number>}
     * @memberof QueuesConfiguration
     */
    salesZones: Array<number>;
}

export function QueuesConfigurationFromJSON(json: any): QueuesConfiguration {
    return QueuesConfigurationFromJSONTyped(json, false);
}

export function QueuesConfigurationFromJSONTyped(json: any, ignoreDiscriminator: boolean): QueuesConfiguration {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'queueZones': json['queue_zones'],
        'salesZones': json['sales_zones'],
    };
}

export function QueuesConfigurationToJSON(value?: QueuesConfiguration | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'queue_zones': value.queueZones,
        'sales_zones': value.salesZones,
    };
}

