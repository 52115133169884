/* tslint:disable */
/* eslint-disable */
/**
 * Vividi Dashboard API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StaySafeNotificationPreferences
 */
export interface StaySafeNotificationPreferences {
    /**
     * 
     * @type {number}
     * @memberof StaySafeNotificationPreferences
     */
    localityId: number;
    /**
     * 
     * @type {boolean}
     * @memberof StaySafeNotificationPreferences
     */
    useEmail: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof StaySafeNotificationPreferences
     */
    useSms: boolean;
    /**
     * How many people need to be in the locality before a notification is sent
     * @type {number}
     * @memberof StaySafeNotificationPreferences
     */
    capacityExceededNotificationThreshold: number;
    /**
     * How long (in seconds) after detecting exceeded capacity we wait before sending the first notification
     * @type {number}
     * @memberof StaySafeNotificationPreferences
     */
    capacityExceededNotificationGracePeriod: number;
    /**
     * How long (in seconds) we wait before sending another 'capacity exceeded' notification
     * @type {number}
     * @memberof StaySafeNotificationPreferences
     */
    capacityExceededNotificationCooldown: number;
}

export function StaySafeNotificationPreferencesFromJSON(json: any): StaySafeNotificationPreferences {
    return StaySafeNotificationPreferencesFromJSONTyped(json, false);
}

export function StaySafeNotificationPreferencesFromJSONTyped(json: any, ignoreDiscriminator: boolean): StaySafeNotificationPreferences {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'localityId': json['locality_id'],
        'useEmail': json['use_email'],
        'useSms': json['use_sms'],
        'capacityExceededNotificationThreshold': json['capacity_exceeded_notification_threshold'],
        'capacityExceededNotificationGracePeriod': json['capacity_exceeded_notification_grace_period'],
        'capacityExceededNotificationCooldown': json['capacity_exceeded_notification_cooldown'],
    };
}

export function StaySafeNotificationPreferencesToJSON(value?: StaySafeNotificationPreferences | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'locality_id': value.localityId,
        'use_email': value.useEmail,
        'use_sms': value.useSms,
        'capacity_exceeded_notification_threshold': value.capacityExceededNotificationThreshold,
        'capacity_exceeded_notification_grace_period': value.capacityExceededNotificationGracePeriod,
        'capacity_exceeded_notification_cooldown': value.capacityExceededNotificationCooldown,
    };
}

