/* tslint:disable */
/* eslint-disable */
/**
 * Vividi Dashboard API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface VisibleEntitiesByDeviceItem
 */
export interface VisibleEntitiesByDeviceItem {
    /**
     * Identifier of the scene in which the entities were observed
     * @type {number}
     * @memberof VisibleEntitiesByDeviceItem
     */
    sceneId: number;
    /**
     * Number of entities currently visible in the scene, regardless of zone configuration
     * @type {number}
     * @memberof VisibleEntitiesByDeviceItem
     */
    visibleEntities: number;
}

export function VisibleEntitiesByDeviceItemFromJSON(json: any): VisibleEntitiesByDeviceItem {
    return VisibleEntitiesByDeviceItemFromJSONTyped(json, false);
}

export function VisibleEntitiesByDeviceItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): VisibleEntitiesByDeviceItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sceneId': json['scene_id'],
        'visibleEntities': json['visible_entities'],
    };
}

export function VisibleEntitiesByDeviceItemToJSON(value?: VisibleEntitiesByDeviceItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'scene_id': value.sceneId,
        'visible_entities': value.visibleEntities,
    };
}

