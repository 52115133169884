import { uniqueId } from 'lodash'
import React, { useMemo } from 'react'
import { Modal, Image } from 'react-bootstrap'

import { Possibly } from '@helpers/types'

import styles from './ImageWithModal.module.scss'

type Props = {
    src: string
    isOpen: boolean
    onClose: () => void
} & Possibly<{ onClick: () => void; className?: string }>

const ImageWithModalControlled: React.FC<Props> = ({ src, isOpen, onClose, children, onClick, className }) => {
    const id = useMemo(uniqueId, [])

    return (
        <>
            <Modal dialogClassName={styles.imageModal} id={id} show={isOpen} onHide={() => onClose()}>
                <Modal.Body>
                    <Image key={id} alt="" fluid={true} src={src} />
                </Modal.Body>
            </Modal>
            {React.cloneElement(children as React.ReactElement, {
                src,
                onClick,
                className,
            })}
        </>
    )
}

export default ImageWithModalControlled
