import { flatMap, noop } from 'lodash'
import { DateTime, Interval } from 'luxon'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { useParams } from 'react-router'
import { useHistory } from 'react-router-dom'

import { DetectionZoneWithId, LocalityNameModel } from '@api'

import { localityApi, statisticsApi } from '@services'

import { generateEmotionsDetailsCustomIntervalPath, generateEmotionsDetailsPath } from '@helpers/VividiURLs'
import { emotionsSummaryByLocalityQuery, mergeQueryResults, visibleSceneDescriptionsQuery } from '@helpers/api'
import { useTimezoneConfig } from '@helpers/timezoneConfig'
import { IntervalType, ZoneOccupancyGranularity } from '@helpers/types'
import { pickColumn } from '@helpers/utils'

import DashboardParamsNormalizer, { NormalizedDashboardParams } from '@components/DashboardParamsNormalizer'
import DetailsLayout from '@components/DetailsLayout'
import EmotionsDetailsContent from '@components/EmotionsDetails/EmotionsDetailsContent'
import { EmotionsDetailsExportButton } from '@components/EmotionsDetails/EmotionsDetailsExportButton'
import ErrorView from '@components/ErrorView'
import Helmet from '@components/Helmet'
import LegacyLoadingWrapper from '@components/LegacyLoadingWrapper'
import LoadingSpinner from '@components/LoadingSpinner'
import { granularityToDuration } from '@components/ZoneOccupancyStatistics/zoneOccupancyData'
import { partitionInterval } from '@components/plots/common'

interface Params {
    zoneIds?: string
}

const EmotionsDetailsPage: React.FC<NormalizedDashboardParams> = ({ locality, interval, intervalType }) => {
    const { t } = useTranslation()
    const intervalLength = interval.length('days')
    const params = useParams<Params>()
    const history = useHistory()
    const [now] = useState(DateTime.utc())

    const { intervals: partitionedInterval } = partitionInterval(
        interval,
        useTimezoneConfig(),
        granularityToDuration(intervalLength <= 14 ? ZoneOccupancyGranularity.HOUR : ZoneOccupancyGranularity.DAY)
    )

    const selectedZones = params.zoneIds === undefined ? [] : params.zoneIds.split('-').map(Number)

    const scenesCall = useQuery(
        localityApi.getLocalityScenes.query({
            localityId: locality.id,
        })
    )

    const sceneConfigurationCall = useQuery({
        ...visibleSceneDescriptionsQuery(scenesCall.data?.scenes.map(({ id }) => id) ?? []),
        enabled: scenesCall.isSuccess,
    })

    const outagesCall = useQuery({
        ...statisticsApi.getMultiDeviceOutages.query({
            body: {
                sceneIds: pickColumn(scenesCall.data?.scenes ?? [], 'id'),
                startingFrom: interval.start.toISO(),
                endingAt: interval.end.toISO(),
            },
        }),
        enabled: scenesCall.isSuccess,
    })

    const emotionsSummaryCall = useQuery({
        ...emotionsSummaryByLocalityQuery({
            intervals: [
                {
                    startingFrom: interval.start.toISO(),
                    endingAt: interval.end.toISO(),
                },
            ],
            localityIds: [locality.id],
        }),
    })

    // TODO - aggregate data eventually
    const granularEmotionsSummaryCall = useQuery({
        ...emotionsSummaryByLocalityQuery({
            intervals: partitionedInterval,
            localityIds: [locality.id],
        }),
    })

    const handleIntervalStartChange = (intervalStart: DateTime) => {
        history.push(generateEmotionsDetailsPath(locality.id, intervalStart, intervalType, selectedZones))
    }

    const handleIntervalTypeChange = (newIntervalType: IntervalType) => {
        history.push(generateEmotionsDetailsPath(locality.id, interval.start, newIntervalType, selectedZones))
    }

    const handleLocalityChange = (locality: LocalityNameModel) => {
        const isCustomIntervalSelected = intervalType === IntervalType.CUSTOM && interval.start && interval.end

        if (isCustomIntervalSelected) {
            return history.push(generateEmotionsDetailsCustomIntervalPath(locality.id, interval.start, interval.end))
        }

        return history.push(generateEmotionsDetailsPath(locality.id, interval.start, intervalType))
    }

    const onZonesChange = (zones: DetectionZoneWithId[]) => {
        return history.push(
            generateEmotionsDetailsCustomIntervalPath(
                locality.id,
                interval.start,
                interval.end,
                pickColumn(zones, 'id')
            )
        )
    }

    const handleCustomIntervalChange = (interval: Interval) => {
        history.push(
            generateEmotionsDetailsCustomIntervalPath(locality.id, interval.start, interval.end, selectedZones)
        )
    }

    const documentTitle = t('title.emotionStatistics', 'Emotion Statistics')

    return (
        <>
            <Helmet>
                <title>{documentTitle}</title>
            </Helmet>
            <LegacyLoadingWrapper
                errorComponent={
                    <DetailsLayout
                        containerWidth="1400px"
                        intervalType={intervalType}
                        organizationFilter={(org) => org.features.emotions}
                        pickedInterval={interval}
                        selectedLocality={locality}
                        onCustomIntervalChange={handleCustomIntervalChange}
                        onIntervalStartChange={handleIntervalStartChange}
                        onIntervalTypeChange={handleIntervalTypeChange}
                        onLocalityChange={handleLocalityChange}
                    >
                        <ErrorView message={t('others.problemFetchingData', 'There was a problem fetching data.')} />
                    </DetailsLayout>
                }
                placeholder={
                    <DetailsLayout
                        containerWidth="1400px"
                        intervalType={intervalType}
                        organizationFilter={(org) => org.features.emotions}
                        pickedInterval={interval}
                        selectedLocality={locality}
                        onCustomIntervalChange={handleCustomIntervalChange}
                        onIntervalStartChange={handleIntervalStartChange}
                        onIntervalTypeChange={handleIntervalTypeChange}
                        onLocalityChange={handleLocalityChange}
                    >
                        <LoadingSpinner />
                    </DetailsLayout>
                }
                request={mergeQueryResults(
                    emotionsSummaryCall,
                    granularEmotionsSummaryCall,
                    scenesCall,
                    sceneConfigurationCall,
                    outagesCall
                )}
            >
                {([emotionsSummary, granularEmotionsSummary, scenes, sceneConfiguration, outages]) => {
                    const deviceZones = flatMap(flatMap(sceneConfiguration).map((device) => device.detectionZones))

                    const selectedLocalityZones = deviceZones.filter((zone) => selectedZones.includes(zone.id))

                    const detectionZonesBySceneName = Object.entries(sceneConfiguration).map(
                        ([sceneId, configuration]) => ({
                            detectionZones: configuration.detectionZones,
                            sceneName: scenes.scenes.find((it) => it.id === Number(sceneId))!.label,
                            sceneId,
                        })
                    )

                    return (
                        <>
                            <Helmet>
                                <title>{`${locality.name} ${documentTitle}`}</title>
                            </Helmet>
                            <DetailsLayout
                                key={locality.id}
                                containerWidth="1400px"
                                intervalType={intervalType}
                                layoutActionButton={
                                    <EmotionsDetailsExportButton
                                        detectionZonesByDeviceName={detectionZonesBySceneName}
                                        granularEmotionsSummary={granularEmotionsSummary}
                                        intervalType={intervalType}
                                        pickedInterval={interval}
                                        selectedLocality={locality}
                                        selectedZones={selectedLocalityZones}
                                    />
                                }
                                organizationFilter={(org) => org.features.emotions}
                                pickedInterval={interval}
                                sceneConfiguration={sceneConfiguration}
                                selectedLocality={locality}
                                selectedZones={selectedLocalityZones}
                                onCustomIntervalChange={handleCustomIntervalChange}
                                onIntervalStartChange={handleIntervalStartChange}
                                onIntervalTypeChange={handleIntervalTypeChange}
                                onLocalityChange={handleLocalityChange}
                                onZonesChange={onZonesChange}
                            >
                                <EmotionsDetailsContent
                                    detectionZonesBySceneName={detectionZonesBySceneName}
                                    emotionsSummary={emotionsSummary}
                                    granularEmotionsSummary={granularEmotionsSummary}
                                    interval={interval}
                                    intervalType={intervalType}
                                    locality={locality}
                                    localityDetectionZones={deviceZones}
                                    now={now}
                                    outages={outages.outages}
                                    sceneConfiguration={sceneConfiguration}
                                    scenes={scenes}
                                    selectedZones={selectedLocalityZones}
                                />
                            </DetailsLayout>
                        </>
                    )
                }}
            </LegacyLoadingWrapper>
        </>
    )
}

const EmotionsDetailsParamsNormalizer: React.FC = () => {
    const { t } = useTranslation()

    return (
        <DashboardParamsNormalizer
            customIntervalPathGenerator={generateEmotionsDetailsCustomIntervalPath}
            defaultLocalityPathGenerator={generateEmotionsDetailsPath}
            errorComponent={({ intervalType, interval }) => (
                <DetailsLayout
                    containerWidth="1400px"
                    intervalType={intervalType}
                    organizationFilter={(org) => org.features.emotions}
                    pickedInterval={interval}
                    onCustomIntervalChange={noop}
                    onIntervalStartChange={noop}
                    onIntervalTypeChange={noop}
                    onLocalityChange={noop}
                >
                    <ErrorView
                        message={t('others.fetchingListFailed', 'Fetching the list of localities failed')}
                        title={t('others.errorWhileFetchingData', 'Error while fetching data')}
                    />
                </DetailsLayout>
            )}
            intervalPathGenerator={generateEmotionsDetailsPath}
            loadingComponent={({ intervalType, interval }) => (
                <DetailsLayout
                    containerWidth="1400px"
                    intervalType={intervalType}
                    organizationFilter={(org) => org.features.emotions}
                    pickedInterval={interval}
                    onCustomIntervalChange={noop}
                    onIntervalStartChange={noop}
                    onIntervalTypeChange={noop}
                    onLocalityChange={noop}
                >
                    <LoadingSpinner />
                </DetailsLayout>
            )}
            successComponent={(params) => <EmotionsDetailsPage {...params} />}
        />
    )
}

export default EmotionsDetailsParamsNormalizer
