import React, { useContext } from 'react'
import { Alert, Toast } from 'react-bootstrap'

import styles from './NotificationArea.module.scss'
import { notificationsContext } from './NotificationsContext'

const NotificationArea = () => {
    const context = useContext(notificationsContext)

    return (
        <div aria-atomic="true" aria-live="polite" className={styles.toastWrapper}>
            {context.notifications.map((notification) => (
                <Toast key={notification.id} className={styles.toast}>
                    <Alert
                        className={styles.alert}
                        dismissible={true}
                        variant={notification.variant}
                        onClose={() => context.removeNotification(notification)}
                    >
                        <Alert.Heading>{notification.title}</Alert.Heading>
                        <p>{notification.content}</p>
                    </Alert>
                </Toast>
            ))}
        </div>
    )
}

export default NotificationArea
