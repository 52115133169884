import { noop } from 'lodash'
import { DateTime, Duration, Interval } from 'luxon'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { useHistory } from 'react-router-dom'

import { LocalityNameModel } from '@api'

import { localityApi, statisticsApi } from '@services'

import { generateStaySafeDetailsPath } from '@helpers/VividiURLs'
import { mergeQueryResults, visibleOrganizationsQuery, visibleSceneDescriptionsQuery } from '@helpers/api'
import { maskQueryFailure } from '@helpers/apiCallManipulation'
import { isMaleCategory } from '@helpers/footfallData'
import useProfile from '@helpers/profile'
import { useLocalizeDateTime, useTimezoneConfig } from '@helpers/timezoneConfig'
import { IntervalType, ZoneOccupancyGranularity } from '@helpers/types'
import { assertIsIntervalType, pickColumn } from '@helpers/utils'

import DashboardParamsNormalizer, { NormalizedDashboardParams } from '@components/DashboardParamsNormalizer'
import DetailsLayout, { LayoutModules } from '@components/DetailsLayout'
import ErrorView from '@components/ErrorView'
import Helmet from '@components/Helmet'
import LegacyLoadingWrapper from '@components/LegacyLoadingWrapper'
import LoadingSpinner from '@components/LoadingSpinner'
import StaySafeDetailsContent from '@components/StaySafeDetails/StaySafeDetailsContent'
import { StaySafeDetailsExportButton } from '@components/StaySafeDetails/StaySafeDetailsExportButton'
import { granularityToDuration } from '@components/ZoneOccupancyStatistics/zoneOccupancyData'
import { partitionInterval } from '@components/plots/common'

const StaySafeDetailsPage: React.FC<NormalizedDashboardParams> = ({ locality, interval, intervalType }) => {
    const { t } = useTranslation()

    const history = useHistory()

    const zone = useTimezoneConfig()
    const localize = useLocalizeDateTime()
    const nowLocalized = useMemo(() => localize(DateTime.utc()), [interval])
    const footfallInterval = useMemo(
        () => Interval.fromDateTimes(DateTime.utc().minus(Duration.fromObject({ hours: 24 })), DateTime.utc()),
        []
    )

    const localityConfigurationsCall = useQuery(localityApi.getLocalityConfigurationStates.query())

    const crowdSizeHistoryCall = useQuery(
        statisticsApi.getCrowdSizeHistory.query({
            localityId: locality.id,
            body: {
                intervals: partitionInterval(
                    interval,
                    zone,
                    granularityToDuration(ZoneOccupancyGranularity.FIVE_MINUTES)
                ).intervals,
            },
        })
    )

    const floorplanCrowdSizeHistoryCall = useQuery(
        statisticsApi.getFloorplanCrowdSizeHistory.query({
            localityId: locality.id,
            body: {
                intervals: partitionInterval(
                    interval,
                    zone,
                    granularityToDuration(ZoneOccupancyGranularity.FIVE_MINUTES)
                ).intervals,
            },
        })
    )

    const propertySummaryCall = useQuery(
        statisticsApi.getPropertySummary.query({
            body: {
                ...partitionInterval(footfallInterval, useTimezoneConfig(), Duration.fromObject({ minutes: 15 })),
                locality: locality.id,
                propertyCategories: [isMaleCategory],
            },
        })
    )

    const scenesCall = useQuery(localityApi.getLocalityScenes.query({ localityId: locality.id }))

    const sceneConfigurationCall = useQuery({
        ...visibleSceneDescriptionsQuery(pickColumn(scenesCall.data?.scenes ?? [], 'id')),
        enabled: scenesCall.isSuccess,
    })

    const profileCall = useProfile()
    const visibleOrganizationsCall = useQuery(visibleOrganizationsQuery(profileCall.data))

    const request = mergeQueryResults(
        visibleOrganizationsCall,
        scenesCall,
        sceneConfigurationCall,
        localityConfigurationsCall,
        maskQueryFailure(crowdSizeHistoryCall),
        maskQueryFailure(floorplanCrowdSizeHistoryCall),
        maskQueryFailure(propertySummaryCall)
    )

    const handleIntervalStartChange = (intervalStart: DateTime) => {
        history.push(generateStaySafeDetailsPath(locality.id, intervalStart, IntervalType.DAY))
    }

    const handleIntervalTypeChange = (newIntervalType: IntervalType) => {
        history.push(generateStaySafeDetailsPath(locality.id, interval.start, newIntervalType))
    }

    const handleLocalityChange = (locality: LocalityNameModel) => {
        if (interval === undefined) {
            return history.push(generateStaySafeDetailsPath(locality.id, nowLocalized, IntervalType.DAY))
        }

        assertIsIntervalType(intervalType)

        return history.push(generateStaySafeDetailsPath(locality.id, interval.start, intervalType))
    }

    const documentTitle = t('title.realtimeStatistics', 'Real-time Statistics')

    return (
        <>
            <Helmet>
                <title>{documentTitle}</title>
            </Helmet>
            <LegacyLoadingWrapper
                errorComponent={
                    <DetailsLayout
                        containerWidth="1600px"
                        intervalType={intervalType}
                        layoutModules={[LayoutModules.LOCALITYPICKER]}
                        organizationFilter={() => false}
                        pickedInterval={interval}
                        selectedLocality={locality}
                        showLiveIndicator={true}
                        onCustomIntervalChange={noop}
                        onIntervalStartChange={handleIntervalStartChange}
                        onIntervalTypeChange={handleIntervalTypeChange}
                        onLocalityChange={handleLocalityChange}
                    >
                        <ErrorView message={t('others.problemFetchingData', 'There was a problem fetching data.')} />
                    </DetailsLayout>
                }
                placeholder={
                    <DetailsLayout
                        containerWidth="1600px"
                        intervalType={intervalType}
                        layoutModules={[LayoutModules.LOCALITYPICKER]}
                        organizationFilter={() => false}
                        pickedInterval={interval}
                        selectedLocality={locality}
                        showLiveIndicator={true}
                        onCustomIntervalChange={noop}
                        onIntervalStartChange={handleIntervalStartChange}
                        onIntervalTypeChange={handleIntervalTypeChange}
                        onLocalityChange={handleLocalityChange}
                    >
                        <LoadingSpinner />
                    </DetailsLayout>
                }
                request={request}
            >
                {([organizations, scenes, sceneConfiguration, { localities }]) => {
                    return (
                        <>
                            <Helmet>
                                <title>{`${locality.name} ${documentTitle}`}</title>
                            </Helmet>
                            <DetailsLayout
                                key={locality.id}
                                containerWidth="1600px"
                                intervalType={intervalType}
                                layoutActionButton={
                                    <StaySafeDetailsExportButton
                                        crowdSizeHistoryCall={crowdSizeHistoryCall}
                                        floorplanCrowdSizeHistoryCall={floorplanCrowdSizeHistoryCall}
                                        now={nowLocalized}
                                        pickedInterval={interval}
                                        sceneCount={scenes.scenes.length ?? 0}
                                        selectedLocality={locality}
                                    />
                                }
                                layoutModules={[LayoutModules.LOCALITYPICKER]}
                                localityFilter={({ id }) => {
                                    const locality = localities.find((it) => it.id === id)

                                    return Boolean(
                                        locality?.isStaySafeConfigured || locality?.isStaySafeFloorplanConfigured
                                    )
                                }}
                                organizationFilter={(org) =>
                                    org.features.realtimeOccupancy || org.features.staySafeFloorplan
                                }
                                pickedInterval={interval}
                                selectedLocality={locality}
                                showLiveIndicator={true}
                                onCustomIntervalChange={() => ''}
                                onIntervalStartChange={handleIntervalStartChange}
                                onIntervalTypeChange={handleIntervalTypeChange}
                                onLocalityChange={handleLocalityChange}
                            >
                                <StaySafeDetailsContent
                                    crowdSizeHistory={crowdSizeHistoryCall}
                                    floorplanCrowdSizeHistory={floorplanCrowdSizeHistoryCall}
                                    interval={interval}
                                    locality={locality}
                                    localityConfiguration={localities.find((l) => l.id === locality.id)!}
                                    now={nowLocalized}
                                    organization={
                                        organizations.organizations.find((org) => org.id === locality.organizationId)!
                                    }
                                    propertySummary={propertySummaryCall}
                                    sceneConfiguration={sceneConfiguration}
                                    scenes={scenes.scenes}
                                />
                            </DetailsLayout>
                        </>
                    )
                }}
            </LegacyLoadingWrapper>
        </>
    )
}

const StaySafeDetailsParamsNormalizer: React.FC = () => (
    <DashboardParamsNormalizer
        customIntervalPathGenerator={() => ''}
        defaultLocalityPathGenerator={generateStaySafeDetailsPath}
        errorComponent={({ intervalType, interval }) => (
            <DetailsLayout
                containerWidth="1600px"
                intervalType={intervalType}
                layoutModules={[LayoutModules.LOCALITYPICKER]}
                organizationFilter={() => false}
                pickedInterval={interval}
                showLiveIndicator={true}
                onCustomIntervalChange={noop}
                onIntervalStartChange={noop}
                onIntervalTypeChange={noop}
                onLocalityChange={noop}
            >
                <ErrorView message="Fetching the list of localities failed" title="Error while fetching data" />
            </DetailsLayout>
        )}
        intervalPathGenerator={generateStaySafeDetailsPath}
        loadingComponent={({ intervalType, interval }) => (
            <DetailsLayout
                containerWidth="1600px"
                intervalType={intervalType}
                layoutModules={[LayoutModules.LOCALITYPICKER]}
                organizationFilter={() => false}
                pickedInterval={interval}
                showLiveIndicator={true}
                onCustomIntervalChange={noop}
                onIntervalStartChange={noop}
                onIntervalTypeChange={noop}
                onLocalityChange={noop}
            >
                <LoadingSpinner />
            </DetailsLayout>
        )}
        successComponent={(params) => <StaySafeDetailsPage {...params} />}
    />
)

export default StaySafeDetailsParamsNormalizer
