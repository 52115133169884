/* tslint:disable */
/* eslint-disable */
/**
 * Vividi Dashboard API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    LocalityFloorplanResponse,
    LocalityFloorplanResponseFromJSON,
    LocalityFloorplanResponseFromJSONTyped,
    LocalityFloorplanResponseToJSON,
} from './LocalityFloorplanResponse';

/**
 * 
 * @export
 * @interface FloorplanListResponse
 */
export interface FloorplanListResponse {
    /**
     * 
     * @type {Array<LocalityFloorplanResponse>}
     * @memberof FloorplanListResponse
     */
    floorplans: Array<LocalityFloorplanResponse>;
}

export function FloorplanListResponseFromJSON(json: any): FloorplanListResponse {
    return FloorplanListResponseFromJSONTyped(json, false);
}

export function FloorplanListResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): FloorplanListResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'floorplans': ((json['floorplans'] as Array<any>).map(LocalityFloorplanResponseFromJSON)),
    };
}

export function FloorplanListResponseToJSON(value?: FloorplanListResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'floorplans': ((value.floorplans as Array<any>).map(LocalityFloorplanResponseToJSON)),
    };
}

