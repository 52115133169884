import classNames from 'classnames'
import { keyBy } from 'lodash'
import { DateTime, Interval } from 'luxon'
import { useEffect, useMemo, useState } from 'react'
import { Button, ButtonGroup } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { QueryStatus, useMutation, useQuery, UseQueryResult } from 'react-query'
import { useHistory, useParams } from 'react-router'

import { EntityListResponse, SceneResponse } from '@api'

import { sceneApi, statisticsApi } from '@services'

import { generateSceneDiagnosticsPath } from '@helpers/VividiURLs'
import { useApiCallCleaner } from '@helpers/api'
import { mergeQueryResults, visibleLocalitiesQuery, visibleOrganizationsQuery } from '@helpers/api'
import { getImageSize } from '@helpers/images'
import useProfile from '@helpers/profile'
import { Size } from '@helpers/types'

import DateTimeIntervalPicker from '@components/DateTimeIntervalPicker/DateTimeIntervalPicker'
import EntityTrajectoryVisualizer from '@components/EntityTrajectoryVisualizer/EntityTrajectoryVisualizer'
import NumberInput from '@components/GenericInputs/NumberInput'
import PageContentLayout from '@components/Layouts/PageContentLayout'
import LegacyLoadingWrapper from '@components/LegacyLoadingWrapper'
import PopoverButton from '@components/PopoverButton/PopoverButton'
import ScenesPicker from '@components/ScenesPicker'
import { useNotify } from '@components/notifications/NotificationsContext'
import { parseDate } from '@components/plots/common'

const SceneDiagnosticsPage = () => {
    const { t } = useTranslation()
    const params = useParams<{ id: string }>()
    const sceneId = parseInt(params.id, 10)

    const [pickedInterval, setPickedInterval] = useState<Interval>(
        Interval.fromDateTimes(DateTime.utc().minus({ hour: 1 }), DateTime.utc())
    )

    const [minVisitLength, setMinVisitLength] = useState<number>()

    const scenesCall = useQuery(sceneApi.getAllScenes.query())
    const snapshotCall = useQuery(sceneApi.getSceneSnapshot.query({ sceneId }))
    const trajectoriesCall = useQuery(
        statisticsApi.getEntities.query({
            sceneId,
            body: {
                startingFrom: pickedInterval.start.toISO(),
                endingAt: pickedInterval.end.toISO(),
            },
        })
    )

    const sceneConfigurationCall = useQuery(sceneApi.getSceneDescription.query({ sceneId }))

    const snapshotUrl = useMemo(() => {
        if (snapshotCall.data === undefined) {
            return undefined
        }

        return URL.createObjectURL(snapshotCall.data)
    }, [snapshotCall.data])

    const [snapshotSize, setSnapshotSize] = useState<Size>()

    useEffect(() => {
        if (snapshotUrl) {
            getImageSize(snapshotUrl).then(setSnapshotSize)
        }
    }, [snapshotUrl])

    const selectedDevice = scenesCall?.data?.scenes.find((s) => s.id === sceneId)
    const request = mergeQueryResults(trajectoriesCall, sceneConfigurationCall, snapshotCall)
    const heading = t('heading.deviceDiagnostics', 'Device diagnostics')
    const [selectedEntities, setSelectedEntities] = useState<number[]>([])
    const documentTitle = t('title.deviceDiagnostics', 'diagnostics')

    return (
        <>
            <LegacyLoadingWrapper
                placeholder={
                    <PageContentLayout
                        documentTitle={`${t('others.loading', 'Loading')} ${documentTitle}`}
                        fluid={true}
                        heading={heading}
                        headingButtons={
                            <Toolbar
                                loadingState={request.status}
                                minVisitLength={minVisitLength}
                                pickedInterval={pickedInterval}
                                selectedEntityIds={selectedEntities}
                                selectedScene={selectedDevice}
                                trajectoriesCall={trajectoriesCall}
                                onIntervalPicked={setPickedInterval}
                                onMinVisitLengthChange={setMinVisitLength}
                            />
                        }
                        loadingState={request.status}
                    />
                }
                request={request}
            >
                {([trajectories, sceneConfiguration]) => {
                    const entities = trajectories.entities.filter(
                        (e) =>
                            minVisitLength === undefined ||
                            parseDate(e.endedAt).diff(parseDate(e.startedAt)).as('seconds') >= minVisitLength
                    )

                    return (
                        <>
                            <PageContentLayout
                                documentTitle={`#${selectedDevice?.id} ${documentTitle}`}
                                fluid={true}
                                heading={heading}
                                headingButtons={
                                    <Toolbar
                                        loadingState={request.status}
                                        minVisitLength={minVisitLength}
                                        pickedInterval={pickedInterval}
                                        selectedEntityIds={entities.map(({ globalEntityId }) => globalEntityId)}
                                        selectedScene={selectedDevice}
                                        trajectoriesCall={trajectoriesCall}
                                        onIntervalPicked={setPickedInterval}
                                        onMinVisitLengthChange={setMinVisitLength}
                                    />
                                }
                            >
                                <EntityTrajectoryVisualizer
                                    entities={entities}
                                    sceneDescription={sceneConfiguration}
                                    snapshotSize={snapshotSize}
                                    snapshotUrl={snapshotUrl}
                                    onSelectionUpdate={(entityIds) => {
                                        setSelectedEntities(entityIds)
                                    }}
                                />
                            </PageContentLayout>
                        </>
                    )
                }}
            </LegacyLoadingWrapper>
        </>
    )
}

type ToolbarProps = {
    selectedScene?: SceneResponse
    loadingState: QueryStatus
    pickedInterval: Interval
    onIntervalPicked: (interval: Interval) => void
    trajectoriesCall: UseQueryResult<EntityListResponse>
    selectedEntityIds: number[]
    minVisitLength?: number
    onMinVisitLengthChange: (minVisitLength?: number) => void
}

const Toolbar = ({
    selectedScene,
    pickedInterval,
    trajectoriesCall,
    onIntervalPicked,
    minVisitLength,
    onMinVisitLengthChange,
    selectedEntityIds,
}: ToolbarProps) => {
    const { t } = useTranslation()
    const [now] = useState(DateTime.utc())
    const clean = useApiCallCleaner()

    const [minLengthPickerOpen, setMinLengthPickerOpen] = useState(false)
    const history = useHistory()
    const { mutateAsync: deleteTrigger } = useMutation(statisticsApi.deleteEntity)
    const notify = useNotify()
    const profileCall = useProfile()
    const scenesCall = useQuery(sceneApi.getAllScenes.query())

    const localitiesCall = useQuery({
        ...visibleLocalitiesQuery(profileCall.data),
    })

    const organizationsCall = useQuery({
        ...visibleOrganizationsQuery(profileCall.data),
    })

    return (
        <>
            <div className="d-flex">
                <ButtonGroup>
                    <ScenesPicker
                        localities={keyBy(localitiesCall.data?.localities, (l) => l.id)}
                        organizations={keyBy(organizationsCall.data?.organizations, (o) => o.id)}
                        scenes={scenesCall.data?.scenes.filter((s) => s.deviceId !== undefined) || []}
                        selectedScene={selectedScene}
                        onSelect={(devices) => history.push(generateSceneDiagnosticsPath(devices[0].id))}
                    />
                    <PopoverButton
                        content={<NumberInput value={minVisitLength} onChange={onMinVisitLengthChange} />}
                        isOpen={minLengthPickerOpen}
                        placement="bottom"
                        trigger="click"
                        onToggle={setMinLengthPickerOpen}
                    >
                        <Button>
                            Min. length (s)
                            {minVisitLength !== undefined && `: ${minVisitLength}s`}
                        </Button>
                    </PopoverButton>
                    <DateTimeIntervalPicker
                        interval={pickedInterval}
                        isTimeDisabled={false}
                        now={now}
                        timePickerIncrementInSeconds={1}
                        onSubmit={onIntervalPicked}
                    >
                        <input
                            className={classNames('btn', 'btn-secondary')}
                            type="button"
                            value={t('button.setInterval', 'Set interval') as string}
                        />
                    </DateTimeIntervalPicker>
                    <Button
                        variant="danger"
                        onClick={async () => {
                            try {
                                await Promise.all(selectedEntityIds.map((id) => deleteTrigger({ globalEntityId: id })))

                                notify({
                                    variant: 'success',
                                    title: t('notification.success', 'Success'),
                                    content: t('notification.entityDeletionContent', `Entities deleted successfully.`),
                                    timeoutSeconds: 5,
                                })

                                clean(sceneApi)
                                clean(statisticsApi)
                                trajectoriesCall.refetch()
                            } catch (error) {
                                notify({
                                    title: t('notification.error', 'Error'),
                                    content: t('notification.entityDeletionFailedContent', `Entity deletion failed.`),
                                    variant: 'danger',
                                })
                            }
                        }}
                    >
                        {t('button.deleteEntities', `Delete (${selectedEntityIds.length})`, {
                            value: selectedEntityIds.length,
                        })}
                    </Button>
                </ButtonGroup>
            </div>
        </>
    )
}

export default SceneDiagnosticsPage
