import { DateTime } from 'luxon'
import React from 'react'

import CookiePolicyV1 from './v1/CookiePolicy'
import PrivacyPolicyV1 from './v1/PrivacyPolicy'
import TermsOfSaleV1 from './v1/TermsOfSale'
import TermsOfServiceV1 from './v1/TermsOfService'
import VividiBoxEULAV1 from './v1/VividiBoxEULA'

export interface TermsAndConditions {
    cookiePolicy: JSX.Element
    eula: JSX.Element
    privacyPolicy: JSX.Element
    termsOfService: JSX.Element
    termsOfSale: JSX.Element
    revisedBy?: TermsAndConditions
    effectiveSince: DateTime
}

const v1: TermsAndConditions = {
    cookiePolicy: <CookiePolicyV1 />,
    eula: <VividiBoxEULAV1 />,
    privacyPolicy: <PrivacyPolicyV1 />,
    termsOfService: <TermsOfServiceV1 />,
    termsOfSale: <TermsOfSaleV1 />,
    effectiveSince: DateTime.utc(2019, 11, 16),
}

type Content = { [versionId: string]: TermsAndConditions }

const index: Content = { v1 }

export const getCurrentVersion = () => Object.entries(index).find(([_version, terms]) => terms.revisedBy === undefined)!

export default index
