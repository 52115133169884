import classNames from 'classnames'
import React from 'react'

import { Possibly } from '@helpers/types'

import { LayoutHeading, LayoutHeadingProps } from './LayoutHeading'
import styles from './SideNavigationLayout.module.scss'

type Gap = 'md' | 'lg' | 'sm'

type SideNavigationListLayoutProps = {
    navigation: React.ReactNode
    content: React.ReactNode
    columnGap?: Gap
    rowGap?: Gap
} & Possibly<HeaderRowProps>

type HeaderRowProps = {
    listHeading?: LayoutHeadingProps
    contentHeading?: LayoutHeadingProps
}

const determineGapStyles = (columnGap: Gap, rowGap: Gap): string =>
    classNames({
        [styles.mediumColumnGap]: columnGap === 'md',
        [styles.largeColumnGap]: columnGap === 'lg',
        [styles.smallColumnGap]: columnGap === 'sm',
        [styles.mediumRowGap]: rowGap === 'md',
        [styles.largeRowGap]: rowGap === 'lg',
        [styles.smallRowGap]: rowGap === 'sm',
    })

const HeadingRow: React.FC<HeaderRowProps> = ({ listHeading, contentHeading }) => {
    return (
        <>
            {listHeading ? (
                <LayoutHeading
                    heading={listHeading.heading}
                    headingButtons={listHeading.headingButtons}
                    headingClassName={listHeading.headingClassName}
                    headingType={listHeading.headingType}
                />
            ) : (
                <div />
            )}
            {contentHeading ? (
                <LayoutHeading
                    heading={contentHeading.heading}
                    headingButtons={contentHeading.headingButtons}
                    headingClassName={contentHeading.headingClassName}
                    headingType={contentHeading.headingType}
                />
            ) : (
                <div />
            )}
        </>
    )
}

const SideNavigationListLayout: React.FC<SideNavigationListLayoutProps> = ({
    navigation,
    content,
    listHeading,
    contentHeading,
    columnGap = 'lg',
    rowGap = 'md',
}) => {
    return (
        <div className={classNames(styles.sideNavigationLayout, determineGapStyles(columnGap, rowGap))}>
            {(listHeading || contentHeading) && (
                <HeadingRow contentHeading={contentHeading} listHeading={listHeading} />
            )}
            {navigation}
            {content}
        </div>
    )
}

export default SideNavigationListLayout
