import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import _, { sum, round, partition, flatten, clamp, isEmpty, mapValues, pick, fromPairs } from 'lodash'
import { DateTime, IANAZone, Interval } from 'luxon'
import { Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import AutoSizer from 'react-virtualized-auto-sizer'

import { LocalityConfigurationStateModel, OrganizationResponse } from '@api'

import { statisticsApi } from '@services'

import {
    generateConversionDashboardCustomIntervalPath,
    generateOrganizationsEditLocalitiesPath,
} from '@helpers/VividiURLs'
import { useSuspendingQuery } from '@helpers/api'
import { useAddBackLink } from '@helpers/backlinks'
import { gdynusaReadableInterval } from '@helpers/datetimeUtils'
import { GoogleEventName } from '@helpers/gtag'
import { formatIntervalToFilename } from '@helpers/intervals'
import { orderLocalities } from '@helpers/orderFunctions'
import { useTimezoneConfig, useLocalizeDateTime, migrateTimestamp } from '@helpers/timezoneConfig'
import { pickColumn } from '@helpers/utils'
import { ExportData, exportToExcel } from '@helpers/xlsxExporter'

import Box from '@elements/Box/Box'
import TableWithSorting, { CellContent } from '@elements/Table/TableWithSorting'

import AdminOnlyLinkRenderer from '@components/AdminOnlyLinkRenderer'
import ConversionDataChangeBox from '@components/StatisticsSummary/ConversionDataChangeBox'
import ConversionStatisticBox from '@components/StatisticsSummary/ConversionStatisticBox'
import NotConfiguredLocalitiesList from '@components/StatisticsSummary/NotConfiguredLocalitiesList'
import SectionSubheading from '@components/StatisticsSummary/SectionSubheading'
import { calculatePercentageChange, partitionByInterval } from '@components/StatisticsSummary/utils'
import { useNotify } from '@components/notifications/NotificationsContext'
import StatisticsSummaryConversionPlot from '@components/plots/StatisticsSummaryConversionPlot'
import { parseDate } from '@components/plots/common'
import DownloadButtonWrapper from '@components/plots/downloadButtons/DownloadButtonWrapper'

import styles from './ConversionSection.module.scss'
import DetailLink from './DetailLink'
import { ConversionsStatistics } from './SectionsTypes'

type Props = {
    localities: LocalityConfigurationStateModel[]
    selectedLocalities: LocalityConfigurationStateModel[]
    days: Array<DateTime>
    comparisonDays?: Array<DateTime>
    interval: Interval
    comparisonInterval?: Interval
    selectedOrganization: OrganizationResponse
    shouldDisplayLocalitySummary: boolean
}

export const conversionRatio = (averageA: number | undefined, averageB: number | undefined) => {
    if (averageA !== undefined && averageB !== undefined) {
        const ratio = (averageA / averageB) * 100

        if (!Number.isNaN(ratio)) {
            return clamp(ratio, 0, 100)
        }

        return 0
    }

    return 0
}

const ConversionSection = ({
    localities,
    interval,
    comparisonInterval,
    selectedLocalities,
    selectedOrganization,
    shouldDisplayLocalitySummary,
    days,
    comparisonDays,
}: Props) => {
    const { t } = useTranslation()

    const notify = useNotify()
    const addBackLink = useAddBackLink()

    const [configuredLocalities, notConfiguredLocalities] = partition(
        orderLocalities(selectedLocalities),
        ({ isConversionsConfigured }) => isConversionsConfigured
    )

    const { data: conversionsOverview } = useSuspendingQuery(
        statisticsApi.getConversionsOverview.query({
            body: {
                dates: [...days, ...(comparisonDays ?? [])].map((d) => d.toISODate()),
                localities: pickColumn(localities, 'id'),
            },
        })
    )

    const zone = useTimezoneConfig()
    const localize = useLocalizeDateTime()

    const itemsByLocality = fromPairs(
        conversionsOverview.itemsByLocality.map(({ localityId, items }) => [localityId, items])
    )

    const { currentInterval, previousInterval } = partitionByInterval(
        interval,
        flatten(configuredLocalities.map(({ id }) => itemsByLocality[id.toString()] ?? []))
    )

    const conversionsData: ConversionsStatistics = {
        currentInterval: {
            intervalTotalVisitors: sum(currentInterval.map(({ visitors }) => visitors ?? 0)),
            intervalTotalBounced: sum(currentInterval.map(({ bounced }) => bounced ?? 0)),
            intervalTotalPassersBy: sum(currentInterval.map(({ passerBy }) => passerBy ?? 0)),
            intervalTotalSales: sum(currentInterval.map(({ sales }) => sales ?? 0)),
            intervalTotalBrowsingVisitors:
                sum(currentInterval.map(({ visitors }) => visitors ?? 0)) -
                sum(currentInterval.map(({ bounced }) => bounced ?? 0)),
        },
        previousInterval:
            comparisonInterval !== undefined
                ? {
                      intervalTotalVisitors: sum(previousInterval.map(({ visitors }) => visitors ?? 0)),
                      intervalTotalBounced: sum(previousInterval.map(({ bounced }) => bounced ?? 0)),
                      intervalTotalPassersBy: sum(previousInterval.map(({ passerBy }) => passerBy ?? 0)),
                      intervalTotalSales: sum(previousInterval.map(({ sales }) => sales ?? 0)),
                      intervalTotalBrowsingVisitors:
                          sum(previousInterval.map(({ visitors }) => visitors ?? 0)) -
                          sum(previousInterval.map(({ bounced }) => bounced ?? 0)),
                  }
                : undefined,
    }

    const conversionPlotData = [
        {
            name: t('conversions.totalPassersby', 'Total passersby'),
            value: conversionsData.currentInterval.intervalTotalPassersBy,
            description: t(
                'conversions.totalPassersbyDescription',
                'the total number of people who pass the store in the selected localities'
            ),
            comparisonValue: conversionsData.previousInterval?.intervalTotalPassersBy,
        },
        {
            name: t('statistics.totalVisitors', 'Total visitors'),
            value: conversionsData.currentInterval.intervalTotalVisitors,
            description: t(
                'conversions.totalVisitorsDescription',
                'the total number of people who enter the store in the selected localities'
            ),
            comparisonValue: conversionsData.previousInterval?.intervalTotalVisitors,
        },
        {
            name: t('statistics.totalBrowsingVisitors', 'Total browsing visitors'),
            value: conversionsData.currentInterval.intervalTotalBrowsingVisitors,
            description: t(
                'conversions.totalBrowsingVisitorsDescription',
                'the total number of people who enter and browse the store (do not leave immediately) in the selected localities'
            ),
            comparisonValue: conversionsData.previousInterval?.intervalTotalBrowsingVisitors,
        },
        {
            name: t('statistics.totalSales', 'Total sales'),
            value: conversionsData.currentInterval.intervalTotalSales,
            description: t(
                'conversions.totalSalesDescription',
                'the total number of browsing visitors who actually buy something in the selected localities'
            ),
            comparisonValue: conversionsData.previousInterval?.intervalTotalSales,
        },
    ]

    const conversionsByLocalityByInterval = mapValues(
        pick(
            itemsByLocality,
            configuredLocalities.map((l) => l.id.toString())
        ),
        (items) => {
            const { currentInterval, previousInterval } = partitionByInterval(interval, items)

            return [currentInterval, previousInterval]
        }
    )

    const conversionDataLocalities = configuredLocalities.map(({ id, name, timezone }) => {
        const [currentInterval, previousInterval] = conversionsByLocalityByInterval[id]

        const currentPassersByLocality = sum(currentInterval.map(({ passerBy }) => passerBy ?? 0)) ?? 0

        const currentVisitorsLocality = sum(currentInterval.map(({ visitors }) => visitors ?? 0)) ?? 0

        const currentPassersbyToVisitorsRatioLocality = round(
            conversionRatio(currentVisitorsLocality, currentPassersByLocality) ?? 0,
            1
        )

        const currentBouncedLocality = sum(currentInterval.map(({ bounced }) => bounced ?? 0)) ?? 0
        const currentBouncedRatioLocality = conversionRatio(currentBouncedLocality, currentVisitorsLocality) ?? 0

        const currentSalesLocality = sum(currentInterval.map(({ sales }) => sales ?? 0)) ?? 0

        const previousPassersByLocality =
            previousInterval !== undefined ? sum(previousInterval.map(({ passerBy }) => passerBy ?? 0)) ?? 0 : 0
        const previousVisitorsLocality =
            previousInterval !== undefined ? sum(previousInterval.map(({ visitors }) => visitors ?? 0)) ?? 0 : 0
        const previousPassersbyToVisitorsRatioLocality = round(
            conversionRatio(previousVisitorsLocality, previousPassersByLocality) ?? 0,
            1
        )

        const previousBouncedLocality =
            previousInterval !== undefined ? sum(previousInterval.map(({ bounced }) => bounced ?? 0)) ?? 0 : 0

        const previousBouncedRatioLocality = conversionRatio(previousBouncedLocality, previousVisitorsLocality) ?? 0

        const previousSalesLocality =
            previousInterval !== undefined ? sum(previousInterval.map(({ sales }) => sales ?? 0)) ?? 0 : 0

        const cells: {
            [key: string]: CellContent
        } = {
            locality: {
                content: name,
                cellType: 'text',
                targetLink: addBackLink(generateOrganizationsEditLocalitiesPath(selectedOrganization.id, id)),
                renderer: AdminOnlyLinkRenderer,
            },
            passerby: {
                content: currentPassersByLocality,
                cellType: 'numeric',
                numberFormat: 'count',
                comparisonValue: previousPassersByLocality,
                valueChange: calculatePercentageChange(currentPassersByLocality, previousPassersByLocality),
                isChangeAbsolute: false,
            },
            visitors: {
                content: currentVisitorsLocality,
                cellType: 'numeric',
                numberFormat: 'count',
                comparisonValue: previousVisitorsLocality,
                valueChange: calculatePercentageChange(currentVisitorsLocality, previousVisitorsLocality),
                isChangeAbsolute: false,
                roundingPrecision: 1,
            },
            passersbyConversions: {
                content: currentPassersbyToVisitorsRatioLocality,
                cellType: 'numeric',
                numberFormat: 'percentage',
                comparisonValue: previousPassersbyToVisitorsRatioLocality,
                valueChange: round(
                    currentPassersbyToVisitorsRatioLocality - previousPassersbyToVisitorsRatioLocality,
                    1
                ),
                isChangeAbsolute: true,
                roundingPrecision: 1,
            },
            bounceRate: {
                content: currentBouncedRatioLocality,
                cellType: 'numeric',
                numberFormat: 'percentage',
                comparisonValue: previousBouncedRatioLocality,
                valueChange: round(currentBouncedRatioLocality - previousBouncedRatioLocality, 1),
                isChangeAbsolute: true,
                roundingPrecision: 1,
            },
            sales: {
                content: currentSalesLocality,
                cellType: 'numeric',
                numberFormat: 'count',
                comparisonValue: previousSalesLocality,
                valueChange: calculatePercentageChange(currentSalesLocality, previousSalesLocality),
                isChangeAbsolute: false,
            },
            detail: {
                cellType: 'rawElement',
                element: (
                    <DetailLink
                        to={generateConversionDashboardCustomIntervalPath(
                            id,
                            migrateTimestamp(interval.start, zone, new IANAZone(timezone)),
                            migrateTimestamp(interval.end, zone, new IANAZone(timezone))
                        )}
                    />
                ),
            },
        }

        return cells
    })

    const passersbyToVisitorsRatioCurrent = round(
        conversionRatio(
            conversionsData.currentInterval.intervalTotalVisitors,
            conversionsData.currentInterval.intervalTotalPassersBy
        ),
        1
    )

    const passersbyToVisitorsRatioPrevious = round(
        conversionRatio(
            conversionsData.previousInterval?.intervalTotalVisitors,
            conversionsData.previousInterval?.intervalTotalPassersBy
        ),
        1
    )

    const bouncedRatioCurrent = round(
        conversionRatio(
            conversionsData.currentInterval.intervalTotalBounced,
            conversionsData.currentInterval.intervalTotalVisitors
        ),
        1
    )

    const bouncedRatioPrevious = round(
        conversionRatio(
            conversionsData.previousInterval?.intervalTotalBounced,
            conversionsData.previousInterval?.intervalTotalVisitors
        ),
        1
    )

    const visitorToSaleRatioCurrent = round(
        conversionRatio(
            conversionsData.currentInterval.intervalTotalSales,
            conversionsData.currentInterval.intervalTotalVisitors
        ),
        1
    )

    const visitorToSaleRatioPrevious = round(
        conversionRatio(
            conversionsData.previousInterval?.intervalTotalSales,
            conversionsData.previousInterval?.intervalTotalVisitors
        ),
        1
    )

    const handleDownload = () => {
        const conversionLocalitiesData = _(itemsByLocality)
            .mapValues((items) =>
                items.map(({ startingFrom, passerBy, visitors, bounced, sales }) => ({
                    [t('others.date', 'Date')]: localize(parseDate(startingFrom)).toLocaleString(),
                    [t('conversions.passersby', 'Passersby')]: passerBy ?? 0,
                    [t('conversions.visitors', 'Visitors')]: visitors ?? 0,
                    [t('conversions.bounced', 'Bounced')]: bounced ?? 0,
                    [t('conversions.browsingVisitors', 'Browsing visitors')]: (visitors ?? 0) - (bounced ?? 0),
                    [t('conversions.sales', 'Sales')]: sales ?? 0,
                    [t('conversions.passerbyVisitorRate', 'Passerby > visitor (%)')]:
                        visitors && passerBy ? round(conversionRatio(visitors, passerBy), 1) : 0,
                    [t('conversions.bounceRate', 'Bounce rate (%)')]:
                        bounced && visitors ? round(conversionRatio(bounced, visitors), 1) : 0,
                    [t('conversions.visitorSaleRate', 'Visitor > sale (%)')]:
                        sales && visitors ? round(conversionRatio(sales, visitors), 1) : 0,
                }))
            )
            .mapKeys((_, localityId) => localities.find((l) => l.id.toString() === localityId)?.name ?? localityId)
            .value()

        const conversionSummary: ExportData = {
            [t('heading.localityComparisonUnderscore', '_Comparison of localities_')]: conversionDataLocalities.map(
                (cell) => ({
                    [t('heading.locality', 'Locality')]: cell['locality'].content ?? '',
                    [t('conversions.passersby', 'Passersby')]: cell['passerby'].content ?? 0,
                    [t('conversions.visitors', 'Visitors')]: cell['visitors'].content ?? 0,
                    [t('conversions.passerbyVisitorRate', 'Passerby > visitor (%)')]:
                        cell['passersbyConversions'].content ?? 0,
                    [t('conversions.bounceRate', 'Bounce rate (%)')]: round(Number(cell['bounceRate'].content ?? 0), 1),
                    [t('conversions.sales', 'Sales')]: cell['sales'].content ?? 0,
                })
            ),
        }

        const exportData = {
            ...conversionSummary,
            ...conversionLocalitiesData,
        }

        if (!isEmpty(exportData)) {
            exportToExcel(
                exportData,
                `${selectedOrganization.name}-conversions-${formatIntervalToFilename(
                    gdynusaReadableInterval(interval.mapEndpoints(localize))
                )}`,
                GoogleEventName.DOWNLOAD_CONVERSIONS_ORGANIZATION_EXCEL,
                true
            )
        } else {
            notify({
                title: t('notification.error', 'Error'),
                content: t('notification.nothingToExport', 'Nothing to export'),
                variant: 'danger',
            })
        }
    }

    return (
        <Box paddingSize="lg">
            <Box.Title
                buttons={
                    <DownloadButtonWrapper
                        buttonText={t('button.downloadXLSX', 'Download XLSX')}
                        onClick={handleDownload}
                    />
                }
                hideButtons={isEmpty(configuredLocalities)}
                text={t('heading.conversionsStatistics', 'Conversions Statistics')}
            />
            {!isEmpty(configuredLocalities) && (
                <>
                    {shouldDisplayLocalitySummary && (
                        <>
                            <ConversionStatisticBox
                                comparisonInterval={comparisonInterval}
                                data={conversionPlotData}
                                interval={interval}
                                localities={configuredLocalities}
                            />
                            <div className={styles.plotContainer}>
                                <AutoSizer>
                                    {({ width, height }) => (
                                        <StatisticsSummaryConversionPlot
                                            className={styles.plot}
                                            data={[
                                                ...conversionPlotData,
                                                conversionPlotData[conversionPlotData.length - 1],
                                            ]}
                                            height={height}
                                            width={width}
                                        />
                                    )}
                                </AutoSizer>
                            </div>
                            <Row>
                                <ConversionDataChangeBox
                                    comparisonInterval={comparisonInterval}
                                    data={{
                                        value: passersbyToVisitorsRatioCurrent,
                                        comparisonValue: passersbyToVisitorsRatioPrevious,
                                        change: passersbyToVisitorsRatioCurrent - passersbyToVisitorsRatioPrevious,
                                        unit: '%',
                                        roundingPrecision: 1,
                                    }}
                                    description={t(
                                        'conversions.passerbyVisitorRateDescription',
                                        'Average conversion ratio describes how many passersby are converted into store visitors in the selected localities'
                                    )}
                                    desiredTrend="positive"
                                    interval={interval}
                                    label={
                                        <>
                                            {t('conversions.passerby', 'Passerby ')}
                                            <FontAwesomeIcon className="ml-2 mr-2" icon={faArrowRight} />
                                            {t('conversions.visitor', ' Visitor')}
                                        </>
                                    }
                                    localities={configuredLocalities}
                                />
                                <ConversionDataChangeBox
                                    comparisonInterval={comparisonInterval}
                                    data={{
                                        value: bouncedRatioCurrent,
                                        comparisonValue: bouncedRatioPrevious,
                                        change: bouncedRatioCurrent - bouncedRatioPrevious,
                                        unit: '%',
                                        roundingPrecision: 1,
                                    }}
                                    description={t(
                                        'conversions.bouncedRatioDescription',
                                        'Average bounce ratio describes how many store visitors leave the store immediately after entering it in the selected localities'
                                    )}
                                    desiredTrend="negative"
                                    interval={interval}
                                    label={t('heading.bounceRate', 'Bounce rate')}
                                    localities={configuredLocalities}
                                />

                                <ConversionDataChangeBox
                                    comparisonInterval={comparisonInterval}
                                    data={{
                                        value: visitorToSaleRatioCurrent,
                                        comparisonValue: visitorToSaleRatioPrevious,
                                        change: visitorToSaleRatioCurrent - visitorToSaleRatioPrevious,
                                        unit: '%',
                                        roundingPrecision: 1,
                                    }}
                                    description={t(
                                        'conversions.visitorSaleRateDescription',
                                        'Average conversion ratio describes how many browsing visitors actually purchase something in the selected localities'
                                    )}
                                    desiredTrend="positive"
                                    interval={interval}
                                    label={
                                        <>
                                            {t('conversions.visitor', ' Visitor')}
                                            <FontAwesomeIcon className="ml-2 mr-2" icon={faArrowRight} />
                                            {t('conversions.sale', ' Sale')}
                                        </>
                                    }
                                    localities={configuredLocalities}
                                />
                            </Row>
                        </>
                    )}

                    <SectionSubheading selectedOrganization={selectedOrganization}>
                        {t('heading.localityComparison', 'Comparison of localities')}
                    </SectionSubheading>
                    <TableWithSorting
                        bodyRows={conversionDataLocalities}
                        comparisonInterval={comparisonInterval}
                        defaultSortingColumn="passersbyConversions"
                        defaultSortingOrder="desc"
                        excludedSortingColumns={['detail']}
                        headRow={[
                            {
                                name: 'locality',
                                displayName: t('heading.locality', 'Locality'),
                            },
                            {
                                name: 'passerby',
                                displayName: t('conversions.passersby', 'Passersby'),
                                align: 'right',
                            },
                            {
                                name: 'visitors',
                                displayName: t('conversions.visitors', 'Visitors'),
                                align: 'right',
                            },
                            {
                                name: 'passersbyConversions',
                                displayName: t('conversions.passerbyVisitorTableRate', 'Passerby > visitor'),
                                align: 'right',
                            },
                            {
                                name: 'bounceRate',
                                displayName: t('heading.bounceRate', 'Bounce rate'),
                                align: 'right',
                            },
                            {
                                name: 'sales',
                                displayName: t('conversions.sales', 'Sales'),
                                align: 'right',
                            },
                            {
                                name: 'detail',
                                displayName: t('table.actions', 'Actions'),
                                align: 'right',
                            },
                        ]}
                        paginationSize={15}
                    />
                </>
            )}
            {!isEmpty(notConfiguredLocalities) && (
                <NotConfiguredLocalitiesList
                    notConfiguredLocalities={notConfiguredLocalities}
                    nothingConfigured={isEmpty(configuredLocalities)}
                />
            )}
        </Box>
    )
}

export default ConversionSection
