/* tslint:disable */
/* eslint-disable */
/**
 * Vividi Dashboard API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ConversionsConfiguration,
    ConversionsConfigurationFromJSON,
    ConversionsConfigurationFromJSONTyped,
    ConversionsConfigurationToJSON,
} from './ConversionsConfiguration';
import {
    EmotionConfiguration,
    EmotionConfigurationFromJSON,
    EmotionConfigurationFromJSONTyped,
    EmotionConfigurationToJSON,
} from './EmotionConfiguration';
import {
    FootfallConfiguration,
    FootfallConfigurationFromJSON,
    FootfallConfigurationFromJSONTyped,
    FootfallConfigurationToJSON,
} from './FootfallConfiguration';
import {
    Location,
    LocationFromJSON,
    LocationFromJSONTyped,
    LocationToJSON,
} from './Location';
import {
    OccupancyConfiguration,
    OccupancyConfigurationFromJSON,
    OccupancyConfigurationFromJSONTyped,
    OccupancyConfigurationToJSON,
} from './OccupancyConfiguration';
import {
    OpeningHours,
    OpeningHoursFromJSON,
    OpeningHoursFromJSONTyped,
    OpeningHoursToJSON,
} from './OpeningHours';
import {
    QueuesConfiguration,
    QueuesConfigurationFromJSON,
    QueuesConfigurationFromJSONTyped,
    QueuesConfigurationToJSON,
} from './QueuesConfiguration';
import {
    StaySafeConfiguration,
    StaySafeConfigurationFromJSON,
    StaySafeConfigurationFromJSONTyped,
    StaySafeConfigurationToJSON,
} from './StaySafeConfiguration';

/**
 * 
 * @export
 * @interface LocalityModel
 */
export interface LocalityModel {
    /**
     * 
     * @type {string}
     * @memberof LocalityModel
     */
    name: string;
    /**
     * 
     * @type {Location}
     * @memberof LocalityModel
     */
    location?: Location;
    /**
     * 
     * @type {number}
     * @memberof LocalityModel
     */
    capacity?: number;
    /**
     * 
     * @type {OpeningHours}
     * @memberof LocalityModel
     */
    openingHours?: OpeningHours;
    /**
     * 
     * @type {string}
     * @memberof LocalityModel
     */
    timezone?: string;
    /**
     * 
     * @type {boolean}
     * @memberof LocalityModel
     */
    isRealTimeOccupancyEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LocalityModel
     */
    isFloorplanOccupancyEnabled?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof LocalityModel
     */
    labels: Array<string>;
    /**
     * 
     * @type {ConversionsConfiguration}
     * @memberof LocalityModel
     */
    conversionsConfiguration?: ConversionsConfiguration;
    /**
     * 
     * @type {QueuesConfiguration}
     * @memberof LocalityModel
     */
    queuesConfiguration?: QueuesConfiguration;
    /**
     * 
     * @type {OccupancyConfiguration}
     * @memberof LocalityModel
     */
    occupancyConfiguration?: OccupancyConfiguration;
    /**
     * 
     * @type {StaySafeConfiguration}
     * @memberof LocalityModel
     */
    staySafeConfiguration?: StaySafeConfiguration;
    /**
     * 
     * @type {FootfallConfiguration}
     * @memberof LocalityModel
     */
    footfallConfiguration?: FootfallConfiguration;
    /**
     * 
     * @type {EmotionConfiguration}
     * @memberof LocalityModel
     */
    emotionConfiguration?: EmotionConfiguration;
}

export function LocalityModelFromJSON(json: any): LocalityModel {
    return LocalityModelFromJSONTyped(json, false);
}

export function LocalityModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): LocalityModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'location': !exists(json, 'location') ? undefined : LocationFromJSON(json['location']),
        'capacity': !exists(json, 'capacity') ? undefined : json['capacity'],
        'openingHours': !exists(json, 'opening_hours') ? undefined : OpeningHoursFromJSON(json['opening_hours']),
        'timezone': !exists(json, 'timezone') ? undefined : json['timezone'],
        'isRealTimeOccupancyEnabled': !exists(json, 'is_real_time_occupancy_enabled') ? undefined : json['is_real_time_occupancy_enabled'],
        'isFloorplanOccupancyEnabled': !exists(json, 'is_floorplan_occupancy_enabled') ? undefined : json['is_floorplan_occupancy_enabled'],
        'labels': json['labels'],
        'conversionsConfiguration': !exists(json, 'conversions_configuration') ? undefined : ConversionsConfigurationFromJSON(json['conversions_configuration']),
        'queuesConfiguration': !exists(json, 'queues_configuration') ? undefined : QueuesConfigurationFromJSON(json['queues_configuration']),
        'occupancyConfiguration': !exists(json, 'occupancy_configuration') ? undefined : OccupancyConfigurationFromJSON(json['occupancy_configuration']),
        'staySafeConfiguration': !exists(json, 'stay_safe_configuration') ? undefined : StaySafeConfigurationFromJSON(json['stay_safe_configuration']),
        'footfallConfiguration': !exists(json, 'footfall_configuration') ? undefined : FootfallConfigurationFromJSON(json['footfall_configuration']),
        'emotionConfiguration': !exists(json, 'emotion_configuration') ? undefined : EmotionConfigurationFromJSON(json['emotion_configuration']),
    };
}

export function LocalityModelToJSON(value?: LocalityModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'location': LocationToJSON(value.location),
        'capacity': value.capacity,
        'opening_hours': OpeningHoursToJSON(value.openingHours),
        'timezone': value.timezone,
        'is_real_time_occupancy_enabled': value.isRealTimeOccupancyEnabled,
        'is_floorplan_occupancy_enabled': value.isFloorplanOccupancyEnabled,
        'labels': value.labels,
        'conversions_configuration': ConversionsConfigurationToJSON(value.conversionsConfiguration),
        'queues_configuration': QueuesConfigurationToJSON(value.queuesConfiguration),
        'occupancy_configuration': OccupancyConfigurationToJSON(value.occupancyConfiguration),
        'stay_safe_configuration': StaySafeConfigurationToJSON(value.staySafeConfiguration),
        'footfall_configuration': FootfallConfigurationToJSON(value.footfallConfiguration),
        'emotion_configuration': EmotionConfigurationToJSON(value.emotionConfiguration),
    };
}

