import { faXmark } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import produce from 'immer'
import React, { useState } from 'react'
import { Form, Row, Col, Button } from 'react-bootstrap'
import Helmet from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { MutationStatus, useQueryClient } from 'react-query'

import { LocalityModel, LocalityResponse, OpeningHours, OpeningHoursInterval } from '@api/models'

import { localityApi } from '@services'

import { useDetectMobile } from '@hooks/useDetectDevice'

import Box from '@elements/Box/Box'

import AsyncButton from './AsyncButton'
import styles from './LocalityEditForm.module.scss'
import OpeningHoursPicker, {
    DaysInWeekShortened,
    defaultOpeningHours,
    defaultOpeningHoursInterval,
} from './OpeningHoursPicker/OpeningHoursPicker'

interface Props {
    locality: LocalityResponse
    onSubmit: (data: LocalityModel) => void
    submissionStatus: MutationStatus
    onCloseModal: () => void
}

const LocalityOpeningHoursForm = (props: Props) => {
    const { t } = useTranslation()
    const queryClient = useQueryClient()
    const isMobile = useDetectMobile()

    const [openingHours, setOpeningHours] = useState<OpeningHours>(props.locality?.openingHours ?? defaultOpeningHours)
    const [openingHoursUpdated, setOpeningHoursUpdated] = useState(false)

    const isEdited = openingHoursUpdated || props.locality?.openingHours !== openingHours

    const documentTitle = props.locality && `${props.locality.name} - ${t('tab.openingHours', 'Opening hours')}`

    const handleCancel = () => {
        setOpeningHours(props.locality?.openingHours ?? defaultOpeningHours)
        setOpeningHoursUpdated(false)
    }

    const handleOpeningHoursChange = (openingHoursInterval: OpeningHoursInterval[], day: DaysInWeekShortened) => {
        setOpeningHours((previousValue) =>
            produce(previousValue, (draft) => {
                draft[day] = openingHoursInterval
            })
        )
        setOpeningHoursUpdated(true)
    }

    const handleIntervalAdd = (day: DaysInWeekShortened) => {
        setOpeningHours((previousValue) =>
            produce(previousValue, (draft) => {
                draft[day] = [defaultOpeningHoursInterval, ...previousValue[day]]
            })
        )
        setOpeningHoursUpdated(true)
    }

    const handleIntervalDelete = (day: DaysInWeekShortened, index?: number) => {
        if (index === undefined) {
            setOpeningHours((previousValue) =>
                produce(previousValue, (draft) => {
                    draft[day] = []
                })
            )
            setOpeningHoursUpdated(true)

            return
        }
        setOpeningHours((previousValue) =>
            produce(previousValue, (draft) => {
                draft[day].splice(index, 1)
            })
        )
        setOpeningHoursUpdated(true)
    }

    const onSubmit: React.FormEventHandler = (e) => {
        e.preventDefault()

        props.onSubmit({
            name: props.locality.name,
            labels: props.locality.labels,
            openingHours: openingHoursUpdated ? openingHours : undefined,
            capacity: props.locality.capacity,
        })

        queryClient.invalidateQueries(localityApi.updateLocality.mutationKey)
    }

    return (
        <>
            <Helmet>
                <title>{documentTitle}</title>
            </Helmet>
            <Box paddingSize="lg">
                <Box.Title
                    buttons={
                        isEdited ? (
                            <div className={styles.buttonGroupRight}>
                                <Button variant="secondary" onClick={handleCancel}>
                                    {t('button.cancel', 'Cancel')}
                                </Button>
                                <AsyncButton
                                    status={props.submissionStatus}
                                    text={t('button.saveChanges', 'Save changes')}
                                    variant="primary"
                                    onClick={onSubmit}
                                />
                            </div>
                        ) : isMobile ? (
                            <FontAwesomeIcon icon={faXmark} onClick={() => props.onCloseModal()} />
                        ) : null
                    }
                    text={t('tab.openingHours', 'Opening hours')}
                />

                <Form className={styles.formContainer} onSubmit={onSubmit}>
                    <Form.Group>
                        <Row>
                            <Col sm={12}>
                                <OpeningHoursPicker
                                    openingHours={openingHours}
                                    onChange={handleOpeningHoursChange}
                                    onIntervalAdd={handleIntervalAdd}
                                    onIntervalDelete={handleIntervalDelete}
                                />
                            </Col>
                        </Row>
                    </Form.Group>
                </Form>
            </Box>
        </>
    )
}

export default LocalityOpeningHoursForm
