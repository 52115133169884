import React, { useState } from 'react'
import { Form, Col, Row, Button } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { MutationStatus } from 'react-query'

import AsyncButton from './AsyncButton'
import styles from './SetPasswordForm.module.scss'

interface Props {
    onSubmit: (password: string, currentPassword: string) => void
    onCancel?: () => void
    askCurrentPassword: boolean
    submissionStatus: MutationStatus
    isCompact?: boolean
}

const SetPasswordForm: React.FC<Props> = ({ askCurrentPassword, submissionStatus, isCompact, onSubmit, onCancel }) => {
    const { t } = useTranslation()

    const [password, setPassword] = useState('')
    const [passwordConfirm, setPasswordConfirm] = useState('')
    const [currentPassword, setCurrentPassword] = useState('')

    const handleFormReset = () => {
        setPassword('')
        setPasswordConfirm('')
        onCancel?.()
    }

    const onFormSubmit: React.FormEventHandler = (e) => {
        e.preventDefault()

        if (password.length === 0 || password !== passwordConfirm) {
            return
        }

        onSubmit(password, currentPassword)
    }

    const touched = Boolean(password) || Boolean(passwordConfirm) || Boolean(currentPassword)

    return (
        <Form onSubmit={onFormSubmit}>
            {askCurrentPassword && (
                <Form.Group as={Row}>
                    <Form.Label column={true} sm={2}>
                        {t('form.currentPassword', 'Current password')}
                    </Form.Label>
                    <Col sm={10}>
                        <Form.Control
                            isInvalid={touched && currentPassword.length === 0}
                            type="password"
                            value={currentPassword}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setCurrentPassword(e.target.value)}
                        />
                    </Col>
                </Form.Group>
            )}
            <Form.Group as={Row}>
                <Form.Label column={true} sm={isCompact ? 5 : 2}>
                    {t('form.newPassword', 'New password')}
                </Form.Label>
                <Col sm={isCompact ? 7 : 10}>
                    <Form.Control
                        isInvalid={touched && password.length === 0}
                        type="password"
                        value={password}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)}
                    />
                </Col>
            </Form.Group>
            <Form.Group as={Row}>
                <Form.Label column={true} sm={isCompact ? 5 : 2}>
                    {t('form.confirmNewPassword', 'Confirm new password')}
                </Form.Label>
                <Col sm={isCompact ? 7 : 10}>
                    <Form.Control
                        isInvalid={touched && password !== passwordConfirm}
                        type="password"
                        value={passwordConfirm}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPasswordConfirm(e.target.value)}
                    />
                </Col>
            </Form.Group>
            {!isCompact && (
                <Form.Group as={Row}>
                    <Col sm={{ span: 10 }}>
                        <AsyncButton
                            loadingText={t('button.saving', 'Saving...')}
                            status={submissionStatus}
                            text={t('button.submit', 'Submit')}
                            type="submit"
                            variant="primary"
                        />
                    </Col>
                </Form.Group>
            )}
            {isCompact && (
                <Form.Group as={Row}>
                    <Col className={styles.controlButtonsRow} sm={12}>
                        <Button variant="secondary" onClick={() => handleFormReset()}>
                            {t('button.cancel', 'Cancel')}
                        </Button>
                        <AsyncButton
                            allowRefetch={true}
                            loadingText={t('button.saving', 'Saving...')}
                            status={submissionStatus}
                            text={t('button.save', 'Save')}
                            type="submit"
                            variant="primary"
                        />
                    </Col>
                </Form.Group>
            )}
        </Form>
    )
}

export default SetPasswordForm
