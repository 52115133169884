import React from 'react'
import { useTranslation } from 'react-i18next'

import Box from '@elements/Box/Box'

import styles from './LoadingSpinner.module.scss'

interface Props {
    bare?: boolean
}

const LoadingSpinnerInner = () => {
    const { t } = useTranslation()

    return (
        <div className={styles.spinnerWrapper}>
            <div className="spinner-border" role="status">
                <span className="sr-only">{t('others.loading', 'Loading')}</span>
            </div>
        </div>
    )
}

const LoadingSpinner: React.FC<Props> = (props) => {
    return !props.bare ? (
        <Box>
            <LoadingSpinnerInner />
        </Box>
    ) : (
        <LoadingSpinnerInner />
    )
}

export default LoadingSpinner
