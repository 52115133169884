/**
 * Vividi Dashboard API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime'
import {UseQueryOptions, QueryKey} from 'react-query'

import {
    BackgroundJobBatchResponse,
    BackgroundJobBatchResponseFromJSON,
    BackgroundJobBatchResponseToJSON,
    BackgroundJobList,
    BackgroundJobListFromJSON,
    BackgroundJobListToJSON,
    BackgroundJobResponse,
    BackgroundJobResponseFromJSON,
    BackgroundJobResponseToJSON,
    EntityCopyingRequestBody,
    EntityCopyingRequestBodyFromJSON,
    EntityCopyingRequestBodyToJSON,
    OverviewPopulationRequestBody,
    OverviewPopulationRequestBodyFromJSON,
    OverviewPopulationRequestBodyToJSON,
    RetryJobRequestBody,
    RetryJobRequestBodyFromJSON,
    RetryJobRequestBodyToJSON,
    StatisticsRecalculationRequestBody,
    StatisticsRecalculationRequestBodyFromJSON,
    StatisticsRecalculationRequestBodyToJSON,
    SuccessResponse,
    SuccessResponseFromJSON,
    SuccessResponseToJSON,
} from '../models';

export interface RetryJobRequest {
    jobId: number;
    body: RetryJobRequestBody;
}

export interface ScheduleConversionsOverviewPopulationRequest {
    body: OverviewPopulationRequestBody;
}

export interface ScheduleEntityCopyingRequest {
    body: EntityCopyingRequestBody;
}

export interface ScheduleFootfallOverviewPopulationRequest {
    body: OverviewPopulationRequestBody;
}

export interface ScheduleStatisticsPopulationRequest {
    body: StatisticsRecalculationRequestBody;
}


/**
 * 
 */
export class BackgroundJobsApi extends runtime.BaseAPI {
    readonly name = 'BackgroundJobsApi'

    /**
     * List all jobs that are currently pending.
     */
    getPendingJobs = Object.assign(
        async (initOverrides?: RequestInit): Promise<BackgroundJobList> => {
            const response = await this.getPendingJobs.invokeRaw(initOverrides);
            return await response.value();
        },
        {
            invokeRaw: async (initOverrides?: RequestInit): Promise<runtime.ApiResponse<BackgroundJobList>> => {
                const queryParameters: any = {};
            
                const headerParameters: runtime.HTTPHeaders = {};
            
                if (this.configuration && this.configuration.accessToken) {
                    const token = this.configuration.accessToken;
                    const tokenString = await token("token", []);
            
                    if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                    }
                }
                const response = await this.request({
                    path: `/api/background-jobs/pending`,
                    method: 'GET',
                    headers: headerParameters,
                    query: queryParameters,
                }, initOverrides);
            
                return new runtime.JSONApiResponse(response, (jsonValue) => BackgroundJobListFromJSON(jsonValue));
            },
            mutationKey: [
                'BackgroundJobsApi',
                'getPendingJobs', 
            ],
            queryKey: (initOverrides?: RequestInit) => ([
                    ...this.getPendingJobs.mutationKey, 
                {
                }
            ]),
            query: (initOverrides?: RequestInit): UseQueryOptions<BackgroundJobList> & {queryKey: QueryKey} => ({
                queryFn: () => this.getPendingJobs(initOverrides),
                queryKey: this.getPendingJobs.queryKey(initOverrides)
            })
        }
    )

    /**
     * List all jobs that are currently being processed.
     */
    getRunningJobs = Object.assign(
        async (initOverrides?: RequestInit): Promise<BackgroundJobList> => {
            const response = await this.getRunningJobs.invokeRaw(initOverrides);
            return await response.value();
        },
        {
            invokeRaw: async (initOverrides?: RequestInit): Promise<runtime.ApiResponse<BackgroundJobList>> => {
                const queryParameters: any = {};
            
                const headerParameters: runtime.HTTPHeaders = {};
            
                if (this.configuration && this.configuration.accessToken) {
                    const token = this.configuration.accessToken;
                    const tokenString = await token("token", []);
            
                    if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                    }
                }
                const response = await this.request({
                    path: `/api/background-jobs/running`,
                    method: 'GET',
                    headers: headerParameters,
                    query: queryParameters,
                }, initOverrides);
            
                return new runtime.JSONApiResponse(response, (jsonValue) => BackgroundJobListFromJSON(jsonValue));
            },
            mutationKey: [
                'BackgroundJobsApi',
                'getRunningJobs', 
            ],
            queryKey: (initOverrides?: RequestInit) => ([
                    ...this.getRunningJobs.mutationKey, 
                {
                }
            ]),
            query: (initOverrides?: RequestInit): UseQueryOptions<BackgroundJobList> & {queryKey: QueryKey} => ({
                queryFn: () => this.getRunningJobs(initOverrides),
                queryKey: this.getRunningJobs.queryKey(initOverrides)
            })
        }
    )

    /**
     * Retry a failed job.
     */
    retryJob = Object.assign(
        async (requestParameters: RetryJobRequest, initOverrides?: RequestInit): Promise<SuccessResponse> => {
            const response = await this.retryJob.invokeRaw(requestParameters, initOverrides);
            return await response.value();
        },
        {
            invokeRaw: async (requestParameters: RetryJobRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SuccessResponse>> => {
                if (requestParameters.jobId === null || requestParameters.jobId === undefined) {
                    throw new runtime.RequiredError('jobId','Required parameter requestParameters.jobId was null or undefined when calling retryJob.');
                }
            
                if (requestParameters.body === null || requestParameters.body === undefined) {
                    throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling retryJob.');
                }
            
                const queryParameters: any = {};
            
                const headerParameters: runtime.HTTPHeaders = {};
            
                headerParameters['Content-Type'] = 'application/json';
            
                if (this.configuration && this.configuration.accessToken) {
                    const token = this.configuration.accessToken;
                    const tokenString = await token("token", []);
            
                    if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                    }
                }
                const response = await this.request({
                    path: `/api/background-jobs/{job_id}/retry`.replace(`{${"job_id"}}`, encodeURIComponent(String(requestParameters.jobId))),
                    method: 'POST',
                    headers: headerParameters,
                    query: queryParameters,
                    body: RetryJobRequestBodyToJSON(requestParameters.body),
                }, initOverrides);
            
                return new runtime.JSONApiResponse(response, (jsonValue) => SuccessResponseFromJSON(jsonValue));
            },
            mutationKey: [
                'BackgroundJobsApi',
                'retryJob', 
            ],
            queryKey: (requestParameters: RetryJobRequest, initOverrides?: RequestInit) => ([
                    ...this.retryJob.mutationKey, 
                {
                jobId: requestParameters.jobId,
                body: requestParameters.body,
                }
            ]),
            query: (requestParameters: RetryJobRequest, initOverrides?: RequestInit): UseQueryOptions<SuccessResponse> & {queryKey: QueryKey} => ({
                queryFn: () => this.retryJob(requestParameters, initOverrides),
                queryKey: this.retryJob.queryKey(requestParameters, initOverrides)
            })
        }
    )

    /**
     * Schedule the population (or recalculation) of conversion overviews for one or more localities.
     */
    scheduleConversionsOverviewPopulation = Object.assign(
        async (requestParameters: ScheduleConversionsOverviewPopulationRequest, initOverrides?: RequestInit): Promise<BackgroundJobBatchResponse> => {
            const response = await this.scheduleConversionsOverviewPopulation.invokeRaw(requestParameters, initOverrides);
            return await response.value();
        },
        {
            invokeRaw: async (requestParameters: ScheduleConversionsOverviewPopulationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<BackgroundJobBatchResponse>> => {
                if (requestParameters.body === null || requestParameters.body === undefined) {
                    throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling scheduleConversionsOverviewPopulation.');
                }
            
                const queryParameters: any = {};
            
                const headerParameters: runtime.HTTPHeaders = {};
            
                headerParameters['Content-Type'] = 'application/json';
            
                if (this.configuration && this.configuration.accessToken) {
                    const token = this.configuration.accessToken;
                    const tokenString = await token("token", []);
            
                    if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                    }
                }
                const response = await this.request({
                    path: `/api/background-jobs/populate-conversions-overview`,
                    method: 'POST',
                    headers: headerParameters,
                    query: queryParameters,
                    body: OverviewPopulationRequestBodyToJSON(requestParameters.body),
                }, initOverrides);
            
                return new runtime.JSONApiResponse(response, (jsonValue) => BackgroundJobBatchResponseFromJSON(jsonValue));
            },
            mutationKey: [
                'BackgroundJobsApi',
                'scheduleConversionsOverviewPopulation', 
            ],
            queryKey: (requestParameters: ScheduleConversionsOverviewPopulationRequest, initOverrides?: RequestInit) => ([
                    ...this.scheduleConversionsOverviewPopulation.mutationKey, 
                {
                body: requestParameters.body,
                }
            ]),
            query: (requestParameters: ScheduleConversionsOverviewPopulationRequest, initOverrides?: RequestInit): UseQueryOptions<BackgroundJobBatchResponse> & {queryKey: QueryKey} => ({
                queryFn: () => this.scheduleConversionsOverviewPopulation(requestParameters, initOverrides),
                queryKey: this.scheduleConversionsOverviewPopulation.queryKey(requestParameters, initOverrides)
            })
        }
    )

    /**
     */
    scheduleEntityCopying = Object.assign(
        async (requestParameters: ScheduleEntityCopyingRequest, initOverrides?: RequestInit): Promise<BackgroundJobResponse> => {
            const response = await this.scheduleEntityCopying.invokeRaw(requestParameters, initOverrides);
            return await response.value();
        },
        {
            invokeRaw: async (requestParameters: ScheduleEntityCopyingRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<BackgroundJobResponse>> => {
                if (requestParameters.body === null || requestParameters.body === undefined) {
                    throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling scheduleEntityCopying.');
                }
            
                const queryParameters: any = {};
            
                const headerParameters: runtime.HTTPHeaders = {};
            
                headerParameters['Content-Type'] = 'application/json';
            
                if (this.configuration && this.configuration.accessToken) {
                    const token = this.configuration.accessToken;
                    const tokenString = await token("token", []);
            
                    if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                    }
                }
                const response = await this.request({
                    path: `/api/background-jobs/copy-entities`,
                    method: 'POST',
                    headers: headerParameters,
                    query: queryParameters,
                    body: EntityCopyingRequestBodyToJSON(requestParameters.body),
                }, initOverrides);
            
                return new runtime.JSONApiResponse(response, (jsonValue) => BackgroundJobResponseFromJSON(jsonValue));
            },
            mutationKey: [
                'BackgroundJobsApi',
                'scheduleEntityCopying', 
            ],
            queryKey: (requestParameters: ScheduleEntityCopyingRequest, initOverrides?: RequestInit) => ([
                    ...this.scheduleEntityCopying.mutationKey, 
                {
                body: requestParameters.body,
                }
            ]),
            query: (requestParameters: ScheduleEntityCopyingRequest, initOverrides?: RequestInit): UseQueryOptions<BackgroundJobResponse> & {queryKey: QueryKey} => ({
                queryFn: () => this.scheduleEntityCopying(requestParameters, initOverrides),
                queryKey: this.scheduleEntityCopying.queryKey(requestParameters, initOverrides)
            })
        }
    )

    /**
     * Schedule the population (or recalculation) of footfall overviews for one or more localities.
     */
    scheduleFootfallOverviewPopulation = Object.assign(
        async (requestParameters: ScheduleFootfallOverviewPopulationRequest, initOverrides?: RequestInit): Promise<BackgroundJobBatchResponse> => {
            const response = await this.scheduleFootfallOverviewPopulation.invokeRaw(requestParameters, initOverrides);
            return await response.value();
        },
        {
            invokeRaw: async (requestParameters: ScheduleFootfallOverviewPopulationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<BackgroundJobBatchResponse>> => {
                if (requestParameters.body === null || requestParameters.body === undefined) {
                    throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling scheduleFootfallOverviewPopulation.');
                }
            
                const queryParameters: any = {};
            
                const headerParameters: runtime.HTTPHeaders = {};
            
                headerParameters['Content-Type'] = 'application/json';
            
                if (this.configuration && this.configuration.accessToken) {
                    const token = this.configuration.accessToken;
                    const tokenString = await token("token", []);
            
                    if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                    }
                }
                const response = await this.request({
                    path: `/api/background-jobs/populate-footfall-overview`,
                    method: 'POST',
                    headers: headerParameters,
                    query: queryParameters,
                    body: OverviewPopulationRequestBodyToJSON(requestParameters.body),
                }, initOverrides);
            
                return new runtime.JSONApiResponse(response, (jsonValue) => BackgroundJobBatchResponseFromJSON(jsonValue));
            },
            mutationKey: [
                'BackgroundJobsApi',
                'scheduleFootfallOverviewPopulation', 
            ],
            queryKey: (requestParameters: ScheduleFootfallOverviewPopulationRequest, initOverrides?: RequestInit) => ([
                    ...this.scheduleFootfallOverviewPopulation.mutationKey, 
                {
                body: requestParameters.body,
                }
            ]),
            query: (requestParameters: ScheduleFootfallOverviewPopulationRequest, initOverrides?: RequestInit): UseQueryOptions<BackgroundJobBatchResponse> & {queryKey: QueryKey} => ({
                queryFn: () => this.scheduleFootfallOverviewPopulation(requestParameters, initOverrides),
                queryKey: this.scheduleFootfallOverviewPopulation.queryKey(requestParameters, initOverrides)
            })
        }
    )

    /**
     * Schedule the population (or recalculation) of statistics for one or more localities.
     */
    scheduleStatisticsPopulation = Object.assign(
        async (requestParameters: ScheduleStatisticsPopulationRequest, initOverrides?: RequestInit): Promise<BackgroundJobBatchResponse> => {
            const response = await this.scheduleStatisticsPopulation.invokeRaw(requestParameters, initOverrides);
            return await response.value();
        },
        {
            invokeRaw: async (requestParameters: ScheduleStatisticsPopulationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<BackgroundJobBatchResponse>> => {
                if (requestParameters.body === null || requestParameters.body === undefined) {
                    throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling scheduleStatisticsPopulation.');
                }
            
                const queryParameters: any = {};
            
                const headerParameters: runtime.HTTPHeaders = {};
            
                headerParameters['Content-Type'] = 'application/json';
            
                if (this.configuration && this.configuration.accessToken) {
                    const token = this.configuration.accessToken;
                    const tokenString = await token("token", []);
            
                    if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                    }
                }
                const response = await this.request({
                    path: `/api/background-jobs/populate-statistics`,
                    method: 'POST',
                    headers: headerParameters,
                    query: queryParameters,
                    body: StatisticsRecalculationRequestBodyToJSON(requestParameters.body),
                }, initOverrides);
            
                return new runtime.JSONApiResponse(response, (jsonValue) => BackgroundJobBatchResponseFromJSON(jsonValue));
            },
            mutationKey: [
                'BackgroundJobsApi',
                'scheduleStatisticsPopulation', 
            ],
            queryKey: (requestParameters: ScheduleStatisticsPopulationRequest, initOverrides?: RequestInit) => ([
                    ...this.scheduleStatisticsPopulation.mutationKey, 
                {
                body: requestParameters.body,
                }
            ]),
            query: (requestParameters: ScheduleStatisticsPopulationRequest, initOverrides?: RequestInit): UseQueryOptions<BackgroundJobBatchResponse> & {queryKey: QueryKey} => ({
                queryFn: () => this.scheduleStatisticsPopulation(requestParameters, initOverrides),
                queryKey: this.scheduleStatisticsPopulation.queryKey(requestParameters, initOverrides)
            })
        }
    )

}
