import { DateTime } from 'luxon'
import React, { createContext, useContext, useMemo, useState, useCallback } from 'react'
import { AlertProps } from 'react-bootstrap'

interface Notification {
    id?: number
    title: string
    content: React.ReactNode
    variant: AlertProps['variant']
    timeoutSeconds?: number
}

const defaultNotification = {
    title: '',
    content: '',
    variant: 'primary' as AlertProps['variant'],
}

interface NotificationsContextShape {
    notifications: Array<Notification>
    addNotification: (notification: Notification) => Notification
    removeNotification: (notification: Notification) => void
}

export const notificationsContext = createContext<NotificationsContextShape>({
    notifications: [],
    addNotification: () => defaultNotification,
    removeNotification: () => {},
})

export const Provider = (props: React.PropsWithChildren<Record<string, unknown>>) => {
    const [notifications, setNotifications] = useState<Notification[]>([])

    const addNotification = useCallback((notification: Notification) => {
        const id = DateTime.utc().valueOf()
        const notificationWithId = { id, ...notification }
        setNotifications((prevState) => [...prevState, { id, ...notification }])

        if (notification.timeoutSeconds !== undefined) {
            setTimeout(
                () => setNotifications((prevState) => prevState.filter((it) => it.id !== id)),
                notification.timeoutSeconds * 1000
            )
        }

        return notificationWithId
    }, [])

    const contextValue = useMemo(() => {
        return {
            notifications,
            addNotification,
            removeNotification: (notification: Notification) => {
                setNotifications((prevState) => prevState.filter((it) => it.id !== notification.id))
            },
        }
    }, [notifications, addNotification])

    return <notificationsContext.Provider value={contextValue}>{props.children}</notificationsContext.Provider>
}

export const useNotify = () => useContext(notificationsContext).addNotification
