import { uniqueId } from 'lodash'
import { DateTime } from 'luxon'
import React, { useState } from 'react'
import { useMemo } from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import { EntityVisitRecordClassificationEnum, EntityVisitRecord, VisitBoundary, SceneDescription } from '@api/models'
import { Point } from '@api/models'

import { useLocalizeDateTime } from '@helpers/timezoneConfig'
import { Size } from '@helpers/types'

import { parseDate } from '@components/plots/common'

import styles from './EntityTrajectoryVisualizer.module.scss'

const VisualizationOverlay: React.FC<{
    entities: EntityVisitRecord[]
    boundaries: VisitBoundary[]
    size: Size
    onSelectionUpdate: (trajectoryIds: number[]) => void
}> = (props) => {
    const { t } = useTranslation()

    const [highlightedEntity, setHighlightedEntity] = useState<EntityVisitRecord>()
    const tooltipId = useMemo(uniqueId, [])
    const localize = useLocalizeDateTime()
    const [selectionStart, setSelectionStart] = useState<Point | undefined>(undefined)
    const [selectionEnd, setSelectionEnd] = useState<Point | undefined>(undefined)
    const [isMouseDown, setIsMouseDown] = useState<boolean>(false)

    const isSelected = (record: EntityVisitRecord) => {
        return (
            selectionStart &&
            selectionEnd &&
            record.trajectory.filter((pt) => {
                return (
                    pt.x > Math.min(selectionStart!.x, selectionEnd!.x) &&
                    pt.y > Math.min(selectionStart!.y, selectionEnd.y) &&
                    pt.x < Math.max(selectionEnd!.x, selectionStart.x) &&
                    pt.y < Math.max(selectionEnd!.y, selectionStart.y)
                )
            }).length === record.trajectory.length
        )
    }

    return (
        <svg
            height={props.size.height}
            width={props.size.width}
            onMouseDown={(evt) => {
                const rect = evt.currentTarget.getBoundingClientRect()
                setSelectionStart({
                    x: evt.clientX - rect.x,
                    y: evt.clientY - rect.y,
                })
                setSelectionEnd(undefined)
                setIsMouseDown(true)
            }}
            onMouseMove={(evt) => {
                if (isMouseDown) {
                    const rect = evt.currentTarget.getBoundingClientRect()
                    setSelectionEnd({
                        x: evt.clientX - rect.x,
                        y: evt.clientY - rect.y,
                    })
                }
            }}
            onMouseUp={() => {
                props.onSelectionUpdate(
                    props.entities
                        .filter((entity) => {
                            return isSelected(entity)
                        })
                        .map((entity) => entity.globalEntityId)
                )
                setIsMouseDown(false)
            }}
        >
            {props.entities.map((entity) => {
                const trajectorySelected = isSelected(entity)

                return (
                    <>
                        <OverlayTrigger
                            key={entity.entityId}
                            overlay={
                                <Tooltip id={tooltipId}>
                                    <dl>
                                        <dt>{t('tooltip.appearedAt', 'Appeared at')}</dt>
                                        <dd>
                                            {localize(parseDate(entity.startedAt)).toLocaleString(
                                                DateTime.DATETIME_SHORT_WITH_SECONDS
                                            )}
                                        </dd>
                                        <dt>{t('tooltip.disappearedAt', 'Disappeared at')}</dt>
                                        <dd>
                                            {localize(parseDate(entity.endedAt)).toLocaleString(
                                                DateTime.DATETIME_SHORT_WITH_SECONDS
                                            )}
                                        </dd>
                                        <dt>{t('tooltip.age', 'Age')}</dt>
                                        <dd>{entity.age ?? '-'}</dd>
                                        <dt>{t('tooltip.maleProbability', 'Male probability')}</dt>
                                        <dd>{entity.isMale?.toFixed(2) ?? '-'}</dd>
                                        <dt>{t('tooltip.emotionScore', 'Emotion score')}</dt>
                                        <dd>{entity.emotionScore?.toFixed(2) ?? '-'}</dd>
                                    </dl>
                                </Tooltip>
                            }
                        >
                            <path
                                d={entity.trajectory
                                    .map((point, i) => `${i === 0 ? 'M' : 'L'} ${point.x},${point.y}`)
                                    .join('\n')}
                                fill="none"
                                stroke={
                                    trajectorySelected
                                        ? 'purple'
                                        : entity.classification === EntityVisitRecordClassificationEnum.Incoming
                                        ? 'green'
                                        : entity.classification === EntityVisitRecordClassificationEnum.Outgoing
                                        ? 'red'
                                        : 'yellow'
                                }
                                strokeOpacity={
                                    highlightedEntity === undefined || highlightedEntity === entity ? 1 : 0.15
                                }
                                strokeWidth={2}
                                onMouseEnter={() => setHighlightedEntity(entity)}
                                onMouseLeave={() => setHighlightedEntity(undefined)}
                            />
                        </OverlayTrigger>
                        <circle
                            cx={entity.trajectory[0].x}
                            cy={entity.trajectory[0].y}
                            fill="black"
                            fillOpacity={highlightedEntity === undefined || highlightedEntity === entity ? 1 : 0.15}
                            r={3}
                        />
                    </>
                )
            })}
            {props.boundaries.map((boundary, i) => (
                <path
                    // eslint-disable-next-line
                    key={i}
                    d={boundary.points.map((point, i) => `${i === 0 ? 'M' : 'L'} ${point.x},${point.y}`).join('\n')}
                    fill="none"
                    stroke="blue"
                    strokeWidth={3}
                />
            ))}
            {selectionStart && selectionEnd && (
                <rect
                    fill="purple"
                    fillOpacity={0.2}
                    height={Math.max(selectionStart!.y, selectionEnd!.y) - Math.min(selectionStart!.y, selectionEnd!.y)}
                    width={Math.max(selectionStart!.x, selectionEnd!.x) - Math.min(selectionStart!.x, selectionEnd!.x)}
                    x={Math.min(selectionStart!.x, selectionEnd!.x)}
                    y={Math.min(selectionStart!.y, selectionEnd!.y)}
                />
            )}
        </svg>
    )
}

interface Props {
    entities: EntityVisitRecord[]
    snapshotUrl?: string
    snapshotSize?: Size
    sceneDescription: SceneDescription
    onSelectionUpdate: (trajectoryIds: number[]) => void
}

const EntityTrajectoryVisualizer: React.FC<Props> = (props) => (
    <div className={styles.editor}>
        <div>
            <img alt="" src={props.snapshotUrl} />
        </div>
        {props.snapshotSize !== undefined && (
            <div>
                <VisualizationOverlay
                    boundaries={props.sceneDescription.visitBoundaries!}
                    entities={props.entities}
                    size={props.snapshotSize}
                    onSelectionUpdate={props.onSelectionUpdate}
                />
            </div>
        )}
    </div>
)

export default EntityTrajectoryVisualizer
