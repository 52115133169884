import { faFrown, faGrin, faMeh } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import { max, noop } from 'lodash'
import { DateTime, Interval } from 'luxon'
import { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Bar, CartesianGrid, Tooltip, YAxis } from 'recharts'

import { IntervalType } from '@helpers/types'

import palette from '@components/palette.module.scss'
import Cursor from '@components/plots/Cursor'
import TimeLineChart from '@components/plots/TimeLineChart'
import TooltipContent from '@components/plots/TooltipContent'
import TooltipLabel from '@components/plots/TooltipLabel'
import TooltipTable from '@components/plots/TooltipTable'

import styles from './EmotionsDetails.module.scss'
import { TimelineEmotionDataItem } from './emotionsDetailsPreprocessors'

export const EmotionsLegend: React.FC = () => {
    const { t } = useTranslation()

    return (
        <div className={styles.emotionsLegend}>
            <div className={styles.leftItem}>
                <FontAwesomeIcon className={styles.positiveIcon} icon={faGrin} />
                {t('emotions.positiveLegendValue', 'Positive')}
            </div>
            <div className={styles.centerItem}>
                <FontAwesomeIcon className={styles.neutralIcon} icon={faMeh} />
                {t('emotions.neutralLegendValue', 'Neutral')}
            </div>
            <div className={styles.rightItem}>
                <FontAwesomeIcon className={styles.negativeIcon} icon={faFrown} />
                {t('emotions.negativeLegendValue', 'Negative')}
            </div>
        </div>
    )
}

const EmotionScoreInTimeTooltipItem: React.FC<{
    label: string
    tooltipItemType: 'positive' | 'negative' | 'neutral'
    tooltipItemValue: number
    plot: 'emotionsScoreDistribution' | 'emotionsScoreAverage'
}> = ({ tooltipItemType, tooltipItemValue, label }) => {
    const Icon = (
        <FontAwesomeIcon
            className={classNames(
                {
                    [styles.positivePlotIcon]: tooltipItemType === 'positive',
                    [styles.neutralPlotIcon]: tooltipItemType === 'neutral',
                    [styles.negativePlotIcon]: tooltipItemType === 'negative',
                },
                styles.emotionIconSmall
            )}
            icon={tooltipItemType === 'positive' ? faGrin : tooltipItemType === 'neutral' ? faMeh : faFrown}
        />
    )

    return (
        <TooltipTable.Item label={label}>
            <>
                <span className={styles.emotionScoreTitle}>
                    <strong>{tooltipItemValue}</strong>
                </span>
                {Icon}
            </>
        </TooltipTable.Item>
    )
}

const EmotionsScoreDistributionInTimePlot: React.FC<{
    emotionData: Array<TimelineEmotionDataItem>
    now: DateTime
    intervalType: IntervalType
    interval: Interval
}> = ({ emotionData, now, intervalType, interval }) => {
    const { t } = useTranslation()

    const canvasRef = useRef<HTMLCanvasElement>(null)

    const maxData =
        max(emotionData.flatMap((it) => [it.positiveObservations, it.negativeObservations, it.neutralObservations])) ??
        0

    const canvasContext = canvasRef.current?.getContext('2d')
    const axisWidth = canvasContext?.measureText((-maxData).toString()).width
    const radius = intervalType === IntervalType.DAY ? ([25, 25, 0, 0] as [number, number, number, number]) : 0

    const determinedIntervalType = interval.length('hours') <= 22 ? IntervalType.WEEK : IntervalType.MONTH

    return (
        <>
            <TimeLineChart
                canvasRef={canvasRef}
                className={styles.emotionsDistributionPlot}
                data={emotionData}
                determinedIntervalType={determinedIntervalType}
                endDate={interval.end}
                intervalType={intervalType}
                now={now}
                outages={[]}
                startDate={interval.start}
                onSelectInterval={noop}
            >
                <YAxis
                    allowDecimals={false}
                    domain={[0, 1.1 * maxData]}
                    tick={(tickProps) => {
                        return (
                            <g transform={`translate(${tickProps.x},${tickProps.y})`}>
                                <text className="small" fill={palette.darkgrey} textAnchor="end" x={-4} y={4}>
                                    {tickProps.payload.value}
                                </text>
                            </g>
                        )
                    }}
                    ticks={[0, Math.round(maxData / 2), maxData]}
                    width={Math.ceil(axisWidth ?? 30) + 6}
                />
                <Bar
                    dataKey="positiveObservations"
                    fill={palette.emotionsPlotPositive}
                    radius={radius}
                    stroke={palette.emotionsPlotPositive}
                    strokeWidth={2}
                />
                <Bar
                    dataKey="neutralObservations"
                    fill={palette.emotionsPlotNeutral}
                    radius={radius}
                    stroke={palette.emotionsPlotNeutral}
                    strokeWidth={2}
                />
                <Bar
                    dataKey="negativeObservations"
                    fill={palette.emotionsPlotNegative}
                    radius={radius}
                    stroke={palette.emotionsPlotNegative}
                    strokeWidth={2}
                />
                <CartesianGrid strokeOpacity={0.3} strokeWidth={1} vertical={false} />
                <Tooltip
                    content={
                        <TooltipContent
                            intervalResolver={(label) => emotionData[Number(label)].interval}
                            labelFormatter={(label) => label}
                            now={now}
                            outages={[]}
                        >
                            {(label) => {
                                const record = emotionData.find((it) => it.name === label)!

                                return (
                                    <TooltipTable>
                                        <EmotionScoreInTimeTooltipItem
                                            label={t('statistics.numberOfPositiveFaces', 'Number of positive faces')}
                                            plot="emotionsScoreDistribution"
                                            tooltipItemType="positive"
                                            tooltipItemValue={record.positiveObservations}
                                        />
                                        <EmotionScoreInTimeTooltipItem
                                            label={t('statistics.numberOfNeutralFaces', 'Number of neutral faces')}
                                            plot="emotionsScoreDistribution"
                                            tooltipItemType="neutral"
                                            tooltipItemValue={record.neutralObservations}
                                        />
                                        <EmotionScoreInTimeTooltipItem
                                            label={t('statistics.numberOfNegativeFaces', 'Number of negative faces')}
                                            plot="emotionsScoreDistribution"
                                            tooltipItemType="negative"
                                            tooltipItemValue={record.negativeObservations}
                                        />
                                    </TooltipTable>
                                )
                            }}
                        </TooltipContent>
                    }
                    cursor={<Cursor dataLength={emotionData.length} />}
                    filterNull={false}
                    labelFormatter={(index) => {
                        const item = emotionData[parseInt(index as string, 10)]

                        if (item === undefined) {
                            return
                        }

                        const interval = item.interval

                        return (
                            <TooltipLabel
                                endDate={interval.end}
                                interval={interval}
                                now={now}
                                startDate={interval.start}
                            />
                        )
                    }}
                />
            </TimeLineChart>
        </>
    )
}

export default EmotionsScoreDistributionInTimePlot
