import { faEllipsisV, faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import { LocationDescriptor } from 'history'
import { Dictionary, isEmpty, pickBy, uniqueId } from 'lodash'
import React, { useMemo, useState } from 'react'
import { Button, Card, OverlayTrigger, Popover } from 'react-bootstrap'
import { TFunction, useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { OrganizationFeatures, OrganizationResponse, UserResponse } from '@api/models'

import {
    generateEditOrganizationPath,
    generateOrganizationEditUserPath,
    OrganizationEditPageTabs,
} from '@helpers/VividiURLs'
import { useAddBackLink } from '@helpers/backlinks'
import { NumberFormat } from '@helpers/types'

import tableStyles from '@elements/Table/Table.module.scss'
import TableWithSorting, { CellContent, HeaderCell } from '@elements/Table/TableWithSorting'
import ToggleIcon from '@elements/ToggleIcon/ToggleIcon'

import BadgeList from './BadgeList'
import LiveIndicator from './LiveIndicator/LiveIndicator'
import { FrontendModules } from './Navbar/NavbarView'
import styles from './OrganizationList.module.scss'
import PopConfirm from './PopConfirm/PopConfirm'
import tooltipStyles from './TooltipIcon.module.scss'
import UserRoleIndicator from './UserRoleIndicator'

interface Props {
    organizations: Array<
        OrganizationResponse & {
            localitiesCount: number
            devicesCount: number
            scenesCount: number
            users: Array<UserResponse>
            filteredUserIndexes: Array<number>
        }
    >
    onDelete: (organization: OrganizationResponse) => void
    filteredIndexes: Array<number>
    isUserSearchActive: boolean
}

const convertFeaturesIntoModuleNames = (features: OrganizationFeatures, t: TFunction) =>
    Object.keys(pickBy(features, (value) => value))
        .map((feature) => {
            switch (feature) {
                case 'footfall':
                    return t('button.footfall', FrontendModules.FOOTFALL)
                case 'zoneOccupancy':
                    return t('button.occupancy', FrontendModules.OCCUPANCY)
                case 'realtimeOccupancy':
                    return t('realTime.title', FrontendModules.REALTIME)
                case 'queueMonitoring':
                    return t('navbar.queues', FrontendModules.QUEUE)
                case 'conversions':
                    return t('navbar.conversions', FrontendModules.CONVERSION)
                case 'emotions':
                    return t('button.emotions', FrontendModules.EMOTIONS)
                case 'floorplans':
                    return t('navbar.heatmaps', 'Heatmaps')
                default:
                    return ''
            }
        })
        .sort((a, b) => a.localeCompare(b))

const NumericLinkCell: React.FC<{
    valueType: NumberFormat
    explanatoryString?: string
    targetLink?: string | LocationDescriptor
    className?: string
}> = ({ explanatoryString, targetLink, className }) => (
    <td className={className}>
        {targetLink && (
            <Link className={styles.linkCell} to={targetLink}>
                {explanatoryString}
            </Link>
        )}
    </td>
)

const TextLinkCell: React.FC<{
    text: string
    targetLink?: string | LocationDescriptor
    className?: string
}> = ({ text, targetLink, className }) => (
    <td className={className}>
        {targetLink ? (
            <Link className={styles.linkCell} to={targetLink}>
                {text}
            </Link>
        ) : (
            text
        )}
    </td>
)

const IndicatorCell: React.FC<{
    isMonitoringEnabled?: boolean
    t: TFunction
}> = ({ isMonitoringEnabled, t }) => {
    const tooltipId = useMemo(uniqueId, [])

    const TooltipBody = (
        <Popover className={tooltipStyles.overlayPopover} id={tooltipId}>
            <Popover.Content>
                {isMonitoringEnabled ? t('button.enabled', 'Enabled') : t('disabled', 'Disabled')}
            </Popover.Content>
        </Popover>
    )

    return (
        <td className={classNames(styles.monitoringCell, tableStyles.topDownPadding, tableStyles.hiddenOnMobile)}>
            <OverlayTrigger overlay={TooltipBody} placement="bottom" trigger={['hover', 'focus']}>
                <LiveIndicator disable={!isMonitoringEnabled} freeze={true} hideTitle={true} />
            </OverlayTrigger>
        </td>
    )
}

const ActionCell: React.FC<{
    onDelete: () => void
    organizationName: string
}> = ({ onDelete, organizationName }) => {
    const { t } = useTranslation()
    const tooltipId = useMemo(uniqueId, [])

    const [isPickerOpen, setIsPickerOpen] = useState<boolean>(false)

    return (
        <td className={tableStyles.hiddenOnMobile}>
            <OverlayTrigger
                overlay={
                    <Popover id={tooltipId}>
                        <Popover.Content className={styles.dropDown}>
                            <PopConfirm
                                cancelButtonVariant="secondary"
                                confirmButtonVariant="danger"
                                confirmMessage={t(
                                    'others.deleteOrganization',
                                    'Are you sure you want to delete organization {{value}}?',
                                    { value: organizationName }
                                )}
                                onConfirm={onDelete}
                            >
                                <Button variant="link danger">
                                    <FontAwesomeIcon icon={faTrash} />
                                    {t('button.delete', 'Delete')}
                                </Button>
                            </PopConfirm>
                        </Popover.Content>
                    </Popover>
                }
                placement="bottom"
                rootClose={true}
                show={isPickerOpen}
                trigger={['click']}
                onToggle={setIsPickerOpen}
            >
                <ToggleIcon
                    icon={faEllipsisV}
                    iconContainerClass={styles.actionButtonContainer}
                    selected={isPickerOpen}
                    tooltipText={t('table.actions', 'Actions')}
                    onToggle={() => setIsPickerOpen((prev) => !prev)}
                />
            </OverlayTrigger>
        </td>
    )
}

const OrganizationList: React.FC<Props> = ({ organizations, onDelete, filteredIndexes, isUserSearchActive }) => {
    const { t } = useTranslation()
    const addBackLink = useAddBackLink()

    const body: Array<Dictionary<CellContent>> = organizations.map(
        ({
            name,
            id,
            isMonitoringEnabled,
            isLocalitySummaryEnabled,
            localitiesCount,
            devicesCount,
            scenesCount,
            users,
            features,
            ownerId,
        }) => ({
            organization: {
                content: name,
                cellType: 'text',
                renderer: TextLinkCell,
                targetLink: addBackLink(generateEditOrganizationPath(id, OrganizationEditPageTabs.localities)),
            },
            localities: {
                content: localitiesCount,
                cellType: 'numeric',
                numberFormat: 'count',
                renderer: NumericLinkCell,
                explanatoryString: t('table.localitiesInTable', '{{value}} localities', { value: localitiesCount }),
                targetLink: generateEditOrganizationPath(id, OrganizationEditPageTabs.localities),
            },
            scenes: {
                content: scenesCount,
                cellType: 'numeric',
                numberFormat: 'count',
                renderer: NumericLinkCell,
                explanatoryString: t('table.scenesInTable', '{{value}} scenes', { value: scenesCount }),
                targetLink: addBackLink(generateEditOrganizationPath(id, OrganizationEditPageTabs.scenes)),
            },
            devices: {
                content: devicesCount,
                cellType: 'numeric',
                numberFormat: 'count',
                renderer: NumericLinkCell,
                explanatoryString: t('table.devicesInTable', '{{value}} devices', { value: devicesCount }),
                targetLink: addBackLink(generateEditOrganizationPath(id, OrganizationEditPageTabs.devices)),
            },
            users: {
                content: users.length,
                cellType: 'numeric',
                numberFormat: 'count',
                renderer: NumericLinkCell,
                explanatoryString: t('table.usersInTable', '{{value}} users', { value: users.length }),
                targetLink: generateEditOrganizationPath(id, OrganizationEditPageTabs.users),
            },
            monitoring: {
                cellType: 'rawElement',
                align: 'center',
                element: <IndicatorCell key={`indicator-${id}`} isMonitoringEnabled={isMonitoringEnabled} t={t} />,
            },
            modulesEnabled: {
                cellType: 'rawElement',
                element: (
                    <td key={`badgeList-${id}`} className={(tableStyles.topDownPadding, tableStyles.hiddenOnMobile)}>
                        <BadgeList
                            badgeVariant="primary"
                            displayType="grid"
                            items={convertFeaturesIntoModuleNames(features, t)}
                            maxDisplayed={4}
                        />
                    </td>
                ),
                targetLink: addBackLink(generateEditOrganizationPath(id, OrganizationEditPageTabs.modules)),
            },
            actions: {
                cellType: 'rawElement',
                element: (
                    <ActionCell
                        key={`action-key-${id}`}
                        organizationName={name}
                        onDelete={() =>
                            onDelete({ name, isMonitoringEnabled, isLocalitySummaryEnabled, id, ownerId, features })
                        }
                    />
                ),
            },
        })
    )

    const head: Array<HeaderCell> = [
        {
            name: 'organization',
            displayName: t('form.organization', 'Organization'),
        },
        {
            name: 'localities',
            displayName: t('table.localities', 'Localities'),
        },
        {
            name: 'scenes',
            displayName: t('table.scenes', 'Scenes'),
            align: 'left',
        },
        {
            name: 'devices',
            displayName: t('table.devices', 'Devices'),
            align: 'left',
        },
        {
            name: 'users',
            displayName: t('form.users', 'Users'),
            align: 'left',
        },
        {
            name: 'monitoring',
            displayName: t('heading.monitoringConfiguration', 'Monitoring'),
            align: 'center',
        },
        {
            name: 'modulesEnabled',
            displayName: t('table.modulesEnabled', 'Modules enabled'),
            align: 'left',
        },
        { name: 'actions', displayName: '', align: 'right' },
    ]

    const bodyRows = useMemo(() => body, [organizations])

    const addonRows = organizations.map(({ users, filteredUserIndexes }) => ({
        items: users.map((user) => (
            <Link
                key={user.email}
                className={styles.userLink}
                to={generateOrganizationEditUserPath(user.id, user.organizationId)}
            >
                <UserRoleIndicator user={user} /> <span className={styles.text}>{user.email}</span>
            </Link>
        )),
        filteredIndexes: isUserSearchActive ? filteredUserIndexes : [],
    }))

    return !isEmpty(organizations) ? (
        <Card>
            <Card.Body>
                <TableWithSorting
                    addonRows={addonRows}
                    bodyRows={bodyRows}
                    defaultSortingColumn="organization"
                    defaultSortingOrder="asc"
                    excludedSortingColumns={['monitoring', 'modulesEnabled', 'actions']}
                    filteredIndexes={filteredIndexes}
                    headRow={head}
                    mobileIncludedColumns={['organization', 'localities', 'devices', 'users']}
                    paginationSize={100}
                />
            </Card.Body>
        </Card>
    ) : (
        <p className="lead">{t('others.noOrganizations', 'No organizations')}</p>
    )
}

export default OrganizationList
