/* tslint:disable */
/* eslint-disable */
/**
 * Vividi Dashboard API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Scope,
    ScopeFromJSON,
    ScopeFromJSONTyped,
    ScopeToJSON,
} from './Scope';

/**
 * 
 * @export
 * @interface AuthenticationRequestBody
 */
export interface AuthenticationRequestBody {
    /**
     * 
     * @type {string}
     * @memberof AuthenticationRequestBody
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof AuthenticationRequestBody
     */
    password: string;
    /**
     * 
     * @type {Array<Scope>}
     * @memberof AuthenticationRequestBody
     */
    scopes?: Array<Scope>;
}

export function AuthenticationRequestBodyFromJSON(json: any): AuthenticationRequestBody {
    return AuthenticationRequestBodyFromJSONTyped(json, false);
}

export function AuthenticationRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): AuthenticationRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'email': json['email'],
        'password': json['password'],
        'scopes': !exists(json, 'scopes') ? undefined : ((json['scopes'] as Array<any>).map(ScopeFromJSON)),
    };
}

export function AuthenticationRequestBodyToJSON(value?: AuthenticationRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'email': value.email,
        'password': value.password,
        'scopes': value.scopes === undefined ? undefined : ((value.scopes as Array<any>).map(ScopeToJSON)),
    };
}

