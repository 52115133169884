/* tslint:disable */
/* eslint-disable */
/**
 * Vividi Dashboard API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OccupancyConfiguration
 */
export interface OccupancyConfiguration {
    /**
     * How long does a zone have to be not-occupied to break a session.
     * @type {number}
     * @memberof OccupancyConfiguration
     */
    minBreakDuration: number;
    /**
     * How long does a zone have to be occupied to start a session.
     * @type {number}
     * @memberof OccupancyConfiguration
     */
    minSessionDuration: number;
    /**
     * Occupancy zone ids.
     * @type {Array<number>}
     * @memberof OccupancyConfiguration
     */
    occupancyZones: Array<number>;
}

export function OccupancyConfigurationFromJSON(json: any): OccupancyConfiguration {
    return OccupancyConfigurationFromJSONTyped(json, false);
}

export function OccupancyConfigurationFromJSONTyped(json: any, ignoreDiscriminator: boolean): OccupancyConfiguration {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'minBreakDuration': json['min_break_duration'],
        'minSessionDuration': json['min_session_duration'],
        'occupancyZones': json['occupancy_zones'],
    };
}

export function OccupancyConfigurationToJSON(value?: OccupancyConfiguration | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'min_break_duration': value.minBreakDuration,
        'min_session_duration': value.minSessionDuration,
        'occupancy_zones': value.occupancyZones,
    };
}

