/* tslint:disable */
/* eslint-disable */
/**
 * Vividi Dashboard API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PropertyBreakdownItem,
    PropertyBreakdownItemFromJSON,
    PropertyBreakdownItemFromJSONTyped,
    PropertyBreakdownItemToJSON,
} from './PropertyBreakdownItem';

/**
 * 
 * @export
 * @interface BreakdownItem
 */
export interface BreakdownItem {
    /**
     * 
     * @type {string}
     * @memberof BreakdownItem
     */
    startingFrom: string;
    /**
     * 
     * @type {string}
     * @memberof BreakdownItem
     */
    endingAt: string;
    /**
     * 
     * @type {Array<PropertyBreakdownItem>}
     * @memberof BreakdownItem
     */
    items: Array<PropertyBreakdownItem>;
    /**
     * Portion (0-1) of interval within opening hours
     * @type {number}
     * @memberof BreakdownItem
     */
    openedPortion: number;
}

export function BreakdownItemFromJSON(json: any): BreakdownItem {
    return BreakdownItemFromJSONTyped(json, false);
}

export function BreakdownItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): BreakdownItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'startingFrom': json['starting_from'],
        'endingAt': json['ending_at'],
        'items': ((json['items'] as Array<any>).map(PropertyBreakdownItemFromJSON)),
        'openedPortion': json['opened_portion'],
    };
}

export function BreakdownItemToJSON(value?: BreakdownItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'starting_from': value.startingFrom,
        'ending_at': value.endingAt,
        'items': ((value.items as Array<any>).map(PropertyBreakdownItemToJSON)),
        'opened_portion': value.openedPortion,
    };
}

