import { faDownload } from '@fortawesome/free-solid-svg-icons'
import React from 'react'
import { ButtonProps } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'

import { reportsApi } from '@services'

import AsyncButton from '@components/AsyncButton'
import { downloadBlob } from '@components/ConversionReportList/DownloadButton'

interface Props {
    reportId: number
    localityId: number
    text?: string
    size?: ButtonProps['size']
}

const DownloadButton: React.FC<Props> = ({ reportId, localityId, text, size }) => {
    const { t } = useTranslation()

    const { mutate: downloadCall, status } = useMutation(reportsApi.getLocalityQueueStatisticsReportPdf.invokeRaw, {
        onSuccess: downloadBlob,
    })

    return (
        <AsyncButton
            allowRefetch={true}
            icon={faDownload}
            size={size}
            status={status}
            text={text ?? t('button.downloadPDF', 'Download PDF')}
            onClick={() =>
                downloadCall({
                    reportId,
                    localityId,
                })
            }
        />
    )
}

export default DownloadButton
