import React from 'react'
import { Col, ColProps, InputGroup } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import { subtractPhoneNumberCountryCode } from '@helpers/phoneNumber'

import { PhoneNumberErrors } from '@components/UserForm/UserEditForm'

import styles from './LocalityPickerInput.module.scss'
import TextInput from './TextInput'

type Visuals = {
    labelBoxMd: ColProps['md']
    phoneNumberBoxMd: ColProps['md']
}

type PhoneNumberInputProps = {
    label?: string
    phoneNumber?: string
    countryCode?: string
    errors: PhoneNumberErrors
    onUpdate: (value: string) => void
} & Visuals

const PhoneNumberInput: React.FC<PhoneNumberInputProps> = ({
    label,
    phoneNumber,
    countryCode,
    errors,
    onUpdate,
    labelBoxMd,
    phoneNumberBoxMd,
}) => {
    const { t } = useTranslation()

    return (
        <>
            <Col className={styles.label} md={labelBoxMd} sm={12}>
                {label ?? t('form.phoneNumber', 'Phone number')}
            </Col>
            <Col md={phoneNumberBoxMd} sm={12}>
                <InputGroup>
                    {countryCode && (
                        <InputGroup.Prepend>
                            <InputGroup.Text>{countryCode}</InputGroup.Text>
                        </InputGroup.Prepend>
                    )}
                    <TextInput
                        className={styles.responsiveInput}
                        isInvalid={errors.phoneNumber.isTouched && errors.phoneNumber.isInvalid}
                        value={subtractPhoneNumberCountryCode(phoneNumber) ?? ''}
                        onChange={onUpdate}
                    />
                </InputGroup>
            </Col>
        </>
    )
}

export default PhoneNumberInput
