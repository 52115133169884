import { faBell, faKey, faSignInAlt, faTrash, IconDefinition } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import { isEmpty, merge } from 'lodash'
import React, { useEffect, useMemo, useState } from 'react'
import { Button, ButtonGroup, Col, Form, Modal, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { MutationStatus, useMutation, useQuery, UseQueryResult } from 'react-query'
import { useParams } from 'react-router'

import {
    LocalityNameModel,
    OrganizationResponse,
    ProfileResponse,
    Role,
    StaySafeNotificationPreferences,
    UserModel,
    UserNotificationPreferences,
    UserResponse,
} from '@api'

import { userApi } from '@services'

import { GoogleEventCategory, GoogleEventName, trackEvent } from '@helpers/gtag'
import { determinePhoneNumberCountryCode, subtractPhoneNumberCountryCode } from '@helpers/phoneNumber'
import { defaultErrorEntry, ErrorEntry } from '@helpers/types'
import { isEmailValid, isPhoneNumberWithoutCountryCodeValid } from '@helpers/validations'

import LocalityPickerInput, {
    LocalityPickerLabel,
    LocalityTokenRenderer,
} from '@components/GenericInputs/LocalityPickerInput'
import PhoneNumberInput from '@components/GenericInputs/PhoneNumberInput'
import TextInput from '@components/GenericInputs/TextInput'
import LegacyLoadingWrapper from '@components/LegacyLoadingWrapper'
import {
    initialNotificationErrorState,
    initialNotificationPreferences,
    NotificationErrors,
} from '@components/NotificationPreferencesForm'
import styles from '@components/OrganizationEditForms/UsersTab/UsersTabForm.module.scss'
import PopConfirm from '@components/PopConfirm/PopConfirm'
import RoleChecker from '@components/RoleChecker'
import SetNotificationsForm from '@components/SetNotificationsForm'
import SetPasswordForm from '@components/SetPasswordForm'
import { useNotify } from '@components/notifications/NotificationsContext'

export interface UserEditData {
    user: UserModel
    role: Role
    localities: Array<LocalityNameModel>
    sendInvitation?: boolean
}

interface UserActionModalProps {
    localities: Array<LocalityNameModel>
    organization: OrganizationResponse
    onNotificationPreferencesUpdate: (staySafeNotifications: UserNotificationPreferences) => void
    onPasswordChange: (password: string) => void
    buttonText: string
    icon: IconDefinition
    submissionStatus: MutationStatus
    modalType: 'password' | 'notifications'
    notifications?: UserNotificationPreferences
}

export interface UsersEditFormErrors {
    phoneNumber: ErrorEntry
    email: ErrorEntry
}

const UserActionModal: React.FC<UserActionModalProps> = ({
    onNotificationPreferencesUpdate,
    onPasswordChange,
    buttonText,
    icon,
    submissionStatus,
    modalType,
    notifications,
    organization,
    localities,
}) => {
    const notify = useNotify()
    const { t } = useTranslation()

    const params = useParams<{ localityId?: string }>()

    const localityId = params.localityId ? parseInt(params.localityId, 10) : undefined

    const [isOpen, setIsOpen] = useState(false)

    const [pickedLocality, setPickedLocality] = useState<LocalityNameModel | undefined>(
        localityId ? localities.find((l) => l.id === localityId) : undefined
    )

    const [editedStaySafeSubscription, setEditedStaySafeSubscription] = useState<
        StaySafeNotificationPreferences | undefined
    >(undefined)

    const [errors, setErrors] = useState<NotificationErrors>(initialNotificationErrorState)

    const userStaySafeSubscriptions = useMemo(
        () =>
            localities.map(({ id }) => {
                const localityNotificationPreferences = notifications?.staySafe.find(
                    ({ localityId }) => localityId === id
                )

                return localityNotificationPreferences
                    ? { ...localityNotificationPreferences }
                    : { ...initialNotificationPreferences, localityId: id }
            }),
        [localities]
    )

    const handleOpenModal = () => {
        setIsOpen(true)
    }

    const handleLocalitySelect = (localityId: number) => {
        setPickedLocality(localities.find(({ id }) => id === localityId))
        setEditedStaySafeSubscription(userStaySafeSubscriptions.find(({ localityId: id }) => localityId === id))
        setErrors(initialNotificationErrorState)
    }

    const handleEditedSubscription = (notificationPreferences: StaySafeNotificationPreferences) => {
        setEditedStaySafeSubscription(notificationPreferences)
    }

    const handleSaveNotificationPreferences = () => {
        const hasErrors = Object.values(errors).some((it) => it.isInvalid)

        if (editedStaySafeSubscription && hasErrors) {
            notify({
                variant: 'danger',
                title: t('notification.validationFailed', 'Validation failed'),
                content: t('notification.invalidFields', 'Some of the fields are not filled in correctly'),
            })

            return
        }

        if (editedStaySafeSubscription && !hasErrors && editedStaySafeSubscription.localityId !== -1) {
            const updatedNotifications = merge(notifications?.staySafe, [editedStaySafeSubscription])
            onNotificationPreferencesUpdate({ staySafe: updatedNotifications })
            setIsOpen(false)
        }
    }

    return (
        <>
            <Modal dialogClassName={styles.usersEditDialog} show={isOpen} centered onHide={() => setIsOpen(false)}>
                <Modal.Header className={styles.header}>
                    <strong>{buttonText}</strong>
                </Modal.Header>
                <Modal.Body>
                    {modalType === 'password' && (
                        <SetPasswordForm
                            askCurrentPassword={false}
                            submissionStatus={submissionStatus}
                            isCompact
                            onCancel={() => setIsOpen(false)}
                            onSubmit={(updatedPassword) => {
                                onPasswordChange(updatedPassword)
                                setIsOpen(false)
                            }}
                        />
                    )}
                    {modalType === 'notifications' && notifications && (
                        <SetNotificationsForm
                            currentValues={
                                editedStaySafeSubscription &&
                                editedStaySafeSubscription.localityId === pickedLocality?.id
                                    ? editedStaySafeSubscription
                                    : pickedLocality && editedStaySafeSubscription?.localityId !== pickedLocality.id
                                    ? userStaySafeSubscriptions.find(
                                          ({ localityId }) => localityId === pickedLocality?.id
                                      )
                                    : initialNotificationPreferences
                            }
                            disabled={!pickedLocality || isEmpty(localities)}
                            errors={
                                editedStaySafeSubscription &&
                                editedStaySafeSubscription.localityId === pickedLocality?.id
                                    ? errors
                                    : initialNotificationErrorState
                            }
                            handleLocalitySelect={handleLocalitySelect}
                            localities={localities}
                            organizations={[organization]}
                            pickedLocality={pickedLocality}
                            submissionStatus={submissionStatus}
                            t={t}
                            onCancel={() => setIsOpen(false)}
                            onErrors={setErrors}
                            onSubmit={handleSaveNotificationPreferences}
                            onUpdate={handleEditedSubscription}
                        />
                    )}
                </Modal.Body>
            </Modal>
            <Button variant="secondary" onClick={handleOpenModal}>
                <FontAwesomeIcon icon={icon} />
                {buttonText}
            </Button>
        </>
    )
}

interface EmailRowProps {
    email: string
    isNewUser?: boolean
    errors: UsersEditFormErrors
    onUpdate: (value: string) => void
}

const EmailRow: React.FC<EmailRowProps> = ({ email, isNewUser, errors, onUpdate }) => {
    const { t } = useTranslation()

    const { isTouched, isInvalid } = errors.email

    return (
        <Form.Group as={Row} className={classNames({ [styles.emailRow]: !isNewUser })}>
            <Col className={styles.emailLabel} md={3}>
                {t('form.email', 'E-mail')}
            </Col>
            <Col md={9}>
                {!isNewUser && <strong>{email}</strong>}
                {isNewUser && (
                    <TextInput
                        className={styles.input}
                        isInvalid={isTouched && isInvalid}
                        value={email ?? ''}
                        onChange={onUpdate}
                    />
                )}
            </Col>
        </Form.Group>
    )
}

interface RolePickerRowProps {
    role: Role
    onUpdate: (role: Role) => void
}

const RolePickerRow: React.FC<RolePickerRowProps> = ({ role, onUpdate }) => {
    const { t } = useTranslation()

    const roles = [
        { displayName: t('role.user', 'User'), apiRole: Role.User },
        { displayName: t('role.administratorShort', 'Admin'), apiRole: Role.OrganizationAdministrator },
    ]

    if (role === Role.OrganizationOwner || role === Role.Administrator) {
        const isOwner = role === Role.OrganizationOwner

        return (
            <Form.Group as={Row} className={styles.row}>
                <Col className={styles.label} md={3} sm={12}>
                    {t('role.role', 'Role')}
                </Col>
                <Col md={9}>
                    <strong>
                        {isOwner ? t('role.owner', 'Owner') : t('role.vividiAdministrator', 'Vividi Administrator')}
                    </strong>
                </Col>
            </Form.Group>
        )
    }

    return (
        <Form.Group as={Row} className={styles.row}>
            <Col className={styles.label} md={3} sm={12}>
                {t('role.role', 'Role')}
            </Col>
            <Col md={9} sm={12}>
                <ButtonGroup>
                    {roles.map(({ displayName, apiRole }) => (
                        <Button
                            key={displayName}
                            variant={apiRole === role ? 'primary' : 'secondary'}
                            onClick={() => onUpdate(apiRole)}
                        >
                            <strong>{displayName}</strong>
                        </Button>
                    ))}
                </ButtonGroup>
            </Col>
        </Form.Group>
    )
}

interface ActionsRowProps {
    user: UserEditData
    editedUser: UserResponse
    localities: Array<LocalityNameModel>
    organization: OrganizationResponse
    onTakeover: () => void
    onDelete: () => void
    saveChangesButton?: JSX.Element
    isDeletionInProgress?: boolean
}

const ActionsRow: React.FC<ActionsRowProps> = ({
    editedUser,
    onTakeover,
    user,
    onDelete,
    organization,
    localities,
    isDeletionInProgress,
}) => {
    const { t } = useTranslation()
    const notify = useNotify()

    const {
        mutate: changePassword,
        status: changePasswordState,
        reset: changePasswordStateReset,
    } = useMutation(userApi.setUserPassword, {
        onSuccess: () => {
            changePasswordStateReset()
            trackEvent(GoogleEventCategory.USER, GoogleEventName.CHANGE_PASSWORD, user.user.email, user.user.email)
            notify({
                title: t('notification.success', 'Success'),
                content: t('notification.passwordChanged', 'Password changed'),
                variant: 'success',
            })
        },
        onError: () => {
            notify({
                title: t('notification.error', 'Error'),
                content: t('notification.passwordChangedFailed', 'Password change failed'),
                variant: 'error',
            })
        },
    })

    const {
        mutate: updateNotificationPreferences,
        status: updateNotificationPreferencesState,
        reset: notificationPreferencesReset,
    } = useMutation(userApi.setNotificationPreferences, {
        onSuccess: () => {
            notificationPreferencesReset()
        },
    })

    const notificationsCall = useQuery(userApi.getNotificationPreferences.query({ userId: editedUser.id }))

    const enabledNotifications = Object.entries(organization.features).some(
        ([feature, enabled]) => feature === 'staySafeNotifier' && enabled
    )

    return (
        <>
            <Form.Group as={Row} className={styles.actionsRow}>
                <Col className={styles.label} md={3} sm={12}>
                    {t('table.actions', 'Actions')}
                </Col>
                <Col className={styles.actionsColumn} md={9}>
                    <RoleChecker whitelist={[Role.Administrator]}>
                        <Button variant="secondary" onClick={onTakeover}>
                            <FontAwesomeIcon icon={faSignInAlt} />
                            {t('button.loginAs', 'Login as')}
                        </Button>
                    </RoleChecker>
                    <UserActionModal
                        buttonText={t('button.changePassword', 'Change password')}
                        icon={faKey}
                        localities={localities}
                        modalType="password"
                        organization={organization}
                        submissionStatus={changePasswordState}
                        onNotificationPreferencesUpdate={async () => {}}
                        onPasswordChange={(password) => changePassword({ userId: editedUser.id, body: { password } })}
                    />
                    <PopConfirm
                        cancelButtonVariant="secondary"
                        confirmButtonVariant="danger"
                        confirmMessage={t('others.deleteUser', `Are you sure you want to delete user {{ value }}?`, {
                            value: user.user.email,
                        })}
                        onConfirm={onDelete}
                    >
                        <Button disabled={isDeletionInProgress} variant="danger">
                            <FontAwesomeIcon icon={faTrash} />
                            {t('button.deleteUser', 'Delete user')}
                        </Button>
                    </PopConfirm>
                    {enabledNotifications && (
                        <LegacyLoadingWrapper
                            placeholder={
                                <Button variant="secondary">
                                    <FontAwesomeIcon icon={faBell} /> Add notifications
                                </Button>
                            }
                            request={notificationsCall}
                        >
                            {(notifications) => (
                                <UserActionModal
                                    buttonText={t('button.addNotification', 'Add notification')}
                                    icon={faBell}
                                    localities={localities}
                                    modalType="notifications"
                                    notifications={notifications}
                                    organization={organization}
                                    submissionStatus={updateNotificationPreferencesState}
                                    onNotificationPreferencesUpdate={(updatedNotifications) =>
                                        updateNotificationPreferences({
                                            userId: editedUser.id,
                                            body: updatedNotifications,
                                        })
                                    }
                                    onPasswordChange={async () => {}}
                                />
                            )}
                        </LegacyLoadingWrapper>
                    )}
                </Col>
            </Form.Group>
        </>
    )
}

interface Props {
    user: UserEditData
    errors?: UsersEditFormErrors
    onErrorsChanged: (errors: UsersEditFormErrors) => void
    localities: Array<LocalityNameModel>
    organization: OrganizationResponse
    onTakeover: () => void
    onDelete: () => void
    onUserUpdate: (updatedUser: Partial<UserEditData>) => void
    saveChangesButton?: JSX.Element
    profile: UseQueryResult<ProfileResponse>
    editedUser?: UserResponse
    isNewUser?: boolean
    localityPickerTokenRenderer: LocalityTokenRenderer
    isDeletionInProgress?: boolean
}

const UsersTabForm: React.FC<Props> = ({
    user,
    localities,
    onTakeover,
    onDelete,
    onUserUpdate,
    saveChangesButton,
    organization,
    profile,
    editedUser,
    errors,
    onErrorsChanged,
    isNewUser,
    localityPickerTokenRenderer,
    isDeletionInProgress,
}) => {
    const { t } = useTranslation()
    const {
        user: { email, phoneNumber },
        role,
    } = user
    const countryCode = determinePhoneNumberCountryCode(profile.data?.language, phoneNumber)
    const localityChoices = Object.fromEntries(localities.map(({ id, name }) => [id.toString(), name]))
    const defaultErrors = { email: defaultErrorEntry, phoneNumber: defaultErrorEntry }
    const [emailTouched, setEmailTouched] = useState(false)
    const [phoneNumberTouched, setPhoneNumberTouched] = useState(false)

    useEffect(() => {
        onErrorsChanged({
            phoneNumber: {
                isTouched: phoneNumberTouched,
                isInvalid:
                    !isEmpty(phoneNumber ? subtractPhoneNumberCountryCode(phoneNumber) : undefined) &&
                    !isPhoneNumberWithoutCountryCodeValid(subtractPhoneNumberCountryCode(phoneNumber)!),
            },
            email: {
                isTouched: emailTouched,
                isInvalid: email !== undefined && !isEmailValid(email),
            },
        })
    }, [email, phoneNumber, emailTouched, phoneNumberTouched])

    const handleLocalitiesSelect = (localityIds: Array<string>) => {
        const selection = localities.filter(({ id }) => localityIds.includes(id.toString()))
        onUserUpdate({ localities: selection })
    }

    const handleRoleUpdate = (newRole: Role) => {
        onUserUpdate({ ...user, role: newRole })
    }

    const handleEmailUpdate = (newEmail: string) => {
        setEmailTouched(true)
        onUserUpdate({ user: { ...user, email: newEmail } })
    }

    const handlePhoneNumberUpdate = (newPhoneNumber: string) => {
        setPhoneNumberTouched(true)
        onUserUpdate({
            user: {
                ...user.user,
                phoneNumber: !isEmpty(newPhoneNumber) ? `${countryCode}${newPhoneNumber}` : undefined,
            },
        })
    }

    return (
        <Form className={styles.usersEditForm}>
            <EmailRow
                email={email}
                errors={errors ?? defaultErrors}
                isNewUser={isNewUser}
                onUpdate={handleEmailUpdate}
            />
            <RolePickerRow role={role} onUpdate={handleRoleUpdate} />
            <Form.Group as={Row} className={styles.row}>
                <PhoneNumberInput
                    countryCode={countryCode}
                    errors={errors ?? defaultErrors}
                    labelBoxMd={3}
                    phoneNumber={phoneNumber}
                    phoneNumberBoxMd={6}
                    onUpdate={handlePhoneNumberUpdate}
                />
            </Form.Group>
            <Form.Group as={Row} className={styles.row}>
                {user.role === Role.User ? (
                    <LocalityPickerInput
                        localityChoices={localityChoices}
                        selection={user.localities}
                        tokenRenderer={localityPickerTokenRenderer}
                        onSelect={handleLocalitiesSelect}
                    />
                ) : (
                    <>
                        <LocalityPickerLabel md={3} sm={12} />
                        <Col md={9} sm={12}>
                            <div className="text-muted">
                                {t(
                                    'form.localitiesAdminNote',
                                    'Admin always has access to all organization localities'
                                )}
                            </div>
                        </Col>
                    </>
                )}
            </Form.Group>
            {saveChangesButton && <Col className={styles.saveChangesButtonColumn}>{saveChangesButton}</Col>}
            {editedUser !== undefined && !isNewUser && (
                <ActionsRow
                    editedUser={editedUser}
                    isDeletionInProgress={isDeletionInProgress}
                    localities={user.localities}
                    organization={organization}
                    user={user}
                    onDelete={onDelete}
                    onTakeover={onTakeover}
                />
            )}
        </Form>
    )
}
export default UsersTabForm
