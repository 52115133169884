import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'

import { FloorplanHeatmapData } from '@api'

import { statisticsApi } from '@services'

import ApiCallErrorView from '@components/ApiCallErrorView'
import { AutoSizedImageWrapper } from '@components/FloorplanForm/Floorplan'
import LegacyLoadingWrapper from '@components/LegacyLoadingWrapper'
import LoadingSpinner from '@components/LoadingSpinner'

interface Props {
    heatmap?: FloorplanHeatmapData
}

const FloorplanHeatmapDisplayBox: React.FC<Props> = ({ heatmap }) => {
    const { t } = useTranslation()

    const heatmapContentCall = useQuery({
        ...statisticsApi.getFloorplanHeatmapContent.query({
            heatmapId: heatmap?.heatmapId ?? 0,
        }),
        enabled: heatmap?.heatmapId !== undefined,
    })

    const imageContainerRef = useRef<HTMLDivElement>(null)

    const errors = { 404: t('heatmapDetailPage.heatmapNotFound', 'Heatmap not found') }

    return (
        <LegacyLoadingWrapper
            errorComponent={<ApiCallErrorView apiErrors={errors} request={heatmapContentCall} />}
            placeholder={<LoadingSpinner bare />}
            request={heatmapContentCall}
        >
            {(heatmap) => {
                const src = heatmap ? window.URL.createObjectURL(heatmap) : ''

                return (
                    <div ref={imageContainerRef}>
                        {heatmap ? (
                            <AutoSizedImageWrapper src={src} />
                        ) : (
                            t(
                                'heatmapDetailPage.noHeatmapsAvailableForFloorplan',
                                'No heatmaps available for selected floor plan'
                            )
                        )}
                    </div>
                )
            }}
        </LegacyLoadingWrapper>
    )
}

export default FloorplanHeatmapDisplayBox
