/**
 * Vividi Dashboard API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime'
import {UseQueryOptions, QueryKey} from 'react-query'

import {
    GeneratedReportList,
    GeneratedReportListFromJSON,
    GeneratedReportListToJSON,
} from '../models';

export interface GetLocalityConversionsReportPdfRequest {
    localityId: number;
    reportId: number;
}

export interface GetLocalityFootfallReportPdfRequest {
    localityId: number;
    reportId: number;
}

export interface GetLocalityQueueStatisticsReportPdfRequest {
    localityId: number;
    reportId: number;
}

export interface GetOrganizationOccupancyReportPdfRequest {
    organizationId: number;
    reportId: number;
}

export interface ListLocalityConversionReportsRequest {
    localityId: number;
}

export interface ListLocalityFootfallReportsRequest {
    localityId: number;
}

export interface ListLocalityQueueStatisticsReportsRequest {
    localityId: number;
}

export interface ListOrganizationOccupancyReportsRequest {
    organizationId: number;
}


/**
 * 
 */
export class ReportsApi extends runtime.BaseAPI {
    readonly name = 'ReportsApi'

    /**
     * Download the contents of a locality conversions report.
     */
    getLocalityConversionsReportPdf = Object.assign(
        async (requestParameters: GetLocalityConversionsReportPdfRequest, initOverrides?: RequestInit): Promise<Blob> => {
            const response = await this.getLocalityConversionsReportPdf.invokeRaw(requestParameters, initOverrides);
            return await response.value();
        },
        {
            invokeRaw: async (requestParameters: GetLocalityConversionsReportPdfRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Blob>> => {
                if (requestParameters.localityId === null || requestParameters.localityId === undefined) {
                    throw new runtime.RequiredError('localityId','Required parameter requestParameters.localityId was null or undefined when calling getLocalityConversionsReportPdf.');
                }
            
                if (requestParameters.reportId === null || requestParameters.reportId === undefined) {
                    throw new runtime.RequiredError('reportId','Required parameter requestParameters.reportId was null or undefined when calling getLocalityConversionsReportPdf.');
                }
            
                const queryParameters: any = {};
            
                const headerParameters: runtime.HTTPHeaders = {};
            
                if (this.configuration && this.configuration.accessToken) {
                    const token = this.configuration.accessToken;
                    const tokenString = await token("token", []);
            
                    if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                    }
                }
                const response = await this.request({
                    path: `/api/reports/locality-conversions/{locality_id}/{report_id}.pdf`.replace(`{${"locality_id"}}`, encodeURIComponent(String(requestParameters.localityId))).replace(`{${"report_id"}}`, encodeURIComponent(String(requestParameters.reportId))),
                    method: 'GET',
                    headers: headerParameters,
                    query: queryParameters,
                }, initOverrides);
            
                return new runtime.BlobApiResponse(response);
            },
            mutationKey: [
                'ReportsApi',
                'getLocalityConversionsReportPdf', 
            ],
            queryKey: (requestParameters: GetLocalityConversionsReportPdfRequest, initOverrides?: RequestInit) => ([
                    ...this.getLocalityConversionsReportPdf.mutationKey, 
                {
                localityId: requestParameters.localityId,
                reportId: requestParameters.reportId,
                }
            ]),
            query: (requestParameters: GetLocalityConversionsReportPdfRequest, initOverrides?: RequestInit): UseQueryOptions<Blob> & {queryKey: QueryKey} => ({
                queryFn: () => this.getLocalityConversionsReportPdf(requestParameters, initOverrides),
                queryKey: this.getLocalityConversionsReportPdf.queryKey(requestParameters, initOverrides)
            })
        }
    )

    /**
     * Download the contents of a locality footfall report.
     */
    getLocalityFootfallReportPdf = Object.assign(
        async (requestParameters: GetLocalityFootfallReportPdfRequest, initOverrides?: RequestInit): Promise<Blob> => {
            const response = await this.getLocalityFootfallReportPdf.invokeRaw(requestParameters, initOverrides);
            return await response.value();
        },
        {
            invokeRaw: async (requestParameters: GetLocalityFootfallReportPdfRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Blob>> => {
                if (requestParameters.localityId === null || requestParameters.localityId === undefined) {
                    throw new runtime.RequiredError('localityId','Required parameter requestParameters.localityId was null or undefined when calling getLocalityFootfallReportPdf.');
                }
            
                if (requestParameters.reportId === null || requestParameters.reportId === undefined) {
                    throw new runtime.RequiredError('reportId','Required parameter requestParameters.reportId was null or undefined when calling getLocalityFootfallReportPdf.');
                }
            
                const queryParameters: any = {};
            
                const headerParameters: runtime.HTTPHeaders = {};
            
                if (this.configuration && this.configuration.accessToken) {
                    const token = this.configuration.accessToken;
                    const tokenString = await token("token", []);
            
                    if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                    }
                }
                const response = await this.request({
                    path: `/api/reports/locality-footfall/{locality_id}/{report_id}.pdf`.replace(`{${"locality_id"}}`, encodeURIComponent(String(requestParameters.localityId))).replace(`{${"report_id"}}`, encodeURIComponent(String(requestParameters.reportId))),
                    method: 'GET',
                    headers: headerParameters,
                    query: queryParameters,
                }, initOverrides);
            
                return new runtime.BlobApiResponse(response);
            },
            mutationKey: [
                'ReportsApi',
                'getLocalityFootfallReportPdf', 
            ],
            queryKey: (requestParameters: GetLocalityFootfallReportPdfRequest, initOverrides?: RequestInit) => ([
                    ...this.getLocalityFootfallReportPdf.mutationKey, 
                {
                localityId: requestParameters.localityId,
                reportId: requestParameters.reportId,
                }
            ]),
            query: (requestParameters: GetLocalityFootfallReportPdfRequest, initOverrides?: RequestInit): UseQueryOptions<Blob> & {queryKey: QueryKey} => ({
                queryFn: () => this.getLocalityFootfallReportPdf(requestParameters, initOverrides),
                queryKey: this.getLocalityFootfallReportPdf.queryKey(requestParameters, initOverrides)
            })
        }
    )

    /**
     * Download the contents of a locality queue statistics report.
     */
    getLocalityQueueStatisticsReportPdf = Object.assign(
        async (requestParameters: GetLocalityQueueStatisticsReportPdfRequest, initOverrides?: RequestInit): Promise<Blob> => {
            const response = await this.getLocalityQueueStatisticsReportPdf.invokeRaw(requestParameters, initOverrides);
            return await response.value();
        },
        {
            invokeRaw: async (requestParameters: GetLocalityQueueStatisticsReportPdfRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Blob>> => {
                if (requestParameters.localityId === null || requestParameters.localityId === undefined) {
                    throw new runtime.RequiredError('localityId','Required parameter requestParameters.localityId was null or undefined when calling getLocalityQueueStatisticsReportPdf.');
                }
            
                if (requestParameters.reportId === null || requestParameters.reportId === undefined) {
                    throw new runtime.RequiredError('reportId','Required parameter requestParameters.reportId was null or undefined when calling getLocalityQueueStatisticsReportPdf.');
                }
            
                const queryParameters: any = {};
            
                const headerParameters: runtime.HTTPHeaders = {};
            
                if (this.configuration && this.configuration.accessToken) {
                    const token = this.configuration.accessToken;
                    const tokenString = await token("token", []);
            
                    if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                    }
                }
                const response = await this.request({
                    path: `/api/reports/locality-queue-statistics/{locality_id}/{report_id}.pdf`.replace(`{${"locality_id"}}`, encodeURIComponent(String(requestParameters.localityId))).replace(`{${"report_id"}}`, encodeURIComponent(String(requestParameters.reportId))),
                    method: 'GET',
                    headers: headerParameters,
                    query: queryParameters,
                }, initOverrides);
            
                return new runtime.BlobApiResponse(response);
            },
            mutationKey: [
                'ReportsApi',
                'getLocalityQueueStatisticsReportPdf', 
            ],
            queryKey: (requestParameters: GetLocalityQueueStatisticsReportPdfRequest, initOverrides?: RequestInit) => ([
                    ...this.getLocalityQueueStatisticsReportPdf.mutationKey, 
                {
                localityId: requestParameters.localityId,
                reportId: requestParameters.reportId,
                }
            ]),
            query: (requestParameters: GetLocalityQueueStatisticsReportPdfRequest, initOverrides?: RequestInit): UseQueryOptions<Blob> & {queryKey: QueryKey} => ({
                queryFn: () => this.getLocalityQueueStatisticsReportPdf(requestParameters, initOverrides),
                queryKey: this.getLocalityQueueStatisticsReportPdf.queryKey(requestParameters, initOverrides)
            })
        }
    )

    /**
     * Download the contents of an organization occupancy report.
     */
    getOrganizationOccupancyReportPdf = Object.assign(
        async (requestParameters: GetOrganizationOccupancyReportPdfRequest, initOverrides?: RequestInit): Promise<Blob> => {
            const response = await this.getOrganizationOccupancyReportPdf.invokeRaw(requestParameters, initOverrides);
            return await response.value();
        },
        {
            invokeRaw: async (requestParameters: GetOrganizationOccupancyReportPdfRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Blob>> => {
                if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
                    throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling getOrganizationOccupancyReportPdf.');
                }
            
                if (requestParameters.reportId === null || requestParameters.reportId === undefined) {
                    throw new runtime.RequiredError('reportId','Required parameter requestParameters.reportId was null or undefined when calling getOrganizationOccupancyReportPdf.');
                }
            
                const queryParameters: any = {};
            
                const headerParameters: runtime.HTTPHeaders = {};
            
                if (this.configuration && this.configuration.accessToken) {
                    const token = this.configuration.accessToken;
                    const tokenString = await token("token", []);
            
                    if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                    }
                }
                const response = await this.request({
                    path: `/api/reports/organization-occupancy/{organization_id}/{report_id}.pdf`.replace(`{${"organization_id"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"report_id"}}`, encodeURIComponent(String(requestParameters.reportId))),
                    method: 'GET',
                    headers: headerParameters,
                    query: queryParameters,
                }, initOverrides);
            
                return new runtime.BlobApiResponse(response);
            },
            mutationKey: [
                'ReportsApi',
                'getOrganizationOccupancyReportPdf', 
            ],
            queryKey: (requestParameters: GetOrganizationOccupancyReportPdfRequest, initOverrides?: RequestInit) => ([
                    ...this.getOrganizationOccupancyReportPdf.mutationKey, 
                {
                organizationId: requestParameters.organizationId,
                reportId: requestParameters.reportId,
                }
            ]),
            query: (requestParameters: GetOrganizationOccupancyReportPdfRequest, initOverrides?: RequestInit): UseQueryOptions<Blob> & {queryKey: QueryKey} => ({
                queryFn: () => this.getOrganizationOccupancyReportPdf(requestParameters, initOverrides),
                queryKey: this.getOrganizationOccupancyReportPdf.queryKey(requestParameters, initOverrides)
            })
        }
    )

    /**
     * Get a list of existing locality conversion reports.
     */
    listLocalityConversionReports = Object.assign(
        async (requestParameters: ListLocalityConversionReportsRequest, initOverrides?: RequestInit): Promise<GeneratedReportList> => {
            const response = await this.listLocalityConversionReports.invokeRaw(requestParameters, initOverrides);
            return await response.value();
        },
        {
            invokeRaw: async (requestParameters: ListLocalityConversionReportsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<GeneratedReportList>> => {
                if (requestParameters.localityId === null || requestParameters.localityId === undefined) {
                    throw new runtime.RequiredError('localityId','Required parameter requestParameters.localityId was null or undefined when calling listLocalityConversionReports.');
                }
            
                const queryParameters: any = {};
            
                const headerParameters: runtime.HTTPHeaders = {};
            
                if (this.configuration && this.configuration.accessToken) {
                    const token = this.configuration.accessToken;
                    const tokenString = await token("token", []);
            
                    if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                    }
                }
                const response = await this.request({
                    path: `/api/reports/locality-conversions/{locality_id}/`.replace(`{${"locality_id"}}`, encodeURIComponent(String(requestParameters.localityId))),
                    method: 'GET',
                    headers: headerParameters,
                    query: queryParameters,
                }, initOverrides);
            
                return new runtime.JSONApiResponse(response, (jsonValue) => GeneratedReportListFromJSON(jsonValue));
            },
            mutationKey: [
                'ReportsApi',
                'listLocalityConversionReports', 
            ],
            queryKey: (requestParameters: ListLocalityConversionReportsRequest, initOverrides?: RequestInit) => ([
                    ...this.listLocalityConversionReports.mutationKey, 
                {
                localityId: requestParameters.localityId,
                }
            ]),
            query: (requestParameters: ListLocalityConversionReportsRequest, initOverrides?: RequestInit): UseQueryOptions<GeneratedReportList> & {queryKey: QueryKey} => ({
                queryFn: () => this.listLocalityConversionReports(requestParameters, initOverrides),
                queryKey: this.listLocalityConversionReports.queryKey(requestParameters, initOverrides)
            })
        }
    )

    /**
     * Get a list of existing locality footfall reports.
     */
    listLocalityFootfallReports = Object.assign(
        async (requestParameters: ListLocalityFootfallReportsRequest, initOverrides?: RequestInit): Promise<GeneratedReportList> => {
            const response = await this.listLocalityFootfallReports.invokeRaw(requestParameters, initOverrides);
            return await response.value();
        },
        {
            invokeRaw: async (requestParameters: ListLocalityFootfallReportsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<GeneratedReportList>> => {
                if (requestParameters.localityId === null || requestParameters.localityId === undefined) {
                    throw new runtime.RequiredError('localityId','Required parameter requestParameters.localityId was null or undefined when calling listLocalityFootfallReports.');
                }
            
                const queryParameters: any = {};
            
                const headerParameters: runtime.HTTPHeaders = {};
            
                if (this.configuration && this.configuration.accessToken) {
                    const token = this.configuration.accessToken;
                    const tokenString = await token("token", []);
            
                    if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                    }
                }
                const response = await this.request({
                    path: `/api/reports/locality-footfall/{locality_id}/`.replace(`{${"locality_id"}}`, encodeURIComponent(String(requestParameters.localityId))),
                    method: 'GET',
                    headers: headerParameters,
                    query: queryParameters,
                }, initOverrides);
            
                return new runtime.JSONApiResponse(response, (jsonValue) => GeneratedReportListFromJSON(jsonValue));
            },
            mutationKey: [
                'ReportsApi',
                'listLocalityFootfallReports', 
            ],
            queryKey: (requestParameters: ListLocalityFootfallReportsRequest, initOverrides?: RequestInit) => ([
                    ...this.listLocalityFootfallReports.mutationKey, 
                {
                localityId: requestParameters.localityId,
                }
            ]),
            query: (requestParameters: ListLocalityFootfallReportsRequest, initOverrides?: RequestInit): UseQueryOptions<GeneratedReportList> & {queryKey: QueryKey} => ({
                queryFn: () => this.listLocalityFootfallReports(requestParameters, initOverrides),
                queryKey: this.listLocalityFootfallReports.queryKey(requestParameters, initOverrides)
            })
        }
    )

    /**
     * Get a list of existing locality queue statistic reports.
     */
    listLocalityQueueStatisticsReports = Object.assign(
        async (requestParameters: ListLocalityQueueStatisticsReportsRequest, initOverrides?: RequestInit): Promise<GeneratedReportList> => {
            const response = await this.listLocalityQueueStatisticsReports.invokeRaw(requestParameters, initOverrides);
            return await response.value();
        },
        {
            invokeRaw: async (requestParameters: ListLocalityQueueStatisticsReportsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<GeneratedReportList>> => {
                if (requestParameters.localityId === null || requestParameters.localityId === undefined) {
                    throw new runtime.RequiredError('localityId','Required parameter requestParameters.localityId was null or undefined when calling listLocalityQueueStatisticsReports.');
                }
            
                const queryParameters: any = {};
            
                const headerParameters: runtime.HTTPHeaders = {};
            
                if (this.configuration && this.configuration.accessToken) {
                    const token = this.configuration.accessToken;
                    const tokenString = await token("token", []);
            
                    if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                    }
                }
                const response = await this.request({
                    path: `/api/reports/locality-queue-statistics/{locality_id}/`.replace(`{${"locality_id"}}`, encodeURIComponent(String(requestParameters.localityId))),
                    method: 'GET',
                    headers: headerParameters,
                    query: queryParameters,
                }, initOverrides);
            
                return new runtime.JSONApiResponse(response, (jsonValue) => GeneratedReportListFromJSON(jsonValue));
            },
            mutationKey: [
                'ReportsApi',
                'listLocalityQueueStatisticsReports', 
            ],
            queryKey: (requestParameters: ListLocalityQueueStatisticsReportsRequest, initOverrides?: RequestInit) => ([
                    ...this.listLocalityQueueStatisticsReports.mutationKey, 
                {
                localityId: requestParameters.localityId,
                }
            ]),
            query: (requestParameters: ListLocalityQueueStatisticsReportsRequest, initOverrides?: RequestInit): UseQueryOptions<GeneratedReportList> & {queryKey: QueryKey} => ({
                queryFn: () => this.listLocalityQueueStatisticsReports(requestParameters, initOverrides),
                queryKey: this.listLocalityQueueStatisticsReports.queryKey(requestParameters, initOverrides)
            })
        }
    )

    /**
     * Get a list of existing organization occupancy reports.
     */
    listOrganizationOccupancyReports = Object.assign(
        async (requestParameters: ListOrganizationOccupancyReportsRequest, initOverrides?: RequestInit): Promise<GeneratedReportList> => {
            const response = await this.listOrganizationOccupancyReports.invokeRaw(requestParameters, initOverrides);
            return await response.value();
        },
        {
            invokeRaw: async (requestParameters: ListOrganizationOccupancyReportsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<GeneratedReportList>> => {
                if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
                    throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling listOrganizationOccupancyReports.');
                }
            
                const queryParameters: any = {};
            
                const headerParameters: runtime.HTTPHeaders = {};
            
                if (this.configuration && this.configuration.accessToken) {
                    const token = this.configuration.accessToken;
                    const tokenString = await token("token", []);
            
                    if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                    }
                }
                const response = await this.request({
                    path: `/api/reports/organization-occupancy/{organization_id}/`.replace(`{${"organization_id"}}`, encodeURIComponent(String(requestParameters.organizationId))),
                    method: 'GET',
                    headers: headerParameters,
                    query: queryParameters,
                }, initOverrides);
            
                return new runtime.JSONApiResponse(response, (jsonValue) => GeneratedReportListFromJSON(jsonValue));
            },
            mutationKey: [
                'ReportsApi',
                'listOrganizationOccupancyReports', 
            ],
            queryKey: (requestParameters: ListOrganizationOccupancyReportsRequest, initOverrides?: RequestInit) => ([
                    ...this.listOrganizationOccupancyReports.mutationKey, 
                {
                organizationId: requestParameters.organizationId,
                }
            ]),
            query: (requestParameters: ListOrganizationOccupancyReportsRequest, initOverrides?: RequestInit): UseQueryOptions<GeneratedReportList> & {queryKey: QueryKey} => ({
                queryFn: () => this.listOrganizationOccupancyReports(requestParameters, initOverrides),
                queryKey: this.listOrganizationOccupancyReports.queryKey(requestParameters, initOverrides)
            })
        }
    )

}
