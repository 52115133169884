import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { isEmpty } from 'lodash'
import React from 'react'
import { Col, Button, ColProps } from 'react-bootstrap'
import { TokenProps } from 'react-bootstrap-typeahead'
import { useTranslation } from 'react-i18next'

import { LocalityNameModel } from '@api'

import MultiSelectBox from '@components/MultiSelectBox'

import styles from './LocalityPickerInput.module.scss'

export const LocalityPickerLabel: React.FC<{ label?: string; md: ColProps['md']; sm: ColProps['sm'] }> = ({
    label,
    md,
    sm,
}) => {
    const { t } = useTranslation()

    return (
        <Col className={styles.label} md={md} sm={sm}>
            {label ?? t('table.localities', 'Localities')}
        </Col>
    )
}

export type LocalityTokenRenderer = React.FC<TokenProps & { tokenId?: number }>

const LocalityPickerInput: React.FC<{
    label?: string
    localityChoices: {
        [id: string]: string
    }
    selection: Array<LocalityNameModel>
    onSelect: (selection: string[]) => void
    tokenRenderer?: LocalityTokenRenderer
}> = ({ label, localityChoices, selection, onSelect, tokenRenderer }) => {
    const { t } = useTranslation()

    const lgColSize = !isEmpty(localityChoices) ? 6 : 9
    const mdColSize = !isEmpty(localityChoices) ? 5 : 9

    return (
        <>
            <LocalityPickerLabel label={label} md={3} sm={12} />
            <>
                <Col lg={lgColSize} md={mdColSize} sm={12}>
                    <MultiSelectBox
                        choices={localityChoices}
                        disabled={isEmpty(Object.values(localityChoices))}
                        selection={selection.map(({ id }) => id.toString())}
                        tokenRenderer={tokenRenderer}
                        onSelect={onSelect}
                    />
                </Col>
                {!isEmpty(localityChoices) && (
                    <Col className={styles.allLocalitiesButtonColumn} lg={3} md={4} sm={12}>
                        <Button variant="secondary" onClick={() => onSelect(Object.keys(localityChoices))}>
                            <FontAwesomeIcon icon={faPlus} /> {t('button.allLocalities', 'All localities')}
                        </Button>
                    </Col>
                )}
            </>
        </>
    )
}

export default LocalityPickerInput
