/* tslint:disable */
/* eslint-disable */
/**
 * Vividi Dashboard API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CategorySet,
    CategorySetFromJSON,
    CategorySetFromJSONTyped,
    CategorySetToJSON,
} from './CategorySet';

/**
 * 
 * @export
 * @interface FootfallOverviewRequestBody
 */
export interface FootfallOverviewRequestBody {
    /**
     * 
     * @type {Array<number>}
     * @memberof FootfallOverviewRequestBody
     */
    localities: Array<number>;
    /**
     * 
     * @type {CategorySet}
     * @memberof FootfallOverviewRequestBody
     */
    categorySet: CategorySet;
    /**
     * List of dates to fetch statistics for
     * @type {Array<string>}
     * @memberof FootfallOverviewRequestBody
     */
    dates: Array<string>;
}

export function FootfallOverviewRequestBodyFromJSON(json: any): FootfallOverviewRequestBody {
    return FootfallOverviewRequestBodyFromJSONTyped(json, false);
}

export function FootfallOverviewRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): FootfallOverviewRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'localities': json['localities'],
        'categorySet': CategorySetFromJSON(json['category_set']),
        'dates': json['dates'],
    };
}

export function FootfallOverviewRequestBodyToJSON(value?: FootfallOverviewRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'localities': value.localities,
        'category_set': CategorySetToJSON(value.categorySet),
        'dates': value.dates,
    };
}

