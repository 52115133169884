import { faUser, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon as FAIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import { TFunction } from 'i18next'
import { Duration } from 'luxon'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { OccupancyData } from '@components/LocalityOccupancyConsumer'

import logo from '@images/logo/logo_white.svg'

import styles from './StaySafeDashboardView.module.scss'

const formatWaitTime = (waitTime?: Duration) => {
    if (waitTime === undefined) {
        return '-'
    }

    if (waitTime < Duration.fromObject({ minute: 1 })) {
        return (
            <div className={styles.estimationTextWrapper}>
                <span dangerouslySetInnerHTML={{ __html: '&lt;' }} className={styles.estimationText} />
                <span dangerouslySetInnerHTML={{ __html: '&nbsp;1&nbsp;' }} />
                <span className={styles.waitTime}>min</span>
            </div>
        )
    }

    if (waitTime > Duration.fromObject({ minute: 30 })) {
        return (
            <div className={styles.longWaitTime}>
                <span dangerouslySetInnerHTML={{ __html: '&gt;' }} className={styles.estimationText} />
                <span dangerouslySetInnerHTML={{ __html: '&nbsp;30&nbsp;' }} />
                <span className={styles.waitTime}>min</span>
            </div>
        )
    }

    return waitTime.toFormat('mm:ss')
}

const formatOccupancyPercentage = (value: number | undefined, t: TFunction) => {
    if (value === undefined) {
        return '-'
    }

    return t('staysafe.percentage', { value })
}

const StaySafeDashboardView: React.FC<{
    data: OccupancyData
    showCapacity: boolean
    showExactNumbers: boolean
}> = ({ data, showCapacity, showExactNumbers }) => {
    const { t, i18n } = useTranslation()

    // Reload the page every 12 hours
    useEffect(() => {
        const timeout = setTimeout(() => {
            window.location.reload()
        }, 12 * 3600 * 1000)

        return () => clearTimeout(timeout)
    }, [])

    return (
        <div
            className={classNames({
                [styles.layout]: true,
                [styles.safe]: data.isSafeToEnter,
                [styles.unsafe]: !data.isSafeToEnter,
                [styles.offline]: data.isOffline,
            })}
        >
            <div className={styles.progressBarArea}>
                <div className={styles.progressBar}>
                    <div
                        className={styles.progressBarInner}
                        style={{
                            height: `${data.occupancyPercentage}%`,
                        }}
                    />
                </div>
            </div>
            <div className={styles.statusArea}>
                {!data.isOffline ? (
                    <div
                        className={classNames({
                            [styles.statusText]: true,
                            [styles.statusTextSmaller]:
                                i18n.language === 'sv' || i18n.language === 'cs' || i18n.language === 'pl',
                        })}
                    >
                        {data.isSafeToEnter ? t('staysafe.enter') : t('staysafe.wait')}
                    </div>
                ) : (
                    <div>
                        <h2>{t('staysafe.offline')}</h2>
                        <FAIcon className={styles.dangerIcon} icon={faExclamationTriangle} />
                    </div>
                )}

                <div className={styles.statistics}>
                    {/* STORE OCCUPANCY */}
                    <div className={styles.metricContainer}>
                        <h2>{t('staysafe.occupancy')}</h2>
                        <div>{formatOccupancyPercentage(data.occupancyPercentage, t)}</div>
                    </div>

                    {showExactNumbers && (
                        <div className={styles.metricContainer}>
                            {data.isSafeToEnter && (
                                <div>
                                    <h2>{t('staysafe.inside')}</h2>
                                    <div>{data.currentCrowdSize}</div>
                                </div>
                            )}

                            {!data.isSafeToEnter && (
                                <>
                                    <h2>{t('staysafe.waitTime')}</h2>
                                    <div>{formatWaitTime(data.waitTime)}</div>
                                </>
                            )}
                        </div>
                    )}

                    {/* STORE CAPACITY */}
                    {showCapacity && (
                        <div className={styles.metricContainer}>
                            <h2>{t('staysafe.storeLimit')}</h2>
                            <div>
                                {data.maximumCapacity}
                                <FAIcon className={styles.userIcon} icon={faUser} />
                            </div>
                        </div>
                    )}
                </div>
                <div className={styles.logo}>
                    <img alt="Vividi logo" src={logo} />
                </div>
            </div>
        </div>
    )
}

export default StaySafeDashboardView
