import React from 'react'
import { useQuery } from 'react-query'

import { OrganizationFeatures, OrganizationResponse, Role } from '@api/models'

import { visibleOrganizationsQuery } from '@helpers/api'
import useProfile from '@helpers/profile'

interface CommonProps {
    children: React.ReactNode | Array<React.ReactNode>
    /** The content displayed when the feature is not allowed */
    placeholder?: JSX.Element
    /** Should the children be displayed for the admin user even when the organization in question doesn't have the required features? (true by default) */
    allowForAdmin?: boolean
    /** An escape hatch - does the current user have an exemption from the feature rule? (false by default) */
    hasExemption?: boolean
    organization?: OrganizationResponse
}

interface SingleFeatureProps extends CommonProps {
    feature: keyof OrganizationFeatures
    features?: never
}

interface MultiFeatureProps extends CommonProps {
    feature?: never
    features: Array<keyof OrganizationFeatures>
}

const FeatureChecker: React.FC<SingleFeatureProps | MultiFeatureProps> = (props) => {
    const allowForAdmin = props.allowForAdmin ?? true
    const hasExemption = props.hasExemption ?? false
    const placeholder = props.placeholder ?? null

    const profileCall = useProfile()

    const organizationsCall = useQuery({
        ...visibleOrganizationsQuery(profileCall.data),
        enabled: profileCall.data !== undefined,
    })

    if (profileCall.data?.role === Role.Administrator && allowForAdmin) {
        return <>{props.children}</>
    }

    if (hasExemption) {
        return <>{props.children}</>
    }

    if (profileCall.data === undefined || organizationsCall.data === undefined) {
        return null
    }

    const organization =
        props.organization ??
        organizationsCall.data.organizations.find((o) => o.id === profileCall.data!.organizationId)

    if (organization === undefined) {
        return placeholder
    }

    if (props.feature === undefined) {
        if (props.features.every((f) => !organization.features[f])) {
            return placeholder
        }
    } else if (!organization.features[props.feature]) {
        return placeholder
    }

    return <>{props.children}</>
}

export default FeatureChecker
