/**
 * Vividi Dashboard API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime'
import {UseQueryOptions, QueryKey} from 'react-query'

import {
    CreateSceneRequestBody,
    CreateSceneRequestBodyFromJSON,
    CreateSceneRequestBodyToJSON,
    DeviceAssignmentRequestBody,
    DeviceAssignmentRequestBodyFromJSON,
    DeviceAssignmentRequestBodyToJSON,
    LocalityIdListModel,
    LocalityIdListModelFromJSON,
    LocalityIdListModelToJSON,
    SceneConfigurationFile,
    SceneConfigurationFileFromJSON,
    SceneConfigurationFileToJSON,
    SceneDescription,
    SceneDescriptionFromJSON,
    SceneDescriptionToJSON,
    SceneDeviceAssignmentListResponse,
    SceneDeviceAssignmentListResponseFromJSON,
    SceneDeviceAssignmentListResponseToJSON,
    SceneListResponse,
    SceneListResponseFromJSON,
    SceneListResponseToJSON,
    SceneResponse,
    SceneResponseFromJSON,
    SceneResponseToJSON,
    SuccessResponse,
    SuccessResponseFromJSON,
    SuccessResponseToJSON,
    UpdateCaptureSettingsRequestBody,
    UpdateCaptureSettingsRequestBodyFromJSON,
    UpdateCaptureSettingsRequestBodyToJSON,
    UpdateSceneDescriptionRequestBody,
    UpdateSceneDescriptionRequestBodyFromJSON,
    UpdateSceneDescriptionRequestBodyToJSON,
    UpdateSceneRequestBody,
    UpdateSceneRequestBodyFromJSON,
    UpdateSceneRequestBodyToJSON,
} from '../models';

export interface AddSceneRequest {
    body: CreateSceneRequestBody;
}

export interface DeleteSceneRequest {
    sceneId: number;
}

export interface DeleteSceneSnapshotRequest {
    sceneId: number;
}

export interface GetBoxManagerConfigRequest {
    sceneId: number;
}

export interface GetEndpointHeatmapRequest {
    sceneId: number;
}

export interface GetSceneRequest {
    sceneId: number;
}

export interface GetSceneDescriptionRequest {
    sceneId: number;
}

export interface GetSceneDeviceHistoryRequest {
    sceneId: number;
}

export interface GetSceneSnapshotRequest {
    sceneId: number;
}

export interface GetTrackerConfigRequest {
    sceneId: number;
}

export interface GetTrajectoryHeatmapRequest {
    sceneId: number;
}

export interface GetTrajectoryHeatmapSampleRequest {
    sceneId: number;
}

export interface RemoveSceneDeviceRequest {
    sceneId: number;
}

export interface SetBoxManagerConfigRequest {
    sceneId: number;
    body: SceneConfigurationFile;
}

export interface SetSceneDeviceRequest {
    sceneId: number;
    body: DeviceAssignmentRequestBody;
}

export interface SetSceneLocalitiesRequest {
    sceneId: number;
    body: LocalityIdListModel;
}

export interface SetTrackerConfigRequest {
    sceneId: number;
    body: SceneConfigurationFile;
}

export interface UpdateCaptureSettingsRequest {
    sceneId: number;
    body: UpdateCaptureSettingsRequestBody;
}

export interface UpdateSceneRequest {
    sceneId: number;
    body: UpdateSceneRequestBody;
}

export interface UpdateSceneDescriptionRequest {
    sceneId: number;
    body: UpdateSceneDescriptionRequestBody;
}

export interface UpdateSceneSnapshotRequest {
    sceneId: number;
    body?: Blob;
}


/**
 * 
 */
export class LocalityScenesApi extends runtime.BaseAPI {
    readonly name = 'LocalityScenesApi'

    /**
     * Create a new scene.
     */
    addScene = Object.assign(
        async (requestParameters: AddSceneRequest, initOverrides?: RequestInit): Promise<SceneResponse> => {
            const response = await this.addScene.invokeRaw(requestParameters, initOverrides);
            return await response.value();
        },
        {
            invokeRaw: async (requestParameters: AddSceneRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SceneResponse>> => {
                if (requestParameters.body === null || requestParameters.body === undefined) {
                    throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling addScene.');
                }
            
                const queryParameters: any = {};
            
                const headerParameters: runtime.HTTPHeaders = {};
            
                headerParameters['Content-Type'] = 'application/json';
            
                if (this.configuration && this.configuration.accessToken) {
                    const token = this.configuration.accessToken;
                    const tokenString = await token("token", []);
            
                    if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                    }
                }
                const response = await this.request({
                    path: `/api/scenes`,
                    method: 'POST',
                    headers: headerParameters,
                    query: queryParameters,
                    body: CreateSceneRequestBodyToJSON(requestParameters.body),
                }, initOverrides);
            
                return new runtime.JSONApiResponse(response, (jsonValue) => SceneResponseFromJSON(jsonValue));
            },
            mutationKey: [
                'LocalityScenesApi',
                'addScene', 
            ],
            queryKey: (requestParameters: AddSceneRequest, initOverrides?: RequestInit) => ([
                    ...this.addScene.mutationKey, 
                {
                body: requestParameters.body,
                }
            ]),
            query: (requestParameters: AddSceneRequest, initOverrides?: RequestInit): UseQueryOptions<SceneResponse> & {queryKey: QueryKey} => ({
                queryFn: () => this.addScene(requestParameters, initOverrides),
                queryKey: this.addScene.queryKey(requestParameters, initOverrides)
            })
        }
    )

    /**
     * Delete a scene (no data will be permanently removed)
     */
    deleteScene = Object.assign(
        async (requestParameters: DeleteSceneRequest, initOverrides?: RequestInit): Promise<SuccessResponse> => {
            const response = await this.deleteScene.invokeRaw(requestParameters, initOverrides);
            return await response.value();
        },
        {
            invokeRaw: async (requestParameters: DeleteSceneRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SuccessResponse>> => {
                if (requestParameters.sceneId === null || requestParameters.sceneId === undefined) {
                    throw new runtime.RequiredError('sceneId','Required parameter requestParameters.sceneId was null or undefined when calling deleteScene.');
                }
            
                const queryParameters: any = {};
            
                const headerParameters: runtime.HTTPHeaders = {};
            
                if (this.configuration && this.configuration.accessToken) {
                    const token = this.configuration.accessToken;
                    const tokenString = await token("token", []);
            
                    if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                    }
                }
                const response = await this.request({
                    path: `/api/scenes/{scene_id}`.replace(`{${"scene_id"}}`, encodeURIComponent(String(requestParameters.sceneId))),
                    method: 'DELETE',
                    headers: headerParameters,
                    query: queryParameters,
                }, initOverrides);
            
                return new runtime.JSONApiResponse(response, (jsonValue) => SuccessResponseFromJSON(jsonValue));
            },
            mutationKey: [
                'LocalityScenesApi',
                'deleteScene', 
            ],
            queryKey: (requestParameters: DeleteSceneRequest, initOverrides?: RequestInit) => ([
                    ...this.deleteScene.mutationKey, 
                {
                sceneId: requestParameters.sceneId,
                }
            ]),
            query: (requestParameters: DeleteSceneRequest, initOverrides?: RequestInit): UseQueryOptions<SuccessResponse> & {queryKey: QueryKey} => ({
                queryFn: () => this.deleteScene(requestParameters, initOverrides),
                queryKey: this.deleteScene.queryKey(requestParameters, initOverrides)
            })
        }
    )

    /**
     * Delete a stored scene snapshot.
     */
    deleteSceneSnapshot = Object.assign(
        async (requestParameters: DeleteSceneSnapshotRequest, initOverrides?: RequestInit): Promise<SuccessResponse> => {
            const response = await this.deleteSceneSnapshot.invokeRaw(requestParameters, initOverrides);
            return await response.value();
        },
        {
            invokeRaw: async (requestParameters: DeleteSceneSnapshotRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SuccessResponse>> => {
                if (requestParameters.sceneId === null || requestParameters.sceneId === undefined) {
                    throw new runtime.RequiredError('sceneId','Required parameter requestParameters.sceneId was null or undefined when calling deleteSceneSnapshot.');
                }
            
                const queryParameters: any = {};
            
                const headerParameters: runtime.HTTPHeaders = {};
            
                if (this.configuration && this.configuration.accessToken) {
                    const token = this.configuration.accessToken;
                    const tokenString = await token("token", []);
            
                    if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                    }
                }
                const response = await this.request({
                    path: `/api/scenes/{scene_id}/snapshot`.replace(`{${"scene_id"}}`, encodeURIComponent(String(requestParameters.sceneId))),
                    method: 'DELETE',
                    headers: headerParameters,
                    query: queryParameters,
                }, initOverrides);
            
                return new runtime.JSONApiResponse(response, (jsonValue) => SuccessResponseFromJSON(jsonValue));
            },
            mutationKey: [
                'LocalityScenesApi',
                'deleteSceneSnapshot', 
            ],
            queryKey: (requestParameters: DeleteSceneSnapshotRequest, initOverrides?: RequestInit) => ([
                    ...this.deleteSceneSnapshot.mutationKey, 
                {
                sceneId: requestParameters.sceneId,
                }
            ]),
            query: (requestParameters: DeleteSceneSnapshotRequest, initOverrides?: RequestInit): UseQueryOptions<SuccessResponse> & {queryKey: QueryKey} => ({
                queryFn: () => this.deleteSceneSnapshot(requestParameters, initOverrides),
                queryKey: this.deleteSceneSnapshot.queryKey(requestParameters, initOverrides)
            })
        }
    )

    /**
     * Get a list of all scenes defined in Vividi.
     */
    getAllScenes = Object.assign(
        async (initOverrides?: RequestInit): Promise<SceneListResponse> => {
            const response = await this.getAllScenes.invokeRaw(initOverrides);
            return await response.value();
        },
        {
            invokeRaw: async (initOverrides?: RequestInit): Promise<runtime.ApiResponse<SceneListResponse>> => {
                const queryParameters: any = {};
            
                const headerParameters: runtime.HTTPHeaders = {};
            
                if (this.configuration && this.configuration.accessToken) {
                    const token = this.configuration.accessToken;
                    const tokenString = await token("token", []);
            
                    if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                    }
                }
                const response = await this.request({
                    path: `/api/scenes`,
                    method: 'GET',
                    headers: headerParameters,
                    query: queryParameters,
                }, initOverrides);
            
                return new runtime.JSONApiResponse(response, (jsonValue) => SceneListResponseFromJSON(jsonValue));
            },
            mutationKey: [
                'LocalityScenesApi',
                'getAllScenes', 
            ],
            queryKey: (initOverrides?: RequestInit) => ([
                    ...this.getAllScenes.mutationKey, 
                {
                }
            ]),
            query: (initOverrides?: RequestInit): UseQueryOptions<SceneListResponse> & {queryKey: QueryKey} => ({
                queryFn: () => this.getAllScenes(initOverrides),
                queryKey: this.getAllScenes.queryKey(initOverrides)
            })
        }
    )

    /**
     * Get box manager configuration for a scene.
     */
    getBoxManagerConfig = Object.assign(
        async (requestParameters: GetBoxManagerConfigRequest, initOverrides?: RequestInit): Promise<SceneConfigurationFile> => {
            const response = await this.getBoxManagerConfig.invokeRaw(requestParameters, initOverrides);
            return await response.value();
        },
        {
            invokeRaw: async (requestParameters: GetBoxManagerConfigRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SceneConfigurationFile>> => {
                if (requestParameters.sceneId === null || requestParameters.sceneId === undefined) {
                    throw new runtime.RequiredError('sceneId','Required parameter requestParameters.sceneId was null or undefined when calling getBoxManagerConfig.');
                }
            
                const queryParameters: any = {};
            
                const headerParameters: runtime.HTTPHeaders = {};
            
                if (this.configuration && this.configuration.accessToken) {
                    const token = this.configuration.accessToken;
                    const tokenString = await token("token", []);
            
                    if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                    }
                }
                const response = await this.request({
                    path: `/api/scenes/{scene_id}/box-manager-config`.replace(`{${"scene_id"}}`, encodeURIComponent(String(requestParameters.sceneId))),
                    method: 'GET',
                    headers: headerParameters,
                    query: queryParameters,
                }, initOverrides);
            
                return new runtime.JSONApiResponse(response, (jsonValue) => SceneConfigurationFileFromJSON(jsonValue));
            },
            mutationKey: [
                'LocalityScenesApi',
                'getBoxManagerConfig', 
            ],
            queryKey: (requestParameters: GetBoxManagerConfigRequest, initOverrides?: RequestInit) => ([
                    ...this.getBoxManagerConfig.mutationKey, 
                {
                sceneId: requestParameters.sceneId,
                }
            ]),
            query: (requestParameters: GetBoxManagerConfigRequest, initOverrides?: RequestInit): UseQueryOptions<SceneConfigurationFile> & {queryKey: QueryKey} => ({
                queryFn: () => this.getBoxManagerConfig(requestParameters, initOverrides),
                queryKey: this.getBoxManagerConfig.queryKey(requestParameters, initOverrides)
            })
        }
    )

    /**
     * Draw and return a heatmap of visit origins and ends over the scene snapshot (if available).
     */
    getEndpointHeatmap = Object.assign(
        async (requestParameters: GetEndpointHeatmapRequest, initOverrides?: RequestInit): Promise<Blob> => {
            const response = await this.getEndpointHeatmap.invokeRaw(requestParameters, initOverrides);
            return await response.value();
        },
        {
            invokeRaw: async (requestParameters: GetEndpointHeatmapRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Blob>> => {
                if (requestParameters.sceneId === null || requestParameters.sceneId === undefined) {
                    throw new runtime.RequiredError('sceneId','Required parameter requestParameters.sceneId was null or undefined when calling getEndpointHeatmap.');
                }
            
                const queryParameters: any = {};
            
                const headerParameters: runtime.HTTPHeaders = {};
            
                if (this.configuration && this.configuration.accessToken) {
                    const token = this.configuration.accessToken;
                    const tokenString = await token("token", []);
            
                    if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                    }
                }
                const response = await this.request({
                    path: `/api/scenes/{scene_id}/endpoint-heatmap`.replace(`{${"scene_id"}}`, encodeURIComponent(String(requestParameters.sceneId))),
                    method: 'POST',
                    headers: headerParameters,
                    query: queryParameters,
                }, initOverrides);
            
                return new runtime.BlobApiResponse(response);
            },
            mutationKey: [
                'LocalityScenesApi',
                'getEndpointHeatmap', 
            ],
            queryKey: (requestParameters: GetEndpointHeatmapRequest, initOverrides?: RequestInit) => ([
                    ...this.getEndpointHeatmap.mutationKey, 
                {
                sceneId: requestParameters.sceneId,
                }
            ]),
            query: (requestParameters: GetEndpointHeatmapRequest, initOverrides?: RequestInit): UseQueryOptions<Blob> & {queryKey: QueryKey} => ({
                queryFn: () => this.getEndpointHeatmap(requestParameters, initOverrides),
                queryKey: this.getEndpointHeatmap.queryKey(requestParameters, initOverrides)
            })
        }
    )

    /**
     * Get information for a single scene.
     */
    getScene = Object.assign(
        async (requestParameters: GetSceneRequest, initOverrides?: RequestInit): Promise<SceneResponse> => {
            const response = await this.getScene.invokeRaw(requestParameters, initOverrides);
            return await response.value();
        },
        {
            invokeRaw: async (requestParameters: GetSceneRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SceneResponse>> => {
                if (requestParameters.sceneId === null || requestParameters.sceneId === undefined) {
                    throw new runtime.RequiredError('sceneId','Required parameter requestParameters.sceneId was null or undefined when calling getScene.');
                }
            
                const queryParameters: any = {};
            
                const headerParameters: runtime.HTTPHeaders = {};
            
                if (this.configuration && this.configuration.accessToken) {
                    const token = this.configuration.accessToken;
                    const tokenString = await token("token", []);
            
                    if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                    }
                }
                const response = await this.request({
                    path: `/api/scenes/{scene_id}`.replace(`{${"scene_id"}}`, encodeURIComponent(String(requestParameters.sceneId))),
                    method: 'GET',
                    headers: headerParameters,
                    query: queryParameters,
                }, initOverrides);
            
                return new runtime.JSONApiResponse(response, (jsonValue) => SceneResponseFromJSON(jsonValue));
            },
            mutationKey: [
                'LocalityScenesApi',
                'getScene', 
            ],
            queryKey: (requestParameters: GetSceneRequest, initOverrides?: RequestInit) => ([
                    ...this.getScene.mutationKey, 
                {
                sceneId: requestParameters.sceneId,
                }
            ]),
            query: (requestParameters: GetSceneRequest, initOverrides?: RequestInit): UseQueryOptions<SceneResponse> & {queryKey: QueryKey} => ({
                queryFn: () => this.getScene(requestParameters, initOverrides),
                queryKey: this.getScene.queryKey(requestParameters, initOverrides)
            })
        }
    )

    /**
     * Get the scene description (visit boundaries, zones, ...).
     */
    getSceneDescription = Object.assign(
        async (requestParameters: GetSceneDescriptionRequest, initOverrides?: RequestInit): Promise<SceneDescription> => {
            const response = await this.getSceneDescription.invokeRaw(requestParameters, initOverrides);
            return await response.value();
        },
        {
            invokeRaw: async (requestParameters: GetSceneDescriptionRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SceneDescription>> => {
                if (requestParameters.sceneId === null || requestParameters.sceneId === undefined) {
                    throw new runtime.RequiredError('sceneId','Required parameter requestParameters.sceneId was null or undefined when calling getSceneDescription.');
                }
            
                const queryParameters: any = {};
            
                const headerParameters: runtime.HTTPHeaders = {};
            
                if (this.configuration && this.configuration.accessToken) {
                    const token = this.configuration.accessToken;
                    const tokenString = await token("token", []);
            
                    if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                    }
                }
                const response = await this.request({
                    path: `/api/scenes/{scene_id}/scene-description`.replace(`{${"scene_id"}}`, encodeURIComponent(String(requestParameters.sceneId))),
                    method: 'GET',
                    headers: headerParameters,
                    query: queryParameters,
                }, initOverrides);
            
                return new runtime.JSONApiResponse(response, (jsonValue) => SceneDescriptionFromJSON(jsonValue));
            },
            mutationKey: [
                'LocalityScenesApi',
                'getSceneDescription', 
            ],
            queryKey: (requestParameters: GetSceneDescriptionRequest, initOverrides?: RequestInit) => ([
                    ...this.getSceneDescription.mutationKey, 
                {
                sceneId: requestParameters.sceneId,
                }
            ]),
            query: (requestParameters: GetSceneDescriptionRequest, initOverrides?: RequestInit): UseQueryOptions<SceneDescription> & {queryKey: QueryKey} => ({
                queryFn: () => this.getSceneDescription(requestParameters, initOverrides),
                queryKey: this.getSceneDescription.queryKey(requestParameters, initOverrides)
            })
        }
    )

    /**
     * Get the list of all devices that have been assigned to the device through the course of time (including the current one).
     */
    getSceneDeviceHistory = Object.assign(
        async (requestParameters: GetSceneDeviceHistoryRequest, initOverrides?: RequestInit): Promise<SceneDeviceAssignmentListResponse> => {
            const response = await this.getSceneDeviceHistory.invokeRaw(requestParameters, initOverrides);
            return await response.value();
        },
        {
            invokeRaw: async (requestParameters: GetSceneDeviceHistoryRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SceneDeviceAssignmentListResponse>> => {
                if (requestParameters.sceneId === null || requestParameters.sceneId === undefined) {
                    throw new runtime.RequiredError('sceneId','Required parameter requestParameters.sceneId was null or undefined when calling getSceneDeviceHistory.');
                }
            
                const queryParameters: any = {};
            
                const headerParameters: runtime.HTTPHeaders = {};
            
                if (this.configuration && this.configuration.accessToken) {
                    const token = this.configuration.accessToken;
                    const tokenString = await token("token", []);
            
                    if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                    }
                }
                const response = await this.request({
                    path: `/api/scenes/{scene_id}/device-history`.replace(`{${"scene_id"}}`, encodeURIComponent(String(requestParameters.sceneId))),
                    method: 'GET',
                    headers: headerParameters,
                    query: queryParameters,
                }, initOverrides);
            
                return new runtime.JSONApiResponse(response, (jsonValue) => SceneDeviceAssignmentListResponseFromJSON(jsonValue));
            },
            mutationKey: [
                'LocalityScenesApi',
                'getSceneDeviceHistory', 
            ],
            queryKey: (requestParameters: GetSceneDeviceHistoryRequest, initOverrides?: RequestInit) => ([
                    ...this.getSceneDeviceHistory.mutationKey, 
                {
                sceneId: requestParameters.sceneId,
                }
            ]),
            query: (requestParameters: GetSceneDeviceHistoryRequest, initOverrides?: RequestInit): UseQueryOptions<SceneDeviceAssignmentListResponse> & {queryKey: QueryKey} => ({
                queryFn: () => this.getSceneDeviceHistory(requestParameters, initOverrides),
                queryKey: this.getSceneDeviceHistory.queryKey(requestParameters, initOverrides)
            })
        }
    )

    /**
     * Get the stored snapshot image for a scene.
     */
    getSceneSnapshot = Object.assign(
        async (requestParameters: GetSceneSnapshotRequest, initOverrides?: RequestInit): Promise<Blob> => {
            const response = await this.getSceneSnapshot.invokeRaw(requestParameters, initOverrides);
            return await response.value();
        },
        {
            invokeRaw: async (requestParameters: GetSceneSnapshotRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Blob>> => {
                if (requestParameters.sceneId === null || requestParameters.sceneId === undefined) {
                    throw new runtime.RequiredError('sceneId','Required parameter requestParameters.sceneId was null or undefined when calling getSceneSnapshot.');
                }
            
                const queryParameters: any = {};
            
                const headerParameters: runtime.HTTPHeaders = {};
            
                if (this.configuration && this.configuration.accessToken) {
                    const token = this.configuration.accessToken;
                    const tokenString = await token("token", []);
            
                    if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                    }
                }
                const response = await this.request({
                    path: `/api/scenes/{scene_id}/snapshot`.replace(`{${"scene_id"}}`, encodeURIComponent(String(requestParameters.sceneId))),
                    method: 'GET',
                    headers: headerParameters,
                    query: queryParameters,
                }, initOverrides);
            
                return new runtime.BlobApiResponse(response);
            },
            mutationKey: [
                'LocalityScenesApi',
                'getSceneSnapshot', 
            ],
            queryKey: (requestParameters: GetSceneSnapshotRequest, initOverrides?: RequestInit) => ([
                    ...this.getSceneSnapshot.mutationKey, 
                {
                sceneId: requestParameters.sceneId,
                }
            ]),
            query: (requestParameters: GetSceneSnapshotRequest, initOverrides?: RequestInit): UseQueryOptions<Blob> & {queryKey: QueryKey} => ({
                queryFn: () => this.getSceneSnapshot(requestParameters, initOverrides),
                queryKey: this.getSceneSnapshot.queryKey(requestParameters, initOverrides)
            })
        }
    )

    /**
     * Get tracker configuration for a scene.
     */
    getTrackerConfig = Object.assign(
        async (requestParameters: GetTrackerConfigRequest, initOverrides?: RequestInit): Promise<SceneConfigurationFile> => {
            const response = await this.getTrackerConfig.invokeRaw(requestParameters, initOverrides);
            return await response.value();
        },
        {
            invokeRaw: async (requestParameters: GetTrackerConfigRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SceneConfigurationFile>> => {
                if (requestParameters.sceneId === null || requestParameters.sceneId === undefined) {
                    throw new runtime.RequiredError('sceneId','Required parameter requestParameters.sceneId was null or undefined when calling getTrackerConfig.');
                }
            
                const queryParameters: any = {};
            
                const headerParameters: runtime.HTTPHeaders = {};
            
                if (this.configuration && this.configuration.accessToken) {
                    const token = this.configuration.accessToken;
                    const tokenString = await token("token", []);
            
                    if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                    }
                }
                const response = await this.request({
                    path: `/api/scenes/{scene_id}/tracker-config`.replace(`{${"scene_id"}}`, encodeURIComponent(String(requestParameters.sceneId))),
                    method: 'GET',
                    headers: headerParameters,
                    query: queryParameters,
                }, initOverrides);
            
                return new runtime.JSONApiResponse(response, (jsonValue) => SceneConfigurationFileFromJSON(jsonValue));
            },
            mutationKey: [
                'LocalityScenesApi',
                'getTrackerConfig', 
            ],
            queryKey: (requestParameters: GetTrackerConfigRequest, initOverrides?: RequestInit) => ([
                    ...this.getTrackerConfig.mutationKey, 
                {
                sceneId: requestParameters.sceneId,
                }
            ]),
            query: (requestParameters: GetTrackerConfigRequest, initOverrides?: RequestInit): UseQueryOptions<SceneConfigurationFile> & {queryKey: QueryKey} => ({
                queryFn: () => this.getTrackerConfig(requestParameters, initOverrides),
                queryKey: this.getTrackerConfig.queryKey(requestParameters, initOverrides)
            })
        }
    )

    /**
     * Draw and return a heatmap of visit trajectories over the scene snapshot (if available).
     */
    getTrajectoryHeatmap = Object.assign(
        async (requestParameters: GetTrajectoryHeatmapRequest, initOverrides?: RequestInit): Promise<Blob> => {
            const response = await this.getTrajectoryHeatmap.invokeRaw(requestParameters, initOverrides);
            return await response.value();
        },
        {
            invokeRaw: async (requestParameters: GetTrajectoryHeatmapRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Blob>> => {
                if (requestParameters.sceneId === null || requestParameters.sceneId === undefined) {
                    throw new runtime.RequiredError('sceneId','Required parameter requestParameters.sceneId was null or undefined when calling getTrajectoryHeatmap.');
                }
            
                const queryParameters: any = {};
            
                const headerParameters: runtime.HTTPHeaders = {};
            
                if (this.configuration && this.configuration.accessToken) {
                    const token = this.configuration.accessToken;
                    const tokenString = await token("token", []);
            
                    if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                    }
                }
                const response = await this.request({
                    path: `/api/scenes/{scene_id}/trajectory-heatmap`.replace(`{${"scene_id"}}`, encodeURIComponent(String(requestParameters.sceneId))),
                    method: 'GET',
                    headers: headerParameters,
                    query: queryParameters,
                }, initOverrides);
            
                return new runtime.BlobApiResponse(response);
            },
            mutationKey: [
                'LocalityScenesApi',
                'getTrajectoryHeatmap', 
            ],
            queryKey: (requestParameters: GetTrajectoryHeatmapRequest, initOverrides?: RequestInit) => ([
                    ...this.getTrajectoryHeatmap.mutationKey, 
                {
                sceneId: requestParameters.sceneId,
                }
            ]),
            query: (requestParameters: GetTrajectoryHeatmapRequest, initOverrides?: RequestInit): UseQueryOptions<Blob> & {queryKey: QueryKey} => ({
                queryFn: () => this.getTrajectoryHeatmap(requestParameters, initOverrides),
                queryKey: this.getTrajectoryHeatmap.queryKey(requestParameters, initOverrides)
            })
        }
    )

    /**
     * Draw and return an up-to-date heatmap made of at most 1000 visit trajectories from the last 24 hours.
     */
    getTrajectoryHeatmapSample = Object.assign(
        async (requestParameters: GetTrajectoryHeatmapSampleRequest, initOverrides?: RequestInit): Promise<Blob> => {
            const response = await this.getTrajectoryHeatmapSample.invokeRaw(requestParameters, initOverrides);
            return await response.value();
        },
        {
            invokeRaw: async (requestParameters: GetTrajectoryHeatmapSampleRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Blob>> => {
                if (requestParameters.sceneId === null || requestParameters.sceneId === undefined) {
                    throw new runtime.RequiredError('sceneId','Required parameter requestParameters.sceneId was null or undefined when calling getTrajectoryHeatmapSample.');
                }
            
                const queryParameters: any = {};
            
                const headerParameters: runtime.HTTPHeaders = {};
            
                if (this.configuration && this.configuration.accessToken) {
                    const token = this.configuration.accessToken;
                    const tokenString = await token("token", []);
            
                    if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                    }
                }
                const response = await this.request({
                    path: `/api/scenes/{scene_id}/trajectory-heatmap-sample`.replace(`{${"scene_id"}}`, encodeURIComponent(String(requestParameters.sceneId))),
                    method: 'POST',
                    headers: headerParameters,
                    query: queryParameters,
                }, initOverrides);
            
                return new runtime.BlobApiResponse(response);
            },
            mutationKey: [
                'LocalityScenesApi',
                'getTrajectoryHeatmapSample', 
            ],
            queryKey: (requestParameters: GetTrajectoryHeatmapSampleRequest, initOverrides?: RequestInit) => ([
                    ...this.getTrajectoryHeatmapSample.mutationKey, 
                {
                sceneId: requestParameters.sceneId,
                }
            ]),
            query: (requestParameters: GetTrajectoryHeatmapSampleRequest, initOverrides?: RequestInit): UseQueryOptions<Blob> & {queryKey: QueryKey} => ({
                queryFn: () => this.getTrajectoryHeatmapSample(requestParameters, initOverrides),
                queryKey: this.getTrajectoryHeatmapSample.queryKey(requestParameters, initOverrides)
            })
        }
    )

    /**
     * Remove any device assigned to a scene.
     */
    removeSceneDevice = Object.assign(
        async (requestParameters: RemoveSceneDeviceRequest, initOverrides?: RequestInit): Promise<SceneResponse> => {
            const response = await this.removeSceneDevice.invokeRaw(requestParameters, initOverrides);
            return await response.value();
        },
        {
            invokeRaw: async (requestParameters: RemoveSceneDeviceRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SceneResponse>> => {
                if (requestParameters.sceneId === null || requestParameters.sceneId === undefined) {
                    throw new runtime.RequiredError('sceneId','Required parameter requestParameters.sceneId was null or undefined when calling removeSceneDevice.');
                }
            
                const queryParameters: any = {};
            
                const headerParameters: runtime.HTTPHeaders = {};
            
                if (this.configuration && this.configuration.accessToken) {
                    const token = this.configuration.accessToken;
                    const tokenString = await token("token", []);
            
                    if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                    }
                }
                const response = await this.request({
                    path: `/api/scenes/{scene_id}/device`.replace(`{${"scene_id"}}`, encodeURIComponent(String(requestParameters.sceneId))),
                    method: 'DELETE',
                    headers: headerParameters,
                    query: queryParameters,
                }, initOverrides);
            
                return new runtime.JSONApiResponse(response, (jsonValue) => SceneResponseFromJSON(jsonValue));
            },
            mutationKey: [
                'LocalityScenesApi',
                'removeSceneDevice', 
            ],
            queryKey: (requestParameters: RemoveSceneDeviceRequest, initOverrides?: RequestInit) => ([
                    ...this.removeSceneDevice.mutationKey, 
                {
                sceneId: requestParameters.sceneId,
                }
            ]),
            query: (requestParameters: RemoveSceneDeviceRequest, initOverrides?: RequestInit): UseQueryOptions<SceneResponse> & {queryKey: QueryKey} => ({
                queryFn: () => this.removeSceneDevice(requestParameters, initOverrides),
                queryKey: this.removeSceneDevice.queryKey(requestParameters, initOverrides)
            })
        }
    )

    /**
     * Set box manager configuration for a scene.
     */
    setBoxManagerConfig = Object.assign(
        async (requestParameters: SetBoxManagerConfigRequest, initOverrides?: RequestInit): Promise<SuccessResponse> => {
            const response = await this.setBoxManagerConfig.invokeRaw(requestParameters, initOverrides);
            return await response.value();
        },
        {
            invokeRaw: async (requestParameters: SetBoxManagerConfigRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SuccessResponse>> => {
                if (requestParameters.sceneId === null || requestParameters.sceneId === undefined) {
                    throw new runtime.RequiredError('sceneId','Required parameter requestParameters.sceneId was null or undefined when calling setBoxManagerConfig.');
                }
            
                if (requestParameters.body === null || requestParameters.body === undefined) {
                    throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling setBoxManagerConfig.');
                }
            
                const queryParameters: any = {};
            
                const headerParameters: runtime.HTTPHeaders = {};
            
                headerParameters['Content-Type'] = 'application/json';
            
                if (this.configuration && this.configuration.accessToken) {
                    const token = this.configuration.accessToken;
                    const tokenString = await token("token", []);
            
                    if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                    }
                }
                const response = await this.request({
                    path: `/api/scenes/{scene_id}/box-manager-config`.replace(`{${"scene_id"}}`, encodeURIComponent(String(requestParameters.sceneId))),
                    method: 'PUT',
                    headers: headerParameters,
                    query: queryParameters,
                    body: SceneConfigurationFileToJSON(requestParameters.body),
                }, initOverrides);
            
                return new runtime.JSONApiResponse(response, (jsonValue) => SuccessResponseFromJSON(jsonValue));
            },
            mutationKey: [
                'LocalityScenesApi',
                'setBoxManagerConfig', 
            ],
            queryKey: (requestParameters: SetBoxManagerConfigRequest, initOverrides?: RequestInit) => ([
                    ...this.setBoxManagerConfig.mutationKey, 
                {
                sceneId: requestParameters.sceneId,
                body: requestParameters.body,
                }
            ]),
            query: (requestParameters: SetBoxManagerConfigRequest, initOverrides?: RequestInit): UseQueryOptions<SuccessResponse> & {queryKey: QueryKey} => ({
                queryFn: () => this.setBoxManagerConfig(requestParameters, initOverrides),
                queryKey: this.setBoxManagerConfig.queryKey(requestParameters, initOverrides)
            })
        }
    )

    /**
     * Assign a device to a scene.
     */
    setSceneDevice = Object.assign(
        async (requestParameters: SetSceneDeviceRequest, initOverrides?: RequestInit): Promise<SceneResponse> => {
            const response = await this.setSceneDevice.invokeRaw(requestParameters, initOverrides);
            return await response.value();
        },
        {
            invokeRaw: async (requestParameters: SetSceneDeviceRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SceneResponse>> => {
                if (requestParameters.sceneId === null || requestParameters.sceneId === undefined) {
                    throw new runtime.RequiredError('sceneId','Required parameter requestParameters.sceneId was null or undefined when calling setSceneDevice.');
                }
            
                if (requestParameters.body === null || requestParameters.body === undefined) {
                    throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling setSceneDevice.');
                }
            
                const queryParameters: any = {};
            
                const headerParameters: runtime.HTTPHeaders = {};
            
                headerParameters['Content-Type'] = 'application/json';
            
                if (this.configuration && this.configuration.accessToken) {
                    const token = this.configuration.accessToken;
                    const tokenString = await token("token", []);
            
                    if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                    }
                }
                const response = await this.request({
                    path: `/api/scenes/{scene_id}/device`.replace(`{${"scene_id"}}`, encodeURIComponent(String(requestParameters.sceneId))),
                    method: 'POST',
                    headers: headerParameters,
                    query: queryParameters,
                    body: DeviceAssignmentRequestBodyToJSON(requestParameters.body),
                }, initOverrides);
            
                return new runtime.JSONApiResponse(response, (jsonValue) => SceneResponseFromJSON(jsonValue));
            },
            mutationKey: [
                'LocalityScenesApi',
                'setSceneDevice', 
            ],
            queryKey: (requestParameters: SetSceneDeviceRequest, initOverrides?: RequestInit) => ([
                    ...this.setSceneDevice.mutationKey, 
                {
                sceneId: requestParameters.sceneId,
                body: requestParameters.body,
                }
            ]),
            query: (requestParameters: SetSceneDeviceRequest, initOverrides?: RequestInit): UseQueryOptions<SceneResponse> & {queryKey: QueryKey} => ({
                queryFn: () => this.setSceneDevice(requestParameters, initOverrides),
                queryKey: this.setSceneDevice.queryKey(requestParameters, initOverrides)
            })
        }
    )

    /**
     * Set the set of localities a scene belongs to.
     */
    setSceneLocalities = Object.assign(
        async (requestParameters: SetSceneLocalitiesRequest, initOverrides?: RequestInit): Promise<SceneResponse> => {
            const response = await this.setSceneLocalities.invokeRaw(requestParameters, initOverrides);
            return await response.value();
        },
        {
            invokeRaw: async (requestParameters: SetSceneLocalitiesRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SceneResponse>> => {
                if (requestParameters.sceneId === null || requestParameters.sceneId === undefined) {
                    throw new runtime.RequiredError('sceneId','Required parameter requestParameters.sceneId was null or undefined when calling setSceneLocalities.');
                }
            
                if (requestParameters.body === null || requestParameters.body === undefined) {
                    throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling setSceneLocalities.');
                }
            
                const queryParameters: any = {};
            
                const headerParameters: runtime.HTTPHeaders = {};
            
                headerParameters['Content-Type'] = 'application/json';
            
                if (this.configuration && this.configuration.accessToken) {
                    const token = this.configuration.accessToken;
                    const tokenString = await token("token", []);
            
                    if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                    }
                }
                const response = await this.request({
                    path: `/api/scenes/{scene_id}/localities`.replace(`{${"scene_id"}}`, encodeURIComponent(String(requestParameters.sceneId))),
                    method: 'PUT',
                    headers: headerParameters,
                    query: queryParameters,
                    body: LocalityIdListModelToJSON(requestParameters.body),
                }, initOverrides);
            
                return new runtime.JSONApiResponse(response, (jsonValue) => SceneResponseFromJSON(jsonValue));
            },
            mutationKey: [
                'LocalityScenesApi',
                'setSceneLocalities', 
            ],
            queryKey: (requestParameters: SetSceneLocalitiesRequest, initOverrides?: RequestInit) => ([
                    ...this.setSceneLocalities.mutationKey, 
                {
                sceneId: requestParameters.sceneId,
                body: requestParameters.body,
                }
            ]),
            query: (requestParameters: SetSceneLocalitiesRequest, initOverrides?: RequestInit): UseQueryOptions<SceneResponse> & {queryKey: QueryKey} => ({
                queryFn: () => this.setSceneLocalities(requestParameters, initOverrides),
                queryKey: this.setSceneLocalities.queryKey(requestParameters, initOverrides)
            })
        }
    )

    /**
     * Set tracker configuration for a scene.
     */
    setTrackerConfig = Object.assign(
        async (requestParameters: SetTrackerConfigRequest, initOverrides?: RequestInit): Promise<SuccessResponse> => {
            const response = await this.setTrackerConfig.invokeRaw(requestParameters, initOverrides);
            return await response.value();
        },
        {
            invokeRaw: async (requestParameters: SetTrackerConfigRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SuccessResponse>> => {
                if (requestParameters.sceneId === null || requestParameters.sceneId === undefined) {
                    throw new runtime.RequiredError('sceneId','Required parameter requestParameters.sceneId was null or undefined when calling setTrackerConfig.');
                }
            
                if (requestParameters.body === null || requestParameters.body === undefined) {
                    throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling setTrackerConfig.');
                }
            
                const queryParameters: any = {};
            
                const headerParameters: runtime.HTTPHeaders = {};
            
                headerParameters['Content-Type'] = 'application/json';
            
                if (this.configuration && this.configuration.accessToken) {
                    const token = this.configuration.accessToken;
                    const tokenString = await token("token", []);
            
                    if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                    }
                }
                const response = await this.request({
                    path: `/api/scenes/{scene_id}/tracker-config`.replace(`{${"scene_id"}}`, encodeURIComponent(String(requestParameters.sceneId))),
                    method: 'PUT',
                    headers: headerParameters,
                    query: queryParameters,
                    body: SceneConfigurationFileToJSON(requestParameters.body),
                }, initOverrides);
            
                return new runtime.JSONApiResponse(response, (jsonValue) => SuccessResponseFromJSON(jsonValue));
            },
            mutationKey: [
                'LocalityScenesApi',
                'setTrackerConfig', 
            ],
            queryKey: (requestParameters: SetTrackerConfigRequest, initOverrides?: RequestInit) => ([
                    ...this.setTrackerConfig.mutationKey, 
                {
                sceneId: requestParameters.sceneId,
                body: requestParameters.body,
                }
            ]),
            query: (requestParameters: SetTrackerConfigRequest, initOverrides?: RequestInit): UseQueryOptions<SuccessResponse> & {queryKey: QueryKey} => ({
                queryFn: () => this.setTrackerConfig(requestParameters, initOverrides),
                queryKey: this.setTrackerConfig.queryKey(requestParameters, initOverrides)
            })
        }
    )

    /**
     * Update scene capture settings
     */
    updateCaptureSettings = Object.assign(
        async (requestParameters: UpdateCaptureSettingsRequest, initOverrides?: RequestInit): Promise<SceneResponse> => {
            const response = await this.updateCaptureSettings.invokeRaw(requestParameters, initOverrides);
            return await response.value();
        },
        {
            invokeRaw: async (requestParameters: UpdateCaptureSettingsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SceneResponse>> => {
                if (requestParameters.sceneId === null || requestParameters.sceneId === undefined) {
                    throw new runtime.RequiredError('sceneId','Required parameter requestParameters.sceneId was null or undefined when calling updateCaptureSettings.');
                }
            
                if (requestParameters.body === null || requestParameters.body === undefined) {
                    throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling updateCaptureSettings.');
                }
            
                const queryParameters: any = {};
            
                const headerParameters: runtime.HTTPHeaders = {};
            
                headerParameters['Content-Type'] = 'application/json';
            
                if (this.configuration && this.configuration.accessToken) {
                    const token = this.configuration.accessToken;
                    const tokenString = await token("token", []);
            
                    if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                    }
                }
                const response = await this.request({
                    path: `/api/scenes/{scene_id}/capture-settings`.replace(`{${"scene_id"}}`, encodeURIComponent(String(requestParameters.sceneId))),
                    method: 'PUT',
                    headers: headerParameters,
                    query: queryParameters,
                    body: UpdateCaptureSettingsRequestBodyToJSON(requestParameters.body),
                }, initOverrides);
            
                return new runtime.JSONApiResponse(response, (jsonValue) => SceneResponseFromJSON(jsonValue));
            },
            mutationKey: [
                'LocalityScenesApi',
                'updateCaptureSettings', 
            ],
            queryKey: (requestParameters: UpdateCaptureSettingsRequest, initOverrides?: RequestInit) => ([
                    ...this.updateCaptureSettings.mutationKey, 
                {
                sceneId: requestParameters.sceneId,
                body: requestParameters.body,
                }
            ]),
            query: (requestParameters: UpdateCaptureSettingsRequest, initOverrides?: RequestInit): UseQueryOptions<SceneResponse> & {queryKey: QueryKey} => ({
                queryFn: () => this.updateCaptureSettings(requestParameters, initOverrides),
                queryKey: this.updateCaptureSettings.queryKey(requestParameters, initOverrides)
            })
        }
    )

    /**
     * Update scene metadata
     */
    updateScene = Object.assign(
        async (requestParameters: UpdateSceneRequest, initOverrides?: RequestInit): Promise<SceneResponse> => {
            const response = await this.updateScene.invokeRaw(requestParameters, initOverrides);
            return await response.value();
        },
        {
            invokeRaw: async (requestParameters: UpdateSceneRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SceneResponse>> => {
                if (requestParameters.sceneId === null || requestParameters.sceneId === undefined) {
                    throw new runtime.RequiredError('sceneId','Required parameter requestParameters.sceneId was null or undefined when calling updateScene.');
                }
            
                if (requestParameters.body === null || requestParameters.body === undefined) {
                    throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling updateScene.');
                }
            
                const queryParameters: any = {};
            
                const headerParameters: runtime.HTTPHeaders = {};
            
                headerParameters['Content-Type'] = 'application/json';
            
                if (this.configuration && this.configuration.accessToken) {
                    const token = this.configuration.accessToken;
                    const tokenString = await token("token", []);
            
                    if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                    }
                }
                const response = await this.request({
                    path: `/api/scenes/{scene_id}`.replace(`{${"scene_id"}}`, encodeURIComponent(String(requestParameters.sceneId))),
                    method: 'PUT',
                    headers: headerParameters,
                    query: queryParameters,
                    body: UpdateSceneRequestBodyToJSON(requestParameters.body),
                }, initOverrides);
            
                return new runtime.JSONApiResponse(response, (jsonValue) => SceneResponseFromJSON(jsonValue));
            },
            mutationKey: [
                'LocalityScenesApi',
                'updateScene', 
            ],
            queryKey: (requestParameters: UpdateSceneRequest, initOverrides?: RequestInit) => ([
                    ...this.updateScene.mutationKey, 
                {
                sceneId: requestParameters.sceneId,
                body: requestParameters.body,
                }
            ]),
            query: (requestParameters: UpdateSceneRequest, initOverrides?: RequestInit): UseQueryOptions<SceneResponse> & {queryKey: QueryKey} => ({
                queryFn: () => this.updateScene(requestParameters, initOverrides),
                queryKey: this.updateScene.queryKey(requestParameters, initOverrides)
            })
        }
    )

    /**
     * Update the scene configuration for a single device.
     * The order of visit boundaries and detection zones is guaranteed to be the same in the request and response body.
     */
    updateSceneDescription = Object.assign(
        async (requestParameters: UpdateSceneDescriptionRequest, initOverrides?: RequestInit): Promise<SceneDescription> => {
            const response = await this.updateSceneDescription.invokeRaw(requestParameters, initOverrides);
            return await response.value();
        },
        {
            invokeRaw: async (requestParameters: UpdateSceneDescriptionRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SceneDescription>> => {
                if (requestParameters.sceneId === null || requestParameters.sceneId === undefined) {
                    throw new runtime.RequiredError('sceneId','Required parameter requestParameters.sceneId was null or undefined when calling updateSceneDescription.');
                }
            
                if (requestParameters.body === null || requestParameters.body === undefined) {
                    throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling updateSceneDescription.');
                }
            
                const queryParameters: any = {};
            
                const headerParameters: runtime.HTTPHeaders = {};
            
                headerParameters['Content-Type'] = 'application/json';
            
                if (this.configuration && this.configuration.accessToken) {
                    const token = this.configuration.accessToken;
                    const tokenString = await token("token", []);
            
                    if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                    }
                }
                const response = await this.request({
                    path: `/api/scenes/{scene_id}/scene-description`.replace(`{${"scene_id"}}`, encodeURIComponent(String(requestParameters.sceneId))),
                    method: 'PUT',
                    headers: headerParameters,
                    query: queryParameters,
                    body: UpdateSceneDescriptionRequestBodyToJSON(requestParameters.body),
                }, initOverrides);
            
                return new runtime.JSONApiResponse(response, (jsonValue) => SceneDescriptionFromJSON(jsonValue));
            },
            mutationKey: [
                'LocalityScenesApi',
                'updateSceneDescription', 
            ],
            queryKey: (requestParameters: UpdateSceneDescriptionRequest, initOverrides?: RequestInit) => ([
                    ...this.updateSceneDescription.mutationKey, 
                {
                sceneId: requestParameters.sceneId,
                body: requestParameters.body,
                }
            ]),
            query: (requestParameters: UpdateSceneDescriptionRequest, initOverrides?: RequestInit): UseQueryOptions<SceneDescription> & {queryKey: QueryKey} => ({
                queryFn: () => this.updateSceneDescription(requestParameters, initOverrides),
                queryKey: this.updateSceneDescription.queryKey(requestParameters, initOverrides)
            })
        }
    )

    /**
     * Upload a new scene snapshot.
     */
    updateSceneSnapshot = Object.assign(
        async (requestParameters: UpdateSceneSnapshotRequest, initOverrides?: RequestInit): Promise<SuccessResponse> => {
            const response = await this.updateSceneSnapshot.invokeRaw(requestParameters, initOverrides);
            return await response.value();
        },
        {
            invokeRaw: async (requestParameters: UpdateSceneSnapshotRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SuccessResponse>> => {
                if (requestParameters.sceneId === null || requestParameters.sceneId === undefined) {
                    throw new runtime.RequiredError('sceneId','Required parameter requestParameters.sceneId was null or undefined when calling updateSceneSnapshot.');
                }
            
                const queryParameters: any = {};
            
                const headerParameters: runtime.HTTPHeaders = {};
            
                headerParameters['Content-Type'] = 'image/png';
            
                if (this.configuration && this.configuration.accessToken) {
                    const token = this.configuration.accessToken;
                    const tokenString = await token("token", []);
            
                    if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                    }
                }
                const response = await this.request({
                    path: `/api/scenes/{scene_id}/snapshot`.replace(`{${"scene_id"}}`, encodeURIComponent(String(requestParameters.sceneId))),
                    method: 'PUT',
                    headers: headerParameters,
                    query: queryParameters,
                    body: requestParameters.body as any,
                }, initOverrides);
            
                return new runtime.JSONApiResponse(response, (jsonValue) => SuccessResponseFromJSON(jsonValue));
            },
            mutationKey: [
                'LocalityScenesApi',
                'updateSceneSnapshot', 
            ],
            queryKey: (requestParameters: UpdateSceneSnapshotRequest, initOverrides?: RequestInit) => ([
                    ...this.updateSceneSnapshot.mutationKey, 
                {
                sceneId: requestParameters.sceneId,
                body: requestParameters.body,
                }
            ]),
            query: (requestParameters: UpdateSceneSnapshotRequest, initOverrides?: RequestInit): UseQueryOptions<SuccessResponse> & {queryKey: QueryKey} => ({
                queryFn: () => this.updateSceneSnapshot(requestParameters, initOverrides),
                queryKey: this.updateSceneSnapshot.queryKey(requestParameters, initOverrides)
            })
        }
    )

}
