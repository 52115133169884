import { debounce } from 'lodash'
import { DateTime } from 'luxon'
import { useState, useMemo, useEffect } from 'react'
import { useMutation } from 'react-query'

import { LocalityNameModel } from '@api'

import { localityApi } from '@services'

import { OccupancyData } from '@components/LocalityOccupancyConsumer'

interface OccupancyAdjustmentState {
    occupancy: number
    adjustedAt: DateTime
    submittedAt: DateTime
    finishedAt: DateTime
}

export const useLocalityOccupancyAdjuster = (locality: LocalityNameModel, data: OccupancyData) => {
    const [adjustmentState, setAdjustmentState] = useState<OccupancyAdjustmentState>({
        occupancy: 0,
        adjustedAt: DateTime.fromMillis(0),
        submittedAt: DateTime.fromMillis(0),
        finishedAt: DateTime.fromMillis(0),
    })
    const { mutate: setOccupancyCall, status: setOccupancyCallStatus } = useMutation(localityApi.setLocalityCrowdSize, {
        onSuccess: () => {
            setAdjustmentState((prev) => ({
                ...prev,
                finishedAt: DateTime.local(),
            }))
        },
        onError: () => {
            setAdjustmentState((prev) => ({
                ...prev,
                finishedAt: DateTime.local(),
            }))
        },
    })

    // Local copy to convince eslint we're cool
    const setOccupancyCallTrigger = setOccupancyCall

    // Update call wrapped with debounce so that it waits 1.5s after the last change
    // before the adjusted value is actually sent to the backend
    const setOccupancy = useMemo(
        () =>
            debounce(
                (crowdSize: number) => {
                    setAdjustmentState((prev) => ({
                        ...prev,
                        submittedAt: DateTime.local(),
                    }))
                    setOccupancyCallTrigger({
                        organizationId: locality.organizationId,
                        localityId: locality.id,
                        body: {
                            crowdSize,
                        },
                    })
                },
                1500,
                { leading: false, trailing: true }
            ),
        [setOccupancyCallTrigger, locality.id, locality.organizationId]
    )

    // When the occupancy is changed, update it in the backend (with a delay to prevent unnecessary calls)
    useEffect(() => {
        if (adjustmentState.adjustedAt > adjustmentState.submittedAt) {
            setOccupancy(adjustmentState.occupancy)
        }
    }, [setOccupancy, adjustmentState, setOccupancyCallStatus])

    // Flush the changes immediately if the component gets unmounted
    useEffect(() => {
        return setOccupancy.flush
    }, [setOccupancy])

    // If there is a "pending" adjustment, display the local value instead of the one received from the backend
    const displayedOccupancy =
        data.observedAt === undefined ||
        data.observedAt.diff(adjustmentState.submittedAt).as('seconds') <= 1 ||
        adjustmentState.adjustedAt > adjustmentState.submittedAt
            ? adjustmentState.occupancy
            : data.currentCrowdSize

    const increaseOccupancy = () => {
        const adjustedAt = DateTime.local()
        setAdjustmentState((prev) => ({
            ...prev,
            adjustedAt,
            occupancy: (prev.adjustedAt > prev.submittedAt ? prev.occupancy : data.currentCrowdSize ?? 0) + 1,
        }))
    }

    const decreaseOccupancy = () => {
        const adjustedAt = DateTime.local()
        setAdjustmentState((prev) => {
            const occupancy = (prev.adjustedAt > prev.submittedAt ? prev.occupancy : data.currentCrowdSize ?? 0) - 1

            if (occupancy < 0) {
                return prev
            }

            return {
                ...prev,
                adjustedAt,
                occupancy,
            }
        })
    }

    return {
        displayedOccupancy,
        increaseOccupancy,
        decreaseOccupancy,
        adjustmentState: setOccupancyCallStatus,
    }
}
