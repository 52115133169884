import { faSignInAlt } from '@fortawesome/free-solid-svg-icons'
import React, { useEffect, useState } from 'react'
import { Alert, Form } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { MutationStatus } from 'react-query'

import { AuthenticationRequestBody } from '@api/models'

import AsyncButton from './AsyncButton'

interface Props {
    onSubmit: (authRequest: AuthenticationRequestBody) => void
    failedText?: string
    loginRequestStatus: MutationStatus
    className?: string
    buttonClassName?: string
    bsPrefix?: string
}

const LoginForm: React.FC<Props> = (props) => {
    const { t } = useTranslation()

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [displayAlert, setDisplayAlert] = useState(false)

    useEffect(() => {
        setDisplayAlert(false)
    }, [email, password, setDisplayAlert])

    useEffect(() => {
        if (props.loginRequestStatus !== 'idle') {
            setDisplayAlert(true)
        }
    }, [props.loginRequestStatus])

    const onSubmit: React.FormEventHandler = (e) => {
        e.preventDefault()
        props.onSubmit({
            email,
            password,
        })
    }

    return (
        <Form className={props.className} onSubmit={onSubmit}>
            <Form.Group>
                <Form.Label column={false}>{t('form.email', 'E-mail')}</Form.Label>
                <Form.Control
                    type="email"
                    value={email}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
                />
            </Form.Group>
            <Form.Group>
                <Form.Label column={false}>{t('form.password', 'Password')}</Form.Label>
                <Form.Control
                    type="password"
                    value={password}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)}
                />
            </Form.Group>
            {props.failedText && displayAlert && (
                <Alert className="text-center" variant="warning">
                    {props.failedText}
                </Alert>
            )}
            <Form.Group className="submitGroup">
                <AsyncButton
                    bsPrefix={props.bsPrefix}
                    className={props.buttonClassName}
                    icon={faSignInAlt}
                    loadingText={t('loginPage.loggingIn', 'Logging in...')}
                    retryTimeoutSeconds={1}
                    size="lg"
                    status={props.loginRequestStatus}
                    text={t('button.login', 'Log in')}
                    type="submit"
                    variant="primary"
                    allowRefetch
                />
            </Form.Group>
        </Form>
    )
}

export default LoginForm
