import React, { useState } from 'react'
import { Form, Row, Col } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { MutationStatus } from 'react-query'

import { LocalityResponse } from '@api/models'

import { defaultErrorEntry, ErrorEntry } from '@helpers/types'

import AsyncButton from './AsyncButton'
import NumberInput from './GenericInputs/NumberInput'

interface Props {
    locality: LocalityResponse
    onSubmit: ({
        crowdSize,
        capacity,
        locality,
    }: {
        crowdSize: number
        capacity: number
        locality: LocalityResponse
    }) => void
    submissionState: MutationStatus
    defaultCrowdSize?: number
}

interface Errors {
    capacity: ErrorEntry
    crowdSize: ErrorEntry
}

const LocalityOccupancyEditForm: React.FC<Props> = (props) => {
    const { t } = useTranslation()

    const [capacity, setCapacity] = useState(props.locality.capacity || 0)
    const [crowdSize, setCrowdSize] = useState<number>()
    const [errors, setErrors] = useState<Errors>({
        capacity: defaultErrorEntry,
        crowdSize: defaultErrorEntry,
    })

    const defaultCrowdSize = props.defaultCrowdSize ?? 0

    return (
        <Form
            onSubmit={(e) => {
                e.preventDefault()
                props.onSubmit({ capacity, crowdSize: crowdSize ?? 0, locality: props.locality })
            }}
        >
            <Form.Group as={Row}>
                <Form.Label column={true} sm={2}>
                    {t('form.capacity', 'Capacity')}
                </Form.Label>
                <Col sm={10}>
                    <NumberInput
                        isInvalid={errors.capacity.isTouched && errors.capacity.isInvalid}
                        value={capacity}
                        onChange={(capacity) => {
                            setCapacity(capacity || 0)
                            setErrors((prev) => ({
                                ...prev,
                                capacity: {
                                    isTouched: true,
                                    isInvalid: (capacity || 0) < 0,
                                },
                            }))
                        }}
                    />
                </Col>
            </Form.Group>
            <Form.Group as={Row}>
                <Form.Label column={true} sm={2}>
                    {t('form.currentOccupancy', 'Current occupancy')}
                </Form.Label>
                <Col sm={10}>
                    <NumberInput
                        isInvalid={errors.crowdSize.isTouched && errors.crowdSize.isInvalid}
                        placeholder={defaultCrowdSize.toString()}
                        value={crowdSize}
                        onChange={(crowdSize) => {
                            setCrowdSize(crowdSize)
                            setErrors((prev) => ({
                                ...prev,
                                crowdSize: {
                                    isTouched: true,
                                    isInvalid: crowdSize !== undefined && crowdSize < 0,
                                },
                            }))
                        }}
                    />
                </Col>
            </Form.Group>
            <Form.Group as={Row}>
                <Col sm={{ span: 10, offset: 2 }}>
                    <AsyncButton status={props.submissionState} text={t('button.save', 'Save')} />
                </Col>
            </Form.Group>
        </Form>
    )
}

export default LocalityOccupancyEditForm
