import { faUser, faExclamationTriangle, faExpand } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon as FAIcon, FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import { TFunction } from 'i18next'
import { Duration } from 'luxon'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { LocalityNameModel } from '@api'

import { passCSSVariable } from '@helpers/cssUtils'

import { OccupancyData } from '@components/LocalityOccupancyConsumer'

import logo from '@images/logo/logo_white.svg'

import styles from './StaySafeDashboardMinified.module.scss'

const formatWaitTime = (waitTime?: Duration) => {
    if (waitTime === undefined) {
        return '-'
    }

    if (waitTime < Duration.fromObject({ minute: 1 })) {
        return (
            <div className={styles.estimationTextWrapper}>
                <span dangerouslySetInnerHTML={{ __html: '&lt;' }} className={styles.estimationText} />
                <span dangerouslySetInnerHTML={{ __html: '&nbsp;1&nbsp;' }} />
                <span className={styles.waitTime}>min</span>
            </div>
        )
    }

    if (waitTime > Duration.fromObject({ minute: 30 })) {
        return (
            <div className={styles.longWaitTime}>
                <span dangerouslySetInnerHTML={{ __html: '&gt;' }} className={styles.estimationText} />
                <span dangerouslySetInnerHTML={{ __html: '&nbsp;30&nbsp;' }} />
                <span className={styles.waitTime}>min</span>
            </div>
        )
    }

    return waitTime.toFormat('mm:ss')
}

const formatOccupancyPercentage = (value: number | undefined, t: TFunction) => {
    if (value === undefined) {
        return '-'
    }

    return t('staysafe.percentage', { value })
}

const StaySafeDashboardView: React.FC<{
    data: OccupancyData
    showCapacity: boolean
    showExactNumbers: boolean
    locality: LocalityNameModel
    language: string
    redirectTarget: string
}> = ({ data, showCapacity, showExactNumbers, language, redirectTarget }) => {
    const { t } = useTranslation()

    const [hover, setHoverState] = useState(false)

    const redirectToStaySafeView = () => {
        return window.open(redirectTarget, '_blank')
    }

    // Reload the page every 12 hours
    useEffect(() => {
        const timeout = setTimeout(() => {
            window.location.reload()
        }, 12 * 3600 * 1000)

        return () => clearTimeout(timeout)
    }, [])

    return (
        <>
            <div
                className={styles.overlay}
                style={passCSSVariable('DisplayOverlay', hover ? 'block' : undefined)}
                onClick={redirectToStaySafeView}
                onMouseEnter={() => setHoverState(true)}
            >
                <div className={styles.overlayText}>
                    <FontAwesomeIcon icon={faExpand} />
                    <span>
                        &nbsp; Go to &nbsp;
                        {t('button.staySafeScreen', 'Stay Safe screen')}
                    </span>
                </div>
            </div>
            <div
                className={classNames({
                    [styles.layout]: true,
                    [styles.safe]: data.isSafeToEnter,
                    [styles.unsafe]: !data.isSafeToEnter,
                    [styles.offline]: data.isOffline,
                })}
                style={passCSSVariable('Brightness', hover ? '0.4' : undefined)}
                onClick={redirectToStaySafeView}
                onMouseEnter={() => setHoverState(true)}
                onMouseLeave={() => setHoverState(false)}
            >
                <div className={styles.progressBarArea}>
                    <div className={styles.progressBar}>
                        <div
                            className={styles.progressBarInner}
                            style={{
                                height: `${data.occupancyPercentage}%`,
                            }}
                        />
                    </div>
                </div>
                <div className={styles.statusArea}>
                    {!data.isOffline ? (
                        <div
                            className={classNames({
                                [styles.statusText]: true,
                                [styles.statusTextSmaller]: language === 'cs',
                            })}
                        >
                            {data.isSafeToEnter ? t('staysafe.enter') : t('staysafe.wait')}
                        </div>
                    ) : (
                        <div>
                            <h2>{t('staysafe.offline')}</h2>
                            <FAIcon className={styles.dangerIcon} icon={faExclamationTriangle} />
                        </div>
                    )}

                    <div className={styles.statistics}>
                        {/* STORE OCCUPANCY */}
                        <div className={styles.metricContainer}>
                            <h2>{t('staysafe.occupancy')}</h2>
                            <div>{formatOccupancyPercentage(data.occupancyPercentage, t)}</div>
                        </div>

                        {showExactNumbers && (
                            <div className={styles.metricContainer}>
                                {data.isSafeToEnter && (
                                    <div>
                                        <h2>{t('staysafe.inside')}</h2>
                                        <div>{data.currentCrowdSize}</div>
                                    </div>
                                )}

                                {!data.isSafeToEnter && (
                                    <>
                                        <h2>{t('staysafe.waitTime')}</h2>
                                        <div>{formatWaitTime(data.waitTime)}</div>
                                    </>
                                )}
                            </div>
                        )}

                        {/* STORE CAPACITY */}
                        {showCapacity && (
                            <div className={styles.metricContainer}>
                                <h2>{t('staysafe.storeLimit')}</h2>
                                <div>
                                    {data.maximumCapacity}
                                    <FAIcon className={styles.userIcon} icon={faUser} />
                                </div>
                            </div>
                        )}
                    </div>
                    <div className={styles.logo}>
                        <img alt="Vividi logo" src={logo} />
                    </div>
                </div>
            </div>
        </>
    )
}

export default StaySafeDashboardView
