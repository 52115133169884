/* tslint:disable */
/* eslint-disable */
/**
 * Vividi Dashboard API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IntervalMovement,
    IntervalMovementFromJSON,
    IntervalMovementFromJSONTyped,
    IntervalMovementToJSON,
} from './IntervalMovement';

/**
 * 
 * @export
 * @interface IntervalMovementByBoundary
 */
export interface IntervalMovementByBoundary {
    /**
     * 
     * @type {number}
     * @memberof IntervalMovementByBoundary
     */
    boundaryId: number;
    /**
     * 
     * @type {string}
     * @memberof IntervalMovementByBoundary
     */
    boundaryName: string;
    /**
     * 
     * @type {number}
     * @memberof IntervalMovementByBoundary
     */
    deviceId: number;
    /**
     * 
     * @type {number}
     * @memberof IntervalMovementByBoundary
     */
    sceneId: number;
    /**
     * 
     * @type {Array<IntervalMovement>}
     * @memberof IntervalMovementByBoundary
     */
    items: Array<IntervalMovement>;
}

export function IntervalMovementByBoundaryFromJSON(json: any): IntervalMovementByBoundary {
    return IntervalMovementByBoundaryFromJSONTyped(json, false);
}

export function IntervalMovementByBoundaryFromJSONTyped(json: any, ignoreDiscriminator: boolean): IntervalMovementByBoundary {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'boundaryId': json['boundary_id'],
        'boundaryName': json['boundary_name'],
        'deviceId': json['device_id'],
        'sceneId': json['scene_id'],
        'items': ((json['items'] as Array<any>).map(IntervalMovementFromJSON)),
    };
}

export function IntervalMovementByBoundaryToJSON(value?: IntervalMovementByBoundary | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'boundary_id': value.boundaryId,
        'boundary_name': value.boundaryName,
        'device_id': value.deviceId,
        'scene_id': value.sceneId,
        'items': ((value.items as Array<any>).map(IntervalMovementToJSON)),
    };
}

