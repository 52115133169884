import { clamp } from 'lodash'
import { DateTime, Interval, Zone, Duration, DateTimeFormatOptions } from 'luxon'

import { gdynusaReadableInterval, localeAwareAlign } from '@helpers/datetimeUtils'
import { IntervalType, SummaryRequest } from '@helpers/types'

export const timePointToSeconds = (startDate: DateTime, endDate: DateTime, timePoint: DateTime) => {
    const intervalWidth = endDate.diff(startDate).as('seconds')

    return clamp(timePoint.diff(startDate).as('seconds'), 0, intervalWidth)
}

export interface EventData {
    activeLabel: string
    activePayload: Array<{ payload: any }>
}

export const parseDate = (dateString: string) =>
    DateTime.fromISO(dateString, {
        zone: 'utc',
    })

/**
 * Determine an appropriate partitioning granularity for given interval.
 */
const getGranularityForInterval = (interval: Interval): Duration => {
    if (interval.length('days') > 10) {
        return Duration.fromObject({ day: 1 })
    }

    if (interval.length('hours') > 6) {
        return Duration.fromObject({ hour: 1 })
    }

    return Duration.fromObject({ minute: 1 })
}

export const getDividersForInterval = (interval: Interval, zone: Zone): Array<DateTime> => {
    const result: Array<DateTime> = []

    if (interval.length('days') < 7) {
        return []
    }

    if (interval.length('days') >= 6 && interval.length('days') < 15) {
        const firstHourInDay = localeAwareAlign(interval.start, 'day', zone)

        for (let index = 0; index < interval.length('days') + 1; index++) {
            result.push(firstHourInDay.plus({ day: index }))
        }

        return result
    }

    const firstDayInWeek = localeAwareAlign(interval.start, 'week', zone)

    for (let index = 0; index < interval.length('weeks') + 1; index++) {
        result.push(firstDayInWeek.plus({ week: index }))
    }

    return result
}

/**
 * Transform an interval into the format used for summary requests.
 * @param interval the interval to transform
 */
export const partitionInterval = (interval: Interval, zone: Zone, granularity?: Duration): SummaryRequest => {
    const intervals: SummaryRequest['intervals'] = []
    const step = granularity ?? getGranularityForInterval(interval)
    let cursor = interval.start.setZone(zone)

    while (cursor < interval.end.setZone(zone)) {
        const next = cursor.plus(step)
        intervals.push({
            startingFrom: cursor.setZone('UTC').toISO(),
            endingAt: next.setZone('UTC').toISO(),
        })

        cursor = next
    }

    return {
        intervals,
    }
}

const determineCustomIntervalType = (interval: Interval) => {
    if (interval.length('hours') <= 6) {
        return IntervalType.HOUR
    }

    if (interval.length('days') <= 10) {
        return IntervalType.WEEK
    }

    return IntervalType.MONTH
}

/**
 * Determine interval label type based on the provided interval type and length.
 *
 * In case supplied `IntervalType` is CUSTOM and `determinedIntervalType` is provided - use `determinedIntervalType` as the derivedIntervalType, otherwise use the `determineCustomIntervalType` function to derive it.
 * @param intervalType the default interval type
 * @param determinedIntervalType the derived CUSTOM interval type
 */
const intervalLabel = (
    interval: Interval,
    zone: Zone,
    intervalType?: IntervalType,
    determinedIntervalType?: Exclude<IntervalType, IntervalType.CUSTOM>
) => {
    const dateConfig: DateTimeFormatOptions = {
        weekday: 'short',
        day: '2-digit',
        month: '2-digit',
    }

    const derivedIntervalType =
        intervalType !== undefined && intervalType !== IntervalType.CUSTOM
            ? intervalType
            : determinedIntervalType ?? determineCustomIntervalType(interval)

    if (derivedIntervalType === IntervalType.MONTH) {
        const intervalDate = interval.start.setZone(zone)
        const weekStart = intervalDate
        const weekEnd = intervalDate.plus({ days: 7 })

        const gdynusaInterval = gdynusaReadableInterval(Interval.fromDateTimes(weekStart, weekEnd))
        const gdynusaIntervalStart = gdynusaInterval.start.toLocaleString(dateConfig)
        const gdynusaIntervalEnd = gdynusaInterval.end.toLocaleString(dateConfig)

        if (gdynusaInterval.start.month === gdynusaInterval.end.month) {
            return `${gdynusaIntervalStart} - ${gdynusaIntervalEnd}`
        }

        return gdynusaIntervalStart
    }

    // week is because for a week intervalType we are fetching hourly data. Therefore the label would be in minutes instead of days for a week intervalType
    if (derivedIntervalType === IntervalType.WEEK) {
        return interval.start.setZone(zone).toLocaleString(dateConfig)
    }

    return interval.start.setZone(zone).toFormat('HH:mm')
}

export const tickFormatter = (
    i: string,
    zone: Zone,
    interval?: Interval,
    intervalType?: IntervalType,
    determinedIntervalType?: Exclude<IntervalType, IntervalType.CUSTOM>
) => {
    if (interval === undefined) {
        return i
    }

    return intervalLabel(interval, zone, intervalType, determinedIntervalType)
}

/**
 *
 * This function basically filter an array of intervals to contain
 * only relevant records to the interval type.
 * It returns indexes of the relevant records
 *
 * This function does not modify the data. It is used to force recharts to correctly display the x axis labels
 *
 * @param zone zone to properly align displayed time
 * @param intervalType
 * @param data suppose to be an array of intervals of all entries to the chart
 *
 * @return array that contain relevant record indexes
 */
export const filterTickIndexes = (
    zone: Zone,
    intervalType: IntervalType,
    data: Array<{ interval: Interval }>
): number[] => {
    const filteredIndexes = Object.keys(data).filter((index) => {
        const chunk = data[Number(index)]
        const date = chunk.interval.start.setZone(zone)

        switch (intervalType) {
            case IntervalType.HOUR: {
                return date.minute % 5 === 0
            }

            case IntervalType.DAY: {
                return date.minute === 0
            }

            case IntervalType.WEEK: {
                return date.hour === 0
            }

            case IntervalType.MONTH: {
                return date.weekday === 1
            }

            case IntervalType.CUSTOM: {
                if (data.length) {
                    const intervalLength = data[data.length - 1].interval.end.diff(data[0].interval.start)

                    if (intervalLength > Duration.fromObject({ days: 3 })) {
                        return date.hour === 0 && date.minute === 0
                    }

                    if (intervalLength > Duration.fromObject({ days: 15 })) {
                        return date.weekday === 1 && chunk.interval.length('hour') >= 22
                    }
                }

                return true
            }

            default:
                return true
        }
    })

    return filteredIndexes.map(Number)
}
