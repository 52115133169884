/* tslint:disable */
/* eslint-disable */
/**
 * Vividi Dashboard API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Role,
    RoleFromJSON,
    RoleFromJSONTyped,
    RoleToJSON,
} from './Role';

/**
 * 
 * @export
 * @interface PlainUserResponse
 */
export interface PlainUserResponse {
    /**
     * 
     * @type {string}
     * @memberof PlainUserResponse
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof PlainUserResponse
     */
    phoneNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof PlainUserResponse
     */
    language?: string;
    /**
     * 
     * @type {number}
     * @memberof PlainUserResponse
     */
    id: number;
    /**
     * 
     * @type {Role}
     * @memberof PlainUserResponse
     */
    role: Role;
    /**
     * 
     * @type {number}
     * @memberof PlainUserResponse
     */
    organizationId?: number;
}

export function PlainUserResponseFromJSON(json: any): PlainUserResponse {
    return PlainUserResponseFromJSONTyped(json, false);
}

export function PlainUserResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): PlainUserResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'email': json['email'],
        'phoneNumber': !exists(json, 'phone_number') ? undefined : json['phone_number'],
        'language': !exists(json, 'language') ? undefined : json['language'],
        'id': json['id'],
        'role': RoleFromJSON(json['role']),
        'organizationId': !exists(json, 'organization_id') ? undefined : json['organization_id'],
    };
}

export function PlainUserResponseToJSON(value?: PlainUserResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'email': value.email,
        'phone_number': value.phoneNumber,
        'language': value.language,
        'id': value.id,
        'role': RoleToJSON(value.role),
        'organization_id': value.organizationId,
    };
}

