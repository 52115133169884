import React, { useMemo } from 'react'
import { I18nextProvider } from 'react-i18next'
import { useParams } from 'react-router'

import globalI18n from '@i18n'

const LanguageOverride: React.FC<{
    children: React.ReactNode
    language?: string
}> = ({ children, language }) => {
    const params = useParams<{ lang?: string }>()
    const desiredLanguage = language ?? params.lang

    const i18n = useMemo(() => {
        if (desiredLanguage === undefined || !(globalI18n.options.supportedLngs || []).includes(desiredLanguage)) {
            return globalI18n
        }

        const scopedI18n = globalI18n.cloneInstance()
        scopedI18n.changeLanguage(desiredLanguage)

        return scopedI18n
    }, [desiredLanguage])

    return <I18nextProvider i18n={i18n}>{children}</I18nextProvider>
}

export default LanguageOverride
