import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ReactElement, useState } from 'react'
import { Button, Col, Form, Image, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { MutationStatus, useQuery } from 'react-query'
import AutoSizer from 'react-virtualized-auto-sizer'

import { LocalityFloorplanDetails, LocalityResponse, OrganizationResponse, Role } from '@api'

import { localityApi } from '@services'

import { defaultErrorEntry, ErrorEntry } from '@helpers/types'

import Box from '@elements/Box/Box'

import AsyncButton from '@components/AsyncButton'
import NumberInput from '@components/GenericInputs/NumberInput'
import TextInput from '@components/GenericInputs/TextInput'
import ImageLoadingWrapper from '@components/ImageLoadingWrapper'
import LoadingSpinner from '@components/LoadingSpinner'
import PopConfirm from '@components/PopConfirm/PopConfirm'
import RoleChecker from '@components/RoleChecker'
import TooltipIcon from '@components/TooltipIcon'

import styles from './Floorplan.module.scss'
import FloorplanDropdown from './FloorplanDropdown'

interface Props {
    locality: LocalityResponse
    organization: OrganizationResponse
    selectedFloorplan: number
    floorplanDetails: LocalityFloorplanDetails
    onUpdateDetails: (details: LocalityFloorplanDetails) => void
    updateDetailsState: MutationStatus
    onSelect: (floorplanId: number, floorplanName: string) => void
    onDelete: (floorplanId: number) => void
    onCreate: () => void
}

interface AutoSizedImageWrapperProps {
    legendName?: string
    src: string
}
export const AutoSizedImageWrapper = ({ src }: AutoSizedImageWrapperProps) => (
    <AutoSizer disableHeight={true} style={{ textAlign: 'center', width: 'auto' }}>
        {({ width }) => (
            <Image
                src={src}
                style={{
                    maxHeight: width * 0.75,
                    maxWidth: width,
                }}
            />
        )}
    </AutoSizer>
)

export const AutoSizedImageWithLegendWrapper = ({ legendName, src }: AutoSizedImageWrapperProps) => {
    const { t } = useTranslation()

    return (
        <AutoSizer disableHeight={true} style={{ textAlign: 'center', width: 'auto' }}>
            {({ width }) => (
                <>
                    <div className={styles.legendTitleContainer}>
                        {legendName ?? t('heatmapDetailPage.visitorImpressionsLegend', 'Visitor impressions')}
                    </div>
                    <Image
                        src={src}
                        style={{
                            maxHeight: width * 0.75,
                            maxWidth: width,
                        }}
                    />
                    <div className={styles.intensityLegendContainer}>
                        <span>{t('heatmapDetailPage.legendLow', 'Low')}</span>
                        <span>{t('heatmapDetailPage.legendHigh', 'High')}</span>
                    </div>
                </>
            )}
        </AutoSizer>
    )
}

interface Errors {
    floorplanName: ErrorEntry
    scaleCoefficient: ErrorEntry
}

const defaultScaleCoefficient = 0.2

export default function Floorplan({
    locality,
    organization,
    selectedFloorplan,
    onSelect,
    onDelete,
    floorplanDetails,
    onUpdateDetails,
    updateDetailsState,
}: Props): ReactElement {
    const { t } = useTranslation()
    const [floorplanName, setFloorplanName] = useState<string>(floorplanDetails.name)
    const [scaleCoefficient, setScaleCoefficient] = useState<number | undefined>(
        floorplanDetails.scale ?? defaultScaleCoefficient
    )

    const [errors, setErrors] = useState<Errors>({
        floorplanName: defaultErrorEntry,
        scaleCoefficient: defaultErrorEntry,
    })

    const isEdited =
        floorplanDetails.name !== floorplanName ||
        (floorplanDetails.scale && floorplanDetails.scale !== scaleCoefficient) ||
        (!floorplanDetails.scale && scaleCoefficient !== defaultScaleCoefficient)

    const floorplanCall = useQuery(
        localityApi.getLocalityFloorplan.query({
            organizationId: organization.id,
            localityId: locality.id,
            floorplanId: selectedFloorplan,
        })
    )

    const floorplans = Object.entries(locality.floorplans)

    const handleChangeName = (name: string) => {
        setFloorplanName(name)
    }

    const handleChangeScaleCoefficient = (scale?: number) => {
        setScaleCoefficient(scale)
        setErrors({
            ...errors,
            scaleCoefficient: {
                isTouched: true,
                isInvalid: scale !== undefined && scale <= 0,
            },
        })
    }

    const onCancel = () => {
        setFloorplanName(floorplanDetails.name)
        setScaleCoefficient(floorplanDetails.scale ?? defaultScaleCoefficient)
        setErrors({
            floorplanName: defaultErrorEntry,
            scaleCoefficient: defaultErrorEntry,
        })
    }

    const onSubmit = () => {
        const hasErrors = Object.values(errors).some((it) => it.isInvalid)

        if (!hasErrors) {
            onUpdateDetails({
                name: floorplanName,
                scale: scaleCoefficient,
            })
        }
    }

    const hasMultipleFloorplans = floorplans.length > 1

    return (
        <Box paddingSize="lg">
            <Box.Title
                buttons={
                    isEdited && (
                        <div className={styles.floorplanButtonsContainer}>
                            <Button className={styles.floorplansButton} variant="secondary" onClick={onCancel}>
                                {t('button.cancel', 'Cancel')}
                            </Button>
                            <AsyncButton
                                status={updateDetailsState}
                                text={t('button.saveChanges', 'Save changes')}
                                variant="primary"
                                onClick={onSubmit}
                            />
                        </div>
                    )
                }
                text={t('heading.localityFloorplans', '{{locality}} floorplans', { locality: locality.name })}
            />
            <div className={styles.floorplanContainer}>
                {hasMultipleFloorplans && (
                    <div className={styles.floorplanDropdownContainer}>
                        <FloorplanDropdown
                            floorplans={floorplans}
                            selectedFloorplan={selectedFloorplan}
                            onSelect={onSelect}
                        />
                    </div>
                )}
                <div className={styles.floorplanImageContainer}>
                    <ImageLoadingWrapper
                        errorComponent={
                            <span className={styles.floorplanErrorMessage}>
                                {t('others.floorplanFailed', 'Failed to load floorplan image')}
                            </span>
                        }
                        placeholder={<LoadingSpinner bare={true} />}
                        request={floorplanCall}
                    >
                        <AutoSizedImageWrapper src="" />
                    </ImageLoadingWrapper>
                </div>
                <Form.Group as={Row} className={styles.floorplanRow}>
                    <Form.Label column={true} sm={2}>
                        {t('floorplan.floorplanName', 'Floor plan name')}
                    </Form.Label>
                    <Col sm={4}>
                        <TextInput
                            isInvalid={errors.floorplanName.isTouched && errors.floorplanName.isInvalid}
                            value={floorplanName}
                            onChange={handleChangeName}
                        />
                    </Col>
                </Form.Group>
                <RoleChecker whitelist={[Role.Administrator]}>
                    <Form.Group as={Row} className={styles.floorplanRow}>
                        <Form.Label column={true} sm={2}>
                            {t('floorplan.scaleCoefficient', 'Scale coefficient')}
                            <TooltipIcon>
                                {t(
                                    'tooltip.scaleFloorplanHint',
                                    'Default 0.2. Higher the scale, higher number of sectors (= smaller size of the sector square)'
                                )}
                            </TooltipIcon>
                        </Form.Label>
                        <Col sm={2}>
                            <NumberInput
                                isInvalid={errors.scaleCoefficient.isTouched && errors.scaleCoefficient.isInvalid}
                                step={0.1}
                                value={scaleCoefficient}
                                allowDecimals
                                onChange={handleChangeScaleCoefficient}
                            />
                        </Col>
                        <Col sm={2}>
                            <PopConfirm
                                cancelButtonVariant="secondary"
                                confirmButtonVariant="danger"
                                confirmMessage={t(
                                    'popup.deleteFloorplan',
                                    'Are you sure you want to delete the selected floor plan?'
                                )}
                                placement="top"
                                onConfirm={() => onDelete(Number(selectedFloorplan))}
                            >
                                <Button variant="danger">
                                    <FontAwesomeIcon icon={faTrash} />
                                    {t('button.deleteFloorplan', 'Delete floor plan')}
                                </Button>
                            </PopConfirm>
                        </Col>
                    </Form.Group>
                </RoleChecker>
            </div>
        </Box>
    )
}
