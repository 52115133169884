import { faMapMarkerAlt, faPlus, faVectorSquare } from '@fortawesome/free-solid-svg-icons'
import { faTimes } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { deburr, delay, isEmpty, keyBy, pick } from 'lodash'
import React, { useEffect, useMemo, useState } from 'react'
import { Button, Card, Modal } from 'react-bootstrap'
import Helmet from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { MutationStatus, useMutation, useQuery } from 'react-query'
import { useHistory, useLocation, useParams, useRouteMatch } from 'react-router'
import { NavigationConfirm } from 'react-router-navigation-confirm'

import {
    LocalityConfigurationStateModel,
    LocalityModel,
    LocalityNameModel,
    OpeningHours,
    OrganizationResponse,
    SceneResponse,
    UserResponse,
    Location,
} from '@api'

import { localityApi } from '@services'

import {
    generateEditOrganizationPath,
    generateOrganizationsCreateLocalitiesPath,
    generateOrganizationsEditLocalitiesPath,
    ORGANIZATION_LOCALITIES_NEW_TAB_PATH,
    ORGANIZATION_LOCALITIES_TAB_PATH,
    OrganizationEditPageTabs,
    ORGANIZATION_LOCALITY_EDIT_FLOORPLAN_MAPPING_PATH,
} from '@helpers/VividiURLs'
import { mergeQueryResults, visibleFloorplanMappingsQuery, visibleSceneDescriptionsQuery } from '@helpers/api'
import { useSuspendingQuery } from '@helpers/api'
import { orderLocalities } from '@helpers/orderFunctions'

import { useDetectMobile } from '@hooks/useDetectDevice'

import Box from '@elements/Box/Box'

import AsyncButton from '@components/AsyncButton'
import ScenesToFloorplan from '@components/FloorplanMapping/ScenesToFloorplan'
import AccordionLayout from '@components/Layouts/AccordionLayout'
import SideNavigationListLayout from '@components/Layouts/SideNavigationLayout'
import LegacyLoadingWrapper from '@components/LegacyLoadingWrapper'
import LocalityPicker from '@components/LocalityPicker'
import VerticalListGroup, { VerticalListEntry } from '@components/NavigationLists/VerticalListGroup'

import localityEditStyles from './LocalitiesTab.module.scss'
import LocalitiesTabForm, { isExistingLocality, LocalitiesEditFormErrors } from './LocalitiesTabForm'
import styles from './LocalitiesTabForm.module.scss'

const newEmptyLocality = {
    name: '',
    labels: [],
}

export type LocalitiesEditData = {
    locality: LocalityNameModel | LocalityModel
    scenes?: Array<number>
    users?: Array<number>
    openingHours?: OpeningHours
    location?: Location
    isRealTimeOccupancyEnabled?: boolean
    isFloorplanOccupancyEnabled?: boolean
}

const localityToEditData = (
    locality: LocalityNameModel | undefined,
    scenes?: Array<number>,
    users?: Array<number>,
    openingHours?: OpeningHours,
    location?: Location,
    isRealTimeOccupancyEnabled?: boolean,
    isFloorplanOccupancyEnabled?: boolean
): LocalitiesEditData | undefined =>
    locality
        ? {
              locality,
              scenes,
              users,
              openingHours,
              location,
              isRealTimeOccupancyEnabled,
              isFloorplanOccupancyEnabled,
          }
        : undefined

const NEW_ITEM_ID = 'new'

const determineIndicators = (localityId: number, localityConfigurations: LocalityConfigurationStateModel[]) => {
    const indicators: Array<JSX.Element> = []

    const localityConfiguration = localityConfigurations.find(({ id }) => id === localityId)

    if (localityConfiguration) {
        const moduleConfigurations = Object.values(
            pick(localityConfiguration, [
                'isStaySafeConfigured',
                'isStaySafeFloorplanConfigured',
                'isFootfallConfigured',
                'isConversionsConfigured',
                'isQueuesConfigured',
                'isOccupancyConfigured',
                'isEmotionsConfigured',
            ])
        ).some((configured) => configured)

        if (moduleConfigurations) {
            indicators.push(<FontAwesomeIcon key={`${localityId}-configured`} icon={faVectorSquare} />)
        }
    }

    return indicators
}

const LocalitiesTab: React.FC<{
    users: UserResponse[]
    localities: LocalityNameModel[]
    organization: OrganizationResponse
    onLocalityCreate: ({ organizationId, values }: { organizationId: number; values: LocalitiesEditData }) => void
    onLocalityDelete: ({ organizationId, localityId }: { organizationId: number; localityId: number }) => void
    onLocalityUpdate: ({
        organizationId,
        localityData,
    }: {
        organizationId: number
        localityData: {
            locality: LocalityNameModel
            scenes?: number[] | undefined
            users?: number[] | undefined
            openingHours?: OpeningHours
            location?: Location
            isRealTimeOccupancyEnabled?: boolean
            isFloorplanOccupancyEnabled?: boolean
        }
    }) => void
    localityCreationState: MutationStatus
    localityModificationState: MutationStatus
    scenesInOrganization: SceneResponse[]
}> = ({
    users,
    localities,
    organization,
    localityCreationState,
    localityModificationState,
    onLocalityCreate,
    onLocalityDelete,
    onLocalityUpdate,
    scenesInOrganization,
}) => {
    const history = useHistory()
    const location = useLocation()

    const { t } = useTranslation()
    const mobile = useDetectMobile()
    const params = useParams<{ localityId?: string; floorplanId?: string }>()
    const search = useMemo(() => new URLSearchParams(location.search).get('search') ?? '', [location.search])
    const isNewLocality = useRouteMatch(ORGANIZATION_LOCALITIES_NEW_TAB_PATH) !== null
    const isUserListPage = useRouteMatch(ORGANIZATION_LOCALITIES_TAB_PATH) !== null
    const localityId = params.localityId !== undefined ? parseInt(params.localityId, 10) : undefined
    const selectedLocality = localities.find(({ id }) => id === localityId)
    const usersInLocality = localityId ? users.filter(({ localityIds }) => localityIds.includes(localityId)) : []

    const localityConfigurations = useSuspendingQuery(localityApi.getLocalityConfigurationStates.query())

    const localityOpeningHoursCall = useQuery(localityApi.getLocalityOpeningHours.query())

    const localityCall = useQuery(
        localityApi.getLocality.query({
            localityId: localityId ?? -1,
        })
    )

    const localityLabelsCall = useQuery(localityApi.getLocalityNames.query())

    const {
        mutate: updateLocality,
        status: localityUpdateState,
        reset: resetLocalityUpdate,
    } = useMutation(localityApi.updateLocality, {
        onSuccess: () => {
            //we want to avoid reloading the whole tab just because we copy the opening hours
            localityOpeningHoursCall.refetch()
            localityCall.refetch()
            delay(() => resetLocalityUpdate(), 1000)
        },
    })

    const scenesInLocalityCall = useQuery({
        ...localityApi.getLocalityScenes.query({
            localityId: localityId!,
        }),
        enabled: localityId !== undefined,
    })

    const floorplanMappingsCall = useQuery({
        ...visibleFloorplanMappingsQuery(localityCall.data),
    })

    const sceneConfigurationsCall = useQuery({
        ...visibleSceneDescriptionsQuery(scenesInLocalityCall.data?.scenes.map(({ id }) => id) ?? []),
        enabled: scenesInLocalityCall.isSuccess,
    })

    const floorplanMappingsRequests = mergeQueryResults(localityCall, floorplanMappingsCall, scenesInLocalityCall)

    const isFloorplanMappingPath = useRouteMatch(ORGANIZATION_LOCALITY_EDIT_FLOORPLAN_MAPPING_PATH) !== null

    const [selectedScenes, setSelectedScenes] = useState<Array<SceneResponse> | undefined>(undefined)
    const [selectedUsers, setSelectedUsers] = useState<Array<UserResponse> | undefined>(undefined)
    const [isEdited, setEdited] = useState(false)
    const [localityData, setLocalityData] = useState<LocalitiesEditData | undefined>(
        localityToEditData(selectedLocality)
    )

    const [geoLocation, setGeoLocation] = useState<Location | undefined>(undefined)
    const [isRealTimeOccupancyEnabled, setRealTimeOccupancyEnabled] = useState(false)
    const [isFloorplanOccupancyEnabled, setFloorplanOccupancyEnabled] = useState(false)
    const [errors, setErrors] = useState<LocalitiesEditFormErrors>()
    const isValid = errors && !Object.values(errors).some((it) => it.isInvalid)
    const documentTitle = selectedLocality && organization && `${organization.name} - ${selectedLocality.name}`

    const handleSelectUser = (userIds: string[]) => {
        const selectedUsers = users.filter((user) => userIds.indexOf(user.id.toString()) >= 0)
        setEdited(true)
        setSelectedUsers(selectedUsers)
        setLocalityData((prev) => (prev ? { ...prev, users: selectedUsers.map((u) => u.id) } : undefined))
    }

    const handleSelectScenes = (sceneIds: string[]) => {
        const selectedScenes = scenesInOrganization.filter((scene) => sceneIds.includes(scene.id.toString()))
        setEdited(true)
        setSelectedScenes(selectedScenes)
        setLocalityData((prev) => (prev ? { ...prev, scenes: selectedScenes.map((s) => s.id) } : undefined))
    }

    const handleGeoLocationUpdate = (location?: Location | undefined) => {
        setEdited(true)
        setGeoLocation(location)
        setLocalityData((prev) => (prev ? { ...prev, location } : undefined))
    }

    const handleRealtimeOccupancyUpdate = (isRealTimeOccupancyEnabled: boolean) => {
        setEdited(true)
        setRealTimeOccupancyEnabled(isRealTimeOccupancyEnabled)
        setLocalityData((prev) => (prev ? { ...prev, isRealTimeOccupancyEnabled } : undefined))
    }

    const handleFloorplanOccupancyUpdate = (isFloorplanOccupancyEnabled: boolean) => {
        setEdited(true)
        setFloorplanOccupancyEnabled(isFloorplanOccupancyEnabled)
        setLocalityData((prev) => (prev ? { ...prev, isFloorplanOccupancyEnabled } : undefined))
    }

    const onOpeningHoursUpdate = (data: LocalityModel) => {
        if (selectedLocality === undefined) {
            return
        }

        const { openingHours } = data

        setLocalityData((prev) => (prev ? { ...prev, openingHours } : undefined))

        updateLocality({
            organizationId: organization.id,
            localityId: selectedLocality.id,
            body: { ...selectedLocality, openingHours },
        })
    }

    const normalizedLocalities = useMemo(
        () => localities.map((locality) => ({ ...locality, normalizedName: deburr(locality.name) })),
        [localities]
    )

    const filteredLocalities = normalizedLocalities.filter(({ normalizedName }) =>
        normalizedName.toLowerCase().includes(deburr(search).toLowerCase())
    )

    const openingHours = localityOpeningHoursCall.data?.items ?? []

    const localityOpeningHours = openingHours.find(
        ({ localityId }) => localityId === selectedLocality?.id
    )?.openingHours

    const openingHoursCopyCandidates = openingHours.filter(({ openingHours }) =>
        Object.values(openingHours).some((values) => !isEmpty(values))
    )

    const copyLocalityOpeningHours = (openingHours: OpeningHours) => {
        if (selectedLocality === undefined) {
            return
        }

        setLocalityData((prev) => (prev ? { ...prev, openingHours } : undefined))

        updateLocality({
            organizationId: organization.id,
            localityId: selectedLocality.id,
            body: { ...selectedLocality, openingHours },
        })
    }

    useEffect(() => {
        setEdited(false)

        if (isNewLocality) {
            setLocalityData({ locality: { ...newEmptyLocality, organizationId: organization.id } })
        } else if (isUserListPage && !mobile && selectedLocality === undefined && !isEmpty(localities)) {
            handleLocalitySelection(localities[0], true)
        } else {
            setLocalityData(localityToEditData(selectedLocality, undefined, undefined, localityOpeningHours))
        }
    }, [selectedLocality, isNewLocality, mobile, isUserListPage, localityOpeningHours, documentTitle])

    useEffect(() => {
        if (scenesInLocalityCall.status === 'success') {
            setSelectedScenes(scenesInLocalityCall.data?.scenes)
        }
    }, [scenesInLocalityCall.status, selectedLocality])

    useEffect(() => {
        setSelectedUsers(usersInLocality)
    }, [selectedLocality])

    useEffect(() => {
        if (localityCall.isSuccess) {
            setGeoLocation(localityCall.data.location)
            setRealTimeOccupancyEnabled(localityCall.data.isRealTimeOccupancyEnabled)
            setFloorplanOccupancyEnabled(localityCall.data.isFloorplanOccupancyEnabled)
        }
    }, [localityCall.status, selectedLocality])

    const handleSearch = (search: string) => {
        history.replace({ ...location, search: search ? `search=${search}` : undefined })
    }

    const handleLocalitySelection = (locality?: LocalityNameModel, forceReplace = false) => {
        const url =
            locality !== undefined
                ? search
                    ? `${generateOrganizationsEditLocalitiesPath(organization.id, locality.id)}?search=${search}`
                    : generateOrganizationsEditLocalitiesPath(organization.id, locality.id)
                : generateEditOrganizationPath(organization.id, OrganizationEditPageTabs.localities)

        if (selectedLocality || forceReplace) {
            history.replace(url)
        } else {
            history.push(url)
        }
    }

    const handleNewLocality = () => {
        if (selectedLocality) {
            history.push(generateOrganizationsCreateLocalitiesPath(organization.id))
        } else {
            history.replace(generateOrganizationsCreateLocalitiesPath(organization.id))
        }
    }

    const handleLocalityCreate = (localityData?: LocalitiesEditData) => {
        if (localityData) {
            onLocalityCreate({ organizationId: organization.id, values: localityData })
        }

        return
    }

    const saveButton = (
        <AsyncButton
            disabled={!isValid}
            loadingText={t('button.savingChanges', 'Saving changes...')}
            status={localityModificationState}
            text={t('button.saveChanges', 'Save changes')}
            type="submit"
            variant="primary"
            onClick={(e) => {
                e.preventDefault()
                handleSaveChanges()
            }}
        />
    )

    const createButton = (
        <AsyncButton
            disabled={!isValid}
            loadingText={t('button.savingChanges', 'Saving changes...')}
            status={localityCreationState}
            text={t('button.createNewLocality', 'Create new locality')}
            type="submit"
            variant="primary"
            onClick={() => handleLocalityCreate(localityData)}
        />
    )

    const cancelButton = (
        <Button
            variant="secondary"
            onClick={() => {
                if (isNewLocality) {
                    const resetLocality = mobile ? undefined : localities[0]
                    handleLocalitySelection(resetLocality)
                } else {
                    setLocalityData(localityToEditData(selectedLocality, undefined, undefined, localityOpeningHours))
                    setEdited(false)
                    setSelectedScenes(scenesInLocalityCall.data?.scenes)
                    setSelectedUsers(usersInLocality)
                }
            }}
        >
            {t('button.cancel', 'Cancel')}
        </Button>
    )

    const addLocalityButton = (
        <Button variant="secondary" onClick={handleNewLocality}>
            <FontAwesomeIcon icon={faPlus} />
            {t('button.addLocality', 'Add locality')}
        </Button>
    )

    const localityOpeningHoursCopyCandidates = Object.fromEntries(
        localities
            .filter(({ id }) => openingHoursCopyCandidates.some(({ localityId }) => localityId === id))
            .map((locality) => [locality.id, locality])
    )

    const copyOpeningHoursButton = openingHoursCopyCandidates && (
        <LocalityPicker
            features={[]}
            localities={localityOpeningHoursCopyCandidates}
            organizations={keyBy([organization], (o) => o.id)}
            placeholder={t('button.CopyOpeningHoursFromElsewhere', 'Copy opening hours from elsewhere')}
            selectedLocality={undefined}
            onSelect={(locality) => {
                const newOpeningHours = openingHoursCopyCandidates.find(
                    ({ localityId }) => localityId === locality.id
                )?.openingHours

                if (newOpeningHours && isExistingLocality(localityData?.locality)) {
                    copyLocalityOpeningHours(newOpeningHours)
                }
            }}
        />
    )

    const placeholder = (
        <div className={styles.noItemsCreatedContainer}>
            <span className={styles.noItemsCreated}>{t('others.noLocalitiesCreated', 'No localities created')}</span>
        </div>
    )

    const form = localityData && (
        <LocalitiesTabForm
            key={isExistingLocality(localityData.locality) ? localityData.locality.id : NEW_ITEM_ID}
            actionButtons={
                mobile ? (
                    <>
                        {(isEdited || isNewLocality) && (
                            <div className={styles.controlButtonRow}>
                                {isNewLocality ? createButton : saveButton}
                                {cancelButton}
                            </div>
                        )}
                    </>
                ) : undefined
            }
            copyOpeningHoursButton={!isEmpty(localityOpeningHoursCopyCandidates) ? copyOpeningHoursButton : undefined}
            errors={errors}
            geoLocation={geoLocation}
            isFloorplanOccupancyEnabled={isFloorplanOccupancyEnabled}
            isRealTimeOccupancyEnabled={isRealTimeOccupancyEnabled}
            localityCall={localityCall}
            localityLabelsCall={localityLabelsCall}
            localityOpeningHoursCall={localityOpeningHoursCall}
            localitySceneDescriptionsCall={sceneConfigurationsCall}
            localityScenes={scenesInLocalityCall.data?.scenes ?? []}
            localityToEdit={localityData}
            openingHoursUpdateStatus={localityUpdateState}
            organization={organization}
            organizationScenes={scenesInOrganization}
            organizationUsers={users}
            selectedScenes={selectedScenes ?? []}
            selectedUsers={selectedUsers ?? []}
            onErrorsChanged={setErrors}
            onFloorplanOccupancyToggle={handleFloorplanOccupancyUpdate}
            onLocalityDelete={onLocalityDelete}
            onLocalityUpdate={(localityUpdate) => {
                setLocalityData((prev) => (prev ? { ...prev, ...localityUpdate } : undefined))
                setEdited(true)
            }}
            onLocationSelect={handleGeoLocationUpdate}
            onOpeningHoursUpdate={onOpeningHoursUpdate}
            onRealtimeOccupancyToggle={handleRealtimeOccupancyUpdate}
            onScenesSelect={handleSelectScenes}
            onUsersSelect={handleSelectUser}
        />
    )

    const unfilteredSelectableEntries = normalizedLocalities.map(({ id, name }) => ({
        id: String(id),
        displayText: name,
        icon: faMapMarkerAlt,
        indicators: determineIndicators(id, localityConfigurations.data.localities),
    }))

    const navigationItems: Array<VerticalListEntry> = orderLocalities(filteredLocalities).map(({ id, name }) => ({
        id: String(id),
        displayText: name,
        icon: faMapMarkerAlt,
        indicators: determineIndicators(id, localityConfigurations.data.localities),
    }))

    const navigationItemsWithNew = isNewLocality
        ? [
              {
                  id: NEW_ITEM_ID,
                  displayText: t('others.newLocality', 'New locality'),
                  icon: faMapMarkerAlt,
              },
              ...navigationItems,
          ]
        : navigationItems

    const desktopNavigation = (
        <VerticalListGroup
            placeholder={t('others.localityName', 'Locality name')}
            search={search}
            selectableEntries={navigationItemsWithNew}
            selectedEntry={isNewLocality ? NEW_ITEM_ID : selectedLocality?.id.toString()}
            unfilteredSelectableEntries={unfilteredSelectableEntries}
            scrollToSelected
            onSearch={handleSearch}
            onSelectionChange={(id) => {
                handleLocalitySelection(filteredLocalities.find((l) => l.id.toString() === id))
            }}
        />
    )

    const showConfirmDialog =
        Boolean(useRouteMatch(ORGANIZATION_LOCALITIES_TAB_PATH)) && isEdited && isValid && !isNewLocality

    const handleSaveChanges = () => {
        if (localityData && isValid && isExistingLocality(localityData.locality)) {
            onLocalityUpdate({
                organizationId: organization.id,
                localityData: {
                    locality: localityData.locality,
                    scenes: localityData.scenes,
                    users: localityData.users,
                    openingHours: localityData.openingHours,
                    location: localityData.location,
                    isFloorplanOccupancyEnabled: localityData.isFloorplanOccupancyEnabled,
                    isRealTimeOccupancyEnabled: localityData.isRealTimeOccupancyEnabled,
                },
            })
        }
    }

    const handleResetChanges = (action: () => void) => {
        setLocalityData(localityToEditData(selectedLocality, undefined, undefined, localityOpeningHours))
        setEdited(false)
        action()
    }

    return (
        <>
            <Helmet>
                <title>{documentTitle}</title>
            </Helmet>
            {isFloorplanMappingPath ? (
                params.floorplanId && (
                    <LegacyLoadingWrapper request={floorplanMappingsRequests}>
                        {([locality, { mappings }, { scenes }]) => (
                            <ScenesToFloorplan
                                key={params.floorplanId}
                                locality={locality}
                                mappings={mappings}
                                organization={organization}
                                scenesInLocality={scenes}
                                selectedFloorplanNumber={Number(params.floorplanId)}
                            />
                        )}
                    </LegacyLoadingWrapper>
                )
            ) : (
                <Box paddingSize="lg">
                    {mobile ? (
                        <AccordionLayout
                            heading={t('table.localities', 'Localities')!}
                            headingButtons={<>{!isNewLocality && addLocalityButton}</>}
                            items={navigationItemsWithNew}
                            placeholder={placeholder}
                            selectedItem={isNewLocality ? NEW_ITEM_ID : selectedLocality?.id.toString()}
                            selectedItemContent={form ?? <></>}
                            onSelect={(id) =>
                                handleLocalitySelection(filteredLocalities.find((l) => l.id.toString() === id))
                            }
                        />
                    ) : (
                        <SideNavigationListLayout
                            columnGap="md"
                            content={form}
                            contentHeading={{
                                heading: !localityData ? placeholder : '',
                                headingButtons: (
                                    <>
                                        {(isEdited || isNewLocality) && (
                                            <div className={styles.controlButtonRow}>
                                                {isNewLocality ? createButton : saveButton}
                                                {cancelButton}
                                            </div>
                                        )}
                                    </>
                                ),
                            }}
                            listHeading={{
                                heading: t('table.localities', 'Localities'),
                                headingButtons: !isNewLocality ? addLocalityButton : undefined,
                            }}
                            navigation={desktopNavigation}
                        />
                    )}
                    <NavigationConfirm when={showConfirmDialog}>
                        {({ onCancel }) => (
                            <Modal
                                centered={true}
                                className={localityEditStyles.navigationConfirmModal}
                                show={isEdited && isValid}
                                onHide={() => {
                                    setEdited(false)
                                    onCancel()
                                }}
                            >
                                <Card.Header className={localityEditStyles.header}>
                                    <span>
                                        <strong>{t('modal.unsavedChanges', 'Unsaved changes')}</strong>
                                    </span>
                                    <FontAwesomeIcon
                                        icon={faTimes}
                                        onClick={() => {
                                            setEdited(false)
                                            onCancel()
                                        }}
                                    />
                                </Card.Header>
                                <Modal.Body>
                                    {t(
                                        'modal.unsavedChangesMessage',
                                        'You have unsaved changes in {{locality}}. Do you want to save or discard them?',
                                        { locality: selectedLocality?.name }
                                    )}
                                    <div className={localityEditStyles.buttonRow}>
                                        <Button variant="primary" onClick={() => handleSaveChanges()}>
                                            {t('button.saveChanges', 'Save changes')}
                                        </Button>
                                        <Button variant="secondary" onClick={() => handleResetChanges(onCancel)}>
                                            {t('button.discardChanges', 'Discard changes')}
                                        </Button>
                                    </div>
                                </Modal.Body>
                            </Modal>
                        )}
                    </NavigationConfirm>
                </Box>
            )}
        </>
    )
}

export default LocalitiesTab
