/* tslint:disable */
/* eslint-disable */
/**
 * Vividi Dashboard API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PropertyName,
    PropertyNameFromJSON,
    PropertyNameFromJSONTyped,
    PropertyNameToJSON,
} from './PropertyName';
import {
    SummaryBracket,
    SummaryBracketFromJSON,
    SummaryBracketFromJSONTyped,
    SummaryBracketToJSON,
} from './SummaryBracket';

/**
 * 
 * @export
 * @interface SummaryCategory
 */
export interface SummaryCategory {
    /**
     * 
     * @type {PropertyName}
     * @memberof SummaryCategory
     */
    propertyName: PropertyName;
    /**
     * 
     * @type {Array<SummaryBracket>}
     * @memberof SummaryCategory
     */
    brackets: Array<SummaryBracket>;
}

export function SummaryCategoryFromJSON(json: any): SummaryCategory {
    return SummaryCategoryFromJSONTyped(json, false);
}

export function SummaryCategoryFromJSONTyped(json: any, ignoreDiscriminator: boolean): SummaryCategory {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'propertyName': PropertyNameFromJSON(json['property_name']),
        'brackets': ((json['brackets'] as Array<any>).map(SummaryBracketFromJSON)),
    };
}

export function SummaryCategoryToJSON(value?: SummaryCategory | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'property_name': PropertyNameToJSON(value.propertyName),
        'brackets': ((value.brackets as Array<any>).map(SummaryBracketToJSON)),
    };
}

