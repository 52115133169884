import classNames from 'classnames'
import { DateTime, Interval } from 'luxon'
import React, { useState } from 'react'
import { Button, ButtonGroup } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import { displayTranslatedGranularity } from '@helpers/translateUtils'
import { IntervalType } from '@helpers/types'

import DateTimeIntervalPicker from '@components/DateTimeIntervalPicker/DateTimeIntervalPicker'

interface IntervalButtonProps {
    intervalType: IntervalType
    currentInterval?: IntervalType
    label: string
    onClick: (intervalType: IntervalType) => void
}

interface Props {
    pickedInterval: Interval
    intervalType: IntervalType
    intervalPickerClassName?: string
    onIntervalTypeChange: (intervalType: IntervalType) => void
    onCustomIntervalChange: (interval: Interval) => void
    availableGranularity: IntervalType[]
}

const IntervalButton: React.FC<IntervalButtonProps> = (props) => (
    <Button
        variant={props.intervalType === props.currentInterval ? 'primary' : 'secondary'}
        onClick={() => props.onClick(props.intervalType)}
    >
        {props.label}
    </Button>
)

const GranularityPicker: React.FC<Props> = (props) => {
    const { t } = useTranslation()
    const [now] = useState(DateTime.utc())

    return (
        <>
            <ButtonGroup className={props.intervalPickerClassName}>
                {props.availableGranularity.map((granularity) => {
                    if (granularity === IntervalType.CUSTOM) {
                        return (
                            <DateTimeIntervalPicker
                                key={granularity}
                                interval={props.pickedInterval}
                                isTimeDisabled={false}
                                now={now}
                                onSubmit={props.onCustomIntervalChange}
                            >
                                <input
                                    className={classNames({
                                        btn: true,
                                        'btn-primary': props.intervalType === IntervalType.CUSTOM,
                                        'btn-secondary': props.intervalType !== IntervalType.CUSTOM,
                                    })}
                                    type="button"
                                    value={displayTranslatedGranularity(IntervalType.CUSTOM, t)}
                                />
                            </DateTimeIntervalPicker>
                        )
                    }

                    return (
                        <IntervalButton
                            key={granularity}
                            currentInterval={props.intervalType}
                            intervalType={granularity}
                            label={displayTranslatedGranularity(granularity, t)}
                            onClick={props.onIntervalTypeChange}
                        />
                    )
                })}
            </ButtonGroup>
        </>
    )
}

export default GranularityPicker
