import { Interval } from 'luxon'
import React from 'react'

import { LocalityNameModel } from '@api/models'

import { displayNumericValue, formatLargeNumber } from '@helpers/displayUtils'

import ExplanationTooltip from '@components/ExplanationTooltip'

import styles from './ConversionStatisticBox.module.scss'
import ChangeIndicator from './LocalityTable/ChangeIndicator'
import { calculatePercentageChange } from './utils'

type Props = {
    data: Array<{
        name: string
        value: number
        description: string
        comparisonValue?: number
    }>
    interval: Interval
    comparisonInterval?: Interval
    localities: LocalityNameModel[]
}

const ConversionStatisticBox = ({ data, interval, comparisonInterval, localities }: Props) => {
    return (
        <div className={styles.wrapper}>
            {data.map((chunk) => (
                <div key={chunk.name} className={styles.column}>
                    <div className={styles.label}>
                        {chunk.name}
                        <ExplanationTooltip
                            selectedInterval={interval}
                            selectedLocalities={localities}
                            statisticsValue={Math.round(chunk.value)}
                            statisticsValueDescription={chunk.description}
                        />
                    </div>
                    <div className={styles.dataDisplay}>
                        <div className={styles.data}>{formatLargeNumber(displayNumericValue(chunk.value, 0))}</div>
                    </div>
                    {comparisonInterval && (
                        <div className={styles.changeContainer}>
                            <ChangeIndicator
                                change={calculatePercentageChange(chunk.value, chunk.comparisonValue)}
                                comparisonInterval={comparisonInterval}
                                dataType="count"
                                desiredTrend="positive"
                                isVisible={comparisonInterval !== undefined}
                                valueToCompare={chunk.comparisonValue ?? 0}
                                isBigger
                            />
                        </div>
                    )}
                </div>
            ))}
        </div>
    )
}

export default ConversionStatisticBox
